import m from 'm';
import _ from 'lodash';
import App from 'Models/App';
import Enum from 'models/Enum';
import popup from 'services/popupService';
import reactCreator from 'components/react.creator';

var component = {};

var OPTIONS = Enum.RestrictionTypes;

component.controller = function controller(args) {
  var self = this;

  self.currentLanguageId = m.prop(0);
  self.segmentationSelected = makeSegmentationSelection();

  self.key = m.prop(makeKey());

  self.source = m.prop(makeSource());
  self.typeId = args.authRule.restrictionId();
  self.index = m.prop(args.id + 1);

  self.optionConfigurations = m.prop({
    [OPTIONS.CODE]: m.prop({
      type          : 'magic_code',
      onButtonClick : handleButtonClick.bind(null, args, self),
      onIconClick   : generateCode.bind(null, args, self),
      onInputChange : onCodeChange.bind(null, args, self),
      buttonText    : 'Copy',
      value         : args.accessControl.code(),
      buttonDisabled: !args.accessControl.code(),
    }),
    [OPTIONS.EMAIL_DOMAIN]: m.prop({
      type       : 'email_domain',
      placeholder: 'ex   : sparted.com',
      onChange   : onDomainChange.bind(null, args, self),
      value      : args.accessControl.domain(),
    }),
    [OPTIONS.WHITELIST]: m.prop({
      type   : 'email_list',
      text   : getEmailText(),
      onClick: viewList.bind(null, args),
    }),
  });

  self.optionInformation = {
    [OPTIONS.CODE]: {
      parameterLabel: 'Choose a Magic code to control the access to your application',
    },
    [OPTIONS.EMAIL_DOMAIN]: {
      parameterLabel: 'Define the domain name which control the access to your application',
    },
    [OPTIONS.WHITELIST]: {
      parameterLabel: 'Add an email list to manage the access to your application',
    },
  };

  function getEmailText() {
    if (!args.authRule.accessControls().at(args.id).id()
      || !args.authRule.accessControls().at(args.id).emails().length)
      return 'Add Email list';

    return 'View list (' + args.authRule.accessControls().at(args.id).emails().length + ' items )';
  }

  function viewList(_args) {
    var id = _args.authRule.accessControls().at(_args.id).id();

    if (!id) {
      return popup.open({
        type : 'info',
        title: 'Please save before editing emails',
      });
    }
    var path = '/gaia/signup/segmented/emailList/' + id;

    return m.route(path);
  }

  function makeSegmentationSelection() {
    if (!args.accessControl.segmentation().length)
      return [];

    var segmentation = args.accessControl.segmentation();

    if (typeof segmentation.toJSON === 'function')
      segmentation = segmentation.toJSON();

    return segmentation
      .reduce((acc, segment) => {
        if (segment.dimension.typeId === Enum.dimensionTypes.LANGUAGE)
          self.currentLanguageId(segment.id);

        var newSelection = {
          [segment.id]: true,
        };

        return Object.assign(acc, newSelection);
      }, {});
  }

  function makeSource() {
    var items = args.segmentationGroupItems
      .map((segGroup) => ({
        category   : 'segment',
        id         : segGroup.id(),
        value      : segGroup.label(),
        selected   : self.segmentationSelected[segGroup.id()] === true,
        color      : segGroup.dimension().color,
        dimensionId: segGroup.dimensionId(),
      }));

    return {
      icon   : '',
      name   : '',
      filters: [
        { category: 'segment', type: 'multiselect', items },
      ],
    };
  }

  function onDomainChange(_args, _self, value) {
    _args.authRule.accessControls().at(args.id).domain(value);

    var config = _self.optionConfigurations()[_self.typeId]();

    config.value = value;

    _self.optionConfigurations()[_self.typeId](config);
    m.redraw();
  }

  function onCodeChange(_args, _self, value) {
    _args.authRule.accessControls().at(args.id).code(value.trim());

    var config = _self.optionConfigurations()[_self.typeId]();

    config.value = value;
    config.buttonDisabled = !args.accessControl.code();

    _self.optionConfigurations()[_self.typeId](config);
    m.redraw();
  }

  function generateCode(_args, _self) {
    var DEFAULT_CODE_LENGTH = 6;
    var letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var nums = '12345678910';
    var code = '';

    while (code.length < DEFAULT_CODE_LENGTH) {
      if (Math.random() > 0.5)
        code += _.sample(letters) + _.sample(letters);
      else
        code += _.sample(nums) + _.sample(nums);
    }

    _args.authRule.accessControls().at(args.id).code(code);
    _args.modified(true);
    var config = _self.optionConfigurations()[_self.typeId]();

    config.value = code;
    config.buttonDisabled = !_args.authRule.accessControls().at(args.id).code();

    _self.optionConfigurations()[_self.typeId](config);
    m.redraw();
  }

  function handleButtonClick(_args, _self, value) {
    var toSearch = 'input[data-copy-id=\'s-code-input-' + value + '\']';
    var toCopy = document.querySelector(toSearch);
    var config = _self.optionConfigurations()[_self.typeId]();

    toCopy.select();
    if (document.execCommand('copy')) {
      window.getSelection().removeAllRanges();
      config.buttonText = 'Copied!';
      _self.optionConfigurations()[_self.typeId](config);
      m.redraw();

      var temp = setInterval(() => {
        config.buttonText = 'Copy';
        _self.optionConfigurations()[_self.typeId](config);
        m.redraw();
        clearInterval(temp);
      }, 1000);
    } else
      console.error('The clipboard copy went wrong…');
  }

  function makeKey() {
    return 'restriction-' + args.accessControl.id() + '-' + self.currentLanguageId();
  }

  /**
   * @Function getUniqueLanguageSegment
   * Allow us to replace the new language segment selected by user (if there is
   * a new language segment in the new user selection)
   *
   * @Params :
   * - oldId: current language segment id
   * - newSegmentation: the new items selected by the user (Collection of
   * SegmentationGroupItem, with probably two language segment)
   * - newId: the new language id init to zero
   *
   * @Return :
   * - An Object with the newId updated to the new language id (set to 0 if
   * there is no language in the selection), and the new segmentation with only
   * one language segment or none.
   */
  function getUniqueLanguageSegment(oldId, newSegmentation, newId) {
    if (oldId !== 0) {
      var segPurged = newSegmentation.filter((segment) => {
        if (segment.dimension().typeId === Enum.dimensionTypes.LANGUAGE) {
          if (segment.id() === oldId)
            return false;
          newId = segment.id();

          return true;
        }

        return true;
      });

      if (newId !== 0)
        newSegmentation = segPurged;
      else
        newId = oldId;
    } else {
      var languageSegment = newSegmentation.find((segment) => {
        return segment.dimension().typeId === Enum.dimensionTypes.LANGUAGE;
      });

      newId = languageSegment ? languageSegment.id() : 0;
    }

    return {
      newId          : newId,
      newSegmentation: newSegmentation,
    };
  }

  self.getDimensions = function getDimensions() {

    return App.segmentationDimensions().items.map(dimension => {
      return ({
        id   : dimension.id(),
        label: dimension.getLabel().label(),
      });
    });
  }

  self.handleFilterChanged = function handleFilterChanged(filters) {
    var filter = filters.length && filters[0];
    var oldId = self.currentLanguageId();
    var newId = 0;

    if (filter.category !== 'segment')
      return;

    var selectedIds = filter.ids
      .reduce((acc, id) => {
        var newSelection = {
          [id]: true,
        };

        return Object.assign(acc, newSelection);
      }, {});

    var newSegmentation = args.segmentationGroupItems
      .filter((segment) => {
        return selectedIds[segment.id()] === true;
      });

    var values = getUniqueLanguageSegment(oldId, newSegmentation, newId);

    newId = values.newId;
    newSegmentation = values.newSegmentation;

    // update the source given to the Omnibox in case of the language had change and save the new current language
    if (newId !== oldId) {
      delete selectedIds[oldId];
      self.segmentationSelected = selectedIds;
      self.source(makeSource());
      self.currentLanguageId(newId);
      self.key(makeKey());
    }

    // update the segmentation selected in the access control model
    args.authRule.accessControls().at(args.id).segmentation(newSegmentation);
    m.redraw();
  };
};

component.view = function view(c, args) {
  var typeId = args.authRule.restrictionId();

  c.index(args.id + 1);

  return m('.authentication__settings__access-control_parameters', [
    m(reactCreator, {
      component: 'SRestrictionParameter',
      props    : {
        title          : 'Restriction parameter n°',
        key            : c.key,
        id             : c.index,
        parameterLabel : c.optionInformation[typeId].parameterLabel,
        source         : c.source,
        configuration  : c.optionConfigurations()[typeId],
        filterLabel    : 'Automatically affiliate players to a segmentation when they sign up through this method (optional)',
        onFilterChanged: c.handleFilterChanged,
        onDelete       : args.onDelete.bind(null, args.id),
        deletable      : args.deletable,
        dimensions     : c.getDimensions(),
      },
    }),
  ]);
};

export default component;
