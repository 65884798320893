import { mergeDeep } from 'Libs/mergeDeep';

import SegmentationDimension from 'ModelsReact/SegmentationDimension/SegmentationDimension';

import type { SegmentationDimensionType } from 'ModelsReact/SegmentationDimension/SegmentationDimension';

export type SegmentationGroupItemType = Readonly<{
  id: number,
  label: string,
  dimension: SegmentationDimensionType,
}>;

export default function createSegmentationGroupItem(values?: any): SegmentationGroupItemType {
  const defaultState: SegmentationGroupItemType = {
    id: 0,
    label: '',
    dimension: SegmentationDimension(),
  };

  if (!values) {
    return defaultState;
  }

  const dimension = SegmentationDimension(values.dimension);

  return mergeDeep<SegmentationGroupItemType>(defaultState, { ...values, dimension });
}
