import Model from 'models/Model';

var SegmentationType = {
  endpoint  : 'api/SegmentationTypes',
  attributes: {
    label: {
      type: 'string',
    },
    behaviors: {
      type : 'collection',
      Model: 'SegmentationBehavior',
    },
  },
  methods: {},
};

export default Model.register('SegmentationType', SegmentationType);
