import { ELEVATIONS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    boxShadow: ELEVATIONS.LIGHT_LOW,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  header: {
    maxHeight: '160px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header__image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 16px',
  },
  content__title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
  },
  content__text: {
    marginBottom: '32px',
  },
});

export default styleSheet;
