import Model from 'models/Model';

var KnowledgeStatus = {
  endpoint  : 'api/KnowledgeStatuses',
  attributes: {
    label: {
      type: 'string',
    },
  },
  classMethods: {
    itemAfter: function itemAfter(_Knowledge, currentId, all) {
      var newStatus = null;

      all.map(function getNewStatus(item, i, array) {
        if (item.id() === currentId)
          newStatus = array[i + 1];
      });

      return newStatus;
    },
    itemBefore: function itemAfter(_Knowledge, currentId, all) {
      var newStatus = null;

      all.map(function getNewStatus(item, i, array) {
        if (item.id() === currentId)
          newStatus = array[i - 1];
      });

      return newStatus;
    },
  },
};

export default Model.register('KnowledgeStatus', KnowledgeStatus);
