import React from 'react';
import { useSelector } from 'react-redux';

import SDivider from 'Components/structural/SDivider/SDivider';
import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import { getIsModeSelectionLocked, useModuleSelector } from '../../../redux';

import styles from './Panel.style';

import LaunchDateForm from '../LaunchDateForm/LaunchDateForm';
import DailySerieTypeForm from '../DailySerieTypeForm/DailySerieTypeForm';
import DaysEnabledForm from '../DaysEnabledForm/DaysEnabledForm';
import OrderModeForm from '../OrderModeForm/OrderModeForm';

export const Panel = () => {
  const modeSelectionLocked = useSelector(getIsModeSelectionLocked);

  const isLocked = useModuleSelector((state) => state.isLocked);
  const overlayStyle = isLocked ? styles.locked : styles.unlocked;

  return (
    <div style={styles.wrapper}>
      <OrderModeForm editable={!modeSelectionLocked} />
      <SDivider style={styles.divider} />
      <DailySerieTypeForm />
      <SDivider style={styles.divider} />
      <LaunchDateForm />
      <SDivider style={styles.divider} />
      <DaysEnabledForm />
      <SDivider style={styles.divider} />
      <div style={styles.bottomSpace} />
      <div style={overlayStyle}>
        <UIcon name="lock" size={64} color={COLORS.TEXT.SECONDARY_DEFAULT} />
      </div>
    </div>
  );
};
