import React, { memo, useMemo } from 'react';
import { getCdnUrl } from 'Libs/cdn';

import UImage from 'Components/unit/UImage/UImage';

import styles from './UFlag.style';

export type UFlagProps = {
  countryCode: string;
  size: number;
  style?: any;
  withBorder?: boolean;
};

/**
 * Display the country flag according to its country code
 *
 * Props:
 *  - countryCode: The string code of the country (ex: fr)
 *  - size: Size of the icon
 *  - style: override component's style
 */
export const UFlag = ({ countryCode, size, style, withBorder = true }: UFlagProps) => {
  const sizeStyle = useMemo(
    () => ({ width: withBorder ? size : size - 2, height: withBorder ? size : size - 2 }),
    [withBorder, size],
  );

  return (
    <div style={{ ...styles.wrapper, ...sizeStyle, ...style }}>
      <UImage
        // Subtract 2 pixel of the svg size because of the border of the component
        width={size - 2}
        style={{ ...styles.image, ...(withBorder && styles.withBorder) }}
        src={getCdnUrl('common', `/flag-images/${countryCode}.svg`)}
      />
    </div>
  );
};

export default memo<UFlagProps>(UFlag);
