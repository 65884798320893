import { createStyleSheet } from 'Components/foundation/stylesheets';

import { CORNERS, COLORS, ELEVATIONS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  container: {
    width: '100%',
    height: '100%',
    padding: '24px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: CORNERS.CORNER4,
    boxShadow: ELEVATIONS.LIGHT_TINY,
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderWidth: '1px',
    borderStyle: 'solid',
    minWidth: '350px',
    position: 'relative',
    transition: 'box-shadow 300ms, background-color 150ms',
  },
  containerSelected: {
    backgroundColor: COLORS.PRIMARY_LIGHT.DEFAULT,
  },
  containerHoverWithoutPointer: {
    boxShadow: ELEVATIONS.LIGHT_LOW,
  },
  containerHover: {
    cursor: 'pointer',
    boxShadow: ELEVATIONS.LIGHT_LOW,
  },
  containerDisabled: {
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    top: '16px',
    right: '16px',
    position: 'absolute',
  },
  contentDisabled: {
    opacity: 0.75,
  },
});

export default styleSheet;
