/**
 * Displays an icon
 *
 * Props:
 * - name: the name of the icon
 * - size: the size of the icon, it will be interpreted as the width and height
 * - color: the color of the icon
 * - style: can override the component's style
 */

import React from 'react';
import type { CSSProperties, FunctionComponent } from 'react';

import { COLORS } from 'Components/foundation';
import { ICONS, IconName } from 'Components/foundation/icons';

export type UIconProps = {
  name: IconName;
  color?: string;
  size?: number;
  style?: CSSProperties;
};

export const UIcon: FunctionComponent<UIconProps> = ({
  name,
  style = undefined,
  color = COLORS.BLACK.DEFAULT,
  size = 32,
}) => {
  const icon = ICONS[name];
  const colorProperty = icon?.colorProperty || 'fill';

  return (
    <svg
      name={name}
      width={size}
      height={size}
      viewBox={icon.viewBox}
      style={style}
      stroke="transparent"
      fill="transparent"
      // Use the color property of the icon to override the transparent above. It can be fill or stroke.
      {...{ [colorProperty]: color }}
    >
      {icon.svg}
    </svg>
  );
};

export default UIcon;
