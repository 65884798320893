import request from 'services/requestService';
import Enum from 'models/Enum';
import Model from 'models/Model';

var SQCMChoice = {
  endpoint  : 'api/SQCMChoices',
  attributes: {
    gameItem: {
      type : 'pointer',
      Model: 'GameItem',
    },
    sqcm: {
      type : 'pointer',
      Model: 'SQCM',
    },
    gameItemId: {
      type: 'number',
    },
    sqcmId: {
      type: 'number',
    },
    rightAnswer: {
      type: 'boolean',
    },
  },
  methods: {},
};

export default Model.register('SQCMChoice', SQCMChoice);
