import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    width: 260,
  },
  instanceName: {
    ...TYPOGRAPHY.BODY3,
    opacity: 0.2,
    letterSpacing: 4,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    ...TYPOGRAPHY.TITAN_L,
    color: COLORS.BLACK.DEFAULT,
    marginTop: 2,
  },
  instruction: {
    ...TYPOGRAPHY.H2,
    color: COLORS.BLACK.DEFAULT,
    marginTop: 16,
  },
  passwordInputWrapper: {
    marginTop: 32,
  },
  buttonWrapper: {
    marginTop: 56,
  },
  errorWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  error: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
    position: 'absolute',
    top: 8,
  },
});

export default styleSheet;
