import UButton, { UButtonProps } from 'Components/unit/UButton/UButton';
import withLoading from 'Components/hoc/withLoading/withLoading';

/**
 * Displays a button and add prop loading
 *
 * Props:
 * - onRequestEnd: Function called in the loading end
 * - onClick: Function called when we click on the button
 * - type: The type of button
 * - style: For overriding the component's style
 * - text: The content of the button
 * - disabled: Boolean that prevent user's actions on the button
 * - rightIcon: String to set a icon on the right of the button content
 * - leftIcon: String to set a icon on the left of the button content
 * - rightIconSize: Size of the right icon
 * - leftIconSize: Size of the left icon
 * - loading: Boolean, shows a spinner if true
 * - progress: Set the percentage of loading progression
 * - ghost : Boolean to trigger ghost mode
 */
export const UButtonWithLoading = withLoading({
  timeBeforeLoading: 100,
  minTimeLoading: 200,
})<UButtonProps>(UButton);

export default UButtonWithLoading;
