import { fetchFactory } from 'Libs/http/utils';

type SummarizePayloadAndResponse = {
  text: string;
  languageCode: string;
};

export const summarizeText = async ({ text, languageCode }: SummarizePayloadAndResponse) => {
  const { fetchFn } = fetchFactory<SummarizePayloadAndResponse>({
    route: `/api/summarize`,
    method: 'POST',
    payload: {
      text,
      languageCode,
    },
  });

  try {
    const result = await fetchFn();

    return { result };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return { error: err };
  }
};
