import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const VIDEO_WIDTH = '532';
export const VIDEO_HEIGHT = '296';

export default createStyleSheet({
  container: {
    width: '100%',
    paddingLeft: '127px',
    paddingRight: '127px',
    paddingTop: '24px',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  title: {
    ...TYPOGRAPHY.HERO_L_BOLD,
  },
  subtitle: {
    ...TYPOGRAPHY.H3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  gameplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '77px',
    marginBottom: '24px',
    paddingLeft: '48px',
    gap: '40px',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
  gameplayDescriptionContainer: {},
  gameplayTitle: {
    ...TYPOGRAPHY.H1_SEMIBOLD,
  },
  gameplayDescription: {
    ...TYPOGRAPHY.H3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  videoContainer: {
    width: `${VIDEO_WIDTH}px`,
    backgroundColor: COLORS.WHITE.HOVER,
  },
  pointsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 30px',
    ...TYPOGRAPHY.H2,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
