import React, { useCallback, useEffect, useMemo } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { t } from 'i18next';

import { useAppDispatch } from 'Libs/redux/store';

import { getContributionInfos } from 'Pages/Homepage/redux/thunks';
import { useModuleSelector } from 'Pages/Homepage/redux';
import { actions } from 'Pages/Homepage/redux/slice';

import SPageHeader from 'Components/structural/SPageHeader/SPageHeader';
import SDiscover, { SDiscoverProps } from 'Components/structural/SDiscover/SDiscover';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { COLORS } from 'Components/foundation';
import { SFooter } from 'Components/structural/SFooter/SFooter';
import styles from './Homepage.style';
import FinalState from './components/FinalState';
import InitialState from './components/InitialState';
import IntermediateState from './components/IntermediateState';

const SOME_IDEAS_CARDS: SDiscoverProps[] = [
  {
    image: 'assets/img/img-content-catalog.png',
    titleIcon: 'books',
    title: 'home:get_some_ideas_block.catalog_title',
    description: 'home:get_some_ideas_block.catalog_description',
    linkText: 'home:get_some_ideas_block.lets_go',
    linkHref: 'https://www.notion.so/SPARTED-Content-Catalog-03c309f4b4114c3da3d8a20ffee9b71b',
    trackingAction: 'navigate',
    trackingContext: 'content-catalog-from-homepage',
  },
  {
    image: 'assets/img/img-playbook.png',
    titleIcon: 'target',
    title: 'home:get_some_ideas_block.playbook_title',
    description: 'home:get_some_ideas_block.playbook_description',
    linkText: 'home:get_some_ideas_block.lets_go',
    linkHref: 'https://www.notion.so/SPARTED-Playbook-519fe9d609b9462ca0d27e87e6e89229',
    trackingAction: 'navigate',
    trackingContext: 'playbook-from-homepage',
  },
];

const getFirstName = () => {
  const jwtToken = localStorage.getItem('JWTToken');

  if (jwtToken) {
    const userData = localStorage.getItem(`${jwtToken}/user`);
    if (userData) {
      return JSON.parse(userData).firstName;
    }
  }

  return null;
};

export const Homepage = () => {
  const dispatch = useAppDispatch();
  const loading = useModuleSelector((state) => state.loading);
  const hasCreatedCampaign = useModuleSelector((state) => state.hasCreatedCampaign);
  const hasCreatedBreakingNews = useModuleSelector((state) => state.hasCreatedBreakingNews);
  const hasCreatedKnowledge = useModuleSelector((state) => state.hasCreatedKnowledge);
  const isOldUser = useModuleSelector((state) => state.isOldUser);
  const hasSkippedIntro = useModuleSelector((state) => state.hasSkippedIntro);
  const skipIntroVisibility = !loading && !hasSkippedIntro && !isOldUser && !hasCreatedCampaign;
  const welcomeFirstName = useMemo(() => {
    const firstName = getFirstName();

    // eslint-disable-next-line no-nested-ternary
    return firstName
      ? isOldUser || hasCreatedKnowledge
        ? `${t('home:welcome_back', { firstName })}`
        : `${t('home:welcome', { firstName })}`
      : 'Welcome!';
  }, [isOldUser, hasCreatedKnowledge]);

  const skipIntroduction = () => {
    localStorage.setItem('hasSkippedIntro', 'true');
    dispatch(actions.skipIntro());
  };

  useEffect(() => {
    if (localStorage.getItem('hasSkippedIntro') === 'true') {
      dispatch(actions.skipIntro());
    }

    const promise = dispatch(getContributionInfos());

    promise.unwrap().catch((e: any) => {
      // eslint-disable-next-line no-console -- Error is already handled with redux
      console.error('Something went wrong while fetching the contribution infos.', e);
    });

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const onboardingProcess = useCallback(() => {
    if (hasSkippedIntro || isOldUser) {
      return <FinalState />;
    }
    if (!hasCreatedKnowledge && !hasCreatedCampaign && !hasCreatedBreakingNews) {
      return <InitialState />;
    }
    if (!hasCreatedCampaign) {
      return <IntermediateState />;
    }

    return <FinalState />;
  }, [hasCreatedCampaign, hasCreatedBreakingNews, hasCreatedKnowledge, isOldUser, hasSkippedIntro]);

  return (
    <div style={styles.wrapper}>
      <SPageHeader
        type="accentuated"
        title={welcomeFirstName}
        subtitle={t('home:subtitle')}
        style={styles.mainHeader}
      />
      {skipIntroVisibility ? (
        <UTextLink
          style={styles.skipIntro}
          text="Skip this intro phase"
          onClick={skipIntroduction}
          trackingAction="skip"
          trackingContext="skip-onboarding-homepage"
        />
      ) : null}
      <SPageHeader type="standard" title={t('home:start_here_title')} />
      {loading && (
        <div style={styles.spinnerContainer}>
          <CircleSpinner size={20} color={COLORS.TEXT.SECONDARY_HOVER} loading={loading} />
        </div>
      )}
      {!loading && <div style={styles.rowContainer}>{onboardingProcess()}</div>}
      <SPageHeader type="standard" title={t('home:get_some_ideas_title')} />
      <div style={styles.sDiscoverContainer}>
        {SOME_IDEAS_CARDS.map((cardProps) => (
          <SDiscover
            {...cardProps}
            title={t(cardProps.title)}
            description={t(cardProps.description)}
            linkText={t(cardProps.linkText)}
            key={cardProps.title}
          />
        ))}
      </div>
      <SFooter>
        <UTextLink text={''} type="secondary" />
      </SFooter>
    </div>
  );
};

export default Homepage;
