import React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import UImage from 'Components/unit/UImage/UImage';

import styles from '../SActivityListItem.style';

type ImageProps = {
  image?: string | null;
};

export const Image = ({ image }: ImageProps) => {
  if (image) {
    return (
      <div style={styles.imageContainer}>
        <UImage
          style={styles.image}
          src={image}
          // Using the correct size as transformation makes the image blurry
          transformation={{
            height: 162 * 2,
            width: 162 * 2,
          }}
        />
      </div>
    );
  }

  return <UIcon name="image" size={70} color={COLORS.GREY_DARK.DEFAULT} />;
};
