import _ from 'lodash';
import {t} from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';

var PictureSpot = {
  endpoint  : 'api/PictureSpots',
  attributes: {
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    image: {
      type : 'pointer',
      Model: 'Image',
    },
    spots: {
      type : 'collection',
      Model: 'PictureSpotItem',
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_PictureSpot, knowledgeId) {
      return {
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
  methods: {
    presetFromKnowledge: function presetFromKnowledge(_PictureSpot, knowledge) {
      var explanationTexts = knowledge.getDistinctGameItemByTypeId(Enum.gameItemTypes.TEXT);
      var newExplanationData = explanationTexts.length ? explanationTexts[0] : knowledge.explanation().data();

      this.explanation().data(newExplanationData);
    },
    isEqualTo: function isEqualTo(_PictureSpot, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },
    countUseOfGameItem: function countUseOfGameItem(_OpenQuestion, giId) {
      var count = 0;

      this.spots().forEach(function countInItem(item) {
        if (item.question().id() === giId)
          count++;
        if (item.description().id() === giId)
          count++;
      });

      if (this.explanation().id() === giId)
        count++;

      return count;
    },
    deleteGameItem: function deleteGameItem(_PictureSpot, giId) {
      var GameItem = Model('GameItem');

      this.spots().forEach(function countInItem(item) {
        if (item.question().id() === giId)
          item.question(new GameItem());
        if (item.description().id() === giId)
          item.description(new GameItem());
      });

      if (this.explanation().id() === giId)
        this.explanation(new GameItem());
    },

    validate: function validate() {
      if (!this.spots().length)
        throw new Error(t('gameplays:validation.at_least_one_spot'));

      if (!this.explanation().data().length)
        throw new Error(t('gameplays:validation.explanation_missing'));

      this.spots().forEach(function countInItem(item, key) {
        if (!item.question().data().length)
          throw new Error(t('gameplays:validation.question_missing_question', {index: key + 1}));
        if (!item.description().data().length)
          throw new Error(t('gameplays:validation.description_missing_question', {index: key + 1}));
      });

      return true;
    },
    getDirective: function getDirective() {
      if (!this.image() || !this.image().url())
        return t('gameplays:directives.add_image');
      if (!this.spots().length)
        return t('gameplays:directives.first_spot');
      if (this.spots().length && !this.spots().at(0).question().data())
        return t('gameplays:directives.spot_question');
      if (this.spots().length && !this.spots().at(0).description().data())
        return t('gameplays:directives.spot_answer');
      if (!this.explanation().data())
        return t('gameplays:directives.enter_explanation');

      return '';
    },
  },
};

export default Model.register('PictureSpot', PictureSpot);
