import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UTextLink, { UTextLinkProps } from 'Components/unit/UTextLink/UTextLink';
import UButton, { UButtonProps } from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';

import { TYPOGRAPHY } from 'Components/foundation';
import { IllustrationName } from 'Components/foundation/illustrations';

import styles from './SEmptyState.style';

export type SEmptyStateProps = {
  title?: string;
  description?: string;
  size?: 'M' | 'L';
  illustrationHeight?: number | string;
  illustrationWidth?: number | string;
  textLink?: UTextLinkProps;
  button?: Button;
  style?: CSSProperties;
  illustrationName: IllustrationName;
};

type Button = {
  onButtonClick?: () => void;
  onButtonEnd?: () => void;
} & Omit<UButtonProps, 'onClick' | 'onRequestEnd'>;

const textsSize = {
  title: {
    M: TYPOGRAPHY.HERO_L_SEMIBOLD,
    L: TYPOGRAPHY.HERO_XL_SEMIBOLD,
  },
  description: {
    M: TYPOGRAPHY.BODY1,
    L: TYPOGRAPHY.H1,
  },
};

const illustrationSize = {
  width: {
    M: 250,
    L: 300,
  },
  height: {
    M: 155,
    L: 186,
  },
};

/**
 * SEmptyState props:
 * - title: string which displays the title
 * - description: string which displays the description
 * - size: size of the texts and illustrations
 * - illustrationHeight: height of the illustration
 * - illustrationWidth: width of the illustration
 * - onLearnMorePress: function called when the Learn more link is clicked
 * - onButtonClick: function called when the Let's go button is clicked
 * - onButtonEnd: function used in case of UButtonWithLoading
 * - link: link that will be opened when clicking the Learn More button
 * - style: prop to override the component's style
 */
export const SEmptyState = ({
  style = {},
  title,
  size = 'L',
  description,
  textLink,
  button,
  illustrationName,
}: SEmptyStateProps) => {
  const buttonStyling = textLink || button ? styles.buttonWrapper : undefined;

  const textStyle = useMemo(
    () =>
      description
        ? {
            ...styles.title,
            ...textsSize.title[size],
          }
        : {
            ...styles.title,
            ...textsSize.title[size],
            ...styles.titleWithoutDescription,
          },
    [description, size],
  );

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <UIllustration
        width={illustrationSize.width[size]}
        height={illustrationSize.height[size]}
        style={styles.illustration}
        illustration={illustrationName}
      />
      {title && <p style={textStyle}>{title}</p>}
      {description && (
        <p
          style={{
            ...styles.description,
            ...textsSize.description[size],
          }}
        >
          {description}
        </p>
      )}
      <div style={buttonStyling}>
        {textLink && <UTextLink style={styles.textLink} {...textLink} />}
        {button?.onButtonEnd && button?.onButtonClick && (
          <UButtonWithLoading
            onClick={button.onButtonClick as () => Promise<void>}
            onRequestEnd={button.onButtonEnd}
            {...button}
          />
        )}
        {button && !button.onButtonEnd && <UButton onClick={button.onButtonClick} {...button} />}
      </div>
    </div>
  );
};

export default SEmptyState;
