import Promise from 'bluebird';

import Enum from 'Models/Enum';
import Video from 'Models/Video';
import Image, { ImageInstance } from 'Models/Image';

import type { VideoProvider } from './MVideoUpload';

const YOUTUBE: VideoProvider = {
  icon: 'youtube',
  name: 'Youtube',
  typeId: Enum.videoType.Youtube,
  validateUrl: (url: string) => Video.isValidURL(url, Enum.videoType.Youtube),
  instruction: 'Right click on the video on youtube and copy the URL',
  instructionHref:
    'https://support.sparted.com/hc/en-us/articles/360014982932-How-to-add-a-video-on-youtube-for-your-Campaign-',
};

const KUMULLUS: VideoProvider = {
  icon: 'alert',
  name: 'Kumullus',
  typeId: Enum.videoType.Kumullus,
  validateUrl: (url: string) => Video.isValidURL(url, Enum.videoType.Kumullus),
  instruction: 'Paste the kumullus video link',
  instructionHref: '',
};

const APIVIDEO: VideoProvider = {
  icon: 'api-video',
  name: 'api.video',
  typeId: Enum.videoType.ApiVideo,
  validateUrl: (url: string) => Video.isValidURL(url, Enum.videoType.ApiVideo),
  instruction: 'Paste video link in .mp4 format',
  instructionHref: 'https://support.sparted.com/hc/en-us/articles/6023939256988-How-to-upload-a-video-with-video-api',
};

export const VIDEO_PROVIDERS: Record<number, VideoProvider> = {
  [Enum.videoType.Youtube]: YOUTUBE,
  [Enum.videoType.Kumullus]: KUMULLUS,
  [Enum.videoType.ApiVideo]: APIVIDEO,
};

type UploadVideoArgs = { url: string; videoTypeId: number };

export const saveVideo = async ({ url, videoTypeId }: UploadVideoArgs, uploadThumbnail = false) => {
  const video = new Video({
    url: Video.getVideoUrl(url, videoTypeId).trim(),
    typeId: videoTypeId,
  });

  return Promise.props({
    video: video.save(),
    ...(uploadThumbnail
      ? {
          image: video.getOriginalThumbnail().then((thumbnailUrl) =>
            Image.createFromMediaLib({
              url: thumbnailUrl,
              filename: video.thumbnailFilename(),
              height: 0,
              width: 0,
              type: Enum.imageTypeId.ORIGINAL,
            }),
          ) as Promise<ImageInstance>,
        }
      : {}),
  });
};
