/**
 * Input Number component
 * This component display an input with two buttons
 * which can reduce or increase the value passed as a parameter.
 *
 * @module inputNumber
 * @param {Number} value - displayed value
 * @param {Function} modified - prop who allow to know if the value is modified
 * @param {Function} validator - (optional) allow to know if we can continue to reduce or increase the value according to the min/max value
 * @param {Number} minValue - (optional) defined the min value authorized -> use to grayed out the button
 * @param {Number} maxValue - (optional) defined the min value authorized -> use to grayed out the button
 * @param {String} class - (optional)
 *
 */

import m from 'm';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.protectedValue = function protectedValue(value) {
    if (value === undefined)
      return args.value();

    value = parseInt(value);

    if (value < 1)
      value = 0;

    if (args.validator) {
      if (args.validator(value - args.value())) {
        if (args.modified)
          args.modified(true);

        return args.value(value);
      }
    } else {
      if (args.modified)
        args.modified(true);

      return args.value(value);
    }
  };

  self.add = function add() {
    self.protectedValue(self.protectedValue() + 1);
    if (args.modified)
      args.modified(true);
  };

  self.remove = function remove() {
    self.protectedValue(self.protectedValue() - 1);
    if (args.modified)
      args.modified(true);
  };
};

component.view = function view(c, args) {
  return m('.input-number', [
    m('.input-number-button.input-number__minus', {
      onclick: c.remove,
    }, [
      m.hide(!args.minValue || args.minValue !== args.value(), '.input-number-button__overlay .input-number-button__overlay__left'),
    ], '-'),
    m('.input-number__entry', {
      class: args.class,
    },
      m('input.input-number__value', {
        type   : 'number',
        value  : c.protectedValue(),
        oninput: m.withAttr('value', c.protectedValue),
      })
    ),
    m('.input-number-button.input-number__plus', {
      onclick: c.add,
    }, [
      m.hide(!args.maxValue || args.maxValue !== args.value(), '.input-number-button__overlay .input-number-button__overlay__right'),
    ], '+'),
  ]);
};
export default component;
