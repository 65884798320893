import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import type { IconName } from 'Components/foundation/icons';
import SBackgroundHeader from 'Components/structural/SBackgroundHeader/SBackgroundHeader';

import styles, { SUMMARY_THEMES } from './SSummary.style';

export type SummaryItem = {
  text: string;
  valid: boolean;
  icon?: IconName;
};

export type SSummaryProps = {
  title: string;
  items: SummaryItem[];
  disabled?: boolean;
  showHeaderIcon?: boolean;
  style?: CSSProperties;
};

const deriveStatus = (itemsAreValid: boolean, disabled?: boolean) => {
  if (disabled) {
    return 'locked';
  }

  return itemsAreValid ? 'validated' : 'warning';
};

export const SSummary = ({ title, items, disabled, showHeaderIcon = true, style = {} }: SSummaryProps) => {
  const itemsAreValid = useMemo(() => items.every((item) => item.valid), [items]);
  const status = deriveStatus(itemsAreValid, disabled);
  const theme = SUMMARY_THEMES[status];

  const containerStyle = {
    ...styles.container,
    borderColor: theme.borderColor,
    ...style,
  };

  const itemList = useMemo(
    () =>
      items.map((item) => {
        const textStyle = {
          ...styles.text,
          ...(item.valid ? theme.validTextStyle : theme.invalidTextStyle),
        };

        const iconColor = item.valid ? theme.iconColor : theme.invalidIconColor;
        const icon = item.valid ? 'check-save' : 'alert';

        return (
          <li key={item.text} style={styles.item}>
            <UIcon name={item.icon || icon} color={iconColor} size={15} />
            <p style={textStyle}>{item.text}</p>
          </li>
        );
      }),
    [items, theme],
  );

  return (
    <div style={containerStyle}>
      <SBackgroundHeader showIcon={showHeaderIcon} status={status} text={title} size="M" />
      <ul style={styles.list}>{itemList}</ul>
    </div>
  );
};

export default SSummary;
