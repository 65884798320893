import Model from 'models/Model';

var InterseasonCard = {
  endpoint  : 'api/InterseasonCards',
  attributes: {
    availableContentTitle: {
      type: 'string',
    },
    availableContentDescription: {
      type: 'string',
    },
    activityId: {
      type: 'number',
    },
  },
};

export default Model.register('InterseasonCard', InterseasonCard);
