import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { useTypedSelector } from 'Libs/redux/utils';
import { useAppDispatch } from 'Libs/redux/store';
import { selectAll } from 'Store/entities/activity/activity.selectors';
import { searchActivities } from 'Store/entities/activity/activity.thunks';
import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';

import { selectedFiltersToQuery, useActivityFilters } from './hooks/useActivityFilter';
import styles from './ActivityList.style';
import { List } from './components/List';
import { LoadMore } from './components/LoadMore';
import { CreateActivityButton } from './components/CreateActivityButton';
import { CreateActivityModal } from './components/CreateActivityModal';

export const ActivityList = () => {
  const dispatch = useAppDispatch();

  const [displayActivityCreationModal, setDisplayActivityCreationModal] = useState(false);

  const activities = useTypedSelector((state) => selectAll(state.entities.activity));
  const { loading: fetchingActivities, total } = useTypedSelector((state) => state.entities.activity.search);

  const { description, filters, onFilterChange, selectedFilters } = useActivityFilters({
    activityCount: activities.length,
  });

  useEffect(() => {
    const query = selectedFiltersToQuery(selectedFilters);

    dispatch(searchActivities({ offset: 0, limit: 25, query }));
  }, [selectedFilters]);

  const activityCreationModalToggle = () => {
    setDisplayActivityCreationModal((prev) => !prev);
  };

  return (
    <div style={styles.container}>
      <SFilterBox
        title={t('activities:list.filter.title')}
        placeholder={t('activities:list.filter.placeholder')}
        count={total}
        description={description}
        filters={filters}
        onChange={onFilterChange}
      />
      <div style={styles.list}>
        <CreateActivityButton onClick={activityCreationModalToggle} />
        <List activities={activities} loading={fetchingActivities} hasFilters={selectedFilters.hasFilters} />
        <LoadMore />
      </div>
      <CreateActivityModal visible={displayActivityCreationModal} onClose={activityCreationModalToggle} />
    </div>
  );
};

export default ActivityList;
