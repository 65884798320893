export type Color = {
  [key: string]: string;
};

const PRIMARY = {
  DEFAULT: '#348FFC',
  HOVER: '#59A4FF',
  DISABLED: '#E3E3E3',
  LIGHTER: '#ECF1FF',
  LIGHT: '#EFF6FF',
  MEDIUM: '#99C7FD',
};

const SECONDARY = {
  LIGHTER: '#FCEAF1',
  LIGHT: '#F9CBDB',
  HOVER: '#EF82A9',
  DEFAULT: '#E52E70',
  DARK: '#CE2964',
  DARKEST: '#A0204E',
};

const PRIMARY_LIGHT = {
  DEFAULT: '#EFF6FF',
  OUTLINE: '#D7DDEE',
};

const BLUE_GRAY_LIGHT = {
  DEFAULT: '#F6F8FB',
};

const BLUE_GRAY_MEDIUM = {
  DEFAULT: '#E1E6EF',
};

const BLUE_GRAY_DARK = {
  DEFAULT: '#C8D0DE',
};

const BLUE_GRAY_DARKER = {
  DEFAULT: '#A0ACC3',
};

const BLUE_GRAY_DARKEST = {
  DEFAULT: '#8694AD',
};

const GREY_LIGHT = {
  DEFAULT: '#FAFAFA',
  HOVER: '#F6F6F6',
  DISABLED: '#E3E3E3',
};

const GREY_MEDIUM = {
  DEFAULT: '#EDEDED',
  HOVER: '#F6F6F6',
  DISABLED: '#E3E3E3',
};

const GREY_DARK = {
  DEFAULT: '#CCCCCC',
  HOVER: '#AAAAAA',
  OUTLINE_FOCUSED: '#AAAAAA',
  OUTLINE_DISABLED: '#E3E3E3',
  DISABLED: '#E3E3E3',
};

const GREY_DARKER = {
  DEFAULT: '#525659',
  HOVER: '#73787C',
  DISABLED: '#E3E3E3',
};

const BLACK = {
  DEFAULT: '#222A34',
  DISABLED: '#E3E3E3',
};

const WHITE = {
  DEFAULT: '#FFFFFF',
  HOVER: '#F6F6F6',
  ACTIVE: '#EEEEEE',
};

const BLUE_GRAY = {
  DEFAULT: '#A0ACC3',
  LIGHTER: '#F6F8FB',
  LIGHT: '#E1E6EF',
  MEDIUM: '#E1E6EF',
  DARK: '#A0ACC3',
  DARKER: '#A0ACC3',
  DARKEST: '#8694AD',
};

const SUCCESS = {
  DEFAULT: '#2ACF60',
  HOVER: '#47E77B',
  DISABLED: '#E3E3E3',
  LIGHTER: '#E8FCEF',
  LIGHT: '#BDF0CE',
  DARK: '#23B151',
  DARKER: '#1F8B42',
};

const ERROR = {
  DEFAULT: '#FF676D',
  HOVER: '#FF8489',
  DISABLED: '#E3E3E3',
  LIGHTER: '#FFEDED',
  LIGHT: '#FFC1C4',
  DARK: '#EC484E',
  DARKER: '#C0141A',
};

const WARNING = {
  DEFAULT: '#E07514',
  HOVER: '#F59742',
  DISABLED: '#E3E3E3',
  LIGHTER: '#FEEFE3',
  LIGHT: '#FBD0AA',
  DARK: '#C66107',
};

const CHIPS = {
  DEFAULT: '#EDEDED',
};

const SEGMENT = {
  TEAL: '#8DCDB1',
  PURPLE: '#BC8DCD',
  BROWN: '#D5A89D',
  PINK: '#D59DC1',
  BLUE: '#8DC8CD',
  GREEN: '#A6D579',
  YELLOW: '#D7C058',
  LIGHT_PURPLE: '#B3ACD9',
  BLUE_GRAY: '#9DBAD5',
};

const GAMEPLAY = {
  ERROR_TEXT: '#13C391',
  ERROR_TEXT_HOVER: '#42CFA7',
  NO_INT_CONTENT: '#FC7F5C',
  NO_INT_CONTENT_HOVER: '#FD997D',
  OPEN_QUESTION: '#A376F7',
  OPEN_QUESTION_HOVER: '#B591F9',
  PICTURE_SPOT: '#F0D415',
  PICTURE_SPOT_HOVER: '#F3DD44',
  QCM: '#004EA2',
  SQCM: '#51A6FD',
  SQCM_HOVER: '#74B8FD',
  SWIPING_CARDS: '#75DD72',
  SWIPING_CARDS_HOVER: '#91E48E',
  ORDERING_CARDS: '#FC5C5C',
  ORDERING_CARDS_HOVER: '#FD7D7D',
};

const TEXT = {
  DEFAULT: '#222A34',
  DISABLED: '#E3E3E3',
  SECONDARY_DEFAULT: '#696969',
  SECONDARY_DISABLED: '#E3E3E3',
  SECONDARY_HOVER: '#AAAAAA',
  DISABLED_DEFAULT: '#AAAAAA',
  DISABLED_DISABLED: '#E3E3E3',
  PLACEHOLDER_DEFAULT: '#CCCCCC',
  PLACEHOLDER_DISABLED: '#E3E3E3',
  ACCENTUATED: '#348FFC',
};

const ALERT = {
  DEFAULT: BLACK.DEFAULT,
  ERROR: '#EC484E',
  SUCCESS: '#2ACF60',
};

const ACTIVITY = {
  CAMPAIGN_LIGHT: '#FDE8E7E5',
  CAMPAIGN_MEDIUM: '#DC5F5E',
  BREAKING_NEWS_LIGHT: '#FCF6E2',
  BREAKING_NEWS_MEDIUM: '#977F36',
  INTERSEASON_LIGHT: '#EFDCF4',
  INTERSEASON_MEDIUM: '#9B23BA',
  THATS_ALL_FOLKS_LIGHT: '#FEF5EC',
  THATS_ALL_FOLKS_MEDIUM: '#BE6A1C',
  WELCOME_LIGHT: '#E2E8F6',
  WELCOME_MEDIUM: '#3E68C1',
};

const DOCUMENT_TYPES = {
  PDF: '#FC5C5C',
};

// These colors were duplicated to deal with Illustration colors
const DISABLED = {
  DEFAULT: GREY_DARK.OUTLINE_FOCUSED,
  MEDIUM: GREY_DARK.DEFAULT,
  LIGHTER: GREY_MEDIUM.DEFAULT,
  LIGHT: GREY_DARK.DEFAULT,
  HOVER: GREY_DARK.DEFAULT,
};

const COLORS = {
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY,
  BLUE_GRAY,
  BLUE_GRAY_LIGHT,
  BLUE_GRAY_MEDIUM,
  BLUE_GRAY_DARK,
  BLUE_GRAY_DARKER,
  BLUE_GRAY_DARKEST,
  GREY_LIGHT,
  GREY_MEDIUM,
  GREY_DARK,
  GREY_DARKER,
  BLACK,
  WHITE,
  SUCCESS,
  ERROR,
  WARNING,
  CHIPS,
  SEGMENT,
  GAMEPLAY,
  TEXT,
  ALERT,
  ACTIVITY,
  DOCUMENT_TYPES,
  DISABLED,
} as const;

export default COLORS;

export type ColorName = keyof typeof COLORS;

export const COLOR_NAMES = Object.keys(COLORS) as ColorName[];
