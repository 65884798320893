// @flow

import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../../redux/models/Alert';
import { actions, useModuleSelector } from '../../redux';

import styles from './LeaderboardAlertView.style';

type Props = {|
  component: React$AbstractComponent<any, any>,
|};

const ALL_SCOPES: Array<AlertScope> = [
  'getLeaderboardReportError',
];

export const IDS: { [AlertScope]: number } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [(x: any)]: i }), {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  'getLeaderboardReportError': 'error',
};

const TITLES = {
  getLeaderboardReportError: '',
};

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const message = params.message || '';

  switch (scope) {
    case 'getLeaderboardReportError': return t('analytics:leaderboard.leaderboard_alert_view.data_could_not_be_dl', { message });

    default: return '';
  }
};

const makeAlertsFromStateAlerts = (alerts) => alerts
  .map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

export const LeaderboardAlertView = ({ component }: Props) => {
  const stateAlerts = useModuleSelector(state => state.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return (
    <AlertHOC alerts={alerts} onClose={onClose} />
  );
};
