import { createStyleSheet } from 'Components/foundation/stylesheets';

// zIndex = Zendesk zIndex + 1
const zIndex = 999999;

const styleSheet = createStyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuWrapper: {
    position: 'absolute',
    top: 0,
    zIndex,
  },
  dropDownBox: {
    position: 'absolute',
    minWidth: '132px',
    maxHeight: '350px',
    right: 0,
    top: 24,
    zIndex,
  },
  dropdownItem: {
    userSelect: 'none',
  },
  icon: {
    padding: 0,
  },
});

export default styleSheet;
