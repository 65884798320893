import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    // INFO allow scrolling this view independently of alerts
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  alignText: {
    justifyContent: 'flex-end',
  },
  container: {
    width: 960,
    margin: 'auto',
    paddingTop: 80,
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperChild: {
    minHeight: 400,
    width: 720,

    paddingTop: 31,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,

    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },

  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  bottomSpace: {
    height: 100,
  },
});
