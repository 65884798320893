import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  container: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderLeft: `8px solid ${COLORS.BLUE_GRAY_DARK.DEFAULT}`,
    borderRadius: '12px',
  },
  innerContainer: {
    padding: '15px 21px 19px 28px',
    gap: '24px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderTop: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
    borderRight: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
    borderBottom: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
    borderRadius: '0 12px 12px 0',
  },
});
