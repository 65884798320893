import m from 'm';

var component = {};

/*
  args = {
    background  : boolean
    picture     : Picture instance
    videoPicture: string url of image from video Youtube (use for old support)
    round       : boolean
  };
*/
component.controller = function controller(args) {
  var self = this;

  self.pictureId = m.prop(null);

  // eslint-disable-next-line complexity
  self.pictureConfig = function pictureConfig(args, elem, isInit, ctx) {
    var dimensions = {};

    if (!args.picture())
      return;

    if (args.picture().id() && (!ctx.loaded || self.pictureId() !== args.picture().id())) {
      self.pictureId(args.picture().id());

      dimensions = elem.getBoundingClientRect();
      elem.onload = fadeinPicture;

      elem.src = args.picture().cdn({
        width : Math.ceil(dimensions.width),
        height: Math.ceil(dimensions.height),
        crop  : 'crop',
      });

      if (elem.complete)
        elem.onload = showPicture;
    } else if (!ctx.loaded)
      fadeinPicture();

    function showPicture() {
      ctx.loaded = true;
      elem.className += ' responsivePicture--show';
    }

    function fadeinPicture() {
      ctx.loaded = true;
      elem.className += ' responsivePicture--fadeIn';
    }
  };
};

component.view = function view(c, args) {
  var classArgs = [
    args.class,
    args.background ? 'responsivePicture--show' : '',
    args.round ? 'responsivePicture--round' : '',
  ].join(' ');
  var elem = args.background ? '' : 'img';

  if (args.videoPicture) {
    const videoClass = ['responsivePicture', 'responsivePicture--show'].join(' ');

    return m('img', {
      src  : args.videoPicture,
      class: videoClass,
    });
  }

  return m(elem + '.responsivePicture', {
    config: c.pictureConfig.bind(null, args),
    onload: c.showPicture,
    class : classArgs,
  });
};

export default component;
