import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  signupWrapper: {
    minWidth: 250,
  },
  welcome: {
    ...TYPOGRAPHY.BODY3,
    opacity: 0.2,
    letterSpacing: 4,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  instruction: {
    ...TYPOGRAPHY.TITAN_L,
    color: COLORS.BLACK.DEFAULT,
    fontWeight: 400,
    marginTop: 2,
  },
  instanceName: {
    ...TYPOGRAPHY.TITAN_L,
    fontWeight: 'bold',
  },
  instructionMore: {
    width: 250,
    marginTop: 16,
  },
  inputWrapper: {
    width: 250,
    marginTop: 32,
  },
  lastNameInput: {
    marginTop: 16,
  },
  passwordInput: {
    marginTop: 24,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 56,
    alignItems: 'center',
  },
  button: {
    width: 151,
    marginRight: 24,
    height: 'fit-content',
  },
  errorMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
    maxWidth: 175,
  },
});

export default styleSheet;
