import { useCallback, useState } from 'react';

type BasicServiceResponse = { result: any; error: undefined } | { result: undefined; error: any };
type BasicService = (...args: any[]) => Promise<BasicServiceResponse>;

type ServiceArgs<Service> = Service extends (args: infer Arguments) => Promise<any> ? Arguments : any;
type ServiceResponse<Service> = Service extends (...args: any[]) => Promise<{ result: infer Response; error: undefined } | { result: undefined; error: any }> ? Response : any;
type ServiceErrors<Service> = Service extends (...args: any[]) => Promise<{ result: any; error: undefined } | { result: undefined; error: infer Errors }> ? Errors : any;

export type UseServiceArgs<Service extends BasicService> = {
  service: Service;
  onSuccess?: (args: ServiceResponse<Service>) => void | Promise<void>;
  onError?: (args: ServiceErrors<Service>) => void | Promise<void>;
};

const noop = () => {};

export function useService<Service extends BasicService>({
  service,
  onSuccess = noop,
  onError = noop,
}: UseServiceArgs<Service>) {
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState<ServiceResponse<Service> | undefined>(undefined);

  const [error, setError] = useState<ServiceErrors<Service> | undefined>(undefined);

  const clean = useCallback(() => {
    setResponse(undefined);
    setError(undefined);
  }, []);

  const call = useCallback(async (args: ServiceArgs<Service>) => {
    clean();

    setLoading(true);

    const { error: serviceErrors, result } = await service(args);

    if (serviceErrors !== undefined) {
      setLoading(false);
      setError(serviceErrors);
      await onError(serviceErrors);
      return;
    }

    setLoading(false);
    setResponse(result);
    await onSuccess(result);
  }, [clean, onSuccess, onError, service]);

  return {
    loading,
    response,
    error,
    clean,
    call,
  };
}
