// @flow

import UnsplashInstance, { toJson } from 'unsplash-js';
import { ImageHandler } from '../../flowTypes';
import type { ImageBody, ImageHandleArgs } from '../../flowTypes';

const ACCESS_KEY = '69287559efddc4b2c491c3bd26217589cbd31a94e461ae3ed2c201a6340362f9';
const DEFAULT_PER_PAGE = 20;

export class Unsplash implements ImageHandler {
  _instance: typeof UnsplashInstance;

  constructor() {
    this._instance = new UnsplashInstance({ accessKey: ACCESS_KEY });
  }

  fetchImages = ({ keyword, page, perPage = DEFAULT_PER_PAGE }: ImageHandleArgs): Promise<Array<ImageBody>> => {
    if (!keyword)
      return this.getRandomImages(perPage);

    return this._instance.search.photos(keyword, page, perPage)
      .then(toJson)
      .then(this._getResults)
      .then(this._getExpectedObjects)
      .catch(this._handleError);
  }

  getRandomImages = (perPage: number): Promise<Array<ImageBody>> => {
    const options = {
      count: perPage,
    };

    return this._instance.photos.getRandomPhoto(options)
      .then(toJson)
      .then(this._getExpectedObjects)
      .catch(this._handleError);
  }

  sendDownloadData = (url: string): Promise<any> => {
    const query = `${url}?client_id=${ACCESS_KEY}`;

    return fetch(query);
  }

  getSourceName = () => {
    return 'Unsplash';
  }

  _getResults = (response: Object): Array<Object> => {
    return response.results;
  };

  _getExpectedObjects = (results: Array<Object>): Array<ImageBody> => {
    return results
      .map(this._getRelevantKeys);
  }

  _getRelevantKeys = (result: Object): ImageBody => {
    let profileLink = '';

    if (result.user.links && result.user.links.html)
      profileLink = result.user.links.html;

    return {
      title: result.description || '',
      user : {
        name: result.user ? result.user.name : '',
        profileLink,
      },
      urls: {
        small   : result.urls.small,
        big     : result.urls.full,
        download: result.links.download_location,
      },
      size: {
        width : result.width,
        height: result.height,
      },
    };
  }

  _handleError = () => {
    return [];
  }
}

export default Unsplash;
