import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuOptions } from 'Components/structural/SOptionsMenu/SOptionsMenu';
import SImageMicroKnowledgeItem from 'Components/structural/SMicroKnowledgeListItem/SImageMicroKnowledgeItem/SImageMicroKnowledgeItem';
import STextMicroKnowledgeItem from 'Components/structural/SMicroKnowledgeListItem/STextMicroKnowledgeItem/STextMicroKnowledgeItem';
import SChapterMicroKnowledgeItem from 'Components/structural/SMicroKnowledgeListItem/SChapterMicroKnowledgeItem/SChapterMicroKnowledgeItem';
import { PickerSelectedImage, PickerSelectedVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import {
  MicroKnowledgeChapter,
  MicroKnowledgeItem,
  MicroKnowledgeText,
  MicroKnowledgeImage,
  MicroKnowledgeVideo,
  isMkVideo,
  isMkImage,
  isMkText,
  isMkChapter,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import SVideoMicroKnowledgeItem from 'Components/structural/SMicroKnowledgeListItem/SVideoMicroKnowledgeItem/SVideoMicroKnowledgeItem';
import type { MicroKnowledgesListProps } from './MicroKnowledgeList';

type SharedMkWrapperProps = {
  onDelete: ({ id }: { id: string }) => void;
  onUpdateType: (item: MicroKnowledgeItem) => void;
  onTitleChange: MicroKnowledgesListProps['onTitleChange'];
  onInsert: MicroKnowledgesListProps['onInsert'];
};

const noop = () => {};

type OptionsCallbacks = {
  onUpdateType: SharedMkWrapperProps['onUpdateType'];
  onDelete: SharedMkWrapperProps['onDelete'];
};

const useGetChapterOptions = (
  { id, ...restItem }: MicroKnowledgeChapter,
  { onUpdateType, onDelete }: OptionsCallbacks,
): MenuOptions[] => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      mode: 'standard',
      text: t('courses:mk_menu_options.type'),
      onClick: noop,
      children: [
        {
          id: 2,
          text: t('courses:mk_menu_options.text'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'text', content: '' }),
          isSelected: false,
        },
        {
          id: 3,
          text: t('courses:mk_menu_options.chapter'),
          onClick: noop,
          isSelected: true,
        },
        {
          id: 4,
          text: t('courses:mk_menu_options.image'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'image', image: undefined, content: '' }),
          isSelected: false,
        },
        {
          id: 6,
          text: t('courses:mk_menu_options.video'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'video', video: undefined }),
          isSelected: false,
        },
      ],
    },
    {
      id: 5,
      text: t('courses:mk_menu_options.delete'),
      onClick: () => onDelete({ id }),
    },
  ];
};

const useGetTextOptions = (
  { id, ...restItem }: MicroKnowledgeText,
  { onUpdateType, onDelete }: OptionsCallbacks,
): MenuOptions[] => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      mode: 'standard',
      text: t('courses:mk_menu_options.type'),
      onClick: noop,
      children: [
        {
          id: 2,
          text: t('courses:mk_menu_options.text'),
          onClick: noop,
          isSelected: true,
        },
        ...(!restItem.chapterId
          ? [
              {
                id: 3,
                text: t('courses:mk_menu_options.chapter'),
                onClick: () => onUpdateType({ ...restItem, id, type: 'chapter', isCollapsed: false }),
                isSelected: false,
              },
            ]
          : []),
        {
          id: 4,
          text: t('courses:mk_menu_options.image'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'image', image: undefined, content: '' }),
          isSelected: false,
        },
        {
          id: 6,
          text: t('courses:mk_menu_options.video'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'video' }),
          isSelected: false,
        },
      ],
    },
    {
      id: 5,
      text: t('courses:mk_menu_options.delete'),
      onClick: () => onDelete({ id }),
    },
  ];
};

const useGetImageOptions = (
  { id, ...restItem }: MicroKnowledgeImage,
  { onUpdateType, onDelete }: OptionsCallbacks,
): MenuOptions[] => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      mode: 'standard',
      text: t('courses:mk_menu_options.type'),
      onClick: noop,
      children: [
        {
          id: 2,
          text: t('courses:mk_menu_options.text'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'text', content: '' }),
          isSelected: false,
        },
        ...(!restItem.chapterId
          ? [
              {
                id: 3,
                text: t('courses:mk_menu_options.chapter'),
                onClick: () => onUpdateType({ ...restItem, id, type: 'chapter', isCollapsed: false }),
                isSelected: false,
              },
            ]
          : []),
        {
          id: 4,
          text: t('courses:mk_menu_options.image'),
          onClick: noop,
          isSelected: true,
        },
        {
          id: 6,
          text: t('courses:mk_menu_options.video'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'video' }),
          isSelected: false,
        },
      ],
    },
    {
      id: 5,
      text: t('courses:mk_menu_options.delete'),
      onClick: () => onDelete({ id }),
    },
  ];
};

const useGetVideoOptions = (
  { id, ...restItem }: MicroKnowledgeVideo,
  { onUpdateType, onDelete }: OptionsCallbacks,
): MenuOptions[] => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      mode: 'standard',
      text: t('courses:mk_menu_options.type'),
      onClick: noop,
      children: [
        {
          id: 2,
          text: t('courses:mk_menu_options.text'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'text', content: '' }),
          isSelected: false,
        },
        ...(!restItem.chapterId
          ? [
              {
                id: 3,
                text: t('courses:mk_menu_options.chapter'),
                onClick: () => onUpdateType({ ...restItem, id, type: 'chapter', isCollapsed: false }),
                isSelected: false,
              },
            ]
          : []),
        {
          id: 4,
          text: t('courses:mk_menu_options.image'),
          onClick: () => onUpdateType({ ...restItem, id, type: 'image', content: '' }),
          isSelected: false,
        },
        {
          id: 6,
          text: t('courses:mk_menu_options.video'),
          onClick: noop,
          isSelected: true,
        },
      ],
    },
    {
      id: 5,
      text: t('courses:mk_menu_options.delete'),
      onClick: () => onDelete({ id }),
    },
  ];
};

type ChapterMkWrapperProps = SharedMkWrapperProps & {
  chapterItem: MicroKnowledgeChapter;
  onToggleCollapse: MicroKnowledgesListProps['onToggleCollapse'];
};

export const ChapterMkWrapper = memo(function ChapterMkWrapper({
  chapterItem,
  onTitleChange,
  onToggleCollapse,
  onInsert,
  onDelete,
  onUpdateType,
}: ChapterMkWrapperProps) {
  const { id: chapterId, title, isCollapsed } = chapterItem;

  const options = useGetChapterOptions(chapterItem, { onDelete, onUpdateType });

  const handleCollapse = useCallback((id: string) => onToggleCollapse({ id }), [onToggleCollapse]);

  const handleInsert = useCallback(
    (targetId: string) => {
      onInsert({ targetId });
    },
    [onInsert],
  );

  const handleTitleChange = useCallback(
    (id: string, newTitle: string) => {
      onTitleChange({ ...chapterItem, title: newTitle });
    },
    [chapterItem, onTitleChange],
  );

  return (
    <SChapterMicroKnowledgeItem
      id={chapterId}
      key={chapterId}
      collapsable
      title={title || ''}
      onAdd={handleInsert}
      options={options}
      isCollapsed={isCollapsed}
      onToggleCollapse={handleCollapse}
      onTitleChange={handleTitleChange}
    />
  );
});

type TextMkWrapperProps = SharedMkWrapperProps & {
  textItem: MicroKnowledgeText;
  hide: boolean;
  onContentChange: MicroKnowledgesListProps['onContentChange'];
};
export const TextMkWrapper = memo(function TextMkWrapper({
  textItem,
  hide,
  onInsert,
  onTitleChange,
  onContentChange,
  onDelete,
  onUpdateType,
}: TextMkWrapperProps) {
  const { id: itemId, content, title, chapterId } = textItem;

  const options = useGetTextOptions(textItem, { onDelete, onUpdateType });

  const handleInsert = useCallback(
    (targetId: string) => {
      onInsert({ targetId });
    },
    [onInsert],
  );

  const handleTitleChange = useCallback(
    (id: string, newTitle: string) => {
      onTitleChange({ ...textItem, title: newTitle });
    },
    [textItem, onTitleChange],
  );

  const handleContentChange = useCallback(
    (id: string, newContent: string) => {
      onContentChange({ ...textItem, content: newContent });
    },
    [textItem, onContentChange],
  );

  if (hide) {
    return null;
  }

  return (
    <STextMicroKnowledgeItem
      id={itemId}
      key={itemId}
      content={content}
      title={title || ''}
      onAdd={handleInsert}
      options={options}
      isChild={chapterId !== null}
      onTitleChange={handleTitleChange}
      onContentChange={handleContentChange}
    />
  );
});

type ImageMkWrapperProps = SharedMkWrapperProps & {
  imageItem: MicroKnowledgeImage;
  hide: boolean;
  onContentChange: MicroKnowledgesListProps['onContentChange'];
  onImageChange: MicroKnowledgesListProps['onContentChange'];
};

export const ImageMkWrapper = memo(function ImageMkWrapper({
  imageItem,
  hide,
  onInsert,
  onTitleChange,
  onContentChange,
  onDelete,
  onUpdateType,
  onImageChange,
}: ImageMkWrapperProps) {
  const { t } = useTranslation();
  const { id: itemId, content, title, chapterId, image } = imageItem;
  const imageLabel = useMemo(
    () => (!image?.url ? t('courses:course_edition.upload_image') : t('courses:course_edition.update_image')),
    [image],
  );
  const options = useGetImageOptions(imageItem, { onDelete, onUpdateType });

  const handleInsert = useCallback(
    (targetId: string) => {
      onInsert({ targetId });
    },
    [onInsert],
  );

  const handleTitleChange = useCallback(
    (id: string, newTitle: string) => {
      onTitleChange({ ...imageItem, title: newTitle });
    },
    [imageItem, onTitleChange],
  );

  const handleContentChange = useCallback(
    (id: string, newContent: string) => {
      onContentChange({ ...imageItem, content: newContent });
    },
    [imageItem, onContentChange],
  );

  const handleImageChange = useCallback(
    (selectedImage: PickerSelectedImage) => {
      onImageChange?.({
        ...imageItem,
        image: {
          id: selectedImage.id,
          url: selectedImage.url,
        },
      });
    },
    [imageItem, onImageChange],
  );

  if (hide) {
    return null;
  }

  return (
    <SImageMicroKnowledgeItem
      imageLabel={imageLabel}
      id={itemId}
      imageUrl={image?.url}
      key={itemId}
      content={content}
      title={title || ''}
      onAdd={handleInsert}
      options={options}
      isChild={chapterId !== null}
      onTitleChange={handleTitleChange}
      onContentChange={handleContentChange}
      onImageChange={handleImageChange}
    />
  );
});

type VideoMkWrapperProps = SharedMkWrapperProps & {
  videoItem: MicroKnowledgeVideo;
  hide: boolean;
  onVideoChange: MicroKnowledgesListProps['onVideoChange'];
};

export const VideoMkWrapper = memo(function SharedMkWrapperProps({
  videoItem,
  hide,
  onInsert,
  onTitleChange,
  onDelete,
  onUpdateType,
  onVideoChange,
}: VideoMkWrapperProps) {
  const { t } = useTranslation();
  const { id: itemId, title, chapterId, video } = videoItem;
  const videoLabel = useMemo(
    () => (!video?.url ? t('courses:course_edition.upload_video') : t('courses:course_edition.update_video')),
    [video],
  );
  const options = useGetVideoOptions(videoItem, { onDelete, onUpdateType });

  const handleInsert = useCallback(
    (targetId: string) => {
      onInsert({ targetId });
    },
    [onInsert],
  );

  const handleTitleChange = useCallback(
    (id: string, newTitle: string) => {
      onTitleChange({ ...videoItem, title: newTitle });
    },
    [videoItem, onTitleChange],
  );

  const handleVideoChange = useCallback(
    (selectedVideo: PickerSelectedVideo) => {
      const { type, ...commonVideoProperties } = selectedVideo;
      const video: MicroKnowledgeVideo['video'] = commonVideoProperties;

      onVideoChange?.({
        ...videoItem,
        video,
      });
    },
    [videoItem, onVideoChange],
  );

  if (hide) {
    return null;
  }

  return (
    <SVideoMicroKnowledgeItem
      videoLabel={videoLabel}
      id={itemId}
      video={video}
      key={itemId}
      title={title || ''}
      onAdd={handleInsert}
      options={options}
      isChild={chapterId !== null}
      onTitleChange={handleTitleChange}
      onVideoChange={handleVideoChange}
    />
  );
});

export const MkItemWrapper = ({
  item,
  shouldHideChild,
  onDelete,
  onInsert,
  onTitleChange,
  onUpdateType,
  onToggleCollapse,
  onContentChange,
  onImageChange,
  onVideoChange,
}: {
  item: MicroKnowledgeItem;
  shouldHideChild: boolean;
  onDelete: ({ id }: { id: string }) => void;
  onUpdateType: (item: MicroKnowledgeItem) => void;
  onTitleChange: MicroKnowledgesListProps['onTitleChange'];
  onInsert: MicroKnowledgesListProps['onInsert'];
  onToggleCollapse: MicroKnowledgesListProps['onToggleCollapse'];
  onContentChange: MicroKnowledgesListProps['onContentChange'];
  onImageChange: MicroKnowledgesListProps['onContentChange'];
  onVideoChange: MicroKnowledgesListProps['onVideoChange'];
}) => {
  if (isMkChapter(item)) {
    return (
      <ChapterMkWrapper
        key={item.id}
        chapterItem={item}
        onDelete={onDelete}
        onInsert={onInsert}
        onUpdateType={onUpdateType}
        onTitleChange={onTitleChange}
        onToggleCollapse={onToggleCollapse}
      />
    );
  }

  if (isMkText(item)) {
    return (
      <TextMkWrapper
        key={item.id}
        textItem={item}
        hide={shouldHideChild}
        onInsert={onInsert}
        onDelete={onDelete}
        onUpdateType={onUpdateType}
        onTitleChange={onTitleChange}
        onContentChange={onContentChange}
      />
    );
  }

  if (isMkImage(item)) {
    return (
      <ImageMkWrapper
        key={item.id}
        imageItem={item}
        hide={shouldHideChild}
        onInsert={onInsert}
        onDelete={onDelete}
        onUpdateType={onUpdateType}
        onTitleChange={onTitleChange}
        onContentChange={onContentChange}
        onImageChange={onImageChange}
      />
    );
  }

  if (isMkVideo(item)) {
    return (
      <VideoMkWrapper
        key={item.id}
        videoItem={item}
        hide={shouldHideChild}
        onInsert={onInsert}
        onDelete={onDelete}
        onUpdateType={onUpdateType}
        onTitleChange={onTitleChange}
        onVideoChange={onVideoChange}
      />
    );
  }

  return null;
};
