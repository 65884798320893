/**
 * Display an image in a container
 *
 * Props:
 *  - style: override component's style
 *  - width: the width of the component
 *  - height: the height of the component
 *  - image: An object with properties of the image
 *  - onMount: Call when the component is mount
 *  - onClick: Call when select the image
 *  - source: Source of the image
 *  - isSelected: boolean if the image is selected or not
 */

import React, { useEffect, useState } from 'react';

import UImage from 'Components/unit/UImage/UImage';

import { ImageGalleryType } from 'Libs/ts/types';
import styles from './UGalleryItem.style';
import Overlay from './Overlay';
import PlaceHolder from './Placeholder';

type Props = {
  onMount?: () => void;
  style?: object;
  width: number;
  height: number;
  image: ImageGalleryType;
  onClick?: (image: ImageGalleryType, isSelected: boolean) => void;
  isSelected: boolean;
  source?: string;
};

export const UGalleryItem = ({ width, height, image, onMount, isSelected, style, source = '', onClick }: Props) => {
  const [calledOnMount, setCalledOnMount] = useState(false);

  useEffect(() => {
    if (!calledOnMount && onMount) {
      onMount();
      setCalledOnMount(true);
    }
  }, [onMount, calledOnMount]);

  const handleClick = () => {
    onClick?.(image, !isSelected);
  };

  if (!image.displayUrl) return <PlaceHolder width={width} height={height} />;

  return (
    <div style={{ ...styles.containGlobal, width, ...style }} onClick={handleClick}>
      <UImage src={image.displayUrl} style={{ ...styles.containPicture, width, height }} />
      <Overlay source={source} width={width} image={image} isSelected={isSelected} />
    </div>
  );
};

export default UGalleryItem;
