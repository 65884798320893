/* eslint-disable no-param-reassign */

import { useCallback, useEffect, useState } from 'react';
import Sortable, { MoveEvent, SortableEvent } from 'sortablejs';

import { useTypedSelector } from 'Libs/redux/utils';

import { getSelectedActivity } from '../../redux';

type UseSortableContentsParams = {
  moveContentInDailySerieDays: (
    oldDraggableIndex: number,
    oldDayIndex: number,
    newDraggableIndex: number,
    newDayIndex: number,
  ) => void;
};
export const useSortableContents = ({ moveContentInDailySerieDays }: UseSortableContentsParams) => {
  const selectedActivity = useTypedSelector(getSelectedActivity);
  const isLocked = useTypedSelector((state) => state.pages.activity.isLocked);

  const [draggedContentToDayIndex, setDraggedContentToDayIndex] = useState<number>(-1);

  const handleStartDragging = (evt: SortableEvent) => {
    const { from } = evt;

    setDraggedContentToDayIndex(parseInt(from.id, 10) - 1);

    if (Sortable.ghost) {
      Sortable.ghost.style.opacity = '1';
    }

    // HACK to have the cursor grabbing while dragging the element
    const page = document.getElementsByTagName('html')[0];
    const contents = document.querySelectorAll<HTMLElement>('.ReactDraggable');

    page.style.cursor = 'grabbing';
    contents.forEach((content) => {
      content.style.cursor = 'grabbing';
    });
  };

  const handleDragging = (evt: MoveEvent) => {
    const { to } = evt;
    setDraggedContentToDayIndex(parseInt(to.id, 10) - 1);

    return true;
  };

  const handleEndDragging = useCallback(
    (evt: SortableEvent) => {
      const { oldDraggableIndex = 0, newDraggableIndex = 0, from, to } = evt;
      const oldDayIndex = parseInt(from.id, 10) - 1;
      const newDayIndex = parseInt(to.id, 10) - 1;

      const page = document.getElementsByTagName('html')[0];
      const contents = document.querySelectorAll<HTMLElement>('.ReactDraggable');

      page.style.cursor = 'auto';
      contents.forEach((content) => {
        content.style.cursor = 'grab';
      });

      setDraggedContentToDayIndex(-1);

      moveContentInDailySerieDays(oldDraggableIndex, oldDayIndex, newDraggableIndex, newDayIndex);
    },
    [moveContentInDailySerieDays],
  );

  const handleAdd = (evt: SortableEvent) => {
    const { item, from: origParent, to: destParent } = evt;

    // Prevent crash when moving an element
    // See: https://github.com/SortableJS/Sortable/issues/986
    if (origParent.id !== 'drawer-container') {
      origParent.appendChild(item);
    } else {
      // Remove the clone to have the true content card when it is dragged from Drawer
      destParent.removeChild(item);
    }
  };

  const createSortable = () => {
    const containersContent = document.querySelectorAll<HTMLElement>('.container-content');

    const options: Sortable.Options = {
      group: {
        name: 'sortable-list',
        pull: true,
        put: true,
      },
      disabled: isLocked || !selectedActivity.isDefault,
      animation: 250,
      forceFallback: true,
      filter: '.nonDraggable',
      onStart: handleStartDragging,
      onEnd: handleEndDragging,
      onMove: handleDragging,
      onAdd: handleAdd,
      draggable: isLocked || !selectedActivity.isDefault ? '.none' : '.ReactDraggable',
    };

    containersContent.forEach((container) => {
      const sortable = Sortable.get(container);
      sortable?.destroy();
      Sortable.create(container, options);
    });
  };

  useEffect(() => {
    createSortable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivity, isLocked]);

  return {
    draggedContentToDayIndex,
    setDraggedContentToDayIndex,
  };
};
