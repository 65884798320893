import React, { useMemo } from 'react';
import type { FunctionComponent, CSSProperties, FocusEventHandler } from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import type { InputStateStatus } from 'Hooks/useInputStatus/useInputStatus';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import ULabel from 'Components/unit/ULabel/ULabel';

import styles from './SInputContainer.style';
import { getInputStateStyle, getTextStyle, ICON_BY_STATUS } from './SInputContainer.utils';

export type ValueChangeFeedbackStatus = 'loading' | 'warning' | 'error' | 'success' | 'none';

export type MessageStatus = 'warning' | 'error' | 'success';

type FocusHandler = FocusEventHandler<HTMLInputElement> & FocusEventHandler<HTMLTextAreaElement>;

export type SInputContainerProps = {
  id?: string;
  label?: string;
  currentCount?: number;
  description?: string;
  ghost?: boolean;
  valueChangeFeedbackMessage?: string;
  valueChangeFeedbackStatus?: ValueChangeFeedbackStatus;
  maxLength?: number;
  hideCounter?: boolean;
  inputStateStatus?: InputStateStatus;
  childrenStyle?: CSSProperties;
  style?: CSSProperties;
  required?: boolean;

  onFocus?: FocusHandler;
  onBlur?: FocusHandler;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

const InputStatusExplanation = ({
  isDescriptionActive,
  valueChangeFeedbackStatus,
  valueChangeFeedbackMessage,
  isIconDisplayed = true,
}: {
  isDescriptionActive: boolean;
  valueChangeFeedbackStatus: ValueChangeFeedbackStatus;
  valueChangeFeedbackMessage: string;
  isIconDisplayed: boolean;
}) => {
  const wrapperStyle = useMemo(
    () => ({
      ...(isDescriptionActive ? styles.errorMessageWithDescription : styles.errorMessage),
      ...(!isIconDisplayed ? styles.withoutIcon : {}),
    }),
    [isDescriptionActive, isIconDisplayed],
  );
  const textStyle = useMemo(() => getTextStyle(valueChangeFeedbackStatus), [valueChangeFeedbackStatus]);

  if (valueChangeFeedbackStatus === 'none') {
    return null;
  }

  if (valueChangeFeedbackStatus === 'loading') {
    return <CircleSpinner size={14} color={COLORS.TEXT.SECONDARY_DEFAULT} loading />;
  }

  const { name, color } = ICON_BY_STATUS[valueChangeFeedbackStatus];

  return (
    <div style={wrapperStyle}>
      {isIconDisplayed && (
        <div style={styles.icon}>
          <UIcon name={name} size={14} color={color} />
        </div>
      )}
      <p style={textStyle}>{valueChangeFeedbackMessage}</p>
    </div>
  );
};

type InputCounterProps = Pick<Required<SInputContainerProps>, 'maxLength' | 'currentCount'> & {
  hidden?: boolean;
  inline?: boolean;
};
const InputCounter = ({ maxLength, currentCount = 0, hidden = false, inline = false }: InputCounterProps) => {
  const countStyle = useMemo(
    (): CSSProperties => ({
      ...(inline ? styles.inlineCounter : styles.basicCounter),
      ...styles.count,
      ...(currentCount >= maxLength ? styles.countReached : {}),
      ...(hidden ? { visibility: 'hidden' } : {}),
    }),
    [maxLength, inline, currentCount, hidden],
  );

  return (
    <div style={countStyle} data-test-id="input-container-counter">
      {`${currentCount}/${maxLength}`}
    </div>
  );
};

type LabelAndCounterLineProps = {
  label?: string;
  displayCounter: boolean;
  required: boolean;
  id?: string;
  ghost: boolean;
  maxLength?: number;
  currentCount: number;
};

export const LabelAndCounterLine = ({
  label,
  displayCounter,
  required,
  id,
  ghost,
  maxLength,
  currentCount,
}: LabelAndCounterLineProps) => {
  if (!label && (!displayCounter || ghost)) return null;

  return (
    <div style={styles.labelAndCounterLine}>
      {label ? (
        <ULabel required={required} htmlFor={id} style={styles.labelPlacement}>
          {label}
        </ULabel>
      ) : (
        <div />
      )}
      {displayCounter && !ghost && <InputCounter maxLength={maxLength!} currentCount={currentCount} />}
    </div>
  );
};

/**
 * Display an SInputContainer
 *
 * Props:
 * - label: Set a input label
 * - children: The children to render (which are encapsulated by the parent SInputContainer)
 * - currentCount: Props that count the current characters for the input value
 * - description: String to set the input description
 * - valueChangeFeedbackMessage: String to set under the input
 * - valueChangeFeedbackStatus: String of the status to set as an error, loading, warning, success or none
 * - maxLength: Specify a max number of characs
 * - hideCounter: Hide the counter from the input
 * - inputStateStatus: The status of the input state (none, disabled or focused)
 */
export const SInputContainer: FunctionComponent<SInputContainerProps> = ({
  children,
  id,
  label = '',
  currentCount = 0,
  description = '',
  valueChangeFeedbackMessage = '',
  valueChangeFeedbackStatus = 'none',
  maxLength = undefined,
  hideCounter = false,
  ghost = false,
  inputStateStatus = 'none',
  childrenStyle = undefined,
  required = false,
  style = undefined,

  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
}) => {
  const childrenWrapperStyle = useMemo(
    () => ({
      ...styles.childrenWrapper,
      ...childrenStyle,
      ...getInputStateStyle(inputStateStatus, ghost),
    }),
    [inputStateStatus, childrenStyle, ghost],
  );

  const displayCounter = useMemo(() => !!maxLength && maxLength > 0 && !hideCounter, [maxLength, hideCounter]);

  const hasInlineCounter = useMemo(() => displayCounter && ghost, [displayCounter, ghost]);
  const displayInlineCounter = useMemo(
    () => hasInlineCounter && ['hovered', 'focused'].includes(inputStateStatus),
    [hasInlineCounter, inputStateStatus],
  );

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <LabelAndCounterLine
        label={label}
        displayCounter={displayCounter}
        required={required}
        id={id}
        ghost={ghost}
        maxLength={maxLength}
        currentCount={currentCount}
      />
      <div
        style={childrenWrapperStyle}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
        {hasInlineCounter && (
          <InputCounter maxLength={maxLength!} currentCount={currentCount} hidden={!displayInlineCounter} inline />
        )}
      </div>
      <div>
        {description && <div style={styles.description}>{description}</div>}
        {valueChangeFeedbackStatus && (
          <InputStatusExplanation
            isDescriptionActive={!!description}
            valueChangeFeedbackMessage={valueChangeFeedbackMessage}
            valueChangeFeedbackStatus={valueChangeFeedbackStatus}
            isIconDisplayed={!ghost}
          />
        )}
      </div>
    </div>
  );
};

export default SInputContainer;
