import React, { useCallback, useEffect, useState } from 'react';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { CSSProperties } from 'react';
import { v4 as uuid } from 'uuid';
import { t } from 'i18next';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import SGameplayListItem from 'Components/structural/SGameplayListItem/SGameplayListItem';
import UTextLink from 'Components/unit/UTextLink//UTextLink';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';
import { IconName } from 'Components/foundation/icons';

import styles from './SGameplayListItemsSection.style';
import { swipingCardsIconMap } from '../SSwipingCard/SSwipingCard.utils';

const UTextLinkWithTooltip = withTooltip(UTextLink);

export type GameplayListSectionType = 'standard' | 'accentuated';

export type GameplayListSectionVariant = 'radio' | 'check' | 'none';

export type SGameplayListItemProps = {
  id?: string | number;
  icon?: IconName;
  value: string;
  disabled?: boolean;
  selected?: boolean;
};

export type SGameplayListItemsSectionProps = {
  items: SGameplayListItemProps[];
  style?: CSSProperties;
  minLength?: number;
  maxLength?: number;
  type: GameplayListSectionType;
  itemPlaceholder?: string;
  variant?: GameplayListSectionVariant;
  textLinkTooltipText: string;
  textLinkText: string;
  onItemsChange: (items: SGameplayListItemProps[]) => void;
  withArrowsIcons?: boolean;
  dragAndDropDisabled?: boolean;
  maxInputLength?: number;
};

const Wrapper = ({
  children,
  dragAndDropDisabled,
  onDragEnd,
  sensors,
  items,
}: {
  children: React.ReactNode;
  dragAndDropDisabled: boolean;
  onDragEnd: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  items: { id: string | number }[];
}) =>
  dragAndDropDisabled ? (
    <>{children}</>
  ) : (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );

export const SGameplayListItemsSection = ({
  items: originalItems = [],
  itemPlaceholder,
  style = {},
  textLinkTooltipText,
  textLinkText,
  variant,
  type,
  minLength = 1,
  maxLength,
  onItemsChange,
  withArrowsIcons,
  dragAndDropDisabled = false,
  maxInputLength,
}: SGameplayListItemsSectionProps) => {
  const [items, setItems] = useState<Array<SGameplayListItemProps & { id: string | number }>>(
    (originalItems.length > 0 ? originalItems : Array(minLength).fill({ value: '' })).map((item, index) => ({
      ...item,
      id: item.id || index + 1,
    })),
  );

  useEffect(() => {
    onItemsChange(items);
  }, [items]);

  const onItemTextChange = (value: string, index: number) => {
    const prevItems = items.slice(0, index);
    const nextItems = items.slice(index + 1);

    setItems([...prevItems, { ...items[index], value }, ...nextItems]);
  };

  const onAddElement = useCallback(() => {
    if (maxLength && items.length >= maxLength) {
      return;
    }

    const uniqueId = uuid();

    setItems([...items, { id: uniqueId, value: '', selected: false, disabled: false }]);
  }, [items, maxLength, onItemsChange]);

  const onDeleteElement = (id: string | number) => {
    /* Cannot delete if just one element on the list */
    if (items.length <= minLength) {
      return;
    }

    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const onSelectItem = (index: number) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return variant === 'radio' ? { ...item, selected: true } : { ...item, selected: !item.selected };
      }

      return variant === 'radio' ? { ...item, selected: false } : item;
    });

    setItems(newItems);
  };

  const getOptionsMenuOptions = useCallback(
    (id: string | number) => {
      return [
        {
          id: 1,
          text:
            originalItems.length <= minLength
              ? t('structural_component:s_gameplay_list_items_section.cannot_delete', { count: minLength })
              : t('structural_component:s_gameplay_list_items_section.delete'),
          onClick: () => onDeleteElement(id),
        },
      ];
    },
    [onDeleteElement],
  );

  const onDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id === over.id) {
        return;
      }

      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const newArray = arrayMove([...items], oldIndex, newIndex);

      setItems(newArray);
    },
    [items, onItemsChange],
  );

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 0.01,
    },
  });

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <Wrapper items={items} sensors={sensors} onDragEnd={onDragEnd} dragAndDropDisabled={dragAndDropDisabled}>
        {items.map((item, index) => (
          <SGameplayListItem
            icon={withArrowsIcons ? swipingCardsIconMap.get(items.length)?.[index] : undefined}
            key={item.id}
            id={item.id}
            variant={variant}
            value={item.value}
            type={type}
            onTextChange={(value) => {
              onItemTextChange(value, index);
            }}
            itemPlaceholder={itemPlaceholder}
            style={styles.gameplayItem}
            selected={item.selected || false}
            onSelect={() => {
              onSelectItem(index);
            }}
            isSelectedDisabled={item.disabled || false}
            contextMenuOptions={getOptionsMenuOptions(item.id)}
            maxInputLength={maxInputLength}
          />
        ))}
      </Wrapper>
      <div style={styles.uTextSection}>
        <UTextLinkWithTooltip
          tooltipPosition="top"
          leftIcon="plus"
          tooltipContent={textLinkTooltipText}
          tooltipEnabled={items.length >= (maxLength || 4)}
          tooltipMaxWidth={240}
          disabled={items.length >= (maxLength || 4)}
          typography="BODY2_SEMIBOLD"
          text={textLinkText}
          onClick={onAddElement}
        />
      </div>
    </div>
  );
};

export default SGameplayListItemsSection;
