import React, { useMemo } from 'react';

import { Filter } from 'Libs/filter/types';
import { getCategoryLabel, getCategoryValue } from 'Libs/filter/utils';
import UButton from 'Components/unit/UButton/UButton';
import UFilterSelect from 'Components/unit/UFilterSelect/UFilterSelect';

import styles from './SFiltersSelectGroup.style';
import STruncatedChipList from '../STruncatedChipList/STruncatedChipList';
import { useSFiltersSelectGroup } from './useSFiltersSelectGroup';

type SFiltersSelectGroupProps = {
  filters: Filter[];
  showClearButton?: boolean;
  onFiltersChanged: (Filters: Filter[]) => void;
};

export const SFiltersSelectGroup = ({
  filters,
  showClearButton = true,
  onFiltersChanged,
}: SFiltersSelectGroupProps) => {
  const { selectedFilters, handleSelectFilter, handleDeleteFilter, resetFilters, areItemsSelected } =
    useSFiltersSelectGroup(filters, onFiltersChanged);

  const filterWithChips = useMemo(() => {
    return selectedFilters.map((filter) => {
      return {
        ...filter,
        chips: filter.items.filter((it) => it.selected).map((it) => ({ label: it?.value, id: it.id, color: it.color })),
      };
    });
  }, [selectedFilters]);

  return (
    <div>
      <div>
        {filters.map((filter: Filter, index) => (
          <UFilterSelect
            key={getCategoryValue(filter.category)}
            filter={filter}
            onFilterChanged={(filter) => handleSelectFilter(filter, index)}
            style={styles.filterSelect}
          />
        ))}
      </div>
      <div style={styles.chipDiv}>
        {showClearButton && areItemsSelected && (
          <UButton text="Clear all" onClick={resetFilters} style={styles.clearAllButton} typography="BODY3_SEMIBOLD" />
        )}
        {filterWithChips.map((filter, index) => (
          <STruncatedChipList
            style={styles.chipList}
            key={getCategoryValue(filter.category)}
            chips={filter.chips}
            max={3}
            aggregatedDropdownLabel={getCategoryLabel(filter)}
            showCloseButtonOnChips
            onChipClose={(id: number) => {
              handleDeleteFilter(index, id);
            }}
          />
        ))}
      </div>
    </div>
  );
};
