import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
