import m from 'm';
import Enum from 'models/Enum';
import contentPreviewImage from 'components/content.preview.image';
import contentPreviewQuestion from 'components/content.preview.question';

var component = {};

component.view = function view(c, args) {
  var question = args.content.game().question().data();
  var image = args.content.game().mediaTypeFrontId() === Enum.mediaType.Image
    ? args.content.game().imageFront().cdn({transformation: {width: 300}})
    : args.content.game().videoFront().getOriginalThumbnailSync();
  var words = args.content.game().getFalsyText();

  return m('.preview-error-text', [
    m(contentPreviewImage, {
      image: image,
    }),
    m(contentPreviewQuestion, {
      question: question,
    }),
    m('.preview-error-text__bottom', [
      m('.preview-error-text__text', words.map(renderWords)),
    ]),
  ]);

  function renderWords(word) {
    return m('.preview-error-text__text__word', word);
  }
};

export default component;
