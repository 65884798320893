import React from 'react';

import type { CSSProperties } from 'react';

import type { IconName } from 'Components/foundation/icons';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import UButton from 'Components/unit/UButton/UButton';

import styles from './SAlert.style';

type AlertDisplayStatus = 'success' | 'error' | 'warning';
type AlertDisplayPriority = 'low' | 'medium';

export type SAlertProps = {
  status: AlertDisplayStatus;
  priority?: AlertDisplayPriority;
  icon: IconName;
  title?: string;
  text: string;
  isCallToAction?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  style?: CSSProperties;
};

const colors = {
  low: {
    error: COLORS.ALERT.ERROR,
    success: COLORS.ALERT.SUCCESS,
    warning: COLORS.WARNING.HOVER,
  },
  medium: {
    error: COLORS.ERROR.DARKER,
    success: COLORS.SUCCESS.DARKER,
    warning: COLORS.WARNING.DARK,
  },
};

const secondaryColors = {
  low: {
    error: COLORS.ERROR.DEFAULT,
    success: COLORS.SUCCESS.DEFAULT,
    warning: COLORS.WARNING.HOVER,
  },
  medium: {
    error: COLORS.ERROR.DEFAULT,
    success: COLORS.SUCCESS.DEFAULT,
    warning: COLORS.WARNING.HOVER,
  },
};

const backgroundWrapper = {
  error: COLORS.ERROR.LIGHTER,
  success: COLORS.SUCCESS.LIGHTER,
  warning: COLORS.WARNING.LIGHTER,
};

const onSuccessColor = {
  error: styles.onError,
  success: styles.onSuccess,
  warning: styles.onWarning,
};

/**
 * Display a alert component
 *
 * Props:
 * - style: For overriding the component's style
 * - status: set the color of the component
 * - priority: set the priority of the component
 * - icon: set the icon displayed on the left of the component
 * - title: set the title of the component
 * - text : set the text of the component
 * - isCallToAction: set the call to Action container visible
 * - onClose: Function called when we click on the X icon of the component
 * - onCancel: Function called when we click on the No button of the component
 * - onSuccess: Function called when we click on the Yes button of the component
 */

export class SAlert extends React.Component<SAlertProps> {
  static defaultProps = {
    priority: 'low',
    style: undefined,
    title: '',
    isCallToAction: false,
    onSuccess: () => {},
    onCancel: () => {},
  };

  renderTitle = () => {
    const { title } = this.props;

    return title ? <span style={styles.title}>{title}</span> : null;
  };

  renderText = () => {
    const { text, priority, title } = this.props;
    const fontText = title ? TYPOGRAPHY.BODY4 : TYPOGRAPHY.BODY3;
    const styleDecision = title ? styles.textMedium : styles.textLow;

    if (priority === 'medium') {
      return <span style={{ ...fontText, ...styleDecision }}>{text}</span>;
    }

    return <span style={{ ...fontText, ...styles.textLow }}>{text}</span>;
  };

  render() {
    const { style, priority, isCallToAction, title, status, icon, onClose, onSuccess, onCancel } = this.props;
    const closeIconColor = priority === 'low' ? secondaryColors[priority][status] : COLORS.GREY_DARKER.DEFAULT;
    const successButtonStyle = priority === 'medium' ? onSuccessColor[status] : null;
    const shouldShowButtonsContainer = priority === 'medium' && isCallToAction && title;
    const colorStyle = {
      color: colors[priority || 'low'][status],
      border: `1px solid ${secondaryColors[priority || 'low'][status]}`,
      backgroundColor: priority === 'medium' ? backgroundWrapper[status] : COLORS.WHITE.DEFAULT,
    };

    return (
      <div style={{ ...(styles.wrapper as CSSProperties), ...colorStyle, ...style }}>
        <div>
          <UIcon
            name={icon}
            size={20}
            color={colors[priority || 'low'][status]}
            style={{ ...styles.icon } as CSSProperties}
          />
          <div onClick={onClose} style={styles.closeIcon as CSSProperties}>
            <UIcon name={'close' as IconName} size={10} color={closeIconColor} />
          </div>
        </div>
        {this.renderTitle()}
        {this.renderText()}
        {shouldShowButtonsContainer ? (
          <div style={styles.buttonsContainer}>
            <UButton type="standard" text="Yes" onClick={onSuccess} style={successButtonStyle} />
            <UButton type="standard" text="No" onClick={onCancel} style={styles.cancelButton} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default SAlert;
