// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  modal: {
    width: 400,
    height: 237,
    margin: 'auto',
  },
  content: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 32,
    whiteSpace: 'pre-wrap',
  },
  contentWrapper: {
    padding: '0 20px',
  },
};

