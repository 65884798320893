/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Display a grey input
 *
 * Props:
 * - height: Set a default height
 * - value: Set the value to the textarea
 * - placeholder: Value to set in the textarea placeholder
 * - errorMessage: String to set for error feedback
 * - maxLength: Specify a max number of character
 * - onChange: callback on change
 * - disabled: disable the capacity to modify input
 * - style: can override the component style
 */

import * as React from 'react';

import styles from './SGreyInput.style';

type Props = {|

  // optional
  height: number,
  value: string,
  placeholder: string,
  errorMessage: string,
  maxLength: number,
  onChange: Function,
  disabled: boolean,
  style: Object,
|};

export class SGreyInput extends React.PureComponent<Props> {
  static defaultProps = {
    height: 40,
    value: '',
    placeholder: '',
    errorMessage: '',
    maxLength: 0,
    disabled: false,
    onChange: () => {},
    style: undefined,
  };

  // eslint-disable-next-line flowtype/require-compound-type-alias
  textAreaRef: { current: HTMLTextAreaElement | null };

  constructor(props: Props) {
    super(props);

    this.textAreaRef = React.createRef<HTMLTextAreaElement>();
  }

  componentDidUpdate() {
    if (this.textAreaRef.current) {

      // reset height
      this.textAreaRef.current.style.height = 'inherit';

      // set height to scroll textarea height
      this.textAreaRef.current.style.height = `${this.textAreaRef.current.scrollHeight}px`;
    }
  }

  render() {
    const { style, disabled } = this.props;

    const disabledStyle = disabled ? { ...styles.disabled } : {};

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderCount()}
        <div style={{ ...styles.textAreaWrapper, ...disabledStyle }}>
          { this.renderGreyInput() }
        </div>
        { this.renderErrorMessage() }
      </div>
    );
  }

  renderCount = () => {
    const { maxLength, value } = this.props;

    let style = { ...styles.count };

    if (!maxLength)
      return null;

    if (maxLength === value.length)
      style = { ...style, ...styles.countReached };

    return (
      <div style={style}>
        {`${value.length}/${maxLength}`}
      </div>
    );
  };

  renderGreyInput = () => {
    const { placeholder, height, value, maxLength, disabled } = this.props;

    const minHeightStyle = {
      'minHeight': height + 'px',
    };
    const disabledStyle = disabled ? styles.textDisabled : {};

    return (
      <textarea
        ref={this.textAreaRef}
        placeholder={placeholder}
        maxLength={maxLength || undefined}
        value={value}
        style={{ ...styles.textAreaField, ...minHeightStyle, ...disabledStyle }}
        onChange={this.handleValueChange}
        disabled={disabled}
        className='structural-inputs'
      />
    );
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.props;

    if (!errorMessage)
      return null;

    return (
      <div style={styles.errorMessage}>
        { errorMessage }
      </div>
    );
  };

  handleValueChange = (event: Object) => {
    const { onChange, maxLength } = this.props;
    const value = event.target.value
      .slice(0, maxLength || undefined);

    onChange(value);
  };
}

export default SGreyInput;
