import { useMemo, useCallback } from 'react';

import { useAppDispatch } from 'Libs/redux/store';
import { assignAudienceGroupsToEntity } from 'Features/audience/stores/thunks/audience-group.thunks';
import { useActionDialogConfirmation } from 'Hooks/useActionDialogConfirmation/useActionDialogConfirmation';
import { enqueueBasicAlert } from 'Pages/AlertManager';

type UseAudienceGroupAssignmentArgs = {
  entityId: number;
  assignedAudienceGroupIds: readonly number[];
  onGroupAssignment: (newGroupIds: number[]) => void;
};

const ALL_GROUP_ID = 1;

export const useAudienceGroupAssignment = ({
  entityId,
  assignedAudienceGroupIds,
  onGroupAssignment,
}: UseAudienceGroupAssignmentArgs) => {
  const dispatch = useAppDispatch();

  const assignedAudienceGroupIdsSet = useMemo(() => new Set(assignedAudienceGroupIds), [assignedAudienceGroupIds]);

  const {
    dialog,
    askForConfirmation,
  } = useActionDialogConfirmation({
    title: 'Set Audience to all',
    description: `Are you sure you want to set your course audience to all?\n
**All other groups will be unselected.**`,
    primaryActionLabel: 'Confirm',
    secondaryActionLabel: 'Cancel',
  });

  const assignAudienceGroup = useCallback(async (groupId: number) => {
    const isAlreadySelected = assignedAudienceGroupIdsSet.has(groupId);

    const audienceGroupIdsWithUpdatedSelection = isAlreadySelected
      ? assignedAudienceGroupIds.filter((id) => id !== groupId)
      : [...assignedAudienceGroupIds, groupId];

    /**
     * Handle the `All` group cases.
     * - If the selected groupId is `All` we remove all the other selected groups otherwise we filter it out
     * - If we unselect the last assigned group, we assign `All`
     */
    const newAudienceGroupIds = audienceGroupIdsWithUpdatedSelection.length && groupId !== ALL_GROUP_ID
      ? audienceGroupIdsWithUpdatedSelection.filter((id) => id !== ALL_GROUP_ID)
      : [ALL_GROUP_ID];

    // Prevent unselecting the `All` group if it is the only one selected
    if (audienceGroupIdsWithUpdatedSelection.length === 1 && assignedAudienceGroupIdsSet.has(ALL_GROUP_ID)) {
      return;
    }

    try {
      await dispatch(
        assignAudienceGroupsToEntity({
          entityId,
          audienceGroupIds: newAudienceGroupIds,
        }),
      );

      onGroupAssignment(newAudienceGroupIds);
    } catch (e) {
      enqueueBasicAlert({
        id: 'AUDIENCE_GROUP_ASSIGNMENT',
        text: 'Error while assigning audience groups.',
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    }
  }, [assignedAudienceGroupIds, assignedAudienceGroupIdsSet, dispatch, entityId, onGroupAssignment]);

  const assignAudienceGroupWithConfirmation = useCallback((groupId: number) => {
    // Ask a confirmation when selecting the `All` group with other groups selected
    if (assignedAudienceGroupIds.length
        && groupId === ALL_GROUP_ID
        && !assignedAudienceGroupIdsSet.has(ALL_GROUP_ID)
    ) {
      askForConfirmation(() => assignAudienceGroup(groupId));
      return;
    }

    assignAudienceGroup(groupId);
  }, [askForConfirmation, assignAudienceGroup, assignedAudienceGroupIds.length, assignedAudienceGroupIdsSet]);

  return {
    dialog,
    assignAudienceGroup: assignAudienceGroupWithConfirmation,
    assignedAudienceGroupIdsSet,
  };
};
