import { COLORS, ELEVATIONS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    padding: 24,
    backgroundColor: COLORS.WHITE.DEFAULT,
    border: '1px solid',
    borderColor: COLORS.BLUE_GRAY.LIGHT,
    boxShadow: ELEVATIONS.LIGHT_TINY,
    borderRadius: 8,
    position: 'relative',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  coverRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  coverColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  textAreaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 32,
  },
  textArea: {
    marginBottom: 8,
  },
  radioOptions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24,
  },
  radioWrapper: {
    marginRight: 56,
  },
});

export default styleSheet;
