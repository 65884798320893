// @flow

export default {
  emailInput: {
    marginTop: 40,
  },
  firstNameInput: {
    marginTop: 40,
  },
  lastNameInput: {
    marginTop: 40,
  },
  extraInfoInput: {
    marginTop: 40,
  },
  modalLabel: {
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    lineHeight: 'normal',
    color: 'rgb(34, 42, 52)',
    fontWeight: '700',
    position: 'absolute',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '70%',
    marginTop: '0px',
  },
  segmentationLabel: {
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    lineHeight: 'normal',
    color: 'rgb(34, 42, 52)',
    fontWeight: '700',
    position: 'absolute',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '70%',
    marginTop: '-22px',
  },
  segmentationWrapper: {
    marginTop: '30px',
  },
  deactivatedStatus: {
    display: 'inline-block',
    position: 'relative',
    top: '10px',
    marginRight: '10px',
  },
  activatedStatus: {
    display: 'inline-block',
    position: 'relative',
    top: '10px',
    marginLeft: '10px',
  },
};
