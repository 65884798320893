import Model from 'models/Model';

var AvailableSegmentationGroup = {
  endpoint  : 'api/AvailableSegmentationGroups',
  attributes: {
    label: {
      type: 'string',
    },
    data: {
      type: 'string',
    },
    groups: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    typeId: {
      type: 'number',
    },
    type: {
      type : 'pointer',
      Model: 'SegmentationType',
    },
  },
};

export default Model.register('AvailableSegmentationGroup', AvailableSegmentationGroup);
