import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';
export const geEditionBarHeight = ({ canSeeAudience }: { canSeeAudience: boolean }) => {
  return canSeeAudience ? 96 : 43;
};

export const getStyles = ({ canSeeAudience }: { canSeeAudience: boolean }) =>
  createStyleSheet({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: COLORS.WHITE.DEFAULT,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      height: geEditionBarHeight({ canSeeAudience }),
    },
    downloadButton: {
      marginRight: '8px',
    },
    editBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: 53,
      paddingLeft: 24,
      paddingRight: 30,
      borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    },
    sideContainer: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    imagePicker: {
      marginLeft: '24px',
      marginRight: '8px',
      width: 32,
      height: 32,
    },
    titleInput: {
      maxWidth: 590,
    },
    editButton: {
      marginLeft: 20,
      marginRight: 20,
    },
    divider: {
      marginLeft: 20,
      marginRight: 20,
    },
    publishIndicator: {
      marginRight: 20,
    },
    tabBar: {
      display: 'flex',
      alignItems: 'flex-end',
      height: 43,
      borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    },
    tabList: {
      marginBottom: -1,
    },
  });
