// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  optionLine: {
    ...TYPOGRAPHY.BODY3,
    display: 'flex',
  },
  infoIcon: {
    marginLeft: 10,
    marginRight: 40,
  },
  textArea: {
    marginTop: 32,
  },
};
