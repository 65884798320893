import React from 'react';

import UGameplayIndicator from 'Components/unit/UGameplayIndicator/UGameplayIndicator';
import SFlagGroup from 'Components/structural/SFlagGroup/SFlagGroup';
import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from '../SContentCard.style';
import { SContentCardProps } from '../SContentCard';

export const Content = ({
  id,
  locked,
  title,
  translationStatuses,
  type,
}: Pick<SContentCardProps, 'id' | 'locked' | 'title' | 'translationStatuses' | 'type'>) => {
  return (
    <div style={styles.contentContainer}>
      <div style={styles.contentId}>{id}</div>
      <div style={styles.title}>{title}</div>
      <div style={styles.cardFooter}>
        <div style={styles.infoContainer}>{type && <UGameplayIndicator key={type} type={type} />}</div>
        <div style={styles.flagContainer}>
          <SFlagGroup translationStatuses={translationStatuses} />
        </div>
      </div>
      {locked && <UIcon name="lock" size={12} color={COLORS.TEXT.SECONDARY_DEFAULT} />}
    </div>
  );
};
