import { t } from 'i18next';
import React, { useCallback } from 'react';

import MModal from 'Components/modal/MModal/MModal';

import styles from './BackModal.style';

type Props = {
  visible: boolean;
  onAction: (success: boolean) => void;
  onClose: () => void;
  isActionLoading: boolean;
};

export const BackModal = ({ visible, onAction, isActionLoading, onClose }: Props) => {
  const onDismiss = useCallback(() => onAction(false), [onAction]);
  const onValidate = useCallback(() => onAction(true), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onClose}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('contents:unsaved_changes.title')}
      labelActionButton={t('contents:unsaved_changes.save_and_leave')}
      labelSecondButton={t('contents:unsaved_changes.leave_without_saving')}
      actionButtonType="accentuated"
      isActionLoading={isActionLoading}
      bodyStyle={styles.modal}
    >
      <div style={styles.content}>{t('contents:unsaved_changes.message')}</div>
    </MModal>
  );
};
