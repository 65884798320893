import m from 'm';
import App from 'models/App';
import Enum from 'models/Enum';
import activityIcon from 'components/activity.icon';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.types = App.activityTypes();
};

component.view = function view(c, args) {

  var a = args.activity;
  var currentType = args.activity.typeId();

  return m('.activity-icon__grid.activity-icon__grid--popup', {},
    c.types
    .filter(getEnabledActivities)
    .filter(stripRanking)
    .filter(stripFinisher)
    .map(function activityTypes(type) {
      return m('.activity-icon__cell', {
        class: type.id() === currentType
          ? ''
          : 'activity-icon__cell--disabled',
        onclick: function onclick() {
          console.log('Choosing', type.id(), type.label());
          a.typeId(type.id());
          args.selectType();
        },
      }, [
        m.component(activityIcon, {
          activityType: type.id(),
          size        : 'large',
        }),
        m('.activity-icon__subtitle', type.label()),
      ]);
    })
  );

  function getEnabledActivities(item) {
    return item.enabled();
  }

  function stripRanking(item) {
    return item.id() !== Enum.activityTypeEnum.RANKING;
  }

  function stripFinisher(item) {
    return item.id() !== Enum.activityTypeEnum.FINISHER;
  }
};

export default component;
