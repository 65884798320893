import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';

var AccessControl = {
  endpoint  : 'api/AccessControls',
  attributes: {
    code: {
      type: 'string',
    },
    domain: {
      type: 'string',
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    emails: {
      type : 'collection',
      Model: 'AuthorizedEmail',
    },
    restrictionTypeId: {
      type: 'number',
    },
    authenticationSettings: {
      type : 'pointer',
      Model: 'AuthenticationSettings',
    },
    authenticationSettingsId: {
      type: 'number',
    },
  },
  methods: {
    uploadWhiteList: function uploadWhiteList(_AccessControl, file, progress, options) {
      var path = 'api/AuthorizedEmails/upload';

      return request.upload(path, file, progress, options)
        .then(function returnResponse(response) {
          console.log(response);
          if (response.result) {
            if (response.result.length)
              return response.result[0];

            return response.result;
          }

          return response;
        });
    },
  },
  classMethods: {
    isCodeUnique: function isCodeUnique(_AccessControl, code) {
      return request.get('api/accesscontrols/existingEnabledCodes')
        .then(function returnResponse(codes) {
          if (codes.length) {
            var formattedCodes = codes.map((a) => a.code.toLowerCase());

            return !formattedCodes.includes(code.toLowerCase());
          }

          return true;
        });
    },
  },
};

export default Model.register('AccessControl', AccessControl);
