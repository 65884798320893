import { COLORS, LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    position: 'relative',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    width: 28,
    height: 18,
    cursor: 'pointer',
  },
  buttonForCountry: {
    display: 'flex',
    flexDirection: 'row',
    width: 74,
    height: 18,
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 4,
    marginTop: 5,
  },
  iconForCountry: {
    marginLeft: 6,
    marginTop: 5,
  },
  dropdown: {
    position: 'absolute',
    right: 0,
    top: 32,
    width: 250,
    display: 'flex',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  dropdownCountry: {
    position: 'absolute',
    left: -16,
    top: 32,
    width: 183,
    display: 'flex',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  hasDialCodeSimpleDigit: {
    marginLeft: 20,
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  hasDialCodeDoubleDigit: {
    marginLeft: 15,
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  hasDialCodeTripleDigit: {
    marginLeft: 10,
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  disabledDropdown: {
    opacity: 0.3,
    cursor: 'default',
    pointerEvents: 'none',
    userSelect: 'none',
  },
});
