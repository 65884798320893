import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  firstNameInput: {
    marginTop: 23,
  },
  lastNameInput: {
    marginTop: 39,
  },
  phoneNumberInput: {
    marginTop: 39,
    width: '100%',
  },
});

export default styleSheet;
