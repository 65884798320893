/* eslint-disable complexity */
/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';
import contentHeader from 'components/content.header';
import deviceScreen from 'components/device.screen';
import inputQuestion from 'components/input.question';
import inputExplanationText from 'components/input.explanation.text';
import inputGameItem from 'components/input.game.item';
import inputRadio from 'components/input.radio';
import reactCreator from 'components/react.creator';
import EnumMedia from 'models/MediaHandlerEnum';

const Image = Model('Image');
const SwipingCardsItem = Model('SwipingCardsItem');
const GameItem = Model('GameItem');
const directions = ['left', 'right', 'top', 'bottom'];
const MAX_CARD = 6;

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.isImagePopupVisible = m.prop(false);
  self.isAddProposition = m.prop(false);
  self.isAddingImageToText = m.prop(false);

  self.openImagePopup = function openImagePopup() {
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.proposition = undefined;

    self.isImagePopupVisible(false);
    self.isAddingImageToText(false);
    m.redraw();
  };

  self.addImageToText = (proposition) => {
    self.proposition = proposition;
    self.isAddingImageToText(true);
  };

  if (!args.content().id())
    args.content().presetFromKnowledge(args.knowledge());

  self.addProposition = function addProposition(args, typeId) {
    if (args.content().game().propositions().length >= MAX_CARD)
      return;

    var proposition = new SwipingCardsItem({
      typeId: typeId,
      text  : {
        typeId     : Enum.gameItemTypes.ANSWER,
        knowledgeId: args.content().knowledgeId(),
      },
    });

    if (typeId === Enum.swipingCardsItemType.IMAGE) {
      self.proposition = proposition;

      self.openImagePopup();
    } else {
      args.content().game().propositions().push(proposition);
      self.isAddProposition(true);
    }
  };

  self.scrollProposition = function scrollThat() {
    var container = document.getElementsByClassName('swiping-cards__propositions')[0];
    var carousel = document.getElementsByClassName('swiping-cards__carousel')[0];

    container.scrollTop = container.scrollHeight - 578;
    carousel.scrollLeft = carousel.scrollWidth - 460;
    self.isAddProposition(false);
  };

  self.removeProposition = function removeProposition(args, proposition) {
    if (args.editable())
      args.content().game().propositions().removeByUUID(proposition._uuid);
  };

  self.setAnswer = function setAnswer(proposition, option) {
    proposition.answer(option);
    proposition.answerId(option.id());
  };

  self.addImageProposition = function addImageProposition(args, cropped) {
    self.linkImage(cropped);
    self.proposition.imageId(cropped.id());
    self.proposition.image(cropped);
    args.knowledge().linkImage(cropped.id());

    if (!self.isAddingImageToText()) {
      args.content().game().propositions().push(self.proposition);
      self.isAddProposition(true);
    }

    self.closeImagePopup();
  };

  self.linkImage = function linkImage(image) {
    return args.knowledge().linkImage(image.id())
      .then(function addImageToKnowledge(_image) {
        args.knowledge().images().push(_image);
      });
  };
};

component.view = function view(c, args) {
  var swipingCards = args.content().game();
  var imagePopup = m(reactCreator, {
    component: 'MImageCrop',
    props    : {
      onCancel        : c.closeImagePopup,
      onSave          : c.addImageProposition.bind(null, args),
      cropProps       : EnumMedia.SWIPINGCARDS,
    },
  });

  if (c.isAddProposition())
    setTimeout(c.scrollProposition, 50);

  return m('.content-edition', [
    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:swiping_cards.label'),
        gameplayName   : 'SWIPING_CARDS',
        explanationText: args.content().getDirective(),
      }),
      m('.content-edition__page-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            displayQuestion(c, args),
            displayOptions(c, args),
            displayPropositions(c, args),
          ]),
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : swipingCards.explanation,
              placeholder: t('gameplays:swiping_cards.explanation_placeholder'),
              editable   : args.editable(),
            }),
            displayCarousel(c, args),
          ]),
        ]),
      ]),
    ]),
    c.isImagePopupVisible() || c.isAddingImageToText() ? imagePopup : null,
  ]);
};

function displayQuestion(c, args) {
  var swipingCards = args.content().game();

  if (!swipingCards.question().typeId()) {
    swipingCards.question(new GameItem({
      typeId     : Enum.gameItemTypes.QUESTION,
      knowledgeId: args.content().knowledgeId(),
    }));
  }

  return m(inputQuestion, {
    gameItem   : swipingCards.question,
    placeholder: t('gameplays:swiping_cards.question_placeholder'),
    editable   : args.editable(),
  });
}

function displayOptions(c, args) {
  var options = args.content().game().options();

  if (options.at(options.length - 1).data() && options.length < 4) {
    options.push(new GameItem({
      typeId     : Enum.gameItemTypes.SHORT,
      knowledgeId: args.content().knowledgeId(),
    }));
  }

  return m('.swiping-cards__options', options.map(displayOption));

  function displayOption(option, i) {
    var arrowClass = '.swiping-cards__option__arrow--' + directions[i];

    if ((i === 2 || i === 3) && (!options.at(i - 1).data())) {
      options.at(i).data('');

      return null;
    }

    return ('.swiping-cards__option', [
      m('.swiping-cards__option__arrow' + arrowClass),
      m('.swiping-cards__option__input', [
        m(inputGameItem, {
          gameItem   : options.at.bind(options, i),
          placeholder: i >= 2 ? t('gameplays:swiping_cards.optional_value_placeholder') : t('gameplays:swiping_cards.value_placeholder'),
          editable   : args.editable(),
        }),
      ]),
    ]);
  }
}

function displayPropositions(c, args) {
  var options = args.content().game().options();
  var filledOptionNb = options.reduce(function reduce(nb, option) {
    return nb + (!!option.data());
  }, 0);
  var propositions = args.content().game().propositions();
  var propositionClass = [
    'swiping-cards__propositions--',
    options.length,
    'options',
  ].join('');

  return m('.swiping-cards__propositions', {
    class: propositionClass,
  }, [
    m.hide(filledOptionNb >= 2, '.swiping-cards__propositions__placeholder', t('gameplays:swiping_cards.values_validation')),
    propositions.map(displayProposition),
    m.hide(filledOptionNb < 2 || !args.editable() || args.content().game().propositions().length >= MAX_CARD, '.swiping-cards__proposition__add', [
      m('.swiping-cards__proposition__add__title', t('gameplays:swiping_cards.add')),
      m('.swiping-cards__proposition__add__buttons', [
        m('.swiping-cards__proposition__add__button.swiping-cards__proposition__add__button--txt', {
          onclick: c.addProposition.bind(null, args, Enum.swipingCardsItemType.TEXT),
        }, [
          m('.swiping-cards__proposition__add__button__value', t('gameplays:swiping_cards.text')),
        ]),
        m('.swiping-cards__proposition__add__button.swiping-cards__proposition__add__button--img', {
          onclick: c.addProposition.bind(null, args, Enum.swipingCardsItemType.IMAGE),
        }, [
          m('.swiping-cards__proposition__add__button__value', t('gameplays:swiping_cards.image')),
        ]),
      ]),
    ]),
  ]);

  function displayProposition(proposition, i) {
    const withImage = proposition.typeId() !== Enum.swipingCardsItemType.TEXT
      && proposition.image().id();

    return m('.swiping-cards__proposition', [
      m('.swiping-cards__proposition__counter', [
        m('.swiping-cards__proposition__counter__nb', i + 1),
        m('.swiping-cards__proposition__counter__total', '/' + propositions.length),
      ]),
      m('.swiping-cards__proposition__trash', {
        class  : !args.editable() ? 'swiping-cards__proposition__trash--disabled' : '',
        onclick: c.removeProposition.bind(null, args, proposition),
      }),
      options.map(displayPropositionOption),
      m('.swiping-cards__proposition__value', {
        class: !withImage ? 'swiping-cards__proposition__value--txt' : '',
        style: {
          backgroundImage: withImage
            ? 'url(' + proposition.image().cdn() + ')'
            : '',
        },
      }, [
        displayText(),
      ]),
      displayVariation(),
    ]);

    function displayText() {
      if (proposition.typeId() !== Enum.swipingCardsItemType.TEXT_AND_IMAGE) {
        return m(inputGameItem, {
          gameItem   : proposition.text,
          placeholder: withImage ? '' : t('gameplays:swiping_cards.text_placeholder'),
          editable   : proposition.typeId() !== Enum.swipingCardsItemType.IMAGE && args.editable(),
        });
      }

      return m('.swiping-cards-image-container',
        m('.swiping-cards-text-and-image-input-container',
          m(inputGameItem, {
            gameItem   : proposition.text,
            placeholder: t('gameplays:swiping_cards.text_placeholder'),
            editable   : proposition.typeId() !== Enum.swipingCardsItemType.IMAGE && args.editable(),
          })
        ),
      );
    }

    function displayPropositionOption(option, _i) {
      var existing = option.id() && proposition.answer().id();
      var equal = existing && option.id() === proposition.answer().id();

      return m.hide(!option.data(), '.swiping-cards__proposition__option.swiping-cards__proposition__option--' + directions[_i], [
        m('.swiping-cards__proposition__option__arrow.swiping-cards__proposition__option__arrow--' + directions[_i]),
        m('.swiping-cards__proposition__option__radio', [
          m(inputRadio, {
            editable: args.editable,
            value   : equal || proposition.answer() === option,
            oninput : c.setAnswer.bind(null, proposition, option),
          }),
        ]),
      ]);
    }

    function displayVariation() {
      return m('.swiping-card__proposition__variation', [
        getTextVariation(),
      ]);
    }

    function getTextVariation() {
      const typeId = proposition.typeId();

      if (typeId === Enum.swipingCardsItemType.IMAGE) {
        return m('.variation', {
          onclick: () => {
            proposition.typeId(Enum.swipingCardsItemType.TEXT_AND_IMAGE);
            proposition.text().typeId(2);
            proposition.text().knowledgeId(args.knowledge().id());
          },
        }, t('gameplays:swiping_cards.add_text'));
      }
      if (typeId === Enum.swipingCardsItemType.TEXT) {
        return m('.variation', {
          onclick: () => {
            c.addImageToText(proposition);

            proposition.typeId(Enum.swipingCardsItemType.TEXT_AND_IMAGE);
          },
        }, t('gameplays:swiping_cards.add_image'));
      }

      return m('.swiping-cards-remove-variation', [
        m('.swiping-cards-remove-image', {
          onclick: () => {
            proposition.typeId(Enum.swipingCardsItemType.TEXT);
            proposition.image(new Image());
            proposition.imageId(0);
          },
        }, t('gameplays:swiping_cards.remove_image')),
        m('.swiping-cards-remove-text', {
          onclick: () => {
            proposition.typeId(Enum.swipingCardsItemType.IMAGE);
            proposition.text(new GameItem());
            proposition.textId(0);
          },
        }, t('gameplays:swiping_cards.remove_text')),
      ]);
    }
  }
}

function displayCarousel(c, args) {
  var propositions = args.content().game().propositions();

  return m('.swiping-cards__carousel', propositions.map(displayCarouselProp));

  function displayCarouselProp(proposition) {

    return m('.swiping-cards__carousel__proposition', [
      getContent(proposition),
      m('.swiping-cards__carousel__proposition__option', [
        m('.swiping-cards__carousel__proposition__option__value', proposition.answer().data()),
      ]),
    ]);
  }

  function getContent(proposition) {
    const withImage = proposition.typeId() !== Enum.swipingCardsItemType.TEXT
      && proposition.image().id();

    if (proposition.typeId() === Enum.swipingCardsItemType.TEXT_AND_IMAGE) {
      return m('.swiping-cards__proposition__value', {
        style: {
          backgroundImage: withImage
            ? 'url(' + proposition.image().cdn() + ')'
            : '',
        },
      }, [
        m('.swiping-cards-image-container',
          m('.swiping-cards-text-and-image-input-container',
            m('.input-game-item', [
              m(inputGameItem, {
                gameItem   : proposition.text,
                placeholder: t('gameplays:swiping_cards.text_placeholder'),
                editable   : false,
              }),
            ]),
          ),
        ),
      ]);
    }

    return m('.swiping-cards__carousel__proposition__value', {
      class: !withImage ? 'swiping-cards__carousel__proposition__value--txt' : '',
      style: {
        backgroundImage: withImage
          ? 'url(' + proposition.image().cdn() + ')'
          : '',
      },
    }, [
      m('.swiping-cards__carousel__proposition__value__txt', proposition.text().data()),
    ]);
  }
}

export default component;
