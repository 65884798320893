// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  modal: {
    position: 'absolute',
    top: 70,
    bottom: 70,
    left: 40,
    right: 40,
    width: 800,
    margin: '0px auto',
    transform: '',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    ...TYPOGRAPHY.BODY3,
  },
};
