const BORDER_RADIUS = (cornerValue: number | string) => ({
  MozBorderRadius: cornerValue,
  WebkitBorderRadius: cornerValue,
  borderRadius: cornerValue,
});

export default {
  BORDER_RADIUS,
  CORNER1: 2,
  CORNER2: 4,
  CORNER3: 8,
  CORNER4: 12,
  ROUND: '50%',
};
