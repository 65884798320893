import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const CircleBackgroundIllustration = ({ width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 123 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60" cy="60" r="60" fill="#C6E0FE" />
    <circle cx="60" cy="60" r="60" fill="#C6E0FE" />
    <circle cx="60" cy="60" r="60" fill="#C6E0FE" />
    <path
      d="M107 120C115.837 120 123 112.837 123 104C123 95.1634 115.837 88 107 88C98.1634 88 91 95.1634 91 104C91 112.837 98.1634 120 107 120Z"
      fill={COLORS.SUCCESS.DARKER}
    />
    <path
      d="M104.263 110.682L98.7166 105.103C98.3834 104.768 98.3834 104.225 98.7166 103.89L99.9234 102.676C100.257 102.341 100.797 102.341 101.13 102.676L104.867 106.434L112.87 98.3847C113.203 98.0495 113.743 98.0495 114.077 98.3847L115.283 99.5984C115.617 99.9336 115.617 100.477 115.283 100.812L105.47 110.682C105.137 111.017 104.597 111.017 104.263 110.682Z"
      fill={COLORS.WHITE.DEFAULT}
    />
  </svg>
);

export default CircleBackgroundIllustration;
