import m from 'm';
import { t } from 'i18next';
import button from 'components/button';

var component = {};

component.view = function view(c, args) {
  var title = args.title || t('mithril_components:popup_leave_edition_confirmation.title') ;
  var cancelWording = args.cancelWording || t('mithril_components:popup_leave_edition_confirmation.cancel');
  var message = args.message || t('mithril_components:popup_leave_edition_confirmation.message');
  var confirmButton = args.confirmButton || t('mithril_components:popup_leave_edition_confirmation.confirm');

  var messageTxt = m('.popup__leave-edition-confirmation__message', [
    m('.popup__leave-edition-confirmation__message__title', title),
    message,
  ]);

  var cancelBtn = m(button, {
    value : cancelWording,
    class : 'button--action button--left button--border button--cancel',
    action: args.dontSave,
  });

  var saveBtn = m(button, {
    value : confirmButton,
    class : 'button--action button--right',
    action: args.save,
  });

  var actions = m('.popup__leave-edition-confirmation__actions', [
    cancelBtn,
    saveBtn,
  ]);

  return m('.popup__leave-edition-confirmation', [
    messageTxt,
    actions,
  ]);
};

export default component;
