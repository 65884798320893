import Model from 'models/Model';
import Request from 'services/requestService';

var PolyglotLabel = {
  endpoint  : 'api/PolyglotLabels',
  attributes: {
    translatedLabels: {
      type : 'collection',
      Model: 'TranslatedLabel',
    },
  },
  methods: {
    getLabel: function getLabel(_PolyglotLabel, languageId) {
      var TranslatedLabel = Model('TranslatedLabel');
      var newLabel = null;
      var existing = this.translatedLabels()
        .find({
          languageId: languageId,
        }).at(0);

      if (existing)
        return existing;

      newLabel = new TranslatedLabel({
        languageId     : languageId,
        polyglotLabelId: this.id(),
      });

      this.translatedLabels().push(newLabel);

      return newLabel;
    },
  },
  classMethods: {
    getTranslatedLabel: function getTranslatedLabel(_PolyglotLabel, id, languageId) {
      return Request.get('api/PolyglotLabels/' + id + '/translated/' + (languageId || 0));
    },
  },
};

export default Model.register('PolyglotLabel', PolyglotLabel);
