import { COLORS, LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    height: '100%',
    /**
     * We want the date picker to be visible when spreading over the right part of the panel.
     * However, setting overflow-y: auto prevents us from setting overflow-x: visible so we need
     * to have a big enough width.
     *
     * (see https://teamtreehouse.com/community/overflow-y-not-visible-when-overflow-x-is-set-to-scroll)
     */
    width: 305,
    overflowY: 'auto',
    flexShrink: 0,
    paddingTop: 32,
    backgroundColor: COLORS.WHITE.DEFAULT,
    position: 'relative',
    borderRight: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  inner: {
    position: 'relative',
    height: 'auto',
  },
  locked: {
    position: 'absolute',
    width: 300,
    top: 0,
    height: '100%',
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: LEVELS.LOCAL_OFFSET_2,
    textAlign: 'center',
    paddingTop: 244,
    paddingRight: 98,
    paddingLeft: 98,
  },
  unlocked: {
    display: 'none',
  },

  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  bottomSpace: {
    height: 200,
  },
  divider: {
    height: 1,
    marginBottom: 23,
  },
});

export default styleSheet;
