import { useMemo } from 'react';
import { DeepReadonly } from 'ts-essentials';

export type PdfPageBlock = {
  id: string;
  type: BlockContentType;
  data: string;
  bounds: [number, number, number, number];
};

export enum BlockContent {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
}

export type PdfBlockForCourse = {
  id: string;
  type: BlockContentType;
  content: string;
  bounds: [number, number, number, number];
};

export type PdfPageForCourse = {
  pageNumber: number;
  previewImage: string;
  width: number;
  height: number;
  blocks: PdfBlockForCourse[];
};

export type BlockContentType = BlockContent | `${BlockContent}`;

export const getBlockInfo = (block: DeepReadonly<PdfPageBlock>) => {
  return { type: block.type, content: block.data };
};

export type PdfPage = {
  id: number;
  pageNumber: number;
  previewImage: string;
  width: number;
  height: number;
  blocks: PdfPageBlock[];
};

type useExtractPdfPagesProps = {
  pages: DeepReadonly<PdfPage[]>;
};

export const useExtractPdfPages = ({ pages }: useExtractPdfPagesProps) => {
  const extractedPages = useMemo((): PdfPageForCourse[] => {
    return pages.map((page) => ({
      pageNumber: page.pageNumber,
      previewImage: page.previewImage,
      width: page.width,
      height: page.height,
      blocks: page.blocks.map((block) => {
        const bounds = block.bounds;

        return {
          ...getBlockInfo(block),
          id: block.id,
          bounds: [bounds[0], bounds[1], bounds[2], bounds[3]],
        };
      }),
    }));
  }, [pages]);

  return { extractedPages };
};
