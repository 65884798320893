// @flow

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    width: 353,
    height: 616,
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: 12,
    padding: 14,
    paddingBottom: 16,
    boxShadow: 'inset 3px -3px 9px rgba(0,0,0,0.061), 0px 13px 34px 0px rgba(0,0,0,0.065)',
    position: 'relative',
  },
  iframe: {
    width: 325,
    height: 586,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
  },
};
