// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 12;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

const USER_SELECT_NONE = {
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
};

export default {
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ...BORDER_RADIUS,
    ...USER_SELECT_NONE,
    cursor: 'pointer',
    height: 206,
    width: 167,
    minWidth: 167,
    minHeight: 206,
  },
  firstModeBoxShadow: {
    boxShadow: `0px 0px 0px 2px ${COLORS.PRIMARY.DEFAULT}`,
  },
  label: {
    ...TYPOGRAPHY.H2_SEMIBOLD,
    fontStyle: 'bold',
    textAlign: 'center',
    margin: 0,
    padding: '16px 40px 0 40px',
  },
};
