import Model from 'models/Model';

var DailySerie = {
  endpoint  : 'api/DailySeries',
  attributes: {
    typeId: {
      type: 'number',
    },
    activityId: {
      type: 'number',
    },
    visitNb: {
      type: 'number',
    },
    nbDays: {
      type: 'number',
    },
    type: {
      type : 'pointer',
      Model: 'DailySerieType',
    },
    weekDays: {
      type : 'collection',
      Model: 'WeekDay',
    },
    specificRuleActive: {
      type: 'boolean',
    },
    specificRuleText: {
      type: 'string',
    },
  },
};

export default Model.register('DailySerie', DailySerie);
