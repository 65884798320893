import { StyleSheet as Aphrodite } from 'aphrodite';
import type { CSSProperties } from 'aphrodite';

import ANIMATIONS from 'Components/foundation/animations';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export const ANIMATION_DURATION = ANIMATIONS.SPEED_NUMBER.SLOW;

const animationConfig: CSSProperties = {
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 1,
  animationFillMode: 'forwards',
  animationDuration: ANIMATIONS.SPEED.SLOW,
};

export const { enterAnimation, exitAnimation } = Aphrodite.create({
  enterAnimation: {
    ...animationConfig,
    animationName: {
      ...ANIMATIONS.TRANSFORMATIONS.FADE_IN,
    },
  },
  exitAnimation: {
    ...animationConfig,
    animationName: {
      ...ANIMATIONS.TRANSFORMATIONS.FADE_OUT,
    },
    // FIXME Remove this once exit animation on modals
    // does not cause a flicker because of modal state change
    animationDuration: '0ms',
  },
});

const styleSheet = createStyleSheet({
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(34, 42, 52, 0.5)',
    zIndex: 10000000,
  },
});

export default styleSheet;
