import { createEntityAdapter } from '@reduxjs/toolkit';

import { State } from 'Libs/redux/types';

import { ContentEntity } from './content.types';

export const contentAdapter = createEntityAdapter<ContentEntity>({});

export const {
  selectAll,
  selectById: selectByIdFromAdapter,
  selectEntities,
  selectIds,
  selectTotal,
} = contentAdapter.getSelectors();

export const selectById = (contentId?: number | null) => (state: State) =>
  contentId ? selectByIdFromAdapter(state.entities.content, contentId) : null;

export const contentMetadataSelector = (state: State) => state.entities.content.metadata;
