import React from 'react';
import { t } from 'i18next';

import UButton from 'Components/unit/UButton/UButton';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SArticle from 'Components/structural/SArticle/SArticle';

import styles from './UploadListSection.style';

export type UploadListSectionProps = {
  onAction: () => void;
  linkLearnMore: string;
  style?: React.CSSProperties;
};

export const UploadListSection = ({ linkLearnMore, onAction, style }: UploadListSectionProps) => {
  return (
    <SArticle
      title={t('config_ambassadors:upload_list_section.title')}
      subtitle={t('config_ambassadors:upload_list_section.subtitle')}
      style={style}
    >
      <div style={styles.buttonContainer}>
        <UButton
          text={t('config_ambassadors:upload_list_section.text_button')}
          type="accentuated"
          onClick={onAction}
          leftIcon="restore"
          style={styles.buttonWrapper}
        />
      </div>
      <div style={styles.callToActionContainer}>
        <UTextLink
          text={t('config_ambassadors:upload_list_section.learn_more')}
          leftIcon="external-link"
          link={linkLearnMore}
        />
      </div>
    </SArticle>
  );
};

export default UploadListSection;
