import React from 'react';

import type { CSSProperties } from 'react';

import styles from './SArticle.style';

export type SArticleProps = {
  style?: CSSProperties;
  children?: JSX.Element | JSX.Element[];
  title?: string;
  subtitle?: string;
};
export const SArticle = ({ style = {}, children, title, subtitle }: SArticleProps) => (
  <div style={{ ...styles.wrapper, ...style }}>
    {title && <div style={styles.title}>{title}</div>}
    {subtitle && <div style={styles.subtitle}>{subtitle}</div>}
    {children}
  </div>
);

export default SArticle;
