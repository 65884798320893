/* eslint-disable complexity */
import m from 'm';
import { t } from 'i18next';
import Promise from 'bluebird';
import moment from 'moment';
import Enum from 'models/Enum';
import MEDIAENUM from 'models/MediaHandlerEnum';
import App from 'models/App';
import popup from 'services/popupService';
import inputImage from 'components/input.image';
import segmentationInput from 'components/segmentation.input';
import inputTitle from 'components/input.title';
import inputTextArea from 'components/input.textarea';
import selectList from 'components/select.list';
import reactCreator from 'components/react.creator';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

var component = {};
const TYPES = Enum.gameplay;

component.controller = function controller(args) {
  var self = this;

  self.tabMenu = m.prop('main');
  self.thematic = m.prop(args.knowledge.thematic().name());
  self.thematicOne = m.prop([]);
  self.thematicTwo = m.prop([]);
  self.thematicThree = m.prop([]);
  self.languages = m.prop(App.userLanguages().items);
  self.languagesLabel = m.prop([]);
  self.language = m.prop(args.knowledge.getTargetLanguage());
  self.explanationPlaceholder = m.prop([
    t('contents:content_details.explanation_placeholder_2'),
  ]);
  self.knowMorePlaceholder = m.prop([
    t('contents:content_details.know_more_placeholder'),
  ]);
  self.isOpenThematicModal = m.prop(false);

  if (!App.userLanguages().length)
    self.languages(App.languages().items);

  self.gameplayNumber = m.prop({
    [TYPES.QCM]: 0,

    // [TYPES.SQCM]                  : 0, -- not available
    [TYPES.SWIPING_CARDS]: 0,
    [TYPES.NO_INTERACTION_CONTENT]: 0,
    [TYPES.OPEN_QUESTION]: 0,
    [TYPES.PICTURE_SPOT]: 0,
    [TYPES.ERROR_TEXT]: 0,
    [TYPES.ORDERING_CARDS]: 0,
  });

  self.iconGameplaySelected = m.prop(0);
  self.isImagePopupVisible = m.prop(false);

  self.openImagePopup = function openImagePopup() {
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.isImagePopupVisible(false);
    m.redraw();
  };

  self.openThematicModal = function openThematicModal() {
    self.isOpenThematicModal(true);
  };

  self.closeThematicModal = function closeThematicModal() {
    self.setThematicsFromApp();
    self.isOpenThematicModal(false);
  };

  self.changeImage = function changeImage(image) {
    self.linkImage(image);
    args.knowledge.images().push(image);
    args.knowledge.cover_id(image.id());
    self.closeImagePopup();
  };

  self.setThematicValue = function setThematicValue(value) {
    args.knowledge.thematic(value);
    args.knowledge.thematicId(value.id());
  };

  self.setLanguageValue = function setLanguageValue(value) {
    args.knowledge.setLanguage(value);
  };

  self.createGameplay = function createGameplay(id) {
    var currentGameplays = args.knowledge.contents().map(function returnGameplayId(el) {
      return el.gameplayId();
    });

    popup.open({
      type: 'selectGameplay',
      knowledgeId: args.knowledge.id(),
      gameplayId: id,
      currentGameplays: currentGameplays,
    });
  };

  self.openPopupContributor = function openPopupContributor() {
    popup.open({
      type: 'contributor',
      knowledge: args.knowledge,
    });
  };

  self.confirmThematicFromCreationModal = function confirmThematicFromCreationModal(selectedThematic) {
    self.setThematicValue(selectedThematic);
    self.thematic(selectedThematic.name());
    self.closeThematicModal();
  };

  self.tinyInputValue = m.prop(args.knowledge.customId() ? args.knowledge.customId() : args.knowledge.id());

  function splitThematicsByLevel(tabThematic) {
    for (var i = 0; i < tabThematic.length; i++) {
      var ancestorId = tabThematic[i].ancestorId();

      if (ancestorId === null)
        self.thematicOne().push(tabThematic[i]);
      else {
        var j = 0;

        for (; j < self.thematicOne().length; j++) {
          if (self.thematicOne()[j].id() === tabThematic[i].ancestorId()) {
            self.thematicTwo().push(tabThematic[i]);
            break;
          }
        }
        if (j === self.thematicOne().length)
          self.thematicThree().push(tabThematic[i]);
      }
    }
  }

  self.setThematicsFromApp = function setThematicsFromApp() {
    self.thematicOne([]);
    self.thematicTwo([]);
    self.thematicThree([]);

    splitThematicsByLevel(App.thematics().items);
  };

  function getLanguages(languages) {
    for (var i = 0; i < languages.length; i++)
      self.languagesLabel().push(languages[i].label());
  }

  function countGamePlays() {
    const gameplayCount = self.gameplayNumber();

    for (var i = 0; i < args.knowledge.contents().items.length; i++) {
      var gameplay = args.knowledge.contents().items[i].gameplay().id();

      if (gameplay in gameplayCount)
        gameplayCount[gameplay] += 1;
    }

    self.gameplayNumber(gameplayCount);
  }

  countGamePlays();
  getLanguages(self.languages());
  self.setThematicsFromApp();

  self.linkImage = function linkImage(image) {
    if (!args.knowledge.id())
      return Promise.resolve();

    return args.knowledge.linkImage(image.id())
      .then(function addImageToKnowledge(_image) {
        args.knowledge.images().push(_image);
      });
  };

  self.handleInputChange = function handleInputChange(value) {
    const newValue = value || null;

    args.knowledge.customId(newValue);
    self.tinyInputValue(value);
    m.redraw();
  };

  self.handleInputBlur = function handleInputBlur(value) {
    if (!value)
      self.tinyInputValue(args.knowledge.id());
    m.redraw();
  };
};

component.view = function view(c, args) {
  var imageInput = m(inputImage, {
    type: 'selectImage',
    images: args.knowledge.originalImages,
    origin: 'current knowledge',
    image: args.knowledge.cover,
    editable: args.editable(),
    onEdit: c.openImagePopup,
  });

  var imagePopup = m(reactCreator, {
    component: 'MImageCrop',
    props: {
      onCancel: c.closeImagePopup,
      onSave: c.changeImage,
      cropProps: MEDIAENUM.KNOWLEDGE,
    },
  });

  var title = m(inputTitle, {
    value: args.knowledge.knowledgeTitle().data(),
    placeholder: t('contents:content_details.title_placeholder'),
    maxlength: 40,
    callback: args.knowledge.knowledgeTitle().data,
    editable: args.editable(),
    invalid: args.error() && !args.knowledge.isTitleValid(),
  });

  var tinyInput = m('div.knowledge-id', [
    m('span', {
      style: `
        font-family: ${TYPOGRAPHY.BODY3.fontFamily};
        font-size: ${TYPOGRAPHY.BODY3.fontSize};
        line-height: ${TYPOGRAPHY.BODY3.lineHeight};
        color: ${COLORS.TEXT.DISABLED_DEFAULT};
        margin: 0 4px 1px 0;
      `,
    }, '#'),
    m(reactCreator, {
      component: 'UTinyInput',
      props: {
        value: c.tinyInputValue,
        width: '50px',
        maxLength: 5,
        onChange: c.handleInputChange,
        onBlur: c.handleInputBlur,
      },
    }),
  ]);

  var tabsHeader;

  if (!args.isCreation) {
    tabsHeader = m('ul.knowledge-header__menu', [
      m('li.knowledge-header__menu__elem', {
        class: c.tabMenu() === 'main' ? 'knowledge-header__menu__elem--selected' : '',
        onclick: c.tabMenu.bind(null, 'main'),
      }, t('contents:content_details.menu_tabs.main_info')),
      m('li.knowledge-header__menu__elem', {
        class: c.tabMenu() === 'explanation'
          ? 'knowledge-header__menu__elem--selected'
          : !args.knowledge.explanation().data().length
            ? 'knowledge-header__menu__elem--empty'
            : '',
        onclick: c.tabMenu.bind(null, 'explanation'),
      },  t('contents:content_details.menu_tabs.explanation')),
      m('li.knowledge-header__menu__elem', {
        class: c.tabMenu() === 'more'
          ? 'knowledge-header__menu__elem--selected'
          : !args.knowledge.knowMore().data().length
            ? 'knowledge-header__menu__elem--empty'
            : '',
        onclick: c.tabMenu.bind(null, 'more'),
      }, t('contents:content_details.menu_tabs.know_more')),
      m('li.knowledge-header__menu__elem', {
        class: c.tabMenu() === 'segmentation'
          ? 'knowledge-header__menu__elem--selected'
          : !args.knowledge.segmentation().length
            ? 'knowledge-header__menu__elem--empty'
            : '',
        onclick: c.tabMenu.bind(null, 'segmentation'),
      }, t('contents:content_details.menu_tabs.segmentation')),
    ]);
  }

  var mainInfos = drawMainInfos(c, args);
  var segmentation;
  var explanationText;
  var knowMoreText;
  var contributor;

  if (!args.isCreation) {
    explanationText = drawExplanationText(c, args);
    knowMoreText = drawMoreText(c, args);
    segmentation = drawSegmentation(c, args);
    contributor = drawContributor(c, args);
  }

  return m('.knowledge-header', [
    imageInput,
    m('.knowledge-header__informations', [
      tinyInput,
      title,
      tabsHeader,
      mainInfos,
      explanationText,
      knowMoreText,
      segmentation,
      contributor,
    ]),
    c.isImagePopupVisible() ? imagePopup : null,
  ]);
};

function drawMainInfos(c, args) {
  const addThematicButton = m(reactCreator, {
    component: 'UIconButton',
    props: {
      icon: 'plus-light',
      onClick: () => {
        c.openThematicModal();
      },
      style: {
        width: '100%',
      },
    },
  });

  const thematicTooltip = m(reactCreator, {
    component: 'STooltip',
    props: {
      id: 'addThematic',
      position: 'bottom',
      content: t('contents:content_details.thematics.add_thematic'),
    },
  });

  const knowledgeThematicsModal = m(reactCreator, {
    component: 'KnowledgeThematicsModal',
    props: {
      onClose: () => {
        c.closeThematicModal();
      },
      onConfirm: c.confirmThematicFromCreationModal,
      visible: c.isOpenThematicModal,
    },
  });

  var thematic = m('.knowledge-header__block-infos-first', [
    m('label.knowledge-header__label', t('contents:content_details.thematics.input_name')),
    m('div.knowledge-header__thematic-block-wrapper', [
      m(selectList, {
        key: 'key' + c.thematic(),
        levelOne: c.thematicOne(),
        levelTwo: c.thematicTwo(),
        levelThree: c.thematicThree(),
        value: c.thematic(),
        placeholder: t('contents:content_details.thematics.placeholder'),
        index: 1,
        callback: c.setThematicValue,
        editable: args.editable(),
        invalid: args.error() && !args.knowledge.isThematicValid(),
      }),
    ]),
    m('div.knowledge-header__thematic-button[data-tip][data-for=addThematic]', [
      addThematicButton,
      thematicTooltip,
    ]),
    knowledgeThematicsModal,
  ]);

  var language = m('.knowledge-header__block-infos', [
    m('label.knowledge-header__label', t('contents:content_details.language.input_name')),
    m(selectList, {
      levelOne: c.languages(),
      value: c.language(),
      placeholder: t('contents:content_details.language.placeholder'),
      index: 2,
      callback: c.setLanguageValue,
      editable: args.editable(),
      invalid: args.error() && !args.knowledge.isLanguageValid(),
    }),
  ]);

  function makeGameplayIcon(identifier, type) {
    var cssCls = '';
    var blockCssCls = '';
    var gpNb = c.gameplayNumber()[type];

    if (gpNb) {
      cssCls = 'knowledge-header__icon-gameplay-' + identifier + '-present';
      blockCssCls = identifier + '-present';
    }
    if (c.iconGameplaySelected() === type) {
      cssCls = 'knowledge-header__icon-gameplay-' + identifier + '-selected';
      blockCssCls = identifier + '-selected';
    }

    return m('.knowledge-header__block-icon-gameplay.gameplay-' + identifier, {
      onmouseover: c.iconGameplaySelected.bind(null, type),
      onmouseout: c.iconGameplaySelected.bind(null, 0),
      onclick: c.createGameplay.bind(null, type),
      class: blockCssCls,
    }, [
      m('.knowledge-header__icon-gameplay-' + identifier, {
        class: cssCls,
      }),
    ]);
  }

  var count = c.gameplayNumber();
  var counterByGP = [];

  for (const type of Object.values(TYPES)) {
    if (type !== TYPES.SQCM)
      counterByGP.push(m('.knowledge-header__gameplay-number', count[type] ? 'x' + count[type] : ''));
  }

  var gbNbRow = m('.knowledge-header__block-gameplay-number', counterByGP);

  var gameplays;

  if (!args.isCreation) {
    const gameplaysIcons = [
      m('.knowledge-header__label', t('contents:content_details.gameplays.title')),
      makeGameplayIcon('mcq', TYPES.QCM),

      // makeGameplayIcon('smcq', TYPES.SQCM), -- not available
      makeGameplayIcon('error-text', TYPES.ERROR_TEXT),
      makeGameplayIcon('open-text', TYPES.OPEN_QUESTION),
      makeGameplayIcon('no-interaction', TYPES.NO_INTERACTION_CONTENT),
      makeGameplayIcon('picture-spot', TYPES.PICTURE_SPOT),
      makeGameplayIcon('swiping-card', TYPES.SWIPING_CARDS),
      makeGameplayIcon('ordering-cards', TYPES.ORDERING_CARDS),
    ];

    gameplaysIcons.push(gbNbRow);

    gameplays = m('.knowledge-header__block-gameplay', gameplaysIcons);
  }

  return m('.knowledge-header__content-infos', {
    class: c.tabMenu() === 'main' ? '' : 'hide',
  }, [
    thematic,
    language,
    gameplays,
  ]);
}

function drawSegmentation(c, args) {
  return m('.knowledge-header__segmentation', {
    class: c.tabMenu() === 'segmentation' ? '' : 'hide',
  }, [
    m(segmentationInput, {
      entity: args.knowledge,
      languageUnicity: true,
      index: 1,
      readOnly: !args.editable(),
    }),
  ]);
}

function drawExplanationText(c, args) {
  return m('.knowledge-header__explanation', {
    class: c.tabMenu() === 'explanation' ? '' : 'hide',
  }, [
    m(inputTextArea, {
      placeholder: c.explanationPlaceholder().join(' '),
      value: args.knowledge.explanation().data,
      warningMax: 256,
      warningText: t('contents:content_details.explanation_placeholder'),
      max: 500,
      class: 'knowledge-header__input-explanation input--none',
      editable: args.editable(),
    }),
  ]);
}

function drawMoreText(c, args) {
  return m('.knowledge-header__more-text', {
    class: c.tabMenu() === 'more' ? '' : 'hide',
  }, [
    m(inputTextArea, {
      placeholder: c.knowMorePlaceholder().join(' '),
      value: args.knowledge.knowMore().data,
      max: 10000,
      class: 'knowledge-header__input-more-text input--none',
      editable: args.editable(),
    }),
  ]);
}

const getActionLabel = (action) => {
  if(action === 'Created'){
    return t('contents:content_details.contributors.popup.created')
  }
  if(action === 'Edited'){
    return t('contents:content_details.contributors.popup.edited')
  }
  return null
}


function drawContributor(c, args) {
  var lastIndex = args.knowledge.contributions().length - 1;
  var contributor = args.knowledge.contributions().at(lastIndex);
  var contribution = t('contents:content_details.contributors.no_contributors');
  if (contributor) {
    contribution = [
      getActionLabel(contributor.type().label()),
      t('contents:content_details.contributors.on'), writeDate(contributor.date()),
      t('contents:content_details.contributors.by'), contributor.player().fullName(),
    ].join(' ');
  }

  return m('.knowledge-header__contributor', {
    onclick: c.openPopupContributor.bind(null),
  }, [
    m('.knowledge-header__contributor__text', t('contents:content_details.contributors.title')),
    m('.knowledge-header__contributor__img__container', [
      m('.knowledge-header__contributor__img'),
    ]),
    m(args.knowledge.contributions().length > 1 ? '.knowledge-header__contributor__img--back' : 'hide'),
    m('.knowledge-header__contributor__infos', [
      m('.knowledge-header__contributor__infos__arrow'),
      m('.knowledge-header__contributor__infos__text', contribution),
      m('.knowledge-header__contributor__infos__click', t('contents:content_details.contributors.click_for_details')),
    ]),
  ]);
}

function writeDate(date) {
  return moment(date).format('MM.DD.YYYY');
}

export default component;
