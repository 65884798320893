import { handle } from 'redux-pack';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import type { Action } from 'Libs/redux/types';
import createPlayer from './models/Player';
import type { PlayerType } from './models/Player';

import type { AlertType } from './models/Alert';

export type LeaderboardState = DeepReadonly<{
  rankedLeaderboard: ReadonlyArray<PlayerType>;
  isGetRankedLeaderboardLoading: boolean;
  alerts: ReadonlyArray<AlertType>;
  leaderboardCount: number;
  selectedSegmentationIds: ReadonlyArray<number>;
  selectedCampaignIds: ReadonlyArray<number>;
  selectedTextValues: ReadonlyArray<string>;
  isFiltering: boolean;
}>;

type Filter = Pick<LeaderboardState, 'selectedSegmentationIds' | 'selectedCampaignIds' | 'selectedTextValues'>;

export const defaultState: LeaderboardState = {
  rankedLeaderboard: [],
  isGetRankedLeaderboardLoading: false,
  alerts: [],
  leaderboardCount: 0,
  selectedSegmentationIds: [],
  selectedCampaignIds: [],
  selectedTextValues: [],
  isFiltering: false,
};

const ERROR_SCOPE = {
  LEADERBOARD_GET_EXPORT: 'getLeaderboardReportError',
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: LeaderboardState = defaultState, action: Action): LeaderboardState => {
  switch (action.type) {
    case 'LEADERBOARD_GET_RANKED_LEADERBOARD': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isGetRankedLeaderboardLoading: true }),
        success: (prevState) => mergeDeep(prevState, { rankedLeaderboard: action.payload.map(createPlayer) }),
        finish: (prevState) => mergeDeep(prevState, { isGetRankedLeaderboardLoading: false }),
      });
    }

    case 'LEADERBOARD_GET_COUNT': {
      return handle(state, action, {
        success: (prevState) => mergeDeep(prevState, { leaderboardCount: action.payload }),
      });
    }

    case 'LEADERBOARD_SET_ALERT': {
      const alert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === alert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? alert : err))
        : state.alerts.concat([alert]);

      return mergeDeep(state, { alerts });
    }

    case 'LEADERBOARD_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'LEADERBOARD_GET_EXPORT': {
      return handle(state, action, {
        start: (prevState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]);

          return mergeDeep(prevState, { alerts });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {},
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
      });
    }

    case 'LEADERBOARD_UPDATE_FILTERS': {
      const defaultFilters: Filter = {
        selectedSegmentationIds: [],
        selectedCampaignIds: [],
        selectedTextValues: [],
      };

      const filters = action.selectedFilters.reduce((acc, filter) => {
        if (filter.category === 'segment') {
          return { ...acc, selectedSegmentationIds: filter.ids };
        }
        if (filter.category === 'campaign') {
          return { ...acc, selectedCampaignIds: filter.ids };
        }
        if (filter.category === 'text') {
          return { ...acc, selectedTextValues: filter.values };
        }

        return acc;
      }, defaultFilters);

      const filtersName = Object.keys(filters);
      const isFiltering = filtersName.reduce(
        (acc, filter) => acc || Boolean(filters[filter as keyof Filter].length),
        false,
      );

      return mergeDeep(state, { ...filters, isFiltering });
    }

    default:
      return state;
  }
};
