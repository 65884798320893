// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Specialty button
 *
 * Props:
 * - onClick: Function called when we click on the button
 * - text: The content of the button
 */

import * as React from 'react';

import styles from './SSpecialtyButton.style';


type Props = {|
  text: string,
  onClick: MouseEventHandler,
  style?: Object,
  disabled: boolean,
|};

type State = {|
  isHover: boolean,
|};

export class SSpecialtyButton extends React.PureComponent<Props, State> {
  state = { isHover: false };

  static defaultProps = {
    style: undefined,
    disabled: false,
  };

  render() {
    const { style, text, disabled, onClick } = this.props;
    const { isHover } = this.state;

    const disableStyle = disabled ? styles.disabled : styles.enabled;
    const hoverStyle = isHover ? styles.hoverStyle : {};

    const wrapperStyle = {
      ...styles.wrapper,

      // $FlowFixMe this is due to use the spread operator on more than one variable that depend on a condition
      ...disableStyle,
      ...hoverStyle,
      ...style,
    };

    return (
      <div
        style={wrapperStyle}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={onClick}
      >
        {text}
      </div>
    );
  }

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default SSpecialtyButton;
