import { mergeDeep } from 'Libs/mergeDeep';

import type { UndefinableId } from 'Libs/ts/types';

import createGameItem from '../GameItem';
import type { GameItemType } from '../GameItem';

export type OrderingCardsItemType = Readonly<{
  key: number;
  id: number;
  cardContent: GameItemType;
}>;

export type OrderingCardsItemToSend = UndefinableId<OrderingCardsItemType>;

const defaultState: OrderingCardsItemType = {
  key: 0,
  id: 0,
  cardContent: createGameItem(),
};

export function createOrderingCardsItem(values?: any): OrderingCardsItemType {
  if (!values) {
    return defaultState;
  }

  const orderingCardsItem: OrderingCardsItemType = {
    key: values.key || 0,
    id: values.id,
    cardContent: createGameItem(values.cardContent),
  };

  return mergeDeep(defaultState, orderingCardsItem);
}
