// @flow

import React from 'react';

import { NPSAlertView } from './NPSAlertView/NPSAlertView';
import { NPSView } from './NPSView/NPSView';

import styles from './NPS.style';

export const NPS: React$ComponentType<{||}> = () => {
  return (
    <div style={styles.wrapper}>
      <NPSAlertView component={NPSView} />
    </div>
  );
};
