// @flow

/* eslint-disable key-spacing */

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    zIndex: 10,
    width: '100%',
  },
  replacement: {
    flex: 1,
    display: 'flex',
    height: 64,
    backgroundColor: 'white',
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
};
