// @flow

import * as React from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './MRemoveLanguage.style';


type Props = {|
  style: Object,
  visible: boolean,
  onConfirm: () => void,
  onClose: () => void,
  isLoading: boolean,
|};

export class MRemoveLanguage extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, onConfirm, onClose, visible, isLoading } = this.props;

    return (
      <MModal
        labelActionButton={t('activities:modals.remove_language.button')}
        labelSecondButton={t('activities:modals.remove_language.cancel')}
        actionButtonType="destructive"
        onAction={onConfirm}
        onCloseModal={onClose}
        onSecondAction={onClose}
        title={t('activities:modals.remove_language.title')}
        visible={visible}
        style={{ ...styles.modal, ...style }}
        bodyStyle={styles.contentWrapper}
        isActionLoading={isLoading}
      >
        {t('activities:modals.remove_language.content')}
      </MModal>
    );
  }
}

export default MRemoveLanguage;
