// @flow

export default {
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
  },
};
