import m from 'm';
import { t } from 'i18next';
import moment from 'moment';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.action = function action() {
    args.action();
    args.close();
  };
};

component.view = function view(c, args) {
  return m('.popup--contributor', [
    m('.popup--contributor__header', [
      m('.popup--contributor__header__title', t('contents:content_details.contributors.popup.title')),
    ]),
    m('.popup--contributor__body', [
      m('.popup--contributor__body__header', [
        m('.popup--contributor__body__header__action', [
          m('.popup--contributor__body__header__action__img'),
          m('.popup--contributor__body__header__action__text', t('contents:content_details.contributors.popup.action')),
        ]),
        m('.popup--contributor__body__header__contributor', [
          m('.popup--contributor__body__header__contributor__img'),
          m('.popup--contributor__body__header__contributor__text', t('contents:content_details.contributors.popup.contributors')),
        ]),
        m('.popup--contributor__body__header__date', [
          m('.popup--contributor__body__header__date__img'),
          m('.popup--contributor__body__header__date__text', t('contents:content_details.contributors.popup.date')),
        ]),
      ]),
      m('.popup--contributor__body__list', [
        args.knowledge.contributions().items.map(function writeLine(item) {
          const getActionLabel = (action) => {
            if(action === 'Created'){
              return t('contents:content_details.contributors.popup.created')
            }
            if(action === 'Edited'){
              return t('contents:content_details.contributors.popup.edited')
            }
            return null
          }

          return m('.popup--contributor__body__list__line', [
            m('.popup--contributor__body__list__line__action', getActionLabel(item.type().label())),
            m('.popup--contributor__body__list__line__by', t('contents:content_details.contributors.by')),
            m('.popup--contributor__body__list__line__picture'),
            m('.popup--contributor__body__list__line__contributor', item.player().firstName() + ' ' + item.player().lastName()),
            m('.popup--contributor__body__list__line__on', t('contents:content_details.contributors.on')),
            m('.popup--contributor__body__list__line__date', writeDate(item.date())),
          ]);
        }),
      ]),
    ]),
  ]);
};

function writeDate(date) {
  return moment(date).format('MM.DD.YYYY');
}

export default component;
