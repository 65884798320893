import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  resetPasswordWrapper: {
    width: '100%',
  },
  instanceName: {
    ...TYPOGRAPHY.BODY3,
    opacity: 0.2,
    letterSpacing: 4,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 2,
  },
  title: {
    ...TYPOGRAPHY.TITAN_L,
    color: COLORS.BLACK.DEFAULT,
    marginBottom: 16,
  },
  instruction: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.BLACK.DEFAULT,
    marginBottom: 32,
    whiteSpace: 'pre-wrap',
  },
  validation: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.SUCCESS.DEFAULT,
    whiteSpace: 'pre-wrap',
    marginTop: 24,
  },
  inputWrapper: {
    width: 250,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 56,
  },
  button: {
    marginRight: 24,
  },
  errorMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
  },
  remainingTimeMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
});

export default styleSheet;
