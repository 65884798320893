import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import UDatePicker from 'Components/unit/UDatePicker/UDatePicker';

import { actions, useModuleSelector } from '../../../redux';

import styles from '../panel.style';
import launchDateFormStyles from './LaunchDateForm.style';

type LaunchDateFormProps = {
  disabled?: boolean;
};

export const LaunchDateForm = ({ disabled }: LaunchDateFormProps) => {
  const startDate = useModuleSelector((state) => state.activities[0].startDate);

  const dispatch = useTypedDispatch();
  const onClick = useCallback((date: Date) => dispatch(actions.setStartDate(date.toISOString())), [dispatch]);

  return (
    <div style={styles.box}>
      <h4 style={styles.title}>{t('activities:scheduling.side_panel.launch_date')}</h4>
      <div style={styles.element}>
        <UDatePicker
          date={new Date(startDate)}
          disabled={disabled}
          onClick={onClick}
          style={launchDateFormStyles.datePicker}
        />
      </div>
    </div>
  );
};

export default LaunchDateForm;
