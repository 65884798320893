import Model from 'models/Model';

var WelcomeCard = {
  endpoint  : 'api/WelcomeCards',
  attributes: {
    activityId: {
      type: 'number',
    },
    panels: {
      type : 'collection',
      Model: 'WelcomePanel',
    },
  },
};

export default Model.register('WelcomeCard', WelcomeCard);
