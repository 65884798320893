import m from 'm';
import menu from 'pages/menu';
import analyticsMenu from 'components/analytics.menu';
import App from 'models/App';
import Activity from 'models/Activity';
import User from 'services/userService';

import Config from 'services/instanceConfig';
import reactCreator from 'components/react.creator';

import sha256 from 'sha256';

const CACHE = {};

var component = {};

component.controller = function controller() {
  var self = this;

  self.userSegmentationIds = m.prop(User.meSync().segmentation().map((s) => s.id()));
  self.segmentationIds = m.prop(CACHE.segmentationIds || []);
  self.activityIds = m.prop(CACHE.activityIds || []);
  self.existingActivitiesMap = {};

  Activity.findAll()
    .then(activities => {
      activities.toJSON().forEach((activity) => {
        if (!self.existingActivitiesMap[activity.multilingualId])
          self.existingActivitiesMap[activity.multilingualId] = [];

        self.existingActivitiesMap[activity.multilingualId].push(activity.id);
      });

      m.redraw();
    });

  function encodeInstanceId() {
    const salt = 'sparted42';

    return sha256(Config.instanceId + salt);
  }

  self.getDimensionIdsBySegmentationId = function getDimensionIdsBySegmentationId() {
    const filteredSegmentation = App.userSegmentationItems();

    return filteredSegmentation
      .map((segItem) => segItem.group())
      .filter((x, i, a) => a.findIndex((y) => y.id() === x.id()) === i)
      .reduce((acc, group) => ({ ...acc, [group.id()]: group.dimensionId() }), {});
  };

  self.getIframeLink = function getIframeLink() {
    let link = 'https://analytics.sparted.com/app/?noHeader=true&instance=' + encodeInstanceId();

    if (self.activityIds().length)
      link += '&activityId=' + self.activityIds().join(';');

    const selectedSeg = Array.from(new Set(self.segmentationIds().concat(self.userSegmentationIds())));

    if (selectedSeg.length) {
      const dimensionIdsBySegmentationId = self.getDimensionIdsBySegmentationId();
      const segmentationIdsByDimension = selectedSeg
        .reduce((acc, id) => {
          const dimensionId = dimensionIdsBySegmentationId[id];
          const value = (acc[dimensionId] || []).concat(id);

          return { ...acc, [dimensionId]: value };
        }, {});

      link += '&segmentationId=' + Object.values(segmentationIdsByDimension)
        .map(ids => ids.join(';'))
        .join(':');
    }

    return link;
  };

  self.onFilterBoxChange = function onFilterBoxChange(filters) {
    filters.forEach((filter) => {
      if (filter.category === 'segment')
        CACHE.segmentationIds = self.segmentationIds(filter.ids);
      if (filter.category === 'campaign') {
        let realIds = [];

        filter.ids.forEach(multiLingualId => {
          if (self.existingActivitiesMap[multiLingualId])
            realIds = [...realIds, ...self.existingActivitiesMap[multiLingualId]];
        });

        realIds = realIds.filter((x, i, a) => a.findIndex((y) => y === x) === i);

        CACHE.activityIds = self.activityIds(realIds);
      }
    });

    m.redraw();
  };
};

component.view = function view(c) {
  return m('.analytics-page', [
    m('.analytics-page__menu', [
      m(menu),
      m(analyticsMenu),
    ]),
    m('.analytics-page__body', [
      m('.filter__box', [
        m(reactCreator, {
          component: 'SFilterBox',
          key      : 'filterbox-analytics-dashboard',
          props    : {
            title      : 'My analytics',
            description: 'Bring out valuable insight from the activity on your application',
            placeholder: 'Find the data you need',
            filters    : [
              { category: 'segment', ids: c.segmentationIds() },
              { category: 'campaign', ids: c.activityIds() },
            ],
            onChange: c.onFilterBoxChange,
            count   : null,
          },
        }),
      ]),
      m('iframe', {
        src: c.getIframeLink(),
        id : 'analytics_iframe',
      }),
    ]),
  ]);
};

export default component;
