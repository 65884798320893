import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  uploadWrapper: {
    width: 300,
  },
  parent: {
    width: 960,
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto auto auto 1fr',
    gap: 20,
    paddingBottom: 130,
  },
  firstBlock: {
    gridRow: 1,
    gridColumn: '1 / 3',
  },
  secondBlock: {
    gridColumn: 1,
    gridRow: '2 / 5',
  },
  thirdBlock: {
    gridColumn: 2,
    gridRow: 2,
  },
  fourthBlock: {
    gridColumn: 2,
    gridRow: 3,
  },
  title: {
    ...TYPOGRAPHY.TITAN_L,
    marginBottom: 4,
    marginTop: 82,
  },
  subtitle: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBottom: 36,
  },
  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
};
