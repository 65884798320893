import m from 'm';

import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

const component = {};

component.view = function view() {
  return m('.activity-page', [
    m('.header', [
      m(menu),
    ]),
    m(reactCreator, {
      component: 'ActivityList',
      style    : { height: '100%' },
    }),
  ]);
};

export default component;
