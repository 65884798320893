// @flow

import React from 'react';
import { t } from 'i18next';

import SDivider from 'Components/structural/SDivider/SDivider';

import { DailySerieDescriptionForm } from './components/DailySerieDescriptionForm/DailySerieDescriptionForm';
import { DailySerieDisclaimerForm } from './components/DailySerieDisclaimerForm/DailySerieDisclaimerForm';
import { DailySerieNameForm } from './components/DailySerieNameForm/DailySerieNameForm';
import { DailySerieOverviewForm } from './components/DailySerieOverviewForm/DailySerieOverviewForm';
import { DailySerieValidityCheckList } from './components/DailySerieValidityCheckList/DailySerieValidityCheckList';
import styles from './Overview.style.js';

export const Overview = () => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <div style={styles.box1}>
          <DailySerieValidityCheckList />
        </div>
        <div style={styles.box2}>
          <DailySerieOverviewForm />
          <DailySerieNameForm />
          <DailySerieDescriptionForm />
          <SDivider style={styles.divider} text={t('activities:overview.form.advanced_options')} />
          <DailySerieDisclaimerForm />
        </div>
        <div style={styles.requiredFields}>
          {t('activities:overview.form.required_fields')}
        </div>
        <div style={styles.bottomSpace} />
      </div>
    </div>
  );
};
