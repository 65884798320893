// @flow

/* eslint-disable key-spacing */

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 4;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

const FORM_BLOCK = {
  width: 650,
  ...BORDER_RADIUS,
  backgroundColor: COLORS.WHITE.DEFAULT,
  paddingLeft: 30,
  paddingTop: 24,
  paddingBottom: 51,
  marginRight: 50,
  marginBottom: 16,
};

export default {
  wrapper: {
    height: '100%',
    width: 970,
    margin: 'auto',
    paddingTop: 32,
  },
  title: {
    ...TYPOGRAPHY.TITAN_L,
    marginBottom: 32,
  },
  form: {
  },
  content: {
    display: 'flex',
  },
  formTitleContainer: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  titleForm: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
  },
  subtitleForm: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 8,
  },
  instructionsBlock: {
    ...FORM_BLOCK,
    paddingRight: 80,
  },
  answersBlock: {
    ...FORM_BLOCK,
    paddingRight: 30,
  },
  titleAndImage: {
    marginBottom: 35,
  },
  divider: {
    marginBottom: 40,
  },
  requiredFields: {
    ...TYPOGRAPHY.BODY3,
    marginTop: 8,
  },
  gameplayInformations: {
    width: 270,
  },
  categoryWrapper: {
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  categoryTitle: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 'bold',
  },
  categoryExplanation: {
    ...TYPOGRAPHY.BODY3,
  },

  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  bottomSpace: {
    height: 101,
  },
};
