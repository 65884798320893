
// @flow

/* eslint-disable key-spacing */
/* eslint-disable no-inline-comments */
/* eslint-disable react/no-multi-comp */

import React from 'react';
import {useTranslation} from 'react-i18next';

import { OCInstructionForm } from './components/OCInstructionForm/OCInstructionForm';
import { OCHelperForm } from './components/OCHelperForm/OCHelperForm';
import { OCExplanationForm } from './components/OCExplanationForm/OCExplanationForm';
import { OCItemsForm } from './components/OCItemsForm/OCItemsForm';

import styles from './OCCreation.style.js';

export const OCCreation = () => {
  const { t } = useTranslation();
  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>
        { t('gameplays:ordering_cards.label') }
      </div>
      <div style={styles.content}>
        <div style={styles.form}>
          <div style={styles.instructionsBlock}>
            {renderTitleForm(
              t('gameplays:ordering_cards.instruction_title'),
              t('gameplays:ordering_cards.instruction_subtitle')
            )}
            <OCInstructionForm />
            <OCHelperForm />
          </div>
          <div style={styles.answersBlock}>
            {renderTitleForm(
              t('gameplays:ordering_cards.answer_title'),
              t('gameplays:ordering_cards.answer_subtitle')
            )}
            <OCExplanationForm />
            <OCItemsForm />
          </div>
        </div>

        <div style={styles.gameplayInformations}>
          {renderGameplayExplanation(t('gameplays:ordering_cards.use_case_title'), t('gameplays:ordering_cards.use_case_explanation'))}
          {renderGameplayExplanation(t('gameplays:ordering_cards.value_title'), t('gameplays:ordering_cards.value_explanation'))}
          {renderGameplayExplanation(t('gameplays:ordering_cards.time_title'), t('gameplays:ordering_cards.time_explanation'))}

          { /* // TODO add video when it's ready */ }
        </div>
      </div>
      <div style={styles.bottomSpace} />
    </div>
  );
};

function renderTitleForm(
  title: string,
  subtitle: string,
) {
  return (
    <div style={styles.formTitleContainer}>
      <span style={styles.titleForm}>
        { title }
      </span>
      <span style={styles.subtitleForm}>
        { subtitle }
      </span>
    </div>
  );
}

function renderGameplayExplanation(
  title: string,
  explanation: string,
) {
  return (
    <div style={styles.categoryWrapper}>
      <span style={styles.categoryTitle}>
        { title }
      </span>
      <span style={styles.categoryExplanation}>
        { explanation }
      </span>
    </div>
  );
}

export default OCCreation;
