import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  sCampaignInfo: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 24,
  },
  campaignHeader: {
    zIndex: 9,
  },
  stickySCampaignInfo: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
});
