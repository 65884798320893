import { t } from 'i18next';
import type { CSSProperties } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';

import { LabelSize } from 'Components/unit/ULabel/ULabel';
import { ComparisonTextArea } from './ComparisonTextArea/ComparisonTextArea';
import styles, { TEXT_AREA_HEIGHT } from './SErrorTextAreas.style';
import { getTextComparisonSegments } from './utils/getTextComparisonSegments.utils';

export type SErrorTextAreasProps = {
  style?: CSSProperties;
  correctValue?: string;
  wrongValue?: string;
  onChangeCorrect: (value: string) => void;
  onChangeWrong: (value: string) => void;
};

const MAX_LENGTH = 100;
const LABEL_SIZE = 'M' as LabelSize;
const TEXT_AREA_TYPO = 'BODY2' as keyof typeof TYPOGRAPHY;
const WRAPPER_RADIUS = 8;
const LABEL_ICON_SIZE = 16;

const COMMON_PROPS = {
  canResize: false,
  height: TEXT_AREA_HEIGHT,
  maxLength: MAX_LENGTH,
  textAreaStyle: styles.textArea,
  labelSize: LABEL_SIZE,
  textAreaTypo: TEXT_AREA_TYPO,
  radiusWrapper: WRAPPER_RADIUS,
  labelIconSize: LABEL_ICON_SIZE,
};

export const SErrorTextAreas = ({
  style = {},
  correctValue = '',
  wrongValue = '',
  onChangeCorrect,
  onChangeWrong,
}: SErrorTextAreasProps) => {
  const [wrongValueTouched, setWrongValueTouched] = useState(false);
  const handleChangeWrong = useCallback(
    (args) => {
      if (!wrongValueTouched) {
        setWrongValueTouched(true);
      }
      return onChangeWrong(args);
    },
    [onChangeWrong, wrongValueTouched],
  );
  // The wrong text input follows the right text input until it is touched
  const wrongValueOrDefault = useMemo(
    () => (wrongValueTouched ? wrongValue : wrongValue || correctValue),
    [wrongValue, correctValue, wrongValueTouched],
  );

  const textComparisonSegments = useMemo(
    () => getTextComparisonSegments(correctValue, wrongValueOrDefault),
    [correctValue, wrongValue],
  );

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <ComparisonTextArea
        value={correctValue}
        onChange={onChangeCorrect}
        variant="valid"
        label={t('gameplays:error_text.create_correct_sentence')}
        labelIcon={'check' as IconName}
        labelIconColor={COLORS.SUCCESS.DEFAULT}
        comparisonSegments={textComparisonSegments}
        {...COMMON_PROPS}
      />
      <div style={styles.spacerBetweenAreas}></div>
      <ComparisonTextArea
        value={wrongValueOrDefault}
        onChange={handleChangeWrong}
        variant="invalid"
        label={t('gameplays:error_text.create_wrong_sentence')}
        labelIcon={'close' as IconName}
        labelIconColor={COLORS.ERROR.DARK}
        comparisonSegments={textComparisonSegments}
        {...COMMON_PROPS}
      />
    </div>
  );
};

export default SErrorTextAreas;
