import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import UImage from 'Components/unit/UImage/UImage';

import styles from './SCropPreview.style';

export interface SCropPreviewProps {
  ratio: number;
  picturePath: string;
  title: string;
  style?: CSSProperties;
}

/**
 * Display a picture preview
 *
 * Props:
 *  - style: For overriding the component's style
 *  - ratio: expected ratio of the picture
 *  - picturePath: the path of the picture
 *  - title : text displayed on the top of the picture
 */
export const SCropPreview = ({ ratio, picturePath, title, style = {} }: SCropPreviewProps) => {
  /**
   * Dynamic attr
   */
  const height = useMemo(() => (styles.picture.width as number) / ratio, [ratio]);
  /**
   * Render
   */
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <span style={styles.title}>{title}</span>
      <UImage src={picturePath} alt={title} style={{ ...styles.picture, height }} />
    </div>
  );
};

export default SCropPreview;
