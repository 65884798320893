import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import type { StyleSheet } from 'Components/foundation/stylesheets';

const stylesheet: StyleSheet = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  picture: {
    width: 193,
    objectFit: 'cover',
    boxShadow: `0 0 0 1px ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  title: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    alignSelf: 'flex-start',
    marginBottom: 5,
  },
};

export default stylesheet;
