import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  container: {
    padding: '15px 23px 15px 24px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: '12px',
    border: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
  },
});
