import React, { Children, CSSProperties } from 'react';
import type { FunctionComponent } from 'react';

import styles, { cardDimensions } from './CourseGrid.style';

export type CourseGridProps = {
  style?: CSSProperties;
};

export const CourseGrid: FunctionComponent<CourseGridProps> = ({ style, children }) => {
  const childrenCount = Children.count(children);
  const hasMoreThanSixChild = childrenCount > 6;

  const gridStyle = hasMoreThanSixChild ? styles.threeColumnGrid : styles.twoColumnGrid;

  const cellStyle = hasMoreThanSixChild ? cardDimensions.smallCard : cardDimensions.bigCard;

  return (
    <div style={{ ...gridStyle, ...style }}>
      {Children.map(children, (child, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div style={cellStyle} key={String(i)}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default CourseGrid;
