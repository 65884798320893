import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { t } from 'i18next';
import { getTimeDiffAsString } from '@sparted/shared-library';

import { useTimeoutCallback } from 'Hooks/useTimeoutCallback/useTimeoutCallback';

import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UMediaPicker from 'Components/unit/UMediaPicker/UMediaPicker';
import SCourseCard from 'Components/structural/SCourseCard/SCourseCard';
import MModalGroup, { MModalGroupItem } from 'Components/modal/MModalGroup/MModalGroup';

import { COLORS } from 'Components/foundation';

import Enum from 'Models/Enum';

import { trackEventWithLocation } from 'Services/trackingService';
import { getCurrentModalState } from './utils/MCoursePublication.utils';
import type { ModalStateTypes } from './utils/MCoursePublication.utils';

import styles from './MCoursePublication.style';

type SelectedImageData = {
  id: number;
  cdn: string;
  url: string;
};

export type CourseData = {
  id: number;
  title: string;
  status: 'published' | 'pending' | 'unpublished';
  language: string;
  lastEditDate: Date;
  lastEditUsername: string;
  image?: string;
};

export type MCoursePublicationProps = {
  visible: boolean;
  isPublished: boolean;
  courseData: CourseData;
  canPublish?: boolean;
  onConfirm: () => Promise<any>;
  onClose: () => void;
  onImageSelect: (image: SelectedImageData) => void;
};

const SUCCESS_MODAL_TIMEOUT = 1500;

const modalStepMap = {
  IMAGE_CONFIRMATION: 'IMAGE',
  IMAGE_SELECTION: 'IMAGE',
  PUBLISHING: 'PUBLISH',
  UNPUBLISHING: 'UNPUBLISH',
  UPDATING: 'PUBLISH',
  PUBLISH_SUCCESS: 'SUCCESS',
  UNPUBLISH_SUCCESS: 'SUCCESS',
  UPDATE_SUCCESS: 'SUCCESS',
  ERRORS: 'ERRORS',
};

const getSuccessText = (modalState: string): string => {
  if (modalState === 'UPDATE_SUCCESS') {
    return 'courses:publication_modal.successfully_updated';
  }

  if (modalState === 'PUBLISH_SUCCESS') {
    return 'courses:publication_modal.successfully_published';
  }

  return 'courses:publication_modal.successfully_unpublished';
};

const STATE_TO_EVENT: Partial<Record<ModalStateTypes, string>> = {
  PUBLISH_SUCCESS: 'publish',
  UPDATE_SUCCESS: 'update',
  UNPUBLISH_SUCCESS: 'unpublish',
};

const sendAnalitycs = (modalState: ModalStateTypes) => {
  const event = STATE_TO_EVENT[modalState];
  if (event) {
    trackEventWithLocation(event, { context: 'course' });
  }
};

/**
 * Component wrapping the validation logic for publishing a course
 *
 * Props:
 * - visible: boolean for whether the modal is visible or not
 * - courseData: data used to validate the course
 * - onConfirm: callback when confirming publication, should return a bluebird promise
 * - onClose: callback fired when the modal is closed, or action is canceled
 * - onImageSelect: callback fired when an image is selected by the user
 * - style: override style of the modal
 */
export const MCoursePublication = ({
  visible,
  courseData,
  isPublished,

  canPublish = true,
  onConfirm,
  onClose,
  onImageSelect,
}: MCoursePublicationProps) => {
  const [localImage, setLocalImage] = useState<SelectedImageData | undefined>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [wasUpdating, setWasUpdating] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);

  // Reset modal state when visible => false;
  useEffect(
    () => () => {
      if (!visible) {
        setShowSuccess(false);
        setLocalImage(undefined);
      }
    },
    [visible],
  );

  const hideSuccessModal = useTimeoutCallback(
    useCallback(() => {
      onClose();
    }, [onClose]),
    SUCCESS_MODAL_TIMEOUT,
  );

  const handleClose = useCallback(() => {
    const { clear } = hideSuccessModal;

    clear();
    onClose();
  }, [onClose, hideSuccessModal]);

  const handleAutoClose = useCallback(
    (step: string) => {
      if (step === 'SUCCESS') {
        hideSuccessModal.exec();
      }
    },
    [hideSuccessModal],
  );

  const handleLocalImageSelection = useCallback((image: SelectedImageData) => {
    setLocalImage(image);
  }, []);

  const handleImageConfirm = useCallback(() => {
    onImageSelect(localImage!);
    setLocalImage(undefined);
  }, [localImage, onImageSelect]);

  const modalState = useMemo(
    () =>
      getCurrentModalState(courseData, Boolean(localImage), isPublished, {
        success: showSuccess,
        wasUpdating,
        withErrors: !!errors,
      }),
    [courseData, isPublished, localImage, showSuccess, wasUpdating, errors],
  );

  const modalStep = useMemo(() => modalStepMap[modalState], [modalState]);

  const handleRequestEnd = useCallback(() => {
    if (errors) {
      return;
    }
    if (modalState === 'UPDATING') {
      setWasUpdating(true);
      setShowSuccess(true);
    } else {
      setShowSuccess(true);
    }
  }, [modalState, errors]);

  const lastEditedBy = useMemo(() => {
    const diffString = getTimeDiffAsString(courseData.lastEditDate);
    return `Last edited ${diffString} by ${courseData.lastEditUsername}`;
  }, [courseData.lastEditDate, courseData.lastEditUsername]);

  const handleConfirm = useCallback(async () => {
    try {
      await onConfirm();
    } catch (e) {
      setErrors((e as Error).message);
    }
  }, [onConfirm]);

  const displayCard = useMemo(
    () => (
      <SCourseCard
        id={courseData.id}
        title={courseData.title}
        language={Enum.Languages[courseData.language as keyof typeof Enum.Languages]}
        status={courseData.status}
        image={courseData.image}
        isStatic
        size="S"
        showStatusIndicator={false}
      />
    ),
    [courseData],
  );

  useEffect(() => {
    sendAnalitycs(modalState);
  }, [modalState]);

  return (
    <MModalGroup visible={visible} currentStep={modalStep} onClose={handleClose} onStepEnd={handleAutoClose}>
      <MModalGroupItem
        step="PUBLISH"
        title={
          modalState === 'UPDATING'
            ? t('courses:publication_modal.about_to_update')
            : t('courses:publication_modal.about_to_publish')
        }
        labelActionButton={
          modalState === 'UPDATING' ? t('courses:publication_modal.update') : t('courses:publication_modal.publish')
        }
        labelSecondButton={t('courses:publication_modal.cancel')}
        onAction={handleConfirm}
        onSecondAction={handleClose}
        onActionEnd={handleRequestEnd}
        style={styles.modal}
        showExitCross
        type="dialog"
        disableActionButton={!canPublish}
      >
        <div style={styles.publishContainer}>
          <div style={styles.cardContainer}>
            {displayCard}
            <p style={styles.lastEditedBy}>{lastEditedBy}</p>
          </div>
          {modalState === 'UPDATING' ? (
            <div>
              <p style={styles.paragraph}>{t('courses:publication_modal.disclaimer_4')}</p>
            </div>
          ) : (
            <div>
              <p style={styles.paragraph}>{t('courses:publication_modal.disclaimer_1')}</p>
              <p style={styles.paragraph}>{t('courses:publication_modal.disclaimer_2')}</p>
            </div>
          )}
        </div>
      </MModalGroupItem>
      <MModalGroupItem
        step="UNPUBLISH"
        title={t('courses:publication_modal.about_to_unpublish')}
        labelActionButton={t('courses:publication_modal.unpublish')}
        actionButtonType="destructive"
        labelSecondButton={t('courses:publication_modal.cancel')}
        onAction={handleConfirm}
        onSecondAction={handleClose}
        onActionEnd={handleRequestEnd}
        style={styles.modal}
        showExitCross
        type="dialog"
      >
        <div style={styles.publishContainer}>
          <div style={styles.cardContainer}>
            {displayCard}
            <p style={styles.lastEditedBy}>{lastEditedBy}</p>
          </div>
          <div>
            <p style={styles.paragraph}>{t('courses:publication_modal.disclaimer_3')}</p>
          </div>
        </div>
      </MModalGroupItem>

      <MModalGroupItem
        step="IMAGE"
        title={t('courses:publication_modal.must_add_image')}
        labelActionButton={t('courses:publication_modal.choose_image')}
        labelSecondButton={t('courses:publication_modal.cancel')}
        onAction={modalState === 'IMAGE_CONFIRMATION' ? handleImageConfirm : undefined}
        onSecondAction={handleClose}
        style={styles.modal}
        showExitCross
        type="dialog"
      >
        <div style={styles.imagePickerContainer}>
          <UMediaPicker
            size="L"
            image={localImage?.url}
            dropArea
            hideModalOverlay
            media={{ mediaType: 'image', onChange: handleLocalImageSelection }}
          />
        </div>
      </MModalGroupItem>

      <MModalGroupItem step="SUCCESS" style={styles.modal} onScroll={handleClose} showExitCross type="dialog">
        <div style={styles.successContainer}>
          <h1 style={styles.successTitle}>{t(getSuccessText(modalState))}</h1>
          <div style={styles.successIllustration}>
            <UIllustration
              color={isPublished ? COLORS.SUCCESS : COLORS.BLUE_GRAY}
              illustration="course-checked"
              data-test-id="success-illustration"
            />
          </div>
        </div>
      </MModalGroupItem>

      <MModalGroupItem step="ERRORS" style={styles.modal} onScroll={handleClose} showExitCross type="dialog">
        <div style={styles.successContainer}>
          <h1 style={styles.errorTitle}>{t('courses:publication_modal.cannot_publish')}</h1>
          <div style={styles.errorIllustration}>
            <UIllustration illustration="course-error" data-test-id="errors-illustration" />
          </div>
          <div>
            <p style={styles.errorParagraph}>{errors}</p>
          </div>
        </div>
      </MModalGroupItem>
    </MModalGroup>
  );
};

export default MCoursePublication;
