import sha256 from 'sha256';

import instance from 'Services/instanceConfig';

const makeUrl = (activityIds: Readonly<number[]>, instanceId: string) => {
  const saltedInstanceId = sha256(`${instanceId}sparted42`);

  return `https://analytics.sparted.com/app?instance=${saltedInstanceId}&activityId=${activityIds}`;
};

export const redirectToActivityAnalytics = (activityIds: Readonly<number[]>) => {
  const url = makeUrl(activityIds, instance.instanceId);

  window.open(url, '_blank');
};
