import React from 'react';

import styles from 'Components/structural/STable/STable.style';

type TitleExplanationProps = {
  explanation?: string;
};

const TitleExplanation = ({ explanation }: TitleExplanationProps) => {

  return <h2 style={styles.headingExplanation}>{explanation}</h2>;
};

export default TitleExplanation;
