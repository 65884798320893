import m from 'mithril';
import reactCreator from 'components/react.creator';

import Enum from 'models/Enum';

import homepage from 'pages/homepage';
import login from 'pages/login';
import resetPassword from 'pages/reset.password';
import signup from 'pages/signup';
import updatePassword from 'pages/contributor.update.password';
import twoFactor from 'pages/twoFactor';

import courses from 'pages/courses';
import courseDetails from 'pages/course.details';
import knowledgeGrid from 'pages/knowledgeGrid';
import knowledge from 'pages/knowledge';
import content from 'pages/content';
import dolphinContents from 'pages/dolphin.contents';
import dolphinContent from 'pages/dolphin.content';
import dolphinActivityList from 'pages/dolphin.activity.list';
import translation from 'pages/translation';
import document from 'pages/document';

import activityList from 'pages/activity.list';
import dailyserieActivity from 'pages/activity.dailyserie';
import breakingnewsActivity from 'pages/activity.breakingnews';
import welcomeActivity from 'pages/activity.welcome';
import thatsAllFolksActivity from 'pages/activity.thatsAllFolks';
import interseasonActivity from 'pages/activity.interseason';

import analyticsLeaderboards from 'pages/analytics.leaderboards';
import analyticsDashboard from 'pages/analytics.dashboard';
import analyticsReports from 'pages/analytics.reports';
import analyticsNps from 'pages/analytics.nps';

import gaiaAmbassadors from 'pages/gaia.ambassadors';
import gaiaPlayers from 'pages/gaia.players';
import gaiaContributors from 'pages/gaia.contributors';
import segmentationTranslation from 'pages/gaia.translation.segmentation';
import signupTranslation from 'pages/gaia.translation.signuplabels';
import thematicTranslation from 'pages/gaia.translation.thematic';
import categoryTranslation from 'pages/gaia.translation.category';
import emailSectionTranslation from 'pages/gaia.translation.emailsection';
import segmentation from 'pages/gaia.segmentation';
import audience from 'pages/gaia.audience';
import gaiaThematics from 'pages/gaia.thematics';
import applicationConfiguration from 'pages/gaia.configuration.application';
import backOfficeConfiguration from 'pages/gaia.configuration.backoffice';
import gaiaSignup from 'pages/gaia.signup';
import gaiaEmailList from 'pages/gaia.email.list';
import authenticationSettings from 'pages/settings.authentication';
import gaiaGiftGrid from 'pages/gaia.giftGrid';
import gaiaGiftEdition from 'pages/gaia.gift.edition';
import gaiaCategories from 'pages/gaia.categories';

import { isFeatureEnabled } from 'services/feature-flags';

const AUDIENCE_CONFIGURATION_PAGE = 'audience-configuration-page';

const wrapRoute = (mithrilComponent) => ({
  view: () => m('.page-wrapper', [
    m(reactCreator, {
      component: 'AlertManager',
      props: {},
    }),
    mithrilComponent,
  ]),
});

export default {
  '/home': {
    component: homepage,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/login': {
    component: login,
    authorizedRoles: [
      Enum.Role.UNAUTHENTICATED,
    ],
  },
  '/reset_password': {
    component: resetPassword,
    authorizedRoles: [
      Enum.Role.UNAUTHENTICATED,
    ],
  },
  '/two-factor/:scope': {
    component: twoFactor,
    authorizedRoles: [
      Enum.Role.UNAUTHENTICATED,
    ],
  },
  '/activate/:token': {
    component: signup,
    authorizedRoles: [
      Enum.Role.UNAUTHENTICATED,
    ],
  },
  '/update_password/:token': {
    component: updatePassword,
    authorizedRoles: [
      Enum.Role.UNAUTHENTICATED,
    ],
  },
  '/dolphin-activity': {
    component: wrapRoute(dolphinActivityList),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/content': {
    component: wrapRoute(dolphinContents),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/content/:id': {
    component: wrapRoute(dolphinContent),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/courses': {
    component: wrapRoute(courses),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/course/:id/microknowledges': {
    component: wrapRoute(courseDetails),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/course/:id/audience': {
    component: wrapRoute(courseDetails),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
    ],
  },
  '/knowledge': {
    component: knowledgeGrid,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
      Enum.Role.TRANSLATOR,
    ],
  },
  '/knowledge/:id': {
    component: knowledge,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
      Enum.Role.TRANSLATOR,
    ],
  },
  '/knowledge/:knowledgeId/content/:id': {
    component: content,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
      Enum.Role.TRANSLATOR,
    ],
  },
  '/knowledge/:id/translation': {
    component: translation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.WRITER,
      Enum.Role.TRANSLATOR,
    ],
  },
  '/activity': {
    component: activityList,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/dailyserie/:id': {
    component: dailyserieActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/dailyserie/:id/scheduling': {
    component: dailyserieActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/dailyserie/:id/audience': {
    component: dailyserieActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/breakingnews/:id': {
    component: breakingnewsActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/welcome/:id': {
    component: welcomeActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/thatsAllFolks/:id': {
    component: thatsAllFolksActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/activity/interseason/:id': {
    component: interseasonActivity,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/analytics/leaderboards': {
    component: analyticsLeaderboards,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.ANALYST,
    ],
  },
  '/analytics/dashboard': {
    component: analyticsDashboard,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.ANALYST,
    ],
  },
  '/analytics/reports': {
    component: analyticsReports,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.ANALYST,
    ],
  },
  '/document': {
    component: document,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/players': {
    component: gaiaPlayers,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/ambassadors': {
    component: wrapRoute(gaiaAmbassadors),
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/contributors': {
    component: gaiaContributors,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/translation/segmentation': {
    component: segmentationTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/translation/signup': {
    component: signupTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
    ],
  },
  '/gaia/translation/thematic': {
    component: thematicTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/translation/category': {
    component: categoryTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/translation/email-section': {
    component: emailSectionTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/audience/groups': {
    component: audience,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/audience/segmentation/values': {
    component: segmentation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/audience/segmentation/translation': {
    component: segmentationTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
    ],
  },
  '/gaia/segmentation/values': {
    component: segmentation,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/segmentation/translation': {
    component: segmentationTranslation,
    authorizedRoles: [
      Enum.Role.MASTER,
    ],
  },
  '/gaia/thematics': {
    component: gaiaThematics,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/configuration/application': {
    component: applicationConfiguration,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/configuration/backoffice': {
    component: backOfficeConfiguration,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/signup/segmented': {
    component: wrapRoute(gaiaSignup),
    authorizedRoles: [
      Enum.Role.ADMIN,
      Enum.Role.MASTER,
    ],
  },
  '/gaia/signup/segmented/emailList/:id': {
    component: gaiaEmailList,
    authorizedRoles: [
      Enum.Role.ADMIN,
      Enum.Role.MASTER,
    ],
  },
  '/gaia/signup/old': {
    component: authenticationSettings,
    authorizedRoles: [
      Enum.Role.ADMIN,
      Enum.Role.MASTER,
    ],
  },
  '/gaia/gifts': {
    component: gaiaGiftGrid,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/gifts/:id': {
    component: gaiaGiftEdition,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/gaia/categories': {
    component: gaiaCategories,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
    ],
  },
  '/analytics/nps': {
    component: analyticsNps,
    authorizedRoles: [
      Enum.Role.MASTER,
      Enum.Role.ADMIN,
      Enum.Role.ANALYST,
    ],
  },
};
