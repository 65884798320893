import React, { useEffect } from 'react';
import { t } from 'i18next';

import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { CategoriesTable } from './components/CategoriesTable/CategoriesTable';
import { CategoryCreationModal } from './components/CategoryCreationModal/CategoryCreationModal';
import { CategoryDeletionModal } from './components/CategoryDeletionModal/CategoryDeletionModal';

import { CategoryListAlertView } from './CategoryListAlertView/CategoryListAlertView';

import { actions } from './redux';

import styles from './CategoryList.style';

const Page = () => {
  const dispatch = useTypedDispatch();
  const loading = useTypedSelector((state) => state.pages.categoryList.loading);

  useEffect(() => {
    const action = actions.fetch({}, true);

    dispatch(action);

    return action.cancel;
  }, [dispatch]);

  // reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  if (loading) {
    return <SPageLoaderWithTimer isLoading={loading} />;
  }

  return (
    <div style={styles.contentWrapper}>
      <div style={styles.subContentWrapper}>
        <div style={styles.pageHeader}>
          <h1 style={styles.pageTitle}>{t('config_categories:category_list.page_title')}</h1>
          <h4 style={styles.pageSubtitle}>{t('config_categories:category_list.page_subtitle')}</h4>
        </div>

        <div style={styles.categoryListWrapper}>
          <CategoriesTable />
        </div>
        <CategoryCreationModal />
        <CategoryDeletionModal />
      </div>
    </div>
  );
};

export const CategoryList = () => {
  return (
    <div style={styles.wrapper}>
      <CategoryListAlertView component={Page} />
    </div>
  );
};
