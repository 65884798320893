export type UButtonType = 'standard' | 'accentuated' | 'destructive' | 'validation' | 'warning' | 'standard-light';

export const BUTTON_TYPES = Object.freeze({
  STANDARD: 'standard',
  ACCENTUATED: 'accentuated',
  DESTRUCTIVE: 'destructive',
  VALIDATION: 'validation',
  WARNING: 'warning',
  STANDARD_LIGHT: 'standard-light',
});
