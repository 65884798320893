import Enum from 'models/Enum';
import Model from 'models/Model';

const { QUESTION, ANSWER, TITLE, LONG_TEXT, SHORT, TEXT, HELPER, INSTRUCTION, OC_ANSWER, QCM_ANSWER } = Enum.gameItemTypes;

const limitationByType = {
  [QUESTION]   : 150,
  [ANSWER]     : 60,
  [TITLE]      : 40,
  [TEXT]       : 500,
  [LONG_TEXT]  : 10000,
  [SHORT]      : 20,
  [INSTRUCTION]: 60,
  [HELPER]     : 30,
  [OC_ANSWER]  : 90,
};

var GameItem = {
  endpoint  : 'api/GameItems',
  attributes: {
    data: {
      type      : 'string',
      defaultsTo: '',
    },
    originData: {
      type: 'string',
    },
    type: {
      type : 'pointer',
      Model: 'GameItemType',
    },
    typeId: {
      type: 'number',
    },
    knowledgeId: {
      type: 'number',
    },
    originId: {
      type: 'number',
    },
  },
  methods: {
    getSizeLimitation: function getSizeLimitation() {
      return (limitationByType[this.typeId()] || 0);
    },
  },
  classMethods: {
    newWithType: function newWithType(_GameItem, types, type, args) {
      var gi = new _GameItem(args);

      gi.type(types.items.find(function findType(_type) {
        return _type.label() === type;
      }));
      gi.typeId(gi.type().id());

      return gi;
    },
    newWithTypeId: function newWithTypeId(_GameItem, types, typeId, args) {
      var gi = new _GameItem(args);

      gi.type(types.get(typeId));
      gi.typeId(typeId);

      return gi;
    },
  },
};

export default Model.register('GameItem', GameItem);
