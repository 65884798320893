// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  availableDocumentsTitle: {
    marginTop: '56px',
    marginBottom: '8px',
    ...TYPOGRAPHY.H1,
  },
  availableDocuments: {
    marginBottom: '32px',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.BLUE_GRAY_DARKER.DEFAULT,
  },
  listItem: {
    marginBottom: '16px',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '54px',
  },
};
