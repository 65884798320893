import { COLORS } from 'Components/foundation';
import { RefObject, useEffect, useRef, useState } from 'react';

type UseDragNDrop = {
  containerRef: RefObject<HTMLDivElement>;
  boxRef: RefObject<HTMLDivElement>;
  setAnswerZoneSize: (size: number) => void;
  answerZoneSize: number;
};

function createMagnifier(boxRef: RefObject<HTMLDivElement>, radius: number, x: number, y: number) {
  const magnifier = document.createElement('div');
  magnifier.style.position = 'relative';
  magnifier.style.left = `${x - radius}px`;
  magnifier.style.top = `${y - radius}px`;
  magnifier.style.width = `${radius * 2}px`;
  magnifier.style.height = `${radius * 2}px`;
  magnifier.style.borderRadius = '50%';
  magnifier.style.border = `3px solid ${COLORS.GREY_LIGHT.DEFAULT}`;
  boxRef.current?.firstChild?.remove();
  boxRef.current?.appendChild(magnifier);
  return magnifier;
}

function useDraggable(
  onPositionChange: (x: number, y: number) => void,
  initialSize?: number,
  initialX?: number,
  initialY?: number,
): UseDragNDrop {
  const isDraggingRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const coordinates = useRef({ x: initialX ?? 0, y: initialY ?? 0 });
  const [answerZoneSize, setAnswerZoneSize] = useState<number>(initialSize || 30);

  const onMouseDown = () => {
    if (boxRef.current === null) return;
    containerRef.current?.addEventListener('mousemove', mouseHandler);
    boxRef.current.style.cursor = 'grabbing';
    isDraggingRef.current = true;
  };

  const onMouseUp = () => {
    if (boxRef.current === null || !isDraggingRef.current) return;
    containerRef.current?.removeEventListener('mousemove', mouseHandler);
    boxRef.current.style.cursor = 'grab';
    isDraggingRef.current = false;
  };

  const mouseHandler = (e: MouseEvent) => {
    if (!boxRef.current || !containerRef.current || !e.target) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    boxRef.current.style.clipPath = `circle(${answerZoneSize}px at ${x}px ${y}px)`;

    (boxRef.current.firstChild! as HTMLDivElement).style.left = `${x - answerZoneSize}px`;
    (boxRef.current.firstChild! as HTMLDivElement).style.top = `${y - answerZoneSize}px`;
    coordinates.current = { x, y };
    if (!isDraggingRef.current) {
      onPositionChange(x, y);
    }
  };

  useEffect(() => {
    if (!containerRef.current || !boxRef.current) return;
    boxRef.current.style.clipPath = `circle(${answerZoneSize}px at ${coordinates.current.x}px ${coordinates.current.y}px)`;
    createMagnifier(boxRef, answerZoneSize, coordinates.current.x, coordinates.current.y);

    containerRef.current?.addEventListener('click', mouseHandler);
    boxRef.current?.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      containerRef.current?.removeEventListener('click', mouseHandler);
      boxRef.current?.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [answerZoneSize]);

  return {
    containerRef,
    boxRef,
    setAnswerZoneSize,
    answerZoneSize,
  };
}

export default useDraggable;
