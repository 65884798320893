/* eslint-disable complexity */
/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import Enum from 'models/Enum';
import Model from 'models/Model';
import deviceScreen from 'components/device.screen';
import mediaSelector from 'components/content.media.selector';
import contentHeader from 'components/content.header';
import inputQuestion from 'components/input.question';
import inputExplanationText from 'components/input.explanation.text';
import inputGameItem from 'components/input.game.item';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

var frontCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.QCM_FRONT,
};

var backCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.QCM_BACK,
};

component.controller = function controller(args) {
  var self = this;
  var QCMChoice = Model('QCMChoice');

  self.getAIConfig = function getAIConfig() {
    if (!args.aiEnabled())
      return null;

    const { game: qcm, id } = args.content();

    return {
      targetTexts: [
        qcm().question,
        qcm().explanation,
      ],
      contentId: id,
    };
  };

  self.isAnswer = function isAnswer(_args, choice) {
    return choice.rightAnswer();
  };

  self.toggleGoodAnswer = function toggleGoodAnswer(_args, choice) {
    if (args.editable())
      choice.rightAnswer(!choice.rightAnswer());
  };

  self.switchFormat = function switchFormat(_args, formatId) {
    if (args.editable()) {
      _args.content().game().formatId(formatId);
      self.formatAnswers(_args);
    }
  };

  self.formatAnswers = function formatAnswers(_args) {
    var qcm = _args.content().game();
    var MAX = 4;
    var nbChoices = qcm.getNbChoicesRequired();

    // remove other choices
    for (var i = nbChoices; i < MAX && qcm.qcmChoices().length > nbChoices; i++)
      qcm.qcmChoices().removeAt(qcm.qcmChoices().length - 1);

    // add Choices if needed
    for (var j = 0; j < nbChoices; j++) {
      if (!qcm.qcmChoices().at(j)) {
        var newChoice = new QCMChoice({
          qcmId   : qcm.id(),
          gameItem: {
            typeId     : Enum.gameItemTypes.ANSWER,
            knowledgeId: _args.knowledge().id(),
          },
        });

        qcm.qcmChoices().push(newChoice);
      }
    }
  };

  self.formatAnswers(args);

  if (!args.content().id())
    args.content().presetFromKnowledge(args.knowledge());
};

component.view = function view(c, args) {
  var qcm = args.content().game();

  return m('.content-edition', {
    config: c.configSize,
  }, [

    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:qcm.label'),
        gameplayName   : 'QCM',
        explanationText: args.content().getDirective(),
      }),
      m('.content-edition__page-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : qcm.imageFront,
              video    : qcm.videoFront,
              type     : qcm.mediaTypeFrontId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: frontCropProps,
              mediaPositionFront: true,
              getAIConfig: c.getAIConfig,
            }),
            m(inputQuestion, {
              gameItem   : qcm.question,
              placeholder: t('gameplays:qcm.question_placeholder'),
              editable   : args.editable(),
              maxFieldSize: 200,
            }),
            m('.content-edition__page__qcm-workspace', [
              displayFormats(c, args),
              displayChoices(c, args),
            ]),
          ]),
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : qcm.explanation,
              placeholder: t('gameplays:qcm.explanation_placeholder'),
              editable   : args.editable(),
            }),
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : qcm.imageBack,
              video    : qcm.videoBack,
              type     : qcm.mediaTypeBackId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: backCropProps,
              getAIConfig: c.getAIConfig,
            }),
          ]),
        ]),
      ]),
      m('.content-edition__commands', {
        class: !args.content().game().formatId() ? 'hidden' : '',
      }, [
        m('', {
          class: [
            args.editable() ? 'qcm-command__format' : 'qcm-command__format--disabled',
            'qcm-command__format--' + Enum.QCMFormat.twoSBS,
            qcm.formatId() === Enum.QCMFormat.twoSBS ? 'qcm-command__format--selected' : '',
          ].join(' '),
          onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.twoSBS),
        }),
        m('', {
          class: [
            args.editable() ? 'qcm-command__format' : 'qcm-command__format--disabled',
            'qcm-command__format--' + Enum.QCMFormat.twoUpDown,
            qcm.formatId() === Enum.QCMFormat.twoUpDown ? 'qcm-command__format--selected' : '',
          ].join(' '),
          onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.twoUpDown),
        }),
        m('', {
          class: [
            args.editable() ? 'qcm-command__format' : 'qcm-command__format--disabled',
            'qcm-command__format--' + Enum.QCMFormat.three,
            qcm.formatId() === Enum.QCMFormat.three ? 'qcm-command__format--selected' : '',
          ].join(' '),
          onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.three),
        }),
        m('', {
          class: [
            args.editable() ? 'qcm-command__format' : 'qcm-command__format--disabled',
            'qcm-command__format--' + Enum.QCMFormat.four,
            qcm.formatId() === Enum.QCMFormat.four ? 'qcm-command__format--selected' : '',
          ].join(' '),
          onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.four),
        }),
      ]),
    ]),
  ]);
};

function displayChoices(c, args) {
  var choices = args.content().game().qcmChoices();
  var allChoicesFilled = choices.items.every(function checkFilledData(choice) {
    return choice.gameItem().data().length;
  });

  return m('.qcm-workspace__choices', {
    class: !args.content().game().formatId() ? 'hidden' : '',
  }, choices.map(displayChoice));

  function displayChoice(choice) {
    return m('.qcm-workspace__choice', {
      class: 'qcm-workspace__choice--' + args.content().game().formatId(),
    }, [
      m.hide(!allChoicesFilled, '.qcm-workspace__choice__marker', {
        class: [
          c.isAnswer(args, choice) ? 'qcm-workspace__choice__marker--highlighted' : '', !args.editable() ? 'qcm-workspace__choice__marker--disabled' : '',
        ].join(' '),
        onclick: c.toggleGoodAnswer.bind(null, args, choice),
      }, []),
      m(inputGameItem, {
        gameItem    : choice.gameItem,
        placeholder : t('gameplays:qcm.choice_placeholder'),
        editable    : args.editable(),
        format      : args.content().game().formatId(),
        alwaysResize: true,
        maxFieldSize: 60,

      }),
    ]);
  }
}

function displayFormats(c, args) {
  return m('.qcm-workspace__formats', {
    class: args.content().game().formatId() ? 'hidden' : '',
  }, [
    m('.qcm-workspace__format.qcm-workspace__format--' + Enum.QCMFormat.twoSBS, {
      onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.twoSBS),
    }),
    m('.qcm-workspace__format.qcm-workspace__format--' + Enum.QCMFormat.twoUpDown, {
      onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.twoUpDown),
    }),
    m('.qcm-workspace__format.qcm-workspace__format--' + Enum.QCMFormat.three, {
      onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.three),
    }),
    m('.qcm-workspace__format.qcm-workspace__format--' + Enum.QCMFormat.four, {
      onclick: c.switchFormat.bind(null, args, Enum.QCMFormat.four),
    }),
  ]);
}

export default component;
