// @flow

/**
  * Display a modal that handle content translation
  *
  * Props:
  *   - style: custom style to override the modal's style
  *   - visible: if the modal should be displayed, by default false
  *   - contentTitle: title of the content displayed
  *   - contentId: id of the content displayed
  *   - languageFrom: language from which the content will be translated
  *   - languageTo: the language to which the content is to be translated
  *   - isLoading: boolean to define if the confirm button is loading
  *   - onClose: callback triggered on modal closing
  *   - onConfirm: callback triggered on click on the confirm button
 */

import * as React from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './MSingleLanguageTranslation.style';


type Props = {|
  style: Object,
  visible: boolean,
  isLoading: boolean,

  contentTitle: string,
  contentId: number,
  languageFrom: string,
  languageTo: string,

  onConfirm: () => void,
  onClose: () => void,
|};


export class MSingleLanguageTranslation extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const {
      visible,
      style,
      isLoading,
      onClose,
      onConfirm,
      contentTitle,
      contentId,
      languageFrom,
      languageTo,
    } = this.props;

    return (
      <MModal
        title={t('activities:modals.single_language_translation.title')}
        labelActionButton={t('activities:modals.single_language_translation.button')}
        labelSecondButton={t('activities:modals.single_language_translation.cancel')}
        onAction={onConfirm}
        onCloseModal={onClose}
        onSecondAction={onClose}
        visible={visible}
        isActionLoading={isLoading}
        style={{ ...styles.modal, ...style }}
        bodyStyle={styles.contentWrapper}
      >
        <p style={styles.content}>
          {t('activities:modals.single_language_translation.content', {
            contentTitle,
            contentId,
            languageFrom,
            languageTo,
          })}
        </p>
      </MModal>
    );
  }
}

export default MSingleLanguageTranslation;
