import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  roleInput: {
    marginTop: 23,
    zIndex: 1000,
  },
});

export default styleSheet;
