// @flow

import React, { useCallback } from 'react';
import MModal from 'Components/modal/MModal/MModal';

import styles from './ContentDeleteModal.style';

type Props = {|
  visible: boolean,
  onAction: (success: boolean) => mixed,
|};

export const ContentDeleteModal = ({ visible, onAction }: Props) => {
  const onValidate = useCallback(() => onAction(true), [onAction]);
  const onDismiss = useCallback(() => onAction(false), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title="Delete content"
      labelActionButton="Delete content"
      labelSecondButton="Cancel"
      actionButtonType="destructive"
    >
      <div style={styles.content}>
        {'Deleting this content will make it disappear from the list of '}
        {'content and may not be used in an activity. If this content is '}
        {'currently scheduled in an activity you will need to re-schedule this activity.'}
      </div>
    </MModal>
  );
};
