/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import deviceScreen from 'components/device.screen';
import mediaSelector from 'components/content.media.selector';
import contentHeader from 'components/content.header';
import inputQuestion from 'components/input.question';
import inputExplanationText from 'components/input.explanation.text';
import inputGameItem from 'components/input.game.item';
import toggleButton from 'components/toggle.button';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};
var frontCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.OPENQUESTION_FRONT,
};

var backCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.OPENQUESTION_BACK,
};

component.controller = function controller(args) {
  const self = this;

  self.formatAnswers = function formatAnswers(_args) {
    if (!_args.content().id())
      _args.content().presetFromKnowledge(_args.knowledge());
  };

  self.getAIConfig = function getAIConfig() {
    if (!args.aiEnabled())
      return null;

    const { game: openQuestion, id } = args.content();

    return {
      targetTexts: [
        openQuestion().question,
        openQuestion().explanation,
      ],
      contentId: id,
    };
  };

  self.formatAnswers(args);
};

component.view = function view(c, args) {
  var openQuestion = args.content().game();

  return m('.content-edition', {
    config: c.configSize,
  }, [

    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:open_question.label'),
        gameplayName   : 'OPEN_QUESTION',
        explanationText: args.content().getDirective(),
      }),
      m('.content-edition__page-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : openQuestion.imageFront,
              video    : openQuestion.videoFront,
              type     : openQuestion.mediaTypeFrontId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: frontCropProps,
              mediaPositionFront: true,
              getAIConfig: c.getAIConfig,
            }),
            m(inputQuestion, {
              gameItem   : openQuestion.question,
              placeholder: t('gameplays:open_question.question_placeholder'),
              editable   : args.editable(),
            }),
            m('.content-edition__page__open-question-workspace', [
              m('.content-edition__page__open-question__input-wrapper', [
                m(inputGameItem, {
                  gameItem   : openQuestion.answer,
                  placeholder: t('gameplays:open_question.answer_placeholder'),
                  editable   : args.editable(),
                }),
              ]),
            ]),
          ]),
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : openQuestion.explanation,
              placeholder: t('gameplays:open_question.explanation_placeholder'),
              editable   : args.editable(),
            }),
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : openQuestion.imageBack,
              video    : openQuestion.videoBack,
              type     : openQuestion.mediaTypeBackId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: backCropProps,
              getAIConfig: c.getAIConfig,
            }),
          ]),
        ]),

      ]),
      m('.content-edition__page__open-question__switch', [
        m(toggleButton, {
          disabled: !args.editable(),
          value   : function returnToogleValue() {
            return openQuestion.perfectMode();
          },
          onclick: function swapToogle() {
            return openQuestion.perfectMode(!openQuestion.perfectMode());
          },
          class: 'activity-info-toggle toggle__button__open-question',
        }),
        m('.content-edition__page__open-question__switch__label', [
          openQuestion.perfectMode()
          ?  t('gameplays:open_question.strict_match_enabled')
          : t('gameplays:open_question.strict_match_disabled'),
        ]),
      ]),
    ]),
  ]);
};

export default component;
