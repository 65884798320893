import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils/helpers';

import { ThematicEntity } from './thematic.types';

export const fetchThematic = createAsyncAction<ThematicEntity[]>('configuration/fetchThematic', () => {
  const { fetchFn } = fetchFactory<ThematicEntity[]>({
    route: '/api/Thematics/',
    method: 'GET',
  });
  return fetchFn();
});
