// @flow

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: COLORS.WHITE.DEFAULT,
    width: 18,
    height: 18,
    borderStyle: 'solid',
    position: 'relative',
  },
  wrapperCircle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    position: 'absolute',
    top: -1,
    left: -1,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: COLORS.WHITE.DEFAULT,
    borderStyle: 'solid',
  },
  selectedWrapper: {
    backgroundColor: COLORS.PRIMARY.DEFAULT,
  },
  unselectedWrapper: {
    backgroundColor: 'transparent',
  },
  unselectedContrastWrapper: {
    backgroundColor: COLORS.GREY_DARKER.DEFAULT,
    opacity: 0.25,
  },
  unselectedIdle: {
    opacity: 0.5,
  },
};
