import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  dailySessionContainer: {
    marginBottom: 16,
  },
  dailySessionContentCard: {
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 16,
  },
});

export default styles;
