import React, { useMemo } from 'react';

import type { CSSProperties } from 'react';
import { IconName } from 'Components/foundation/icons';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { UTextLink } from 'Components/unit/UTextLink/UTextLink';
import { UIcon } from 'Components/unit/UIcon/UIcon';
import UImage from 'Components/unit/UImage/UImage';
import { TrackingProps } from 'Services/trackingService';

import styles from './SDiscover.style';

export interface SDiscoverProps extends TrackingProps {
  image: string;
  titleIcon: IconName;
  title: string;
  description: string;
  linkText: string;
  linkHref: string;
  isCentered?: boolean;
  style?: CSSProperties;
}

/**
 * Discover card
 * Props:
 * - image: header of the card,
 * - titleIcon: icon at the left of the title,
 * - title: title of the card,
 * - description: small explanatory text,
 * - linkText: text replacing the link for clarity,
 * - linkHref: website url,
 * - isCentered?: text alignment,
 * - trackingAction?: tracking action
 * - trackingContext?: tracking context
 * - style?: CSSProperties.
 */
export const SDiscover = ({
  image,
  titleIcon,
  title,
  description,
  linkText,
  linkHref,
  isCentered = false,
  style = {},
  trackingAction,
  trackingContext,
}: SDiscoverProps) => {
  const trackingProps = useMemo<TrackingProps>(
    () =>
      trackingAction !== undefined && trackingContext !== undefined
        ? {
            trackingAction,
            trackingContext,
          }
        : {},
    [trackingAction, trackingContext],
  );
  const sDiscoverStyle = useMemo(
    (): CSSProperties => ({
      ...styles.wrapper,
      ...style,
    }),
    [style],
  );
  const sDiscoverTitleStyle = useMemo(
    (): CSSProperties => ({
      ...styles.content__title,
      justifyContent: isCentered ? 'center' : undefined,
    }),
    [isCentered],
  );
  const sDiscoverTextStyle = useMemo(
    (): CSSProperties => ({
      ...TYPOGRAPHY.BODY3,
      ...styles.content__text,
      textAlign: isCentered ? 'center' : undefined,
    }),
    [isCentered],
  );
  const sDiscoverLinkStyle = useMemo(
    (): CSSProperties => ({
      alignSelf: isCentered ? 'center' : 'flex-end',
    }),
    [isCentered],
  );
  return (
    <div style={sDiscoverStyle}>
      <div style={styles.header}>
        <UImage alt="s-discover" src={image} style={styles.header__image} />
      </div>
      <div style={styles.content}>
        <div id="s-discover-title" style={sDiscoverTitleStyle}>
          <UIcon name={titleIcon} size={20} color={COLORS.TEXT.SECONDARY_DEFAULT} />
          <h1 style={TYPOGRAPHY.HERO_S_SEMIBOLD}>{title}</h1>
        </div>
        <p id="s-discover-text" style={sDiscoverTextStyle}>
          {description}
        </p>
        <UTextLink
          text={linkText}
          link={linkHref}
          style={sDiscoverLinkStyle}
          colorOverride={COLORS.PRIMARY.DEFAULT}
          marginLeft={0}
          marginRight={0}
          rightIcon="external-link"
          {...trackingProps}
        />
      </div>
    </div>
  );
};

export default SDiscover;
