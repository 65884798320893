/* eslint-disable complexity */
/*
 * args = {
 *   entity         : the entity on which we read the segmentation
 *   index          : to support multiple component on the same page
 *   readOnly       : if the segmentation is editable or not
 *   onlyLanguage   : if the segmentation should only display the language dimension
 *   withoutLanguage: if the segmentation should skip the display of the language dimension
 *   languageUnicity: if there should be only one language selected
 *   oninput        : function called when selecting or unselecting an item
 *   placeholder    : default to "Click here to add a segmentation"
 *   searchMode     : boolean, true if segmentation input is used for filter false otherwise
 * }
 */

import m from 'm';
import { t } from 'i18next';
import Enum from 'models/Enum';
import App from 'models/App';
import Tree from 'models/Tree';
import Collection from 'models/Collection';
import Model from 'models/Model';
import User from 'services/userService';
import popover from 'components/popover';

var timeToSearch = 600;

var component = {};

var SegmentationGroupItem = Model('SegmentationGroupItem');

component.controller = function controller(args) {
  var self = this;
  var onMouseUp = mouseup.bind(null, args.index);

  var filteredSegmentation = App.userSegmentationItems();

  if (args.segmentationToHide) {
    filteredSegmentation = SegmentationGroupItem
      .filterOutSegmentation(filteredSegmentation, args.segmentationToHide());
    console.warn('[segmentation.input]', 'filteringSegmentation', filteredSegmentation);
  }

  var user = User.meSync();
  var all = new Tree(filteredSegmentation.toJSON());

  var selectedMap = {};
  var gisProposalMap = {};
  var entitySegmentation = args.entity && args.entity.segmentation && args.entity.segmentation();
  var itemsFromEntity = [];

  self.init = init;

  self.itemToGIByDimension = itemToGIByDimension;
  self.toDisplay = new Tree();
  self.tmp = [];
  self.gisProposal = [];
  self.inputValue = m.prop('');
  self.userSegmentation = user.segmentation;
  self.editionMode = m.prop(false);
  self.inputValue = m.prop('');
  self.lastInput = m.prop(new Date());
  self.alreadySelected = m.prop([]);
  self.loading = m.prop(false);
  self.editableSegs = m.prop(args.editableSegs || false);
  setTimeout(init.bind(null, args));

  function init(_args) {
    selectedMap = {};
    gisProposalMap = {};
    entitySegmentation = _args.entity && _args.entity.segmentation && _args.entity.segmentation();
    itemsFromEntity = [];

    self.itemToGIByDimension = itemToGIByDimension;
    self.toDisplay = all.clone(); // 489ms
    self.tmp = itemToGI(self.toDisplay); // 127ms
    self.gisProposal = [];
    self.inputValue = m.prop('');
    self.userSegmentation = user.segmentation;
    self.editionMode = m.prop(false);
    self.inputValue = m.prop('');
    self.lastInput = m.prop(new Date());
    self.alreadySelected = m.prop([]);
    self.loading = m.prop(false);

    if (entitySegmentation) {
      entitySegmentation.toJSON().forEach(function extractMembers(gi) {

        if (!gi.members || gi.members && !gi.members.length)
          console.warn('[segmentation]', 'No members found in the entity segmentation.');

        for (var i = 0, l = gi.members.length; i < l; i++) {
          var member = gi.members[i];
          var tempMembers = gi.members;

          // Avoid to clone the members because when there is a lot, the BO crashes
          delete gi.members;

          member.group = clone(gi);
          gi.members = tempMembers;

          if (args.withoutLanguage && member.group.dimension.typeId === Enum.dimensionTypes.LANGUAGE)
            continue;

          else if (!args.onlyLanguage || member.group.dimension.typeId === Enum.dimensionTypes.LANGUAGE)
            itemsFromEntity.push(member);
        }
      });
    }

    self.selected2 = new Tree(itemsFromEntity, initTree);

    function initTree(item, tree, map) {
      return !item.parentId || map[item.parentId];
    }

    computeGI(); // 1.2s
    m.redraw();
  }

  function addItemToGIs(itemTree) {
    itemTree.forEach(function iterate(item) {
      var clonedItem = clone(item);
      var group = gisProposalMap[item.groupId];

      if (clonedItem.group.label.toLowerCase().indexOf(self.inputValue().toLowerCase()) === -1)
        return;

      if (args.withoutLanguage && clonedItem.group.dimension.typeId === Enum.dimensionTypes.LANGUAGE)
        return;

      if (args.onlyLanguage && clonedItem.group.dimension.typeId !== Enum.dimensionTypes.LANGUAGE)
        return;

      if (group) {
        if (!group.membersMap[item.id]) {
          group.members.push(clonedItem);
          group.membersMap[item.id] = clonedItem;
        }
      } else {
        group = clone(item.group);
        group.members = [];
        group.membersMap = {};

        group.members.push(clonedItem);
        group.membersMap[item.id] = clonedItem;

        gisProposalMap[item.groupId] = group;
        gisProposalMap[item.groupId].i = self.gisProposal.length;
        self.gisProposal.push(group);
      }
    });
  }

  function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function itemToGIByDimension(items) {
    var dimensions = [];
    var dimensionsMap = {};

    items = items || [];

    items.forEach(function iterate(item) {
      var dimensionId = item.group.dimensionId;

      if (!dimensionsMap[dimensionId]) {
        dimensionsMap[dimensionId] = {
          data     : item.group.dimension,
          groups   : [],
          groupsMap: {},
        };
        dimensions.push(dimensionsMap[dimensionId]);
      }
      itemToGIInDimension(dimensionsMap[dimensionId], item);
    });

    return dimensions;
  }

  function itemToGIInDimension(dimension, item) {
    var groups = dimension.groups;
    var groupsMap = dimension.groupsMap;
    var groupId = item.groupId;

    if (groupsMap[groupId])
      groupsMap[groupId].members.push(item);
    else {
      groupsMap[groupId] = clone(item.group);
      groupsMap[groupId].members = groupsMap[groupId].members || [];
      groupsMap[groupId].members.push(item);
      groups.push(groupsMap[groupId]);
    }
  }

  function itemToGI(itemTree) {
    var gis = [];
    var gisObj = {};

    itemTree.forEach(function iterate(item) {
      if (gisObj[item.groupId])
        gisObj[item.groupId].members.push(item);
      else {
        gisObj[item.groupId] = JSON.parse(JSON.stringify(item.group));
        gisObj[item.groupId].members = gisObj[item.groupId].members || [];
        gisObj[item.groupId].members.push(item);
        gis.push(gisObj[item.groupId]);
      }
    });

    return gis;
  }

  self.display = function display() {
    var gis = [];
    var gisMap = {};

    self.tmp.forEach(function iterateToDisplay(item) {
      var clonedItem = clone(item);
      var group = gisMap[item.groupId];

      if (group)
        group.members.push(clonedItem);
      else {
        group = clone(item.group);
        group.members = [];
        group.members.push(clonedItem);
        gis.push(group);
        gisMap[item.groupId] = group;
      }
    });

    return gis;
  };

  self.addGI = function addGI(gi) {
    var branch = null;

    self.loading(true);
    self.editionMode(false);
    m.redraw();
    m.startComputation();

    setTimeout(function () {
      for (var i = 0, l = gi.members.length; i < l; i++) {
        branch = all.getBranch(gi.members[i].id);

        if (args.languageUnicity && branch.tree.children[0].group.dimension.typeId === Enum.dimensionTypes.LANGUAGE) {
          for (var j = 0, len = self.selected2.tree.children.length, pursue = true; j < len && pursue; j++) {
            if (self.selected2.tree.children[j].group.dimension.typeId === Enum.dimensionTypes.LANGUAGE
              && self.selected2.tree.children[j].groupId !== branch.tree.children[0].groupId) {
              pursue = false;
              self.selected2.remove(self.selected2.tree.children[j]);
            }
          }
        }
        self.selected2.merge(branch);
        self.selected2.mergeConditional(all.subTree(gi.members[i].id), addOrNot);
      }

      function addOrNot(item) {
        var toAdd = false;

        self.selected2.forEach(function sameGroup(current) {
          if (current.groupId === item.groupId)
            toAdd = true;
        });

        return toAdd;
      }

      computeGI();
      args.entity.segmentation(new Collection(SegmentationGroupItem, itemToGI(self.selected2)));
      if (args.oninput)
        args.oninput();
      self.loading(false);
      self.editionMode(false);
      m.endComputation();
    }, 0);
  };

  self.removeGI = function removeGI(gi, e) {
    e.stopPropagation();
    self.loading(true);
    m.redraw();
    m.startComputation();

    setTimeout(function () {
      self.selected2.remove(gi.members);
      computeGI();
      args.entity.segmentation(new Collection(SegmentationGroupItem, itemToGI(self.selected2)));
      if (args.oninput)
        args.oninput();
      self.loading(false);
      self.editionMode(false);
      m.endComputation();
    }, 0);
  };

  self.removeDimension = function removeDimension(dimension, e) {
    var members = [];

    for (var i = 0, l = dimension.groups.length; i < l; i++)
      members = members.concat(dimension.groups[i].members);

    self.removeGI({
      members: members,
    }, e);
  };

  function computeGI() {
    self.toDisplay = all.clone(); // 500ms
    self.gisProposal = [];
    gisProposalMap = {};

    self.selected2
      .forEach(function removeChildrenBrother(_item) {
        var brothers = null;
        var item = all.get(_item.id);

        if (!item) {
          console.warn('[segmentation.input]', '#', _item.id, 'Not found.', _item);

          return;
        }

        for (var i = 0, l = item.children.length; i < l; i++) {
          if (!self.selected2.get(item.children[i].id))
            addItemToGIs(all.subTree(item.children[i]));
        }

        if (!self.toDisplay.get(item.id))
          return;

        brothers = self.toDisplay
          .getBrothers(item.id)
          .filter(function filter(bro) {
            return bro.group.dimensionId === item.group.dimensionId
              && !self.selected2.get(bro.id);
          });

        for (var j = 0, len = brothers.length; j < len; j++)
          self.toDisplay.remove(brothers[j].children);

        self.toDisplay.remove(item.id);
      });
    addItemToGIs(self.toDisplay); // 843ms
  }

  function add(item) {
    var group = item.group;

    if (!selectedMap[group.id]) {
      args.entity.segmentation().push(new SegmentationGroupItem(group));

      // self.selected2.push(group);
      selectedMap[group.id] = true;
    }

    if (item.parentId)
      add(all.get(item.parentId));
  }

  self.removeTag = function removeTag(gi) {
    if (args.readOnly)
      return;
    args.entity.segmentation().searchAndRemove({
      id: gi.id(),
    });
    deleteChildren(gi);
    self.search();
    if (args.oninput)
      args.oninput();
  };

  function deleteChildren(group) {
    var potentialChildren = args.entity.segmentation().items.filter(function isInChildDimension(gr) {
      return gr.dimension().parentId === group.dimensionId();
    });

    var children = potentialChildren.filter(function isChildren(gr) {
      var result = false;

      gr.members().forEach(function isCh(item) {
        if (group.members().find({
          id: item.parentId(),
        }))
          result = true;
      });

      return result;
    });

    children.forEach(function remove(ch) {
      args.entity.segmentation().searchAndRemove({
        id: ch.id(),
      });
      deleteChildren(ch);
    });
  }

  self.addTag = function addTag(gi) {
    var already = args.entity.segmentation().find({
      id: gi.id(),
    });

    if (already.items.length)
      return;

    if (args.languageUnicity && gi.dimension().typeId === Enum.dimensionTypes.LANGUAGE) {
      var list = args.entity.segmentation().filter(function isALanguage(g) {
        return g.dimension().typeId === Enum.dimensionTypes.LANGUAGE;
      });

      list.forEach(function deleteFromInput(g) {
        args.entity.segmentation().searchAndRemove({
          id: g.id(),
        });
      });
    }

    gi.getParentGroups()
      .then(function pushParents(parents) {
        parents.forEach(function addItOrNot(p) {
          var isAlready = args.entity.segmentation().find({
            id: p.id(),
          });

          if (isAlready.items.length)
            return;
          args.entity.segmentation().push(p);
        });
        args.entity.segmentation().push(gi);
        self.search();
        if (args.oninput)
          args.oninput();
      });
  };

  self.switchEditionMode = function switchEditionMode() {
    var selector = '#segmentationInput' + (args.index || '');

    if (!self.editionMode() && !args.readOnly) {
      self.editionMode(true);
      m.redraw();
    }

    setTimeout(function givefocus() {
      document.querySelector(selector).focus();
    }, 0);
  };

  self.newInput = function newInput(value) {
    self.inputValue(value);
    self.lastInput(new Date());
    self.loading(true);

    setTimeout(function searchIfTime() {
      var now = new Date();

      if (now.getTime() - self.lastInput().getTime() >= timeToSearch) {
        computeGI();
        self.alreadySelected(args.entity.segmentation().toJSON().filter(testGroup));
        self.loading(false);
        m.redraw();
      }

      return;
    }, timeToSearch);
  };

  function testGroup(group) {
    console.log(group);
    var regexp = new RegExp(self.inputValue(), 'i');

    return regexp.test(group.label);
  }

  self.search = function search() {
    return SegmentationGroupItem.getConvenients(args.entity.segmentation(), self.inputValue())
      .then(function displayResults(res) {
        self.searchResults(res);
        self.alreadySelected(args.entity.segmentation().items.filter(testGroup));
        m.redraw();
      });
  };

  self.configInput = function configInput(elem, isInit, ctx) {
    if (isInit)
      return;

    document.addEventListener('mouseup', onMouseUp);
  };

  self.onunload = function onunload() {
    document.removeEventListener('mouseup', onMouseUp);
  };

  function mouseup(index, e) {
    var selector = '#segmentationInputContainer' + (index || '');
    var container = document.querySelector(selector);

    if (!container.contains(e.target)) {
      self.editionMode(false);
      m.redraw();
    }
  }

  // if (!args.readOnly)
  //   self.search()
  //   .then(function () {
  //     console.log('SEARCH', self.searchResults().length);
  //   });
};

component.view = function view(c, args) {
  if (args.refresh && args.refresh()) {
    console.log('[segmentation]', 'component is refreshing...');
    c.init(args);
    args.refresh(false);
    m.redraw();
  }

  return m('.segmentation-input', {
    id     : 'segmentationInputContainer' + (args.index || ''),
    key    : 'segmentationInputContainer' + (args.index || ''),
    onclick: c.switchEditionMode,
    config : c.configInput,
    class  : args.readOnly ? 'segmentation-input--readonly' : '',
  }, [
    displaygroups(),
    displayResults(),
    displayLocker(),
  ]);

  function displayLocker() {
    if (c.editableSegs())
      return null;

    return m('.segmentation-input__locker', {
      class: c.userSegmentation().length ? '' : 'hide',
    }, [
      m(popover, {

      }, [
        m('.popover__txt', [
          t('mithril_components:segmentation_input.user_rights_are_restricted_to') + ' ',
          c.userSegmentation().map(function renderUserSegmentation(gi, i) {
            return [
              m('.popover__highlight', gi.label()),
              c.userSegmentation().length - 1 === i ? '' : ', ',
            ];
          }),
        ]),
      ]),
    ]);

  }

  function displaygroups() {
    var placeholderShouldBeVisible = !c.editionMode()
      && !c.itemToGIByDimension(c.selected2).length;

    // args.entity.segmentation().sort(orderGroups);

    return m('', {
      class: c.userSegmentation().length ? 'segmentation-input__tag-and-placeholder' : '',
    }, [
      m.hide(!placeholderShouldBeVisible, '.segmentation-input__placeholder', args.readOnly
        ? t('mithril_components:segmentation_input.all_segmentation')
        : (args.placeholder || t('mithril_components:segmentation_input.click_here_to_add_a_segmentation'))),
      m.hide(placeholderShouldBeVisible, '.segmentation-input__tag-container', [

        // args.entity.segmentation().map(displaySegmentationTag),
        c.itemToGIByDimension(c.selected2).sort(function sortByDimension(a, b) {
          return a.data.order - b.data.order;

          // return a.dimension.order - b.dimension.order || ((a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1);
        }).map(displayGroupsByDimension),
        displayInput(),
      ]),
    ]);
  }

  function displayGroupsByDimension(dimension) {
    var editable = true;
    var segmentationItemIds = [];

    if (dimension.groups.length > 5) {
      return m('.segmentation-dimension', {
        style: {
          backgroundColor: dimension.data.color || '#52afdf',
        },
      }, [
        m('.segmentation-dimension__label', [dimension.groups.length, dimension.data.label].join(' ')),
        editable
          ? m('.segmentation-dimension__delete', {
            onclick: c.removeDimension.bind(null, dimension),
          }) : '',
        m('.segmentation-dimension__groups', dimension.groups
          .sort(function sortByAlphabeticalOrder(a, b) {
            return (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1;
          })
          .map(displaySegmentationTag.bind(null, true))
        ),
      ]);
    }

    return dimension.groups
      .sort(function sortByAlphabeticalOrder(a, b) {
        return (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1;
      })
      .map(displaySegmentationTag.bind(null, false));
  }

  function displaySegmentationTag(inDimension, gi) {
    var editable = !c.userSegmentation().get(gi.id) || c.editableSegs();
    var brothers = [];

    for (var i = 0, l = gi.members.length; i < l && !editable; i++) {
      brothers = c.selected2.getBrothers(gi.members[i].id);

      for (var _i = 0, _l = brothers.length; _i < _l && !editable; _i++) {
        if (brothers[_i].dimensionId === gi.dimensionId)
          editable = true;
      }
    }
    if (args.searchMode)
      editable = true;

    return m('.segmentation-tag', {
      class: inDimension ? 'segmentation-tag--inDimension' : '',
      style: {
        backgroundColor: gi.dimension.color || '#52afdf',
      },
      title  : gi.dimension.label,
      onclick: function (e) {
        e.stopPropagation();
      },
    }, [
      m('.segmentation-tag__label', !gi.members.length || gi.members.length > 1 ? gi.label : gi.members[0].label),
      m.hide(!editable, '.segmentation-tag__delete', {
        onclick: c.removeGI.bind(null, gi),
      }),
    ]);
  }

  function displayInput() {
    return m.hide(!c.editionMode(), 'input.segmentation-input__search', {
      id     : 'segmentationInput' + (args.index || ''),
      key    : 'segmentationInput' + (args.index || ''),
      value  : c.inputValue(),
      oninput: m.withAttr('value', c.newInput),
    });
  }

  function displayResults() {
    return m.hide(!c.editionMode(), '.segmentation-input__result-container', [
      m.hide(!c.loading(), '.segmentation-input__loader', [
        t('mithril_components:segmentation_input.loading_please_wait'),
        m('br'),
        t('mithril_components:segmentation_input.depending_on_your_segmentation_complexity_it_can_take_a_few_minutes'),
      ]),
      m.hide(c.loading(), '.segmentation-input__result-container__to-add', c.gisProposal
        .sort(function sortByDimension(a, b) {
          return a.dimension.order - b.dimension.order || ((a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1);
        })
        .map(function displayGI(gi) {
          return m('.segmentation-tag.segmentation-tag--to-add', {
            style: {
              borderColor: gi.dimension.color,
              color      : gi.dimension.color,
            },
            title  : gi.dimension.label,
            onclick: c.addGI.bind(null, gi),
          }, ' + ' + (!gi.members.length || gi.members.length > 1 ? gi.label : gi.members[0].label));
        })
      ),
      m.hide(!(c.alreadySelected().length && c.inputValue().length), '.segmentation-input__result-container__added', [
        m('.result-container__added__title', 'Already selected'),
        c.alreadySelected().map(displaySegmentationTag.bind(null, false)),
      ]),
    ]);
  }

  function getLabelToDisplay(group) {
    var item = getSpecificItemInGroup(group);

    if (!item)
      return group.label();

    return item.label();
  }

  function getSpecificItemInGroup(group) {
    if (!group.dimension().parentId)
      return undefined;
    if (!args.entity.segmentation().find({
      dimensionId: group.dimension().parentId,
    }).length)
      return undefined;
    if (!args.entity.segmentation().find({
      dimensionId: group.dimension().parentId,
    }).length > 1)
      return undefined;

    var ancestorGroup = args.entity.segmentation().find({
      dimensionId: group.dimension().parentId,
    }).at(0);
    var ancestor = getSpecificItemInGroup(ancestorGroup);

    if (!ancestor)
      return undefined;

    return group.members().find({
      parentId: ancestor.id(),
    });
  }
};

export default component;
