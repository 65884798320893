import { mergeDeep } from 'Libs/mergeDeep';

import createDailySerieContent from './DailySerieContent';
import type { DailySerieContentType } from './DailySerieContent';

export type DailySerieDayType = Readonly<{
  key: number;
  dailySerieContents: ReadonlyArray<DailySerieContentType>;
}>;

export default function createDailySerieDay(values?: any): DailySerieDayType {
  const defaultState: DailySerieDayType = {
    key: 0,
    dailySerieContents: [],
  };

  if (!values) {
    return defaultState;
  }

  const dailySerieContents = values.dailySerieContents.map((dsContent: Partial<DailySerieContentType>, i: number) => ({
    ...createDailySerieContent(dsContent),
    key: i,
  }));

  return mergeDeep(defaultState, { ...values, dailySerieContents });
}
