// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display a container
 *
 * Props:
 *  - label: Text displayed on top
 *  - style: Override component's style
 *  - children: Child of this component
 *
 */

import * as React from 'react';

import styles from './SPanelSection.style';


type Props = {|
  style: Object,
  label: string,
  children: React.Node,
|};

export class SPanelSection extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, children, label } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <span style={styles.label}>
          {label}
        </span>
        {children}
      </div>
    );
  }
}

export default SPanelSection;
