import React, { type CSSProperties, type FC } from 'react';

import styles from './UDropBar.style';

export type UDropBarProps = {
  style?: CSSProperties;
};

export const UDropBar: FC<UDropBarProps> = ({ style }) => {
  return <div style={{ ...styles.wrapper, ...style }} />;
};
