import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    gap: 24,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  backButton: {
    paddingTop: 21,
    paddingLeft: 21,
  },
  blockTitle: {
    ...TYPOGRAPHY.BODY4_BOLD,
    paddingLeft: 4,
  },
  radioBlock: {
    width: 202,
  },
  commentBlock: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  comment: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  notice: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  languageSelect: {
    zIndex: 5,
  },
});
