import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: 55,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto auto auto',
    alignItems: 'center',
    borderBottom: '1px #E8E8E8 solid',
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 10,
  },
  logo: {
    gridColumn: 1,
    height: '100%',
    width: 160,
    cursor: 'inherit !important',
    padding: '0 20px',
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
  },
  internalLinkContainer: {
    gridColumn: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  internalLink: {
    fontSize: 15,
    fontWeight: 700,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    padding: '0 16px',
  },
  activeInternalLink: {
    color: '#348FFC',
  },
  spacer: {
    width: 1,
    height: 39,
    background: '#EDEDED',
    marginLeft: 12,
    marginRight: 12,
  },
  externalLinkContainer: {
    gridColumn: 4,
    display: 'flex',
  },
  externalLink: {
    fontSize: 15,
    textTransform: 'none',
    fontWeight: 700,
    display: 'flex',
    marginLeft: 6,
    marginRight: 6,
  },
  languageItem: {
    marginLeft: 4,
  },
  languageButton: {
    position: 'relative',
    margin: '0 8px 0 6px',
    fontSize: 15,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  profileItem: {
    fontSize: 15,
    textTransform: 'uppercase',
    fontWeight: 700,
    position: 'relative',
  },
  profileButton: {
    position: 'relative',
    margin: '0 22px 0 16px',
  },
  dropDownMenu: {
    position: 'absolute',
    top: 49,
    right: 24,
  },
  userDropdownBox: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
});
