import Model from 'models/Model';

var Contribution = {
  endpoint  : 'api/Contribution/full',
  attributes: {
    date: {
      type: 'date',
    },
    type: {
      type : 'pointer',
      Model: 'ContributionType',
    },
    player: {
      type : 'pointer',
      Model: 'Player',
    },
  },
};

export default Model.register('Contribution', Contribution);
