import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './ArchiveModal.style';

type Props = {
  visible: boolean;
  onAction: (success: boolean) => void;
  isArchiveLoading: boolean;
};

export const ArchiveModal = ({ visible, onAction, isArchiveLoading }: Props) => {
  const onDismiss = useCallback(() => onAction(false), [onAction]);
  const onValidate = useCallback(() => onAction(true), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('activities:modals.archive.title')}
      labelActionButton={t('activities:modals.archive.button')}
      labelSecondButton={t('activities:modals.archive.cancel')}
      actionButtonType="destructive"
      isActionLoading={isArchiveLoading}
    >
      <div style={styles.content}>{t('activities:modals.archive.content')}</div>
    </MModal>
  );
};
