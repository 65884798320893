import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  text: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 24,
  },
  buttonWrapper: {
    fontWeight: 400,
  },
  callToActionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};
