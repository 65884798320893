import m from 'm';

import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

var component = {};

component.controller = function controller() {
  var self = this;

  self.id = m.route.param('id');
  self.tabIndex = m.prop(0);
};

component.view = function view(c) {
  return m('.activity-page', [
    m('.header', [
      m(menu),
    ]),
    m(reactCreator, {
      component: 'Activity',
      style    : { height: '100%' },
      props    : {
        id      : c.id,
        tabIndex: c.tabIndex,
      },
    }),
  ]);
};

export default component;
