import React, { useState, useCallback, useEffect, useMemo, useRef, FC } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { generateCode } from 'Libs/audienceManager';

import SCodeInput from 'Components/structural/SCodeInput/SCodeInput';
import SArticle from 'Components/structural/SArticle/SArticle';

import { actions, useModuleSelector } from '../redux';
import styles from './GlobalCodeSection.style';

const COPY_TIME = 1000;
const SAVE_TIME = 600;

export type GlobalCodeSectionProps = Record<string, never>;
export const GlobalCodeSection: FC<GlobalCodeSectionProps> = () => {
  const globalCode = useModuleSelector((state) => state.globalCode);
  const originalGlobalCode = useModuleSelector((state) => state.originalGlobalCode);
  const isGetGlobalCodeLoading = useModuleSelector((state) => state.isGetGlobalCodeLoading);
  const isSetGlobalCodeFailure = useModuleSelector((state) => state.isSetGlobalCodeFailure);
  const globalCodeDoesNotExist = useModuleSelector((state) => state.globalCodeDoesNotExist);
  const isSetGlobalCodeLoading = useModuleSelector((state) => state.isSetGlobalCodeLoading);
  const dispatch = useTypedDispatch();
  const saveTimeout = useRef<NodeJS.Timeout | null>(null);
  const [codeChangeFeedback, setCodeChangeFeedback] = useState({ status: 'saved', message: 'Magic Code saved' });
  const [simulatedLoading, setSimulatedLoading] = useState(false);
  const inputRefs = useRef({});
  const [currentInputValue, setCurrentInputValue] = useState('');

  useEffect(() => {
    const action = actions.getGlobalCode();

    dispatch(action);

    return action.cancel;
  }, [dispatch]);

  useEffect(() => {
    if (!globalCodeDoesNotExist) return () => {};

    const newGlobalCode = generateCode();
    const action = actions.setGlobalCode(newGlobalCode);

    dispatch(action);

    return action.cancel;
  }, [globalCodeDoesNotExist, dispatch]);

  const [buttonText, setButtonText] = useState(t('config_ambassadors:global_code_section.button_text'));
  const onButtonClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(currentInputValue);
      setButtonText(t('config_ambassadors:global_code_section.set_button_text'));
      setTimeout(() => setButtonText(t('config_ambassadors:global_code_section.button_text')), COPY_TIME);
    } catch (err) {
      console.error('Failed to copy text to clipboard', err);
    }
  }, [currentInputValue, t]);

  const saveGlobalCode = useCallback(
    (code) => {
      if (!isGetGlobalCodeLoading && !globalCode.trim()) return;

      setSimulatedLoading(true);
      saveTimeout.current = setTimeout(() => {
        setSimulatedLoading(false);

        dispatch(actions.setGlobalCode(code));
      }, SAVE_TIME);
    },
    [dispatch, globalCode, isGetGlobalCodeLoading],
  );

  const onIconClick = useCallback(() => {
    const code = generateCode();

    if (saveTimeout.current !== null) {
      clearTimeout(saveTimeout.current);
    }
    dispatch(actions.editGlobalCode(code));
    saveGlobalCode(code);
  }, [dispatch, saveGlobalCode]);

  const onBlur = useCallback(() => {
    if (!globalCode.trim()) {
      dispatch(actions.editGlobalCode(originalGlobalCode));
      if (saveTimeout.current !== null) {
        clearTimeout(saveTimeout.current);
      }
      return;
    }
  }, [originalGlobalCode, globalCode, dispatch]);

  const onInputChange = useCallback(
    (value) => {
      if (saveTimeout.current !== null) {
        clearTimeout(saveTimeout.current);
      }
      dispatch(actions.editGlobalCode(value));

      // Update the input value state
      setCurrentInputValue(value);

      if (!value.trim()) return;

      saveGlobalCode(value);
    },
    [dispatch, saveGlobalCode],
  );

  const shouldShowLoading = useMemo(() => {
    return simulatedLoading || isSetGlobalCodeLoading || globalCode !== originalGlobalCode;
  }, [globalCode, isSetGlobalCodeLoading, originalGlobalCode, simulatedLoading]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isSetGlobalCodeFailure) setCodeChangeFeedback({ status: 'none', message: '' });
    else if (!isGetGlobalCodeLoading && !globalCode.trim())
      setCodeChangeFeedback({
        status: 'warning',
        message: t('config_ambassadors:global_code_section.status.warning'),
      });
    else if (shouldShowLoading)
      setCodeChangeFeedback({
        status: 'loading',
        message: t('config_ambassadors:global_code_section.status.loading'),
      });
    else if (globalCode === originalGlobalCode)
      setCodeChangeFeedback({
        status: 'saved',
        message: t('config_ambassadors:global_code_section.status.saved'),
      });
  }, [
    globalCode,
    originalGlobalCode,
    isGetGlobalCodeLoading,
    isSetGlobalCodeLoading,
    isSetGlobalCodeFailure,
    shouldShowLoading,
    t,
  ]);

  return (
    <SArticle
      title={t('config_ambassadors:global_code_section.title')}
      subtitle={t('config_ambassadors:global_code_section.subtitle')}
      style={styles.wrapper}
    >
      <SCodeInput
        value={globalCode}
        onButtonClick={onButtonClick}
        onIconClick={onIconClick}
        onInputChange={onInputChange}
        buttonText={buttonText}
        inputGroupWidth={240}
        codeChangeFeedbackStatus={codeChangeFeedback.status}
        codeChangeFeedbackMessage={codeChangeFeedback.message}
        onBlur={onBlur}
      />
    </SArticle>
  );
};
