import { createEntityAdapter } from '@reduxjs/toolkit';

import { State } from 'Libs/redux/types';

import { ThematicEntity } from './thematic.types';

export const thematicAdapter = createEntityAdapter<ThematicEntity>({});

export const { selectAll, selectById, selectEntities, selectIds, selectTotal } = thematicAdapter.getSelectors();

const isNotUndefined = <T>(value: T | undefined): value is T => value !== undefined;

export const getAllThematics = (state: State): ThematicEntity[] => {
  return Object.values(state.entities.thematic.entities)
    .filter(isNotUndefined)
    .filter((thematic) => thematic.archived === false);
};
