/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import Enum from 'models/Enum';
import Model from 'models/Model';
import App from 'models/App';
import popup from 'services/popupService';
import deviceScreen from 'components/device.screen';
import contentHeader from 'components/content.header';
import mediaSelectorPictureSpot from 'components/content.media.selector.picture.spot';
import pictureSpotOverlay from 'components/picture.spot.overlay';
import inputExplanationText from 'components/input.explanation.text';
import reactCreator from 'components/react.creator';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

var MAX_SPOT_NUMBER = 3;

component.controller = function controller(args) {
  var self = this;
  var PictureSpotItem = Model('PictureSpotItem');
  var GameItem = Model('GameItem');

  self.isImagePopupVisible = m.prop(false);

  self.openImagePopup = function openImagePopup() {
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.isImagePopupVisible(false);
    m.redraw();
  };

  self.selectedSpotIndex = m.prop();

  if (args.content().game().spots().length)
    self.selectedSpotIndex(0);

  if (!args.content().id())
    args.content().presetFromKnowledge(args.knowledge());

  if (!args.content().id())
    args.content().presetFromKnowledge(args.knowledge());

  self.createSpot = function createSpot(_args) {
    var selectedSpot = args.content().game().spots().at(self.selectedSpotIndex());
    var newSpot = new PictureSpotItem({
      x: 0,
      y: 0,
      r: 20,
    });

    newSpot.question(self.newGameItem('Question', '', (newSpot || selectedSpot).question));
    newSpot.description(self.newGameItem('Answer', '', (newSpot || selectedSpot).description));
    _args.content().game().spots().push(newSpot);

    self.selectedSpotIndex(_args.content().game().spots().length - 1);
    m.redraw();
  };

  self.newGameItem = function newGameItem(type, text) {
    return GameItem.newWithType(App.gameItemTypes(), type, {
      data       : text,
      knowledgeId: args.content().knowledgeId(),
    });
  };

  self.tryDeletingSpot = function tryDeletingSpot(_args) {
    popup.open({
      type         : 'leaveEditionConfirmation',
      title        : t('gameplays:picture_spot.delete_question'),
      content      : t('gameplays:picture_spot.delete_question_confirmation'),
      confirmButton: t('gameplays:picture_spot.delete'),
      save         : self.deleteSpot.bind(null, _args),
      dontSave     : closePopup.bind(null),
    });
  };

  function closePopup() {
    popup.close();
  }

  self.deleteSpot = function deleteSpot(_args) {
    _args.content().game().spots().removeAt(self.selectedSpotIndex());
    if (_args.content().game().spots().length)
      self.selectedSpotIndex(0);
    m.redraw();
    closePopup();
  };

  self.linkImage = function linkImage(_args, image) {
    return _args.knowledge().linkImage(image.id())
      .then(function addImageToKnowledge(_image) {
        _args.knowledge().images().push(_image);
      });
  };

  self.addCropPicture = function addCropPicture(_args, image) {
    self.linkImage.bind(_args, image);
    _args.content().game().image(image);
    self.closeImagePopup();
  };
};

component.view = function view(c, args) {
  var pictureSpot = args.content().game();
  var selectedSpot = args.content().game().spots().at(c.selectedSpotIndex());

  var imagePopup =  m(reactCreator, {
    component: 'MImageCrop',
    props    : {
      onCancel        : c.closeImagePopup,
      onSave          : c.addCropPicture.bind(null, args),
      cropProps       : EnumMedia.PICTURESPOT,
    },
  });

  return m('.content-edition', [
    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:picture_spot.label'),
        gameplayName   : 'PICTURE_SPOT',
        explanationText: args.content().getDirective(),
      }),
      m('.content-edition__page__picture-spot-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            m(mediaSelectorPictureSpot, {
              images          : args.knowledge().originalImages,
              image           : pictureSpot.image,
              type            : m.prop(Enum.mediaType.Image),
              actionAddPicture: c.linkImage.bind(null, args),
              onImageEdit     : c.openImagePopup,
            }),
            m.hide(!pictureSpot.image, pictureSpotOverlay, {
              key         : selectedSpot ? selectedSpot._uuid : 'new',
              pictureSpot : pictureSpot,
              selectedSpot: selectedSpot,
            }),
          ]),
          c.isImagePopupVisible() ? imagePopup : null,
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : pictureSpot.explanation,
              placeholder: t('gameplays:picture_spot.explanation_placeholder'),
              editable   : true,
            }),
            m('.content-edition__page__picture-spot-back-picture', {
              style: {
                backgroundImage: 'url(' + pictureSpot.image().cdn() + ')',
              },
            }),
          ]),
        ]),
      ]),
      m('.content-edition__commands.content-edition__commands--picture-spot', [
        m('.content-edition__page__picture-spot__display__spots', [
          displaySpots(c, args),
          m.hide(!pictureSpot.image().filename() || pictureSpot.spots().length >= MAX_SPOT_NUMBER, '.button--action-spot--add', {
            onclick: c.createSpot.bind(null, args),
          }, t('gameplays:picture_spot.add_question')),
        ]),
        m.hide(!pictureSpot.image().filename(), '.content-edition__page__picture-spot__replace__picture', {
          onclick: c.openImagePopup,
        }, t('gameplays:picture_spot.replace_image')),
      ]),
    ]),
  ]);
};

function displaySpots(c, args) {
  var pictureSpot = args.content().game();
  var isInDraftMode = args.status() === Enum.contentStatus.DRAFT;
  var buttonsClass = pictureSpot.spots().length > 1 || isInDraftMode ? 'button--action-spot__selected' : 'button--action-spot__only-child';

  return pictureSpot.spots().items.map(function returnSpots(spot, index) {
    return m('.button--action-spot button_' + index, {
      onclick: changeSelectedSpot.bind(null, index),
      class  : [
        c.selectedSpotIndex() === index ? buttonsClass : '',
      ],
    }, index + 1);
  });

  function changeSelectedSpot(index) {
    var isSelectedSpot = c.selectedSpotIndex() === index;
    var canDeleteGameplay = isSelectedSpot && (pictureSpot.spots().length > 1 || isInDraftMode);

    if (canDeleteGameplay)
      c.tryDeletingSpot(args);
    c.selectedSpotIndex(index);
  }
}

export default component;
