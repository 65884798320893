/**
 * Display an illustration
 *
 * Props:
 * - illustration: name of the illustration to display
 * - color: color of the illustration ('PRIMARY')
 * - size: size of the illustration (M | L)
 * - width: optional props, when present, will override the size props width
 * - height: optional props, when present, will override the size props height
 * - validated: boolean to display a check on the bottom of the illustration
 * - margins: size of the margins around the illustration
 * - style: to override component's style
 */

import React, { CSSProperties, useMemo } from 'react';

import { COLORS } from 'Components/foundation';

import { ILLUSTRATIONS, IllustrationName, IllustrationSVGType } from 'Components/foundation/illustrations';

import styles from './UIllustration.style';

export type IllustrationSizeType = 'S' | 'M' | 'L';

export type UIllustrationProps = {
  illustration: IllustrationName;

  // The color props gives the possibility to add other colors
  // The color must have the following keys to be used as an illustration color: DEFAULT, MEDIUM, LIGHTER, HOVER
  color?: IllustrationSVGType['color'];
  disabled?: boolean;
  size?: IllustrationSizeType;
  height?: number | string;
  width?: number | string;
  validated?: boolean;
  margin?: number | string;
  style?: CSSProperties;
  illustrationWrapperStyle?: CSSProperties;
};

const ILLUSTRATION_SIZE = Object.freeze({
  S: {
    width: 24,
    height: 25,
  },
  M: {
    width: 75,
    height: 74,
  },
  L: {
    width: 114,
    height: 117,
  },
});

const BACKGROUND_ILLUSTRATION_SIZE = Object.freeze({
  S: {
    width: 20,
    height: 20,
  },
  M: {
    width: 70,
    height: 70,
  },
  L: {
    width: 120,
    height: 120,
  },
});

export const UIllustration = ({
  illustration,
  color = COLORS.SUCCESS,
  disabled = false,
  size = 'L',
  width = undefined,
  height = undefined,
  validated = false,
  margin = 0,
  style = {},
  illustrationWrapperStyle = {},
}: UIllustrationProps) => {
  const isValidationVisible = useMemo(() => !disabled && validated, [disabled, validated]);

  const Comp = useMemo(() => ILLUSTRATIONS[illustration], [illustration]);
  const BackgroundComp = useMemo(() => ILLUSTRATIONS['circle-background'], []);

  return (
    <div style={{ margin, ...styles.wrapper, ...style }}>
      <div style={styles.illustrationBackgroundWrapper}>
        <div style={{ ...styles.illustrationWrapper, ...illustrationWrapperStyle }}>
          <Comp
            test-id="illustration"
            color={disabled ? COLORS.DISABLED : color}
            height={height || ILLUSTRATION_SIZE[size].height}
            width={width || ILLUSTRATION_SIZE[size].width}
          />
        </div>
        {isValidationVisible && (
          <div style={{ ...styles.validatedBackground, ...styles[`validatedBackground${size}`] }}>
            <BackgroundComp
              test-id="validated-illustration-background"
              color={disabled ? COLORS.DISABLED : color}
              height={BACKGROUND_ILLUSTRATION_SIZE[size].height}
              width={BACKGROUND_ILLUSTRATION_SIZE[size].width}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UIllustration;
