/*
 * ARGS:
 * image
 * video
 * type
 */

import m from 'm';
import { t } from 'i18next';

import inputQuestion from 'components/input.question';
import inputGameItem from 'components/input.game.item';

var component = {};
var MAX_RADIUS = 50;
var MIN_RADIUS = 4;
var HUNDRED = 100;
var DEFAULT_RADIUS = 15;
var DELTA = 2;

component.controller = function controller(args) {
  var self = this;

  self.draggingSpot = m.prop(false);

  self.dragDetected = function dragDetected(selectedSpot, e) {
    if (!self.draggingSpot()) {
      m.redraw.strategy('none');

      return;
    }

    self.clickDetected(selectedSpot, e);
  };

  self.clickDetected = function clickDetected(selectedSpot, e) {
    var HUNDRED = 100;
    var DEFAULT_RADIUS = 15;
    var elem = document.querySelector('.content-edition__page__picture-spot-overlay').getBoundingClientRect();

    var newX = (e.pageX - elem.left - elem.width / 2) * HUNDRED / elem.width;
    var newY = (e.pageY - elem.top - elem.height / 2) * HUNDRED / elem.width;

    var spotElem = document.querySelector('.input-game-item');

    if (newY > 0) {
      spotElem.style.top = '-3em';
    } else {
      spotElem.style.top = '0em';
    }
  
    if (!selectedSpot.r())
      selectedSpot.r(DEFAULT_RADIUS);

    selectedSpot.x(Math.round(newX));
    selectedSpot.y(Math.round(newY));
  };

  self.changeRadius = function changeRadius(_args, delta, e) {
    e.stopPropagation();
    if (_args.selectedSpot.r() + delta > MAX_RADIUS || _args.selectedSpot.r() + delta < MIN_RADIUS)
      return;
    _args.selectedSpot.r(_args.selectedSpot.r() + delta);
  };

  if (args.selectedSpot)
    self.sliderHidden = m.prop(args.selectedSpot.question().data().length || false);
  else
    self.sliderHidden = m.prop(false);

  self.stopDragging = function stopDragging() {
    self.draggingSpot(false);
    self.sliderHidden(false);
  };

  self.stopEventPropagation = function stopEventPropagation(e) {
    e.stopImmediatePropagation();
  };
};

component.view = function view(c, args) {
  var selectedSpot = args.selectedSpot;
  var spotDoesNotExist = 0;

  if (!selectedSpot)
    return m('');

  spotDoesNotExist = selectedSpot.r();

  return m('.content-edition__page__picture-spot-overlay-container', [
    m('.content-edition__page__picture-spot-overlay', {
      config: setupSpot.bind(null, selectedSpot),
    }, [
      m('.content-edition__page__picture-spot'),
      m('.content-edition__page__picture-spot__label-container', {
        onmousemove: c.dragDetected.bind(null, selectedSpot),
        onmouseup  : c.stopDragging,
        onmousedown: c.draggingSpot.bind(null, true),
      }, [
        m('.content-edition__page__picture-spot__label ' + determineDescriptionClass(c, selectedSpot), {
          onmousemove: c.stopEventPropagation,
          onmouseup  : c.stopEventPropagation,
          onmousedown: c.stopEventPropagation,
        }, [
          m.hide(!c.sliderHidden(), inputGameItem, {
            gameItem    : selectedSpot.description,
            placeholder : args.placeholder || t('gameplays:picture_spot.description_placeholder'),
            editable    : true,
            stickToWidth: false,
            maxFieldSize: selectedSpot.description().getSizeLimitation(),
            class       : 'input-game-item__input--pictureSpot',
          }),
        ]),
      ]),
    ]),
    selectedSpot
    ? m('.content-edition__page__picture-spot-overlay__question-input', [
      m(inputQuestion, {
        gameItem   : m.prop(selectedSpot && selectedSpot.question()),
        placeholder: t('gameplays:picture_spot.question_placeholder'),
        editable   : true,
        class: 'input-question-spot',
      }),
    ])
    : null,
    m.hide(c.sliderHidden(), '.picture-spot__command-bottom', [
      m('.picture-spot__radius-selection', {
        class: spotDoesNotExist ? '' : '',
      }, [
        m('.picture-spot__radius-selection__button', {
          onclick: c.changeRadius.bind(null, args, -DELTA),
        }, [
          m('.picture-spot__radius-selection__button__value', '-'),
        ]),
        m('.picture-spot__radius-selection__slide-bar', [
          m('.picture-spot__radius-selection__slider', {
            style: {
              left: ((selectedSpot.r() - MIN_RADIUS) / ((MAX_RADIUS - MIN_RADIUS) / HUNDRED)) + '%',
            },
          }),
        ]),
        m('.picture-spot__radius-selection__button', {
          onclick: c.changeRadius.bind(null, args, DELTA),
        }, [
          m('.picture-spot__radius-selection__button__value', '+'),
        ]),
        m('.picture-spot__radius-selection__button', {
          onclick: c.sliderHidden.bind(null, true),
        }, [
          m('.picture-spot__radius-selection__button__value', 'Ok'),
        ]),
      ]),
    ]),
  ]);
};

function setupSpot(selectedSpot, elem, isInit) {
  setTimeout(function setDimensions() {
    var width = elem.clientWidth;
    var height = elem.clientHeight;
    var x = selectedSpot.x();
    var y = selectedSpot.y();
    var r = selectedSpot.r();
    var HUNDRED = 100;

    var spotElem = document.querySelector('.content-edition__page__picture-spot');
    var labelSpotElemContainer = document.querySelector('.content-edition__page__picture-spot__label-container');
    var labelSpotElem = document.querySelector('.content-edition__page__picture-spot__label');

    if (spotElem) {
      spotElem.style.top = (width * (y + 50) / HUNDRED + (height - width) / 2) + 'px';
      spotElem.style.left = width * (x + 50) / HUNDRED + 'px';
      spotElem.style.width = (width * r / HUNDRED) * 2 + 'px';
      spotElem.style.height = (width * r / HUNDRED) * 2 + 'px';
    }

    if (labelSpotElemContainer) {
      labelSpotElemContainer.value = selectedSpot.description().data;
      labelSpotElemContainer.style.top = width * (y + 50) / HUNDRED + (height - width) / 2 + 'px';
      labelSpotElemContainer.style.left = width * (x + 50) / HUNDRED + 'px';
      labelSpotElemContainer.style.width = (width * r / HUNDRED) * 2 + 'px';
      labelSpotElemContainer.style.height = (width * r / HUNDRED) * 2 + 'px';
    }

    if (labelSpotElem) {
      labelSpotElem.value = selectedSpot.description().data;
      labelSpotElem.style.width = (width * r / HUNDRED) * 2 + 'px';
    }
  });
}

function determineDescriptionClass(c, selectedSpot) {
  var className = [];

  className.push('content-edition__page__picture-spot__label--'
    + (selectedSpot.y() <= 0 ? 'bottom' : 'top'));

  if (selectedSpot.r() <= 35) {
    if ((selectedSpot.x() + selectedSpot.r()) >= 40)
      className.push('content-edition__page__picture-spot__label--left');
    if ((selectedSpot.x() - selectedSpot.r()) <= -40)
      className.push('content-edition__page__picture-spot__label--right');
  }

  return className.join(' ');
}

export default component;
