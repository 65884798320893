// @flow

/**
 * SImageAiSelectionLoader
 * props:
 * - title: Title of the loader selection
 * - processingMessages: Processing instruction message cycling every 3 secondes
 * - errorTitle: Error title
 * - errorDescription: Error state with description
 * - notice: bottom notice
 * - onClose: callback on processing close
 * - style: custom style
 */

import * as React from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import { COLORS } from 'Components/foundation';

import styles from './SImageAiSelectionLoader.style';

type Props = {|
  title: string,
  processingMessages: $ReadOnlyArray<string>,
  errorTitle: string,
  errorDescription: string,
  notice: string,
  onClose: () => mixed,
  style?: Object,
|};

type State = {|
  processingMessageIdx: number,
|};

const TEXT_ROTATION_MS = 3000;

export class SImageAiSelectionLoader extends React.PureComponent<Props, State> {
  interval: IntervalID;

  static defaultProps = {
    style: undefined,
    processingMessages: [],
    errorDescription: '',
  };

  state = {
    processingMessageIdx: 0,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const { processingMessageIdx } = this.state;
      const { processingMessages } = this.props;

      this.setState({ processingMessageIdx: (processingMessageIdx + 1) % processingMessages.length });
    }, TEXT_ROTATION_MS);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { style, notice } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={styles.box}>
          <div style={styles.close}>
            <UIconButton
              icon='close-light'
              size='S'
              onClick={this.handleClose}
              ghost
            />
          </div>
          <div style={styles.textWrapper}>
            {this.renderText()}
          </div>
          <div style={styles.loader}>
            <CircleSpinner
              size={22}
              color={COLORS.TEXT.SECONDARY_DEFAULT}
              loading
            />
          </div>
          <div style={styles.notice}>
            {notice}
          </div>
        </div>
      </div>
    );
  }

  renderText() {
    const { processingMessageIdx } = this.state;
    const {
      title,
      errorTitle,
      errorDescription,
      processingMessages,
    } = this.props;

    // There is an error
    if (errorDescription) {
      return (
        <>
          <div style={styles.errorTitle}>
            {errorTitle}
          </div>
          <div style={styles.errorDescription}>
            {errorDescription}
          </div>
        </>
      );
    }

    return (
      <>
        <div style={styles.title}>
          {title}
        </div>
        <div style={styles.processingText}>
          {processingMessages[processingMessageIdx]}
        </div>
      </>
    );
  }

  handleClose = () => {
    const { onClose } = this.props;

    onClose();
  };
}

export default SImageAiSelectionLoader;
