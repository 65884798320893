// @flow

/**
 * HOC which render a SToolTip on hover
 *
 * Props:
 *  - tooltipPosition: Position of the tooltip
 *  - tooltipContent: Content of the tooltip
 *  - tooltipEnabled: Set if tooltip must render on hover
 * Info:
 *  The Storybook that represents how it works is the STooltip story book
 *
 */

import * as React from 'react';

import { TooltipPosition } from 'Components/structural/STooltip/STooltip';

import STooltip from 'Components/structural/STooltip/STooltip';

import styles from './withWTooltip.style';

type Props = {|
  tooltipPosition?: typeof TooltipPosition,
  tooltipContent: string,
  tooltipEnabled: boolean,
  tooltipMaxWidth?: number,
|};

type ReturnProps<P> = {|
  ...P,
  ...Props,
|};

let nextTooltipId = 0;

export default function withTooltip<P>(
  WrappedComponent: React.AbstractComponent<P>,
): React.AbstractComponent<ReturnProps<P>> {
  return class _withTooltip extends React.PureComponent<ReturnProps<P>> {
    tooltipId = `withTooltip-id-${nextTooltipId++}`;

    static defaultProps = {
      tooltipMaxWidth: 200,
    };

    render() {
      // eslint-disable-next-line no-unused-vars
      const { tooltipPosition, tooltipContent, tooltipEnabled, ...compProps } = this.props;

      return (
        <>
          <div data-tip data-for={this.tooltipId} style={styles.wrappedComponentWrapper}>
            <WrappedComponent {...compProps} />
          </div>
          {this.renderTooltip()}
        </>
      );
    }

    renderTooltip = () => {
      const { tooltipPosition, tooltipContent, tooltipEnabled, tooltipMaxWidth } = this.props;

      if (!tooltipEnabled) return null;
      return (
        <STooltip id={this.tooltipId} position={tooltipPosition} content={tooltipContent} maxWidth={tooltipMaxWidth} />
      );
    };
  };
}
