import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FunctionComponent } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import type { SearchSortOrder } from '@sparted/shared-library/types';
import { t } from 'i18next';

import SPageHeader, { IconPropsType } from 'Components/structural/SPageHeader/SPageHeader';
import SShortcutCard from 'Components/structural/SShortcutCard/SShortcutCard';
import SDisplayControlItems from 'Components/structural/SDisplayControlItems/SDisplayControlItems';
import UFilterSelect from 'Components/unit/UFilterSelect/UFilterSelect';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import MCourseForm from 'Components/modal/MCourseForm/MCourseForm';
import type { USecondaryTabLabelProps } from 'Components/unit/USecondaryTabLabel/USecondaryTabLabel';
import MActionDialog from 'Components/modal/MActionDialog/MActionDialog';
import { SFiltersSelectGroup } from 'Components/structural/SFiltersSelectGroup/SFiltersSelectGroup';

import { COLORS } from 'Components/foundation';

import { FetchAudienceGroupsResponse } from 'Features/audience/stores/thunks/audience-group.thunks';

import { enqueueBasicAlert } from 'Pages/AlertManager';
import { actions } from 'Pages/Courses/redux/slice';
import { fetchFilteredCourses, fetchNextCoursePage, createCourse } from 'Pages/Courses/redux/thunks';
import { useModuleSelector } from 'Pages/Courses/redux';
import { ContributorType } from 'Pages/Contributor/redux/models/Contributor';
import type { CourseFormData } from 'Pages/Courses/types';
import type { CourseSort, CourseStatusFilter } from 'Pages/Courses/redux';

import { useAppDispatch } from 'Libs/redux/store';
import { redirect } from 'Libs/routing/redirect';
import { reload } from 'Libs/routing/reload';
import type { Filter } from 'Libs/filter/types';

import { Language } from 'Services/language/languageService';
import { getSync } from 'Services/localStorageService';
import { trackEventWithLocation } from 'Services/trackingService';

import { usePersistedState } from 'Hooks/usePersistedState/usePersistedState';

import { getAudienceGroupFactory } from 'Features/audience/services/audience-group-list.service';

import { SFooter } from 'Components/structural/SFooter/SFooter';

import CourseGrid from './components/CourseGrid/CourseGrid';
import styles from './CourseList.style';
import { CourseItem } from './components/CourseItem/CourseItem';

export const SORT_BY = {
  LAST_EDITED: 1,
  ALPHABETICAL: 2,
};
export const COURSE_LIMIT = 20;
export const AUDIENCE_GROUP_PAGINATION = { limit: 50, offset: 0 };

export type CoursesSortProperty = {
  order: SearchSortOrder;
  value: string;
};

const updateAudienceFilter =
  (audienceGroups: FetchAudienceGroupsResponse, currentlySelectedItems: Readonly<number[]>) =>
  (previousFilters: Filter[]) => {
    const languageFilters = previousFilters.slice(0, 1);
    return [
      ...languageFilters,
      {
        category: {
          label: t('courses:filters.audience_groups'),
          value: 'audienceGroups',
        },
        type: 'multiselect' as const,
        items: audienceGroups.items.map((audienceGroup) => ({
          category: {
            label: t('courses:filters.audience_groups'),
            value: 'audienceGroups',
          },
          selected: currentlySelectedItems.includes(audienceGroup.id),
          id: audienceGroup.id,
          value: audienceGroup.name,
        })),
      },
    ];
  };

const SORT_PROPERTY: Record<number, CourseSort> = {
  [SORT_BY.LAST_EDITED]: 'lastEditionDate',
  [SORT_BY.ALPHABETICAL]: 'alphabetical',
} as const;

export type CoursesFilterState = {
  courseName: string;
};

export type CourseListProps = {
  languages: Language[];
};

export const CourseList: FunctionComponent<CourseListProps> = ({ languages }) => {
  const dispatch = useAppDispatch();

  const areFilterInitialised = useRef<boolean>(false);

  const courseFormError = useModuleSelector((state) => state.errors.courseFormError);
  const hasEditionIdMismatchError = useModuleSelector((state) => state.errors.hasEditionIdMismatchError);
  const hasEditionError = useModuleSelector((state) => state.errors.hasEditionError);

  const courses = useModuleSelector((state) => state.list.items);
  const nextPage = useModuleSelector((state) => state.list.nextPage);
  const statusFilter = useModuleSelector((state) => state.list.filters.query.status);
  const titleFilter = useModuleSelector((state) => state.list.filters.query.title);
  const audienceGroupIdFilter = useModuleSelector((state) => state.list.filters.query.audienceGroupId);
  const languageCodeFilter = useModuleSelector((state) => state.list.filters.query.languageCode);
  const firstCourseCreated = useModuleSelector((state) => state.firstCourseCreated);
  const loading = useModuleSelector((state) => state.list.loading);

  const [transformedLanguages] = useState(
    // format languages to use in MCourseForm
    languages.map(({ languageCode, languageName, ...rest }) => ({ ...rest, label: languageName, value: languageCode })),
  );

  const [filters, setFilters] = useState<Filter[]>([
    {
      category: 'language' as const,
      type: 'multiselect' as const,
      items: languages.map((language) => ({
        category: 'language' as const,
        id: language.id,
        value: language.languageName,
        selected: languageCodeFilter.includes(language.languageCode),
      })),
    },
  ]);

  const defaultFilter: Filter = {
    category: 'sort',
    type: 'select',
    items: [
      {
        category: 'sort',
        selected: true,
        value: t('courses:filters.sort_last_edited'),
        id: SORT_BY.LAST_EDITED,
      },
      {
        category: 'sort',
        selected: false,
        value: t('courses:filters.sort_alphabetical'),
        id: SORT_BY.ALPHABETICAL,
      },
    ],
  };

  const [sortFilter, setSortFilter] = usePersistedState('course-list-sort', defaultFilter);

  const sortFilterName = useMemo(() => {
    const filter = sortFilter.items.find((item) => item.selected);

    return SORT_PROPERTY[filter?.id || SORT_BY.LAST_EDITED];
  }, [sortFilter]);

  const [courseFormVisible, setCourseFormVisible] = useState(false);

  const handleCourseFormOpen = useCallback(() => {
    setCourseFormVisible(true);
  }, []);

  const handleCourseFormClose = useCallback(() => {
    setCourseFormVisible(false);
  }, []);

  const handleCourseFormSubmit = useCallback(
    async (data: CourseFormData) => {
      const currentUser: ContributorType = getSync('user');

      const promise = dispatch(createCourse({ ...data, currentUser }));

      try {
        const { course } = await promise.unwrap();

        if (!firstCourseCreated) {
          dispatch(actions.setFirstCourseCreated({ isCreated: true }));
        }

        trackEventWithLocation('create', { context: 'course' });
        redirect(`/course/${course.id}/microknowledges`);
      } catch (e) {
        // eslint-disable-next-line no-console -- Error is already handled with redux
        console.error(e);
      }

      return () => {
        promise.abort();
      };
    },
    [dispatch, firstCourseCreated],
  );

  const handleTabFilterChange = useCallback(
    (status: CourseStatusFilter) => () => {
      dispatch(actions.updateFilters({ status }));
    },
    [dispatch],
  );

  const handleNameFilterChange = useCallback(
    (value: string) => {
      dispatch(actions.updateFilters({ courseTitle: value }));
    },
    [dispatch],
  );

  const handleFiltersChange = useCallback(
    (filters: Filter[]) => {
      if (!filters.length || !areFilterInitialised.current) {
        return;
      }

      const languageFilter = filters[0];
      const audienceGroupsFilter = filters[1];

      const languageCode = languageFilter.items
        .filter((item) => item.selected)
        .map((item) => languages.find((language) => language.languageName === item.value)?.languageCode)
        .filter((languageCode) => languageCode !== undefined);

      const audienceGroupId = audienceGroupsFilter.items.filter((item) => item.selected).map((item) => item.id);

      dispatch(actions.updateFilters({ audienceGroupId, languageCode: languageCode as string[] }));
      setFilters(filters);
    },
    [dispatch],
  );

  // TODO: Logic to write --> function called when the Learn more button is clicked
  // const onLearnMorePress = useCallback(() => { }, []);

  const displayControlTabs = useMemo(
    (): USecondaryTabLabelProps[] => [
      {
        text: t('courses:filters.all'),
        onClick: handleTabFilterChange('all'),
        active: statusFilter === 'all',
        disabled: false,
      },
      {
        text: t('courses:filters.published'),
        onClick: handleTabFilterChange('published'),
        active: statusFilter === 'published',
        disabled: !firstCourseCreated,
      },
      {
        text: t('courses:filters.unpublished'),
        onClick: handleTabFilterChange('draft'),
        active: statusFilter === 'draft',
        disabled: !firstCourseCreated,
      },
    ],
    [statusFilter, handleTabFilterChange, firstCourseCreated],
  );

  const iconProps = useMemo(
    (): IconPropsType => ({
      iconName: 'plus-light',
      onIconClick: handleCourseFormOpen,
      iconType: 'accentuated',
    }),
    [handleCourseFormOpen],
  );

  const handleViewMoreClick = useCallback(() => {
    if (nextPage) {
      dispatch(fetchNextCoursePage(nextPage));
    }
  }, [dispatch, nextPage]);

  const fetchAudienceGroups = useCallback(async () => {
    const getAudienceGroups = getAudienceGroupFactory(dispatch);
    const audiencegroups = await getAudienceGroups({
      status: 'all' as const,
      title: null,
      selectedSort: {
        id: 1,
        category: 'sort',
        selected: true,
        value: 'Alphabetical order',
        field: 'name',
        order: 'asc',
      },
      limit: AUDIENCE_GROUP_PAGINATION.limit,
      offset: AUDIENCE_GROUP_PAGINATION.offset,
    });

    if (!audiencegroups) {
      return;
    }

    setFilters(updateAudienceFilter(audiencegroups, audienceGroupIdFilter));
    areFilterInitialised.current = true;
  }, []);

  const fetchCourses = useCallback(() => {
    const promise = dispatch(
      fetchFilteredCourses({
        status: statusFilter,
        title: titleFilter || undefined,
        audienceGroupId: audienceGroupIdFilter?.join(',') || undefined,
        languageCode: languageCodeFilter?.join(',') || undefined,
        sort: sortFilterName,
        offset: 0,
        limit: COURSE_LIMIT,
      }),
    );

    promise.unwrap().catch((e: any) => {
      console.error('Something went wrong while retrieving course list', e);

      // Stops showing an error when the request is aborted via navigating out of the page.
      if (e.message === 'Aborted') {
        return;
      }

      enqueueBasicAlert({
        id: 'COURSES_LIST_SEARCH_ERROR',
        text: t('courses:search_error_text'),
        title: t('courses:search_error_title'),
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    });

    return promise.abort;
  }, [dispatch, sortFilterName, statusFilter, titleFilter, audienceGroupIdFilter, languageCodeFilter]);

  useEffect(() => {
    fetchAudienceGroups().then(() => {
      if (courses.length === 0) {
        fetchCourses();
      }
    });
  }, []);

  useEffect(() => {
    if (!areFilterInitialised.current) {
      return;
    }

    return fetchCourses();
  }, [fetchCourses]);

  useEffect(
    () => () => {
      dispatch(actions.resetErrors());
    },
    [dispatch],
  );

  return (
    <>
      <div style={styles.wrapper}>
        <SPageHeader title={t('courses:title')} iconProps={iconProps} />
        <div>
          <SDisplayControlItems
            filterValue={titleFilter || ''}
            filterPlaceholder={t('courses:search')}
            onFilterChange={handleNameFilterChange}
            inputOpened={(titleFilter && titleFilter.length > 0) || false}
            tabs={displayControlTabs}
          />
        </div>

        <div style={styles.optionsWrapper}>
          <SFiltersSelectGroup filters={filters} onFiltersChanged={handleFiltersChange} />

          <UFilterSelect
            allowNoChoice={false}
            filter={sortFilter}
            onFilterChanged={setSortFilter}
            style={styles.select}
          />
        </div>
        {courses.length > 0 && (
          <CourseGrid style={{ marginTop: '40px', marginBottom: '32px' }}>
            {courses.map(({ course, revision }) => (
              <CourseItem
                key={course.id}
                course={course}
                coursesLength={courses.length}
                editionId={revision.editionId}
              />
            ))}
          </CourseGrid>
        )}
        {nextPage && (
          <div style={{ margin: 'auto', marginBottom: '84px' }}>
            <SFooter>
              <UTextLink
                text={t('courses:view_more')}
                onClick={handleViewMoreClick}
                type="secondary"
                rightIcon="bottom-chevron"
              />
            </SFooter>
          </div>
        )}
        {firstCourseCreated === false && (
          <SShortcutCard
            data-test-id="shortcut-card-create-first-course"
            illustration="course"
            title={t('courses:create_your_first_course_title')}
            body={t('courses:create_your_first_course_description')}
            // TODO: Add link to open when clicking on the Learn More button
            link=""
            onNavigationClick={handleCourseFormOpen}
            // onLearnMorePress={onLearnMorePress}
            size="100%"
            style={styles.shortCutCard}
          />
        )}
        {firstCourseCreated && courses.length === 0 && !loading && (
          <SShortcutCard
            data-test-id="shortcut-card-nothing-here"
            illustration="course"
            title={t('courses:nothing_here_card.title')}
            body={t('courses:nothing_here_card.description')}
            size="100%"
            status="disabled"
            style={styles.shortCutCard}
          />
        )}
        {loading && courses?.length === 0 && (
          <div style={styles.loader}>
            <CircleSpinner size={13} color={COLORS.TEXT.SECONDARY_HOVER} loading={loading} />
          </div>
        )}
      </div>
      <MCourseForm
        visible={courseFormVisible}
        availableLanguages={transformedLanguages}
        onClose={handleCourseFormClose}
        onConfirm={handleCourseFormSubmit}
        error={courseFormError ? t('courses:new_course_form_error') : undefined}
      />
      <MActionDialog
        data-test-id="action-dialog-edition-id-mismatch"
        visible={hasEditionIdMismatchError}
        title={t('courses:modal_edition_mismatch.title')}
        description={t('courses:modal_edition_mismatch.description')}
        primaryActionLabel={t('courses:modal_edition_mismatch.action')}
        onPrimaryAction={reload}
        iconName="refresh"
        secondaryActionLabel={t('courses:modal_edition_mismatch.secondary_action')}
      />
      <MActionDialog
        data-test-id="action-dialog-unknown-error"
        visible={hasEditionError}
        title={t('courses:modal_unknown_error.title')}
        description={t('courses:modal_unknown_error.description')}
        primaryActionLabel={t('courses:modal_unknown_error.action')}
        onPrimaryAction={reload}
        iconName="refresh"
      />
    </>
  );
};

export default CourseList;
