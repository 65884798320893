// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  content: {
    ...TYPOGRAPHY.BODY3,
  },
};
