import React, { CSSProperties, FunctionComponent, useMemo } from 'react';

import styles from './DefaultContainer.style';

type DefaultContainerProps = {
  active: boolean;
  contentCount: number;
  style?: CSSProperties;
};

export const DefaultContainer: FunctionComponent<DefaultContainerProps> = ({
  children,
  contentCount,
  active,
  style,
}) => {
  const outerContainerStyle = useMemo(
    () => ({
      ...styles.outerContainer,
      ...style,
    }),
    [style],
  );

  const innerContainerStyle = useMemo(() => {
    const applyContentStyle = !active && contentCount > 0;

    return {
      ...styles.innerContainer,
      ...(active ? styles.activeInnerContainer : {}),
      ...(applyContentStyle ? styles.innerContainerWithContent : {}),
    };
  }, [active, contentCount]);

  return (
    <div style={outerContainerStyle}>
      <div style={innerContainerStyle}>{children}</div>
    </div>
  );
};
