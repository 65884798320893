/* eslint-disable no-magic-numbers */
var ENUM = {};

var tabsLabels = {
  search : 'SEARCH',
  upload : 'UPLOAD',
  library: 'LIBRARY',
};

var dropAreaLabels = {
  defaultSentence      : 'Upload or drop an image right here',
  defaultLabel         : 'It works for JPG, PNG, and GIF formats - max size 20MB',
  dropSentence         : 'Drop that file right here',
  warnSentence1        : 'Hmm, that file type wont work',
  warnSentence2        : 'Try a JPG, PNG or GIF',
  errorIncorrectFormat1: 'The file type you uploaded is not supported.',
  errorIncorrectFormat2: 'Try again with a JPG, PNG or GIF',
  errorSizeTooBig      : 'The file is too big. Try re-uploading a smaller file.',
  errorManyFiles       : 'More than one file were selected. Select only one file to upload',
  uploadingSentence    : 'Uploading...',
};

ENUM.COMMON = {
  titleSelect        : 'Select an image',
  titleCrop          : 'Crop your image',
  cancelLabel        : 'Cancel',
  imageSelectionLabel: 'Next',
  imageCropLabel     : 'Add image',
  galleryPlaceHolder : 'A picture is worth a thousand words--but your search terms found zero pictures. Try again!',
  searchPlaceHolder  : 'Search for high-resolution images',
  tabsLabels         : tabsLabels,
  dropAreaLabels     : dropAreaLabels,
  goBackLabel        : 'Back',
  cropProps          : {
    title : 'Preview',
    notice: 'See how your image will be viewed in different devices.',
  },
  errorMessage: 'Unexpected error. Please try again',
};

ENUM.KNOWLEDGE = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 343/246,
    },
    {
      label: 'Google Pixel2',
      ratio: 380/246,
    },
    {
      label: 'Iphone SE',
      ratio: 288/246,
    },
  ],
};

ENUM.ERRORTEXT_FRONT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/383,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/304,
    },
    {
      label: 'Iphone SE',
      ratio: 320/140,
    },
  ],
};

ENUM.ERRORTEXT_BACK = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/231,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/231,
    },
    {
      label: 'Iphone SE',
      ratio: 320/231,
    },
  ],
};

ENUM.NOINTERACTION_FRONT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/284,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/284,
    },
    {
      label: 'Iphone SE',
      ratio: 320/284,
    },
  ],
};

ENUM.NOINTERACTION_BACK = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 412/293,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/293,
    },
    {
      label: 'Iphone SE',
      ratio: 412/293,
    },
  ],
};

ENUM.OPENQUESTION_FRONT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/391,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/319,
    },
    {
      label: 'Iphone SE',
      ratio: 320/174,
    },
  ],
};

ENUM.OPENQUESTION_BACK = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/231,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/231,
    },
    {
      label: 'Iphone SE',
      ratio: 320/231,
    },
  ],
};

ENUM.PICTURESPOT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/649,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/569,
    },
    {
      label: 'Iphone SE',
      ratio: 320/405,
    },
  ],
};

ENUM.QCM_FRONT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/374,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/293,
    },
    {
      label: 'Iphone SE',
      ratio: 320/129,
    },
  ],
};

ENUM.QCM_BACK = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/231,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/231,
    },
    {
      label: 'Iphone SE',
      ratio: 320/231,
    },
  ],
};

ENUM.SQCM_FRONT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/374,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/293,
    },
    {
      label: 'Iphone SE',
      ratio: 320/129,
    },
  ],
};

ENUM.SQCM_BACK = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/231,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/231,
    },
    {
      label: 'Iphone SE',
      ratio: 320/231,
    },
  ],
};

ENUM.SWIPINGCARDS = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 1,
    },
    {
      label: 'Google Pixel2',
      ratio: 1,
    },
    {
      label: 'Iphone SE',
      ratio: 1,
    },
  ],
};

ENUM.CAMPAIGN = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/470,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/516,
    },
    {
      label: 'Iphone SE',
      ratio: 320/401,
    },
  ],
};

ENUM.WELCOME_CARDS = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 343/260,
    },
    {
      label: 'Google Pixel2',
      ratio: 380/260,
    },
    {
      label: 'Iphone SE',
      ratio: 288/260,
    },
  ],
};

ENUM.BREAKING_NEWS = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/390,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/350,
    },
    {
      label: 'Iphone SE',
      ratio: 320/268,
    },
  ],
};

ENUM.THATS_ALL_FOLKS = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 375/128,
    },
    {
      label: 'Google Pixel2',
      ratio: 412/128,
    },
    {
      label: 'Iphone SE',
      ratio: 320/128,
    },
  ],
};

ENUM.GIFT = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 335/184,
    },
    {
      label: 'Google Pixel2',
      ratio: 372/184,
    },
    {
      label: 'Iphone SE',
      ratio: 280/184,
    },
  ],
};

// TODO: change to reflect courses images sizes on the app when they are decided
ENUM.COURSES = {
  deviceList: [
    {
      label: 'Iphone X',
      ratio: 335/184,
    },
    {
      label: 'Google Pixel2',
      ratio: 372/184,
    },
    {
      label: 'Iphone SE',
      ratio: 280/184,
    },
  ],
};

export default ENUM;
