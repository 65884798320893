import Request from 'Services/requestService';
import Model from 'Models/Model';

import { getCdnFromS3Url } from 'Libs/cdn';
import { makeModel } from './types';

export type ImageInstance = InstanceType<typeof Image>;

const Image = makeModel({
  endpoint: 'api/Images',
  attributes: {
    url: {
      type: 'string',
    },
    filename: {
      type: 'string',
    },
    width: {
      type: 'number',
    },
    height: {
      type: 'number',
    },
    ancestorId: {
      type: 'number',
    },
    orientation: {
      type: 'string',
    },
    type: {
      type: 'pointer' as any,
      Model: 'ImageType',
    },
    typeId: {
      type: 'number',
    },
    uploading: {
      type: 'boolean',
      defaultsTo: false,
    },
  },
  classMethods: {
    createFromMediaLib: function createFromMediaLib(_Image, obj): Promise<any> {
      return Request.post('api/Images/fromMediaLib', obj).then((image: any) => new _Image(image));
    },
    getAllImages: function getAllImages(_Image, obj): Promise<any> {
      return Request.get('api/Images/paginated', obj).map((image: any) => new _Image(image));
    },
    crop: function crop(_Image, coord): Promise<any> {
      return Request.post('api/Images/crop', coord).then((image: any) => new _Image(image));
    },
  },
  methods: {
    crop: function crop(_Image, coord): Promise<any> {
      const self = this as ImageInstance;

      // eslint-disable-next-line no-param-reassign
      coord.ancestorId = self.id();

      return Request.post('api/Images/crop', coord).then((image: any) => new _Image(image));
    },
    fullyDelete: function fullyDelete(): Promise<any> {
      const self = this as ImageInstance;
      const path = `/api/Images/${self.id()}/delete`;

      return Request.post(path, {});
    },
    cdn: function cdn(_Image, params = {}): any {
      const self = this as ImageInstance;

      return getCdnFromS3Url(self.url(), params);
    },
  },
});

export default Model.register('Image', Image) as typeof Image;
