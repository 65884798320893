// @flow

/**
 * Displays a circular button
 *
 * Props:
 * - onClick: Function called when we click on the button
 * - icon: the name of the icon
 * - style: For overriding the component's style
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UCircularButton.style';


type Props = {|
  onClick: Function,
  icon: string,
  style?: ?Object,
|};

type State = {|
  isHover: boolean,
|};

const GREY_COLOR = COLORS.GREY_MEDIUM;

export class UCircularButton extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
  };

  state = {
    isHover: false,
  };

  render() {
    const { icon, onClick, style: propStyle } = this.props;
    const { isHover } = this.state;
    const backgroundColor = isHover ? GREY_COLOR.HOVER : GREY_COLOR.DEFAULT;

    return (
      <div
        onClick={onClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        style={{ backgroundColor, ...styles.wrapper, ...propStyle }}
      >
        <UIcon
          name={icon}
          size={16}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
        />
      </div>
    );
  }

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default UCircularButton;
