import React, { useMemo } from 'react';
import { t } from 'i18next';

import UButton from 'Components/unit/UButton/UButton';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import { useTypedDispatch } from 'Libs/redux/utils';
import { actions, useModuleSelector } from 'Pages/Activity/redux';

import styles from '../panel.style';

type Order = 'manual' | 'random';

const NewButtonWithTooltip = withTooltip(UButton);

export type OrderModeFormProps = {
  editable?: boolean;
  onChange?: (order: Order) => void;
};

const modeFormStyle = {
  container: {
    width: '100%',
  },
};

export const OrderModeForm = ({ editable = true, onChange }: OrderModeFormProps) => {
  const selectedOrder = useModuleSelector((state) => state.activities[0].dailySerie.random) ? 'random' : 'manual';

  const dispatch = useTypedDispatch();

  const onClick = () => {
    const id: Order = selectedOrder === 'manual' ? 'random' : 'manual';
    if (id === 'manual') {
      dispatch(actions.convertActivityToManual());
    }
    if (id === 'random') {
      dispatch(actions.convertActivityToRandom());
    }

    onChange?.(id);
  };

  const item = useMemo(() => {
    if (selectedOrder === 'manual') {
      return {
        convertText: t('activities:scheduling.side_panel.convert_to_random'),
        subtitle: t('activities:scheduling.side_panel.manual_mode_subtitle'),
        icon: 'random-order' as const,
        tooltip: {
          tooltipContent: !editable
            ? t('activities:scheduling.side_panel.cannot_convert_warning')
            : t('activities:scheduling.side_panel.random_mode_tooltip'),
          tooltipPosition: 'right',
          tooltipEnabled: true,
          tooltipMaxWidth: 243,
        },
      };
    }

    return {
      convertText: t('activities:scheduling.side_panel.convert_to_manual'),
      subtitle: t('activities:scheduling.side_panel.random_mode_subtitle'),
      icon: 'manual-order' as const,
      tooltip: {
        tooltipContent: !editable
          ? t('activities:scheduling.side_panel.cannot_convert_warning')
          : t('activities:scheduling.side_panel.manual_mode_tooltip'),
        tooltipPosition: 'right',
        tooltipEnabled: true,
        tooltipMaxWidth: 243,
      },
    };
  }, [selectedOrder, editable]);

  return (
    <div style={styles.box}>
      <h4 style={styles.title}>{t('activities:scheduling.side_panel.mode_title')}</h4>
      <p style={styles.subtitle}>{item.subtitle}</p>

      <div style={styles.element}>
        <NewButtonWithTooltip
          tooltipContent={item.tooltip.tooltipContent}
          tooltipPosition={item.tooltip.tooltipPosition}
          tooltipEnabled={item.tooltip.tooltipEnabled}
          tooltipMaxWidth={item.tooltip.tooltipMaxWidth}
          text={item.convertText}
          type="accentuated"
          disabled={!editable}
          style={modeFormStyle.container}
          leftIcon={item.icon}
          onClick={onClick}
          ghost
        />
      </div>
    </div>
  );
};

export default OrderModeForm;
