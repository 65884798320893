import { OrderingCards, LanguageCode, GAME_TYPE } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import React from 'react';
import { DeepPartial } from 'ts-essentials';
import { useFormContext } from 'react-hook-form';

import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';
import SGameplayListItemsSection, {
  SGameplayListItemProps,
} from 'Components/structural/SGameplayListItemsSection/SGameplayListItemsSection';
import STextArea from 'Components/structural/STextArea/STextArea';
import ULabel from 'Components/unit/ULabel/ULabel';
import SInput from 'Components/structural/SInput/SInput';

import { QUESTION_CHAR_LIMIT, EXPLANATION_CHAR_LIMIT, ORDERING_CARDS_LABEL_CHAR_LIMIT } from './gameplay.constants';
import generalStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';
import { BaseContentFormValues } from '../ContentEdition/ContentEdition';

type OrderingCardsFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

export const OrderingCardsForm = ({ language, editGameplay, isGameplayAlreadyChosen }: OrderingCardsFormProps) => {
  const { watch, setValue } = useFormContext<BaseContentFormValues & { game: DeepPartial<OrderingCards> }>();

  const formatedChoices =
    watch('game.cards')?.map((card) => ({
      value: card?.[language] || '',
    })) ?? [];

  const onChoiceChange = (items: SGameplayListItemProps[]) => {
    setValue(
      'game.cards',
      items.map((item) => ({ [language]: item.value })),
    );
  };

  return (
    <div style={generalStyles.wrapper}>
      <div style={generalStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.ORDERING_CARDS}
          title={t('gameplays:ordering_cards.label')}
          description={t('gameplays:ordering_cards.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:ordering_cards.instruction_title')}</ULabel>
          <ULabel style={generalStyles.subLabel}>{t('gameplays:ordering_cards.instruction_subtitle')}</ULabel>
          <div style={{ ...generalStyles.imageTextSection }}>
            <STextArea
              canResize={false}
              placeholder={t('gameplays:ordering_cards.instruction_placeholder')}
              height="80"
              onChange={(text) => {
                setValue(`game.question.${language}`, text);
              }}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              value={watch(`game.question.${language}`) || ''}
              maxLength={QUESTION_CHAR_LIMIT}
            />
          </div>
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:ordering_cards.helpers_label')}</ULabel>
          <ULabel style={generalStyles.subLabel}>{t('gameplays:ordering_cards.helpers_subtitle')}</ULabel>
          <ULabel style={generalStyles.subLabel}>{t('gameplays:ordering_cards.helpers_tips')}</ULabel>
          <div style={{ ...generalStyles.imageTextSection, ...generalStyles.sectionSeparation }}>
            <SInput
              type="large"
              placeholder={t('gameplays:ordering_cards.top_label_placeholder')}
              label={t('gameplays:ordering_cards.top_label')}
              onChange={(text) => {
                setValue(`game.topLabel.${language}`, text);
              }}
              style={generalStyles.rightSpacing}
              value={watch(`game.topLabel.${language}`) || ''}
              required
              maxLength={ORDERING_CARDS_LABEL_CHAR_LIMIT}
            />
            <SInput
              type="large"
              placeholder={t('gameplays:ordering_cards.bottom_label_placeholder')}
              label={t('gameplays:ordering_cards.bottom_label')}
              onChange={(text) => {
                setValue(`game.bottomLabel.${language}`, text);
              }}
              value={watch(`game.bottomLabel.${language}`) || ''}
              required
              maxLength={ORDERING_CARDS_LABEL_CHAR_LIMIT}
            />
          </div>
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:ordering_cards.items_title')}</ULabel>
          <ULabel style={generalStyles.subLabel}>{t('gameplays:ordering_cards.items_subtitle')}</ULabel>
          <SGameplayListItemsSection
            variant="none"
            type="standard"
            items={formatedChoices}
            onItemsChange={onChoiceChange}
            style={generalStyles.sectionSeparation}
            textLinkText={t('gameplays:ordering_cards.add_item')}
            textLinkTooltipText={t('gameplays:ordering_cards.too_many_items')}
            maxLength={10}
          />
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:explanation')}</ULabel>
          <STextArea
            canResize={false}
            placeholder={t('gameplays:ordering_cards.explanation_text_placeholder')}
            height="130"
            onChange={(text) => {
              setValue(`game.explanation.${language}`, text);
            }}
            value={watch(`game.explanation.${language}`) || ''}
            radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
            maxLength={EXPLANATION_CHAR_LIMIT}
          />
        </div>
      </div>
    </div>
  );
};
