/* eslint-disable no-magic-numbers */
import m from 'm';
import Enum from 'models/Enum';
import AccessControl from 'models/AccessControl';
import popup from 'services/popupService';
import Checkbox from 'components/checkbox';
import radioSelect from 'components/radio.select';
import button from 'components/button';
import reactCreator from 'components/react.creator';
import authSettingsAccessControl from 'components/settings.authentication.accesscontrol.js';
import { enqueueBasicAlert } from 'Pages/AlertManager';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.loading = m.prop(false);
  self.deletable = m.prop(true);

  console.log('[authentication]', args.authRule.toJSON());

  self.identificationTypes = [
    {
      inactive: false,
      type    : 'email',
      label   : m.prop('E-mail'),
      locked  : true,
    },
    {
      inactive: false,
      type    : 'sso',
      label   : m.prop('External SSO'),
      value   : args.authRule.ssoIdentification,
    },
    {
      inactive: true,
      type    : 'linkedin',
      label   : m.prop('LinkedIn Connect'),
      value   : args.authRule.linkedinIdentification,
    },
    {
      inactive: true,
      type    : 'facebook',
      label   : m.prop('Facebook Connect'),
      value   : args.authRule.facebookIdentification,
    },
    {
      inactive: true,
      type    : 'mobile',
      label   : m.prop('Mobile Number'),
      value   : args.authRule.mobileIdentification,
    },
    {
      inactive: true,
      type    : 'corporateId',
      label   : m.prop('Corporate ID'),
      value   : args.authRule.corporateIdIdentification,
    },
  ];

  // Authentication
  self.authenticationTypes = [
    {
      label   : m.prop('E-mail'),
      id      : m.prop(Enum.AuthenticationTypes.EMAIL),
      icon    : '/assets/img/ico-small-email.svg',
      inactive: false,
    },
    {
      label: m.prop('None'),
      id   : m.prop(Enum.AuthenticationTypes.NO_AUTH),
      icon : '/assets/img/ico-small-lock-open.svg',
    },

    // {
    //   label   : m.prop('SMS'),
    //   id      : m.prop(Enum.AuthenticationTypes.SMS),
    //   inactive: true,
    // },
    // {
    //   label   : m.prop('With an external ID'),
    //   id      : m.prop(Enum.AuthenticationTypes.EXTERNAL),
    //   inactive: true,
    // },
  ];

  // Restriction
  self.restrictionTypes = [
    {
      label: m.prop('E-mail List'),
      id   : m.prop(Enum.RestrictionTypes.WHITELIST),
      icon : '/assets/img/ico-small-list.svg',
    },
    {
      label: m.prop('E-mail Domain'),
      id   : m.prop(Enum.RestrictionTypes.EMAIL_DOMAIN),
      icon : '/assets/img/ico-small-email.svg',
    },
    {
      label: m.prop('Magic Code Only'),
      id   : m.prop(Enum.RestrictionTypes.CODE),
      icon : '/assets/img/ico-small-password.svg',
    },
    {
      label   : m.prop('External SSO Only'),
      id      : m.prop(Enum.RestrictionTypes.EXTERNAL_SSO),
      icon    : '/assets/img/ico-small-corporateid.svg',
      inactive: true,
    },
    {
      label   : m.prop('No Restriction'),
      sublabel: m.prop('free access'),
      icon    : '/assets/img/ico-small-lock-open.svg',
      id      : m.prop(Enum.RestrictionTypes.NO_RESTRICTION),
    },
  ];

  var validationFt = {};

  validationFt[Enum.RestrictionTypes.NO_RESTRICTION] = validateAlwaysTrue;
  validationFt[Enum.RestrictionTypes.WHITELIST] = validateAlwaysTrue;
  validationFt[Enum.RestrictionTypes.EMAIL_DOMAIN] = validateDomain;
  validationFt[Enum.RestrictionTypes.EXTERNAL_SSO] = validateAlwaysTrue;
  validationFt[Enum.RestrictionTypes.CODE] = validateCode;

  self.originalAuthRule = args.originalList.at(args.index);

  self.restrictionTypeChanged = function restrictionTypeChanged(newType) {
    args.modified(true);
    args.authRule.restrictionId(newType.id());
    args.authRule.accessControls().forEach(function setRestrictionType(accessControl) {
      accessControl.restrictionTypeId(newType.id());
    });

    m.redraw();
  };

  self.addAccessControl = function addAccessControl(isDefault) {
    var newAccessControl = new AccessControl({
      restrictionTypeId: args.authRule.restrictionTypeId,
    });

    if (!isDefault)
      args.modified(true);
    args.authRule.accessControls().push(newAccessControl);
  };

  self.removeAccessControlAtPosition = function removeAccessControlAtPosition(i) {
    args.authRule.accessControls().items.splice(i, 1);
    args.modified(true);
    m.redraw();
  };

  self.destroy = function destroy() {
    if (!args.authRule.id())
      return args.removeRule();

    return args.authRule.destroy()
      .then(function removeFromList() {
        if (args.removeRule)
          args.removeRule();
        else
          console.warn('[authentication]', 'No action specified for destroy.');
      })
      .then(m.redraw);
  };

  function validateCode() {
    var isValid = true;

    args.authRule.accessControls().forEach(function checkCode(accessControl) {
      var code = accessControl.code().trim();

      if (!code || code.length > 10)
        isValid = false;
    });

    return isValid;
  }

  function validateDomain() {
    var isValid = true;

    args.authRule.accessControls().forEach(function checkCode(accessControl) {
      var domain = accessControl.domain().trim();

      if (!domain)
        isValid = false;
    });

    return isValid;
  }

  function validateAlwaysTrue() {
    return true;
  }

  if (!args.authRule.accessControls().length)
    self.addAccessControl(true);

  self.save = function save() {
    if (!validationFt[args.authRule.restrictionId()]()) {
      popup.open({
        type    : 'info',
        title   : 'Missing information',
        subtitle: 'It seems you forgot to fill all the fields',
        button  : 'I understand',
      });
    } else {
      self.loading(true);
      m.redraw();

      args.authRule
        .save()
        .then(function setOriginal(res) {
          self.originalAuthRule = res;
          enqueueBasicAlert({
            id: 'CONFIG_SIGNUP_SAVE_SETTINGS_SUCCESS',
            text: 'Modifications successfully saved',
            icon: 'success-circle',
            status: 'success',
            priority: 'low',
          });
        })
        .catch(function catching() {
          enqueueBasicAlert({
            id: 'CONFIG_SIGNUP_SAVE_SETTINGS_ERRORS',
            text: 'Your modifications could not be saved',
            title: 'Error',
            icon: 'alert',
            status: 'error',
            priority: 'low',
          });
        })
        .then(function resetState() {
          self.loading(false);
          args.modified(false);
          m.redraw();
        });
    }
  };

  self.addAccessControl = function addAccessControl(isDefault) {
    var newAccessControl = new AccessControl({
      restrictionTypeId: args.authRule.restrictionTypeId,
    });

    if (!isDefault)
      args.modified(true);
    args.authRule.accessControls().push(newAccessControl);
    m.redraw();
  };

};

component.view = function view(c, args) {
  return m('.authentication__settings__item', [
    m('.authentication__settings__informations', [
      m('.authentication__settings__title', [
        args.index ? 'Exception ' + args.index : 'Default',
      ]),
    ]),
    m('.authentication__settings__actions', [
      m.hide(!args.index, button, {
        class : 'button--delete button--left',
        action: c.destroy,
        value : 'Delete',
      }),
      m(button, {
        class  : 'button button--save',
        action : c.save,
        value  : 'Save',
        loading: c.loading(),
      }),
    ]),
    m('.authentication__settings__details', [
      m('.authentication__settings__details__item', [
        m('.authentication__settings__details__title', 'ID Type'),
        c.identificationTypes.map(function displayItem(item) {
          return m('.detail__signup__check .detail__signup__check--inactive', {
            style: {
              opacity: item.inactive ? 0.3 : 1,
            },
          }, [
            item.type !== 'email' ? m(Checkbox, {
              value   : item.value,
              onclick : args.modified.bind(null, true),
              disabled: item.inactive,
            }) : '',
            m('.detail__signup__ico .detail__signup__ico--' + item.type),
            m('.detail__signup__info', item.label()),
          ]);
        }),
      ]),
      m('.authentication__settings__details__item', [
        m('.authentication__settings__details__title', 'ID Validation Method'),
        m(radioSelect, {
          items        : c.authenticationTypes,
          value        : args.authRule.authenticationId,
          displayPath  : 'label',
          selectionPath: 'id',
          block        : true,
          onselect     : args.modified.bind(null, true),

        }),
      ]),
      m('.authentication__settings__details__item', [
        m('.authentication__settings__details__title', 'Access Control Restriction'),
        m(radioSelect, {
          items        : c.restrictionTypes,
          value        : args.authRule.restrictionId,
          displayPath  : 'label',
          selectionPath: 'id',
          block        : true,
          onselect     : c.restrictionTypeChanged,
        }),
      ]),
    ]),
    displaySubs(),
  ]);

  function displaySubs() {
    if (args.authRule.restrictionId() === Enum.RestrictionTypes.EXTERNAL_SSO
      || args.authRule.restrictionId() === Enum.RestrictionTypes.NO_RESTRICTION)
      return '';

    var length = args.authRule.accessControls().length;
    var key = 'item-' + args.authRule.restrictionId() + '-' + length;

    c.deletable(length > 1);

    return m('.authentication__settings__access-control__container', [
      m('.authentication__settings__access-controls__add-button__top', [
        m(reactCreator, {
          component: 'UButton',
          props    : {
            text   : 'Add Restriction parameters',
            onClick: c.addAccessControl,
          },
        }),
      ]),
      m('.authentication__settings__access-controls',
        args.authRule.accessControls().map(function displaySub(accessControl, i) {
          return m('.authentication__settings__access-control', [
            m(authSettingsAccessControl, {
              key                   : key,
              accessControl         : accessControl,
              accessControls        : args.authRule.accessControls,
              id                    : i,
              authRule              : args.authRule,
              originalAuthRule      : c.originalAuthRule,
              segmentationGroupItems: args.segmentationGroupItems,
              modified              : args.modified,
              onDelete              : c.removeAccessControlAtPosition,
              deletable             : c.deletable,
            }),
          ]);
        }).reverse(),
      ),
      length >= 3 ? m(reactCreator, {
        component: 'UButton',
        props    : {
          text   : 'Add Restriction parameters',
          onClick: c.addAccessControl,
          style  : {
            marginTop   : 27,
            marginLeft  : 10,
            marginBottom: 20,
          },
        },
      }) : '',
    ]);
  }
};

export default component;
