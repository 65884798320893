import Model from 'models/Model';

var DailySerieType = {
  endpoint  : 'api/DailySerieTypes',
  attributes: {
    label: {
      type: 'string',
    },
  },
};

export default Model.register('DailySerieType', DailySerieType);
