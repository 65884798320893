// @flow

export const loadThirdPartyScript = (id: string, src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const doesScriptExist = document.getElementById(id);

    if (doesScriptExist) {
      reject();

      return;
    }

    const script = document.createElement('script');

    script.id = id;
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (e) => reject(e);

    document.body?.appendChild(script);
  });
