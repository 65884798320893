// @flow

/**
 * Props:
 *  - style: For overriding the component's style
 *  - children: The components that will be contained in the wrapper
 */

import * as React from 'react';

import styles from './SAccordion.style';

import UIcon from 'Components/unit/UIcon/UIcon';


type Props = {|
  style: Object,
  children: React.Node,
  headerText: string,
|};

type State = {|
  isHovered: boolean,
  isSelected: boolean,
|};

export class SAccordion extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isHovered: false,
      isSelected: false,
    };
  }

  render() {
    const { style, children, headerText } = this.props;
    const { isHovered, isSelected } = this.state;

    const headerBarHovered = isHovered ? styles.headerBarHovered : {};
    const iconSelected = isSelected ? styles.iconSelected : {};
    const childrenWrapperSelected = isSelected ? styles.childrenWrapperSelected : {};

    return (
      <div
        style={{ ...styles.wrapper, ...style }}
      >
        <header
          style={{ ...styles.headerBar, ...headerBarHovered }}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleLeave}
          onClick={this.handleClick}
        >
          <span>
            { headerText }
          </span>
          <UIcon name='arrow-down' size={8} style={{ ...styles.icon, ...iconSelected }} />
        </header>
        <div style={childrenWrapperSelected}>
          {isSelected && children}
        </div>
      </div>
    );
  }
  handleClick = () => {
    const { isSelected } = this.state;

    this.setState({ isSelected: !isSelected });
  };

  handleHover = () => {
    this.setState({ isHovered: true });
  };

  handleLeave = () => {
    this.setState({ isHovered: false });
  };
}

export default SAccordion;
