import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  loader: {
    zIndex: 10,
  },
  wrapper: {
    // INFO allow scrolling this view independently of alerts
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  container: {
    width: 960,
    margin: 'auto',
    paddingTop: 82,
    display: 'flex',
    justifyContent: 'center',
  },

  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  bottomSpace: {
    height: 100,
  },
});

export default styleSheet;
