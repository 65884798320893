import React, { CSSProperties, useMemo } from 'react';
import type { FunctionComponent, MouseEvent, ReactNode } from 'react';

import type { TrackingProps } from 'Services/trackingService';

import { getTrackingDatasetFromProps } from 'Services/trackingService';

import { styles } from './ClickableArea.style';

export type ClickableAreaProps = {
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  onMouseUp?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
  cypressTestId?: string;
  tracking?: TrackingProps;
  style?: CSSProperties;
  debug?: boolean;
  children: ReactNode;
  height: number;
  width: number;
};

/**
 * Add a clickable zone beneath its children that does not take extra space.
 */
export const ClickableArea: FunctionComponent<ClickableAreaProps> = ({
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onMouseDown = () => {},
  onMouseUp = () => {},
  onClick = () => {},
  tracking = {},
  debug = false,
  style = {},
  cypressTestId,
  children,
  height,
  width,
}) => {
  const clickableZoneStyle = useMemo(
    (): CSSProperties => ({
      ...styles.clickableZone,
      backgroundColor: debug ? '#3AFD3A33' : 'transparent',
      height,
      width,
    }),
    [width, debug, height],
  );

  const containerStyle = useMemo(
    (): CSSProperties => ({
      ...styles.container,
      ...style,
    }),
    [style],
  );

  return (
    <div style={containerStyle}>
      {children}
      <div
        data-testid="clickable-zone"
        data-cypresstestid={cypressTestId}
        style={clickableZoneStyle}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        {...getTrackingDatasetFromProps(tracking)}
      />
    </div>
  );
};

export default ClickableArea;
