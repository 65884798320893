// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import UToggle from 'Components/unit/UToggle/UToggle';
import STextArea from 'Components/structural/STextArea/STextArea';
import STooltip from 'Components/structural/STooltip/STooltip';

import { actions, useSelectedActivity } from '../../../redux';

import styles from './DailySerieDisclaimerForm.style';

const TOOLTIP_ID = 'disclaimer-info';

export const DailySerieDisclaimerForm = () => {
  const dispatch = useTypedDispatch();

  const selectedActivity = useSelectedActivity();

  const specificRuleActive = selectedActivity.dailySerie.specificRuleActive;
  const onToggleChange = useCallback(active => dispatch(actions.setSpecificRuleActive(active)), [dispatch]);

  const specificRuleText = selectedActivity.dailySerie.specificRuleText;
  const onTextAreaChange = useCallback(value => dispatch(actions.setSpecificRuleText(value)), [dispatch]);

  return (
    <div>
      <div style={styles.optionLine}>
        {t('activities:overview.form.campaign_disclaimer_title')}
        <div
          data-tip
          data-for={TOOLTIP_ID}
          style={styles.infoIcon}
        >
          <UIcon
            name="info"
            size={13}
            color={COLORS.TEXT.PLACEHOLDER_DEFAULT}
          />
          <STooltip
            id={TOOLTIP_ID}
            position="top"
            content={t('activities:overview.form.campaign_disclaimer_tooltip')}
          />
        </div>
        <UToggle toggled={specificRuleActive} onChange={onToggleChange} />
      </div>

      {specificRuleActive ? (
        <STextArea
          style={styles.textArea}
          height={153}
          placeholder={t('activities:overview.form.campaign_disclaimer_placeholder')}
          maxLength={10000}
          value={specificRuleText}
          onChange={onTextAreaChange}
        />
      ): null}
    </div>
  );
};
