import { useCallback, useMemo, useState } from 'react';
import type { FocusEventHandler } from 'react';

export type InputStateStatus = 'disabled' | 'focused' | 'hovered' | 'errored' | 'none';

type UseInputStatusProps<T = Element> = {
  onFocus?: FocusEventHandler<T>,
  onBlur?: FocusEventHandler<T>,
  onHover?: (value: boolean) => void,
  autofocus?: boolean,
  disabled?: boolean,
  isInError?: boolean,
};

type UseInputStatusOutput<T = Element> = {
  handleFocus: (value: boolean) => FocusEventHandler<T>,
  handleHover: (value: boolean) => () => void,
  stateStatus: InputStateStatus,
};

const getInputStateStatus = ({
  disabled = false,
  isFocused = false,
  isHovered = false,
  isInError = false,
}: { disabled?: boolean, isFocused?: boolean, isHovered?: boolean, isInError?: boolean; }) => {
  if (disabled) {
    return 'disabled';
  }

  if (isFocused) {
    return 'focused';
  }

  if (isHovered) {
    return 'hovered';
  }

  if (isInError) {
    return 'errored';
  }

  return 'none';
};

export type UseInputStatusHook<T = Element> = (props: UseInputStatusProps<T>) => UseInputStatusOutput<T>;

/**
 * Hook to ease the input status handling, it will manage focus / hover / disable state to determine priority of these state
 *
 * @param props.onBlur - Function callback called when leaving input
 * @param props.onFocus - Function callback called when entering input
 * @param props.onHover - Function callback called when hovering input
 * @param props.autofocus - boolean which control if input should be directly focus
 * @param props.disabled - boolean which control if input should be disabled
 */
export const useInputStatus: UseInputStatusHook = ({
  onBlur,
  onFocus,
  onHover,
  autofocus = false,
  disabled = false,
  isInError = false,
}) => {
  const [isFocused, setIsFocused] = useState(autofocus);
  const [isHovered, setIsHovered] = useState(false);

  const handleFocus = useCallback((focusValue: boolean) => (event: any) => {
    setIsFocused(focusValue);

    return focusValue ? onFocus?.(event) : onBlur?.(event);
  }, [onFocus, onBlur]);

  const handleHover = useCallback((hoverValue: boolean) => () => {
    setIsHovered(hoverValue);

    return onHover?.(hoverValue);
  }, [onHover]);

  const stateStatus = useMemo(() => (
    getInputStateStatus({ disabled, isFocused, isHovered, isInError })
  ), [disabled, isFocused, isHovered, isInError]);

  return {
    handleFocus,
    handleHover,
    stateStatus,
  };
};

export default useInputStatus;
