import { handle } from 'redux-pack';

import { mergeDeep } from 'Libs/mergeDeep';
import { FilterState } from 'Libs/filter/types';
import type { Action } from 'Libs/redux/types';
import createActivityListItem from './models/ActivityListItem';
import type { ActivityListItemType } from './models/ActivityListItem';

type FilteringState = {
  selectedSegmentationIds: ReadonlyArray<number>;
  selectedActivityTypeId: number;
  selectedActivityStatusId: number;
  selectedTargetModeId: number;
  selectedActivityLanguageIds: ReadonlyArray<number>;
  selectedTextValues: ReadonlyArray<string>;
  counter: number;
  activities: ActivityListItemType[];
};

type ModuleState = {
  isFiltering: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isArchiveLoading: boolean;
  selectedCampaign: number;
  isCreationLoading: boolean;
};

export type ActivityListState = FilteringState & ModuleState;

const defaultFilteringState: FilteringState = {
  selectedSegmentationIds: [],
  selectedActivityTypeId: 0,
  selectedActivityStatusId: 0,
  selectedTargetModeId: 0,
  selectedActivityLanguageIds: [],
  selectedTextValues: [],
  counter: 0,
  activities: [],
};

const defaultModuleState: ModuleState = {
  isFiltering: false,
  isLoading: false,
  isLoadingMore: false,
  isArchiveLoading: false,
  selectedCampaign: 0,
  isCreationLoading: false,
};

export const defaultState: ActivityListState = {
  ...defaultFilteringState,
  ...defaultModuleState,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ActivityListState = defaultState, action: Action): ActivityListState => {
  switch (action.type) {
    case 'ACTIVITY_LIST_UPDATE_FILTERS': {
      let {
        selectedSegmentationIds,
        selectedActivityTypeId,
        selectedActivityStatusId,
        selectedTargetModeId,
        selectedActivityLanguageIds,
        selectedTextValues,
      } = state;

      action.selectedFilters.forEach((filter: FilterState) => {
        if (filter.category === 'segment') {
          selectedSegmentationIds = filter.ids;
        }
        if (filter.category === 'activityType') {
          selectedActivityTypeId = filter.ids.length ? filter.ids[0] : 0;
        }
        if (filter.category === 'activityStatus') {
          selectedActivityStatusId = filter.ids.length ? filter.ids[0] : 0;
        }
        if (filter.category === 'text') {
          selectedTextValues = filter.values;
        }
        if (filter.category === 'audienceMode') {
          selectedTargetModeId = filter.ids.length ? filter.ids[0] : 0;
        }
        if (filter.category === 'language') {
          selectedActivityLanguageIds = filter.ids;
        }
      });

      const isFiltering =
        selectedSegmentationIds.length > 0 ||
        selectedActivityTypeId > 0 ||
        selectedActivityStatusId > 0 ||
        selectedActivityLanguageIds.length > 0 ||
        selectedTargetModeId > 0 ||
        selectedTextValues.length > 0;

      return mergeDeep(state, {
        selectedSegmentationIds,
        selectedActivityTypeId,
        selectedActivityStatusId,
        selectedTargetModeId,
        selectedActivityLanguageIds,
        selectedTextValues,
        isFiltering,
      });
    }

    case 'ACTIVITY_LIST_GET_FULL_PAGINATED':
      return handle(state, action, {
        start: (prevState) => {
          const newLoadingState = action.meta.toAdd ? { isLoadingMore: true } : { isLoading: true };

          return mergeDeep(prevState, { selectedCampaign: 0, ...newLoadingState });
        },
        success: (prevState) => {
          const currentActivities = prevState.activities;
          const newActivities = action.payload.multilingualActivities.map(createActivityListItem);

          const activities = action.payload.toAdd ? currentActivities.concat(newActivities) : newActivities;

          return mergeDeep(prevState, { activities, counter: action.payload.count });
        },
        finish: (prevState) => mergeDeep(prevState, { isLoading: false, isLoadingMore: false }),
      });

    case 'ACTIVITY_LIST_UPDATE_ARCHIVE_STATUS':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isArchiveLoading: true }),
        success: (prevState) => {
          const changedActivity = action.payload.activities.find((act: any) => act.isDefault);
          const activities = prevState.activities.filter(
            (activity) => activity.multilingualId !== changedActivity.multilingualId,
          );

          return mergeDeep(prevState, { activities, counter: prevState.counter - 1 });
        },
        finish: (prevState) => mergeDeep(prevState, { isArchiveLoading: false }),
      });

    case 'ACTIVITY_LIST_CREATE_MULTILINGUAL_CAMPAIGN':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isCreationLoading: true }),
        success: (prevState) => {
          const activity = action.payload.activities.filter((act: any) => act.isDefault)[0];

          return mergeDeep(prevState, { selectedCampaign: activity.multilingualId });
        },
        finish: (prevState) => mergeDeep(prevState, { isCreationLoading: false }),
      });

    case 'ACTIVITY_LIST_RESET':
      return {
        ...state,
        ...defaultModuleState,
      };

    default:
      return state;
  }
};
