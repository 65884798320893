import { combineReducers } from 'redux';

import { Action } from 'Libs/redux/actions';

import { AudienceGroupState } from './audience-group/audience-group.types';
import { audienceGroupState, reducer as audienceGroupReducer } from './audience-group/audience-group.slice';
import { contentReducer, contentState } from './content/content.slice';
import { ContentState } from './content/content.types';
import { ThematicState } from './configuration/thematic/thematic.types';
import { thematicReducer, thematicState } from './configuration/thematic/thematic.slice';
import { ActivityState } from './activity/activity.type';
import { activityReducer, activityState } from './activity/activity.slice';

export type Entities = {
  audienceGroup: AudienceGroupState;
  content: ContentState;
  thematic: ThematicState;
  activity: ActivityState;
};

export const entityState: Entities = {
  audienceGroup: audienceGroupState,
  content: contentState,
  thematic: thematicState,
  activity: activityState,
};

export const entityReducers = combineReducers<Entities, Action>({
  audienceGroup: audienceGroupReducer,
  content: contentReducer,
  thematic: thematicReducer,
  activity: activityReducer,
});
