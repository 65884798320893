import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  instanceName: {
    ...TYPOGRAPHY.BODY3,
    opacity: 0.2,
    letterSpacing: 4,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    ...TYPOGRAPHY.TITAN_L,
    marginTop: 2,
  },
});

export default styleSheet;
