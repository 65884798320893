import { t } from 'i18next';
import { ValueOf } from 'ts-essentials';

import Enum from 'Models/Enum';
import { CONTENT_STATUS } from 'Store/entities/content/content.utils';

export const activityTypeLabel = (value: number) => {
  switch (value) {
    case Enum.activityTypeEnum.DAILY_SERIE:
      return t('structural_component:s_filter_box.labels.daily_serie');
    case Enum.activityTypeEnum.BREAKING_NEWS:
      return t('structural_component:s_filter_box.labels.breaking_news');
    case Enum.activityTypeEnum.WELCOME:
      return t('structural_component:s_filter_box.labels.welcome');
    case Enum.activityTypeEnum.THATS_ALL_FOLKS:
      return t('structural_component:s_filter_box.labels.thats_all_folks');
    case Enum.activityTypeEnum.INTERSEASON:
      return t('structural_component:s_filter_box.labels.interseason');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const activityStatusLabel = (value: number) => {
  switch (value) {
    case Enum.activityStatus.UPCOMING:
      return t('structural_component:s_filter_box.activity_status.upcoming');
    case Enum.activityStatus.LIVE:
      return t('structural_component:s_filter_box.activity_status.live');
    case Enum.activityStatus.FINISHED:
      return t('structural_component:s_filter_box.activity_status.finished');
    case Enum.activityStatus.INACTIVE:
      return t('structural_component:s_filter_box.activity_status.inactive');
    case Enum.activityStatus.ARCHIVED:
      return t('structural_component:s_filter_box.activity_status.archived');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const contentStatusLabel = (value: number) => {
  switch (value) {
    case Enum.contentStatus.DRAFT:
      return t('structural_component:s_filter_box.content_status.draft');
    case Enum.contentStatus.TO_VALIDATE:
      return t('structural_component:s_filter_box.content_status.to_validate');
    case Enum.contentStatus.VALIDATED:
      return t('structural_component:s_filter_box.content_status.validated');
    case Enum.contentStatus.ARCHIVED:
      return t('structural_component:s_filter_box.content_status.archived');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const dolphinContentStatusLabel = (value: ValueOf<typeof CONTENT_STATUS>) => {
  switch (value) {
    case CONTENT_STATUS.DRAFT:
      return t('structural_component:s_filter_box.content_status.draft');
    case CONTENT_STATUS.WAITING_TRANSLATION:
      return t('structural_component:s_filter_box.content_status.to_translate');
    case CONTENT_STATUS.WAITING_VALIDATION:
      return t('structural_component:s_filter_box.content_status.to_validate');
    case CONTENT_STATUS.VALIDATED:
      return t('structural_component:s_filter_box.content_status.validated');
    case CONTENT_STATUS.ARCHIVED:
      return t('structural_component:s_filter_box.content_status.archived');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const translationStatusLabel = (value: number) => {
  switch (value) {
    case Enum.translationStatus.PENDING:
      return t('structural_component:s_filter_box.translation.pending');
    case Enum.translationStatus.COMPLETE:
      return t('structural_component:s_filter_box.translation.complete');
    case Enum.translationStatus.NONE:
      return t('structural_component:s_filter_box.translation.none');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const documentStatusLabel = (value: number) => {
  switch (value) {
    case Enum.DocumentStatus.AVAILABLE:
      return t('structural_component:s_filter_box.document_status.available');
    case Enum.DocumentStatus.UNAVAILABLE:
      return t('structural_component:s_filter_box.document_status.unavailable');
    case Enum.DocumentStatus.ARCHIVED:
      return t('structural_component:s_filter_box.document_status.archived');
    default:
      return t('structural_component:s_filter_box.unknown');
  }
};

export const getAudienceTargetModeLabel = (id: number) =>
  ({
    1: t('audiences:audience_target_mode.magic_code'),
    2: t('audiences:audience_target_mode.email_list'),
    3: t('audiences:audience_target_mode.segmentation'),
  }[id]);
