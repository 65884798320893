import { handle } from 'redux-pack';

import type { Knowledge } from 'Models/react/Knowledge/Knowledge';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { FilterState } from 'Libs/filter/types';
import type { Action } from 'Libs/redux/types';

export type Filter = {
  selectedSegmentationIds: ReadonlyArray<number>;
  selectedContributorsIds: ReadonlyArray<number>;
  selectedTextValues: ReadonlyArray<string>;
  selectedActivityIds: ReadonlyArray<number>;
  selectedTranslationStatusIds: ReadonlyArray<number>;
  selectedContentStatusIds: ReadonlyArray<number>;
  selectedThematicIds: ReadonlyArray<number>;
};

export type KnowledgeGridState = DeepReadonly<{
  knowledges: ReadonlyArray<Knowledge>;
  isLoading: boolean;
  isLoadingMore: boolean;
  isFiltering: boolean;
  count: number;
  filter: Filter;
}>;

export const defaultFilter: Filter = {
  selectedSegmentationIds: [],
  selectedContributorsIds: [],
  selectedTextValues: [],
  selectedActivityIds: [],
  selectedTranslationStatusIds: [],
  selectedContentStatusIds: [],
  selectedThematicIds: [],
};

export const defaultState: KnowledgeGridState = {
  filter: defaultFilter,
  knowledges: [],
  isLoading: false,
  isLoadingMore: false,
  isFiltering: false,
  count: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: KnowledgeGridState = defaultState, action: Action): KnowledgeGridState => {
  switch (action.type) {
    case 'KNOWLEDGE_GRID_RESET':
      return defaultState;
    case 'KNOWLEDGE_GRID_GET_FULL_PAGINATED': {
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, action.meta.isInitialLoad ? { isLoading: true } : { isLoadingMore: true }),
        success: (prevState) => {
          const newKnowledges = action.meta.isInitialLoad
            ? action.payload.result
            : [...prevState.knowledges, ...action.payload.result];

          return mergeDeep(prevState, {
            count: action.payload.count,
            knowledges: newKnowledges,
          });
        },
        finish: (prevState) => mergeDeep(prevState, { isLoading: false, isLoadingMore: false }),
      });
    }
    case 'KNOWLEDGE_GRID_UPDATE_FILTERS': {
      const newFilter: Filter = action.selectedFilters.reduce((acc: Filter, filter: FilterState) => {
        if (filter.category === 'text') {
          return { ...acc, selectedTextValues: filter.values };
        }
        if (filter.category === 'segment') {
          return { ...acc, selectedSegmentationIds: filter.ids };
        }
        if (filter.category === 'campaign') {
          return { ...acc, selectedActivityIds: filter.ids };
        }
        if (filter.category === 'user') {
          return { ...acc, selectedContributorsIds: filter.ids };
        }
        if (filter.category === 'thematic') {
          return { ...acc, selectedThematicIds: filter.ids };
        }
        if (filter.category === 'translationStatus') {
          return { ...acc, selectedTranslationStatusIds: filter.ids };
        }
        if (filter.category === 'contentStatus') {
          return { ...acc, selectedContentStatusIds: filter.ids };
        }

        return acc;
      }, defaultFilter);

      const isFiltering = Object.keys(newFilter).reduce(
        (acc, k) => acc || Boolean(newFilter[k as keyof Filter].length),
        false,
      );

      return mergeDeep(state, {
        filter: newFilter,
        isFiltering,
      });
    }
    default:
      return state;
  }
};
