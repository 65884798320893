import Promise from 'bluebird';

export const LS_TOKEN_KEY = 'JWTToken';

/**
 * Getter setter for the user token
 * @param  {String} value JWT Token to be stored
 */
export const token = (value?: string): string | null => {
  if (value && typeof value === 'string') {
    localStorage.setItem(LS_TOKEN_KEY, value);
  }

  return localStorage.getItem(LS_TOKEN_KEY);
};

/**
 * Read Synchronously in the localStorage
 * @param  {String} key Record to return from the LS
 */
export const getSync = <T>(key: string): T => {
  const ret = localStorage.getItem(`${token()}/${key}`);

  if (ret !== 'undefined' && ret) {
    return (JSON.parse(ret) as T);
  }

  throw new Error([token(), '/', key, ' not found'].join(''));
};

/**
 * Read in the localStorage
 * @param  {String} key Record to return from the LS
 */
export const get = <T>(key: string): Promise<T> => new Promise((resolve, reject) => {
  try {
    const value = getSync<T>(key);

    resolve(value);
  } catch (e) {
    reject(e);
  }
});

/**
 * Write Synchronously in the localStorage
 * @param  {String} key   Key of the record to write in the LS
 * @param  {Object} value Value to write in the LS[key]
 */
export const postSync = (key: string, value: unknown) => {
  const rec = JSON.stringify(value);

  localStorage.setItem(`${token()}/${key}`, rec);

  return value;
};

/**
 * Write in the localStorage
 * @param  {String} key   Key of the record to write in the LS
 * @param  {Object} value Value to write in the LS[key]
 */
export const post = async (key: string, value: unknown) => new Promise((resolve, reject) => {
  try {
    resolve(postSync(key, value));
  } catch (e) {
    reject(e);
  }
});

/**
 * Delete a record from the localStorage
 * @param  {String} key Key of the record to delete
 */
export const destroy = async (key: string): Promise<void> => localStorage.removeItem(`${token()}/${key}`);

/**
 * Clear all record in the localStorage
 */
export const clear = async () => localStorage.clear();

export default {
  token,
  get,
  getSync,
  post,
  postSync,

  destroy,
  clear,
};
