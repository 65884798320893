import m from 'm';

var component = {};

component.controller = function controller() {
  var self = this;

  self.clickButton = function clickButton(args) {
    args.value(!args.value());
    if (args.onclick)
      args.onclick();
  };
};

component.view = function view(c, args) {
  var disabled = false;

  if (args.disabled)
    disabled = true;

  return m('.toggle-button-container', {
    onclick: !disabled ? c.clickButton.bind(null, args) : (args.noop || function noop() {}),
    class  : [
      'toggle-button-container--' + (args.value() ? 'on' : 'off'),
      disabled ? 'toggle-button--disabled' : '',
      args.class ? args.class : '',
    ].join(' '),
  }, [
    m('.toggle-button-container__button'),
  ]);
};

export default component;
