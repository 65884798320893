import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  modal: {
    height: 430,
  },
  modalWithMock: {
    height: 470,
  },
  contentWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    marginBottom: 32,
  },
  description: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.GREY_DARKER.HOVER,
    marginTop: 0,
    marginBottom: 16,
  },
  body: {
    overflow: 'hidden',
  },
  footer: {
    paddingBottom: 16,
  },
  mockButton: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: 4,
  },
});

export default styleSheet;
