// @flow

/**
 * Display a navigation button
 *
 * Props:
 * - total : maximum position
 * - visible : the component is visible
 * - onPrev : callback when user click on prev button
 * - onNext : callback when user click on next button
 */

import * as React from 'react';
import { css } from 'aphrodite';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles, { extendedStyles } from './UNavigationButton.style';


type Props = $ReadOnly<{|
  style?: ?Object,

  // Required
  total: number,
  onPrev: (number: number) => void,
  onNext: (number: number) => void,

  // Optional
  visible: boolean,
|}>;

type State = {|
  current: number,
|};

export class UNavigationButton extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    visible: false,
  };

  state = {
    current: 1,
  };

  render() {
    const { current } = this.state;
    const { style, total, visible } = this.props;

    const visibilityStyle = (visible) ? { opacity : 1 } : '';

    return (
      <div style={{ ...styles.wrapper, ...visibilityStyle, ...style }}>
        <div
          style={{ ...styles.button }}
          className={css(extendedStyles.buttonLeft)}
          onClick={this.handleButtonPrev}
          data-test='prev'
        >
          <UIcon
            name="chevron-left"
            size={8}
            color={COLORS.TEXT.SECONDARY_DEFAULT}
          />
        </div>
        <div style={{ ...styles.text }}>
          {`${current} / ${total}`}
        </div>
        <div
          style={{ ...styles.button }}
          className={css(extendedStyles.buttonRight)}
          onClick={this.handleButtonNext}
          data-test='next'
        >
          <UIcon
            name="chevron-right"
            size={8}
            color={COLORS.TEXT.SECONDARY_DEFAULT}
          />
        </div>
      </div>
    );
  }

  handleButtonPrev = () => {
    const { onPrev } = this.props;
    const { current } = this.state;

    if (current <= 1)
      return;

    const prevPosition = current - 1;

    this.setState({ current: prevPosition });
    onPrev(prevPosition);
  };

  handleButtonNext = () => {
    const { total, onNext } = this.props;
    const { current } = this.state;

    if (current >= total)
      return;

    const nextPosition = current + 1;

    this.setState({ current: nextPosition });
    onNext(nextPosition);
  };
}

export default UNavigationButton;
