import React from 'react';

import { getCells } from 'Components/structural/STable/components/CellsComponents';
import styles from 'Components/structural/STable/STable.style';
import CallbackOptions from 'Components/structural/STable/components/CallbackOptions';
import { STableRow } from 'Components/structural/STable/types';

type RenderRowProps = {
  prepareRow: (row: STableRow) => void;
  row: STableRow;
};

function RenderRow({ prepareRow, row }: RenderRowProps) {
  const originalRow = row.original;

  const { id: rowIndex, locked, callbackOptions } = originalRow;

  const cells = getCells(row.values, locked || false);

  prepareRow(row);

  return (
    <div {...row.getRowProps()} key={rowIndex}>
      {row.cells.map((cell, cellIndex: number) => {
        if (!cell.value) throw new Error(`Error in row '${rowIndex}', missing value for column with id: ${cellIndex}`);

        const cellKey = cell.value.id;

        const cellStyle = {
          ...cell.getCellProps().style,
          ...styles.cell,
          ...cell.value.wrapperStyle,
        };

        return (
          <div {...cell.getCellProps()} key={cellKey} style={cellStyle}>
            {cells[cellIndex]}
          </div>
        );
      })}
      <CallbackOptions row={originalRow} callbackOptions={callbackOptions} />
    </div>
  );
}

export default RenderRow;
