import Model from 'models/Model';

var ThatsAllFolksCard = {
  endpoint  : 'api/ThatsAllFolksCards',
  attributes: {
    description: {
      type: 'string',
    },
    buttonLabel: {
      type: 'string',
    },
    url: {
      type: 'string',
    },
    displayUrl: {
      type: 'boolean',
    },
    activityId: {
      type: 'number',
    },
  },
};

export default Model.register('ThatsAllFolksCard', ThatsAllFolksCard);
