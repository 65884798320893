/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';

import EnumMedia from 'models/MediaHandlerEnum';
import Model from 'models/Model';
import Collection from 'models/Collection';
import deviceScreen from 'components/device.screen';
import mediaSelector from 'components/content.media.selector';
import contentHeader from 'components/content.header';
import inputExplanationText from 'components/input.explanation.text';

import isRtl from 'services/RtlManagement/RtlManagement';

var component = {};
var GameItem = Model('GameItem');
var NB_MAX_SUBS = 4;
var MAX_NB_CHAR = 100;
var frontCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.ERRORTEXT_FRONT,
};

var backCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.ERRORTEXT_BACK,
};

component.controller = function controller(args) {
  var self = this;

  self.readonly = m.prop(false);
  self.trueErrorTextFocus = m.prop(false);
  self.falseErrorTextFocus = m.prop(false);
  self.falsyText = m.prop(generateFalsyText());
  self.error = m.prop('');
  self.state = m.prop('');

  self.getAIConfig = function getAIConfig() {
    if (!args.aiEnabled())
      return null;

    const { game: errorText, id } = args.content();

    return {
      targetTexts: [
        errorText().explanation,
      ],
      contentId: id,
    };
  };

  self.formatAnswers = function formatAnswers(_args) {
    if (!_args.content().id())
      _args.content().presetFromKnowledge(_args.knowledge());
  };

  self.formatAnswers(args);

  self.isFalsyValid = function isFalsyValid() {
    var result = true;
    var oText = args.content().game().originalText().data();
    var realWords = oText.split(' ');
    var noRealWords = self.falsyText().split(' ');
    var nbWrongNeeded = args.content().game().substitutes().length;
    var wrongWords = [];

    if (realWords.length !== noRealWords.length)
      result = false;

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i])
        wrongWords.push(noRealWords[i]);
    }

    if (wrongWords.length !== nbWrongNeeded)
      result = false;

    return result;

  };

  self.diff = m.prop({});
  self.error = m.prop('');
  self.editingTruthy = m.prop(false);
  self.editingFalsy = m.prop(false);

  self.onfocus = function onfocus(truthy) {
    if (truthy)
      self.editingTruthy(true);
    else
      self.editingFalsy(true);
  };

  self.onblur = function onblur(truthy) {
    if (truthy)
      self.editingTruthy(false);
    else
      self.editingFalsy(false);
  };

  self.textareaOnKeyDown = function textareaOnKeyDown(e) {
    if (e.keyCode === 13)
      e.target.blur();

  };

  self.computeErrorText = function computeErrorText(_args, e) {
    if (!_args.content().game())
      return;

    if (!args.content().game().originalText().data())
      return;

    var rightPhrase = _args.content().game().originalText().data;
    var wrongPhrase = self.falsyText;

    rightPhrase(rightPhrase().replace(/\s+/g, ' ').trim());
    wrongPhrase(wrongPhrase().replace(/\s+/g, ' ').trim());

    var realWords = rightPhrase().split(' ');
    var noRealWords = wrongPhrase().split(' ');

    var wrongWords = [];
    var wrongWordsPositions = [];

    self.falseErrorTextFocus(false);
    self.trueErrorTextFocus(false);

    if (realWords.length !== noRealWords.length) {
      self.state('missmatch');

      return;
    }

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i]) {
        wrongWords.push(noRealWords[i]);
        wrongWordsPositions.push(i);
      }
    }

    if (!wrongWordsPositions.length || wrongWordsPositions.length > 4)
      self.state('boundaries');

    var positions = {};
    var formerSubstitutes = _args.content().game().substitutes().items;

    _args.content().game().substitutes(new Collection(Model('GameItem')));

    for (var j = 0, y = wrongWords.length; j < y; j++) {
      var toSave = formerSubstitutes[j] || new GameItem();

      toSave.data(wrongWords[j]);
      positions[wrongWordsPositions[j]] = toSave.id() || null;
      _args.content().game().substitutes().push(toSave);
    }
    _args.content().game().substitutePositions(positions);

    if (!wrongWordsPositions.length || wrongWordsPositions.length > 4)
      self.state('boundaries');
    else
      self.state('correct');

    var falsyText = _args.content().game().getFalsyText();

    self.falsyText(falsyText.join(' '));
  };

  self.validateErrorText = function validateErrorText() {
    if (!args.content().game())
      return;

    if (!args.content().game().originalText().data()) {
      m.redraw();

      return;
    }

    var oText = args.content().game().originalText().data();
    var realWords = oText.split(' ');
    var noRealWords = self.falsyText().split(' ');
    var wrongWords = [];
    var wrongWordsPositions = [];

    self.falseErrorTextFocus(false);
    self.trueErrorTextFocus(false);

    if (realWords.length !== noRealWords.length) {
      self.state('missmatch');
      m.redraw();
    }

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i]) {
        wrongWords.push(noRealWords[i]);
        wrongWordsPositions.push(i);
      }
    }

    if (!wrongWordsPositions.length || wrongWordsPositions.length > NB_MAX_SUBS) {
      self.state('boundaries');
      m.redraw();
    } else {
      self.state('correct');
      m.redraw();
    }

    var falsyText = args.content().game().getFalsyText();

    self.falsyText(falsyText.join(' '));
  };

  self.validateErrorText();

  function generateFalsyText() {
    var positions = Object.keys(args.content().game().substitutePositions());
    var splitted = args.content().game().originalText().data().split(' ');

    for (var i = 0, l = positions.length; i < l; i++) {
      var gi = args.content().game().substitutes()
        .get(args.content().game().substitutePositions()[positions[i]]);

      splitted[positions[i]] = gi ? gi.data() : '';
    }

    return splitted.join(' ');
  }
};

component.view = function view(c, args) {
  var errorText = args.content().game();

  return m('.content-edition', {
    config: c.configSize,
  }, [
    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:error_text.label'),
        gameplayName   : 'ERROR_TEXT',
        explanationText: args.content().getDirective({
          state: c.state(),
        }),
      }),
      m('.content-edition__page-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            m(mediaSelector, {
              images            : args.knowledge().originalImages,
              image             : errorText.imageFront,
              video             : errorText.videoFront,
              type              : errorText.mediaTypeFrontId,
              mediaPositionFront: true,
              editable          : args.editable(),
              knowledge         : args.knowledge,
              cropProps         : frontCropProps,
              getAIConfig       : c.getAIConfig,
              class             : 'content-media-selector--error-text',
            }),
            m('.content-edition__page__error-text-workspace', [
              m('.error-text-workspace__handler', [
                m('.error-text-workspace__label.error-text-workspace__label--right',
                  m('.error-text-workspace__label__txt', {
                    class: c.trueErrorTextFocus() ? 'error-text-workspace__label__txt--focus' : '',
                  }, t('gameplays:error_text.right_sentence'))),
                m('.error-text-workspace__txt-container', [
                  c.trueErrorTextFocus()
                    ? m('textarea#trueErrorText.error-text-workspace__txt', {
                      maxlength: 100,
                      dir: 'auto',
                      value    : args.content().game().originalText().data(),
                      onfocus  : c.onfocus.bind(null, args.content().game().originalText().data),
                      onkeydown: c.textareaOnKeyDown,
                      config   : c.initTextarea,
                      onblur   : c.computeErrorText.bind(null, args),
                      oninput  : m.withAttr('value', args.content().game().originalText().data),

                      // onchange : m.withAttr('value', args.content().game().originalText().data),
                      placeholder: t('gameplays:error_text.right_sentence_placeholder'),
                    })
                    : m('.error-text-workspace__txt', {
                      onclick: function () {
                        if (!args.editable())
                          return;
                        c.trueErrorTextFocus(true);
                        m.redraw();
                        document.querySelector('#trueErrorText').focus();
                      },
                    }, getRightSentenceText(c, args)),
                  m('.error-text-workspace__maxlength',
                    m.hide(!c.trueErrorTextFocus(), '.error-text-workspace__maxlength__text',
                      args.content().game().originalText().data().length + ' / ' + MAX_NB_CHAR
                    )
                  ),
                ]),
              ]),
              m('.error-text-workspace__handler', [
                m('.error-text-workspace__label.error-text-workspace__label--wrong',
                  m('.error-text-workspace__label__txt', {
                    class: c.falseErrorTextFocus() ? 'error-text-workspace__label__txt--focus' : '',
                  },  t('gameplays:error_text.wrong_sentence'))),
                m('.error-text-workspace__txt-container', [
                  c.falseErrorTextFocus()
                  ? m('textarea#falseErrorText.error-text-workspace__txt', {
                    dir: 'auto',
                    maxlength: 100,
                    value    : c.falsyText(),
                    onfocus  : c.onfocus.bind(null, c.falsyText),
                    config   : c.initTextarea,
                    onblur   : c.computeErrorText.bind(null, args),
                    onkeydown: c.textareaOnKeyDown,

                    // onchange : m.withAttr('value', c.falsyText),
                    oninput    : m.withAttr('value', c.falsyText),
                    placeholder:  t('gameplays:error_text.wrong_sentence_placeholder'),
                  })
                  : m('.error-text-workspace__txt', {
                    onclick: function () {
                      if (!args.editable())
                        return;
                      c.falseErrorTextFocus(true);
                      m.redraw();
                      document.querySelector('#falseErrorText').focus();
                    },
                  }, getWrongSentenceText(c, args)),
                  m('.error-text-workspace__maxlength',
                    m.hide(!c.falseErrorTextFocus(), '.error-text-workspace__maxlength__text',
                      c.falsyText().length + ' / ' + MAX_NB_CHAR
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : errorText.explanation,
              placeholder: t('gameplays:error_text.explanation_placeholder'),
              editable   : args.editable(),
            }),
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : errorText.imageBack,
              video    : errorText.videoBack,
              type     : errorText.mediaTypeBackId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: backCropProps,
              getAIConfig: c.getAIConfig,
            }),
          ]),
        ]),
      ]),
    ]),
  ]);
};

function getRightSentenceText(c, args) {
  var originalText = args.content().game().originalText().data();

  if (originalText.length > 0 && c.isFalsyValid())
    return m('', displayTrueByWords(c, args));
  else if (originalText.length > 0)
    return m('.translation-table__row__input--incorrect', originalText);

  return m('.translation-table__row__input--empty', t('gameplays:error_text.right_sentence_placeholder'));
}

function getWrongSentenceText(c, args) {
  var originalText = args.content().game().originalText().data();
  var falsyText = c.falsyText();

  if (originalText.length > 0 && c.isFalsyValid())
    return m('', displayFalseByWords(c, args));
  else if (falsyText.length > 0)
    return m('.translation-table__row__input--incorrect', falsyText);

  return m('.translation-table__row__input--empty', t('gameplays:error_text.wrong_sentence_placeholder'));
}

function displayTrueByWords(c, args) {
  var gi = args.content().game().originalText();
  var positions = args.content().game().substitutePositions();
  var words = gi.data().split(' ');
  var count = 1;

  const arrayOfWords = words.map(function displayWord(word, key) {
    var tempCount = count;

    if (positions.hasOwnProperty(key))
      count++;

    return m('span', {
      dir: 'auto',
      class: tempCount <= 4 && positions.hasOwnProperty(key)
        ? ['error-text-workspace__falsy-word', 'error-text-workspace__falsy-word-' + tempCount].join(' ')
        : '',
    }, word + ' ');
  });

  if (isRtl(gi.data()))
    return arrayOfWords.reverse();

  return arrayOfWords;
}

// eslint-disable-next-line complexity
function displayFalseByWords(c, args) {
  var gi = args.content().game().originalText();
  var positions = args.content().game().substitutePositions();
  var substitutes = args.content().game().substitutes();
  var words = gi.data().split(' ');
  var tmp = 0;
  var count = 1;

  for (var pos in positions) {
    if (positions.hasOwnProperty(pos)) {
      var id = positions[pos];
      var sub = id
        ? substitutes.get(positions[pos])
        : substitutes.at(tmp);

      words[pos] = sub.data();
      tmp++;
    }
  }

  const arrayOfWords = words.map(function displayWord(word, key) {
    var tempCount = count;

    if (positions.hasOwnProperty(key))
      count++;

    return m('span', {
      dir: 'auto',
      class: tempCount <= 4 && positions.hasOwnProperty(key)
        ? ['error-text-workspace__falsy-word', 'error-text-workspace__falsy-word-' + tempCount].join(' ')
        : '',
    }, word + ' ');
  });

  if (isRtl(gi.data()))
    return arrayOfWords.reverse();

  return arrayOfWords;
}

export default component;
