import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  badge: {
    padding: 1,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    marginRight: 8,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  titleWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  placeholder: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  title: {
    ...TYPOGRAPHY.H2,
    color: COLORS.BLACK.DEFAULT,
  },
  translated: {
    marginLeft: 10,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  status: {
    height: 15,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 4,
    ...TYPOGRAPHY.BODY4,
  },
  dot: {
    width: 8,
    height: 8,
    marginTop: 1,
    marginRight: 8,
    borderRadius: '50%',
  },
  helper: {
    zIndex: LEVELS.LOCAL_OFFSET,
    cursor: 'pointer',
    marginTop: 3,
    marginLeft: 10,
  },
  topActionBarHelper: {
    whiteSpace: 'normal',
  },
});
