import Promise from 'bluebird';
import Request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';
import { fetchFactory } from 'Libs/http/utils';

var AuthorizedEmail = {
  endpoint  : 'api/AuthorizedEmails',
  attributes: {
    email: {
      type: 'string',
    },
    accessControlId: {
      type: 'number',
    },
  },
  classMethods: {
    upsertSeveral: function upsertSeveral(_AuthorizedEmail, _authorizedEmails) {
      var emailsFromHostPromise = Request
        .get('api/AccessControls', { filter: { include: 'emails' }})
        .then(extractEmailsFromAccessControl);

      var sortedAuthorizedEmailsPromise = Promise
        .join(emailsFromHostPromise, _authorizedEmails, sortAndValidateAuthorizedEmails);

      var existingAuthorizedEmailsPromise = sortedAuthorizedEmailsPromise
        .get('alreadyExisting');

      var createdAuthorizedEmailsPromise = sortedAuthorizedEmailsPromise
        .get('toCreate')
        .map(postAuthorizedEmail);

      return Promise
        .join(existingAuthorizedEmailsPromise, createdAuthorizedEmailsPromise, mergeInCollection);

      function extractEmailsFromAccessControl(accessControls) {
        const emails =  accessControls.reduce((authEmails, accessControl) => {
          if (accessControl?.authenticationSettingsId)
            authEmails.push(accessControl.emails);

          return authEmails.flat();
        }, []);

        return emails;
      }

      function sortAndValidateAuthorizedEmails(emailsFromHost, authorizedEmails) {
        return authorizedEmails.reduce(function reducer(sortedAuthorizedEmails, authorizedEmail) {
          if (authorizedEmail.id())
            sortedAuthorizedEmails.alreadyExisting.push(authorizedEmail);
          else if (emailsFromHost.indexOf(authorizedEmail.email()) > -1)
            throw new Error(`The email address ${authorizedEmail.email()} is already used in an other restriction setting.`);
          else
            sortedAuthorizedEmails.toCreate.push(authorizedEmail.toJSON());

          return sortedAuthorizedEmails;
        }, { alreadyExisting: [], toCreate: []});
      }

      function postAuthorizedEmail(jsonObject) {
        return Request.post('api/AuthorizedEmails', jsonObject);
      }

      function mergeInCollection(existingAuthorizedEmails, createdAuthorizedEmails) {
        var merged = existingAuthorizedEmails.concat(createdAuthorizedEmails);

        return new Collection('AuthorizedEmail', AuthorizedEmail, merged);
      }

    },
  },
};

export default Model.register('AuthorizedEmail', AuthorizedEmail);
