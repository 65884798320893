/* eslint-disable complexity */
// @flow

/**
  * Display a modal that handle gameplay preview
  *
  * Props:
  *
  * - title: change the title in the information panel
  * - subtitle: change the subtitle in the information panel
  * - url: change the url to generate the QRCode (@see SQRCodeIndicator) and to give to the Iframe (@see SPhoneFrame)
  * - onCloseModal: callback triggered on modal closing
  * - visible: if the modal should be displayed, by default false
  * - qrCodeLabel: the label to display above the QRCode
  * - refreshLabel: the text to display in the @see UButton which refresh IFrame
  * - onRefresh: callback triggered when we click on refresh button
  * - style: custom style to override the modal's style
 */

import * as React from 'react';
import ReactModal from 'react-modal';
import { t } from 'i18next';

import UButton from 'Components/unit/UButton/UButton';
import UIconButton from 'Components/unit/UIconButton/UIconButton';

import SQRCodeIndicator from 'Components/structural/SQRCodeIndicator/SQRCodeIndicator';
import SPhoneFrame from 'Components/structural/SPhoneFrame/SPhoneFrame';

import styles from './MGameplayPreview.style';

type Props = {|
  title: string,
  subtitle: string,
  url: string,
  onCloseModal: Function,
  visible?: boolean,
  qrCodeLabel?: string,
  refreshLabel?: string,
  onRefresh?: () => {},
  style?: Object,
|};

type State = {|
  refreshToggle: boolean,
|};

export class MGameplayPreview extends React.Component<Props, State> {
  static defaultProps = {
    visible: false,
    qrCodeLabel: '',
    refreshLabel: '',
    onRefresh: undefined,
    style: undefined,
  };

  state = {
    refreshToggle: false,
  };

  constructor(props: Props) {
    super(props);

    ReactModal.setAppElement('body');
  }

  render() {
    const {
      visible,
      style,
    } = this.props;

    return (
      <div style={style}>
        <ReactModal
          isOpen={visible}
          onRequestClose={this.handleCloseModal}
          style={styles.modalStyle}
        >
          <div style={styles.bodyContainer}>
            {this.renderPreviewPanel()}
            {this.renderInformationPanel()}
          </div>
        </ReactModal>
      </div>
    );
  }

  renderPreviewPanel = () => {
    const { url } = this.props;
    const { refreshToggle } = this.state;

    return (
      <div style={styles.previewPanel}>
        <div style={styles.phoneFrameWrapper}>
          <SPhoneFrame
            url={url}
            toggleReload={refreshToggle}
          />
          {this.renderRefreshButton()}
        </div>
      </div>
    );
  };

  renderRefreshButton = () => {
    const { refreshLabel } = this.props;

    return (
      <UButton
        text={refreshLabel || t('gameplays:restart_game')}
        leftIcon='refresh'
        onClick={this.handleToggleRefresh}
        style={styles.refreshButton}
      />
    );
  };

  renderInformationPanel = () => {
    const {
      title,
      subtitle,
      url,
      qrCodeLabel,
    } = this.props;

    return (
      <div style={styles.informationPanel}>
        <div style={styles.actionContainer}>
          <UIconButton
            icon='close-light'
            onClick={this.handleCloseModal}
            ghost
          />
        </div>
        <div style={styles.titleContainer}>
          <span style={styles.title}>
            {title}
          </span>
          <span style={styles.subtitle}>
            {subtitle}
          </span>
        </div>
        <SQRCodeIndicator
          qrContent={url}
          title={qrCodeLabel}
          size={styles.qrCode.width}
          style={styles.qrCodeContainer}
        />
      </div>
    );
  };

  handleToggleRefresh = () => {
    const { onRefresh } = this.props;
    const { refreshToggle } = this.state;

    this.setState({ refreshToggle: !refreshToggle });

    if (onRefresh)
      onRefresh();
  };

  handleCloseModal = () => {
    const { onCloseModal } = this.props;

    onCloseModal();
  };

}

export default MGameplayPreview;
