import m from 'm';
import GiftOrder from 'models/GiftOrder';
import button from 'components/button';
import radioSelect from 'components/radio.select';
import inputDate from 'components/input.date';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.options = [{
    type : 'allTime',
    label: m.prop('All time'),
    id   : m.prop(1),
  },
  {
    type : 'period',
    label: m.prop('Period'),
    id   : m.prop(2),
  },
  ];

  self.choice = m.prop(1);
  self.from = m.prop(new Date());
  self.to = m.prop(new Date());

  self.exportOrders = function exportOrders() {
    GiftOrder.exportOrders(self.choice() === 2, self.from(), self.to())
      .then(function close() {
        args.close();
      });
  };

};

component.view = function view(c, args) {

  return m('.export-orders-popup.popup', [
    m('.popup__body', [
      m('.popup__title', 'Export orders'),
      m('.popup-export-orders', [
        m('.popup-export-orders__choices', [
          m(radioSelect, {
            items        : c.options,
            value        : c.choice,
            displayPath  : 'label',
            selectionPath: 'id',
            block        : true,
          }),
        ]),
        m('.popup-export-orders__dates', {
          class: c.choice() === 1 ? 'hidden' : '',
        }, [
          m(inputDate, {
            label: 'From:',
            value: c.from,
          }),
          m(inputDate, {
            label: 'To:',
            value: c.to,
          }),
        ]),
      ]),
    ]),
    m('.popup__footer', [
      m(button, {
        value : 'Cancel',
        class : 'button--cancel',
        action: args.close,
      }),
      m(button, {
        value : 'Export',
        class : 'button--action button--ok',
        action: c.exportOrders,
      }),
    ]),
  ]);
};

export default component;
