import { StyleSheet } from 'aphrodite';
import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    flex: 1,
    display: 'flex',
    height: 64,
    backgroundColor: 'white',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  wrapperInfos: {
    display: 'flex',
    height: 64,
    whiteSpace: 'nowrap',
    zIndex: LEVELS.LOCAL_OFFSET_3,
  },
  wrapperTabs: {
    position: 'relative',
    width: '100%',
    top: '-28px',
    order: 3,
    zIndex: LEVELS.LOCAL_OFFSET,
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    boxSizing: 'border-box',
    height: '28px',
  },
  wrapperActions: {
    display: 'flex',
    height: 64,
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  backButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    marginTop: 16,
    marginLeft: 20,
    marginBottom: 16,
    marginRight: 24,
  },
  saveButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    marginTop: 16,
    marginLeft: 16,
    marginBottom: 16,
    marginRight: 20,
  },
  topActionBar: {
    marginTop: 12,
  },
  optionButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionDropDown: {
    position: 'absolute',
    top: 35,
    right: 0,
  },
  lastEdit: {
    textAlign: 'right',
    ...TYPOGRAPHY.BODY4,
    width: '120px',
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  spacer: {
    width: 1,
    height: 44,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  toggle: {
    marginRight: 20,
  },
  lock: {
    zIndex: LEVELS.LOCAL_OFFSET,
    height: 63,
    width: 60,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
  },
  languageButton: {
    marginLeft: 20,
  },
});

export const extendedStyles = StyleSheet.create({
  wrapper: {
    '@media (max-width: 1224px)': {
      height: 'auto',
      paddingBottom: 20,
    },
  },
  wrapperTabs: {
    '@media (max-width: 1224px)': {
      top: 20,
      backgroundColor: 'white',
    },
  },
  border: {
    '@media (max-width: 1224px)': {
      borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
      flex: 1,
    },
  },
});
