// @flow

export default {
  wrapper: {
    height: '100%',
    width: '100%',

    // compensate top menu
    paddingTop: 104,
  },
};
