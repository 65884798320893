import { Crop } from 'react-image-crop';

import type { ImageDimensionType } from '../types';
import { PADDING_WRAPPER } from '../constants';

/**
 * Creates an image from the source image and the cropping parameters
 *
 * @param imageRef image element living in ReactCrop component
 * @param scaleX TODO
 * @param scaleY TODO
 * @param crop cropping parameters
 * @returns cropped image as a base64 string
 */
export const getImageUrl = (imageRef: HTMLImageElement, scaleX: number, scaleY: number, crop: Crop) => {
  const canvas = document.createElement('canvas');

  canvas.width = crop.width ? crop.width * scaleX : scaleX;
  canvas.height = crop.height ? crop.height * scaleY : scaleY;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.drawImage(
      imageRef,
      crop.x ? crop.x * scaleX : scaleX,
      crop.y ? crop.y * scaleY : scaleY,
      crop.width ? crop.width * scaleX : scaleX,
      crop.height ? crop.height * scaleY : scaleY,
      0,
      0,
      canvas.width,
      canvas.height,
    );
  }

  return canvas.toDataURL();
};

/**
 * Compute the dimensions of the ReactCrop image container
 *
 * @param originalSize image original dimensions
 * @param heightMax maximum value of the container height
 * @param widthMax maximum value of the container width
 * @returns dimensions of the container
 */
export const computeImgDimensions = (originalSize: ImageDimensionType, heightMax: number, widthMax: number) => {
  const ratio = originalSize.width / originalSize.height;
  const height = heightMax - PADDING_WRAPPER * 2;
  const width = widthMax - PADDING_WRAPPER * 2;
  if (originalSize.height <= height) {
    if (originalSize.width <= width) {
      return {
        width: originalSize.width,
        height: originalSize.height,
      };
    }

    return {
      width,
      height: width / ratio,
    };
  }

  const containerRatio = widthMax / heightMax;
  if (originalSize.width <= width || ratio <= containerRatio) {
    return {
      width: ratio * height,
      height,
    };
  }

  return {
    width,
    height: width / ratio,
  };
};

/**
 * Compute initial crop state from image dimensions
 *
 * @param imageDimensions original size of the image
 * @returns a crop object
 */
export const computeCrop = (imageDimensions: ImageDimensionType) => {
  const xInPx = (imageDimensions.width / 100) * 7.5;
  const yInPx = (imageDimensions.height / 100) * 10;
  const widthInPx = (imageDimensions.width / 100) * 85;
  const heightInPx = (imageDimensions.height / 100) * 80;

  return {
    unit: 'px',
    x: xInPx,
    y: yInPx,
    width: widthInPx,
    height: heightInPx,
  } as Crop;
};
