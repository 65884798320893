import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
    marginRight: 10,
    height: 23,
  },
  wrapperMultiSelected: {
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderRadius: 4,
  },
  wrapperSelect: {
    paddingLeft: 4,
  },
  wrapperTextIcon: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    paddingRight: 4,
  },
  wrapperText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontSize: 14,
  },
  dropdownbox: {
    display: 'inline-block',
    position: 'absolute',
    top: 26,
    zIndex: 999,
  },
  iconSelect: {
    marginLeft: 12,
  },
  iconMultiSelect: {
    marginLeft: 8,
  },
});

export default styleSheet;
