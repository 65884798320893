import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { redirect } from 'Libs/routing/redirect';

import SShortcutCard from 'Components/structural/SShortcutCard/SShortcutCard';

type CampaignBlockProps = {
  title: string;
  size: '100%' | '50%' | '33%';
  status?: 'disabled' | 'active' | 'locked';
  align?: 'left' | 'center';
  link?: string;
  style?: CSSProperties;
};

const noop = () => {};
const redirectToCampaign = () => redirect('/activity?newActivity=campaign');

const CampaignBlock = ({ title, style, size, link, status = 'active', align = 'center' }: CampaignBlockProps) => {
  const { t } = useTranslation();

  return (
    <SShortcutCard
      illustration="campaign"
      title={title}
      body={t('home:start_here_block.campaign_description')}
      link={link}
      onLearnMorePress={noop}
      onNavigationClick={redirectToCampaign}
      status={status}
      size={size}
      align={align}
      style={style}
      trackingAction="create"
      trackingContext="campaign-from-homepage"
    />
  );
};

export default CampaignBlock;
