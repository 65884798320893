import moment from 'moment';
import { t } from 'i18next';

import Enum from 'Models/Enum';
import { mergeDeep } from 'Libs/mergeDeep';

import type { SegmentationGroupItemType } from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';
import type { LanguagesCodeType } from 'Libs/ts/types';

type ActivityType =
  | typeof Enum.activityTypeEnum.DailySerie
  | typeof Enum.activityTypeEnum.BreakingNews
  | typeof Enum.activityTypeEnum.Welcome
  | typeof Enum.activityTypeEnum.ThatsAllFolks
  | typeof Enum.activityTypeEnum.Interseason;

type ActivityStatusType =
  | 'Upcoming'
  | 'Live'
  | 'Visible'
  | 'Finished'
  | 'Inactive'
  | 'Archived'
  | 'Draft'
  | 'Pending translation';

type SegmentType = Readonly<{
  value: string;
  color?: string;
}>;

type AudienceSegmentType = {
  segments: Array<SegmentType>;
};

type AudienceMagicCodeType = {
  label: string;
  magicCode: string;
};

type AudienceEmailListType = {
  labelEmail: string;
};

type AudienceDailySerieType = {
  campaignName: string;
};

type AudienceType = AudienceSegmentType | AudienceMagicCodeType | AudienceEmailListType | AudienceDailySerieType;

type Audience = AudienceType | null;

export type ActivityListItemType = {
  multilingualId: number;
  title: string;
  activityType: ActivityType;
  activityStatus: ActivityStatusType;
  activityStatusLabel: string;
  startDate: string;
  endDate: string;
  audience: Audience;
  image?: string;
  languages: ReadonlyArray<string>;
  activityIds: ReadonlyArray<number>;
  route: string;
  hasPlayerPlayed: boolean;
  views?: number;
  likes?: number;
};

const DATE_FORMAT = 'DD/MM/YYYY';

function getTranslationProgress(activities: ReadonlyArray<any>, defaultActivity: any): string {
  if (defaultActivity.typeId !== Enum.activityTypeEnum.DAILY_SERIE) {
    return '';
  }

  const numTotalContents = activities.reduce((acc, activity) => acc + activity.numTotalContents, 0);
  const numValidContents = activities.reduce((acc, activity) => acc + activity.numValidContents, 0);

  activities.map((acti) => acti);

  if (numValidContents === numTotalContents) {
    return '';
  }

  return t('activities:status.pending_validation_or_translation', {
    valid: numValidContents,
    total: numTotalContents,
  });
}

function getStatusFromActivity(
  activities: Array<any>,
  defaultActivity: any,
): {
  activityStatus: ActivityStatusType;
  activityStatusLabel: string;
} {
  const nowIsBefore = moment().isBefore(moment(defaultActivity.startDate).startOf('day'));
  const nowIsAfter = moment().isAfter(moment(defaultActivity.endDate));

  if (defaultActivity.archived) {
    return { activityStatus: 'Archived', activityStatusLabel: t('activities:list.item.status.archived') };
  }

  if (defaultActivity.typeId === Enum.activityTypeEnum.DAILY_SERIE) {
    const nbOfContents = defaultActivity.dailySerie.days.reduce(
      (nb: number, day: any) => nb + day.dailySerieContents.length,
      0,
    );

    if (nbOfContents === 0) {
      return { activityStatus: 'Draft', activityStatusLabel: t('activities:list.item.status.draft') };
    }
  }

  const translationsStatus = getTranslationProgress(activities, defaultActivity);

  if (translationsStatus) {
    return { activityStatus: 'Pending translation', activityStatusLabel: translationsStatus };
  }

  if (defaultActivity.active) {
    if (nowIsBefore) {
      return { activityStatus: 'Upcoming', activityStatusLabel: t('activities:list.item.status.upcoming') };
    }

    if (nowIsAfter) {
      const activityStatusLabel =
        defaultActivity.typeId === Enum.activityTypeEnum.DAILY_SERIE
          ? t('activities:list.item.status.finished_campaign')
          : t('activities:list.item.status.finished');

      return { activityStatus: 'Finished', activityStatusLabel };
    }

    if (
      defaultActivity.typeId === Enum.activityTypeEnum.DAILY_SERIE ||
      defaultActivity.typeId === Enum.activityTypeEnum.BREAKING_NEWS
    ) {
      return { activityStatus: 'Live', activityStatusLabel: t('activities:list.item.status.live') };
    }

    return { activityStatus: 'Visible', activityStatusLabel: t('activities:list.item.status.visible') };
  }

  return { activityStatus: 'Inactive', activityStatusLabel: t('activities:list.item.status.inactive') };
}

function getRouteFromActivity(activity: any): string {
  const url = '/activity';

  const urls = {
    [Enum.activityTypeEnum.DAILY_SERIE]: `/dailyserie/${activity.id}`,
    [Enum.activityTypeEnum.WELCOME]: `/welcome/${activity.id}`,
    [Enum.activityTypeEnum.INTERSEASON]: `/interseason/${activity.id}`,
    [Enum.activityTypeEnum.BREAKING_NEWS]: `/breakingnews/${activity.id}`,
    [Enum.activityTypeEnum.THATS_ALL_FOLKS]: `/thatsAllFolks/${activity.id}`,
  };

  return url + urls[activity.typeId];
}

// eslint-disable-next-line complexity
function getAudienceFromActivity(activity: any): Audience {
  if (activity.typeId === Enum.activityTypeEnum.BREAKING_NEWS && activity.breakingNewsCard.campaign) {
    const segDailySerie: AudienceDailySerieType = {
      campaignName: t('activities:list.item.audience.campaign', {
        campaignName: activity.breakingNewsCard.campaign.name,
      }),
    };

    return segDailySerie;
  }

  if (
    activity.typeId !== Enum.activityTypeEnum.DAILY_SERIE ||
    activity.targetModeId === Enum.audienceTargetMode.SEGMENTATION
  ) {
    const segments = activity.segmentation.map((seg: SegmentationGroupItemType) => ({
      value: seg.label,
      color: seg.dimension.color,
    }));

    const segAudience: AudienceSegmentType = { segments };

    return segAudience;
  }

  if (activity.targetModeId === Enum.audienceTargetMode.MAGIC_CODE) {
    const magicCodeAudience: AudienceMagicCodeType = {
      label: t('activities:list.item.audience.magic_code'),
      magicCode: activity.magicCode,
    };

    return magicCodeAudience;
  }

  if (activity.targetModeId === Enum.audienceTargetMode.EMAIL_LIST) {
    const emailAudience: AudienceEmailListType = {
      labelEmail: t('activities:list.item.audience.email_list'),
    };

    return emailAudience;
  }

  return null;
}
function getLanguagesFromActivity(activities: ReadonlyArray<any>): ReadonlyArray<string> {
  const defaultActivity = activities.filter((activity) => activity.isDefault)[0];

  if (defaultActivity.typeId !== Enum.activityTypeEnum.DAILY_SERIE) {
    return [];
  }

  const languages = activities.map((activity) => Enum.Languages[activity.language.data as LanguagesCodeType]);

  return languages.filter((lang) => lang);
}

function getLikesOrViewsFromActivity(activities: ReadonlyArray<any>, key: 'nbViews' | 'nbLikes'): number {
  return activities.reduce((partialSum, activity) => (activity[key] ? partialSum + activity[key] : partialSum), 0);
}

export default function createActivityListItem(values?: any): ActivityListItemType {
  const defaultState: ActivityListItemType = {
    multilingualId: 0,
    title: '',
    activityType: Enum.activityTypeEnum.DailySerie,
    activityStatus: 'Draft',
    activityStatusLabel: t('activities:list.item.status.draft'),
    startDate: '',
    endDate: '',
    audience: null,
    image: undefined,
    languages: [],
    activityIds: [],
    route: '/activity',
    hasPlayerPlayed: false,
    views: 0,
    likes: 0,
  };

  if (!values) {
    return defaultState;
  }

  const defaultActivity = values.activities.find((activity: any) => activity.isDefault);
  const { multilingualId } = defaultActivity;
  const title = defaultActivity.name;
  const activityType = defaultActivity.typeId;
  const image = defaultActivity.coverId && defaultActivity.cover ? defaultActivity.cover.url : '';
  const audience = getAudienceFromActivity(defaultActivity);
  const { activityStatus, activityStatusLabel } = getStatusFromActivity(values.activities, defaultActivity);
  const startDate = moment.utc(defaultActivity.startDate).format(DATE_FORMAT);
  const endDate = moment.utc(defaultActivity.endDate).format(DATE_FORMAT);
  const languages = getLanguagesFromActivity(values.activities);
  const route = getRouteFromActivity(defaultActivity);
  const activityIds = values.activities.map((act: any) => act.id);
  const { hasPlayerPlayed } = values;
  const views = getLikesOrViewsFromActivity(values.activities, 'nbViews');
  const likes = getLikesOrViewsFromActivity(values.activities, 'nbLikes');

  return mergeDeep(defaultState, {
    multilingualId,
    title,
    startDate,
    endDate,
    activityType,
    image,
    audience,
    activityStatus,
    activityStatusLabel,
    languages,
    route,
    activityIds,
    hasPlayerPlayed,
    views,
    likes,
  });
}
