import { apiAction } from 'Libs/redux/utils';

import { store } from 'Libs/redux/store';

import type { ApiActionType } from 'Libs/redux/utils';

import type { RoleType } from 'ModelsReact/Role/Role';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import type { AlertScope } from './models/Alert';

import { prepareContributor } from './models/Contributor';

import type { ContributorType } from './models/Contributor';

type ContributorInviteAction = ApiActionType<'CONTRIBUTOR_INVITE'>;
type ContributorFetchAllAction = ApiActionType<'CONTRIBUTOR_FETCH_ALL'>;
type ContributorRemoveAction = ApiActionType<'CONTRIBUTOR_REMOVE'>;
type ContributorResendInviteAction = ApiActionType<'CONTRIBUTOR_RESEND_INVITE'>;
type ContributorUpdateAction = ApiActionType<'CONTRIBUTOR_UPDATE'>;

type ContributorFetchRolesAction = ApiActionType<'CONTRIBUTOR_FETCH_ROLES'>;

type IsModalOpenType = {
  isDeleteModalOpen?: boolean;
  isManageModalOpen?: boolean;
  isResendInviteModalOpen?: boolean;
  isSelfEditModalOpen?: boolean;
};

type ContributorToggleModalAction = {
  type: 'CONTRIBUTOR_TOGGLE_MODAL';
  isOpen: IsModalOpenType;
  contributor?: ContributorType;
};

type ContributorSetSegmentationAction = {
  type: 'CONTRIBUTOR_SET_SEGMENTATION';
  segmentation: Array<SegmentationGroupItemType>;
};

type ContributorRemoveAlertAction = {
  type: 'CONTRIBUTOR_REMOVE_ALERT';
  scope: AlertScope;
};

type ContributorResetAction = {
  type: 'CONTRIBUTOR_RESET';
};

type InvitePayload = {
  email: string;
  editorRoleId: number;
  role?: RoleType;
  language: string;
  contributorToManage: ContributorType;
};

type UpdatePayload = {
  phoneNumber: string;
  phoneCountryCode: string;
  role?: RoleType;
  contributorToManage: ContributorType;
  // these two are optional because the ContributorSelfEdtiModal needs them
  firstName?: string;
  lastName?: string;
};

export const actions = {
  fetchAll: (
    filter: {
      query?: string;
      order?: string;
    } = Object.freeze({}),
  ): ContributorFetchAllAction =>
    apiAction({
      type: 'CONTRIBUTOR_FETCH_ALL',
      route: '/api/Players/admins',
      method: 'GET',
      meta: { filter },
    }),

  fetchRoles: (editorRoleId?: number): ContributorFetchRolesAction =>
    apiAction({
      type: 'CONTRIBUTOR_FETCH_ROLES',
      route: '/api/PlayerRoles',
      method: 'GET',
      meta: { editorRoleId },
    }),

  invite: (payload: InvitePayload): ContributorInviteAction => {
    const { email, language, role, contributorToManage, editorRoleId } = payload;
    const existingRole = role ? role : { id: 5, name: 'Admin' };

    const payloadToSend = prepareContributor({
      ...contributorToManage,
      email,
      roles: [existingRole] || undefined,
      language,
    });

    return apiAction({
      type: 'CONTRIBUTOR_INVITE',
      route: '/api/Players/full',
      method: 'POST',
      payload: payloadToSend,
      meta: {
        email: payloadToSend.email,
        editorRoleId: editorRoleId,
      },
    });
  },

  update: (payload: UpdatePayload): ContributorUpdateAction => {
    const { phoneNumber, phoneCountryCode, firstName = '', lastName = '', role, contributorToManage } = payload;
    const existingRole = role || { id: 5, name: 'Admin' };

    const payloadToSend =
      firstName || lastName
        ? prepareContributor({
            ...contributorToManage,
            phoneNumber,
            phoneCountryCode,
            firstName,
            lastName,
            roles: [existingRole],
          })
        : prepareContributor({
            ...contributorToManage,
            phoneNumber,
            phoneCountryCode,
            roles: [existingRole],
          });

    return apiAction({
      type: 'CONTRIBUTOR_UPDATE',
      route: `/api/Players/full/${contributorToManage.id}`,
      method: 'PUT',
      payload: payloadToSend,
    });
  },

  toggleContributorModal: (isOpen: IsModalOpenType, contributor?: ContributorType): ContributorToggleModalAction => ({
    type: 'CONTRIBUTOR_TOGGLE_MODAL',
    isOpen,
    contributor,
  }),

  setContributorSegmentation: (segmentation: Array<SegmentationGroupItemType>): ContributorSetSegmentationAction => ({
    type: 'CONTRIBUTOR_SET_SEGMENTATION',
    segmentation,
  }),

  removeContributor: (contributorId: number): ContributorRemoveAction => {
    const state = store.getState();
    const { contributorToManage } = state.pages.contributor.manageContributorModal;
    const isInvitation = contributorToManage.status === 'inactive';
    const payload = prepareContributor({ ...contributorToManage, status: 'archived' });

    return apiAction({
      type: 'CONTRIBUTOR_REMOVE',
      route: `/api/Players/full/${contributorId}`,
      method: 'PUT',
      payload,
      meta: { id: contributorId, isInvitation },
    });
  },

  resendInviteContributor: (contributor: ContributorType): ContributorResendInviteAction =>
    apiAction({
      type: 'CONTRIBUTOR_RESEND_INVITE',
      route: '/api/Players/sendInviteEmailToContributor',
      method: 'POST',
      payload: contributor,
    }),

  removeAlert: (scope: AlertScope): ContributorRemoveAlertAction => ({
    type: 'CONTRIBUTOR_REMOVE_ALERT',
    scope,
  }),

  reset: (): ContributorResetAction => ({
    type: 'CONTRIBUTOR_RESET',
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type ContributorListAction = ReturnType<ActionsFn>;
