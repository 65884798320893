import m from 'mithril';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.newMail = m.prop('');
  self.oninput = function oninput(mails, value) {
    if (value[value.length - 1] === ' ' && self.newMail().trim()) {
      mails().push(self.newMail());
      self.newMail('');
      m.redraw();
      self.focus();

      return;
    }
    if (value[value.length - 1] !== ' ')
      self.newMail(value);
  };

  self.onKeyDown = function onKeyDown(mails, e) {
    if ((e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 9) && self.newMail().trim()) {
      e.preventDefault();
      mails().push(self.newMail());
      self.newMail('');
      m.redraw();
      self.focus();

      return;
    }
  };

  self.onblur = function onblur(mails) {
    if (self.newMail().trim()) {
      mails().push(self.newMail());
      self.newMail('');
    }
  };

  self.focus = function focus() {
    var input = document.querySelector('#email-input');

    input.focus();
  };

  self.deleteElement = function deleteElement(mails, key) {
    mails().splice(key, 1);
  };

};

component.view = function view(c, args) {
  return m('.email-input', {
    onclick: c.focus,
  }, [
    args.mails().map(displayMail),
    m('input#email-input.email-input__input', {
      value      : c.newMail(),
      oninput    : m.withAttr('value', c.oninput.bind(null, args.mails)),
      onkeydown  : c.onKeyDown.bind(null, args.mails),
      onblur     : c.onblur.bind(null, args.mails),
      placeholder: 'Add an email here...',
    }),
  ]);

  function displayMail(mail, key) {
    return m('.email-input__element', [
      m('.email-input__element__label', mail),
      m('.email-input__element__delete', {
        onclick: c.deleteElement.bind(null, args.mails, key),
      }),
    ]);
  }
};

export default component;
