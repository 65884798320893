import React, { useEffect, useRef } from 'react';
import type { HTMLProps, RefObject } from 'react';

export type UTextAreaProps = {
  autoresize?: boolean;
  forceScroll?: boolean;
} & HTMLProps<HTMLTextAreaElement>;

// [HACKERMAN]: This allows autoresize for cut/copy/pasting and predefined text by setting height to 'auto' to..
// ..force the navigator to recompute the scrollHeight, before setting the height to the scrollHeight
const autoresizeOnInput = (textareaRef: RefObject<HTMLTextAreaElement>) => {
  const textarea = textareaRef.current;
  if (!textarea) {
    return;
  }

  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const noop = () => {};

/**
 * A basic wrap around `<textarea />`
 *
 * Props:
 * - autoresize: enable autoresize on input, cut, copy and paste
 *
 * Also supports every props of textarea
 */
export const UTextArea = ({ autoresize, forceScroll, ...props }: UTextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref && autoresize) {
      const { current: textarea } = ref;
      if (!textarea) {
        return noop;
      }

      textarea.style.height = `${textarea.scrollHeight}px`;

      const onInput = () => autoresizeOnInput(ref);
      textarea?.addEventListener('input', onInput);

      return () => textarea?.removeEventListener('input', onInput);
    }

    return noop;
  }, [autoresize, ref]);

  useEffect(() => {
    // trigger automatic scroll to top when textarea onBlur
    const { current: textarea } = ref;

    if (ref && textarea && forceScroll) {
      textarea.scrollTo(0, 0);
    }

    return noop;
  }, [ref, forceScroll]);

  return <textarea ref={ref} {...props} />;
};

export default UTextArea;
