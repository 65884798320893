/**
 * Displays an icon button
 *
 * Props:
 * - icon: the name of the icon to display
 * - onClick: Function called when the button is clicked
 * - type: string to set the icon button type ('standard-dark' | 'standard-light' | 'accentuated' | 'destructive' | 'validation' | 'warning')
 * - size: string to set the icon button size ('S' | 'L' | 'M')
 * - disabled: boolean to disable interaction with the icon
 * - ghost: boolean to trigger ghost mode
 * - loading: boolean to display spinner on loading
 * - stopClickPropagation : boolean to stop click propagation
 * - style: For overriding the component's style
 */

import React, { useState, useCallback, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import type { TrackingProps } from 'Services/trackingService';

import UIcon from 'Components/unit/UIcon/UIcon';
import ClickableArea from 'Components/hoc/ClickableArea/ClickableArea';

import type { IconName } from 'Components/foundation/icons';
import { COLORS, CORNERS } from 'Components/foundation';

import styles from './UIconButton.style';

export type UIconButtonSize = 'S' | 'M' | 'L';
export type UIconButtonType =
  | 'standard-dark'
  | 'standard-light'
  | 'accentuated'
  | 'destructive'
  | 'validation'
  | 'warning';
export type UIconButtonPropsType = {
  icon: IconName;
  onClick: (event: MouseEvent) => void;
  type?: UIconButtonType;
  size?: UIconButtonSize;
  disabled?: boolean;
  ghost?: boolean;
  loading?: boolean;
  round?: boolean;
  stopClickPropagation?: boolean;
  preventDefault?: boolean;
  style?: CSSProperties;
  isTriggeredOnMouseDown?: boolean;
} & TrackingProps;

export const BUTTON_TYPES = Object.freeze({
  STANDARD_DARK: 'standard-dark',
  STANDARD_LIGHT: 'standard-light',
  ACCENTUATED: 'accentuated',
  DESTRUCTIVE: 'destructive',
  VALIDATION: 'validation',
  WARNING: 'warning',
});

const ICON_SIZE = Object.freeze({
  L: 20,
  M: 14,
  S: 10,
});

const { CORNER2, ROUND, BORDER_RADIUS } = CORNERS;

const EXTRA_CLICKABLE_SIZE = 8;

const { GREY_MEDIUM, GREY_DARKER, PRIMARY, ERROR, SUCCESS, WARNING, TEXT, WHITE, BLUE_GRAY_MEDIUM, BLACK } = COLORS;

const GHOST_BACKGROUD = {
  GHOST: 'transparent',
  GHOST_DISABLED: 'transparent',
  GHOST_HOVER: 'transparent',
};

const BACKGROUND_COLOR = {
  [BUTTON_TYPES.STANDARD_DARK]: {
    ...GREY_MEDIUM,
    ...GHOST_BACKGROUD,
  },
  [BUTTON_TYPES.STANDARD_LIGHT]: {
    ...GREY_DARKER,
    ...GHOST_BACKGROUD,
  },
  [BUTTON_TYPES.ACCENTUATED]: {
    ...PRIMARY,
    ...GHOST_BACKGROUD,
  },
  [BUTTON_TYPES.DESTRUCTIVE]: {
    DEFAULT: ERROR.DARK,
    HOVER: ERROR.DEFAULT,
    DISABLED: TEXT.DISABLED,
    ...GHOST_BACKGROUD,
  },
  [BUTTON_TYPES.VALIDATION]: {
    DEFAULT: SUCCESS.DARK,
    HOVER: SUCCESS.DEFAULT,
    DISABLED: TEXT.DISABLED,
    ...GHOST_BACKGROUD,
  },
  [BUTTON_TYPES.WARNING]: {
    DEFAULT: WARNING.DARK,
    HOVER: WARNING.DEFAULT,
    DISABLED: TEXT.DISABLED,
    ...GHOST_BACKGROUD,
  },
};

const ICON_COLOR = {
  [BUTTON_TYPES.STANDARD_DARK]: {
    DEFAULT: BLACK.DEFAULT,
    HOVER: BLACK.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: TEXT.SECONDARY_DEFAULT,
    GHOST_HOVER: TEXT.SECONDARY_HOVER,
    GHOST_DISABLED: TEXT.DISABLED_DEFAULT,
  },
  [BUTTON_TYPES.STANDARD_LIGHT]: {
    DEFAULT: WHITE.DEFAULT,
    HOVER: WHITE.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: WHITE.DEFAULT,
    GHOST_HOVER: WHITE.HOVER,
    GHOST_DISABLED: BLUE_GRAY_MEDIUM.DEFAULT,
  },
  [BUTTON_TYPES.ACCENTUATED]: {
    DEFAULT: WHITE.DEFAULT,
    HOVER: WHITE.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: PRIMARY.DEFAULT,
    GHOST_HOVER: PRIMARY.HOVER,
    GHOST_DISABLED: TEXT.DISABLED_DEFAULT,
  },
  [BUTTON_TYPES.DESTRUCTIVE]: {
    DEFAULT: WHITE.DEFAULT,
    HOVER: WHITE.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: ERROR.DARK,
    GHOST_HOVER: ERROR.DEFAULT,
    GHOST_DISABLED: TEXT.DISABLED_DEFAULT,
  },
  [BUTTON_TYPES.VALIDATION]: {
    DEFAULT: WHITE.DEFAULT,
    HOVER: WHITE.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: SUCCESS.DARK,
    GHOST_HOVER: SUCCESS.DEFAULT,
    GHOST_DISABLED: TEXT.DISABLED_DEFAULT,
  },
  [BUTTON_TYPES.WARNING]: {
    DEFAULT: WHITE.DEFAULT,
    HOVER: WHITE.DEFAULT,
    DISABLED: TEXT.DISABLED_DEFAULT,
    GHOST: WARNING.DEFAULT,
    GHOST_HOVER: WARNING.HOVER,
    GHOST_DISABLED: TEXT.DISABLED_DEFAULT,
  },
};

export const UIconButton = ({
  icon,
  onClick,
  type = 'standard-dark',
  size = 'L',
  disabled = false,
  ghost = false,
  loading = false,
  round = false,
  stopClickPropagation = false,
  preventDefault = false,
  style = {},
  isTriggeredOnMouseDown = false,
  ...rest
}: UIconButtonPropsType) => {
  const [isHover, setIsHover] = useState(false);

  const handleClick = useCallback(
    (event) => {
      if (stopClickPropagation) {
        event.stopPropagation();
      }
      if (preventDefault) {
        event.preventDefault();
      }

      if (!(disabled || loading)) {
        onClick(event);
      }
    },
    [disabled, onClick, stopClickPropagation, preventDefault, loading],
  );

  const handleHover = useCallback(() => {
    if (!(disabled || loading)) {
      setIsHover(true);
    }
  }, [disabled, loading]);

  const handleLeave = useCallback(() => setIsHover(false), []);

  const getColorFromType = useCallback(
    (colorType) => {
      if (disabled && ghost) {
        return colorType[type].GHOST_DISABLED;
      }
      if (disabled) {
        return colorType[type].DISABLED;
      }
      if (isHover && ghost) {
        return colorType[type].GHOST_HOVER;
      }
      if (isHover) {
        return colorType[type].HOVER;
      }
      if (ghost) {
        return colorType[type].GHOST;
      }

      return colorType[type].DEFAULT;
    },
    [disabled, ghost, type, isHover],
  );

  const getColors = useCallback(() => {
    const backgroundColor = getColorFromType(BACKGROUND_COLOR);

    const color = getColorFromType(ICON_COLOR);

    return {
      color,
      backgroundColor,
    };
  }, [getColorFromType]);

  const extraStyle = useMemo(
    () => ({
      cursor: isHover && !disabled ? 'pointer' : 'default',
      padding: size === 'S' ? 4 : 6,
      ...BORDER_RADIUS(round ? ROUND : CORNER2),
    }),
    [size, disabled, isHover, round],
  );

  const { backgroundColor, color } = getColors();

  const wrapperStyle = {
    ...extraStyle,
    ...styles.wrapper,
    backgroundColor,
    ...style,
  };

  const clickableSize = useMemo(() => {
    const addedPadding = extraStyle.padding * 2;
    const computedSize = ICON_SIZE[size] + addedPadding;

    return {
      height: computedSize + EXTRA_CLICKABLE_SIZE,
      width: computedSize + EXTRA_CLICKABLE_SIZE,
    };
  }, [extraStyle, size]);

  return (
    <ClickableArea
      height={clickableSize.height}
      width={clickableSize.width}
      cypressTestId="icon-button"
      {...(preventDefault || isTriggeredOnMouseDown ? { onMouseDown: handleClick } : { onClick: handleClick })}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={wrapperStyle}
      tracking={rest}
    >
      {!loading && <UIcon name={icon} size={ICON_SIZE[size]} color={color} />}
      <CircleSpinner size={ICON_SIZE[size]} color={color} loading={loading} />
    </ClickableArea>
  );
};

export default UIconButton;
