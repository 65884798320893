import { COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  label: {
    marginLeft: 8,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  star: {
    color: COLORS.ERROR.DARK,
    paddingRight: 6,
  },
  tooltip: {
    paddingLeft: 6,
  },
  icon: {
    position: 'relative',
    top: 1,
  },
  iconL: {
    position: 'relative',
    top: 0,
  },
  leftIcon: {
    position: 'relative',
    marginRight: 4,
  },
  leftIconL: {
    position: 'relative',
    top: -2,
    marginRight: 4,
  },
});

export default styles;
