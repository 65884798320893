// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display a navigation bloc
 *
 * Props:
 * - onClick: Function called on click
 * - title: Title of the navigation action
 * - description: Description of the navigation action
 * - icon: Choose the navigation icon
 * - isNew: Should add the "new" indicator
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import UIndicator from 'Components/unit/UIndicator/UIndicator';
import { COLORS } from 'Components/foundation';

import styles from './SNavigationBloc.style';


type Props = {|
  onClick: Function,
  title: string,
  description: string,
  icon: string,
  isNew: boolean,
  style: ?Object,
|};

type State = {|
  isHover: boolean,
|};

export class SNavigationBloc extends React.PureComponent<Props, State> {
  static defaultProps = {
    icon: 'arrow-right',
    isNew: false,
    style: undefined,
  };

  state = {
    isHover: false,
  };

  render() {
    const { onClick, title, description, icon, style: propStyle } = this.props;
    const { isHover } = this.state;

    const backgroundColor = isHover ? COLORS.PRIMARY.DEFAULT : COLORS.WHITE.DEFAULT;
    const borderColor = isHover ? COLORS.PRIMARY.DEFAULT : COLORS.GREY_DARK.DEFAULT;
    const color = isHover ? COLORS.WHITE.DEFAULT : COLORS.BLACK.DEFAULT;
    const colorSecondary = isHover ? COLORS.WHITE.DEFAULT : COLORS.TEXT.SECONDARY_DEFAULT;

    return (
      <div
        style={{ backgroundColor, borderColor, ...styles.wrapper, ...propStyle }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={onClick}
      >
        <div style={styles.wrapperL}>
          <div style={{ ...styles.title, color }}>
            {title}
            {this.renderIndicator()}
          </div>
          <div style={{ ...styles.description, color: colorSecondary }}>
            {description}
          </div>
        </div>

        <div style={styles.wrapperR}>
          <UIcon
            name={icon}
            size={16}
            color={color}
          />
        </div>
      </div>
    );
  }

  renderIndicator() {
    const { isNew } = this.props;
    const { isHover } = this.state;

    if (!isNew)
      return null;

    return (
      <UIndicator
        text='NEW'
        mode={isHover ? 'contrasted' : 'accentuated'}
        style={styles.indicator}
      />
    );
  }

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default SNavigationBloc;
