import React from 'react';
import type { ReactElement, FunctionComponent } from 'react';

export type SwitchItem = ReactElement<{ id: string }>;

export interface SwitchViewProps {
  currentIndex: string;
  children: SwitchItem[];
}

const assertIdOnProps = (child: React.ReactNode): SwitchItem => {
  const assertedChild = child as SwitchItem;
  if (assertedChild?.props?.id) {
    return assertedChild;
  }

  throw new Error('Child of SwitchView does not have id prop');
};

/**
 * Component used to switch between multiple component based on their id props.
 * CHILDRENS MUST HAVE AN ID PROP
 *
 * - currentIndex: id of the child to display
 * - children: array of component with an id prop
 */
export const SwitchView: FunctionComponent<SwitchViewProps> = ({ currentIndex, children }) => {
  const childrens = React.Children.toArray(children).map(assertIdOnProps);
  const currentChild = childrens.find((child) => child.props.id === currentIndex);

  return currentChild || null;
};

export default SwitchView;
