import T from 'types';

var D = {};

var DAYS_IN_WEEK = 7;
var MIN_IN_HOUR = 60;
var SEC_IN_MIN = 60;
var MS_IN_A_DAY = 86400000;
var MS_IN_A_WEEK = 604800000;
var TWO_LENGTH = 2;
var THREE_LENGTH = 3;
var FIVE_LENGTH = 5;
var SIX_LENGTH = 6;
var NOON = 12;
var HUNDRED = 100;
var THOUSAND = 1000;
var TEN = 10;
var LEAP = 4;
var FIRST_DAY_OF_YEAR = 4;

var dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

var formatTokenFunctions = {
  M: function getMonthNb(d) {
    return d.getMonth() + 1;
  },
  MM: function getTwoDigitsMonth(d) {
    return ('0' + (d.getMonth() + 1)).slice(-TWO_LENGTH);
  },
  MMM: function getShortMonthName(d) {
    return D.getMonthName(d, THREE_LENGTH);
  },
  MMMM: function getFullMonthName(d) {
    return D.getMonthName(d);
  },
  D: function getDate(d) {
    return d.getDate();
  },
  DD: function getTwoDigitsDate(d) {
    return ('0' + d.getDate()).slice(-TWO_LENGTH);
  },
  DDD: function getDayOfYear(d) {
    var startOfYear = new Date(d.getFullYear(), 0, 1);

    return Math.floor((d - startOfYear) / MS_IN_A_DAY);
  },
  DDDo: function getOrdinalDate(d) {
    return D.getOrdinal(d.getDate(), 'en');
  },
  d: function getDay(d) {
    return d.getDay();
  },
  dd: function getShortDayName(d) {
    return D.getDayName(d, 2);
  },
  ddd: function getLongDayName(d) {
    return D.getDayName(d, THREE_LENGTH);
  },
  dddd: function getFullDayName(d) {
    return D.getDayName(d);
  },
  w: function getWeekNumber(d) {
    var startOfYear = new Date(d.getFullYear(), 0, FIRST_DAY_OF_YEAR);

    return Math.ceil((d - startOfYear) / MS_IN_A_WEEK) + 1;
  },
  W: function getWeekNumber(d) {
    return formatTokenFunctions.w(d);
  },
  YY: function getTwoDigitsYear(d) {
    return d.getFullYear() % HUNDRED;
  },
  YYYY: function getFullYear(d) {
    return d.getFullYear();
  },
  YYYYY: function getFourDigitsYear(d) {
    return ('0000' + d.getFullYear()).slice(-FIVE_LENGTH);
  },
  YYYYYY: function getSixDigitsYear(d) {
    var y = d.getFullYear(),
      sign = (y >= 0) ? '+' : '-';

    return (sign + '0000' + Math.abs(y)).slice(-SIX_LENGTH);
  },
  e: function getDay(d) {
    return d.getDay();
  },
  E: function getDayOfWeek(d) {
    return d.getDay() || DAYS_IN_WEEK;
  },
  a: function amOrPm(d) {
    var mid = new Date(d.getFullYear(), d.getMonth(), d.getDate(), NOON);

    return (d < mid) ? 'am' : 'pm';
  },
  A: function amOrPmUppercase(d) {
    return formatTokenFunctions.a(d).toUpperCase();
  },
  H: function getHours(d) {
    return d.getHours();
  },
  h: function getTwelveHours(d) {
    return d.getHours() % NOON || NOON;
  },
  m: function getMinutes(d) {
    return d.getMinutes();
  },
  s: function getSeconds(d) {
    return d.getSeconds();
  },
  S: function getDeciseconds(d) {
    return parseInt(d.getMilliseconds() / HUNDRED);
  },
  SS: function getCentiseconds(d) {
    return parseInt(d.getMilliseconds() / TEN);
  },
  SSS: function getMilliseconds(d) {
    return d.getMilliseconds();
  },
  Z: function getHourlyTimezoneOffset(d) {
    var offset = d.getTimezoneOffset();
    var sign = '+';

    if (offset < 0) {
      offset = -offset;
      sign = '-';
    }

    return [
      sign,
      ('0' + parseInt(offset / MIN_IN_HOUR)).slice(-TWO_LENGTH),
      ':',
      ('0' + parseInt(offset % MIN_IN_HOUR)).slice(-TWO_LENGTH),
    ].join('');
  },
  ZZ: function getHourlyTimezoneOffset(d) {
    var offset = d.getTimezoneOffset();
    var sign = '+';

    if (offset < 0) {
      offset = -offset;
      sign = '-';
    }

    return [
      sign,
      ('0' + parseInt(offset / MIN_IN_HOUR)).slice(-TWO_LENGTH),
      ('0' + parseInt(offset % MIN_IN_HOUR)).slice(-TWO_LENGTH),
    ].join('');
  },
  x: function getMillisecondsTimestamp(d) {
    return d.valueOf();
  },
  X: function getUnixTimestamp(d) {
    return parseInt(d.valueOf() / THOUSAND);
  },
  Q: function isLeapYear(d) {
    return parseInt((d.getMonth() + 1) / LEAP) + 1;
  },
};

D.formatDate = function formatDate(d, format, offset) {
  var ret = '';
  var formattingTokens = /(\[[^\[]*\])|(\\)?(Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Q|YYYYYY|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|mm?|ss?|S{1,4}|x|X|zz?|ZZ?|.)/g;
  var tokens = format.match(formattingTokens);

  if (!d)
    return format;

  if (offset)
    d = new Date(d.getTime() + (offset * SEC_IN_MIN * THOUSAND));

  for (var i = 0, len = tokens.length; i < len; i++) {
    if (tokens[i].search(/M|MMMM?|D|DDD|dd?d?d?|w|W|YY|YYYYY?Y?|e|E|a|A|H|h|m|s|SS?S?|ZZ?|x|X|Q/) === -1)
      ret += tokens[i];
    else
      ret += formatTokenFunctions[tokens[i]](d);
  }

  return ret;
};

D.getDayName = function getDayName(d, len, lower) {
  var name = '';

  d = T.is.date(d) ? d.getDay() : d;
  d %= 7;
  name = dayNames[d];

  if (len && len < name.length - 1)
    name = name.slice(0, len) + '.';
  if (lower)
    name = name.toLowerCase();

  return name;
};

D.getMonthName = function getMonthName(m, len, lower) {
  var name = '';

  m = T.is.date(m) ? m.getMonth() : m;
  m %= 12;
  name = monthNames[m];

  if (len && len < name.length - 1)
    name = name.slice(0, len) + '.';
  if (lower)
    name = name.toLowerCase();

  return name;
};

D.getDaysNbInMonth = function getDaysNbInMonth(year, month) {
  var date = null;

  month++;

  if (year > -1 && year < HUNDRED) {
    date = new Date(year, month, 0);
    date.setFullYear(year);

    return (date.getDate());
  }

  return new Date(year, month, 0).getDate();
};

D.getOrdinal = function getOrdinal(nb, lang) {
  if (lang === 'en') {
    var hundred = nb % 100;
    var tenth = nb % 10;

    if (hundred === 11 || hundred === 12)
      return nb + 'th';
    if (tenth === 1)
      return nb + 'st';
    if (tenth === 2)
      return nb + 'nd';
    if (tenth === 3)
      return nb + 'rd';

    return nb + 'th';
  }
};

export default D;
