import React from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import type { FilterItem, Filter } from 'Libs/filter/types';
import { makeNoChoiceLabel, FILTER_TYPE, getCategoryLabel } from 'Libs/filter/utils';

import UIcon from 'Components/unit/UIcon/UIcon';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import { COLORS } from 'Components/foundation';

import styles from './UFilterSelect.style';

export type UFilterSelectProps = {
  filter: Filter;
  onFilterChanged: (filter: Filter) => void;
  style?: CSSProperties;
  allowNoChoice?: boolean;
};

export type UFilterSelectState = {
  isDropdownBoxOpen: boolean;
};

export const NO_CHOICE_ITEM_ID = -1000;
const ITEM_HEIGHT = 31;
const NB_ITEM_BEFORE_VIRTUALIZED = 50;

const FONT_WEIGHT_NORMAL = 400;
const FONT_WEIGHT_BOLD = 700;

const deselectAllItem = (item: FilterItem) => {
  item.selected = false;
};

const selectOneItem = (item: FilterItem, id: number) => {
  if (item.id === id) {
    item.selected = true;
  } else {
    item.selected = false;
  }
};

const selectItem = (item: FilterItem, id: number) => {
  if (item.id === id) {
    item.selected = true;
  }
};

const getSelectedName = (item: FilterItem) => (item.selected ? item.value : null);

/**
 * A Filter with a DropdownBox to show every item
 *
 * Props:
 *  - filter: filter element which contain items to display
 *  - onFilterChanged: call when a filter is changed
 *  - style: override component's style
 */
export class UFilterSelect extends React.PureComponent<UFilterSelectProps, UFilterSelectState> {
  node: HTMLDivElement | null = null;

  static defaultProps = {
    style: undefined,
    allowNoChoice: true,
  };

  constructor(props: UFilterSelectProps) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.state = {
      isDropdownBoxOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  renderDropDownBox = (nameToDisplay: string) => {
    const { filter, allowNoChoice } = this.props;
    const { items, type, category } = filter;
    const { isDropdownBoxOpen } = this.state;

    let itemsWithNoChoiceLabel = items;

    if (!isDropdownBoxOpen) {
      return null;
    }

    if (type === 'select' && allowNoChoice) {
      const noChoiceLabel = makeNoChoiceLabel(getCategoryLabel(filter));
      const selected = nameToDisplay === noChoiceLabel;

      // Warning: some objects (e.g. activity types) allow negative id values
      itemsWithNoChoiceLabel = [
        // Category is omited here because we dont want the noChoice label to be grouped under a category
        {
          value: t('unit_components:u_filter_select.labels.any'),
          id: NO_CHOICE_ITEM_ID,
          selected,
        } as unknown as FilterItem,
        ...items,
      ];
    }

    // Enable virtualization only on segment
    const virtualized = category === 'segment' && items.length > NB_ITEM_BEFORE_VIRTUALIZED;

    return (
      <UDropdownBox
        items={itemsWithNoChoiceLabel}
        renderItem={this.renderItem}
        virtualized={virtualized}
        itemHeight={ITEM_HEIGHT}
        style={styles.dropdownbox}
      />
    );
  };

  renderItem = (item: FilterItem) => this.renderUDropdownItem(item, 0);

  renderUDropdownItem = (item: FilterItem, nested: number) => {
    const { children, value, id, color } = item;
    const {
      filter: { type, category },
    } = this.props;
    const selected = item.selected && type === FILTER_TYPE.SELECT;

    const childrenToRender = children
      ? children.map((dropdownItem) => this.renderUDropdownItem(dropdownItem, nested + 1))
      : null;

    const chipsProps = category === 'segment' ? { value, color, type: 'standard' } : null;

    return (
      <UDropdownItem
        key={id}
        id={id}
        text={value}
        selected={selected}
        onClick={this.handleClickItem}
        nested={nested}
        chipsProps={chipsProps}
      >
        {childrenToRender}
      </UDropdownItem>
    );
  };

  handleDropdownBox = () => {
    const { isDropdownBoxOpen } = this.state;

    this.setState({ isDropdownBoxOpen: !isDropdownBoxOpen });
  };

  handleClickItem = (id: number) => {
    const {
      filter: { type },
      filter,
      onFilterChanged,
    } = this.props;
    const copyFilter = JSON.parse(JSON.stringify(filter));

    let functionToApply = null;

    if (type === FILTER_TYPE.SELECT && id === NO_CHOICE_ITEM_ID) {
      functionToApply = deselectAllItem;
    } else if (type === FILTER_TYPE.SELECT) {
      functionToApply = selectOneItem;
    } else {
      functionToApply = selectItem;
    }

    this.applyFunctionOnTree(copyFilter.items, functionToApply, id);

    this.setState({ isDropdownBoxOpen: false }, onFilterChanged.bind(null, copyFilter));
  };

  handleClickOutside = (event: MouseEvent) => {
    if (!this.node?.contains(event.target as Node)) {
      this.setState({ isDropdownBoxOpen: false });
    }
  };

  setWrapperRef = (node: HTMLDivElement) => {
    this.node = node;
  };

  /*
   ** Apply a given function on the whole tree
   */
  applyFunctionOnTree = (
    items: Array<FilterItem>,
    functionToApply: ((item: FilterItem, id: number) => void) | ((item: FilterItem) => string | null),
    id?: number,
  ) => {
    let result: string | null | void = null;

    items.forEach((item) => {
      const { children } = item;
      const resultApplyFt = functionToApply(item, id!);

      result = result || resultApplyFt;

      if (children) {
        const ftRes = this.applyFunctionOnTree(children, functionToApply, id);

        result = result || ftRes;
      }
    });

    return result;
  };

  render() {
    const { style, filter } = this.props;

    const { type } = filter;
    const nameToDisplay = getCategoryLabel(filter, this.applyFunctionOnTree(filter.items, getSelectedName));

    const [styleSelect, styleIcon] =
      type !== FILTER_TYPE.SELECT
        ? [styles.wrapperMultiSelected, styles.iconMultiSelect]
        : [styles.wrapperSelect, styles.iconSelect];

    const [color, fontWeight] =
      type === FILTER_TYPE.SELECT && nameToDisplay !== makeNoChoiceLabel(getCategoryLabel(filter))
        ? [COLORS.PRIMARY.DEFAULT, FONT_WEIGHT_BOLD]
        : [COLORS.TEXT.SECONDARY_DEFAULT, FONT_WEIGHT_NORMAL];

    return (
      <div ref={this.setWrapperRef} data-testid="filter-select" style={{ ...styles.wrapper, ...style }}>
        <div onClick={this.handleDropdownBox} style={{ ...styles.wrapperTextIcon, ...styleSelect }}>
          <span
            style={{
              ...styles.wrapperText,
              color,
              fontWeight,
            }}
          >
            {nameToDisplay}
          </span>
          <UIcon name="arrow-down" size={6} color={color} style={styleIcon} />
        </div>
        {this.renderDropDownBox(nameToDisplay)}
      </div>
    );
  }
}

export default UFilterSelect;
