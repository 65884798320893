import sha256 from 'sha256';
import moment from 'moment';
import { t } from 'i18next';

import Request from 'services/requestService';
import Model from 'models/Model';
import Enum from 'models/Enum';
import Collection from 'models/Collection';
import instance from 'services/instanceConfig';
import regex from 'Libs/regex';

var Activity = {
  endpoint  : 'api/Activities/full',
  attributes: {
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    active: {
      type      : 'boolean',
      defaultsTo: false,
    },
    archived: {
      type      : 'boolean',
      defaultsTo: false,
    },
    multilingualId: {
      type: 'number',
    },
    isDefault: {
      type: 'boolean',
      defaultsTo: true,
    },
    languageId: {
      type: 'number',
    },
    startDate: {
      type: 'date',
    },
    endDate: {
      type: 'date',
    },
    activityId: {
      type: 'number',
    },
    typeId: {
      type: 'number',
    },
    type: {
      type : 'pointer',
      Model: 'ActivityType',
    },
    targetModeId: {
      type: 'number',
    },
    targetMode: {
      type : 'pointer',
      Model: 'AudienceTargetMode',
    },
    origin: {
      type : 'pointer',
      Model: 'Activity',
    },
    originId: {
      type: 'number',
    },
    children: {
      type : 'collection',
      Model: 'Activity',
    },
    pendingTranslation: {
      type: 'boolean',
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    dailySerie: {
      type      : 'pointer',
      Model     : 'DailySerie',
      defaultsTo: null,
    },
    welcomeCard: {
      type      : 'pointer',
      Model     : 'WelcomeCard',
      defaultsTo: null,
    },
    interseasonCard: {
      type      : 'pointer',
      Model     : 'InterseasonCard',
      defaultsTo: null,
    },
    thatsAllFolksCard: {
      type      : 'pointer',
      Model     : 'ThatsAllFolksCard',
      defaultsTo: null,
    },
    breakingNewsCard: {
      type      : 'pointer',
      Model     : 'BreakingNewsCard',
      defaultsTo: null,
    },
    cover: {
      type : 'pointer',
      Model: 'Image',
    },
    coverId: {
      type: 'number',
    },
    videoCover: {
      type : 'pointer',
      Model: 'Video',
    },
    videoCoverId: {
      type: 'number',
    },
  },
  methods: {
    updateSimple: function updateSimple(_Activity, toUpdate) {

      return Request.put('api/activities/' + this.id(), toUpdate)
        .then(function returnActivity(activity) {
          return new _Activity(activity);
        });
    },
    duplicate: function duplicate(_Activity) {
      return Request.post('api/activities/' + this.id() + '/duplicate')
        .then(function returnActivity(activity) {
          return new _Activity(activity);
        });
    },
    getRoute: function getRoute() {
      var self = this;
      var url = '/activity';

      switch (self.typeId()) {
        case Enum.activityTypeEnum.DAILY_SERIE:
          url += '/dailyserie/' + self.id();
          break;
        case Enum.activityTypeEnum.WELCOME:
          url += '/welcome/' + self.id();
          break;
        case Enum.activityTypeEnum.INTERSEASON:
          url += '/interseason/' + self.id();
          break;
        case Enum.activityTypeEnum.BREAKING_NEWS:
          url += '/breakingnews/' + self.id();
          break;
        case Enum.activityTypeEnum.THATS_ALL_FOLKS:
          url += '/thatsAllFolks/' + self.id();
          break;
        default:
      }

      return url;
    },
    status: function status(_Activity) {
      var self = this;

      const nowIsBefore = moment().isBefore(moment(self.startDate()).startOf('day'));
      const nowIsAfter = moment().isAfter(self.endDate());
      var statusId = 0;
      var states = [
        'inactive',
        'upcoming',
        'live',
        'finished',
      ];

      states[-1] = 'archived';

      if (self.active()) {
        if (nowIsBefore)
          statusId = 1;
        else if (nowIsAfter)
          statusId = 3;
        else
          statusId = 2;
      }

      if (this.archived())
        statusId = -1;

      return states[statusId];
    },
    live: function live() {
      var self = this;

      var now = new Date();

      if (self.active()) {
        if (self.startDate() < now && now < self.endDate())
          return true;
      }

      return false;
    },
    containsAtLeastALanguage: function containsAtLeastALanguage() {
      var self = this;
      var languages = self.segmentation().filter(isLanguage);

      return languages.length;

      function isLanguage(segmentation) {
        return segmentation.dimension().typeId === Enum.dimensionTypes.LANGUAGE;
      }
    },
    subActivity: function subActivity(_Content) {
      var self = this;

      var typeId = self.typeId();
      var args = [].slice.call(arguments, 2);
      var sub = null;

      if (typeId === Enum.activityTypeEnum.DAILY_SERIE)
        sub = self.dailySerie;

      if (typeId === Enum.activityTypeEnum.WELCOME)
        sub = self.welcomeCard;

      if (typeId === Enum.activityTypeEnum.INTERSEASON)
        sub = self.interseasonCard;

      if (typeId === Enum.activityTypeEnum.THATS_ALL_FOLKS)
        sub = self.thatsAllFolksCard;

      if (typeId === Enum.activityTypeEnum.BREAKING_NEWS)
        sub = self.breakingNewsCard;

      if (!sub)
        return null;

      return sub.apply(self, args);
    },

    getDescriptionLimit: function getDescriptionLimit() {
      var self = this;

      switch (self.typeId()) {
        case Enum.activityTypeEnum.WELCOME:
          return 160;

          // TODO: Description limit not used for dailyserie yet.
        case Enum.activityTypeEnum.DAILY_SERIE:
          return 120;
        case Enum.activityTypeEnum.INTERSEASON:
        case Enum.activityTypeEnum.THATS_ALL_FOLKS:
          return 60;
        default:
          return 80;
      }
    },

    getAbsoluteValue: function getAbsoluteValue(_Activity, first, second) {
      var MILLISEC_IN_DAY = 86400000;
      var abs = Math.abs((first.getTime() - second.getTime()) / MILLISEC_IN_DAY);

      return abs;
    },

    validateThatsAllFolks: function validateThatsAllFolks() {
      var self = this;
      var msg = {
        validate: true,
      };

      if (self.thatsAllFolksCard().displayUrl()) {
        if (!self.thatsAllFolksCard().url() || !regex.url.test(self.thatsAllFolksCard().url())) {
          msg.subtitle = t('activities:validation.invalid_thats_all_url_error_title');
          msg.content = t('activities:validation.invalid_thats_all_url_message');
          msg.validate = false;
        } else if (!self.thatsAllFolksCard().buttonLabel()) {
          msg.subtitle = t('activities:validation.missing_thats_all_label_error_title');
          msg.content = t('activities:validation.missing_thats_all_label_message');
          msg.validate = false;
        }
      }

      return msg;
    },

    validateInterseason: function validateInterseason() {
      var self = this;
      var msg = {};

      if (!self.interseasonCard().availableContentTitle()) {
        msg.subtitle = t('activities:validation.missing_active_campaign_error_title');
        msg.content = t('activities:validation.missing_active_campaign_message');
        msg.validate = false;
      } else if (!self.interseasonCard().availableContentDescription()) {
        msg.subtitle = t('activities:validation.missing_active_campaign_description_error_title');
        msg.content = t('activities:validation.missing_active_campaign_description_message');
        msg.validate = false;
      }

      return msg;
    },

    validate: function validate(_Activity, isBreakingNewsCampaignToggle) {
      var self = this;
      var msg = {
        validate: true,
      };

      if (!self.name()) {
        msg.subtitle = t('activities:validation.missing_activity_title_error_title');
        msg.content = t('activities:validation.missing_activity_title_message');
        msg.validate = false;
      } else if (!self.description()) {
        msg.subtitle = t('activities:validation.missing_activity_description_error_title');
        msg.content = t('activities:validation.missing_activity_description_message');
        msg.validate = false;
      } else if (self.startDate() > self.endDate()) {
        msg.subtitle = t('activities:validation.invalid_activity_date_end_error_title')
        msg.content = t('activities:validation.invalid_activity_date_end_message');
        msg.validate = false;
      } else if (!self.containsAtLeastALanguage()) {
        if (self.typeId() !== Enum.activityTypeEnum.BREAKING_NEWS || (self.typeId() === Enum.activityTypeEnum.BREAKING_NEWS && !isBreakingNewsCampaignToggle)) {
          msg.subtitle = t('activities:validation.missing_activity_language_error_title');
          msg.content = t('activities:validation.missing_activity_language_message');
          msg.validate = false;
        } else if (self.typeId() === Enum.activityTypeEnum.BREAKING_NEWS && !self.breakingNewsCard().campaignId()) {
          msg.subtitle = t('activities:validation.missing_activity_audience_error_title');
          msg.content = t('activities:validation.missing_activity_audience_message');
          msg.validate = false;
        }
      }

      if (!msg.validate)
        return msg;

      switch (self.typeId()) {
        case Enum.activityTypeEnum.THATS_ALL_FOLKS:
          msg = self.validateThatsAllFolks();
          break;
        case Enum.activityTypeEnum.INTERSEASON:
          msg = self.validateInterseason();
          break;
        default:
          return msg;
      }

      if (!msg.validate)
        return msg;

      return msg;
    },
    getAnalyticsURL: function getAnalyticsURL() {
      var activityId = this.id();
      var url = 'https://analytics.sparted.com/app?instance=' + encodeInstanceId() + '&activityId=' + activityId;

      return url;

      function encodeInstanceId() {
        var salt = 'sparted42';

        return sha256(instance.instanceId + salt);
      }
    },
  },
  classMethods: {
    get: function get(_Activity, activityId) {
      return Request.get('api/activities/full/' + activityId)
        .then(function returnActivity(activity) {
          if (activity.typeId === Enum.activityTypeEnum.BREAKING_NEWS) {
            var BreakingNewsCard = Model('BreakingNewsCard');

            activity.breakingNewsCard = new BreakingNewsCard(activity.breakingNewsCard);
          }

          return new _Activity(activity);
        });
    },
    findForInput: function findForInput() {
      const filter = {
        where: {
          and: [
            {
              or: [
                { archived: false },
                { archived: null },
              ],
            },
            { active: true },
            { isDefault: true },
            { typeId: Enum.activityTypeEnum.DAILY_SERIE },
          ],
        },
      };

      return Request.get('api/activities?filter=' + JSON.stringify(filter))
        .then(function returnCollection(activities) {
          return new Collection('Activity', Activity, activities);
        });
    },
    findAll: function findAll() {
      const filter = {
        where: {
          and: [
            {
              or: [
                { archived: false },
                { archived: null },
              ],
            },
            { active: true },
            { typeId: Enum.activityTypeEnum.DAILY_SERIE },
          ],
        },
      };

      return Request.get('api/activities?filter=' + JSON.stringify(filter))
        .then(function returnCollection(activities) {
          return new Collection('Activity', Activity, activities);
        });
    },
  },
};

export default Model.register('Activity', Activity);
