import { Promise } from 'bluebird';

import Request from 'Services/requestService';
import Model from 'Models/Model';
import Enum from 'Models/Enum';
import { normalizeYoutubeUrl } from 'Libs/video/youtube';

import { makeModel } from './types';
import { Video as SharedVideo } from '@sparted/shared-library/business/types';

const REGEX = {
  [Enum.videoType.Youtube]: [
    /https:\/\/youtu.be\/([^&]+)/,
    /https:\/\/www.youtube.com\/watch\?v=([^&]+)/,
    /https:\/\/youtu.be\/shorts\/([^&]+)/,
    /https:\/\/www.youtube.com\/shorts\/([^&]+)/,
  ],
  [Enum.videoType.Kumullus]: [/https:\/\/airapi.kumullus.com\/api\/v1\/kumullus\/([^&]+)\/deploy/],
  [Enum.videoType.ApiVideo]: [/https:\/\/[a-z]+.api.video\/vod\/([^&]+)\/mp4\/source.mp4/],
};

const videoTypeMapping: {
  typeId: typeof Enum.videoType['Youtube' | 'Kumullus' | 'ApiVideo'];
  videoType: SharedVideo['type'];
}[] = [
  {
    typeId: 1,
    videoType: 'Youtube',
  },
  {
    typeId: 2,
    videoType: 'api.video',
  },
  {
    typeId: 3,
    videoType: 'Kumullus',
  },
];

export const VIDEO_TYPE_ID_TYPE_MAPPING: Record<
  typeof Enum.videoType['Youtube' | 'Kumullus' | 'ApiVideo'],
  SharedVideo['type']
> = videoTypeMapping.reduce((acc, mapping) => {
  acc[mapping.typeId] = mapping.videoType;
  return acc;
}, {} as Record<typeof Enum.videoType['Youtube' | 'Kumullus' | 'ApiVideo'], SharedVideo['type']>);

export const VIDEO_TYPE_TO_TYPE_ID_MAPPING: Record<
  SharedVideo['type'],
  typeof Enum.videoType['Youtube' | 'Kumullus' | 'ApiVideo']
> = videoTypeMapping.reduce((acc, mapping) => {
  acc[mapping.videoType] = mapping.typeId;
  return acc;
}, {} as Record<SharedVideo['type'], typeof Enum.videoType['Youtube' | 'Kumullus' | 'ApiVideo']>);

const fetchKumullusThumbnail = (video: VideoInstance) =>
  Request.get(`https://airapi.kumullus.com/api/v1/player/${video.token()}/config`).then(
    (kumullusConfig: { stages: [{ cover_url: string }] }) => {
      const { stages } = kumullusConfig;

      return stages && stages.length ? stages[0].cover_url : '';
    },
  );

// This mapping exists to keep interop with old code which did not support
// async fetching of thumbnail and would be too costly to update.
const SYNC_THUMBNAILS: Record<number, (video: VideoInstance) => string> = {
  [Enum.videoType.Youtube]: (video) => `https://img.youtube.com/vi/${video.token()}/0.jpg`,
  [Enum.videoType.ApiVideo]: (video) => `https://cdn.api.video/vod/${video.token()}/thumbnail.jpg`,
};

const ASYNC_THUMBNAILS: Record<number, (video: VideoInstance) => Promise<string>> = {
  [Enum.videoType.Youtube]: (video) => Promise.resolve(SYNC_THUMBNAILS[Enum.videoType.Youtube](video)),
  [Enum.videoType.Kumullus]: fetchKumullusThumbnail,
  [Enum.videoType.ApiVideo]: (video) => Promise.resolve(SYNC_THUMBNAILS[Enum.videoType.ApiVideo](video)),
};

const THUMBNAIL_EXTENSION: Record<number, string> = {
  [Enum.videoType.Youtube]: '.jpg',
  [Enum.videoType.Kumullus]: '.png',
  [Enum.videoType.ApiVideo]: '.jpg',
};

const THUMBNAIL_PREFIX: Record<number, string> = {
  [Enum.videoType.Youtube]: 'youtube',
  [Enum.videoType.Kumullus]: 'kumullus',
  [Enum.videoType.ApiVideo]: 'apivideo',
};

const EMBEDDED_LINK: Record<number, (video: VideoInstance) => string> = {
  [Enum.videoType.Youtube]: (video) => `https://www.youtube.com/embed/${video.token()}`,
  [Enum.videoType.Kumullus]: (video) => video.url(),
  [Enum.videoType.ApiVideo]: (video) => `https://embed.api.video/vod/${video.token()}`,
};

export type VideoInstance = InstanceType<typeof Video>;

const Video = makeModel({
  endpoint: 'api/Videos',
  attributes: {
    url: {
      type: 'string',
      unique: true,
    },
    typeId: {
      type: 'number',
    },
  },
  methods: {
    linkToKnowledge: function linkToKnowledge(_Video, knowledge: { id: () => number; videos: () => any[] }): any {
      const self = this as VideoInstance;
      const path = `/api/Knowledge/${knowledge.id()}/videos/rel/${self.id()}`;

      return Request.put(path).then(() => {
        knowledge.videos().push(self);

        return self;
      });
    },

    /**
     * Returns token from the url link.
     * It accepts url of the following form depending of type:
     *
     * Youtube:
     * - https://www.youtube.com/watch?v=wyv5AIXTTTc
     * - https://youtu.be/wyv5AIXTTTc
     *
     * Kumullus:
     * - https://airapi.kumullus.com/api/v1/kumullus/wyv5AIXTTTc/deploy
     *
     * ApiVideo:
     * - https://cdn.api.video/vod/wyv5AIXTTTc/mp4/source.mp4
     *
     * It will return the following string: wyv5AIXTTTc
     *
     * The returned string will be empty if the link is not supported.
     */
    token: function token(): string {
      const self = this as VideoInstance;
      const typeLinks = REGEX[self.typeId()];

      if (!typeLinks) {
        return '';
      }

      const found = Object.values(typeLinks).reduce((acc, regex) => {
        const match = self.url().match(regex);

        return match?.length ? match[1] : acc;
      }, '');

      return found;
    },

    getOriginalThumbnail: function getOriginalThumbnail(): Promise<string | undefined> {
      const self = this as VideoInstance;
      const thumb = ASYNC_THUMBNAILS?.[self.typeId()]?.(self) || Promise.resolve();

      return thumb;
    },

    getOriginalThumbnailSync: function getOriginalThumbnailSync(): string {
      const self = this as VideoInstance;
      const thumb = SYNC_THUMBNAILS?.[self.typeId()]?.(self) || '';

      return thumb;
    },

    thumbnailPrefix: function thumbnailPrefix(): string {
      const self = this as VideoInstance;
      const prefix = THUMBNAIL_PREFIX?.[self.typeId()];

      return prefix;
    },

    thumbnailExtension: function thumbnailExtension(): string {
      const self = this as VideoInstance;
      const prefix = THUMBNAIL_EXTENSION?.[self.typeId()];

      return prefix;
    },

    thumbnailFilename: function thumbnailFilename(): string {
      const self = this as VideoInstance;

      return `${self.thumbnailPrefix()}-${self.token()}${self.thumbnailExtension()}`;
    },

    /**
     * Returns an embed URL with the given parameters.
     * The URL will be empty if the video is not supported.
     *
     * @returns {string} the fully serialized embed URL
     */
    embedUrl: function embedUrl(_Video, params): string {
      const self = this as VideoInstance;

      const url = EMBEDDED_LINK?.[self.typeId()]?.(self) || '';

      const hasParams = url && Object.keys(params).length;

      return hasParams ? `${url}?${Request.builQuery(params)}` : url;
    },

    /**
     * Returns a full URL to use as the source of an iframe.
     * Typically it will not show controls nor info, play inline,
     * and enable the js API.
     *
     * The URL will be empty if not supported
     *
     * @returns {string} a URL to use as an iframe source
     */
    getIframeSrcUrl: function getIframeSrcUrl(): string {
      const self = this as VideoInstance;

      const options = {
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        controls: 0,
        playsinline: 1,
        enablejsapi: 1,
      };

      return self.embedUrl(options);
    },

    getVideoType: function getVideoType(): SharedVideo['type'] {
      const self = this as VideoInstance;
      return VIDEO_TYPE_ID_TYPE_MAPPING[self.typeId()];
    },
  },
  classMethods: {
    isValidURL: function isValidURL(_Video, url: string, typeId: number): boolean {
      const regexes: RegExp[] = REGEX[typeId];
      const found = regexes.find((regex) => url.match(regex));

      return Boolean(found);
    },

    getVideoUrl: function getVideoUrl(_Video, url: string, typeId: number): string {
      // for youtube short, we just save the normal url including the videoId
      if (typeId === Enum.videoType.Youtube) {
        const normalizedUrl = normalizeYoutubeUrl(url);

        return normalizedUrl ? normalizedUrl : url;
      }

      return url;
    },
  },
});

export default Model.register('Video', Video) as typeof Video;
