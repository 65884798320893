import Model from 'models/Model';

var MediaType = {
  endpoint  : 'api/MediaTypes',
  attributes: {
    label: {
      type: 'string',
    },
  },
};

export default Model.register('MediaType', MediaType);
