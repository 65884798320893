import React from 'react';
import styles from 'Components/structural/STable/STable.style';

type ShowMoreErrorProps = {
  showMoreError: string;
};

const ShowMoreError = ({ showMoreError }: ShowMoreErrorProps) => {
  if (typeof showMoreError !== 'string') return null;

  return <p style={styles.showMoreError}>{showMoreError}</p>;
};

export default ShowMoreError;
