import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const ELLIPSIS = {
  textOverflow: 'ellipsis',
  WhiteSpace: 'nowrap',
  overflow: 'hidden',
};

export default createStyleSheet({
  wrapper: {
    flex: 1,
    height: 31,
    width: '100%',
    paddingTop: 8,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    cursor: 'pointer',
  },
  wrapperSelected: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    cursor: 'default',
  },
  wrapperHovered: {
    backgroundColor: COLORS.WHITE.HOVER,
  },
  wrapperDefault: {
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  flag: {
    marginRight: 12,
  },
  text: {
    ...TYPOGRAPHY.BODY4,
    ...ELLIPSIS,
  },
  captionText: {
    marginTop: 2,
    marginLeft: 4,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  hoveredText: {
    flexShrink: 0,
    marginTop: 2,
    marginLeft: 4,
    marginRight: 6,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  barContainer: {
    flexShrink: 0,
    marginTop: 1,
    marginLeft: 4,
    width: 84,
  },
  fill: {
    flex: 1,
  },
  indicatorWrapper: {
    ...TYPOGRAPHY.CAPTION,
    display: 'flex',
    color: COLORS.SUCCESS.DEFAULT,
    alignItems: 'center',
    marginRight: 6,
  },
  indicatorIcon: {
    marginLeft: 4,
  },
});
