import React from 'react';

import { UErrorTextHighlight } from 'Components/unit/UErrorTextHighlight/UErrorTextHighlight';

import { ComparisonSegment } from '../types';
import { styles } from './ComparisonTextArea.style';

export const ComparisonSegments = ({
  comparisonSegments,
  variant,
  text,
}: {
  comparisonSegments: ComparisonSegment[];
  variant: 'valid' | 'invalid';
  text: string;
}) => {
  return (
    <>
      {comparisonSegments.map((segment, index) => {
        const startIndex = variant === 'valid' ? segment.correctOriginalPosition : segment.wrongOriginalPosition;
        const endIndex =
          variant === 'valid'
            ? comparisonSegments[index + 1]?.correctOriginalPosition
            : comparisonSegments[index + 1]?.wrongOriginalPosition;

        if (startIndex === null) {
          return null;
        }
        const segmentText = text.slice(startIndex, endIndex ?? undefined);

        if (segment.areSame) {
          // Key index is ok only because elements never switch order and because nothing else uniquely identifies these elements
          return (
            <span style={styles.textArea} key={index}>
              {segmentText}
            </span>
          );
        }
        if (segment.correct === null || segment.wrong === null) {
          return (
            <span style={{ ...styles.uncomparedText, ...styles.textArea }} key={index}>
              {segmentText}
            </span>
          );
        }
        return (
          <UErrorTextHighlight variant={variant} key={index}>
            {segmentText}
          </UErrorTextHighlight>
        );
      })}
    </>
  );
};
