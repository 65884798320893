// @flow

import * as React from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { memoize } from 'lodash';

import Enum from 'Models/Enum';
import type { Dispatch, State as ReduxState } from 'Libs/redux/types';

import { COLORS } from 'Components/foundation';
import MDrawer from 'Components/modal/MDrawer/MDrawer';
import MRemoveLanguage from 'Components/modal/MRemoveLanguage/MRemoveLanguage';
import MAllLanguageTranslation from 'Components/modal/MAllLanguageTranslation/MAllLanguageTranslation';
import MMultipleLanguagesTranslation from 'Components/modal/MMultipleLanguagesTranslation/MMultipleLanguagesTranslation';
import MEmptyContentsTranslation from 'Components/modal/MEmptyContentsTranslation/MEmptyContentsTranslation';
import SAccordion from 'Components/structural/SAccordion/SAccordion';
import ULanguageListItem from 'Components/unit/ULanguageListItem/ULanguageListItem';
import UButton from 'Components/unit/UButton/UButton';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownLanguageItem from 'Components/unit/UDropdownLanguageItem/UDropdownLanguageItem';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';
import type { OptionType } from 'Components/unit/ULanguageListItem/ULanguageListItem';

import {
  actions,
  getDefaultActivity,
  getLanguagesSupported,
  getLanguagesIdsSupported,
  getAvailableSegmentationGroups,
  getLanguagesAvailable,
  getLanguagesProgressTranslation,
  getActivityEndDate,
} from '../../../redux';
import type { LanguageSupportedType, LanguageAvailableType, LanguageProgressTranslationType } from '../../../redux';

import style from './LanguageDrawer.style.js';

type UButtonReturnType = $Exact<React.ElementConfig<typeof UButton>>;

type AvailableLanguageItemType = {|
  id: number,
  label: string,
  availableGroupId: number,
|};

type OwnProps = {|
  visible: boolean,
  onClose: Function,
|};

type DispatchProps = {|
  addLanguage: (languageId: number) => mixed,
  askTranslationBatch: (knowledgeIds: $ReadOnlyArray<number>, languageIds: $ReadOnlyArray<number>) => Promise<mixed>,
  removeLanguage: (id: number) => mixed,
  selectLanguage: (languageId: number) => mixed,
|};

type StateProps = {|
  isCampaignLive: boolean,
  languagesProgressTranslation: $ReadOnlyArray<LanguageProgressTranslationType>,
  languagesSupported: $ReadOnlyArray<LanguageSupportedType>,
  languageIdsSupported: Map<number, true>,
  availableGroupItemMap: Map<number, string>,
  languagesAvailable: $ReadOnlyArray<LanguageAvailableType>,
  selectedLanguageId: number,
  defaultLanguageId: number,
  knowledgeIds: $ReadOnlyArray<number>,
  isAskKnowledgeTranslationLoading: boolean,
|};

type Props = {|
  ...OwnProps,
  ...DispatchProps,
  ...StateProps,
|};

type DefaultProps = {|
|};

type OwnConfig = React$Config<OwnProps, DefaultProps>;

type State = {|
  isLanguageDropdownOpen: boolean,
  languageIdToRemove: number,
  languageIdToTranslate: number,
  isMultipleLanguageTranslationModalOpen: boolean,
|};

type CallbackType = () => mixed;

const mapStateToProps = (state: ReduxState) => {
  const defaultActivity = getDefaultActivity(state);
  const endDate = getActivityEndDate(state);
  const knowledgeIds = defaultActivity.dailySerie.days.reduce((acc, day) => [
    ...acc,
    ...day.dailySerieContents.map(dsContent => dsContent.content.knowledgeId),
  ], []);
  const isCampaignLive = defaultActivity.active
      && moment().isSameOrAfter(moment(defaultActivity.startDate).startOf('day'))
      && moment().isSameOrBefore(endDate);

  return {
    languagesProgressTranslation: getLanguagesProgressTranslation(state),
    languagesSupported: getLanguagesSupported(state),
    languageIdsSupported: getLanguagesIdsSupported(state),
    availableGroupItemMap: getAvailableSegmentationGroups(),
    languagesAvailable: getLanguagesAvailable(),
    selectedLanguageId: state.pages.activity.selectedLanguageId,
    defaultLanguageId: defaultActivity.languageId,
    knowledgeIds,
    isAskKnowledgeTranslationLoading: state.pages.activity.isAskKnowledgeTranslationLoading,
    isCampaignLive,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addLanguage: (languageId: number) => dispatch(actions.addLanguage(languageId)),
  askTranslationBatch: (knowledgeIds: $ReadOnlyArray<number>, languageIds: $ReadOnlyArray<number>) => {
    const action = actions.askTranslationBatch(knowledgeIds, languageIds);

    dispatch(action);

    // INFO it is an antipattern to access the internal 'promise' field of the action
    // instead of relying on the flags in redux state.
    // However we make an exception here as several components may dispatch this action
    // and it would require tracking each request in the redux state.
    return action.promise;
  },
  removeLanguage: (languageId: number) => dispatch(actions.removeLanguage(languageId)),
  selectLanguage: (languageId: number) => dispatch(actions.selectLanguage(languageId)),
});

export class LanguageDrawer extends React.PureComponent<Props, State> {
  dropdownRef = React.createRef<HTMLDivElement>();
  WrappedUButton = withTooltip<UButtonReturnType>(UButton);

  state = {
    isLanguageDropdownOpen: false,
    languageIdToRemove: 0,
    languageIdToTranslate: 0,
    isMultipleLanguageTranslationModalOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideDropdown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideDropdown, false);
  }

  render() {
    const { visible, languagesSupported } = this.props;

    return (
      <MDrawer
        onClose={this.handleClose}
        title={t('activities:modals.language_drawer.title')}
        visible={visible}
        style={style.drawer}
      >
        <div style={style.container}>
          <div style={style.abstract}>
            {t('activities:modals.language_drawer.description')}
          </div>
          {this.renderAccordion()}
          <div style={style.current}>
            {t('activities:modals.language_drawer.current_languages')}
          </div>
          {languagesSupported.map(this.renderLanguageItem)}
          {this.renderAddLanguage()}
        </div>
        {this.renderRemoveLanguageModal()}
        {this.renderLanguageTranslationModal()}
        {this.renderMultipleLanguageTranslationModal()}
      </MDrawer>
    );
  }

  renderAccordion = () => {
    const { languagesProgressTranslation } = this.props;
    const enableTranslateAllContentsButton = languagesProgressTranslation.length >= 2;

    return (
      <SAccordion
        headerText={t('activities:modals.language_drawer.advanced_options.title')}
        style={style.accordion}
      >
        <div style={style.containerInAccordion}>
          <div style={style.sentenceAccordion}>
            {t('activities:modals.language_drawer.advanced_options.description')}
          </div>
          <UButton
            text={t('activities:modals.language_drawer.advanced_options.button')}
            onClick={this.handleTranslateAllContents}
            style={style.buttonTranslateAllContents}
            disabled={!enableTranslateAllContentsButton}
            ghost
            type='accentuated'
          />
        </div>
      </SAccordion>
    );
  };

  renderLanguageItem = (item: LanguageSupportedType, index: number) => {
    const { languagesProgressTranslation, isCampaignLive } = this.props;
    const { languageId, ...rest } = item;
    const options = this.getOptions(languageId);
    const { progressLabel, progress } = languagesProgressTranslation[index];

    return (
      <ULanguageListItem
        key={languageId}
        {...rest}
        options={options}
        progressLabel={progressLabel}
        progress={progress}
        optionsTooltipText={t('activities:modals.language_drawer.cannot_change_while_live_tooltip')}
        optionsDisabled={isCampaignLive}
      />
    );
  };

  renderAddLanguage = () => {
    const { languageIdsSupported, languagesAvailable, isCampaignLive } = this.props;

    const languagesToDisplay = languagesAvailable.filter((languageAvailable) => {
      return !languageIdsSupported.get(languageAvailable.id);
    });
    const Comp = this.WrappedUButton;

    if (!languagesToDisplay.length)
      return null;

    return (
      <div style={style.wrapperAddLanguages}>
        <div>
          <Comp
            type="accentuated"
            text={t('activities:modals.language_drawer.add_language')}
            rightIcon="plus-light"
            onClick={this.handleOpenLanguageDropdown}
            rightIconSize={12}
            disabled={isCampaignLive}
            tooltipPosition="right"
            tooltipContent={t('activities:modals.language_drawer.cannot_change_while_live_tooltip')}
            tooltipEnabled={isCampaignLive}
            tooltipMaxWidth={400}
          />
        </div>
        {this.renderDropdownLanguages(languagesToDisplay)}
      </div>
    );
  };

  renderDropdownLanguages = (languagesToDisplay: $ReadOnlyArray<AvailableLanguageItemType>) => {
    const { isLanguageDropdownOpen } = this.state;

    if (!isLanguageDropdownOpen)
      return null;

    return (
      <div
        ref={this.dropdownRef}
        style={style.containerDropdownLanguages}
      >
        <UDropdownBox
          items={languagesToDisplay}
          renderItem={this.renderDropdownLanguageItem}
        />
      </div>
    );
  };

  renderDropdownLanguageItem = ({ id, label, availableGroupId }: AvailableLanguageItemType) => {
    const { availableGroupItemMap } = this.props;

    const languageData = availableGroupItemMap.get(availableGroupId) || '';

    // $FlowFixMe the language may not be found
    const countryCode = Enum.Languages[languageData];

    return (
      <UDropdownLanguageItem
        key={id}
        onClick={this.handleAddLanguage(id)}
        switchToLabel=""
        defaultLabel=""
        text={label}
        countryCode={countryCode}
      />
    );
  };

  renderRemoveLanguageModal = () => {
    const { languageIdToRemove } = this.state;

    return (
      <MRemoveLanguage
        visible={!!languageIdToRemove}
        onConfirm={this.handleConfirmLanguageRemove}
        onClose={this.handleCancelLanguageRemove}
        isLoading={false}
      />
    );
  };

  renderLanguageTranslationModal = () => {
    const { isAskKnowledgeTranslationLoading, languagesProgressTranslation } = this.props;
    const { languageIdToTranslate } = this.state;
    const translationProgress = languagesProgressTranslation.find(x => x.languageId === languageIdToTranslate);

    if (!(translationProgress && translationProgress.canAskForTranslation)) {
      return (
        <MEmptyContentsTranslation
          visible={!!languageIdToTranslate}
          onAction={this.handleCancelLanguageTranslate}
        />
      );
    }

    return (
      <MAllLanguageTranslation
        visible={!!languageIdToTranslate}
        onConfirm={this.handleConfirmLanguageTranslate}
        onClose={this.handleCancelLanguageTranslate}
        isLoading={isAskKnowledgeTranslationLoading}
      />
    );
  };

  renderMultipleLanguageTranslationModal = () => {
    const { isAskKnowledgeTranslationLoading, languagesProgressTranslation, languagesSupported } = this.props;
    const { isMultipleLanguageTranslationModalOpen } = this.state;
    const translatableLanguages = languagesSupported
      .filter(supportedLanguage => {
        const translationProgress = languagesProgressTranslation.find(x => x.languageId === supportedLanguage.languageId);

        return translationProgress && translationProgress.canAskForTranslation;
      })
      .map(({ languageId, label }) => ({ id: languageId, label }));

    if (!translatableLanguages.length) {
      return (
        <MEmptyContentsTranslation
          visible={isMultipleLanguageTranslationModalOpen}
          onAction={this.handleCancelMultipleLanguageTranslate}
        />
      );
    }

    return (
      <MMultipleLanguagesTranslation
        languages={translatableLanguages}
        visible={isMultipleLanguageTranslationModalOpen}
        onConfirm={this.handleConfirmMultipleLanguageTranslate}
        onClose={this.handleCancelMultipleLanguageTranslate}
        isLoading={isAskKnowledgeTranslationLoading}
      />
    );
  };

  handleCancelLanguageRemove = () => {
    this.setState({ languageIdToRemove: 0 });
  };

  handleConfirmLanguageRemove = () => {
    const { removeLanguage, selectedLanguageId, selectLanguage, defaultLanguageId } = this.props;
    const { languageIdToRemove } = this.state;

    if (languageIdToRemove === selectedLanguageId)
      selectLanguage(defaultLanguageId);

    removeLanguage(languageIdToRemove);

    this.setState({ languageIdToRemove: 0 });
  };

  handleCancelLanguageTranslate = () => {
    this.setState({ languageIdToTranslate: 0 });
  };

  handleConfirmLanguageTranslate = () => {
    const { askTranslationBatch, knowledgeIds } = this.props;
    const { languageIdToTranslate } = this.state;

    askTranslationBatch(knowledgeIds, [languageIdToTranslate])
      .then(() => this.setState({ languageIdToTranslate: 0 }));
  };

  handleCancelMultipleLanguageTranslate = () => {
    this.setState({ isMultipleLanguageTranslationModalOpen: false });
  };

  handleConfirmMultipleLanguageTranslate = (languageIds: Array<number>) => {
    const { askTranslationBatch, knowledgeIds } = this.props;

    askTranslationBatch(knowledgeIds, languageIds)
      .then(() => this.setState({ isMultipleLanguageTranslationModalOpen: false }));
  };

  handleAddLanguage = memoize<[number], CallbackType>((id: number) => () => {
    const { addLanguage } = this.props;

    addLanguage(id);
    this.setState({ isLanguageDropdownOpen: false });
  });

  handleOpenLanguageDropdown = () => {
    this.setState({ isLanguageDropdownOpen: true });
  };

  handleClose = () => {
    const { onClose } = this.props;
    const { languageIdToRemove, languageIdToTranslate, isMultipleLanguageTranslationModalOpen } = this.state;

    // If a modal is open, we do not want the click to close the drawer
    if (!languageIdToRemove && !languageIdToTranslate && !isMultipleLanguageTranslationModalOpen)
      onClose();
  };

  handleTranslateAllContent = memoize<[number], CallbackType>((id: number) => () => {
    this.setState({ languageIdToTranslate: id });
  });

  handleRemoveLanguage = memoize<[number], CallbackType>((languageId: number) => () => {
    this.setState({ languageIdToRemove: languageId });
  });

  handleClickOutsideDropdown = (event: MouseEvent) => {
    if (this.dropdownRef.current && event.target instanceof Node && !this.dropdownRef.current.contains(event.target))
      this.setState({ isLanguageDropdownOpen: false });
  };

  handleTranslateAllContents = () => {
    this.setState({ isMultipleLanguageTranslationModalOpen: true });
  };

  getOptions = memoize<[number], Array<OptionType>>((languageId: number) => {
    const options: Array<OptionType> = [
      {
        title: t('activities:modals.language_drawer.options.translate_all'),
        callback: this.handleTranslateAllContent(languageId),
      },
      {
        title: t('activities:modals.language_drawer.options.remove'),
        callback: this.handleRemoveLanguage(languageId),
        color: COLORS.ALERT.ERROR,
      },
    ];

    return options;
  });

}

export default connect<_, OwnConfig, _, _, ReduxState, Dispatch>(mapStateToProps, mapDispatchToProps)(LanguageDrawer);
