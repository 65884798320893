import React, { useState, useMemo } from 'react';

import { validateToken } from 'Libs/jwt';

import Config from 'Services/instanceConfig';

import SLandingPage from 'Components/structural/SLandingPage/SLandingPage';
import { getCdnUrl } from 'Libs/cdn';

import { UpdatePasswordForm } from './components/UpdatePasswordForm/UpdatePasswordForm';
import { UpdatePasswordExpirationScreen } from './components/UpdatePasswordExpirationScreen/UpdatePasswordExpirationScreen';

const TOKEN_URL_EMPLACEMENT = 2;

const extractTwoFactorTokenFromPathname = (pathname: string) => pathname.split('/')[TOKEN_URL_EMPLACEMENT];

export const UpdatePassword = () => {
  const { pathname } = window.location;
  const token = useMemo(() => extractTwoFactorTokenFromPathname(pathname), [pathname]);
  const [isTokenValid] = useState(validateToken(token));

  if (!isTokenValid) {
    return <UpdatePasswordExpirationScreen />;
  }

  const instanceLogo = getCdnUrl(Config.instanceId, '/logo.png');

  return (
    <SLandingPage instanceLogo={instanceLogo}>
      <UpdatePasswordForm token={token} instanceName={Config.instanceId} />
    </SLandingPage>
  );
};
