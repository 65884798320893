// @flow

/* eslint-disable key-spacing */

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const box = {
  borderRadius: 4,
  backgroundColor: COLORS.WHITE.DEFAULT,
  paddingLeft: 190,
  paddingRight: 190,
};

export default {
  wrapper: {

    // INFO allow scrolling this view independently of alerts
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  container: {
    height: '100%',
    width: 1000,
    margin: 'auto',
    paddingTop: 32,
  },
  box1: {
    ...box,
    paddingTop: 60,
    paddingBottom: 60,
    marginBottom: 20,
  },
  box2: {
    ...box,
    paddingTop: 53,
    paddingBottom: 120,
  },
  titleAndImage: {
    marginBottom: 35,
  },
  divider: {
    marginBottom: 40,
  },
  title: {
    ...TYPOGRAPHY.H1,
    marginBottom: 20,
  },
  asterisk: {
    color: '#FD4E5A',
  },
  requiredFields: {
    ...TYPOGRAPHY.BODY3,
    marginTop: 8,
  },

  // INFO this must be an element and not just padding
  // to enable extended scrolling on firefox
  // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  bottomSpace: {
    height: 130,
  },
};
