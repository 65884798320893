import { COLORS, TYPOGRAPHY, ELEVATIONS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: 16,
    borderRadius: 12,
    cursor: 'pointer',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 162,
    borderRadius: 4,
    overflow: 'hidden',
    minWidth: 162,
    position: 'relative',
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  imageContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  containerInfos: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 20,
  },
  containerHeader: {
    display: 'flex',
  },
  containerHeaderLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
  },
  containerType: {
    display: 'flex',
    alignItems: 'center',
    height: 19,
  },
  id: {
    height: 17,
    padding: 1,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    marginLeft: 10,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  title: {
    marginTop: 8,
    ...TYPOGRAPHY.H1,
  },
  titlePlaceholder: {
    marginTop: 8,
    ...TYPOGRAPHY.H1,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  containerHeaderRight: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    height: 20,
  },
  iconOptions: {
    marginLeft: 16,
    paddingRight: 0,
  },
  containerFooter: {
    display: 'flex',
    marginTop: 36,
  },
  containerFooterLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
  },
  containerFooterRight: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  containerLikesViews: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginRight: '24px',
  },

  containerCalendar: {
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  containerTarget: {
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 15,
  },
  iconFooter: {
    marginRight: 16,
    minWidth: 16,
  },
  iconArrow: {
    marginLeft: 8,
    marginRight: 10,
  },
  cellSegment: {
    minHeight: 0,
    padding: '0px 0px 2px 0px',
    marginTop: -2,
  },
  magicCode: {
    fontWeight: 700,
  },
  nbLanguagesMore: {
    marginRight: 2,
    ...TYPOGRAPHY.BODY3,
  },
  mainFlag: {
    marginLeft: 8,
  },
  flag: {
    marginLeft: 6,
    opacity: 0.5,
  },
  dropdownBox: {
    position: 'absolute',
    top: 26,
    right: 0,
  },
  targetPlaceholder: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  analyticsIcon: {
    marginTop: 10,
  },
  textLikesViews: {
    ...TYPOGRAPHY.BODY3,
    marginRight: '6px',
  },
  textViews: {
    marginLeft: '17px',
  },
  wrapperFooterRight: {
    alignItems: 'flex-end',
    display: 'flex',
  },
});

export default styleSheet;

export const getStyleWrapper = (isHover: boolean) =>
  isHover ? { boxShadow: ELEVATIONS.LIGHT_HIGH } : { boxShadow: ELEVATIONS.LIGHT_LOW };

export const getLikesViewsMargin = (languagesLength: number) =>
  languagesLength === 0 ? { marginRight: '0px' } : { marginRight: '24px' };
