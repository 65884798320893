import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import styles from 'Components/structural/STable/STable.style';
import UButton from 'Components/unit/UButton/UButton';
import { COLORS } from 'Components/foundation';
import ShowMoreError from 'Components/structural/STable/components/ShowMoreError';

type ShowMoreProps = {
  canShowMore: boolean;
  onShowMoreClicked: React.MouseEventHandler<HTMLButtonElement>;
  showMoreError: string;
  showMoreText: string;
  loadingText: string;
  isContentLoading: boolean;
};

function ShowMore({
  canShowMore,
  onShowMoreClicked,
  showMoreError,
  showMoreText,
  loadingText,
  isContentLoading,
}: ShowMoreProps) {
  if (!canShowMore) return null;

  const marginTopStyle = showMoreError ? styles.errorShowMoreMargin : {};

  if (isContentLoading) {
    return (
      <div style={{ ...styles.showMoreContainer, ...styles.showMoreLoading }}>
        <div style={styles.loadingMessage}>{loadingText}</div>
        <div style={styles.loader}>
          <CircleSpinner size={16} color={COLORS.TEXT.SECONDARY_DEFAULT} />
        </div>
      </div>
    );
  }

  return (
    <div style={{ ...styles.showMoreContainer, ...styles.showMoreIdle, ...marginTopStyle }}>
      <ShowMoreError showMoreError={showMoreError} />
      <UButton type="standard" text={showMoreText} onClick={onShowMoreClicked} />
    </div>
  );
}

export default ShowMore;
