/* eslint-disable react/jsx-no-bind */
/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
// @flow

import React, { useState, useRef, useCallback } from 'react';
import { t } from 'i18next';

import { CircleSpinner } from 'react-spinners-kit';

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { COLORS } from 'Components/foundation';
import UButton from 'Components/unit/UButton/UButton';
import UNavigationButton from 'Components/unit/UNavigationButton/UNavigationButton';

import { actions } from '../../../../redux';
import styles from './DocumentModalPreview.style';

const DocumentPreviewError = (withRetry = false, onRetry = () => {}) => {
  const RetryButton = () => {
    if (!withRetry) return null;

    return <UButton leftIcon="refresh" text={t('documents:document_modal_preview.button_text')} onClick={onRetry} />;
  };

  return (
    <div style={styles.errorWrapper}>
      <div style={styles.errorText}>{t('documents:document_modal_preview.error_text')}</div>
      <RetryButton />
    </div>
  );
};

const DocumentPreviewLoader = () => {
  return (
    <div style={styles.loaderWrapper}>
      <CircleSpinner size={22} color={COLORS.WHITE.DEFAULT} loading />
      <div style={styles.loaderText}>{t('documents:document_modal_preview.loader_text')}</div>
    </div>
  );
};

export const DocumentPreview = () => {
  const dispatch = useTypedDispatch();
  const previewRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [navigationVisibility, setNavigationVisibility] = useState(true);

  const documentId = useTypedSelector((state) => state.pages.documentList.documentModal?.id);
  const file = useTypedSelector((state) => state.pages.documentList.documentFile);
  const path = useTypedSelector((state) => state.pages.documentList.documentModal?.path);

  let fileToLoad = documentId && path ? path : file;

  // Handle document major error
  const handleDocumentError = useCallback(
    (e: Object) => {
      if (e.message === t('documents:document_modal_preview.error_message')) {
        return setError(
          DocumentPreviewError.bind(null, true, () => {
            // Force retry
            setError(false);
          }),
        );
      }

      return dispatch(
        actions.setDocumentModalError(
          t('documents:document_modal_preview.document_modal_error', { reason: e.message }),
        ),
      );
    },
    [dispatch, t],
  );

  // Handle document with password protection
  const handleDocumentPassword = useCallback(() => {
    dispatch(actions.setDocumentModalError(t('documents:document_modal_preview.document_password_modal_error')));
    setError(DocumentPreviewError.bind(null, false, () => {}));
  }, [dispatch, t]);

  // Handle document page navigation visibility
  const handleNavigationVisibility = useCallback((visibility: boolean) => {
    setNavigationVisibility(visibility);
  }, []);

  // Handle the document navigation (next / prev)
  const handleNavigation = useCallback((next: number) => {
    setPageNumber(next);
  }, []);

  // Callback when the document is loaded
  const onPreviewLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages);
    setLoaded(true);
  }, []);

  // Callback when the document is rendered
  const onPreviewRenderSuccess = useCallback(() => {
    setTimeout(() => {
      const previewCanvas = document.getElementsByClassName('react-pdf__Page__canvas')[0];

      if (!previewCanvas || !previewRef.current) return;

      // Set the proper maximum size to preserve aspect ratio
      const previewPadding = 24;
      const maxHeight = previewRef.current.offsetHeight - previewPadding;
      const maxWidth = previewRef.current.offsetWidth - previewPadding;

      previewCanvas.style.maxHeight = `${maxHeight}px`;
      previewCanvas.style.maxWidth = `${maxWidth}px`;
    }, 0);
  }, []);

  if (!fileToLoad) return null;

  return (
    <div
      ref={previewRef}
      style={styles.preview}
      className="document-page__preview"
      onMouseEnter={handleNavigationVisibility.bind(null, true)}
      onMouseLeave={handleNavigationVisibility.bind(null, false)}
    >
      {error || (
        <Document
          file={fileToLoad}
          onLoadSuccess={onPreviewLoadSuccess}
          loading={DocumentPreviewLoader}
          error={DocumentPreviewError}
          noData={DocumentPreviewError}
          onLoadError={handleDocumentError}
          onSourceError={handleDocumentError}
          onPassword={handleDocumentPassword}
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderInteractiveForms={false}
            renderTextLayer={false}
            loading=""
            error={DocumentPreviewError}
            noData={DocumentPreviewError}
            onRenderSuccess={onPreviewRenderSuccess}
            onLoadError={handleDocumentError}
            onSourceError={handleDocumentError}
            onRenderError={handleDocumentError}
          />
        </Document>
      )}

      {loaded && totalPages > 1 ? (
        <UNavigationButton
          style={styles.previewNavigation}
          visible={navigationVisibility}
          total={totalPages}
          onNext={handleNavigation}
          onPrev={handleNavigation}
        />
      ) : null}
    </div>
  );
};
