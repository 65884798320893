/* eslint-disable react/no-unused-prop-types -- MenuOptions used by the UDropdownBox */
import React, { useState, useCallback } from 'react';

import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';
import { IconName } from 'Components/foundation/icons';
import { COLORS } from 'Components/foundation';

import type { UIconButtonSize } from 'Components/unit/UIconButton/UIconButton';
import type { CSSProperties } from 'react';

import styles from './SOptionsMenu.style';

const TYPE_COLORS = {
  standard: COLORS.GREY_DARKER.DEFAULT,
  accentuated: COLORS.TEXT.ACCENTUATED,
  warning: COLORS.WARNING.DARK,
  destructive: COLORS.ERROR.DARKER,
} as const;

export type SOptionsButtonType = 'standard-dark' | 'standard-light';

export type ChildrenOptions = {
  id: number;
  text: string;
  onClick: (id: number) => void;
  isSelected: boolean;
};

export type MenuOptions = {
  id: number | string;
  onClick: (id: number) => void;
  text: string;
  icon?: IconName;
  mode?: keyof typeof TYPE_COLORS;
  children?: ReadonlyArray<ChildrenOptions>;
};

export type SOptionsMenuProps = {
  style?: CSSProperties;
  icon?: IconName;
  type?: SOptionsButtonType;
  size?: UIconButtonSize;
  options: ReadonlyArray<MenuOptions>;
};

const OFFSET_MAPPING: Record<UIconButtonSize, number> = {
  L: 28,
  M: 22,
  S: 18,
};

/**
 * Displays an SOptionsMenu
 *  Props:
 * - type: string to set the icon button type ('standard-dark' | 'standard-light' | 'accentuated' | 'destructive' | 'validation' | 'warning')
 * - size: string to set the icon button size ('S' | 'L' | 'M')
 * - options : object that renders dropdown items and which contains has an id, text, its style (mode and icon) and an onClick function
 * - style: For overriding the component's style
 */
export const SOptionsMenu = ({
  style,
  options,
  type = 'standard-dark',
  size = 'L',
  icon = 'dot-menu',
}: SOptionsMenuProps) => {
  const [isDropdownOpened, setDropdownOpen] = useState(false);

  const handleClick = useCallback(() => {
    setDropdownOpen((previous) => !previous);
  }, []);

  const renderOptionItem = useCallback(
    ({ id, text, icon: iconName, mode = 'standard', onClick, children }: MenuOptions) => {
      if (children) {
        return (
          <UDropdownItem
            color={TYPE_COLORS[mode]}
            key={id}
            text={text}
            onClick={onClick}
            icon={iconName}
            stopClickPropagation
            style={styles.dropdownItem}
          >
            {children.map((option, index) => (
              <UDropdownItem
                key={option.id}
                id={index}
                text={option.text}
                onClick={option.onClick}
                nested={1}
                selected={option.isSelected}
                stopClickPropagation
              />
            ))}
          </UDropdownItem>
        );
      }

      return (
        <UDropdownItem
          color={TYPE_COLORS[mode]}
          key={id}
          text={text}
          onClick={onClick}
          icon={iconName}
          style={styles.dropdownItem}
          stopClickPropagation
        />
      );
    },
    [],
  );

  const outsideClick = useCallback(
    (isOutsideClicked: boolean) => (isOutsideClicked && isDropdownOpened ? setDropdownOpen(false) : null),
    [isDropdownOpened],
  );

  return (
    <div style={{ ...styles.container, ...style }}>
      <UIconButton
        icon={icon}
        ghost
        onClick={handleClick}
        stopClickPropagation
        size={size}
        style={styles.icon}
        type={type}
      />
      {isDropdownOpened && (
        <OutsideClickWrapper onClick={outsideClick} style={styles.menuWrapper} stopClickPropagation>
          <UDropdownBox
            items={options}
            renderItem={renderOptionItem}
            style={{ ...styles.dropDownBox, top: OFFSET_MAPPING[size] }}
          />
        </OutsideClickWrapper>
      )}
    </div>
  );
};

export default SOptionsMenu;
