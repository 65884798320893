import jwt from 'jsonwebtoken';

export const isTokenExpired = (exp: number) => {
  const currentTime = Date.now() / 1000;

  return currentTime > exp;
};

export const validateToken = (rawToken: string): boolean => {
  const decodedToken = jwt.decode(rawToken);

  if (!decodedToken || typeof decodedToken === 'string') {
    return false;
  }

  const isJwtExpired = isTokenExpired(decodedToken.exp || -1);

  return !isJwtExpired;
};
