import React, { CSSProperties, MouseEventHandler } from 'react';
import { GameType } from '@sparted/shared-library/business/types';

import USelectorToggle from 'Components/unit/USelectorToggle/USelectorToggle';
import UDotsMenu from 'Components/unit/UDotsMenu/UDotsMenu';
import UGameplayIndicator from 'Components/unit/UGameplayIndicator/UGameplayIndicator';
import UIcon from 'Components/unit/UIcon/UIcon';
import UIndicator from 'Components/unit/UIndicator/UIndicator';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UImage from 'Components/unit/UImage/UImage';
import UStatusIndicator from 'Components/unit/UStatusIndicator/UStatusIndicator';
import STooltip from 'Components/structural/STooltip/STooltip';

import { COLORS, ELEVATIONS } from 'Components/foundation';

import { GAMEPLAY } from 'Components/utils/Enum';

import { GameplayType } from 'Libs/ts/types';

import styles, { customBoxShadow } from './SLegacyContentCard.style';

export type CardOption = {
  id: number;
  onClick: (id: number) => any;
  text: string;
  color?: string;
};

export type SLegacyContentCardProps = {
  // required
  id: string;
  imageUrl: string;
  title: string;
  types: ReadonlyArray<GameplayType>;
  locale: string | null;
  indicatorText: string;

  // optional
  selectable: boolean;
  onSelect: (isSelected: boolean) => any;

  statusIndicatorType: 'valid' | 'warning' | null;
  statusIndicatorTooltip: string;

  previewable: boolean;
  previewText: string;
  onPreviewClick: MouseEventHandler;

  displayOption: boolean;
  options: ReadonlyArray<CardOption>;

  selected: boolean;
  disabled: boolean;
  locked: boolean;
  alreadyUsed: boolean;
  canShowOverlay: boolean;
  canBeDragged: boolean;
  style: CSSProperties;
};

type State = {
  isHovered: boolean;
  isPreviewHovered: boolean;
  isOptionsVisible: boolean;
};

/**
 * Display a card to show content's information
 *
 * Props:
 *  - id: The content id to display in the card's body
 *  - imageUrl: An url to display the cover image,
 *  - title: The content title to display in the card's body
 *  - types: All types of gameplays displayed
 *  - locale: The content language to display in the card's body
 *  - indicatorText: The text to display in the @see UIndicator when card is disabled
 *  - selectable: Enable the @see USelectorToggle component on hover
 *  - onSelect: callback when clicking on the SelectorToggle
 *  - displayOption: Enable the @see UDotsMenu component on hover
 *  - options: options to display when clicking on DotsMenu
 *  - previewable: Enable the preview on hover
 *  - previewText: Text to display which trigger preview callback
 *  - onPreviewClick: callback when clicking on the preview text
 *  - selected: Enable selected card's mode
 *  - disabled: Enable disabled card's mode (hover is deactivated)
 *  - locked: Enable locked card's mode (hover is still activated, drag'n'drop deactivated)
 *  - alreadyUsed: Enable already used card's mode
 *  - canShowOverlay: Can disabled the overlay
 *  - canBeDragged: Display different cursor if content card be dragged
 */
export class SLegacyContentCard extends React.PureComponent<SLegacyContentCardProps, State> {
  static defaultProps = {
    selectable: true,
    statusIndicatorType: null,
    statusIndicatorTooltip: '',
    previewable: true,
    displayOption: true,
    selected: false,
    disabled: false,
    locked: false,
    canShowOverlay: true,
    canBeDragged: false,
    indicatorText: '',
    previewText: '',
    options: [],
    onPreviewClick: () => {},
    onSelect: () => {},
    style: undefined,
    alreadyUsed: false,
  };

  static nextTooltipId = 0;

  // eslint-disable-next-line no-plusplus
  tooltipId = `SContentCard-tooltip-${SLegacyContentCard.nextTooltipId++}`;

  state = {
    isHovered: false,
    isPreviewHovered: false,
    isOptionsVisible: false,
  };

  handleHover = () => {
    this.setState({ isHovered: true });
  };

  handleLeave = () => {
    this.setState({ isHovered: false });
  };

  handlePreviewHover = () => {
    this.setState({ isPreviewHovered: true });
  };

  handlePreviewLeave = () => {
    this.setState({ isPreviewHovered: false });
  };

  handleToggleMenu = () => {
    const { isOptionsVisible } = this.state;

    this.setState({ isOptionsVisible: !isOptionsVisible });
  };

  handleOptionClick = (id: number) => {
    const { options } = this.props;

    const option = options.find((item: CardOption) => item.id === id);

    this.setState({ isOptionsVisible: false });

    if (option) option.onClick(id);
  };

  getBoxShadow = () => {
    const { selected, locked, disabled } = this.props;

    const boxShadow: string = ELEVATIONS.LIGHT_LOW;

    if (locked) return customBoxShadow.locked;
    if (selected) return `${boxShadow}, ${customBoxShadow.selected}`;
    if (disabled) return `${boxShadow}, ${customBoxShadow.disabled}`;

    return boxShadow;
  };

  renderDisabledOverlay = () => {
    const { disabled } = this.props;

    if (!disabled) return null;

    return <div style={styles.disabledOverlay} />;
  };

  renderAlreadyUsed = () => {
    const { alreadyUsed } = this.props;

    if (!alreadyUsed) return null;

    return <div style={styles.alreadyUsedOverlay} data-test-id="alreadyUsedOverlay" />;
  };

  renderOptionItem = (option: CardOption) => (
    <UDropdownItem key={option.id} {...option} onClick={this.handleOptionClick} />
  );

  renderOptionMenu = () => {
    const { options } = this.props;
    const { isOptionsVisible } = this.state;

    if (!isOptionsVisible || !options.length) return null;

    return (
      <div style={styles.optionsDropdownWrapper} className="nonDraggable">
        <UDropdownBox items={options} renderItem={this.renderOptionItem} style={styles.dropdownBox} />
      </div>
    );
  };

  renderDotsOption = () => {
    const { displayOption } = this.props;

    if (!displayOption) return null;

    return (
      <div style={styles.optionContainer}>
        <UDotsMenu onClick={this.handleToggleMenu} />
      </div>
    );
  };

  renderPreview = () => {
    const { previewable, onPreviewClick, previewText } = this.props;
    const { isPreviewHovered } = this.state;

    if (!previewable || !previewText) return null;

    const previewStyle = isPreviewHovered
      ? { ...styles.previewContainer, ...styles.previewHovered }
      : styles.previewContainer;

    return (
      <span
        onClick={onPreviewClick}
        onMouseEnter={this.handlePreviewHover}
        onMouseLeave={this.handlePreviewLeave}
        data-test-id="PREVIEW_BUTTON"
        style={previewStyle}
      >
        {previewText}
      </span>
    );
  };

  renderHoverLayout = () => {
    const { canShowOverlay } = this.props;
    const { isHovered } = this.state;

    if (!isHovered || !canShowOverlay) return null;

    return (
      <div style={styles.hoverLayout}>
        {this.renderDotsOption()}
        {this.renderPreview()}
      </div>
    );
  };

  renderSelected = () => {
    const { selected, selectable, onSelect, canShowOverlay } = this.props;
    const { isHovered } = this.state;

    if (!selectable || (!selected && !isHovered) || !canShowOverlay) return null;

    return (
      <div style={styles.selectorWrapper} className="nonDraggable">
        <USelectorToggle selected={selected} onClick={onSelect} />
      </div>
    );
  };

  renderHeader = () => {
    const { imageUrl } = this.props;

    return (
      <>
        {this.renderAlreadyUsed()}
        <div style={styles.headerContainer}>
          <div style={styles.imageContainer}>
            <UImage
              style={styles.image}
              src={imageUrl}
              transformation={{
                // Three times the original height so it's not blurry
                height: 100 * 3,
              }}
            />
          </div>
          {this.renderDisabledOverlay()}
          {this.renderIndicator()}
          {this.renderSelected()}
          {this.renderOptionMenu()}
          {this.renderHoverLayout()}
        </div>
      </>
    );
  };

  renderIndicator = () => {
    const { disabled, indicatorText } = this.props;

    if (!disabled) return null;

    return <UIndicator mode="disabled" text={indicatorText} style={styles.indicator} />;
  };

  renderLockedIcon = () => {
    const { locked } = this.props;

    if (!locked) return null;

    return <UIcon name="lock" size={12} color={COLORS.TEXT.SECONDARY_DEFAULT} />;
  };

  renderContent = () => {
    const { id, title, locale, disabled, types } = this.props;

    const localeStyle = types.length ? { marginLeft: 10 } : {};
    const containerStyle = disabled
      ? ({ ...styles.contentContainer, WebkitUserSelect: 'none' } as const)
      : styles.contentContainer;

    return (
      <div style={containerStyle}>
        <div style={styles.contentId}>{id}</div>
        <div style={styles.title}>{title}</div>
        <div style={styles.cardFooter}>
          <div style={styles.infoContainer}>
            {this.renderGameplayIndicator()}
            <span style={{ ...styles.locale, ...localeStyle }}>{locale}</span>
            {this.renderStatusIndicator()}
          </div>
          {this.renderLockedIcon()}
        </div>
      </div>
    );
  };

  renderGameplayIndicator = () => {
    const { types } = this.props;

    const filteredArray = GAMEPLAY.filter((game) => types.find((type) => type === game));
    const sizeArray = filteredArray.length - 1;
    const hasMultipleTypes = types.length > 1;

    return filteredArray.map((type: GameplayType, key: number) => {
      const style = sizeArray !== key ? { marginRight: 3 } : {};
      const replacedType = type.replace('_', '-') as GameType;

      return <UGameplayIndicator key={type} type={replacedType} hasMultipleTypes={hasMultipleTypes} style={style} />;
    });
  };

  renderStatusIndicator = () => {
    const { statusIndicatorType, statusIndicatorTooltip } = this.props;

    if (!statusIndicatorType) return null;

    return (
      <div data-tip data-for={this.tooltipId} style={styles.statusIndicator}>
        <UStatusIndicator type={statusIndicatorType} />
        {statusIndicatorTooltip ? (
          <STooltip id={this.tooltipId} content={statusIndicatorTooltip} position="top" />
        ) : null}
      </div>
    );
  };

  render() {
    const { isHovered } = this.state;
    const { locked, style, canBeDragged } = this.props;

    const boxShadow = isHovered ? customBoxShadow.hover : this.getBoxShadow();

    const dragStyle = locked ? { WebkitUserDrag: 'none' } : {};
    const cursorStyle = canBeDragged ? { cursor: 'grab' } : {};

    return (
      <div
        className="ReactDraggable"
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        style={{
          ...styles.cardWrapper,
          boxShadow,
          ...dragStyle,
          ...cursorStyle,
          ...style,
        }}
      >
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

export default SLegacyContentCard;
