import React from 'react';
import { t } from 'i18next';
import type { CSSProperties } from 'react';
import { GameType } from '@sparted/shared-library/business/types';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UGameplayIndicator.style';

export type UGameplayIndicatorProps = {
  style?: CSSProperties;
  type: GameType;
  hasMultipleTypes?: boolean;
};

const useGameplayTranslations = () => {
  return {
    'swiping-cards': t('unit_components:u_gameplay_indicator.swiping_cards'),
    'picture-spot': t('unit_components:u_gameplay_indicator.picture_spot'),
    'open-question': t('unit_components:u_gameplay_indicator.open_question'),
    'no-interaction': t('unit_components:u_gameplay_indicator.no_interaction'),
    mcq: t('unit_components:u_gameplay_indicator.qcm'),
    'error-text': t('unit_components:u_gameplay_indicator.error_text'),
    'ordering-cards': t('unit_components:u_gameplay_indicator.ordering_cards'),
  };
};

export const getLabel = (type: GameType): string => {
  const translations = useGameplayTranslations();
  return translations[type] || type;
};

/**
 * UGameplayIndicator
 * type: type of the indicator
 * style: style of the component
 */
export const UGameplayIndicator = ({ style = {}, type, hasMultipleTypes }: UGameplayIndicatorProps) => {
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <UIcon color={COLORS.TEXT.SECONDARY_DEFAULT} name={type} size={14} style={styles.icon} />
      {!hasMultipleTypes && <div style={styles.text}>{getLabel(type)}</div>}
    </div>
  );
};

export default UGameplayIndicator;
