// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    marginBottom: 15,
    ...TYPOGRAPHY.BODY3,
  },
  content: {
    marginLeft: 10,
    marginTop: 1,
  },
  checkbox: {
    minWidth: 14,
    marginTop: 3,
  },
};
