import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import type { ClassicComponent, FunctionComponent } from 'react';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams, AlertType } from '../redux/models/Alert';
import { actions, useModuleSelector } from '../redux';

import styles from './ContributorAlertView.style';

type Props = {
  component: ClassicComponent<any, any> | FunctionComponent<any>;
  props: any;
};

const ALL_SCOPES: ReadonlyArray<AlertScope> = [
  'fetchAllError',
  'inviteSuccess',
  'removeContributorSuccess',
  'resendInviteContributorSuccess',
] as const;

export const IDS: { [key in AlertScope]?: number } = ALL_SCOPES.reduce((acc, x, i) => ({ ...acc, [x]: i }), {});

export const SCOPES: { [key: number]: AlertScope } = ALL_SCOPES.reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  fetchAllError: t('config_contributors:contributors.alert.types.fetch_all_error'),
  inviteSuccess: t('config_contributors:contributors.alert.types.invite_success'),
  removeContributorSuccess: t('config_contributors:contributors.alert.types.remove_contributor_success'),
  resendInviteContributorSuccess: t('config_contributors:contributors.alert.types.resend_invite_contributor_success'),
};

const TITLES = {
  fetchAllError: t('config_contributors:contributors.alert.titles.fetch_all_error'),
  inviteSuccess: '',
  removeContributorSuccess: '',
  resendInviteContributorSuccess: '',
};

// eslint-disable-next-line complexity
export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const { message = '', email = '', isInvitation } = params;

  switch (scope) {
    case 'fetchAllError':
      return t('config_contributors:contributors.alert.invitation_error', { message });
    case 'inviteSuccess':
      return t('config_contributors:contributors.alert.invitation_sent', { email });
    case 'removeContributorSuccess':
      return isInvitation
        ? t('config_contributors:contributors.alert.invitation_revoked')
        : t('config_contributors:contributors.alert.invitation_revoked_confirmed');
    case 'resendInviteContributorSuccess':
      return t('config_contributors:contributors.alert.invitation_resent', { email });
    default:
      return '';
  }
};

const makeAlertsFromStateAlerts = (alerts: ReadonlyArray<AlertType>) =>
  alerts.map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

export const ContributorAlertView = ({ component, props }: Props) => {
  const stateAlerts = useModuleSelector((state) => state.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => withAlert({ wrapperStyle: styles.wrapper })(component), [component]);

  return <AlertHOC alerts={alerts} onClose={onClose} {...props} />;
};
