export const DEFAULT_PASSWORD_LENGTH = 8;

/**
 * Generates a password accepted by the system.
 * It will have a default length of 8 characters unless specified otherwise.
 * It will have a minimum of:
 * - 1 special character
 * - 1 number
 * - 1 uppercase letter
 * - 1 lowercase letter
 *
 * @param {number} length of random string
 */
function generatePassword(length: number = DEFAULT_PASSWORD_LENGTH): string {
  let text = '';
  const passwordLength = length < DEFAULT_PASSWORD_LENGTH ? DEFAULT_PASSWORD_LENGTH : length;
  const numbers = '0123456789';
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const uppercases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const specials = '`~!@#$%^&*()_|+=?;:\'",.<>//{}[]';
  const all = numbers + letters + uppercases + specials;

  text += numbers.charAt(Math.floor(Math.random() * numbers.length));
  text += uppercases.charAt(Math.floor(Math.random() * uppercases.length));
  text += specials.charAt(Math.floor(Math.random() * specials.length));
  text += letters.charAt(Math.floor(Math.random() * letters.length));

  const defaultLength = text.length;

  // The first 4 characters are already filled
  for (let i = 0; i < passwordLength - defaultLength; i += 1) {
    text += all.charAt(Math.floor(Math.random() * all.length));
  }

  return text;
}

export default {
  generatePassword,
};
