import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';

var SegmentationGroupItem = {
  endpoint  : 'api/SegmentationGroupItems',
  attributes: {
    label: {
      type: 'string',
    },
    members: {
      type : 'collection',
      Model: 'SegmentationItem',
    },
    dimension: {
      type: 'object',
    },
    dimensionId: {
      type: 'number',
    },
    availableGroupId: {
      type: 'number',
    },
    isDefault: {
      type      : 'boolean',
      defaultsTo: false,
    },
  },
  methods: {
    getParentGroups: function getParentGroups(_SegmentationGroupItem) {
      return request.get('api/SegmentationGroupItems/getParentGroups/' + this.id())
        .then(function handleResult(res) {
          return new Collection('SegmentationGroupItem', SegmentationGroupItem, res);
        });
    },
    setDefaultLanguage: function setDefaultLanguage(_SegmentationGroupItem) {
      return request.put('api/SegmentationGroupItems/setDefaultLanguage/' + this.id())
        .then(function handleResult(res) {
          return new _SegmentationGroupItem(res);
        });
    },
    destroy: function destroy() {
      return request.destroy('api/SegmentationGroupItems/full/' + this.id());
    },
    updateLabel: function updateLabel(_SegmentationGroupItem) {
      return request.put('api/SegmentationGroupItems/updateLabel/' + this.id(), {
        label: this.label(),
      })
        .then(function handleResult(res) {
          return new _SegmentationGroupItem(res);
        });
    },
  },
  classMethods: {
    getConvenients: function getConvenients(_SegmentationGroupItem, selected, inputFilter) {
      return request.get('api/SegmentationGroupItems/findConvenient', {
        existingGIs: selected.toJSON(),
        inputFilter: inputFilter,
      })
        .then(function handleResult(res) {
          return new Collection('SegmentationGroupItem', SegmentationGroupItem, res);
        });
    },
    substractSegmentation: function substractSegmentation(_SegmentationGroupItem, originalSegmentation, toRemove) {
      var hash = {};

      toRemove.reduce(idAsKey, hash);

      var newSegmentation = originalSegmentation.filter(notInHash);

      return new Collection('SegmentationGroupItem', SegmentationGroupItem, newSegmentation);

      function notInHash(item) {
        return !hash[item.id()];
      }
    },
    filterOutSegmentation: function filterOutSegmentation(_SegmentationGroupItem, segmentationItems, playerSegmentation) {
      var toHide = _SegmentationGroupItem.filterWithUserSegmentation(segmentationItems, playerSegmentation);
      var map = {};

      toHide.reduce(idAsKey, map);

      var finalSegmentation = segmentationItems.filter(shouldBeHidden);

      return new Collection('SegmentationGroupItem', SegmentationGroupItem, finalSegmentation);

      function shouldBeHidden(item) {
        return !map[item.id()];
      }
    },
    filterWithUserSegmentation: function filterWithUserSegmentation(_SegmentationGroupItem, segmentationItems, playerSegmentation) {
      var columns = {};
      var playerColumns = {};
      var finalSegmentation = [];
      var finalSegmentationIds = {};

      playerSegmentation = playerSegmentation || [];
      segmentationItems = segmentationItems.toArray();

      console.log('[segmentation]', 'We have access to', segmentationItems.length);

      playerSegmentation.reduce(turnToColumnsOfIDs, playerColumns);
      segmentationItems.reduce(segItemsToColumnsOfIDs, columns);

      for (var col in columns) {
        var p = playerColumns[col];

        // Overrides segmentation with player segmentation
        if (p && p.length)
          columns[col] = p;

        // Appends to the hash
        for (var i = 0; i < columns[col].length; i++) {
          var index = columns[col][i];

          finalSegmentationIds[index] = true;
        }
      }

      var count = Object.keys(finalSegmentationIds).length;

      console.log('[segmentation]', 'Player is restricted to', count);

      finalSegmentation = segmentationItems.sort().filter(itemIsAllowed);

      console.log('[segmentation]', 'Player has access to', finalSegmentation.length);

      return new Collection('SegmentationGroupItem', SegmentationGroupItem, finalSegmentation);

      function itemIsAllowed(o) {
        if (o.parentId() && !finalSegmentationIds[o.parentId()])
          finalSegmentationIds[o.id()] = false;

        return finalSegmentationIds[o.id()];
      }
    },
  },
};

function idAsKey(map, item) {
  map[item.id()] = item;

  return map;
}

function turnToColumnsOfIDs(hash, item) {
  var index = item.dimensionId();
  var members = item.members();

  if (!hash[index])
    hash[index] = [];

  hash[index] = hash[index].concat(members.map(getId));

  return hash;
}

function segItemsToColumnsOfIDs(hash, item) {
  var index = item.group().dimensionId();

  if (!hash[index])
    hash[index] = [];

  hash[index].push(item.id());

  return hash;
}

function getId(o) {
  return o.id();
}

export default Model.register('SegmentationGroupItem', SegmentationGroupItem);
