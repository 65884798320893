/* eslint-disable react/jsx-no-bind */
// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { Document } from 'ModelsReact';

import { COLORS } from 'Components/foundation';
import { PDF_MIME_TYPES } from 'Components/utils/Enum';
import SUploadDropArea from 'Components/structural/SUploadDropArea/SUploadDropArea';

import { actions } from '../../redux';
import styles from './DocumentListDropZone.style';

export const DocumentListDropZone = () => {
  const dispatch = useTypedDispatch();

  const handleDocumentModalVisibility = useCallback((file: any) => {
    dispatch(actions.setDocumentModalVisibility(true, Document(), file));
  }, [dispatch]);

  return (
    <>
      <div style={styles.uploadDropZoneTitle}>{t('documents:document_list_drop_zone.title')}</div>

      <SUploadDropArea
        backgroundColor={COLORS.PRIMARY_LIGHT.DEFAULT}
        dotsColor={COLORS.PRIMARY.DEFAULT}
        defaultSentence={t('documents:document_list_drop_zone.default_sentence')}
        defaultLabel={t('documents:document_list_drop_zone.default_label')}
        dropSentence={t('documents:document_list_drop_zone.drop_sentence')}
        warnSentence1={t('documents:document_list_drop_zone.warn_sentence1')}
        warnSentence2={t('documents:document_list_drop_zone.warn_sentence2')}
        errorIncorrectFormat1={t('documents:document_list_drop_zone.error_incorrect_format1')}
        errorIncorrectFormat2={t('documents:document_list_drop_zone.error_incorrect_format2')}
        errorSizeTooBig={t('documents:document_list_drop_zone.error_size_too_big')}
        errorManyFiles={t('documents:document_list_drop_zone.error_many_files')}
        uploadingSentence={t('documents:document_list_drop_zone.uploading_sentence')}
        allowedMimeTypes={[PDF_MIME_TYPES.PDF]}
        onDrop={handleDocumentModalVisibility}
      />
    </>
  );
};
