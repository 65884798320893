// @flow

/**
 * This component render a cell of the header row
 *
 * Props:
 *  - text: The text displayed
 *  - canBeSorted: Define if we should see or not the sorting icons
 *  - align: Item position
 *  - onClick: Action trigger to sort
 *  - style: For overriding the component's style
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';

import { COLORS } from 'Components/foundation';

import styles from './UTableCellHeader.style';

type SortingType = 'up' | 'down' | 'default';

type Align = 'left' | 'center';

type Props = {|
  text: string,
  canBeSorted?: boolean,
  align?: Align,
  onClick?: Function,
  style?: Object,
|};

type State = {|
  sortingType: SortingType,
|};

const sortingTypeToOrder = {
  'up': 'asc',
  'down': 'desc',
  'default': 'none',
};

export class UTableCellHeader extends React.Component<Props, State> {
  static defaultProps = {
    canBeSorted: false,
    align: 'left',
    onClick: () => {},
    style: undefined,
  };

  state = {
    sortingType: 'default',
  };

  render() {
    const { text, align, canBeSorted, style } = this.props;

    return (
      <div
        style={{
          ...styles.wrapper,
          justifyContent: align,
          cursor: canBeSorted ? 'pointer' : 'default',
          ...style,
        }}
        onClick={this.handleClick}
      >
        <div style={styles.text}>
          {text}
        </div>
        <div style={styles.icon}>
          {this.renderSortingComponent()}
        </div>
      </div>
    );
  }

  renderSortingComponent = () => {
    const { canBeSorted } = this.props;
    const { sortingType } = this.state;

    if (!canBeSorted)
      return null;

    if (sortingType === 'up')
      return this.renderArrows(true, false);
    else if (sortingType === 'down')
      return this.renderArrows(false, true);

    return this.renderArrows(true, true);
  };

  renderArrows = (sortingUp: boolean, sortingDown: boolean) => {
    return [
      this.renderArrowUp(sortingUp),
      this.renderArrowDown(sortingDown),
    ];
  };

  renderArrowUp = (enable: boolean) => {
    const color = enable
      ? COLORS.TEXT.SECONDARY_DEFAULT
      : COLORS.TEXT.SECONDARY_DISABLED;

    return (
      <UIcon
        key="arrow-up"
        name="arrow-up"
        size={5}
        color={color}
      />
    );
  };

  renderArrowDown = (enable: boolean) => {
    const color = enable
      ? COLORS.TEXT.SECONDARY_DEFAULT
      : COLORS.TEXT.SECONDARY_DISABLED;

    return (
      <UIcon
        key="arrow-down"
        name="arrow-down"
        size={5}
        color={color}
      />
    );
  };

  handleClick = () => {
    const { canBeSorted } = this.props;
    const { sortingType } = this.state;

    if (!canBeSorted)
      return ;

    if (sortingType === 'default')
      this.setState({ sortingType: 'up' }, this.callCallBackProps);
    else if (sortingType === 'up')
      this.setState({ sortingType: 'down' }, this.callCallBackProps);
    else if (sortingType === 'down')
      this.setState({ sortingType: 'default' }, this.callCallBackProps);
  };

  callCallBackProps = () => {
    const { sortingType } = this.state;
    const { onClick } = this.props;

    if (onClick)
      onClick(sortingTypeToOrder[sortingType]);
  };
}

export default UTableCellHeader;
