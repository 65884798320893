// @flow

export default {
  wrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  gridWrapper: {
    height: '100%',
  },
};
