import React from 'react';
import type { FunctionComponent, HTMLAttributes } from 'react';

import styles from './SlimContainer.style';

export const SlimContainer: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ children, style, ...rest }) => {
  const containerStyles = { ...styles.container, ...style };

  return (
    <div style={containerStyles} {...rest}>
      {children}
    </div>
  );
};
