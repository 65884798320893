// @flow

import React, { useCallback } from 'react';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { useTranslation } from 'react-i18next';

import { GAMEPLAY_KEYS } from 'Components/utils/Enum';

import STextArea from 'Components/structural/STextArea/STextArea';
import { actions } from '../../../redux';

import styles from './OCExplanationForm.style';

export const OCExplanationForm = () => {
  const { t } = useTranslation();

  const content = useTypedSelector(state => state.pages.content.content);
  const canEdit = useTypedSelector(state => state.pages.content.canEditContent);

  const orderingCards = content[GAMEPLAY_KEYS.ORDERING_CARDS];

  const explanation = orderingCards.explanation.data;

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setOCExplanation(value)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.label}>
        {t('gameplays:ordering_cards.explanation_text')}
      </div>

      <STextArea
        placeholder={t('gameplays:ordering_cards.explanation_text_placeholder')}
        value={explanation}
        height={88}
        maxLength={500}
        onChange={onChange}
        disabled={!canEdit}
      />
    </div>
  );
};

export default OCExplanationForm;
