import m from 'm';
import popup from 'services/popupService';
import App from 'models/App';
import Model from 'models/Model';
import menu from 'pages/menu';
import button from 'components/button';
import gaiaMenu from 'components/gaia.menu';
import authSettingsItem from 'components/settings.authentication.block.js';

var component = {};

component.controller = function controller() {
  var self = this;

  self.authRulesList = App.AuthenticationSettings();

  self.modifications = m.prop(self.authRulesList.map(function returnFalse() {
    return m.prop(false);
  }));
  self.originalAuthRulesList = App.AuthenticationSettings().clone();

  console.log('[gaia.signup]', self.authRulesList.toJSON());

  self.segmentationGroupItems = App.segmentationItems()
    .map((segItem) => segItem.group())
    .filter((x, i, a) => a.findIndex((y) => y.id() === x.id()) === i)
    .sort((a, b) => a.dimensionId() - b.dimensionId());

  self.addSettings = function addSettings() {
    var authRule = Model.new('AuthenticationSettings');

    self.authRulesList.push(authRule);
    self.modifications().push(m.prop(true));
    m.redraw();
  };

  self.removeRule = function removeRule(index) {
    self.authRulesList.removeAt(index);
    delete self.modifications().splice(index, 1);
    m.redraw();
  };

  self.confirmPageExit = function confirmPageExit(action) {
    var modified = false;

    self.modifications().forEach(function hasModifications(modif) {
      if (modif())
        modified = true;
    });

    if (!modified)
      return action();
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit this page? All unsaved modifications will be lost.',
      confirm: 'Quit',
      cancel : 'Don\'t quit',
      action : action,
    });
  };
};

component.view = function view(c) {
  return m('.gaia__signup__page', [
    m('.header', [
      m(menu, {
        confirm: c.confirmPageExit,
      }),
      m(gaiaMenu, {
        confirm: c.confirmPageExit,
      }),
    ]),
    m('.gaia__signup__body', [
      m('.gaia__signup__toolbar', [
        m('.gaia__signup__title', 'Authentication Settings'),
      ]),
      m('.authentication__settings__list',
        c.authRulesList.map(function displaySettings(authRule, index) {
          return m(authSettingsItem, {
            authRule              : authRule,
            index                 : index,
            removeRule            : c.removeRule.bind(null, index),
            originalList          : c.originalAuthRulesList,
            segmentationGroupItems: c.segmentationGroupItems,
            modified              : c.modifications()[index],
          });
        }),
      ),
      m('.gaia__signup__toolbar--right', [
        m(button, {
          class : 'activity-toolbar__button--save button--action',
          action: c.addSettings,
          value : 'Add Exception',
        }),
      ]),
    ]),
  ]);
};

export default component;
