import { useCallback, useMemo, useState } from 'react';

export type UseDeleteAudienceGroupModalArgs = {
  onDone: (id: number) => void;
};

export const useDeleteAudienceGroupModal = ({
  onDone,
}: UseDeleteAudienceGroupModalArgs) => {
  const [audienceGroupId, setAudienceGroupId] = useState<number>(-1);

  const onClose = useCallback(() => {
    setAudienceGroupId(-1);
  }, []);

  const handleOnDone = useCallback(async () => {
    onDone(audienceGroupId);
    onClose();
  }, [audienceGroupId, onClose, onDone]);

  const deleteAudienceGroupModalProps = useMemo(() => ({
    audienceGroupId,
    onDone: handleOnDone,
    onClose,
  }), [audienceGroupId, handleOnDone, onClose]);

  return {
    deleteAudienceGroupModalProps,
    openDeleteModal: setAudienceGroupId,
  };
};
