// @flow

/**
 * Table cell segment is cell that represent multiple chips
 * Work only alongside UTableComponent
 *
 * Props:
 *  - items: contains multiple chips
 */

import * as React from 'react';

import UChips from 'Components/unit/UChips/UChips';

import styles from './UTableCellSegment.style';

type Chip = {|
  value: string,
  color?: string,
|};

type Props = $ReadOnly<{|
  items: $ReadOnlyArray<Chip>,
  style?: Object,
|}>;

export class UTableCellSegment extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { items, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {items.map(this.renderChip)}
      </div>
    );
  }

  renderChip(chip: Chip, index: number) {
    return (
      <UChips
        key={index}
        style={styles.chip}
        type='compact'
        {...chip}
      />
    );
  }
}

export default UTableCellSegment;
