import { COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export const styles = createStyleSheet({
  textAreaWrapperForOverlay: {
    position: 'relative',
  },
  textAreaWithHighlightWrapper: {
    position: 'relative',
    flex: 1,
  },
  textAreaWithHighlightOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  uncomparedText: {
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  textArea: {
    lineHeight: '34px',
  },
});