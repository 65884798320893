import React from 'react';

import type { CSSProperties, FunctionComponent } from 'react';

import type { IllustrationName } from 'Components/foundation/illustrations';

import { COLORS } from 'Components/foundation';

import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UEntityCardCore from 'Components/unit/UEntityCardCore/UEntityCardCore';
import UBackground from 'Components/unit/UBackground/UBackground';

import styles from './SNewItemCard.style';

export type SNewItemCardProps = {
  style?: CSSProperties;
  title: string;
  selected?: boolean;
  description: string;
  illustration: IllustrationName;
  onClick: () => void;
  onSelect?: () => void;
};

export const SNewItemCard: FunctionComponent<SNewItemCardProps> = ({
  style = {},
  selected,
  description,
  illustration,
  title,
  onClick,
  onSelect,
}) => (
  <div style={style}>
    <UEntityCardCore
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}
      disabled={false}
      selectionVariant="radio"
      styleConfig={{
        paddingVertical: 20,
      }}
    >
      <div style={styles.container}>
        <div style={styles.illustrationContainer}>
          <UBackground name="shape-background" height={125} width={125} color="PRIMARY" borderRadius={8} />
          <div style={styles.illustration}>
            <UIllustration illustration={illustration} height={75} width={75} color={COLORS.PRIMARY} />
          </div>
        </div>
        <div style={styles.content}>
          <div style={styles.title}>{title}</div>
          <div style={styles.description}>{description}</div>
        </div>
      </div>
    </UEntityCardCore>
  </div>
);

export default SNewItemCard;
