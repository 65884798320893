import { useEffect, useRef } from 'react';

/**
 * Send the previous value of a props ar state.
 *
 * Works by returning the ref before the update in the useEffect.
 * ```
 * const [state] = useState();
 * const previousState = usePrevious(state);
 * ```
 */
export const usePrevious = <T>(value: T, predicate: (value: T) => boolean = () => true) => {
  const previousValue = useRef<T>();

  useEffect(() => {
    if (predicate(value)) previousValue.current = value;
  }, [value]);

  return previousValue.current;
};
