// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: 8,
    paddingLeft: 17,
    paddingRight: 17,
    height: '100%',
    overflow: 'auto',
  },
  placeholder: {
    height: 42,
    textAlign: 'center',
    paddingTop: 8,
    paddingLeft: 17,
    paddingRight: 17,
    ...TYPOGRAPHY.BODY3,
  },
  galleryItemLoading: {
    marginBottom: 16,
  },
  loaderContainer: {
    paddingTop: 16,
    display: 'flex',
  },
  loaderColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
  },
};
