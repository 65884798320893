import Model from 'models/Model';

var OrderingCardsItem = {
  endpoint  : 'api/OrderingCardsItems',
  attributes: {
    orderingCards: {
      type : 'pointer',
      Model: 'OrderingCards',
    },
    orderingCardsId: {
      type: 'number',
    },
    cardContent: {
      type : 'pointer',
      Model: 'GameItem',
    },
    cardContentId: {
      type: 'number',
    },
    order: {
      type: 'number',
    },
  },
  methods: {},
};

export default Model.register('OrderingCardsItem', OrderingCardsItem);
