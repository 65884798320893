import { GameType } from '@sparted/shared-library/business/types';
import React, { CSSProperties, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UImage from 'Components/unit/UImage/UImage';
import USelectorToggle from 'Components/unit/USelectorToggle/USelectorToggle';

import { TranslationStatus } from '../SFlagGroup/SFlagGroup';
import styles, { getBoxShadow } from './SContentCard.style';
import { AlreadyUsedOverlay } from './components/AlreadyUsedOverlay';
import { Content } from './components/Content';
import { HoverLayout } from './components/HoverLayout';

export type CardOption = {
  id: number;
  onClick: (id: number) => any;
  text: string;
  color?: string;
};

export type SContentCardProps = {
  // required
  id: string;
  imageUrl: string;
  title: string;
  type?: Readonly<GameType>;
  translationStatuses: TranslationStatus[];

  // optional
  selectable?: boolean;
  onSelect?: (isSelected: boolean) => any;

  previewable?: boolean;
  previewText?: string;
  onPreviewClick?: MouseEventHandler;

  displayOption?: boolean;
  options?: ReadonlyArray<CardOption>;

  selected?: boolean;
  locked?: boolean;
  alreadyUsed?: boolean;
  canShowOverlay?: boolean;
  canBeDragged?: boolean;
  style?: CSSProperties;
};

export const SContentCard = ({
  selectable = true,
  previewable = true,
  displayOption = true,
  selected = false,
  locked = false,
  canShowOverlay = true,
  canBeDragged = false,
  previewText = '',
  options = [],
  onPreviewClick = () => {},
  onSelect = () => {},
  style = undefined,
  alreadyUsed = false,
  imageUrl,
  type,
  translationStatuses,
  id,
  title,
}: SContentCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const handleHover = () => setIsHovered(true);
  const handleLeave = () => setIsHovered(false);

  const handleToggleMenu = () => setIsOptionsVisible((prev) => !prev);

  const handleOptionClick = useCallback(
    (id: number) => {
      const option = options.find((item: CardOption) => item.id === id);

      setIsOptionsVisible(false);

      if (option) option.onClick(id);
    },
    [options],
  );

  const shouldRenderOptions = isOptionsVisible && options.length !== 0;
  const renderOptionItem = useCallback(
    (option: CardOption) => <UDropdownItem key={option.id} {...option} onClick={handleOptionClick} />,
    [handleOptionClick],
  );

  const shouldRenderHoverLayout = isHovered && canShowOverlay;

  const boxShadow = useMemo(() => getBoxShadow(isHovered, locked, selected), [isHovered, locked, selected]);

  const dragStyle = locked ? { draggable: false } : {};
  const cursorStyle = canBeDragged ? { cursor: 'grab' } : {};

  return (
    <div
      className="ReactDraggable"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={{
        ...styles.cardWrapper,
        boxShadow,
        ...dragStyle,
        ...cursorStyle,
        ...style,
      }}
    >
      <div style={styles.headerContainer}>
        <div style={styles.imageContainer}>
          <UImage
            style={styles.image}
            src={imageUrl}
            transformation={{
              // Three times the original height so it's not blurry
              height: 100 * 3,
            }}
          />
        </div>
        {selectable && (
          <div style={styles.selectorWrapper} className="nonDraggable">
            <USelectorToggle selected={selected} onClick={onSelect} enableHover={false} hasContrastBackground />
          </div>
        )}
        {shouldRenderOptions && (
          <div style={styles.optionsDropdownWrapper} className="nonDraggable">
            <UDropdownBox items={options} renderItem={renderOptionItem} style={styles.dropdownBox} />
          </div>
        )}
        {shouldRenderHoverLayout && (
          <HoverLayout {...{ previewable, previewText, displayOption, handleToggleMenu, onPreviewClick }} />
        )}
      </div>
      <Content {...{ id, title, locked, translationStatuses, type }} />
      {alreadyUsed && <AlreadyUsedOverlay />}
    </div>
  );
};

export default SContentCard;
