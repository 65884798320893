import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const CourseErrorIllustration = ({ width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="54.0224" cy="69.1864" r="52.1269" fill="#FFEDED" />
    <g clipPath="url(#clip0_7489_280120)">
      <path
        d="M47.1093 28.2792L30.6357 85.5634C30.1778 87.1556 31.0909 88.8193 32.6752 89.2795L72.3098 100.792C73.8941 101.252 75.5496 100.334 76.0074 98.7419L92.4811 41.4577C92.939 39.8656 92.0258 38.2018 90.4416 37.7417L50.8069 26.2295C49.2227 25.7694 47.5672 26.6871 47.1093 28.2792Z"
        fill={COLORS.ERROR.DEFAULT}
        stroke={COLORS.ERROR.DEFAULT}
        strokeWidth="1.92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0435 76.9292L17.597 80.0523L15.8892 82.0148L12.3358 78.8917L14.0435 76.9292Z"
        fill={COLORS.ERROR.DEFAULT}
      />
      <path
        d="M55.0494 10.8329L13.3834 14.4006C11.0042 14.6044 9.23977 16.7079 9.44249 19.099L14.5479 79.3192C14.7506 81.7103 16.8437 83.4835 19.2229 83.2798L60.889 79.712C63.2682 79.5082 65.0326 77.4047 64.8299 75.0136L59.7244 14.7934C59.5217 12.4023 57.4287 10.6291 55.0494 10.8329Z"
        fill={COLORS.ERROR.DEFAULT}
      />
      <path
        d="M51.341 8.66813L9.675 12.2359C8.00954 12.3785 6.77446 13.851 6.91636 15.5248L12.0218 75.7449C12.1637 77.4187 13.6289 78.66 15.2943 78.5174L56.9603 74.9496C58.6258 74.807 59.8609 73.3345 59.719 71.6607L54.6135 11.4405C54.4716 9.76677 53.0065 8.52552 51.341 8.66813Z"
        fill={COLORS.ERROR.LIGHT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78503 13.5349C8.83334 13.6164 8.12758 14.4579 8.20866 15.4143L13.3141 75.6345C13.3952 76.5909 14.2324 77.3002 15.1841 77.2187L56.8501 73.6509C57.8018 73.5694 58.5076 72.728 58.4265 71.7716L53.3211 11.5514C53.24 10.595 52.4028 9.88567 51.4511 9.96716L9.78503 13.5349ZM5.62389 15.6356C5.42117 13.2445 7.18558 11.141 9.5648 10.9373L51.2308 7.36948C53.6101 7.16575 55.7031 8.93897 55.9059 11.3301L61.0113 71.5502C61.214 73.9413 59.4496 76.0449 57.0704 76.2486L15.4043 79.8164C13.0251 80.0201 10.932 78.2469 10.7293 75.8558L5.62389 15.6356Z"
        fill={COLORS.ERROR.DEFAULT}
      />
      <path
        d="M37.9555 29.5839L36.3207 10.0714L47.7511 9.09375L49.413 28.6062L42.8871 25.2251L37.9555 29.5839Z"
        fill={COLORS.WHITE.DEFAULT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6455 7.84686C48.3305 7.78828 48.9332 8.29866 48.9918 8.98704L50.6537 28.4995C50.6923 28.9534 50.4825 29.3925 50.1059 29.646C49.7293 29.8995 49.2453 29.9273 48.8424 29.7185L43.0716 26.7286L38.7779 30.5236C38.4247 30.8357 37.9268 30.9212 37.4905 30.7447C37.0543 30.5682 36.7542 30.1598 36.7147 29.6889L35.0799 10.1764C35.0222 9.48816 35.5303 8.8831 36.2151 8.82452L47.6455 7.84686ZM37.6659 11.2123L38.989 27.004L42.0648 24.2854C42.4495 23.9454 43.0023 23.8769 43.4577 24.1129L47.9801 26.4559L46.6166 10.4467L37.6659 11.2123Z"
        fill={COLORS.ERROR.DEFAULT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1369 79.4968L13.3947 11.7667L15.876 11.5542L21.6183 79.2843L19.1369 79.4968Z"
        fill={COLORS.ERROR.DEFAULT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3294 8.35059L58.5584 12.2476L56.8052 14.1692L52.5762 10.2722L54.3294 8.35059Z"
        fill={COLORS.ERROR.DEFAULT}
      />
    </g>
    <circle cx="100.463" cy="32.2238" r="26.5373" fill={COLORS.ERROR.LIGHT} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.5336 34.1808L97.8488 22.1976C97.8143 21.5936 98.3646 21.0874 99.0557 21.0874H103.765C104.457 21.0874 105.007 21.5936 104.972 22.1976L104.288 34.1808C104.255 34.7436 103.725 35.1853 103.081 35.1853H99.7404C99.0965 35.1853 98.5657 34.7436 98.5336 34.1808ZM101.411 46.8665C103.632 46.8665 105.439 45.0596 105.439 42.8385C105.439 40.6175 103.632 38.8105 101.411 38.8105C99.1895 38.8105 97.3826 40.6175 97.3826 42.8385C97.3826 45.0596 99.1895 46.8665 101.411 46.8665Z"
      fill={COLORS.ERROR.DEFAULT}
    />
    <defs>
      <clipPath id="clip0_7489_280120">
        <rect width="98.5672" height="98.5672" fill={COLORS.WHITE.DEFAULT} transform="translate(0 5.68652)" />
      </clipPath>
    </defs>
  </svg>
);

export default CourseErrorIllustration;
