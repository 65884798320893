// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  inputWrapper: {
    width: '100%',
    minWidth: '30px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
  },
  focusedInput: {
    borderColor: COLORS.GREY_DARK.OUTLINE_FOCUSED,
  },
  revealButton: {
    marginRight: '4px',
  },
  textField: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    outline: 'none',
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 11,
    ...TYPOGRAPHY.BODY3,
  },
  validationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 2,
    userSelect: 'none',
  },
  validationItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginRight: 20,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  validationIcon: {
    marginRight: 6,
  },
};
