import Fuse from 'fuse.js';
import { handle } from 'redux-pack';

import { getErrorMessage } from 'Libs/redux/utils';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import Enum from 'Models/Enum';
import { ROLES_AVAILABLE_BY_EDITOR_ROLE } from 'Components/utils/Enum';

import createRole from 'ModelsReact/Role/Role';

import type { RoleType } from 'ModelsReact/Role/Role';

import type { Action } from 'Libs/redux/types';
import createContributor from './models/Contributor';

import type { ContributorType } from './models/Contributor';
import type { AlertType } from './models/Alert';

type SortKeysType = 'name' | 'role';
type OrderType = 'asc' | 'desc';

type IsModalOpenType = {
  isDeleteModalOpen?: boolean;
  isManageModalOpen?: boolean;
  isResendInviteModalOpen?: boolean;
  isSelfEditModalOpen?: boolean;
};

export type ManageContributorModalType = {
  originalContributor: ContributorType;
  contributorToManage: ContributorType;
  error: string;
  isOpen: IsModalOpenType;
};

export type ContributorListState = DeepReadonly<{
  contributors: ReadonlyArray<ContributorType>;
  contributorCount: number;
  manageContributorModal: ManageContributorModalType;
  alerts: ReadonlyArray<AlertType>;
  rolesAvailable: ReadonlyArray<RoleType>;
  isFetchAllLoading: boolean;
  loading: boolean;
}>;

const defaultContributor = createContributor();

const { ADMIN } = Enum.Role;

export const defaultState: ContributorListState = {
  contributors: [],
  contributorCount: 0,
  manageContributorModal: {
    originalContributor: { ...defaultContributor },
    contributorToManage: { ...defaultContributor },
    error: '',
    isOpen: {
      isDeleteModalOpen: false,
      isManageModalOpen: false,
      isResendInviteModalOpen: false,
      isSelfEditModalOpen: false,
    },
  },
  loading: true,
  alerts: [],
  rolesAvailable: [],
  isFetchAllLoading: false,
};

const SUCCESS_SCOPE = {
  CONTRIBUTOR_INVITE: 'inviteSuccess',
  CONTRIBUTOR_REMOVE: 'removeContributorSuccess',
  CONTRIBUTOR_RESEND_INVITE: 'resendInviteContributorSuccess',
} as const;

const ERROR_SCOPE = {
  CONTRIBUTOR_FETCH_ALL: 'fetchAllError',
} as const;

function compareValues(key: SortKeysType, order: OrderType) {
  function getKeyFromContributor(contributor: ContributorType) {
    switch (key) {
      case 'role':
        return contributor.roles[0]?.name || '';
      case 'name':
      default:
        return contributor.lastName;
    }
  }

  return function innerSort(a: ContributorType, b: ContributorType) {
    const aValue = getKeyFromContributor(a);
    const bValue = getKeyFromContributor(b);
    const comparison = aValue.localeCompare(bValue);

    return order === 'desc' ? comparison * -1 : comparison;
  };
}

function reorderRoles(rights: ReadonlyArray<number>, roles: Array<RoleType>) {
  const itemPositions = rights.reduce<{ [keyId: number]: number }>((acc, rightId, index) => {
    acc[rightId] = index;

    return acc;
  }, {});

  roles.sort((a, b) => itemPositions[a.id] - itemPositions[b.id]);

  return roles;
}

function getValidationMessage(error: any, params: any) {
  const { messages, codes } = error.details;

  if (messages.email?.length) {
    // By default the message is in the first index but in custom case it can be in the second index
    const index = messages.email.length - 1;
    const message = messages.email[index];

    return codes.email[index] === 'uniqueness' ? `'${params.email}' ${message}` : message;
  }

  return 'Something went wrong, please try again.';
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ContributorListState = defaultState, action: Action): ContributorListState => {
  switch (action.type) {
    case 'CONTRIBUTOR_FETCH_ALL':
      return handle(state, action, {
        start: (prevState: ContributorListState) =>
          mergeDeep(prevState, {
            isFetchAllLoading: true,
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]),
          }),
        success: (prevState: ContributorListState) => {
          const {
            payload: { editionAllowed, editionForbidden },
            meta: { filter },
          } = action;
          const { query, order } = filter;

          const contributorsAllowed: Array<ContributorType> = editionAllowed.map((contributor: ContributorType) => ({
            ...createContributor(contributor),
            isEditionAllowed: true,
          }));
          const contributorsForbidden: Array<ContributorType> = editionForbidden.map(
            (contributor: ContributorType) => ({ ...createContributor(contributor), isEditionAllowed: false }),
          );

          let newContributors: Array<ContributorType> = [...contributorsAllowed, ...contributorsForbidden]
            .filter((contributor) => contributor.status !== 'archived')
            .sort((contributorA, contributorB) => contributorA.id - contributorB.id);

          // Search in contributors refreshed
          if (query) {
            const search = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const options = { threshold: 0, keys: ['email', 'firstName', 'lastName'] };
            const fuse = new Fuse<ContributorType>(newContributors, options);

            newContributors = fuse.search(search).map(({ item }) => item);
          }

          // Reorder contributors
          if (order) {
            const [column, orderType] = order.split(' ');

            newContributors = newContributors.sort(compareValues(column, orderType));

            // If we are filtering by name, pending invited should be at the end of the list
            if (column === 'name') {
              const pendingInvited: Array<ContributorType> = [];

              const contributorsConfirmed = newContributors.reduce<Array<ContributorType>>(
                (contributorsPurged, currContributors) => {
                  if (currContributors.status !== 'inactive') {
                    contributorsPurged.push(currContributors);
                  } else {
                    pendingInvited.push(currContributors);
                  }

                  return contributorsPurged;
                },
                [],
              );

              newContributors = [...contributorsConfirmed, ...pendingInvited];
            }
          }

          return mergeDeep(prevState, { contributors: newContributors });
        },
        failure: (prevState: ContributorListState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState: ContributorListState) => mergeDeep(prevState, { isFetchAllLoading: false }),
      });

    case 'CONTRIBUTOR_FETCH_ROLES': {
      return handle(state, action, {
        start: (prevState: ContributorListState) =>
          mergeDeep(prevState, {
            loading: true,
          }),
        success: (prevState: ContributorListState) => {
          const {
            payload,
            meta: { editorRoleId },
          } = action;
          const defaultRights = ROLES_AVAILABLE_BY_EDITOR_ROLE[ADMIN];
          const rights = ROLES_AVAILABLE_BY_EDITOR_ROLE[editorRoleId] || defaultRights;

          const roles: Array<RoleType> = payload.reduce((rolesAvailable: Array<RoleType>, role: Partial<RoleType>) => {
            if (!rights.length || rights.includes(role.id!)) {
              rolesAvailable.push(createRole(role));
            }

            return rolesAvailable;
          }, []);

          const rolesReordered = reorderRoles(rights, roles);

          return mergeDeep(prevState, { rolesAvailable: rolesReordered });
        },
        finish: (prevState: ContributorListState) => mergeDeep(prevState, { loading: false }),
      });
    }

    case 'CONTRIBUTOR_TOGGLE_MODAL': {
      const { contributor, isOpen } = action;

      let contributorToManage = contributor ? { ...createContributor(contributor) } : { ...defaultContributor };
      const { rolesAvailable } = state;

      // If it is an invite we will set admin role in UI, because it should be the most common role
      if (isOpen.isManageModalOpen && !contributorToManage.id) {
        const adminRole = rolesAvailable.find((role) => role.id === ADMIN) || rolesAvailable[0];

        contributorToManage = { ...contributorToManage, roles: [adminRole] };
      }

      const modalState: ManageContributorModalType = {
        originalContributor: contributorToManage,
        contributorToManage,
        error: '',
        isOpen: { ...isOpen },
      };

      return mergeDeep(state, { manageContributorModal: { ...modalState } });
    }

    case 'CONTRIBUTOR_SET_SEGMENTATION': {
      return mergeDeep(state, {
        manageContributorModal: { contributorToManage: { segmentation: action.segmentation } },
      });
    }

    case 'CONTRIBUTOR_INVITE': {
      return handle(state, action, {
        start: (prevState: ContributorListState) =>
          mergeDeep(prevState, { isFetchAllLoading: true, manageContributorModal: { error: '' } }),
        success: (prevState: ContributorListState) => {
          const {
            payload,
            meta: { editorRoleId },
          } = action;
          const { contributors: oldContributors } = prevState;
          const rights = ROLES_AVAILABLE_BY_EDITOR_ROLE[editorRoleId] || ROLES_AVAILABLE_BY_EDITOR_ROLE[ADMIN];

          const newEntry = createContributor(payload);
          const isEditionAllowed = rights.includes(newEntry.roles[0].id);

          const newEntries = [...oldContributors, { ...newEntry, isEditionAllowed }];

          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: { email: newEntry.email },
            },
          ]);

          return mergeDeep(prevState, { contributors: newEntries, alerts });
        },
        failure: (prevState: ContributorListState) => {
          const { error } = action.payload;

          let message = 'Something went wrong, please try again.';

          if (error && error.name === 'ValidationError') {
            message = getValidationMessage(error, action.meta);
          }

          return mergeDeep(prevState, { manageContributorModal: { error: message } });
        },
        finish: (prevState: ContributorListState) => mergeDeep(prevState, { isFetchAllLoading: false }),
      });
    }

    case 'CONTRIBUTOR_UPDATE': {
      return handle(state, action, {
        success: (prevState: ContributorListState) => {
          const { payload: editedContributor } = action;
          const { contributors } = prevState;
          const { id } = editedContributor;

          const contributorId = contributors.findIndex((entry) => entry.id === id);
          const newContributorEdited = createContributor({ ...editedContributor, isEditionAllowed: true });
          const newContributors: Array<ContributorType> = [
            ...contributors.slice(0, contributorId),
            newContributorEdited,
            ...contributors.slice(contributorId + 1),
          ];

          return mergeDeep(prevState, { contributors: newContributors });
        },
        failure: (prevState: ContributorListState) =>
          mergeDeep(prevState, { manageContributorModal: { error: getErrorMessage(action) } }),
      });
    }

    case 'CONTRIBUTOR_REMOVE': {
      return handle(state, action, {
        start: (prevState: ContributorListState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]);

          return mergeDeep(prevState, { alerts, manageContributorModal: { error: '' } });
        },
        success: (prevState: ContributorListState) => {
          const { id, isInvitation } = action.meta;

          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: { isInvitation },
            },
          ]);

          return mergeDeep(prevState, {
            contributors: prevState.contributors.filter((contributor) => contributor.id !== id),
            alerts,
          });
        },
        failure: (prevState: ContributorListState) =>
          mergeDeep(prevState, { manageContributorModal: { error: getErrorMessage(action) } }),
      });
    }

    case 'CONTRIBUTOR_RESEND_INVITE': {
      return handle(state, action, {
        start: (prevState: ContributorListState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]);

          return mergeDeep(prevState, { alerts, manageContributorModal: { error: '' } });
        },
        success: (prevState: ContributorListState) => {
          const {
            payload: { email },
          } = action;

          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: { email },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        failure: (prevState: ContributorListState) =>
          mergeDeep(prevState, { manageContributorModal: { error: getErrorMessage(action) } }),
      });
    }

    case 'CONTRIBUTOR_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'CONTRIBUTOR_RESET':
      return defaultState;

    default:
      return state;
  }
};
