// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 8;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

export default {
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  textAreaWrapper: {
    width: '100%',
    minWidth: '300px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    ...BORDER_RADIUS,
  },
  textAreaField: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    color: COLORS.TEXT.DEFAULT,
    ...BORDER_RADIUS,
    outline: 'none',
    resize: 'none',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 14,
    paddingBottom: 14,
    overflow: 'hidden',
    ...TYPOGRAPHY.BODY3,
  },
  errorMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DARK,
    position: 'absolute',
    left: 0,
    bottom: -20,
  },
  count: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    fontWeight: 400,
    textAlign: 'right',
    position: 'absolute',
    top: -20,
    right: 0,
  },
  textDisabled: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  disabled: {
    backgroundColor: COLORS.TEXT.DISABLED,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  countReached: {
    color: COLORS.ERROR.DARK,
  },
};
