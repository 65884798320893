// @flow

/**
 * Display an input field
 *
 * Props:
 * - value: The value contained in the field
 * - placeholder: The placeholder of the input
 * - onValueChange: Function called when the value change in the field
 * - style: can override the component style
 */

import * as React from 'react';

import { COLORS } from 'Components/foundation';

import styles from './SInputField.style';


type Props = {|
  value?: string,
  placeholder: string,
  onValueChange: Function,
  style?: Object,
  onFocus?: Function,
  onBlur?: Function,
|};

type State = {|
  text: string,
  isFocused: boolean,
|};

export class SInputField extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    onFocus: () => {},
    onBlur: () => {},
    style: undefined,
  };

  constructor(props: Props) {
    super(props);

    const { value } = this.props;

    this.state = {
      text: value || '',
      isFocused: false,
    };
  }

  render() {
    const { placeholder, style: propStyle } = this.props;
    const { text, isFocused } = this.state;
    const { GREY_DARK } = COLORS;
    const borderColor = isFocused || text.length
      ? GREY_DARK.HOVER
      : GREY_DARK.DEFAULT;

    return (
      <div style={{ ...styles.wrapper, ...propStyle }}>
        <div style={{ ...styles.inputFieldContainer, borderColor }}>
          <input
            id="input-field"
            type="text"
            value={text}
            placeholder={placeholder}
            onChange={this.handleValueChange}
            style={{ ...styles.inputField }}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className="structural-inputs"
          />
        </div>
      </div>
    );
  }

  handleValueChange = (event: Object) => {
    const { onValueChange } = this.props;
    const text = event.target.value;

    this.setState({ text }, () => onValueChange(text));
  };

  handleFocus = () => {
    const { onFocus } = this.props;

    this.setState({ isFocused: true }, onFocus);
  };

  handleBlur = () => {
    const { onBlur } = this.props;

    this.setState({ isFocused: false }, onBlur);
  };
}

export default SInputField;
