import { fetchFactory } from 'Libs/http/utils';

export type DeleteAudienceGroupArgs = {
  id: number;
};

export type DeleteAudienceGroupResponse = {
  group: {
    id: number;
  };
};

export type DeleteAudienceGroupErrors = {
  type: 'cannot-delete-group',
  code: 'AUD-DAG-DAG-002',
  message: 'You cannot delete this group.',
  params: {
    reason: 'locked',
  },
} | {
  type: 'group-does-not-exist',
  code: 'AUD-DAG-DAG-001',
};

export const deleteAudienceGroup = async ({ id }: DeleteAudienceGroupArgs) => {
  const { fetchFn } = fetchFactory<DeleteAudienceGroupResponse>({
    route: `/api/audiences/groups/${id}`,
    method: 'DELETE',
  });

  return fetchFn()
    .then((value) => ({ result: value, error: undefined }))
    .catch((reason: DeleteAudienceGroupErrors) => ({ result: undefined, error: reason }));
};
