import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: 4,
  },
  icon: {
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
});

export default styleSheet;
