import React, { CSSProperties, useMemo } from 'react';

import UIcon, { UIconProps } from 'Components/unit/UIcon/UIcon';
import STooltip from 'Components/structural/STooltip/STooltip';

import styles from './ULargeRadioButton.style';

export type ULargeRadioButtonProps = {
  text: string;
  tooltip?: string;
  tooltipWidth?: number;
  icon?: UIconProps['name'];
  delay?: number;
  onClick: () => void;

  selected: boolean;
  hideIcon?: boolean;
  centered?: boolean;
  textAlign?: 'start' | 'center';
  disabled?: boolean;
  minWidth?: number | string;
  maxWidth?: number | string;

  style?: CSSProperties;
};

export const ULargeRadioButton = ({
  text,
  icon,
  tooltip,
  tooltipWidth = 400,
  centered,
  selected,
  disabled,
  hideIcon,
  minWidth,
  maxWidth,
  textAlign = 'center',
  delay = 1000,
  onClick,
  style,
}: ULargeRadioButtonProps) => {
  const selectedStyle = selected ? styles.selected : styles.unselected;

  // Only add selected styling if the button is not disabled
  const containerStyle = useMemo(
    () => ({
      ...styles.container,
      ...(centered ? styles.centered : {}),
      ...(disabled ? styles.disabled : selectedStyle),
      minWidth,
      maxWidth,
      ...style,
    }),
    [centered, disabled, maxWidth, minWidth, selectedStyle, style],
  );

  const textStyle = useMemo(
    () => ({
      ...styles.text,
      ...(disabled ? styles.disabledText : {}),
      ...(minWidth || maxWidth ? styles.wrapText : {}),
      textAlign,
    }),
    [disabled, maxWidth, minWidth, textAlign],
  );

  const radioIcon = selected ? 'radio-filled' : 'radio-empty';

  const radioSize = icon ? 16 : 14;

  const iconStyle = useMemo(
    () => ({
      ...styles.icon,
      // The radio icon has a different fill colors than other icons
      ...(!icon ? styles.radioIcon : {}),
      // If there is no icon, the default icons are radio-empty && radio-filled. This icon is the only one the be filled when selected
      ...(!icon && selected ? styles.selectedRadioIcon : {}),
      ...(disabled ? styles.disabledIcon : {}),
    }),
    [disabled, icon, selected],
  );

  const tooltipId = `radio-button-${text}`;

  return (
    <div style={containerStyle} data-tip data-for={tooltipId} onClick={!disabled ? onClick : undefined}>
      {!hideIcon && <UIcon name={icon || radioIcon} size={radioSize} style={iconStyle} />}
      <p style={textStyle}>{text}</p>
      <STooltip id={tooltipId} content={tooltip} delay={1000} maxWidth={tooltipWidth} position="right" />
    </div>
  );
};

export default ULargeRadioButton;
