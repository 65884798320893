/* eslint-disable complexity */
import m from 'm';
import Promise from 'bluebird';
import D from 'dates';
import Bus from 'services/pubSubService';
import App from 'models/App';
import Collection from 'models/Collection';
import Player from 'models/Player';
import Enum from 'models/Enum';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import reactCreator from 'components/react.creator';

const CACHE = {};

var component = {};

component.controller = function controller() {
  var self = this;
  var PAGINATION_STEP = 50;
  var DEFAULT_COLUMNS_NUMBER = 5;

  var query = m.route.param('query') || m.route.param('q') || '';

  self.counter = m.prop(null);
  self.deactivated = m.prop(false);
  self.order = m.prop({
    name: 'globalScore',
    asc : false,
  });
  self.exportDownloading = m.prop(false);
  self.loading = m.prop(true);
  self.nbColumns = m.prop(DEFAULT_COLUMNS_NUMBER);
  self.extraInfoEnabled = m.prop(false);
  self.extraInfoLabel = m.prop('');
  self.thereIsMore = m.prop(false);

  self.expanded = m.prop(-1);
  self.expandedShown = m.prop(false);
  self.currentPage = m.prop(1);

  self.segmentationIds = m.prop(CACHE.segmentationIds || []);
  self.textValues = m.prop(CACHE.textValues || []);
  self.description = m.prop('');

  if (query)
    self.textValues([query]);

  var extraInfoConfig = m.prop(App.configurations().find({
    id: Enum.configurations.SIGNUP_OPEN_INPUT_ACTIVE,
  }).at(0));

  self.extraInfoEnabled(extraInfoConfig() && extraInfoConfig().data() === 'true');

  if (self.extraInfoEnabled()) {
    self.nbColumns(self.nbColumns() + 1);
    self.extraInfoLabel(App.signupOpenTextLabel());
  }

  // SUBSCRIPTIONS
  Bus.subscribe('user:add', addUser);

  self.onunload = function onunload() {
    Bus.unsubscribe('user:add', addUser);
  };

  self.players = m.prop(new Collection(Player));

  self.refresh = function refresh() {
    self.players(new Collection(Player));
    self.currentPage(1);
    self.expanded(-1);
    self.thereIsMore(false);

    self.loading(true);
    m.redraw();

    setTimeout(function doLater() {
      refreshPlayerCounter();
      self.pageData(1).then(m.redraw.bind(null, null));
    }, 0);
  };

  function refreshPlayerCounter() {
    Player
      .countBy(self.textValues(), self.deactivated(), self.order(), self.segmentationIds())
      .then(function refreshCounts(response) {
        const newCount = self.isFiltering()
          ? response
          : (response || null);

        self.counter(newCount);
        CACHE.counter = newCount;

        self.description(self.getDescription());
      })
      .then(m.redraw.bind(null, null));
  }

  self.orderBy = function orderBy(name) {
    if (self.order().name === name)
      self.order().asc = !self.order().asc;
    else {
      self.order({
        name: name,
        asc : true,
      });
    }
    self.refresh();
  };

  self.isFiltering = function isFiltering() {
    return (CACHE.segmentationIds && CACHE.segmentationIds.length)
      || (CACHE.textValues && CACHE.textValues.length);
  };

  self.getDescription = function getDescription() {
    if (!self.isFiltering()) {
      if (!self.counter())
        return 'Overview of the player base of your application';

      return `${self.counter() > 1 ? 'players' : 'player'} are registered`;
    }

    return `${self.counter() > 1 ? 'players match' : 'player matches'} your search`;
  };

  self.isSelected = function isSelected(name) {
    return self.order().name === name;
  };

  self.toggleDeactivated = function toggleDeactivated() {
    self.deactivated(!self.deactivated());
    self.refresh();
  };

  self.exportPlayers = function exportPlayers() {
    self.exportDownloading(true);

    Player
      .exportPlayers(
        self.textValues(),
        self.deactivated(),
        self.order(),
        self.segmentationIds()
      ).then(function disableLoading() {
        self.exportDownloading(false);
        m.redraw();
      });
  };

  self.verifyUser = function verifyUser(user, state) {
    user.adminValidationId(state);
    if (state === Enum.playerValidations.PENDING)
      user.active(true);
    user.save()
      .then(function handleResponse(savedUser) {
        user.adminValidationId(savedUser.adminValidationId());
        if (savedUser.adminValidationId() === Enum.playerValidations.VALIDATED)
          self.reactivatePlayer(user);
        else if (savedUser.adminValidationId() === Enum.playerValidations.REJECTED)
          self.deactivatePlayer(user);
      })
      .then(m.redraw.bind(null, null));
  };

  self.deactivatePlayer = function deactivatePlayer(user) {
    user.deactivate()
      .then(function handleResponse(response) {
        user.active(response.active());
        user.adminValidationId(Enum.playerValidations.REJECTED);
        user.save()
          .then(function handleSaved(savedUser) {
            user.adminValidationId(savedUser.adminValidationId());
          });
      })
      .then(m.redraw.bind(null, null));
  };

  self.reactivatePlayer = function reactivatePlayer(user) {
    user.reactivate()
      .then(function handleResponse(response) {
        user.active(response.active());
        user.adminValidationId(Enum.playerValidations.VALIDATED);
        user.save()
          .then(function handleSaved(savedUser) {
            user.adminValidationId(savedUser.adminValidationId());
          });
      })
      .then(m.redraw.bind(null, null));
  };

  function addUser(user) {
    self.users().push(user);
    self.filtered(self.users());
  }

  self.clearFilter = function clearFilter() {
    self.searchQuery('');
    self.refresh();
  };

  self.expandCard = function expandCard(user) {
    if (self.expanded() === user.id()) {
      self.expanded('');
      self.expandedShown(false);
      m.redraw();
    } else {
      self.expanded(user.id());
      self.expandedShown(false);
      m.redraw();
      setTimeout(function () {
        self.expandedShown(true);
        m.redraw();
      }, 500);
    }
  };

  self.onFilterBoxChange = function onFilterBoxChange(filters) {
    filters.forEach((filter) => {
      if (filter.category === 'segment')
        CACHE.segmentationIds = self.segmentationIds(filter.ids);
      if (filter.category === 'text')
        CACHE.textValues = self.textValues(filter.values);
    });

    self.refresh();
  };

  self.pageData = function pageData(pageNb) {
    var start = (pageNb - 1) * PAGINATION_STEP;
    var length = PAGINATION_STEP;

    if (self.players().length >= start + length)
      return Promise.resolve(self.players().slice(start, start + length));

    return Player
      .findPlayerPaginated(
        self.textValues(),
        self.deactivated(),
        self.order(),
        self.segmentationIds(),
        start,
        length
      )
      .then(function handlePagination(response) {
        self.loading(false);

        self.thereIsMore(length === response.players.length);

        if (self.players().length - start === 0)
          self.players(self.players().concat(response.players.items));

        return response.players;
      });
  };

  refreshPlayerCounter();
  self.pageData(self.currentPage()).then(m.redraw.bind(null, null));
};

component.view = function view(c) {
  return m('.player-list-page', [
    m('.player-list-page__menu', [
      m(menu),
      m(gaiaMenu),
    ]),
    m('.player-page__body',
      m(reactCreator, {
        component: 'Players',
        style    : { height: '100%' },
        props    : {},
      })),
  ]);
};

export default component;
