import m from 'm';

import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

import { isFeatureEnabled } from 'services/feature-flags';
import { getLanguagesAvailableWithCode } from 'services/language/languageService';

const component = {};

const DOLPHIN_CONTENTS_FLAG_NAME = 'dolphin-contents';

component.view = function view() {
  const languages = getLanguagesAvailableWithCode();

  return m('.page', [
    m('.header', [
      m(menu),
    ]),
    isFeatureEnabled(DOLPHIN_CONTENTS_FLAG_NAME)
      ? m(reactCreator, {
        component: 'ContentList',
        props: {
          languages,
        },
        style: {
          height: '100%',
        },
      })
      : null,
  ]);
};

export default component;
