import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SCallout from 'Components/structural/SCallout/SCallout';
import MModalGroup, { MModalGroupItem } from 'Components/modal/MModalGroup/MModalGroup';
import UButton from 'Components/unit/UButton/UButton';
import { useCourseListFromAudience } from 'Features/audience/hooks/useCourseListFromAudience';
import {
  AudienceGroup,
  updateAudienceGroup as defaultUpdateAudienceGroup,
  UpdateAudienceGroupFn,
} from 'Features/audience/services/update-audience-group.service';
import { Dimension } from 'Features/audience/ui/components/forms/AudienceGroupForm/utils/get-dimensions';
import AudienceGroupForm from 'Features/audience/ui/components/forms/AudienceGroupForm/AudienceGroupForm';
import { type GroupFormData } from 'Features/audience/services/create-audience-group.service';
import type { Source } from 'Libs/ts/types';
import { useTypedSelector } from 'Libs/redux/utils';
import { enqueueBasicAlert } from 'Pages/AlertManager/redux/utils';
import { AudienceGroupEntity } from 'Store/entities/audience-group/audience-group.types';
import { selectById } from 'Store/entities/audience-group/audience-group.selectors';

import styles from './UpdateAudienceGroupModal.style';
import { areSegmentationsEqual, Errors, formatErrors, isUpdateFormValid } from './UpdateAudienceGroupModal.utils';

const noop = () => {};

export type UpdateAudienceGroupModalProps = {
  audienceGroupId: number;

  onClose: () => void;
  onConfirm: (formData: AudienceGroup) => void;

  // Used in testing and to avoid dependencies.
  updateAudienceGroup?: UpdateAudienceGroupFn;
  segmentationDimensions?: Dimension[];
  segmentationSource?: Source;
};

export const UpdateAudienceGroupModal = ({
  audienceGroupId,
  onConfirm,
  onClose,

  // Used in testing and to avoid direct dependencies to fetch and apis.
  updateAudienceGroup = defaultUpdateAudienceGroup,
  segmentationDimensions,
  segmentationSource,
}: UpdateAudienceGroupModalProps) => {
  const { t } = useTranslation();

  const audienceGroup = useTypedSelector<AudienceGroupEntity | undefined>((state) =>
    selectById(state.entities.audienceGroup, audienceGroupId),
  );

  const [currentStep, setCurrentStep] = useState<'FORM' | 'COURSES_LIST'>('FORM');

  const [formData, setFormData] = useState<GroupFormData>({
    name: audienceGroup?.name || '',
    segmentations: audienceGroup?.segmentations || [],
  });

  const [errors, setErrors] = useState<Errors>(null);

  const handleFormDataChange = useCallback((newFormData: GroupFormData) => {
    setFormData(newFormData);
    setErrors(null);
  }, []);

  const resetErrors = useCallback(() => {
    setErrors(null);
  }, []);

  const resetForm = useCallback(async () => {
    resetErrors();
  }, [resetErrors]);

  useEffect(() => {
    resetForm();
  }, [resetForm, audienceGroupId]);

  const handleAction = useCallback(async () => {
    const { result, error } = await updateAudienceGroup(audienceGroupId, formData);

    setErrors(formatErrors(result, error));
    if (error || !result) {
      setCurrentStep((previousStep) => (previousStep === 'COURSES_LIST' ? 'FORM' : previousStep));
      return;
    }

    enqueueBasicAlert(
      {
        id: 'AUDIENCE_GROUP_UPDATED',
        title: 'Your changes have been saved',
        icon: 'success-circle',
        status: 'success',
        priority: 'low',
      },
      5000,
    );

    onConfirm(result);
  }, [updateAudienceGroup, formData, onConfirm, audienceGroupId]);

  const { courses, CourseListFromAudience, setAudienceGroupId } = useCourseListFromAudience();

  useEffect(() => {
    if (audienceGroupId) {
      setAudienceGroupId(audienceGroupId);
    }
  }, [audienceGroupId, setAudienceGroupId]);

  const handleFormModalConfirm = useCallback(async () => {
    const segmentationEqual = areSegmentationsEqual(formData.segmentations, audienceGroup?.segmentations);

    if (!courses || courses.length === 0 || segmentationEqual) {
      await handleAction();
    } else {
      setCurrentStep('COURSES_LIST');
    }
  }, [courses, formData.segmentations, audienceGroup?.segmentations, handleAction]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  if (!audienceGroup) return null;
  const isFormDataValid = isUpdateFormValid(formData, audienceGroup);

  return (
    <MModalGroup visible onClose={handleClose} currentStep={currentStep}>
      <MModalGroupItem
        step="FORM"
        type="dialog"
        labelActionButton={t('audiences:update_audience_modal.update')}
        labelSecondButton={t('audiences:update_audience_modal.cancel')}
        title={t('audiences:update_audience_modal.title')}
        description={t('audiences:update_audience_modal.description')}
        onAction={handleFormModalConfirm}
        onActionEnd={noop}
        onSecondAction={handleClose}
        showExitCross
        disableActionButton={!isFormDataValid}
        style={styles.modalForm}
        data-test-id="form-group-item"
      >
        <div style={styles.innerContainer}>
          {errors?.segmentation && (
            <SCallout
              type="warning"
              description={errors.segmentation}
              style={styles.callout}
              data-test-id="segmentation-error"
            />
          )}
          {errors?.general && <SCallout type="warning" description={errors.general} style={styles.callout} />}
          <AudienceGroupForm
            values={formData}
            onChange={handleFormDataChange}
            segmentationDimensions={segmentationDimensions}
            segmentationSource={segmentationSource}
            errors={errors?.name ? { nameInput: errors.name } : undefined}
          />
        </div>
      </MModalGroupItem>

      <MModalGroupItem
        type="dialog"
        labelActionButton={t('audiences:update_audience_modal.update')}
        labelSecondButton={t('audiences:update_audience_modal.cancel')}
        title={t('audiences:update_audience_modal.title')}
        onAction={handleAction}
        onActionEnd={noop}
        onSecondAction={handleClose}
        showExitCross
        step="COURSES_LIST"
        disableActionButton={!isFormDataValid || !!errors}
        style={styles.modalCourseList}
        labelError={errors ? t('audiences:update_audience_modal.not_updated') : ''}
        data-test-id="courses-list-group-item"
      >
        <div style={styles.container} data-test-id="courses-list-container">
          <div style={styles.buttonAndDisclaimerWrapper}>
            <UButton
              text="Back"
              onClick={() => setCurrentStep('FORM')}
              style={styles.backButtonStyle}
              leftIcon="arrow-left"
              leftIconSize={12}
              type={errors ? 'accentuated' : 'standard'}
            />
            <p style={styles.disclaimer}>{t('audiences:update_audience_modal.disclaimer')}</p>
          </div>
          <CourseListFromAudience />
        </div>
      </MModalGroupItem>
    </MModalGroup>
  );
};

export default UpdateAudienceGroupModal;
