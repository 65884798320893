/* eslint-disable max-len */
// @flow

/**
 * Display a password input
 *
 * Props
 * - value: Set a value from props to input
 * - placeholder: String to set the input placeholder
 * - autoComplete: Control the auto fill of browser
 * - autofocus: trigger the input focus at component render
 * - privacy: Set text default password visibility
 * - requireMinCharacters: Set minimal characters
 * - requireMinNumber: Set minimal number
 * - requireMinUppercase: Set minimal uppercase
 * - requireMinSpecialCharacters: Set minimal special characters
 * - requireMinCharactersStr: Set minimal characters translation
 * - requireMinNumberStr: Set minimal number translation
 * - requireMinUppercaseStr: Set minimal uppercase translation
 * - requireMinSpecialCharactersStr: Set minimal special character translation
 * - onSubmit: Function call to submit password
 * - onChange: Function call to submit password after change
 * - onFocus: Function call to do action at input focus
 * - onChecksValidationChange: Function call when the password validation status change
 * - style: can override the component style
 */

import * as React from 'react';

import { COLORS } from 'Components/foundation';

import styles from './SPasswordInput.style';

import UIcon from 'Components/unit/UIcon/UIcon';
import UIconButton from 'Components/unit/UIconButton/UIconButton';


type AutoCompleteType = 'on' | 'off' | 'new-password';

type Props = {|

  // Optional
  value: string,
  placeholder: string,
  autoComplete: AutoCompleteType,
  autofocus: boolean,
  privacy: boolean,
  requireMinCharacters: number,
  requireMinNumber: number,
  requireMinUppercase: number,
  requireMinSpecialCharacters: number,
  requireMinCharactersStr: string,
  requireMinNumberStr: string,
  requireMinUppercaseStr: string,
  requireMinSpecialCharactersStr: string,
  onSubmit: Function,
  onChange: Function,
  onFocus: Function,
  onChecksValidationChange: (validation: boolean) => mixed,
  style: Object,
|};

type State = {|
  isFocused: boolean,
  isRevealed: boolean,
  isValidPassword: boolean,
|};

const ENTER_KEY_EVENT = 'Enter';

const REGEX_MIN_CHARACTERS = (requireMinCharacters) => new RegExp(`^.{${requireMinCharacters},}$`);
const REGEX_MIN_UPPERCASES = (requireMinUppercase) => new RegExp(`^.*[A-Z]{${requireMinUppercase},}.*$`);
const REGEX_MIN_NUMBER = (requireMinNumber) => new RegExp(`^.*[0-9]{${requireMinNumber},}.*$`);
const REGEX_MIN_SPECIAL_CHARS = (requireMinSpecialCharacters) => new RegExp(`^.*[~!@#$%^&*()_|+=?;:'",.<>//{}]{${requireMinSpecialCharacters},}.*$`);

export class SPasswordInput extends React.Component<Props, State> {
  // We need to use a ref to handle autofocus 'cause autofocus prop on input
  // only works when the <input> is initially rendered
  inputRef = React.createRef<HTMLInputElement>();

  static defaultProps = {
    style: undefined,
    value: '',
    placeholder: '',
    autoComplete: 'on',
    autofocus: false,
    privacy: true,
    requireMinCharacters: 8,
    requireMinNumber: 1,
    requireMinUppercase: 1,
    requireMinSpecialCharacters: 1,
    requireMinCharactersStr: 'characters',
    requireMinNumberStr: 'number',
    requireMinUppercaseStr: 'uppercase letter',
    requireMinSpecialCharactersStr: 'special character',
    onSubmit: () => {},
    onChange: () => {},
    onFocus: () => {},
    onChecksValidationChange: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isFocused: props.autofocus,
      isRevealed: !props.privacy,
      isValidPassword: false,
    };
  }

  componentDidUpdate() {
    const { autofocus } = this.props;

    if (autofocus && this.inputRef.current)
      this.inputRef.current.focus();
  }

  render() {
    const { style } = this.props;
    const { isFocused } = this.state;

    const focusedStyle = isFocused ? styles.focusedInput : {};

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={{ ...styles.inputWrapper, ...focusedStyle }}>
          { this.renderInput() }
          { this.renderRevealIcon() }
        </div>
        { this.renderValidation() }
      </div>
    );
  }

  renderValidation = () => {
    const {
      requireMinCharacters,
      requireMinNumber,
      requireMinUppercase,
      requireMinSpecialCharacters,
      requireMinCharactersStr,
      requireMinNumberStr,
      requireMinUppercaseStr,
      requireMinSpecialCharactersStr,
    } = this.props;

    return (
      <div style={styles.validationWrapper}>
        { requireMinCharacters > 0 && this.renderValidationItem(requireMinCharacters, requireMinCharactersStr, REGEX_MIN_CHARACTERS(requireMinCharacters)) }
        { requireMinUppercase > 0 && this.renderValidationItem(requireMinUppercase, requireMinUppercaseStr, REGEX_MIN_UPPERCASES(requireMinUppercase)) }
        { requireMinNumber > 0 && this.renderValidationItem(requireMinNumber, requireMinNumberStr, REGEX_MIN_NUMBER(requireMinNumber)) }
        { requireMinSpecialCharacters > 0 && this.renderValidationItem(requireMinSpecialCharacters, requireMinSpecialCharactersStr, REGEX_MIN_SPECIAL_CHARS(requireMinSpecialCharacters)) }
      </div>
    );
  };

  renderValidationItem = (number: number, text: string, regex: RegExp) => {
    const { value } = this.props;
    const isValid = regex.test(value);

    return (
      <div style={styles.validationItem}>
        <UIcon
          name='success-circle'
          size={10}
          color={isValid ? COLORS.SUCCESS.DEFAULT : COLORS.SUCCESS.DISABLED}
          style={styles.validationIcon}
        />
        <div>
          {`${number} ${text}`}
        </div>
      </div>
    );
  };

  renderRevealIcon = () => {
    const { isRevealed } = this.state;
    const revealIconName = isRevealed ? 'preview-disabled' : 'preview' ;

    return (
      <UIconButton
        icon={revealIconName}
        size="S"
        onClick={this.handleRevealButtonClick}
        style={styles.revealButton}
        type='standard-dark'
        ghost
      />
    );
  };

  renderInput = () => {
    const { value, placeholder, autofocus, autoComplete, onFocus } = this.props;
    const { isRevealed } = this.state;

    return (
      <input
        type={isRevealed ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        style={styles.textField}
        onChange={this.handleValueChange}
        onClick={this.handleOnClick}
        onBlur={this.handleLeave}
        onFocus={onFocus}
        onKeyDown={this.handleSubmit}
        autoFocus={autofocus}
        autoComplete={autoComplete}
        className='structural-inputs'
      />
    );
  };

  handleRevealButtonClick = () => {
    const { isRevealed } = this.state;

    this.setState({ isRevealed: !isRevealed });
  };

  handleSubmit = (event: KeyboardEvent) => {
    const { onSubmit, value } = this.props;

    if (event.key === ENTER_KEY_EVENT && onSubmit)
      onSubmit(value);
  };

  handleLeave = () => {
    this.setState({ isFocused: false });
  };

  handleOnClick = () => {
    this.setState({ isFocused: true });
  };

  handleValueChange = (event: Object) => {
    const { onChange, onChecksValidationChange } = this.props;
    const { isValidPassword } = this.state;
    const { value } = event.target;
    const validation = this.isValueIsValidPassword(value);

    onChange(event.target.value);

    if (validation !== isValidPassword)
      this.setState({ isValidPassword: validation }, () => onChecksValidationChange(validation));
  };

  isValueIsValidPassword = (value: string) => {
    const {
      requireMinCharacters,
      requireMinNumber,
      requireMinUppercase,
      requireMinSpecialCharacters,
    } = this.props;

    return [
      REGEX_MIN_CHARACTERS(requireMinCharacters),
      REGEX_MIN_UPPERCASES(requireMinUppercase),
      REGEX_MIN_NUMBER(requireMinNumber),
      REGEX_MIN_SPECIAL_CHARS(requireMinSpecialCharacters),
    ].every((regex) => regex.test(value));
  };
}

export default SPasswordInput;
