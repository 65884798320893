import m from 'm';

import reactCreator from 'components/react.creator';

import { getLanguagesAvailableWithCode } from 'services/language/languageService';
import { isFeatureEnabled } from 'services/feature-flags';

const component = {};

const COURSE_EDITION_FLAG_NAME = 'course-edition';

component.view = function view() {
  const languages = getLanguagesAvailableWithCode();

  return m('.course-details-page', [
    isFeatureEnabled(COURSE_EDITION_FLAG_NAME)
      ? m(reactCreator, {
        component: 'CourseEdition',
        props: { languages },
        style: {
          height: '100%',
        },
      })
      : null,
  ]);
};

export default component;
