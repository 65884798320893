import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
  },
  rangeInput: {
    width: '100%',
    height: 6,
    borderRadius: 4,
    backgroundColor: COLORS.BLUE_GRAY_DARK.DEFAULT,
  },
});

export default styleSheet;
