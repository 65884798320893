// @flow

/* eslint-disable key-spacing */

export default {
  wrapper: {
    width: '100%',
    height: '100%',
  },
};
