import m from 'm';
import Promise from 'bluebird';
import _ from 'lodash';

// Shut down unnecessary globals to force the use of dependencies
delete window.Promise;
delete window._;

Promise.config({
  longStackTraces: true,
});
window.Promise = Promise;

m.hide = function conditionalHide() {
  var bool = arguments[0];
  var args = _.slice(arguments, 1);

  if (!bool)
    return m.apply(this, args);

  if (args.length === 1) {
    args.push({
      class: 'hide',
    });

    return m.apply(this, args);
  }

  if (args.length === 2 && !_.isPlainObject(args[1])) {
    args.splice(1, 0, {
      class: 'hide',
    });

    return m.apply(this, args);
  }

  if (args.length === 3 || args.length === 2 && _.isObject(args[1])) {
    args[1].class += ' hide';

    return m.apply(this, args);
  }
};
