import m from 'm';
import KEY from 'keys';
import _ from 'lodash';

var component = {};

/*
    placeholder: 'placeholder',
    values     : Collection,
    mutable    : Collection,
    list       : Collection,
    path       : 'path',
 */
component.controller = function controller(args) {
  var self = this;
  var moduleElem = null;
  var inputElem = null;

  self.value = m.prop('');
  self.isOpened = false;

  self.bgClass = m.prop('');

  self.onunload = function onunload() {
    document.removeEventListener('click', onclick, false);
  };

  self.moduleConfig = function moduleConfig(elem, init) {
    if (!init)
      moduleElem = elem;
  };

  self.inputConfig = function inputConfig(elem, init) {
    if (!init)
      inputElem = elem;
  };

  self.open = function open() {
    if (!self.isOpened) {
      document.addEventListener('click', onclick, false);
      inputElem.focus();
    }
    self.isOpened = true;
  };

  self.close = function close(e) {
    if (e)
      e.stopPropagation();

    document.removeEventListener('click', onclick, false);
    self.isOpened = false;
  };

  self.toggle = function toggle(e) {
    if (self.isOpened)
      self.close(e);
    else
      self.open(e);
  };

  self.onkeydown = function ontab(e) {
    if (e.keyCode === KEY.TAB)
      self.close();
  };

  function onclick(e) {
    var target = e.target;

    if (!moduleElem.contains(target)) {
      e.preventDefault();

      self.close();
      m.redraw();
    }
  }

  self.filteredData = function filteredData(_args) {
    return _args.list.filter(function filterList(item) {
      var value = ('' + item[_args.path]()).toLowerCase();

      return value.indexOf(self.value().toLowerCase()) !== -1;
    });
  };

  self.addToValues = function addToValues(_args, item, e) {
    if (e)
      e.stopPropagation();

    console.log('[multi]', 'Adding item', item.toJSON());
    _args.values().push(item);
  };

  self.removeFromValues = function removeFromValues(_args, item, e) {
    if (e)
      e.stopPropagation();

    console.log('[multi]', 'Removing item', item);
    _args.values().searchAndRemove({
      id: item.id(),
    });
  };

  self.addAllValues = function addAllValues(_args, e) {
    if (e)
      e.stopPropagation();

    var mutable = _args.editable && _args.editable.length ? _args.editable : _args.list;
    var mine = mutable.map(getID);
    var present = _args.values().map(getID);
    var missing = _.difference(mine, present);

    missing.map(function moveValue(itemID) {
      var item = mutable.get(itemID);

      self.addToValues(_args, item);
    });
  };

  self.removeAllValues = function removeAllValues(_args, e) {
    if (e)
      e.stopPropagation();

    var mutable = _args.editable && _args.editable.length ? _args.editable : _args.list;
    var mine = mutable.map(getID);
    var present = _args.values();

    for (var i = present.length - 1; i >= 0; i--) {
      var item = present.at(i);

      if (mine.indexOf(item.id()) !== -1)
        self.removeFromValues(_args, item);
    }
  };

  self.printValues = function printValues(_args) {
    var mutable = args.editable && args.editable.length ? args.editable : args.list;
    var allSelected = containsAll(args.values(), mutable);
    var firstValue = _args.values().at(0);
    var len = _args.values().length;
    var value = '';

    if (!len) {
      // return m('.filteringMultiSelect__value');
      return m('.filteringMultiSelect__value', {
        class: 'filteringMultiSelect__value--placeholder',
      }, args.simplePlaceholder ? 'Select ' + args.simplePlaceholder : 'All ' + args.placeholder);
    }

    // if (allSelected) {
    //   return m('.filteringMultiSelect__value', {
    //     class: 'filteringMultiSelect__value--placeholder',
    //   }, 'All ' + args.placeholder);
    // }

    value = '' + firstValue[args.path]();

    return m('.filteringMultiSelect__placeholder', [
      m('.filteringMultiSelect__value', {
        class: self.bgClass() || '',
      }, [
        m('.filteringMultiSelect__value-label', {
          class: args.readOnly ? 'filteringMultiSelect__value-label--readOnly' : '',
        }, value),
        m.hide(args.readOnly, '.filteringMultiSelect__removeValue', {
          onclick: allSelected ? self.removeAllValues : self.removeFromValues.bind(null, firstValue),
        }),
      ]),
      (len > 1)
      ? m('.filteringMultiSelect__value', {
        class: self.bgClass() || '',
      }, [
        m('.filteringMultiSelect__value-label.filteringMultiSelect__value-counter', [
          '+' + (len - 1),
        ]),
      ])
      : '',
    ]);
  };
};

component.view = function view(c, args) {
  var mutable = args.editable && args.editable.length ? args.editable : args.list;
  var allSelected = containsAll(args.values(), mutable);
  var editableList = mutable.map(getID);
  var selectedList = args.values().map(getID);

  return m('.filteringMultiSelect', {
    config : c.moduleConfig,
    onclick: args.onClick || c.open,
    class  : args.class,
  }, [
    m('.filteringSelect__arrow', {
      class  : c.isOpened ? 'filteringSelect__arrow--opened' : '',
      onclick: args.onClick || c.toggle,
    }),
    m('.filteringMultiSelect__howMany', {}, c.printValues(args)),
    m('.filteringSelect__input'),
    m('.filteringSelect__opened', {
      class: c.isOpened ? '' : 'hide',
    }, [
      m('input.filteringSelect__search', {
        config     : c.inputConfig,
        value      : c.value(),
        oninput    : m.withAttr('value', c.value),
        onfocus    : c.open,
        onkeydown  : c.onkeydown,
        placeholder: args.simplePlaceholder ? 'Search ' + args.simplePlaceholder : 'Search ' + args.placeholder || '',
      }),
      m('.filteringSelect__list', [
        c.value()
        ? ''
        : m('.filteringSelect__item', {
          title  : 'Will assign all current and upcoming ' + args.placeholder || '',
          onclick: allSelected ? c.removeAllValues.bind(null, args) : c.addAllValues.bind(null, args),
        }, [
          m('.filteringMultiSelect__item__checkbox', {
            class: allSelected ? 'filteringMultiSelect__checkbox--selected' : '',
          }, [
            m('.filteringMultiSelect__item__checkbox__check', {
              class: allSelected ? c.bgClass() || '' : '',
            }),
          ]),
          m('.filteringMultiSelect__item__label', [
            m('b', 'All'),
          ]),
        ]),
        c.filteredData(args).map(function mapList(item) {
          var editable = editableList.indexOf(item.id()) !== -1;
          var selected = selectedList.indexOf(item.id()) !== -1;

          return m('.filteringSelect__item', {
            onclick: !editable
              ? noop
              : selected
              ? c.removeFromValues.bind(null, args, item)
              : c.addToValues.bind(null, args, item),
          }, [
            m('.filteringMultiSelect__item__checkbox', {
              class: selected ? 'filteringMultiSelect__checkbox--selected' : '',
            }, [
              m('.filteringMultiSelect__item__checkbox__check', {
                class: selected ? c.bgClass() || '' : '',
              }),
            ]),
            m('.filteringMultiSelect__item__label', {}, item[args.path]()),
          ]);
        }),
      ]),
    ]),
  ]);
};

function containsAll(subset, superset) {

  var all = superset.map(getID);
  var present = subset.map(getID);
  var missing = _.difference(present, all);
  var contains = missing.length === present.length - all.length;

  return contains;
}

function getID(item) {
  return item.id();
}

function noop() {}

export default component;
