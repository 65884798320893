import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { FunctionComponent } from 'react';
import SwitchView from 'Components/hoc/SwitchView/SwitchView';

import CodeForm from './components/CodeForm/CodeForm';
import LandingPageWrapper from './components/LandingPageWrapper/LandingPageWrapper';
import PhoneForm from './components/PhoneForm/PhoneForm';
import PhoneRequestMail from './components/PhoneRequestMail/PhoneRequestMail';

import { actions } from './redux/actions';

const enum TwoFactorScopes {
  ENTER_CODE = 'ENTER_CODE',
  ENTER_PHONE = 'ENTER_PHONE',
  EMAIL_SENT = 'EMAIL_SENT',
}

const extractScope = (location: Location) => {
  const elements = location.pathname.split('/');
  const [tail] = elements.slice(-1);

  return (
    {
      'enter-code': TwoFactorScopes.ENTER_CODE,
      'enter-phone': TwoFactorScopes.ENTER_PHONE,
      'email-sent': TwoFactorScopes.EMAIL_SENT,
    }[tail] || null
  );
};

const getScopeUrl = (location: Location, scope: TwoFactorScopes) => {
  const elements = location.pathname.split('/');
  const head = elements.slice(0, -1);
  const path = {
    [TwoFactorScopes.ENTER_CODE]: 'enter-code',
    [TwoFactorScopes.ENTER_PHONE]: 'enter-phone',
    [TwoFactorScopes.EMAIL_SENT]: 'email-sent',
  }[scope];

  return [...head, path].join('/');
};

const extractInvitationToken = (location: Location) => {
  const params = new URLSearchParams(location.search);

  return params.get('invitationToken') || '';
};

export const TwoFactor: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentScope = extractScope(window.location);
  const invitationToken = extractInvitationToken(window.location);

  // parse invitation token
  // and store it to state
  // in order to have it when clicking on edit phone number during workflow
  useEffect(() => {
    if (invitationToken.length > 0) {
      dispatch(actions.setInvitationToken(invitationToken));
    }
  }, [invitationToken, dispatch]);

  const [, forceUpdate] = useState({});
  const redirectToScope = useCallback(
    (scope: TwoFactorScopes) => () => {
      window.history.replaceState({}, '', getScopeUrl(window.location, scope));

      // INFO Re-render the component to take url changes into account,
      // This can be removed when routing is fully managed by react.
      forceUpdate({});
    },
    [],
  );

  // reset state on unmount
  useEffect(
    () => () => {
      dispatch(actions.reset());
    },
    [dispatch],
  );

  return (
    <SwitchView currentIndex={currentScope || 'none'}>
      <div id={TwoFactorScopes.ENTER_PHONE}>
        <PhoneForm onSwitchToCode={redirectToScope(TwoFactorScopes.ENTER_CODE)} />
      </div>
      <div id={TwoFactorScopes.ENTER_CODE}>
        <CodeForm onSwitchToPhone={redirectToScope(TwoFactorScopes.ENTER_PHONE)} />
      </div>
      <div id={TwoFactorScopes.EMAIL_SENT}>
        <PhoneRequestMail />
      </div>
      <div id="none">
        <LandingPageWrapper>Not found</LandingPageWrapper>
      </div>
    </SwitchView>
  );
};
