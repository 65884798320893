import request from 'services/requestService';
import Model from 'models/Model';

var ApiKey = {
  endpoint  : '/api/ApiKeys',
  attributes: {
    apiKey: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
  },
  classMethods: {
    createKey: function createKey(_Content, obj) {
      var path = '/api/ApiKeys/generateApiKey';

      return request
        .post(path, obj)
        .then(function turnToModelList(contents) {
          return contents;
        });
    },
  },
};

export default Model.register('ApiKey', ApiKey);
