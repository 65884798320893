// @flow

import { StyleSheet } from 'aphrodite';
import { COLORS, TYPOGRAPHY, ELEVATIONS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'inline-flex',
    backgroundColor: COLORS.WHITE.DEFAULT,
    height: 32,
    minWidth: 96,
    borderRadius: 8,
    boxShadow: ELEVATIONS.MEDIUM,
    transition: 'opacity 0.075s ease-out',
    opacity: 0,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  text: {
    ...TYPOGRAPHY.BODY4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    minWidth: 32,
    padding: 8,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    userSelect: 'none',
  },
};

export const extendedStyles = StyleSheet.create({
  buttonLeft: {
    ':hover': {
      backgroundColor: COLORS.WHITE.HOVER,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    ':active': {
      backgroundColor: COLORS.WHITE.ACTIVE,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  buttonRight: {
    ':hover': {
      backgroundColor: COLORS.WHITE.HOVER,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    ':active': {
      backgroundColor: COLORS.WHITE.ACTIVE,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
});

