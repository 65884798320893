import m from 'm';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.imageHeader = m.prop('');
  self.titleKnowledge = m.prop(args.value);
  self.focusTitle = m.prop(false);

  self.countMaxLength = function countMaxLength(e) {
    var elem = e.target;

    self.titleKnowledge(elem.value);
    args.callback(elem.value);
    m.redraw();
  };

  self.checkTitle = function checkTitle(isFocus, e) {
    var value = e.target.value.trim();

    self.focusTitle(isFocus);
    self.titleKnowledge(value);
    args.callback(value);
  };
};

component.view = function view(c, args) {
  return m('.input-title', [
    m('.input-title__placeholder', {
      class: !c.focusTitle() && c.titleKnowledge().length ? 'hide'
        : c.titleKnowledge().length ? 'input-title__placeholder--filled'
        : c.focusTitle() ? 'input-title__placeholder--focus'
        : '',
    }, args.placeholder),
    m('input.input-title__input', {
      class: [
        c.focusTitle() && c.titleKnowledge().length ? 'input-title__input--writing'
        : !c.focusTitle() && c.titleKnowledge().length ? 'input-title__input--filled'
        : '',
        args.invalid ? 'input-title__input--invalid' : '',
      ].join(' '),
      type     : 'text',
      maxlength: args.maxlength,
      value    : c.titleKnowledge(),
      oninput  : c.countMaxLength,
      onfocus  : c.focusTitle.bind(null, true),
      onblur   : c.checkTitle.bind(null, false),
      disabled : !args.editable,
    }),
    m('span.input-title__title-length', {
      class: !c.focusTitle() ? 'hide' : '',
    }, c.titleKnowledge().length + '/' + args.maxlength),
  ]);
};

export default component;
