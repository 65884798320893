import { handle } from 'redux-pack';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import Enum from 'Models/Enum';

import type { Action } from 'Libs/redux/types';
import type { AlertType } from './models/Alert';
import createPlayer from './models/Player';

export type PlayerType = {
  id: number;
  adminValidationId: number;
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  extraInfo: string;
  profilePicture: any;
  globalScore: number;
  segmentation: any;
  createdAt: any;
};

type IsModalOpenType = {
  isManageModalOpen?: boolean;
};

type ManagePlayerModalType = {
  originalPlayer: PlayerType;
  playerToManage: PlayerType;
  error: string;
  isOpen: IsModalOpenType;
};

export type PlayersState = DeepReadonly<{
  players: ReadonlyArray<PlayerType>;
  managePlayerModal: ManagePlayerModalType;
  allDisplayedPlayers: boolean;
  selectedSegmentationIds: ReadonlyArray<number>;
  selectedTextValues: ReadonlyArray<string>;
  isFiltering: boolean;
  alerts: ReadonlyArray<AlertType>;
  isModalOpen: boolean;
  isLoading: boolean;
  playersCount: number;
}>;

type Filter = Pick<PlayersState, 'selectedSegmentationIds' | 'selectedTextValues'>;

const defaultPlayer = createPlayer();

export const defaultState: PlayersState = {
  players: [],
  managePlayerModal: {
    originalPlayer: { ...defaultPlayer },
    playerToManage: { ...defaultPlayer },
    error: '',
    isOpen: {
      isManageModalOpen: false,
    },
  },
  allDisplayedPlayers: true,
  selectedSegmentationIds: [],
  selectedTextValues: [],
  isFiltering: false,
  alerts: [],
  isModalOpen: false,
  isLoading: false,
  playersCount: 0,
};

const ERROR_SCOPE = {
  PLAYERS_GET_EXPORT: 'fetchAllError',
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: PlayersState = defaultState, action: Action): PlayersState => {
  switch (action.type) {
    case 'PLAYERS_GET_ALL': {
      return handle(state, action, {
        start: (prevState) => {
          const { meta } = action;

          let empty = true;

          // If user clicks on Show More button, do NOT empty the array, just concat with previous data
          // If user wants to displayAll or viceversa, empty array because new data is being fetched

          if (meta.isShowMore && !meta.isDisplayAll) {
            empty = false;
          } else if (meta.isDisplayAll) {
            empty = true;
          }

          return mergeDeep(prevState, {
            isLoading: true,
            players: empty ? [] : prevState.players,
            allDisplayedPlayers: meta.shouldDisplay,
          });
        },
        success: (prevState) => {
          const { payload } = action;

          const players = payload.map(createPlayer);
          const concatenatedArray = action.meta.isShowMore
            ? [...prevState.players, ...players]
            : [...prevState.players];

          return mergeDeep(prevState, {
            players: action.meta.isShowMore ? concatenatedArray : [...players],
          });
        },
        finish: (prevState) => mergeDeep(prevState, { isLoading: false }),
      });
    }
    case 'GET_PLAYERS_COUNT': {
      return handle(state, action, {
        success: (prevState) => mergeDeep(prevState, { playersCount: action.payload }),
      });
    }
    case 'PLAYERS_UPDATE_FILTERS': {
      const defaultFilters: Filter = {
        selectedSegmentationIds: [],
        selectedTextValues: [],
      };

      const filters = action.selectedFilters.reduce((acc, filter) => {
        if (filter.category === 'segment') {
          return { ...acc, selectedSegmentationIds: filter.ids };
        }
        if (filter.category === 'text') {
          return { ...acc, selectedTextValues: filter.values };
        }

        return acc;
      }, defaultFilters);

      const filtersName = Object.keys(filters);
      const isFiltering = filtersName.reduce(
        (acc, filter) => acc || Boolean(filters[filter as keyof Filter].length),
        false,
      );

      return mergeDeep(state, { ...filters, isFiltering });
    }
    case 'PLAYERS_GET_EXPORT': {
      return handle(state, action, {
        start: (prevState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]);

          return mergeDeep(prevState, { alerts });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {},
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
      });
    }
    case 'PLAYER_TOGGLE_MODAL': {
      const { player, isOpen } = action;

      const playerToManage = { ...createPlayer(player) };

      const modalState: ManagePlayerModalType = {
        originalPlayer: playerToManage,
        playerToManage,
        error: '',
        isOpen: { ...isOpen },
      };

      return mergeDeep(state, { managePlayerModal: { ...modalState } });
    }
    case 'PLAYER_UPDATE': {
      return handle(state, action, {
        success: (prevState) => {
          const { id, email, extraInfo, adminValidationId, lastName, firstName, segmentation } = action.payload;

          const players = state.players.map((player) => {
            if (player.id === id) {
              return { ...player, email, extraInfo, adminValidationId, firstName, lastName, segmentation };
            }

            return player;
          });

          return mergeDeep(prevState, { players });
        },
      });
    }
    case 'PLAYER_SET_EMAIL': {
      return mergeDeep(state, { managePlayerModal: { playerToManage: { email: action.email } } });
    }
    case 'PLAYER_SET_FIRST_NAME': {
      return mergeDeep(state, { managePlayerModal: { playerToManage: { firstName: action.firstName } } });
    }
    case 'PLAYER_SET_LAST_NAME': {
      return mergeDeep(state, { managePlayerModal: { playerToManage: { lastName: action.lastName } } });
    }
    case 'PLAYER_SET_EXTRA_INFO': {
      return mergeDeep(state, { managePlayerModal: { playerToManage: { extraInfo: action.extraInfo } } });
    }
    case 'PLAYER_SET_SEGMENTATION': {
      return mergeDeep(state, { managePlayerModal: { playerToManage: { segmentation: action.segmentation } } });
    }
    case 'PLAYER_DEACTIVATE': {
      return handle(state, action, {
        success: (prevState) => {
          const { id } = action.payload;

          const players = state.players.map((player) => {
            if (player.id === id) {
              return { ...player, active: false };
            }

            return player;
          });

          return mergeDeep(prevState, {
            players,
            managePlayerModal: {
              isOpen: { isManageModalOpen: false },
              playerToManage: { active: false, adminValidationId: Enum.playerValidations.REJECTED },
            },
          });
        },
      });
    }
    case 'PLAYER_REACTIVATE': {
      return handle(state, action, {
        success: (prevState) => {
          const { id } = action.payload;

          const players = state.players.map((player) => {
            if (player.id === id) {
              return { ...player, active: true };
            }

            return player;
          });

          return mergeDeep(prevState, {
            players,
            managePlayerModal: {
              isOpen: { isManageModalOpen: false },
              playerToManage: { active: true, adminValidationId: Enum.playerValidations.PENDING },
            },
          });
        },
      });
    }
    case 'PLAYER_SET_VERIFICATION': {
      const { isVerified } = action;

      return mergeDeep(state, {
        managePlayerModal: {
          playerToManage: {
            adminValidationId: isVerified ? Enum.playerValidations.VALIDATED : Enum.playerValidations.PENDING,
          },
        },
      });
    }
    case 'PLAYER_SET_MODAL_ERROR': {
      return mergeDeep(state, { managePlayerModal: { error: action.error } });
    }
    default:
      return state;
  }
};
