import m from 'm';
import button from 'components/button';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.name = args.name;

  self.steps = args.steps || [{
    name     : 'Name of the step',
    component: m('.step-class', 'Here is the step component.'),
    subtitle : 'Subtitle of the step.',
  }];

  self.currentStepIndex = m.prop(args.step ? args.step : 0);

  self.currentStep = function currentStep() {
    return self.steps[self.currentStepIndex()];
  };

  self.isAtFirstStep = function isAtFirstStep() {
    return self.currentStepIndex() <= 0;
  };

  self.isAtLastStep = function isAtLastStep() {
    return self.steps.length - 1 <= self.currentStepIndex();
  };

  self.next = function next() {

    // If onNext returns false, don't go to the next step
    if (args.onNext && !args.onNext())
      return;

    console.log('next', self.currentStep());

    console.log('Last step reached.');

    // If onLast returns false, don't close
    if (args.onLast && !args.onLast()) {
      console.log('[multistep] onLast prevented closing.');

      return;
    }

    args.close();
  };

  self.prev = function prev() {

    // If onPrev returns false, don't go to the next step
    if (args.onPrev && !args.onPrev())
      return;

    if (!self.isAtFirstStep()) {
      var nextIndex = self.currentStepIndex();

      self.currentStepIndex(--nextIndex);
    } else
      console.log('First step reached.');
  };

  self.prefix = function subtitle() {
    return self.name
      ? self.name + ': '
      : '';
  };

  // Generates "Back-office creator: Thematics (2/4)"
  self.title = function title() {
    var titleString = [
      self.prefix(),
      self.currentStep().name,
    ].join('');

    return titleString;
  };

  self.subtitle = function subtitle() {
    return self.currentStep().subtitle;
  };
};

component.view = function view(c, args) {
  return m('.newThematics.popup', [
    m('.popup__body', [
      m('.popup__title', c.title()),
      m('.popup__subtitle', c.subtitle()),
      c.currentStep().component,
    ]),
    m('.popup__footer.clearFix', [
      m.component(button, {
        value: 'Previous',
        class: [
          'button--cancel',
          c.currentStepIndex() ? '' : 'hide',
        ].join(' '),
        action: c.prev,
      }),
      m('.popup__steps', {
        class: (c.currentStepIndex() === c.steps.length - 1) ? 'hide' : '',
      }),
      m.component(button, {
        value : 'Next step',
        class : 'button--ok',
        action: c.next,
      }),
    ]),
  ]);
};

export default component;
