import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { CSSProperties, KeyboardEventHandler } from 'react';

import { COLORS } from 'Components/foundation';
import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';
import SInput, { SInputProps } from 'Components/structural/SInput/SInput';
import UIcon from 'Components/unit/UIcon/UIcon';

import { STreeSelectItem } from './components/STreeSelectItem';
import { keyHandlersFactory } from './keys-handler';
import styles from './STreeSelect.style';

export type TreeSelectItem = {
  id: number;
  label: string;
  parentId?: number | null;
};

export type TreeSelecteItemWithMeta = TreeSelectItem & {
  collapsed: boolean;
  active: boolean;
};

export type TreeSelectItemWithChildren = TreeSelecteItemWithMeta & {
  children: TreeSelectItemWithChildren[];
};

export const convertToTrees = (items: TreeSelecteItemWithMeta[]): TreeSelectItemWithChildren[] => {
  const trees: TreeSelectItemWithChildren[] = [];
  const itemsById: Record<number, TreeSelectItemWithChildren> = {};

  items.forEach((item) => {
    itemsById[item.id] = { ...item, children: [] };
  });

  items.forEach((item) => {
    const parent = itemsById[item.parentId || 0];

    if (parent) {
      parent.children.push(itemsById[item.id]);
      return;
    }

    trees.push(itemsById[item.id]);
  });

  return trees;
};

export const addMetaToItems = (
  items: TreeSelectItem[],
  collapsed: boolean,
  selectedItem?: TreeSelectItem,
): TreeSelecteItemWithMeta[] => {
  return items.map((item) => {
    const active = selectedItem?.id === item.id;

    return { ...item, collapsed, active };
  });
};

export const collapseItem = (trees: TreeSelecteItemWithMeta[], id: number): TreeSelecteItemWithMeta[] => {
  return trees.map((tree) => {
    if (tree.id === id) return { ...tree, collapsed: !tree.collapsed };

    return tree;
  });
};

export const filterItems = (items: TreeSelecteItemWithMeta[], searchInput: string): TreeSelecteItemWithMeta[] => {
  const search = searchInput.toLowerCase();

  return items.filter((item) => {
    const label = item.label.toLowerCase();

    return label.includes(search);
  });
};

export type STreeSelectProps = {
  items: TreeSelectItem[];
  onSelect: (id: number) => void;

  type?: SInputProps['type'];
  collapsed?: boolean;
  selectedItem?: TreeSelectItem;
  placeholder?: string;
  search?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
};

export const STreeSelect = ({
  items,
  onSelect,
  selectedItem,
  placeholder,
  search,
  disabled,
  type = 'small',
  collapsed = false,
  style = {},
}: STreeSelectProps) => {
  const [dropdownListId] = useState(() => `tree-select-dropdown-${uuidv4()}`);
  const [inputId] = useState(() => `tree-select-input-${uuidv4()}`);

  const [searchInput, setSearchInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [itemsWithMeta, setItemsWithMeta] = useState(() => addMetaToItems(items, collapsed));

  useEffect(() => setItemsWithMeta(addMetaToItems(items, collapsed, selectedItem)), [items, collapsed, selectedItem]);

  const handleCollapse = useCallback((id: number) => {
    setItemsWithMeta((prev) => collapseItem(prev, id));
  }, []);

  const handleSelect = useCallback(
    (id: number) => {
      setSearchInput(() => '');
      setShowDropdown(() => false);
      onSelect(id);
    },
    [onSelect],
  );

  const toggleDropdown = useCallback(() => {
    setShowDropdown((prev) => !prev);
  }, []);

  const handleFocus = useCallback(() => {
    if (disabled) return;

    setShowDropdown(true);
  }, []);

  const handleDefocus = useCallback((isClickedOutside: boolean) => {
    if (!isClickedOutside) return;

    setShowDropdown(false);
    setSearchInput('');
  }, []);

  const keyHandlers = useMemo(
    () =>
      keyHandlersFactory({
        dropdownListId,
        inputId,
        onSelect: handleSelect,
        onCancel: () => handleDefocus(true),
      }),
    [dropdownListId, inputId, handleDefocus, handleSelect],
  );

  const handleKeydown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (evt) => {
      if (!Object.keys(keyHandlers).includes(evt.key)) return;

      return keyHandlers[evt.key]?.(evt);
    },
    [keyHandlers],
  );

  const filteredItems = useMemo(
    () => (searchInput ? filterItems(itemsWithMeta, searchInput) : undefined),
    [itemsWithMeta, searchInput],
  );
  const itemsToDisplay = useMemo(() => filteredItems ?? convertToTrees(itemsWithMeta), [filteredItems, itemsWithMeta]);
  const inputValue = showDropdown ? searchInput : selectedItem?.label ?? '';

  return (
    <OutsideClickWrapper onClick={handleDefocus} style={{ ...styles.container, ...style }}>
      <SInput
        id={inputId}
        type={type}
        value={inputValue}
        placeholder={placeholder}
        onChange={setSearchInput}
        onFocus={handleFocus}
        disabled={disabled}
        icon={{ icon: 'arrow-down', type: 'standard-dark', ghost: true }}
        customIcon={
          <div onClick={toggleDropdown} style={styles.icon}>
            <UIcon name={showDropdown ? 'arrow-up' : 'arrow-down'} size={8} color={COLORS.TEXT.SECONDARY_DEFAULT} />
          </div>
        }
        style={{ cursor: search ? 'text' : 'pointer', ...styles.input }}
        inputHtmlAttributes={{ onKeyDown: handleKeydown }}
      />
      <div
        data-test-id="tree-select-dropdown"
        onKeyDown={handleKeydown}
        id={dropdownListId}
        style={{ ...styles.dropdown, ...(showDropdown ? styles.activeDropdown : {}) }}
      >
        {itemsToDisplay.map((item) => {
          return (
            <STreeSelectItem
              key={item.id}
              item={item}
              level={0}
              onSelect={handleSelect}
              collapsable={collapsed}
              handleCollapse={handleCollapse}
            />
          );
        })}
      </div>
    </OutsideClickWrapper>
  );
};

export default STreeSelect;
