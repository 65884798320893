import Model from 'models/Model';

var Configuration = {
  endpoint  : 'api/Configurations',
  attributes: {
    label: {
      type: 'string',
    },
    data: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    default: {
      type: 'string',
    },
    schema: {
      type: 'string',
    },
  },
  methods: {
    value: function value(_Configuration, newValue) {
      if (newValue !== undefined)
        this.data(JSON.stringify(newValue));

      var value = new Error('Unable to parse data');

      try {
        value = JSON.parse(this.data());
      } catch (e) {
        console.error('Value = ', value, e);
      }

      return value;
    },
  },
};

export default Model.register('Configuration', Configuration);
