// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    height: 50,
    width: 30,
    borderRadius: 4,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isHover: {
    backgroundColor: COLORS.GREY_LIGHT.HOVER,
  },
  label: {
    ...TYPOGRAPHY.CAPTION,
    fontWeight: 700,
    marginTop: 1,
  },
  labelUnselected: {
    color: COLORS.TEXT.SECONDARY_HOVER,
    fontWeight: 400,
  },
  indicator: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginTop: 8,
  },
  indicatorSelected: {
    backgroundColor: COLORS.BLACK.DEFAULT,
  },
  indicatorUnselected: {
    boxShadow: `0px 0px 0px 1px ${COLORS.BLACK.DISABLED}`,
  },
};
