import { createSlice } from '@reduxjs/toolkit';

import { mergeDeep } from 'Libs/mergeDeep';
import { getErrorMessage } from 'Libs/redux/utils';
import { getContributionInfos } from './thunks';

import type { HomepageState } from './types';

export const defaultState: HomepageState = {
  hasCreatedKnowledge: false,
  hasCreatedCampaign: false,
  hasCreatedBreakingNews: false,
  hasCreatedCourse: false,
  isOldUser: false,
  contributionInfosError: undefined,
  loading: true,
  hasSkippedIntro: false,
};

const slice = createSlice({
  name: 'homepage',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    resetError: (state) => mergeDeep(state, { contributionInfosError: undefined }),
    skipIntro: (state) => mergeDeep(state, { hasSkippedIntro: true }),
  },
  extraReducers: (builder) => {
    builder.addCase(getContributionInfos.pending, (state) =>
      mergeDeep(state, {
        contributionInfosError: undefined,
        loading: true,
      }),
    );
    builder.addCase(getContributionInfos.fulfilled, (state, { payload }) =>
      mergeDeep(state, { ...payload, loading: false }),
    );
    builder.addCase(getContributionInfos.rejected, (state, action) =>
      mergeDeep(state, {
        contributionInfosError: getErrorMessage(action),
        loading: false,
      }),
    );
  },
});

export const { actions, reducer } = slice;
