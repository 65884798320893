import { t } from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';

var SwipingCards = {
  endpoint  : 'api/SwipingCards',
  attributes: {
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    propositions: {
      type : 'collection',
      Model: 'SwipingCardsItem',
    },
    options: {
      type : 'collection',
      Model: 'GameItem',
    },
    questionId: {
      type: 'number',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_SwipingCards, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;
      var clone = this.clone();
      var isEqual = true;

      clone = clone.toJSON();

      var lastOption = clone.options[clone.options.length - 1];
      var secondLastOption = jsonToCompare.options[jsonToCompare.options.length - 1];

      if (!lastOption.data && clone.options.length > 2)
        clone.options.pop();

      if (!secondLastOption.data && jsonToCompare.options.length > 2)
        jsonToCompare.options.pop();

      if (clone.question.data !== jsonToCompare.question.data)
        isEqual = false;
      else if (clone.explanation.data !== jsonToCompare.explanation.data)
        isEqual = false;
      else if (clone.options.length !== jsonToCompare.options.length)
        isEqual = false;
      else if (clone.propositions.length !== jsonToCompare.propositions.length)
        isEqual = false;
      else {
        clone.options.forEach(function compareOption(option, i) {
          if (option.data !== jsonToCompare.options[i].data)
            isEqual = false;
        });

        clone.propositions.forEach(function compareProposition(proposition, i) {
          var propositionToCompare = jsonToCompare.propositions[i];

          if (proposition.answer.data !== propositionToCompare.answer.data)
            isEqual = false;
          else if (proposition.typeId !== propositionToCompare.typeId)
            isEqual = false;
          else if (proposition.typeId !== Enum.swipingCardsItemType.IMAGE
            && proposition.text.data !== propositionToCompare.text.data)
            isEqual = false;
          else if (proposition.typeId !== Enum.swipingCardsItemType.TEXT
            && proposition.image.url !== propositionToCompare.image.url)
            isEqual = false;
        });
      }

      return isEqual;
    },
    countUseOfGameItem: function countUseOfGameItem(_SwipingCards, giId) {
      var count = 0;

      this.options().forEach(function countInItem(item) {
        if (item.id() === giId)
          count++;
      });

      this.propositions().forEach(function countInItem(item) {
        if (item.text().id() === giId)
          count++;
      });

      if (this.explanation().id() === giId)
        count++;

      if (this.question().id() === giId)
        count++;

      return count;
    },
    deleteGameItem: function deleteGameItem(_SwipingCards, giId) {
      var self = this;
      var GameItem = Model('GameItem');

      this.options().forEach(function countInItem(item, key) {
        if (item.id() === giId)
          self.options().replaceAt(key, new GameItem());
      });

      this.propositions().forEach(function countInItem(item) {
        if (item.text().id() === giId)
          item.text(new GameItem());
      });

      if (this.explanation().id() === giId)
        this.explanation(new GameItem());

      if (this.question().id() === giId)
        this.question(new GameItem());
    },

    validate: function validate() {
      if (this.options().length < 2)
        throw new Error(t('gameplays:validation.at_least_two_options'));

      if (!this.propositions().length)
        throw new Error(t('gameplays:validation.at_least_one_card'));

      if (!this.explanation().data())
        throw new Error(t('gameplays:validation.explanation_missing'));

      this.propositions().forEach(function countInItem(item, key) {
        if (item.typeId() === Enum.swipingCardsItemType.TEXT && !item.text().data())
          throw new Error(t('gameplays:validation.cards_text_missing', {index: key + 1}));
        if (item.typeId() === Enum.swipingCardsItemType.IMAGE && !item.image().url())
          throw new Error(t('gameplays:validation.cards_image_missing', {index: key + 1}));
        if (!item.answer().data())
          throw new Error(t('gameplays:validation.card_selected_answer', {index: key + 1}));
      });

      this.options().forEach(function checkOptions(item, key, arr) {
        if (!item.data() && key < arr.length - 1)
          throw new Error(t('gameplays:validation.options_text_missing', {index: key + 1}));
      });

      return true;
    },
    presetFromKnowledge: function presetFromKnowledge(_SwipingCards, knowledge) {
      var explanationTexts = knowledge
        .getDistinctGameItemByTypeId(Enum.gameItemTypes.TEXT);
      var newExplanationData = explanationTexts.length ? explanationTexts[0] : knowledge.explanation().data();

      this.explanation().data(newExplanationData);
    },
    getDirective: function getDirective() {
      var self = this;

      if (countOptions() < 2)
        return t('gameplays:directives.at_least_two_options');
      if (!countPropositions())
        return t('gameplays:directives.at_least_one_proposition');
      if (self.propositions().length && !self.propositions().at(0).answer().data())
        return t('gameplays:directives.right_option_proposition');
      if (!self.explanation().data())
        return t('gameplays:directives.enter_explanation');

      return '';

      function countOptions() {
        return self.options().filter(function isNotEmpty(option) {
          return option.data();
        }).length;
      }

      function countPropositions() {
        return self.propositions().filter(function isNotEmpty(proposition) {
          return proposition.text().data() || proposition.image().url();
        }).length;
      }
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_SwipingCards, knowledgeId) {
      return {
        question: {
          typeId     : Enum.gameItemTypes.QUESTION,
          knowledgeId: knowledgeId,
        },
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
        options: [{
          typeId     : Enum.gameItemTypes.SHORT,
          knowledgeId: knowledgeId,
        },
        {
          typeId     : Enum.gameItemTypes.SHORT,
          knowledgeId: knowledgeId,
        },
        ],
      };
    },
  },
};

export default Model.register('SwipingCards', SwipingCards);
