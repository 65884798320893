import React, { useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';

import STable, { TableAction } from 'Components/structural/STable/STable';
import { Header, HeaderType, TABLE_ACTION_TYPES, TableMessage } from 'Components/structural/STable/types';
import { useTypedDispatch } from 'Libs/redux/utils';

import { actions, useModuleSelector } from '../redux';
import { AmbassadorRemoveModal } from '../AmbassadorRemoveModal/AmbassadorRemoveModal';

import styles from './AmbassadorList.style';

export type FetchAmbassadorsParams = {
  from: number;
  to: number;
  isShowMore: boolean;
};

type PlayerToRemoveType = {
  id: number;
  data: string;
};

export type PropsAmbassadorList = {
  fetchAmbassadors: (params: FetchAmbassadorsParams) => Promise<void>;
  tableMessage: TableMessage | undefined;
  showMoreError: string;
  onSort: (params: string[]) => void;
  onSearch: (params: string[]) => void;
  ambassadorsFetchAmount: number;
};

export const AmbassadorList = ({
  fetchAmbassadors,
  tableMessage,
  showMoreError,
  ambassadorsFetchAmount,
  onSort,
  onSearch,
}: PropsAmbassadorList) => {
  const ambassadors = useModuleSelector((state) => state.ambassadors);
  const ambassadorCount = useModuleSelector((state) => state.ambassadorCount);
  const isGetAllLoading = useModuleSelector((state) => state.isGetAllLoading);
  const isRemoveLoading = useModuleSelector((state) => state.isRemoveLoading);

  const dispatch = useTypedDispatch();

  const [playerToRemove, setPlayerToRemove] = useState<PlayerToRemoveType | null>(null);

  const onRemoveAction = useCallback(
    async (success: boolean) => {
      if (!playerToRemove) return;

      if (!success) {
        setPlayerToRemove(null);

        return;
      }

      await dispatch(actions.remove(playerToRemove.id)).promise;

      setPlayerToRemove(null);
    },
    [playerToRemove, dispatch],
  );

  const handleSort = useCallback((column: string, columnOrder: string) => {
    onSort(columnOrder === 'none' ? [] : [`${column} ${columnOrder}`]);
  }, []);

  const handleSearch = useCallback((str: string) => {
    onSearch(str === '' ? [] : [str]);
  }, []);

  const handleExportAmbassadorsReport = useCallback(() => {
    return dispatch(actions.getAmbassadorReport());
  }, [dispatch]);

  const handleShowMore = useCallback(() => {
    return fetchAmbassadors({
      from: ambassadors.length,
      to: ambassadors.length + ambassadorsFetchAmount,
      isShowMore: true,
    });
  }, [ambassadors, fetchAmbassadors]);

  const tableActions: TableAction[] = useMemo(
    () => [
      {
        type: TABLE_ACTION_TYPES.SEARCH,
        id: 'table-action-1',
        params: {
          placeholder: t('config_ambassadors:ambassador_list.table_actions.placeholder'),
          placeholderOnExpand: t('config_ambassadors:ambassador_list.table_actions.placeholder_on_expand'),
          onAction: handleSearch,
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
      {
        type: TABLE_ACTION_TYPES.EXPORT,
        id: 'table-action-2',
        params: {
          text: t('config_ambassadors:ambassador_list.table_actions.text'),
          leftIcon: 'download',
          onAction: handleExportAmbassadorsReport,
          trackingAction: 'export',
          trackingContext: 'ambassador',
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
    ],
    [handleSearch, handleExportAmbassadorsReport, t],
  );

  const columns: Header[] = useMemo(
    () => [
      {
        id: '0',
        type: HeaderType.EmptyHeader,
        width: '44px',
        disableSorting: true,
        accessor: 'profilePicture',
      },
      {
        id: '1',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_ambassadors:ambassador_list.table_actions.name'),
          align: 'left',
          onAction: handleSort,
        },
        width: '180px',
        accessor: 'lastName',
      },
    ],
    [handleSort, t],
  );

  const callbackOptions = useMemo(
    () => [
      {
        icon: 'delete',
        id: 'action_delete_id',
        callback: setPlayerToRemove,
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      ambassadors.map(({ id, player }) => ({
        id: id,
        dataAccessor: `${player.lastName || ''} ${player.firstName || ''}`.trim(),
        profilePicture: {
          id: `${id}_profilePicture`,
          type: 'UTableCellProfilePicture',
          params: {
            picture: player.profilePicture.url,
          },
          wrapperStyle: {
            display: 'flex',
            flex: 1,
          },
        },
        lastName: {
          id: `${id}_lastName`,
          type: 'UTableCellUser',
          params: {
            name: `${player.lastName || ''} ${player.firstName || ''}`.trim(),
            email: player.email,
            isValidated: player.isValidated,
          },
        },
        callbackOptions,
      })),
    [ambassadors, callbackOptions],
  );

  return (
    <div style={styles.wrapper}>
      <STable
        title={t('config_ambassadors:ambassador_list.title')}
        titleExplanation={t('config_ambassadors:ambassador_list.subtitle', {
          count: ambassadorCount,
        })}
        columns={columns}
        data={data}
        total={ambassadorCount}
        range={ambassadorsFetchAmount}
        tableActions={tableActions}
        isTableLoading={data.length === 0 && isGetAllLoading}
        notice={t('config_ambassadors:ambassador_list.notice')}
        onShowMoreClicked={handleShowMore}
        showMoreError={showMoreError}
        tableMessage={tableMessage}
      />
      <AmbassadorRemoveModal
        playerName={playerToRemove ? playerToRemove.data : ''}
        visible={!!playerToRemove}
        onAction={onRemoveAction}
        loading={isRemoveLoading}
      />
    </div>
  );
};
