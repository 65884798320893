import React, { CSSProperties, FunctionComponent, useMemo } from 'react';

import ULargeRadioButton, { ULargeRadioButtonProps } from 'Components/unit/ULargeRadioButton/ULargeRadioButton';

import styles from './SLargeRadio.style';

export type Item = Omit<ULargeRadioButtonProps, 'selected' | 'onClick'> & {
  id: string;
};

export type SLargeRadioProps = {
  items: Item[];
  activeItemId: string;
  onClick: (id: string) => void;

  style?: CSSProperties;
};

export const SLargeRadio: FunctionComponent<SLargeRadioProps> = ({ items, activeItemId, onClick, style }) => {
  const containerStyle = useMemo(
    () => ({
      ...styles.container,
      ...style,
    }),
    [style],
  );

  const radioItems = useMemo(
    () =>
      items.map((item) => {
        const { id, style: childPropStyle = {}, ...props } = item;
        const selected = id === activeItemId;
        const itemStyle = {
          ...childPropStyle,
          ...styles.item,
        };

        return (
          <ULargeRadioButton key={id} {...props} style={itemStyle} selected={selected} onClick={() => onClick(id)} />
        );
      }),
    [activeItemId, items, onClick],
  );

  return <div style={containerStyle}>{radioItems}</div>;
};
export default SLargeRadio;
