import { mergeDeep } from 'Libs/mergeDeep';

export type RoleType = Readonly<{
  id: number,
  name: string,
}>;

export default function createRole(values?: any): RoleType {
  const defaultState: RoleType = {
    id: 0,
    name: '',
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep<RoleType>(defaultState, { ...values });
}
