import m from 'm';
import T from 'types';
import KEY from 'keys';
import Bus from 'services/pubSubService';

/* args:
 *  type: [ email, password, search ],
 *  placeholder:
 *  --
 *  value (m.prop)
 *  or -
 *  model
 *  path
 *  --
 *  onclick
 *  class
 *  max
 */
var classTypes = {
  email   : 'input--email',
  password: 'input--password',
  search  : 'input--search',
};

var component = {};

component.controller = function controller(args) {
  var self = this;
  var blurProtected = m.prop(false);

  self.error = m.prop(false);

  self.oninput = function oninput(_args, value) {
    noError();
    self.propValue(_args)(value);
  };

  self.propValue = function propValue(_args) {
    if (_args.model && _args.path)
      return _args.model[_args.path];

    return _args.value;
  };

  self.onkeyup = function onkeyup(e) {
    var key = e.which;

    e.preventDefault();

    if (key === KEY.ENTER && args.autosave)
      save();
  };

  self.onblur = function onblur() {
    if (!blurProtected() && args.autosave)
      save();
  };

  self.onclick = function onclick() {
    if (args.onclick)
      args.onclick(args.model);
  };

  function save() {
    var promise = null;

    if (!args.model || !args.path)
      return;

    if (args.model[args.path](args.model[args.path]().trim()))
      promise = args.model.save();
    else if (args.model.id() !== undefined)
      promise = args.model.destroy();

    blurProtected(true);

    if (promise) {
      promise
        .then(m.redraw)
        .then(blurProtected.bind(null, false))
        .catch(function triggerError(err) {
          self.error(true);
          Bus.trigger('popup:error', err);
          Bus.trigger('general:error', err);
          m.redraw();
        });
    }
  }

  function noError() {
    self.error(false);
    Bus.trigger('popup:noError');
    Bus.trigger('general:noError');
  }
};

component.view = function view(c, args) {
  var readonly = args && args.readonly;
  var genericPlaceholder;

  if (args.model && args.path)
    genericPlaceholder = ['New', T.lowercase(args.model._type)].join(' ');

  var inputArgs = {
    class: [
      c.error() ? 'popup-init__error' : '',
      readonly ? 'input--readonly' : '',
    ].join(' '),
    type       : args.type || 'text',
    placeholder: args.placeholder || genericPlaceholder,
    onfocus    : c.error.bind(null, false),
    onblur     : c.onblur,
    value      : c.propValue(args)(),
    oninput    : m.withAttr('value', c.oninput.bind(null, args)),
    onclick    : c.onclick,
    onkeyup    : c.onkeyup,
    maxlength  : args.max ? args.max : '',
  };

  if (readonly)
    inputArgs.readonly = true;

  return m('.newInput', {
    class: [
      classTypes[args.type] || 'input--none',
      args.class,
    ].join(' '),
  }, [
    m('input.newInput__value', inputArgs),
    m('.input__type'),
    m('.newInput__maxlength', args.max
      ? [c.propValue(args)().length, args.max].join(' / ')
      : ''),
  ]);
};

export default component;
