import { useCallback, useMemo, useState } from 'react';
import { invert } from 'lodash';
import { useTranslation } from 'react-i18next';

type Tabs = string | 'unknown';

const TAB_PK_ROUTE = {
  'course-microknowledges': /\/course\/[0-9]*\/microknowledges/,
  'course-audience': /\/course\/[0-9]*\/audience/,
};

const TAB_IDX_PK: Record<number, Tabs> = {
  0: 'course-microknowledges',
  1: 'course-audience',
};

const TAB_PK_IDX = invert(TAB_IDX_PK);

export const getCurrentTabFromPathname = (pathname: string): Tabs =>
  (Object.entries(TAB_PK_ROUTE).find(([, regex]) => pathname.match(regex))?.[0] || 'unknown') as Tabs;

export const extractCourseIdFromPathname = (pathname: string) => {
  const splitUrl = pathname.split('/');

  const id = splitUrl.reduce((acc, current) => {
    const parsedId = parseInt(current, 10);

    return Number.isNaN(parsedId) ? acc : parsedId;
  }, NaN);

  return id;
};

export const useCourseEditionTabs = () => {
  const { t } = useTranslation();
  const TABS: Record<string, string> = {
    'course-microknowledges': t('courses:edition_bar.content_tab'),
    'course-audience': t('courses:edition_bar.audience_tab'),
  };
  const tabs = useMemo(() => Object.values(TAB_IDX_PK).map((pk) => TABS[pk]), []);

  const [currentTab, setCurrentTab] = useState<Tabs>(() => getCurrentTabFromPathname(window.location.pathname) as Tabs);
  const currentTabIndex = useMemo(() => Number(TAB_PK_IDX[currentTab]), [currentTab]);

  const idParam = useMemo(() => extractCourseIdFromPathname(window.location.pathname), []);

  const goToTab = useCallback(
    (tabIdx: number) => {
      const tab = TAB_IDX_PK[tabIdx];
      if (tab === 'course-audience') {
        window.history.pushState({}, '', `/course/${idParam}/audience`);
        setCurrentTab('course-audience');
      }

      if (tab === 'course-microknowledges') {
        window.history.pushState({}, '', `/course/${idParam}/microknowledges`);
        setCurrentTab('course-microknowledges');
      }
    },
    [idParam],
  );

  return {
    tabs,
    idParam,
    currentTab,
    currentTabIndex,
    goToTab,
  };
};
