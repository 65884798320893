import React, { useMemo, MouseEvent, MouseEventHandler, useCallback } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { IconName } from 'Components/foundation/icons';
import ULabel from 'Components/unit/ULabel/ULabel';

import defaultStyle, { stylesMap } from './UCheckbox.style';

export type CheckBoxStatus = 'checked' | 'unchecked' | 'mixed';

type CheckBoxType = 'standard' | 'accentuated';

export type UCheckboxProps = {
  onClick: (type: CheckBoxStatus, event: MouseEvent<HTMLDivElement>) => void;
  type?: CheckBoxType;
  checkBoxStatus: CheckBoxStatus;
  isDisabled?: boolean;
  isLabelRequired?: boolean;
  label?: string;
  style?: any;
};

const UNCHECKED = 'unchecked';
const CHECKED = 'checked';
const MIXED = 'mixed';

const statusMap: Record<CheckBoxStatus, CheckBoxStatus> = {
  [UNCHECKED]: CHECKED,
  [MIXED]: CHECKED,
  [CHECKED]: UNCHECKED,
};

const iconMap: Record<'checked' | 'mixed', IconName> = {
  [CHECKED]: 'check',
  [MIXED]: 'mixed-check',
};

/**
 * Display a checkBox
 *
 * Props:
 *  - onClick: Function called when we click on the checkbox
 *  - type: standard or accentuated
 *  - checkBoxStatus: set the state of the check box (mixed|checked|unchecked)
 *  - isDisabled : should not be clicked when the checkbox is disabled
 *  - style: For overriding the component's style
 *
 */
export const UCheckbox = ({
  checkBoxStatus,
  isDisabled,
  isLabelRequired,
  label,
  style,
  type = 'standard',
  onClick,
}: UCheckboxProps) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (isDisabled) return;

      onClick(statusMap[checkBoxStatus], event);
    },
    [checkBoxStatus, isDisabled, onClick],
  );

  const styles = useMemo(() => {
    const backgroundUnchecked = checkBoxStatus === UNCHECKED ? { backgroundColor: 'transparent' } : {};

    // eslint-disable-next-line no-nested-ternary
    const styleType = isDisabled ? 'disabled' : type === 'accentuated' ? 'accentuated' : 'enabled';

    const stylesheet = stylesMap[styleType];

    return {
      checkbox: {
        ...stylesheet.checkbox,
        ...backgroundUnchecked,
        ...style,
      },
      icon: stylesheet.icon,
    };
  }, [checkBoxStatus, isDisabled, style, type]);

  return (
    <div
      style={{
        ...defaultStyle.clickableArea,
        ...(isDisabled && defaultStyle.disabledClickableArea),
        ...defaultStyle.linearWrapper,
      }}
      onClick={handleClick}
      data-test-id="UCheckbox"
    >
      <div style={styles.checkbox}>
        {checkBoxStatus !== 'unchecked' && iconMap[checkBoxStatus] ? (
          <UIcon style={styles.icon} size={12} name={iconMap[checkBoxStatus]} />
        ) : null}
      </div>
      {label && (
        <ULabel required={isLabelRequired} style={defaultStyle.label} size="S">
          {label}
        </ULabel>
      )}
    </div>
  );
};

export default UCheckbox;
