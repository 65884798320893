import { createStyleSheet } from 'Components/foundation/stylesheets';

import { ELEVATIONS, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0px',
    width: '100%',
  },
  draggedContainer: {
    borderColor: COLORS.PRIMARY.DEFAULT,
  },
  rightContainer: {
    width: '100%',
    height: 'auto',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderRadius: '8px',
  },
  selectedRightContainer: {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: COLORS.PRIMARY.DEFAULT,
    borderRadius: '8px',
    boxShadow: ELEVATIONS.LIGHT_TINY,
  },
  optionsMenu: {
    marginLeft: '11px',
    position: 'relative',
  },
  errorContainer: {
    width: '100%',
    height: '48px',
    border: '1px solid',
    borderColor: COLORS.ERROR.DEFAULT,
    borderRadius: '8px',
    boxShadow: ELEVATIONS.LIGHT_TINY,
  },
  childContainer: {
    marginLeft: '42px',
    display: 'flex',
  },
  onHover: {
    boxShadow: ELEVATIONS.LIGHT_TINY,
    transition: 'box-shadow 0.2s ease-in-out',
  },
  leftContainer: {
    cursor: 'pointer',
  },
  dragAndDrop: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
  visiblePlus: {
    position: 'relative',
    left: '-10px',
    top: '0px',
  },
  visibleChildPlus: {
    position: 'relative',
    left: '32px',
    top: '0px',
  },
  invisiblePlus: {
    visibility: 'hidden',
  },
});

export default styleSheet;
