// @flow

/* eslint-disable key-spacing */

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const INPUT_SIZE = 258;

export default {
  wrapper: {
    width: 540,
    marginTop: 51,
  },
  helperFormTitle: {
    ...TYPOGRAPHY.H1,
    marginBottom: 8,
  },
  explanationTipsWrapper: {
    marginBottom: 20,
  },
  explanation: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBottom: 15,
  },
  explanationLink: {
    color: COLORS.TEXT.ACCENTUATED,
  },
  tips: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontStyle: 'italic',
  },
  form: {
    display: 'flex',
  },
  formLabel: {
    ...TYPOGRAPHY.BODY4,
    marginBottom: 8,
  },
  topLabelForm: {
    marginRight: 24,
    width: INPUT_SIZE,
  },
  bottomLabelForm: {
    width: INPUT_SIZE,
  },
};
