import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Libs/redux/utils';
import { actions, getSelectedActivity, getDefaultActivity, getSelectedContentIds } from '../../../redux';
import type { ContentType } from '../../../redux/models/Content';
import Drawer from '../Drawer/Drawer';

import { DailySession } from '../DailySession/DailySession';
import { useTranslateContent } from '../../hooks/useTranslateContent';
import { useSortableContents } from '../../hooks/useSortableContents';

type DrawerDayKeyType = number | null;

export const Schedule = () => {
  const dispatch = useDispatch();
  const addContentsToDailySerieDay = (key: number, contents: Array<ContentType>) =>
    dispatch(actions.addContentsToDailySerieDay(key, contents));
  const removeDailySerieDay = (key: number) => {
    dispatch(actions.removeDailySerieDay(key));
  };
  const removeContentFromDailySerieDay = ({ contentKey, dayKey }: { contentKey: number; dayKey: number }) =>
    dispatch(actions.removeContentFromDailySerieDay(contentKey, dayKey));
  const addContentToDailySerieDayToIndex = (dayIndex: number, contentIndex: number, content: ContentType) =>
    dispatch(actions.addContentToDailySerieDayToIndex(dayIndex, contentIndex, content));
  const moveContentInDailySerieDays = (
    oldIndex: number,
    oldDayIndex: number,
    newIndex: number,
    newDayIndex: number,
  ) => {
    dispatch(actions.moveContentInDailySerieDays(oldIndex, oldDayIndex, newIndex, newDayIndex));
  };

  const { translationModal, setContentToTranslate } = useTranslateContent();

  const [drawerDayKey, setDrawerDayKey] = useState<number | null>(null);
  const { draggedContentToDayIndex, setDraggedContentToDayIndex } = useSortableContents({
    moveContentInDailySerieDays,
  });

  const defaultActivity = useTypedSelector(getDefaultActivity);

  const selectedActivity = useTypedSelector(getSelectedActivity);

  const selectedContentIds = useTypedSelector(getSelectedContentIds);

  const handleDrawerClose = () => {
    setDrawerDayKey(null);
  };

  const handleNewContentClick = () => {
    window.open('/knowledge/new', '_blank');
  };

  const handleSelectContent = (key: DrawerDayKeyType) => (contents: Array<ContentType>) => {
    setDrawerDayKey(null);

    if (key !== null) {
      addContentsToDailySerieDay(key, contents);
    }
  };

  const handleAddClickFactory = (key: number) => () => {
    setDrawerDayKey(key);
  };

  const handleContentDragFromDrawer = (dayIndex: number, contentIndex: number, content: ContentType) => {
    addContentToDailySerieDayToIndex(dayIndex, contentIndex, content);
  };

  const handleContentMoveFromDrawer = (dayIndex: number) => {
    setDraggedContentToDayIndex(dayIndex);
  };

  const handleDeleteClickFactory = (key: number) => () => {
    removeDailySerieDay(key);
  };

  return (
    <>
      <Drawer
        languageId={defaultActivity.languageId}
        visible={drawerDayKey !== null}
        onClose={handleDrawerClose}
        onSelect={handleSelectContent(drawerDayKey)}
        onNewContentPress={handleNewContentClick}
        onContentDrag={handleContentDragFromDrawer}
        onContentMove={handleContentMoveFromDrawer}
        contentIsDragging={draggedContentToDayIndex !== -1}
        selectedContentIds={selectedContentIds}
      />
      {translationModal}
      {selectedActivity.dailySerie.days.map(({ key }, index) => (
        <DailySession
          index={index}
          dayKey={key}
          key={key}
          handleAddClickFactory={handleAddClickFactory}
          handleDeleteClickFactory={handleDeleteClickFactory}
          draggedContentToDayIndex={draggedContentToDayIndex}
          setContentToTranslate={setContentToTranslate}
          removeContentFromDailySerieDay={removeContentFromDailySerieDay}
        />
      ))}
    </>
  );
};
