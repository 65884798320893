import styles from 'Components/structural/SUploadDropArea/SUploadDropArea.style';
import React from 'react';

type ErrorMessageProps = {
  errorIncorrectFormat1: string;
  errorIncorrectFormat2: string;
  errorSizeTooBig: string;
  errorManyFiles: string;
  dropErrorNr: number;
};

type ErrorIncorrectFormatProps = {
  errorIncorrectFormat1: string;
  errorIncorrectFormat2: string;
};

export const ERROR_DROP = {
  UNSUPPORTED_FILE: 0,
  SIZE_TOO_BIG: 1,
  TOO_MANY_FILES: 2,
};
const ErrorIncorrectFormat = ({ errorIncorrectFormat1, errorIncorrectFormat2 }: ErrorIncorrectFormatProps) => (
  <>
    <div data-test-id="droparea-error-incorrect-format">{errorIncorrectFormat1}</div>
    {errorIncorrectFormat2}
  </>
);

export const ErrorMessage = ({
  errorIncorrectFormat1,
  errorIncorrectFormat2,
  errorSizeTooBig,
  errorManyFiles,
  dropErrorNr,
}: ErrorMessageProps) => {
  const hashError = {
    [ERROR_DROP.SIZE_TOO_BIG]: errorSizeTooBig,
    [ERROR_DROP.TOO_MANY_FILES]: errorManyFiles,
    [ERROR_DROP.UNSUPPORTED_FILE]: (
      <ErrorIncorrectFormat
        errorIncorrectFormat1={errorIncorrectFormat1}
        errorIncorrectFormat2={errorIncorrectFormat2}
      />
    ),
  };

  return (
    <div style={styles.errorSentence} data-test-id="droparea-error-message">
      {hashError[dropErrorNr]}
    </div>
  );
};

export default ErrorMessage;
