/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import type { EffectCallback, DependencyList } from 'react';
import { useIsFirstRender } from 'Hooks/useIsFirstRender/useIsFirstRender';

export const useAfterMountEffect = (effect: EffectCallback, deps: DependencyList = []) => {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      effect();
    }
  }, [isFirstRender, ...deps]);
};

export default useAfterMountEffect;
