// @flow

/**
 * SActionInfoBoxPage - page to display action information
 * Required
 * - children: children components to display in the content box
 *
 * Optional
 * - spartedLogo: custom right sparted logo path
 */

import * as React from 'react';
import { getCdnUrl } from 'Libs/cdn';

import UImage from 'Components/unit/UImage/UImage';

import styles from './SActionInfoBoxPage.style';


type Props = {|

  // Required
  children: React.Node,

  // Optional
  spartedLogo: string,
  style: Object,
|};

export class SActionInfoBoxPage extends React.PureComponent<Props> {
  static defaultProps = {
    spartedLogo: getCdnUrl('common', '/sparted-logo-baseline-white.png'),
    style: undefined,
  };

  render() {
    const { spartedLogo, children, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={styles.contentWrapper}>
          <UImage
            style={styles.spartedLogo}
            src={spartedLogo}
          />
          <div style={styles.childrenWrapper}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default SActionInfoBoxPage;
