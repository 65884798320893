// @flow

/* eslint-disable react/no-multi-comp */
import React, { useCallback, useMemo } from 'react';
import m from 'mithril';
import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { actions } from '../../redux';
import { useModuleSelector } from '../../redux/hooks';

import { extractGameplayTypes, getCoverImageUrl } from 'Models/react/Knowledge/Knowledge';
import { Knowledge } from 'Models/react/Knowledge/Knowledge';
import {
  hasUserWriteRole,
  getLanguageSegmentation,
  getKnowledgeFetchLimit,
  getIndicatorText,
  getKnowledgeRoute,
} from '../../redux/utils';

import UButton from 'Components/unit/UButton/UButton';
import SNewContentCard from 'Components/structural/SNewContentCard/SNewContentCard';
import { SLegacyContentCard } from 'Components/structural/SContentCard/legacy/SLegacyContentCard';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import { SFooter } from 'Components/structural/SFooter/SFooter';

import styles from './KnowledgeGridBody.style';

export type KnowledgeItemProps = {
  knowledge: Knowledge,
};

const KnowledgeItem: React$ComponentType<KnowledgeItemProps> = ({
  knowledge: { id, cover_id, customId, images, locale, knowledgeTitle, pendingTranslation, contents },
}: KnowledgeItemProps) => {
  const types = useMemo(() => extractGameplayTypes(contents), [contents]);

  const indicatorText = useMemo(() => getIndicatorText(pendingTranslation, contents), [contents, pendingTranslation]);

  const coverUrl = useMemo(() => getCoverImageUrl(cover_id, images), [cover_id, images]);

  const handleKnowledgeClick = useCallback(
    (e) => {
      if (e && e.type === 'click') {
        e.preventDefault();
        m.route(getKnowledgeRoute(id, pendingTranslation));
      }
    },
    [id, pendingTranslation],
  );

  return (
    <a
      href={getKnowledgeRoute(id, pendingTranslation)}
      style={styles.knowledgeItemWrapper}
      onClick={handleKnowledgeClick}
      onContextMenu={handleKnowledgeClick}
      data-testid="HREF_KNOWLEDGE_CARD"
    >
      <SLegacyContentCard
        id={`#${customId || id}`}
        previewable={false}
        selectable={false}
        displayOption={false}
        title={knowledgeTitle?.data}
        types={types}
        locale={locale}
        indicatorText={indicatorText}
        style={styles.knowledgeItem}
        imageUrl={coverUrl}
        disabled={Boolean(indicatorText)}
      />
    </a>
  );
};

const LoadMore: React$ComponentType<{||}> = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const knowledges = useModuleSelector((knowledgeGrid) => knowledgeGrid.knowledges);
  const count = useModuleSelector((knowledgeGrid) => knowledgeGrid.count);
  const isLoadingMore = useModuleSelector((knowledgeGrid) => knowledgeGrid.isLoadingMore);

  const canLoadMore = useMemo(
    () => !(knowledges.length === 0) && count - knowledges.length > 0,
    [count, knowledges.length],
  );

  const handlePress = useCallback(() => {
    if (isLoadingMore) return;
    dispatch(actions.getKnowledgeFullPaginated(knowledges.length, getKnowledgeFetchLimit(window.innerWidth)));
  }, [dispatch, isLoadingMore, knowledges.length]);

  return (
    <div style={styles.loadMoreWrapper}>
      {canLoadMore && (
        <SFooter>
          <UTextLink
            text={t('contents:contents_list.load_more')}
            onClick={handlePress}
            type="secondary"
            rightIcon="bottom-chevron"
          />
        </SFooter>
      )}
    </div>
  );
};

export const KnowledgeGridBody: React$ComponentType<{||}> = () => {
  const { t } = useTranslation();

  const isLoading = useModuleSelector((knowledgeGrid) => knowledgeGrid.isLoading);
  const { selectedThematicIds, selectedSegmentationIds } = useModuleSelector((knowledgeGrid) => knowledgeGrid.filter);
  const count = useModuleSelector((knowledgeGrid) => knowledgeGrid.count);
  const knowledges = useModuleSelector((knowledgeGrid) => knowledgeGrid.knowledges);
  const hasWriteRole = useMemo(() => hasUserWriteRole(), []);

  const handleNewContentCardClick = useCallback(() => {
    const presetSeg = getLanguageSegmentation(selectedSegmentationIds);

    m.route('/knowledge/new', {
      thematicId: selectedThematicIds?.[0] || null,
      languageSegmentationId: presetSeg?.id || null,
    });
  }, [selectedSegmentationIds, selectedThematicIds]);

  if (isLoading) {
    return (
      <div style={styles.pageLoader}>
        <SPageLoaderWithTimer isLoading={isLoading} style={styles.pageLoader} />
      </div>
    );
  }

  return (
    <>
      <div style={styles.grid}>
        {hasWriteRole && (
          <SNewContentCard
            onClick={handleNewContentCardClick}
            style={styles.newContentCard}
            type={count > 0 ? 'standard' : 'accentuated'}
            label={count > 0 ? '' : t('contents:contents_list.add_first_content')}
            data-test-id="new-content-card"
          />
        )}
        {knowledges.map((k) => (
          <KnowledgeItem key={k.id} knowledge={k} />
        ))}
      </div>
      <LoadMore />
    </>
  );
};
