import T from 'types';

var Bus = {
  topics     : {},
  publish    : publish,
  trigger    : publish,
  fire       : publish,
  emit       : publish,
  broadcast  : publish,
  subscribe  : subscribe,
  on         : subscribe,
  unsubscribe: unsubscribe,
  off        : unsubscribe,
};

/** @this Bus */
function publish(topic, data) {
  var self = this;

  if (!self.topics[topic] || !self.topics[topic].length)
    return;

  self.topics[topic].forEach(function iterateOverListeners(listener) {
    listener(data);
  });
}

/** @this Bus */
function subscribe(topic, callback) {
  var self = this;

  if (!self.topics[topic])
    self.topics[topic] = [];

  if (T.is.ft(callback))
    self.topics[topic].push(callback);
  else
    console.error('Not a function', topic, callback);
}

/** @this Bus */
function unsubscribe(topic, callback) {
  var self = this;

  if (self.topics[topic]) {
    for (var i = self.topics[topic].length - 1; i >= 0; i--) {
      if (self.topics[topic][i] === callback) {
        self.topics[topic].splice(i, 1);
        break;
      }
    }
  }
}

export default Bus;
