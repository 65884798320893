import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  modal: {
    width: 700,
  },

  divider: {
    marginTop: 32,
    marginBottom: 32,
  },
  providerList: {
    display: 'flex',
    flexDirection: 'column',
  },
  providerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  providerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  providerTitle: {
    marginLeft: 10,
    ...TYPOGRAPHY.H2_BOLD,
  },
  providerInstructionsLink: {
    ...TYPOGRAPHY.BODY3,
    marginRight: 0,
    marginLeft: 0,
  },
  providerInput: {
    marginTop: 22,
  },
});

export default styleSheet;
