import m from 'm';
import Promise from 'bluebird';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import textInput from 'components/new.new.input.text';

var component = {};

component.controller = function controller() {
  var self = this;

  self.value = m.prop('');

  self.fakeItems = [{
    value      : m.prop(''),
    placeholder: 'Fake Big Data',
  },
  {
    value      : m.prop(''),
    placeholder: 'Fake Big Data Apprenti',
  },
  ];

  self.oninput = function oninput(_args, item, value) {
    item.value(value);
    if (_args.modified)
      _args.modified(true);
  };

  return self;
};

component.view = function view(c, args) {
  var readonly = args && args.readonly;
  var title = args && args.title || 'Fake title';
  var items = args && args.items || c.fakeItems;

  return m('.gaia__translation__column', {
    class: args && args.class || '',
  }, [
    m('.translation__column__header', [
      m('.translation__column__header', [
        m('.translation__column__title', {
          class: readonly ? 'translation__column__title--locked' : '',
        }, [
          m('.translation__column__title__label', title),
          m('.translation__column__title__default', {
            class: readonly ? '' : 'hidden',
          }, 'Default'),
        ]),
      ]),
      m('.translation__column__body', [
        items.map(function displayInput(item) {
          if (!readonly) {
            return m('input.translation__column__item', {
              type    : 'text',
              value   : item.value(),
              oninput : m.withAttr('value', c.oninput.bind(null, args, item)),
              onchange: args.modified ? args.modified.bind(null, true) : function noop() {
                console.log('NOOOP');
              },
              placeholder: item.placeholder,
            });
          }

          return m('.translation__column__item', item.value());

        }),
      ]),
    ]),
  ]);

};

export default component;
