import { createSlice } from '@reduxjs/toolkit';

import { mergeDeep } from 'Libs/mergeDeep';
import { getErrorMessage } from 'Libs/redux/utils';

import { createCourse } from 'Pages/Courses/redux';

import { mapCoursesResponseToState } from './utils/map-courses-response-to-state';

import {
  sendCommands,
  fetchDraftCourse,
  fetchNextCoursePage,
  fetchFilteredCourses,
  fetchAudienceMetadata,
  updateCoursePublishStatus,
  fetchPdf,
} from './thunks';

import type {
  CoursesState,
  UpdateFilterAction,
  SetRevisionAction,
  SetListAction,
  EditMkItemAction,
  UpdateCourseAction,
  ToggleChapterAction,
  SetFirstCourseCreated,
  SetHasEditionIdMismatchError,
  SetHasEditionError,
  SetDraftStatus,
  UpdateCourseInListAction,
  UpdateCourseGroupIds,
  SetAskForConfirmationPreference,
  SavePdfPagesAction,
} from './types';
import { computeMetaData, MicroKnowledgeChapter } from '../utils/mk-mapped-linked-list';

export const defaultState: CoursesState = {
  list: {
    items: [],
    nextPage: null,
    loading: false,
    filters: {
      query: {
        title: null,
        status: 'all',
        audienceGroupId: [],
        languageCode: [],
      },
    },
  },
  edition: {
    course: undefined,
    revision: undefined,
    loading: true,
    editors: [],
    groupIds: [],
    microKnowledges: {
      headId: null,
      items: {},
    },
    numberTargetedPlayers: null,
    pdfPages: [],
    pdfIds: [],
  },
  errors: {
    courseFormError: undefined,
    hasEditionIdMismatchError: false,
    hasEditionError: false,
  },
  askConfirmationPreferenceMapping: {},
  firstCourseCreated: null,
};

const slice = createSlice({
  name: 'courses',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    resetEdition: (state) => ({
      ...state,
      edition: {
        ...defaultState.edition,
        microKnowledges: {
          headId: null,
          items: {},
        },
      },
    }),
    resetErrors: (state) => ({
      ...state,
      errors: defaultState.errors,
    }),
    updateCourse: (state, { payload }: UpdateCourseAction) => mergeDeep(state, { edition: { course: payload } }),
    updateCourseGroupIds: (state, { payload }: UpdateCourseGroupIds) =>
      mergeDeep(state, { edition: { groupIds: payload.groupsIds } }),
    updateCourseInList: (state, { payload }: UpdateCourseInListAction) => {
      const courseIndex = state.list.items.findIndex(({ course }) => course.id === payload.id);
      // eslint-disable-next-line no-param-reassign
      state.list.items[courseIndex] = {
        ...state.list.items[courseIndex],
        course: { ...state.list.items[courseIndex].course, ...payload },
      };
      return state;
    },
    setRevision: (state, { payload }: SetRevisionAction) => mergeDeep(state, { edition: { revision: payload } }),
    setMkList: (state, { payload }: SetListAction) => ({
      ...state,
      edition: {
        ...state.edition,
        microKnowledges: payload,
      },
    }),
    editMkItem: (state, { payload }: EditMkItemAction) => ({
      ...state,
      edition: {
        ...state.edition,
        microKnowledges: {
          ...state.edition.microKnowledges,
          items: {
            ...state.edition.microKnowledges.items,
            [payload.id]: payload,
          },
        },
      },
    }),
    toggleChapterCollapse: (state, { payload: { id } }: ToggleChapterAction) => {
      const chapter = state.edition.microKnowledges.items[id] as MicroKnowledgeChapter;

      return mergeDeep(state, {
        edition: {
          microKnowledges: {
            items: {
              [id]: {
                isCollapsed: !chapter.isCollapsed,
              },
            },
          },
        },
      });
    },
    setAskForConfirmationPreference: (state, { payload }: SetAskForConfirmationPreference) => ({
      ...state,
      askConfirmationPreferenceMapping: {
        ...state.askConfirmationPreferenceMapping,
        [payload.courseId]: payload.shouldAsk,
      },
    }),
    setFirstCourseCreated: (state, { payload }: SetFirstCourseCreated) =>
      mergeDeep(state, { firstCourseCreated: payload.isCreated }),
    updateFilters: (state, { payload }: UpdateFilterAction) =>
      mergeDeep(state, {
        list: {
          filters: {
            query: {
              title: payload.courseTitle !== '' ? payload.courseTitle || state.list.filters.query.title : null,
              status: payload.status || state.list.filters.query.status,
              audienceGroupId: payload.audienceGroupId || state.list.filters.query.audienceGroupId,
              languageCode: payload.languageCode || state.list.filters.query.languageCode,
            },
          },
        },
      }),

    setHasEditionIdMismatchError: (state, { payload: { hasEditionIdMismatchError } }: SetHasEditionIdMismatchError) =>
      mergeDeep(state, { errors: { hasEditionIdMismatchError } }),
    setHasEditionError: (state, { payload: { hasEditionError } }: SetHasEditionError) =>
      mergeDeep(state, { errors: { hasEditionError } }),
    setDraftStatus: (state, { payload: { draft } }: SetDraftStatus) =>
      mergeDeep(state, {
        edition: {
          course: {
            statuses: { draft },
          },
        },
      }),
    savePdfPages: (state, { payload: { id, pages } }: SavePdfPagesAction) =>
      mergeDeep(state, {
        edition: {
          pdfPages: pages,
          pdfIds: [...state.edition.pdfIds, id],
        },
      }),
  },
  extraReducers: (builder) => {
    builder.addCase(createCourse.pending, (state) =>
      mergeDeep(state, {
        errors: { courseFormError: undefined },
      }),
    );
    builder.addCase(createCourse.fulfilled, (state, { payload: { course, revision }, meta }) => {
      const askConfirmationPreferenceMapping = {
        ...state.askConfirmationPreferenceMapping,
        [course.id]: true,
      };

      const newState = mergeDeep(state, {
        edition: {
          course: {
            id: course.id,
            dataId: course.dataId,
            title: course.title,
            language: course.language,
            description: course.description ?? undefined,
            coverImageUrl: course.coverImageUrl ?? undefined,
            statuses: {
              draft: true,
              published: false,
            },
          },
          groupIds: course.groupIds,
          loading: false,
          revision: {
            editionId: revision.editionId,
          },
          editors: [
            {
              lastEditedDate: new Date().toISOString(),
              lastAccessDate: new Date().toISOString(),
              id: meta.arg.currentUser.id,
              lastName: meta.arg.currentUser.lastName,
              firstName: meta.arg.currentUser.firstName,
            },
          ],
        },
      });

      return {
        ...newState,
        askConfirmationPreferenceMapping,
      };
    });
    builder.addCase(createCourse.rejected, (state, action) =>
      mergeDeep(state, {
        errors: { courseFormError: getErrorMessage(action) },
      }),
    );
    builder.addCase(fetchFilteredCourses.pending, (state) =>
      mergeDeep(state, {
        list: {
          items: [],
          loading: true,
          nextPage: null,
        },
      }),
    );
    builder.addCase(fetchFilteredCourses.fulfilled, (state, { payload }) => {
      const { firstCourseCreated } = state;
      const updatedFirstCourseCreated = firstCourseCreated === null && payload.total !== 0;

      return mergeDeep(state, {
        firstCourseCreated: firstCourseCreated || updatedFirstCourseCreated,
        list: {
          items: mapCoursesResponseToState(payload),
          nextPage: payload.next,
          loading: false,
        },
      });
    });
    builder.addCase(fetchFilteredCourses.rejected, (state) =>
      mergeDeep(state, {
        list: {
          items: [],
          loading: false,
        },
      }),
    );
    builder.addCase(fetchNextCoursePage.pending, (state) =>
      mergeDeep(state, {
        list: {
          loading: true,
        },
      }),
    );
    builder.addCase(fetchNextCoursePage.fulfilled, (state, { payload }) =>
      mergeDeep(state, {
        list: {
          items: state.list.items.concat(mapCoursesResponseToState(payload)),
          loading: false,
          nextPage: payload.next,
        },
      }),
    );
    builder.addCase(
      sendCommands.fulfilled,
      (
        state,
        {
          payload: {
            revision: { editionId },
          },
          meta,
        },
      ) => {
        const { context, id } = meta.arg;
        if (context === 'list') {
          const oldCourseIndex = state.list.items.findIndex((item) => item.course.id === id);
          const beforeStateList = state.list.items.slice(0, oldCourseIndex);
          const afterStateList = state.list.items.slice(oldCourseIndex + 1);
          const oldCourse = state.list.items[oldCourseIndex];
          const newCourse = { ...oldCourse, revision: { editionId } };
          return { ...state, list: { ...state.list, items: [...beforeStateList, newCourse, ...afterStateList] } };
        }

        return mergeDeep(state, {
          edition: { revision: { editionId } },
        });
      },
    );
    builder.addCase(fetchDraftCourse.pending, (state) =>
      mergeDeep(state, {
        edition: {
          loading: true,
        },
      }),
    );
    builder.addCase(
      fetchDraftCourse.fulfilled,
      (state, { payload: { course, groupIds, revision, microKnowledges, editors, pdfIds } }) => {
        const list = computeMetaData(microKnowledges);
        const askConfirmationPreferenceMapping = {
          ...state.askConfirmationPreferenceMapping,
          [course.id]: state.askConfirmationPreferenceMapping[course.id] || true,
        };

        const newState = mergeDeep(state, {
          edition: {
            course: {
              id: course.id,
              dataId: course.dataId,
              title: course.title,
              language: course.language,
              description: course.description ?? undefined,
              coverImageUrl: course.coverImage ? course.coverImage.url : undefined,
              statuses: course.statuses,
              lastPublicationDate: course.lastPublicationDate,
              started: course.started,
            },
            revision: {
              editionId: revision.editionId,
            },
            editors,
            groupIds,
            loading: false,
            pdfIds: pdfIds ?? [],
          },
        });

        return {
          ...newState,
          askConfirmationPreferenceMapping,
          edition: {
            ...newState.edition,
            microKnowledges: list,
          },
        };
      },
    );
    builder.addCase(fetchDraftCourse.rejected, (state) =>
      mergeDeep(state, {
        edition: {
          loading: false,
        },
      }),
    );
    builder.addCase(updateCoursePublishStatus.fulfilled, (state, { payload }) => {
      const { edition, list } = state;
      const { id: courseId, statuses, revision } = payload;

      const updatedEditionState =
        edition.course?.id === courseId
          ? mergeDeep(state, {
              edition: {
                course: { statuses },
                revision: {
                  editionId: revision.editionId,
                },
              },
            })
          : state;

      const { items } = list;

      const newItems = items.map((item) => {
        const { course } = item;

        if (course.id !== courseId) {
          return item;
        }

        return mergeDeep(item, {
          course: { statuses },
          revision: {
            editionId: revision.editionId,
          },
        });
      });

      return mergeDeep(updatedEditionState, {
        list: {
          items: newItems,
        },
      });
    });
    builder.addCase(fetchAudienceMetadata.fulfilled, (state, { payload }) =>
      mergeDeep(state, {
        edition: {
          numberTargetedPlayers: payload.numberOfPlayers,
        },
      }),
    );
    builder.addCase(fetchPdf.pending, (state) => {
      return mergeDeep(state, {
        edition: {
          loading: true,
        },
      });
    });
    builder.addCase(fetchPdf.fulfilled, (state, { payload: { pdfs } }) => {
      return mergeDeep(state, {
        edition: {
          pdfPages: pdfs?.[0]?.pages || state.edition.pdfPages,
          loading: false,
        },
      });
    });
    builder.addCase(fetchPdf.rejected, (state) => {
      return mergeDeep(state, {
        edition: {
          loading: false,
        },
      });
    });
  },
});

export const { actions, reducer } = slice;
