import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    borderRadius: 4,
  },
  input: {
    minWidth: 40,
    resize: 'none',
    backgroundColor: 'transparent',
  },
});

export default styleSheet;
