import { apiAction } from 'Libs/redux/utils';
import type { ApiActionType } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/filter/types';
import type { AlertScope, AlertParams } from './models/Alert';

type LeaderboardGetRankedLeaderboardAction = ApiActionType<'LEADERBOARD_GET_RANKED_LEADERBOARD'>;
type LeaderboardGetCount = ApiActionType<'LEADERBOARD_GET_COUNT'>;
type LeaderboardGetExport = ApiActionType<'LEADERBOARD_GET_EXPORT'>;
type LeaderboardUpdateFilters = {
  type: 'LEADERBOARD_UPDATE_FILTERS';
  selectedFilters: Array<FilterState>;
};

type LeaderboardSetAlertAction = {
  type: 'LEADERBOARD_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};
type LeaderboardRemoveAlertAction = {
  type: 'LEADERBOARD_REMOVE_ALERT';
  scope: AlertScope;
};

export const actions = {
  getRankedLeaderboard: (
    activityIds: ReadonlyArray<number>,
    options: {
      segmentation: ReadonlyArray<number>;
      after: number;
      query: ReadonlyArray<string>;
    },
  ): LeaderboardGetRankedLeaderboardAction =>
    apiAction({
      type: 'LEADERBOARD_GET_RANKED_LEADERBOARD',
      route: '/api/Leaderboards/ranked',
      method: 'GET',
      query: {
        activityIds,
        options,
      },
    }),

  // eslint-disable-next-line @typescript-eslint/ban-types
  getLeaderboardCount: (activityIds: Array<number>, options: Object): LeaderboardGetCount =>
    apiAction({
      type: 'LEADERBOARD_GET_COUNT',
      route: '/api/Leaderboards/count',
      method: 'GET',
      query: {
        activityIds,
        options,
      },
    }),

  setError: (scope: AlertScope, params: AlertParams = Object.freeze({})): LeaderboardSetAlertAction => ({
    type: 'LEADERBOARD_SET_ALERT',
    scope,
    params,
  }),

  removeAlert: (scope: AlertScope): LeaderboardRemoveAlertAction => ({
    type: 'LEADERBOARD_REMOVE_ALERT',
    scope,
  }),

  // eslint-disable-next-line @typescript-eslint/ban-types
  getLeaderboardExport: (options?: Object): LeaderboardGetExport =>
    apiAction({
      type: 'LEADERBOARD_GET_EXPORT',
      route: '/api/Leaderboards/export',
      method: 'GET',
      query: {
        options,
        responseType: 'arraybuffer',
      },
    }),

  updateFilters: (selectedFilters: Array<FilterState>): LeaderboardUpdateFilters => ({
    type: 'LEADERBOARD_UPDATE_FILTERS',
    selectedFilters,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type LeaderboardAction = ReturnType<ActionsFn>;
