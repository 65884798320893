// @flow

/**
 * Display a progress bar
 *
 * Props:
 *  - percent: percent of the progress bar filled
 *  - label: optional label
 *  - style: override component's style
 */

import React, { memo } from 'react';

import styles from './UProgressBar.style';


type Props = {|
  percent: number,
  label?: string,
  style?: { [key: string]: any },
|};

export function UProgressBar({ percent, label, style }: Props) {
  const widthProgress = { width: `${percent}%` };

  return (
    <div style={style}>
      {label ? (
        <div style={styles.label}>
          {label}
        </div>
      ) : null}

      <div style={styles.wrapperProgress}>
        <div style={styles.containerProgress}>
          <div style={styles.progressBackground}>
            <div style={{ ...styles.progress, ...widthProgress }} />
          </div>
        </div>
        <div style={styles.textPercent}>
          {`${percent}%`}
        </div>
      </div>
    </div>
  );
}

UProgressBar.defaultProps = {
  label: '',
  style: {},
};

export default memo<Props>(UProgressBar);
