import { mergeDeep } from 'Libs/mergeDeep';

export type DisabledDayType = Readonly<{
  key: number;
  date: string | null;
}>;

export default function createDisabledDay(values?: any): DisabledDayType {
  const defaultState: DisabledDayType = {
    key: 0,
    date: null,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep(defaultState, values);
}
