// @flow

import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/flowTypes';

import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import STable from 'Components/structural/STable/STable';

import { useModuleSelector, actions } from '../../redux';
import styles from './LeaderboardView.style';

const RANGE = 20;

export const LeaderboardView: React$ComponentType<{||}> = () => {
  const cancelGetRankedLeaderboard = useRef(() => {});
  const cancelGetCount = useRef(() => {});
  const rankedLeaderboard = useModuleSelector((state) => state.rankedLeaderboard);
  const isGetRankedLeaderboardLoading = useModuleSelector((state) => state.isGetRankedLeaderboardLoading);
  const leaderboardCount = useModuleSelector((state) => state.leaderboardCount);
  const selectedSegmentationIds = useModuleSelector((state) => state.selectedSegmentationIds);
  const selectedCampaignIds = useModuleSelector((state) => state.selectedCampaignIds);
  const selectedTextValues = useModuleSelector((state) => state.selectedTextValues);
  const isFiltering = useModuleSelector((state) => state.isFiltering);
  const dispatch = useTypedDispatch();

  const [tableMessage, setTableMessage] = useState<{| type: string, message: string |} | null>(null);
  const [showMoreError, setShowMoreError] = useState('');

  const filters = useMemo(
    () => [
      { category: 'segment', ids: [...selectedSegmentationIds] },
      { category: 'campaign', ids: [...selectedCampaignIds] },
      { category: 'text', values: [...selectedTextValues] },
    ],
    [selectedCampaignIds, selectedSegmentationIds, selectedTextValues],
  );

  const getAllInfos = useCallback(async () => {
    cancelGetRankedLeaderboard.current();
    cancelGetCount.current();

    const actionGetRankedLeaderboard = actions.getRankedLeaderboard([...selectedCampaignIds], {
      segmentation: selectedSegmentationIds,
      after: RANGE,
      query: [...selectedTextValues],
    });
    const actionGetCount = actions.getLeaderboardCount([...selectedCampaignIds], {
      segmentation: selectedSegmentationIds,
      query: [...selectedTextValues],
    });

    cancelGetRankedLeaderboard.current = actionGetRankedLeaderboard.cancel;
    cancelGetCount.current = actionGetCount.cancel;

    dispatch(actionGetCount);
    const result = await dispatch(actionGetRankedLeaderboard);

    if (result.error)
      setTableMessage({
        type: 'error',
        message: t('analytics:leaderboard.leaderboard_view.an_error_has_occured'),
      });
  }, [dispatch, selectedSegmentationIds, selectedCampaignIds, selectedTextValues, t]);

  useEffect(() => {
    getAllInfos();

    return () => {
      cancelGetRankedLeaderboard.current();
      cancelGetCount.current();
    };
  }, [getAllInfos]);

  const onFilterBoxChange = useCallback(
    (selectedFilters: Array<FilterState>) => {
      dispatch(actions.updateFilters(selectedFilters));
    },
    [dispatch],
  );

  const handleShowMore = useCallback(async () => {
    const result = await dispatch(
      actions.getRankedLeaderboard([...selectedCampaignIds], {
        segmentation: selectedSegmentationIds,
        after: rankedLeaderboard.length + RANGE,
        query: [...selectedTextValues],
      }),
    );

    if (result.error) setShowMoreError(t('analytics:leaderboard.leaderboard_view.an_error_has_occured'));
  }, [rankedLeaderboard, dispatch, selectedSegmentationIds, selectedTextValues, selectedCampaignIds]);

  const handleExportLeaderboard = useCallback(() => {
    return dispatch(
      actions.getLeaderboardExport({
        text: selectedTextValues,
        segmentation: selectedSegmentationIds,
        activityIds: selectedCampaignIds,
      }),
    );
  }, [dispatch, selectedTextValues, selectedCampaignIds, selectedSegmentationIds, t]);

  const columns = useMemo(
    () => [
      {
        id: '0',
        type: 'UTableCellHeader',
        headerParams: {
          text: t('analytics:leaderboard.leaderboard_view.rank'),
          align: 'center',
        },
        width: '50px',
        disableSorting: true,
        accessor: 'rank',
      },
      {
        id: '1',
        type: 'EmptyHeader',
        width: '44px',
        disableSorting: true,
        accessor: 'profilePicture',
      },
      {
        id: '2',
        type: 'UTableCellHeader',
        headerParams: {
          text: t('analytics:leaderboard.leaderboard_view.name'),
          align: 'left',
        },
        disableSorting: true,
        accessor: 'name',
      },
      {
        id: '3',
        type: 'UTableCellHeader',
        headerParams: {
          text: t('analytics:leaderboard.leaderboard_view.score'),
          align: 'center',
        },
        disableSorting: true,
        width: '100px',
        accessor: 'score',
      },
    ],
    [t],
  );

  const data = useMemo(
    () =>
      rankedLeaderboard.map(({ id, rank, firstName, lastName, score, profilePicture }) => ({
        id: id,
        dataAccessor: null,
        rank: {
          id: `${id}_rank`,
          type: 'UTableCellText',
          params: {
            value: rank,
            style: {
              justifyContent: 'center',
            },
            textStyle: {
              fontWeight: 600,
            },
          },
        },
        profilePicture: {
          id: `${id}_profilePicture`,
          type: 'UTableCellProfilePicture',
          params: {
            picture: profilePicture.url,
          },
          wrapperStyle: {
            display: 'flex',
            flex: 1,
          },
        },
        name: {
          id: `${id}_name`,
          type: 'UTableCellUser',
          params: {
            name: `${firstName || ''} ${lastName || ''}`.trim(),
          },
        },
        score: {
          id: `${id}_score`,
          type: 'UTableCellText',
          params: {
            value: score,
            style: {
              justifyContent: 'center',
            },
          },
        },
      })),
    [rankedLeaderboard],
  );

  const countDescription = useMemo(() => {
    if (data.length === 0 && !isFiltering) return '';

    return t('analytics:leaderboard.leaderboard_view.player', { count: leaderboardCount });
  }, [leaderboardCount, isFiltering, data.length, t]);

  const tableActions = useMemo(
    () => [
      {
        type: 'EXPORT',
        id: 'table-action-1',
        params: {
          text: t('analytics:leaderboard.leaderboard_view.export_leaderboard'),
          leftIcon: 'download',
          onAction: handleExportLeaderboard,
          trackingAction: 'export',
          trackingContext: 'leaderboard',
        },
      },
    ],
    [handleExportLeaderboard, t],
  );

  return (
    <div style={styles.wrapper}>
      <SFilterBox
        count={leaderboardCount}
        filters={filters}
        onChange={onFilterBoxChange}
        placeholder={t('analytics:leaderboard.leaderboard_view.placeholder')}
        title={t('analytics:leaderboard.leaderboard_view.title_analytics')}
        description={countDescription}
      />
      <div style={styles.tableContainer}>
        <div style={styles.tableWrapper}>
          <STable
            data={data}
            total={leaderboardCount}
            columns={columns}
            isTableLoading={data.length === 0 && isGetRankedLeaderboardLoading}
            onShowMoreClicked={handleShowMore}
            range={RANGE}
            showMoreError={showMoreError}
            tableMessage={tableMessage}
            tableActions={tableActions}
            title={t('analytics:leaderboard.leaderboard_view.title_leaderboard')}
            overrideContentLoading={data.length > 0 && isGetRankedLeaderboardLoading}
          />
        </div>
      </div>
      <div style={styles.bottomSpace} />
    </div>
  );
};
