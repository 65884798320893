/* eslint-disable flowtype/require-types-at-top */
/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Displays an indicator
 *
 * Props:
 * - text: the text of the indicator
 * - mode: the indicator mode (@see mode)
 */


import * as React from 'react';

import { COLORS } from 'Components/foundation';
import styles from './UIndicator.style';

const MODE = Object.freeze({
  ACCENTUATED           : 'accentuated',
  CONTRASTED            : 'contrasted',
  DARK                  : 'dark',
  DISABLED              : 'disabled',
  ENABLED               : 'enabled',
});

const STYLE_MODE = {
  [MODE.ACCENTUATED] : {
    backgroundColor: COLORS.PRIMARY.DEFAULT,
    color: COLORS.WHITE.DEFAULT,
  },
  [MODE.CONTRASTED] : {
    backgroundColor: COLORS.WHITE.DEFAULT,
    color: COLORS.PRIMARY.DEFAULT,
  },
  [MODE.DARK] : {
    backgroundColor: COLORS.BLACK.DEFAULT,
    color: COLORS.WHITE.DEFAULT,
  },
  [MODE.DISABLED] : {
    backgroundColor: COLORS.ERROR.DEFAULT,
    color: COLORS.WHITE.DEFAULT,
  },
  [MODE.ENABLED] : {
    backgroundColor: COLORS.SUCCESS.DEFAULT,
    color: COLORS.WHITE.DEFAULT,
  },
};

type Mode = $Values<typeof MODE>;

type Props = {|

  // Required
  text: string,

  // Optional
  mode: Mode,
  style: ?Object,
|};

export class UIndicator extends React.PureComponent<Props> {
  static defaultProps = {
    mode: MODE.ACCENTUATED,
    style: undefined,
  };

  render() {
    const { text, mode, style: propStyle } = this.props;

    let style = STYLE_MODE[mode];

    return (
      <span style={{ ...styles.wrapper, ...style, ...propStyle }}>
        {text}
      </span>
    );
  }
}

export default UIndicator;
