import React, { useState, useMemo } from 'react';
import { t } from 'i18next';
import { GameType } from '@sparted/shared-library/business/types';

import STooltip from 'Components/structural/STooltip/STooltip';
import styleSheet, { iconStyles } from './UIconTab.style';
import UIcon from '../UIcon/UIcon';

export type UIconTabProps = {
  selected: boolean;
  locked?: boolean;
  onClick: () => void;
  iconType: GameType;
};

const tooltipLabelMap = {
  mcq: t('unit_components:u_icon_tab.mcq'),
  'error-text': t('unit_components:u_icon_tab.error_text'),
  'no-interaction': t('unit_components:u_icon_tab.no_interaction_content'),
  'open-question': t('unit_components:u_icon_tab.open_question'),
  'ordering-cards': t('unit_components:u_icon_tab.ordering_cards'),
  'picture-spot': t('unit_components:u_icon_tab.picture_spot'),
  'swiping-cards': t('unit_components:u_icon_tab.swiping_cards'),
};

const tooltipId = 'uicontab-tooltip';

const UIconTab = ({
  selected,
  locked = false,
  onClick,
  iconType,
}: UIconTabProps & { hover?: boolean; setHover?: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [hover, setHover] = useState(false);

  const containerStyle = useMemo(() => {
    const iconStyle = iconStyles[iconType] || {};

    if (locked) {
      return {
        ...styleSheet.container,
        ...iconStyle.selected,
      };
    }

    if (selected) {
      return {
        ...styleSheet.container,
        ...iconStyle.selected,
      };
    }

    if (hover) {
      return {
        ...styleSheet.container,
        ...iconStyle.hover,
      };
    }

    return {
      ...styleSheet.container,
      ...styleSheet.rest,
    };
  }, [hover, selected, locked, iconStyles, iconType]);

  return (
    <>
      {!locked && <STooltip id={tooltipId} position="top" content={tooltipLabelMap[iconType]} delay={250} />}
      <div
        style={containerStyle}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
        data-for={tooltipId}
        data-tip
      >
        <UIcon name={iconType} size={40} />
      </div>
    </>
  );
};

export default UIconTab;
