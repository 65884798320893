import { createSlice } from '@reduxjs/toolkit';

import { fetchAudienceGroups } from 'Features/audience/stores/thunks/audience-group.thunks';
import type { AudienceGroupResponse } from 'Features/audience/stores/thunks/audience-group.thunks';

import { audienceGroupAdaptater } from './audience-group.adapters';
import type { AudienceGroupState } from './audience-group.types';

export const audienceGroupState: AudienceGroupState = audienceGroupAdaptater.getInitialState();

const audienceGroupMapper = (item: AudienceGroupResponse) => ({
  groupId: item.id,
  name: item.name,
  description: item.description,
  segmentations: item.rules
    .map((rule) =>
      rule.segmentationGroupItems.map((seg) => ({
        id: seg.id,
        label: seg.label,
        color: seg.color,
      })),
    )
    .flat(),
  meta: {
    currentAudience: item.metadata.currentAudience,
    maxAudience: item.metadata.maxAudience,
  },
});

export const { actions, reducer } = createSlice({
  name: 'audienceGroups',
  initialState: audienceGroupState,
  reducers: {
    deleteAudienceGroup: audienceGroupAdaptater.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAudienceGroups.fulfilled, (state, payload) => {
      const {
        payload: { items, offset },
      } = payload;
      return offset === 0
        ? audienceGroupAdaptater.setAll(state, items.map(audienceGroupMapper))
        : audienceGroupAdaptater.setMany(state, items.map(audienceGroupMapper));
    });
  },
});
