import React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import Enum from 'Models/Enum';
import { LanguagesLikesViews } from './LanguagesLikesViews';
import { Target } from './Target';
import { AudienceType, ActivityType, ActivityStatusType } from '../SActivityListItem.types';

import styles from '../SActivityListItem.style';

type FooterProps = {
  audience: AudienceType | null;
  audiencePlaceholder: string;
  audienceEmptySegmentsLabel: string;
  languages: ReadonlyArray<string>;
  startDate: string;
  endDate: string;
  likes?: number;
  views?: number;
  activityType: ActivityType;
  activityStatus: ActivityStatusType;
};

export const Footer = ({
  activityType,
  activityStatus,
  startDate,
  endDate,
  languages,
  audience,
  audiencePlaceholder,
  audienceEmptySegmentsLabel,
  likes,
  views,
}: FooterProps) => (
  <div style={styles.containerFooter}>
    <div style={styles.containerFooterLeft}>
      {(activityType === Enum.activityTypeEnum.DailySerie || activityType === Enum.activityTypeEnum.BREAKING_NEWS) && (
        <div style={styles.containerCalendar} data-test-id="calendar-section">
          <UIcon name="calendar" size={16} color={COLORS.GREY_DARK.DEFAULT} style={styles.iconFooter} />
          {activityStatus === 'Draft' ? (
            '-- / -- / --'
          ) : (
            <>
              {startDate}
              <UIcon name="arrow-right" size={10} color={COLORS.GREY_DARK.DEFAULT} style={styles.iconArrow} />
              {endDate}
            </>
          )}
        </div>
      )}
      <Target
        audience={audience}
        audiencePlaceholder={audiencePlaceholder}
        audienceEmptySegmentsLabel={audienceEmptySegmentsLabel}
      />
    </div>
    <LanguagesLikesViews
      languages={languages}
      activityStatus={activityStatus}
      likes={likes}
      views={views}
      activityType={activityType}
    />
  </div>
);
