import { StyleSheet } from 'aphrodite';

import { ANIMATIONS } from 'Components/foundation';

const {
  MOVEMENTS: { APPEAR_FROM_BOTTOM: translateYKeyFrame },
  TRANSFORMATIONS: { FADE_IN: fadeInKeyFrame },
} = ANIMATIONS;

export default StyleSheet.create({
  listWrapper: {
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    zIndex: 10001,
    top: 64,
    right: 16,
  },
  containerAlert: {
    maxHeight: 200,
    marginBottom: 10,
  },

  // Animation
  containerAlertIn: {
    animationName: [fadeInKeyFrame, translateYKeyFrame],
    animationDuration: ANIMATIONS.SPEED.SLOW,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },

  containerAlertOut: {
    opacity: 0,
    marginBottom: 0,
    maxHeight: 0,
    bottom: 0,
    transition: ANIMATIONS.SPEED.SLOW,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },
});
