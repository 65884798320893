// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  container: {
    marginBottom: 56,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
    marginBottom: 6,
  },
  subtitle: {
    ...TYPOGRAPHY.BODY4,
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 27,
  },
  instruction: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  instructionText: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  iconWrapper: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
  },
};
