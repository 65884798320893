import { getCdnFromS3Url } from 'Libs/cdn';
import { mergeDeep } from 'Libs/mergeDeep';

export type PlayerType = Readonly<{
  firstName: string;
  lastName: string;
  email: string;
  isValidated: boolean;
  profilePicture: {
    url: string;
  };
}>;

export default function createPlayer(values?: any): PlayerType {
  const defaultState: PlayerType = {
    firstName: '',
    lastName: '',
    email: '',
    isValidated: false,
    profilePicture: {
      url: '',
    },
  };

  if (!values) {
    return defaultState;
  }

  const isValidated = values.status === 'active';
  const profilePicture = {
    url:
      values.profilePicture && values.profilePicture.url
        ? getCdnFromS3Url(values.profilePicture.url, { asThumbnail: true })
        : '',
  };

  return mergeDeep(defaultState, { ...values, isValidated, profilePicture });
}
