import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import STable, { TableAction } from 'Components/structural/STable/STable';
import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import { Header, HeaderType, TABLE_ACTION_TYPES, TableMessage } from 'Components/structural/STable/types';
import { FilterState } from 'Libs/filter/types';

import { actions, useModuleSelector } from '../redux';
import { PlayersManageModal } from './components/PlayersManageModal';
import styles from './PlayersView.style';

const CROPPED_DATE_LENGTH = 10;
const TRUNCATED_LIMIT = 12;
const PAGINATION_STEP = 50;

let options = {
  where: {
    and: [{ realm: 'FrontOffice' }, { deleted: false }, { active: true }],
  },
  include: [
    {
      relation: 'segmentation',
      scope: {
        include: ['dimension', 'members'],
      },
    },
    'adminValidation',
    'profilePicture',
  ],
  order: 'id desc',
  segmentation: [],
};

export const PlayersView = () => {
  const dispatch = useTypedDispatch();
  const cancelGetAll = useRef(() => {});
  const cancelGetCount = useRef(() => {});
  const players = useModuleSelector((state) => state.players);
  const allDisplayedPlayers = useModuleSelector((state) => state.allDisplayedPlayers);
  const isLoading = useModuleSelector((state) => state.isLoading);
  const playersCount = useModuleSelector((state) => state.playersCount);
  const [tableMessage, setTableMessage] = useState<TableMessage>();
  const [showMoreError, setShowMoreError] = useState('');
  const selectedSegmentationIds = useModuleSelector((state) => state.selectedSegmentationIds);
  const selectedTextValues = useModuleSelector((state) => state.selectedTextValues);
  const isFiltering = useModuleSelector((state) => state.isFiltering);
  const filters: FilterState[] = useMemo(
    () => [
      { category: 'segment', ids: [...selectedSegmentationIds] },
      { category: 'text', values: [...selectedTextValues] },
    ],
    [selectedSegmentationIds, selectedTextValues],
  );

  const order = useRef<object>({});

  const onFilterBoxChange = useCallback(
    (selectedFilters) => {
      dispatch(actions.updateFilters(selectedFilters));
    },
    [dispatch],
  );

  const concatOrderObject = () => {
    const concat = Object.keys(order.current);

    let str = '';

    concat.map((val) => {
      // @ts-ignore
      str += val + ' ' + order.current[val] + ', ';
    });

    return str + 'id desc';
  };

  const setOptionsObject = useCallback(
    (showAllPlayers) => {
      const optionsRefresh = {
        where: {
          and: [{ realm: 'FrontOffice' }, { deleted: false }, showAllPlayers],
        },
        include: [
          {
            relation: 'segmentation',
            scope: {
              include: ['dimension', 'members'],
            },
          },
          'adminValidation',
          'profilePicture',
        ],
        order: !order.current ? 'id desc' : concatOrderObject(),
        segmentation: [],
      };

      options = { ...optionsRefresh };

      if (selectedTextValues && selectedTextValues.length) {
        options.where.and.push({
          // @ts-ignore
          or: selectedTextValues.map((inputText) => {
            return {
              or: [
                {
                  firstName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  lastName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  email: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  workLocation: {
                    regexp: '/' + inputText + '/i',
                  },
                },
              ],
            };
          }),
        });
      }
      if (selectedSegmentationIds && selectedSegmentationIds.length) {
        // @ts-ignore
        options.segmentation = selectedSegmentationIds.map((id) => ({ id }));
      }
    },
    [selectedSegmentationIds, selectedTextValues],
  );

  const getAll = useCallback(
    async (start: number, length: number, isShowMore: boolean, isDisplayAll: boolean) => {
      const shouldDisplay = isDisplayAll ? !allDisplayedPlayers : allDisplayedPlayers;
      const showAllPlayers = shouldDisplay ? { active: true } : undefined;

      setOptionsObject(showAllPlayers);

      cancelGetAll.current();
      cancelGetCount.current();

      setTableMessage(undefined);
      setShowMoreError('');

      const firstAction = actions.fetchPlayers(options, start, length, isShowMore, shouldDisplay, isDisplayAll);
      const secondAction = actions.getPlayersCount(options);

      cancelGetAll.current = firstAction.cancel;
      cancelGetCount.current = secondAction.cancel;

      dispatch(secondAction);
      const firstResult = await dispatch(firstAction);

      // @ts-ignore
      if (firstResult.error) {
        if (isShowMore) setShowMoreError(t('config_players:players.players_view.show_more_error'));
        else setTableMessage({ type: 'error', message: t('config_players:players.players_view.show_more_error') });
      }
    },
    [dispatch, setOptionsObject, allDisplayedPlayers, t],
  );

  useEffect(() => {
    getAll(0, PAGINATION_STEP, false, false);

    return () => {
      cancelGetAll.current();
      cancelGetCount.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegmentationIds, selectedTextValues]);

  const handleDisplayAll = useCallback(() => {
    return getAll(0, PAGINATION_STEP, false, true);
  }, [getAll]);

  const handleSort = useCallback(
    (column: string, columnOrder: string) => {
      if (columnOrder === 'none') {
        // @ts-ignore
        delete order.current[column];
      } else {
        // @ts-ignore
        order.current[column] = columnOrder;
      }

      return getAll(0, PAGINATION_STEP, false, false);
    },
    [getAll],
  );

  const handleShowMore = useCallback(() => {
    return getAll(players.length, PAGINATION_STEP, true, false);
  }, [getAll, players]);

  const columns: Header[] = useMemo(() => {
    const coreColumns: Header[] = [
      {
        id: '0',
        type: HeaderType.EmptyHeader,
        width: '44px',
        disableSorting: true,
        accessor: 'profilePicture',
      },
      {
        id: '1',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_players:players.players_view.column.name'),
          align: 'left',
          onAction: handleSort,
        },
        width: '175px',
        disableResizing: true,
        accessor: 'firstName',
      },
      {
        id: '2',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_players:players.players_view.column.pseudo'),
          align: 'left',
          onAction: handleSort,
        },
        width: '104px',
        disableResizing: true,
        accessor: 'extraInfo',
      },
      {
        id: '3',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_players:players.players_view.column.score'),
          align: 'left',
          onAction: handleSort,
        },
        width: '104px',
        disableResizing: true,
        accessor: 'globalScore',
      },
      {
        id: '4',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_players:players.players_view.column.sign_up'),
          align: 'left',
          onAction: handleSort,
        },
        width: '104px',
        disableResizing: true,
        accessor: 'createdAt',
      },
      {
        id: '5',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_players:players.players_view.column.status'),
          align: 'left',
          onAction: handleSort,
        },
        width: '104px',
        disableResizing: true,
        accessor: 'status',
      },
    ];

    return coreColumns;
  }, [handleSort]);

  const getLocaleScore = (score: any) => {
    return Number(score).toLocaleString('en').toString();
  };

  const getCroppedDate = (createdAt: string) => {
    return createdAt.substr(0, CROPPED_DATE_LENGTH);
  };

  const getTruncatedString = (string: string) => {
    if (!string) return '';

    return string !== null && string.length > TRUNCATED_LIMIT ? string.substr(0, TRUNCATED_LIMIT) + '...' : string;
  };

  const getValueForSorting = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    return `${firstName ?? ''} ${lastName ?? ''}`.trim() || '-';
  };

  const modifyStatusText = (active: boolean) => {
    if (active) return t('config_players:players.players_view.column.players_activated');

    return t('config_players:players.players_view.column.players_deactivated');
  };

  const handleEditPlayer = useCallback(
    ({ data: { player } }) => {
      const openModalConfig = { isManageModalOpen: true };

      return dispatch(actions.togglePlayerModal(openModalConfig, { ...player }));
    },
    [dispatch],
  );

  const data = useMemo(
    () =>
      players.map((player) => {
        const { id, firstName, globalScore, lastName, active, createdAt, extraInfo } = player;
        const callbackOptions = [
          {
            icon: 'edit',
            id: 'action_edit_id',
            callback: handleEditPlayer,
          },
        ];

        return {
          id,
          dataAccessor: { player },
          profilePicture: {
            id: `${id}_profilePicture`,
            type: 'UTableCellProfilePicture',
            params: {
              picture: player.profilePicture ? player.profilePicture.url : null,
            },
            wrapperStyle: {
              display: 'flex',
              flex: 1,
            },
          },
          firstName: {
            id: `${id}_name`,
            type: 'UTableCellText',
            params: {
              value: getValueForSorting({ firstName, lastName }),
              type: 'text',
              editable: false,
            },
          },
          extraInfo: {
            id: `${id}_pseudo`,
            type: 'UTableCellText',
            params: {
              value: getTruncatedString(extraInfo),
              type: 'text',
              editable: false,
            },
          },
          globalScore: {
            id: `${id}_globalScore`,
            type: 'UTableCellText',
            params: {
              value: getLocaleScore(globalScore),
              type: 'text',
            },
          },
          createdAt: {
            id: `${id}_createdAt`,
            type: 'UTableCellText',
            params: {
              value: getCroppedDate(createdAt),
              type: 'text',
            },
          },
          status: {
            id: `${id}_status`,
            type: 'UTableCellText',
            params: {
              value: getTruncatedString(modifyStatusText(active)),
              type: 'text',
              editable: false,
            },
          },
          callbackOptions,
        };
      }),
    [players, handleEditPlayer],
  );

  const getCountDescription = useMemo(() => {
    if (data.length === 0 && !isFiltering) return '';

    return t('config_players:players.players_view.players_count', { count: playersCount });
  }, [playersCount, isFiltering, data.length, t]);

  const handleExportPlayers = useCallback(() => {
    const showAllPlayers = allDisplayedPlayers ? { active: true } : undefined;

    setOptionsObject(showAllPlayers);

    return dispatch(actions.getPlayersExport(options));
  }, [dispatch, allDisplayedPlayers, setOptionsObject]);

  const tableActions: TableAction[] = useMemo(
    () => [
      {
        type: TABLE_ACTION_TYPES.CREATE,
        id: 'table-action-1',
        params: {
          text: allDisplayedPlayers
            ? t('config_players:players.players_view.filters.all_players')
            : t('config_players:players.players_view.filters.active_players'),
          leftIcon: 'eye',
          onAction: handleDisplayAll,
          type: 'standard',
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
      {
        type: TABLE_ACTION_TYPES.EXPORT,
        id: 'table-action-2',
        params: {
          text: t('config_players:players.players_view.export'),
          leftIcon: 'download',
          onAction: handleExportPlayers,
          trackingAction: 'export',
          trackingContext: 'app-players',
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
    ],
    [handleDisplayAll, handleExportPlayers, allDisplayedPlayers, t],
  );

  return (
    <div style={styles.wrapper}>
      <SFilterBox
        count={playersCount}
        filters={filters}
        onChange={onFilterBoxChange}
        placeholder={t('config_players:players.players_view.find_any_players')}
        title={t('config_players:players.players_view.title')}
        description={getCountDescription}
      />
      <div style={styles.container}>
        <div style={styles.wrapperChild}>
          <STable
            columns={columns}
            data={data}
            total={playersCount}
            isTableLoading={data.length === 0 && isLoading}
            onShowMoreClicked={handleShowMore}
            range={PAGINATION_STEP}
            showMoreError={showMoreError}
            tableActions={tableActions}
            tableMessage={tableMessage}
            title={t('config_players:players.players_view.players_title')}
          />
          <PlayersManageModal />
        </div>
      </div>
      <div style={styles.bottomSpace} />
    </div>
  );
};
