import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { validateToken } from 'Libs/jwt';

import { actions, useModuleSelector } from 'Pages/TwoFactor/redux';

import SPhoneInput from 'Components/structural/SPhoneInput/SPhoneInput';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';

import LandingPageWrapper from '../LandingPageWrapper/LandingPageWrapper';

import styles from './PhoneForm.style';
import TokenExpiredInfoBox from '../TokenExpiredInfoBox/TokenExpiredInfoBox';

export type PhoneFormProps = {
  onSwitchToCode: () => void;
};

type PhoneModificationFormProps = {
  onPhoneNumberValidation: (isValid: boolean) => void;
};

const PhoneModificationForm = ({ onPhoneNumberValidation }: PhoneModificationFormProps) => {
  const dispatch = useDispatch();
  const currentPhoneNumber = useModuleSelector((state) => state.phoneNumber);
  const currentPhoneCountryCode = useModuleSelector((state) => state.phoneCountryCode);
  const [defaultCountryCode, setDefautCountryCode] = useState('');

  useEffect(() => {
    const fetchCountryCode = async () => {
      const response = await fetch('https://ipapi.co/json/', { method: 'GET' });
      const data = await response.json();

      setDefautCountryCode(data?.country_code || '');
    };

    fetchCountryCode();
  });

  const onPhoneNumberChange = useCallback(
    (phoneNumberInformation: any) => {
      const { value, phoneCountryCode, isValid } = phoneNumberInformation;

      onPhoneNumberValidation(isValid);

      dispatch(actions.setPhoneNumber(value));
      dispatch(actions.setPhoneCountryCode(phoneCountryCode));
    },
    [dispatch, onPhoneNumberValidation],
  );

  return (
    <SPhoneInput
      defaultPhoneNumber={currentPhoneNumber}
      defaultCountry={currentPhoneCountryCode || defaultCountryCode}
      valueChangeFeedbackStatus="none"
      valueChangeFeedbackMessage=""
      onChange={onPhoneNumberChange}
      label="Enter phone number"
      style={styles.phoneInput}
    />
  );
};

export const PhoneForm: FunctionComponent<PhoneFormProps> = ({ onSwitchToCode }) => {
  const invitationToken = useModuleSelector((state) => state.invitationToken);
  const isInvitationTokenValid = useMemo(() => validateToken(invitationToken), [invitationToken]);

  const dispatch = useDispatch();
  const currentPhoneNumber = useModuleSelector((state) => state.phoneNumber);
  const currentPhoneCountryCode = useModuleSelector((state) => state.phoneCountryCode);
  const requestError = useModuleSelector((state) => state.error);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const handleSendPhoneInformation = useCallback(() => {
    if (isPhoneNumberValid) {
      const sendPhoneAction = actions.registerPhone(invitationToken, currentPhoneNumber, currentPhoneCountryCode);

      dispatch(sendPhoneAction);

      return Promise.resolve(sendPhoneAction.promise);
    }

    return Promise.resolve();
  }, [dispatch, isPhoneNumberValid, invitationToken, currentPhoneNumber, currentPhoneCountryCode]);

  const handleSendEnd = useCallback(
    (res: any) => {
      if (!res || res?.error) {
        return null;
      }

      return onSwitchToCode();
    },
    [onSwitchToCode],
  );

  if (!isInvitationTokenValid) {
    return <TokenExpiredInfoBox />;
  }

  return (
    <LandingPageWrapper>
      <div style={styles.formWrapper}>
        <div style={styles.instruction}>
          Insert your phone number. You will receive a validation code by SMS to connect to your back office.
        </div>

        <PhoneModificationForm onPhoneNumberValidation={setIsPhoneNumberValid} />

        <div style={styles.buttonWrapper}>
          <UButtonWithLoading
            text="Send code"
            onClick={handleSendPhoneInformation}
            onRequestEnd={handleSendEnd}
            type="accentuated"
            disabled={!isPhoneNumberValid}
            style={styles.submitButton}
          />
          <div style={styles.errorMsg}>{requestError}</div>
        </div>
      </div>
    </LandingPageWrapper>
  );
};

export default PhoneForm;
