import { StyleSheet } from 'Libs/css/extended-styles';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const extendedStyles = StyleSheet.create({
  link: {
    '> a': {
      textDecoration: 'underline',
    },
  },
});

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,

    // Allow for multiple line break in markdown when chaining \n
    whiteSpace: 'pre-wrap',
  },
});

export default styleSheet;
