// @flow

import React, { useCallback, useMemo } from 'react';
import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions, useModuleSelector } from '../redux';

import styles from './PlayersAlertView.style';

type Props = {|
  component: React$AbstractComponent<any, any>,
|};

const ALL_SCOPES: Array<AlertScope> = [
  'fetchAllError',
];

export const IDS: { [AlertScope]: number } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [(x: any)]: i }), {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  fetchAllError: 'error',
};

const TITLES = {
  fetchAllError: 'Something went wrong',
};

// eslint-disable-next-line complexity
export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const { message = '' } = params;

  switch (scope) {
    case 'fetchAllError': return `Error while fetching players to display.\n${message}`;

    default: return '';
  }
};

const makeAlertsFromStateAlerts = (alerts) => alerts.map((alert) => ({
  id: IDS[alert.scope],
  title: TITLES[alert.scope],
  message: makeAlertMessage(alert.scope, alert.params),
  icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
  type: TYPES[alert.scope],
}));

export const PlayersAlertView = ({ component }: Props) => {
  const stateAlerts = useModuleSelector(state => state.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return (
    <AlertHOC alerts={alerts} onClose={onClose} />
  );
};
