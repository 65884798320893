import React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import UTableCellSegment from 'Components/unit/UTableCellSegment/UTableCellSegment';
import { AudienceType } from '../SActivityListItem.types';

import styles from '../SActivityListItem.style';

type Props = {
  audience: AudienceType | null;
  audiencePlaceholder: string;
  audienceEmptySegmentsLabel: string;
};

const TargetContent = ({ audience, audiencePlaceholder, audienceEmptySegmentsLabel }: Props) => {
  if (!audience) {
    return <div style={styles.targetPlaceholder}>{audiencePlaceholder}</div>;
  }

  if ('segments' in audience) {
    const { segments } = audience;

    return !segments.length ? (
      <div style={styles.targetPlaceholder}>{audienceEmptySegmentsLabel}</div>
    ) : (
      <UTableCellSegment items={segments} style={styles.cellSegment} />
    );
  }

  if ('magicCode' in audience) {
    const { label, magicCode } = audience;

    return (
      <div>
        {`${label} : `}
        <span style={styles.magicCode}>{magicCode}</span>
      </div>
    );
  }

  if ('labelEmail' in audience) {
    const { labelEmail } = audience;

    return <div>{labelEmail}</div>;
  }

  if ('campaignName' in audience) {
    const { campaignName } = audience;

    return <div style={styles.targetPlaceholder}>{campaignName}</div>;
  }

  return null;
};

export const Target = (props: Props) => (
  <div style={styles.containerTarget}>
    <UIcon name="target" size={16} color={COLORS.GREY_DARK.DEFAULT} style={styles.iconFooter} />
    <TargetContent {...props} />
  </div>
);
