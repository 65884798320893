import React, { CSSProperties } from 'react';
import { t } from 'i18next';
import { Column } from 'react-table';

import UCheckbox from 'Components/unit/UCheckbox/UCheckbox';
import UTableCellHeader from 'Components/unit/UTableCellHeader/UTableCellHeader';
import UButton, { UButtonProps } from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import UCompactInput from 'Components/unit/UCompactInput/UCompactInput';

import styles from './STable.style';
import './STable.css';
import Table from './components/Table';
import Title from './components/Title';
import TitleExplanation from './components/TitleExplanation';
import { CellHeaderParams, CheckBoxHeaderParams, Header, OriginalRow, TABLE_ACTION_TYPES, TableMessage } from './types';

const DEFAULT_RANGE = 20;

type TableActionParams = UButtonProps & {
  placeholder?: string;
  placeholderOnExpand?: string;
  onAction: (...args: any[]) => unknown;
  onSubmit?: (search: string) => void;
  onRequestEnd: () => Promise<void>;
  onClick: () => Promise<void>;
};

export type TableAction = {
  id: string | number;
  type: TABLE_ACTION_TYPES;
  params: TableActionParams;
};

type Props = {
  style?: CSSProperties;
  tableActions: TableAction[];
  loadingText?: string;
  showMoreText?: string;
  showMoreError: string;
  total: number;
  tableMessage: TableMessage;
  overrideContentLoading: boolean;
  isTableLoading: boolean;
  notice: string;
  emptyRowText: string;
  data: OriginalRow[];
  title: string;
  titleExplanation?: string;
  onShowMoreClicked: (from: number, to: number) => Promise<void> | void;
  range: number;
  columns: Header[];
};

type State = {
  isContentLoading: boolean;
};

export class STable extends React.Component<Props, State> {
  static defaultProps = {
    tableActions: [],
    notice: '',
    emptyRowText: null,
    onShowMoreClicked: () => {},
    showMoreText: null,
    showMoreError: '',
    tableMessage: null,
    isTableLoading: false,
    loadingText: null,
    overrideContentLoading: false,
    range: DEFAULT_RANGE,
    style: undefined,
  };

  state = {
    isContentLoading: false,
  };

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderHeading()}
        {this.renderTable()}
      </div>
    );
  }

  renderTable = () => {
    const columns = this.getHeader();

    if (!columns.length) return null;

    const {
      data,
      total,
      notice,
      emptyRowText,
      isTableLoading,
      loadingText,
      showMoreText,
      showMoreError,
      tableMessage,
      overrideContentLoading,
    } = this.props;

    const { isContentLoading } = this.state;
    const isEmpty = data.length === 0;
    const canShowMore = !isEmpty && !isTableLoading && total - data.length > 0;

    return (
      <Table
        columns={columns as Column<OriginalRow>[]}
        data={data}
        isEmpty={isEmpty}
        notice={notice}
        emptyRowText={emptyRowText || t('structural_component:s_table.empty_row_text')}
        tableMessage={tableMessage}
        canShowMore={canShowMore}
        showMoreError={showMoreError}
        onShowMoreClicked={this.handleShowMoreClicked}
        showMoreText={showMoreText || t('structural_component:s_table.show_more_text')}
        isTableLoading={isTableLoading}
        isContentLoading={isContentLoading || overrideContentLoading}
        loadingText={loadingText || t('structural_component:s_table.loading_text')}
      />
    );
  };

  renderHeading = () => {
    const { title, titleExplanation, tableActions } = this.props;

    if (!title && !titleExplanation && (!tableActions || !tableActions.length)) return null;

    return (
      <div style={styles.headingWrapper}>
        <div style={styles.headingTitleSubtitleWrapper}>
          <Title title={title} />
          <TitleExplanation explanation={titleExplanation} />
        </div>
        <div style={styles.tableActionsContainer}>
          {tableActions.map((action: TableAction, id: number) => {
            const spaceStyle = id > 0 ? styles.tableActionSpace : {};

            return (
              <div key={action.id} style={{ ...styles.tableAction, ...spaceStyle }}>
                {this.getTableActionComponent(action)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  handleShowMoreClicked = () => {
    const { onShowMoreClicked, range, data } = this.props;

    const from = data.length;
    const to = from + range;

    return Promise.resolve()
      .then(() => this.setState({ isContentLoading: true }))
      .then(() => onShowMoreClicked(from, to))
      .then(() => {
        this.setState({
          isContentLoading: false,
        });
      });
  };

  handleTableCellHeaderSort = (onAction: (column: string, order: string) => void, column: string, order: string) => {
    return Promise.resolve()
      .then(() => this.setState({ isContentLoading: true }))
      .then(() => onAction(column, order))
      .then(() => {
        this.setState({
          isContentLoading: false,
        });
      });
  };

  handleTableAction = (onAction: (param?: string) => void, param?: string) => {
    return Promise.resolve()
      .then(() => this.setState({ isContentLoading: true }))
      .then(() => onAction(param));
  };

  getHeaderComponents = (column: Header) => {
    const { headerParams } = column;

    switch (column.type) {
      case 'UCheckbox':
        return <UCheckbox {...(headerParams as CheckBoxHeaderParams)} />;
      case 'UTableCellHeader':
        return (
          <UTableCellHeader
            {...headerParams}
            canBeSorted={!column.disableSorting}
            onClick={this.handleTableCellHeaderSort.bind(
              null,
              (headerParams as CellHeaderParams).onAction,
              column.accessor,
            )}
          />
        );
      case 'EmptyHeader':
      default:
        return <div />;
    }
  };

  getHeader = () => {
    const { columns } = this.props;

    return columns.map((column) => ({
      ...column,
      Header: (
        <div key={column.id} style={{ ...column.wrapperStyle }}>
          {this.getHeaderComponents(column)}
        </div>
      ),
    }));
  };

  getTableActionComponent = (action: TableAction) => {
    const { data } = this.props;

    switch (action.type) {
      case TABLE_ACTION_TYPES.SEARCH:
        action.params.onSubmit = (search: string) => {
          return this.handleTableAction(action.params.onAction, search).then(() => {
            this.setState({ isContentLoading: false });
          });
        };

        return <UCompactInput {...action.params} />;
      case TABLE_ACTION_TYPES.EXPORT:
        action.params.disabled = data.length === 0;
        action.params.onClick = () => this.handleTableAction(action.params.onAction);
        action.params.onRequestEnd = () => {
          this.setState({ isContentLoading: false });

          return Promise.resolve();
        };

        return <UButtonWithLoading {...action.params} onClick={action.params.onClick} />;
      case TABLE_ACTION_TYPES.CREATE:
        action.params.onClick = action.params.onAction as () => Promise<void>;

        return <UButton type={action.params.type ? action.params.type : 'accentuated'} {...action.params} />;
      default:
        return null;
    }
  };
}

export default STable;
