import React, { ReactNode, useCallback, useMemo } from 'react';
import { t } from 'i18next';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions, useModuleSelector } from '../redux';

import styles from './AlertView.style';

type Props = {
  component: ReactNode;
};

type Alert = {
  scope: AlertScope;
  params: AlertParams;
};
type AlertScopeToNumberMap = { [K in AlertScope]: number };
type NumberToAlertScopeMap = { [K: number]: AlertScope };

type AlertResult = {
  id: number;
  title: string;
  message: string;
  icon: string;
  type: string;
};

const ALL_SCOPES: Array<AlertScope> = [
  'validationError',
  'unsavedError',
  'fetchError',
  'saveError',
  'activateError',
  'duplicateError',
  'duplicateSuccess',
  'archiveError',
  'archiveSuccess',
  'translateError',
  'translateSuccess',
  'knowledgesTranslateError',
  'knowledgesTranslateSuccess',
  'saveSuccess',
];

export const IDS: AlertScopeToNumberMap = ALL_SCOPES.reduce(
  (acc, x, i) => ({ ...acc, [x]: i }),
  {} as AlertScopeToNumberMap,
);
export const SCOPES: NumberToAlertScopeMap = ALL_SCOPES.reduce(
  (acc, x, i) => ({ ...acc, [i]: x }),
  {} as NumberToAlertScopeMap,
);

const TYPES = {
  validationError: 'error',
  unsavedError: 'error',
  fetchError: 'error',
  saveError: 'error',
  activateError: 'error',
  duplicateError: 'error',
  duplicateSuccess: 'success',
  archiveError: 'error',
  archiveSuccess: 'success',
  translateError: 'error',
  translateSuccess: 'success',
  refreshContentError: 'error',
  knowledgesTranslateError: 'error',
  knowledgesTranslateSuccess: 'success',
  saveSuccess: 'success',
};

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const FIELD_MESSAGES = {
    noName: t('activities:alerts.validation.no_name'),
    noDescription: t('activities:alerts.validation.no_description'),
    noCover: t('activities:alerts.validation.no_cover'),
    noTargetMode: t('activities:alerts.validation.no_target_mode'),
    emptyDay: t('activities:alerts.validation.empty_day'),
    nonValidateContent: t('activities:alerts.validation.non_validated_content'),
  };

  const UNSAVED_MESSAGES = {
    activate: t('activities:alerts.validation.must_save_before_activating'),
    deactivate: t('activities:alerts.validation.must_save_before_deactivating'),
    duplicate: t('activities:alerts.validation.must_save_before_duplication'),
    archive: t('activities:alerts.validation.must_save_before_archiving'),
    unarchive: t('activities:alerts.validation.must_save_before_unarchiving'),
    translate: t('activities:alerts.validation.must_save_before_translating'),
  };

  const message = params.message || '';

  const fieldMessage = params.fields ? params.fields.map((field) => FIELD_MESSAGES[field]).join('\n') : '';

  const unsavedMessage = params.unsavedScope ? UNSAVED_MESSAGES[params.unsavedScope] : '';

  switch (scope) {
    case 'validationError':
      return fieldMessage;
    case 'unsavedError':
      return unsavedMessage;
    case 'fetchError':
      return t('activities:alerts.errors.fetch_error_message', { message });
    case 'saveError':
      return t('activities:alerts.errors.save_error_message', { message });
    case 'activateError':
      return params.active
        ? t('activities:alerts.errors.deactivate_error_message', { message })
        : t('activities:alerts.errors.activate_error_message', { message });
    case 'duplicateError':
      return t('activities:alerts.errors.duplicate_error_message', { message });
    case 'archiveError':
      return params.archived
        ? t('activities:alerts.errors.unarchive_error_message', { message })
        : t('activities:alerts.errors.archive_error_message', { message });
    case 'translateError':
      return t('activities:alerts.errors.translate_error_message', { message });
    case 'knowledgesTranslateError':
      return t('activities:alerts.errors.knowledge_translate_error_message', { message });
    case 'refreshContentError':
      return t('activities:alerts.errors.refresh_content_error_message', { message });
    case 'duplicateSuccess':
      return t('activities:alerts.success.duplicate');
    case 'archiveSuccess':
      return params.archived ? t('activities:alerts.success.archive') : t('activities:alerts.success.unarchive');
    case 'translateSuccess':
      return t('activities:alerts.success.translate');
    case 'saveSuccess':
      return t('activities:alerts.success.save');
    case 'knowledgesTranslateSuccess':
      return t('activities:alerts.success.knowledge_translate');

    default:
      return '';
  }
};

const makeAlertsFromStateAlerts = (alerts: readonly Alert[]): AlertResult[] => {
  const TITLES = {
    validationError: t('activities:alerts.errors.validation_error_title'),
    unsavedError: '',
    fetchError: t('activities:alerts.errors.fetch_error_title'),
    saveError: t('activities:alerts.errors.save_error_title'),
    translateError: t('activities:alerts.errors.translate_error_title'),
    knowledgesTranslateError: t('activities:alerts.errors.knowledge_translate_error_title'),
    activateError: t('activities:alerts.errors.activate_error_title'),
    duplicateError: t('activities:alerts.errors.duplicate_error_title'),
    archiveError: t('activities:alerts.errors.archive_error_title'),
    duplicateSuccess: '',
    archiveSuccess: '',
    translateSuccess: '',
    knowledgesTranslateSuccess: '',
    saveSuccess: '',
    refreshContentError: t('activities:alerts.errors.refresh_content_error_title'),
  };

  return alerts.map(
    (alert): AlertResult => ({
      id: IDS[alert.scope],
      title: TITLES[alert.scope],
      message: makeAlertMessage(alert.scope, alert.params),
      icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
      type: TYPES[alert.scope],
    }),
  );
};

export const AlertView = ({ component }: Props) => {
  const stateAlerts: ReadonlyArray<Alert> = useModuleSelector((state) => state.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return <AlertHOC alerts={alerts} onClose={onClose} />;
};
