// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    textAlign: 'center',
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    fontWeight: 700,

    ...TYPOGRAPHY.CAPTION,
  },
};
