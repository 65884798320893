import { COLORS, CORNERS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const FLAG_RADIUS = 7;
const MORE_FLAGS_INDICATOR_RADIUS = 5.5;
const WARNING_BUBBLE_RADIUS = 6;
const WRAPPER_LATERAL_PADDING = 6;
const WRAPPER_VERTICAL_PADDING = 5;

export const FLAG_SIZE = 2 * FLAG_RADIUS + 2; // takes into account the removal of border
export const MAXIMUM_LANGUAGES_BEFORE_SCROLLING = 5;
export const HEIGHT_OF_DROPDOWN_LANGUAGE_ITEM = 31;
export const MARGIN_BETWEEN_GROUP_AND_DROPDOWN = 4;

const styleSheet = createStyleSheet({
  hoverableZone: {
    height: 2 * FLAG_RADIUS + 2 * WRAPPER_VERTICAL_PADDING + 2 * MARGIN_BETWEEN_GROUP_AND_DROPDOWN, // The hoverable zone sticks out to allow continuous hovering between the wrappper and dropdown.
    paddingTop: MARGIN_BETWEEN_GROUP_AND_DROPDOWN,
    paddingBottom: MARGIN_BETWEEN_GROUP_AND_DROPDOWN,
    display: 'inline-flex',
  },
  wrapper: {
    ...CORNERS.BORDER_RADIUS(CORNERS.CORNER3),
    height: 2 * FLAG_RADIUS + 2 * WRAPPER_VERTICAL_PADDING,
    padding: WRAPPER_VERTICAL_PADDING,
    paddingLeft: WRAPPER_LATERAL_PADDING,
    paddingRight: FLAG_RADIUS + WRAPPER_LATERAL_PADDING, // Flags are in containers of half their size. To allow the last flag to fit in the wrapper we add a radius to the padding.
    display: 'inline-flex',
    flexGrow: 0,
    flexDirection: 'row',
    position: 'relative',
  },
  warningWrapper: {
    backgroundColor: COLORS.WARNING.LIGHTER,
  },
  warningBubble: {
    ...CORNERS.BORDER_RADIUS(CORNERS.ROUND),
    backgroundColor: COLORS.WARNING.LIGHT,
    position: 'absolute',
    right: -4,
    top: -4,
    height: 2 * WARNING_BUBBLE_RADIUS,
    width: 2 * WARNING_BUBBLE_RADIUS,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flagContainer: {
    width: FLAG_RADIUS,
    height: 2 * FLAG_RADIUS,
  },
  moreFlagsIndicator: {
    ...CORNERS.BORDER_RADIUS(CORNERS.ROUND),
    backgroundColor: COLORS.BLUE_GRAY.MEDIUM,
    width: 2 * MORE_FLAGS_INDICATOR_RADIUS,
    height: 2 * MORE_FLAGS_INDICATOR_RADIUS,
    ...TYPOGRAPHY.BODY1_SEMIBOLD,
    fontSize: '8px',
    lineHeight: `${2 * MORE_FLAGS_INDICATOR_RADIUS}px`,
    paddingLeft: 1,
  },
  moreFlagsIndicatorContainer: {
    width: 2 * MORE_FLAGS_INDICATOR_RADIUS - FLAG_RADIUS,
    height: 2 * FLAG_RADIUS + 1, // to make it stick out
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    // This is a slight hack to circumvent a yet not understood behavior:
    // Without this the Indicator renders below the other flags, despite the dom ordering
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  dropdownBox: {
    position: 'absolute',
    top: 2 * FLAG_RADIUS + 2 * WRAPPER_VERTICAL_PADDING + MARGIN_BETWEEN_GROUP_AND_DROPDOWN,
    left: 0,
    zIndex: LEVELS.LOCAL_OFFSET_2, // Must render above warning labels
    width: 250,
    maxHeight: MAXIMUM_LANGUAGES_BEFORE_SCROLLING * HEIGHT_OF_DROPDOWN_LANGUAGE_ITEM,
    paddingBottom: 0,
  },
  dropupBox: {
    bottom: 2 * FLAG_RADIUS + 2 * WRAPPER_VERTICAL_PADDING + MARGIN_BETWEEN_GROUP_AND_DROPDOWN,
    top: 'initial',
  },
  restState: {
    opacity: '50%',
  },
});

export default styleSheet;
