import styles from 'Components/unit/UGalleryItem/UGalleryItem.style';
import React from 'react';
import { ImageGalleryType } from 'Libs/ts/types';

const SOURCE_WIDTH = 62;

type OverlayTextProps = {
  source?: string;
  image: ImageGalleryType;
  isLinkHover: boolean;
  width: number;
  setIsLinkHover: (isHover: boolean) => void;
};

const OverlayText = ({ source, width, image, isLinkHover, setIsLinkHover }: OverlayTextProps) => {
  const widthLinkMargin = 20;
  const distLinkToSource = 4;
  const widthLink = {
    maxWidth: source ? width - widthLinkMargin - SOURCE_WIDTH - distLinkToSource : width - widthLinkMargin,
  };
  const marginLink = { margin: source ? '0 4px 10px 10px' : '0 10px 10px 10px' };
  const textDecoration = isLinkHover ? 'underline' : 'unset';

  const handleHover = () => {
    setIsLinkHover(true);
  };

  const handleLeave = () => {
    setIsLinkHover(false);
  };

  if (!image.linkText) return null;

  return (
    <div style={styles.linkContainer}>
      <a
        href={image.linkPath}
        target="_blank"
        style={{ ...styles.link, textDecoration, ...widthLink, ...marginLink }}
        rel="noreferrer"
      >
        <span onMouseEnter={handleHover} onMouseLeave={handleLeave}>
          {image.linkText}
        </span>
      </a>
      {source ? <div style={styles.source}>{source}</div> : null}
    </div>
  );
};

export default OverlayText;
