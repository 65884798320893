import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  container: {
    marginTop: '32px',
    marginRight: '16px',
    marginLeft: '16px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstFormContainer: {
    display: 'flex',
    marginTop: '24px',
    marginBottom: '24px',
  },
  idLanguageForm: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
    width: '100%',
  },
  languageSelector: {
    marginBottom: '24px',
  },
  thematicSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  thematicSelector: {
    marginBottom: '24px',
  },
  thematicButton: {
    marginLeft: '8px',
  },
  thematicLabel: {
    marginBottom: '8px',
  },
  field: {
    width: '355px',
  },
});
