import { useCallback, useMemo } from 'react';
import { LanguageCode } from '@sparted/shared-library/business/types';

import { FilterState } from 'Libs/filter/types';
import { getLanguagesAvailableWithCode } from 'Services/language/languageService';
import { defaultFilter } from 'Store/entities/content/content.slice';
import { ContentFilters } from 'Store/entities/content/content.types';
import { CONTENT_ID_STATUS_MAP, CONTENT_STATUS_ID_MAP } from 'Store/entities/content/content.utils';
import { createModuleSelectorFactory } from 'Libs/redux/utils';

import { getLanguageIds } from './utils';

export const useFormatFiltersForStore = () => {
  const languages = useMemo(getLanguagesAvailableWithCode, []);
  const formatFiltersForStore = useCallback(
    (filterValues: FilterState[]) =>
      filterValues.reduce((acc: ContentFilters, filter: FilterState) => {
        if (filter.category === 'dolphinContentStatus') {
          return { ...acc, selectedContentStatuses: filter.ids.map((id) => CONTENT_ID_STATUS_MAP[id]) };
        }

        if (filter.category === 'language') {
          const languageCodes = filter.ids
            .map((id) => {
              const languageInfo = languages.find((lang) => lang.id === id);
              if (languageInfo) {
                return languageInfo.languageCode;
              }
              return;
            })
            .filter(Boolean) as LanguageCode[];
          return {
            ...acc,
            selectedLanguageCodes: languageCodes,
          };
        }

        if (filter.category === 'thematic') {
          return { ...acc, selectedThematicIds: filter.ids };
        }

        if (filter.category === 'text') {
          return { ...acc, selectedTexts: filter.values };
        }

        return acc;
      }, defaultFilter),
    [],
  );
  return formatFiltersForStore;
};

export const useContentFilters = () => {
  const availableLanguages = useMemo(getLanguagesAvailableWithCode, []);

  const storeFilters = createModuleSelectorFactory('entities')('content')((state) => state.filters);
  const { selectedContentStatuses, selectedLanguageCodes, selectedThematicIds, selectedTexts } = storeFilters;

  const filters = useMemo<FilterState[]>(
    () => [
      {
        category: 'language',
        ids: getLanguageIds(selectedLanguageCodes, availableLanguages),
      },
      {
        category: 'thematic',
        ids: [...selectedThematicIds],
      },
      {
        category: 'dolphinContentStatus',
        ids: [...selectedContentStatuses.map((status) => CONTENT_STATUS_ID_MAP[status])],
      },
      {
        category: 'text',
        values: [...selectedTexts],
      },
    ],
    [selectedContentStatuses, selectedLanguageCodes, selectedTexts, selectedThematicIds],
  );
  return { filters, storeFilters };
}