import { fetchFactory } from 'Libs/http/utils';

export type AudienceGroupCourse = {
  title: string,
  language: string,
  id: number,
  description: string,
  coverImageUrl: string,
};

export type GetAudienceGroupCoursesListArgs = {
  audienceGroupId: number;
};

export type GetAudienceGroupCoursesListFn = (audienceGroupId: number) => Promise<any>;

export const getAudienceGroupCoursesList = async ({
  audienceGroupId,
}: GetAudienceGroupCoursesListArgs) => {
  const { fetchFn } = fetchFactory<{ courses: AudienceGroupCourse[] }>({
    route: `/api/audience/courses?audienceGroupIds=${audienceGroupId}`,
    method: 'GET',
  });

  const { courses } = await fetchFn();

  return { result: courses, error: undefined };
};
