/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
// @flow

import React from 'react';
import { t } from 'i18next';

import { useTypedDispatch, useTypedSelector } from 'Libs/redux/utils';

import UButton from 'Components/unit/UButton/UButton';

import { actions } from '../../../../redux';
import styles from './DocumentModalFormArchive.style';

export const DocumentModalFormArchive = () => {
  const dispatch = useTypedDispatch();
  const documentId = useTypedSelector(state => state.pages.documentList.documentModal?.id);
  const isArchived = useTypedSelector(state => state.pages.documentList.documentModal?.archived);

  if (!documentId)
    return null;

  if (isArchived) {
    return (
      <>
        <div style={styles.title}>{t('documents:document_modal_form_archive.unarchiving_title')}</div>

        <UButton
          style={styles.button}
          text={t('documents:document_modal_form_archive.unarchive_button_text')}
          type="accentuated"
          onClick={() => dispatch(actions.archive(documentId, false))}
          ghost
        />
      </>
    );
  }

  return (
    <>
      <div style={styles.title}>{t('documents:document_modal_form_archive.archiving_title')}</div>
      <div style={styles.notice}>{t('documents:document_modal_form_archive.archiving_notice')}</div>

      <UButton
        style={styles.button}
        type="destructive"
        text={t('documents:document_modal_form_archive.archive_button_text')}
        onClick={() => dispatch(actions.archive(documentId, true))}
        ghost
      />
    </>
  );
};
