import { TYPOGRAPHY, ELEVATIONS, COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'inline-flex',
    borderRadius: 8,
    position: 'relative',
    boxShadow: ELEVATIONS.LOW,
    maxWidth: '696px',
    verticalAlign: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 'normal',
    fontSize: '16px',
    marginBottom: 3,
    marginTop: 3,
  },
  iconContainer: {
    marginLeft: '0px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    position: 'relative',
  },
  closeButtonContainer: {
    marginLeft: '0px',
    marginRight: '-43px',
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: COLORS.SUCCESS.LIGHTER,
    color: COLORS.SUCCESS.DARKER,
    border: `1px solid ${COLORS.SUCCESS.DARKER}`,
    cursor: 'pointer',
    padding: '7px 12px',
    right: 0,
    margin: 0,
  },
  successStyle: {
    minHeight: '40px',
    textAlign: 'start',
    minWidth: '328px',
    placeContent: 'start',
  },
  restStyle: {
    minHeight: '36px',
    textAlign: 'center',
    minWidth: '288px',
    placeContent: 'center',
  },
  successPadding: {
    padding: '2px 45px 2px 16px',
  },
  restPadding: {
    padding: '2px 16px',
  },
  mediumMargin: {
    marginRight: '17px',
  },
  restMargin: {
    marginRight: '-25px',
  },
  noMargin: {
    marginRight: '0px',
  },
});

export default styleSheet;
