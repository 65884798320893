import { StyleSheet } from 'aphrodite';
import { COLORS, TYPOGRAPHY, ELEVATIONS } from 'Components/foundation';

export default {
  wrapper: {
    flex: 1,
    display: 'flex',
    backgroundColor: COLORS.WHITE.DEFAULT,
    minHeight: 91,
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 30,
    boxShadow: ELEVATIONS.LOW,
    cursor: 'pointer',
  },
  wrapperLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapperRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 160,
  },
  documentIcon: {
    width: 24,
    minWidth: 24,
    marginRight: 24,
  },
  documentTitle: {
    ...TYPOGRAPHY.H2_SEMIBOLD,
  },
  iconTarget: {
    width: 16,
    minWidth: 16,
    marginRight: 8,
    marginTop: 2,
  },
  chips: {
    marginRight: 6,
    marginTop: 2,
    marginBottom: 2,
  },
  noSegmentation: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.BLACK.DEFAULT,
    marginTop: 2,
  },
  options: {
    marginLeft: 20,
    cursor: 'pointer',
  },
  optionsDropDownWrapper: {
    position: 'relative',
    top: 0,
  },
  optionDropDown: {
    position: 'absolute',
    right: 5,
  },
  openingsText: {
    ...TYPOGRAPHY.BODY4,
  },
  noOpeningsText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  eyeIcon: {
    marginRight: 8,
    paddingBottom: 1,
  },
};

export const extendedStyles = StyleSheet.create({
  wrapper: {
    ':hover': {
      boxShadow: ELEVATIONS.MEDIUM,
      transition: 'box-shadow 0.2s ease-in-out',
    },
  },
  wrapperLeftText: {
    display: 'flex',
    flexDirection: 'column',
    width: 310,
    minWidth: 310,
  },
  categoryTitle: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBottom: 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  segmentationBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  openingsBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
});
