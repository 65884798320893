import m from 'm';
import Promise from 'bluebird';

import { t } from 'i18next';

import Knowledge from 'models/Knowledge';
import Enum from 'models/Enum';
import menu from 'pages/menu';
import loader from 'components/loader';
import reactCreator from 'components/react.creator';

// We use -1 as id for the wrong sentence of the error text because no game item actually exist for it
const ERROR_TEXT_WRONG_SENTENCE_ID = -1;

var component = {};

component.controller = function controller() {
  var self = this;

  self.loading = m.prop(true);
  self.knowledge = m.prop(new Knowledge());
  self.original = m.prop(new Knowledge());
  self.editing = m.prop(false);
  self.questionItems = m.prop([]);
  self.answerItems = m.prop([]);
  self.titleItems = m.prop([]);
  self.textItems = m.prop([]);
  self.longTextItems = m.prop([]);
  self.shortTextItems = m.prop([]);
  self.instructionItems = m.prop([]);
  self.helperItems = m.prop([]);
  self.orderingAnswerItems = m.prop([]);
  self.errorText = m.prop(null);
  self.originalErrorText = m.prop(null);
  self.trueErrorTextFocus = m.prop(false);
  self.falseErrorTextFocus = m.prop(false);
  self.falsyText = m.prop('');
  self.modified = m.prop(false);
  self.wordsCount = m.prop(0);
  self.numItemsToTranslate = m.prop(0);
  self.itemsToTranslate = m.prop([]);
  self.translating = m.prop(false);
  self.disablePreviewButton = m.prop(true);
  self.disableConfirmButton = m.prop(true);

  self.waitingForTranslation = `[${t('content_translation:waiting_for_translation')}]`;

  Knowledge
    .get(parseInt(m.route.param('id')))
    .then(function setKnowledge(foundKnowledge) {
      self.knowledge(foundKnowledge);
      handleErrorText();
      Knowledge
        .get(foundKnowledge.originId())
        .then(function setOriginal(foundOriginal) {
          self.original(foundOriginal);
          self.knowledge().gameItems().items = self.knowledge().gameItems().items.map(handlePlaceholder).filter(cleanGameItem);
          orderGameItems();
          getOriginalErrorText();
          cleanKnowledge();
          updateWordsCount();
          self.setItemsToTranslate();
          self.loading(false);
          m.redraw();
        });
    });

  function cleanGameItem(gi) {
    var originalGameItem = originalGI(gi);

    return gi.originId() && originalGameItem && originalGameItem.data().length;
  }

  function updateWordsCount() {
    var wordsCount = 0;

    [
      self.questionItems(),
      self.instructionItems(),
      self.answerItems(),
      self.orderingAnswerItems(),
      self.titleItems(),
      self.textItems(),
      self.longTextItems(),
      self.shortTextItems(),
      self.helperItems(),
    ].forEach(function countGIS(gis) {
      if (gis && gis.forEach) {
        gis.forEach(function countGI(gi) {
          wordsCount += originalGI(gi).data().trim().split(/\s+/).length;
        });
      }
    });
    wordsCount += self.original().knowledgeTitle().data().trim().split(/\s+/).length;
    if (self.errorText()) {
      wordsCount += self.originalErrorText()['Error Text']().originalText().data().trim().split(/\s+/).length;
      wordsCount += self.originalErrorText()['Error Text']().substitutes().length;
    }

    self.wordsCount(wordsCount);
  }

  function handlePlaceholder(gi) {
    if (gi.data() === '' || gi.data() === ' ')
      gi.data(self.waitingForTranslation);

    return gi;
  }

  function handleErrorText() {
    getErrorText();
    if (!self.errorText())
      return;
    removeErrorTextsGIs();
    if (self.errorText()['Error Text']().originalText().data() === '' || self.errorText()['Error Text']().originalText().data() === ' ')
      self.errorText()['Error Text']().originalText().data(self.waitingForTranslation);
    initFalsyText();


    function getErrorText() {
      self.errorText(self.knowledge().contents().items.filter(isErrorText)[0]);

      function isErrorText(content) {
        return content.gameplayId() === Enum.gameplay.ERROR_TEXT;
      }
    }

    function removeErrorTextsGIs() {
      removeErrorTextGI(self.errorText()['Error Text']().originalText());
      self.errorText()['Error Text']().substitutes().forEach(removeErrorTextGI);

      function removeErrorTextGI(gi) {
        self.knowledge().gameItems().searchAndRemove({ id: gi.id() });
      }
    }
  }

  function initFalsyText() {
    var oText = self.errorText()['Error Text']().originalText();
    var positions = self.errorText()['Error Text']().substitutePositions();
    var substitutes = self.errorText()['Error Text']().substitutes();


    if (oText.data() === self.waitingForTranslation)
      return self.falsyText(oText.data());
    if (!oText.data())
      return;

    var words = oText.data().split(' ');

    for (var pos in positions) {
      if (positions.hasOwnProperty(pos)) {
        var sub = substitutes.get(positions[pos]);

        if (sub)
          words[pos] = sub.data();
      }
    }

    self.falsyText(words.join(' '));
  }

  function getOriginalErrorText() {
    self.originalErrorText(self.original().contents().items.filter(isErrorText)[0]);

    function isErrorText(content) {
      return content.gameplayId() === Enum.gameplay.ERROR_TEXT;
    }
  }

  function orderGameItems() {
    const { QUESTION, ANSWER, TITLE, LONG_TEXT, SHORT, TEXT, HELPER, INSTRUCTION, OC_ANSWER } = Enum.gameItemTypes;

    self.questionItems(self.knowledge().gameItems().find({ typeId: QUESTION }));
    self.answerItems(self.knowledge().gameItems().find({ typeId: ANSWER }));
    self.textItems(self.knowledge().gameItems().find({ typeId: TEXT }));
    self.longTextItems(self.knowledge().gameItems().find({ typeId: LONG_TEXT }));
    self.titleItems(self.knowledge().gameItems().find({ typeId: TITLE }));
    self.shortTextItems(self.knowledge().gameItems().find({ typeId: SHORT }));
    self.instructionItems(self.knowledge().gameItems().find({ typeId: INSTRUCTION }));
    self.helperItems(self.knowledge().gameItems().find({ typeId: HELPER }));
    self.orderingAnswerItems(self.knowledge().gameItems().find({ typeId: OC_ANSWER }));

    self.longTextItems(cleanDuplicate(self.longTextItems().filter(hasOriginal)));
    self.questionItems(cleanDuplicate(self.questionItems().filter(hasOriginal)));
    self.answerItems(cleanDuplicate(self.answerItems().filter(hasOriginal)));
    self.titleItems(cleanDuplicate(self.titleItems().filter(hasOriginal)));
    self.textItems(cleanDuplicate(self.textItems().filter(hasOriginal)));
    self.shortTextItems(cleanDuplicate(self.shortTextItems().filter(hasOriginal)));
    self.instructionItems(cleanDuplicate(self.instructionItems().filter(hasOriginal)));
    self.helperItems(cleanDuplicate(self.helperItems().filter(hasOriginal)));
    self.orderingAnswerItems(cleanDuplicate(self.orderingAnswerItems().filter(hasOriginal)));

    function hasOriginal(gi) {
      return gi.originId();
    }
  }

  function cleanDuplicate(gis) {
    var ret = [];

    gis.map(function filterDuplicate(gi) {
      var isPresent = false;

      ret.map(function isExist(giToCompare) {
        var oGameItem = originalGI(gi);
        var oGameItemToCompare = originalGI(giToCompare);

        if (!oGameItem)
          console.warn('[translation]', 'gameItem has no origin', gi.toJSON());


        if (!oGameItemToCompare)
          console.warn('[translation]', 'gameItem to compare has no origin', giToCompare.toJSON());

        if (gi.id() !== giToCompare.id() && oGameItem.data() === oGameItemToCompare.data() && gi.typeId() === giToCompare.typeId())
          isPresent = true;
      });

      if (!isPresent)
        ret.push(gi);
    });
    return ret;
  }

  function propagateDuplicateGI(gis) {
    self.knowledge().gameItems().map(function dispatchTraduction(gi) {
      gis.map(function checkInTranslations(giTranslated) {
        if (originalGI(gi).data() === originalGI(giTranslated).data() && gi.typeId() === giTranslated.typeId())
          gi.data(giTranslated.data());
      });
    });

    return self.knowledge().gameItems();
  }

  function cleanKnowledge() {
    var alreadyTranslated = self.knowledge().knowledgeTitle().data() === '' || self.original().knowledgeTitle().data() === self.knowledge().knowledgeTitle().data() || self.knowledge().knowledgeTitle().data() === ' ';

    if (alreadyTranslated)
      self.knowledge().knowledgeTitle().data(self.waitingForTranslation);
  }

  self.saveKnowledge = function saveKnowledge() {
    if (self.knowledge().knowledgeTitle().data().trim() === '' || self.knowledge().knowledgeTitle().data() === self.waitingForTranslation) {
      self.knowledge().knowledgeTitle().data(self.waitingForTranslation);
      m.redraw();
      return Promise.resolve();
    }

    return self.knowledge().save()
      .then(function handleResponse(saved) {
        orderGameItems();
        m.redraw();
      });
  };

  self.saveAll = function saveAll() {
    var optional = [];
    var gis = [];

    if (self.errorText())
      optional.push(self.computeErrorTextAndSave(undefined, self.errorText()['Error Text']().originalText()));

    gis = gis.concat(self.longTextItems());
    gis = gis.concat(self.questionItems());
    gis = gis.concat(self.answerItems());
    gis = gis.concat(self.titleItems());
    gis = gis.concat(self.textItems());
    gis = gis.concat(self.shortTextItems());
    gis = gis.concat(self.instructionItems());
    gis = gis.concat(self.helperItems());
    gis = gis.concat(self.orderingAnswerItems());

    var ret = propagateDuplicateGI(gis);

    var promises = ret.toArray().map(self.saveGameItem);

    promises.concat(optional);

    return Promise.all(promises)
      .then(function done() {
        self.modified(false);
        m.redraw();
      });
  };

  self.saveGameItem = function saveGameItem(gi) {
    self.trueErrorTextFocus(false);
    self.falseErrorTextFocus(false);
    if (gi.data().trim() === '' || gi.data() === self.waitingForTranslation) {
      gi.data(self.waitingForTranslation);
      m.redraw();
      return Promise.resolve();
    }

    return gi.save()
      .then(function handleResponse(savedGi) {
        m.redraw();
      });
  };

  self.confirmPageExit = function confirmPageExit() {
    m.redraw();
    m.route('/knowledge/' + self.knowledge().id());
  };

  self.validateTranslation = function validateTranslation() {
    return self.saveAll()
      .then(self.knowledge().validateTranslation)
      .then(function handleResponse(knowledge) {
        var route = knowledge.getRoute();

        console.log('[translation]', 'Translation validated going to', route);

        m.redraw();
        m.route(route);
      })
      .catch(function handleError(err) {
        console.log('ERROR', err);
        popup.open({
          type: 'info',
          title: t('content_translation:unexpected_error'),
          content: err.message.error.message,
        });
      });
  };

  self.isTranslationFinished = function isTranslationFinished() {
    var result = true;

    validateList(self.longTextItems());
    validateList(self.questionItems());
    validateList(self.answerItems());
    validateList(self.titleItems());
    validateList(self.textItems());
    validateList(self.instructionItems());
    validateList(self.helperItems());
    validateList(self.orderingAnswerItems());

    if (self.errorText() && !self.isFalsyValid())
      result = false;

    return result;

    function validateList(list) {
      list.forEach(function valid(gi) {
        if (gi.data() === '' || gi.data() === self.waitingForTranslation)
          result = false;
      });
    }
  };

  function originalGI(gameItem) {
    var matching = self.original().gameItems().find({ id: gameItem.originId() });

    return matching.items[0];
  }

  self.oninputFactory = function oninputFactory(value, limit) {
    var oninput = function oninput(e) {

      var cursorPosition = this.selectionEnd;
      var isAtEnd = cursorPosition === this.value.length;
      var diff = this.value.length - value().length;

      if (!isAtEnd && this.value.length > limit) {
        this.value = value();
        cursorPosition -= diff;
      } else if (this.value.length > limit)
        this.value = this.value.substring(0, limit);

      this.setSelectionRange(cursorPosition, cursorPosition);

      value(this.value);
      m.redraw();
      self.resizeTextarea({ target: this });

      if (e.keyCode === 229)
        self.lastKeyCode(229);

      else
        self.lastKeyCode(0);

    };

    return oninput;
  };

  self.resizeTextarea = function resizeTextarea(limit, e) {
    var elem = e.target;

    if (elem.value.length >= limit)
      return;

    // set height to scroll textarea height
    elem.style.height = `${elem.scrollHeight + 5}px`;

    m.redraw.strategy('none');
  };

  self.initTextarea = function initTextarea(elem, init) {
    if (init)
      return;

    const originInputs =  elem.parentElement.parentElement.previousSibling.getElementsByClassName('translation-table__row__input');

    if (originInputs && originInputs.length)
      elem.style.height = originInputs[0].clientHeight + 'px';
  };

  self.onfocus = function onfocus(value) {
    if (value() === self.waitingForTranslation)
      value('');

    self.editing(true);
  };

  self.isValidGI = function isValidGI(gi) {
    var before = self.original().gameItems().get(gi.originId());

    if (!before)
      return false;

    return gi.data() !== before.data() && gi.data() !== self.waitingForTranslation && gi.data().trim() !== '';
  };

  self.isValidTitle = function isValidTitle(title) {
    return title !== self.waitingForTranslation && title.trim() !== '';
  };

  self.inputTestFactory = function inputTestFactory(gi) {
    var inputTest = function inputTest(e) {
      if (e.keyCode === 13 && (!gi || (gi.type().id() !== 3 && gi.type().id() !== 4)))
        e.preventDefault();

      m.redraw.strategy('none');
    };

    return inputTest;
  };

  self.finallySetDataFactory = function finallySetDataFactory(gi) {
    var finallySetData = function finallySetData(e) {
      var limit;

      if (gi.type().id() === 1)
        limit = 150;
      else if (gi.type().id() === 2)
        limit = 80;
      else if (gi.type().id() === 3)
        limit = 500;

      if (this.value > limit) {
        this.value = gi.data();
        return;
      }
      gi.data(this.value);
    };

    return finallySetData;
  };

  self.computeErrorText = function computeErrorText(e, gi) {
    if (!self.errorText())
      return Promise.resolve();
    var oText = self.errorText()['Error Text']().originalText().data();
    var realWords = oText.split(' ');
    var noRealWords = self.falsyText().split(' ');
    var nbWrongNeeded = self.errorText()['Error Text']().substitutes().length;
    var wrongWords = [];
    var wrongWordsPositions = [];
    var promises = [];

    self.falseErrorTextFocus(false);
    self.trueErrorTextFocus(false);

    if (realWords.length !== noRealWords.length) {
      console.log('Not the same amount of words');
      return Promise.resolve();
    }

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i]) {
        wrongWords.push(noRealWords[i]);
        wrongWordsPositions.push(i);
      }
    }

    if (wrongWords.length !== nbWrongNeeded) {
      console.log('Not the same number of error than in the original');
      return Promise.resolve();
    }

    var positions = {};

    for (var j = 0, y = wrongWords.length; j < y; j++) {
      var toSave = self.errorText()['Error Text']().substitutes().at(j);

      toSave.data(wrongWords[j]);
      positions[wrongWordsPositions[j]] = toSave.id();

      // promises.push(toSave.save());
    }

    self.errorText()['Error Text']().substitutePositions(positions);

    // promises.push(self.errorText()['Error Text']().save());


    return Promise.all(promises).then(function handleResult() {
      initFalsyText();
    });
  };

  self.computeErrorTextAndSave = function computeErrorTextAndSave(e, gi) {
    if (!self.errorText())
      return Promise.resolve();
    var oText = self.errorText()['Error Text']().originalText().data();
    var realWords = oText.split(' ');
    var noRealWords = self.falsyText().split(' ');
    var nbWrongNeeded = self.errorText()['Error Text']().substitutes().length;
    var wrongWords = [];
    var wrongWordsPositions = [];
    var promises = [];

    self.falseErrorTextFocus(false);
    self.trueErrorTextFocus(false);
    if (gi)
      promises.push(self.saveGameItem(gi));


    if (realWords.length !== noRealWords.length) {
      console.log('Not the same amount of words');
      return Promise.resolve();
    }

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i]) {
        wrongWords.push(noRealWords[i]);
        wrongWordsPositions.push(i);
      }
    }

    if (wrongWords.length !== nbWrongNeeded) {
      console.log('Not the same number of error than in the original');
      return Promise.resolve();
    }

    var positions = {};

    for (var j = 0, y = wrongWords.length; j < y; j++) {
      var toSave = self.errorText()['Error Text']().substitutes().at(j);

      toSave.data(wrongWords[j]);
      positions[wrongWordsPositions[j]] = toSave.id();
      promises.push(toSave.save());
    }

    self.errorText()['Error Text']().substitutePositions(positions);
    promises.push(self.errorText()['Error Text']().save());


    return Promise.all(promises).then(function handleResult() {
      initFalsyText();
      return Promise.resolve();
    });
  };

  self.isFalsyValid = function isFalsyValid() {
    var result = true;
    var oText = self.errorText()['Error Text']().originalText().data();
    var realWords = oText.split(' ');
    var noRealWords = self.falsyText().split(' ');
    var nbWrongNeeded = self.errorText()['Error Text']().substitutes().length;
    var wrongWords = [];

    // if (self.errorText()['Error Text']().originalText().data() === self.waitingForTranslation)
    //   return true;

    if (realWords.length !== noRealWords.length)
      result = false;

    for (var i = 0, x = realWords.length; i < x; i++) {
      if (realWords[i] !== noRealWords[i])
        wrongWords.push(noRealWords[i]);
    }

    if (wrongWords.length !== nbWrongNeeded)
      result = false;

    return result;

  };

  // eslint-disable-next-line complexity
  self.setItemsToTranslate = () => {
    // Remove the know more from the game items
    // For some reason, it is not displayed in the translation page and has
    // a size limitation of 0. The translation can never be validated
    const gameItems = cleanDuplicate(self.knowledge().gameItems().items).filter((gameItem) => {
      return gameItem.typeId() !== 0;
    });

    let nbTotalGameItems = 0;

    const notTranslatedGameItemMap = gameItems.reduce((acc, gameItem) => {
      if (gameItem.data() === self.waitingForTranslation || gameItem.data() === '')
        acc[gameItem.originId()] = { id: gameItem.id() };

      return acc;
    }, {});

    self.original().gameItems().items.forEach((gameItem) => {
      if (notTranslatedGameItemMap[gameItem.id()])
        notTranslatedGameItemMap[gameItem.id()].value = gameItem.data();
    });

    // Special case for error text
    if (self.errorText()) {
      const errorText = self.errorText()['Error Text']();
      const originErrorText = self.originalErrorText()['Error Text']();

      const sentenceInArray = originErrorText.originalText().data().split(' ');

      for (const [key, value] of Object.entries(originErrorText.substitutePositions())) {
        const word = originErrorText.substitutes().items.find((sub) => sub.id() === value).data();

        sentenceInArray[key] = word;
      }

      if (errorText.originalText().data() === self.waitingForTranslation
        || errorText.originalText().data() === '') {
        nbTotalGameItems++;
        notTranslatedGameItemMap[errorText.originalText().originId()] = {
          id: errorText.originalText().id(),
          value: originErrorText.originalText().data(),
        };
      }

      if (self.falsyText() === '' || self.falsyText() === self.waitingForTranslation) {
        nbTotalGameItems++;
        const wrongSentence = sentenceInArray.join(' ');

        notTranslatedGameItemMap[ERROR_TEXT_WRONG_SENTENCE_ID] = {
          id: ERROR_TEXT_WRONG_SENTENCE_ID,
          value: wrongSentence,
        };
      }
    }

    const gameItemTooLong = gameItems.some((gameItem) => {
      return gameItem.data().length > gameItem.getSizeLimitation();
    });

    const itemsToTranslate = Object.values(notTranslatedGameItemMap);

    nbTotalGameItems += gameItems.length;

    self.disableConfirmButton(gameItemTooLong || (self.errorText() && !self.isFalsyValid()));
    self.itemsToTranslate(itemsToTranslate);
    self.disablePreviewButton(itemsToTranslate.length === nbTotalGameItems);
  };

  self.onBack = () => {
    m.route('/knowledge');
  };

  // eslint-disable-next-line complexity
  self.onTranslationResults = (items) => {
    if (!items.length)
      return;

    const itemsMap = items.reduce((acc, item) => {
      acc[item.id] = item.value;

      return acc;
    }, {});

    self.knowledge().gameItems().items.forEach((gameItem) => {
      const translatedItem = itemsMap[gameItem.id()];

      if (!translatedItem)
        return;

      gameItem.data(translatedItem);
    });

    if (self.errorText()) {
      const errorText = self.errorText()['Error Text']();
      const errorTextId = errorText.originalText().id();
      const errorTextData = errorText.originalText().data();

      if (errorTextData === '' || errorTextData === self.waitingForTranslation)
        errorText.originalText().data(itemsMap[errorTextId]);

      if (self.falsyText() === '' || self.falsyText() === self.waitingForTranslation) {
        const wrongSentence = itemsMap[ERROR_TEXT_WRONG_SENTENCE_ID];
        const substitutePositions = errorText.substitutePositions();
        const subs = errorText.substitutes();

        for (let pos in substitutePositions) {
          if (substitutePositions.hasOwnProperty(pos))
            subs.get(substitutePositions[pos]).data(wrongSentence.split(' ')[pos]);
        }

        self.falsyText(wrongSentence);
      }
    }

    self.setItemsToTranslate();
    self.modified(true);

    m.redraw();
  };

  self.onTranslate = (isTranslating) => {
    self.translating(isTranslating);
    m.redraw();

    if (!isTranslating)
      return;

    self.knowledge().gameItems().items.forEach((gameItem) => {
      if (gameItem.data() === self.waitingForTranslation)
        gameItem.data('');
    });
  };
};

component.view = function view(c) {
  if (c.loading()) {
    return m(loader, {
      loading: c.loading(),
    });
  }

  const originalLanguageId = c.original().segmentation().first({ dimensionId: Enum.dimensionTypes.LANGUAGE }).id();
  const translatedLanguageId = c.knowledge().segmentation().first({ dimensionId: Enum.dimensionTypes.LANGUAGE }).id();

  return m('.translationPage', [
    m('.header', [
      m(menu, {
        confirm: (action) => action(),
      }),
    ]),
    m('.translationPage__body', [
      m(reactCreator, {
        component: 'KnowledgeTranslationHeader',
        props    : {
          itemsToTranslate: c.itemsToTranslate,
          onTranslationResults: c.onTranslationResults,
          onSave: c.saveAll,
          onPreview: c.confirmPageExit,
          onValidate: c.validateTranslation,
          isModified: c.modified,
          knowledgeTitle: c.original().knowledgeTitle().data(),
          originalLanguageId,
          translatedLanguageId,
          onBack: c.onBack,
          onTranslationRequestStateChange: c.onTranslate,
          disablePreviewButton: c.disablePreviewButton,
          disableConfirmButton: c.disableConfirmButton,
        },
      }),
      m('.translation-table.newtranslation-table', [
        m('.translation-table__head', [
          m('.translation-table__head__origin', c.original().getTargetLanguage()),
          m('.translation-table__head__target', c.knowledge().getTargetLanguage()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.title_items')),
          displayGameItems(c.titleItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.question_items')),
          displayGameItems(c.questionItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.instruction_items')),
          displayGameItems(c.instructionItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.answer_items')),
          displayGameItems(c.answerItems()),
          displayGameItems(c.orderingAnswerItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.text_items')),
          displayGameItems(c.textItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.long_text_items')),
          displayGameItems(c.longTextItems()),
        ]),
        m('.translation-table__section', [
          m('.translation-table__title.translation-table__title--margin-top', t('content_translation:game_item.short_text_items')),
          displayGameItems(c.shortTextItems()),
          displayGameItems(c.helperItems()),
        ]),
        displayErrorText(),
      ]),
    ]),
  ]);

  function divify(o) {
    return m('', o);
  }

  function getGameItemInputClassName(gameItem) {
    const limit = gameItem.getSizeLimitation();
    const translated = c.isValidGI(gameItem);
    const value = gameItem.data();

    if (value.length > limit && value !== self.waitingForTranslation)
      return 'error';

    if (translated)
      return 'translated';

    return '';
  }

  function displayGameItems(gis) {
    // eslint-disable-next-line complexity
    return gis.map(function displayGameItem(gi) {
      const limit = gi.getSizeLimitation();
      const changed = gi.originData() && gi.originData() !== originalGI(gi).data();
      const inputClass = getGameItemInputClassName(gi);

      return m('.translation-table__row', {
        class: changed ? 'translation-table__row--changed' : '',
      }, [
        m('.translation-table__row__origin', [
          m('.translation-table__row__info-change', {
            class: changed ? '' : 'hidden',
          }),
          m('.translation-table__row__input', originalGI(gi).data().split('\n').map(divify)),
        ]),
        m('.translation-table__row__target', [
          m(
            '.translation-table__row__info-change',
            {
              class: changed ? '' : 'hidden',
            }, t('content_translation:source_label_changed_check_translation')
          ),
          m('.translation-table__row__input__wrapper', [
            m('textarea.translation-table__row__input', {
              class    : inputClass,
              value    : gi.data(),
              onfocus  : c.onfocus.bind(null, gi.data),
              oninput  : () => {
                m.withAttr('value', gi.data)();
                c.modified(true);
                c.setItemsToTranslate();
              },
              onkeydown: c.inputTestFactory.bind(null, gi, limit),
              onpaste  : c.resizeTextarea.bind(null, limit),
              oncut    : c.resizeTextarea.bind(null, limit),
              config   : c.initTextarea,
              onblur   : c.modified.bind(null, true),
              disabled: c.translating(),
            }),
            m.hide(!c.translating() || gi.data() !== '', '.translation-table__row__loading', [
              m('.translation-table__row__spinner',
                // eslint-disable-next-line no-magic-numbers
                new Array(12).fill(0).map(() => {
                  return m('.translation-table__row__spinner-bar');
                })
              ),
            ]),
            m('.translation--maxlength',
              {
                class: gi.data().length > limit && gi.data() !== self.waitingForTranslation ? 'white' : '',
              },
              [gi.data() === self.waitingForTranslation ? 0 : gi.data().length, limit].join(' / ')
            ),
          ]),
          m.hide(gi.data().length <= limit || gi.data() === self.waitingForTranslation, '.translation-table__row__limit-reached', t('content_translation:character_limit_reached')),
        ]),
      ]);
    });
  }

  function originalGI(gameItem) {
    var matching = c.original().gameItems().find({ id: gameItem.originId() });

    return matching.items[0];
  }

  function displayErrorText() {
    if (!c.errorText())
      return '';
    var errorText = c.errorText()['Error Text']();
    var originErrorText = c.originalErrorText()['Error Text']();

    return m('.translation-table__section', [
      m('.translation-table__title.translation-table__title--margin-top', t('content_translation:error_text.error_text_translation')),
      m('.translation-table__infos', {
        class: c.isFalsyValid() || errorText.originalText().data() === self.waitingForTranslation
          ? ''
          : 'translation-table__infos--incorrect',
      }, t('content_translation:error_text.the_following_sentences_must_have_the_same_number_of_words', { count:errorText.substitutes().length  })),
      m('.translation-table__subtitle', t('content_translation:error_text.right_sentence')),
      m('.translation-table__subtitle', t('content_translation:error_text.right_sentence')),
      m('.translation-table__row', [
        m('.translation-table__row__origin', [
          m('.translation-table__row__input', displayTrueByWords(originErrorText.originalText(), originErrorText.substitutePositions())),
        ]),
        m('.translation-table__row__target', [
          m.hide(!c.trueErrorTextFocus(), '.translation-table__row__input__wrapper', [
            m('textarea#trueErrorText.translation-table__row__input', {
              value    : errorText.originalText().data(),
              onfocus  : c.onfocus.bind(null, errorText.originalText().data),
              oninput: () => {
                m.withAttr('value', errorText.originalText().data)();
                c.modified(true);
                c.setItemsToTranslate();
              },
              onkeydown: c.inputTestFactory.bind(null, errorText.originalText(), 100),
              onpaste  : c.resizeTextarea.bind(null, 100),
              oncut    : c.resizeTextarea.bind(null, 100),
              config   : c.initTextarea,
              onblur   : c.computeErrorText.bind(null, null, errorText.originalText()),
              disabled: c.translating(),
            }),
            m(
              '.translation--maxlength',
              [errorText.originalText().data() === self.waitingForTranslation ? 0 : errorText.originalText().data().length, 100].join(' / ')
            ),
          ]),
          m.hide(c.trueErrorTextFocus(), '.translation-table__row__input__wrapper', [
            m('.translation-table__row__input', {
              class: errorText.originalText().data().length <= 100 ? '' : 'translation-table__row__input--incorrect',
              onclick: function () {
                c.trueErrorTextFocus(true);
                m.redraw();
                document.querySelector('#trueErrorText').focus();
              },
            }, c.isFalsyValid()
              ? displayTrueByWords(errorText.originalText(), errorText.substitutePositions(), true)
              : errorText.originalText().data()
            ),
            m(
              '.translation--maxlength',
              {
                class: errorText.originalText().data().length <= 100 ? '' : 'white',
              },
              [errorText.originalText().data() === self.waitingForTranslation ? 0 : errorText.originalText().data().length, 100].join(' / ')
            ),
            m.hide(!c.translating() || errorText.originalText().data() !== '', '.translation-table__row__loading', [
              m('.translation-table__row__spinner',
                // eslint-disable-next-line no-magic-numbers
                new Array(12).fill(0).map(() => {
                  return m('.translation-table__row__spinner-bar');
                })
              ),
            ]),
          ]),
          m.hide(errorText.originalText().data().length <= 100, '.translation-table__row__limit-reached', t('content_translation:character_limit_reached')),
        ]),
      ]),
      m('.translation-table__subtitle', t('content_translation:error_text.wrong_sentence')),
      m('.translation-table__subtitle', t('content_translation:error_text.wrong_sentence')),
      m('.translation-table__row', [
        m('.translation-table__row__origin', [
          m('.translation-table__row__input', displayFalseByWords(originErrorText.originalText(), originErrorText.substitutePositions(), originErrorText.substitutes())),
        ]),
        m('.translation-table__row__target', [
          m.hide(!c.falseErrorTextFocus(), '.translation-table__row__input__wrapper', [
            m('textarea#falseErrorText.translation-table__row__input', {
              value    : c.falsyText(),
              oninput: () => {
                m.withAttr('value', c.falsyText)();
                c.modified(true);
                c.setItemsToTranslate();
              },
              onpaste  : c.resizeTextarea.bind(null, 100),
              oncut    : c.resizeTextarea.bind(null, 100),
              onfocus  : c.onfocus.bind(null, c.falsyText),
              config   : c.initTextarea,
              onblur   : c.computeErrorText,
              disabled: c.translating(),
            }),
            m(
              '.translation--maxlength',
              [c.falsyText() === self.waitingForTranslation ? 0 : c.falsyText().length, 100].join(' / ')
            ),
          ]),
          m.hide(c.falseErrorTextFocus(), '.translation-table__row__input__wrapper', [
            m('.translation-table__row__input', {
              class: (c.isFalsyValid() || errorText.originalText().data() === self.waitingForTranslation) && errorText.originalText().data().length <= 100
                ? ''
                : 'translation-table__row__input--incorrect',
              onclick: function () {
                c.falseErrorTextFocus(true);
                m.redraw();
                document.querySelector('#falseErrorText').focus();
              },
            }, c.isFalsyValid()
              ? displayFalseByWords(errorText.originalText(), errorText.substitutePositions(), errorText.substitutes(), true)
              : c.falsyText()
            ),
            m(
              '.translation--maxlength',
              {
                class: c.falsyText().length <= 100 && (c.isFalsyValid() || errorText.originalText().data() === self.waitingForTranslation) ? '' : 'white',
              },
              [c.falsyText() === self.waitingForTranslation ? 0 : c.falsyText().length, 100].join(' / ')
            ),
            m.hide(!c.translating() || c.falsyText() !== '', '.translation-table__row__loading', [
              m('.translation-table__row__spinner',
                // eslint-disable-next-line no-magic-numbers
                new Array(12).fill(0).map(() => {
                  return m('.translation-table__row__spinner-bar');
                })
              ),
            ]),
          ]),
          m.hide(c.falsyText().length <= 100, '.translation-table__row__limit-reached', t('content_translation:error_text.error_text_translation')),
        ]),
      ]),
      m('.translation-table__row', [
        m('.translation-table__row__origin', []),
        m('.translation-table__row__target', [
          errorText.originalText().data() !== self.waitingForTranslation
            ? m('.error-text-translation__status-message', {
              class: c.isFalsyValid() || errorText.originalText().data() === self.waitingForTranslation
                ? 'error-text-translation__status-message--correct'
                : 'error-text-translation__status-message--incorrect',
            }, c.isFalsyValid() || errorText.originalText().data() === self.waitingForTranslation
                ? t('content_translation:error_text.correct_match')
                : t('content_translation:error_text.incorrect_match'))
            : '',
        ]),
      ]),
    ]);
  }

  function displayTrueByWords(gi, positions, withColor) {
    var words = gi.data().split(' ');
    var count = 1;

    return words.map(function displayWord(word, key) {
      var tempCount = count;

      if (positions[key])
        count++;

      return m('span', {
        class: positions[key]
          ? ['falsy-word', withColor ? ('falsy-word-' + tempCount) : ''].join(' ')
          : '',
      }, word + ' ');
    });
  }

  function displayFalseByWords(gi, positions, substitutes, withColor) {
    var words = gi.data().split(' ');

    for (var pos in positions) {
      if (positions.hasOwnProperty(pos)) {
        var sub = substitutes.get(positions[pos]);

        words[pos] = sub.data();
      }
    }
    var count = 1;

    return words.map(function displayWord(word, key) {
      var tempCount = count;

      if (positions[key])
        count++;

      return m('span', {
        class: positions[key]
          ? ['falsy-word', withColor ? ('falsy-word-' + tempCount) : ''].join(' ')
          : '',
      }, word + ' ');
    });
  }
};
export default component;
