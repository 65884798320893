// @flow

/**
 * Render dropDownItem to see who is logged
 *
 * Props:
 *   - mail: the mail of the user
 *   - style: override component's style
 *   - label: required default text label
 */


import * as React from 'react';

import styles from './ULoggedItem.style';

type Props = $ReadOnly<{|
  style?: ?Object,
  mail: string,
  label: string,
|}>;

export class ULoggedItem extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, mail, label } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <span style={styles.labelText}>
          {label}
        </span>
        <span style={styles.mailText}>
          {mail}
        </span>
      </div>
    );
  }
}

export default ULoggedItem;
