import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    minHeight: 400,
    width: 700,

    paddingTop: 31,
    paddingBottom: 48,
    paddingLeft: 30,
    paddingRight: 30,

    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  invitePending: {
    ...TYPOGRAPHY.BODY4,
    fontWeight: 400,
    color: COLORS.GREY_DARK.DEFAULT,
  },
  alignText: {
    justifyContent: 'flex-end',
  },
  segmentCell: {
    overflow: 'hidden',
  },
});

export default styleSheet;
