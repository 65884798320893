import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  modal: {
    margin: 0,
    width: '800px',
  },
  explanation: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: '16px',
  },
  callout: {
    marginBottom: '8px',
  },
});

export default styleSheet;
