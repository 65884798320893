import React, { FC, useCallback } from 'react';
import { t } from 'i18next';

import Enum, { Languages } from 'Models/Enum';
import { Activity } from 'Store/entities/activity/activity.type';
import { redirectToActivityAnalytics } from 'Pages/DolphinActivity/activity.utils';
import {
  getLegacyActivityAudience,
  legacyActivityTypeIdMap,
  mapActivityStatus,
  mapActivityStatusLabel,
} from 'Pages/DolphinActivity/activity.mapper';
import { COLORS } from 'Components/foundation';
import SActivityListItem from 'Components/structural/SActivityListItem/SActivityListItem';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';

import styles from '../ActivityList.style';
import { EmptyList } from './EmptyList';

export type ListProps = {
  activities: Activity[];
  hasFilters: boolean;
  loading: boolean;
};

const formatEndDate = (endDate?: string) => {
  return endDate ? endDate.split('T')[0] : '';
};

const Loader = () => {
  return (
    <div style={styles.loader}>
      <SPageLoaderWithTimer isLoading style={styles.loader} />
    </div>
  );
};

export const List: FC<ListProps> = ({ activities, loading, hasFilters }) => {
  const getOptions = useCallback(
    (archived: boolean) => [
      archived
        ? {
            title: t('activities:list.archive_activity'),
            color: COLORS.ERROR.DEFAULT,
            callback: () => {},
          }
        : {
            title: t('activities:list.unarchive_activity'),
            color: COLORS.TEXT.DEFAULT,
            callback: () => {},
          },
    ],
    [],
  );

  return (
    <>
      {!activities.length && loading && <Loader />}
      {!activities.length && !loading && hasFilters && <EmptyList />}
      {!!activities.length &&
        activities.map((activity) => {
          // TODO: use current selected language of bo or take the first one
          const title =
            Object.entries(activity.informations?.name || {}).find(([, value]) => value !== null)?.[1] || '';

          const languages = activity.languages?.map((code) => Languages[code as keyof typeof Languages] || code) || [];

          return (
            <SActivityListItem
              key={activity.id}
              id={activity.id}
              title={title}
              titlePlaceholder={t('activities:list.activity_title_placeholder')}
              activityType={legacyActivityTypeIdMap[activity.type]}
              activityIds={[activity.id]}
              audience={getLegacyActivityAudience(activity)}
              audiencePlaceholder={t('activities:list.item.audience.no_target_mode')}
              audienceEmptySegmentsLabel={t('activities:list.item.audience.empty_segments_label')}
              onAnalyticsClick={redirectToActivityAnalytics}
              startDate={activity.scheduling?.launchDate || ''}
              endDate={formatEndDate(activity.scheduling?.endDate)}
              image={activity.informations.coverImage?.url}
              languages={languages}
              activityStatus={mapActivityStatus(activity.status)}
              activityStatusLabel={mapActivityStatusLabel(activity.status)}
              // TODO: Not yet implemented but required
              onClick={() => {}}
              options={getOptions(false)}
              hasPlayerPlayed={false}
            />
          );
        })}
    </>
  );
};
