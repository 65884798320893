import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { getCdnFromS3Url } from 'Libs/cdn';

import Enum from 'Models/Enum';
import Video from 'Models/Video';

export type ContentType = Readonly<{
  id: number;
  gameplayId: number;
  knowledgeId: number;
  knowledgeCustomId: string;
  knowledgePendingTranslation: boolean;
  knowledgeArchived: boolean;
  statusId: number;
  title: string;
  imageUrl: string;
  locale: string;
  archived: boolean;
  multilingualId: number;
  languageId: number;
}>;

type ContentImage = Readonly<{
  url: string;
}>;

type SwipingCardProposition = DeepReadonly<{
  image?: {
    url: string;
  };
}>;

const makeTitle = (values: any): string => values?.knowledge?.knowledgeTitle?.data || '';

const getImageUrl = (image?: ContentImage) => (image ? getCdnFromS3Url(image.url, { asThumbnail: true }) : '');

const getVideoThumbnailUrl = (video?: any): string => (video ? new Video(video).getOriginalThumbnailSync() : '');

const getFrontOrBackMediaUrl = (gameplay: any) => {
  const front =
    gameplay.mediaTypeFrontId === Enum.mediaType.Image
      ? getImageUrl(gameplay.imageFront)
      : getVideoThumbnailUrl(gameplay.videoFront);
  const back =
    gameplay.mediaTypeBackId === Enum.mediaType.Image
      ? getImageUrl(gameplay.imageBack)
      : getVideoThumbnailUrl(gameplay.videoBack);

  return front || back;
};

const getTopOrBottomMediaUrl = (gameplay: any) => {
  const top =
    gameplay.mediaTypeTopId === Enum.mediaType.Image
      ? getImageUrl(gameplay.imageTop)
      : getVideoThumbnailUrl(gameplay.videoTop);
  const bottom =
    gameplay.mediaTypeBottomId === Enum.mediaType.Image
      ? getImageUrl(gameplay.imageBottom)
      : getVideoThumbnailUrl(gameplay.videoBottom);

  return top || bottom;
};

const getImageFromSwipingCardsPropositions = (propositions: ReadonlyArray<SwipingCardProposition>) =>
  propositions
    .filter((x) => x.image)
    .filter((_prop, i: number) => i === 0)
    .reduce((_prop, x) => getImageUrl(x.image), '');

const makeImageUrl = (values: any): string => {
  try {
    switch (values.gameplayId) {
      case Enum.gameplay.QCM:
        return getFrontOrBackMediaUrl(values.QCM);
      case Enum.gameplay.SQCM:
        return getFrontOrBackMediaUrl(values.SQCM);
      case Enum.gameplay.ERROR_TEXT:
        return getFrontOrBackMediaUrl(values['Error Text']);
      case Enum.gameplay.OPEN_QUESTION:
        return getFrontOrBackMediaUrl(values['Open Question']);
      case Enum.gameplay.NO_INTERACTION_CONTENT:
        return getTopOrBottomMediaUrl(values['No Interaction Content']);
      case Enum.gameplay.PICTURE_SPOT:
        return getImageUrl(values['Picture Spot'].image);
      case Enum.gameplay.SWIPING_CARDS:
        return getImageFromSwipingCardsPropositions(values['Swiping Cards'].propositions);
      default:
        return '';
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('content parse error:', err);

    return '';
  }
};

const makeFallbackImageUrl = (values?: any): string => {
  try {
    if (values?.knowledge.cover) {
      return getCdnFromS3Url(values.knowledge.cover.url, { asThumbnail: true });
    }

    if (values?.knowledge.images.length) {
      return getCdnFromS3Url(values.knowledge.images[0].url, { asThumbnail: true });
    }

    return '';
  } catch (err) {
    return '';
  }
};

const makeLanguageId = (values?: any): number => {
  const { segmentation } = values?.knowledge || {};
  const language = segmentation.find((seg: any) => seg.dimensionId === Enum.dimensionTypes.LANGUAGE);

  return language.id;
};

export default function createContent(values?: any): ContentType {
  const defaultState: ContentType = {
    id: 0,
    gameplayId: 0,
    knowledgeId: 0,
    knowledgeCustomId: '',
    knowledgePendingTranslation: false,
    knowledgeArchived: false,
    statusId: 0,
    title: '',
    imageUrl: '',
    locale: '',
    archived: false,
    multilingualId: 0,
    languageId: 0,
  };

  if (!values) {
    return defaultState;
  }

  const content: ContentType = {
    id: values.id,
    gameplayId: values.gameplayId,
    knowledgeId: values.knowledgeId,
    knowledgeCustomId: values.knowledge.customId || '',
    knowledgePendingTranslation: values.knowledge.pendingTranslation,
    knowledgeArchived: values.knowledge.archived,
    statusId: values.statusId,
    title: makeTitle(values),
    imageUrl: makeImageUrl(values) || makeFallbackImageUrl(values),
    locale: values.locale,
    archived: values.archived,
    multilingualId: values.multilingualId,
    languageId: makeLanguageId(values),
  };

  return mergeDeep(defaultState, content);
}
