import React, { FC, useMemo, useState } from 'react';

import { type IconName } from 'Components/foundation/icons';
import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';
import { UButton } from 'Components/unit/UButton/UButton';
import UClientLogo from 'Components/unit/UClientLogo/UClientLogo';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import ULanguageMenu, { type OptionType } from 'Components/unit/ULanguageMenu/ULanguageMenu';
import ULoggedItem from 'Components/unit/ULoggedItem/ULoggedItem';
import UProfile from 'Components/unit/UProfile/UProfile';
import { getCdnUrl } from 'Libs/cdn';
import Config from 'Services/instanceConfig';

import styles from './SMenu.style';
import { ButtonEvent } from 'Services/trackingService';

export type InternalLink = {
  name: string;
  path: string;
  trackingAction?: string;
  trackingContext?: string;
};

export type ExternalLink = {
  icon: IconName;
  tooltip: string;
  link: string;
  trackingAction?: string;
  trackingContext?: string;
};
export type UserMenuDropdownOption = {
  key: string;
  name: string;
  text: string;
  trackingAction?: ButtonEvent;
  trackingContext?: string;
  onClick?: () => void;
};

export type SMenuProps = {
  internalLinks: InternalLink[];
  externalLinks: ExternalLink[];
  menuDropdownOptions: UserMenuDropdownOption[];
  currentPathname: string;
  currentLangCode: string;
  langOptions: OptionType[];
  userInitials: string;
  onClickLogo: () => void;
  onOpenInternalLink: (link: string) => void;
  onOpenExternalLink: (link: string) => void;
};

function renderItem(item: UserMenuDropdownOption, key: any) {
  let componentObject;

  switch (item.name) {
    case 'ULoggedItem':
      componentObject = {
        ...item,
        key: key,
        mail: 'menu:loading',
      };

      return <ULoggedItem {...componentObject} />;
    case 'UDropdownItem':
      componentObject = {
        ...item,
        key: key,
      };

      return <UDropdownItem {...componentObject} />;

    default:
      return null;
  }
}

export const SMenu: FC<SMenuProps> = ({
  externalLinks,
  menuDropdownOptions,
  internalLinks,
  currentPathname,
  currentLangCode,
  langOptions,
  userInitials,
  onClickLogo,
  onOpenInternalLink,
  onOpenExternalLink,
}) => {
  const logoSrc = useMemo(
    () => (Config.instanceId === 'local' ? getCdnUrl('test', '/logo.png') : getCdnUrl(Config.instanceId, '/logo.png')),
    [],
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <nav style={styles.wrapper}>
      <a data-tracking-action="navigate" data-tracking-context="menu-logo" style={styles.logo}>
        <UClientLogo height={34} maxWidth={280} logoSrc={logoSrc} onClick={() => onClickLogo()} />
      </a>

      <div style={styles.internalLinkContainer}>
        {internalLinks.map((item) => (
          <UButton
            key={item.path}
            text={item.name}
            style={{
              ...styles.internalLink,
              ...(currentPathname === item.path ? styles.activeInternalLink : null),
            }}
            onClick={() => onOpenInternalLink(item.path)}
          />
        ))}
      </div>

      <div
        style={{
          ...styles.spacer,
          gridColumn: 3,
        }}
      />

      <div style={styles.externalLinkContainer}>
        {externalLinks.map((item) => (
          <UIconButton
            key={item.icon}
            icon={item.icon}
            style={styles.externalLink}
            ghost
            onClick={() => onOpenExternalLink(item.link)}
          />
        ))}
      </div>

      <div style={styles.languageItem}>
        <ULanguageMenu options={langOptions} countryCode={currentLangCode} style={styles.languageButton} />
      </div>

      <OutsideClickWrapper onClick={(isOutsideClicked) => (isOutsideClicked ? setIsDropdownOpen(false) : null)}>
        <div style={styles.profileItem}>
          <UProfile
            style={styles.profileButton}
            initials={userInitials}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
          {isDropdownOpen && (
            <div style={styles.userDropdownBox}>
              <UDropdownBox items={menuDropdownOptions} style={styles.dropDownMenu} renderItem={renderItem} />
            </div>
          )}
        </div>
      </OutsideClickWrapper>
    </nav>
  );
};

export default SMenu;
