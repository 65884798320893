import React, { useCallback, useEffect } from 'react';

import type { WithLoadingProps } from 'Components/hoc/withLoading/withLoading';
import type { UTextLinkProps } from 'Components/unit/UTextLink/UTextLink';
import UTextLinkWithLoading from 'Components/unit/UTextLink/UTextLinkWithLoading';
import useCountdown from 'Hooks/useCountdown/useCountdown';

export type UTextCountdownWithLoadingProps = {
  countdownFrom: number;
  countdownText?: string;
  countdownCallback?: () => void;
  countdownOnFirstRender?: boolean;
  onClick: () => void;
} & UTextLinkProps &
  Partial<WithLoadingProps>;

/**
 * UTextLink with capabilities for a 'cooldown' phase where the UTextLink is disabled
 *
 * Props:
 * - countdown: start of the cooldown in seconds. 15 becomes 15 -> ... -> 1
 * - countdownText: string appended with the countdown. "Try again in" -> "Try again in 15s"
 * - countdownCallback: fired when reaching zero
 * - countdownOnFirstRender: controls whether the countdown is active on first render
 *
 * - Also posesses all the props of UTextLinkProps & WithLoadingProps
 */
export const UTextCountdownWithLoading = ({
  countdownFrom,
  countdownText,
  countdownCallback,
  countdownOnFirstRender = false,
  text,
  loading,
  onRequestEnd,
  ...rest
}: UTextCountdownWithLoadingProps) => {
  const { start, currentCount } = useCountdown(countdownFrom, countdownCallback);
  const isDisabled = loading || Boolean(currentCount);
  const currentText = isDisabled ? `${countdownText || 'Try again in'} ${currentCount}s...` : text;

  useEffect(() => {
    if (countdownOnFirstRender) {
      start();
    }
  }, [countdownOnFirstRender, start]);

  const handleCountdownStart = useCallback(
    (param: unknown) => {
      start();
      return onRequestEnd?.(param);
    },
    [onRequestEnd, start],
  );

  return (
    <UTextLinkWithLoading
      disabled={isDisabled}
      loading={loading}
      text={currentText}
      onRequestEnd={handleCountdownStart}
      {...rest}
    />
  );
};

export default UTextCountdownWithLoading;
