import { apiAction } from 'Libs/redux/utils';
import type { ApiActionType } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from './models/Alert';

type NPSGetStatsAction = ApiActionType<'NPS_GET_STATS'>;
type NPSGetReportAction = ApiActionType<'NPS_GET_REPORT'>;

type NPSSetAlertAction = {
  type: 'NPS_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};

type NPSRemoveAlertAction = {
  type: 'NPS_REMOVE_ALERT';
  scope: AlertScope;
};

export const actions = {
  getStats: (): NPSGetStatsAction =>
    apiAction({
      type: 'NPS_GET_STATS',
      route: '/api/NPS/stats',
      method: 'GET',
    }),

  getReport: (): NPSGetReportAction =>
    apiAction({
      type: 'NPS_GET_REPORT',
      route: '/api/NPS/report',
      method: 'GET',
    }),

  setAlert: (scope: AlertScope, params: AlertParams = Object.freeze({})): NPSSetAlertAction => ({
    type: 'NPS_SET_ALERT',
    scope,
    params,
  }),

  removeAlert: (scope: AlertScope): NPSRemoveAlertAction => ({
    type: 'NPS_REMOVE_ALERT',
    scope,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type NpsAction = ReturnType<ActionsFn>;
