// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    minWidth: 20,
  },
  icon: {
    marginBottom: 4,
  },
  text: {
    userSelect: 'none',
    ...TYPOGRAPHY.BODY4,
  },
  textHidden: {
    visibility: 'hidden',
    height: 0,
  },
};
