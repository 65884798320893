export const YOUTUBE_ID_REGEX =
  // eslint-disable-next-line no-useless-escape -- the escape is not useless, eslint is dumb af
  /(?:https?:\/\/|\/\/)?(?:www.|m.|.+.)?(?:youtu.be\/|youtube.com\/(?:embed\/|v\/|shorts\/|feeds\/api\/videos\/|watch?v=|watch?.+&v=|watch?.+\?v=))([\w\-1-9]{11,})(?![\w\-1-9]{11,})/i;

export const getYoutubeVideoId = (url: string) => {
  const match = YOUTUBE_ID_REGEX.exec(url);

  return match && match?.[1]?.length === 11 ? match[1] : null;
};

export const normalizeYoutubeUrl = (url: string) => {
  const videoId = getYoutubeVideoId(url);

  return videoId ? `https://youtu.be/${videoId}` : null;
};
