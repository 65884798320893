import Promise from 'bluebird';
import button from 'components/button';
import _ from 'lodash';
import m from 'm';
import AccessControl from 'models/AccessControl';
import App from 'models/App';
import AuthorizedEmail from 'models/AuthorizedEmail';
import menu from 'pages/menu';
import popup from 'services/popupService';

var component = {};

component.controller = function controller() {
  var self = this;

  self.accessControl = m.prop({});
  self.loading = m.prop(true);
  self.newEmail = m.prop('');
  self.emailToDelete = m.prop([]);
  self.emailFilter = m.prop('');
  self.csvButtonsVisible = m.prop(false);
  self.filteredEmails = m.prop([]);
  self.changed = m.prop(false);
  self.errorMail = m.prop(false);
  self.saveLoading = m.prop(false);

  AccessControl.get(m.route.param('id'))
    .then(self.accessControl)
    .then(self.loading.bind(null, false))
    .then(function updateApp() {
      App.AuthenticationSettings()
        .find({
          id: self.accessControl().authenticationSettingsId(),
        })
        .at(0)
        .accessControls()
        .find({
          id: self.accessControl().id(),
        })
        .at(0)
        .emails(self.accessControl().emails());
      self.saveLoading(false);
    })
    .then(m.redraw.bind(null, null));

  self.addEmail = function addEmail(e) {
    e.preventDefault();

    var validation =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!self.newEmail().trim()) {
      self.errorMail(true);

      return;
    }

    if (!validation.test(self.newEmail())) {
      self.errorMail(true);

      return;
    }

    if (emailAlreadyInThelList()) {
      self.errorMail(true);

      return;
    }
    self.errorMail(false);

    var newEmail = new AuthorizedEmail({
      accessControlId: self.accessControl().id(),
      email: self.newEmail().toLowerCase(),
    });

    self.accessControl().emails().items.unshift(newEmail);
    self.newEmail('');
    self.changed(true);

    function emailAlreadyInThelList() {
      var displayedEmails = self
        .accessControl()
        .emails()
        .items.map(function filtrByText(email) {
          return email.email().toLowerCase();
        });

      return displayedEmails.indexOf(self.newEmail().toLowerCase()) > -1;
    }
  };

  self.deleteEmail = function deleteEmail(email) {
    self.accessControl().emails().searchAndRemove({
      email: email.email(),
    });
    if (!email.id()) return;
    self.emailToDelete().push(email);
    self.changed(true);
  };

  self.warningSave = function warningSave(action) {
    if (!self.changed()) return action();
    popup.open({
      type: 'confirm',
      title: 'Modifications not saved',
      content: 'Are you sure you want to do that? All unsaved modifications will be lost.',
      confirm: 'Do it',
      cancel: 'Cancel',
      action: action,
    });
  };

  self.save = function save() {
    self.saveLoading(true);
    m.redraw();

    return AuthorizedEmail.upsertSeveral(self.accessControl().emails())
      .then(self.accessControl().emails)
      .then(function deleteEmails() {
        var promises = self.emailToDelete().map(function deleteOne(email) {
          return email.destroy();
        });

        return Promise.all(promises);
      })
      .then(self.emailToDelete.bind(null, []))
      .then(self.changed.bind(null, false))
      .then(function modifyInApp() {
        App.AuthenticationSettings()
          .find({
            id: self.accessControl().authenticationSettingsId(),
          })
          .at(0)
          .accessControls()
          .find({
            id: self.accessControl().id(),
          })
          .at(0)
          .emails(self.accessControl().emails());
        self.saveLoading(false);
      })
      .then(m.redraw)
      .then(
        popup.open.bind(null, {
          type: 'info',
          title: 'Modification saved',
          subtitle: 'The modifications were saved successfuly',
          button: 'OK',
        }),
      )
      .catch(function logError(err) {
        console.debug(err);
        var message = err.message.error ? err.message.error.message : err.message;

        popup.open({
          type: 'info',
          title: 'Impossible to add this email',
          content: message,
          button: 'OK',
        });

        self.saveLoading(false);
        m.redraw();
      });
  };

  self.fileHandler = function fileHandler(replace, e) {
    var target = e.target;
    var file = target.files[0];

    if (file) {
      self
        .accessControl()
        .uploadWhiteList(file, _.noop, {
          replace: replace,
          accessControlId: self.accessControl().id(),
        })
        .then(function openPopup(response) {
          const route = m.route();

          popup.open({
            type: 'info',
            title: 'Success!',
            subtitle: ' ',
            content: response.count + ' email adressed has been white-listed.',
            button: 'Ok',
            action: () => m.route(route),
          });
        })
        .catch(function logError(err) {
          console.debug(err);
          alert('upload failed' + JSON.stringify(err));
        });
    }
  };

  self.filterEmails = function filterEmails(filter) {
    if (filter !== undefined) self.emailFilter(filter);
    self.filteredEmails(
      self
        .accessControl()
        .emails()
        .filter(function filtrByText(email) {
          return email.email().toLowerCase().indexOf(self.emailFilter().toLowerCase()) !== -1;
        }),
    );
  };
};

component.view = function view(c) {
  const route = m.route();

  if (c.loading()) return m('.loading');

  return m('.gaia__email-list__page', [
    m('.header', [m(menu)]),
    m('.gaia__email-list__body', [
      m('.gaia__email-list__toolbar', [
        m(button, {
          class: 'button--action',
          value: '< Back to authentication settings',
          action: c.warningSave.bind(null, () => m.route('/gaia/signup/segmented')),
        }),
        m('.gaia__email-list__toolbar--right', [
          m(button, {
            class: 'activity-toolbar__button--save button--border',
            value: 'Cancel',
            action: c.warningSave.bind(null, () => m.route(route)),
          }),
          m(button, {
            class: 'button--save ml',
            value: 'Save',
            action: c.save,
            loading: c.saveLoading(),
          }),
        ]),
      ]),
      m('.gaia__email-list__container', [
        m('.gaia__email-list__header', [
          m('.gaia__email-list__header__left', [
            m('.gaia__email-list__header__left', [
              m('.gaia__email-list__header__title', 'Granted email list'),
              m('.gaia__email-list__header__segmentation', displaySegmentation()),
            ]),
          ]),
          m('.gaia__email-list__header__right', [displayCSVButton()]),
        ]),
        m(
          'form.gaia__email-list__add',
          {
            onsubmit: c.addEmail,
          },
          [
            m('input.gaia__email-list__add-input', {
              class: c.errorMail() ? 'gaia__email-list__add-input--invalid' : '',
              type: 'text',
              value: c.newEmail(),
              oninput: m.withAttr('value', c.newEmail),
              placeholder: 'Add an email here...',
            }),
            m('input.gaia__email-list__add-button.button--action', {
              type: 'submit',
              value: '+ Add e-mail',
            }),
          ],
        ),
        m('.gaia__email-list__table', [
          m('.gaia__email-list__table__line.gaia__email-list__table__line--header', [
            m(
              '.gaia__email-list__table__cell.gaia__email-list__table__cell--email.gaia__email-list__table__cell--header',
              [
                m('.gaia__email-list__table__email-cell__title', 'E-mail adresses'),
                m('input.gaia__email-list__table__email-cell__input', {
                  type: 'text',
                  placeholder: 'Search an email here...',
                  value: c.emailFilter(),
                  oninput: m.withAttr('value', c.filterEmails),
                }),
              ],
            ),
            m(
              '.gaia__email-list__table__cell.gaia__email-list__table__cell--status.gaia__email-list__table__cell--header',
              'Status',
            ),
          ]),
          (c.emailFilter() ? c.filteredEmails() : c.accessControl().emails()).map(displayLine),
        ]),
      ]),
    ]),
  ]);

  function displayLine(email, key) {
    return m(
      '.gaia__email-list__table__line',
      {
        class: key % 2 === 0 ? 'gaia__email-list__table__line--even' : '',
      },
      [
        m('.gaia__email-list__table__cell.gaia__email-list__table__cell--email', email.email()),
        m('.gaia__email-list__table__cell.gaia__email-list__table__cell--status', [
          '...',
          m(
            '.gaia__email-list__table__cell__overlay-command.gaia__email-list__table__cell__overlay-command--delete',
            {
              onclick: c.deleteEmail.bind(null, email),
            },
            'Delete e-mail',
          ),
        ]),
      ],
    );
  }

  function displayCSVButton() {
    if (!c.csvButtonsVisible()) {
      return m(button, {
        class: 'activity-toolbar__button--save button--action button--border button__upload-purple',
        value: 'Import .csv list',
        action: c.csvButtonsVisible.bind(null, !c.csvButtonsVisible()),
      });
    }

    return [
      m('label.button.mail-list-button--upload.button--action.button__upload', [
        m('.button__elem', [m('.button__value', 'Add to the list')]),
        m('input.hide', {
          type: 'file',
          accept: '.csv',
          onchange: c.fileHandler.bind(null, false),
        }),
      ]),
      m('label.button.mail-list-button--upload.button--delete.button__upload', [
        m('.button__elem', [m('.button__value', 'Replace the list')]),
        m('input.hide', {
          type: 'file',
          accept: '.csv',
          onchange: c.fileHandler.bind(null, true),
        }),
      ]),
    ];
  }

  function displaySegmentation() {
    return c.accessControl().segmentation().map(displaySegmentationTag);
  }

  function displaySegmentationTag(group) {
    return m(
      '.segmentation-tag',
      {
        style: {
          // [NOPOINTER] to change when fix dependance circulaire
          'background-color': group.dimension().color || '#52AFDF',
        },
        title: group.dimension().label,
      },
      [m('.segmentation-tag__label', group.label())],
    );
  }
};

export default component;
