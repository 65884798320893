import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  wrapperVertical: {
    width: 1000,
  },
  wrapperHorizontal: {
    width: 1000,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  subtitleHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  subtitleWrapper: {
    display: 'flex',
  },
  accentuatedWrapper: {
    paddingTop: 62,
    paddingBottom: 56,
  },
  standardWrapper: {
    paddingTop: 56,
    paddingBottom: 24,
  },
  compactWrapper: {
    paddingTop: 56,
    paddingBottom: 24,
  },
  title: {
    maxWidth: 724,
  },
  subtitle: {
    maxWidth: 724,
  },
  iconSubtitleSM: {
    minWidth: 'fit-content',
    marginTop: 4,
    marginRight: 4,
  },
  iconSubtitleL: {
    minWidth: 'fit-content',
    marginTop: 8,
    marginRight: 4,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 724,
    marginRight: 32,
  },
  dynamicText: {
    fontWeight: 800,
  },
  accentuatedIcon: {
    marginLeft: 16,
  },
  standardIcon: {
    marginLeft: 16,
  },
  compactIcon: {
    marginLeft: 8,
  },
});

export default styles;
