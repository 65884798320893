import type { CourseData } from '../MCoursePublication';

type ModalStatus = {
  success: boolean;
  wasUpdating: boolean;
  withErrors: boolean;
};

export type ModalStateTypes =
  | 'IMAGE_CONFIRMATION'
  | 'IMAGE_SELECTION'
  | 'UNPUBLISHING'
  | 'PUBLISHING'
  | 'UPDATING'
  | 'UNPUBLISH_SUCCESS'
  | 'PUBLISH_SUCCESS'
  | 'UPDATE_SUCCESS'
  | 'ERRORS';

export const getCurrentModalState = (
  courseData: CourseData,
  hasLocalImage: boolean,
  isPublished: boolean,
  { success, wasUpdating, withErrors }: ModalStatus,
): ModalStateTypes => {
  if (withErrors) {
    return 'ERRORS';
  }

  if (!courseData.image) {
    return hasLocalImage ? 'IMAGE_CONFIRMATION' : 'IMAGE_SELECTION';
  }

  if (!success) {
    if (!isPublished) {
      return 'UNPUBLISHING';
    }

    return courseData.status === 'unpublished' ? 'PUBLISHING' : 'UPDATING';
  }

  if (wasUpdating) {
    return 'UPDATE_SUCCESS';
  }

  return isPublished ? 'PUBLISH_SUCCESS' : 'UNPUBLISH_SUCCESS';
};
