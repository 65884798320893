import { LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const VIDEO_WIDTH = '532';
export const VIDEO_HEIGHT = '296';

export default createStyleSheet({
  container: {
    display: 'flex',
    marginTop: 10,
  },
  editionBar: {
    position: 'fixed',
    top: 0,
    width: '100%',
    borderBottom: '1px solid #EDEDED',
    zIndex: LEVELS.LOCAL_OFFSET,
    minWidth: 950,
  },
});
