import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import UButton from 'Components/unit/UButton/UButton';

import styles from './AudienceBackButton.style';

import { actions } from '../../../redux';

export const AudienceBackButton = () => {
  const dispatch = useTypedDispatch();
  const onClick = useCallback(() => dispatch(actions.setTargetModeId(0)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <UButton text={t('activities:audience.change_mode')} onClick={onClick} />
    </div>
  );
};
