import request from 'services/requestService';
import Model from 'models/Model';
import Enum from 'models/Enum';

var Analytics = {
  endpoint    : 'api/Analytics',
  attributes  : {},
  classMethods: {
    getReports: function getReports(_Analytics, reportId, filter) {
      return request
        .get('api/Analytics/reports/', {
          reportId    : reportId,
          startDate   : filter.startDate,
          endDate     : filter.endDate,
          responseType: 'arraybuffer',
        });
    },
  },
};

export default Model.register('Analytics', Analytics);
