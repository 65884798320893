import { GAME_TYPE, LanguageCode, OpenQuestion } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { DeepPartial } from 'ts-essentials';
import { useFormContext } from 'react-hook-form';

import Enum from 'Models/Enum';

import UToggle from 'Components/unit/UToggle/UToggle';
import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';
import STextArea from 'Components/structural/STextArea/STextArea';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker, { ShowableVideo, isShowableVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import generalStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';
import { ANSWER_CHAR_LIMIT, EXPLANATION_CHAR_LIMIT, QUESTION_CHAR_LIMIT } from './gameplay.constants';
import { VIDEO_PROVIDERS } from 'Components/modal/MVideoUpload/utils';

import { BaseContentFormValues } from '../ContentEdition/ContentEdition';

type OpenQuestionFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

const LEVENSTEIN_DISTANCE = 2;

export const OpenQuestionForm = ({ language, isGameplayAlreadyChosen, editGameplay }: OpenQuestionFormProps) => {
  const { watch, setValue } = useFormContext<BaseContentFormValues & { game: DeepPartial<OpenQuestion> }>();

  const onNewGameMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.gameMedia.video', newMedia.media);
      }
      return setValue('game.gameMedia.image', newMedia.media);
    },
    [setValue],
  );
  const onNewExplanationMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.explanationMedia.video', newMedia.media);
      }
      return setValue('game.explanationMedia.image', newMedia.media);
    },
    [setValue],
  );

  return (
    <div style={generalStyles.wrapper}>
      <div style={generalStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.OPEN_QUESTION}
          title={t('gameplays:open_question.label')}
          description={t('gameplays:open_question.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:question')}</ULabel>
          <div style={{ ...generalStyles.imageTextSection, ...generalStyles.sectionSeparation }}>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('game.gameMedia.image.url') || ''}
              video={
                isShowableVideo(watch('game.gameMedia.video'))
                  ? (watch('game.gameMedia.video') as ShowableVideo)
                  : undefined
              }
              media={{
                mediaType: 'imageOrVideo',
                onChange: onNewGameMedia,
                providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
              }}
              onClearMedia={() => {
                setValue('game.gameMedia', {});
              }}
            />
            <STextArea
              canResize={false}
              placeholder={t('gameplays:open_question.question_placeholder')}
              height="80"
              onChange={(text) => {
                setValue(`game.question.${language}`, text);
              }}
              style={generalStyles.imageTextArea}
              value={watch(`game.question.${language}`) || ''}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              maxLength={QUESTION_CHAR_LIMIT}
            />
          </div>
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:answer')}</ULabel>
          <STextArea
            canResize={false}
            placeholder={t('gameplays:open_question.answer_placeholder')}
            height="80"
            onChange={(text) => {
              setValue(`game.answer.${language}`, text);
            }}
            radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
            value={watch(`game.answer.${language}`) || ''}
            maxLength={ANSWER_CHAR_LIMIT}
          />
          <div style={generalStyles.smallSeparation}>
            <UToggle
              toggled={(watch('game.levenshteinDistance') && watch('game.levenshteinDistance')! > 0) || false}
              onChange={(toggled) => {
                setValue('game.levenshteinDistance', toggled ? LEVENSTEIN_DISTANCE : 0);
              }}
              outsideLabel={
                watch('game.levenshteinDistance') && watch('game.levenshteinDistance')! > 0
                  ? t('gameplays:open_question.strict_match_enabled')
                  : t('gameplays:open_question.strict_match_disabled')
              }
            />
          </div>
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:explanation')}</ULabel>
          <div style={generalStyles.imageTextSection}>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('game.explanationMedia.image.url') || ''}
              video={
                isShowableVideo(watch('game.explanationMedia.video'))
                  ? (watch('game.explanationMedia.video') as ShowableVideo)
                  : undefined
              }
              media={{
                mediaType: 'imageOrVideo',
                onChange: onNewExplanationMedia,
                providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
              }}
              onClearMedia={() => {
                setValue('game.explanationMedia', {});
              }}
            />
            <STextArea
              canResize={false}
              placeholder={t('gameplays:open_question.explanation_placeholder')}
              height="130"
              onChange={(text) => {
                setValue(`game.explanation.${language}`, text);
              }}
              style={generalStyles.imageTextArea}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              value={watch(`game.explanation.${language}`) || ''}
              maxLength={EXPLANATION_CHAR_LIMIT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
