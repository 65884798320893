import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
    padding: '32px 24px 16px 24px',
    borderRadius: '8px',
  },
  gameplayItem: {
    marginBottom: '24px',
  },
  uTextSection: {
    marginTop: '16px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styleSheet;
