import React, { CSSProperties, FC, useCallback, useMemo } from 'react';

import styles from './STabList.style';

type Align = 'left' | 'center';

interface STabItemProps {
  selectedItem: number;
  index: number;
  tab: string;
  nbTab: number;
  compact: boolean;
  onChangeTab: (index: number) => void;
}

const STabItem: FC<STabItemProps> = ({ selectedItem, index, tab, nbTab, compact, onChangeTab }) => {
  const handleOnClick = useCallback(() => {
    if (index !== selectedItem) {
      onChangeTab(index);
    }
  }, [index, onChangeTab, selectedItem]);

  const tabStyle = useMemo(
    () => ({
      ...styles.tab,
      ...(compact ? styles.tabCompact : {}),
      ...(selectedItem === index ? styles.selectedTab : {}),
      ...(index < nbTab - 1 ? (compact && { marginRight: 16 }) || { marginRight: 24 } : {}),
    }),
    [compact, index, nbTab, selectedItem],
  );

  return (
    <div data-test-id={index} style={tabStyle} onClick={handleOnClick}>
      {tab}
    </div>
  );
};

export interface STabListProps {
  tabs: string[];
  selectedItem: number;
  onChangeTab: (index: number) => void;
  align?: Align;
  compact?: boolean;
  style?: CSSProperties;
}

/**
 * Display a horizontal navigation within a page
 *
 * Props:
 *  - align: String 'left' | 'center'. Position of the items
 *  - tabs: Array of String. Define the tabs
 *  - onChangeTab: Function call when we change tab
 *  - selectedItem: Index of the current selected item
 *  - compact: If true, change the font size
 *  - style: Object. Component's style
 */
export const STabList: FC<STabListProps> = ({
  tabs,
  selectedItem,
  onChangeTab,
  align = 'left',
  compact = false,
  style = {},
}) => {
  const rootStyle = useMemo(
    () => ({
      ...styles.wrapper,
      ...(align === 'left' ? styles.alignLeft : styles.alignCenter),
      ...style,
    }),
    [align, style],
  );
  return (
    <div style={rootStyle}>
      {tabs.map((tab: string, index: number) => (
        <STabItem
          key={tab}
          compact={compact}
          index={index}
          nbTab={tabs.length}
          tab={tab}
          selectedItem={selectedItem}
          onChangeTab={onChangeTab}
        />
      ))}
    </div>
  );
};

export default STabList;
