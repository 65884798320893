import { StyleSheet as StyleAphrodite } from 'aphrodite';

import { createStyleSheet } from 'Components/foundation/stylesheets';
import { ELEVATIONS, COLORS, TYPOGRAPHY } from 'Components/foundation';

export const OPTIONS_MENU_LEFT_OFFSET = {
  S: { marginLeft: 16 },
  M: { marginLeft: 32 },
};

export const styles = createStyleSheet({
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
    borderRadius: 12,
    boxShadow: ELEVATIONS.LIGHT_TINY,
  },
  deletedWrapper: {
    display: 'flex',
    position: 'relative',
    borderRadius: 12,
    width: 'fit-content',
  },
  wrapperS: {
    height: 96,
  },
  wrapperM: {
    height: 144,
  },
  title: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleM: {
    width: 290,
    height: 58,
    marginTop: 54,
    marginLeft: 24,
    ...TYPOGRAPHY.TITAN_M_SEMIBOLD,
  },
  titleS: {
    width: 179,
    height: 34,
    marginTop: 34,
    marginLeft: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ...TYPOGRAPHY.H3,
  },
  rightContainer: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: '100%',
  },
  deletedRightContainer: {
    border: `1px solid ${COLORS.PRIMARY.DISABLED}`,
  },
  rightContainerM: {
    width: 330,
  },
  rightContainerS: {
    width: 212,
  },
  leftContainer: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  leftContainerS: {
    width: 96,
  },
  leftContainerM: {
    width: 144,
    height: 144,
  },
  statusIndicatorContainer: {
    marginLeft: 8,
  },
  optionsWrapperDeleted: {
    userSelect: 'none',
  },
  optionsMenu: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1000,
  },
  courseImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  deletedCourseImg: {
    opacity: '50%',
  },
  deletedBackground: {
    backgroundColor: COLORS.PRIMARY.DISABLED,
  },
  baseWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    minHeight: 37,
    padding: 10,
    paddingRight: 16,
    userSelect: 'none',
  },
});

export const extendedStyles = StyleAphrodite.create({
  wrapper: {
    ':hover': {
      boxShadow: ELEVATIONS.LIGHT_HIGH,
      transition: 'box-shadow 0.3s ease-in-out',
      cursor: 'pointer',
    },
  },
});
