import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: '100%',
    width: '100%',

    // compensate top menu
    paddingTop: 104,
  },
});
