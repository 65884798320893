/* eslint-disable react/jsx-no-bind */
/* eslint-disable key-spacing */
// @flow

import m from 'mithril';
import moment from 'moment';

import React from 'react';
import { connect } from 'react-redux';

import Enum from 'Models/Enum';
import SContentActionBar from 'Components/structural/SContentActionBar/SContentActionBar';

import type { ContentType } from '../redux/models/Content';
import type { KnowledgeInfoType } from '../redux/models/Knowledge';
import type { Dispatch, State as ReduxState } from 'Libs/redux/types';
import type { ContentOptionType, ActionConfigType } from 'Components/structural/SContentActionBar/SContentActionBar';
import type { AlertScope, AlertParams, AlertValidationField, AlertValidationParams } from '../redux/models/Alert';
import type { ValidationConfig } from '../redux/contentReducer';

import { ContentDeleteModal } from './components/ContentDeleteModal/ContentDeleteModal';
import { UnvalidateModal } from './components/UnvalidateModal/UnvalidateModal';
import { ContentSaveModal } from './components/ContentSaveModal/ContentSaveModal';

import { validateContent } from '../redux/models/Content';

import { actions } from '../redux';

import styles from './ContentActionBar.style';

type OwnProps = {||};

type DispatchProps = {|
  saveContent: () => mixed,
  previewContent: () => mixed,
  setAlert: (scope: AlertScope, params?: AlertParams) => mixed,
  removeAlert: (scope: AlertScope) => mixed,
  changeStatus: (status: number) => mixed,
  archive: (archived: boolean) => Promise<void>,
|};

type StateProps = {|
  content: ContentType,
  knowledge: KnowledgeInfoType,
  actionsConfig: $ReadOnlyArray<ActionConfigType>,
  canEditContent: boolean,
  canEditValidContent: boolean,
  userCanAskValidation: boolean,
  hasChanged: boolean,
  isPreviewable: boolean,
|};

type Props = {|
  ...DispatchProps,
  ...StateProps,
|};

type State = {|
  deleteModalVisible: boolean,
  saveModalVisible: boolean,
  unvalidateModalVisible: boolean,
|};

const mapStateToProps = (state: ReduxState) => ({
  content: state.pages.content.content,
  knowledge: state.pages.content.knowledgeInfo,
  actionsConfig: state.pages.content.actionsConfig,
  canEditContent: state.pages.content.canEditContent,
  canEditValidContent: state.pages.content.canEditValidContent,
  userCanAskValidation: state.pages.content.canAskForValidation,
  hasChanged: state.pages.content.originalContent !== state.pages.content.content,
  isPreviewable: state.pages.content.isPreviewable,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveContent: () => dispatch(actions.save()),
  previewContent: () => dispatch(actions.setPreviewVisibility(true)),
  setAlert: (scope: AlertScope, params?: AlertParams) => dispatch(actions.setAlert(scope, params)),
  removeAlert: (scope: AlertScope) => dispatch(actions.removeAlert(scope)),
  changeStatus: (status: number) => dispatch(actions.setStatus(status)),
  archive: (archived: boolean) => {
    const action = actions.archive(archived);

    dispatch(action);

    return action.promise;
  },
});

export class ContentActionBar extends React.Component<Props, State> {
  state = {
    deleteModalVisible: false,
    saveModalVisible: false,
    unvalidateModalVisible: false,
  };

  componentDidMount() {
    window.onpopstate = this.handleBack;
  }

  componentWillUnmount() {
    window.onpopstate = () => {};
  }

  render() {
    const {
      knowledge,
      content,
      isPreviewable,
      previewContent,
      actionsConfig,
      hasChanged,
    } = this.props;
    const {
      deleteModalVisible,
      unvalidateModalVisible,
      saveModalVisible,
    } = this.state;

    const { title, id: knowledgeId, customId, pendingTranslation } = knowledge;

    const statusObject = this.getStatus(content);
    const lastSave = this.getLastDate(content);
    const { canValidate, canAskValidation } = this.getValidationConfig(content);
    const id = customId || knowledgeId;
    const backWording = pendingTranslation ? 'Back to knowledge' : 'Back';

    let validationWording = '';

    let validationCallback = this.handleValidate.bind(null, Enum.contentStatus.TO_VALIDATE);

    let helper;

    if (canValidate) {
      validationWording = 'Validate';
      validationCallback = this.handleValidate.bind(null, Enum.contentStatus.VALIDATED);
    } else if (canAskValidation)
      validationWording = 'Ask for validation';

    const options = this.makeOptions();

    return (
      <div style={styles.wrapper}>
        <SContentActionBar
          id={id}
          title={title}
          status={statusObject.status}
          statusWording={statusObject.statusWording}
          validationWording={validationWording}
          previewWording='Preview'
          onBack={this.handleOpenSaveModal}
          isTranslated={knowledge.pendingTranslation}
          options={options}
          lastSaved={lastSave}
          helper={helper}
          onSave={this.handleSave}
          canSave={hasChanged}
          canPreview={isPreviewable}
          onPreview={previewContent}
          onValidate={validationCallback}
          canValidate={!hasChanged}
          backWording={backWording}
          actionsConfig={actionsConfig}
        />
        <ContentDeleteModal
          visible={deleteModalVisible}
          onAction={this.handleDeleteModalAction}
        />
        <UnvalidateModal
          visible={unvalidateModalVisible}
          onAction={this.handleUnvalidateModalAction}
        />
        <ContentSaveModal
          visible={saveModalVisible}
          onAction={this.handleLeaveWithSaveAction}
          onActionEnd={this.handleCloseDialogBeforeLeave}
          onDismiss={this.handleBack}
          onClose={this.handleCloseSaveDialog}
        />
      </div>
    );
  }

  handleSave = (): Promise<any> => {
    const { saveContent } = this.props;

    return Promise.resolve(saveContent());
  };

  handleDelete = (): Promise<any> => {
    const { archive } = this.props;

    return Promise.resolve()
      .then(() => archive(true))
      .then((res) => {
        if (res && res.error)
          return;

        this.handleBack();
      });
  };

  handleUnvalidate = (): Promise<any> => {
    const { changeStatus } = this.props;

    return Promise.resolve(changeStatus(Enum.contentStatus.DRAFT));
  };

  handleValidate = (newStatus: number): Promise<any> => {
    const { content, changeStatus } = this.props;

    const { fields, params: validationParams } = validateContent(content);

    this.updateValidationErrorAlert(fields, validationParams);

    if (fields.length)
      return Promise.resolve({ error: true });

    return Promise.resolve(changeStatus(newStatus));
  };

  handleBack = () => {
    const { knowledge } = this.props;

    const route = `/knowledge/${knowledge.id}`;

    m.redraw();
    m.route(route);
  };

  handleOpenDeleteModal = () => {
    this.setState({ deleteModalVisible: true });
  };

  handleDeleteModalAction = (success: boolean) => {
    this.setState({ deleteModalVisible: false });

    if (success)
      return this.handleDelete();

    return Promise.resolve();
  };

  handleOpenUnvalidateModal = () => {
    this.setState({ unvalidateModalVisible: true });
  };

  handleUnvalidateModalAction = (success: boolean): Promise<any> => {
    this.setState({ unvalidateModalVisible: false });

    if (success)
      return this.handleUnvalidate();

    return Promise.resolve();
  };

  handleOpenSaveModal = () => {
    const { hasChanged, canEditContent } = this.props;

    if (hasChanged && canEditContent)
      return this.setState({ saveModalVisible: true });

    return this.handleBack();
  };

  handleLeaveWithSaveAction = (): Promise<any> => {
    const { saveContent } = this.props;

    return Promise.resolve(saveContent());
  };

  handleCloseDialogBeforeLeave = () => {
    this.setState({ saveModalVisible: false });

    this.handleBack();
  };

  handleCloseSaveDialog = () => {
    this.setState({ saveModalVisible: false });
  };

  updateValidationErrorAlert = (
    fields: $ReadOnlyArray<AlertValidationField>,
    validationParams?: AlertValidationParams,
  ) => {
    const { setAlert, removeAlert } = this.props;

    if (fields.length > 0)
      setAlert('validationError', { fields, validationParams });
    else
      removeAlert('validationError');
  };

  getLastDate = (content: ContentType): string => {
    const { canEditContent } = this.props;
    const { updatedAt } = content;

    if (!updatedAt || !canEditContent)
      return '';

    return `${moment(updatedAt).calendar(null, {
      sameDay: '[Last saved at] LT',
      nextDay: '',
      nextWeek: '',
      lastDay: '[Last saved yesterday at] LT',
      lastWeek: '[Saved last] dddd',
      sameElse: '[Last saved:] DD/MM/YYYY',
    })}`;
  };

  getStatus = (content: ContentType) => {
    const { statusId } = content;

    const statusObject = {
      status: 'disabled',
      statusWording: 'Not saved',
      tooltip: '',
    };

    switch (statusId) {
      case Enum.contentStatus.DRAFT:
        statusObject.status = 'disabled';
        statusObject.statusWording = 'Draft';
        break;
      case Enum.contentStatus.TO_VALIDATE:
        statusObject.status = 'warning';
        statusObject.statusWording = 'Waiting for validation';
        break;
      case Enum.contentStatus.VALIDATED:
        statusObject.status = 'active';
        statusObject.statusWording = 'Validated';
        break;
      default:
        break;
    }

    return statusObject;
  };

  makeOptions = (): $ReadOnlyArray<ContentOptionType> => {
    const {
      canEditContent,
      canEditValidContent,
      knowledge: { pendingTranslation },
      content,
    } = this.props;
    const { statusId } = content;
    const options = [];

    if (canEditContent && !pendingTranslation) {
      options.push({
        title: 'Delete content',
        callback: this.handleOpenDeleteModal,
      });
    }

    if (statusId === Enum.contentStatus.VALIDATED && canEditValidContent) {
      options.push({
        title: 'Unvalidate',
        callback: this.handleOpenUnvalidateModal,
      });
    }

    return options;
  };

  getValidationConfig = (content: ContentType): ValidationConfig => {
    const { canEditValidContent, userCanAskValidation } = this.props;
    const { statusId } = content;

    const isDraft = statusId === Enum.contentStatus.DRAFT;
    const isToValidate = statusId === Enum.contentStatus.TO_VALIDATE;
    const canAskForValidation = isDraft && userCanAskValidation;

    return {
      canValidate: (isDraft || isToValidate) && canEditValidContent,
      canAskValidation: canAskForValidation,
    };
  };
}

export default connect<Props, OwnProps, _, _, ReduxState, Dispatch>(mapStateToProps, mapDispatchToProps)(ContentActionBar);
