import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const UsersIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6414 35.0405C20.5132 34.9958 24.4488 31.0259 24.4488 26.1353C24.4488 21.2169 20.4684 17.2297 15.5582 17.2297C10.6481 17.2297 6.66767 21.2169 6.66767 26.1353C6.66767 31.026 10.6034 34.9959 15.4753 35.0405C6.92086 35.0853 -2.28882e-05 42.0338 -2.28882e-05 50.5988V51.4453C-2.28882e-05 52.2219 0.629529 52.8514 1.40612 52.8514H29.7109C30.4874 52.8514 31.117 52.2219 31.117 51.4453V50.5988C31.117 42.0337 24.196 35.0851 15.6414 35.0405Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6414 35.0405C20.5132 34.9958 24.4488 31.0259 24.4488 26.1353C24.4488 21.2169 20.4684 17.2297 15.5582 17.2297C10.6481 17.2297 6.66767 21.2169 6.66767 26.1353C6.66767 31.026 10.6034 34.9959 15.4753 35.0405C6.92086 35.0853 -2.28882e-05 42.0338 -2.28882e-05 50.5988V51.4453C-2.28882e-05 52.2219 0.629529 52.8514 1.40612 52.8514H29.7109C30.4874 52.8514 31.117 52.2219 31.117 51.4453V50.5988C31.117 42.0337 24.196 35.0851 15.6414 35.0405Z"
      fill={color.HOVER}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5244 35.0405C64.3962 34.9958 68.3318 31.0259 68.3318 26.1353C68.3318 21.2169 64.3514 17.2297 59.4413 17.2297C54.5311 17.2297 50.5507 21.2169 50.5507 26.1353C50.5507 31.026 54.4864 34.9959 59.3583 35.0405C50.8039 35.0853 43.883 42.0338 43.883 50.5988V51.4453C43.883 52.2219 44.5126 52.8514 45.2891 52.8514H73.5939C74.3705 52.8514 75 52.2219 75 51.4453V50.5988C75 42.0337 68.079 35.0851 59.5244 35.0405Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.9141 26.1353C67.9141 31.0537 63.9336 35.0409 59.0235 35.0409C54.1134 35.0409 50.1329 31.0537 50.1329 26.1353C50.1329 21.2169 54.1134 17.2297 59.0235 17.2297C63.9336 17.2297 67.9141 21.2169 67.9141 26.1353ZM43.883 50.5988V51.4453C43.883 52.2219 44.5126 52.8514 45.2891 52.8514H73.5939C74.3705 52.8514 75 52.2219 75 51.4453V50.5988C75 42.0061 68.0342 35.0403 59.4415 35.0403C50.8488 35.0403 43.883 42.0061 43.883 50.5988Z"
      fill={color.HOVER}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3804 40.2356C43.2525 40.1912 47.1884 36.2212 47.1884 31.3304C47.1884 26.412 43.2079 22.4248 38.2978 22.4248C33.3877 22.4248 29.4072 26.412 29.4072 31.3304C29.4072 36.2213 33.3432 40.1913 38.2154 40.2356C29.6607 40.2801 22.7395 47.2287 22.7395 55.7939V56.1118C22.7395 57.1803 23.6057 58.0465 24.6742 58.0465H51.9218C52.9903 58.0465 53.8565 57.1803 53.8565 56.1118V55.7939C53.8565 47.2286 46.9352 40.2799 38.3804 40.2356Z"
      fill={color.DEFAULT}
    />
  </svg>
);

export default UsersIllustration;
