import { ELEVATIONS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  contentList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '16px',
    marginTop: '24px',
  },
  draggingOver: {
    boxShadow: ELEVATIONS.MEDIUM,
  },
});
