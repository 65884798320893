import { fetchFactory } from 'Libs/http/utils';

export type Segmentation = {
  id: number;
};

export type GroupFormData = {
  name: string;
  type?: 'segmentation' | 'email-list' | 'magic-code';
  segmentations: Segmentation[];
};

export type AudienceGroup = {
  id: number;
  name: string;
  segmentations: {
    id: number;
    label: string;
  }[];
};

export type ApiErrorResponse = {
  errorCode: string;
  message: string;
  params?: {
    groupName?: string;
  };
};

export type SaveResult = {
  error?: ApiErrorResponse;
  result?: AudienceGroup;
};

export type CreateAudienceGroupFn = (formData: GroupFormData) => Promise<SaveResult>;

export const createAudienceGroup: CreateAudienceGroupFn = async ({ name, segmentations, type = 'segmentation' }) => {
  const { fetchFn } = fetchFactory<AudienceGroup>({
    route: '/api/audiences/groups',
    method: 'POST',
    payload: {
      name,
      type,
      segmentationGroupItemIds: segmentations.map(({ id }) => id),
    },
  });

  try {
    const result = await fetchFn();

    return { result };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return { error: err as ApiErrorResponse };
  }
};
