// @flow

/**
  * Display a modal to translate all contents in the selected languages
  *
  * Props:
  *   - style: custom style to override the modal's style
  *   - visible: if the modal should be displayed, by default false
  *   - isLoading: boolean to define if the confirm button is loading
  *   - onClose: callback triggered on modal closing
  *   - onConfirm: callback triggered on click on the confirm button
  *   - languages: list displayed languages
 */

import * as React from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';
import SCheckboxListItem from 'Components/structural/SCheckboxListItem/SCheckboxListItem';

import styles from './MMultipleLanguagesTranslation.style';

export type AllLanguageType = {|
  label: string,
  id: number,
|};

type Props = {|
  style: Object,
  visible: boolean,
  onConfirm: (Array<number>) => void,
  onClose: () => void,
  isLoading: boolean,
  languages: Array<AllLanguageType>,
|};

type State = {|
  isLanguageSelected: {| +[number]: true |},
|};

export class MMultipleLanguagesTranslation extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
  };
  state = {
    isLanguageSelected: {},
  };

  componentDidUpdate(prevProps: Props) {
    const { visible } = this.props;

    // INFO cleanup to prevent old languages from staying selected in the state
    if (prevProps.visible && !visible) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLanguageSelected: {}});
    }
  }

  render() {
    const { style, onClose, visible, isLoading } = this.props;
    const { isLanguageSelected } = this.state;

    const selectionIsEmpty = Object.keys(isLanguageSelected).length === 0;

    return (
      <MModal
        labelActionButton={t('activities:modals.multiple_languages_translation.button')}
        labelSecondButton={t('activities:modals.multiple_languages_translation.cancel')}
        onAction={this.handleConfirm}
        disableActionButton={selectionIsEmpty}
        onCloseModal={onClose}
        onSecondAction={onClose}
        title={t('activities:modals.multiple_languages_translation.title')}
        visible={visible}
        style={{ ...styles.modal, ...style }}
        bodyStyle={styles.contentWrapper}
        isActionLoading={isLoading}
      >
        {t('activities:modals.multiple_languages_translation.select_languages')}
        <div style={styles.allCheckbox}>
          {this.renderLanguages()}
        </div>
        <div style={styles.warning}>
          {t('activities:modals.multiple_languages_translation.disclaimer')}
        </div>
      </MModal>
    );
  }

  renderLanguages = () => {
    const { languages } = this.props;
    const { isLanguageSelected } = this.state;

    return languages.map<React.Element<typeof SCheckboxListItem>>(lang => {
      return (
        <SCheckboxListItem
          key={lang.id}
          content={lang.label}
          onClick={this.handleCheckboxClick.bind(this, lang.id)}
          checkBoxStatus={isLanguageSelected[lang.id] ? 'checked' : 'unchecked'}
        />
      );
    });
  };

  handleCheckboxClick = (langId: number, status: string) => {
    const { isLanguageSelected } = this.state;
    const newIsLanguageSelected = { ...isLanguageSelected };

    if (status === 'checked')
      newIsLanguageSelected[langId] = true;
    else
      delete newIsLanguageSelected[langId];

    this.setState({ isLanguageSelected: newIsLanguageSelected });
  };

  handleConfirm = () => {
    const { isLanguageSelected } = this.state;
    const { onConfirm } = this.props;

    onConfirm(Object.keys(isLanguageSelected).map(str => parseInt(str)));
  };
}

export default MMultipleLanguagesTranslation;
