import m from 'mithril';
import D from 'dates';
import T from 'types';

var component = {};

component.controller = function controller() {
  var self = this;

};

component.view = function view(c, args) {

  var gift = args.gift;

  var imageUrl = ['url(', gift.picture().cdn(), ')'].join('');
  var status = T.capitalize(gift.status());
  var start = gift.startDate();
  var end = gift.endDate();

  var startDate = D.formatDate(start, 'DD/MM/YYYY', start.getTimezoneOffset());
  var endDate = D.formatDate(end, 'DD/MM/YYYY', end.getTimezoneOffset());

  return m('.gaia__card__gift', {
    class: gift.status() === 'finished' ? 'gaia__card__gift--finished' : '',
  }, [
    m('.card__gift__header', [
      m('.card__gift__date__container', [
        m('.card__gift__date__icon'),
        gift.validityPeriod()
        ? m('.card__gift__date__range', [
          m('.card__gift__date.card__gift__date--value', startDate),
          m('.card__gift__date.card__gift__date--separator', 'to'),
          m('.card__gift__date.card__gift__date--value', endDate),
        ])
        : m('.card__gift__date__range', [
          m('.card__gift__date--value', 'No deadline for orders'),
        ]),
      ]),
      m('.card__gift__status', [
        m('.gift__status__icon', {
          class: 'gift__status__icon--' + gift.status(),
        }),
        m('.gift__status__value', status),
      ]),
    ]),
    m('.card__gift__body', [
      m('.card__gift__image', {
        style: {
          backgroundImage: imageUrl,
        },
      }),
      m('.card__gift__informations', [
        m('.card__gift__title', gift.name()),
        m('.card__gift__description', gift.description()),
        m('.card__gift__metrics', [
          m('.card__gift__metric', [
            m('.card__gift__icon.card__gift__icon--stock'),
            m('.card__gift__value', gift.stock() - gift.orders().length),
            m('.card__gift__value.card__gift__value--light', '/' + gift.stock()),
          ]),
          m('.card__gift__metric.card__gift__metric--credit', [
            m('.card__gift__credit', [
              m('.card__gift__icon.card__gift__icon--credit'),
              m('.card__gift__value', gift.credits()),
            ]),
          ]),
        ]),
      ]),
    ]),
  ]);

};

export default component;
