import type { SimpleLinkedList, LinkedListItem } from '@sparted/shared-library/linked-list';
import type { DeepPartial } from 'redux';

import {
  getChildrenOfChapter,
  isMkChapter,
  isMkImage,
  isMkText,
  isMkVideo,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import type {
  MicroKnowledgeChapter,
  MicroKnowledgeItem,
  MicroKnowledgeText,
  MicroKnowledgeListType,
} from 'Pages/Courses/utils/mk-mapped-linked-list';

import type { CourseFormData } from 'Pages/Courses/types';

import type { RequireAtLeastOne } from 'Libs/utils/types/object';

export type OrderMeta = {
  nextId: string | null;
  previousId: string | null;
};

export type CommonData = {
  title: string | null;
  chapterId: string | null;
};

export type MicroKnowledgeChapterData = CommonData;

export type MicroKnowledgeTextData = CommonData & {
  content: string | null;
};

export type MicroKnowledgeImageData = CommonData & {
  content: string | null;
  imageId: number | null;
};

export type MicroKnowledgeVideoData = CommonData & {
  videoId: number | null;
};

export type InsertCommand = {
  id: string;
  action: 'insert-mk';
  type: 'text' | 'chapter' | 'image' | 'video';
  order: OrderMeta;
} & (
  | { type: 'text'; data?: Partial<MicroKnowledgeTextData> }
  | { type: 'chapter'; data?: Partial<MicroKnowledgeChapterData> }
  | { type: 'image'; data?: Partial<MicroKnowledgeImageData> }
  | { type: 'video'; data?: Partial<MicroKnowledgeVideoData> }
);

export type DeleteCommand = {
  action: 'delete-mk';
  id: string;
};

export type EditCommand = {
  action: 'edit-mk';
  id: string;
  type: 'text' | 'chapter' | 'image' | 'video';
  order: OrderMeta;
} & (
  | { type: 'text'; data?: Partial<MicroKnowledgeTextData> }
  | { type: 'chapter'; data?: Partial<MicroKnowledgeChapterData> }
  | { type: 'image'; data?: Partial<MicroKnowledgeImageData> }
  | { type: 'video'; data?: Partial<MicroKnowledgeVideoData> }
);

export type EditCourseDataCommand = {
  action: 'edit-course-data';
  courseDataId: number;
  title?: string;
  language?: string;
  description?: string | null;
  imageId?: number;
};

export type Command = InsertCommand | DeleteCommand | EditCommand | EditCourseDataCommand;

export type CommandsRequest = {
  commands: Command[];
  editionId: string;
};

export const getItemOrNull = <T extends LinkedListItem>(list: SimpleLinkedList<T>, id?: string | null) =>
  id && list.items?.[id] ? list.items?.[id] : null;

// Type guards
export const isInsertCommand = (command: Command): command is InsertCommand => command.action === 'insert-mk';
export const isDeleteCommand = (command: Command): command is DeleteCommand => command.action === 'delete-mk';
export const isEditCommand = (command: Command): command is EditCommand => command.action === 'edit-mk';
export const isEditCourseDataCommand = (command: Command): command is EditCourseDataCommand =>
  command.action === 'edit-course-data';

// Commands utils
export const getCommandsToRemoveChapterIdFromChildren = (
  list: MicroKnowledgeListType,
  item: MicroKnowledgeChapter,
): EditCommand[] => {
  const childrenIds = getChildrenOfChapter(list, item);

  return childrenIds.map((id) => {
    const { type, nextId, previousId } = list.items[id];

    return {
      action: 'edit-mk',
      id,
      type,
      order: { nextId, previousId },
      data: { chapterId: null },
    };
  });
};

// Accounts for chapters and their childs
export const findInsertionTargetId = (list: MicroKnowledgeListType, targetId: string) => {
  const item = list.items[targetId];

  if (isMkChapter(item)) {
    const childrenIds = getChildrenOfChapter(list, item);

    return childrenIds.length ? childrenIds[childrenIds.length - 1] : targetId;
  }

  return targetId;
};

export const getCommandsToLinkNodes = (
  list: MicroKnowledgeListType,
  params: { previousId: string | null; nextId: string | null },
) => {
  const previous = params?.previousId ? list.items[params.previousId] : undefined;

  const next = params?.nextId ? list.items[params.nextId] : undefined;

  const updatePreviousCmd: Command | undefined = previous
    ? {
        action: 'edit-mk',
        id: previous.id,
        type: previous.type,
        order: {
          previousId: previous.previousId,
          nextId: next?.id || null,
        },
      }
    : undefined;

  const updateNextCmd: Command | undefined = next
    ? {
        action: 'edit-mk',
        id: next.id,
        type: next.type,
        order: {
          previousId: previous?.id || null,
          nextId: next.nextId,
        },
      }
    : undefined;

  return [...(updatePreviousCmd ? [updatePreviousCmd] : []), ...(updateNextCmd ? [updateNextCmd] : [])];
};

export type UpdateSourroundingMicroAdjustement = {
  previous?: boolean;
  next?: boolean;
};

export const getCommandsToUpdateSurroundingItemsAfterInsert = (
  list: MicroKnowledgeListType,
  itemToInsert: { id: string; nextId: string | null; previousId: string | null },
  microAdjustment: UpdateSourroundingMicroAdjustement = {},
): Command[] => {
  const previous = getItemOrNull(list, itemToInsert.previousId);
  const next = getItemOrNull(list, itemToInsert.nextId);

  const commandForNext = microAdjustment.next !== undefined ? microAdjustment.next : true;
  const commandForPrevious = microAdjustment.previous !== undefined ? microAdjustment.previous : true;

  const updatePreviousCmd: Command | undefined =
    previous && commandForPrevious
      ? {
          action: 'edit-mk',
          id: previous.id,
          type: previous.type,
          order: {
            previousId: previous.previousId,
            nextId: itemToInsert.id,
          },
        }
      : undefined;

  const updateNextCmd: Command | undefined =
    next && commandForNext
      ? {
          action: 'edit-mk',
          id: next.id,
          type: next.type,
          order: {
            previousId: itemToInsert.id,
            nextId: next.nextId,
          },
        }
      : undefined;

  return [...(updatePreviousCmd ? [updatePreviousCmd] : []), ...(updateNextCmd ? [updateNextCmd] : [])];
};

// Factories
export const createDeleteCommands = (list: MicroKnowledgeListType, idToDelete: string): Command[] => {
  const itemToDelete = list.items[idToDelete];
  const deleteCommand: DeleteCommand = { action: 'delete-mk', id: itemToDelete.id };

  const singleItemDeleteCommands = [deleteCommand, ...getCommandsToLinkNodes(list, itemToDelete)];

  if (!isMkChapter(itemToDelete)) {
    return singleItemDeleteCommands;
  }

  const children = getChildrenOfChapter(list, itemToDelete).map((childId) => list.items[childId]);

  if (!children.length) {
    return singleItemDeleteCommands;
  }

  const lastChild = children[children.length - 1];
  const deleteChildrenCommands = children.map(({ id }) => ({ action: 'delete-mk', id } as DeleteCommand));

  const patchHoleCommands = getCommandsToLinkNodes(list, {
    previousId: itemToDelete.previousId,
    nextId: lastChild.nextId,
  });

  return [deleteCommand, ...deleteChildrenCommands, ...patchHoleCommands];
};

export type CreateUpdateCommandData = {
  id: string;
} & DeepPartial<MicroKnowledgeItem>;

export type EditionPreparedUpdateCommandData = {
  itemToUpdate: CreateUpdateCommandData;
  switched: {
    toChapter: boolean;
  };
  optionnalInsertion?: MicroKnowledgeText;
};

export const createUpdateCommands = (list: MicroKnowledgeListType, data: EditionPreparedUpdateCommandData) => {
  const { itemToUpdate, optionnalInsertion, switched } = data;
  const oldItem = list.items[itemToUpdate.id];

  const { id } = oldItem;

  // If old item was a chapter and isnt one anymore
  const editChildCommands =
    isMkChapter(oldItem) && !isMkChapter(itemToUpdate) ? getCommandsToRemoveChapterIdFromChildren(list, oldItem) : [];

  const defaultMicroKnowledgeForChapter =
    switched.toChapter && optionnalInsertion
      ? {
          surroundingUpdates: getCommandsToUpdateSurroundingItemsAfterInsert(list, optionnalInsertion, {
            previous: false,
          }),
          insertCommand: [
            {
              id: optionnalInsertion.id,
              type: optionnalInsertion.type,
              order: {
                nextId: optionnalInsertion.nextId,
                previousId: optionnalInsertion.previousId,
              },
              data: {
                title: optionnalInsertion.title,
                chapterId: optionnalInsertion.chapterId,
                content: optionnalInsertion.content,
              },
              action: 'insert-mk',
            } as InsertCommand,
          ],
        }
      : { surroundingUpdates: [], insertCommand: [] };

  if (isMkChapter(itemToUpdate)) {
    const { title } = itemToUpdate;
    const command: EditCommand = {
      id,
      type: itemToUpdate.type,
      action: 'edit-mk',
      data: { title },
      order: {
        previousId: itemToUpdate.previousId || oldItem.previousId,
        nextId: itemToUpdate.nextId || oldItem.nextId,
      },
    };

    return [
      ...defaultMicroKnowledgeForChapter.insertCommand,
      ...defaultMicroKnowledgeForChapter.surroundingUpdates,
      command,
    ];
  }

  if (isMkImage(itemToUpdate)) {
    const { content, chapterId, image, title } = itemToUpdate;
    const command: EditCommand = {
      id,
      type: itemToUpdate.type,
      action: 'edit-mk',
      data: {
        imageId: image?.id,
        content,
        title,
        chapterId,
      },
      order: {
        previousId: itemToUpdate.previousId || oldItem.previousId,
        nextId: itemToUpdate.nextId || oldItem.nextId,
      },
    };

    return [...editChildCommands, command];
  }

  if (isMkText(itemToUpdate)) {
    const { title, content, chapterId } = itemToUpdate;
    const command: EditCommand = {
      id,
      type: itemToUpdate.type,
      action: 'edit-mk',
      data: { title, content, chapterId },
      order: {
        previousId: itemToUpdate.previousId || oldItem.previousId,
        nextId: itemToUpdate.nextId || oldItem.nextId,
      },
    };

    return [...editChildCommands, command];
  }

  if (isMkVideo(itemToUpdate)) {
    const { title, video, chapterId } = itemToUpdate;
    const command: EditCommand = {
      id,
      type: itemToUpdate.type,
      action: 'edit-mk',
      data: { title, videoId: video?.id, chapterId },
      order: {
        previousId: itemToUpdate.previousId || oldItem.previousId,
        nextId: itemToUpdate.nextId || oldItem.nextId,
      },
    };
    return [...editChildCommands, command];
  }

  return [];
};

export type CreateInsertCommandData = MicroKnowledgeItem;
export const createInsertCommands = (list: MicroKnowledgeListType, toInsert: CreateInsertCommandData): Command[] => {
  const updateSurroundingItemCmd = getCommandsToUpdateSurroundingItemsAfterInsert(list, toInsert);

  const { id, nextId, previousId, title, chapterId } = toInsert;

  if (isMkChapter(toInsert)) {
    const insertChapterItemCmd: InsertCommand = {
      id,
      type: toInsert.type,
      action: 'insert-mk',
      order: { nextId, previousId },
      data: { title },
    };

    return [insertChapterItemCmd, ...updateSurroundingItemCmd];
  }

  if (isMkText(toInsert)) {
    const { content } = toInsert;
    const insertChapterItemCmd: InsertCommand = {
      id,
      type: toInsert.type,
      action: 'insert-mk',
      order: { nextId, previousId },
      data: {
        title,
        content,
        chapterId,
      },
    };

    return [insertChapterItemCmd, ...updateSurroundingItemCmd];
  }

  if (isMkImage(toInsert)) {
    const { content, image } = toInsert;
    const insertChapterItemCmd: InsertCommand = {
      id,
      type: toInsert.type,
      action: 'insert-mk',
      order: { nextId, previousId },
      data: {
        title,
        content,
        chapterId,
        imageId: image?.id,
      },
    };

    return [insertChapterItemCmd, ...updateSurroundingItemCmd];
  }

  return [];
};

export type CreateEditCourseDataCommandData = RequireAtLeastOne<CourseFormData>;
export const createEditCourseDataCommands = (
  courseDataId: number,
  toUpdate: CreateEditCourseDataCommandData,
): Command[] => {
  const { title, languageCountry, description, image } = toUpdate;

  const editCourseDataCmd: EditCourseDataCommand = {
    action: 'edit-course-data',
    courseDataId,
    title,
    language: languageCountry,
    description,
    imageId: image?.id,
  };

  return [editCourseDataCmd];
};

export const createReorderCommands = (list: MicroKnowledgeListType, nextOrderedMks: MicroKnowledgeItem[] | null) => {
  const { items } = list;

  if (!nextOrderedMks) {
    return [];
  }

  return nextOrderedMks.reduce<EditCommand[]>((acc, nextMk) => {
    if (JSON.stringify(items[nextMk.id]) === JSON.stringify(nextMk)) {
      return acc;
    }

    return [
      ...acc,
      {
        action: 'edit-mk',
        id: nextMk.id,
        type: nextMk.type,
        order: {
          previousId: nextMk.previousId,
          nextId: nextMk.nextId,
        },
        data: {
          chapterId: nextMk.chapterId,
        },
      },
    ];
  }, []);
};
