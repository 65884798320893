import { COLORS, LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import { StyleSheet as AphroditeStylesheet } from 'aphrodite';

export const IMAGE_PICKER_SIZES = {
  XS: {
    width: 30,
    height: 30,
    borderRadius: 4,
  },
  S: {
    width: 40,
    height: 40,
    borderRadius: 4,
  },
  M: {
    width: 80,
    height: 80,
    borderRadius: 8,
  },
  L: {
    width: 130,
    height: 130,
    borderRadius: 12,
  },
};

export const ILLUSTRATION_SIZES = {
  XS: {
    width: 20,
    height: 17,
  },
  S: {
    width: 24,
    height: 26,
  },
  M: {
    width: 49,
    height: 53,
  },
  L: {
    width: 73,
    height: 78,
  },
};

export const DROPAREA_ICON_SIZES = {
  XS: 18,
  S: 25,
  M: 50,
  L: 76,
};

export const IMAGE_PICKER_ILLUSTRATION_COLOR_SCHEME = {
  DEFAULT: COLORS.PRIMARY_LIGHT.OUTLINE,
  MEDIUM: COLORS.PRIMARY.LIGHTER,
  HOVER: COLORS.PRIMARY.LIGHT,
  LIGHT: COLORS.PRIMARY.LIGHTER,
} as const;

export const getWrapperStylesheet = (dropArea: boolean, image?: string, dualMediaTypeMode?: boolean) => {
  const backgroundStyles = image
    ? {
        backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT,
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        ':hover': {
          opacity: 0.7,
          cursor: 'pointer',
        },
      }
    : {
        backgroundColor: COLORS.PRIMARY.LIGHTER,
      };

  const shouldUseDropAreaStyle = (dropArea || dualMediaTypeMode) && !image;
  const shouldUsePointerCursor = dropArea && !dualMediaTypeMode;

  const dropAreaStyles = shouldUseDropAreaStyle
    ? {
        backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
        ':hover': dualMediaTypeMode
          ? {}
          : {
              backgroundColor: COLORS.WHITE.DEFAULT,
              border: `1px solid ${COLORS.GREY_DARK.OUTLINE_FOCUSED}`,
            },
      }
    : {};

  return AphroditeStylesheet.create({
    wrapper: {
      ...backgroundStyles,
      ...dropAreaStyles,
      cursor: shouldUsePointerCursor ? 'pointer' : 'default',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

const styleSheet = createStyleSheet({
  illustration: {
    position: 'absolute',
    top: 'auto',
    left: 'auto',
  },
  dropAreaIcon: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  glowEffect: {
    position: 'absolute',
    top: 'auto',
    left: 'auto',
    zIndex: LEVELS.LOCAL_OFFSET_2,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    boxShadow: '0px 0px 20px 9px rgba(255,255,255,0.9)',
  },
  textLinkHorizontal: {
    marginLeft: 8,
  },
  textLinkVertical: {
    marginTop: 8,
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  withContainer: {
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
    width: '100%',
  },
  withoutContainer: {
    borderStyle: 'none',
  },
  column: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    zIndex: LEVELS.LOCAL_OFFSET,
    position: 'absolute',
    right: -7,
    top: -7,
  },
  closeRadius: {
    borderRadius: '50%',
  },
  dualMediaTypeInputWrapper: {
    inset: 0,
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    padding: 12,
    gap: 12,
  },
  dualMediaTypeInputButton: {
    width: '100%',
  },
  dualMediaTypeInputText: {
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
});

export default styleSheet;
