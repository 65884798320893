import m from 'mithril';
import { t } from 'i18next';
import React, { useMemo, useState, useEffect, useCallback, type MutableRefObject, ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Enum from 'Models/Enum';

import { COLORS } from 'Components/foundation';
import SActionBar from 'Components/structural/SActionBar/SActionBar';
import SFullWidthCallout from 'Components/structural/SFullWidthCallout/SFullWidthCallout';
import SCallout from 'Components/structural/SCallout/SCallout';
import type { OptionType } from 'Components/structural/SActionBar/SActionBar';
import type { StatusType } from 'Components/unit/UTopActionBar/UTopActionBar';
import type { OptionType as LanguageOptionType } from 'Components/unit/ULanguageMenu/ULanguageMenu';
import type { DailySerieDayType } from 'Pages/Activity/redux/models/DailySerieDay';
import type { ActivityType } from 'Pages/Activity/redux/models/Activity';
import { getCampaignInfoErrors, getSchedulingErrors, getAudienceErrors } from 'Pages/Activity/redux';
import { useTypedSelector } from 'Libs/redux/utils';
import { usePrevious } from 'Hooks/usePrevious/usePrevious';

import {
  actions,
  getActivityEndDate,
  hasChanged as hasChangedSelector,
  hasTargetModeChanged,
  getLanguagesForOptions,
  getLanguageDetail,
  getSelectedActivity,
  getDefaultActivity,
  getIsPendingTranslation,
  getIsContentSelectionLocked,
} from '../redux';
import type { LanguageOptionWithoutCallbackType } from '../redux';
import type { AlertScope, AlertParams, AlertValidationField } from '../redux/models/Alert';
import { checkActivityCanBeTranslated } from '../validate';

import { ArchiveModal } from './components/ArchiveModal/ArchiveModal';
import { SaveModal } from './components/SaveModal/SaveModal';
import { TranslateModal } from './components/TranslateModal/TranslateModal';

import LanguageDrawer from './components/LanguageDrawer/LanguageDrawer';

import styles from './ActionBar.style';
import { useRandomCampaignActivationConfirmation } from './hooks/useRandomCampaignActivationConfirmation';
import { useManualCampaignActivationConfirmation } from './hooks/useManualCampaignActivationConfirmation';

type ActionBarProps = {
  selectedTabIndex?: number;
  innerRef?: MutableRefObject<null>;
  selectedActivity: ActivityType;
};

type StatusObject = {
  status: StatusType;
  statusWording: string;
  tooltip: string;
};

const handleChangeTab = (index: number) => {
  const subPath = window.location.pathname
    .split('/')
    .filter((_, i) => i <= 3)
    .join('/');

  // TODO: Replace this when react owns the routing
  switch (index) {
    case 0:
      window.history.replaceState({}, '', subPath);
      break;

    case 1:
      window.history.replaceState({}, '', subPath + '/scheduling');
      break;

    case 2:
      window.history.replaceState({}, '', subPath + '/audience');
      break;

    default:
      break;
  }
};

const handleBack = () => {
  m.route('/activity');
};

function withSelectedActivity(Component: ComponentType<ActionBarProps>) {
  return function WrappedActionBar(props: Omit<ActionBarProps, 'selectedActivity'>) {
    const selectedActivity = useTypedSelector(getSelectedActivity);

    if (!selectedActivity.id) return null;

    return <Component {...props} selectedActivity={selectedActivity} />;
  };
}

export function ActionBar({ selectedTabIndex = 0, innerRef, selectedActivity }: ActionBarProps) {
  const dispatch = useDispatch();
  const TABS = [t('activities:tabs.overview'), t('activities:tabs.scheduling'), t('activities:tabs.audience')];

  const [drawerVisible, setDrawerVisible] = useState(false);

  const setAlert = (scope: AlertScope, params?: AlertParams) => dispatch(actions.setAlert(scope, params));
  const removeAlert = (scope: AlertScope) => dispatch(actions.removeAlert(scope));

  const updateValidationErrorAlert = (fields: AlertValidationField[] | Readonly<AlertValidationField[]>) => {
    if (fields.length > 0) {
      return setAlert('validationError', { fields });
    }

    removeAlert('validationError');
  };

  const handleClickLanguage = useCallback(
    (languageId: number) => () => {
      dispatch(actions.selectLanguage(languageId));
    },
    [],
  );

  const targetModeChanged = useTypedSelector(hasTargetModeChanged);

  const campaignInfoErrors = useTypedSelector((state) => getCampaignInfoErrors(state.pages.activity));
  const audienceErrors = useTypedSelector((state) => getAudienceErrors(state.pages.activity));
  const schedulingErrors = useTypedSelector((state) =>
    getSchedulingErrors(state.pages.activity).map((err) => err.reason),
  );

  const currentErrorFields = useMemo(
    () =>
      [...campaignInfoErrors, ...schedulingErrors, ...audienceErrors].filter(
        (errorField, i, a) => a.findIndex((y) => y === errorField) === i,
      ),
    [campaignInfoErrors, schedulingErrors, audienceErrors],
  );

  const defaultActivity = useTypedSelector(getDefaultActivity);

  const handleSave = () => {
    let errorFields: AlertValidationField[] = [];

    if (!defaultActivity.active) {
      if (!defaultActivity.name) errorFields = ['noName'];
    } else {
      errorFields = currentErrorFields;
    }

    updateValidationErrorAlert(errorFields);

    if (errorFields.length) {
      return { error: true };
    }

    if (!targetModeChanged) {
      return dispatch(actions.save());
    }

    setSaveModalVisible(true);

    return null;
  };

  const updateUnsavedErrorAlert = (hasChanged: boolean, unsavedScope: AlertParams['unsavedScope']) => {
    if (hasChanged) {
      setAlert('unsavedError', { unsavedScope });
    } else {
      removeAlert('unsavedError');
    }

    return hasChanged;
  };

  const hasChanged = useTypedSelector(hasChangedSelector);

  const handleDuplicate = () => {
    updateUnsavedErrorAlert(hasChanged, 'duplicate');

    if (hasChanged) {
      return Promise.resolve({ error: true });
    }

    return Promise.resolve(dispatch(actions.duplicate(selectedActivity.multilingualId)));
  };

  const handleArchive = () => {
    updateUnsavedErrorAlert(hasChanged, selectedActivity.archived ? 'unarchive' : 'archive');

    if (hasChanged) {
      return Promise.resolve({ error: true });
    }

    return Promise.resolve(dispatch(actions.archive(!selectedActivity.archived)));
  };

  const handleTranslate = (ids: number[]) => {
    return Promise.resolve(dispatch(actions.askTranslation(ids)));
  };

  const {
    askForConfirmation: askForConfirmationBeforeActivatingRandomCampaign,
    dialog: randomCampaignActivationDialog,
  } = useRandomCampaignActivationConfirmation();
  const {
    askForConfirmation: askForConfirmationBeforeActivatingManualCampaign,
    dialog: manualCampaignActivationDialog,
  } = useManualCampaignActivationConfirmation();

  const handleActivate = () => {
    const {
      active,
      dailySerie: { random },
      lastActivated,
    } = defaultActivity;

    if (!active) {
      updateUnsavedErrorAlert(hasChanged, 'activate');
      updateValidationErrorAlert(currentErrorFields);

      if (hasChanged || currentErrorFields.length) {
        return Promise.resolve({ error: true });
      }
    }

    if (random && !lastActivated) {
      return askForConfirmationBeforeActivatingRandomCampaign(() => dispatch(actions.activate(!active)));
    }

    if (!random && !lastActivated) {
      return askForConfirmationBeforeActivatingManualCampaign(() => dispatch(actions.activate(!active)));
    }

    return Promise.resolve(dispatch(actions.activate(!active)));
  };

  const handleLock = (lock: boolean) => {
    dispatch(actions.setLock(lock));
  };

  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const handleArchiveModalAction = async (success: boolean) => {
    if (success) {
      await handleArchive();
      setArchiveModalVisible(false);
    } else {
      setArchiveModalVisible(false);
    }
  };

  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const handleSaveModalAction = (success: boolean) => {
    setSaveModalVisible(false);

    return success ? Promise.resolve(dispatch(actions.save())) : Promise.resolve();
  };

  const [translateModalVisible, setTranslateModalVisible] = useState(false);
  const handleTranslateModalHide = () => {
    setTranslateModalVisible(false);
  };

  const handleOpenTranslationModal = () => {
    const fields = checkActivityCanBeTranslated(defaultActivity);

    updateUnsavedErrorAlert(hasChanged, 'translate');
    updateValidationErrorAlert(fields);

    if (hasChanged || fields.length) return { error: true };

    setTranslateModalVisible(true);

    return null;
  };

  const getRatioContentsTranslatedValidated = (days: Readonly<DailySerieDayType[]>) => {
    let number = 0,
      toTranslated = 0,
      toValidate = 0;

    days.forEach((day) => {
      day.dailySerieContents.forEach((dsContent) => {
        number++;

        if (
          dsContent.content.statusId !== Enum.contentStatus.VALIDATED &&
          dsContent.content.knowledgePendingTranslation
        ) {
          toTranslated++;
        } else if (!dsContent.content.archived && dsContent.content.statusId !== Enum.contentStatus.VALIDATED) {
          toValidate++;
        }
      });
    });

    return {
      number,
      toTranslated,
      toValidate,
    };
  };

  const endDate = useTypedSelector(getActivityEndDate);

  const getStatus = useCallback(
    (activity: ActivityType): StatusObject => {
      const { active, startDate, dailySerie, archived } = activity;
      const nowIsBefore = moment().isBefore(moment(startDate).startOf('day'));
      const nowIsAfter = moment().isAfter(endDate);

      const contentsStatus = getRatioContentsTranslatedValidated(dailySerie.days);

      const statusObject: StatusObject = {
        status: 'active',
        statusWording: t('activities:status.live'),
        tooltip: '',
      };

      if (contentsStatus.toTranslated || contentsStatus.toValidate) {
        statusObject.status = 'important';
        if (contentsStatus.toTranslated && contentsStatus.toValidate) {
          statusObject.statusWording = t('activities:status.translations_and_validations_pending');
          statusObject.tooltip = t('activities:status.translations_and_validations_pending_tooltip');
        } else if (contentsStatus.toTranslated) {
          statusObject.statusWording = t('activities:status.translations_pending', {
            translated: contentsStatus.number - contentsStatus.toTranslated,
            total: contentsStatus.number,
          });
          statusObject.tooltip = t('activities:status.translations_pending_tooltip');
        } else {
          statusObject.statusWording = t('activities:status.validations_pending', {
            validated: contentsStatus.number - contentsStatus.toValidate,
            total: contentsStatus.number,
          });
          statusObject.tooltip = t('activities:status.validations_pending_tooltip');
        }
      } else if (archived && active) {
        statusObject.status = 'disabled';
        statusObject.statusWording = t('activities:status.archived');
      } else if (!active) {
        statusObject.status = 'inactive';
        statusObject.statusWording = archived ? t('activities:status.archived') : t('activities:status.inactive');
      } else if (nowIsBefore) {
        statusObject.status = 'warning';
        statusObject.statusWording = t('activities:status.upcoming');
      } else if (nowIsAfter) {
        statusObject.status = 'active';
        statusObject.statusWording = t('activities:status.ended');
      }

      return statusObject;
    },
    [endDate],
  );

  const isPendingTranslation = useTypedSelector(getIsPendingTranslation);

  const getOptions = useCallback((activity: ActivityType): OptionType[] => {
    const { id, archived } = activity;

    return [
      {
        title: t('activities:actions.export_content'),
        callback: () => dispatch(actions.exportActivity(selectedActivity.multilingualId)),
        trackingAction: 'export',
        trackingContext: 'content-activity',
      },
      ...(!archived && id && !isPendingTranslation
        ? [
            {
              title: t('activities:actions.translate'),
              callback: handleOpenTranslationModal,
            },
          ]
        : []),
      {
        title: t('activities:actions.duplicate'),
        callback: handleDuplicate,
      },
      {
        title: archived ? t('activities:actions.unarchive') : t('activities:actions.archive'),
        callback: archived ? handleArchive : () => setArchiveModalVisible(true),
      },
    ];
  }, []);

  const getOptionsLanguages = useCallback(
    (optionsLanguages: Readonly<LanguageOptionWithoutCallbackType[]>): LanguageOptionType[] => {
      const optLanguages = optionsLanguages.map((opt) => {
        const { id, ...rest } = opt;

        return {
          ...rest,
          props: {
            ...rest.props,
            onClick: handleClickLanguage(id),
          },
        };
      });

      return [
        ...optLanguages,
        { type: 'divider', key: 'divider-0' },
        {
          type: 'standard',
          key: 'text-change',
          text: t('activities:actions.manage_languages'),
          onClick: () => setDrawerVisible(true),
        },
      ];
    },
    [],
  );

  const { multilingualId, name, active, updatedAt, originId, startDate, isDefault } = selectedActivity;
  const { name: defaultName } = defaultActivity;

  const isTranslated = Boolean(originId);
  const statusObject = useMemo(() => getStatus(selectedActivity), [selectedActivity, getStatus]);

  const lastSave = useMemo(
    () =>
      multilingualId
        ? `${moment(updatedAt).calendar(null, {
            sameDay: `[${t('activities:dates.same_day_save')}] LT`,
            nextDay: '',
            nextWeek: '',
            lastDay: `[${t('activities:dates.last_day_save')}] LT`,
            lastWeek: `[${t('activities:dates.last_save_long_date')}] DD/MM/YYYY`,
            sameElse: `[${t('activities:dates.last_save_long_date')}] DD/MM/YYYY`,
          })}`
        : '',
    [multilingualId, updatedAt],
  );

  const canLock = useMemo(
    () => active && moment().isSameOrAfter(moment(startDate).startOf('day')) && moment().isSameOrBefore(endDate),
    [active, startDate, endDate],
  );

  const activeToggleLabel = canLock ? t('activities:status.live') : t('activities:status.active');
  const activityLanguageId = defaultActivity.languageId;

  const helper =
    statusObject.status === 'important'
      ? ({
          icon: 'info',
          content: statusObject.tooltip,
        } as const)
      : undefined;

  const options = useMemo(() => getOptions(selectedActivity), [selectedActivity, getOptions]);

  const optionsLanguages = useTypedSelector(getLanguagesForOptions);
  const optLanguages = useMemo(() => getOptionsLanguages(optionsLanguages), [optionsLanguages, getOptionsLanguages]);

  const canActivate = (!currentErrorFields.length && !hasChanged) || active;

  const tooltipText = hasChanged
    ? active
      ? t('activities:warnings.save_change_before_activating_campaign')
      : t('activities:warnings.save_change_before_deactivating_campaign')
    : t('activities:warnings.campaign_not_ready_for_activation');

  const isLocked = useTypedSelector((state) => state.pages.activity.isLocked);
  const isSaveLoading = useTypedSelector((state) => state.pages.activity.isSaveLoading);
  const isActivateLoading = useTypedSelector((state) => state.pages.activity.isActivateLoading);
  const isArchiveLoading = useTypedSelector((state) => state.pages.activity.isArchiveLoading);
  const isTranslateLoading = useTypedSelector((state) => state.pages.activity.isTranslateLoading);
  const oldIsTranslateLoading = usePrevious(isTranslateLoading);
  const isContentSelectionLocked = useTypedSelector(getIsContentSelectionLocked);

  const supportUrl = 'https://support.sparted.com/hc/en-us/articles/9218072883356';

  useEffect(() => {
    if (oldIsTranslateLoading && !isTranslateLoading) {
      handleTranslateModalHide();
    }
  }, [isTranslateLoading]);

  const selectedLanguageLabel = useTypedSelector((state) =>
    getLanguageDetail(state.pages.activity.selectedLanguageId),
  ).label;

  return (
    <>
      <div ref={innerRef} style={styles.wrapper}>
        <SActionBar
          id={multilingualId}
          title={name}
          titlePlaceholder={defaultName}
          isActive={active}
          activeToggleLabel={activeToggleLabel}
          inactiveToggleLabel={t('activities:status.inactive')}
          status={statusObject.status}
          statusWording={statusObject.statusWording}
          tabs={TABS}
          onBack={handleBack}
          isTranslated={isTranslated}
          onChangeTab={handleChangeTab}
          options={options}
          selectedTab={selectedTabIndex}
          lastSaved={lastSave}
          helper={helper}
          onSave={handleSave}
          onToggleChange={handleActivate}
          canSave={hasChanged}
          onLock={handleLock}
          canLock={canLock}
          locked={canLock && isLocked}
          isSaveLoading={isSaveLoading}
          isToggleLoading={isActivateLoading}
          optionsLanguages={optLanguages}
          tooltipText={tooltipText}
          canActivate={canActivate}
        />
        <LanguageDrawer onClose={() => setDrawerVisible(false)} visible={drawerVisible} />
        {!isDefault ? (
          <SFullWidthCallout
            content={t('activities:infos.current_viewed_campaign_language', { languageLabel: selectedLanguageLabel })}
            backgroundColor={COLORS.BLUE_GRAY_DARKER.DEFAULT}
            textColor={COLORS.WHITE.DEFAULT}
          />
        ) : null}
        {isContentSelectionLocked && (
          <SCallout
            type="standard-dark"
            title={t('activities:warnings.random_campaign_modifications_blocked')}
            description={t('activities:warnings.random_campaign_modifications_blocked_explanation')}
            banner={{
              illustration: 'campaign',
              illustrationColor: 'WARNING',
            }}
            action={{
              label: t('activities:actions.know_more'),
              onAction: () => window.open(supportUrl, '_blank'),
              buttonType: 'button',
              rightIcon: 'external-link',
            }}
          />
        )}
      </div>
      <ArchiveModal
        visible={archiveModalVisible}
        onAction={handleArchiveModalAction}
        isArchiveLoading={isArchiveLoading}
      />
      <SaveModal visible={saveModalVisible} onAction={handleSaveModalAction} />
      <TranslateModal
        visible={translateModalVisible}
        onAction={handleTranslate}
        isActionLoading={isTranslateLoading}
        onCancel={handleTranslateModalHide}
        activityLanguageId={activityLanguageId}
      />
      {manualCampaignActivationDialog}
      {randomCampaignActivationDialog}
    </>
  );
}

export default withSelectedActivity(ActionBar);
