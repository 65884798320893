/* eslint-disable complexity */
import m from 'm';
import reactCreator from 'components/react.creator';

var component = {};

component.view = function view() {
  return m(reactCreator, {
    component: 'Login',
    props: {},
  });
};

export default component;
