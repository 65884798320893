import React, { useCallback } from 'react';
import { t } from 'i18next';
import { DeepPartial } from 'ts-essentials';
import { Image, SwipingCardPosition } from '@sparted/shared-library/business/types';

import UTextLink from 'Components/unit/UTextLink//UTextLink';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import SSwipingCard, { SSwipingCardRadioOptions } from '../SSwipingCard/SSwipingCard';
import styles from './SSwipingCardsSection.style';

const UTextLinkWithTooltip = withTooltip(UTextLink);

export type SSwipingCardSectionItems = {
  image?: DeepPartial<Image>;
  maxLength?: number;
  textAreaValue?: string;
  selected?: SwipingCardPosition;
};

type SSwipingCardSectionProps = {
  items: SSwipingCardSectionItems[];
  options: SSwipingCardRadioOptions[];
  onItemsChange: (items: SSwipingCardSectionItems[]) => void;
  maxLength?: number;
  maxTitleLength?: number;
};

export const SSwipingCardsSection = ({
  items,
  options,
  onItemsChange: setItems,
  maxLength,
  maxTitleLength,
}: SSwipingCardSectionProps) => {
  const onItemTextAreaChange = (textAreaValue: string, index: number) => {
    const prevItems = items.slice(0, index);
    const nextItems = items.slice(index + 1);

    setItems([...prevItems, { ...items[index], textAreaValue }, ...nextItems]);
  };

  const onImageChange = (image: Image, index: number) => {
    const prevItems = items.slice(0, index);
    const nextItems = items.slice(index + 1);

    setItems([...prevItems, { ...items[index], image }, ...nextItems]);
  };

  const onAddElement = useCallback(() => {
    if (maxLength && items.length >= maxLength) {
      return;
    }

    setItems([...items, { textAreaValue: '', selected: options[0].id }]);
  }, [items, maxLength]);

  const onDeleteElement = (indexToRemove: string | number) => {
    /* Cannot delete if just one element on the list */
    if (items.length === 1) {
      return;
    }

    const newItems = items.filter((_item, index) => index !== indexToRemove);
    setItems(newItems);
  };

  const onSelectItem = (index: number, choiceId: SwipingCardPosition) => {
    const prevItems = items.slice(0, index);
    const nextItems = items.slice(index + 1);

    setItems([...prevItems, { ...items[index], selected: choiceId }, ...nextItems]);
  };

  return (
    <div style={styles.wrapper}>
      {items.map((item, index) => (
        <SSwipingCard
          style={styles.gameplayItem}
          key={`swiping-card-${index}`}
          title={`${t('structural_component:s_swiping_cards_section.title')} ${index + 1}`}
          image={item.image?.url || ''}
          onImageChange={(image) => onImageChange(image as any, index)}
          textAreaValue={item.textAreaValue}
          maxLength={maxTitleLength}
          radioOptions={options}
          onTextAreaChange={(textAreaValue) => onItemTextAreaChange(textAreaValue, index)}
          onDelete={() => onDeleteElement(index)}
          onSelect={(choiceId) => onSelectItem(index, choiceId)}
          selected={item.selected || 'left'}
          disableDelete={items.length === 1}
          disableDeleteTooltipText={t('structural_component:s_swiping_cards_section.cannot_delete')}
        />
      ))}
      <div style={styles.uTextSection}>
        <UTextLinkWithTooltip
          leftIcon="plus"
          size="M"
          variant="outlined"
          type="button"
          onClick={onAddElement}
          disabled={maxLength && items.length >= maxLength}
          tooltipEnabled={maxLength && items.length >= maxLength}
          tooltipContent={
            maxLength && items.length >= maxLength
              ? t('structural_component:s_swiping_cards_section.max_length', { max: maxLength })
              : ''
          }
          typography="BODY2_SEMIBOLD"
          text={t('structural_component:s_swiping_cards_section.add_card')}
        />
      </div>
    </div>
  );
};
