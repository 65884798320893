// @flow

import React from 'react';

import { PlayersAlertView } from './PlayersAlertView/PlayersAlertView';
import { PlayersView } from './PlayersView/PlayersView';
import styles from './Players.style.js';

export const Players: React$ComponentType<{||}> = () => {
  return (
    <div style={styles.wrapper}>
      <PlayersAlertView component={PlayersView} />
    </div>
  );
};
