import COLORS from 'Components/foundation/colors';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  loadingDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
    userSelect: 'none',
    display: 'inline-block',
    marginRight: '2px',
  },
  isTextLinkDisabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    textDecoration: 'none',
  },
  isAccentuated: {
    color: COLORS.TEXT.ACCENTUATED,
  },
});

export default styleSheet;
