// @flow

import { COLORS } from 'Components/foundation';

export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    borderRadius: 8,
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderWidth: 1,
    borderStyle: 'solid',

    paddingLeft: 48,
    paddingRight: 48,
    paddingTop: 32,
    paddingBottom: 32,

    marginBottom: 16,
  },
  score: {
    fontFamily: 'Source Sans Pro',
    fontSize: '70px',
    lineHeight: 'normal',
    fontWeight: 600,
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  scorePositive: {
    color: COLORS.SUCCESS.DEFAULT,
  },
  scoreNegative: {
    color: COLORS.ERROR.DEFAULT,
  },
};
