import React, { useMemo, useCallback } from 'react';
import { t } from 'i18next';

import App from 'Models/App';
import Enum from 'Models/Enum';
import { SegmentationGroupItemType } from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';

import { SegmentationItemModel } from 'Libs/ts/types';

import UButton from 'Components/unit/UButton/UButton';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';
import SInput from 'Components/structural/SInput/SInput';
import SLargeRadio, { SLargeRadioProps } from 'Components/structural/SLargeRadio/SLargeRadio';
import SSelect from 'Components/structural/SSelect/SSelect';

import genericFormStyle from '../../ActivityList.style';
import styles from './CampaignForm.style';

export type CampaignMode = 'manual' | 'random';

export type CampaignFormProps = {
  name: string;
  onCampaignModeSelect: (value: CampaignMode) => void;
  selectedCampaignMode: CampaignMode;
  onNameChange: (value: string) => void;
  onLanguageSelect: (value: SegmentationGroupItemType) => void;
  selectedLanguageId: number;
  onBack: () => void;
};

const getLanguages = (): Readonly<SegmentationGroupItemType[]> => {
  // We get the user's segmentation from the global App model, its type cannot be correctly infered automatically.
  const filteredSegmentation: Array<SegmentationItemModel> = (App as any).userSegmentationItems().toJSON();

  const groups = filteredSegmentation
    .map((segItem) => segItem.group)
    .filter((group, i, a) => a.findIndex((y) => y.id === group.id) === i)
    .filter((group) => group.dimension.id === Enum.dimensionTypes.LANGUAGE);

  return groups.map((group) => ({
    id: group.id,
    label: group.label,
    dimension: {
      id: group.dimension.id,
      color: group.dimension.color,
      typeId: group.dimension.typeId,
    },
  }));
};

export const CampaignForm = ({
  name,
  onNameChange,
  onCampaignModeSelect,
  selectedCampaignMode,
  onLanguageSelect,
  selectedLanguageId,
  onBack,
}: CampaignFormProps) => {
  const radioItems: SLargeRadioProps['items'] = useMemo(
    () => [
      {
        id: 'manual',
        text: t('activities:list.campaign_form.radio_item_manual'),
        icon: 'manual-order',
        tooltipWidth: 230,
        tooltip: t('activities:list.campaign_form.radio_item_manual_tooltip'),
      },
      {
        id: 'random',
        text: t('activities:list.campaign_form.radio_item_random'),
        icon: 'random-order',
        tooltipWidth: 230,
        tooltip: t('activities:list.campaign_form.radio_item_random_tooltip'),
      },
    ],
    [],
  );

  const languages = useMemo(() => getLanguages(), []);

  const languageSelectItems = useMemo(
    () => languages.map(({ id, label }) => ({ id, label, value: label })),
    [languages],
  );

  const selectedLanguage = languageSelectItems.find((x) => x.id === selectedLanguageId);

  const handleSelect = useCallback(
    (item) => {
      const languageToAdd = languages.find((x) => x.id === item.id);

      if (languageToAdd) {
        onLanguageSelect(languageToAdd);
      }
    },
    [languages, onLanguageSelect],
  );

  const campaignModeExplanation =
    selectedCampaignMode === 'manual'
      ? t('activities:list.campaign_form.manual_mode_explanation')
      : t('activities:list.campaign_form.random_mode_explanation');

  return (
    <div style={genericFormStyle.formWrapper}>
      <div style={styles.backButton}>
        <UButton
          text={t('activities:list.campaign_form.back')}
          typography="BODY3"
          leftIcon="arrow-left"
          iconSize="small"
          onClick={onBack}
        />
      </div>
      <div style={styles.form}>
        <section style={styles.block}>
          <p style={styles.blockTitle}>{t('activities:list.campaign_form.mode')}</p>
          <SLargeRadio
            activeItemId={selectedCampaignMode}
            items={radioItems}
            onClick={(item) => onCampaignModeSelect(item as CampaignMode)}
            style={styles.radioBlock}
          />
          <div style={styles.commentBlock}>
            <UMarkdown style={styles.notice} markdown={campaignModeExplanation} />
          </div>
        </section>
        <section style={styles.block}>
          <p style={styles.blockTitle}>
            {t('activities:list.campaign_form.name')}
            <span style={genericFormStyle.asterisk}>*</span>
          </p>
          <SInput
            type="large"
            placeholder={t('activities:list.campaign_form.name_placeholder')}
            maxLength={40}
            value={name}
            onChange={onNameChange}
          />
        </section>
        <section style={styles.block}>
          <p style={styles.blockTitle}>
            {t('activities:list.campaign_form.select_language')}
            <span style={genericFormStyle.asterisk}>*</span>
          </p>
          <SSelect
            placeholder={t('activities:list.campaign_form.select_language_placeholder')}
            items={languageSelectItems}
            onSelect={handleSelect}
            selected={selectedLanguage || null}
            style={styles.languageSelect}
          />
          <div style={styles.commentBlock}>
            <p style={styles.comment}>{t('activities:list.campaign_form.comment')}</p>
            <p style={styles.notice}>{t('activities:list.campaign_form.notice')}</p>
          </div>
        </section>
      </div>
    </div>
  );
};
