// @flow

import { COLORS, CORNERS } from 'Components/foundation';
import { getCdnUrl } from 'Libs/cdn';

const { CORNER3, BORDER_RADIUS } = CORNERS;

export default {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    background: '#295075',
    backgroundImage: `url(${getCdnUrl('common', '/sparted-landing-background-xl.svg')}), linear-gradient(to right, #295075, #173150)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  spartedLogo: {
    width: 136,
    position: 'absolute',
    top: -106,
  },
  childrenWrapper: {
    ...BORDER_RADIUS(CORNER3),
    background: COLORS.WHITE.DEFAULT,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 320,
    width: 600,
  },
};
