import React, { CSSProperties } from 'react';

import UFlag from 'Components/unit/UFlag/UFlag';
import UIcon from 'Components/unit/UIcon/UIcon';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownDivider from 'Components/unit/UDropdownDivider/UDropdownDivider';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UDropdownCountryItem from 'Components/unit/UDropdownCountryItem/UDropdownCountryItem';
import UDropdownLanguageItem from 'Components/unit/UDropdownLanguageItem/UDropdownLanguageItem';

import type { UDropdownLanguageItemProps } from 'Components/unit/UDropdownLanguageItem/UDropdownLanguageItem';
import type { UDropdownCountryItemProps } from 'Components/unit/UDropdownCountryItem/UDropdownCountryItem';

import styles from './ULanguageMenu.style';

export type OptionType =
  | { type: 'country'; key: string; props: UDropdownCountryItemProps }
  | { type: 'language'; key: string; props: UDropdownLanguageItemProps }
  | { type: 'standard'; key: string; text: string; onClick: () => void }
  | { type: 'divider'; key: string };

export type ULanguageMenuProps = {
  countryCode: string;
  options: Readonly<OptionType[]>;

  disabled?: boolean;
  dialCode?: string;
  style?: CSSProperties;
};

export type ULanguageMenuState = {
  isOpen: boolean;
};

const DIALCODE_MIN_LENGTH = 3;

/**
 * Display a language menu
 *
 * Props:
 *  - countryCode: code of the country (ex: fr)
 *  - options: Array of OptionType which are displayed in the dropdown
 *
 *  - dialCode: dial code to display if the menu is used for phone numbers (ex: +33)
 *  - disabled: boolean to disable menu
 *  - style: override component's style
 */
export class ULanguageMenu extends React.PureComponent<ULanguageMenuProps, ULanguageMenuState> {
  wrapperRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    style: undefined,
    disabled: false,
    dialCode: '',
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  renderOptions = () => {
    const { options } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) return null;

    return (
      <div style={this.getDropdownStyle()} onClick={this.handleClick} data-test-id="drop-down-option">
        <UDropdownBox items={options} renderItem={this.renderItem} />
      </div>
    );
  };

  renderItem = (option: OptionType) => {
    switch (option.type) {
      case 'country':
        return <UDropdownCountryItem key={option.key} {...option.props} />;
      case 'language':
        return <UDropdownLanguageItem key={option.key} {...option.props} />;
      case 'standard':
        return <UDropdownItem key={option.key} text={option.text} onClick={option.onClick} />;
      case 'divider':
      default:
        return <UDropdownDivider key={option.key} />;
    }
  };

  handleClick = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target))
      this.setState({ isOpen: false });
  };

  getDropdownStyle = () => {
    const { options } = this.props;
    const result = { isFound: false };

    options.map((elem) => {
      if (elem.type === 'country') result.isFound = true;
    });

    return !options || !result.isFound ? styles.dropdown : styles.dropdownCountry;
  };

  getDialCodeMargin = () => {
    const { dialCode } = this.props;

    if (dialCode?.length === DIALCODE_MIN_LENGTH - 1) return styles.hasDialCodeSimpleDigit;
    if (dialCode?.length === DIALCODE_MIN_LENGTH + 1) return styles.hasDialCodeTripleDigit;

    return styles.hasDialCodeDoubleDigit;
  };

  render() {
    const { style, countryCode, disabled, dialCode } = this.props;
    const disabledStyle = disabled ? styles.disabledDropdown : null;
    const dialCodeStyle = this.getDialCodeMargin();

    return (
      <div ref={this.wrapperRef} style={{ ...styles.wrapper, ...style, ...disabledStyle }}>
        <div
          data-test-id="button"
          style={!dialCode ? styles.button : styles.buttonForCountry}
          onClick={this.handleClick}
        >
          <UFlag countryCode={countryCode ? countryCode.toLowerCase() : countryCode} size={18} />
          <UIcon name="arrow-down" size={6} style={!dialCode ? styles.icon : styles.iconForCountry} />
          {dialCode ? (
            <p style={dialCodeStyle} data-test-id="dial-code">
              {dialCode}
            </p>
          ) : null}
        </div>
        {this.renderOptions()}
      </div>
    );
  }
}

export default ULanguageMenu;
