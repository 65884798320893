import m from 'm';
import ReactDOM from 'react-dom';
import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from 'Libs/redux/store';
import * as Components from 'Components';
import * as Pages from 'Pages';
import * as MithrilReact from 'MithrilReact';

var component = {};

component.controller = function controller(args) {
  const self = this;

  let Component = Components[args.component] || MithrilReact[args.component];

  if (!Component) {
    const Page = Pages[args.component];

    if (!Page)
      throw new Error(`component not found: ${args.component}`);

    Component = function connectedPage(props) {
      return (
        <Provider store={store}>
          <Page {...props} />
        </Provider>
      );
    };
  }

  self.injectReact = function injectReact(elem, isInit) {
    if (!isInit)
      self.elem = elem;

    const props = Object.entries(args.props || {})
      .map(([key, prop]) => [key,
        typeof prop === 'function' && prop.prototype && prop.prototype.constructor.name === 'prop'
          ? prop()
          : prop])
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    ReactDOM.render(<Component {...props} />, elem);
  };

  self.onunload = function onunload() {
    if (self.elem)
      ReactDOM.unmountComponentAtNode(self.elem);
  };
};

component.view = function view(c, args) {
  return m('div', {
    config: c.injectReact,
    ...(args.style ? { style: args.style } : {})
  });
};

export default component;
