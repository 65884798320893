import React from 'react';
import moment from 'moment';

import { COLORS } from 'Components/foundation';

import UIcon from 'Components/unit/UIcon/UIcon';
import styles from './DateBlock.style';

const DATE_PLACEHOLDER = '--/--/----';
const FORMAT_RULE = 'DD/MM/YYYY';

export const formatDates = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) {
    return DATE_PLACEHOLDER;
  }

  return `${moment(startDate).format(FORMAT_RULE)} - ${moment(endDate).format(FORMAT_RULE)}`;
};

export type DateBlockProps = {
  startDate?: string;
  endDate?: string;
};

export const DateBlock = ({ startDate, endDate }: DateBlockProps) => (
  <div style={styles.dateContainer}>
    <UIcon name="calendar" size={24} color={COLORS.BLACK.DEFAULT} />
    <h2 style={styles.dates}>{formatDates(startDate, endDate)}</h2>
  </div>
);
