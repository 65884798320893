// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'inline-flex',
    padding: '4px 0 4px 4px',
    alignItems: 'center',
  },
  label: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
    marginLeft: 20,
  },
};
