import styles from 'Components/structural/STable/STable.style';
import { CircleSpinner } from 'react-spinners-kit';
import RenderRow from 'Components/structural/STable/components/RenderRow';
import RowMessage from 'Components/structural/STable/components/RowMessage';
import React from 'react';
import { COLORS } from 'Components/foundation';
import { STableRow } from 'Components/structural/STable/types';
import { TableBodyProps } from 'react-table';

type TableRowsProps = {
  getTableBodyProps: () => TableBodyProps;
  prepareRow: (row: STableRow) => void;
  rows: STableRow[];
  isTableLoading: boolean;
  isContentLoading: boolean;
  loadingText: string;
  isEmpty: boolean;
};

function TableRows({
  getTableBodyProps,
  prepareRow,
  rows,
  isTableLoading,
  isContentLoading,
  loadingText,
  isEmpty,
}: TableRowsProps) {
  const bodyProps = getTableBodyProps();
  const loadingStyle = isContentLoading ? styles.bodyLoading : {};

  if (isTableLoading) {
    return (
      <div style={styles.rowsLoadingContainer}>
        <div style={styles.loadingMessage}>{loadingText}</div>
        <div style={styles.loader}>
          <CircleSpinner size={16} color={COLORS.TEXT.SECONDARY_DEFAULT} />
        </div>
      </div>
    );
  }

  if (isEmpty) return null;

  return (
    <div {...bodyProps} style={{ ...bodyProps.style, ...loadingStyle }}>
      {rows.map((row: STableRow) => {
        const originalRow = row.original;
        const rowIndex = originalRow.id;

        return (
          <div key={`row-wrapper-${rowIndex}`} className="stable--rowWrapper" style={styles.rowWrapper}>
            <RenderRow prepareRow={prepareRow} row={row} />
            <RowMessage {...originalRow} />
          </div>
        );
      })}
    </div>
  );
}

export default TableRows;
