import React, { CSSProperties } from 'react';
import { css } from 'aphrodite';
import { t } from 'i18next';

import { TrackingProps } from 'Services/trackingService';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UIconToggle from 'Components/unit/UIconToggle/UIconToggle';
import UButton from 'Components/unit/UButton/UButton';
import UToggle from 'Components/unit/UToggle/UToggle';
import UTopActionBar from 'Components/unit/UTopActionBar/UTopActionBar';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import ULanguageMenu from 'Components/unit/ULanguageMenu/ULanguageMenu';
import STabList from 'Components/structural/STabList/STabList';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';
import type { StatusType, HelperType } from 'Components/unit/UTopActionBar/UTopActionBar';
import type { OptionType as LanguageOptionType, ULanguageMenuProps } from 'Components/unit/ULanguageMenu/ULanguageMenu';

import styles, { extendedStyles } from './SActionBar.style';

export type OptionType = {
  title: string;
  callback: () => void;
} & TrackingProps;

export type SActionBarProps = {
  isActive?: boolean;
  activeToggleLabel?: string;
  inactiveToggleLabel?: string;
  lastSaved?: string;
  canSave?: boolean;
  onSave: () => void;
  onBack: () => void;
  canActivate?: boolean;

  // @see withTooltip
  tooltipText?: string;

  // @see UTopActionBar
  id: number;
  title: string;
  titlePlaceholder?: string;
  status: StatusType;
  statusWording: string;
  isTranslated: boolean;
  helper?: HelperType;

  // @see STabList
  tabs: string[];
  selectedTab: number;
  onChangeTab: (tab: number) => void;

  // @see UIconButton
  options: Readonly<OptionType[]> | OptionType[];

  // @see ULanguageMenu
  optionsLanguages: Readonly<LanguageOptionType[]> | LanguageOptionType[];

  // @see UToggle
  onToggleChange: (toggled: boolean) => void;
  isToggleLoading?: boolean;

  isSaveLoading?: boolean;

  // @see UIconToggle
  onLock: (lock: boolean) => void;
  locked?: boolean;
  canLock?: boolean;

  style?: CSSProperties;
};

type SActionBarState = {
  displayOption: boolean;
};

/**
 * Display the activity action bar
 *
 * Props:
 *
 * //required
 * - id: activity id
 * - title: activity title
 * - status: status of the activity (@see StatusType)
 * - statusWording: wording of the given status
 * - isTranslated: is this activity is translated ?
 * - helper: display an helper icon
 * - tabs: array of string that represent a tab
 * - selectedTab: the current selected tab
 * - options: dropdown options (@see OptionType)
 * - optionsLanguages: options for the ULanguageMenu
 *
 * // optional
 * - titlePlaceholder: activity title placeholder
 * - isActive: is this activity is active
 * - canActivate: is the activation enabled
 * - tooltipText: text to display when the activation is disabled and the user clicks
 * - activeToggleLabel: toggle active label
 * - inactiveToggleLabel: toggle inactive label
 * - isToggleLoading: is the toggle loading
 * - isSaveLoading: is the save button loading
 * - canSave: is this activity can be saved ?
 * - lastSaved: text that represent the last saved
 * - canLock: is this activity can be locked ?
 * - locked: the locked status
 * - style: style to override

 * Callbacks (required):
 * - onSave: callback activity save
 * - onBack: callback on back
 * - onChangeTab: callback with the current new tab
 * - onToggleChange: callback on toggle change
 * - onLock: callback on lock
 */
export class SActionBar extends React.PureComponent<SActionBarProps, SActionBarState> {
  wrapperRef = React.createRef<HTMLDivElement>();
  WrappedCompToggle = withTooltip(UToggle);

  static defaultProps = {
    isActive: false,
    activeToggleLabel: 'Active',
    inactiveToggleLabel: 'Inactive',
    isToggleLoading: false,
    isSaveLoading: false,
    lastSaved: '',
    canSave: false,
    isTranslated: false,
    helper: undefined,
    locked: false,
    canLock: true,
    style: undefined,
    canActivate: true,
    tooltipText: '',
    titlePlaceholder: '',
  };

  state = {
    displayOption: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { lastSaved, onBack, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }} className={css(extendedStyles.wrapper)}>
        <div style={styles.wrapperInfos} className={css(extendedStyles.border)}>
          <UButton text={t('structural_component:s_action_bar.back')} onClick={onBack} style={styles.backButton} />

          {this.renderDescription()}
        </div>

        <div style={styles.wrapperTabs} className={css(extendedStyles.wrapperTabs)}>
          {this.renderTabs()}
        </div>

        <div style={styles.wrapperActions} className={css(extendedStyles.border)}>
          <div style={styles.lastEdit}>{lastSaved}</div>

          {this.renderSave()}
          {this.renderOption()}
          {this.renderLanguageButton()}
          {this.renderSpacer()}
          {this.renderToggle()}
          {this.renderLock()}
        </div>
      </div>
    );
  }

  renderDescription = () => {
    const { id, title, titlePlaceholder, status, statusWording, isTranslated, helper } = this.props;

    return (
      <UTopActionBar
        id={id}
        title={title}
        placeholder={titlePlaceholder}
        status={status}
        statusWording={statusWording}
        isTranslated={isTranslated}
        helper={helper}
        style={styles.topActionBar}
      />
    );
  };

  renderTabs = () => {
    const { selectedTab, tabs } = this.props;

    return <STabList tabs={tabs} selectedItem={selectedTab} onChangeTab={this.handleTabChange} align="center" />;
  };

  renderSave = () => {
    const { canSave, onSave, isSaveLoading } = this.props;

    return (
      <UButton
        text={t('structural_component:s_action_bar.save')}
        disabled={!canSave}
        type={!canSave ? 'standard' : 'accentuated'}
        style={styles.saveButton}
        onClick={onSave}
        loading={isSaveLoading}
      />
    );
  };

  renderLanguageButton = () => {
    const { optionsLanguages } = this.props;

    const countryCodeOptions = optionsLanguages.find(
      (optionLanguage) => optionLanguage.type === 'language' && optionLanguage.props.selected,
    );
    const countryCode = (countryCodeOptions as unknown as { props?: ULanguageMenuProps })?.props?.countryCode || '';

    return <ULanguageMenu countryCode={countryCode} options={optionsLanguages} style={styles.languageButton} />;
  };

  renderOption = () => {
    return (
      <div ref={this.wrapperRef} style={styles.optionButton}>
        <UIconButton icon="options" onClick={this.handleOptionClick} ghost />
        {this.renderOptionItems()}
      </div>
    );
  };

  renderOptionItems = () => {
    const { options } = this.props;
    const { displayOption } = this.state;

    if (!displayOption) return null;

    return <UDropdownBox items={options} renderItem={this.renderOptionItem} style={styles.optionDropDown} />;
  };

  renderOptionItem = (item: OptionType) => {
    const { title, callback, trackingAction, trackingContext } = item;

    return (
      <UDropdownItem
        key={title}
        text={title}
        onClick={this.handleOptionItemClick.bind(null, callback)}
        trackingAction={trackingAction}
        trackingContext={trackingContext}
      />
    );
  };

  renderLock = () => {
    const { canLock, locked } = this.props;

    if (canLock) {
      return (
        <UIconToggle
          iconOn="lock"
          textOn={t('structural_component:s_action_bar.lock')}
          iconOff="unlock"
          textOff={t('structural_component:s_action_bar.unlock')}
          toggled={locked}
          onToggle={this.handleLock}
          style={styles.lock}
        />
      );
    }

    return null;
  };

  renderSpacer = () => {
    return <div style={styles.spacer} />;
  };

  renderToggle = () => {
    const {
      isActive,
      activeToggleLabel,
      inactiveToggleLabel,
      onToggleChange,
      locked,
      isToggleLoading,
      canActivate,
      tooltipText,
    } = this.props;
    const Comp = this.WrappedCompToggle;

    return (
      <Comp
        tooltipPosition="left"
        tooltipContent={tooltipText}
        tooltipEnabled={!canActivate}
        tooltipMaxWidth={400}
        type="large"
        onChange={onToggleChange}
        style={styles.toggle}
        toggled={isActive}
        toggledLabel={activeToggleLabel}
        unToggledLabel={inactiveToggleLabel}
        isLocked={locked}
        isLoading={isToggleLoading}
        isClickable={canActivate}
      />
    );
  };

  handleLock = () => {
    const { onLock, locked } = this.props;

    onLock(!locked);
  };

  handleTabChange = (index: number) => {
    const { onChangeTab } = this.props;

    onChangeTab(index);
  };

  handleOptionClick = () => {
    const { displayOption } = this.state;

    this.setState({ displayOption: !displayOption });
  };

  handleOptionItemClick = (callback: () => void) => {
    this.setState({ displayOption: false });
    callback();
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target))
      this.setState({ displayOption: false });
  };
}

export default SActionBar;
