import type { Reducer } from 'redux';
import { handle } from 'redux-pack';

import type { Action } from 'Libs/redux/types';
import { AuthenticationContext, AuthenticationSteps } from 'Models/Enum';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { getErrorMessage } from 'Libs/redux/utils';

export type TwoFactorState = DeepReadonly<{
  phoneNumber: string;
  phoneCountryCode: string;
  invitationToken: string;
  twoFactorToken: string;
  type?: AuthenticationSteps;
  context: AuthenticationContext;
  error: string;
}>;

export const defaultState: TwoFactorState = {
  phoneNumber: '',
  phoneCountryCode: '',
  invitationToken: '',
  twoFactorToken: '',
  type: undefined,
  context: AuthenticationContext.LOGIN,
  error: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer: Reducer<TwoFactorState, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case '2FA_SET_INVITATION_TOKEN':
      return { ...state, invitationToken: action.invitationToken };
    case '2FA_SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.phoneNumber };
    case '2FA_SET_PHONE_COUNTRY_CODE':
      return { ...state, phoneCountryCode: action.phoneCountryCode };
    case 'ADMIN_ACTIVATE':
    case 'ADMIN_LOGIN': {
      return handle(state, action, {
        success: (prevState: TwoFactorState) => {
          const {
            payload: { type, twoFactorToken, phoneNumber },
          } = action;

          // FIXME Why do we need to restrict the type?
          const newType = [
            AuthenticationSteps.USER_SESSION,
            AuthenticationSteps.TWO_FACTOR_CREDENTIALS_MISSING,
          ].includes(type)
            ? type
            : undefined;

          return mergeDeep(prevState, {
            type: newType,
            context: action.type === 'ADMIN_ACTIVATE' ? AuthenticationContext.SIGN_UP : AuthenticationContext.LOGIN,
            twoFactorToken,
            phoneNumber: action.type === 'ADMIN_LOGIN' ? phoneNumber : prevState.phoneNumber,
            error: '',
          });
        },
      });
    }
    case '2FA_RESEND_CODE': {
      return handle(state, action, {
        success: (prevState) => {
          const {
            payload: { twoFactorToken, type },
          } = action;

          return mergeDeep(prevState, {
            type,
            twoFactorToken,
            error: '',
          });
        },
        failure: (prevState) => mergeDeep(prevState, { error: getErrorMessage(action) }),
      });
    }
    case '2FA_REGISTER_PHONE': {
      return handle(state, action, {
        success: (prevState: TwoFactorState) => {
          const {
            payload: { type, twoFactorToken, phoneNumber },
          } = action;

          return mergeDeep(prevState, { type, twoFactorToken, phoneNumber, error: '' });
        },
        failure: (prevState) => {
          const errorMessage = getErrorMessage(action);

          return mergeDeep(prevState, { error: errorMessage });
        },
      });
    }
    case '2FA_VERIFY_CODE': {
      return handle(state, action, {
        success: (prevState: TwoFactorState) => {
          const {
            payload: { type },
          } = action;

          return mergeDeep(prevState, { type, error: '' });
        },
        failure: (prevState) => {
          const errorMessage = getErrorMessage(action);

          return mergeDeep(prevState, { error: errorMessage });
        },
      });
    }
    case '2FA_RESET': {
      return { ...state, ...defaultState };
    }
    case '2FA_RESET_ERROR': {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};
