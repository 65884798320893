import React, { useCallback, useEffect, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { css } from 'aphrodite';
import { useTranslation } from 'react-i18next';

import MModal from 'Components/modal/MModal/MModal';
import { COLORS } from 'Components/foundation';
import { useService } from 'Hooks/useService';
import { deleteAudienceGroup } from 'Features/audience/services/delete-audience-group.service';
import { useCourseListFromAudience } from 'Features/audience/hooks/useCourseListFromAudience';
import { enqueueBasicAlert } from 'Pages/AlertManager';

import styles from './DeleteAudienceGroupModal.style';

export type DeleteAudienceGroupModalProps = {
  audienceGroupId: number;
  onDone: () => Promise<void>;
  onClose: () => void;
};

/**
 * DeleteAudienceGroupModal
 *
 * @param audienceGroupId (required) audience group id to delete, modal not visible if set to -1
 * @param onDone (required) callback to execute when the audience group is deleted
 * @param onClose (required) callback to execute to close the modal
 */
export const DeleteAudienceGroupModal: FunctionComponent<DeleteAudienceGroupModalProps> = ({
  audienceGroupId,
  onDone,
  onClose,
}) => {
  const { t } = useTranslation();

  const onHandleDeleteError = useCallback(() => {
    enqueueBasicAlert({
      id: 'COURSE_EDITION_AUDIENCE_DELETE_ERROR',
      text: 'Error while deleting audience group.',
      title: 'Something went wrong',
      icon: 'alert',
      status: 'error',
      priority: 'low',
    });
    onClose();
  }, [onClose]);

  const { loading, call: handleDelete } = useService({
    service: deleteAudienceGroup,
    onSuccess: onDone,
    onError: onHandleDeleteError,
  });

  const handleAction = useCallback(() => handleDelete({ id: audienceGroupId }), [audienceGroupId, handleDelete]);

  const {
    courses,
    loading: courseListLoading,
    CourseListFromAudience,
    setAudienceGroupId,
  } = useCourseListFromAudience();

  useEffect(() => {
    setAudienceGroupId(audienceGroupId);
  }, [audienceGroupId, setAudienceGroupId]);

  const description = useMemo(() => {
    if (courses && courses.length > 0) {
      return t('audiences:delete_audience_modal.description_affecting_groups');
    }

    return t('audiences:delete_audience_modal.description_default');
  }, [courses]);

  const isVisible = useMemo(() => audienceGroupId !== -1, [audienceGroupId]);

  return (
    <MModal
      type="dialog"
      visible={isVisible}
      showExitCross
      title={t('audiences:delete_audience_modal.title')}
      description={description}
      labelActionButton={t('audiences:delete_audience_modal.delete')}
      labelSecondButton={t('audiences:delete_audience_modal.cancel')}
      actionButtonType="destructive"
      isActionLoading={loading}
      className={css(styles.modal)}
      onCloseModal={onClose}
      onAction={handleAction}
      onSecondAction={onClose}
      disableActionButton={courseListLoading}
    >
      <div className={css(styles.courseListFromAudienceContainer)}>
        <CourseListFromAudience />
        <div className={css(styles.courseListFromAudienceContainerLoading)}>
          <CircleSpinner size={18} color={COLORS.TEXT.SECONDARY_DEFAULT} loading={courseListLoading} />
        </div>
      </div>
    </MModal>
  );
};
