import m from 'mithril';

var component = {};

/*
 * args = {
 *   promise,
 *   class,
 *   type,
 *   action,
 *   value,
 *   error,
 *   displayErr,
 * };
 */
component.controller = function controller(args) {
  var self = this;
  var button;

  self.elem = function configButtonElem(elem, init) {
    if (!init)
      button = elem;
  };

  self.loader = function loader() {
    var txt = button.children[0].textContent;

    button.children[0].textContent = '';

    button.classList.add('loader');
    button.classList.add('loader--light');

    args.promise()
      .then(removeLoader.bind(null, txt))
      .catch(removeLoader.bind(null, txt));
  };

  function removeLoader(txt) {
    button.classList.remove('loader--light');
    button.classList.remove('loader');
    button.children[0].textContent = txt;
  }
};

component.view = function view(c, args) {
  var disabled = false;

  if (args.disabled)
    disabled = true;

  return m('.button', {
    class: args.class,
  }, [
    m('button.button__elem', {
      class   : disabled ? 'button--disabled' : '',
      config  : c.elem,
      type    : args.type || 'button',
      onclick : args.action,
      disabled: disabled,
    }, [
      m('.button__type.button__type--left'),
      m('.button__value', {
        class: args.loading
          ? 'button__value--transparent'
          : '',
      }, args.value),
      m('.button__value.button-loading_loading-animation', {
        class: [
          args.loading
          ? 'button-loading--loading'
          : '',
        ].join(' '),
      }, [
        m('.loading-animation__magic-point.magic-point-one'),
        m('.loading-animation__magic-point.magic-point-two'),
        m('.loading-animation__magic-point.magic-point-three'),
      ]),
      m('.button__type.button__type--right'),
    ]),
    args.error ? m('.button__error', {
      class: args.error.msg().length && args.displayErr() ? '' : 'hide',
    }, args.error.msg()) : null,
  ]);
};

export default component;
