// @flow

export default {
  wrapper: {
    display: 'flex',
    padding: '20px 30px',
    alignItems: 'center',
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0.1,
  },
};
