import { t } from 'i18next';
import { COLORS } from 'Components/foundation';
import Enum from 'Models/Enum';
import { UStatusType } from 'Components/unit/UStatusIndicator/UStatusIndicator';

import { ActivityType, ActivityStatusType } from './SActivityListItem.types';

// Header constants
type ActivityTypeMap = {
  [key: ActivityType]: {
    label: string;
    colorLabel: keyof typeof COLORS.ACTIVITY;
    backgroundColor: keyof typeof COLORS.ACTIVITY;
  };
};
export const getActivityTypeMap = (): ActivityTypeMap => ({
  [Enum.activityTypeEnum.DailySerie]: {
    label: t('structural_component:s_activity_list_item.campaign_label'),
    colorLabel: 'CAMPAIGN_MEDIUM',
    backgroundColor: 'CAMPAIGN_LIGHT',
  },
  [Enum.activityTypeEnum.BreakingNews]: {
    label: t('structural_component:s_activity_list_item.breaking_news_label'),
    colorLabel: 'BREAKING_NEWS_MEDIUM',
    backgroundColor: 'BREAKING_NEWS_LIGHT',
  },
  [Enum.activityTypeEnum.Welcome]: {
    label: t('structural_component:s_activity_list_item.welcome_label'),
    colorLabel: 'WELCOME_MEDIUM',
    backgroundColor: 'WELCOME_LIGHT',
  },
  [Enum.activityTypeEnum.ThatsAllFolks]: {
    label: t('structural_component:s_activity_list_item.thats_all_folks_label'),
    colorLabel: 'THATS_ALL_FOLKS_MEDIUM',
    backgroundColor: 'THATS_ALL_FOLKS_LIGHT',
  },
  [Enum.activityTypeEnum.Interseason]: {
    label: t('structural_component:s_activity_list_item.interseason_label'),
    colorLabel: 'INTERSEASON_MEDIUM',
    backgroundColor: 'INTERSEASON_LIGHT',
  },
});

export const STATUS = Object.freeze({
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
  LIVE: 'Live',
  VISIBLE: 'Visible',
  UPCOMING: 'Upcoming',
  FINISHED: 'Finished',
  ARCHIVED: 'Archived',
  PENDING_TRANSLATION: 'Pending translation',
});

export const STATUS_COLOR = {
  [STATUS.DRAFT]: COLORS.TEXT.DISABLED_DEFAULT,
  [STATUS.ARCHIVED]: COLORS.TEXT.DISABLED_DEFAULT,
  [STATUS.INACTIVE]: COLORS.TEXT.SECONDARY_DEFAULT,
  [STATUS.LIVE]: COLORS.SUCCESS.DEFAULT,
  [STATUS.FINISHED]: COLORS.SUCCESS.DEFAULT,
  [STATUS.VISIBLE]: COLORS.SUCCESS.DEFAULT,
  [STATUS.UPCOMING]: COLORS.WARNING.DEFAULT,
  [STATUS.PENDING_TRANSLATION]: COLORS.ALERT.ERROR,
};

export const ACTIVITY_STATUS_TYPE_MAP: { [key in ActivityStatusType]: UStatusType } = {
  Upcoming: 'upcoming',
  Live: 'valid',
  Visible: 'valid',
  Finished: 'valid',
  Inactive: 'invalid',
  Archived: 'invalid',
  Draft: 'invalid',
  'Pending translation': 'warning',
};
