// @flow

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { GAMEPLAY_KEYS } from 'Components/utils/Enum';

import SInput from 'Components/structural/SInput/SInput';
import { actions } from '../../../redux';

import styles from './OCInstructionForm.style';

export const OCInstructionForm = () => {
  const { t } = useTranslation();

  const content = useTypedSelector(state => state.pages.content.content);
  const canEdit = useTypedSelector(state => state.pages.content.canEditContent);

  const orderingCards = content[GAMEPLAY_KEYS.ORDERING_CARDS];

  const instruction = orderingCards.question.data;

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setOCQuestion(value)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.label}>
        {t('gameplays:ordering_cards.instruction_label')}
      </div>

      <SInput
        type="large"
        placeholder={t('gameplays:ordering_cards.instruction_placeholder')}
        value={instruction}
        onChange={onChange}
        maxLength={60}
        disabled={!canEdit}
      />
    </div>
  );
};

export default OCInstructionForm;
