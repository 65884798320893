/**
 * Displays a UTextLink and add prop loading
 *
 * Props:
 * - onClick: Function called when we click on the link
 * - onActionEnd: Function called in the loading end
 * - loadingLabel: label next to the loader
 * - type: The type of link ('standard' or 'accentuated')
 * - style: For overriding the component's style
 * - text: The content of the link
 * - disabled: Boolean that prevent user's actions on the link
 */

import withLoading from 'Components/hoc/withLoading/withLoading';
import UTextLink from './UTextLink';

const UTextLinkWithLoading = withLoading({
  timeBeforeLoading: 100,
  minTimeLoading: 200,
})(UTextLink);

export default UTextLinkWithLoading;
