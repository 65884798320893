/* eslint-disable complexity */
import m from 'm';

import { COLORS, LEVELS } from 'Components/foundation';

var component = {};

component.controller = function controller(args) {
  var self = this;

  var onMouseUp = mouseup.bind(null, args.index);

  self.onmouse = m.prop(false);
  self.selected = m.prop(false);
  self.value = m.prop(args.value || '');
  self.hideList = m.prop(false);

  self.changeSelected = function changeSelected() {
    self.selected(!self.selected());

    if (self.selected()) self.hideList(false);
  };

  self.configInput = function configInput(elem, isInit, ctx) {
    if (isInit) return;

    document.addEventListener('mouseup', onMouseUp);
  };

  self.onunload = function onunload() {
    document.removeEventListener('mouseup', onMouseUp);
  };

  self.chooseValue = function chooseValue(value) {
    self.value(value.name ? value.name() : value.label());
    args.callback(value);
  };

  function mouseup(index, e) {
    var selector = '#selectListContainer' + index;
    var container = document.querySelector(selector);

    if (!container.contains(e.target)) {
      self.selected(false);
      self.hideList(true);
      m.redraw();
    }
  }
};

function maskImageStyle(args) {
  const arrowImage = 'url(/assets/img/arrow_blue_bottom.png)';
  const arrowColor = !args.editable ? COLORS.BLUE_GRAY_DARK.DEFAULT : '#4D8DF7';

  return {
    maskImage: arrowImage,
    webkitMaskImage: arrowImage,
    backgroundColor: arrowColor,
    zIndex: LEVELS.LOCAL_OFFSET,
  };
}

component.view = function view(c, args) {
  return m(
    '.select-list',
    {
      class: [!args.editable ? 'select-list--disabled' : '', args.invalid ? 'select-list--invalid' : ''].join(' '),
      onmouseover: c.onmouse.bind(null, true),
      onmouseout: c.onmouse.bind(null, false),
      onclick: args.editable ? c.changeSelected.bind(null) : function () {},
      config: c.configInput,
      id: 'selectListContainer' + args.index,
    },
    [
      m(
        '.select-list-box',
        {
          class: c.value().length && !c.selected() ? 'select-list-filled' : 'select-list-empty',
        },
        [
          m(
            '.select-list-text',
            {
              class: c.value().length && !c.selected() ? '' : 'select-list-placeholder',
            },
            c.value() && !c.selected() ? c.value() : args.placeholder,
          ),
          m('.select-icon', {
            style: { ...maskImageStyle(args) },
          }),
        ],
      ),
      m(
        '.select-list-choice',
        {
          class: c.selected() && !c.hideList() ? '' : 'hide',
        },
        renderTree(args.levelOne, args.levelTwo, args.levelThree),
      ),
    ],
  );

  function renderTree(levelOne, levelTwo, levelThree) {
    var ancestors = {};
    var tree = [];

    // Level 2
    if (levelTwo && levelTwo.length) {
      levelTwo.map(function addLevel2(elemTwo) {
        var elemTwoAncestorId = elemTwo.ancestorId();

        if (!ancestors[elemTwoAncestorId]) ancestors[elemTwoAncestorId] = [];

        ancestors[elemTwoAncestorId].push(elemTwo);
      });
    }

    // Level 3
    if (levelThree && levelThree.length) {
      levelThree.map(function addLevel2(elemThree) {
        var elemThreeAncestorId = elemThree.ancestorId();

        if (!ancestors[elemThreeAncestorId]) ancestors[elemThreeAncestorId] = [];

        ancestors[elemThreeAncestorId].push(elemThree);
      });
    }

    // Level 1
    if (levelOne && levelOne.length) {
      levelOne.map(function renderLevel1(elemOne, key) {
        var elemTwoChildren = ancestors[elemOne.id()] || [];

        // Render Level 1
        tree.push(displayLevel(elemOne, 1, key));

        elemTwoChildren.map(function renderLevel2(childrenTwo, childrenTwoKey) {
          var elemThreeChildren = ancestors[childrenTwo.id()] || [];

          // Render Level 2
          tree.push(displayLevel(childrenTwo, 2, childrenTwoKey));

          elemThreeChildren.map(function renderLevel3(childrenThree, childrenThreeKey) {
            // Render Level 3
            tree.push(displayLevel(childrenThree, 3, childrenThreeKey));
          });
        });
      });
    }

    return tree;
  }

  function displayLevel(elem, level, key) {
    return m(
      '.select-list-choice-level',
      {
        class: c.selected() ? (key === 0 && level === 1 ? 'select-list-choice-first' : '') : 'hide',
        onclick: c.chooseValue.bind(null, elem),
        style: {
          paddingLeft: level * 10 + 'px',
          fontWeight: level === 1 ? '600' : 'normal',
        },
      },
      elem.name ? elem.name() : elem.label(),
    );
  }
};
export default component;
