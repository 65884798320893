import React, { MouseEventHandler } from 'react';
import type { CSSProperties } from 'react';
import { memoize } from 'lodash';
import { t } from 'i18next';

import USuggestionItem from 'Components/unit/USuggestionItem/USuggestionItem';

import styles from './USuggestionBox.style';

export type TextSuggestion = {
  category: 'text';
  value: string;
};

export type CampaignSuggestion = {
  category: 'campaign';
  id: number;
  name: string;
};

export type UserSuggestion = {
  category: 'user';
  id: number;
  name: string;
  picturePath?: string;
};

export type SegmentSuggestion = {
  category: 'segment';
  id: number;
  name: string;
  color?: string;
};

export type Suggestion = TextSuggestion | CampaignSuggestion | UserSuggestion | SegmentSuggestion;

export type USuggestionBoxProps = {
  style: CSSProperties;
  suggestions: Array<Suggestion>;
  maxSuggestionBoxHeight: number;
  onSelect: (suggestion: Suggestion) => void;
  showCategoryName?: boolean;
};

type USuggestionBoxState = {
  activeKey: string;
  didActivateWithMouse: boolean;
};

const MIN_VALUE_MAX_HEIGHT = 126;
const DEFAULT_MAX_BOX_HEIGHT = 380;

const makeKey = (suggestion: Suggestion) =>
  `${suggestion.category}:${suggestion.category === 'text' ? suggestion.value : suggestion.id}`;

const getHeaderName = (category: Suggestion['category']) => {
  switch (category) {
    case 'campaign':
      return t('unit_components:u_suggestion_box.header.campaigns');
    case 'user':
      return t('unit_components:u_suggestion_box.header.users');
    case 'segment':
      return t('unit_components:u_suggestion_box.header.segments');
    case 'text':
    default:
      return t('unit_components:u_suggestion_box.header.text');
  }
};

const renderHeader = (category: Suggestion['category']) => (
  <div data-test-id="suggestion-box-header" style={styles.headerContainer}>
    <div style={styles.header}>{getHeaderName(category)}</div>
  </div>
);

/**
 * Suggestion box.
 *
 * Props:
 * - suggestions: The array of suggestions
 * - maxSuggestionBoxHeight: The max height to set to the suggestion box
 * - onSelect: Function called when a suggestion is selected
 */
export class USuggestionBox extends React.PureComponent<USuggestionBoxProps, USuggestionBoxState> {
  static defaultProps = {
    maxSuggestionBoxHeight: DEFAULT_MAX_BOX_HEIGHT,
    showCategoryName: true,
    style: undefined,
  };

  state = {
    activeKey: '',
    didActivateWithMouse: false,
  };

  suggestionRefs: { [key: string]: { current: null | HTMLDivElement } } = {};

  handleClick = memoize(
    (suggestion: Suggestion): MouseEventHandler<Element> =>
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { onSelect } = this.props;

        onSelect(suggestion);
      },
  );

  setActiveKey = memoize((key: string) => () => {
    this.setState({ activeKey: key, didActivateWithMouse: true });
  });

  unsetActiveKey = memoize((key: string) => () => {
    this.setState((state) => (state.activeKey === key ? { activeKey: '', didActivateWithMouse: false } : state));
  });

  componentDidUpdate() {
    const { didActivateWithMouse } = this.state;

    // INFO disable auto-scroll when mouse hovering to prevent side effects
    if (!didActivateWithMouse) {
      this.scrollIntoActiveSuggestion();
    }
  }

  renderElements = () => {
    const { suggestions, showCategoryName } = this.props;
    const { activeKey } = this.state;

    return suggestions.reduce((acc, suggestion, i, a) => {
      if (i === 0 || a[i - 1].category !== suggestion.category) {
        acc.push(
          <React.Fragment key={suggestion.category}>
            {showCategoryName && renderHeader(suggestion.category)}
          </React.Fragment>,
        );
      }

      const key = makeKey(suggestion);

      this.suggestionRefs[key] = React.createRef<HTMLDivElement>();
      acc.push(
        <div key={key} ref={this.suggestionRefs[key]}>
          <USuggestionItem
            suggestion={suggestion}
            active={key === activeKey}
            onClick={this.handleClick(suggestion)}
            onMouseMove={this.setActiveKey(key)}
            onMouseLeave={this.unsetActiveKey(key)}
          />
        </div>,
      );

      return acc;
    }, [] as JSX.Element[]);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- called from outside
  activateNext = () => {
    const { suggestions } = this.props;

    if (suggestions.length === 0) {
      return;
    }

    const nextIndex = this.makeNextIndex(true);

    this.setState({ activeKey: makeKey(suggestions[nextIndex]), didActivateWithMouse: false });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- called from outside
  activatePrevious = () => {
    const { suggestions } = this.props;

    if (suggestions.length === 0) {
      return;
    }

    const nextIndex = this.makeNextIndex(false);

    this.setState({ activeKey: makeKey(suggestions[nextIndex]), didActivateWithMouse: false });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- called from outside
  getActive = () => {
    const { suggestions } = this.props;
    const { activeKey } = this.state;
    const suggestion = suggestions.find((x) => makeKey(x) === activeKey);

    return suggestion || null;
  };

  makeNextIndex = (ascending: boolean) => {
    const { suggestions } = this.props;
    const { activeKey } = this.state;
    const index = suggestions.findIndex((x) => makeKey(x) === activeKey);

    // eslint-disable-next-line no-nested-ternary
    return ascending
      ? index >= 0
        ? (index + 1) % suggestions.length
        : 0
      : index >= 0
      ? (index + suggestions.length - 1) % suggestions.length
      : suggestions.length - 1;
  };

  scrollIntoActiveSuggestion = () => {
    const { suggestions } = this.props;
    const { activeKey } = this.state;

    const suggestion = suggestions.find((x) => makeKey(x) === activeKey);

    if (suggestion) {
      const ref = this.suggestionRefs[makeKey(suggestion)];

      if (ref && ref.current) {
        ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    }
  };

  render() {
    const { style, maxSuggestionBoxHeight, showCategoryName } = this.props;
    const elements = this.renderElements();

    const boxHeight = {
      maxHeight: Math.max(maxSuggestionBoxHeight, MIN_VALUE_MAX_HEIGHT),
      minHeight: showCategoryName ? 68 : 35,
    };

    return <div style={{ ...styles.wrapper, ...boxHeight, ...style }}>{elements}</div>;
  }
}

export default USuggestionBox;
