/* eslint-disable max-len */
/* eslint-disable complexity */
import m from 'm';
import Promise from 'bluebird';
import popup from 'services/popupService';
import User from 'services/userService';
import App from 'models/App';
import Enum from 'models/Enum';
import Model from 'models/Model';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import toggleButton from 'components/toggle.button';
import inputNumber from 'components/input.number';
import { VALID_LINK_REGEX } from 'Libs/regex';

var component = {};
var MIN_ATTEMPS = 2;
var MAX_ATTEMPS = 20;
var MIN_FRAME_TIME = 1;
var MAX_FRAME_TIME = 59;
var MIN_LOCK_TIME = 1;
var MAX_LOCK_TIME = 59;

var PolyglotLabel = Model('PolyglotLabel');
var TranslatedLabel = Model('TranslatedLabel');
var DEFAULT_NOTIF_HOUR = 14;
var MAX_NB_DAYS_OFFLINE = 3;
var MIN_NB_DAYS_OFFLINE = 0;
var MIN_NOTIFICATION_HOUR = 0;
var MAX_NOTIFICATION_HOUR = 23;

const noop = () => {};

component.controller = function controller() {
  var self = this;

  self.saving = m.prop(false);
  self.modified = m.prop(false);
  self.saved = m.prop(false);

  self.lockoutEnabled = App.configurations().get(Enum.configurations.APPLICATION_LOCKOUT);
  self.maxAttempts = App.configurations().get(Enum.configurations.APPLICATION_LOCKOUT_MAX_ATTEMPTS);
  self.frameTime = App.configurations().get(Enum.configurations.APPLICATION_LOCKOUT_FRAME_TIME);
  self.lockTime = App.configurations().get(Enum.configurations.APPLICATION_LOCKOUT_LOCK_TIME);

  self.configLockoutEnabled = m.prop(self.lockoutEnabled.value());
  self.configMaxAttempts = m.prop(self.maxAttempts.value());
  self.configFrameTime = m.prop(self.frameTime.value());
  self.configLockTime = m.prop(self.lockTime.value());
  self.configLockoutEnabledDescription = m.prop(self.lockoutEnabled.description());
  self.configMaxAttemptsDescription = m.prop(self.maxAttempts.description());
  self.configFrameTimeDescription = m.prop(self.frameTime.description());
  self.configLockTimeDescription = m.prop(self.lockTime.description());

  self.notificationTimeConfig = m.prop(App.configurations().first({
    id: Enum.configurations.NOTIFICATION_TIME,
  }));

  self.signupOpenInputActiveConfig = m.prop(
    App.configurations().get(Enum.configurations.SIGNUP_OPEN_INPUT_ACTIVE)
  );

  self.setupCodeConfig = m.prop(
    App.configurations().get(Enum.configurations.SETUP_CODE_AVAILABLE)
  );

  self.signupOpenInputLabelConfig = m.prop(
    App.configurations().get(Enum.configurations.SIGNUP_OPEN_INPUT_LABEL)
  );

  self.signupOpenInputLabel = m.prop();

  self.signupOpenInputMandatoryConfig = m.prop(
    App.configurations().get(Enum.configurations.SIGNUP_OPEN_INPUT_MANDATORY)
  );

  self.setupCodeAvailable = m.prop(self.setupCodeConfig().value());
  self.signupOpenInputActive = m.prop(self.signupOpenInputActiveConfig().value());
  self.signupOpenInputMandatory = m.prop(self.signupOpenInputMandatoryConfig().value());

  self.offlineNbDaysDownloaded = m.prop(
    App.configurations().get(Enum.configurations.OFFLINE_NB_DAYS_DOWNLOAD)
  );

  self.nbDaysOffline = m.prop(self.offlineNbDaysDownloaded().value());

  // Email customization configs
  self.customEmailEnabledConfig = m.prop(App.configurations().get(Enum.configurations.CUSTOM_PLAYER_EMAIL_ACTIVATED));
  self.customEmailTitleConfig = m.prop(App.configurations().get(Enum.configurations.CUSTOM_PLAYER_EMAIL_TITLE));
  self.customEmailDescriptionConfig = m.prop(App.configurations().get(Enum.configurations.CUSTOM_PLAYER_EMAIL_DESCRIPTION));
  self.customEmailButtonLabelConfig = m.prop(App.configurations().get(Enum.configurations.CUSTOM_PLAYER_EMAIL_BUTTON_LABEL));
  self.customEmailButtonLinkConfig = m.prop(App.configurations().get(Enum.configurations.CUSTOM_PLAYER_EMAIL_BUTTON_LINK));

  // Email customization polyglot labels
  self.customEmailTitlePolyglotLabel = m.prop();
  self.customEmailDescriptionPolyglotLabel = m.prop();
  self.customEmailButtonLabelPolyglotLabel = m.prop();
  self.customEmailButtonLinkPolyglotLabel = m.prop();

  self.isCustomEmailEnabled = m.prop(self.customEmailEnabledConfig().value());

  const MAX_CUSTOM_EMAIL_TITLE_LENGTH = 50;
  const MAX_CUSTOM_EMAIL_DESCRIPTION_LENGTH = 500;
  const MAX_CUSTOM_EMAIL_BUTTON_LABEL_LENGTH = 25;

  self.handleCustomEmailFormValidation = () => {
    const isEnabled = self.isCustomEmailEnabled();

    if (!isEnabled)
      return undefined;

    const title = getPolyglotLabel(self.customEmailTitlePolyglotLabel()).label();
    const description = getPolyglotLabel(self.customEmailDescriptionPolyglotLabel()).label();
    const buttonLabel = getPolyglotLabel(self.customEmailButtonLabelPolyglotLabel()).label();
    const buttonLink = getPolyglotLabel(self.customEmailButtonLinkPolyglotLabel()).label();

    const validationRules = [
      { type: 'title', value: title, maxLength: MAX_CUSTOM_EMAIL_TITLE_LENGTH },
      { type: 'content', value: description, maxLength: MAX_CUSTOM_EMAIL_DESCRIPTION_LENGTH },
      { type: 'button label', value: buttonLabel, maxLength: MAX_CUSTOM_EMAIL_BUTTON_LABEL_LENGTH },
    ];

    for (const rule of validationRules) {
      const { type, value, maxLength } = rule;

      if (maxLength && value.length > maxLength) {
        return {
          type: 'info',
          title: `Invalid email ${type}`,
          content: `The maximum length of ${type} is ${maxLength} characters`,
        };
      }
    }

    // Check if either the link or the label are set and the other isnt
    if ((buttonLink.length && !buttonLabel.length) || (!buttonLink.length && buttonLabel.length)) {
      return {
        type: 'info',
        title: 'Some informations are missing',
        content: 'You need to set both the button label and the button link',
      };
    }

    if (buttonLink && !buttonLink.match(VALID_LINK_REGEX)) {
      return {
        type: 'info',
        title: 'Invalid email button link',
        content: 'The button link should be a valid link. https://www.website.com',
      };
    }

    return undefined;
  };

  self.handleCustomEmailSave = () => {
    const isEnabled = self.isCustomEmailEnabled();

    self.customEmailEnabledConfig().value(isEnabled);

    const saveCustomEmailEnabledPromise = self.customEmailEnabledConfig().save();

    // Don't save change to form if custom email is disabled
    return isEnabled
      ? Promise.all([
        saveCustomEmailEnabledPromise,
        getPolyglotLabel(self.customEmailTitlePolyglotLabel()).save(),
        getPolyglotLabel(self.customEmailDescriptionPolyglotLabel()).save(),
        getPolyglotLabel(self.customEmailButtonLabelPolyglotLabel()).save(),
        getPolyglotLabel(self.customEmailButtonLinkPolyglotLabel()).save(),
      ])
      : saveCustomEmailEnabledPromise;
  };

  self.handleFetchPolyglotLabels = async () => {
    const getFilter = (id) => ({
      filter: {
        where: { id },
        include: 'translatedLabels',
      },
    });

    const [
      signupOpenInputLabelPolyglot,
      customEmailTitlePolyglot,
      customEmailDescriptionPolyglot,
      customEmailButtonLabelPolyglot,
      customEmailButtonLinkPolyglot,
    ] = await Promise.all([
      PolyglotLabel.find(getFilter(self.signupOpenInputLabelConfig().value())),
      PolyglotLabel.find(getFilter(self.customEmailTitleConfig().value())),
      PolyglotLabel.find(getFilter(self.customEmailDescriptionConfig().value())),
      PolyglotLabel.find(getFilter(self.customEmailButtonLabelConfig().value())),
      PolyglotLabel.find(getFilter(self.customEmailButtonLinkConfig().value())),
    ]);

    self.signupOpenInputLabel(signupOpenInputLabelPolyglot.at(0));
    self.customEmailTitlePolyglotLabel(customEmailTitlePolyglot.at(0));
    self.customEmailDescriptionPolyglotLabel(customEmailDescriptionPolyglot.at(0));
    self.customEmailButtonLabelPolyglotLabel(customEmailButtonLabelPolyglot.at(0));
    self.customEmailButtonLinkPolyglotLabel(customEmailButtonLinkPolyglot.at(0));

    m.redraw();
  };

  self.handleFetchPolyglotLabels();

  var notificationHour = self.notificationTimeConfig().value();

  console.log('[configuration]', 'Loaded hour:', notificationHour);
  if (!notificationHour || isNaN(notificationHour))
    notificationHour = DEFAULT_NOTIF_HOUR;

  self.notificationHour = m.prop(notificationHour);

  self.save = function save() {
    self.saving(true);
    m.redraw();

    const customEmailFormValidationAlert = self.handleCustomEmailFormValidation();

    if (customEmailFormValidationAlert) {
      self.saving(false);

      return popup.open(customEmailFormValidationAlert);
    }

    if (self.notificationHour() < 0 || self.notificationHour() > 23) {
      self.saving(false);

      return popup.open({
        type    : 'info',
        title   : 'Incorrect notification time',
        subtitle: 'The notification time have to be between 0 and 23',
      });
    }

    if (self.nbDaysOffline() < MIN_NB_DAYS_OFFLINE || self.nbDaysOffline() > MAX_NB_DAYS_OFFLINE) {
      self.saving(false);

      return popup.open({
        type    : 'info',
        title   : 'Incorrect number of days offline',
        subtitle: [
          'You can only choose a value between',
          MIN_NB_DAYS_OFFLINE,
          'and',
          MAX_NB_DAYS_OFFLINE,
          'as the number of day to play offline',
        ].join(' '),
      });
    }

    self.lockoutEnabled.value(self.configLockoutEnabled());
    self.maxAttempts.value(self.configMaxAttempts());
    self.frameTime.value(self.configFrameTime());
    self.lockTime.value(self.configLockTime());

    self.notificationTimeConfig().value(self.notificationHour());
    self.offlineNbDaysDownloaded().value(self.nbDaysOffline());

    self.signupOpenInputActiveConfig().value(self.signupOpenInputActive());
    self.signupOpenInputMandatoryConfig().value(self.signupOpenInputMandatory());
    self.setupCodeConfig().value(self.setupCodeAvailable());

    var promises = [
      self.lockoutEnabled.save(),
      self.maxAttempts.save(),
      self.frameTime.save(),
      self.lockTime.save(),
      self.notificationTimeConfig().save(),
      self.signupOpenInputActiveConfig().save(),
      self.signupOpenInputMandatoryConfig().save(),
      self.setupCodeConfig().save(),
      self.offlineNbDaysDownloaded().save(),
      self.handleCustomEmailSave(),
      getPolyglotLabel(self.signupOpenInputLabel()).save(),
    ];

    Promise.all(promises)
      .then(self.saved.bind(null, true))
      .then(self.modified.bind(null, false))
      .then(self.saving.bind(null, false))
      .then(m.redraw.bind(null, false));
  };

  self.warningSave = function warningSave(action) {
    if (!self.modified())
      return action();

    function wrappedAction() {
      self.modified(false);
      action();
    }
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit without saving?',
      confirm: 'Don\'t save',
      cancel : 'Cancel',
      action : wrappedAction,
    });
  };

  // Return an handler for onInput that also set the page as modified when setting value
  self.onInputFactory = (setValueFn) => {
    return (value) => {
      self.modified(true);
      setValueFn(value);
    };
  };

  self.validateNbDaysOffline = function validateNbDaysOffline(value) {
    return self.nbDaysOffline() + value >= MIN_NB_DAYS_OFFLINE
      && self.nbDaysOffline() + value <= MAX_NB_DAYS_OFFLINE;
  };

  self.validateNotificationHour = function validateNotificationHour(value) {
    return self.notificationHour() + value >= MIN_NOTIFICATION_HOUR
      && self.notificationHour() + value <= MAX_NOTIFICATION_HOUR;
  };

  self.validateMaxAttempts = function validateMaxAttempts(value) {
    return self.configMaxAttempts() + value >= MIN_ATTEMPS
      && self.configMaxAttempts() + value <= MAX_ATTEMPS;
  };

  self.validateMaxFrameTime = function validateMaxAttempts(value) {
    return self.configFrameTime() + value >= MIN_FRAME_TIME
      && self.configFrameTime() + value <= MAX_FRAME_TIME;
  };

  self.validateMaxLockTime = function validateMaxAttempts(value) {
    return self.configLockTime() + value >= MIN_LOCK_TIME
      && self.configLockTime() + value <= MAX_LOCK_TIME;
  };
};

component.view = function view(c) {
  var saveButton = m(button, {
    value   : c.modified() ? 'Save' : 'Saved',
    class   : 'button--save',
    action  : c.save,
    disabled: !c.modified(),
    loading : c.saving(),
  });

  var isRoot = User.meSync().hasOneRole([Enum.Role.ROOT]);

  return m('.gaia__page', [
    m('.header', [
      m(menu, {
        confirm: c.warningSave,
      }),
      m(gaiaMenu, {
        confirm: c.warningSave,
      }),
    ]),
    m('.gaia__body', [
      m('.gaia__toolbar', [
        m('.gaia__title__container', [
          m('.gaia__title', 'Application'),
          m('.gaia__subtitle', 'Security password'),
        ]),
        m('.gaia__toolbar--right', [saveButton]),
      ]),
      m('.gaia__body__container', {
        key: 'application',
      }, [
        m.hide(c.configLockoutEnabled(), '.gaia__configuration__line__overlay'),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--security'),
            m('.gaia__configuration__line__title-container__label', 'Additional Sign-In security measures'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Additional Sign-In security measures'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configLockoutEnabledDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(toggleButton, {
              value  : c.configLockoutEnabled,
              onclick: c.modified.bind(null, true),
            }),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--attempts'),
            m('.gaia__configuration__line__title-container__label', 'Maximum number of password attempts'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Maximum number of password attempts'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configMaxAttemptsDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configMaxAttempts,
              modified : c.modified,
              validator: c.validateMaxAttempts,
              minValue : MIN_ATTEMPS,
              maxValue : MAX_ATTEMPS,
            }),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--session'),
            m('.gaia__configuration__line__title-container__label', 'Time frame for entering passwords'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Time frame for entering passwords'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configFrameTimeDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configFrameTime,
              modified : c.modified,
              validator: c.validateMaxFrameTime,
              minValue : MIN_FRAME_TIME,
              maxValue : MAX_FRAME_TIME,
            }),
            m('.gaia__configuration__line__input-suffix', 'Min'),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--penalty'),
            m('.gaia__configuration__line__title-container__label', 'Account temporary lock'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Account temporary lock'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configLockTimeDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configLockTime,
              modified : c.modified,
              validator: c.validateMaxLockTime,
              minValue : MIN_LOCK_TIME,
              maxValue : MAX_LOCK_TIME,
            }),
            m('.gaia__configuration__line__input-suffix', 'Min'),
          ]),
        ]),
      ]),
      m('.gaia__subtitle', 'Application'),
      m('.gaia__body__container', [
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--time'),
            m('.gaia__configuration__line__title-container__label', 'Default Notification Time'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Default notification hour'),
                m('.gaia__configuration__line__title-container__info__text-text', c.notificationTimeConfig().description()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.notificationHour,
              modified : c.modified,
              validator: c.validateNotificationHour,
            }),
            m('.gaia__configuration__line__input-suffix', 'H'),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--work'),
            m('.gaia__configuration__line__title-container__label', 'Additional Info at Sign-up'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Additional Info at Sign-up'),
                m('.gaia__configuration__line__title-container__info__text-text', c.signupOpenInputActiveConfig().description()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(toggleButton, {
              value  : c.signupOpenInputActive,
              onclick: c.modified.bind(null, true),
            }),
            m('.gaia__configuration__line__input-container__sublabel', {
              class: c.signupOpenInputActive() ? '' : 'hide',
            }, ' Label '),
            m('input.gaia__configuration__line__input-container__input', {
              value   : c.signupOpenInputLabel() ? getPolyglotLabel(c.signupOpenInputLabel()).label() : '',
              oninput : m.withAttr('value', c.signupOpenInputLabel()
                ? c.onInputFactory(getPolyglotLabel(c.signupOpenInputLabel()).label)
                : noop),
              onchange: c.modified.bind(null, true),
              class   : c.signupOpenInputActive() ? '' : 'hide',
            }),
            m('.gaia__configuration__line__input-container__sublabel', {
              class: c.signupOpenInputActive() ? '' : 'hide',
            }, ' Mandatory: '),
            m('.gaia__configuration__line__input-container__inline', {
              class: c.signupOpenInputActive() ? '' : 'hide',
            }, [
              m(toggleButton, {
                value  : c.signupOpenInputMandatory,
                onclick: c.modified.bind(null, true),
              }),
            ]),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--offline'),
            m('.gaia__configuration__line__title-container__label', 'Offline days to download'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Number of days to download'),
                m('.gaia__configuration__line__title-container__info__text-text', c.offlineNbDaysDownloaded().description()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.nbDaysOffline,
              modified : c.modified,
              validator: c.validateNbDaysOffline,
            }),
            m('.gaia__configuration__line__input-suffix', 'Day(s)'),
          ]),
        ]),
        m.hide(!isRoot, '.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--key'),
            m('.gaia__configuration__line__title-container__label', '⚠ ' + c.setupCodeConfig().label() + ' ⚠'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', c.setupCodeConfig().label() + ' (SPARTED ONLY)'),
                m('.gaia__configuration__line__title-container__info__text-text', c.setupCodeConfig().description()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(toggleButton, {
              value  : c.setupCodeAvailable,
              onclick: c.modified.bind(null, true),
            }),
          ]),
        ]),

        // EMAIL CUSTOMIZATION
        m('.custom-email__container', [
          m('.custom-email__left-container', [
            m('.custom-email__mail-icon'),
            m('.custom-email__info-text', 'Additional email section'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Additional email section'),
                m('.gaia__configuration__line__title-container__info__text-text', c.customEmailEnabledConfig().description()),
              ]),
            ]),
          ]),
          m('.custom-email__right-container', [

            // ENABLE toggle
            m(toggleButton, {
              class: '.custom-email__toggle',
              value: c.isCustomEmailEnabled,
              onclick: () => c.modified(true),
            }),

            // TITLE
            m.hide(!c.isCustomEmailEnabled(), '.custom-email__form-container', [
              m('input.custom-email__input', {
                value   : c.customEmailTitlePolyglotLabel()
                  ? getPolyglotLabel(c.customEmailTitlePolyglotLabel()).label()
                  : '',
                oninput : m.withAttr('value', c.customEmailTitlePolyglotLabel()
                  ? c.onInputFactory(getPolyglotLabel(c.customEmailTitlePolyglotLabel()).label)
                  : noop),
                maxlength: 50,
                placeholder: 'Type title here',
              }),

              // DESCRIPTION
              m('textarea.custom-email__textarea', {
                value   : c.customEmailDescriptionPolyglotLabel()
                  ? getPolyglotLabel(c.customEmailDescriptionPolyglotLabel()).label()
                  : '',
                oninput : m.withAttr('value', c.customEmailDescriptionPolyglotLabel()
                  ? c.onInputFactory(getPolyglotLabel(c.customEmailDescriptionPolyglotLabel()).label)
                  : noop),
                placeholder: 'Type content here',
                maxlength: 500,
              }),

              // BUTTON LABEL
              m('.custom-email__input-wrapper', [
                m('input.custom-email__input--small', {
                  value   : c.customEmailButtonLabelPolyglotLabel()
                    ? getPolyglotLabel(c.customEmailButtonLabelPolyglotLabel()).label()
                    : '',
                  oninput : m.withAttr('value', c.customEmailButtonLabelPolyglotLabel()
                    ? c.onInputFactory(getPolyglotLabel(c.customEmailButtonLabelPolyglotLabel()).label)
                    : noop),
                  maxlength: 25,
                  placeholder: 'Type button label here',
                }),
                m('.empty-divider', { }),

                // BUTTON LINK
                m('input.custom-email__input--small', {
                  value   : c.customEmailButtonLinkPolyglotLabel()
                    ? getPolyglotLabel(c.customEmailButtonLinkPolyglotLabel()).label()
                    : '',
                  oninput : m.withAttr('value', c.customEmailButtonLinkPolyglotLabel()
                    ? c.onInputFactory(getPolyglotLabel(c.customEmailButtonLinkPolyglotLabel()).label)
                    : noop),
                  type: 'url',
                  placeholder: 'Paste button link here',
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
      m('.gaia__toolbar.gaia__toolbar--bottom', [
        m('.gaia__toolbar--right', [saveButton]),
      ]),
    ]),
  ]);
};

function getPolyglotLabel(polyglot) {
  var label = polyglot.getLabel(App.defaultLanguage().id());

  if (!label) {
    label = new TranslatedLabel({
      polyglotLabelId: polyglot.id(),
      languageId     : App.defaultLanguage().id(),
    });
    polyglot.translatedLabels().push(label);
  }

  return label;
}

export default component;
