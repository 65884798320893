/* eslint-disable complexity */
import m from 'm';
import menu from 'pages/menu';
import analyticsMenu from 'components/analytics.menu';
import reactCreator from 'components/react.creator';

var component = {};

component.view = function view(c) {

  return m('.analytics-page', {
    config: c.onscroll,
  }, [
    m('.analytics-page__menu', [
      m(menu),
      m(analyticsMenu),
    ]),
    m('.analytics-page__body',
      m(reactCreator, {
        component: 'Leaderboard',
        key      : 'analytics-leaderboard',
      })

    ),
  ]);
};

export default component;
