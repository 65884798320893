import type { AnyAction, Dispatch } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { Language } from 'Features/audience/types/language';
import type { FilterItem } from 'Libs/filter/types';

import { fetchAudienceGroups } from '../stores/thunks/audience-group.thunks';
import type { AudienceGroupStatus, FetchAudienceGroupsResponse } from '../stores/thunks/audience-group.thunks';

export type GetAudienceGroupArgs = {
  entityId?: number;
  status: AudienceGroupStatus;
  title: string | null;
  selectedSort: FilterItem;
  limit: number;
  offset: number;
  language?: Language;
  ruleType?: 'segmentation' | 'all' | 'magic-code' | 'email-list';
  includeMetaGroupAll?: boolean;
};

export const getAudienceGroupFactory =
  (dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>) =>
  ({
    entityId,
    limit,
    offset,
    selectedSort,
    status,
    title,
    language,
    ruleType,
    includeMetaGroupAll,
  }: GetAudienceGroupArgs) =>
    dispatch(
      fetchAudienceGroups({
        entityId,
        status,
        title,
        sort: selectedSort,
        limit,
        offset,
        language,
        ruleType,
        includeMetaGroupAll,
      }),
    )
      .then((onfulfilled) => {
        const {
          meta: { requestStatus },
        } = onfulfilled;

        if (requestStatus === 'fulfilled') {
          return onfulfilled.payload as FetchAudienceGroupsResponse;
        }

        throw new Error('impossible state');
      })
      .catch((error) => {
        throw new Error(error);
      });
