import { State } from 'Libs/redux/types';
import { createSelector } from 'reselect';
import { Editor } from './types/state';

export const getEditorsSortedByDescendingEditionDate = (state: State) => {
  const { editors } = state.pages.courses.edition;

  const sortedEditors = [...editors].sort((editorA, editorB) => {
    const editorBEditionDate = new Date(editorB.lastEditedDate || 0);
    const editorAEditionDate = new Date(editorA.lastEditedDate || 0);

    return editorBEditionDate.getTime() - editorAEditionDate.getTime();
  });

  return sortedEditors;
};

export const getLastEditor = createSelector(getEditorsSortedByDescendingEditionDate, (editors: Editor[]) => editors[0]);
