import { createStyleSheet } from 'Components/foundation/stylesheets';
import { CORNERS } from 'Components/foundation';

export const styles = createStyleSheet({
  clickableZone: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: CORNERS.CORNER2,
  },
  container: {
    position: 'relative',
  },
});
