import App from 'Models/App';
import Enum from 'Models/Enum';

import type { Source } from 'Libs/ts/types';
import type { FilterItem } from 'Libs/filter/types';

type SegmentationDimension = {
  id: number,
  color: string;
};

type SegmentationGroup = {
  id: number,
  dimension: SegmentationDimension,
  label: string,
};

type SegmentationItem = {
  id: number,
  group: SegmentationGroup,
};

type GetSegmentationSourceParams = { showLanguages?: boolean };

export const getSegmentationSource = (params?: GetSegmentationSourceParams): Source => {
  const segmentationItems: SegmentationItem[] = (App as any).segmentationItems?.().toJSON() || [];
  const segmentationGroups: SegmentationGroup[] = segmentationItems.map((si) => si.group);

  const segmentations = params?.showLanguages
    ? segmentationGroups
    : segmentationGroups.filter(({ dimension: { id } }) => id !== Enum.dimensionTypes.LANGUAGE);

  // Remove duplicates and format to FilterItem[]
  const items: FilterItem[] = segmentations
    .filter(({ id }, index, segs) => segs.findIndex((seg) => id === seg.id) === index)
    .sort((a, b) => a.dimension.id - b.dimension.id)
    .map((group) => ({
      category: 'segment',
      id: group.id,
      value: group.label,
      selected: false,
      color: group.dimension.color,
      dimensionId: group.dimension.id,
    }));

  return {
    name: 'Segmentation',
    filters: [{
      category: 'segment',
      type: 'multiselect',
      items,
    }],
  };
};
