// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  preview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 430,
    backgroundColor: COLORS.GREY_DARKER.DEFAULT,
    overflow: 'hidden',
    userSelect: 'none',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderText: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.WHITE.DEFAULT,
    marginLeft: 20,
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  errorText: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.WHITE.DEFAULT,
    marginBottom: 16,
  },
  previewNavigation: {
    position: 'absolute',
    bottom: 108,
  },
};
