import T from 'types';
import Enum from 'models/Enum';
import User from 'services/userService';

const defaultRouteForRole = {
  [Enum.Role.UNAUTHENTICATED]: '/login',
  [Enum.Role.ROOT]: '/home',
  [Enum.Role.MASTER]: '/home',
  [Enum.Role.ADMIN]: '/home',
  [Enum.Role.WRITER]: '/knowledge',
  [Enum.Role.TRANSLATOR]: '/knowledge',
  [Enum.Role.ANALYST]: '/analytics/leaderboards',
};

const unauthenticatedRoutes = [
  '/login',
  '/reset_password',
  '/update_password',
  '/activate',
  '/emailVerification',
  '/passwordReset',
  '/two-factor',
];

const modes = {
  hash    : '#',
  search  : '?',
  pathname: '',
};

const modeSetters = {
  hash    : 'hash',
  search  : 'search',
  pathname: 'href',
};

export default class Router {
  constructor(m, routeConfig) {
    this.m = m;
    this.routeConfig = routeConfig;
    this.originalRouteFunction = m.route;

    this.m.route.mode = 'pathname';

    this.overrideMithrilRoute();
  }

  overrideMithrilRoute() {
    this.m.route = this.authenticatedRoute.bind(this);
    this.m.route.param = this.originalRouteFunction.param;
    this.m.route.buildQueryString = this.originalRouteFunction.buildQueryString;
    this.m.route.parseQueryString = this.originalRouteFunction.parseQueryString;

    Object.defineProperty(this.m.route, 'mode', {
      get: () => this.originalRouteFunction.mode,
      set: (value) => {
        this.originalRouteFunction.mode = value;
      },
    });
  }

  /**
    The original m.route function can be called in 4 different ways:
      1. m.route()
        Get the current route
      2. m.route(route, ?params, ?shouldReplaceHistoryEntry)
        Navigate to the asked route
      3. m.route(element, defaultRoute, routes)
        Set a router with routes in element
      4. { config: m.route }
        Event handler for <a></a>
    We overload the 2. and 3. signatures to handle user access rights
   */
  authenticatedRoute(...args) {
    // m.route()
    if (!args.length)
      return this.currentRoute();

    // m.route(route, params, shouldReplaceHistoryEntry)
    if (T.is.str(args[0]))
      return this.navigate(...args);

    // m.route(element, defaultRoute, routes)
    if (args.length === 3 && T.is.str(args[1]))
      return this.applyRouteConfig(...args);

    // config: m.route
    return this.originalRouteFunction.apply(this.m, args);
  }

  applyRouteConfig(rootDom, askedDefaultRoute, config) {
    return this.originalRouteFunction.call(this.m, rootDom, this.resolveRouteForUser(askedDefaultRoute), config);
  }

  navigate(askedRoute, params, shouldReplaceHistoryEntry) {
    const route = this.resolveRouteForUser(askedRoute);

    if (window.Appcues && this.currentRoute() !== route) {
      console.log('=========== Calling Appcues page');
      window.Appcues.page();
    }

    return this.originalRouteFunction.call(this.m, route, params, shouldReplaceHistoryEntry);
  }

  currentRoute() {
    return this.originalRouteFunction.call(this.m);
  }

  resolveRouteForUser(route) {
    if (this.routeIsAuthorizedForUser(route))
      return route;

    return this.getDefaultRouteForUser();
  }

  routeIsAuthorizedForUser(route) {
    return User.meOrUnauthenticated().roles().some((role) => {
      return this.routeIsAuthorizedForRole(route, role.id());
    });
  }

  routeIsAuthorizedForRole(route, roleId) {
    if (roleId === Enum.Role.ROOT)
      return true;

    const refRoute = this.getRefRoute(route);

    if (refRoute)
      return this.routeConfig[refRoute].authorizedRoles.includes(roleId);

    return false;
  }

  getRefRoute(route) {
    const [baseRoute] = route.split('?');

    if (this.routeConfig[baseRoute])
      return baseRoute;

    const matchingRoute = Object.keys(this.routeConfig).find((refRoute) => {
      // From mithril.js:1717
      const matcher = new RegExp(`^${refRoute.replace(/:[^\/]+?\.{3}/gu, '(.*?)').replace(/:[^\/]+/g, '([^\\/]+)')}\/?$`);

      return matcher.test(route);
    });

    return matchingRoute || '';
  }

  getDefaultRouteForUser() {
    const [firstRoleId] = User.meOrUnauthenticated().roles().map((role) => role.id()).sort();

    return defaultRouteForRole[firstRoleId] || '/knowledge';
  }

  // eslint-disable-next-line complexity
  unauthenticatedRouter() {
    const mode = modes[this.m.route.mode];
    const modeSetter = modeSetters[this.m.route.mode];
    const path = `${location[this.m.route.mode].slice(mode.length)}${location.search}`;

    if (!unauthenticatedRoutes.find((route) => path.includes(route)))
      location[modeSetter] = `${mode}/login?redirect=${encodeURIComponent(path)}`;

    this.applyRouteConfig(document.querySelector('#m_app'), '/login', this.buildRouteConfigForRoles([Enum.Role.UNAUTHENTICATED]));
  }

  authenticatedRouter() {
    const roleIds = User.meOrUnauthenticated().roles().map((role) => role.id());

    this.applyRouteConfig(document.querySelector('#m_app'), this.getDefaultRouteForUser(roleIds), this.buildRouteConfigForRoles(roleIds));

    if (this.currentRoute().startsWith('/login')) {
      const redirect = decodeURIComponent(this.m.route.param('redirect'));

      let url = '';

      let queryString = '';

      let params = {};

      if (redirect) {
        [, url, queryString] = redirect.match(/([^?]*)(?:\?(.*))?/u);

        if (queryString) {
          params = queryString.split('&').reduce((obj, param) => {
            const [key, value] = param.split('=');

            obj[key] = value;

            return obj;
          }, {});
        }
      }

      this.navigate(url, params);
    }
  }

  buildRouteConfigForRoles(roleIds) {
    return Object.entries(this.routeConfig)
      .reduce((routeConfig, [route, config]) => {
        roleIds.forEach((roleId) => {
          if (roleId === Enum.Role.ROOT || config.authorizedRoles.includes(roleId))
            routeConfig[route] = config.component;
        });

        return routeConfig;
      }, {});
  }

  buildMainRouteOptionForUser(routeOptions) {
    const roleIds = User.meOrUnauthenticated().roles().map((role) => role.id());

    return Object.entries(routeOptions)
      .reduce((newRouteOptions, [route, option]) => {
        roleIds.forEach((roleId) => {
          newRouteOptions[route] = option;

          if (this.routeIsAuthorizedForRole(route, roleId)) {
            newRouteOptions[route].isGranted = true;

            if (T.is.obj(option) && option.children)
              newRouteOptions[route].children = this.buildRouteOptionForUser(option.children);
          } else
            newRouteOptions[route].isGranted = false;
        });

        return newRouteOptions;
      }, {});
  }

  buildRouteOptionForUser(routeOptions) {
    const roleIds = User.meOrUnauthenticated().roles().map((role) => role.id());

    return Object.entries(routeOptions)
      .reduce((newRouteOptions, [route, option]) => {
        roleIds.forEach((roleId) => {
          if (this.routeIsAuthorizedForRole(route, roleId)) {
            newRouteOptions[route] = option;

            if (T.is.obj(option) && option.children)
              newRouteOptions[route].children = this.buildRouteOptionForUser(option.children);
          }
        });

        return newRouteOptions;
      }, {});
  }

  getMainRoute(routeOptions, position = 1) {
    const currentRoute = this.currentRoute();

    return Object.keys(routeOptions).find((route) => currentRoute.startsWith(route.split('/').slice(0, position + 1).join('/')));
  }
}
