/* eslint-disable complexity */
import m from 'm';
import { t } from 'i18next';
import moment from 'moment';
import Promise from 'bluebird';

import Model from 'models/Model';
import Enum from 'models/Enum';
import popup from 'services/popupService';
import userService from 'services/userService';
import menu from 'pages/menu';
import qcmEdition from 'components/qcm.edition';
import sqcmEdition from 'components/sqcm.edition';
import errorTextEdition from 'components/error.text.edition';
import openQuestionEdition from 'components/open.question.edition';
import noInteractionContentEdition from 'components/no.interaction.content.edition';
import swipingCardsEdition from 'components/swiping.cards.edition';
import pictureSpotEdition from 'components/picture.spot.edition';
import reactCreator from 'components/react.creator';
import Collection from 'models/Collection';

var component = {};
var Content = Model('Content');
var Knowledge = Model('Knowledge');
var Player = Model('Player');

const { FRENCH, ENGLISH } = Enum.AvailableLanguages;
const AI_SUPPORTED_LANGUAGE = [FRENCH, ENGLISH];

component.controller = function controller() {
  var self = this;

  self.aiEnabled = m.prop(false);
  self.knowledgeId = m.prop(m.route.param('knowledgeId'));
  self.content = m.prop(new Content());
  self.originalContent = m.prop();
  self.knowledge = m.prop(new Knowledge());
  self.contentIds = m.prop();
  self.contentIndex = m.prop();
  self.currentUser = m.prop(new Player());

  // content status and access
  self.canEditContentRole = m.prop(false);
  self.canEditValidContentRole = m.prop(false);
  self.isDraft = m.prop(false);
  self.isToValidate = m.prop(false);
  self.isValid = m.prop(false);
  self.loading = m.prop(true);
  self.isSaving = m.prop(false);

  // content preview information
  self.contentPreviewUrl = m.prop('');
  self.showContentPreview = m.prop(false);
  self.contentPreviewEnabled = m.prop(true);
  self.contentPreviewTitle = m.prop('');
  self.contentPreviewSubTitle = m.prop('');

  // toolbar props
  self.toolbarStatus = m.prop('disabled');
  self.toolbarStatusWording = m.prop(t('gameplays:action_bar.not_saved'));
  self.toolbarTooltip = m.prop(undefined);
  self.validationWording = m.prop('');
  self.validationCallback = m.prop(null);
  self.knowledgeTitle = m.prop('');
  self.actionsConfig = m.prop([{
    type   : 'save',
    enabled: true,
  }, {
    type   : 'preview',
    enabled: true,
  }, {
    type   : 'validate',
    enabled: false,
  }]);
  self.toolbarActions = m.prop([]);
  self.validateEnabled = m.prop(false);
  self.saveEnabled = m.prop(false);
  self.isTranslated = m.prop(false);
  self.backWording = m.prop(t('gameplays:action_bar.back'));
  self.lastSavedDate = m.prop('');
  self.toolbarId = m.prop('');

  self.goBack = function goBack() {
    if (!self.isContentEdited())
      go();
    else {
      popup.open({
        type    : 'leaveEditionConfirmation',
        save    : saveThenGo,
        dontSave: go,
        title   : t('gameplays:action_bar.changes_not_saved'),
        message : t('gameplays:action_bar.changes_not_saved_explanation'),
        cancelWording: t('gameplays:action_bar.leave_without_saving'),
      });
    }

    function go() {
      popup.close();
      m.route('/knowledge/' + self.knowledgeId());
    }

    function saveThenGo() {
      self.save()
        .then(self.handleSaveEnd)
        .then(go);
    }
  };

  self.save = function save() {
    if (self.isSaving())
      return Promise.resolve(null);

    self.isSaving(true);

    if (self.content().gameplayId() === Enum.gameplay.QCM) {
      self.content().QCM().qcmChoices().items.map(function cutString(item) {
        const limit = item.gameItem().getSizeLimitation();

        item.gameItem().data(item.gameItem().data().substr(0, limit));
      });
    }

    if (self.content().gameplayId() === Enum.gameplay.SWIPING_CARDS) {
      var cleanedOptions = self.content()['Swiping Cards']().options().filter(function removeEmptyOption(option) {
        return option.data();
      });

      self.content()['Swiping Cards']().options(new Collection(cleanedOptions));

      var options = self.content()['Swiping Cards']().options();
      var lastOption = options.at(options.length - 1);

      if (options.length > 2 && !lastOption.data().length)
        options.items.pop();
    }

    if (!self.knowledge().explanationText().length && self.content().gameplayId() !== Enum.gameplay.NO_INTERACTION_CONTENT) {
      self.knowledge().explanationText(self.content().game().explanation().data());
      self.knowledge().save();
    }

    return self.content()
      .saveFull();
  };

  self.handleSaveEnd = function handleSaveEnd(content) {
    const isCreation = !self.content().id();

    self.content(content);
    self.isSaving(false);
    self.originalContent(self.content().toJSON());

    updateStatus();
    updateAccess();

    self.setData({ content, knowledge: self.knowledge() });

    if (isCreation) {
      const contentIdPosition = 4;
      const pathElems = window.location.pathname.split('/').filter((_, i) => i <= contentIdPosition);

      pathElems[contentIdPosition] = self.content().id();

      window.history.replaceState({}, null, pathElems.join('/'));
    }

    m.redraw();
  };

  self.displaySaveWarningIfNedeed = function displaySaveWarningIfNedeed(lr) {
    if (!self.isContentEdited())
      return self.switchGameplay(lr);

    return popup.open({
      type    : 'leaveEditionConfirmation',
      save    : self.saveAndSwitch.bind(null, true, lr),
      dontSave: self.saveAndSwitch.bind(null, false, lr),
      title   : t('gameplays:action_bar.changes_not_saved'),
      message : t('gameplays:action_bar.changes_not_saved_explanation'),
      cancelWording: t('gameplays:action_bar.leave_without_saving'),
    });
  };

  self.saveAndSwitch = function saveAndSwitch(needToSave, lr) {
    if (needToSave) {
      self.save()
        .then(self.handleSaveEnd);
    }

    popup.close();
    self.switchGameplay(lr);
  };

  self.switchGameplay = function switchGameplay(delta) {
    var newIndex, newContentId;

    if (self.contentIndex() === null)
      return;

    newIndex = (self.contentIndex() + delta) % self.contentIds().length;

    if (newIndex < 0)
      newIndex = self.contentIds().length - 1;

    newContentId = self.contentIds()[newIndex];

    m.route('/knowledge/' + self.knowledgeId() + '/content/' + newContentId);
  };

  self.archiveContentButton = function archiveContentButton() {
    return popup.open({
      type   : 'confirm',
      action : archiveContent,
      confirm: t('gameplays:action_bar.delete_content'),
      title  : t('gameplays:action_bar.delete_content'),
      content: t('gameplays:action_bar.delete_content_explanation'),
    });
  };

  self.isContentEdited = function isContentEdited() {
    var original = self.originalContent();

    if (!original)
      return false;

    return !self.content().isEqualTo(original)
      || !self.content().id();
  };

  self.changeStatus = function changeStatus(newStatus) {
    try {
      self.content().game().validate();
    } catch (e) {
      console.error('e', e.message);

      popup.open({
        type    : 'info',
        title   : t('gameplays:action_bar.content_not_valid'),
        subtitle: t('gameplays:action_bar.content_not_valid_explanation'),
        content : e.message,
      });

      return Promise.resolve();
    }

    if (newStatus === Enum.contentStatus.DRAFT) {
      return popup.open({
        type   : 'confirm',
        title  : t('gameplays:action_bar.unvalidate_content'),
        content: t('gameplays:action_bar.unvalidate_content_explanation'),
        confirm: t('gameplays:action_bar.unvalidate_content'),
        action : effectivelyChangeStatus,
      });
    }

    return Promise.resolve(effectivelyChangeStatus());

    function effectivelyChangeStatus() {
      self.content()
        .changeStatus(newStatus)
        .tap(updateContent)
        .tap(updateStatus)
        .tap(updateAccess)
        .then(() => m.redraw());
    }

    function updateContent(content) {
      self.content(content);
      self.originalContent().statusId = content.statusId();
      self.originalContent().status = content.status().toJSON();
    }
  };

  self.setData = function setData(data) {
    const { content, knowledge } = data;
    const {
      knowledgeTitle,
      customId,
      id: newKnowledgeId,
      segmentation,
    } = knowledge;
    const { previewUrl } = content;

    const languageSupported = segmentation().filter((s) => AI_SUPPORTED_LANGUAGE.includes(s.availableGroupId()));

    self.content(content);
    self.knowledge(knowledge);
    self.aiEnabled(languageSupported.length > 0);
    self.knowledgeId(newKnowledgeId());
    self.toolbarId(customId() || newKnowledgeId() || '');
    self.knowledgeTitle(knowledgeTitle().data());
    self.contentPreviewUrl(previewUrl());
    self.contentPreviewTitle(Enum.gameplayName[content.gameplayId()]);
    self.contentPreviewSubTitle(knowledgeTitle().data());
  };

  function startLoading() {
    self.loading(true);
    m.redraw();

    return Promise.resolve();
  }

  function stopLoading() {
    self.loading(false);
    m.redraw();

    return Promise.resolve();
  }

  function checkExistingGameplay() {
    var id = m.route.param('id');

    if (id !== 'new')
      return Promise.resolve();

    var gameplayId = parseInt(m.route.param('gameplayId'));

    self.knowledge().contents().items.map(function checkGameplay(item) {
      if (item.gameplayId() === gameplayId)
        m.route('/knowledge/' + self.knowledgeId() + '/content/' + item.id());
    });

    return Promise.resolve();
  }

  function loadContent() {
    var id = m.route.param('id');
    var knowledgeId = m.route.param('knowledgeId');

    startLoading();

    return Promise.props({
      content: id !== 'new'
        ? Content.getFullById(id)
        : Promise.resolve(self.content()),
      knowledge: knowledgeId !== 'new'
        ? Knowledge.get(knowledgeId)
        : Promise.resolve(self.knowledge()),
    })
      .then(self.setData)
      .then(setIds)
      .tap(updateStatus)
      .then(stopLoading);

    function setIds() {
      var contentIds = getContentIds();

      if (id !== 'new') {
        self.contentIds(contentIds);
        self.contentIndex(contentIds.indexOf(parseInt(id)));
      } else {
        var gameplayId = parseInt(m.route.param('gameplayId'));
        var newContent = Content.createGameplay(gameplayId, self.knowledge().id());

        newContent.gameplayId(gameplayId);
        newContent.knowledgeId(self.knowledge().id());
        self.content(newContent);
      }

      setOriginalContent();
    }

    function getContentIds() {
      return self.knowledge().contents().map(function returnId(content) {
        return content.id();
      });
    }
  }

  function setOriginalContent() {
    self.originalContent(self.content().toJSON());
  }

  function archiveContent() {
    self.content().archived(true);

    return self.save()
      .then(self.handleSaveEnd)
      .then(self.goBack);
  }

  function updateAccess() {
    const { WRITER, ADMIN, ROOT, MASTER, TRANSLATOR } = Enum.Role;
    const { VALIDATE, SAVE } = Enum.ContentActions;

    const isInTranslation = self.knowledge().pendingTranslation();
    const actionsConfig = self.actionsConfig();
    const user = self.currentUser();

    const userCanEdit = user.hasOneRole([WRITER, ADMIN, MASTER, ROOT]);
    const userIsTranslator = user.hasOneRole([TRANSLATOR]);
    const canEditValidContentRole = user.hasOneRole([ADMIN, MASTER, ROOT]);

    let canEditContentRole = self.isValid() ? canEditValidContentRole : userCanEdit;

    let canAskValidation = self.isDraft();

    const canValidate = (self.isDraft() || self.isToValidate()) && canEditValidContentRole;

    // avoid to valid content in a non translated content
    if (userIsTranslator && !isInTranslation)
      canAskValidation = false;

    // change save access
    actionsConfig[SAVE].enabled = canEditContentRole;

    // change validation access
    actionsConfig[VALIDATE].enabled = (canValidate || canAskValidation) && !isInTranslation;

    self.canEditContentRole(canEditContentRole);
    self.canEditValidContentRole(canEditValidContentRole);
    self.actionsConfig(actionsConfig);
  }

  function updateStatus() {
    const status = self.content().statusId();
    const statusObject = getStatus(status);

    self.toolbarStatus(statusObject.status);
    self.toolbarStatusWording(statusObject.statusWording);
    self.toolbarTooltip(statusObject.tooltip);

    self.isDraft(status === Enum.contentStatus.DRAFT);
    self.isToValidate(status === Enum.contentStatus.TO_VALIDATE);
    self.isValid(status === Enum.contentStatus.VALIDATED);

    if (!self.content().id())
      self.contentPreviewEnabled(false);
    else
      self.contentPreviewEnabled(!self.isDraft());
  }

  function loadMe() {
    return userService.me()
      .then(self.currentUser)
      .then(updateAccess);
  }

  function getStatus(statusId) {
    const statusObject = {
      status       : 'disabled',
      statusWording: t('gameplays:action_bar.not_saved'),
      tooltip      : undefined,
    };

    switch (statusId) {
      case Enum.contentStatus.DRAFT:
        statusObject.status = 'disabled';
        statusObject.statusWording = t('gameplays:action_bar.draft');
        break;
      case Enum.contentStatus.TO_VALIDATE:
        statusObject.status = 'warning';
        statusObject.statusWording = t('gameplays:action_bar.waiting_validation');
        break;
      case Enum.contentStatus.VALIDATED:
        statusObject.status = 'active';
        statusObject.statusWording = t('gameplays:action_bar.validated');
        break;
      default:
        break;
    }

    return statusObject;
  }

  startLoading()
    .then(loadContent)
    .then(checkExistingGameplay)
    .then(loadMe)
    .then(stopLoading);
};

component.view = function view(c) {
  var pendingTranslation = c.knowledge().pendingTranslation();
  var toolbarActions = [];

  // if editing is possible and not in translation
  if (c.actionsConfig()[0].enabled && !pendingTranslation) {
    toolbarActions.push({
      title   : t('gameplays:action_bar.delete_content'),
      callback: c.archiveContentButton,
    });
  }

  if (c.isValid() && c.canEditValidContentRole()) {
    toolbarActions.push({
      title   : t('gameplays:action_bar.unvalidate'),
      callback: c.changeStatus.bind(null, Enum.contentStatus.DRAFT),
    });
  }

  c.toolbarActions(toolbarActions);

  if (c.content().gameplayId() === Enum.gameplay.ORDERING_CARDS) {
    return m('.content-page', [
      m('.content-page__header', [
        m(menu),
      ]),
      m(reactCreator, {
        component: 'Content',
        props    : {
          id          : c.content().id(),
          gameplayType: c.content().gameplayId(),
          knowledgeId : c.knowledge().id(),
        },
        style: {
          height: '100%',

          // Offset because of the menu height
          'padding-top': '55px',
        },
      }),
    ]);
  }

  const canAskForValidation = c.isDraft();
  const canValidate = (c.isDraft() || c.isToValidate()) && c.canEditValidContentRole();

  if (canValidate) {
    c.validationWording(t('gameplays:action_bar.validate'));
    c.validationCallback(c.changeStatus.bind(null, Enum.contentStatus.VALIDATED));
  } else if (canAskForValidation) {
    c.validationWording(t('gameplays:action_bar.ask_validation'));
    c.validationCallback(c.changeStatus.bind(null, Enum.contentStatus.TO_VALIDATE));
  }

  const isContentEdited = c.isContentEdited();

  // set value via prop because of xhr request
  c.validateEnabled(!isContentEdited);
  c.saveEnabled(isContentEdited);
  c.backWording(pendingTranslation ? t('gameplays:action_bar.back_to_knowledge') :  t('gameplays:action_bar.back'));
  c.isTranslated(pendingTranslation);
  c.lastSavedDate(getLastSavedDate(c.content(), c.canEditContentRole()));

  return m('.content-page', [
    m(reactCreator, {
      component: 'MGameplayPreview',
      props    : {
        visible     : c.showContentPreview,
        title       : c.contentPreviewTitle,
        subtitle    : c.contentPreviewSubTitle,
        url         : c.contentPreviewUrl,
        qrCodeLabel : t('gameplays:action_bar.scan_preview'),
        onCloseModal: c.showContentPreview.bind(null, false),
      },
    }),
    m('.content-page__header', [
      m(menu),
    ]),
    m(reactCreator, {
      component: 'SContentActionBar',
      key      : 'content',
      props    : {
        id               : c.toolbarId,
        title            : c.knowledgeTitle,
        backWording      : c.backWording,
        onBack           : c.goBack,
        onSave           : c.save,
        onSaveEnd        : c.handleSaveEnd,
        canPreview       : c.contentPreviewEnabled,
        canSave          : c.saveEnabled,
        canValidate      : c.validateEnabled,
        validationWording: c.validationWording,
        onValidate       : c.validationCallback,
        onPreview        : c.showContentPreview.bind(null, true),
        options          : c.toolbarActions,
        status           : c.toolbarStatus,
        statusWording    : c.toolbarStatusWording,
        helper           : c.toolbarTooltip,
        isTranslated     : c.isTranslated,
        previewWording   : t('gameplays:action_bar.preview'),
        actionsConfig    : c.actionsConfig,
        lastSaved        : c.lastSavedDate,
      },
      style: {
        'padding-top': '55px',
      },
    }),
    c.loading() ? makePlaceholder() : makeContent(c),
  ]);
};

function getLastSavedDate(content, canEdit) {
  const updatedAt = content.updatedAt();
  const id = content.id();

  if (!id || !updatedAt || !canEdit)
    return '';

  return `${moment(updatedAt).calendar(null, {
    sameDay : `[${t('gameplays:action_bar.last_saved_at')}] LT`,
    nextDay : '',
    nextWeek: '',
    lastDay : `[${t('gameplays:action_bar.saved_yesterday')}] LT`,
    lastWeek: `[${t('gameplays:action_bar.saved_last')}] dddd`,
    sameElse: `[${t('gameplays:action_bar.last_saved')}] DD/MM/YYYY`,
  })}`;
}

function getGameplayComponent(c) {
  var comp;

  switch (c.content().gameplayId()) {
    case Enum.gameplay.QCM:
      comp = qcmEdition;
      break;
    case Enum.gameplay.SQCM:
      comp = sqcmEdition;
      break;
    case Enum.gameplay.ERROR_TEXT:
      comp = errorTextEdition;
      break;
    case Enum.gameplay.OPEN_QUESTION:
      comp = openQuestionEdition;
      break;
    case Enum.gameplay.NO_INTERACTION_CONTENT:
      comp = noInteractionContentEdition;
      break;
    case Enum.gameplay.PICTURE_SPOT:
      comp = pictureSpotEdition;
      break;
    case Enum.gameplay.SWIPING_CARDS:
      comp = swipingCardsEdition;
      break;
    default:
      comp = '';
      break;
  }

  return comp;
}

function makeContent(c) {
  var gameplayComponent = getGameplayComponent(c);

  return m('.content-page__body', [
    m('.content-page__navigation', {
      class: [
        'content-page__navigation--left',
        c.content().id() ? '' : 'hide',
      ].join(' '),
      onclick: c.displaySaveWarningIfNedeed.bind(null, -1),
    }),
    m('.content-page__edition', [
      m('.content-page__edition__container', m(gameplayComponent, {
        content  : c.content,
        knowledge: c.knowledge,
        title    : c.contentIds() ?  t('gameplays:action_bar.content_title', {index1: c.contentIndex() + 1 , index2: c.contentIds().length}): t('gameplays:action_bar.content_title_new'),
        editable : c.canEditContentRole,
        status   : c.content().statusId,
        aiEnabled: c.aiEnabled,
      })),
    ]),
    m('.content-page__navigation', {
      class: [
        'content-page__navigation--right',
        c.content().id() ? '' : 'hide',
      ].join(' '),
      onclick: c.displaySaveWarningIfNedeed.bind(null, 1),
    }),
  ]);
}

function makePlaceholder() {
  return m('.content-page__placeholder', [
    m(reactCreator, {
      component: 'SPageLoaderWithTimer',
      props    : {
        isLoading: true,
        style    : { zIndex: 9 },
      },
    }),
  ]);
}

export default component;
