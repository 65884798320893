// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
  },
  icon: {
    margin: '3px 20px 0 0',
  },
  title: {
    ...TYPOGRAPHY.H2,
  },
  description: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.BLUE_GRAY_DARKER.DEFAULT,
    marginTop: '2px',
  },
  wrapperText: {
    display: 'flex',
    flexDirection: 'column',
  },
};
