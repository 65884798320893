import React from 'react';

import SActionInfoBoxPage from 'Components/structural/SActionInfoBoxPage/SActionInfoBoxPage';
import UImage from 'Components/unit/UImage/UImage';

import styles from './TokenExpiredInfoBox.styles';

export const TokenExpiredInfoBox = () => (
  <SActionInfoBoxPage>
    <UImage
      src="https://sparted-prod-common.s3-eu-west-1.amazonaws.com/email-link-expired.svg"
      alt="email invitation logo"
    />
    <h1 style={styles.title}>Your invitation link expired</h1>
    <div style={styles.descriptionWrapper}>
      <span style={styles.description}>An invitation link expires after 24h.</span>
      <span style={styles.description}>Contact an admin to receive a new invitation.</span>
    </div>
  </SActionInfoBoxPage>
);

export default TokenExpiredInfoBox;
