import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';
import App from 'models/App';
import Enum from 'models/Enum';

var SegmentationDimension = {
  endpoint  : 'api/SegmentationDimensions',
  attributes: {
    label: {
      type: 'string',
    },
    color: {
      type: 'string',
    },
    icon: {
      type: 'string',
    },
    order: {
      type: 'number',
    },
    parentId: {
      type: 'number',
    },
    groups: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    children: {
      type : 'collection',
      Model: 'SegmentationDimension',
    },
    type: {
      type : 'pointer',
      Model: 'SegmentationType',
    },
    typeId: {
      type: 'number',
    },
    polyglotLabel: {
      type : 'pointer',
      Model: 'PolyglotLabel',
    },
    polyglotLabelId: {
      type: 'number',
    },
    _parent: {
      type: 'object',
    },
    editableByPlayer: {
      type   : 'boolean',
      default: 'true',
    },
  },
  methods: {
    destroy: function destroy() {
      return request.destroy('api/SegmentationDimensions/full/' + this.id());
    },
    save: function save(_SegmentationDimension) {
      var promise;

      if (!this.id())
        promise = request.post('api/SegmentationDimensions/full/', this.toJSON());
      else
        promise = request.put('api/SegmentationDimensions/full/' + this.id(), this.toJSON());

      return promise.then(function returnEntity(res) {
        return new _SegmentationDimension(res);
      });
    },
    getLabel: function getLabel(_SegmentationDimension, languageId) {
      if (!languageId)
        languageId = App.defaultLanguage().id();

      return this.polyglotLabel().getLabel(languageId);
    },
  },

  classMethods: {
    getDimensionsNested: function getDimensionsNested(_SegmentationDimension) {
      return _SegmentationDimension
        .find({
          filter: {
            where: {
              typeId: { neq: Enum.dimensionTypes.HIDDEN_TAG },
            },
            include: [{
              relation: 'groups',
              scope   : {
                include: 'members',
              },
            },
            {
              relation: 'type',
              scope   : {
                include: 'behaviors',
              },
            },
            {
              relation: 'polyglotLabel',
              scope   : {
                include: 'translatedLabels',
              },
            },
            ],
            order: 'order ASC',
          },
        })
        .then(_SegmentationDimension.nestCollection);
    },
    getFull: function getFull(_SegmentationDimension) {
      return _SegmentationDimension
        .find({
          filter: {
            where: {
              typeId: { neq: Enum.dimensionTypes.HIDDEN_TAG },
            },
            include: [{
              relation: 'groups',
              scope   : {
                include: 'members',
              },
            },
            {
              relation: 'polyglotLabel',
              scope   : {
                include: 'translatedLabels',
              },
            },
            ],
            order: 'order ASC',
          },
        });
    },
    nestCollection: function nestCollection(_SegmentationDimension, col) {
      var jsonCollection = col.toJSON();
      var parents = col.filter(function isParent(dim) {
        return !dim.parentId();
      });

      parents.forEach(giveChildrenToDim);

      return new Collection(_SegmentationDimension, parents);


      function giveChildrenToDim(par) {
        par.children(new Collection(_SegmentationDimension, jsonCollection.filter(function getChildren(dim) {
          return dim.parentId === par.id();
        })));
        par.children().forEach(giveChildrenToDim);
      }
    },
  },
};

export default Model.register('SegmentationDimension', SegmentationDimension);
