import { mergeDeep } from 'Libs/mergeDeep';

export type CategoryType = Readonly<{
  id: number,
  label: string,
  locked: boolean,
}>;

type CategoryToSend = Readonly<{
  id?: number,
  label: string,
  locked: boolean,
}>;

export default function createCategory(values?: any): CategoryType {
  const defaultState: CategoryType = {
    id: 0,
    label: '',
    locked: false,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep<CategoryType>(defaultState, values);
}

export function prepareCategory(category: CategoryType): CategoryToSend {
  const { id, label, ...rest } = category;

  const labelTrimmed = label.trim();

  // We need to split the id from the creation payload to avoid to create a category with an id 0
  if (id === 0) {
    return { ...rest, label: labelTrimmed };
  }

  return { ...category, label: labelTrimmed };
}
