import m from 'm';
import Model from 'models/Model';

var component = {};

/*
 * Args:
 * model       <String>
 * selection   <Array>
 * displayPath <String>
 * filter      <object>
 */
component.controller = function controller(args) {
  var self = this;
  var realModel = Model(args.model);

  self.searchInput = m.prop('');
  self.options = m.prop([]);
  self.focused = m.prop(false);
  self.filteredOptions = m.prop([]);

  // self.findEntities = function findEntities(_args, searchValue) {
  //   if (searchValue !== undefined)
  //     self.searchInput(searchValue);
  //   if (!_args.filter)
  //     _args.filter = {where: { and: []}};

  //   _args.filter.and = _args.filter.and || [];

  //   if (self.searchInput()) {
  //     var obj = {};

  //     obj[_args.displayPath] = {regexp: '/' + self.searchInput() + '/i'};
  //     _args.filter.where.and.push(obj);
  //   }

  //   realModel.findForInput(_args.filter)
  //     .then(self.options)
  //     .then(m.redraw.bind(null, null));
  // };
  // self.findEntities(args);

  self.filterOptions = function filterOptions(_args, value) {
    if (value !== undefined)
      self.searchInput(value);

    self.filteredOptions(
      self.searchInput()
      ? _args.entities().filter(function matchInput(option) {
        return option[_args.displayPath]().toLowerCase().indexOf(self.searchInput().toLowerCase()) !== -1;
      })
      : _args.entities()
    );
  };

  self.filterOptions(args);

  self.configInput = function configInput(elem, isInit) {
    if (isInit)
      return;
    document.addEventListener('mouseup', mouseup);
  };

  self.onunload = function onunload() {
    document.removeEventListener('mouseup', mouseup);
  };

  self.addItem = function addItem(_args, entity) {
    if (args.maxSelection && _args.selection().length >= args.maxSelection())
      return;

    _args.selection().push(entity);

    if (_args.onselect)
      _args.onselect();
  };

  self.isSelected = function isSelected(_args, id) {
    return _args.selection().filter(function findId(entity) {
      return entity.id() === id;
    }).length;
  };

  self.removeItem = function removeItem(_args, index) {
    _args.selection().splice(index, 1);
    if (_args.onselect)
      _args.onselect();
  };

  self.focus = function focus() {
    self.focused(true);

    var selector = '#entity-input__search-input';
    var input = document.querySelector(selector);

    input.focus();
  };

  function mouseup(e) {
    var selector = '#entity-input';
    var container = document.querySelector(selector);

    if (!container.contains(e.target)) {
      self.focused(false);
      m.redraw();
    }
  }
};

component.view = function view(c, args) {

  c.filterOptions(args);

  if (!c.filteredOptions().length)
    c.filterOptions(args);

  return m('#entity-input.entity-input', {
    onclick: c.focus,
    config : c.configInput,
    class  : args.maxSelection ? 'entity-input--max_selection' : '',
  }, [
    m('.entity-input__selection-container', {
      class: args.maxSelection ? 'entity-input__selection-container--max_selection' : '',
    }, [
      m.hide(args.selection().length || c.focused(), '.entity-input__placeholder', args.placeholder || 'Select an entity'),
      args.selection().map(displaySelection),
      m('input#entity-input__search-input.entity-input__search-input', {
        style: {
          opacity: c.focused() ? 1 : 0,
        },
        type   : 'text',
        value  : c.searchInput(),
        oninput: m.withAttr('value', c.filterOptions.bind(null, args)),
        key    : 'input',
      }),
    ]),
    m('.entity-input__options-container', {
      class: c.focused() ? 'entity-input__options-container--visible' : '',
    }, c.filteredOptions().map(displayOption)),
  ]);

  function displaySelection(entity, index) {
    return m('.entity-input__tag.entity-input__tag--selection', [
      '#' + entity.id() + ' ' + entity[args.displayPath](),
      m('.entity-input__tag__close', {
        onclick: c.removeItem.bind(null, args, index),
      }),
    ]);
  }

  function displayOption(entity, index) {
    return m('.entity-input__tag.entity-input__tag--option', {
      onclick: c.addItem.bind(null, args, entity),
      class  : c.isSelected(args, entity.id()) ? 'hidden' : '',
    }, '+ #' + entity.id() + ' ' + entity[args.displayPath]());
  }
};

export default component;
