// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 540,
    height: 120,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  wrapperL: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  wrapperR: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 32,
  },
  title: {
    paddingLeft: 20,
    paddingRight: 16,
    paddingBottom: 8,
    fontWeight: 700,

    ...TYPOGRAPHY.H2,
  },
  description: {
    paddingLeft: 20,
    paddingRight: 16,
    fontWeight: 400,

    ...TYPOGRAPHY.BODY3,
  },
  indicator: {
    position: 'relative',
    bottom: 2,
    marginLeft: 10,
  },
};
