import { mergeDeep } from 'Libs/mergeDeep';

export type WeekDayType = Readonly<{
  id: number;
}>;

export default function createWeekDay(values?: any): WeekDayType {
  const defaultState: WeekDayType = {
    id: 0,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep(defaultState, values);
}
