import React, { useEffect } from 'react';
import { t } from 'i18next';
import { DeepPartial } from 'ts-essentials';
import { GAME_TYPE, LanguageCode, SwipingCards } from '@sparted/shared-library/business/types';
import { useFormContext } from 'react-hook-form';

import ULabel from 'Components/unit/ULabel/ULabel';
import STextArea from 'Components/structural/STextArea/STextArea';
import {
  SSwipingCardSectionItems,
  SSwipingCardsSection,
} from 'Components/structural/SSwipingCardsSection/SSwipingCardsSection';
import SGameplayListItemsSection, {
  SGameplayListItemProps,
} from 'Components/structural/SGameplayListItemsSection/SGameplayListItemsSection';
import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';

import generalStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';
import {
  EXPLANATION_CHAR_LIMIT,
  QUESTION_CHAR_LIMIT,
  SWIPING_CARDS_TITLE_CHAR_LIMIT,
  SWIPING_CARDS_ZONE_CHAR_LIMIT,
} from './gameplay.constants';
import { BaseContentFormValues } from '../ContentEdition/ContentEdition';

type SwipingCardsFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

const zones = ['left', 'right', 'bottom', 'top'] as const;

export const SwipingCardsForm = ({ language, isGameplayAlreadyChosen, editGameplay }: SwipingCardsFormProps) => {
  const { watch, setValue } = useFormContext<BaseContentFormValues & { game: DeepPartial<SwipingCards> }>();

  const formatedLabels = {
    labelForm:
      watch('game.zones')?.map((zone, index) => ({
        value: zone?.text?.[language] || '',
        id: zones[index],
      })) ?? [],
    cardForm: watch('game.zones')?.map((zone, index) => ({
      id: zones[index],
      label: zone?.text?.[language] || '',
    })) ?? [{ id: zones[0], label: '' }],
  };

  const formatedCards =
    watch('game.cards')?.map((card) => ({
      textAreaValue: card?.text?.[language] || '',
      image: card?.image,
      selected: card?.answer,
    })) ?? [];

  useEffect(() => {
    if (!watch('game.cards') || watch('game.cards')?.length === 0) {
      setValue('game.cards', [{ text: { [language]: '' }, answer: 'left' as const }]);
    }

    if (!watch('game.zones') || watch('game.zones')?.length === 0) {
      setValue('game.zones', [
        { text: { [language]: '' }, position: 'left' as const },
        { text: { [language]: '' }, position: 'right' as const },
      ]);
    }
  }, []);

  const onLabelsChange = (items: SGameplayListItemProps[]) => {
    setValue(
      `game.zones`,
      items.map((item, index) => ({ text: { [language]: item.value }, position: zones[index] })),
    );

    setValue(
      `game.cards`,
      watch('game.cards')?.map((card) => ({
        ...card,
        // if the selected answer is not in the zones anymore (for exemple when the zone has been deleted), set it to left
        answer: card?.answer && zones.slice(items.length).indexOf(card.answer) ? card?.answer : 'left',
      })),
    );
  };

  const onCardsChange = (items: SSwipingCardSectionItems[]) => {
    setValue(
      'game.cards',
      items.map((item) => ({
        text: { [language]: item.textAreaValue || '' },
        image: item.image,
        answer: item?.selected ?? 'left',
      })),
    );
  };

  return (
    <div style={generalStyles.wrapper}>
      <div style={generalStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.SWIPING_CARDS}
          title={t('gameplays:swiping_cards.label')}
          description={t('gameplays:swiping_cards.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />

        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:question')}</ULabel>
          <STextArea
            textAreaTypo="BODY2"
            canResize={false}
            placeholder={t('gameplays:swiping_cards.question_placeholder')}
            height="130"
            onChange={(text) => {
              setValue(`game.question.${language}`, text);
            }}
            value={watch(`game.question.${language}`) || ''}
            radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
            maxLength={QUESTION_CHAR_LIMIT}
          />
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:swiping_cards.answer_options')}</ULabel>

          <SGameplayListItemsSection
            variant="none"
            type="standard"
            items={formatedLabels.labelForm}
            onItemsChange={onLabelsChange}
            style={generalStyles.sectionSeparation}
            textLinkTooltipText={t('gameplays:swiping_cards.too_many_items')}
            maxLength={4}
            minLength={2}
            withArrowsIcons
            dragAndDropDisabled
            itemPlaceholder={t('gameplays:swiping_cards.placeholder_answer_option')}
            textLinkText={t('gameplays:swiping_cards.add_answer_option')}
            maxInputLength={SWIPING_CARDS_ZONE_CHAR_LIMIT}
          />
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:swiping_cards.cards')}</ULabel>
          <SSwipingCardsSection
            maxTitleLength={SWIPING_CARDS_TITLE_CHAR_LIMIT}
            maxLength={6}
            onItemsChange={onCardsChange}
            items={formatedCards}
            options={formatedLabels.cardForm}
          />
        </div>

        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:explanation')}</ULabel>
          <STextArea
            textAreaTypo="BODY2"
            canResize={false}
            placeholder={t('gameplays:swiping_cards.explanation_placeholder')}
            height="130"
            onChange={(text) => {
              setValue(`game.explanation.${language}`, text);
            }}
            value={watch(`game.explanation.${language}`) || ''}
            radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
            maxLength={EXPLANATION_CHAR_LIMIT}
          />
        </div>
      </div>
    </div>
  );
};
