// @flow

/* eslint-disable key-spacing */

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  content: {
    ...TYPOGRAPHY.BODY3,
    overflow: 'visible',
  },
  label: {
    ...TYPOGRAPHY.BODY3,
  },
  explanationTitle: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 'bold',
    marginTop: 16,
  },
  explanation: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 4,
  },
};
