import React, { useCallback, useEffect, useState } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import { Source } from 'Libs/ts/types';
import MModal from 'Components/modal/MModal/MModal';
import SCallout from 'Components/structural/SCallout/SCallout';

import {
  getAudienceGroupMeta as defaultGetAudienceGroupMeta,
  GetAudienceGroupMetaFn,
} from 'Features/audience/services/audience-group-meta.service';
import {
  AudienceGroup,
  CreateAudienceGroupFn,
  createAudienceGroup as defaultCreateAudienceGroup,
  type GroupFormData,
} from 'Features/audience/services/create-audience-group.service';

import AudienceGroupForm from '../../forms/AudienceGroupForm/AudienceGroupForm';
import { Dimension } from '../../forms/AudienceGroupForm/utils/get-dimensions';

import styles from './CreateAudienceGroupModal.style';

const noop = () => {};

export type CreateAudienceGroupModalProps = {
  visible: boolean;
  style?: CSSProperties;

  onClose: () => void;
  onConfirm: (formData: AudienceGroup) => void;

  // Used in testing and to avoid dependencies.
  createAudienceGroup?: CreateAudienceGroupFn;
  getAudienceGroupMeta?: GetAudienceGroupMetaFn;
  segmentationDimensions?: Dimension[];
  segmentationSource?: Source;
};

export const CreateAudienceGroupModal = ({
  visible,
  style = {},

  onConfirm,
  onClose,

  // Used in testing and to avoid direct dependencies to fetch and apis.
  createAudienceGroup = defaultCreateAudienceGroup,
  getAudienceGroupMeta = defaultGetAudienceGroupMeta,
  segmentationDimensions,
  segmentationSource,
}: CreateAudienceGroupModalProps) => {
  const [formData, setFormData] = useState<GroupFormData>({
    name: '',
    segmentations: [],
  });

  const [duplicateGroupName, setDuplicateGroupName] = useState('');

  const [showNonUniqueNameWarning, setShowNonUniqueNameWarning] = useState(false);
  const [showNonUniqueGroupWarning, setShowNonUniqueGroupWarning] = useState(false);

  const [showUnknownErrorCallout, setShowUnknownErrorCallout] = useState(false);

  const handleFormDataChange = useCallback((newFormData: GroupFormData) => setFormData(newFormData), []);

  const setDefaultFormData = useCallback(async () => {
    const { currentSequence } = await getAudienceGroupMeta();

    setFormData({
      name: `Group ${currentSequence + 1}`,
      segmentations: [],
    });
  }, [getAudienceGroupMeta]);

  const resetErrors = useCallback(() => {
    setShowNonUniqueNameWarning(false);
    setShowNonUniqueGroupWarning(false);
    setShowUnknownErrorCallout(false);
    setDuplicateGroupName('');
  }, []);

  const resetForm = useCallback(async () => {
    resetErrors();
    await setDefaultFormData();
  }, [resetErrors, setDefaultFormData]);

  useEffect(() => {
    resetForm();
  }, [resetForm, visible]);

  const handleAction = useCallback(async () => {
    if (!CreateAudienceGroupModal) {
      return;
    }

    resetErrors();

    const { result, error } = await createAudienceGroup(formData);

    // TODO: add error case for when a group has the same seg as another.

    if (error?.errorCode === 'AUD-CA-CAG-001') {
      setShowNonUniqueNameWarning(true);
      return;
    }

    if (error?.errorCode === 'AUD-CA-CAG-003') {
      setShowNonUniqueGroupWarning(true);
      setDuplicateGroupName(error?.params?.groupName || '');
      return;
    }

    if (!result || error) {
      setShowUnknownErrorCallout(true);
      return;
    }

    await onConfirm(result);
  }, [createAudienceGroup, formData, onConfirm, resetErrors]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const isFormDataValid = formData.name && formData.segmentations.length;

  return (
    <MModal
      type="dialog"
      labelActionButton={t('audiences:create_audience_group_modal.labels.create')}
      labelSecondButton={t('audiences:create_audience_group_modal.labels.cancel')}
      title={t('audiences:create_audience_group_modal.labels.title')}
      description={t('audiences:create_audience_group_modal.labels.description')}
      onAction={handleAction}
      onActionEnd={noop}
      onCloseModal={handleClose}
      onSecondAction={handleClose}
      showExitCross
      disableActionButton={!isFormDataValid}
      visible={visible}
      style={{ ...styles.modal, ...style }}
    >
      {showNonUniqueGroupWarning && (
        <SCallout
          type="warning"
          description={t('audiences:create_audience_group_modal.labels.group_already_exists', {
            groupName: duplicateGroupName,
          })}
          style={styles.callout}
        />
      )}
      {showUnknownErrorCallout && (
        <SCallout
          type="warning"
          description={t('audiences:create_audience_group_modal.labels.unknown_error')}
          style={styles.callout}
        />
      )}
      <AudienceGroupForm
        values={formData}
        onChange={handleFormDataChange}
        segmentationDimensions={segmentationDimensions}
        segmentationSource={segmentationSource}
        errors={
          showNonUniqueNameWarning
            ? { nameInput: t('audiences:create_audience_group_modal.labels.name_already_exists') }
            : undefined
        }
      />
    </MModal>
  );
};

export default CreateAudienceGroupModal;
