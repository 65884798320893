import Model from 'models/Model';

var SwipingCardsItem = {
  endpoint  : 'api/SwipingCardsItems',
  attributes: {
    swipingCards: {
      type : 'pointer',
      Model: 'SwipingCards',
    },
    text: {
      type : 'pointer',
      Model: 'GameItem',
    },
    image: {
      type : 'pointer',
      Model: 'Image',
    },
    type: {
      type : 'pointer',
      Model: 'SwipingCardsItemType',
    },
    swipingCardsId: {
      type: 'number',
    },
    imageId: {
      type: 'number',
    },
    textId: {
      type: 'number',
    },
    answerId: {
      type: 'number',
    },
    answer: {
      type : 'pointer',
      Model: 'GameItem',
    },
    typeId: {
      type: 'number',
    },
  },
  methods: {},
};

export default Model.register('SwipingCardsItem', SwipingCardsItem);
