import React, { FunctionComponent, useMemo } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'Libs/redux/utils';

import { COLORS } from 'Components/foundation';
import SAudienceGroupCard from 'Components/structural/SAudienceGroupCard/SAudienceGroupCard';
import SNewItemCard from 'Components/structural/SNewItemCard/SNewItemCard';
import type { ChipItem } from 'Components/structural/STruncatedChipList/STruncatedChipList';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SSection from 'Components/structural/SSection/SSection';
import SEmptyState from 'Components/structural/SEmptyState/SEmptyState';

import { selectAll } from 'Store/entities/audience-group/audience-group.selectors';
import { AudienceGroupEntity } from 'Store/entities/audience-group/audience-group.types';

import { getMetaForTooltip } from './utils';
import type { AudienceGroupListState } from '../hooks/useAudienceGroupListState';

import styleSheet from './AudienceGroupListBody.style';

export type AudienceScopeProps = 'course' | 'configuration';

export type AudienceGroupListBodyProps = {
  loading: boolean;
  scope?: AudienceScopeProps;
  audienceGroupListState: AudienceGroupListState;
  assignedAudienceGroupIdsSet: Set<number>;
  handleCreateGroupModalToggle: () => void;
  loadNextBatchAudienceGroups: () => void;
  onCardClick: (id: number) => void;
  onCardSelect: (id: number) => void;
  onUpdateAudienceGroup: (id: number) => void;
  onDeleteAudienceGroup: (id: number) => void;
};

const ALL_GROUP_ID = 1;

export const AudienceGroupListBody: FunctionComponent<AudienceGroupListBodyProps> = ({
  loading,
  scope = 'course',
  audienceGroupListState,
  assignedAudienceGroupIdsSet,
  handleCreateGroupModalToggle,
  loadNextBatchAudienceGroups,
  onCardClick,
  onCardSelect,
  onUpdateAudienceGroup,
  onDeleteAudienceGroup,
}) => {
  const audienceGroups = useTypedSelector<AudienceGroupEntity[]>((state) => selectAll(state.entities.audienceGroup));

  const { t } = useTranslation();

  /**
   * Overloading the `.selected` properties because to avoid multiple sources of truth.
   * We trust the array given to AudienceGroupList because it is all the groups assigned to the given entity
   * and not only those returned by the search.
   */
  const audienceGroupsWithSelectedMetadata = useMemo(
    () =>
      audienceGroups.map((item) => ({
        ...item,
        selected: Boolean(assignedAudienceGroupIdsSet.has(item.groupId)),
      })),
    [assignedAudienceGroupIdsSet, audienceGroups],
  );

  return (
    <>
      {!loading && audienceGroupListState === 'empty' && (
        <SSection style={{ marginBottom: 32 }}>
          <SEmptyState
            illustrationName="audience-empty-state"
            title={t('audiences:audience_group_card.empty_state_title')}
            description={t('audiences:audience_group_card.empty_state_description')}
          />
        </SSection>
      )}
      {audienceGroupListState !== 'empty' && (
        <div style={styleSheet.audienceGroupList}>
          {audienceGroupsWithSelectedMetadata.map((group) => (
            <SAudienceGroupCard
              key={group.groupId}
              groupId={group.groupId}
              name={group.name}
              description={group.description}
              meta={{
                explanation: t('audiences:audience_group_card.tooltip.explanation'),
                target: getMetaForTooltip(group.meta, scope),
              }}
              segmentations={group.segmentations as ChipItem[] | undefined}
              selected={group.selected}
              // Disable edition/deletion for the `ALL group`
              showMenu={ALL_GROUP_ID !== group.groupId}
              onClick={ALL_GROUP_ID !== group.groupId ? onCardClick : onCardSelect}
              onSelect={onCardSelect}
              onUpdate={onUpdateAudienceGroup}
              onDelete={onDeleteAudienceGroup}
            />
          ))}
          {audienceGroupListState === 'tutorial' && (
            <SNewItemCard
              title={t('audiences:audience_group_card.first_group')}
              description={t('audiences:audience_group_card.first_group_description')}
              illustration="audience-group"
              onClick={handleCreateGroupModalToggle}
            />
          )}
        </div>
      )}
      {audienceGroupListState === 'partial' && (
        <UTextLink
          marginLeft="auto"
          marginRight="auto"
          onClick={loadNextBatchAudienceGroups}
          rightIcon="bottom-chevron"
          text="View more"
          mode="big"
          colorOverride={COLORS.BLUE_GRAY_DARKER.DEFAULT}
          style={styleSheet.nextBatch}
        />
      )}
      {loading && (
        <div style={styleSheet.loadingContainer}>
          <CircleSpinner size={32} color={COLORS.TEXT.SECONDARY_HOVER} loading />
        </div>
      )}
    </>
  );
};
