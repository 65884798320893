import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { actions } from '../../redux';

import styles from './CategoryCreationModal.style';

export const CategoryCreationModal = () => {
  const dispatch = useTypedDispatch();
  const label = useTypedSelector((state) => state.pages.categoryList.categoryToCreate.label);
  const creationError = useTypedSelector((state) => state.pages.categoryList.creationError);
  const visible = useTypedSelector((state) => state.pages.categoryList.isCreationModalOpen);

  const handleChangedValue = useCallback(
    (newLabel: string) => dispatch(actions.editLabelForCreation(newLabel)),
    [dispatch],
  );

  const handleSaveCategory = useCallback(() => Promise.resolve(dispatch(actions.create())), [dispatch]);

  const handleSaveEnd = useCallback(
    (res: any) => {
      const { error } = res;

      if (!error) dispatch(actions.toggleCreationModalStatus(false));
    },
    [dispatch],
  );

  const handleCloseModal = useCallback(() => dispatch(actions.toggleCreationModalStatus(false)), [dispatch]);

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleSaveCategory}
      onActionEnd={handleSaveEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={t('config_categories:category_creation_modal.title')}
      labelActionButton={t('config_categories:category_creation_modal.label_action_button')}
      labelSecondButton={t('config_categories:category_creation_modal.label_second_button')}
      actionButtonType="accentuated"
      disableActionButton={!label}
      labelError={creationError}
    >
      <div>
        <span style={styles.inputLabel}>{t('config_categories:category_creation_modal.choose_name')}</span>
        <SInput
          type="large"
          value={label}
          onChange={handleChangedValue}
          style={styles.input}
          autofocus
          placeholder={t('config_categories:category_creation_modal.placeholder')}
        />
      </div>
    </MModal>
  );
};
