import m from 'm';

var component = {};

/*
  ARGS
    - value
    - oninput
*/
component.controller = function controller() {
  var self = this;

  self.onclick = function onclick(args) {
    if (args.editable())
      args.oninput();
  };

};
component.view = function view(c, args) {
  return m('.input-radio', {
    class  : !args.editable() ? 'input-radio--disabled' : '',
    onclick: c.onclick.bind(null, args),
  }, [
    m('.input-radio__bullet', {
      class: args.value ? 'input-radio__bullet--selected' : '',
    }),
  ]);
};

export default component;
