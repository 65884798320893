// @flow

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    flex: 1,
    borderRadius: 4,
    display: 'inline-block',
    border: `1px solid ${COLORS.CHIPS.DEFAULT}`,
    backgroundColor: COLORS.WHITE.DEFAULT,
    maxHeight: 350,
    overflow: 'auto',
    paddingBottom: 4,
  },
};
