import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';

var Gift = {
  endpoint  : 'api/Gifts',
  attributes: {
    stock: {
      type: 'number',
    },
    credits: {
      type: 'number',
    },
    cost: {
      type: 'number',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    validityPeriod: {
      type: 'boolean',
    },
    startDate: {
      type: 'date',
    },
    endDate: {
      type: 'date',
    },
    severalOrders: {
      type: 'boolean',
    },
    active: {
      type: 'boolean',
    },
    archived: {
      type: 'boolean',
    },
    mails: {
      type: 'array',
    },
    currency: {
      type : 'pointer',
      Model: 'Currency',
    },
    currencyId: {
      type: 'number',
    },
    picture: {
      type : 'pointer',
      Model: 'Image',
    },
    pictureId: {
      type: 'number',
    },
    orders: {
      type : 'collection',
      Model: 'GiftOrder',
    },
  },
  methods: {
    createFull: function createFull(_Gift) {
      return request.post('/api/gifts/full', this.toJSON())
        .then(function returnIt(created) {
          return new _Gift(created);
        });
    },
    updateFull: function updateFull(_Gift) {
      return request.put('/api/gifts/full/' + this.id(), this.toJSON())
        .then(function returnIt(updated) {
          return new _Gift(updated);
        });
    },
    archive: function archive(_Gift) {
      this.archived(true);

      return this.save();
    },
    status: function status() {
      var self = this;

      var now = new Date();
      var statusId = 0;
      var states = [
        'inactive',
        'upcoming',
        'live',
        'finished',
      ];

      if (self.active()) {
        if (!self.validityPeriod())
          statusId = 2;
        else if (now < self.startDate())
          statusId = 1;
        else if (now < self.endDate())
          statusId = 2;
        else
          statusId = 3;
      }

      return states[statusId];
    },
    getRoute: function getRoute() {
      var self = this;
      var url = '/gaia/gifts/';

      if (self.id())
        return url + self.id();

      return url + 'new';
    },
  },
  classMethods: {
    getFull: function getFull(_Gift, id) {
      return request.get('/api/gifts/full/' + id)
        .then(function returnIt(get) {
          return new _Gift(get);
        });
    },
    filterBy: function filterBy(_Gift, filterByInput) {
      var filter = {};
      var text = filterByInput.text;
      var segmentation = filterByInput.segmentation;

      if (text) {
        filter = {
          where: {
            and: [],
          },
        };

        filter.where.and.push({
          or: text.map((inputText) => {
            return {
              or: [{
                name: {
                  regexp: '/' + inputText + '/i',
                },
              },
              {
                description: {
                  regexp: '/' + inputText + '/i',
                },
              },
              ],
            };
          }),
        });
      }

      if (segmentation && segmentation.length)
        filter.segmentation = segmentation.map((id) => ({ id }));

      return _Gift.filter(filter);
    },
    filter: function filter(_Gift, inputFilter) {
      var path = Gift.endpoint + '/full';

      if (inputFilter && Object.keys(inputFilter).length)
        path += '?filter=' + JSON.stringify(inputFilter);

      return request
        .get(path)
        .then(function turnToModelList(_gifts) {
          return new Collection('Gift', Gift, _gifts);
        });
    },
    sortByStatus: function sortByStatus(_Gift, a, b) {
      var states = {
        'upcoming' : 1,
        'live'     : 2,
        'inactive' : 3,
        'finished' : 4,
        'undefined': 0,
      };

      var n = states[a.status()] - states[b.status()];

      if (n !== 0)
        return n;

      // More recent items first
      return b.id() - a.id();
    },
  },
};

export default Model.register('Gift', Gift);
