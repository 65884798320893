import m from 'm';
import T from 'types';
import KEY from 'keys';
import Bus from 'services/pubSubService';

/* args:
 *  type: [ email, password, search ],
 *  placeholder:
 *  --
 *  value (m.prop)
 *  or -
 *  model
 *  path
 *  --
 *  onclick
 *  class
 *  max
 */
var classTypes = {
  email   : 'input--email',
  password: 'input--password',
  search  : 'input--search',
};

var component = {};

component.controller = function controller(args) {
  var self = this;
  var blurProtected = m.prop(false);

  self.error = m.prop(false);

  self.oninput = function oninput(value) {
    noError();
    self.propValue()(value);
    if (args.oninput)
      args.oninput();
  };

  self.propValue = function propValue() {
    if (args.model && args.path)
      return args.model[args.path];

    return args.value;
  };

  self.onkeyup = function onkeyup(e) {
    var key = e.which;

    e.preventDefault();

    if (key === KEY.ENTER && args.autosave)
      save();
  };

  self.onblur = function onblur() {
    if (!blurProtected() && args.autosave)
      save();
  };

  self.onclick = function onclick() {
    if (args.onclick)
      args.onclick(args.model);
  };

  function save() {
    var promise = null;

    if (!args.model || !args.path)
      return;

    if (args.model[args.path](args.model[args.path]().trim()))
      promise = args.model.save();
    else if (args.model.id() !== undefined)
      promise = args.model.destroy();

    blurProtected(true);

    if (promise) {
      promise
        .then(m.redraw)
        .then(blurProtected.bind(null, false))
        .catch(function triggerError(err) {
          self.error(true);
          Bus.trigger('popup:error', err);
          Bus.trigger('general:error', err);
          m.redraw();
        });
    }
  }

  function noError() {
    self.error(false);
    Bus.trigger('popup:noError');
    Bus.trigger('general:noError');
  }
};

component.view = function view(c, args) {
  var genericPlaceholder;

  if (args.model && args.path)
    genericPlaceholder = ['New', T.lowercase(args.model._type)].join(' ');

  return m('.input', {
    class: [
      classTypes[args.type] || 'input--none',
      args.class,
    ].join(' '),
  }, [
    m('input.input__value', {
      type       : args.type || 'text',
      class      : c.error() ? 'popup-init__error' : '',
      placeholder: args.placeholder || genericPlaceholder,
      onfocus    : c.error.bind(null, false),
      onblur     : c.onblur,
      value      : c.propValue()(),
      oninput    : m.withAttr('value', c.oninput),
      onclick    : c.onclick,
      onkeyup    : c.onkeyup,
      maxlength  : args.max ? args.max : '',
    }),
    m('.input__type'),
    m('.input__maxlength', args.max
      ? [c.propValue()().length, args.max].join(' / ')
      : ''),
  ]);
};

export default component;
