/**
 * WARNING This file appears in multiple locations:
 *  - back-office/src/models/Tree.js
 *  - backend/loopback/libs/Tree.js
 */

var POSITION = 8;

function Tree(args, ft) {
  this.tree = { children: []};
  this.map = {};

  if ({}.toString.call(args).slice(POSITION, -1) === 'Array')
    array2Tree(this.tree, this.map, args, ft);

  if ({}.toString.call(args).slice(POSITION, -1) === 'Object')
    copy(this.tree, this.map, args);

  Object.defineProperty(this, 'length', {
    get: function returnLength() {
      return Object.keys(this.map).length;
    },
  });
}

Tree.prototype.remove = function remove(args) {
  var type = {}.toString.call(args).slice(POSITION, -1);

  if (type === 'Array')
    return this.removeMany(args);
  if (type === 'Object')
    return [this.removeOne(args)];
  if (type === 'Number')
    return [this.removeOne(this.get(args))];
};

Tree.prototype.removeMany = function removeMany(items) {
  var many = [];

  items = items.slice();

  for (var i = 0, l = items.length; i < l; i++)
    many.push(this.removeOne(items[i]));

  return many;
};

Tree.prototype.removeOne = function removeOne(item) {
  var parent = item.parentId ? this.map[item.parentId] : this.tree;
  var positionInParent = indexOf(parent, item);

  removeOffspringFromMap(this.map, item);

  parent.children.splice(positionInParent, 1);
  delete this.map[item.id];

  return item;
};

Tree.prototype.leaves = function getLeaves() {
  var mapFlatten = Object.keys(this.map);
  var leaves = [];
  var id = 0;

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];

    if (!this.map[id].children.length)
      leaves.push(this.map[id]);
  }

  return leaves;
};

Tree.prototype.forEach = function forEach(cb) {
  var mapFlatten = Object.keys(this.map);
  var id = 0;

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];
    cb(this.map[id]);
  }

  return this;
};

Tree.prototype.subTree = function subTree(args) {
  var type = {}.toString.call(args).slice(POSITION, -1);
  var item = null;
  var out = new Tree();

  if (type === 'Number')
    item = this.map[args];
  else if (type === 'Object')
    item = args;

  this.walk('pre', item, insertInTree);

  function insertInTree(_item) {
    var parent = (_item.id === item.id) ? out.tree : out.map[_item.parentId];

    parent.children.push(_item);
    out.map[_item.id] = _item;
  }

  return out;
};

Tree.prototype.get = function get(id) {
  return this.map[id];
};

Tree.prototype.getBrothers = function getBrothers(args) {
  var item = null;
  var parent = null;
  var index = 0;
  var ret = [];

  if ({}.toString.call(args).slice(POSITION, -1) === 'Number')
    item = this.get(args);
  else if ({}.toString.call(args).slice(POSITION, -1) === 'Object')
    item = args;

  parent = item.parentId ? this.map[item.parentId] : this.tree;

  index = indexOf(parent, item);

  ret = parent.children.slice();
  ret.splice(index, 1);

  return ret;
};

Tree.prototype.cut = function cutTree(item) {

};

Tree.prototype.getLevel = function getLevel() {

};

Tree.prototype.uniqBy = function uniqBy(path) {
  var out = this.clone();
  var mapFlatten = Object.keys(this.map);
  var id = 0;
  var seen = {};
  var value = null;

  path = path.split('.');

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];
    value = out.map[id];

    if (value) {
      for (var j = 0, len = path.length; j < len; j++)
        value = value[path[j]];

      if (seen[value])
        out.remove(parseInt(id));
      seen[value] = true;
    }
  }

  return out;
};

Tree.prototype.clone = function cloneTree() {
  return new Tree(clone(this.toArray()));
};

Tree.prototype.toArray = function toArray() {
  var mapFlatten = Object.keys(this.map);
  var id = 0;
  var array = [];

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];
    array.push(this.map[id]);
  }

  return array;
};

Tree.prototype.walk = function walk(type, item, cb) {
  var out = [];

  cb = cb || function noop() {};

  _walk(type, item || this.tree.children[0]);

  return out;

  function _walk(_type, _item) {
    _item = clone(_item);

    if (_type === 'pre') {
      cb(_item);
      out.push(_item);
    }

    for (var i = 0, l = _item.children.length; i < l; i++)
      _walk(_type, _item.children[i]);

    if (_type === 'post') {
      cb(_item);
      out.push(_item);
    }
  }
};

Tree.prototype.filter = function filterTree(args) {
  if ({}.toString.call(args).slice(POSITION, -1) === 'Object') {
    for (var i = 0, l = args.children.length;;) {

    }
  }
};

Tree.prototype.getBranch = function getBranch(args) {
  var item = null;
  var array = [];

  if ({}.toString.call(args).slice(POSITION, -1) === 'Number')
    item = this.get(args);
  else if ({}.toString.call(args).slice(POSITION, -1) === 'Object')
    item = args;

  while (item.parentId) {
    array.push(clone(item));
    item = this.map[item.parentId];
  }

  array.push(clone(item));

  return new Tree(array);
};

/**
  * The two trees must have the same root
  */
Tree.prototype.merge = function merge(tree) {
  var mapFlatten = Object.keys(tree.map);
  var id = 0;

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];
    this.add(tree.map[id]);
  }

  return this;
};

Tree.prototype.mergeConditional = function mergeConditional(tree, cb) {
  var mapFlatten = Object.keys(tree.map);
  var id = 0;

  for (var i = 0, l = mapFlatten.length; i < l; i++) {
    id = mapFlatten[i];
    if (cb(tree.map[id]))
      this.add(tree.map[id]);
  }

  return this;
};

Tree.prototype.add = function add(item) {
  // console.log(item);
  if (this.map[item.id])
    return this;

  item.children = [];
  this.map[item.id] = item;

  if (item.parentId)
    this.map[item.parentId].children.push(item);
  else {
    item.parentId = 0;
    this.tree.children.push(item);
  }

  return this;
};

function copy(tree, map, toCopy) {

}

function array2Tree(tree, map, array, ft) {
  var item = null;
  var shouldBeAdded = true;

  array.sort(sortArrayBeforeTree);

  for (var i = 0, l = array.length; i < l; i++) {
    item = array[i];

    if (ft)
      shouldBeAdded = ft(item, tree, map);

    if (shouldBeAdded) {
      item.children = [];
      map[item.id] = item;

      if (!item.parentId) {
        item.parentId = 0;
        tree.children.push(item);
      } else {
        if (map[item.parentId])
          map[item.parentId].children.push(item);

        // else
        //   console.warn('[tree]', 'orphan leaf');
      }
    }
  }

  return tree;
}

function sortArrayBeforeTree(a, b) {
  return (a.parentId || 0) - (b.parentId || 0);
}

function indexOf(parent, child) {
  var children = parent.children;
  var i = 0;
  var l = children.length;

  while (i < l && child.id !== children[i].id)
    i++;

  return i === l ? -1 : i;
}

function removeOffspringFromMap(map, item) {
  var children = item.children;
  var child = null;

  for (var i = 0, l = children.length; i < l; i++) {
    child = children[i];

    if (child.children.length)
      removeOffspringFromMap(map, child);
    delete map[child.id];
  }
}

// function clone(obj) {
//   return _.cloneDeep(obj);
// }

function clone(obj) {
  var theClone;

  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== 'object')
    return obj;

  // Handle Date
  if (obj instanceof Date) {
    theClone = new Date();
    theClone.setTime(obj.getTime());

    return theClone;
  }

  // Handle Array
  if (obj instanceof Array) {
    theClone = [];
    for (var i = 0, len = obj.length; i < len; i++)
      theClone[i] = clone(obj[i]);

    return theClone;
  }

  // Handle Object
  if (obj instanceof Object) {
    theClone = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr))
        theClone[attr] = clone(obj[attr]);
    }

    return theClone;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

module.exports = Tree;
