import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    margin: 'auto',
    width: 940,
  },
  infoWrapper: {
    paddingTop: 56,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: 8,
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  codeInput: {
    marginTop: 40,
  },
  audienceTable: {
    marginTop: 60,
    paddingBottom: 40,
  },
};
