import m from 'm';
import App from 'models/App';
import SegmentationDimension from 'models/SegmentationDimension';
import SegmentationGroupItem from 'models/SegmentationGroupItem';
import SegmentationItem from 'models/SegmentationItem';
import User from 'services/userService';
import Enum from 'models/Enum';
import Promise from 'bluebird';

var component = {};

/*
 * dimension            : the dimension concerned by the new item
 * ancestor             : the item selected in the previous dimension
 * color                : the color of the dimension
 * depth                : the deepness of the dimension
 * parentName           : the name of the ancestor dimension
 * typeId               : type of the dimension
 */
component.controller = function controller(args) {
  var self = this;

  self.groupItemLabel = m.prop('');
  self.itemLabel = m.prop('');
  self.groupItem = m.prop();
  self.item = m.prop();
  self.opened = m.prop(false);
  self.isMasterAdmin = m.prop(false);
  self.changeLabel = m.prop('');
  self.wantToChange = m.prop(false);
  self.availableSegmentationGroups = [];
  self.isMasterAdmin(User.meSync().isMasterAdmin());

  self.selectGroup = function selectGroup(group) {
    self.groupItem(group);
  };

  function updateAppVariables() {
    const updateUserSegmentationItems = SegmentationItem.getFull()
      .tap(function loadUserSegmentation(segmentationItems) {
        var playerSegmentation = User.meSync().segmentation();
        var filteredSegmentation = SegmentationGroupItem
          .filterWithUserSegmentation(segmentationItems, playerSegmentation);

        App.userSegmentationItems(filteredSegmentation);
      });
    const updateLanguages = SegmentationDimension.find({
      filter: {
        where: {
          typeId: 1,
        },
        include: {
          relation: 'groups',
          scope   : {
            include: ['dimension', 'members'],
          },
        },
      },
    }).then(function setLanguages(dimensions) {
      return dimensions.at(0).groups();
    })
      .then((languages) => App.languages(languages));

    return Promise.all([updateUserSegmentationItems, updateLanguages]);
  }

  self.saveItem = function saveItem(keep) {
    var item = new SegmentationItem({
      groupId : self.groupItem().id(),
      parentId: args.ancestor && args.ancestor.item ? args.ancestor.item.id() : undefined,
    });

    item.label(keep ? self.groupItem().label() : self.changeLabel());

    return item.save()
      .then(function handleResult(res) {

        var group = args.dimension.groups().get(self.groupItem().id());

        if (group)
          group.members().push(res);
        else {
          self.groupItem().members().push(res);
          args.dimension.groups().push(self.groupItem());
        }

        self.resetComponent();
        m.redraw();
      })
      .tap(updateAppVariables);
  };

  self.saveGroupItem = function saveGroupItem() {
    if (!self.groupItemLabel())
      return Promise.reject();

    var groupItem = new SegmentationGroupItem({
      dimensionId: args.dimension.id(),
      label      : self.groupItemLabel(),
    });

    return groupItem.save()
      .then(function handleResult(res) {
        self.groupItem(res);
        m.redraw();
      })
      .tap(updateAppVariables);
  };

  self.saveGroupAndItem = function saveGroupAndItem() {
    return self.saveGroupItem()
      .then(self.saveItem.bind(null, true));
  };

  self.resetComponent = function resetComponent() {
    self.groupItemLabel('');
    self.itemLabel('');
    self.groupItem(undefined);
    self.item(undefined);
    self.opened(false);
    self.changeLabel('');
  };

  self.dropdownOpened = m.prop(false);

  self.onclickDropDown = function onclickDropDown() {
    if (!self.isMasterAdmin())    
      return;

    self.dropdownOpened(!self.dropdownOpened());
  };

  self.chooseAvailableGroup = function chooseAvailableGroup(availableGroup) {
    var newGroupItem = new SegmentationGroupItem({
      label           : availableGroup.label(),
      dimensionId     : args.dimension.id(),
      isDefault       : false,
      availableGroupId: availableGroup.id(),
      parentId        : null,
    });

    return newGroupItem
      .save()
      .then(self.groupItem)
      .then(self.saveItem.bind(null, true))
      .then(initAvailableSegmentationGroups)
      .then(m.redraw);
  };

  function initAvailableSegmentationGroups() {
    self.availableSegmentationGroups = App.AvailableSegmentationGroups()
      .find({ typeId: args.dimension.type().id() })
      .filter(function removeAlreadyCreated(item) {
        return !args.dimension.groups().first({
          availableGroupId: item.id(),
        });
      });
  }

  initAvailableSegmentationGroups();
};

component.view = function view(c, args) {
  return displayOptions();

  function displayOptions() {
    if (args.dimension.type().behaviors().get(Enum.SEGMENTATION_BEHAVIOR.PRESET_SEGMENTATION_GROUP)) {
      if (c.availableSegmentationGroups.length)
        return displaySelect();

      return m('');
    }

    if (args.typeId === Enum.dimensionTypes.STORE)
      return displayStoreInput();

    if (!args.depth)
      return displayGroupInput();

    if (!args.ancestor || !args.ancestor.item)
      return displayGroupInput();

    return displayItemInput();
  }

  function displaySelect() {
    return m('.segmentationSelect', {
      onclick: c.onclickDropDown,
    }, [
      m('.segmentationSelect__selected', {
        class: c.dropdownOpened()
          ? 'segmentationSelect__selected--open'
          : '',
      }, 'Select a language to add it'),
      m.hide(!c.dropdownOpened(), '.segmentationSelect__dropdown', [
        c.availableSegmentationGroups.map(function displayDropdownItem(item) {
          return m('.segmentationSelect__dropdown__item', {
            onclick: c.chooseAvailableGroup.bind(null, item),
          }, item.label());
        }),
      ]),
    ]);
  }

  function displayItemInput() {
    if (!c.opened()) {
      return m('.segmentation-new-input-container__new-group.segmentation-new-input-container__new-group--item', {
        onclick: c.opened.bind(null, !c.opened()),
      }, '+ Add ' + args.dimension.label());
    }

    if (!c.groupItem()) {
      return m('.segmentation-new-input-container', {
        style: {
          'border-color': args.color || '#52AFDF',
        },
      }, [
        m('.segmentation-new-input-container__title', {
          onclick: c.opened.bind(null, !c.opened()),
        }, 'Select a ' + args.dimension.label()),
        m('.segmentation-new-input-container__group-list', [displayGroupsToAdd()]),
        m('input.segmentation-new-input-container__new-group', {
          value      : c.groupItemLabel(),
          placeholder: '+ Add ' + args.dimension.label(),
          oninput    : m.withAttr('value', c.groupItemLabel),
          onkeydown  : function onenter(e) {
            if (e.keyCode === 13)
              c.saveGroupItem();
          },
        }),
      ]);
    }

    if (c.wantToChange()) {
      return m('.segmentation-new-input__rename-container', {
        style: {
          'border-color': args.dimension.color() || '#52AFDF',
        },
      }, [
        m('input.segmentation-new-input__rename-container__input', {
          value      : c.changeLabel(),
          oninput    : m.withAttr('value', c.changeLabel),
          onchange   : c.saveItem.bind(null, false),
          placeholder: '(' + c.groupItem().label() + ')',
          config     : function configInput(elem, isInit, ctx) {
            if (isInit)
              return;
            elem.focus();
          },
        }),
      ]);
    }

    return m('.segmentation-new-input__rename-container', {
      style: {
        'border-color': args.dimension.color() || '#52AFDF',
      },
    }, [
      m('.segmentation-new-input__rename-container__input', c.groupItem().label()),
      m('.segmentation-new-input__rename-container__keep-command', {
        onclick: c.saveItem.bind(null, true),
      }, 'Keep this name'),
      m('.segmentation-new-input__rename-container__change-command', {
        onclick: c.wantToChange.bind(null, true),
      }, 'Change it'),
    ]);

  }

  function displayStoreInput() {
    return m('textarea.segmentation-new-input-container__new-group.segmentation-new-input-container__new-group--store', {
      value      : c.groupItemLabel(),
      placeholder: '+ Add ' + args.dimension.label(),
      oninput    : m.withAttr('value', c.groupItemLabel),
      disabled   : !c.isMasterAdmin(),
      onkeydown  : function onenter(e) {
        if (e.keyCode === 13)
          c.saveGroupAndItem();
      },
    });
  }

  function displayGroupInput() {
    return m('input.segmentation-new-input-container__new-group', {
      disabled   : !c.isMasterAdmin(),
      value      : c.groupItemLabel(),
      placeholder: '+ Add ' + args.dimension.label(),
      oninput    : m.withAttr('value', c.groupItemLabel),
      onkeydown  : function onenter(e) {
        if (e.keyCode === 13)
          c.saveGroupAndItem();
      },
    });
  }

  function displayGroupsToAdd() {
    var possibleGroups = args.dimension.groups().filter(function filterAlreadyUsed(group) {
      return !group.members().find({ parentId: args.ancestor.item.id() }).items.length;
    });

    return possibleGroups.map(function displayGroup(group) {
      return m('.segmentation-new-input-container__group-item', {
        onclick: c.selectGroup.bind(null, group),
      }, group.label());
    });
  }
};

export default component;
