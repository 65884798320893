import React, { MouseEventHandler, useState } from 'react';

import { UDotsMenu } from 'Components/unit';

import { SContentCardProps } from '../SContentCard';
import styles from '../SContentCard.style';

export const HoverLayout = ({
  previewText,
  previewable,
  displayOption,
  handleToggleMenu,
  onPreviewClick,
}: Pick<SContentCardProps, 'previewText' | 'previewable' | 'displayOption'> & {
  handleToggleMenu: () => void;
  onPreviewClick: MouseEventHandler;
}) => {
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);
  const handlePreviewHover = () => setIsPreviewHovered(true);
  const handlePreviewLeave = () => setIsPreviewHovered(false);

  const shouldRenderPreview = previewable && previewText;
  const previewStyle = isPreviewHovered
    ? { ...styles.previewContainer, ...styles.previewHovered }
    : styles.previewContainer;
  return (
    <div style={styles.hoverLayout}>
      {displayOption && (
        <div style={styles.optionContainer}>
          <UDotsMenu onClick={handleToggleMenu} />
        </div>
      )}
      {!!shouldRenderPreview && (
        <span
          onClick={onPreviewClick}
          onMouseEnter={handlePreviewHover}
          onMouseLeave={handlePreviewLeave}
          data-test-id="PREVIEW_BUTTON"
          style={previewStyle}
        >
          {previewText}
        </span>
      )}
    </div>
  );
};