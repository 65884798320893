import React, { useCallback, useEffect, useRef } from 'react';
import type { HTMLProps, FunctionComponent, CSSProperties } from 'react';

/**
 * Value must be provided as a props to enable the resize
 */
export type UInputProps = Omit<HTMLProps<HTMLInputElement>, 'value'> & {
  value: string;
  autoResize?: boolean;
  minWidth?: number;
  extraWidth?: number;
};

export const FONT_PROPERTIES = [
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontSize',
  'lineHeight',
  'fontFamily',
] as const;

export type InputFontStyle = typeof FONT_PROPERTIES[number];

export const DEFAULT_INPUT_STYLE: Pick<CSSProperties, InputFontStyle> = {
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 400,
  fontSize: '13.3333px',
  lineHeight: 'normal',
  fontFamily: 'Arial',
};

export const createDummySpanElement = (text: string, style: CSSProperties) => {
  const span = document.createElement('span');

  FONT_PROPERTIES.forEach((property) => {
    span.style[property] = String(style[property] || DEFAULT_INPUT_STYLE[property]!);
  });

  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.innerText = text;

  return {
    appendToDocument: () => document.body.appendChild(span),
    removeFromDocument: () => document.body.removeChild(span),
    element: span,
  };
};

/**
 * A simple UInput with added functionnalities
 *
 * - autoResize: resize when the value changes
 * - typography: the typography for the input value
 * - extraWidth: used to add width to the input to prevent glitch effect
 * - minWidth: the minimum width for the input
 *
 * Also has the basic input props
 */
export const UInput: FunctionComponent<UInputProps> = ({
  autoResize = false,
  extraWidth = 10,
  minWidth = 50,
  style,
  value,
  placeholder,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const getWidthString = useCallback(
    (span: HTMLSpanElement) => {
      /**
       * The extra width can prevent a weird behavior where the
       * text in the input is going left and right on resize. The
       * default value is an arbituary number. It happens most of the
       * time when there is border around the input.
       */
      const w = Math.max(minWidth, span.offsetWidth + extraWidth);

      return `${w}px`;
    },
    [minWidth, extraWidth],
  );

  useEffect(() => {
    if (!autoResize) {
      return;
    }

    const span = createDummySpanElement(value || placeholder || '', style || {});
    const input = inputRef.current!;

    span.appendToDocument();

    input.style.width = getWidthString(span.element);

    span.removeFromDocument();
  }, [value, autoResize, placeholder, style, getWidthString]);

  return <input {...props} ref={inputRef} value={value} style={style} placeholder={placeholder} />;
};

export default UInput;
