import React from 'react';
import m from 'mithril';

export type MithrilCreatorProps = { mithril: any };

/**
 * Use to inject mithril component inside React
 *
 * Props:
 *  - mithril: The mithril component
 *
 * This component has limits. We attempted to use it inside a mithril page, inside a react modal :
 * mithril page -> react.creator(MModal) -> SMithrilCreator(mithril component)
 *
 * It didn't work for the reasons below :
 * Because of the m.mount in SMithrilCreator, mithril will remount everything
 * (In the mithril implementation of mount, the refresh policy is all instead of diff)
 *
 * Therefore, the mithril page controller was called again, reinitializing the page and closing the modal
 */
export class SMithrilCreator extends React.Component<MithrilCreatorProps> {
  ref: any;

  mithrilInstance: any;

  constructor(props: MithrilCreatorProps) {
    super(props);

    this.ref = React.createRef<any>();
    this.mithrilInstance = m;
  }

  componentDidMount() {
    this.mountMithril();
  }

  componentWillUnmount() {
    this.mithrilInstance.diffMount(this.ref.current, null);
  }

  mountMithril = () => {
    const { mithril } = this.props;

    return this.mithrilInstance.diffMount(this.ref.current, mithril);
  };

  render() {
    return <div ref={this.ref} />;
  }
}

export default SMithrilCreator;
