// @flow

import { COLORS, LEVELS } from 'Components/foundation';

export default {
  grid: {
    backgroundColor: 'inherit',
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(167px, 1fr))',
    padding: '32px 32px 0 32px',
    transition: 'margin-top 0.2s',
    position: 'relative',
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  knowledgeItemWrapper: {
    height: '220px',
    position: 'relative',
    cursor: 'pointer',
  },
  knowledgeItem: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    height: '100%',
    width: '100%',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
    position: 'relative',
  },
  newContentCard: {
    height: '100%',
    width: '100%',
  },
  pageLoader: {
    height: '100%',
    position: 'relative',
  },
};
