// @flow

/**
 * Display a icon with label with or without content
 *
 * Props:
 *  - style: For overriding the component's style
 *  - color: Color of the text and background
 *  - label: Optional text displayed at the component right
 *  - content: Text displayed by defaulte
 *  - icon: Icon displayed with the label
 *  - onClick: Callback call when label and icon provided
 *
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import styles from './SCompactCallout.style';


type Props = {|
  style?: Object,
  color: string,
  label: string,
  content: string,
  icon: string,
  onClick: MouseEventHandler,
|};

type State = {|
  isHover: boolean,
|};

export class SCompactCallout extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    label: '',
    icon: '',
    onClick: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  render() {
    const { style, content, color } = this.props;
    const { isHover } = this.state;

    const hoveredOpacity = 0.12;
    const regularOpacity = 0.06;
    const backgroundOpacity = isHover ? hoveredOpacity : regularOpacity;
    const styleContent = { color };
    const styleWrapper = isHover ? styles.wrapperHovered : {};

    const styleOverlay = {
      backgroundColor: color,
      opacity: backgroundOpacity,
      color: color,
    };

    return (
      <div
        style={{ ...styles.wrapper, ...styleWrapper, ...style }}
        onClick={this.handleClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
      >
        <span style={{ ...styles.content, ...styleContent }}>
          { content }
        </span>
        {this.renderContent()}
        <div style={{ ...styles.overlay, ...styleOverlay }} />
      </div>
    );
  }

  renderContent = () => {
    const { label, icon, color } = this.props;
    const styleLabel = { color };

    if (!(label && icon))
      return null;

    return (
      <div style={styles.assets}>
        <span style={{ ...styles.label, ...styleLabel }}>
          { label }
        </span>
        <UIcon name={icon} color={color} size={10} />
      </div>
    );
  };

  handleClick = (e: MouseEvent) => {
    const { label, icon, onClick } = this.props;

    if (!(label && icon))
      return;

    onClick(e);
  };

  handleHover = () => {
    const { label, icon } = this.props;

    if (!(label && icon))
      return;

    this.setState({ isHover: true });
  };

  handleLeave = () => {
    const { label, icon } = this.props;

    if (!(label && icon))
      return;

    this.setState({ isHover: false });
  };
}

export default SCompactCallout;
