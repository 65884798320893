import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { UseFormSetValue, UseFormWatch, useFormContext } from 'react-hook-form';

import KnowledgeThematicsModal from 'MithrilReact/KnowledgeThematicsModal/KnowledgeThematicsModal';
import Enum from 'Models/Enum';

import { useTypedSelector } from 'Libs/redux/utils';
import { getCurrentBOLanguageOrDefault } from 'Services/language/languageService';
import { translateApiErrors } from 'Services/api-error/api-error.service';
import { ContentValidationError } from 'Store/entities/content/content.types';
import { getAllThematics } from 'Store/entities/configuration/thematic/thematic.selectors';
import { translateThematicsName } from 'Store/entities/configuration/thematic/thematic.service';

import SInput from 'Components/structural/SInput/SInput';
import STextArea from 'Components/structural/STextArea/STextArea';
import STreeSelect from 'Components/structural/STreeSelect/STreeSelect';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker from 'Components/unit/UMediaPicker/UMediaPicker';
import { UToggle } from 'Components/unit/UToggle/UToggle';
import { UFieldError } from 'Components/unit/UFieldError/UFieldError';

import { BaseContentFormValues } from './ContentEdition';

import styles from './Panel.style';

type PanelProps = {
  language: keyof typeof Enum.Languages;
};

export const Panel = ({ language }: PanelProps) => {
  const { watch, setValue, formState } = useFormContext<BaseContentFormValues>();

  const { root: errors } = formState.errors as unknown as { root: ContentValidationError };

  const userLanguage = getCurrentBOLanguageOrDefault();
  const [showKnowMore, setShowKnowMore] = useState(false);
  const [displayThematicModal, setDisplayThematicModal] = useState(false);
  const thematics = useTypedSelector(getAllThematics);

  const formattedThematicsForTreeSelect = useMemo(
    () =>
      translateThematicsName(userLanguage.id, thematics).map((thematic) => ({
        id: thematic.id,
        label: thematic.translatedLabel,
        parentId: thematic.ancestorId,
      })),
    [thematics, userLanguage],
  );

  const selectedThematic = useMemo(
    () => formattedThematicsForTreeSelect.find((thematic) => thematic.id === watch('thematic')),
    [watch('thematic')],
  );

  return (
    <div style={styles.panelContainer}>
      <h2 style={styles.title}>{t('contents:edition.content_settings')}</h2>
      <SInput
        value={watch('name')}
        onChange={(value) => {
          setValue('name', value);
        }}
        label={t('contents:edition.label')}
        required
        type="large"
        maxLength={40}
        errorMessage={translateApiErrors(errors?.metadata?.name)}
      />
      <div style={styles.firstFormContainer}>
        <div style={styles.imageContainer}>
          <ULabel required style={{ marginBottom: '8px' }}>
            {t('contents:edition.image')}
          </ULabel>
          <div>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('image')?.url || ''}
              media={{
                mediaType: 'image',
                onChange: (media) => {
                  setValue('image', media);
                },
              }}
            />
            <UFieldError errorMessage={translateApiErrors(errors?.metadata?.media)} />
          </div>
        </div>
        <div style={styles.idSection}>
          <SInput
            value={watch('identifier')}
            label={t('contents:edition.id')}
            required
            type="large"
            onChange={(value) => {
              setValue('identifier', value);
            }}
            maxLength={5}
            style={styles.idField}
            errorMessage={translateApiErrors(errors?.metadata?.identifier)}
          />
          <div style={styles.thematicSection}>
            <ULabel required style={styles.thematicLabel}>
              {t('contents:edition.thematics')}
            </ULabel>
            <div>
              <STreeSelect
                selectedItem={selectedThematic}
                items={formattedThematicsForTreeSelect}
                type="large"
                style={styles.thematicSelector}
                onSelect={(thematicId) => {
                  setValue('thematic', thematicId);
                }}
              />
              <UFieldError errorMessage={translateApiErrors(errors?.metadata?.thematic)} />
            </div>
          </div>
        </div>
      </div>
      <UToggle
        toggled={showKnowMore}
        onChange={() => {
          setShowKnowMore((prev) => !prev);
        }}
        outsideLabel={showKnowMore ? t('contents:edition.hide_know_more') : t('contents:edition.display_know_more')}
        outsideLabelIcon="info"
      />

      {showKnowMore && (
        <STextArea
          value={watch('knowMore')}
          onChange={(value: string) => {
            setValue('knowMore', value);
          }}
          label={t('contents:edition.know_more')}
          placeholder="Text"
          errorMessage={translateApiErrors(errors?.metadata?.knowMore)}
        />
      )}
      <KnowledgeThematicsModal
        visible={displayThematicModal}
        onClose={() => {
          setDisplayThematicModal(false);
        }}
        onConfirm={() => {}}
      />
    </div>
  );
};
