import { handle } from 'redux-pack';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import Enum from 'Models/Enum';

import type { Action } from 'Libs/redux/types';
import createAmbassador from './models/Ambassador';
import type { AmbassadorType } from './models/Ambassador';

export type AmbassadorState = DeepReadonly<{
  globalCode: string;
  originalGlobalCode: string;
  ambassadors: ReadonlyArray<AmbassadorType>;
  ambassadorCount: number;
  globalCodeDoesNotExist: boolean;
  isGetAllLoading: boolean;
  isRemoveLoading: boolean;
  isGetGlobalCodeLoading: boolean;
  isSetGlobalCodeLoading: boolean;
  isSetGlobalCodeFailure: boolean;
}>;

export const defaultState: AmbassadorState = {
  globalCode: '',
  originalGlobalCode: '',
  ambassadors: [],
  ambassadorCount: 0,
  globalCodeDoesNotExist: false,
  isGetAllLoading: false,
  isRemoveLoading: false,
  isGetGlobalCodeLoading: false,
  isSetGlobalCodeLoading: false,
  isSetGlobalCodeFailure: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: AmbassadorState = defaultState, action: Action): AmbassadorState => {
  switch (action.type) {
    case 'AMBASSADOR_EDIT_GLOBAL_CODE': {
      return mergeDeep(state, { globalCode: action.globalCode, isSetGlobalCodeFailure: false });
    }

    case 'AMBASSADOR_GET_ALL': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isGetAllLoading: true }),
        success: (prevState) => {
          const ambassadors = action.payload.ambassadors.map(createAmbassador);
          const ambassadorCount = action.payload.count;

          return mergeDeep(prevState, {
            ambassadors: action.meta.filter.from > 0 ? prevState.ambassadors.concat(ambassadors) : ambassadors,
            ambassadorCount,
          });
        },
        finish: (prevState) => mergeDeep(prevState, { isGetAllLoading: false }),
      });
    }

    case 'AMBASSADOR_REMOVE': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isRemoveLoading: true }),
        success: (prevState) => {
          const { id } = action.meta;

          return mergeDeep(prevState, {
            ambassadorCount: prevState.ambassadorCount - 1,
            ambassadors: prevState.ambassadors.filter((ambassador) => ambassador.id !== id),
          });
        },
        finish: (prevState) => mergeDeep(prevState, { isRemoveLoading: false }),
      });
    }

    case 'AMBASSADOR_GET_GLOBAL_CODE': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isGetGlobalCodeLoading: true }),
        success: (prevState) => {
          const { payload } = action;

          return mergeDeep(prevState, {
            globalCode: payload.code,
            originalGlobalCode: payload.code,
            globalCodeDoesNotExist: false,
          });
        },
        failure: (prevState) => {
          if (action.payload.error?.status === Enum.HTTP_CODE.NOT_FOUND) {
            return mergeDeep(prevState, { globalCodeDoesNotExist: true });
          }

          return prevState;
        },
        finish: (prevState) => mergeDeep(prevState, { isGetGlobalCodeLoading: false }),
      });
    }

    case 'AMBASSADOR_SET_GLOBAL_CODE': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { isSetGlobalCodeLoading: true, isSetGlobalCodeFailure: false }),
        success: (prevState) => {
          const { payload } = action;

          return mergeDeep(prevState, {
            originalGlobalCode: payload.code,
          });
        },
        failure: (prevState) =>
          mergeDeep(prevState, {
            isSetGlobalCodeFailure: true,
          }),
        finish: (prevState) => mergeDeep(prevState, { isSetGlobalCodeLoading: false }),
      });
    }

    default:
      return state;
  }
};
