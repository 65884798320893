import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    boxSizing: 'content-box',
  },
  withBorder: {
    backgroundColor: COLORS.GREY_DARK.DEFAULT,
    borderRadius: '50%',
    border: `1px solid ${COLORS.GREY_DARK.DEFAULT}`,
  },
});

export default styleSheet;
