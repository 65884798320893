import React, { useState, useCallback, useEffect } from 'react';
import papa from 'papaparse';
import Promise from 'bluebird';
import xlsx from 'xlsx';
import { t } from 'i18next';

import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SInput from 'Components/structural/SInput/SInput';
import { useTypedDispatch, useTypedSelector } from 'Libs/redux/utils';
import regex from 'Libs/regex';

import { AudienceBackButton } from '../AudienceBackButton/AudienceBackButton';
import { AudienceTable } from '../AudienceTable/AudienceTable';

import { actions, useModuleSelector, getDefaultActivity } from '../../../redux';
import type { AlertScope, AlertParams, AlertValidationField } from '../../../redux/models/Alert';
import { checkActivityIsValid } from '../../../validate';

import styles from './AudienceEmailList.style';

function handleXLSXFile(file: File): Promise<string> {
  const reader = new FileReader();

  return new Promise(function handlePromise(resolve, reject) {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target || !e.target.result) {
        reject(new Error('Event target or result is null'));
        return;
      }
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = xlsx.read(data, { type: 'array', WTF: true });

      if (!(workbook as any).Strings) {
        reject(new Error('Workbook has no strings'));
        return;
      }
      const cells = (workbook as any).Strings.map((item: any) => item.t);
      resolve(cells.join());
    };

    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.readAsArrayBuffer(file);
  });
}

function handleCSVFile(file: File): Promise<string> {
  let cells: any[] = [];

  return new Promise<string>((resolve, reject) => {
    const onComplete = (results: papa.ParseResult<any>) => {
      if (results.errors.length) {
        reject(new Error('CSV Parse error'));
        return;
      }

      results.data.forEach((item: any) => {
        cells = cells.concat(item);
      });

      resolve(cells.join());
    };

    papa.parse(file, { complete: onComplete });
  });
}

function handleTXTFile(file: File): Promise<string> {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      const result = reader.result;
      if (typeof result !== 'string') {
        reject();
        return;
      }

      try {
        decodeURIComponent(result);
      } catch {
        reject();
        return;
      }

      resolve(result);
    };

    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.readAsText(file);
  });
}

const handleButtonClick = () => {};

const ADD_INPUT_ICON = {
  icon: 'plus',
  ghost: false,
  type: 'accentuated',
} as const;

export const AudienceEmailList = () => {
  const dispatch = useTypedDispatch();
  const newEmailList = useModuleSelector((state) => state.audience.EMAIL_LIST.emailList);
  const originalEmailList = useModuleSelector((state) => state.originalAudience.EMAIL_LIST.emailList);

  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  // INFO this code is duplicated from ActionBar
  // TODO It would be nice to extract the logic from there
  // and export a way of combining a validate+save+update-alerts function
  // ---------- Start of duplicated code
  const setAlert = useCallback(
    (scope: AlertScope, params?: AlertParams) => dispatch(actions.setAlert(scope, params)),
    [dispatch],
  );
  const removeAlert = useCallback((scope: AlertScope) => dispatch(actions.removeAlert(scope)), [dispatch]);
  const updateValidationErrorAlert = useCallback(
    (fields: ReadonlyArray<AlertValidationField>) => {
      if (fields.length > 0) setAlert('validationError', { fields });
      else removeAlert('validationError');
    },
    [setAlert, removeAlert],
  );

  const defaultActivity = useTypedSelector(getDefaultActivity);
  const targetModeId = useModuleSelector((state) => state.audience.mode);
  const validateAndSave = useCallback(() => {
    const fields = checkActivityIsValid(defaultActivity, targetModeId);

    updateValidationErrorAlert(fields);

    if (fields.length === 0) dispatch(actions.save());
  }, [defaultActivity, targetModeId, dispatch, updateValidationErrorAlert]);

  // ---------- End of duplicated code

  useEffect(() => {
    if (originalEmailList.length !== newEmailList.length) {
      validateAndSave();
      setFileLoading(false);
    }
  }, [validateAndSave, originalEmailList, newEmailList]);

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);

    setErrorMessage('');
  }, []);

  const handleSubmit = useCallback(
    (email) => {
      if (!email.trim()) {
        setErrorMessage(t('activities:audience.email_list.empty_email'));

        return;
      }

      if (!regex.email.test(email)) {
        setErrorMessage(t('activities:audience.email_list.invalid_email'));

        return;
      }

      setValue('');
      setErrorMessage('');

      dispatch(actions.addEmailsToAudience([email]));
    },
    [dispatch],
  );

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage('');

      const files = e.target.files;
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];

      setFileLoading(true);

      return Promise.resolve()
        .then(function handleFile() {
          if (file.name.endsWith('.xlsx')) return handleXLSXFile(file);
          else if (file.name.endsWith('.csv')) return handleCSVFile(file);
          else if (file.name.endsWith('.txt')) return handleTXTFile(file);

          throw new Error();
        })
        .then(function extractEmails(data) {
          const emails = data.match(regex.extractEmail);

          if (emails !== null) {
            dispatch(actions.addEmailsToAudience(emails));
          }
        })
        .catch(() => {
          setErrorMessage(t('activities:audience.email_list.invalid_file'));
          setFileLoading(false);
        });
    },
    [dispatch],
  );
  return (
    <div style={styles.wrapper}>
      <AudienceBackButton />
      <div style={styles.infoWrapper}>
        <div style={styles.title}>{t('activities:audience.email_list.title')}</div>
        <div style={styles.subtitle}>{t('activities:audience.email_list.subtitle')}</div>
        <div style={styles.email}>
          <SInput
            type="small"
            placeholder={t('activities:audience.email_list.email_placeholder')}
            icon={ADD_INPUT_ICON}
            value={value}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
            onIconClick={handleSubmit}
            onChange={handleChange}
            style={styles.emailInput}
          />
          <label>
            <UTextLink
              text={t('activities:audience.email_list.upload_list')}
              onClick={handleButtonClick}
              loading={fileLoading}
              marginLeft={0}
            />
            <input style={styles.fileHandler} type="file" accept=".csv,.xlsx,.txt" onChange={onFileChange} />
          </label>
        </div>
        <div style={styles.audienceTable}>
          <AudienceTable />
        </div>
      </div>
    </div>
  );
};
