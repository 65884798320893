// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    paddingTop: 16,
    paddingBottom: 8,
    backgroundColor: 'transparent',
  },
  inputFieldContainer: {
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    height: 32,
    ...TYPOGRAPHY.BODY4,
  },
  inputField: {
    display: 'flex',
    flex: 1,
    fontWeight: 400,
    padding: '0px 16px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
};
