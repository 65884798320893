import React, { useMemo } from 'react';

import SLegacyContentCard from 'Components/structural/SContentCard/legacy/SLegacyContentCard';
import SDailySessionContainer from 'Components/structural/SDailySessionContainer/SDailySessionContainer';
import { useTypedSelector } from 'Libs/redux/utils';

import { getDateForDailySerieDay } from 'Pages/Activity/date.utils';
import { ContentType } from 'Pages/Activity/redux/models/Content';

import { getSelectedActivity } from 'Pages/Activity/redux/selectors';
import styles from './DailySession.style';
import { getContentStatusAttributes } from 'Pages/Activity/Scheduling/utils/content.utils';

type RemoveContentFromDailySerieDayArgs = { contentKey: number; dayKey: number };

export type DailySessionProps = {
  index: number;
  dayKey: number;
  handleAddClickFactory: (key: number) => () => void;
  handleDeleteClickFactory: (key: number) => () => void;
  draggedContentToDayIndex: number;
  setContentToTranslate: (content: ContentType) => void;
  removeContentFromDailySerieDay: ({ contentKey, dayKey }: RemoveContentFromDailySerieDayArgs) => void;
};

export const DailySession = ({
  index,
  dayKey,
  handleAddClickFactory,
  handleDeleteClickFactory,
  draggedContentToDayIndex,
  setContentToTranslate,
  removeContentFromDailySerieDay,
}: DailySessionProps) => {
  const selectedActivity = useTypedSelector(getSelectedActivity);
  const isLocked = useTypedSelector((state) => state.pages.activity.isLocked);

  const dailySerieContents = useMemo(() => {
    const { days } = selectedActivity.dailySerie;

    const day = days.find((x) => x.key === dayKey);

    return day?.dailySerieContents;
  }, [selectedActivity, dayKey]);

  const { dailySerie, startDate, isDefault } = selectedActivity;
  const { days } = dailySerie;

  const isScheduleEmpty = days.length === 1 && days[0].dailySerieContents?.length === 0;
  const dailySerieDayDisabled = useMemo(
    () => dailySerie.dailySerieDayDisabled.filter((x) => x.date !== null),
    [dailySerie],
  );
  const ds = { ...dailySerie, dailySerieDayDisabled };
  const availabilityDate = getDateForDailySerieDay(ds, startDate, index + 1).toString();
  const isDraggedOver = draggedContentToDayIndex === index;
  const isNewContentCardDisabled = !isDefault;
  const multilingualContentMap = useTypedSelector((state) => state.pages.activity.multilingualContentsMap);

  return (
    <SDailySessionContainer
      key={`manual-${dayKey}`}
      sessionNumber={index + 1}
      contentCount={dailySerieContents?.length || 0}
      startDate={availabilityDate}
      locked={isLocked || isScheduleEmpty}
      isScheduleEmpty={isScheduleEmpty}
      draggingOver={isDraggedOver}
      disabledAdd={isNewContentCardDisabled}
      disabledDelete={!isDefault}
      onAdd={handleAddClickFactory(dayKey)}
      onDelete={handleDeleteClickFactory(dayKey)}
      style={styles.dailySessionContainer}
    >
      {dailySerieContents?.map(({ key: contentKey, content }) => {
        const {
          options,
          isDisabled,
          indicatorText,
          knowledgeCustomId,
          knowledgeId,
          aContentIsDragged,
          imageUrl,
          title,
          types,
          locale,
          statusIndicatorType,
          statusIndicatorTooltip,
        } = getContentStatusAttributes({
          selectedActivity,
          content,
          contentKey,
          dayKey,
          setContentToTranslate,
          removeContentFromDailySerieDay,
          draggedContentToDayIndex,
          multilingualContentMap,
        });

        return (
          <SLegacyContentCard
            key={content.id}
            id={`#${knowledgeCustomId || knowledgeId}`}
            canShowOverlay={!aContentIsDragged}
            canBeDragged={!isLocked && isDefault}
            imageUrl={imageUrl}
            title={title}
            types={types}
            locale={locale}
            indicatorText={indicatorText}
            locked={isLocked}
            disabled={isDisabled}
            style={styles.dailySessionContentCard}
            selectable={false}
            previewable={false}
            displayOption={!isLocked}
            options={options}
            statusIndicatorType={statusIndicatorType}
            statusIndicatorTooltip={statusIndicatorTooltip}
          />
        );
      })}
    </SDailySessionContainer>
  );
};
