import React from 'react';

import styles, { tableMessageColor } from 'Components/structural/STable/STable.style';
import { Message } from 'Components/structural/STable/types';

type ErrorProps = {
  tableMessage?: {
    message: string;
    type: Message['type'];
  };
};

function Error({ tableMessage }: ErrorProps) {
  if (!tableMessage || !tableMessage.message) return null;

  const messageStyle = {
    ...styles.tableMessage,
    ...tableMessageColor[tableMessage.type],
  };

  return <p style={messageStyle}>{tableMessage.message}</p>;
}
export default Error;
