import type { OCAlertValidationField, OCAlertValidationParams } from './OrderingCards';

import { OC_FIELD_MESSAGES } from './OrderingCards/OCAlert';

export type AlertScope =
  | 'validationError'

  // server
  | 'fetchError'
  | 'fetchKnowledgeError'
  | 'saveError'
  | 'archiveError'
  | 'archiveSuccess'
  | 'changeStatusSuccess'
  | 'changeStatusError';

export type AlertValidationField =
  // core content
  | 'noQuestion'
  | 'noExplanation'
  | 'noMatchingGameplay'

  // Ordering cards
  | OCAlertValidationField;

export type AlertValidationParams = OCAlertValidationParams;

export type AlertParams = Readonly<{
  // validation
  fields?: ReadonlyArray<AlertValidationField>;

  // gameplay validation params
  validationParams?: AlertValidationParams;

  // server
  message?: string;
  statusId?: number;
  archived?: boolean;
}>;

export type ContentValidationResult = Readonly<{
  fields: ReadonlyArray<AlertValidationField>;
  params?: AlertValidationParams;
}>;

export type AlertType = Readonly<{
  scope: AlertScope;
  params: AlertParams;
}>;

export const FIELD_MESSAGES = {
  noQuestion: '- You must add a question.',
  noExplanation: '- You must add an explanation text.',
  noMatchingGameplay: '- There is no gameplay to validate.',
  ...OC_FIELD_MESSAGES,
};
