import React, { useCallback, useState } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { WeekDayType } from 'Pages/Activity/redux/models/WeekDay';

import SDaysSelector from 'Components/structural/SDaysSelector/SDaysSelector';
import UButton from 'Components/unit/UButton/UButton';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UDatePicker from 'Components/unit/UDatePicker/UDatePicker';
import UToggle from 'Components/unit/UToggle/UToggle';

import { actions, useModuleSelector } from '../../../redux';
import styles from '../panel.style';

type EnabledDays = { [key: number]: boolean };

const weekDayArrayToMapping = (array: ReadonlyArray<WeekDayType>) =>
  array.reduce<EnabledDays>((acc, day) => ({ ...acc, [day.id]: true }), {});

const weekDayMappingToArray = (enabledDays: EnabledDays) =>
  Object.keys(enabledDays)
    .map((id) => parseInt(id, 10))
    .filter((id) => enabledDays[id])
    .map((id) => ({ id }));

export const DaysEnabledForm = () => {
  const weekDays: ReadonlyArray<WeekDayType> = useModuleSelector((state) => state.activities[0].dailySerie.weekDays);

  const dispatch = useTypedDispatch();
  const onChange = useCallback(
    (days) => {
      // disallow setting no week day
      if (!Object.values(days).find((enabled) => enabled)) {
        return;
      }

      dispatch(actions.setWeekDays(weekDayMappingToArray(days)));
    },
    [dispatch],
  );

  const disabledDays = useModuleSelector((state) => state.activities[0].dailySerie.dailySerieDayDisabled);

  const addUnsetDisabledDay = useCallback(() => {
    dispatch(actions.addDisabledDay(null));
  }, [dispatch]);

  const editDisabledDay = useCallback(
    (key: number) => (date: Date) => {
      dispatch(actions.setDisabledDay(key, date.toISOString()));
    },
    [dispatch],
  );

  const removeDisabledDay = useCallback(
    (key) => () => {
      dispatch(actions.removeDisabledDay(key));
    },
    [dispatch],
  );

  const [toggleActive, setToggleActive] = useState(disabledDays.length > 0);
  const onToggleChange = useCallback(
    (active) => {
      setToggleActive(active);

      if (active && disabledDays.length === 0) {
        addUnsetDisabledDay();
      }

      if (!active) {
        disabledDays.forEach(({ key }) => removeDisabledDay(key)());
      }
    },
    [addUnsetDisabledDay, removeDisabledDay, disabledDays],
  );

  return (
    <div style={styles.box}>
      <h4 style={styles.title}>{t('activities:scheduling.side_panel.enabled_days')}</h4>

      <div style={{ ...styles.element, ...styles.daySelector }}>
        <SDaysSelector selectedDays={weekDayArrayToMapping(weekDays)} onChange={onChange} />
      </div>

      <div style={{ ...styles.element, display: 'flex', justifyContent: 'space-between' }}>
        {t('activities:scheduling.side_panel.disable_days')}
        <UToggle toggled={toggleActive} onChange={onToggleChange} />
      </div>

      {disabledDays.length > 0 ? (
        <div style={styles.element}>
          {disabledDays.map(({ key, date }) => (
            <div key={key} style={styles.disabledDayContainer}>
              <div style={styles.disabledDayDatePicker}>
                <UDatePicker date={date === null ? null : new Date(date)} onClick={editDisabledDay(key)} />
              </div>
              <UIconButton icon="close-light" size="S" onClick={removeDisabledDay(key)} ghost />
            </div>
          ))}
        </div>
      ) : null}

      {toggleActive ? (
        <div style={styles.element}>
          <UButton text={t('activities:scheduling.side_panel.add_day')} onClick={addUnsetDisabledDay} />
        </div>
      ) : null}
    </div>
  );
};

export default DaysEnabledForm;
