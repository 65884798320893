/* eslint-disable complexity */
import m from 'm';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import reactCreator from 'components/react.creator';
import Enum from 'models/Enum';
import App from 'models/App';

const component = {};

component.controller = function controller() {
  const self = this;

  self.is2FAActivated = App.configurations()
    .get(Enum.configurations.BACK_OFFICE_2FA_ACTIVATED).value();

  self.is2FAActivatedProp = m.prop(self.is2FAActivated);

};

component.view = function view(c) {
  return m('.contributors-page', [
    m('.contributors-page__menu', [
      m(menu),
      m(gaiaMenu),
    ]),
    m(reactCreator, {
      component: 'Contributor',
      style    : { height: '100%' },
      props    : {
        is2FAActivated: c.is2FAActivatedProp,
      },
    }),
  ]);
};

export default component;
