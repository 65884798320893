/*
 * dimension      : the dimension to display
 * depth          : the nb of parent the dimension have
 * ancestor       : the item selected in the previous dimension
 * selected       : the item selected in the dimension
 * parentName     : Parent name
 * moveDimensions : function that order the dimensions
 * orderDimensions: function that order the dimensions
 * isLastChild    : is the last in his brotherhood
 * collection     : the collection in which it is
 */

import m from 'm';
import Promise from 'bluebird';
import App from 'models/App';
import segmentationItem from 'components/segmentation.item';
import segmentationNewItem from 'components/segmentation.new.item';
import inputSelect from 'components/input.select';
import toggleButton from 'components/toggle.button';
import popup from 'services/popupService';
import SegmentationType from 'models/masterData/SegmentationType';
import SegmentationDimension from 'models/SegmentationDimension';
import Enum from 'models/Enum';
import User from 'services/userService';

import { COLORS } from 'Components/foundation';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.optionOpened = m.prop(false);
  self.visualOptionOpened = m.prop(false);
  self.isRootUser = m.prop(false);

  self.segmentationTypes = m.prop(
    App
      .segmentationTypes()
      .clone()
      .filter((segmentationType) => segmentationType.id() !== Enum.dimensionTypes.LANGUAGE)
  );
  self.segmentationTypes().push(new SegmentationType({ id: null, label: 'Default' }));
  self.searchInput = m.prop('');
  self.focused = m.prop(false);
  self.isRootUser(User.meSync().isMasterAdmin());
  self.defaultColorValue = m.prop(Object.values(COLORS.SEGMENT));
  self.removeAncestor = function removeAncestor() {
    if (args.ancestor && args.ancestor.item)
      args.ancestor.item = undefined;
  };

  self.saveModification = function saveModification(_args) {
    _args.dimension.save()
      .then(function handleResult(res) {
        res.children(_args.dimension.children());
        res.groups(_args.dimension.groups());
        _args.collection().replaceAt(_args.dimension._i, res);
        self.focused(false);
        m.redraw();
      })
      .catch(function handleError(err) {
        console.log('an error append while saving the dimension', _args.dimension.id(), err);
      });
  };

  self.addSubDimension = function addSubDimension(_args) {
    self.optionOpened(false);
    var newSeg = new SegmentationDimension({
      parentId: _args.dimension.id(),
      order   : _args.dimension.order() + _args.dimension.children().length + 1,
    });

    function addToParent(segmentation) {
      return args.dimension.children().push(segmentation);
    }

    return newSeg.save()
      .then(addToParent)
      .then(_args.orderDimensions)
      .then(m.redraw);
  };

  self.deleteDimension = function deleteDimension(_args) {
    self.optionOpened();
    var promise = _args.dimension.id()
      ? _args.dimension.destroy()
      : Promise.resolve();

    return promise
      .then(function sliceItemFromTheList() {
        _args.collection().removeAt(_args.dimension._i);

        return _args
          .orderDimensions()
          .then(m.redraw);
      });
  };

  self.changeColor = function changeColor(value) {
    args.dimension.color(value);

    return args.dimension
      .save()
      .then(function handleRes(res) {
        m.redraw();
      });
  };

  self.onVisualOptionClick = function onVisualOptionClick() {
    if (!self.isRootUser())
      return;

    self.visualOptionOpened(!self.visualOptionOpened());
  };

  self.onOptionClick = function onOptionClick() {
    if (!self.isRootUser())
      return;

    self.optionOpened(!self.optionOpened());
  };

  self.configVisualOption = function configVisualOption(elem, isInit) {
    if (isInit)
      return;

    document.addEventListener('mouseup', mouseupVisualOption);
  };

  self.configOption = function configOption(elem, isInit) {
    if (isInit)
      return;

    document.addEventListener('mouseup', mouseupOption);
  };

  self.onunload = function onunload() {
    document.removeEventListener('mouseup', mouseupOption);
    document.removeEventListener('mouseup', mouseupVisualOption);
  };

  self.saveType = function saveType(_args) {
    args.dimension.typeId(args.dimension.type().id() || 0);
    self.saveModification(_args);
  };

  self.defaultColor = function defaultColor(position) {
    const colorIndex = (position - 1) % self.defaultColorValue().length;
    const color = self.defaultColorValue()[colorIndex];

    args.dimension.color(color);

    return color;
  };

  function mouseupVisualOption(e) {
    var selector = '#dimensionVisualOption' + args.key;
    var container = document.querySelector(selector);

    if (!container.contains(e.target))
      self.visualOptionOpened(false);
  }

  function mouseupOption(e) {
    var selector = '#dimensionOption' + args.key;
    var container = document.querySelector(selector);

    if (!container.contains(e.target))
      self.optionOpened(false);
  }

  self.availableSegmentationGroups = App.AvailableSegmentationGroups()
    .find({ typeId: args.dimension.type().id() })
    .filter(function removeAlreadyCreated(item) {
      return !args.dimension.groups().first({ availableGroupId: item.id() });
    });
};

component.view = function view(c, args) {
  return m('.segmentation-dimension-container', {
    style: {
      marginTop: `${computeHeight(args.depth)}px`,
    },
    id: 'segmentationDimension' + args.key,
  }, [
    m('.segmentation-dimension__order-container', [
      m('.segmentation-dimension__order-container__down', {
        onclick: args.moveDimension.bind(null, args.dimension, false),
        class  : args.dimension.order() === 1 ? 'segmentation-dimension__order-container__hidden' : '',
      }),
      m('.segmentation-dimension__order-container__label', args.dimension.order()),
      m('.segmentation-dimension__order-container__up', {
        onclick: args.moveDimension.bind(null, args.dimension, true),
        class  : args.isLastChild && !args.dimension.children().length
          ? 'segmentation-dimension__order-container__hidden'
          : '',
      }),
    ]),
    m('.segmentation-dimension__column', [
      m('.segmentation-dimension__column__header', {
        style: {
          'background-color': args.dimension.color() || c.defaultColor(args.dimension.order()),
        },
      }, [
        m('.segmentation-dimension__column__visual-option', {
          id    : 'dimensionVisualOption' + args.key,
          config: c.configVisualOption,
        }, [
          m('.segmentation-dimension__column__visual-option__button', {
            onclick: c.onVisualOptionClick,
          }, ' '),
          m('.segmentation-dimension__column__visual-option__menu', {
            class: c.visualOptionOpened() ? 'segmentation-dimension__column__visual-option__menu--opened' : '',
          }, [
            m('.segmentation-dimension__column__visual-option__menu__type-label', 'choose a color:'),
            m('input.segmentation-dimension__column__visual-option__menu__color-input', {
              type    : 'color',
              onchange: m.withAttr('value', c.changeColor),
              value   : args.dimension.color() || c.defaultColor(args.dimension.order()),
            }),
          ]),
        ]),
        m('input.segmentation-dimension__column__title', {
          disabled   : !c.isRootUser(),
          value      : args.dimension.getLabel().label(),
          onchange   : c.saveModification.bind(null, args),
          oninput    : m.withAttr('value', args.dimension.getLabel().label),
          onfocus    : c.focused.bind(null, true),
          onblur     : c.focused.bind(null, false),
          placeholder: 'Enter the name here',
        }),
        m('.segmentation-dimension__column__title-hint', {
          class: c.focused() ? 'segmentation-dimension__column__title-hint--visible' : '',
        }, [
          m('.segmentation-dimension__column__title-hint__label', 'Edit this title in the default language'),
        ]),
        m('.segmentation-dimension__column__option', {
          id    : 'dimensionOption' + args.key,
          config: c.configOption,
        }, [
          m('.segmentation-dimension__column__option__button', {
            onclick: c.onOptionClick,
          }, ' '),
          m('.segmentation-dimension__column__option__menu', {
            class: c.optionOpened() ? 'segmentation-dimension__column__option__menu--opened' : '',
          }, [
            args.dimension.type().id() !== Enum.dimensionTypes.LANGUAGE
              ? [
                m('.segmentation-dimension__column__option__menu__line', [
                  m('.segmentation-dimension__column__option__menu__type-label', 'choose a type:'),
                  m(inputSelect, {
                    value      : args.dimension.type,
                    data       : c.segmentationTypes(),
                    path       : 'label',
                    onselect   : c.saveType.bind(null, args),
                    placeholder: 'Default',
                  }),
                ]),
              ]
              : '',
            m('.segmentation-dimension__column__option__menu__line', [
              m('.segmentation-dimension__column__option__menu__type-label', 'Editable by player:'),
              m(toggleButton, {
                value  : args.dimension.editableByPlayer,
                onclick: c.saveModification.bind(null, args),
              }),
            ]),
            m('.segmentation-dimension-command.segmentation-dimension__column__option__menu__add-sub', {
              onclick: c.addSubDimension.bind(null, args),
              class  : args.isThereCreation() ? 'hide' : '',
            }, '+ Add a sub-dimension'),
            args.dimension.type().id() !== Enum.dimensionTypes.LANGUAGE
              ? m('.segmentation-dimension-command.segmentation-dimension__column__option__menu__delete', {
                onclick: popup.open.bind(null, {
                  type    : 'confirm',
                  subtitle: 'All items belonging to this dimension will be deleted. This action is irreversible.',
                  action  : c.deleteDimension.bind(null, args),
                  confirm : 'Delete',
                }),
              }, 'Delete dimension'): '',
          ]),
        ]),
      ]),
      m('.segmentation-dimension__column__filter', {
        class: args.ancestor && args.ancestor.item ? 'segmentation-dimension__column__filter--visible' : '',
      }, [
        m('.segmentation-dimension__column__filter__label',
          args.ancestor && args.ancestor.item ? 'Filtered for ' + args.ancestor.item.label() : ''),
        m('.segmentation-dimension__column__filter__remove', {
          onclick: c.removeAncestor,
        }, 'Remove'),
      ]),
      m('input.segmentation-dimension__column__filter__search', {
        type       : 'text',
        oninput    : m.withAttr('value', c.searchInput),
        value      : c.searchInput(),
        placeholder: 'Search',
      }),

      m('.segmentation-dimension__column__items', [
        displayItems(),
        displayNewItem(),
      ]),
    ]),
  ]);

  function displayItems() {
    var toDisplay = getItemsToDisplay();

    // console.log(args.dimension.type().behaviors().get(Enum.SEGMENTATION_BEHAVIOR.PRESET_SEGMENTATION_GROUP));

    return toDisplay.map(function displayGroup(group) {
      return m(segmentationItem, {
        dimension: args.dimension,
        groupList: args.dimension.groups(),
        group    : group,
        ancestor : args.ancestor,
        selected : args.selected,
        color    : args.dimension.color(),
        typeId   : args.dimension.type().id(),
        depth    : args.depth,
      });
    });
  }

  function displayNewItem() {
    if (!args.dimension.id())
      return '';

    return m(segmentationNewItem, {
      availableSegmentationGroups: c.availableSegmentationGroups,
      dimension                  : args.dimension,
      ancestor                   : args.ancestor,
      depth                      : args.depth,
      parentName                 : args.parentName,
      typeId                     : args.dimension.type().id(),
      color                      : args.dimension.color(),
    });
  }

  function getItemsToDisplay() {
    if (!args.ancestor || !args.ancestor.item)
      return args.dimension.groups().filter(filterLabel);

    return args.dimension.groups().filter(function filterGroupwithChildren(group) {
      return group.members().find({ parentId: args.ancestor.item.id() }).items.length;
    }).filter(filterLabel);

    function filterLabel(item) {
      return (item.label().toLowerCase().indexOf(c.searchInput().toLowerCase()) > -1);
    }
  }

  function computeHeight(depth) {
    const COLUMN_TOP_GAP = 32;

    return depth * COLUMN_TOP_GAP;
  }
};

export default component;
