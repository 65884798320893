// @flow

import React, { useCallback } from 'react';
import MModal from 'Components/modal/MModal/MModal';

import styles from './UnvalidateModal.style';

type Props = {|
  visible: boolean,
  onAction: (success: boolean) => mixed,
|};

export const UnvalidateModal = ({ visible, onAction }: Props) => {
  const onValidate = useCallback(() => onAction(true), [onAction]);
  const onDismiss = useCallback(() => onAction(false), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title="Unvalidate your content"
      labelActionButton="Unvalidate content"
      labelSecondButton="Cancel"
      actionButtonType="destructive"
    >
      <div style={styles.content}>
        {'You are about to unvalidate a content, make sure no activities '}
        {'are using this campaign. Unvalidating a content used in a '}
        {'campaign may cause discrepancies in leaderboards and analytics.'}
      </div>
    </MModal>
  );
};
