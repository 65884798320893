/**
 * Display a toast alert component
 *
 * Props:
 * - style: For overriding the component's style
 * - status: status of the toast
 * - title: set the title of the component
 * - priority: set the priority of the toast alert
 * - onClick: Function called when we click on the component's button
 */

import React, { useMemo } from 'react';
import type { FunctionComponent, CSSProperties } from 'react';

import { COLORS } from 'Components/foundation';

import UIcon from 'Components/unit/UIcon/UIcon';
import UButton from 'Components/unit/UButton/UButton';

import styles from './SToast.style';

type AlertPriority = 'low' | 'medium' | 'high';

type Status = 'error' | 'success' | 'informational';

export type SToastProps = {
  style?: CSSProperties;
  status: Status;
  priority: AlertPriority;
  title: string;
  onClick?: () => void;
};

enum StatusEnum {
  error = 0,
  success = 1,
  informational = 2,
}

enum PriorityEnum {
  low = 10,
  medium = 20,
  high = 30,
}

const { SUCCESS, WHITE } = COLORS;
const { mediumMargin, restMargin, noMargin } = styles;

type PriorityAndStatus = {
  [combination: number]: {
    color?: string;
    style: CSSProperties;
  };
};

const stylesByPriorityAndStatus: PriorityAndStatus = {
  [StatusEnum.success + PriorityEnum.low]: { color: SUCCESS.HOVER, style: { ...restMargin } },
  [StatusEnum.success + PriorityEnum.medium]: { color: SUCCESS.DARK, style: { ...mediumMargin } },
  [StatusEnum.success + PriorityEnum.high]: { color: SUCCESS.HOVER, style: { ...restMargin } },
  [StatusEnum.error + PriorityEnum.low]: { color: WHITE.DEFAULT, style: { ...restMargin } },
  [StatusEnum.error + PriorityEnum.medium]: { color: WHITE.DEFAULT, style: { ...restMargin } },
  [StatusEnum.error + PriorityEnum.high]: { color: WHITE.DEFAULT, style: { ...restMargin } },
  [StatusEnum.informational + PriorityEnum.low]: { color: undefined, style: { ...noMargin } },
  [StatusEnum.informational + PriorityEnum.medium]: { color: undefined, style: { ...noMargin } },
  [StatusEnum.informational + PriorityEnum.high]: { color: undefined, style: { ...noMargin } },
};

const colors: { light: string; darker: string; dark: string; hover: string } = {
  light: COLORS.WHITE.DEFAULT,
  darker: COLORS.SUCCESS.DARKER,
  dark: COLORS.SUCCESS.DARK,
  hover: COLORS.SUCCESS.HOVER,
};

const secondaryColors = {
  light: COLORS.GREY_DARKER.DEFAULT,
  dark: COLORS.SUCCESS.LIGHTER,
};

export const SToast: FunctionComponent<SToastProps> = ({ style, status, title, priority, onClick }) => {
  const { color: iconColor } = stylesByPriorityAndStatus[StatusEnum[status] + PriorityEnum[priority]];
  const wrapperStyle = useMemo(() => {
    const color = status === 'success' && priority === 'medium' ? 'dark' : 'light';
    const padding = status === 'success' || status === 'error' ? styles.successPadding : styles.restPadding;

    const baseStyle = status === 'success' && priority === 'medium' ? styles.successStyle : styles.restStyle;

    return {
      ...styles.wrapper,
      ...style,
      ...padding,
      ...baseStyle,
      color: colors[color],
      backgroundColor: secondaryColors[color],
    };
  }, [style, priority, status]);

  const titleStyle = useMemo(() => {
    const minWidth = status === 'success' && priority === 'medium' ? { minWidth: '208px' } : {};

    const textColor = status === 'success' && priority === 'medium' ? 'darker' : 'light';

    const { style: styleForPrioAndStatus } = stylesByPriorityAndStatus[StatusEnum[status] + PriorityEnum[priority]];

    return {
      ...styles.title,
      ...minWidth,
      ...styleForPrioAndStatus,
      color: colors[textColor],
    };
  }, [status, priority]);

  return (
    <div style={wrapperStyle}>
      {(status === 'success' || status === 'error') && (
        <div style={styles.iconContainer}>
          <UIcon
            name={status === 'success' ? 'success-circle' : 'alert'}
            size={17}
            color={iconColor}
            style={styles.icon}
          />
        </div>
      )}
      <span style={titleStyle}>{title}</span>
      {status === 'success' && priority === 'medium' && (
        <div style={styles.closeButtonContainer}>
          <UButton style={styles.closeButton} text="Undo" onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default SToast;
