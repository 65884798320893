import { ContentStatuses, LanguageCode, Languages } from '@sparted/shared-library/business/types';
import { t } from 'i18next';

import { ContentTranslationStatus, TranslationStatus } from 'Components/structural/SFlagGroup/SFlagGroup';
import { Language } from 'Services/language/languageService';
import { ContentEntity } from 'Store/entities/content/content.types';

export const getLanguageIds = (selectedLanguageCodes: ReadonlyArray<LanguageCode>, availableLanguages: Language[]) => {
  return selectedLanguageCodes.reduce((languageIds, languageCode) => {
    const info = availableLanguages.find((language) => language.languageCode === languageCode);
    if (info) {
      return [...languageIds, info.id];
    }
    return languageIds;
  }, [] as number[]);
};

export const getContentFilterDescription = (count: number, isFiltering: boolean): string => {
  if (isFiltering) return t('contents:contents_list.filters.results_match', { count });

  return t('contents:contents_list.filters.results', { count });
};

const CONTENT_STATUS_MAPPING: Record<Required<ContentStatuses>[LanguageCode], ContentTranslationStatus> = {
  draft: 'draft',
  'waiting-validation': 'pending_validation',
  'waiting-translation': 'pending_translation',
  validated: 'ready',
};

export const formatTranslationStatuses = (statuses: ContentEntity['statuses']): TranslationStatus[] => {
  const languages = Object.entries(statuses) as [LanguageCode, Required<ContentStatuses>[LanguageCode]][];
  return languages.map(([countryCode, status]) => {
    return {
      countryCode: Languages[countryCode],
      status: CONTENT_STATUS_MAPPING[status],
      languageLabel: 'TODO',
    };
  });
};
