// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  modal: {
    width: 400,
    height: 255,
    margin: 'auto',
  },
  contentWrapper: {
    padding: '0 20px',
    ...TYPOGRAPHY.BODY3,
  },
};

