// @flow

export default {
  wrapper: {
    padding: '10px 0px',
    cursor: 'pointer',
    display: 'inline-block',
  },
  img: {
    objectFit: 'contain',
    display: 'block',
  },
};
