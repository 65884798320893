import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  modal: {
    overflow: 'visible',
  },
  content: {
    ...TYPOGRAPHY.BODY3,
  },
});
