import { fetchFactory } from 'Libs/http/utils';
import { GroupFormData } from './create-audience-group.service';

export type AudienceGroup = {
  id: number;
  name: string;
  segmentations: {
    id: number;
    label: string;
  }[];
};

export type ApiErrorResponse = {
  errorCode: string;
  message: string;
  params?: {
    groupName?: string;
  };
};

export type SaveResult = {
  error?: ApiErrorResponse;
  result?: AudienceGroup;
};

export type UpdateAudienceGroupFn = (audienceGroupId: number, formData: GroupFormData) => Promise<SaveResult>;

export const updateAudienceGroup: UpdateAudienceGroupFn = async (audienceGroupId, { name, segmentations }) => {
  const { fetchFn } = fetchFactory<AudienceGroup>({
    route: `/api/audiences/groups/${audienceGroupId}`,
    method: 'PUT',
    payload: {
      name,
      segmentationGroupItemIds: segmentations.map(({ id }) => id),
    },
  });

  try {
    const result = await fetchFn();

    return { result };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return { error: err as ApiErrorResponse };
  }
};
