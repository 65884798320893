import { AudienceGroupIllustration } from 'Components/svg/AudienceGroupIllustration';
import BreakingNewIllustration from 'Components/svg/BreakingNewIllustration';
import CampaignIllustration from 'Components/svg/CampaignIllustration';
import { CampaignRoundIllustration } from 'Components/svg/CampaignRoundIllustration';
import CircleBackgroundIllustration from 'Components/svg/CircleBackgroundIllustration';
import CourseCheckedIllustration from 'Components/svg/CourseCheckedIllustration';
import CourseErrorIllustration from 'Components/svg/CourseErrorIllustration';
import CourseIllustration from 'Components/svg/CourseIllustration';
import EmptyStateAudienceIllustration from 'Components/svg/EmptyStateAudienceIllustration';
import KnowledgeIllustration from 'Components/svg/KnowledgeIllustration';
import TrashIllustration from 'Components/svg/TrashIllustration';
import UsersIllustration from 'Components/svg/UsersIllustration';

export type Illustration = {
  svg: JSX.Element;
  viewBox: string;
};

export type IllustrationColors = 'PRIMARY' | 'GREY_DARK' | 'WARNING' | 'SUCCESS' | 'ERROR' | 'BLUE_GRAY';
export const ILLUSTRATION_COLORS = ['PRIMARY', 'GREY_DARK', 'WARNING', 'SUCCESS', 'ERROR', 'BLUE_GRAY'];

export type IllustrationSVGType = {
  color: {
    DEFAULT: string;
    MEDIUM?: string;
    DARK?: string;
    LIGHT?: string;
    LIGHTER?: string;
    HOVER?: string;
  };
  width: number | string;
  height: number | string;
};

export const ILLUSTRATIONS = {
  'audience-empty-state': EmptyStateAudienceIllustration,
  'audience-group': AudienceGroupIllustration,
  knowledge: KnowledgeIllustration,
  'course-checked': CourseCheckedIllustration,
  course: CourseIllustration,
  'course-error': CourseErrorIllustration,
  campaign: CampaignIllustration,
  'breaking-new': BreakingNewIllustration,
  'circle-background': CircleBackgroundIllustration,
  trash: TrashIllustration,
  users: UsersIllustration,
  'campaign-round': CampaignRoundIllustration,
};

export default ILLUSTRATIONS;

export type IllustrationName = keyof typeof ILLUSTRATIONS;

export const ILLUSTRATION_NAMES = Object.keys(ILLUSTRATIONS) as IllustrationName[];
