import Model from 'models/Model';

var ActivityType = {
  endpoint  : 'api/ActivityTypes',
  attributes: {
    label: {
      type: 'string',
    },
    enabled: {
      type: 'boolean',
    },
  },
};

export default Model.register('ActivityType', ActivityType);
