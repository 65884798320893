import m from 'm';
import Promise from 'bluebird';
import User from 'services/userService';
import popup from 'services/popupService';
import Enum from 'models/Enum';
import App from 'models/App';
import Model from 'models/Model';

var component = {};

/*
 * groupList: all the groups in the dimension
 * group    : the group to display
 * ancestor : the item selected in the previous dimension
 * color    : the color of the dimension
 * selected : selected item in dimension
 * typeId   : type of the dimension
 * depth    : depth of the dimension
 */
component.controller = function controller(args) {
  var self = this;

  self.editionMode = m.prop(false);
  self.oldSegmentationValue = m.prop('');
  self.isMasterAdmin = m.prop(false);

  self.isMasterAdmin(User.meSync().isMasterAdmin());


  self.isSelected = function isSelected(item) {
    if (!item)
      return false;

    var itemToHandle = item._type === 'SegmentationGroupItem' ? item.members().at(0) : item;

    if (!itemToHandle)
      return false;

    return (args.selected && args.selected.item) ? args.selected.item.id() === itemToHandle.id() : false;
  };

  self.selectItem = function selectItem(item) {
    var itemToHandle = item._type === 'SegmentationGroupItem' ? item.members().at(0) : item;

    if (!self.isMasterAdmin())
      return;

    console.log('itemToHandle', item.label(), itemToHandle, self.isSelected(itemToHandle));

    if (self.isSelected(itemToHandle))
      args.selected.item = undefined;
    else
      args.selected.item = itemToHandle;
  };

  self.focusOnInput = function focusOnInput(elem) {
    if (!self.isMasterAdmin())
      return;

    elem.focus();
  };

  var SegmentationItem = Model('SegmentationItem');
  var SegmentationGroupItem = Model('SegmentationGroupItem');

  function updateAppVariables() {
    return SegmentationItem.getFull()
      .tap(function loadUserSegmentation(segmentationItems) {
        var playerSegmentation = User.meSync().segmentation();
        var filteredSegmentation = SegmentationGroupItem
          .filterWithUserSegmentation(segmentationItems, playerSegmentation);

        App.userSegmentationItems(filteredSegmentation);
      });
  }

  self.saveItemModification = function saveItemModification(item) {
    if (item.label().trim() === '')
      return self.displayEmptyFieldPopup(item);

    return item.save()
      .tap(updateAppVariables);
  };

  self.saveGroupItemModification = function saveGroupItemModification(groupItem) {
    if (groupItem.label().trim() === '')
      return self.displayEmptyFieldPopup(groupItem);

    return groupItem.updateLabel()
      .then(function handleResult(res) {
        groupItem.label(res.label());
        var members = groupItem.members();

        if (members.length === 1)
          members.items[0].label(res.label());
      })
      .tap(updateAppVariables)
      .catch(function handleError(err) {
        console.log('an error append while saving the item', groupItem.id(), err);
      });
  };

  self.setDefault = function setDefault(_args, e) {
    if (_args.group.isDefault())
      return Promise.resolve();
    var defaultOne = _args.dimension.groups().first({ isDefault: true });
    var promise = Promise.resolve();

    e.preventDefault();
    e.stopPropagation();

    if (!self.isMasterAdmin())
      return promise;

    promise = _args.group.setDefaultLanguage();

    return promise
      .then(function handleRes(res) {
        App.defaultLanguage(res);
        console.log('res', res.toJSON());
        // _args.group = res;
        _args.group.isDefault(true);
        defaultOne.isDefault(false);
        m.redraw();
      })
      .catch(function handleError(err) {
        const { status } = err;
        const message = err.message.error.message || err.message;
        const MISSING_TRAD_CODE = 404;

        const popupMessage = { title: 'This action is impossible', subtitle: '', content: message };

        if (status === MISSING_TRAD_CODE) {
          popupMessage.title = 'Missing label translation';
          popupMessage.subtitle = 'Changing the default language requires all labels to be translated.';
        }

        popup.open({
          type    : 'info',
          ...popupMessage,
        });
      });

    // if (defaultOne) {
    //   defaultOne.isDefault(false);
    //   promise = defaultOne.save();
    // }

    // _args.group.isDefault(true);
    // App.defaultLanguage(_args.group);
    // return promise
    //   .then(_args.group.save.bind(_args.group));
  };

  self.deleteElem = function deleteElem(_args, item, e) {
    e.preventDefault();
    e.stopPropagation();

    if (!self.isMasterAdmin())
      return ;

    var content = _args.dimension.typeId() === Enum.dimensionTypes.LANGUAGE
      ? 'All knowledge, players, activities and segmentation values attached to this language might become unavailable. All translated labels for this language will be lost. Are you really sure you want to delete this language?'
      : 'All knowledge, players, activities and segmentation values attached to this segmentation might become unavailable. Are you really sure you want to delete this segmentation?';

    popup.open({
      type   : 'confirm',
      title  : 'Warning!',
      content: content,
      action : !_args.ancestor.item || !item ? deleteGroup: deleteItem,
      confirm: 'Delete',
    });

    function deleteGroup() {
      _args.group
        .destroy()
        .then(m.redraw.bind(null, null));
    }

    function deleteItem() {
      item
        .destroy()
        .then(m.redraw.bind(null, null));
    }
  };

  self.onUnFocus = function onUnFocus(displayedLabel, oldSegmentationValue, isSegmentationItem) {
    self.editionMode(false);
    self.oldSegmentationValue(oldSegmentationValue);
    if (isSegmentationItem)
      self.saveGroupItemModification(displayedLabel);
    else
      self.saveItemModification(displayedLabel);
  };

  self.displayEmptyFieldPopup = function displayEmptyFieldPopup(groupItem) {
    var oldSegmentationValue = self.oldSegmentationValue();

    groupItem.label(oldSegmentationValue);

    return popup.open({
      type   : 'info',
      title  : 'Warning!',
      content: 'Segmentation value cannot be empty',
    });
  };
};

component.view = function view(c, args) {
  var typeDisplayed = 'SegmentationGroupItem';
  var toDisplay = args.group;

  if (args.ancestor && args.ancestor.item) {
    toDisplay = getItemToDisplay();
    typeDisplayed = 'SegmentationItem';
  }

  return displayItemOrInput();

  function displayItemOrInput() {
    if (c.editionMode())
      return displayInput();

    return displayItem();
  }

  function displayInput() {
    if (args.typeId === Enum.dimensionTypes.STORE) {
      return m('textarea.segmentation-dimension__column__item.segmentation-dimension__column__item--store', {
        disabled: !c.isMasterAdmin(),
        value   : toDisplay.label(),
        oninput : m.withAttr('value', toDisplay.label),
        onblur  : c.onUnFocus.bind(null, toDisplay, c.oldSegmentationValue(), typeDisplayed === 'SegmentationGroupItem'),
        onfocus : c.oldSegmentationValue.bind(null, toDisplay.label()),
        onchange: (typeDisplayed === 'SegmentationGroupItem')
          ? c.saveGroupItemModification.bind(null, toDisplay)
          : c.saveItemModification.bind(null, toDisplay),
        config: c.focusOnInput,
        style : {
          'border-color': args.color || '#52AFDF',
        },
      });
    }

    return m('input.segmentation-dimension__column__item', {
      disabled: !c.isMasterAdmin(),
      value   : toDisplay.label(),
      oninput : m.withAttr('value', toDisplay.label),
      onblur  : c.onUnFocus.bind(null, toDisplay, c.oldSegmentationValue(), typeDisplayed === 'SegmentationGroupItem'),
      onfocus : c.oldSegmentationValue.bind(null, toDisplay.label()),
      onchange: (typeDisplayed === 'SegmentationGroupItem')
        ? c.saveGroupItemModification.bind(null, toDisplay)
        : c.saveItemModification.bind(null, toDisplay),
      config: c.focusOnInput,
      style : {
        'border-color': args.color || '#52AFDF',
      },
    });
  }

  function displayItem() {
    if (args.typeId === Enum.dimensionTypes.STORE) {
      return m('.segmentation-dimension__column__item.segmentation-dimension__column__item--store', {
        style: {
          'border-color'    : args.color || '#52AFDF',
          'background-color': c.isSelected(toDisplay) ? args.color || '#52AFDF' : 'transparent',
        },
        class     : c.isSelected(toDisplay) ? 'segmentation-dimension__column__item--selected' : '',
        onclick   : c.selectItem.bind(null, toDisplay),
        ondblclick: c.editionMode.bind(null, !c.editionMode()),
      }, [
        toDisplay.label(),
        m('span.segmentation-dimension__column__item__sup-label', getSupLabelToDisplay(toDisplay)),
      ]);
    }

    if (args.dimension.type().behaviors().get(Enum.SEGMENTATION_BEHAVIOR.PRESET_SEGMENTATION_GROUP)) {
      return m('.segmentationItem', {
        style: {
          borderColor    : args.color || '#52AFDF',
          backgroundColor: c.isSelected(toDisplay)
            ? args.color || '#52AFDF'
            : 'transparent',
        },
        class: [
          c.isSelected(toDisplay) ? 'segmentationItem--selected' : '',
          args.group.isDefault()
            ? 'segmentationItem--isDefault'
            : 'segmentationItem--isNotDefault',
          c.isMasterAdmin()
            ? 'segmentationItem--isMasterAdmin'
            : '',
        ].join(' '),
        onclick: c.selectItem.bind(null, toDisplay),
      }, [
        m('.segmentationItem__label', toDisplay.label()),
        m('.segmentationItem__additional', getSupLabelToDisplay(toDisplay)),
        m('.segmentationItem__default', {
          onclick: c.setDefault.bind(null, args),
        }, args.group.isDefault()
          ? 'Default'
          : 'Set as default'),
        m('.segmentationItem__delete', {
          onclick: c.deleteElem.bind(null, args, false),
        }, '+'),
      ]);
    }

    return m('.segmentationItem', {
      style: {
        borderColor    : args.color || '#52AFDF',
        backgroundColor: c.isSelected(toDisplay) ? args.color || '#52AFDF' : 'transparent',
      },
      class: [
        c.isSelected(toDisplay) ? 'segmentationItem--selected' : '',
        'segmentationItem--isNotDefault',
        c.isMasterAdmin()
          ? 'segmentationItem--isMasterAdmin'
          : '',
      ].join(' '),
      onclick   : c.selectItem.bind(null, toDisplay),
      ondblclick: c.editionMode.bind(null, !c.editionMode()),
    }, [
      m('.segmentationItem__label', toDisplay.label()),
      m('.segmentationItem__additional', getSupLabelToDisplay(toDisplay)),
      m('.segmentationItem__delete', {
        onclick: c.deleteElem.bind(null, args, toDisplay),
      }, '+'),
    ]);
  }

  function getItemToDisplay() {
    var matchingMembers = args.group.members().find({parentId: args.ancestor.item.id()});

    if (!matchingMembers.items.length)
      return args.group;

    return matchingMembers.at(0);
  }

  function getSupLabelToDisplay(item) {
    if (item._type === 'SegmentationItem' && (item.label() !== args.group.label()))
      return ' (' + args.group.label() + ')';

    return '';
  }

};

export default component;
