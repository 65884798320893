// @flow

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UCompactInput.style';
import './UCompactInput.css';

type Props = {|
  style?: Object,
  icon: string,
  placeholder?: string,
  placeholderOnExpand?: string,
  onSubmit: Function,
|};

type State = {|
  text: string,
  isFocused: boolean,
|};

const ENTER_KEY_EVENT = 'Enter';

export class UCompactInput extends React.Component<Props, State> {
  static defaultProps = {
    icon: 'search',
    placeholder: 'Search',
    placeholderOnExpand: 'Search',
    style: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      text: '',
      isFocused: false,
    };
  }

  render() {
    const { icon, placeholder, placeholderOnExpand, style } = this.props;
    const { text, isFocused } = this.state;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <UIcon
          name={icon}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
          size={10}
        />
        <input
          type='text'
          placeholder={isFocused ? placeholderOnExpand : placeholder}
          value={text}
          className={isFocused ? 'comp--ucompactinput expand' : 'comp--ucompactinput'}
          style={isFocused ? { ...styles.input, ...styles.expanded } : styles.input}
          onBlur={this.handleLeave}
          onClick={this.handleOnClick}
          onChange={this.handleValueChange}
          onKeyDown={this.handleSubmit}
        />
      </div>
    );
  }

  handleValueChange = (event: Object) => {
    const text = event.target.value;

    this.setState({ text });
  };

  handleOnClick = () => {
    this.setState({ isFocused: true });
  };

  handleLeave = () => {
    const { text } = this.state;

    if (!text)
      this.setState({ isFocused: false });
  };

  handleSubmit = (event: Object) => {
    const { text } = this.state;
    const { onSubmit } = this.props;

    if (event.key === ENTER_KEY_EVENT)
      onSubmit(text);
  };
}

export default UCompactInput;
