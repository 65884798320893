import React, { useEffect, useState, useLayoutEffect } from 'react';

import { useTypedDispatch, useTypedSelector } from 'Libs/redux/utils';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';

import ActionBar from './ActionBar/ActionBar';
import { AlertView } from './AlertView/AlertView';
import { Overview } from './Overview/Overview';
import { Scheduling } from './Scheduling/Scheduling';
import { Audience } from './Audience/Audience';
import { ContentUpdater } from './ContentUpdater/ContentUpdater';

import { actions, getSelectedActivity, useModuleSelector } from './redux';
import styles from './Activity.style';

const getId = (): number | null => {
  const idString = window.location.pathname.split('/')?.[3];
  const id = parseInt(idString);

  if (Number.isInteger(id)) {
    return id;
  }

  return null;
};

const COMPONENTS = {
  [0]: Overview,
  [1]: Scheduling,
  [2]: Audience,
} as const;

const getTabIndex = (): keyof typeof COMPONENTS => {
  const tabName = window.location.pathname.split('/')?.[4];

  switch (tabName) {
    case 'scheduling':
      return 1;
    case 'audience':
      return 2;
    default:
      return 0;
  }
};

export const Activity = () => {
  const dispatch = useTypedDispatch();
  const urlId = getId();
  const stateId = useModuleSelector((state) => state.activities[0].multilingualId);
  const tabIndex = getTabIndex();
  const [, forceUpdate] = useState({});

  const selectedActivity = useTypedSelector(getSelectedActivity);

  const [actionBarHeight, setActionBarHeight] = useState(0);
  const actionRef = React.useRef(null);

  // Load activity
  useEffect(() => {
    // INFO an activity can already be present in state if stateId is not 0
    // This case may happen when URL changes after activity creation for instance
    if (urlId === null || stateId !== 0) {
      return () => {};
    }

    const action = actions.fetchById(urlId);

    dispatch(action);

    return action.cancel;
  }, [stateId, urlId, dispatch]);

  // Reset state on unmount
  useEffect(
    () => () => {
      dispatch(actions.reset());
    },
    [dispatch],
  );

  // Change url on creation or duplicate
  useEffect(() => {
    if (stateId !== 0 && stateId !== urlId) {
      const pathElems = window.location.pathname.split('/').filter((_, i) => i <= 4);

      pathElems[3] = String(stateId);

      // on duplicate, go to overview tab
      if (urlId !== null) {
        pathElems.length = 4;
      }

      window.history.replaceState({}, '', pathElems.join('/'));

      // INFO Re-render the component to take url changes into account,
      // For instance to change tab after creation.
      // This can be removed when routing is fully managed by react.
      forceUpdate({});
    }
  }, [stateId, urlId]);

  useLayoutEffect(() => {
    /**
     * The action bar is not rendered when the activity id is 0.
     */
    if (!actionRef.current || selectedActivity.id === 0) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(([actionBarEntry]) => {
      setActionBarHeight(actionBarEntry.contentRect.height);
    });

    resizeObserver.observe(actionRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [selectedActivity]);

  const isPageLoaderActive = useModuleSelector((state) => {
    // First fetch: show loader
    if (stateId === 0) {
      if (state.alerts.find((alert) => alert.scope === 'fetchError')) return false;

      return true;
    }

    return state.isFetchLoading;
  });

  return (
    <div style={styles.wrapper}>
      <ContentUpdater />
      <ActionBar innerRef={actionRef} selectedTabIndex={tabIndex} />
      <div data-testID="activity-content" style={{ ...styles.contentContainer, paddingTop: actionBarHeight }}>
        <AlertView component={COMPONENTS[tabIndex]} />
      </div>
      <SPageLoaderWithTimer isLoading={isPageLoaderActive} style={styles.pageLoader} />
    </div>
  );
};
