import Model from 'models/Model';

var SegmentationBehavior = {
  endpoint  : 'api/SegmentationBehaviors',
  attributes: {
    label: {
      type: 'string',
    },
  },
  methods: {},
};

export default Model.register('SegmentationBehavior', SegmentationBehavior);
