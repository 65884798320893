// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display a day selector
 *
 * Props:
 * - style: override the component's style
 * - label: the day displayed
 * - isSelected : set the state of the daySelector (true|false)
 * - onClick: Function called when we click on the daySelector
 *
 */

import * as React from 'react';

import styles from './UDaySelector.style';


type Props = {|
  style: Object,
  label: string,
  isSelected: boolean,
  onClick: Function,
|};

type State = {|
  isHover: boolean,
|};

export class UDaySelector extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  render() {
    const { style, label, isSelected } = this.props;
    const { isHover } = this.state;

    const indicatorStyle = isSelected ? styles.indicatorSelected : styles.indicatorUnselected;
    const wrapperBackground = isHover ? styles.isHover : {};
    const labelColor = isSelected ? {} : styles.labelUnselected;

    return (
      <div
        style={{ ...styles.wrapper, ...style, ...wrapperBackground }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={this.handleClick}
      >
        <span style={{ ...styles.label, ...labelColor }}>
          {label}
        </span>
        <div
          style={{ ...styles.indicator, ...indicatorStyle }}
        />
      </div>
    );
  }

  handleClick = () => {
    const { onClick } = this.props;

    onClick();
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default UDaySelector;
