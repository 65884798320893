import React, { useCallback, useState, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import type { IconName } from 'Components/foundation/icons';
import UIcon from 'Components/unit/UIcon/UIcon';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';
import URadioButton, { URadioButtonProps } from 'Components/unit/URadioButton/URadioButton';
import UCheckbox, { CheckBoxStatus, UCheckboxProps } from 'Components/unit/UCheckbox/UCheckbox';
import { SOptionsMenu, type SOptionsMenuProps } from 'Components/structural/SOptionsMenu/SOptionsMenu';

import styles from './SGameplayListItem.style';

export type SelectVariant = 'radio' | 'check' | 'none';
export type SelectSize = 'S' | 'M' | 'L';

const SGAMEPLAY_LIST_ITEM_TYPE = {
  RADIO: 'radio',
  CHECK: 'check',
} as const;

type HandlerType = 'radio' | 'check';

type Handlers = {
  [SGAMEPLAY_LIST_ITEM_TYPE.RADIO]: URadioButtonProps['onSelect'];
  [SGAMEPLAY_LIST_ITEM_TYPE.CHECK]: UCheckboxProps['onClick'];
};

export type SGameplayListItemProps = {
  id: number | string;
  style?: CSSProperties;
  type?: 'standard' | 'accentuated';
  variant?: SelectVariant;
  value: string;
  icon?: IconName;
  selected: boolean;
  isSelectedDisabled?: boolean;
  itemPlaceholder?: string;
  contextMenuOptions: SOptionsMenuProps['options'];
  maxInputLength?: number;
  onSelect: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<Element, MouseEvent>,
    id: string | number,
  ) => void;
  onTextChange: (value: string) => void;
};

export const SGameplayListItem = ({
  id,
  icon,
  value,
  selected,
  isSelectedDisabled,
  itemPlaceholder = t('courses:course_edition.type_title'),
  variant = 'none',
  type = 'standard',
  style = {},
  contextMenuOptions,
  onTextChange,
  onSelect,
  maxInputLength,
}: SGameplayListItemProps) => {
  const handlerMapping = useMemo(
    () => ({
      radio: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onSelect(event, id);
      },
      check: (type: CheckBoxStatus, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onSelect(event, id);
      },
    }),
    [onSelect, id],
  );

  const createHandler = <T extends HandlerType>(handlerType: T): Handlers[T] => {
    return handlerMapping[handlerType];
  };

  const renderSelectableByType = useMemo(() => {
    switch (variant) {
      case 'radio':
        return (
          <URadioButton
            onSelect={createHandler('radio')}
            size="S"
            selected={selected}
            disabled={isSelectedDisabled}
            type={type}
            style={styles.radioButton}
          />
        );
      case 'check':
        return (
          <UCheckbox
            onClick={createHandler('check')}
            isDisabled={isSelectedDisabled}
            checkBoxStatus={selected ? 'checked' : 'unchecked'}
            type={type}
            style={styles.uCheckBox}
          />
        );
      case 'none':
        return <div style={styles.noneVariant}></div>;
    }
  }, [variant, type, isSelectedDisabled, selected, createHandler]);

  const [isHover, setIsHover] = useState(false);

  const handleHover = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleLeave = useCallback(() => setIsHover(false), []);
  const hoverStyle = useMemo(() => (isHover ? { ...styles.hover } : undefined), [isHover]);
  const selectedStyle = useMemo(
    () => (selected && variant !== 'none' ? { ...styles.selected } : undefined),
    [selected, variant],
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const gameplayItemStyle = useMemo(
    () => ({
      transition,
      transform: CSS.Transform.toString(transform),
    }),
    [transition, transform],
  );

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={{ ...styles.wrapper, ...style, ...hoverStyle, ...selectedStyle, ...gameplayItemStyle }}
      key={id}
    >
      <div {...attributes} {...listeners} ref={setNodeRef}>
        <SOptionsMenu
          size="M"
          icon="drag-and-drop"
          type="standard-dark"
          options={contextMenuOptions}
          style={styles.optionsMenu}
        />
      </div>
      {renderSelectableByType}
      {icon && <UIcon style={styles.icon} name={icon} size={16} />}
      <SSubtleInput
        inputType="input"
        style={styles.inputStyle}
        placeholder={itemPlaceholder}
        value={value}
        onChange={onTextChange}
        maxLength={maxInputLength}
      />
    </div>
  );
};

export default SGameplayListItem;
