import { useState, useMemo, useCallback } from 'react';

import { useInterval } from '../useInterval/useInterval';

export type UseCountdownHook = (from: number, callback?: () => void) => {
  currentCount?: number,
  start: () => void,
  cancel: () => void,
};

/**
 * Hook used to simplify counting down seconds.
 *
 * @param from - Number of seconds to count down from. 15 -> 14 -> 13 -> 12 -> ...
 * @param callback - Callback fired when the countdown gets to 0
 */
export const useCountdown: UseCountdownHook = (from, callback) => {
  const [countdownActive, setCountdownActive] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const delay = useMemo(() => (countdownActive ? 1000 : undefined), [countdownActive]);

  const cancel = useCallback(() => {
    setCountdownActive(false);
    setCountdown(0);
  }, []);

  const start = useCallback(() => {
    setCountdownActive(true);
    setCountdown(from);
  }, [from]);

  const onInterval = useCallback(() => {
    setCountdown((currentCount) => currentCount - 1);

    if (countdown - 1 === 0) {
      callback?.();
      cancel();
    }
  }, [cancel, countdown, callback]);

  useInterval(onInterval, delay);

  return {
    currentCount: countdownActive ? countdown : undefined,
    cancel,
    start,
  };
};

export default useCountdown;
