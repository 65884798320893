import React, { useState, useMemo, useRef, useEffect, ReactNode } from 'react';

import type { RawReactNodes, RawReactNodeMap } from 'Libs/ts/types';

import { transformMapIntoArray } from 'Pages/AlertManager/redux';

import { getDiffKeys, applyClassNameToNode, transformRawToMap, hasClassname } from './Transition.utils';

export type TransitionProps = {
  entryAnimationCss: string;
  exitAnimationCss: string;
  exitTimeout: number;
  children: ReactNode;
};

/**
 * This component automatically add entry/exit classname to its childrens.
 * Mainly used to animate list transitions from one state to another
 * @param {ReactNode} children CHILDREN SHOULD HAVE UNIQUE KEYS (dont use indices when iterating)
 * @param {String} entryAnimationCss Classname applied to any new child
 * @param {String} exitAnimationCss Classname applied to any child missing from previous Transition render
 * @param {Number} exitTimeout Time in ms before a missing child is no longer rendered (exitAnimationCss length)
 * @returns {React$ComponentType}
 */
export const Transition = ({
  children: _children,
  entryAnimationCss,
  exitAnimationCss,
  exitTimeout = 0,
}: TransitionProps) => {
  const timeoutIds = useRef<ReturnType<typeof setTimeout>[]>([]);

  const children = useMemo(() => React.Children.toArray(_children), [_children]) as RawReactNodes;

  const [localChildren, setLocalChildren] = useState<RawReactNodeMap>(() =>
    transformRawToMap(children.map((child) => applyClassNameToNode(child, entryAnimationCss))),
  );

  const [newKeys, missingKeys] = useMemo(
    () =>
      getDiffKeys(
        children.map((c) => c.key),
        Object.keys(localChildren),
      ),
    [children, localChildren],
  );

  const childrenToDisplay = useMemo(() => transformMapIntoArray(localChildren), [localChildren]);

  useEffect(
    () => () => {
      timeoutIds.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    },
    [],
  );

  if (!newKeys.length && !missingKeys.length) {
    return <>{childrenToDisplay}</>;
  }

  if (newKeys.length) {
    const withEntryAnim = children
      .filter((c) => newKeys.includes(c.key))
      .map((c) => applyClassNameToNode(c, entryAnimationCss));

    setLocalChildren((oldChildren) => ({
      ...transformRawToMap(withEntryAnim),
      ...oldChildren,
    }));
  }

  if (missingKeys.length) {
    const toAddExit = missingKeys.map((key) => localChildren[key]).filter((c) => !hasClassname(c, exitAnimationCss));

    if (toAddExit.length) {
      const withExitAnim = toAddExit.map((c) => applyClassNameToNode(c, exitAnimationCss, entryAnimationCss));

      setLocalChildren((oldChildren) => ({
        ...oldChildren,
        ...transformRawToMap(withExitAnim),
      }));

      timeoutIds.current = toAddExit.map((c) => {
        return setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars -- removing item with deconstruction
          setLocalChildren(({ [c.key]: _, ...rest }) => rest);
        }, exitTimeout);
      });
    }
  }

  return <>{childrenToDisplay}</>;
};

export default Transition;
