import React, { FunctionComponent } from 'react';
import { AmbassadorView } from './AmbassadorView/AmbassadorView';
import styles from './Ambassador.style';

export const Ambassador: FunctionComponent = () => {
  return (
    <div style={styles.wrapper}>
      <AmbassadorView />
    </div>
  );
};
