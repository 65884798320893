import m from 'm';

var component = {};

component.controller = function controller(args) {
  var self = this;

  // Disable error display by default
  self.error = args.error || {
    display: m.prop(false),
  };

  self.check = function check() {
    var cursorPosition = this.selectionEnd;
    var isAtEnd = cursorPosition === this.value.length;
    var diff = this.value.length - args.value().length;

    args.displayErr(false);

    if (!this.value.length)
      self.error.display(false);
    else if (args.check && !args.check.test(this.value))
      self.error.display(true);
    else
      self.error.display(false);

    if (!isAtEnd && this.value.length > args.max) {
      this.value = args.value();
      cursorPosition -= diff;
      if (args.modified)
        args.modified(true);
    } else if (args.max && this.value.length > args.max)
      this.value = this.value.substring(0, args.max);

    this.setSelectionRange(cursorPosition, cursorPosition);

    if (args.modified)
      args.modified(true);

    args.value(this.value);
  };

  self.validate = function validate(value) {
    if (!value.length)
      self.error.display(false);
    else if (args.validate && !args.validate.test(value))
      self.error.display(true);
  };
};

component.view = function view(c, args) {
  args.displayErr = args.displayErr || m.prop(false);

  return m('.newInput', {
    class: [
      args.class || 'input--none',
      'newKnowledge__explanation',
    ].join(' '),
  }, [
    m('textarea.newInput__value', {
      placeholder: args.placeholder,
      oninput    : c.check,
      onblur     : m.withAttr('value', c.validate),
      value      : args.value(),
    }),
    m('.input__type'),
    m('.newTextarea__maxlength', [args.value().length, args.max].join(' / ')),
  ]);
};

export default component;
