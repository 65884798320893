import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';
import type { IconName } from 'Components/foundation/icons';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';
import type { BackgroundColorsType } from 'Components/unit/UBackground/UBackground';

type BackgroundHeaderTheme = {
  backgroundColorScheme: BackgroundColorsType;
  textColor: string;
  illustrationColorScheme?: IllustrationSVGType['color'];
  disableIllustration?: boolean;
  icon?: IconName;
  iconColor?: string;
};

export type BackgroundHeaderThemeName = 'disabled' | 'locked' | 'validated' | 'active' | 'warning';
export const BACKGROUND_HEADER_THEMES: Record<BackgroundHeaderThemeName, BackgroundHeaderTheme> = {
  disabled: {
    backgroundColorScheme: 'DISABLED',
    textColor: COLORS.TEXT.DISABLED_DEFAULT,
    disableIllustration: true,
  },
  locked: {
    backgroundColorScheme: 'DISABLED',
    textColor: COLORS.TEXT.DISABLED_DEFAULT,
    icon: 'lock',
    iconColor: COLORS.TEXT.DISABLED_DEFAULT,
    disableIllustration: true,
  },
  validated: {
    backgroundColorScheme: 'PRIMARY',
    textColor: COLORS.PRIMARY.DEFAULT,
    icon: 'check-save',
    iconColor: COLORS.PRIMARY.DEFAULT,
    illustrationColorScheme: COLORS.PRIMARY,
  },
  active: {
    backgroundColorScheme: 'PRIMARY',
    textColor: COLORS.PRIMARY.DEFAULT,
    illustrationColorScheme: COLORS.PRIMARY,
  },
  warning: {
    backgroundColorScheme: 'SECONDARY',
    textColor: COLORS.SECONDARY.DARK,
    icon: 'alert',
    iconColor: COLORS.SECONDARY.DARK,
    illustrationColorScheme: COLORS.SECONDARY,
  },
};

const styleSheet = createStyleSheet({
  container: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
  },
  background: {
    borderRadius: '8px',
  },
  illustration: {
    position: 'absolute',
    top: 32,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  illustrationSmall: {
    top: 18,
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 7,
  },
  text: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
  },
});

export default styleSheet;
