import { createStyleSheet } from 'Components/foundation/stylesheets';

export const cardDimensions = {
  bigCard: { width: 474, height: 144 },
  smallCard: { width: 308, height: 96 },
};

const styleSheet = createStyleSheet({
  twoColumnGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoFlow: 'row',
    gridGap: 52,
    // [HACKERMAN]: setting max-width because the grid will auto expand the gaps if the cell does not take the entire space
    // (item width * column count) + (grid gap * (column count - 1))
    maxWidth: cardDimensions.bigCard.width * 2 + 58,
  },
  threeColumnGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoFlow: 'row',
    gridGap: 38,
    // See comment above about maxWidth
    maxWidth: cardDimensions.smallCard.width * 3 + 38 * 2,
  },
});

export default styleSheet;
