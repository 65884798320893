import m from 'm';
import { t } from 'i18next';

import Bus from 'services/pubSubService';
import menu from 'pages/menu';
import App from 'models/App';
import Enum from 'models/Enum';
import Activity from 'models/Activity';
import activityToolbar from 'components/activity.toolbar';
import activityHeader from 'components/activity.header.new';
import welcomePanelList from 'components/welcome.panel.list';
import button from 'components/button';
import popup from 'services/popupService';

var component = {};

component.controller = function controller() {
  var self = this;

  var welcomeCardId = m.route.param('id');

  var activityType = App.activityTypes().get(Enum.activityTypeEnum.WELCOME);

  var blankWelcomeCard = {
    id       : undefined,
    typeId   : Enum.activityTypeEnum.WELCOME,
    startDate: -Infinity,
    endDate  : Infinity,
  };

  self.activity = m.prop(new Activity(blankWelcomeCard));
  self.loading = m.prop(true);

  Bus.subscribe('activity:save', updateActivity);

  self.onUnload = function onUnload() {
    Bus.unsubscribe('activity:save', updateActivity);
  };

  if (welcomeCardId === 'new') {
    self.activity(new Activity(blankWelcomeCard));
    self.activity().type(activityType);
    doneLoading();
  } else {
    Activity
      .get(welcomeCardId)
      .then(updateActivity)
      .finally(doneLoading);
  }

  function doneLoading() {
    self.loading(false);
    m.redraw();
  }

  function updateActivity(updatedActivity) {
    self.loading(false);
    self.activity(updatedActivity);
    m.redraw();
  }

  self.back = function back() {
    m.route('/activity');
  };

  self.openPopup = function openPopup(errorMsg) {
    if (errorMsg.subtitle && errorMsg.content) {
      popup.open({
        type    : 'info',
        title   : t('welcome_card:popup.cannot_create'),
        subtitle: '',
        content : errorMsg.content,
      });

      return false;
    }

    return true;
  };

  self.saveActivity = function saveActivity() {
    var validated = self.activity().validate();

    if (self.openPopup(validated)) {
      return self.activity()
        .save(true)
        .tap(function savedActivity(updated) {
          var newRoute = updated.getRoute();
          m.route(newRoute);
        });
    }
  };

};

component.view = function view(c) {
  return m('.welcome-page', [
    m('.header', [
      m(menu),
    ]),
    m('.home__loader', {
      class: c.loading() ? '' : 'hidden',
    }),
    c.loading()
    ? ''
    : m('.welcome-detail-page', {
      class: c.loading() ? 'hidden' : '',
    }, [
      m('.welcome-body', [
        m(activityToolbar, {
          activity    : c.activity(),
          saveActivity: c.saveActivity,
        }),
        m(activityHeader, {
          activity: c.activity,
        }),
        m(welcomePanelList, {
          welcomeCard: c.activity().welcomeCard(),
        }),
        m('.activity__footer', [
          c.activity().typeId() === Enum.activityTypeEnum.DAILY_SERIE
          ? ''
          : m(button, {
            class : 'activity-toolbar__button--save button--action',
            action: c.saveActivity,
            value : c.activity().id() ? t('welcome_card:save') : t('welcome_card:create'),
          }),
        ]),
      ]),
    ]),
  ]);
};

export default component;
