import { handle } from 'redux-pack';
import { getErrorMessage } from 'Libs/redux/utils';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { CategoryType } from 'ModelsReact/Category/Category';
import createCategory from 'ModelsReact/Category/Category';
import type { Action } from 'Libs/redux/types';
import type { CategoryTableData } from './models/CategoryTableData';
import type { AlertType } from './models/Alert';

import createCategoryTableData, { createCategoryRow } from './models/CategoryTableData';

export type CategoryListState = DeepReadonly<{
  loading: boolean;
  categoryTableData: CategoryTableData;
  isCreationModalOpen: boolean;
  categoryToCreate: CategoryType;
  creationError: string;
  alerts: ReadonlyArray<AlertType>;
  isDeletionModalOpen: boolean;
  deletionError: string;
  categoryToDelete: CategoryType & {
    documentsCount: number;
  };
}>;

const defaultCategory = createCategory();
const defaultData = createCategoryTableData();
const defaultDeleteModal = {
  ...createCategory(),
  documentsCount: 0,
};

export const defaultState: CategoryListState = {
  loading: false,
  categoryTableData: defaultData,
  isCreationModalOpen: false,
  categoryToCreate: defaultCategory,
  creationError: '',
  alerts: [],
  isDeletionModalOpen: false,
  deletionError: '',
  categoryToDelete: defaultDeleteModal,
};

const ERROR_SCOPE = {
  CATEGORY_LIST_FETCH: 'fetchError',
} as const;

const SUCCESS_SCOPE = {
  CATEGORY_DELETE: 'deleteSuccess',
} as const;

function getValidationMessage(error: any) {
  const { messages } = error.details;

  if (messages.label) {
    return messages.label[0];
  }

  return '';
}

function sortEntries(array: any[]) {
  return array.sort((a, b) => {
    const labelA = a.label.params.value.toUpperCase();
    const labelB = b.label.params.value.toUpperCase();

    return labelA.localeCompare(labelB);
  });
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: CategoryListState = defaultState, action: Action): CategoryListState => {
  switch (action.type) {
    case 'CATEGORY_LIST_FETCH':
      return handle(state, action, {
        start: (prevState) => {
          const { isInit } = action.meta;

          return mergeDeep(prevState, {
            loading: isInit,
            categoryTableData: { loading: true },
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]),
          });
        },
        success: (prevState) => {
          const newEntries = action.payload.map((entry: any) => createCategoryRow(entry));

          const categoryTableData = createCategoryTableData({
            entries: newEntries,
            total: newEntries.length,
          });

          return mergeDeep(prevState, { categoryTableData });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) =>
          mergeDeep(prevState, {
            loading: false,
            categoryTableData: { loading: false },
          }),
      });

    case 'CATEGORY_CREATE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            categoryTableData: { loading: true },
            creationError: '',
          }),
        success: (prevState) => {
          const { payload } = action;
          const { entries } = prevState.categoryTableData;

          const categoryToAdd = { ...createCategory(payload), documentsCount: 0 };
          const newEntry = createCategoryRow(categoryToAdd);

          const newEntries = sortEntries([newEntry, ...entries]);

          const categoryTableData = createCategoryTableData({
            entries: newEntries,
            total: newEntries.length,
          });

          return mergeDeep(prevState, { categoryTableData, categoryToCreate: defaultCategory });
        },
        failure: (prevState) => {
          const { error } = action.payload;

          let message = getErrorMessage(action);

          if (error && error.name === 'ValidationError') {
            message = getValidationMessage(error);
          }

          return mergeDeep(prevState, { creationError: message });
        },
        finish: (prevState) => mergeDeep(prevState, { categoryTableData: { loading: false } }),
      });

    case 'CATEGORY_EDIT':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            categoryTableData: { loading: true },
          }),
        success: (prevState) => {
          const { payload: editedCategory } = action;
          const { entries } = prevState.categoryTableData;
          const { label, id } = editedCategory;
          const newEntryId = entries.findIndex((entry) => entry.id === id);

          entries[newEntryId].label.params.value = label;
          entries[newEntryId].dataAccessor.name = label;

          let newEntries = sortEntries([...entries]);

          newEntries = newEntries.map((entry) => ({ ...entry, rowMessage: undefined }));

          const categoryTableData = createCategoryTableData({
            entries: newEntries,
            total: newEntries.length,
          });

          return mergeDeep(prevState, { categoryTableData });
        },
        failure: (prevState) => {
          const { entries } = prevState.categoryTableData;
          const { error } = action.payload;
          const { categoryId } = action.meta;

          let message = getErrorMessage(action);

          if (error && error.name === 'ValidationError') {
            message = getValidationMessage(error);
          }

          const newEntries = entries.map((entry) => ({
            ...entry,
            rowMessage: entry.id === categoryId ? { type: 'error', content: message } : entry.rowMessage,
          }));

          const categoryTableData = createCategoryTableData({
            entries: newEntries,
            total: newEntries.length,
          });

          return mergeDeep(prevState, { categoryTableData });
        },
        finish: (prevState) => mergeDeep(prevState, { categoryTableData: { loading: false } }),
      });

    case 'CATEGORY_DELETE': {
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            categoryTableData: { loading: true },
            alerts: prevState.alerts.filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),
          }),
        success: (prevState) => {
          const { payload: id } = action;
          const { entries } = prevState.categoryTableData;
          const newEntries = entries.reduce((accumulator, entry) => {
            if (entry.id !== id) {
              accumulator.push(entry);
            }

            return accumulator;
          }, []);

          const categoryTableData = createCategoryTableData({
            entries: newEntries,
            total: newEntries.length,
          });

          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: {},
            },
          ]);

          return mergeDeep(prevState, { categoryTableData, alerts });
        },
        failure: (prevState) => {
          const message = getErrorMessage(action);

          return mergeDeep(prevState, { deletionError: message });
        },
        finish: (prevState) => mergeDeep(prevState, { categoryTableData: { loading: false } }),
      });
    }

    case 'CATEGORY_SET_ALERT': {
      const newAlert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === newAlert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? newAlert : err))
        : state.alerts.concat([newAlert]);

      return mergeDeep(state, { alerts });
    }

    case 'CATEGORY_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'CATEGORY_RESET':
      return defaultState;

    case 'CATEGORY_LIST_RESET_ROW_ERRORS': {
      const { entries } = state.categoryTableData;
      const newEntries = entries.map((entry) => ({ ...entry, rowMessage: undefined }));

      const categoryTableData = createCategoryTableData({
        entries: newEntries,
        total: newEntries.length,
      });

      return mergeDeep(state, { categoryTableData });
    }

    case 'CATEGORY_TOGGLE_CREATION_MODAL': {
      const modalState = action.isOpen ? {} : { categoryToCreate: defaultCategory, creationError: '' };

      return mergeDeep(state, { isCreationModalOpen: action.isOpen, ...modalState });
    }

    case 'CATEGORY_CREATION_EDIT_LABEL':
      return mergeDeep(state, { categoryToCreate: { label: action.value } });

    case 'CATEGORY_TOGGLE_DELETION_MODAL': {
      const modalState = action.isOpen ? {} : { categoryToDelete: { ...defaultDeleteModal }, deletionError: '' };

      if (action.isOpen && action.category) {
        const {
          id,
          data: { name, nbDocuments },
        } = action.category;

        modalState.categoryToDelete = {
          id,
          label: name,
          locked: false,
          documentsCount: nbDocuments,
        };
      }

      return mergeDeep(state, { isDeletionModalOpen: action.isOpen, ...modalState });
    }

    default:
      return state;
  }
};
