import React, { useCallback, useState } from 'react';

import MActionDialog, { MActionDialogProps } from 'Components/modal/MActionDialog/MActionDialog';

type Callback = () => void;

export type UseActionConfirmationParams = Omit<MActionDialogProps, 'onPrimaryAction' | 'onSecondaryAction' | 'visible'>;

export const useActionDialogConfirmation = (dialogProps: UseActionConfirmationParams) => {
  const [callback, setCallback] = useState<undefined | Callback>();
  const resetCallback = useCallback(() => setCallback(undefined), []);

  const onConfirm = useCallback(() => {
    callback?.();
    resetCallback();
  }, [callback, resetCallback]);

  const onClose = useCallback(() => {
    resetCallback();
    dialogProps.onClose?.();
  }, [dialogProps.onClose, resetCallback]);

  const askForConfirmation = useCallback((cb: Callback) => setCallback(() => cb), []);

  const dialog = (
    <MActionDialog
      {...dialogProps}
      // eslint-disable-next-line react/no-children-prop
      children={dialogProps.children}
      onPrimaryAction={onConfirm}
      onSecondaryAction={resetCallback}
      onClose={onClose}
      visible={Boolean(callback)}
    />
  );

  return {
    askForConfirmation,
    dialog,
  };
};
