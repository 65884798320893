import m from 'm';

var component = {};

component.view = function view(c, args) {
  return m('.image-gameplay-preview', {
    style: {
      backgroundImage: 'url(' + args.image + ')',
    },
  }, args.image ? '' : 'No image yet');
};
export default component;
