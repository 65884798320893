import React, { CSSProperties } from 'react';

import { UTableCellProfilePicture, UTableCellSegment, UTableCellText, UTableCellUser } from 'Components/unit';
import UCheckbox from 'Components/unit/UCheckbox/UCheckbox';

type CellType = 'UCheckbox' | 'UTableCellProfilePicture' | 'UTableCellText' | 'UTableCellUser' | 'UTableCellSegment';

export type Cell = {
  id: string | number;
  type: CellType;
  params: any;
  wrapperStyle?: CSSProperties;
};

export function getCellsComponents(value: Cell, locked: boolean) {
  switch (value.type) {
    case 'UCheckbox':
      return <UCheckbox {...value.params} />;
    case 'UTableCellProfilePicture':
      return <UTableCellProfilePicture {...value.params} />;
    case 'UTableCellText':
      return <UTableCellText {...value.params} editable={value.params.editable && !locked} />;
    case 'UTableCellUser':
      return <UTableCellUser {...value.params} />;
    case 'UTableCellSegment':
      return <UTableCellSegment {...value.params} />;
    default:
      return <div />;
  }
}

export function getCells(values: { [id: number]: Cell }, locked: boolean) {
  const cells: Array<Cell> = Object.values(values);

  return cells.map((value: Cell) => {
    if (!value) return <div />;

    return <React.Fragment key={value.id}>{getCellsComponents(value, locked)}</React.Fragment>;
  });
}
