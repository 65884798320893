import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: '100%',
  },
  contentContainer: {
    height: '100%',
    paddingTop: 64,
  },
  contentContainerSecondary: {
    height: '100%',
    paddingTop: 96,
  },
  pageLoader: {
    zIndex: 11,
  },
});
