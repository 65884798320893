import 'react-image-crop/dist/ReactCrop.css';

import React, { useCallback, useMemo, useState, useEffect, CSSProperties } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import styles from './UCropTool.style';
import { PADDING_WRAPPER } from './constants';
import type { ImageDimensionType } from './types';
import { computeCrop, computeImgDimensions, getImageUrl } from './utils';

export interface UCropToolProps {
  image: string;
  loadedOptimizedImage: HTMLImageElement;
  highDefinitionSize: ImageDimensionType;
  onCropEnd: (crop: Crop, imageUrl: string) => void;
  widthMax: number;
  heightMax: number;
  style?: CSSProperties;
}

/**
 * Display an image with the possibility to crop it
 *
 * Props:
 * - image: url source of the image
 * - loadedOptimizedImage: loaded cdn image
 * - onCropEnd: call with the cropped image
 * - style: override component's style
 * - widthMax: Max width of the image
 * - heightMax: Max height of the image
 */
export const UCropTool = ({
  image,
  loadedOptimizedImage,
  highDefinitionSize,
  heightMax,
  onCropEnd,
  widthMax,
  style = {},
}: UCropToolProps) => {
  const imageDimensions = useMemo(
    () =>
      computeImgDimensions(
        {
          width: loadedOptimizedImage.naturalWidth,
          height: loadedOptimizedImage.naturalHeight,
        },
        heightMax,
        widthMax,
      ),
    [loadedOptimizedImage, heightMax, widthMax],
  );
  const rootStyle = useMemo(() => ({ ...styles.wrapper, padding: PADDING_WRAPPER, ...style }), [style]);

  const [imageRef, setImageRef] = useState<HTMLImageElement>(() => loadedOptimizedImage);
  const [cropState, setCropState] = useState<Crop>(() => computeCrop(imageDimensions));

  const handleCropComplete = useCallback(
    (crop: Crop) => {
      if (crop.width && crop.height) {
        const scalePreviewX = imageRef.naturalWidth / imageDimensions.width;
        const scalePreviewY = imageRef.naturalHeight / imageDimensions.height;
        const croppedImageUrl = getImageUrl(imageRef, scalePreviewX, scalePreviewY, crop);

        const scaleX = highDefinitionSize.width / imageDimensions.width;
        const scaleY = highDefinitionSize.height / imageDimensions.height;

        const newCrop = {
          ...crop,
          x: crop.x !== undefined ? crop.x * scaleX : 0,
          y: crop.y !== undefined ? crop.y * scaleY : 0,
          width: crop.width * scaleX,
          height: crop.height * scaleY,
        };

        onCropEnd(newCrop, croppedImageUrl);
      }
    },
    [
      imageRef,
      imageDimensions.width,
      imageDimensions.height,
      highDefinitionSize.width,
      highDefinitionSize.height,
      onCropEnd,
    ],
  );

  useEffect(() => {
    setCropState(computeCrop(imageDimensions));
  }, [imageDimensions]);

  return (
    <div style={rootStyle}>
      <ReactCrop
        src={image}
        crop={cropState}
        ruleOfThirds
        keepSelection
        onComplete={handleCropComplete}
        crossorigin="anonymous"
        onChange={setCropState}
        onImageLoaded={setImageRef}
        style={imageDimensions}
      />
    </div>
  );
};

export default UCropTool;
