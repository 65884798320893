import { createStyleSheet } from 'Components/foundation/stylesheets';

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  container: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 128,
  },
  containerContent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 32,
  },
  contentCard: {
    marginBottom: 16,
    marginRight: 16,
  },
  thirdContentCard: {
    marginBottom: 16,

    // INFO We add a negative margin to make sure the row
    // will not wrap before this card, even with the presence
    // of a scrollbar
    marginRight: -50,
  },
  selectionText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 32,
  },
  selectButton: {
    height: 32,
  },
  selectedItems: {
    marginLeft: 16,
    ...TYPOGRAPHY.H2,
    color: COLORS.WHITE.DEFAULT,
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
    position: 'relative',
  },
  empty: {
    paddingTop: 48,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyTitle: {
    ...TYPOGRAPHY.H2,
    marginBottom: 8,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  emptySubTitle: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    textAlign: 'center',
  },
  newContentButton: {
    marginTop: 24,
  },
  selection: {
    height: 128,
    width: 597,
    color: COLORS.WHITE.DEFAULT,
    backgroundColor: COLORS.TEXT.ACCENTUATED,
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    display: 'block',
  },
  containerFilter: {
    minWidth: 0,
    width: '100%',
  },
});

export default styleSheet;
