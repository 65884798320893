import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Filter, FilterItem } from 'Libs/filter/types';

export const useAudienceGroupSortConfig = () => {
  const { t } = useTranslation();
  const [sortProps, setSortProps] = useState<Filter>({
    category: 'sort',
    type: 'select',
    items: [
      {
        id: 1,
        category: 'sort',
        selected: true,
        value: t('audiences:audience_list.filters.last_edited'),
        field: 'editionDate',
        order: 'desc',
      },
      {
        id: 2,
        category: 'sort',
        selected: false,
        value: t('audiences:audience_list.filters.alphabetical_order'),
        field: 'name',
        order: 'asc',
      },
    ],
  });

  const selectedSort = useMemo(() => sortProps.items.find((item) => item.selected)! as FilterItem, [sortProps.items]);

  return {
    sortProps,
    selectedSort,
    setSortProps,
  };
};
