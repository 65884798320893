/* eslint-disable react/no-multi-comp */
// @flow

import React, { useMemo } from 'react';
import { t } from 'i18next';

import { COLORS } from 'Components/foundation';

import styles from './NPSGraph.style';

type Props = {|
  nbPassives: number,
  nbPromoters: number,
  nbDetractors: number,
|};

type GraphDataProps = {|
  total: number,
  amount: number,
  color: string,
  name: string,
|};

const GRAPH_BAR_MAX_LENGTH = 175;
const PERCENTAGE = 100;

const GraphData = ({ amount, total, name, color }: GraphDataProps) => {
  const rate = useMemo(() => {
    if (total === 0)
      return 0;

    return Math.round((amount / total) * PERCENTAGE) / PERCENTAGE;
  }, [amount, total]);

  // Sometimes, the Math.round in rate would not be enough
  const percent = `${Math.floor(rate * PERCENTAGE)}%`;

  const graphBarStyle = useMemo(() => ({
    ...styles.graphBar,
    width: rate * GRAPH_BAR_MAX_LENGTH,
    backgroundColor: color,
  }), [rate, color]);

  const percentageStyle = useMemo(() => ({
    ...styles.percentage,
    color,
  }), [color]);

  return (
    <div style={styles.graphData}>
      <div style={styles.dataContainer}>
        <div style={styles.dataAmount}>
          {amount}
        </div>
        <div style={styles.dataName}>
          {name}
        </div>
      </div>
      <div style={styles.barWithPercent}>
        <div style={styles.graphBarContainer}>
          <div style={graphBarStyle} />
        </div>
        <div style={percentageStyle}>
          {percent}
        </div>
      </div>
    </div>
  );
};

export const NPSGraph = ({ nbPromoters, nbPassives, nbDetractors }: Props) => {
  const total = nbPromoters + nbPassives + nbDetractors;

  return (
    <div style={styles.container}>
      <GraphData
        amount={nbPromoters}
        total={total}
        color={COLORS.SUCCESS.DEFAULT}
        name={t('analytics:nps.graph.promoters')}
      />
      <GraphData
        amount={nbPassives}
        total={total}
        color={COLORS.WARNING.DEFAULT}
        name={t('analytics:nps.graph.passives')}
      />
      <GraphData
        amount={nbDetractors}
        total={total}
        color={COLORS.ERROR.DEFAULT}
        name={t('analytics:nps.graph.detractors')}
      />
    </div>
  );
};
