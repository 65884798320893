import Model from 'models/Model';

var BreakingNewsCard = {
  endpoint  : 'api/BreakingNewsCards',
  attributes: {
    text: {
      type: 'string',
    },
    notificationTime: {
      type      : 'date',
      defaultsTo: new Date('2011-04-11T10:00:30Z'),
    },
    recurringNotification: {
      type: 'boolean',
      defaultsTo: false,
    },
    notificationActive: {
      type: 'boolean',
    },
    longTextActive: {
      type: 'boolean',
    },
    activityId: {
      type: 'number',
    },
    cover: {
      type : 'pointer',
      Model: 'Image',
    },
    coverId: {
      type: 'number',
    },
    videoCover: {
      type : 'pointer',
      Model: 'Video',
    },
    videoCoverId: {
      type: 'number',
    },
    campaignId: {
      type      : 'number',
      defaultsTo: null,
    },
    campaign: {
      type      : 'pointer',
      Model     : 'Activity',
      defaultsTo: null,
    },
  },
};

export default Model.register('BreakingNewsCard', BreakingNewsCard);
