import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'Libs/redux/utils/helpers';

import type { ContentType } from 'Pages/Activity/redux/models/Content';
import { actions } from 'Pages/Activity/redux/actions';
import { getLanguageDetail } from 'Pages/Activity/redux/languageHelpers';
import {
  getSelectedActivity,
  getDefaultActivity,
  getIsAskKnowledgeTranslationLoading,
} from 'Pages/Activity/redux/selectors';

import { MSingleLanguageTranslation } from 'Components/modal';

export const useTranslateContent = () => {
  const dispatch = useDispatch();

  const [contentToTranslate, setContentToTranslate] = useState<ContentType | null>(null);

  const defaultActivity = useTypedSelector(getDefaultActivity);

  const selectedActivity = useTypedSelector(getSelectedActivity);

  const languageDetailFrom = getLanguageDetail(defaultActivity.languageId);
  const languageDetailTo = getLanguageDetail(selectedActivity.languageId);

  const isAskKnowledgeTranslationLoading = useTypedSelector(getIsAskKnowledgeTranslationLoading);

  const askTranslation = useCallback(
    (knowledgeIds: number[], languageIds: number[]) => {
      const action = actions.askTranslationBatch(knowledgeIds, languageIds);
      dispatch(action);

      // INFO it is an antipattern to access the internal 'promise' field of the action
      // instead of relying on the flags in redux state.
      // However we make an exception here as several components may dispatch this action
      // and it would require tracking each request in the redux state.
      return action.promise;
    },
    [dispatch],
  );

  const handleConfirmTranslation = useCallback(async () => {
    if (!contentToTranslate) {
      return;
    }

    const { knowledgeId } = contentToTranslate;

    await askTranslation([knowledgeId], [selectedActivity.languageId]);
    setContentToTranslate(null);
  }, [askTranslation, contentToTranslate, selectedActivity.languageId]);

  const translationModal = useMemo(
    () => (
      <MSingleLanguageTranslation
        isLoading={isAskKnowledgeTranslationLoading}
        visible={!!contentToTranslate}
        onClose={() => {
          setContentToTranslate(null);
        }}
        onConfirm={handleConfirmTranslation}
        contentId={contentToTranslate?.id}
        contentTitle={contentToTranslate?.title}
        languageFrom={languageDetailFrom.label}
        languageTo={languageDetailTo.label}
      />
    ),
    [
      contentToTranslate,
      isAskKnowledgeTranslationLoading,
      languageDetailFrom,
      languageDetailTo,
      handleConfirmTranslation,
    ],
  );

  return {
    translationModal,
    setContentToTranslate,
  };
};
