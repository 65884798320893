import type { CSSProperties } from 'aphrodite';
import { StyleSheet as Aphrodite } from 'aphrodite';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import ANIMATIONS from 'Components/foundation/animations';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export const ANIMATION_DURATION = ANIMATIONS.SPEED_NUMBER.SLOW;

const animationConfig: CSSProperties = {
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 1,
  animationFillMode: 'forwards',
  animationDuration: ANIMATIONS.SPEED.SLOW,
};

export const { enterAnimation, exitAnimation } = Aphrodite.create({
  enterAnimation: {
    ...animationConfig,
    animationName: {
      ...ANIMATIONS.TRANSFORMATIONS.FADE_IN,
    },
  },
  exitAnimation: {
    ...animationConfig,
    animationName: {
      ...ANIMATIONS.TRANSFORMATIONS.FADE_OUT,
    },
    // FIXME Remove this once exit animation on modals
    // does not cause a flicker because of modal state change
    animationDuration: '0ms',
  },
});

const styles = createStyleSheet({
  modalContent: {
    zIndex: 10010100,
    position: 'relative',
    padding: 0,
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    display: 'flex',
    flexDirection: 'column',
    width: 800,
    margin: 'auto',
    backgroundColor: COLORS.WHITE.DEFAULT,
    height: '90vh',
  },
  modalOverlay: {
    zIndex: 10010000,
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogModal: {
    position: 'relative',
    width: 400,
    overflow: 'visible',
    bottom: 'unset',
    height: 'auto',
  },
  content: {
    overflow: 'scroll',
  },
  dialogContent: {
    padding: '0px 24px',
  },
  dialogHeader: {
    padding: '32px 24px 24px 24px',
  },
  header: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 32,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
    minHeight: 25,
  },
  spacer: {
    marginBottom: 32,
  },
  description: {
    ...TYPOGRAPHY.BODY3,
    marginTop: 8,
  },
  body: {
    flexGrow: 1,
    overflow: 'scroll',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
  },
  buttonWrapper: {
    marginLeft: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: 8,
  },
  error: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DEFAULT,
  },
  exitCross: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
});

export default styles;
