import { mergeDeep } from 'Libs/mergeDeep';

export type SegmentationDimensionType = {
  id: number,
  color: string,
  typeId: number,
};

export default function createSegmentationDimension(values?: any): SegmentationDimensionType {
  const defaultState: SegmentationDimensionType = {
    id: 0,
    color: '',
    typeId: 0,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep<SegmentationDimensionType>(defaultState, values);
}
