import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams, AlertType } from '../redux/models/Alert';
import { actions } from '../redux/actions';

import styles from './CategoryListAlertView.style';

type Props<T = unknown> = {
  component: React.ComponentType<T>;
};

enum Types {
  FetchError = 'error',
  DeleteSuccess = 'success',
}

const ALERT_SCOPES: Array<'fetchError' | 'deleteSuccess'> = ['fetchError', 'deleteSuccess'];

export const IDS: { [key in 'fetchError' | 'deleteSuccess']: number } = ALERT_SCOPES.reduce(
  (acc, scope, index) => ({ ...acc, [scope]: index }),
  {} as { [key in 'fetchError' | 'deleteSuccess']: number },
);

export const SCOPES: { [index: number]: 'fetchError' | 'deleteSuccess' } = ALERT_SCOPES.reduce(
  (acc, scope, index) => ({ ...acc, [index]: scope }),
  {} as { [index: number]: 'fetchError' | 'deleteSuccess' },
);

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const message = params.message || '';

  switch (scope) {
    case 'fetchError':
      return t('config_categories:category_list_alert_view.alert_fetch_error', { message });
    case 'deleteSuccess':
      return t('config_categories:category_list_alert_view.alert_delete_success');

    default:
      return '';
  }
};

const makeAlertsFromStateAlerts = (alerts: ReadonlyArray<AlertType>) => {
  const TITLES = {
    fetchError: t('config_categories:category_list_alert_view.fetch_error'),
    deleteSuccess: '',
  };

  return alerts.map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: alert.scope === 'deleteSuccess' ? 'success-circle' : 'alert',
    type: alert.scope === 'deleteSuccess' ? Types.DeleteSuccess : Types.FetchError,
  }));
};
export const CategoryListAlertView = ({ component }: Props) => {
  const stateAlerts = useTypedSelector((state) => state.pages.categoryList.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return <AlertHOC alerts={alerts} onClose={onClose} />;
};
