import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  emailInput: {
    marginTop: 23,
    paddingBottom: 16,
  },
  phoneInput: {
    marginTop: 38,
    width: '100%',
  },
  learnAboutLink: {
    marginTop: 8,
  },
  languageSelect: {
    marginTop: 39,
  },
  roleInput: {
    marginTop: 23,
    zIndex: 1000,
  },
});

export default styleSheet;
