import { t } from 'i18next';
import Enum from 'models/Enum';

import Model from 'models/Model';

const statusLabel = {
  [Enum.contentStatus.DRAFT]: () => t('contents:content_status.draft'),
  [Enum.contentStatus.TO_VALIDATE]: () => t('contents:content_status.to_validate'),
  [Enum.contentStatus.VALIDATED]: () => t('contents:content_status.validated'),
  [Enum.contentStatus.ARCHIVED]: () => t('contents:content_status.archived'),
}

var ContentStatus = {
  endpoint  : 'api/ContentStatuses',
  attributes: {
    label: {
      type: 'string',
    },
  },
  methods: {
    label: function label() {
      return statusLabel[this.id() || Enum.contentStatus.DRAFT]();
    },
  } 
};

export default Model.register('ContentStatus', ContentStatus);
