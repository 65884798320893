// @flow

export default {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'transparent',
    minHeight: 50,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 6,
  },
  chip: {
    marginTop: 4,
    marginRight: 6,
  },
};
