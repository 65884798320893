import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import App from 'Models/App';
import createSegmentationGroupItem from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import { useTypedDispatch } from 'Libs/redux/utils';
import type { Filter, FilterCategory } from 'Libs/filter/types';
import type { Source } from 'Libs/ts/types';

import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';
import SAccordion from 'Components/structural/SAccordion/SAccordion';
import type { ContributorType } from 'Pages/Contributor/redux/models/Contributor';

import { actions, useModuleSelector } from '../../../redux';

import styles from './ContributorAdvancedOptionsForm.style';

const getSegmentation = (): { [groupId: number]: SegmentationGroupItemType } => {
  const { userSegmentationItems } = App as any;
  const filteredSegmentation: Array<any> = userSegmentationItems().toJSON();
  const groups = filteredSegmentation.map((segItem) => segItem.group);

  return groups.reduce((map, group) => ({ ...map, [group.id]: createSegmentationGroupItem(group) }), {});
};

const getDimensions = (): Array<{ id: number; label: string }> => {
  const { segmentationDimensions } = App as any;

  return segmentationDimensions().items.map((dimension: any) => ({
    id: dimension.id(),
    label: dimension.getLabel().label(),
  }));
};

export const ContributorAdvancedOptionsForm = () => {
  const dispatch = useTypedDispatch();
  const appSegmentation = getSegmentation();
  const dimensions = getDimensions();
  const contributorToManage: ContributorType = useModuleSelector(
    (state) => state.manageContributorModal.contributorToManage,
  );

  const { segmentation } = contributorToManage;

  const filters: Array<Filter> = useMemo(() => {
    const currentIds = segmentation.map(({ id }) => id);
    const items = Object.keys(appSegmentation).map((groupIdKey: string) => {
      const groupId = parseInt(groupIdKey, 10);
      const { id, label, dimension } = appSegmentation[groupId];
      const { color, id: dimensionId } = dimension;
      const selected = currentIds.includes(id);
      const category: FilterCategory = 'segment';

      return { category, id, value: label, selected, color, dimensionId };
    });

    return [{ category: 'segment', type: 'multiselect', items }];
  }, [segmentation, appSegmentation]);

  const sources: Array<Source> = [
    {
      icon: undefined,
      name: '',
      filters,
    },
  ];

  const handleUpdateSegmentation = useCallback(
    (source: Source) => {
      const filterSegment = source.filters.find((filter) => filter.category === 'segment') || { items: [] };

      const result: Array<SegmentationGroupItemType> = filterSegment.items
        .filter(({ selected }) => selected)
        .map(({ id }) => createSegmentationGroupItem(appSegmentation[id]));

      return dispatch(actions.setContributorSegmentation(result));
    },
    [dispatch, appSegmentation],
  );

  const suggestionOptions = {
    enable: true,
    enableOnEmptyInput: false,
    enableTextSuggestion: false,
    maxSuggestionBoxHeight: 190,
  };

  return (
    <SAccordion headerText={t('config_contributors:contributors.modal.advanced')} style={styles.accordion}>
      <SOmnibox
        sources={sources}
        placeholder={t('config_contributors:contributors.modal.segment')}
        onFilterChanged={handleUpdateSegmentation}
        suggestionOptions={suggestionOptions}
        dimensions={dimensions}
        filterMultiText={false}
      />
    </SAccordion>
  );
};
