import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';

import { useAppDispatch } from 'Libs/redux/store';
import { useTypedSelector } from 'Libs/redux/utils';
import type { Filter, FilterItem } from 'Libs/filter/types';

import { actions } from 'Store/entities/audience-group/audience-group.slice';
import { selectAll } from 'Store/entities/audience-group/audience-group.selectors';
import { AudienceGroupEntity } from 'Store/entities/audience-group/audience-group.types';

import { enqueueBasicAlert } from 'Pages/AlertManager';

import { DeleteAudienceGroupModal } from 'Features/audience/ui/components/modals/DeleteAudienceGroupModal/DeleteAudienceGroupModal';
import { getMetaForTooltip } from 'Features/audience/pages/AudienceGroupList/components/utils';
import { useDeleteAudienceGroupModal } from 'Features/audience/hooks/useDeleteAudienceGroupModal';
import UpdateAudienceGroupModal from 'Features/audience/ui/components/modals/UpdateAudienceGroupModal/UpdateAudienceGroupModal';
import { getAudienceGroupFactory } from 'Features/audience/services/audience-group-list.service';
import CreateAudienceGroupModal from 'Features/audience/ui/components/modals/CreateAudienceGroupModal/CreateAudienceGroupModal';
import { useConfigCreateAudienceGroupModal } from 'Features/audience/pages/AudienceGroupList/hooks/useConfigCreateAudienceGroupModal';

import SPageHeader from 'Components/structural/SPageHeader/SPageHeader';
import SAudienceGroupCard from 'Components/structural/SAudienceGroupCard/SAudienceGroupCard';
import { ChipItem } from 'Components/structural/STruncatedChipList/STruncatedChipList';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SDisplayControlItems from 'Components/structural/SDisplayControlItems/SDisplayControlItems';
import UFilterSelect from 'Components/unit/UFilterSelect/UFilterSelect';
import { SFooter } from 'Components/structural/SFooter/SFooter';

import styleSheet from './AudienceGroupConfiguration.style';

const SORT_BY = {
  LAST_EDITED: 1,
  ALPHABETICAL: 2,
};

const PAGINATION_LENGTH = 12;

const SORT_FIELD = {
  [SORT_BY.LAST_EDITED]: 'editionDate',
  [SORT_BY.ALPHABETICAL]: 'name',
} as const;

const SORT_ORDER = {
  [SORT_BY.LAST_EDITED]: 'desc',
  [SORT_BY.ALPHABETICAL]: 'asc',
} as const;

export const AudienceGroupConfiguration = () => {
  const dispatch = useAppDispatch();
  const [offsetArg, setOffsetArg] = useState(0);
  const [moreData, setMoreData] = useState(true);
  const audienceGroups = useTypedSelector<AudienceGroupEntity[]>((state) => selectAll(state.entities.audienceGroup));
  const [total, setTotal] = useState(audienceGroups.length || 0);

  const handleDeleteAudienceGroupDone = useCallback(
    (id: number) => {
      dispatch(actions.deleteAudienceGroup(id));
    },
    [dispatch],
  );

  const { deleteAudienceGroupModalProps, openDeleteModal } = useDeleteAudienceGroupModal({
    onDone: handleDeleteAudienceGroupDone,
  });

  const [selectedAudienceGroupId, setSelectedAudienceGroupId] = useState<number | null>(null);

  const handleCloseUpdateModal = useCallback(() => {
    setSelectedAudienceGroupId(null);
  }, []);

  const openUpdateModal = useCallback((id) => {
    setSelectedAudienceGroupId(id);
  }, []);

  const [filterState, setFilterState] = useState('');

  const defaultFilter: Filter = {
    category: 'sort',
    type: 'select',
    items: [
      {
        category: 'sort',
        selected: true,
        value: t('courses:filters.sort_last_edited'),
        id: SORT_BY.LAST_EDITED,
      },
      {
        category: 'sort',
        selected: false,
        value: t('courses:filters.sort_alphabetical'),
        id: SORT_BY.ALPHABETICAL,
      },
    ],
  };

  const [sortFilter, setSortFilter] = useState<Filter>(defaultFilter);

  const sortFilterName = useMemo(() => {
    const filter = sortFilter.items.find((item) => item.selected);
    return filter ? { order: SORT_ORDER[filter.id], field: SORT_FIELD[filter.id] } : null;
  }, [sortFilter]);

  const refreshAudienceGroups = useCallback(async () => {
    const sorted = sortFilter.items.find((item) => item.selected) as FilterItem;
    const fetchAudience = getAudienceGroupFactory(dispatch);

    try {
      const response = await fetchAudience({
        offset: offsetArg,
        limit: PAGINATION_LENGTH,
        selectedSort: {
          ...sorted,
          order: sortFilterName?.order,
          field: sortFilterName?.field,
        },
        status: 'all',
        title: filterState ? filterState : null,
      });

      if (response && response.items.length + audienceGroups.length < response.total) {
        setMoreData(true);
        return;
      }
    } catch (error) {
      console.error('AUDIENCE_CONFIGURATION_FETCH_ERROR: ', error);
      enqueueBasicAlert({
        id: 'AUDIENCE_CONFIGURATION_FETCH_ERROR',
        text: 'Error while retrieving audience groups in configuration.',
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
      return;
    }

    setMoreData(false);
  }, [dispatch, audienceGroups, offsetArg, filterState, sortFilter, moreData, sortFilterName]);

  useEffect(() => {
    refreshAudienceGroups();
  }, []);

  const handleUpdateAudienceGroup = useCallback(async () => {
    setSelectedAudienceGroupId(null);
    refreshAudienceGroups();
  }, []);

  useEffect(() => {
    setTotal(audienceGroups.length);
  }, [audienceGroups]);

  const { showCreateAudienceGroupModal, createButtonProps, handleCreateGroupModalToggle, handleOnCreateGroupConfirm } =
    useConfigCreateAudienceGroupModal({ refreshAudienceGroups: refreshAudienceGroups });

  const handleFilterChange = useCallback(
    (filter: string) => {
      setFilterState(filter);
    },
    [setFilterState],
  );

  const handleSortFilterChange = useCallback(
    (filter: Filter) => {
      setSortFilter(filter);
    },
    [setSortFilter, refreshAudienceGroups],
  );

  useEffect(() => {
    refreshAudienceGroups();
  }, [sortFilter, offsetArg, filterState]);

  const handleViewMoreClick = useCallback(() => {
    setOffsetArg(audienceGroups.length);
  }, [audienceGroups]);

  return (
    <div style={styleSheet.wrapper}>
      <SPageHeader
        dynamicText={`${total} groups`}
        subtitle=" "
        subtitleSize="L"
        horizontal
        title={t('menu:audience_groups')}
        iconProps={createButtonProps}
        style={styleSheet.pageHeader}
      />
      <div>
        <SDisplayControlItems
          tabs={[]}
          filterValue={filterState}
          onFilterChange={handleFilterChange}
          filterPlaceholder={t('audiences:audience_filter_placeholder')}
        />
      </div>

      <div style={styleSheet.optionsWrapper}>
        <UFilterSelect
          allowNoChoice={false}
          filter={sortFilter}
          onFilterChanged={handleSortFilterChange}
          style={styleSheet.select}
        />
      </div>

      <div style={styleSheet.audienceGroupList}>
        {audienceGroups.map((group) => (
          <SAudienceGroupCard
            key={group.groupId}
            groupId={group.groupId}
            name={group.name}
            description={group.description}
            meta={{
              explanation: t('audiences:audience_group_card.tooltip.explanation'),
              target: getMetaForTooltip(group.meta, 'configuration'),
            }}
            variant="none"
            segmentations={group.segmentations as ChipItem[] | undefined}
            onUpdate={openUpdateModal}
            onDelete={openDeleteModal}
          />
        ))}
      </div>
      {moreData && (
        <div style={{ margin: 'auto', marginBottom: '84px' }}>
          <SFooter>
            <UTextLink
              text={t('courses:view_more')}
              onClick={handleViewMoreClick}
              type="secondary"
              rightIcon="bottom-chevron"
            />
          </SFooter>
        </div>
      )}
      <CreateAudienceGroupModal
        visible={showCreateAudienceGroupModal}
        onClose={handleCreateGroupModalToggle}
        onConfirm={handleOnCreateGroupConfirm}
      />
      <DeleteAudienceGroupModal {...deleteAudienceGroupModalProps} />
      {!!selectedAudienceGroupId && (
        <UpdateAudienceGroupModal
          audienceGroupId={selectedAudienceGroupId}
          onClose={handleCloseUpdateModal}
          onConfirm={handleUpdateAudienceGroup}
        />
      )}
    </div>
  );
};

export default AudienceGroupConfiguration;
