import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const CONTENT_TEXT_AREA_WRAPPER_RADIUS = 8;

export default createStyleSheet({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  container: {
    width: '750px',
    height: '100%',
    marginTop: '40px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    marginTop: '32px',
  },
  label: {
    ...TYPOGRAPHY.HERO_M_SEMIBOLD,
  },
  subLabel: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  sectionSeparation: {
    marginTop: '24px',
  },
  smallSeparation: {
    marginTop: '12px',
  },
  titleSectionSeparation: {
    marginTop: '16px',
  },
  imageTextSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageTextArea: {
    marginBottom: '21px',
    width: '587px',
  },
  rightSpacing: {
    marginRight: '24px',
  },
});
