import type { Alert } from './reducer';

type AlertManagerActionParams<T, P> = {
  type: T;
  payload: P;
};

export const ALERT_MANAGER_ACTIONS = {
  ENQUEUE: 'ALERT_MANAGER_ENQUEUE',
  REMOVE: 'ALERT_MANAGER_REMOVE',
  RESET: 'ALERT_MANAGER_RESET',
};

export enum SupportedAlertTypes {
  BASIC = 'basic',
  TOAST = 'toast',
}

// ACTIONS
export type AlertManagerEnqueueAction = AlertManagerActionParams<
  'ALERT_MANAGER_ENQUEUE',
  Alert & { alertType: SupportedAlertTypes }
>;

export type AlertManagerRemoveAction = AlertManagerActionParams<
  'ALERT_MANAGER_REMOVE',
  { id: string; alertType: SupportedAlertTypes }
>;

export type AlertManagerResetAction = AlertManagerActionParams<'ALERT_MANAGER_RESET', null>;

export const actions = {
  enqueue: (alertType: SupportedAlertTypes, params: Alert): AlertManagerEnqueueAction => ({
    type: 'ALERT_MANAGER_ENQUEUE',
    payload: { ...params, alertType },
  }),
  remove: (alertType: SupportedAlertTypes, id: string): AlertManagerRemoveAction => ({
    type: 'ALERT_MANAGER_REMOVE',
    payload: { alertType, id },
  }),
  reset: (): AlertManagerResetAction => ({ type: 'ALERT_MANAGER_RESET', payload: null }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type AlertManagerAction = ReturnType<ActionsFn>;
