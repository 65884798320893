import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { CSSProperties } from 'react';

const borderRadius = 8;

const styles: Record<string, CSSProperties> = {
  containGlobal: {
    userSelect: 'none',
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-flex',
  },
  containPicture: {
    borderRadius,
  },
  containOverlay: {
    borderRadius,
    boxShadow: `0 0 0 3px ${COLORS.PRIMARY.HOVER}`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...TYPOGRAPHY.BODY3,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  placeholder: {
    borderRadius,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
  },
  link: {
    color: COLORS.WHITE.DEFAULT,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  linearGradient: {
    background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0px, rgba(0,0,0,0) 40px)',
  },
  source: {
    ...TYPOGRAPHY.BODY4,
    opacity: 0.8,
    color: COLORS.WHITE.DEFAULT,
    fontStyle: 'italic',
    margin: '0 10px 10px 0px',
    width: 62,
  },
  linkContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
  },
};

export default styles;
