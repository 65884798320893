import { useEffect, useState, useRef } from 'react';
import type { Ref } from 'react';

const defaultObserverSettings: IntersectionObserverInit = {
  threshold: [1],
};

export const useDetectSticky = (observerSettings = defaultObserverSettings) => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<Element>();

  useEffect(() => {
    const cachedRef = ref.current;

    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      observerSettings,
    );

    observer.observe(cachedRef as Element);

    return () => {
      observer.unobserve(cachedRef as Element);
    };
  }, [observerSettings, ref]);

  return [isSticky, ref as Ref<any>, setIsSticky] as const;
};
