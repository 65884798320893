import { ComparisonSegment } from '../types';
import { splitTextIntoWords } from './splitTextIntoWords.utils';

/**
 * Compares two texts word by word and returns segments where they differ and concur.
 * The logic that defines a word might omit characters but those characters are still ultimately counted in the segments
 * The segments are specified by start index for both original texts
 */
export const getTextComparisonSegments = (correctText: string, wrongText: string): ComparisonSegment[] => {
  // An empty wrong text is treated the same as being equal to the correct text
  if (wrongText === '') {
    return [
      { correct: correctText, correctOriginalPosition: 0, wrong: correctText, wrongOriginalPosition: 0, areSame: true },
    ];
  }

  const correctTextWords = splitTextIntoWords(correctText);
  const wrongTextWords = splitTextIntoWords(wrongText);

  // Comparison assuming equal length of correct and wrong text in terms of words
  const naiveComparison = correctTextWords.reduce<ComparisonSegment[]>((acc, wordWithPosition, index) => {
    const correctWord = wordWithPosition.word;
    const wrongWord = wrongTextWords[index]?.word;
    const isWordTheSame = correctWord === wrongWord;

    // Begin the first segment
    if (index === 0) {
      return [
        {
          correct: correctWord,
          correctOriginalPosition: wordWithPosition.originalStartPosition,
          wrong: wrongWord ?? null,
          wrongOriginalPosition: wrongTextWords[index]?.originalStartPosition ?? null,
          areSame: isWordTheSame,
        },
      ];
    }

    // Continue a segment only if going from a correct word pair to another
    if (acc.slice(-1)[0].areSame && isWordTheSame) {
      const currentSegment = acc.slice(-1)[0];
      return acc.slice(0, -1).concat({
        ...currentSegment,
        correct: currentSegment.correct + ' ' + correctWord,
        wrong: currentSegment.wrong + ' ' + wrongWord,
        areSame: isWordTheSame,
      });
    }

    // In every other case, create a new segment
    return acc.concat({
      correct: correctWord,
      correctOriginalPosition: wordWithPosition.originalStartPosition,
      wrong: wrongWord ?? null,
      wrongOriginalPosition: wrongTextWords[index]?.originalStartPosition ?? null,
      areSame: isWordTheSame,
    });
  }, []);

  // If wrong words are more than correct words, we add them at the end here
  if (wrongTextWords.length > correctTextWords.length) {
    return naiveComparison.concat(
      wrongTextWords.slice(correctTextWords.length).map((extraWrongWord) => ({
        correct: null,
        correctOriginalPosition: null,
        wrong: extraWrongWord.word,
        wrongOriginalPosition: extraWrongWord.originalStartPosition,
        areSame: false,
      })),
    );
  }
  // If correct words are longer, everything is handled in the reduce
  return naiveComparison;
};
