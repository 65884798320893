import Enum from 'Models/Enum';

import type { ActivityState } from './reducer';
import type { AlertValidationField } from './models/Alert';
import type { ActivityType } from './models/Activity';
import type { DailySerieDayType } from './models/DailySerieDay';

type SchedulingError = {
  languageId: number;
  reason: AlertValidationField;
};

const getDefaultActivity = (state: ActivityState) => {
  const defaultActivity = state.activities.find((a) => a.isDefault);

  if (!defaultActivity) {
    throw new Error('default activity was not found');
  }

  return defaultActivity;
};

export const getCampaignInfoErrors = (state: ActivityState): ReadonlyArray<AlertValidationField> => {
  const defaultActivity = getDefaultActivity(state);

  const result: Array<AlertValidationField> = [];

  if (!defaultActivity.name) {
    result.push('noName');
  }

  if (!defaultActivity.description) {
    result.push('noDescription');
  }

  if (!defaultActivity.coverId) {
    result.push('noCover');
  }

  return result;
};

export const getSchedulingErrors = (state: ActivityState): ReadonlyArray<SchedulingError> => {
  const { multilingualContentsMap } = state;
  const defaultActivity = getDefaultActivity(state);

  const emptyDayErrors: ReadonlyArray<SchedulingError> = defaultActivity.dailySerie.days.some(
    (day: DailySerieDayType) => day.dailySerieContents.length === 0,
  )
    ? [{ languageId: defaultActivity.languageId, reason: 'emptyDay' }]
    : [];

  const secondaryActivities = state.activities.filter((a: ActivityType) => !a.isDefault);
  const translationErrors = secondaryActivities.reduce((acc: ReadonlyArray<SchedulingError>, a) => {
    const { languageId } = a;
    const isInvalid = a.dailySerie.days.some((day, i) =>
      day.dailySerieContents.some((dsContent, j) => {
        const defaultActivityContentId = defaultActivity.dailySerie.days[i].dailySerieContents[j].contentId;

        /**
         * `multilingualContentsMap` always has the most up to date contents and should only be used when
         * adding new content to the campaign. Otherwise, use directly the one received from the database.
         */
        const content =
          languageId === dsContent.content.languageId
            ? dsContent.content
            : multilingualContentsMap[dsContent.content.multilingualId]?.[a.languageId];

        // FIXME check with product if we need a loader?
        if (!content) {
          return false;
        }

        if (content.id === defaultActivityContentId || content.statusId !== Enum.contentStatus.VALIDATED) {
          return true;
        }

        return false;
      }),
    );

    return isInvalid ? acc.concat([{ languageId: a.languageId, reason: 'nonValidateContent' }]) : acc;
  }, []);

  return emptyDayErrors.concat(translationErrors);
};

export const getAudienceErrors = (state: ActivityState): ReadonlyArray<AlertValidationField> =>
  state.audience.mode ? [] : ['noTargetMode'];
