import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    padding: '13px 12px 13px 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  sideContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  arrow: {
    marginLeft: 16,
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: 187,
    borderRight: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    marginRight: 16,
    marginLeft: 16,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    ...TYPOGRAPHY.H2_SEMIBOLD,
  },
  identifier: {
    display: 'flex',
    flexDirection: 'row',
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  languageWrapper: {
    position: 'relative',
  },
  languageSelector: {
    // Absolute + negative margin is needed because because if not the options appear inside of the whole container
    position: 'absolute',
    top: -24,
  },
  wrapperDivider: {
    borderRight: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  translationButton: {
    marginLeft: 16,
    marginRight: 16,
  },
  lastSavedSection: {
    marginLeft: 48,
    marginRight: 16,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  saveButton: {
    marginRight: 16,
  },
  optionsMenu: {
    position: 'relative',
  },
});

export default styleSheet;
