import { StyleSheet as Aphrodite } from 'aphrodite';

// TODO: Aphrodite is unmaintained and css-in-js libraries are heavy, remove this and aphrodite when we can.
export const { StyleSheet, css } = Aphrodite.extend([{
  // Add rough support for descendant selectors to aphrodite.
  selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
    if (selector[0] === '>') {
      const tag = selector.slice(1);
      const nestedTag = generateSubtreeStyles(`${baseSelector} ${tag}`);
      return nestedTag;
    }

    return null;
  }
}]);
