/**
 * Displays a div with a given background
 *
 * Props:
 * - name: name of the background to display
 * - height: height of the background
 * - width: width of the background
 * - color: color of the background
 * - borderRadius: number to add a border radius to the UBackground
 * - style: to override component's style
 */

import React, { CSSProperties } from 'react';

import { COLORS } from 'Components/foundation';
import { getCdnUrl } from 'Libs/cdn';

import styles from './UBackground.style';

export const BACKGROUND = {
  'shape-background': getCdnUrl('common', '/back-office-illustrations/shape-background.svg'),
};

export type BackgroundName = keyof typeof BACKGROUND;

export const BACKGROUND_NAMES = Object.keys(BACKGROUND) as BackgroundName[];

export type BackgroundColorsType = 'PRIMARY' | 'DISABLED' | 'SECONDARY';

export type UBackgroundProps = {
  name: BackgroundName;
  height?: string | number;
  width?: string | number;
  color?: BackgroundColorsType;
  noBackground?: boolean;
  borderRadius?: number;
  style?: CSSProperties;
};

const { PRIMARY_LIGHT, PRIMARY, SECONDARY, GREY_LIGHT, TEXT } = COLORS;

const COLORS_BACKGROUND: Record<BackgroundColorsType, { background: string; shape: string }> = {
  PRIMARY: {
    background: PRIMARY_LIGHT.DEFAULT,
    shape: PRIMARY.DEFAULT,
  },
  SECONDARY: {
    background: SECONDARY.LIGHTER,
    shape: SECONDARY.HOVER,
  },
  DISABLED: {
    background: GREY_LIGHT.HOVER,
    shape: TEXT.DISABLED_DEFAULT,
  },
};

export const UBackground = ({
  name,
  height = '100%',
  width = '100%',
  borderRadius = undefined,
  color = 'PRIMARY',
  noBackground = false,
  style = {},
}: UBackgroundProps) => (
  <div
    style={{
      height,
      width,
      borderRadius,
      backgroundColor: noBackground ? 'transparent' : COLORS_BACKGROUND[color].background,
      ...style,
    }}
  >
    <div
      style={{
        ...styles.mask,
        WebkitMaskImage: `url(${BACKGROUND[name]})`,
        maskImage: `url(${BACKGROUND[name]})`,
        borderRadius,
        backgroundColor: COLORS_BACKGROUND[color].shape,
      }}
    />
  </div>
);

export default UBackground;
