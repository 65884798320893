import { createLinkedList, SimpleLinkedList } from '@sparted/shared-library/linked-list';

import type { FetchCourseResponse } from '../redux';

type MicroKnowledgeBase = {
  id: string;
  title: string | null;
  chapterId: string | null;
  previousId: string | null;
  nextId: string | null;
};

export type MicroKnowledgeChapter = MicroKnowledgeBase & {
  type: 'chapter';
  isCollapsed: boolean;
};

export type MicroKnowledgeText = MicroKnowledgeBase & {
  type: 'text';
  content: string;
};

export type MicroKnowledgeImage = MicroKnowledgeBase & {
  type: 'image';
  content: string;
  image?: {
    id: number;
    url: string;
  };
};

export type MicroKnowledgeVideo = MicroKnowledgeBase & {
  type: 'video';
  video?: {
    id: number;
    url: string;
    thumbnailUrl: string;
  };
};

export type MicroKnowledgeItem = MicroKnowledgeChapter | MicroKnowledgeText | MicroKnowledgeImage | MicroKnowledgeVideo;

export type MicroKnowledgeListType = SimpleLinkedList<MicroKnowledgeItem>;

// Type guards
export const isMkChapter = (item: any): item is MicroKnowledgeChapter => item?.type === 'chapter';
export const isMkText = (item: any): item is MicroKnowledgeText => item?.type === 'text';
export const isMkImage = (item: any): item is MicroKnowledgeImage => item?.type === 'image';
export const isMkVideo = (item: any): item is MicroKnowledgeVideo => item?.type === 'video';

export const computeMetaData = (list: FetchCourseResponse['microKnowledges']): MicroKnowledgeListType => {
  const { items, headId } = list;
  const { itemsWithMeta } = createLinkedList(headId, items).reduce(
    (acc, current) => {
      const { previousId } = acc;
      const newItemsWithMeta = {
        ...acc.itemsWithMeta,
        [current.id]: {
          ...current,
          previousId,
          ...(isMkChapter(current) ? { isCollapsed: true } : {}),
        },
      };

      return {
        itemsWithMeta: newItemsWithMeta,
        previousId: current.id,
      };
    },
    {
      itemsWithMeta: {},
      previousId: null as string | null,
    },
  );

  return {
    headId: list.headId,
    items: itemsWithMeta,
  };
};

export const getChildrenOfChapter = (
  { headId, items }: MicroKnowledgeListType,
  { id, nextId }: MicroKnowledgeChapter,
): string[] => {
  const childIds =
    nextId !== null ? createLinkedList(headId, items).while(({ chapterId }) => id === chapterId, nextId) : [];

  return childIds as string[];
};

export const insertMkItemInList = ({ headId, items }: MicroKnowledgeListType, item: MicroKnowledgeItem) => {
  const listWithDiff = createLinkedList(headId, items).applyDiff(null, item);

  return {
    headId: listWithDiff.head?.id || null,
    items: listWithDiff.items,
  };
};

export const deleteMkItemInList = (list: MicroKnowledgeListType, item: MicroKnowledgeItem) => {
  const { headId, items } = list;
  const linkedList = createLinkedList(headId, items);

  if (isMkChapter(item)) {
    const children = getChildrenOfChapter(list, item);
    const linkedListWithoutChapter = linkedList.applyDiff(item, null);

    const updatedList = children.reduce((currentList, id) => {
      const child = currentList.items[id];

      return currentList.applyDiff(child, null);
    }, linkedListWithoutChapter);

    return {
      headId: updatedList.head?.id || null,
      items: updatedList.items,
    };
  }

  const updatedList = linkedList.applyDiff(item, null);

  return {
    headId: updatedList.head?.id || null,
    items: updatedList.items,
  };
};

export const editMkItemInlist = (
  list: MicroKnowledgeListType,
  previousState: MicroKnowledgeItem,
  nextState: MicroKnowledgeItem,
) => {
  const { headId, items } = list;
  const linkedList = createLinkedList(headId, items);

  // If previousState is a chapter & nextState is not -> generate list of child to edit and remove chapterId
  if (isMkChapter(previousState) && !isMkChapter(nextState)) {
    const childrenIds = getChildrenOfChapter(list, previousState);
    const listWithUpdatedChapter = linkedList.applyDiff(previousState, nextState);

    const updatedList = childrenIds.reduce((currentList, id) => {
      const child = currentList.items[id];

      return currentList.applyDiff(child, { ...child, chapterId: null });
    }, listWithUpdatedChapter);

    return {
      headId: updatedList.head?.id || null,
      items: updatedList.items,
    };
  }

  const updatedList = linkedList.applyDiff(previousState, nextState);

  return {
    headId: updatedList.head?.id || null,
    items: updatedList.items,
  };
};
