import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
    width: '100%',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    border: '1px solid #ededed',
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    alignItems: 'center',
  },
  omniboxWrapper: {
    display: 'flex',
    flex: 1,
    maxWidth: '468px',
    minWidth: '284px',
  },
  omnibox: {
    marginRight: 6,
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
  },
  divider: {
    flex: 0,
    marginRight: 12,
  },
  secondarylistWrapper: {
    minWidth: '499px',
    height: '32px',
  },
  secondarylistWrapperAlternative: {
    minWidth: '499px',
    maxWidth: '681px',
    height: '32px',
  },
  iconButton: {
    marginRight: 6,
  },
  secondaryTabList: {
    height: '32px',
  },
});

export default styleSheet;
