/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-multi-comp */

// @flow

import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import App from 'Models/App';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { tracking } from 'Services/trackingService';

import { COLORS } from 'Components/foundation';
import UButton from 'Components/unit/UButton/UButton';
import SLargeCallout from 'Components/structural/SLargeCallout/SLargeCallout';
import STooltip from 'Components/structural/STooltip/STooltip';

import { BackModal } from './components/BackModal/BackModal';
import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';

import { KnowledgeTranslationAlertView } from './KnowledgeTranslationAlertView/KnowledgeTranslationAlertView';

import { actions } from './redux';
import type { TranslatedItem } from './redux';

import styles from './KnowledgeTranslationHeader.style.js';

/* eslint-disable react/no-unused-prop-types -- Props are injected later on */
type Props = {|
  itemsToTranslate: $ReadOnlyArray<TranslatedItem>,
  isModified: boolean,
  knowledgeTitle: string,
  originalLanguageId: number,
  translatedLanguageId: number,
  disablePreviewButton: boolean,
  disableConfirmButton: boolean,
  onSave: () => Promise<any>,
  onPreview: () => void,
  onValidate: () => Promise<any>,
  onTranslationResults: (items: $ReadOnlyArray<TranslatedItem>) => void,
  onTranslationRequestStateChange: (loading: boolean) => void,
  onBack: () => void,
|};
/* eslint-enable react/no-unused-prop-types */

function getLanguageCode(id: number) {
  const label = App.segmentationItems().items.find((item) => {
    return item.group().id() === id;
  }).group().label();

  return App.AvailableSegmentationGroups().items.find((item) => {
    return item.label() === label;
  }).data().split('-')[0];
}

// eslint-disable-next-line complexity
const KnowledgeTranslationHeaderComp = (props: Props) => {
  const {
    onSave,
    onPreview,
    onValidate,
    isModified,
    knowledgeTitle,
    originalLanguageId,
    translatedLanguageId,
    onBack,
    itemsToTranslate,
    onTranslationResults,
    onTranslationRequestStateChange,
    disablePreviewButton,
    disableConfirmButton,
  } = props;
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const [showBackModal, setShowBackModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [calloutIsGreen, setCalloutIsGreen] = useState(false);

  const loadingSave = useTypedSelector(state => state.pages.knowledgeTranslation.loadingSave);
  const loadingValidation = useTypedSelector(state => state.pages.knowledgeTranslation.loadingValidation);
  const loadingAutoTranslate = useTypedSelector(state => state.pages.knowledgeTranslation.loadingAutoTranslate);

  const languageFrom = useMemo(() => getLanguageCode(originalLanguageId), [originalLanguageId]);
  const languageTo = useMemo(() => getLanguageCode(translatedLanguageId), [translatedLanguageId]);

  const numCharacters = useMemo(() => {
    return itemsToTranslate.reduce((acc, item) => {
      return acc + item.value.length;
    }, 0);
  }, [itemsToTranslate]);

  useEffect(() => {
    window.addEventListener('popstate', onBack);

    return () => {
      window.removeEventListener('popstate', onBack);
    };
  }, [onBack]);

  const onClickBack = useCallback(() => {
    if (isModified)
      setShowBackModal(true);
    else
      onBack();
  }, [isModified, onBack]);
  const onBackAction = useCallback(async (save: boolean) => {
    if (save)
      await dispatch(actions.save(onSave));

    onBack();
  }, [dispatch, onBack, onSave]);

  const onCloseBackModal = useCallback(() => {
    setShowBackModal(false);
  }, [setShowBackModal]);

  const onCloseTranslateModal = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const onConfirmPress = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const onConfirmAction = useCallback(() => {
    dispatch(actions.validateTranslation(onValidate));
  }, [dispatch, onValidate]);

  const onSaveCallback = useCallback(() => {
    dispatch(actions.save(onSave));
  }, [dispatch, onSave]);

  const onTranslatePress = useCallback(() => {
    if (loadingAutoTranslate)
      return;

    tracking.knowledgeAutoTranslated({
      sourceLanguage: languageFrom,
      targetLanguage: languageTo,
      numCharacters,
    });

    dispatch(actions.autoTranslate(itemsToTranslate, originalLanguageId, translatedLanguageId));
  }, [
    dispatch,
    itemsToTranslate,
    originalLanguageId,
    translatedLanguageId,
    loadingAutoTranslate,
    languageFrom,
    languageTo,
    numCharacters,
  ]);

  const translatedItems = useTypedSelector(state => state.pages.knowledgeTranslation.translatedItems);

  useEffect(() => {
    onTranslationResults(translatedItems);
  }, [translatedItems, onTranslationResults]);

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    onTranslationRequestStateChange(loadingAutoTranslate);
  }, [loadingAutoTranslate, onTranslationRequestStateChange]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    // Act like a componentDidUpdate
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }

    // When the request to google translate is finished, then display the green callout
    if (!loadingAutoTranslate)
      setCalloutIsGreen(true);
  }, [loadingAutoTranslate]);

  useEffect(() => {
    if (itemsToTranslate.length)
      setCalloutIsGreen(false);
  }, [itemsToTranslate.length]);

  const numItemsToTranslate = itemsToTranslate.length;

  const titleCalloutStyle = useMemo(() => {
    return {
      ...styles.titleCallout,
      color: calloutIsGreen ? COLORS.SUCCESS.DEFAULT : COLORS.PRIMARY.DEFAULT,
    };

  }, [calloutIsGreen]);

  return (
    <div style={styles.wrapper}>
      <BackModal
        visible={showBackModal}
        onAction={onBackAction}
        onClose={onCloseBackModal}
        isActionLoading={loadingSave}
      />
      <ConfirmModal
        visible={showConfirmModal}
        onClose={onCloseTranslateModal}
        onAction={onConfirmAction}
        isActionLoading={loadingValidation}
      />
      <div>
        <UButton
          text={t('content_translation:header.back')}
          onClick={onClickBack}
        />
      </div>
      <div style={styles.header}>
        <div>
          <p style={styles.textTranslation}>
            {t('content_translation:header.translation_to', { from: languageFrom.toUpperCase(), to: languageTo.toUpperCase() })}
          </p>
          <p style={styles.textKnowledgeTitle}>
            {knowledgeTitle}
          </p>
        </div>
        <div style={styles.containerButtons}>
          <UButton
            text={t('content_translation:header.save_as_draft')}
            onClick={onSaveCallback}
            style={styles.buttonDraft}
            disabled={!isModified}
            loading={showBackModal ? false : loadingSave}
            ghost
          />
          <div
            data-tip
            data-for="Preview"
          >
            <UButton
              text={t('content_translation:header.preview')}
              onClick={onPreview}
              style={styles.buttonPreview}
              leftIcon="eye"
              disabled={isModified || disablePreviewButton}
              ghost
            />
          </div>
          {
            isModified && (
              <STooltip
                id="Preview"
                position="top"
                content={t('content_translation:header.you_must_save_to_preview')}
              />
            )
          }
          <div
            data-tip
            data-for="Confirmation"
          >
            <UButton
              text={t('content_translation:header.confirm_translation')}
              onClick={onConfirmPress}
              disabled={!!numItemsToTranslate || disableConfirmButton}
              type="accentuated"
            />
          </div>
          {
            (!!numItemsToTranslate || disableConfirmButton) && (
              <STooltip
                id="Confirmation"
                position="top"
                content={t('content_translation:header.translate_everything_to_confirm_the_translation')}
              />
            )
          }
        </div>
      </div>
      <SLargeCallout
        color={calloutIsGreen ? COLORS.SUCCESS.DEFAULT : COLORS.PRIMARY.DEFAULT}
        style={styles.callout}
      >
        <div style={styles.containerCallout}>
          <div style={styles.contentCallout}>
            <p style={titleCalloutStyle}>
              {
                calloutIsGreen
                  ? t('content_translation:header.translation_complete')
                  : numItemsToTranslate !== 0
                    // eslint-disable-next-line max-len
                    ? t('content_translation:header.items_not_translated_yet', { count: numItemsToTranslate })
                    : t('content_translation:header.zero_item_to_translate')
              }
            </p>
            <p style={styles.noticeCallout}>
              {
                calloutIsGreen
                  ? t('content_translation:header.take_the_time_to_verify_each_translation')
                  : t('content_translation:header.every_text_item_must_be_translated_to_confirm_the_translation')
              }
            </p>
          </div>
          {!numItemsToTranslate ? null : (
            <UButton
              text={t('content_translation:header.translate_all_with_google')}
              onClick={onTranslatePress}
              leftIcon="google"
              type="accentuated"
              style={styles.buttonCallout}
              loading={loadingAutoTranslate}
            />
          )}
        </div>
      </SLargeCallout>
    </div>
  );
};

export const KnowledgeTranslationHeader = (props: Props) => {
  return (
    <KnowledgeTranslationAlertView component={KnowledgeTranslationHeaderComp} props={props} />
  );
};
