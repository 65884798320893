/* eslint-disable complexity */
import m from 'm';
import _ from 'lodash';

import { t } from 'i18next';

import App from 'models/App';
import Collection from 'models/Collection';
import thematicButton from 'components/thematic.button';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.onThematicSelected = args?.onThematicSelected;

  self.thematics = App.thematics;
  self.editingLevel = m.prop(0);

  self.rootThematics = function rootThematics() {
    return self.thematics().filter(function filterThematic(thematic) {
      return thematic.ancestorId() === null;
    });
  };

  self.subThematics = function subThematics() {

    if (!self.rootThematic()) return [];

    var ancestor = self.rootThematic().id();

    return self.thematics().filter(function filterThematic(thematic) {
      return thematic.ancestorId() === ancestor;
    });
  };

  self.subSubThematics = function subSubThematics() {

    if (!self.subThematic()) return [];

    var ancestor = self.subThematic().id();

    return self.thematics().filter(function filterThematic(thematic) {
      return thematic.ancestorId() === ancestor;
    });
  };

  self.rootThematicIndex = m.prop(0);
  self.rootThematic = function rootThematic() {
    return self.rootThematics()[self.rootThematicIndex()];
  };

  self.subThematicIndex = m.prop(0);
  self.subThematic = function subThematic() {
    return self.subThematics()[self.subThematicIndex()];
  };

  self.subSubThematicIndex = m.prop(0);
  self.subSubThematic = function subSubThematic() {
    return self.subSubThematics()[self.subSubThematicIndex()];
  };

  self.uniqueInCollection = function uniqueInCollection(elem, collection) {
    var unique = true;

    collection.map(function mapOverCollection(thematic) {
      var a = thematic;
      var b = elem;

      var sameAncestor = a.ancestorId() === b.ancestorId();
      var sameName = a.name() === b.name();
      var sameID = a.id() === b.id();

      if (sameAncestor && sameName && !sameID)
        unique = false;
    });

    return unique;
  };

  self.uniqueThematic = function uniqueThematic(thematic) {
    return self.uniqueInCollection(thematic, self.thematics());
  };

  self.addThematic = function addThematic(thematic) {
    self.editingLevel(thematic.level());

    var newThematics = self.thematics().items;

    newThematics.push(thematic);
    self.thematics(new Collection(newThematics));
    m.redraw();

    self.onThematicSelected(thematic);
  };

  self.removeThematic = function removeThematic(thematic) {
    self.editingLevel(thematic.level());

    var newThematics = self.thematics().filter(
      function filterThematic(item) {

        // Beware ! It's children are still present but will not show up
        // Returns all but the thematic
        return item.id() !== thematic.id();
      });

    self.thematics(new Collection(newThematics));
    m.redraw();
  };

  self.handleOnThematicSelect = function handleOnThematicSelect() {
    if (!self.onThematicSelected) return;

    if (!_.isNil(self.subSubThematicIndex()) && self.subSubThematics().length > 0) {
      self.onThematicSelected(self.subSubThematics()[self.subSubThematicIndex()]);

      return;
    }
    if (!_.isNil(self.subThematicIndex()) && self.subThematics().length > 0) {
      self.onThematicSelected(self.subThematics()[self.subThematicIndex()]);

      return;
    }
    self.onThematicSelected(self.rootThematics()[self.rootThematicIndex()]);

    return;
  };
};

component.view = function view(c) {
  function onClickHandlerFactory(type, index) {
    return () => {
      c[type](index);
      c.handleOnThematicSelect();
    };
  }

  function inputSubSubThematic() {
    var parent = c.subThematic();

    if (!parent)
      return null;

    var index = c.subSubThematics().length;
    var active = c.subSubThematicIndex() === index;

    const onClick = onClickHandlerFactory('subSubThematicIndex', index);

    return m(thematicButton, {
      level: 2,
      key: parent.id(),
      last: true,
      edit: m.prop(true),
      type: 'subSubThematic',
      onSave: c.addThematic,
      active: active,
      index: c.subSubThematics().length,
      validate: c.uniqueThematic,
      ancestor: parent.id(),
      focus: c.editingLevel() === 2,
      onclick: onClick,
      placeholder: t('thematic:placeholder_3'),
    });
  }

  function inputSubThematic() {
    var parent = c.rootThematic();

    if (!parent)
      return null;

    var index = c.subThematics().length;
    var active = c.subThematicIndex() === index;

    const onClick = onClickHandlerFactory('subThematicIndex', index);

    return m(thematicButton, {
      level: 1,
      key: parent.id(),
      edit: m.prop(true),
      type: 'subThematic',
      onSave: c.addThematic,
      active: active,
      index: c.subThematics().length,
      validate: c.uniqueThematic,
      ancestor: parent.id(),
      focus: c.editingLevel() === 1,
      onclick: onClick,
      placeholder: t('thematic:placeholder_2'),
    });
  }

  function inputRootThematic() {
    var index = c.rootThematics().length;
    var active = c.rootThematicIndex() === index;

    const onClick = onClickHandlerFactory('rootThematicIndex', index);

    return m(thematicButton, {
      level: 0,
      key: -1,
      edit: m.prop(true),
      type: 'thematic',
      onSave: c.addThematic,
      active: active,
      index: c.rootThematics().length,
      validate: c.uniqueThematic,
      ancestor: null,
      focus: c.editingLevel() === 0,
      onclick: onClick,
      placeholder: t('thematic:placeholder_1'),
    });
  }

  return m('.', [
    m('.newThematics__column__labels', [
      m('.newThematics__column__label.newThematics__column__label--left', t('thematic:thematics')),
      m('.newThematics__column__label.newThematics__column__label--middle', {
        class: !c.rootThematic() ? 'dimmed' : '',
      }, t('thematic:sub_thematics')),
      m('.newThematics__column__label.newThematics__column__label--right', {
        class: !c.subThematic() ? 'dimmed' : '',
      }, t('thematic:sub_sub_thematics')),
    ]),
    m('.newThematics__columns', [
      m('.newThematics__column.newThematics__column--left', [
        c.rootThematics().map(function mapOverRootThematics(thematic, index) {
          var active = c.rootThematicIndex() === index;

          return m(thematicButton, {
            validate: c.uniqueThematic,
            active: active,
            onSave: c.addThematic,
            onDelete: c.removeThematic,
            thematic: thematic,
            index: index,
            type: 'thematic',
            key: thematic.id(),
            onclick: onClickHandlerFactory('rootThematicIndex', index),
          });
        }),
        inputRootThematic(),
      ]),
      m('.newThematics__column.newThematics__column--middle', [
        _.map(c.subThematics(), function mapOverSub(thematic, index) {
          var active = c.subThematicIndex() === index;

          return m(thematicButton, {
            validate: c.uniqueThematic,
            active: active,
            onSave: c.addThematic,
            onDelete: c.removeThematic,
            thematic: thematic,
            index: index,
            type: 'subThematic',
            key: thematic.id(),
            onclick: onClickHandlerFactory('subThematicIndex', index),
          });
        }),
        inputSubThematic(),
      ]),
      m('.newThematics__column.newThematics__column--right', [
        _.map(c.subSubThematics(), function mapOverSubSub(thematic, index) {
          var active = c.subSubThematicIndex() === index;

          return m(thematicButton, {
            validate: c.uniqueThematic,
            active: active,
            onSave: c.addThematic,
            onDelete: c.removeThematic,
            thematic: thematic,
            index: index,
            type: 'subSubThematic',
            key: thematic.id(),
            onclick: onClickHandlerFactory('subSubThematicIndex', index),
            last: true,
          });
        }),
        inputSubSubThematic(),
      ]),
    ]),
  ]);
};

export default component;
