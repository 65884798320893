import { handle } from 'redux-pack';

import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { getErrorMessage } from 'Libs/redux/utils';

import type { Action } from 'Libs/redux/types';
import type { AlertType } from './models/Alert';

export type NpsState = DeepReadonly<{
  stats: {
    currentScore: number | null;
    previousScore: number | null;
    nbDaysBetweenScores: number;
    nbPromoters: number;
    nbPassives: number;
    nbDetractors: number;
    loading: boolean;
    nbPlayersExposed: number | null;
  };
  isReportLoading: boolean;
  alerts: ReadonlyArray<AlertType>;
}>;

export const defaultState: NpsState = {
  stats: {
    currentScore: null,
    previousScore: null,
    nbDaysBetweenScores: 0,
    nbPromoters: 0,
    nbPassives: 0,
    nbDetractors: 0,
    loading: false,
    nbPlayersExposed: null,
  },
  isReportLoading: false,
  alerts: [],
};

const ERROR_SCOPE = {
  NPS_GET_STATS: 'getStatsError',
  NPS_GET_REPORT: 'getReportError',
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: NpsState = defaultState, action: Action): NpsState => {
  switch (action.type) {
    case 'NPS_GET_STATS': {
      return handle(state, action, {
        start: (prevState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]);

          return mergeDeep(prevState, {
            stats: {
              loading: true,
            },
            alerts,
          });
        },
        success: (prevState) => {
          const { payload } = action;

          return mergeDeep(prevState, {
            stats: {
              currentScore: payload.currentScore,
              previousScore: payload.previousScore,
              nbDaysBetweenScores: payload.nbDaysBetweenScores,
              nbPromoters: payload.nbPromoters,
              nbPassives: payload.nbPassives,
              nbDetractors: payload.nbDetractors,
              nbPlayersExposed: payload.nbPlayersExposed,
            },
          });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) =>
          mergeDeep(prevState, {
            stats: {
              loading: false,
            },
          }),
      });
    }

    case 'NPS_GET_REPORT': {
      return handle(state, action, {
        start: (prevState) => {
          const alerts = prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]);

          return mergeDeep(prevState, {
            isReportLoading: true,
            alerts,
          });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) =>
          mergeDeep(prevState, {
            isReportLoading: false,
          }),
      });
    }

    case 'NPS_SET_ALERT': {
      const alert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === alert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? alert : err))
        : state.alerts.concat([alert]);

      return mergeDeep(state, { alerts });
    }

    case 'NPS_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    default:
      return state;
  }
};
