import React, { CSSProperties } from 'react';
import { Translation } from 'react-i18next';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UButton from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import UTopActionBar from 'Components/unit/UTopActionBar/UTopActionBar';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';

import type { StatusType, HelperType } from 'Components/unit/UTopActionBar/UTopActionBar';

import styles from './SContentActionBar.style';
import { WithLoadingProps } from 'Components/hoc/withLoading/withLoading';

export type ContentOptionType = {
  title: string;
  callback: () => void;
};

type ActionType = 'validate' | 'save' | 'preview';

export type ActionConfigType = {
  type: ActionType;
  enabled: boolean;
};

type SContentActionBarProps = {
  // required
  // @see UTopActionBar
  id: string | number;
  title: string;
  status: StatusType;
  statusWording: string;
  isTranslated: boolean;
  helper?: HelperType;

  // @see UIconButton
  options: Readonly<ContentOptionType[]>;

  onSave: WithLoadingProps['onClick'];
  onBack: () => void;

  // optional
  lastSaved?: string;
  onValidate?: WithLoadingProps['onClick'];
  onSaveEnd?: (any: any) => void;
  onValidateEnd?: (any: any) => void;
  onPreview?: () => void;
  canPreview?: boolean;
  canValidate?: boolean;
  canSave?: boolean;
  validationWording?: string;
  previewWording?: string;
  backWording?: string;
  actionsConfig?: Readonly<ActionConfigType[]>;
  style?: CSSProperties;
};

type SContentActionBarState = {
  displayOption: boolean;
};

const noop = () => {};
const asyncNoop = async () => {};

/**
 * Display the content action bar
 *
 * Props:
 *
 * # required
 * - id: knowledge id or custom id
 * - title: content title
 * - status: status of the content (@see StatusType)
 * - statusWording: wording of the given status
 * - isTranslated: is this content is translated ?
 * - helper: display an helper icon
 * - options: dropdown options (@see OptionType)
 * - actionsConfig: list of options available (@see ActionConfigType)
 *
 * # optional
 * - canPreview: is this content can be previewed ?
 * - canValidate: is this content can be validated ?
 * - canSave: is this content can be saved ?
 * - previewWording: the text to display in the preview button
 * - validationWording: the text to display in the validation button
 * - backWording: the text to display in the back button
 * - lastSaved: text that represent the last saved
 * - style: style to override

 * Callbacks (required):
 * - onSave: callback on content save
 * - onSaveEnd: callback when the save is done
 * - onBack: callback on back

 * Callbacks (optional):
 * - onValidate: callback on content validation
 * - onValidateEnd: callback when the validation is done
 * - onPreview: callback on content preview
 */
export class SContentActionBar extends React.Component<SContentActionBarProps, SContentActionBarState> {
  wrapperRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    onValidate: undefined,
    onSaveEnd: () => {},
    onValidateEnd: () => {},
    onPreview: undefined,
    canPreview: false,
    canValidate: false,
    canSave: false,
    validationWording: '',
    previewWording: '',
    backWording: 'Back',
    lastSaved: '',
    isTranslated: false,
    helper: undefined,
    actionsConfig: [
      { type: 'validate', enabled: true },
      { type: 'save', enabled: true },
      { type: 'preview', enabled: true },
    ],
    style: undefined,
  };

  constructor(props: SContentActionBarProps) {
    super(props);

    this.state = {
      displayOption: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { lastSaved, backWording, onBack, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={styles.wrapperInfos}>
          <UButton text={backWording} onClick={onBack} style={styles.backButton} />

          {this.renderDescription()}
        </div>
        <div style={styles.wrapperActions}>
          <div style={styles.lastEdit}>{lastSaved}</div>
          {this.renderSave()}
          {this.renderPreview()}
          {this.renderOption()}
          {this.renderValidation()}
        </div>
      </div>
    );
  }

  renderDescription = () => {
    const { id, title, status, statusWording, isTranslated, helper } = this.props;

    return (
      <Translation>
        {(t) => (
          <UTopActionBar
            id={id}
            title={title}
            status={status}
            statusWording={statusWording}
            placeholder={t('structural_component:s_content_action_bar.search_placeholder')}
            isTranslated={isTranslated}
            helper={helper}
            style={styles.topActionBar}
          />
        )}
      </Translation>
    );
  };

  renderSave = () => {
    const { actionsConfig, canSave, onSave, onSaveEnd } = this.props;

    const saveAction = actionsConfig?.find((config) => config.type === 'save') || { enabled: false };

    if (saveAction.enabled) {
      return (
        <Translation>
          {(t) => (
            <UButtonWithLoading
              text={t('structural_component:s_content_action_bar.save')}
              disabled={!canSave}
              type={!canSave ? 'standard' : 'accentuated'}
              style={styles.saveButton}
              onClick={onSave || asyncNoop}
              onRequestEnd={onSaveEnd || noop}
            />
          )}
        </Translation>
      );
    }

    return null;
  };

  renderPreview = () => {
    const { actionsConfig, canPreview, previewWording, onPreview } = this.props;

    const previewAction = actionsConfig?.find((config) => config.type === 'preview') || { enabled: false };

    if (previewAction.enabled) {
      return (
        <UButton
          type="accentuated"
          leftIcon="eye"
          text={previewWording}
          disabled={!canPreview}
          onClick={onPreview}
          style={styles.previewButton}
          ghost
        />
      );
    }

    return null;
  };

  renderOption = () => {
    const { options } = this.props;

    if (!options.length) return null;

    return (
      <div ref={this.wrapperRef} style={styles.optionButton}>
        <UIconButton icon="options" onClick={this.handleOptionClick} ghost />
        {this.renderOptionItems()}
      </div>
    );
  };

  renderOptionItems = () => {
    const { options } = this.props;
    const { displayOption } = this.state;

    if (!displayOption) return null;

    return <UDropdownBox items={options} renderItem={this.renderOptionItem} style={styles.optionDropDown} />;
  };

  renderOptionItem = (item: ContentOptionType) => {
    const { title, callback } = item;

    return <UDropdownItem key={title} text={title} onClick={this.handleOptionItemClick.bind(null, callback)} />;
  };

  renderValidation = () => {
    const { actionsConfig, canValidate, validationWording, onValidate, onValidateEnd } = this.props;

    const validationAction = actionsConfig?.find((config) => config.type === 'validate') || { enabled: false };

    if (validationAction.enabled) {
      return (
        <div style={styles.validationWrapper}>
          {this.renderSpacer()}
          <UButtonWithLoading
            type={!canValidate ? 'standard' : 'accentuated'}
            text={validationWording}
            disabled={!canValidate}
            onClick={onValidate || asyncNoop}
            onRequestEnd={onValidateEnd || noop}
            style={styles.validateButton}
          />
        </div>
      );
    }

    return null;
  };

  renderSpacer = () => {
    return <div style={styles.spacer} />;
  };

  handleOptionClick = () => {
    const { displayOption } = this.state;

    this.setState({ displayOption: !displayOption });
  };

  handleOptionItemClick = (callback: () => void) => {
    this.setState({ displayOption: false });
    callback();
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target))
      this.setState({ displayOption: false });
  };
}

export default SContentActionBar;
