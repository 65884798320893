import React, { useRef, useEffect, useState, FC, CSSProperties, useMemo } from 'react';
import { t } from 'i18next';
import { css } from 'aphrodite';

import { isFeatureEnabled } from 'Services/feature-flags';

import { pluralHelper } from 'Libs/plural';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import UChips from 'Components/unit/UChips/UChips';
import UIndicator from 'Components/unit/UIndicator/UIndicator';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import { IconName } from 'Components/foundation/icons';

import styles, { extendedStyles } from './SDocumentListItem.style';

type Chip = {
  id: number;
  value: string;
  color?: string;
};

type OptionType = {
  title: string;
  callback: (id: number) => void;
  color?: string;
};

type Props = {
  id: number;
  documentTitle: string;
  categoryTitle: string;
  openingsCount: number;
  options: readonly OptionType[];
  onClick: (id: number) => void;
  chips?: readonly Chip[];
  documentIcon?: IconName;
  isAvailable?: boolean;
  style?: CSSProperties;
};

const SDocumentListItem: FC<Props> = ({
  id,
  documentIcon = 'pdf',
  documentTitle,
  categoryTitle,
  style = undefined,
  chips = [],
  isAvailable = false,
  options,
  onClick,
  openingsCount = 0,
}) => {
  const canSeeDocumentOpenings = isFeatureEnabled('document-openings');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [displayOption, setDisplayOption] = useState(false);
  const openingString = t('documents:document_list_items.document_openings')
    ? t('documents:document_list_items.document_openings')
    : 'openings';
  const finalText = useMemo(() => {
    return pluralHelper(openingsCount, openingString.slice(0, -1), openingString);
  }, [openingString]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && event.target instanceof Node && !wrapperRef.current.contains(event.target)) {
        setDisplayOption(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    onClick(id);
  };

  const handleOptionClick = () => {
    setDisplayOption((prevDisplayOption) => !prevDisplayOption);
  };

  const handleOptionItemClick = (callback: (id: number) => void) => {
    setDisplayOption(false);
    callback(id);
  };

  const renderSegmentationList = () => {
    if (!chips.length) {
      return <div style={styles.noSegmentation}>{t('documents:s_document_list_item.segmentation')}</div>;
    }

    return chips.map((chip) => (
      <UChips key={chip.id} type="compact" value={chip.value} color={chip.color} style={styles.chips} />
    ));
  };

  const renderStatus = () => {
    if (!isAvailable) return null;
    return <UIndicator text={t('documents:s_document_list_item.text')} mode="enabled" />;
  };

  const renderOptions = () => (
    <div ref={wrapperRef} style={styles.options}>
      <UIconButton icon="dot-menu" onClick={handleOptionClick} stopClickPropagation ghost />
      <div style={styles.optionsDropDownWrapper as CSSProperties}>{renderOptionItems()}</div>
    </div>
  );

  const renderOptionItems = () => {
    if (!displayOption) return null;

    return <UDropdownBox items={options} renderItem={renderOptionItem} style={styles.optionDropDown} />;
  };

  const renderOptionItem = (item: OptionType) => (
    <UDropdownItem
      key={item.title}
      text={item.title}
      color={item.color || COLORS.TEXT.DEFAULT}
      onClick={() => handleOptionItemClick(item.callback)}
      stopClickPropagation
    />
  );

  return (
    <div onClick={handleClick} style={{ ...styles.wrapper, ...style }} className={css(extendedStyles.wrapper)}>
      <div style={styles.wrapperLeft}>
        <UIcon size={24} name={documentIcon} color={COLORS.DOCUMENT_TYPES.PDF} style={styles.documentIcon} />

        <div className={css(extendedStyles.wrapperLeftText)}>
          <div className={css(extendedStyles.categoryTitle)}>{categoryTitle}</div>
          <div style={styles.documentTitle}>{documentTitle}</div>
        </div>

        <div className={css(extendedStyles.wrapperLeftText)}>
          <div className={css(extendedStyles.segmentationBlock)}>
            <UIcon size={16} name="target" color={COLORS.BLACK.DEFAULT} style={styles.iconTarget} />
            <div>{renderSegmentationList()}</div>
          </div>
          {canSeeDocumentOpenings && (
            <div className={css(extendedStyles.openingsBlock)}>
              <UIcon
                size={16}
                name="eye"
                color={openingsCount > 0 ? COLORS.BLACK.DEFAULT : COLORS.TEXT.DISABLED_DEFAULT}
                style={styles.eyeIcon}
              />
              <p style={openingsCount > 0 ? styles.openingsText : styles.noOpeningsText}>
                {openingsCount || 0} {finalText}
              </p>
            </div>
          )}
        </div>
      </div>

      <div style={styles.wrapperRight}>
        {renderStatus()}
        {renderOptions()}
      </div>
    </div>
  );
};

export default SDocumentListItem;
