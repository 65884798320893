import React, { FunctionComponent } from 'react';

import LandingPageWrapper from '../LandingPageWrapper/LandingPageWrapper';

import styles from './PhoneRequestMail.style';

export const PhoneRequestMail: FunctionComponent = () => (
  <LandingPageWrapper>
    <div style={styles.instruction}>
      <span>Double authentication has been activated on your platform.</span>
      <br />
      <span style={styles.instructionStrong}>Please click on the link sent to your email </span>
      <span>in order to set up your phone number.</span>
    </div>
  </LandingPageWrapper>
);

export default PhoneRequestMail;
