import React, { useMemo } from 'react';

import type { CSSProperties } from 'react';

import styles from './SSection.style';

export type SSectionProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  isFirstSection?: boolean;
};

/**
 * SSection component props:
 * - title: string which displays the title
 * - description: string which displays the description
 * - children: the children which the SSection component will display
 * - style: overrite the component's style with this prop
 */
export const SSection = ({ title, isFirstSection = false, children, style, containerStyle }: SSectionProps) => {
  const wrapperStyle = useMemo(
    () => (isFirstSection ? { marginTop: '24px', ...style } : { marginTop: '48px', ...style }),
    [isFirstSection, style],
  );
  const containerStyles = useMemo(() => ({ ...styles.container, ...containerStyle }), [containerStyle]);

  return (
    <div style={wrapperStyle}>
      {title && (
        <div style={styles.titleContainer}>
          <p style={styles.title}>{title}</p>
        </div>
      )}
      <div style={containerStyles}>{children}</div>
    </div>
  );
};

export default SSection;
