import { StyleSheet as StyleAphrodite } from 'aphrodite';

import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export const styles = createStyleSheet({
  wrapper: {
    flex: 1,
    width: 185,
    height: 48,
    backgroundColor: 'white',
    borderRadius: 8,
    paddingLeft: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  language: {
    ...TYPOGRAPHY.H2_BOLD,
  },
  description: {
    ...TYPOGRAPHY.BODY4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: 34,
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    backgroundColor: COLORS.WHITE.DEFAULT,
    position: 'relative',
    bottom: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  footerText: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: 4,
  },
  tooltipPosition: {
    position: 'absolute',
    left: 175,
    top: -6,
  },
  tooltip: {
    backgroundColor: COLORS.ERROR.DARKER,
    pointerEvents: 'none',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  flagWrapper: {
    alignItems: 'center',
    marginBottom: 4,
  },
  flag: {
    marginRight: 4,
  },
  iconButton: {
    justifyContent: 'center',
    marginRight: 16,
  },
  outsideClickWrapper: {
    width: 250,
    position: 'relative',
  },
  dropdownBox: {
    width: 250,
    maxHeight: 195,
    marginTop: 4,
  },
});

export const extendedStyles = StyleAphrodite.create({
  footer: {
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.WHITE.HOVER,
    },
  },
});
