import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';

import type { CSSProperties } from 'react';

import SMicroKnowledgeListItem from 'Components/structural/SMicroKnowledgeListItem/SMicroKnowledgeListItem/SMicroKnowledgeListItem';
import UMediaPicker, { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';

import { COLORS } from 'Components/foundation';

import { MicroKnowledgeItemSharedProps } from '../types/shared-props';

import styles from './SImageMicroKnowledgeItem.style';

const MICROKNOWLEDGE_COLOR = COLORS.WHITE.DEFAULT;

export type SImageMicroKnowledgeItemProps = MicroKnowledgeItemSharedProps & {
  imageUrl?: string;
  imageLabel?: string;
  content: string;
  onImageChange?: (image: PickerSelectedImage) => void;
  onContentChange?: (id: string, newContent: string) => void;
  onTitleChange?: (id: string, newContent: string) => void;
  isChild?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
};

/**
 * SImageMicroKnowledgeItem component
 * Props:
 * - imageId?: Id of the selected image from the UImage component,
 * - imageLabel?: Label of the image picker
 * - content?: Text displayed,
 * - onContentChange?: Callback which updates the content,
 * - onTitleChange?: Callback which updates the title,
 * - isChild?: boolean which states whether this microknowledge is a child or not,
 * - backgroundColor?: CSSProperties['backgroundColor'],
 */
export const SImageMicroKnowledgeItem = ({
  id,
  imageUrl = undefined,
  imageLabel = undefined,
  title,
  content,
  backgroundColor = MICROKNOWLEDGE_COLOR,
  isChild = false,
  options,
  style = {},
  onContentChange,
  onTitleChange,
  onImageChange,
  onAdd,
}: SImageMicroKnowledgeItemProps) => {
  const [automaticScroll, setScroll] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const onFocusChange = useCallback(() => {
    setIsFocus(true);
  }, []);

  const onBlurChange = useCallback(() => {
    setScroll(true);
    setIsFocus(false);
  }, []);

  const handleAdd = useCallback(() => onAdd?.(id), [onAdd, id]);

  const handleTitleChange = useCallback((newValue: string) => onTitleChange?.(id, newValue), [onTitleChange, id]);

  const handleContentChange = useCallback((newValue: string) => onContentChange?.(id, newValue), [onContentChange, id]);

  const handleImageChange = useCallback(
    (image: PickerSelectedImage) => {
      onImageChange?.(image);
    },
    [onImageChange],
  );

  const textareaMaxHeight = isFocus ? '200px' : '43px';

  useEffect(() => {
    // reset scroll state
    setScroll(false);
  }, [automaticScroll]);

  return (
    <SMicroKnowledgeListItem
      id={id}
      isChild={isChild}
      contextMenuOptions={options}
      backgroundColor={backgroundColor}
      style={style}
      onAdd={handleAdd}
    >
      <SSubtleInput
        inputType="input"
        style={styles.title}
        value={title}
        placeholder={t('courses:course_edition.type_title')}
        maxLength={40}
        onChange={handleTitleChange}
        debounced
      />
      <UMediaPicker
        size="XS"
        dropArea
        image={imageUrl}
        style={styles.image}
        textLink={imageLabel}
        textLinkStyle={styles.imageLabel}
        textLinkTypography="BODY2"
        media={{ mediaType: 'image', onChange: handleImageChange }}
      />
      <SSubtleInput
        inputType="textarea"
        style={styles.content}
        value={content}
        placeholder={t('courses:course_edition.type_image_cation')}
        maxLength={145}
        maxHeight={textareaMaxHeight}
        onChange={handleContentChange}
        onFocus={onFocusChange}
        onBlur={onBlurChange}
        forceTextareaScroll={automaticScroll}
        debounced
      />
    </SMicroKnowledgeListItem>
  );
};

export default SImageMicroKnowledgeItem;
