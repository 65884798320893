import { IconName } from 'Components/foundation/icons';
import type { Action } from 'Libs/redux/types';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { SupportedAlertTypes } from './actions';

// Minimmum props required to qualify for any alert type.
export type AlertDisplayStatus = 'success' | 'error' | 'warning';
export type AlertDisplayPriority = 'low' | 'medium';
export type Alert = {
  id: string;
  status?: AlertDisplayStatus;
  priority?: AlertDisplayPriority;
  icon?: IconName;
  title?: string;
  text?: string;
  actionCallbacks?: {
    success?: string;
    cancel?: string;
    close?: string;
  };
};

export type AlertManagerState = DeepReadonly<{
  basic: Record<string, Alert>;
  toast: Record<string, Alert>;
}>;

export const defaultState: AlertManagerState = {
  [SupportedAlertTypes.BASIC]: {},
  [SupportedAlertTypes.TOAST]: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: AlertManagerState = defaultState, action: Action): AlertManagerState => {
  switch (action.type) {
    case 'ALERT_MANAGER_ENQUEUE': {
      const { id, alertType, ...rest } = action.payload;

      return state[alertType]
        ? {
            ...state,
            [alertType]: { ...state[alertType], [id]: { id, ...rest } },
          }
        : state;
    }

    case 'ALERT_MANAGER_REMOVE': {
      const { id, alertType } = action.payload;

      if (!state[alertType]) return state;

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        [alertType]: { [id]: _, ...rest },
      } = state;

      return { ...state, [alertType]: rest };
    }

    case 'ALERT_MANAGER_RESET':
      return defaultState;

    default:
      return state;
  }
};
