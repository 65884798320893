import type { GameItemType } from 'Pages/Content/redux/models/GameItem';
import createGameItem from 'Pages/Content/redux/models/GameItem';

import type { SegmentationGroupItemType } from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';
import createSegmentationGroupItem from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';

import type { Thematic } from 'Models/react/Thematic/Thematic';
import { createThematicItem } from 'Models/react/Thematic/Thematic';

import type { KnowledgeContent } from 'Models/react/KnowledgeContent/KnowledgeContent';
import { createKnowledgeContentItem } from 'Models/react/KnowledgeContent/KnowledgeContent';

import { GAMEPLAY_ID_TO_STRING } from 'Components/utils/Enum';

import type { GameplayIdType } from 'Libs/ts/types';
import { mergeDeep } from 'Libs/mergeDeep';

export type KnowledgeImageType = Readonly<{
  label: string,
  id: number,
}>;

export type KnowledgeImage = Readonly<{
  filename: string,
  height: number,
  id: number,
  orientation: string,
  type: KnowledgeImageType,
  url: string,
  width: number,
}>;

export type KnowledgeTitle = Readonly<{
  data: string,
  id: number,
  knowledgeId: number,
}>;

export type Knowledge = Readonly<{
  archived: boolean,
  cover_id: number,
  contents: ReadonlyArray<KnowledgeContent>,
  customId: number | null,
  explanation: GameItemType,
  id: number,
  images: ReadonlyArray<KnowledgeImage>,
  knowledgeTitle: KnowledgeTitle,
  locale: string,
  multilingualId: number,
  pendingTranslation: boolean,
  segmentation: ReadonlyArray<SegmentationGroupItemType>,
  statusId: number,
  thematic: Thematic,
  title: string,
}>;

const defaultKnowledge: Knowledge = {
  archived: false,
  cover_id: 0,
  customId: 0,
  contents: [],
  explanation: createGameItem(),
  id: 0,
  images: [],
  knowledgeTitle: {
    id: 0,
    data: '',
    knowledgeId: 0,
  },
  locale: '',
  multilingualId: 0,
  pendingTranslation: false,
  segmentation: [],
  statusId: 0,
  thematic: createThematicItem(),
  title: '',
};

export const createKnowledgeItem = (values?: any): Knowledge => {
  if (!values || !Object.keys(values).length) {
    return defaultKnowledge;
  }

  return mergeDeep(defaultKnowledge, {
    archived: values.archived,
    cover_id: values.cover_id,
    customId: values.customId,
    contents: values.contents?.map(createKnowledgeContentItem),
    id: values.id,
    images: values.images,
    knowledgeTitle: values.knowledgeTitle,
    locale: values.locale,
    multilingualId: values.multilingualId,
    pendingTranslation: values.pendingTranslation,
    explanation: createGameItem(values.explanation),
    statusId: values.statusId,
    segmentation: values.segmentation?.map(createSegmentationGroupItem),
    thematic: createThematicItem(values.thematic),
    title: values.title,
  });
};

export const extractGameplayTypes = (
  contents: ReadonlyArray<KnowledgeContent>,
): ReadonlyArray<GameplayIdType> => (
  contents.filter((content) => !content.archived)
    .map((content) => GAMEPLAY_ID_TO_STRING[content.gameplayId])
);

export const getCoverImageUrl = (coverId: number, images: ReadonlyArray<KnowledgeImage>): string => {
  const image = images.find((img) => img.id === coverId);

  return image?.url || images?.[0]?.url || '';
};
