import React, { useState, useCallback, useMemo } from 'react';
import type { FunctionComponent, ReactNode, CSSProperties, MouseEvent } from 'react';

import UAbstractSelect, { UAbstractSelectVariant } from 'Components/unit/UAbstractSelect/UAbstractSelect';
import { MenuOptions, SOptionsMenu } from 'Components/structural/SOptionsMenu/SOptionsMenu';

import styles from './UEntityCardCore.style';

export type UEntityCardCoreProps = {
  styleConfig?: {
    paddingHorizontal?: number;
    paddingVertical?: number;
  };
  /**
   * The card select handler. If undefined, does not display the checkbox.
   */
  onSelect?: () => void;
  /**
   * The card click handler. If undefined, does not allow click and disable
   * the hover effect.
   */
  onClick?: () => void;
  /**
   * Handler called once when the hover starts and once when the hover stops. The
   * `hovered` parameter is set accordingly.
   */
  onHover?: (hovered: boolean) => void;
  selected?: boolean;
  disabled?: boolean;
  menuOptions?: ReadonlyArray<MenuOptions>;
  children: ReactNode;
  selectionVariant: UAbstractSelectVariant;
};

export const UEntityCardCore: FunctionComponent<UEntityCardCoreProps> = ({
  styleConfig = {
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  onSelect,
  onClick,
  onHover,
  selected = false,
  disabled = false,
  menuOptions,
  children,
  selectionVariant,
}) => {
  const [hovered, setHovered] = useState(false);

  const handleSelect = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onSelect?.();
    },
    [onSelect],
  );

  const handleHover = useCallback(
    (hovering: boolean) => {
      if (onClick === undefined) {
        return;
      }

      onHover?.(hovering);
      setHovered(hovering);
    },
    [onClick, onHover],
  );

  const handleMouseEnter = useCallback(() => handleHover(true), [handleHover]);
  const handleMouseLeave = useCallback(() => handleHover(false), [handleHover]);

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    onClick?.();
  }, [onClick, disabled]);

  const getHoverStyle = useMemo(() => {
    if (hovered && selectionVariant === 'none') {
      return styles.containerHoverWithoutPointer;
    }
    if (hovered) {
      return styles.containerHover;
    }
  }, [hovered, selectionVariant]);

  const containerStyle = useMemo(
    (): CSSProperties => ({
      ...styles.container,
      ...(getHoverStyle || {}),
      ...(selected ? styles.containerSelected : {}),
      ...(disabled ? styles.containerDisabled : {}),
      ...(styleConfig.paddingVertical
        ? {
            paddingTop: styleConfig.paddingVertical,
            paddingBottom: styleConfig.paddingVertical,
          }
        : {}),
      ...(styleConfig.paddingHorizontal
        ? {
            paddingLeft: styleConfig.paddingHorizontal,
            paddingRight: styleConfig.paddingHorizontal,
          }
        : {}),
    }),
    [hovered, styleConfig, selected, disabled],
  );

  const contentStyle = useMemo(() => (disabled ? styles.contentDisabled : {}), [disabled]);

  return (
    <div
      style={containerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      data-testid="CARD_CORE_CONTAINER"
    >
      <div style={styles.actionContainer}>
        {onSelect !== undefined && (
          <UAbstractSelect
            onSelect={handleSelect}
            selected={selected}
            disabled={disabled}
            type="accentuated"
            variant={selectionVariant}
          />
        )}
        {menuOptions !== undefined && <SOptionsMenu options={menuOptions} />}
      </div>
      <div data-testid="CARD_CORE_CONTENT" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default UEntityCardCore;
