// @flow

import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default {
  largeCallout: {
    marginBottom: 20,
  },
  containerCallout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  textCallout: {
    display: 'flex',
    flexDirection: 'column',
  },
  nbContentToTranslate: {
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
    color: COLORS.PRIMARY.DEFAULT,
    marginBottom: 8,
  },
  notice: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  button: {
    marginLeft: 56,
    zIndex: LEVELS.LOCAL_OFFSET,
  },
};
