import React, { useMemo, useState } from 'react';
import { t } from 'i18next';
import { GameType, GAME_TYPE } from '@sparted/shared-library/business/types';

import UIconTab from 'Components/unit/UIconTab/UIconTab';
import UButton from 'Components/unit/UButton/UButton';

import styles, { VIDEO_HEIGHT, VIDEO_WIDTH } from './gameplayChoice.style';

const DEFAULT_YOUTUBE_VIDEO = 'https://www.youtube.com/embed/ZPsjjY5_gAg';

type GameplayChoiceProps = {
  gameplayType: GameType | null;
  selectGameplayType: (gameplay: GameType) => void;
};

export const GameplayChoice = ({ gameplayType, selectGameplayType }: GameplayChoiceProps) => {
  const [selectedGameplay, setSelectedGameplay] = useState<GameType>(gameplayType || GAME_TYPE.MCQ);

  const gameplayMapperTranslationKey = useMemo(
    () => ({
      [GAME_TYPE.MCQ]: {
        title: t(`gameplays:mcq.label`),
        description: t(`gameplays:mcq.description`),
        points_rules: [
          t(`gameplays:mcq.points_rules_1`),
          t(`gameplays:mcq.points_rules_2`),
          t(`gameplays:mcq.points_rules_3`),
        ],
      },
      [GAME_TYPE.ERROR_TEXT]: {
        title: t(`gameplays:error_text.label`),
        description: t(`gameplays:error_text.description`),
        points_rules: [
          t(`gameplays:error_text.points_rules_1`),
          t(`gameplays:error_text.points_rules_2`),
          t(`gameplays:error_text.points_rules_3`),
        ],
      },
      [GAME_TYPE.OPEN_QUESTION]: {
        title: t(`gameplays:open_question.label`),
        description: t(`gameplays:open_question.description`),
        points_rules: [
          t(`gameplays:open_question.points_rules_1`),
          t(`gameplays:open_question.points_rules_2`),
          t(`gameplays:open_question.points_rules_3`),
        ],
      },
      [GAME_TYPE.NO_INTERACTION]: {
        title: t(`gameplays:no_interaction_content.label`),
        description: t(`gameplays:no_interaction_content.description`),
        points_rules: [t(`gameplays:no_interaction_content.points_rules`)],
      },
      [GAME_TYPE.PICTURE_SPOT]: {
        title: t(`gameplays:picture_spot.label`),
        description: t(`gameplays:picture_spot.description`),
        points_rules: [
          t(`gameplays:picture_spot.points_rules_1`),
          t(`gameplays:picture_spot.points_rules_2`),
          t(`gameplays:picture_spot.points_rules_3`),
        ],
      },
      [GAME_TYPE.ORDERING_CARDS]: {
        title: t(`gameplays:ordering_cards.label`),
        description: t(`gameplays:ordering_cards.description`),
        points_rules: [
          t(`gameplays:ordering_cards.points_rules_1`),
          t(`gameplays:ordering_cards.points_rules_2`),
          t(`gameplays:ordering_cards.points_rules_3`),
        ],
      },
      [GAME_TYPE.SWIPING_CARDS]: {
        title: t(`gameplays:swiping_cards.label`),
        description: t(`gameplays:swiping_cards.description`),
        points_rules: [
          t(`gameplays:swiping_cards.points_rules_1`),
          t(`gameplays:swiping_cards.points_rules_2`),
          t(`gameplays:swiping_cards.points_rules_3`),
        ],
      },
    }),
    [t],
  );

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>{t('gameplays:title')}</h2>
      <p style={styles.subtitle}>{t('gameplays:subtitle')}</p>
      <div style={styles.gameplayContainer}>
        <div style={styles.iconRow}>
          {Object.values(GAME_TYPE).map((gameType) => (
            <UIconTab
              key={gameType}
              iconType={gameType}
              selected={selectedGameplay === gameType}
              onClick={() => {
                setSelectedGameplay(gameType);
              }}
            />
          ))}
        </div>
        <div style={styles.gameplayDescriptionContainer}>
          <h3 style={styles.gameplayTitle}> {gameplayMapperTranslationKey[selectedGameplay].title}</h3>
          <p style={styles.gameplayDescription}> {gameplayMapperTranslationKey[selectedGameplay].description}</p>
        </div>
        <div style={styles.videoContainer}>
          <iframe width={VIDEO_WIDTH} height={VIDEO_HEIGHT} src={DEFAULT_YOUTUBE_VIDEO}></iframe>
          <div style={styles.pointsRow}>
            {gameplayMapperTranslationKey[selectedGameplay].points_rules.map((point_rule, index) => (
              <span key={index}>{point_rule}</span>
            ))}
          </div>
        </div>
        <div style={styles.buttonContainer}>
          <UButton
            type="accentuated"
            onClick={() => {
              selectGameplayType(selectedGameplay);
            }}
            text={t('gameplays:select_button')}
          />
        </div>
      </div>
    </div>
  );
};
