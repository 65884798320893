import m from 'm';
import Enum from 'models/Enum';
import reactCreator from 'components/react.creator';

const component = {};

component.controller = function controller(args) {
  const self = this;

  const game = args.content.game();

  self.makeCards = function makeCards(orderingCards) {
    const cards = orderingCards.cards().toJSON();

    return cards.map((card) => {
      const content = card.cardContent.data;

      return {
        id: `ordering-cards-items-${card.id}`,
        content,
      };
    });
  };

  self.instruction = m.prop(game.question().data() || '');
  self.cards = m.prop(self.makeCards(game));
};

component.view = function view(c, args) {
  const game = args.content.game();

  c.cards(c.makeCards(game));
  c.instruction(game.question().data() || '');

  return m('.preview-ordering', [
    m(reactCreator, {
      component: 'SOrderingCardsPreview',
      props    : {
        instruction: c.instruction,
        cards      : c.cards,
      },
    }),
  ]);
};

export default component;
