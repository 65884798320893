import React, { ReactElement } from 'react';
import type { CSSProperties } from 'react';

import type { USecondaryTabLabelProps } from 'Components/unit/USecondaryTabLabel/USecondaryTabLabel';

export type SSecondaryTabListProps = {
  style?: CSSProperties;
  children: ReactElement<USecondaryTabLabelProps> | ReactElement<USecondaryTabLabelProps>[];
};

export const SSecondaryTabList = ({ style: propStyle = undefined, children }: SSecondaryTabListProps) => (
  <div style={propStyle}>{children}</div>
);

export default SSecondaryTabList;
