import { createStyleSheet } from 'Components/foundation/stylesheets';
import COLORS from 'Components/foundation/colors';

const styleSheet = createStyleSheet({
  container: {
    display: 'flex',
    gap: '10px',
    width: '64px',
    height: '64px',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: COLORS.TEXT.DEFAULT,
  },
  rest: {
    background: COLORS.WHITE.DEFAULT,
  },
});

export const iconStyles = {
  mcq: {
    selected: {
      background: COLORS.GAMEPLAY.SQCM,
    },
    hover: {
      background: COLORS.GAMEPLAY.SQCM_HOVER,
      cursor: 'pointer',
    },
  },
  'error-text': {
    selected: {
      background: COLORS.GAMEPLAY.ERROR_TEXT,
    },
    hover: {
      background: COLORS.GAMEPLAY.ERROR_TEXT_HOVER,
      cursor: 'pointer',
    },
  },
  'no-interaction': {
    selected: {
      background: COLORS.GAMEPLAY.NO_INT_CONTENT,
    },
    hover: {
      background: COLORS.GAMEPLAY.NO_INT_CONTENT_HOVER,
      cursor: 'pointer',
    },
  },
  'open-question': {
    selected: {
      background: COLORS.GAMEPLAY.OPEN_QUESTION,
    },
    hover: {
      background: COLORS.GAMEPLAY.OPEN_QUESTION_HOVER,
      cursor: 'pointer',
    },
  },
  'ordering-cards': {
    selected: {
      background: COLORS.GAMEPLAY.ORDERING_CARDS,
    },
    hover: {
      background: COLORS.GAMEPLAY.ORDERING_CARDS_HOVER,
      cursor: 'pointer',
    },
  },
  'picture-spot': {
    selected: {
      background: COLORS.GAMEPLAY.PICTURE_SPOT,
    },
    hover: {
      background: COLORS.GAMEPLAY.PICTURE_SPOT_HOVER,
      cursor: 'pointer',
    },
  },
  'swiping-cards': {
    selected: {
      background: COLORS.GAMEPLAY.SWIPING_CARDS,
    },
    hover: {
      background: COLORS.GAMEPLAY.SWIPING_CARDS_HOVER,
      cursor: 'pointer',
    },
  },
};

export default styleSheet;
