import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    height: '100%',
    width: '70%',
    maxWidth: '1300px',
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: -50,
    position: 'relative',
  },
  mainHeader: {
    marginBottom: -56,
  },
  campaign: {
    marginLeft: 31,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
  },
  skipIntro: {
    position: 'absolute',
    right: '0px',
    marginTop: '85px',
  },
  blockMargin: {
    marginRight: 32,
  },
  sDiscoverContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '32px',
    marginBottom: '56px',
  },
});

export default styleSheet;
