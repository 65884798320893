import { useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';

import { FilterState } from 'Libs/filter/types';
import { SearchActivityArgs } from 'Store/entities/activity/activity.thunks';
import {
  audienceModeTypeIdToAudienceType,
  mapLanguageIdsToLanguageCodes,
  mapLegacyActivityStatusId,
  mapLegacyActivityTypeToActivityType,
} from 'Pages/DolphinActivity/activity.mapper';

export type UseActivityFiltersParams = {
  activityCount: number;
};

type SelectedFilterState = {
  selectedActivityTypeId: number;
  selectedActivityStatusId: number;
  selectedTargetModeId: number;
  selectedActivityLanguageIds: number[];
  selectedTextValues: string[];
  selectedAudiencegroupIds: number[];
  hasFilters: boolean;
};

export const selectedFiltersToQuery = (selectedFilters: SelectedFilterState): SearchActivityArgs['query'] => {
  const query: SearchActivityArgs['query'] = {};

  if (selectedFilters.selectedTargetModeId) {
    query.audienceType = [audienceModeTypeIdToAudienceType(selectedFilters.selectedTargetModeId)];
  }

  if (selectedFilters.selectedTextValues.length > 0) {
    query.title = selectedFilters.selectedTextValues;
  }

  if (selectedFilters.selectedActivityTypeId) {
    query.type = mapLegacyActivityTypeToActivityType(selectedFilters.selectedActivityTypeId);
  }

  if (selectedFilters.selectedActivityStatusId) {
    query.status = [mapLegacyActivityStatusId(selectedFilters.selectedActivityStatusId)];
  }

  if (selectedFilters.selectedActivityLanguageIds.length > 0) {
    query.language = mapLanguageIdsToLanguageCodes(selectedFilters.selectedActivityLanguageIds);
  }

  if (selectedFilters.selectedAudiencegroupIds.length > 0) {
    query.audienceGroup = selectedFilters.selectedAudiencegroupIds;
  }

  return query;
};

export const useActivityFilters = ({ activityCount }: UseActivityFiltersParams) => {
  const [selectedFiltersState, setSelectedFiltersState] = useState<SelectedFilterState>({
    selectedActivityTypeId: 0,
    selectedActivityStatusId: 0,
    selectedTargetModeId: 0,
    selectedActivityLanguageIds: [],
    selectedTextValues: [],
    selectedAudiencegroupIds: [],
    hasFilters: false,
  });

  const handleFilterChange = useCallback((selectedFilters: Array<FilterState>) => {
    const newSelectedFilters = selectedFilters.reduce(
      (acc: SelectedFilterState, filter: FilterState) => {
        const newSelectedFiltersState = {
          ...acc,
          ...(filter.category === 'activityType' && { selectedActivityTypeId: filter.ids.length ? filter.ids[0] : 0 }),
          ...(filter.category === 'activityStatus' && {
            selectedActivityStatusId: filter.ids.length ? filter.ids[0] : 0,
          }),
          ...(filter.category === 'text' && { selectedTextValues: filter.values }),
          ...(filter.category === 'audienceMode' && { selectedTargetModeId: filter.ids.length ? filter.ids[0] : 0 }),
          ...(filter.category === 'language' && { selectedActivityLanguageIds: filter.ids }),
          ...(filter.category === 'audienceGroup' && { selectedAudiencegroupIds: filter.ids }),
        };

        return newSelectedFiltersState;
      },
      { ...selectedFiltersState },
    );

    const hasFilters =
      newSelectedFilters.selectedActivityTypeId > 0 ||
      !!newSelectedFilters.selectedActivityStatusId ||
      newSelectedFilters.selectedActivityLanguageIds.length > 0 ||
      newSelectedFilters.selectedTargetModeId > 0 ||
      newSelectedFilters.selectedTextValues.length > 0;

    setSelectedFiltersState({
      ...newSelectedFilters,
      hasFilters,
    });
  }, []);

  const description = useMemo(() => {
    if (!selectedFiltersState.hasFilters) {
      if (!activityCount) return t('activities:list.filter.no_activity_label');

      return t('activities:list.filter.activity_created', { count: activityCount });
    }

    return t('activities:list.filter.activity_found', { count: activityCount });
  }, [selectedFiltersState.hasFilters, activityCount]);

  const filters: Array<FilterState> = useMemo(() => {
    return [
      { category: 'text', values: [...selectedFiltersState.selectedTextValues] },
      { category: 'audienceGroup', ids: selectedFiltersState.selectedAudiencegroupIds },
      { category: 'language', ids: selectedFiltersState.selectedActivityLanguageIds },
      { category: 'activityType', ids: [selectedFiltersState.selectedActivityTypeId] },
      { category: 'activityStatus', ids: [selectedFiltersState.selectedActivityStatusId] },
      { category: 'audienceMode', ids: [selectedFiltersState.selectedTargetModeId] },
    ];
  }, [selectedFiltersState]);

  return {
    description,
    filters,
    onFilterChange: handleFilterChange,
    selectedFilters: selectedFiltersState,
  };
};
