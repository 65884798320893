import T from 'types';
import Promise from 'bluebird';
import factory from 'models/Model';

var collectionConstraints = {
  unique: checkUnicity,
};

/*
TODO:
copyWithin
entries
every
fill
find
findIndex
includes
indexOf
join
keys
lastIndexOf
pop
reduceRight
shift
splice
values
*/
/**
 * Can be called in many different ways:
 *   - new Collection([instance, ...])
 *   - new Collection(Constructor)
 *   - new Collection(Constructor, [json, ...])
 *   - new Collection('type', {model})
 *   - new Collection('type', {model}, [json, ...])
 */
function Collection(type, model, array, breadcrumb) {
  var self = this;
  var _class = null;

  if (T.is.arr(type)) {
    /* Swap args in case we called `new Collection([instance, ...])` */
    array = type;
    model = array[0].model;
    type = array[0]._type;
  } else if (!T.is.str(type)) {
    array = model;
    model = type.model;
    type = type._type;
  }

  if (!T.is.str(type))
    throw new Error('You cannot create an abstract collection (without a type)');

  if (!T.is.obj(model))
    throw new Error('You cannot create an abstract collection (without a model)');

  _class = factory(type);

  self.type = type;
  self.model = model;
  self.items = [];

  /* In case we called `new Collection('type', {model})` */
  if (array)
    addWithoutConstraints(self, array, breadcrumb);

  Object.defineProperty(self, 'length', {
    get: function returnLength() {
      return this.items.length;
    },
  });

  self.newItem = new _class({}, breadcrumb);

  self.newItem.save = function saveNewItem() {
    var item = this;
    var cloned = null;

    return self.checkConstraintsAsync(item)
      .then(function cloneItemAndEmpty() {
        cloned = item.clone();
        item.empty();

        return cloned;
      })
      .then(function execSave() {
        return cloned.save();
      })
      .then(addWithoutConstraints.bind(null, self));
  };
}

Collection.prototype.unshift = function addToCollection() {
  var self = this;
  var args = [].slice.call(arguments);
  var error = self.checkConstraints(args);

  if (!error)
    addWithoutConstraints(self, args, null, true);
  else
    throw new Error(error);
};

Collection.prototype.push = function addToCollection() {
  var self = this;
  var args = [].slice.call(arguments);
  var error = self.checkConstraints(args);

  if (!error)
    addWithoutConstraints(self, args);
  else
    throw new Error(error);
};

Collection.prototype.removeAt = function removeAt(index) {
  var self = this;
  var removed = [];

  if (T.is.nb(index)) {
    removed = self.items.splice(index, 1);

    for (var i = index, len = self.items.length; i < len; i++) {
      if (self.items[i])
        self.items[i]._i--;
    }

    return removed;
  }

  throw new Error('index must be a Number');
};

Collection.prototype.removeByUUID = function removeByUUID(uuid) {
  var self = this;
  var i = 0;

  if (T.is.str(uuid)) {
    for (var len = self.items.length; i < len && self.items[i]._uuid !== uuid; i++)
      ;

    return self.items.splice(i, 1);
  }

  throw new Error('uuid must be a String');
};

Collection.prototype.searchAndRemove = function searchAndRemove(conditions) {
  var self = this;
  var removed = [];

  if (T.is.obj(conditions)) {
    removed = self.items.filter(removeItems.bind(null, conditions, false));
    self.items = self.items.filter(removeItems.bind(null, conditions, true));

    for (var i = 0, len = self.items.length; i < len; i++)
      self.items[i]._i = i;

    return removed;
  }

  throw new Error('conditions must be an object');
};

Collection.prototype.getAndReplace = function getAndReplace(id, newItem) {
  var self = this;

  var old = self.get(id);

  if (!old) {
    console.log('[collection]', 'Item #', id, 'not found in collection');

    return;
  }

  newItem._i = old._i;

  self.items[old._i] = newItem;
};

Collection.prototype.get = function findById(id) {
  var self = this;

  for (var i = self.items.length - 1; i >= 0; i--) {
    if (self.items[i].id() === id)
      return self.items[i];
  }
};

Collection.prototype.replaceAt = function replaceAt(i, instance) {
  var self = this;

  instance._i = i;
  self.items[i] = instance;

  return self;
};

Collection.prototype.at = function atIndex(i) {
  var self = this;

  return self.items[i];
};

Collection.prototype.find = function findByAttr(obj) {
  var self = this;
  var result = self.items.filter(removeItems.bind(null, obj, false));

  return new Collection(self.type, self.model, result);
};

Collection.prototype.first = function firstByAttr(obj) {
  var self = this;

  var result = self.items.filter(removeItems.bind(null, obj, false));

  return result[0];
};

Collection.prototype.concat = function concatCollection() {
  var self = this;

  return self.items.concat.apply(self.items, arguments);
};

Collection.prototype.reduce = function reduceCollection() {
  var self = this;

  return self.items.reduce.apply(self.items, arguments);
};

Collection.prototype.map = function mapCollection(cb) {
  var self = this;

  return self.items.map(cb);
};

Collection.prototype.slice = function sliceCollection() {
  var self = this;
  var args = [].slice.call(arguments);

  return new Collection(self.type, self.model, self.items.slice.apply(self.items, args));
};

Collection.prototype.filter = function filterCollection(cb) {
  var self = this;

  return self.items.filter(cb);
};

Collection.prototype.forEach = function forEachCollection(cb) {
  var self = this;

  return self.items.forEach(cb);
};

Collection.prototype.some = function someCollection(cb) {
  var self = this;

  return self.items.some(cb);
};

Collection.prototype.reverse = function reverseCollection() {
  var self = this;

  self.items.reverse();

  return self;
};

Collection.prototype.inverted = function invertedCollection() {
  var self = this;

  self.items.sort(function invertItems(a, b) {
    return a._i < b._i;
  });

  return self;
};

Collection.prototype.sort = function sortCollection(cb) {
  var self = this;

  self.items.sort(cb);

  return self;
};

Collection.prototype.groupBy = function groupBy(cb) {
  var self = this;
  var obj = {};

  self.forEach(function addToGroup(item, i, array) {
    var key = cb(item, i, array);

    if (!obj[key])
      obj[key] = new Collection(self.type, self.model);

    obj[key].push(item);
  });

  return obj;
};

Collection.prototype.checkConstraints = function checkConstraints(array) {
  var self = this;
  var attrs = self.model.attributes;
  var error = '';

  array = T.is.arr(array) ? array : [array];

  array.forEach(function checkConstraintsOnItem(item) {
    Object.keys(attrs).forEach(function iterateOverAttrs(attrName) {
      var attr = attrs[attrName];

      Object.keys(attr).forEach(function iterateOverConstraint(constraintName) {
        if (constraintName in collectionConstraints && attr[constraintName])
          error = collectionConstraints[constraintName].call(self, attrName, item);
      });
    });
  });

  return error;
};

Collection.prototype.checkConstraintsAsync = function checkConstraintsAsync(array) {
  var self = this;

  return new Promise(function promisifyCheck(resolve, reject) {
    var error = self.checkConstraints(array);

    if (error)
      return reject(error);
    resolve();
  });
};

Collection.prototype.toJSON = function toJSON() {
  var self = this;

  return self.items.map(function itemsToJSON(model) {
    return model.toJSON();
  });
};

Collection.prototype.isEqual = function isEqual(toCompare) {
  var self = this;
  var isEqualResult = (self.length === toCompare.length);

  for (var i = 0, l = self.items.length; i < l && isEqualResult; i++)
    isEqualResult = self.items[i].isEqual(toCompare.at(i));

  return isEqualResult;
};

Collection.prototype.toArray = function toArray() {
  var self = this;

  return self.items.map(function itemsToJSON(model) {
    var json = model.toJSON();

    return new (factory(model._type))(json);
  });
};

Collection.prototype.clone = function cloneCollection() {
  var self = this;

  return new Collection(self.type, self.model, self.toJSON());
};

Collection.prototype.JSONToInstance = function JSONToInstance(json, breadcrumb) {
  var self = this;
  var _class = factory(self.type);

  return new _class(json, breadcrumb);
};

Collection.prototype.save = function save() {
  var self = this;

  return Promise.all(self.items.map(function saveItem(item) {
    return item.save();
  }));
};

function addWithoutConstraints(collection, array, breadcrumb, addToEnd) {
  array = T.is.arr(array) ? array : [array];

  array = array.map(function instanciateOrNot(item) {
    var toAdd = factory.isModel(item)
      ? item
      : collection.JSONToInstance(item, breadcrumb);
    var destroy = toAdd.destroy;

    if (!toAdd._alreadyOverrideDestroy) {
      toAdd._originalDestroy = toAdd.destroy;
      toAdd._alreadyOverrideDestroy = true;
    }

    toAdd.destroy = function destroyThenRemove() {
      var self = this;

      return destroy.call(self)
        .then(function removeAtIndex() {
          collection.removeByUUID(self._uuid);
        });
    };

    if (addToEnd)
      toAdd._i = collection.items.unshift(toAdd) - 1;
    else
      toAdd._i = collection.items.push(toAdd) - 1;

    if (!toAdd._uuid)
      toAdd._uuid = generateUUID();

    return toAdd;
  });

  if (array.length === 1)
    return array[0];

  return array;
}

function removeItems(obj, reverse, item) {
  var ret = true;

  Object.keys(obj).forEach(function isEqualToAttr(key) {
    if (obj[key] !== item[key]())
      ret = false;
  });

  ret = reverse ? !ret : ret;

  return ret;
}

/** @this Collection */
function checkUnicity(attrName, itemToCheck) {
  var self = this;
  var valueToCheck = itemToCheck[attrName]();
  var isUnique = true;

  self.items.forEach(function mapOverItems(item) {
    if (item[attrName]() === valueToCheck)
      isUnique = false;
  });

  if (!isUnique) {
    return [
      'A',
      T.lowercase(itemToCheck._type),
      'with that',
      attrName,
      'already exists',
    ].join(' ');
  }
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });
}

export default Collection;
