import React from 'react';

import styles from 'Pages/Homepage/Homepage.style';

import CampaignBlock from './CampaignBlock';
import KnowledgeBlock from './KnowledgeBlock';

const KNOWLEDGE_URL = 'https://support.sparted.com/hc/en-us/articles/360019613699-Game-Manual';
const CAMPAIGN_URL =
  'https://support.sparted.com/hc/en-us/articles/360015533011-The-essentials-for-creating-a-good-campaign';

const IntermediateState = () => (
  <>
    <KnowledgeBlock
      title="Create a new Knowledge"
      size="50%"
      link={KNOWLEDGE_URL}
      align="left"
      style={styles.blockMargin}
    />
    <CampaignBlock title="Create your first Campaign" size="100%" link={CAMPAIGN_URL} />
  </>
);

export default IntermediateState;
