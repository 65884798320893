/* eslint-disable complexity */
// @flow

/**
 * Display a modal that handles media edition
  *
  * Props:
  * - style: custom style to override the modal's style
  * - titleSelect: the modal's title when we select an image
  * - titleCrop: the modal's title when we crop an image
  * - tabsLabels: an object with the texts corresponding to each tab
  * - dropAreaLabels: all labels needs to handle file upload
  * - imageSelectionLabel: the label on action button when we select an image
  * - imageCropLabel: the label on action button when finish cropping an image
  * - cancelLabel: the label on cancel button when we're in a selection page
  * - goBackLabel: the label on cancel button when are on cropping page
  * - searchPlaceHolder: the text to display in the search input when no text is entered
  * - galleryPlaceHolder: the text to display when no items are found in the library
  * - cropProps: an object with all properties needed from crop
  * - sourceParams: an object with extra options to pass to the image search source @see ImageSourceParams
  * - defaultSearchText: the default query to pass to the image search source
  * - defaultSearchSource: the image search source to select by default
  * - onCancel: action to execute when we hit cancel button
  * - onImageSelection: action to execute when we hit action button in selection page
  * - onSave: action to execute when we hit action button in cropping page
 */

import * as React from 'react';
import Promise from 'bluebird';

import { t } from 'i18next';

import { IMAGE_SOURCE_NAMES } from 'Components/utils/Enum';
import SImageGallery from 'Components/structural/SImageGallery/SImageGallery';
import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';
import STabList from 'Components/structural/STabList/STabList';
import SUploadDropArea from 'Components/structural/SUploadDropArea/SUploadDropArea';
import SCropToolPanel from 'Components/structural/SCropToolPanel/SCropToolPanel';
import MModal from 'Components/modal/MModal/MModal';
import Enum from 'Models/Enum';
import Image from 'Models/Image';

import type {
  Source,
  ImageBody,
  ImageGalleryType,
  CropObject,
  ImageHandler,
  ImageSourceParams,
  ImageLibSourceName,
} from 'Libs/flowTypes';
import { Google, Unsplash } from 'Libs/imageLibs';
import fileUploader from 'Services/fileUploader';

import styles from './MImageCrop.style';

type Device = {|
  label: string,
  ratio: number,
|};

type CropProps = {|
  deviceList: Array<Device>,
|};

type Props = {|
  cropProps: CropProps,
  defaultSearchText: string,
  defaultSearchSource: ImageLibSourceName,
  sourceParams: ImageSourceParams,
  onCancel: Function,
  onImageSelection: Function,
  onSave: Function,
  style?: ?Object,
  hideOverlay?: boolean,
|};

type Labels = {|
  titleSelect: string,
  titleCrop: string,
  cancelLabel: string,
  imageSelectionLabel: string,
  imageCropLabel: string,
  galleryPlaceHolder: string,
  searchPlaceHolder: string,
  tabsLabels: {
    search: string,
    upload: string,
    library: string,
  },
  dropAreaLabels: {
    defaultSentence: string,
    defaultLabel: string,
    dropSentence: string,
    warnSentence1: string,
    warnSentence2: string,
    errorIncorrectFormat1: string,
    errorIncorrectFormat2: string,
    errorSizeTooBig: string,
    errorManyFiles: string,
    uploadingSentence: string,
  },
  goBackLabel: string,
  cropProps: {
    title : string,
    notice: string,
  },
  errorMessage: string,
|}

type State = {|
  selectedImage: ?Object,
  selectedTab: number,
  uploadProgress: number,
  isUploading: boolean,
  libraryItems: Array<any>,
  searchItems: Array<any>,
  searchExtraData: boolean,
  libraryExtraData: boolean,
  searchText: string,
  libraryText: string,
  currentSourceName: string,
  searchPage: number,
  libraryStart: number,
  isCropping: boolean,
  crop: $Shape<CropObject>,
  searchLoading: boolean,
  libraryLoading: boolean,
  temporaryImage: any,
  error: string,
|};

const searchSources: Array<Source> = [
  {
    icon: 'unsplash',
    name: IMAGE_SOURCE_NAMES.UNSPLASH,
    filters: [{
      category: 'text',
      type: 'text',
      items: [],
    }],
  },
  {
    icon: 'google',
    name: IMAGE_SOURCE_NAMES.GOOGLE,
    filters: [{
      category: 'text',
      type: 'text',
      items: [],
    }],
  },
];

const CDN_MAX = 1000;
const librarySources = [
  {
    icon: '',
    name: IMAGE_SOURCE_NAMES.LIBRARY,
    filters: [],
  },
];

const handlers: { [handle: string]: ImageHandler } = {
  Unsplash: new Unsplash(),
  Google: new Google(),
};

const NBR_IMAGES = 20;
const PROGRESS_INITIAL = 30;
const PROGRESS_RATIO = 0.7;


// eslint-disable-next-line react/no-unsafe
export class MImageCrop extends React.Component<Props, State> {
  static defaultProps = {
    defaultSearchSource: IMAGE_SOURCE_NAMES.UNSPLASH,
    defaultSearchText: '',
    sourceParams: { [IMAGE_SOURCE_NAMES.GOOGLE]: { imgType: 'photo', imgSize: 'xlarge' }},
    style: undefined,
    hideOverlay: false,
  };

  _searchSources: Array<Source>;
  labels: Labels;

  constructor(props: Props) {
    super(props);

    const { defaultSearchText, defaultSearchSource } = props;

    this._searchSources = searchSources.map((source) => {
      source.filters[0].items = defaultSearchText ? [{ id: 0, category: 'text', value: defaultSearchText }] : [];

      return { ...source };
    });

    this.labels = {
      titleSelect: t('modal_components:image_crop.select_an_image'),
      titleCrop: t('modal_components:image_crop.crop_your_image'),
      cancelLabel: t('modal_components:image_crop.cancel'),
      imageSelectionLabel: t('modal_components:image_crop.next'),
      imageCropLabel: t('modal_components:image_crop.add_image'),
      galleryPlaceHolder: t('modal_components:image_crop.a_picture_is_worth_a_thousand_words'),
      searchPlaceHolder: t('modal_components:image_crop.search_for_high_resolution_images'),
      tabsLabels: {
        search: t('modal_components:image_crop.tabs.search'),
        upload: t('modal_components:image_crop.tabs.upload'),
        library: t('modal_components:image_crop.tabs.library'),
      },
      dropAreaLabels: {
        defaultSentence: t('modal_components:image_crop.drop_area.upload_or_drop_an_image'),
        defaultLabel: t('modal_components:image_crop.drop_area.it_works_for'),
        dropSentence: t('modal_components:image_crop.drop_area.drop_that_file'),
        warnSentence1: t('modal_components:image_crop.drop_area.that_file_wont_work'),
        warnSentence2: t('modal_components:image_crop.drop_area.try_with'),
        errorIncorrectFormat1: t('modal_components:image_crop.drop_area.the_file_is_not_supported'),
        errorIncorrectFormat2: t('modal_components:image_crop.drop_area.try_again_with'),
        errorSizeTooBig: t('modal_components:image_crop.drop_area.the_file_is_too_big'),
        errorManyFiles: t('modal_components:image_crop.drop_area.more_than_one_file_selected'),
        uploadingSentence: t('modal_components:image_crop.drop_area.uploading'),
      },
      goBackLabel: t('modal_components:image_crop.back'),
      cropProps: {
        title : t('modal_components:image_crop.crop.preview'),
        notice: t('modal_components:image_crop.crop.see_how_your_images_will_be_viewed'),
      },
      errorMessage: t('modal_components:image_crop.unexpected_error'),
    };

    this.state = {
      selectedImage: null,
      selectedTab: 0,
      uploadProgress: 0,
      libraryItems: [],
      searchItems: [],
      searchExtraData: true,
      libraryExtraData: true,
      searchText: defaultSearchText,
      libraryText: '',
      currentSourceName: defaultSearchSource,
      searchPage: 1,
      libraryStart: 0,
      isCropping: false,
      isUploading: false,
      crop: {},
      searchLoading: true,
      libraryLoading: true,
      temporaryImage: undefined,
      error: '',
    };
  }

  componentDidMount() {
    const { sourceParams } = this.props;
    const { searchText } = this.state;

    return Promise.all([
      this._fetchSearchImages(searchText, 1, false, sourceParams),
      this._fetchLibraryImages('', 0, false),

    // This is to avoid crashes in storybook, MImageCrop is tighly coupled with the server via the Mithril image model
    // eslint-disable-next-line no-console
    ]).catch(console.error);
  }

  render() {
    const {
      style,
      hideOverlay,
    } = this.props;

    const {
      titleSelect,
      titleCrop,
      cancelLabel,
      imageSelectionLabel,
      imageCropLabel,
      goBackLabel,
    } = this.labels;

    const { selectedImage, isCropping, error } = this.state;

    return (
      <MModal
        visible
        onCloseModal={this.handleCloseModal}
        title={isCropping ? titleCrop : titleSelect}
        labelSecondButton={isCropping ? goBackLabel : cancelLabel}
        labelActionButton={isCropping ? imageCropLabel : imageSelectionLabel}
        onAction={this.handleAction}
        onActionEnd={this.handleActionEnd}
        onSecondAction={this.handleCancel}
        onScroll={this.handleScroll}
        header={this.renderHeader()}
        disableActionButton={!selectedImage}
        labelError={error}
        style={style}
        hideOverlay={hideOverlay}
      >
        {this.renderBody()}
        {this.renderCropTool()}
      </MModal>
    );
  }

  renderHeader = () => {
    const { tabsLabels } = this.labels;
    const { selectedTab, isCropping } = this.state;

    return (
      <div style={isCropping ? styles.displayOff : styles.displayOn}>
        <STabList
          onChangeTab={this.handleChangeTab}
          selectedItem={selectedTab}
          tabs={[tabsLabels.search, tabsLabels.upload, tabsLabels.library]}
          compact
        />

        {this.renderSearch()}
      </div>
    );
  };

  renderSearch = () => {
    const { selectedTab, currentSourceName } = this.state;
    const { searchPlaceHolder } = this.labels;

    let defaultSourceIndex = 0;

    if (selectedTab === 1)
      return null;

    if (selectedTab === 0)
      defaultSourceIndex = this._searchSources.findIndex((s) => s.name === currentSourceName);

    return (
      <SOmnibox
        key={!selectedTab ? 'searchInput' : 'libInput'}
        onFilterChanged={this.handleFiltersChanged}
        placeholder={searchPlaceHolder}
        sources={selectedTab === 0 ? this._searchSources : librarySources}
        filterMultiText={false}
        automaticSearch
        defaultIndexSourceSelected={defaultSourceIndex}
      />
    );
  };

  renderBody = () => {
    const { selectedTab } = this.state;

    if (selectedTab === 1)
      return this.renderDropArea();

    return this.renderImageGallery();
  };

  renderDropArea = () => {
    const {
      uploadProgress,
      isCropping,
      isUploading,
    } = this.state;

    const {
      dropAreaLabels: {
        defaultSentence,
        defaultLabel,
        dropSentence,
        warnSentence1,
        warnSentence2,
        errorIncorrectFormat1,
        errorIncorrectFormat2,
        errorSizeTooBig,
        errorManyFiles,
        uploadingSentence,
      },
    } = this.labels;

    const bodyStyle = isCropping ? styles.displayOff : styles.displayOn;

    return (
      <div style={{ ...styles.dropAreaWrapper, ...bodyStyle }}>
        <SUploadDropArea
          defaultSentence={defaultSentence}
          defaultLabel={defaultLabel}
          dropSentence={dropSentence}
          warnSentence1={warnSentence1}
          warnSentence2={warnSentence2}
          errorIncorrectFormat1={errorIncorrectFormat1}
          errorIncorrectFormat2={errorIncorrectFormat2}
          errorSizeTooBig={errorSizeTooBig}
          errorManyFiles={errorManyFiles}
          uploadingSentence={isUploading ? uploadingSentence : ''}
          uploadProgress={uploadProgress}
          onDrop={this.handleDrop}
        />
      </div>
    );
  };

  // eslint-disable-next-line complexity
  renderImageGallery = () => {
    const {
      selectedTab,
      searchItems,
      libraryItems,
      searchExtraData,
      isCropping,
      libraryExtraData,
      searchLoading,
      libraryLoading,
      currentSourceName,
    } = this.state;

    const { galleryPlaceHolder } = this.labels

    const bodyStyle = isCropping ? styles.displayOff : styles.displayOn;

    return (
      <SImageGallery
        key={!selectedTab ? 'searchGallery' : 'libGallery'}
        items={!selectedTab ? searchItems : libraryItems}
        onClick={this.handleImageSelection}
        placeholderSentence={galleryPlaceHolder}
        extraData={!selectedTab ? searchExtraData : libraryExtraData}
        style={bodyStyle}
        loading={!selectedTab ? searchLoading : libraryLoading}
        source={currentSourceName === IMAGE_SOURCE_NAMES.UNSPLASH ? t('modal_components:image_crop.on_source', { source: 'Unsplash' }) : ''}
      />
    );
  };

  renderCropTool = () => {
    const { isCropping, selectedImage, temporaryImage } = this.state;
    const { cropProps: { deviceList }} = this.props;
    const { cropProps: { title, notice }} = this.labels;

    if (!isCropping || !selectedImage)
      return null;

    return (
      <SCropToolPanel
        style={styles.cropPanel}
        image={selectedImage.displayUrl}
        highDefinitionSize={{ width: selectedImage.width, height: selectedImage.height }}
        deviceList={deviceList}
        isPreview
        titlePreview={title}
        noticePreview={notice}
        onCropEnd={this.handleCropEnd}
        loadedOptimizedImage={temporaryImage}
      />
    );
  };

  handleAction = () => {
    const { isCropping, selectedImage, crop, selectedTab } = this.state;

    this.setState({ error: '' });

    if (!selectedImage)
      return Promise.resolve();


    const cropProps = {
      ...crop,
      url: selectedImage.displayUrl,
      ancestorId: selectedImage.id || null,
    };

    if (isCropping){
      return Image.crop(cropProps)
    }

    if (selectedTab === 2) {
      const imageSelectedModel = new Image(selectedImage);

      return this._setTemporaryImage(imageSelectedModel, selectedImage.displayUrl);
    }

    return this._setTemporaryImage(new Image(selectedImage), selectedImage.displayUrl);
  };

  handleActionEnd = (result: any) => {
    const { isCropping, crop, selectedImage, selectedTab } = this.state;
    const { onSave, onImageSelection } = this.props;
    const { errorMessage } = this.labels;

    if (!result)
      return Promise.resolve();


    if (result.error) {
      this.setState({
        uploadProgress: 0,
        isUploading: false,
        error: errorMessage,
      });

      return Promise.resolve();
    }

    if (isCropping) {
      onSave(result);

      if (selectedImage && !selectedTab)
        return this._sendDownloadData(selectedImage.download);

      return Promise.resolve();
    }

    const newImageSelected = {
      ...selectedImage,
      displayUrl: result.cdn({ width: CDN_MAX }),
      url: result.url(),
    };


    onImageSelection?.(result);

    this.setState({
      crop: { ...crop, url: result.url() },
      isCropping: true,
      selectedImage: newImageSelected,
    });

    return Promise.resolve();
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    const { isCropping } = this.state;

    if (isCropping)
      return this.setState({ isCropping: false, uploadProgress: 0, isUploading: false, error: '' });

    return onCancel();
  };

  handleCloseModal = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  handleFiltersChanged = (source: Source) => {
    const { sourceParams } = this.props;
    const { selectedTab } = this.state;
    const key = this._getKeyFromSource(source);

    if (selectedTab === 2) {
      return this.setState(
        {
          libraryText: key,
          libraryStart: 0,
          currentSourceName: IMAGE_SOURCE_NAMES.LIBRARY,
          error: '',
          selectedImage: null,
        },
        () => this._fetchLibraryImages(key, 0, false)
      );
    }

    return this.setState(
      {
        searchText: key,
        searchPage: 1,
        currentSourceName: source.name,
        error: '',
        selectedImage: null,
      },
      () => this._fetchSearchImages(key, 1, false, sourceParams)
    );
  };

  handleChangeTab = (index: number) => {
    const { LIBRARY, UNSPLASH } = IMAGE_SOURCE_NAMES;
    const sourceState = index === 2 ? { currentSourceName: LIBRARY } : { currentSourceName: UNSPLASH };

    this.setState({ ...sourceState, selectedTab: index, error: '' });
  };

  handleImageSelection = (image: ImageGalleryType, isSelected: boolean) => {
    this.setState({ selectedImage: isSelected ? image: null });
  };

  handleDrop = (file: Object) => {
    this._loadFileForProgessPreview(file);

    return fileUploader.upload(file, 'image', Enum.imageTypeId.ORIGINAL, this._updateUploadProgress)
      .then(this.handleImageUploadEnd)
      .catch(this._resetUploadProgress);
  };

  handleCropEnd = (cropData: CropObject) => {
    const { crop: { ancestorId }} = this.state;

    this.setState({ crop: { ...cropData, ancestorId }});
  };

  handleScroll = (e: Object) => {
    const { searchLoading, selectedTab } = this.state;

    if (searchLoading || selectedTab === 1)
      return;

    const element = e.target;
    const {
      scrollTop,
      clientHeight,
      scrollHeight,
    } = element;

    if (scrollHeight - scrollTop <= clientHeight)
      this.handleScrollEnd();
  };

  handleScrollEnd = () => {
    const { sourceParams } = this.props;
    const { searchText, searchPage, selectedTab, libraryText, libraryStart } = this.state;

    if (!selectedTab) {
      this.setState({ searchPage: searchPage + 1 });

      return this._fetchSearchImages(searchText, searchPage + 1, true, sourceParams);
    }

    if (selectedTab === 2) {
      this.setState({ libraryStart: libraryStart + NBR_IMAGES });

      return this._fetchLibraryImages(libraryText, libraryStart + NBR_IMAGES, true);
    }

    return Promise.resolve();
  };

  handleImageUploadEnd = (attributes: Object) => {
    const { crop } = this.state;
    const { onImageSelection } = this.props;
    const { errorMessage } = this.labels;
    const newImageModel = new Image(attributes);
    const newUrl = newImageModel.cdn({ width: CDN_MAX });


    onImageSelection?.(newImageModel);

    return this._setTemporaryImage(newImageModel, newUrl)
      .then((result) => {
        if (result && result.error) {
          this.setState({
            uploadProgress: 0,
            isUploading: false,
            error: errorMessage,
          });

          return null;
        }

        const imageAttributes = {
          displayUrl: newUrl,
          url: attributes.url,
          width: attributes.width,
          height: attributes.height,
          id: attributes.id,
        };

        this.setState({
          crop: { ...crop, ancestorId: attributes.id },
          selectedImage: imageAttributes,
          uploadProgress: 0,
          isCropping: true,
          isUploading: false,
        });

        return null;
      });
  };

  _getSourceParams = (sourceName: string, sourcesParams: ImageSourceParams): ImageSourceParams => {
    const specificParams = sourcesParams?.[sourceName] || {};

    // $FlowIssue Flow can't parse this but it ensure that the source will receive only the params needed
    return { [sourceName]: specificParams };
  };

  _getKeyFromSource = (source: Source) => source.filters[0].items.length ? source.filters[0].items[0].value : '';

  _fetchSearchImages = (key: string, searchPage: number, add: boolean, sourceParams: ImageSourceParams) => {
    const { currentSourceName } = this.state;
    const handler = handlers[currentSourceName];
    const params = this._getSourceParams(currentSourceName, sourceParams);

    if (!add)
      this.setState({ searchLoading: true });

    return handler.fetchImages({ keyword: key, page: searchPage, perPage: NBR_IMAGES, params })
      .then((images) => this._updateSearchItems(images, key, add));
  };

  _sendDownloadData = (url) => {
    const { currentSourceName } = this.state;
    const handler = handlers[currentSourceName];

    return handler.sendDownloadData(url);
  };

  _updateSearchItems = (images: Array<ImageBody>, value: string, add: boolean) => {
    const { searchExtraData, searchItems, currentSourceName } = this.state;

    let newItems = images
      .filter((elem, index, self) => self.findIndex((img) => img.urls.big === elem.urls.big) === index)
      .map((img) => {
        return {
          displayUrl: currentSourceName === IMAGE_SOURCE_NAMES.GOOGLE ? img.urls.big : img.urls.small,
          linkText: img.user ? img.user.name : null,
          linkPath: img.user ? `${img.user.profileLink}?utm_source=Sparted&utm_medium=referral`: null,
          download: img.urls.download || null,
          width: img.size.width,
          height: img.size.height,
          url: img.urls.big,
        };
      });

    newItems = add ? searchItems.concat(newItems) : newItems;

    const newExtraData = add ? searchExtraData : !searchExtraData;

    this.setState({ searchItems: newItems, searchExtraData: newExtraData, searchLoading: false });
  };

  _fetchLibraryImages = (key: string, libStart: number, add: boolean) => {
    if (!add)
      this.setState({ libraryLoading: true });

    return Image.getAllImages({ start: libStart, length: NBR_IMAGES, query: key }, Enum.imageTypeId.CROPPED)
      .then((images) => this._updateLibraryItems(images, key, add));
  };

  _updateLibraryItems = (images: Array<typeof Image>, value: string, add: boolean) => {
    const { libraryExtraData, libraryItems } = this.state;

    let newItems = images.map((img) => {
      return {
        displayUrl: img.cdn({ width: CDN_MAX }),
        url: img.url(),
        width: img.width(),
        height: img.height(),
        id: img.id(),
      };
    });

    newItems = add ? libraryItems.concat(newItems) : newItems;
    const newExtraData = add ? libraryExtraData : !libraryExtraData;

    this.setState({ libraryItems: newItems, libraryExtraData: newExtraData, libraryLoading: false });
  };

  _loadFileForProgessPreview = (file: Object) => {
    const reader = new FileReader();

    this.setState({ isUploading: true, error: '' });

    reader.onprogress = this._handleFileReaderProgress;

    return reader.readAsDataURL(file);
  };

  _handleFileReaderProgress = (event: Object) => {
    const uploadProgress = Math.floor((event.loaded / event.total) * PROGRESS_INITIAL);

    this.setState({ uploadProgress });
  };

  _updateUploadProgress = (progress: number) => {
    this.setState({ uploadProgress: PROGRESS_INITIAL + progress * PROGRESS_RATIO });
  };

  _resetUploadProgress = () => {
    const { errorMessage } = this.labels;

    this.setState({ uploadProgress: 0, isUploading: false, error: errorMessage });
  };

  _setTemporaryImage = (result: typeof Image, url: string) => new Promise<typeof Image>((resolve, reject) => {
    const imageBis = document.createElement('img');

    imageBis.crossOrigin = 'Anonymous';

    imageBis.onerror = (error) => reject(error);
    imageBis.onload = () => {
      this.setState({ temporaryImage: imageBis }, () => resolve(result));
    };

    imageBis.src = url;
  });
}

export default MImageCrop;
