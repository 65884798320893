import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { handle } from 'redux-pack';

import { getErrorMessage } from 'Libs/redux/utils';

import type { Action } from 'Libs/redux/types';

export type SignUpState = DeepReadonly<{
  error: string;
}>;

export const defaultState: SignUpState = {
  error: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: SignUpState = defaultState, action: Action): SignUpState => {
  switch (action.type) {
    case 'ADMIN_ACTIVATE': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { error: '' }),
        success: (prevState) => mergeDeep(prevState, { error: '' }),
        failure: (prevState) => {
          const errorMessage = getErrorMessage(action);

          return mergeDeep(prevState, { error: errorMessage });
        },
      });
    }

    default:
      return state;
  }
};
