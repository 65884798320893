import React, { useState, useEffect, isValidElement, useMemo, useRef, useCallback } from 'react';
import type { FunctionComponent, ReactElement } from 'react';

import MModal, { MModalProps } from 'Components/modal/MModal/MModal';
import MModalOverlay from 'Components/modal/MModalOverlay/MModalOverlay';
import { useTimeoutCallback } from 'Hooks/useTimeoutCallback/useTimeoutCallback';

export type MModalGroupItemProps = Omit<MModalProps, 'hideOverlay' | 'visible' | 'onCloseModal'> & {
  step: string;
};

export const MModalGroupItem: FunctionComponent<MModalGroupItemProps> = ({ step, ...props }) => (
  <MModal {...(props as Omit<MModalProps, 'hideOverlay'>)} hideOverlay />
);

export type MModalGroupProps = {
  visible: boolean;
  currentStep: string;
  delayBetweenSteps?: number;
  onClose: () => void;
  onStepEnd?: (step: string) => void;
  onStepStart?: (start: string) => void;
};

/**
 * Display multiple modals with a common overlay. Disable the modal
 * overlays if they are not already hidden
 *
 * Props:
 * - visible: if the modal group should be visible
 * - onClose: action when closing the current modal
 * - delayBetweenSteps: delay between two modal items (in ms)
 * - currentStep: the modal to show
 * - onStepStart: callback when the transition between two modals starts
 * - onStepEnd: callback when the transition between two modals is over
 */
export const MModalGroup: FunctionComponent<MModalGroupProps> = ({
  visible,
  children,
  currentStep,
  delayBetweenSteps = 20,
  onClose,
  onStepEnd = () => {},
  onStepStart = () => {},
}) => {
  const originalStep = useRef(currentStep);

  const [modalInfo, setModalInfo] = useState({
    step: currentStep,
    visible: false,
  });

  const showCurrentStep = useTimeoutCallback(
    useCallback(() => {
      setModalInfo({
        step: currentStep,
        visible: true,
      });
      onStepEnd(currentStep);
    }, [currentStep, onStepEnd]),
    delayBetweenSteps,
  );

  const handleStepSwitch = useCallback(() => {
    if (!visible) {
      return;
    }

    showCurrentStep.exec();
  }, [visible, showCurrentStep]);

  const childToRender = useMemo(() => {
    const childrenArray = React.Children.toArray(children);

    const child = childrenArray.find((c) => {
      if (isValidElement(c) && c.props.step) {
        return c.props.step === modalInfo.step;
      }

      throw new Error('Invalid react element: you must use MModalGroupItem');
    });

    const validChild = child! as ReactElement;

    return {
      ...validChild,
      props: {
        ...validChild.props,
        onAfterClose: handleStepSwitch,
        onCloseModal: onClose,
        visible: modalInfo.visible,
      },
    };
  }, [children, modalInfo, onClose, handleStepSwitch]);

  useEffect(() => {
    setModalInfo((prev) => ({
      ...prev,
      step: !visible ? originalStep.current : prev.step,
      visible,
    }));
  }, [visible]);

  useEffect(() => {
    if (currentStep !== modalInfo.step && modalInfo.visible) {
      setModalInfo((prev) => ({
        ...prev,
        visible: false,
      }));
      onStepStart(currentStep);
    }
  }, [currentStep, onStepStart, modalInfo, delayBetweenSteps]);

  return (
    <>
      <MModalOverlay visible={visible} />
      {childToRender}
    </>
  );
};

export default MModalGroup;
