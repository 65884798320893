import React, { useCallback, useState, useRef, useEffect } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import SCodeInput from 'Components/structural/SCodeInput/SCodeInput';

import { AudienceBackButton } from '../AudienceBackButton/AudienceBackButton';
import { AudienceTable } from '../AudienceTable/AudienceTable';
import { actions, useModuleSelector } from '../../../redux';

import styles from './AudienceMagicCode.style';

const COPY_TIME = 1000;
const TIMEOUT_DELAY = 600;

export const AudienceMagicCode = () => {
  const dispatch = useTypedDispatch();
  const currentMagicCode = useModuleSelector((state) => state.audience.MAGIC_CODE.magicCode);
  const originalMagicCode = useModuleSelector((state) => state.originalAudience.MAGIC_CODE.magicCode);
  const warningOn = useModuleSelector((state) => state.audience.isMagicCodeWarningOn);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(t('activities:audience.magic_code.copy'));
  const [changeFeedback, setChangeFeedback] = useState({ status: 'none', message: '' });
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (warningOn) setChangeFeedback({ status: 'warning', message: t('activities:audience.magic_code.already_used') });
    else setChangeFeedback({ status: 'none', message: '' });
  }, [warningOn]);

  const onIconClick = useCallback(() => {
    dispatch(actions.setMagicCode(null));

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      dispatch(actions.isAudienceMagicCodeUnique());
    }, TIMEOUT_DELAY);
  }, [dispatch]);

  const onButtonClick = useCallback((value) => {
    const toSearch = `input[data-copy-id='s-code-input-${value}']`;

    const toCopy = document.querySelector(toSearch) as HTMLInputElement | null;

    if (!toCopy) {
      console.error('Element not found');
      return;
    }

    toCopy.select();

    if (document.execCommand('copy')) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
      setButtonText(t('activities:audience.magic_code.copied'));
      setTimeout(() => setButtonText(t('activities:audience.magic_code.copy')), COPY_TIME);
    }
    // eslint-disable-next-line no-console
    else console.error('The clipboard copy went wrong…');
  }, []);

  const onInputChange = useCallback(
    (value: string) => {
      const newValue = value.trim();

      dispatch(actions.setMagicCode(newValue));
      setButtonDisabled(!newValue);

      if (timeout.current) clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        dispatch(actions.isAudienceMagicCodeUnique());
      }, TIMEOUT_DELAY);
    },
    [dispatch],
  );

  const onBlur = useCallback(() => {
    if (originalMagicCode === currentMagicCode) return;

    if (!currentMagicCode.trim()) {
      dispatch(actions.setMagicCode(originalMagicCode));

      // Avoid to recheck if magic code is used because it's the code already saved, it will be a false warning
      if (timeout.current) clearTimeout(timeout.current);
    }

    setButtonDisabled(false);
  }, [dispatch, currentMagicCode, originalMagicCode, timeout]);

  return (
    <div style={styles.wrapper}>
      <AudienceBackButton />
      <div style={styles.infoWrapper}>
        <div style={styles.title}>{t('activities:audience.magic_code.title')}</div>
        <div style={styles.subtitle}>{t('activities:audience.magic_code.subtitle')}</div>
        <SCodeInput
          value={currentMagicCode}
          onButtonClick={onButtonClick}
          onIconClick={onIconClick}
          onBlur={onBlur}
          onInputChange={onInputChange}
          buttonDisabled={buttonDisabled}
          buttonText={buttonText}
          style={styles.codeInput}
          description={t('activities:audience.magic_code.description')}
          codeChangeFeedbackStatus={changeFeedback.status}
          codeChangeFeedbackMessage={changeFeedback.message}
        />
        <div style={styles.audienceTable}>
          <AudienceTable />
        </div>
      </div>
    </div>
  );
};
