import React, { useCallback, useMemo, useState } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import { COLORS } from 'Components/foundation';
import type { IconName } from 'Components/foundation/icons';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import SDivider from 'Components/structural/SDivider/SDivider';
import UIcon from 'Components/unit/UIcon/UIcon';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { VideoInstance } from 'Models/Video';
import type { ImageInstance } from 'Models/Image';

import styles from './MVideoUpload.style';
import { saveVideo } from './utils';

export type VideoProvider = {
  icon: IconName;
  name: string;
  typeId: number;
  instruction: string;
  instructionHref: string;
  validateUrl: (url: string) => boolean;
};

export type OnSaveVideoArgs = { video: VideoInstance; image?: ImageInstance };

export type MVideoUploadProps = {
  providers: VideoProvider[];
  visible: boolean;
  uploadThumbnail?: boolean;
  style?: CSSProperties;

  onSave: (args: OnSaveVideoArgs) => void;
  onClose: () => void;
};

/**
 * This modal is used to provide an interface to upload videos from differents providers.
 *
 * - providers: a list of providers to display in the modal. They should be supported by the Video model.
 * - uploadThumbnail: if true, the thumbnail is fetched and stored as an image.
 * - validateUrl: this function is used to valdiate wether a video url is valid, by default this is Video.isValidURL
 */
export const MVideoUpload = ({
  providers,
  uploadThumbnail,
  visible,
  style = {},

  onSave,
  onClose,
}: MVideoUploadProps) => {
  const [selectedVideoTypeId, setSelectedVideoTypeId] = useState<number | undefined>(undefined);
  const [url, setUrl] = useState('');
  const [showError, setShowError] = useState(false);

  const handleInputFactory = useCallback(
    (typeId: number, validateUrl: VideoProvider['validateUrl']) => (newUrl: string) => {
      setUrl(newUrl);

      if (!newUrl && selectedVideoTypeId) {
        setSelectedVideoTypeId(undefined);
      }

      if (newUrl && !selectedVideoTypeId) {
        setSelectedVideoTypeId(typeId);
      }

      const isUrlValid = validateUrl(newUrl);

      if (isUrlValid && showError) {
        setShowError(false);
      }

      if (!isUrlValid) {
        setShowError(true);
      }
    },
    [selectedVideoTypeId, showError],
  );

  const handlers = useMemo(
    () =>
      providers.reduce(
        (acc, { typeId, validateUrl }) => ({
          ...acc,
          [typeId]: handleInputFactory(typeId, validateUrl),
        }),
        {} as Record<number, (input: string) => void>,
      ),
    [handleInputFactory, providers],
  );

  const handleSave = useCallback(async () => {
    if (!selectedVideoTypeId || !url) {
      return;
    }

    const result = await saveVideo({ url, videoTypeId: selectedVideoTypeId }, uploadThumbnail);

    onSave?.(result);
  }, [onSave, selectedVideoTypeId, url, uploadThumbnail]);

  const canUpload = Boolean(url && !showError);

  return (
    <MModal
      type="dialog"
      title={t('modal_components:m_video_upload.title')}
      labelActionButton={t('modal_components:m_video_upload.button')}
      labelSecondButton={t('modal_components:m_video_upload.cancel')}
      visible={visible}
      style={{ ...styles.modal, ...style }}
      onAction={handleSave}
      onActionEnd={onClose}
      onCloseModal={onClose}
      onSecondAction={onClose}
      disableActionButton={!canUpload}
      showExitCross
    >
      <div style={styles.providerList}>
        {providers?.map(({ icon, name, typeId, instruction, instructionHref }, i) => (
          <React.Fragment key={typeId}>
            {i !== 0 && <SDivider style={styles.divider} />}
            <div style={styles.providerHeader}>
              <div style={styles.providerTitleContainer}>
                <UIcon color={COLORS.GREY_DARK.DEFAULT} name={icon} size={20} />
                <h3 style={styles.providerTitle}>
                  {t('modal_components:m_video_upload.enter_video_url', { provider: name })}
                </h3>
              </div>
              {instructionHref && (
                <UTextLink
                  text={t('modal_components:m_video_upload.show_instructions')}
                  iconSize="medium"
                  colorOverride={COLORS.TEXT.SECONDARY_DEFAULT}
                  leftIcon="external-link"
                  link={instructionHref}
                  style={styles.providerInstructionsLink}
                />
              )}
            </div>
            <SInput
              type="small"
              placeholder={t('modal_components:m_video_upload.video_url_placeholder')}
              onChange={handlers[typeId]}
              disabled={Boolean(selectedVideoTypeId) && typeId !== selectedVideoTypeId}
              value={typeId === selectedVideoTypeId ? url : ''}
              label={instruction}
              errorMessage={
                typeId === selectedVideoTypeId && showError ? t('modal_components:m_video_upload.invalid_url') : ''
              }
              style={styles.providerInput}
            />
          </React.Fragment>
        ))}
      </div>
    </MModal>
  );
};

export default MVideoUpload;
