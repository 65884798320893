import React from 'react';

import { IconName } from 'Components/foundation/icons';
import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';

import styles from './CountersBlock.style';

export type CounterItem = {
  icon: IconName;
  text: string;
};

export type CountersBlockProps = {
  counters: CounterItem[];
};

export const CountersBlock = ({ counters }: CountersBlockProps) => (
  <div style={styles.list}>
    {counters.map(({ icon, text }) => (
      <div key={`${text}`} style={styles.item}>
        <UIcon name={icon} size={24} color={COLORS.BLACK.DEFAULT} />
        <h3>
          <UMarkdown style={styles.text} markdown={text} />
        </h3>
      </div>
    ))}
  </div>
);
