import Model from 'models/Model';

var AuthenticationType = {
  endpoint  : 'api/AuthenticationTypes',
  attributes: {
    id: {
      type: 'number',
    },
    label: {
      type: 'string',
    },
  },
};

export default Model.register('AuthenticationType', AuthenticationType);
