import React, { useEffect, useRef, useCallback } from 'react';
import type { CSSProperties, FunctionComponent } from 'react';

type OutsideClickWrapperProps = {
  onClick: (isOutsideClicked: boolean) => void;
  style?: CSSProperties;
  stopClickPropagation?: boolean;
};

const OutsideClickWrapper: FunctionComponent<OutsideClickWrapperProps> = ({
  onClick,
  style,
  children,
  stopClickPropagation = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onClick(true);
        if (stopClickPropagation) {
          event.preventDefault();
          event.stopPropagation();
        }
        return;
      }

      onClick(false);
    },
    [onClick, stopClickPropagation],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div style={{ ...style }} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideClickWrapper;
