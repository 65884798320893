import React, { ChangeEvent } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import styles from './STextArea.style';
import ULabel, { LabelSize } from 'Components/unit/ULabel/ULabel';
import { IconName } from 'Components/foundation/icons';

export type STextAreaProps = {
  height: string;
  label: string;
  value: string;
  isLabelRequired?: boolean;
  labelIcon?: IconName;
  labelSize?: LabelSize;
  radiusWrapper?: number;
  textAreaTypo?: keyof typeof TYPOGRAPHY;
  placeholder: string;
  errorMessage: string;
  maxLength: number;
  canResize: boolean;
  onChange: (value: string) => void;
  disabled: boolean;
  style?: React.CSSProperties;
};

interface State {
  isFocused: boolean;
}

/**
 * Display a textarea
 *
 * Props:
 * - height: Set a default height
 * - label: Set a label to textarea
 * - value: Set a value to textarea
 * - placeholder: Value to set in the teaxtarea placeholder
 * - errorMessage: String to set for error feedback
 * - maxLength: Specify a max number of characters
 * - canResize: textarea can be resized
 * - onChange: callback on change
 * - disabled: disable the capacity to modify input
 * - style: can override the component style
 */
export class STextArea extends React.Component<STextAreaProps, State> {
  static defaultProps: Partial<STextAreaProps> = {
    height: '100',
    label: '',
    labelSize: 'S',
    labelIcon: undefined,
    radiusWrapper: 4,
    value: '',
    textAreaTypo: 'BODY3',
    placeholder: '',
    isLabelRequired: false,
    errorMessage: '',
    maxLength: 0,
    canResize: true,
    onChange: () => {},
    disabled: false,
  };

  state: State = {
    isFocused: false,
  };

  render() {
    const { style, disabled, radiusWrapper } = this.props;
    const { isFocused } = this.state;
    const focusedStyle = isFocused ? styles.focused : {};
    const disabledStyle = disabled ? styles.disabled : {};
    const radiusStyle = { borderRadius: radiusWrapper };

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderLabelAndCount()}
        <div style={{ ...styles.textAreaWrapper, ...focusedStyle, ...disabledStyle, ...radiusStyle }}>
          {this.renderTextArea()}
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderLabelAndCount = () => {
    const { label, labelSize, labelIcon, isLabelRequired, maxLength, value } = this.props;

    let style = { ...styles.count };

    if (maxLength === value.length) style = { ...style, ...styles.countReached };

    return (
      <div style={styles.labelAndCountWrapper}>
        {label && (
          <ULabel icon={labelIcon} required={isLabelRequired} size={labelSize}>
            {label}
          </ULabel>
        )}
        {maxLength > 0 && <div style={style}>{`${value.length}/${maxLength}`}</div>}
      </div>
    );
  };

  renderTextArea = () => {
    const { value, textAreaTypo = 'BODY3', maxLength, height, placeholder, canResize, disabled } = this.props;

    const resizeStyle = !canResize ? styles.noResize : {};
    const disabledStyle = disabled ? styles.textDisabled : {};
    const minHeightStyle: React.CSSProperties = {
      minHeight: `${height}px`,
    };

    return (
      <textarea
        placeholder={placeholder}
        maxLength={maxLength || undefined}
        value={value}
        style={{
          ...styles.textAreaField,
          ...minHeightStyle,
          ...resizeStyle,
          ...disabledStyle,
          ...TYPOGRAPHY[textAreaTypo],
        }}
        onChange={this.handleValueChange}
        onClick={this.handleOnClick}
        onBlur={this.handleLeave}
        disabled={disabled}
        className="structural-inputs"
      />
    );
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.props;

    if (!errorMessage) return null;

    return (
      <div style={styles.errorMessage}>
        <UIcon color={COLORS.ERROR.DEFAULT} style={styles.errorIcon} name="error" size={14} />
        {errorMessage}
      </div>
    );
  };

  handleOnClick = () => {
    this.setState({ isFocused: true });
  };

  handleLeave = () => {
    this.setState({ isFocused: false });
  };

  handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onChange, maxLength } = this.props;
    const value = event.target.value.slice(0, maxLength || undefined);

    if (onChange) onChange(value);
  };
}

export default STextArea;
