import { store } from 'Libs/redux/store';

import { actions, QueueContexts, QueueStatus } from './slice';
import { QueueItem } from './types';

export type QueueProcessHandler<T> = (queueItem: QueueItem<T>) => Promise<any>;

type GetQueueItemGeneric<Handler> = Handler extends (queueItem: QueueItem<infer I>) => Promise<any> ? I : never;
export type InferQueueItem<Handler> = QueueItem<GetQueueItemGeneric<Handler>>;
export type InferPushQueueItem<Handler> = Omit<InferQueueItem<Handler>, 'tryCount'>;

function stop(context: QueueContexts) {
  store.dispatch(actions.updateStatus({ context, status: QueueStatus.STOPPED }));
}

function flush(context: QueueContexts) {
  store.dispatch(actions.flush({ context }));
}

async function process<T>(context: QueueContexts, handler: QueueProcessHandler<T>) {
  const currentContext = store.getState().services.queue[context];
  const oldItem: InferQueueItem<typeof handler> = currentContext.queue[0];
  const item = { ...oldItem, tryCount: oldItem.tryCount + 1 };

  await store.dispatch(actions.updateItem({ context, item }));

  try {
    await handler(item);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- the pop is hoisted
    pop(context, handler);
  } catch (err) {
    // eslint-disable-next-line no-console -- Otherwise this error does not get logged anywhere
    console.error(err);
    stop(context);
  }
}

function next<T>(context: QueueContexts, handler: QueueProcessHandler<T>) {
  const currentContext = store.getState().services.queue[context];
  if (currentContext.queue.length && currentContext.status === QueueStatus.IDLE) {
    store.dispatch(actions.updateStatus({ context, status: QueueStatus.BUSY }));
    process(context, handler);
  }
}

function pop<T>(context: QueueContexts, handler: QueueProcessHandler<T>) {
  store.dispatch(actions.pop({ context }));

  const currentContext = store.getState().services.queue[context];
  if (currentContext.status === QueueStatus.BUSY) {
    store.dispatch(actions.updateStatus({ context, status: QueueStatus.IDLE }));
  }

  next(context, handler);
}

function start<T>(context: QueueContexts, handler: QueueProcessHandler<T>) {
  store.dispatch(actions.updateStatus({ context, status: QueueStatus.IDLE }));
  next(context, handler);
}

function push<T>(context: QueueContexts, handler: QueueProcessHandler<T>, item: InferPushQueueItem<typeof handler>) {
  const itemToPush = {
    ...item,
    tryCount: 0,
  };
  store.dispatch(actions.push({ context, item: itemToPush }));
  next(context, handler);
}

export {
  stop,
  start,
  push,
  pop,
  flush,
  next,
  process,
};
