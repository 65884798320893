import { createStyleSheet } from 'Components/foundation/stylesheets';

import { COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT,
  },
});

export default styleSheet;
