/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable flowtype/require-types-at-top */
// @flow

/**
 * Display a new card emplacement to add a new content to a daily-serie
 *
 * Props:
 * - style: For overriding the component's style
 * - onClick: Callback when clicking on the component
 * - type: Different type of the component: standard | first | accentuated | disabled
 * - label: The content language to display in the card's body
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';

import { COLORS } from 'Components/foundation';

import styles from './SNewContentCard.style';

const MODES = Object.freeze({
  STANDARD: 'standard',
  FIRST_CAMPAIGN_SCHEDULING: 'first_campaign_scheduling',
  CAMPAIGN_SCHEDULING: 'campaign_scheduling',
  FIRST: 'first',
  ACCENTUATED: 'accentuated',
  DISABLED: 'disabled',
});

type ContentMode = $Values<typeof MODES>;

type Props = {|
  onClick: MouseEventHandler,
  type: ContentMode,
  label: string,
  style?: Object,
|};

type State = {|
  isHover: boolean,
|};

const STYLE = {
  [MODES.STANDARD]: {
    DEFAULT: {
      color: COLORS.TEXT.SECONDARY_DEFAULT,
      backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    },
    HOVER: {
      color: COLORS.TEXT.SECONDARY_DEFAULT,
      backgroundColor: COLORS.GREY_LIGHT.HOVER,
    },
  },
  [MODES.FIRST_CAMPAIGN_SCHEDULING]: {
    DEFAULT: {
      color: COLORS.PRIMARY.DEFAULT,
      backgroundColor: `${COLORS.PRIMARY_LIGHT.DEFAULT}C8`, // Opacity of 200 (C8 in hex)
    },
    HOVER: {
      color: COLORS.PRIMARY.DEFAULT,
      backgroundColor: `${COLORS.PRIMARY_LIGHT.DEFAULT}64`, // Opacity of 100 (64 in hex)
    },
  },
  [MODES.CAMPAIGN_SCHEDULING]: {
    DEFAULT: {
      color: COLORS.TEXT.SECONDARY_DEFAULT,
      backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    },
    HOVER: {
      color: COLORS.TEXT.SECONDARY_DEFAULT,
      backgroundColor: COLORS.GREY_LIGHT.HOVER,
    },
  },
  [MODES.FIRST]: {
    DEFAULT: {
      color: COLORS.PRIMARY.DEFAULT,
      backgroundColor: `${COLORS.PRIMARY_LIGHT.DEFAULT}C8`, // Opacity of 200 (C8 in hex)
    },
    HOVER: {
      color: COLORS.PRIMARY.DEFAULT,
      backgroundColor: `${COLORS.PRIMARY_LIGHT.DEFAULT}64`, // Opacity of 100 (64 in hex)
    },
  },
  [MODES.ACCENTUATED]: {
    DEFAULT: {
      color: COLORS.WHITE.DEFAULT,
      backgroundColor: COLORS.PRIMARY.DEFAULT,
    },
    HOVER: {
      color: COLORS.WHITE.DEFAULT,
      backgroundColor: COLORS.PRIMARY.HOVER,
    },
  },
  [MODES.DISABLED]: {
    DEFAULT: {
      color: COLORS.GREY_DARK.DEFAULT,
      backgroundColor: COLORS.GREY_DARK.DISABLED,
      cursor: 'default',
    },
    HOVER: {
      color: COLORS.GREY_DARK.DEFAULT,
      backgroundColor: COLORS.GREY_DARK.DISABLED,
      cursor: 'default',
    },
  },
};

export class SNewContentCard extends React.PureComponent<Props, State> {
  static defaultProps = {
    type: 'standard',
    label: '',
    style: undefined,
  };

  state = {
    isHover: false,
  };

  render() {
    const { style, type } = this.props;
    const { isHover } = this.state;
    const isHovered = isHover ? 'HOVER' : 'DEFAULT';

    return (
      <div
        id="new-content-card"
        className="nonDraggable"
        data-tracking-action="create"
        data-tracking-context="content"
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={this.handleClick}
        style={{
          ...styles.cardWrapper,
          ...STYLE[type][isHovered],
          ...style,
        }}
      >
        <UIcon name="plus-light" size={17} color={STYLE[type][isHovered].color} />
        {this.renderExplanation(STYLE[type].DEFAULT.color)}
      </div>
    );
  }

  renderExplanation = (color: string) => {
    const { label, type } = this.props;

    if (type === 'standard' || type === 'disabled') return null;

    return <h2 style={{ ...styles.label, color }}>{label}</h2>;
  };

  handleClick = (event: MouseEvent) => {
    const { onClick, type } = this.props;

    if (type === 'disabled') return;

    onClick(event);
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default SNewContentCard;
