import m from 'm';
import reactCreator from 'components/react.creator';
import menu from 'pages/menu';


const view = () => m('.knowledgePage', [
  m('.header', [
    m(menu),
  ]),
  m(reactCreator, {
    component: 'KnowledgeGrid',
    style    : { height: '100%' },
  }),
]);

const component = { view };

export default component;
