import { t } from 'i18next';
import request from 'services/requestService';
import Enum from 'models/Enum';
import Model from 'models/Model';

var Content = {
  endpoint  : 'api/Contents',
  attributes: {
    status: {
      type : 'pointer',
      Model: 'ContentStatus',
    },
    gameplay: {
      type : 'pointer',
      Model: 'Gameplay',
    },
    gameplayId: {
      type: 'number',
    },
    contentId: {
      type: 'number',
    },
    'QCM': {
      type : 'pointer',
      Model: 'QCM',
    },
    'SQCM': {
      type : 'pointer',
      Model: 'SQCM',
    },
    'Error Text': {
      type : 'pointer',
      Model: 'ErrorText',
    },
    'Open Question': {
      type : 'pointer',
      Model: 'OpenQuestion',
    },
    'No Interaction Content': {
      type : 'pointer',
      Model: 'NoInteractionContent',
    },
    'Picture Spot': {
      type : 'pointer',
      Model: 'PictureSpot',
    },
    'Swiping Cards': {
      type : 'pointer',
      Model: 'SwipingCards',
    },
    'Ordering Cards': {
      type : 'pointer',
      Model: 'OrderingCards',
    },
    thematicId: {
      type: 'number',
    },
    knowledge: {
      type : 'pointer',
      Model: 'Knowledge',
    },
    knowledgeId: {
      type: 'number',
    },
    statusId: {
      type: 'number',
    },
    previewUrl: {
      type: 'string',
    },
    archived: {
      type      : 'boolean',
      defaultsTo: false,
    },
    updatedAt: {
      type: 'date',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_Content, toCompare) {
      var gameplayName = _Content.getGamePlayName(this.gameplayId());
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;
      var gameJsonToCompare = jsonToCompare[gameplayName];
      var gameIsEqual = this.game().isEqualTo(gameJsonToCompare);

      return this.gameplayId() === jsonToCompare.gameplayId
        && this.contentId() === jsonToCompare.contentId
        && this.thematicId() === jsonToCompare.thematicId
        && this.knowledgeId() === jsonToCompare.knowledgeId
        && this.statusId() === jsonToCompare.statusId
        && gameIsEqual;
    },
    getRoute: function getRoute() {
      return this.knowledge().getRoute();
    },
    imageFront: function imageFront(_Content, image) {
      return this.game().imageFront(image);
    },
    imageBack: function imageBack(_Content, image) {
      return this.game().imageBack(image);
    },
    saveFull: function saveFull(_Content) {
      var self = this;

      // TODO: strip useless gameplays
      if (self.gameplayId() !== Enum.gameplay.QCM)
        self.QCM(null);

      if (self.gameplayId() !== Enum.gameplay.SQCM)
        self.SQCM(null);

      if (self.gameplayId() !== Enum.gameplay.ERROR_TEXT)
        self['Error Text'](null);

      if (self.gameplayId() !== Enum.gameplay.OPEN_QUESTION)
        self['Open Question'](null);

      if (self.gameplayId() !== Enum.gameplay.NO_INTERACTION_CONTENT)
        self['No Interaction Content'](null);

      if (self.gameplayId() !== Enum.gameplay.PICTURE_SPOT)
        self['Picture Spot'](null);

      if (self.gameplayId() !== Enum.gameplay.SWIPING_CARDS)
        self['Swiping Cards'](null);

      if (self.gameplayId() !== Enum.gameplay.ORDERING_CARDS)
        self['Ordering Cards'](null);

      var json;
      var promise;

      json = self.toJSON();
      promise = request
        .post(_Content.model.endpoint + '/full', json);

      return promise.then(function instanciate(responseJSON) {
        return new _Content(responseJSON);
      });
    },
    game: function game(_Content) {
      var gameplayName = _Content.getGamePlayName(this.gameplayId());

      return this[gameplayName]();
    },

    isValid: function isValid() {
      var validStatusArray = [
        Enum.contentStatus.TO_VALIDATE,
        Enum.contentStatus.VALIDATED,
      ];

      if (validStatusArray.indexOf(this.statusId()) !== -1)
        return true;
      try {
        return this.game().validate();
      } catch (err) {
        return false;
      }
    },

    nextStatus: function nextStatus() {
      var self = this;

      return request
        .get('api/ContentStatuses/' + self.status().id() + '/nextStatus')
        .then(function instanciateStatus(res) {
          var ContentStatus = Model('ContentStatus');

          return new ContentStatus(res);
        })
        .then(function updateStatus(status) {
          self.status(status);

          return status;
        });
    },
    changeStatus: function changeStatus(_Content, newStatusId) {
      return request.put('api/Contents/' + this.id() + '/changeStatus', {
        newContentStatusId: newStatusId,
      })
        .then(function handleResponse(response) {
          return new _Content(response);
        });
    },
    presetFromKnowledge: function presetFromKnowledge(_Content, knowledge) {
      if (!this.game().presetFromKnowledge)
        console.warn('[Content]', 'presetFromKnowledge not on the gameplay.');

      return this.game().presetFromKnowledge(knowledge);
    },
    getDirective: function getDirective(_Content, data) {
      var directive = this.game().getDirective(data);

      if (directive)
        return directive;
      if (this.status().id() !== Enum.contentStatus.VALIDATED)
        return  t('gameplays:directives.complete');

      return '';
    },
  },
  classMethods: {
    getGamePlayName: function getGamePlayName(_Content, gameplayId) {
      switch (gameplayId) {
        case Enum.gameplay.QCM:
          return 'QCM';
        case Enum.gameplay.SQCM:
          return 'SQCM';
        case Enum.gameplay.ERROR_TEXT:
          return 'Error Text';
        case Enum.gameplay.OPEN_QUESTION:
          return 'Open Question';
        case Enum.gameplay.NO_INTERACTION_CONTENT:
          return 'No Interaction Content';
        case Enum.gameplay.PICTURE_SPOT:
          return 'Picture Spot';
        case Enum.gameplay.SWIPING_CARDS:
          return 'Swiping Cards';
        case Enum.gameplay.ORDERING_CARDS:
          return 'Ordering Cards';
        default:
          console.error('[Content.game] This gameplay is not listed here');

          return '';
          break;
      }
    },
    getGamePlayModel: function getGamePlayName(_Content, gameplayId) {
      switch (gameplayId) {
        case Enum.gameplay.QCM:
          return Model('QCM');
        case Enum.gameplay.SQCM:
          return Model('SQCM');
        case Enum.gameplay.ERROR_TEXT:
          return Model('ErrorText');
        case Enum.gameplay.OPEN_QUESTION:
          return Model('OpenQuestion');
        case Enum.gameplay.NO_INTERACTION_CONTENT:
          return Model('NoInteractionContent');
        case Enum.gameplay.PICTURE_SPOT:
          return Model('PictureSpot');
        case Enum.gameplay.SWIPING_CARDS:
          return Model('SwipingCards');
        case Enum.gameplay.ORDERING_CARDS:
          return Model('OrderingCards');
        default:
          console.error('[Content.game] This gameplay is not listed here');

          return '';
          break;
      }
    },
    getFullById: function getFullById(_Content, id) {
      var url = [_Content.model.endpoint, '/', id, '/full'].join('');

      return request
        .get(url)
        .then(function instantiate(data) {
          return new _Content(data);
        });
    },
    filter: function filter(_Content, obj) {
      var path = '/api/Contents/full';

      return request
        .get(path, obj);
    },
    createGameplay: function createGameplay(_Content, gameplayId, knowledgeId) {
      var gameplayName = _Content.getGamePlayName(gameplayId);
      var gameplayModel = _Content.getGamePlayModel(gameplayId);
      var gameplayTemplate = gameplayModel.initGameplay(knowledgeId);
      var contentTemplate = {
        gameplayId : gameplayId,
        knowledgeId: knowledgeId,
      };

      contentTemplate[gameplayName] = gameplayTemplate;

      return new _Content(contentTemplate);
    },
  },
};

export default Model.register('Content', Content);
