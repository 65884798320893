import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';

var Battle = {
  endpoint  : 'api/Battles',
  attributes: {
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
  },
  methods: {
    saveFull: function saveFull(_Battle) {
      var self = this;

      var json = self.toJSON();
      var promise;

      if (self.id()) {
        promise = request
          .put(_Battle.model.endpoint + '/full/' + self.id(), json);
      } else {
        promise = request
          .post(_Battle.model.endpoint + '/full', json);
      }

      return promise.then(function instanciate(responseJSON) {
        return new _Battle(responseJSON);
      });
    },
  },
  classMethods: {
    getFull: function getFull(_Battle) {
      return request.get(_Battle.model.endpoint + '/full')
        .then(function returnCollection(battles) {
          return new Collection('Battle', Battle, battles);
        });
    },
  },

};

export default Model.register('Battle', Battle);
