import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    width: '1000px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: -55,
    paddingBottom: 72,
  },
  shortCutCard: {
    marginTop: '40px',
    height: '306px',
  },
  optionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px',
  },
  select: {
    marginRight: 0,
    display: 'flex',
  },
  loader: {
    margin: 'auto',
    marginTop: '52px',
  },
});

export default styleSheet;
