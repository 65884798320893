import { createSelector } from 'reselect';
import moment from 'moment';
import { t } from 'i18next';

import Enum from 'Models/Enum';

import type { State } from 'Libs/redux/types';

import type { ActivityType } from './models/Activity';
import type { DailySerieType } from './models/DailySerie';
import type { MultilingualContentsMapType } from './reducer';

import { getDailySerieEndDate } from '../date.utils';
import { getLanguageDetail } from './languageHelpers';
import type { ContentType } from './models/Content';
import type { DailySerieContentType } from './models/DailySerieContent';

export type LanguageOptionWithoutCallbackType = {
  type: 'language';
  key: string;
  id: number;
  props: {
    defaultLabel: string;
    switchToLabel: string;
    isDefault: boolean;
    text: string;
    countryCode: string;
    selected: boolean;
    translationPercent: number | null;
    validLabel: string;
  };
};

export type LanguageSupportedType = {
  languageId: number;
  countryCode: string;
  label: string;
  isDefault: boolean;
  defaultLabel: string;
};

export type LanguageProgressTranslationType = {
  languageId: number;
  progressLabel: string | undefined;
  progress: number | null;
  canAskForTranslation: boolean;
};

const getDailySerie = (state: State) => state.pages.activity.activities[0].dailySerie;
const getStartDate = (state: State) => state.pages.activity.activities[0].startDate;

export const getIsAskKnowledgeTranslationLoading = (state: State) =>
  state.pages.activity.isAskKnowledgeTranslationLoading;

export const getActivityEndDate = createSelector(
  getDailySerie,
  getStartDate,
  (dailySerie: DailySerieType, startDate: string) => {
    const dailySerieDayDisabled = dailySerie.dailySerieDayDisabled.filter((x) => x.date !== null);
    const ds = { ...dailySerie, dailySerieDayDisabled };

    return getDailySerieEndDate(ds, startDate);
  },
);

export const getActivityEndDateForDisplay = createSelector(getActivityEndDate, (endDate: string) =>
  moment.utc(endDate).startOf('day').toISOString(),
);

const getActivities = (state: State) => state.pages.activity.activities;

export const hasChanged = (state: State) => {
  const { activities, originalActivities, audience, originalAudience } = state.pages.activity;

  return activities !== originalActivities || audience !== originalAudience;
};

export const hasTargetModeChanged = (state: State) => {
  const { audience, originalAudience } = state.pages.activity;

  return !!originalAudience.mode && originalAudience.mode !== audience.mode;
};

export const getDefaultActivity = createSelector(
  getActivities,
  (activities: ReadonlyArray<ActivityType>): ActivityType => {
    const defaultActivity = activities.find((a) => a.isDefault);

    if (!defaultActivity) {
      throw new Error('default activity was not found');
    }

    return defaultActivity;
  },
);

const getSelectedLanguageId = (state: State) => state.pages.activity.selectedLanguageId;
const getMultilingualContentMap = (state: State) => state.pages.activity.multilingualContentsMap;

function getTranslationProgress(
  activity: ActivityType,
  multilingualContentsMap: MultilingualContentsMapType,
): {
  languageId: number;
  nbContentToTranslate: number;
  nbContentValidated: number;
  nbContent: number;
  translationPercent: number | null;
} {
  let nbContentToTranslate = 0;
  let nbContentValidated = 0;
  let nbContent = 0;
  let translationPercent = null;

  const { isDefault, languageId } = activity;

  if (isDefault) {
    return { languageId, nbContentToTranslate, nbContentValidated, nbContent, translationPercent };
  }

  const contents: ReadonlyArray<ContentType> = activity.dailySerie.days.reduce(
    (acc: ReadonlyArray<ContentType>, day: any) => [
      ...acc,
      ...day.dailySerieContents.map((dsContent: DailySerieContentType) => dsContent.content),
    ],
    [],
  );

  contents.forEach((content: ContentType) => {
    nbContent += 1;

    /**
     * `multilingualContentsMap` always has the most up to date contents and should only be used when
     * adding new content to the campaign. Otherwise, use directly the one received from the database.
     */
    const multilingualContent =
      content.languageId === languageId ? content : multilingualContentsMap[content.multilingualId]?.[languageId];

    if (!multilingualContent || multilingualContent.languageId !== languageId) {
      nbContentToTranslate += 1;

      return;
    }

    if (multilingualContent.statusId === Enum.contentStatus.VALIDATED) {
      nbContentValidated += 1;
    }
  });

  if (nbContent !== 0) {
    translationPercent = Math.floor((nbContentValidated * 100) / nbContent);
  }

  return { languageId, nbContentToTranslate, nbContentValidated, nbContent, translationPercent };
}

export const getSelectedActivity = createSelector(
  getActivities,
  getSelectedLanguageId,
  (activities: ReadonlyArray<ActivityType>, selectedLanguageId: number): ActivityType => {
    const selectedActivity = activities.find((a) => a.languageId === selectedLanguageId);

    if (!selectedActivity) {
      throw new Error(`activity with language id ${selectedLanguageId} was not found`);
    }

    return selectedActivity;
  },
);

export const selectContentIdsFromActivity = (selectedActivity: ActivityType): number[] => {
  const dayContents = selectedActivity.dailySerie.days.reduce<ReadonlyArray<ReadonlyArray<DailySerieContentType>>>(
    (contents, day) => [...contents, day.dailySerieContents],
    [],
  );

  return dayContents.flat().reduce<number[]>((contentIds, content) => [...contentIds, content.contentId], []);
};

export const getSelectedContentIds = createSelector(getSelectedActivity, (selectedActivity): number[] =>
  selectContentIdsFromActivity(selectedActivity),
);

export const getNbContentToTranslate = createSelector(
  getSelectedActivity,
  getMultilingualContentMap,
  (activity: ActivityType, multilingualContentsMap: MultilingualContentsMapType): number =>
    getTranslationProgress(activity, multilingualContentsMap).nbContentToTranslate,
);

export const getLanguagesProgressTranslation = createSelector(
  getActivities,
  getMultilingualContentMap,
  (
    activities: ReadonlyArray<ActivityType>,
    multilingualContentsMap: MultilingualContentsMapType,
  ): ReadonlyArray<LanguageProgressTranslationType> =>
    activities.map((activity) => {
      const { languageId, nbContentToTranslate, nbContentValidated, nbContent, translationPercent } =
        getTranslationProgress(activity, multilingualContentsMap);

      const canAskForTranslation = nbContentToTranslate > 0;
      const progressLabel = t('content_translation:progress', { nbContentValidated, nbContent });

      //`Content translation progress (${nbContentValidated}/${nbContent})`;

      return {
        languageId,
        progressLabel,
        progress: translationPercent,
        canAskForTranslation,
      };
    }),
);

export const getLanguagesForOptions = createSelector(
  getActivities,
  getSelectedLanguageId,
  getMultilingualContentMap,
  (
    activities: ReadonlyArray<ActivityType>,
    selectedLanguageId: number,
    multilingualContentsMap: MultilingualContentsMapType,
  ): ReadonlyArray<LanguageOptionWithoutCallbackType> =>
    activities.map((activity) => {
      const { isDefault, languageId } = activity;
      const languageDetail = getLanguageDetail(languageId);
      const countryCode = languageDetail.data ? Enum.Languages[languageDetail.data] : '';
      const selected = selectedLanguageId === languageId;
      const { translationPercent } = getTranslationProgress(activity, multilingualContentsMap);

      return {
        type: 'language',
        id: languageId,
        key: `${languageId}`,
        props: {
          defaultLabel: t('content_translation:language_menu.default'),
          switchToLabel: t('content_translation:language_menu.switch_to'),
          isDefault,
          translationPercent,
          text: languageDetail.label,
          countryCode,
          selected,
          validLabel: t('content_translation:language_menu.ready'),
        },
      };
    }),
);

export const getLanguagesSupported = createSelector(
  getActivities,
  (activities: ReadonlyArray<ActivityType>): ReadonlyArray<LanguageSupportedType> =>
    activities.map((activity) => {
      const { isDefault, languageId } = activity;
      const languageDetail = getLanguageDetail(languageId);
      const countryCode = languageDetail.data ? Enum.Languages[languageDetail.data] : '';

      return {
        languageId,
        countryCode,
        label: languageDetail.label,
        isDefault,
        defaultLabel: t('content_translation:language_menu.default'),
      };
    }),
);

export const getLanguagesIdsSupported = createSelector(
  getActivities,
  (activities: ReadonlyArray<ActivityType>): Map<number, true> => {
    const languageIdMap = new Map();

    activities.forEach(({ languageId }) => {
      languageIdMap.set(languageId, true);
    });

    return languageIdMap;
  },
);

export const getIsPendingTranslation = createSelector(getDefaultActivity, (activity: ActivityType) =>
  activity.dailySerie.days.some((day) =>
    day.dailySerieContents.some(
      (dsContent) =>
        dsContent.content.knowledgePendingTranslation || dsContent.content.statusId !== Enum.contentStatus.VALIDATED,
    ),
  ),
);

export const getHasActivityStarted = createSelector(getDefaultActivity, (activity: ActivityType) => {
  const now = moment(Date.now());
  return moment(activity.startDate).startOf('day').isBefore(now);
});

export const getIsModeSelectionLocked = createSelector(
  getDefaultActivity,
  (activity: ActivityType) => activity.lastActivated != null,
);

export const getIsContentSelectionLocked = createSelector(
  getDefaultActivity,
  (activity: ActivityType) => activity.dailySerie.random && activity.lastActivated != null,
);
