import React, { useState, useMemo, useCallback } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import STextArea from 'Components/structural/STextArea/STextArea';
import SSelect, { SSelectItem } from 'Components/structural/SSelect/SSelect';
import UMediaPicker from 'Components/unit/UMediaPicker/UMediaPicker';
import type { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';

import styles from './MCourseForm.style';

export type SelectedLanguage = {
  id: number;
  value: string;
  isDefault: boolean;
  label: string;
};

type CourseFormData = {
  title: string;
  description?: string;
  languageCountry?: string;
  image?: PickerSelectedImage | null;
};

type PresetImage = {
  url: string;
};

export type PresetCourseData = {
  title?: string;
  description?: string;
  image?: PresetImage;
  language?: string;
};

export type MCourseFormProps = {
  visible: boolean;
  availableLanguages: ReadonlyArray<SelectedLanguage>;
  onConfirm: (formData: CourseFormData) => void;
  onClose: () => void;
  modalTitle?: string;
  modalConfirmButtonLabel?: string;
  initialData?: PresetCourseData;
  maxDescriptionLength?: number;
  maxTitleLength?: number;
  error?: string;
  style?: CSSProperties;
};

const DEFAULT_MAX_TITLE_LENGTH = 40;
const DEFAULT_MAX_DESCRIPTION_LENGTH = 282;
const INITIAL_COURSE_DATA = {
  title: '',
  description: '',
  image: undefined,
  language: undefined,
};

const getLanguageFromCountryCode = (availableLanguages: ReadonlyArray<SelectedLanguage>, countryCode: string) =>
  availableLanguages.find((availableLanguage) => availableLanguage.value === countryCode);

/**
 * Displays an MCourse creation form modal
 *
 * Props:
 * - onConfirm: Function called when modal data is confirmed
 * - onClose: Function called to close modal
 * - visible: boolean that handles the visibility of the modal
 * - error: string that is shown as an error beside the actions buttons
 * - style: can override the component's style
 */
export const MCourseForm = ({
  onConfirm,
  onClose,
  availableLanguages,
  modalTitle,
  modalConfirmButtonLabel,
  initialData = undefined,
  visible = false,
  maxDescriptionLength = DEFAULT_MAX_DESCRIPTION_LENGTH,
  maxTitleLength = DEFAULT_MAX_TITLE_LENGTH,
  error = undefined,
  style = {},
}: MCourseFormProps) => {
  const {
    title: initialTitle,
    description: initialDescription,
    image: initialImage,
    language: initialLanguageCountry,
  } = initialData || INITIAL_COURSE_DATA;
  const defaultImageValue = useMemo(
    () => (initialImage && initialImage.url ? { ...initialImage, id: 0, cdn: initialImage.url } : undefined),
    [initialImage],
  );
  const [title, setTitle] = useState(initialTitle || '');
  const [description, setDescription] = useState(initialDescription || '');
  const [image, setImage] = useState<PickerSelectedImage | undefined>(defaultImageValue);

  const defaultLanguage = useMemo(() => availableLanguages.find(({ isDefault }) => isDefault), [availableLanguages]);

  const initialLanguage = useMemo(
    () => (initialLanguageCountry ? getLanguageFromCountryCode(availableLanguages, initialLanguageCountry) : undefined),
    [initialLanguageCountry, availableLanguages],
  );

  const [language, setLanguage] = useState<SelectedLanguage | undefined>(initialLanguage || defaultLanguage);

  const isDisabled = useMemo(() => language === undefined || title.length <= 0, [language, title]);

  const handleLanguage = useCallback(
    (item: SSelectItem) => {
      setLanguage({
        ...item,
        isDefault: item.id === defaultLanguage?.id,
      });
    },
    [defaultLanguage],
  );

  const handleImage = useCallback((chosenImage: PickerSelectedImage) => {
    setImage(chosenImage);
  }, []);

  const handleConfirm = useCallback(async () => {
    onConfirm({
      title,
      description,
      // we know that language is defined because else the action button would be disabled
      languageCountry: language?.value,
      image,
    });
  }, [description, image, language, onConfirm, title]);

  return (
    <MModal
      labelActionButton={modalConfirmButtonLabel || t('courses:course_creation_modal.action_button')}
      labelSecondButton={t('courses:course_creation_modal.cancel')}
      disableActionButton={isDisabled}
      onAction={handleConfirm}
      onActionEnd={onClose}
      onSecondAction={onClose}
      onCloseModal={onClose}
      title={modalTitle || t('courses:course_creation_modal.title')}
      visible={visible}
      style={style}
      showExitCross
      type="dialog"
      labelError={error}
    >
      <div style={styles.leftWrapper}>
        <UMediaPicker dropArea size="L" image={image?.url} media={{ mediaType: 'image', onChange: handleImage }} />
      </div>
      <div style={styles.rightWrapper}>
        <div style={styles.title}>
          {t('courses:course_creation_modal.form_title')}
          <span style={styles.asterisk}>*</span>
        </div>
        <SInput
          autofocus
          type="small"
          placeholder={modalTitle || t('courses:course_creation_modal.title')}
          style={styles.inputStyle}
          value={title}
          onChange={setTitle}
          maxLength={maxTitleLength}
        />
        <div style={styles.title}>
          {t('courses:course_creation_modal.form_language')}
          <span style={styles.asterisk}>*</span>
        </div>
        <SSelect
          type="small"
          placeholder={t('courses:course_creation_modal.language_choice')}
          selected={language}
          items={availableLanguages}
          onSelect={handleLanguage}
        />
      </div>
      <div style={styles.bottomBlock}>
        <div style={{ ...styles.title, ...styles.descriptionLabel }}>
          {t('courses:course_creation_modal.form_description')}
        </div>
        <STextArea
          canResize={false}
          placeholder={t('courses:course_creation_modal.course_description')}
          height="80"
          style={styles.descriptionContent}
          onChange={setDescription}
          value={description}
          maxLength={maxDescriptionLength}
        />
      </div>
    </MModal>
  );
};

export default MCourseForm;
