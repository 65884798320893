import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    position: 'relative',
    marginLeft: 24,
    marginTop: 32,
    marginBottom: 24,
    width: '35%',
    overflowY: 'auto',
  },
});

export default styleSheet;
