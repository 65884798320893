import request from 'services/requestService';
import Enum from 'models/Enum';
import Model from 'models/Model';

var QCMChoice = {
  endpoint  : 'api/QCMChoices',
  attributes: {
    gameItem: {
      type : 'pointer',
      Model: 'GameItem',
    },
    qcm: {
      type : 'pointer',
      Model: 'QCM',
    },
    gameItemId: {
      type: 'number',
    },
    qcmId: {
      type: 'number',
    },
    rightAnswer: {
      type: 'boolean',
    },
  },
  methods: {},
};

export default Model.register('QCMChoice', QCMChoice);
