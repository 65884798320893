import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import type { State } from 'Libs/redux/types';

import { getIsQueueLoading, QueueContexts } from './redux';
import {
  start,
  stop,
  flush,
  push,
  QueueProcessHandler,
  InferPushQueueItem,
} from './redux/utils';

export const useQueue = <T>(context: QueueContexts, handler: QueueProcessHandler<T>) => {
  const processHandler = useRef(handler);

  if (handler !== processHandler.current) {
    throw new Error('useQueue: The handler must stay the same between renders. It must not depend on any implicit context');
  }

  const contextualPush = useCallback(
    (item: InferPushQueueItem<typeof handler>) => push<T>(context, processHandler.current, item),
    [context],
  );
  const contextualStart = useCallback(() => start<T>(context, processHandler.current), [context, processHandler]);
  const contextualStop = useCallback(() => stop(context), [context]);
  const contextualFlush = useCallback(() => flush(context), [context]);

  useEffect(() => {
    contextualStart();

    return () => {
      contextualStop();
    };
  }, [contextualStart, contextualStop]);

  const isQueueLoading = useSelector((state: State) => getIsQueueLoading(state, context));

  return {
    start: contextualStart,
    stop: contextualStop,
    flush: contextualFlush,
    push: contextualPush,
    isQueueLoading,
  };
};
