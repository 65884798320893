import _ from 'lodash';
import { t } from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';

var NoInteractionContent = {
  endpoint  : 'api/NoInteractionContents',
  attributes: {
    title: {
      type : 'pointer',
      Model: 'GameItem',
    },
    longText: {
      type : 'pointer',
      Model: 'GameItem',
    },
    imageTop: {
      type : 'pointer',
      Model: 'Image',
    },
    imageBottom: {
      type : 'pointer',
      Model: 'Image',
    },
    videoTop: {
      type : 'pointer',
      Model: 'Video',
    },
    videoBottom: {
      type : 'pointer',
      Model: 'Video',
    },
    mediaTypeTopId: {
      type: 'number',
    },
    mediaTypeBottomId: {
      type: 'number',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_NIC, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },
    mediaTop: function mediaTop(_NIC, media) {
      if (!media)
        return this.getMedia('Top');

      return this.setMedia('Top', media);
    },

    mediaBottom: function mediaBottom(_NIC, media) {
      if (!media)
        return this.getMedia('Bottom');

      return this.setMedia('Bottom', media);
    },

    getMedia: function getMedia(_NIC, panel) {
      var self = this;

      if (self['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return self['video' + panel]();

      if (self['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return self['image' + panel]();
    },

    setMedia: function setMedia(_NIC, panel, media) {
      var mediaType = media._type;
      var self = this;

      self['mediaType' + panel + 'Id'](Enum.mediaType[mediaType]);

      if (self['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return self['video' + panel](media);

      if (self['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return self['image' + panel](media);
    },

    validate: function validate() {
      var self = this;

      if (!self.title().data())
        throw new Error(t('gameplays:validation.title_missing'));

      if (!self.longText().data())
        throw new Error(t('gameplays:validation.long_text_missing'));

      if (!this.mediaTop() || !this.mediaTop().url())
        throw new Error(t('gameplays:validation.media_top_missing'));

      return true;
    },

    countUseOfGameItem: function countUseOfGameItem(giId) {
      var count = 0;

      if (this.title().id() === giId)
        count += 1;

      if (this.longText().id() === giId)
        count += 1;

      return count;
    },

    deleteGameItem: function deleteGameItem(_NIC, giId) {
      var GameItem = Model('GameItem');

      if (this.title().id() === giId)
        this.title(new GameItem());
      if (this.longText().id() === giId)
        this.longText(new GameItem());
    },
    presetFromKnowledge: function presetFromKnowledge(_NIC, knowledge) {
      var crops = knowledge.croppedImages();

      if ((!this.mediaTypeTopId() || this.mediaTypeTopId() === Enum.mediaType.Image) && !this.imageTop().id()) {
        this.mediaTypeTopId(Enum.mediaType.Image);
        this.imageTop(crops[0] || this.imageTop());
      }
      if ((!this.mediaTypeBottomId() || this.mediaTypeBottomId() === Enum.mediaType.Image) && !this.imageBottom().id())
        this.mediaTypeBottomId(0);

    },
    getDirective: function getDirective(_NIC, knowledge) {
      if (knowledge.referenceText() && !this.title().data() && !this.longText().data())
        return t('gameplays:directives.enter_new_test');
      if (!this.imageTop().url() && !this.videoTop().url())
        return t('gameplays:directives.add_image_video');
      if (!this.title().data())
        return t('gameplays:directives.enter_title_content');
      if (!this.longText().data())
        return t('gameplays:directives.enter_text_content');

      return '';
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_NIC, knowledgeId) {
      return {
        title: {
          typeId     : Enum.gameItemTypes.TITLE,
          knowledgeId: knowledgeId,
        },
        longText: {
          typeId     : Enum.gameItemTypes.LONG_TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
};

export default Model.register('NoInteractionContent', NoInteractionContent);
