import { css } from 'aphrodite';
import { Promise } from 'bluebird';

import type { CSSProperties, FunctionComponent } from 'react';
import React, { ReactElement, useEffect, useMemo } from 'react';

import UButton from 'Components/unit/UButton/UButton';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { COLORS, LEVELS } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import { IllustrationColors, IllustrationName } from 'Components/foundation/illustrations';
import { UCircle } from 'Components/unit/UCircle/UCircle';
import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';

import UIcon from 'Components/unit/UIcon/UIcon';
import styles, {
  actionButton,
  backgroundColorStyle,
  createCloseAnimation,
  descriptionColor,
  getContentContainerStyle,
  iconButtonType,
  textLink,
  titleColorStyle,
} from './SCallout.style';

export type SCalloutColor = {
  border: string;
  background: string;
};
export type SCalloutType = 'accentuated' | 'standard' | 'warning' | 'standard-dark';
export type SCalloutProps = {
  style?: CSSProperties;
  title?: string;
  titleIcon?: IconName;
  description: string;
  type: SCalloutType;
  animationDuration?: number;
  visible?: boolean;
  onCloseAnimationFinished?: () => void;
  onClose?: () => void;
  banner?: { illustration: IllustrationName; illustrationColor?: IllustrationColors };
  action?: {
    label: string;
    onAction: () => void;
    buttonType: 'button' | 'link';
    leftIcon?: IconName;
    rightIcon?: IconName;
  };
};

const emptyFunction = () => {};

type WrapperProps = { children: ReactElement; banner: boolean; type: SCalloutType };

const Wrapper: FunctionComponent<WrapperProps> = ({ children, banner, type }) =>
  banner ? <div style={{ ...backgroundColorStyle[type], ...styles.bannerWrapper }}>{children}</div> : children;

export const SCallout: FunctionComponent<SCalloutProps> = ({
  title,
  titleIcon,
  description,
  type,
  style = {},
  visible = true,
  animationDuration = 200,
  action,
  onClose,
  onCloseAnimationFinished = emptyFunction,
  banner,
}) => {
  const closeAnimation = useMemo(() => createCloseAnimation(`${animationDuration}ms`), [animationDuration]);

  const handleClose = useMemo(() => onClose || emptyFunction, [onClose]);

  useEffect(() => {
    const handleCloseAfterAnim = async () => {
      await Promise.delay(animationDuration);

      onCloseAnimationFinished();
    };

    if (!visible) {
      handleCloseAfterAnim();
    }
  }, [visible, onCloseAnimationFinished, animationDuration]);

  const contentContainerStyle = useMemo(() => getContentContainerStyle({ title }), [title]);

  const className = !visible ? css(closeAnimation) : undefined;

  return (
    <Wrapper banner={!!banner} type={type}>
      <div
        className={className}
        style={{
          ...(banner ? styles.containerWrapper : { ...backgroundColorStyle[type], ...styles.container }),
          ...style,
        }}
      >
        <div data-test-id="content-container" style={{ ...styles.contentContainer, ...contentContainerStyle }}>
          {banner && banner?.illustration && (
            <UCircle style={styles.illustration}>
              <UIllustration
                illustration={banner.illustration}
                height={34}
                width={34}
                color={COLORS[banner.illustrationColor || 'PRIMARY']}
                illustrationWrapperStyle={styles.illustrationWrapper}
              />
            </UCircle>
          )}
          <div style={styles.textContainer}>
            {title && (
              <div
                style={{
                  ...styles.titleContainer,
                  ...(banner ? styles.titleBanner : styles.title),
                  ...titleColorStyle[type],
                }}
              >
                {titleIcon && <UIcon name={titleIcon} size={16} color={titleColorStyle[type]?.color} />}
                <span>{title}</span>
              </div>
            )}
            <UMarkdown
              data-test-id="description"
              markdown={description}
              style={{ ...styles.description, ...descriptionColor[type] }}
            />
          </div>
        </div>
        <div style={title ? styles.buttonWrapper : styles.buttonWrapperWithoutTitle}>
          {action && (
            <div
              data-test-id="button-container"
              style={{
                ...styles.normalButton,
                ...(onClose ? {} : styles.normalButtonWithoutClose),
                ...(title ? {} : styles.centeredActionButtonContainer),
              }}
            >
              {action.buttonType === 'link' ? (
                <UTextLink
                  type={textLink[type]}
                  rightIcon="arrow-right"
                  onClick={action.onAction}
                  text={action.label}
                  leftIcon={action.leftIcon}
                />
              ) : (
                <UButton
                  type={actionButton[type].type}
                  ghost={actionButton[type].ghost}
                  onClick={action.onAction}
                  text={action.label}
                  rightIcon={action.rightIcon}
                  leftIcon={action.leftIcon}
                  textAndIconStyle={{ zIndex: LEVELS.LOCAL_OFFSET }}
                />
              )}
            </div>
          )}
          {!!onClose && (
            <div style={{ ...styles.closeButtonContainer, ...(title ? {} : styles.centeredCloseButtonContainer) }}>
              <UIconButton ghost type={iconButtonType[type]} size="S" icon="close" onClick={handleClose} />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default SCallout;
