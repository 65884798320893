// @flow

/**
 * Display icon with title and description
 *
 * Props:
 *  - title: Title
 *  - description: Description
 *  - isActive: Set the color of the icon
 *  - style: For overriding the component's style
 *
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './SValidityCheckListItem.style';


type Props = {|
  title: string,
  description: string,
  style: Object,
  isActive: boolean,
|};

export class SValidityCheckListItem extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
    description: '',
    isActive: false,
  };

  render() {
    const { style, isActive, title } = this.props;
    const iconColor = isActive ? COLORS.SUCCESS.DEFAULT : COLORS.SUCCESS.DISABLED;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <UIcon
          name="success-circle"
          size={16}
          color={iconColor}
          style={styles.icon}
        />
        <div style={styles.wrapperText}>
          <span style={styles.title}>
            {title}
          </span>
          {this.renderDescription()}
        </div>
      </div>
    );
  }

  renderDescription = () => {
    const { description } = this.props;

    if (!description)
      return null;

    return (
      <span style={styles.description}>
        {description}
      </span>
    );
  };
}

export default SValidityCheckListItem;
