import { t } from 'i18next';

import { MetaType } from 'Store/entities/audience-group/audience-group.types';
import { AudienceScopeProps } from './AudienceGroupListBody';

export const getMetaForTooltip = (meta: MetaType, scope: AudienceScopeProps) => {
  switch (scope) {
    case 'course':
      return t('audiences:audience_group_card.tooltip.targeted_out_of_total', {
        count: meta.currentAudience,
        maxAudience: meta.maxAudience,
      });
    case 'configuration':
      return t('audiences:audience_group_card.tooltip.targeted_players', {
        count: meta.currentAudience,
      });
  }
};
