import { useCallback, useState } from 'react';

import { getSync, postSync } from 'Services/localStorageService';

export const usePersistedState = <T>(key: string, defaultValue: T): [T, (val: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = getSync<T>(key);

      return item || defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const setValue = useCallback((value: T) => {
    try {
      setStoredValue(value);
      postSync(key, value);
    } catch (error) {
      console.error(error);
    }
  }, [key]);

  return [storedValue, setValue];
};
