import Enum from 'Models/Enum';
import { mergeDeep } from 'Libs/mergeDeep';
import { getDailySerieNumExtraDays } from '../../date.utils';

import createDailySerieDay from './DailySerieDay';
import type { DailySerieDayType } from './DailySerieDay';

import createDisabledDay from './DisabledDay';
import type { DisabledDayType } from './DisabledDay';

import createWeekDay from './WeekDay';
import type { WeekDayType } from './WeekDay';

export type DailySerieType = Readonly<{
  id: number;
  typeId: number;
  activityId: number;
  weekDays: ReadonlyArray<WeekDayType>;
  dailySerieDayDisabled: ReadonlyArray<DisabledDayType>;
  days: ReadonlyArray<DailySerieDayType>;
  numExtraDays: number;
  specificRuleActive: boolean;
  specificRuleText: string;
  random: boolean;
}>;

export default function createDailySerie(values?: any, startDate?: string, endDate?: string): DailySerieType {
  const defaultState: DailySerieType = {
    id: 0,
    typeId: Enum.dailySerieTypeEnum.SEQUENTIAL,
    activityId: 0,
    weekDays: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    dailySerieDayDisabled: [],
    days: [createDailySerieDay()],
    numExtraDays: 0,
    specificRuleActive: false,
    specificRuleText: '',
    random: false,
  };

  if (!values || !startDate || !endDate) {
    return defaultState;
  }

  // INFO Check length for safety, although array should not be empty
  const days = values.days.length
    ? values.days.map((day: Partial<DailySerieDayType>, i: number) => ({ ...createDailySerieDay(day), key: i }))
    : defaultState.days;

  // INFO Check length for safety, although array should not be empty
  const weekDays = values.weekDays.length
    ? values.weekDays.map((weekDay: Partial<WeekDayType>) => createWeekDay(weekDay))
    : defaultState.weekDays;

  const specificRuleText = values.specificRuleText || '';

  const dailySerieDayDisabled = values.dailySerieDayDisabled.map((day: Partial<DisabledDayType>, i: number) => ({
    ...createDisabledDay(day),
    key: i,
  }));
  const numExtraDays = getDailySerieNumExtraDays(values as any, startDate, endDate);

  return mergeDeep(defaultState, { ...values, days, weekDays, specificRuleText, dailySerieDayDisabled, numExtraDays });
}

export const calculateContentRepartition = (dailySerie: DailySerieType, numberOfDays: number) => {
  const contents = dailySerie.days.flatMap(({ dailySerieContents }) => dailySerieContents);
  const contentsPerDays = Math.floor(contents.length / numberOfDays);

  // INFO If there is a remainder, we add one content to each day until we reach the number of days
  const additionnalContents = contents.length % numberOfDays;

  let lastIndex = 0;

  const newDays = Array(numberOfDays)
    .fill(0)
    .map((_, i) => {
      const contentMinIndex = lastIndex;
      const contentMaxIndex = i < additionnalContents ? lastIndex + contentsPerDays + 1 : lastIndex + contentsPerDays;
      lastIndex = contentMaxIndex;

      return {
        key: i,
        dailySerieContents: contents.slice(contentMinIndex, contentMaxIndex),
      };
    });

  return {
    ...dailySerie,
    days: newDays,
  };
};
