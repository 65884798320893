// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  title: {
    ...TYPOGRAPHY.H2,
    marginBottom: 4,
  },
  button: {
    marginTop: 20,
  },
  notice: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
};
