import React from 'react';
import type { FunctionComponent, HTMLAttributes } from 'react';

import styles from './LeftBorderContainer.style';

export const LeftBorderContainer: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  style,
  ...rest
}) => {
  const containerStyles = { ...styles.container, ...style };

  return (
    <div style={containerStyles} {...rest}>
      <div style={styles.innerContainer}>{children}</div>
    </div>
  );
};
