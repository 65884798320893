import React from 'react';

import { SFooter } from 'Components/structural/SFooter/SFooter';

import { Panel } from './components/Panel/Panel';
import { SchedulingContent } from './components/SchedulingContent/SchedulingContent';
import { RandomizedContent } from './components/RandomizedContent/RandomizedContent';

import { useModuleSelector } from '../redux';

import styles from './Scheduling.style';

export const Scheduling = () => {
  const random = useModuleSelector((state) => state.activities[0].dailySerie.random);

  return (
    <div style={styles.wrapper}>
      <Panel />
      <div style={styles.content}>
        {random ? <RandomizedContent /> : <SchedulingContent />}
        <SFooter />
      </div>
    </div>
  );
};
