import localStorageService from './localStorageService';

type SessionToken = Required<{ id: string; }>;

// TODO type with real model when models are migrated
export const createSessionAfterApiAction = (user: Record<string, unknown>, token: SessionToken) => {
  localStorageService.token(token.id);

  return localStorageService.postSync('user', user);
};
