// @flow

import { TYPOGRAPHY, COLORS, CORNERS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    width: 277,
    height: 170,
    marginLeft: '10%',
    marginRight: '10%',
    backgroundColor: COLORS.WHITE.DEFAULT,
    ...CORNERS.BORDER_RADIUS(CORNERS.CORNER2),
  },
  close: {
    position: 'absolute',
    right: 17,
    top: 17,
    cursor: 'pointer',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    height: 70,
    userSelect: 'none',
    paddingLeft: 10,
    paddingRight: 10,
  },
  loader: {
    position: 'absolute',
    bottom: 50,
  },
  title: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  processingText: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 7,
  },
  notice: {
    position: 'absolute',
    bottom: 7,
    ...TYPOGRAPHY.CAPTION,
    fontWeight: 'bold',
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  errorTitle: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
  },
  errorDescription: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.ALERT.ERROR,
    marginTop: 9,
  },
};
