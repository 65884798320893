import React, { useCallback, useMemo, useState } from 'react';
import type { CSSProperties } from 'react';
import { css } from 'aphrodite';
import { t } from 'i18next';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UFlag from 'Components/unit/UFlag/UFlag';
import UIcon from 'Components/unit/UIcon/UIcon';
import UStatusIndicator, { UStatusType } from 'Components/unit/UStatusIndicator/UStatusIndicator';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';
import UDropdownLanguageItem from 'Components/unit/UDropdownLanguageItem/UDropdownLanguageItem';
import { COLORS } from 'Components/foundation';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import { styles, extendedStyles } from './SLanguageSelector.style';

const UIconButtonWithTooltip = withTooltip(UIconButton);

export type LanguageOptions = {
  id: number;
  text: string;
  countryCode: string;
  status: {
    type: UStatusType;
    text: string;
  };
  onClick?: (id: number) => void;
}[];

export type SLanguageSelectorProps = {
  style?: CSSProperties;
  selectedLanguage: LanguageOptions[number];
  withFooter?: boolean;
  onAction?: () => void;
  languageOptions: LanguageOptions;
  tooltip?: boolean;
};

const noop = () => {};

export const DropdownFooter = ({
  setIsDropdownOpened,
  onAction,
}: {
  setIsDropdownOpened: (value: boolean) => void;
  onAction?: () => void;
}) => (
  <div
    onClick={() => {
      setIsDropdownOpened(false);
      onAction?.();
    }}
    style={styles.footerWrapper}
    className={css(extendedStyles.footer)}
  >
    <UIcon color={COLORS.TEXT.SECONDARY_DEFAULT} name="plus-circled" size={16} />
    <div style={styles.footerText}>{t('structural_component:s_language_selector.request_another')}</div>
  </div>
);

export const SLanguageSelector = ({
  style = {},
  withFooter = true,
  tooltip = false,
  onAction,
  languageOptions,
  selectedLanguage,
}: SLanguageSelectorProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const hoverStyle = useMemo(
    () => (isHover ? { backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT } : undefined),
    [isHover],
  );
  const iconOption = useMemo(() => (languageOptions.length > 1 ? 'bottom-chevron' : 'plus'), [languageOptions]);

  const handleLeave = useCallback(() => setIsHover(false), []);
  const handleHover = useCallback(() => setIsHover(true), []);

  const renderOptionItem = ({ id, text, countryCode, status, onClick }: LanguageOptions[number]) => (
    <UDropdownLanguageItem
      key={id}
      status={status.type}
      statusText={status.text}
      text={text}
      onClick={() => {
        setIsDropdownOpened(false);
        onClick?.(id);
      }}
      countryCode={countryCode}
    />
  );

  const outsideClickHandler = useCallback(
    (isOutsideClicked: boolean) => (isOutsideClicked && isDropdownOpened ? setIsDropdownOpened(false) : null),
    [isDropdownOpened],
  );

  const iconDecision = useCallback(
    () => (iconOption === 'plus' ? onAction?.() : setIsDropdownOpened((previous) => !previous)),
    [iconOption],
  );

  const filteredLanguages = useMemo(
    () => languageOptions.filter((option) => option.id !== selectedLanguage.id),
    [languageOptions, selectedLanguage],
  );

  return (
    <>
      <div style={style}>
        {tooltip && (
          <div style={styles.tooltipPosition} data-tip data-for="tooltip-language">
            <div>
              <UIconButtonWithTooltip
                style={styles.tooltip}
                round
                type="destructive"
                onClick={noop}
                icon="warning-mini"
                size="S"
                tooltipContent={t('structural_component:s_language_selector.invalid_languages')}
                tooltipMaxWidth={315}
                tooltipPosition="top"
                tooltipEnabled
              />
            </div>
          </div>
        )}
        <div
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          style={{
            ...styles.wrapper,
            ...hoverStyle,
          }}
        >
          <div style={{ ...styles.row, ...styles.spaceBetween }}>
            <div style={styles.column}>
              <div style={{ ...styles.row, ...styles.flagWrapper }}>
                <UFlag countryCode={selectedLanguage.countryCode} size={16} style={styles.flag} />
                <div style={styles.language}>{selectedLanguage.text}</div>
              </div>
              <div style={styles.row}>
                <UStatusIndicator
                  size="medium"
                  type={selectedLanguage.status.type}
                  text={selectedLanguage.status.text}
                />
              </div>
            </div>
            <div style={{ ...styles.column, ...styles.iconButton }}>
              <UIconButtonWithTooltip
                size="S"
                icon={iconOption}
                ghost={languageOptions.length > 1}
                round
                onClick={iconDecision}
                tooltipContent={t('structural_component:s_language_selector.ask_for_translation')}
                tooltipMaxWidth={315}
                tooltipPosition="top"
                tooltipEnabled={languageOptions.length <= 1}
              />
            </div>
          </div>
        </div>
        {isDropdownOpened && (
          <OutsideClickWrapper style={styles.outsideClickWrapper} onClick={outsideClickHandler} stopClickPropagation>
            <UDropdownBox items={filteredLanguages} renderItem={renderOptionItem} style={styles.dropdownBox} />
            {withFooter && <DropdownFooter setIsDropdownOpened={setIsDropdownOpened} onAction={onAction} />}
          </OutsideClickWrapper>
        )}
      </div>
    </>
  );
};

export default SLanguageSelector;
