import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';

import { useTypedDispatch, useTypedSelector } from 'Libs/redux/utils';

import STable, { TableAction } from 'Components/structural/STable/STable';
import { Header, HeaderType, TABLE_ACTION_TYPES, TableMessage } from 'Components/structural/STable/types';
import Enum from 'Models/Enum';

import { actions, hasChanged as hasChangedSelector, useModuleSelector } from '../../../redux';

type FilterType = {
  search: string;
  order: Array<string>;
};
const RANGE = 20;

type Player = {
  data: string;
};

async function noop() {}

export const AudienceTable = () => {
  const noticeByMode: { [key: number]: string } = {
    [Enum.audienceTargetMode.MAGIC_CODE]: t('activities:audience.players.magic_code_notice'),
    [Enum.audienceTargetMode.EMAIL_LIST]: t('activities:audience.players.email_list_notice'),
    [Enum.audienceTargetMode.SEGMENTATION]: t('activities:audience.players.segmentation_notice'),
  } as const;

  const dispatch = useTypedDispatch();
  const hasChanged = useTypedSelector((state) => hasChangedSelector(state));
  const multilingualId = useModuleSelector((state) => state.activities[0].multilingualId);

  const audience = useModuleSelector((state) => state.audience);
  const originalAudience = useModuleSelector((state) => state.originalAudience);
  const targetModeId = audience.mode;
  const originalTargetModeId = originalAudience.mode;

  const audienceTarget = useModuleSelector((state) => {
    const audienceTargetKey = Enum.audienceTargetLabels[targetModeId as keyof typeof Enum.audienceTargetLabels];
    if (audienceTargetKey in state.audienceTarget) {
      return state.audienceTarget[audienceTargetKey];
    }
  });

  const {
    players = [],
    total = 0,
    numTargetedPlayers = 0,
    registered = 0,
    loading: isLoading = false,
    needsRefresh = false,
    deleteError = false,
  } = audienceTarget || {};
  const [filter, setFilter] = useState<FilterType>({ search: '', order: [] });
  const [order, setOrder] = useState<Record<string, string>>({});
  const displayMessage = deleteError;
  const title = t('activities:audience.players.title', { count: total });
  const notice = noticeByMode[targetModeId as keyof typeof noticeByMode];

  const tableMessage = useMemo(() => {
    let message: TableMessage | undefined = !displayMessage
      ? undefined
      : {
          type: 'error',
          message: t('activities:audience.players.delete_error'),
        };

    if (audience.mode === Enum.audienceTargetMode.SEGMENTATION) {
      const segmentation = audience.SEGMENTATION.segmentation;
      const originalSegmentation = originalAudience.SEGMENTATION.segmentation;

      if (
        JSON.stringify(segmentation) !== JSON.stringify(originalSegmentation) ||
        audience.mode !== originalAudience.mode
      ) {
        message = {
          type: 'warning',
          message: t('activities:audience.players.change_warning'),
        };
      }
    }

    return message;
  }, [audience, originalAudience, displayMessage]);

  // eslint-disable-next-line complexity
  const titleExplanation = useMemo(() => {
    let text = '';

    if (targetModeId === Enum.audienceTargetMode.EMAIL_LIST && numTargetedPlayers > 0 && registered > 0) {
      let percentage = (registered / numTargetedPlayers) * 100;

      if (percentage > 99 && percentage < 100) percentage = 99;

      text = t('activities:audience.players.registered_player_percent', { percentage });
    }

    return text;
  }, [numTargetedPlayers, registered, targetModeId]);

  useEffect(() => {
    if (!hasChanged || needsRefresh) {
      const action = actions.getAudienceTarget(multilingualId, 0, RANGE, {});

      dispatch(action);

      return action.cancel;
    }

    return undefined;
  }, [dispatch, multilingualId, hasChanged, needsRefresh]);

  const handleShowMore = useCallback(
    (newFrom: number, newTo: number) => {
      if (originalTargetModeId === targetModeId)
        dispatch(actions.getAudienceTarget(multilingualId, newFrom, newTo, filter));
    },
    [dispatch, originalTargetModeId, targetModeId, filter, multilingualId],
  );

  const tableActions = useMemo((): TableAction[] => {
    const handleExport = () => {
      if (originalTargetModeId !== targetModeId) return null;

      return dispatch(actions.exportTargetAudience(multilingualId));
    };

    const handleSearch = (search?: string) => {
      if (originalTargetModeId !== targetModeId) return null;
      if (search === filter.search || !search) return null;

      const newFilter = { ...filter, search };

      setFilter(newFilter);

      return dispatch(actions.getAudienceTarget(multilingualId, 0, RANGE, newFilter));
    };

    return [
      {
        type: TABLE_ACTION_TYPES.SEARCH,
        id: 'table-action-1',
        params: {
          onClick: noop,
          onRequestEnd: noop,
          placeholder: t('activities:audience.players.search_placeholder'),
          placeholderOnExpand: t('activities:audience.players.search_placeholder_on_expand'),
          onAction: handleSearch,
        },
      },
      {
        type: TABLE_ACTION_TYPES.EXPORT,
        id: 'table-action-2',
        params: {
          onClick: noop,
          onRequestEnd: noop,
          text: t('activities:audience.players.export'),
          onAction: handleExport,
        },
      },
    ];
  }, [dispatch, targetModeId, originalTargetModeId, filter, multilingualId]);

  const columns = useMemo<Header[]>(() => {
    const handleSort = (column: string, columnOrder: string) => {
      if (originalTargetModeId !== targetModeId) return null;

      const currentFilter = filter;
      const currentOrder = order;

      if (columnOrder === 'none') delete currentOrder[column];
      else currentOrder[column] = columnOrder;

      setOrder(currentOrder);

      currentFilter.order = [];

      Object.keys(currentOrder).forEach(function add(key) {
        currentFilter.order.push(key + ' ' + currentOrder[key]);
      });

      return dispatch(actions.getAudienceTarget(multilingualId, 0, RANGE, currentFilter));
    };

    return [
      {
        id: '0',
        type: HeaderType.EmptyHeader,
        width: '44px',
        disableSorting: true,
        accessor: 'profilePicture',
      },
      {
        id: '1',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('activities:audience.players.username'),
          align: 'left',
          onAction: handleSort,
        },
        width: '180px',
        accessor: 'lastName',
      },
      {
        id: '2',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('activities:audience.players.creation'),
          align: 'center',
          onAction: handleSort,
        },
        width: '90px',
        accessor: 'createdAt',
      },
      {
        id: '3',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('activities:audience.players.segments'),
          align: 'left',
          onAction: handleSort,
        },
        disableSorting: true,
        accessor: 'segmentation',
      },
    ];
  }, [dispatch, targetModeId, originalTargetModeId, order, filter, multilingualId]);

  const callbackOptions = useMemo(() => {
    if (targetModeId === Enum.audienceTargetMode.SEGMENTATION) return [];

    const handleRemovePlayerFromAudience = (player: Player) => {
      dispatch(actions.removePlayerFromAudience(multilingualId, [player.data]));
    };

    return [
      {
        icon: 'delete',
        id: 'action_delete_id',
        callback: handleRemovePlayerFromAudience,
      },
    ];
  }, [dispatch, targetModeId, multilingualId]);

  const data = players.map((player) => ({ ...player, callbackOptions }));

  return (
    <STable
      title={title}
      titleExplanation={titleExplanation}
      columns={columns}
      data={data}
      range={RANGE}
      emptyRowText={t('activities:audience.players.empty_row')}
      showMoreText={t('activities:audience.players.show_more')}
      loadingText={t('activities:audience.players.loading')}
      total={total}
      tableActions={tableActions}
      tableMessage={tableMessage}
      isTableLoading={isLoading}
      notice={notice}
      onShowMoreClicked={handleShowMore}
    />
  );
};
