import React, { useCallback, useMemo } from 'react';

import UIndicator from 'Components/unit/UIndicator/UIndicator';
import Enum from 'Models/Enum';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import { COLORS } from 'Components/foundation';
import UStatusIndicator from 'Components/unit/UStatusIndicator/UStatusIndicator';
import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';
import { ACTIVITY_STATUS_TYPE_MAP, getActivityTypeMap } from '../SActivityListItem.constants';
import { ActivityStatusType, ActivityType, OptionType } from '../SActivityListItem.types';

import styles from '../SActivityListItem.style';

type HeaderProps = {
  hasPlayerPlayed: boolean;
  activityType: ActivityType;
  id: number;
  activityStatus: ActivityStatusType;
  activityStatusLabel: string;
  title: string;
  titlePlaceholder: string;
  onAnalyticsClick: (activityIds: ReadonlyArray<number>) => void;
  activityIds: ReadonlyArray<number>;
  options: Array<OptionType>;
  isOptionOpen: boolean;
  setIsOptionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const Header = ({
  hasPlayerPlayed,
  activityType,
  id,
  activityStatus,
  activityStatusLabel,
  title,
  titlePlaceholder,
  onAnalyticsClick,
  activityIds,
  options,
  isOptionOpen,
  setIsOptionOpen,
  wrapperRef,
}: HeaderProps) => {
  const activityTypeMap = useMemo(() => getActivityTypeMap(), []);
  const backgroundColorActivity = COLORS.ACTIVITY[activityTypeMap[activityType].backgroundColor];
  const colorLabel = COLORS.ACTIVITY[activityTypeMap[activityType].colorLabel];
  const disabledAnalytics = !hasPlayerPlayed;

  const handleAnalyticsClick = useCallback(() => {
    onAnalyticsClick(activityIds);
  }, [onAnalyticsClick, activityIds]);

  const handleOptionClick = useCallback(() => {
    setIsOptionOpen((previous) => !previous);
  }, [setIsOptionOpen]);

  const handleClickOption = useCallback(
    (callback: (argId: number) => void) => {
      callback(id);
      setIsOptionOpen(false);
    },
    [setIsOptionOpen, id],
  );

  const handleOutsideClick = useCallback(
    (isOutsideClicked: boolean) => {
      if (isOutsideClicked && isOptionOpen) {
        setIsOptionOpen(false);
      }
    },
    [isOptionOpen, setIsOptionOpen],
  );

  const UIndicatorStyle = useMemo(
    () => ({
      backgroundColor: backgroundColorActivity,
      color: colorLabel,
    }),
    [backgroundColorActivity, colorLabel],
  );

  const handleRenderItem = useCallback(
    ({ title: optionTitle, color, callback }: OptionType) => (
      <UDropdownItem
        key={optionTitle}
        text={optionTitle}
        color={color}
        onClick={() => {
          handleClickOption(callback);
        }}
        stopClickPropagation
      />
    ),
    [handleClickOption],
  );

  return (
    <div style={styles.containerHeader}>
      <div style={styles.containerHeaderLeft}>
        <div style={styles.containerType}>
          <UIndicator text={activityTypeMap[activityType].label} style={UIndicatorStyle} />
          <div style={styles.id}>{`#${id}`}</div>
        </div>

        {title ? (
          <div style={styles.title}>{title}</div>
        ) : (
          <div style={styles.titlePlaceholder}>{titlePlaceholder}</div>
        )}

        <UStatusIndicator type={ACTIVITY_STATUS_TYPE_MAP[activityStatus]} text={activityStatusLabel} size="medium" />
      </div>
      <div style={styles.containerHeaderRight}>
        {activityType === Enum.activityTypeEnum.DailySerie ? (
          <UIconButton
            icon="analytics"
            disabled={disabledAnalytics}
            onClick={handleAnalyticsClick}
            stopClickPropagation
            ghost
            type="accentuated"
            style={styles.analyticsIcon}
            data-test-id="analytics-button"
          />
        ) : null}
        <div ref={wrapperRef}>
          <UIconButton
            icon="options"
            data-test-id="options-menu"
            style={styles.iconOptions}
            onClick={handleOptionClick}
            stopClickPropagation
            ghost
          />
          {isOptionOpen && (
            <OutsideClickWrapper onClick={handleOutsideClick} data-test-id="outside-click-wrapper">
              <UDropdownBox
                data-test-id="options-dropdown"
                items={options}
                renderItem={handleRenderItem}
                style={styles.dropdownBox}
              />
            </OutsideClickWrapper>
          )}
        </div>
      </div>
    </div>
  );
};
