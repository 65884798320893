/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Display an icon and text that can be toggled
 *
 * Props:
 * - iconOn: the name of the icon when "on"
 * - textOn: the text of the icon when "on"
 * - iconOff: the name of the icon when "off"
 * - textOff: the text of the icon when "off"
 * - toggled: the state of the toggle
 * - onToggle: function called onClick
 * - size: the size of the icon
 * - style: can override the component's style
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UIconToggle.style';

type Props = {|
  iconOn: string,
  textOn: string,
  iconOff: string,
  textOff: string,
  toggled: boolean,
  onToggle: (toggled: boolean) => void,
  size: number,
  style: Object,
|};

type State = {|
  isHover: boolean,
|};

export class UIconToggle extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    size: 20,
  };

  state = {
    isHover: false,
  };


  render() {
    const { iconOn, textOn, iconOff, textOff, size, toggled, style } = this.props;
    const { isHover } = this.state;
    const color = isHover ? COLORS.TEXT.SECONDARY_HOVER : COLORS.TEXT.SECONDARY_DEFAULT;
    const icon = toggled ? iconOn : iconOff;
    const styleTextOn = toggled ? styles.textHidden : styles.text;
    const styleTextOff = toggled ? styles.text : styles.textHidden;

    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
        style={{ ...styles.wrapper, ...style }}
      >
        <UIcon
          name={icon}
          size={size}
          color={color}
          style={styles.icon}
        />
        <div style={{ ...styles.text, ...styleTextOn, color }}>
          {textOn}
        </div>
        <div style={{ ...styles.text, ...styleTextOff, color }}>
          {textOff}
        </div>
      </div>
    );
  }

  handleMouseEnter = () => {
    this.setState({ isHover: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHover: false });
  };

  handleClick = () => {
    const { onToggle, toggled } = this.props;

    if (onToggle)
      onToggle(!toggled);
  };
}

export default UIconToggle;
