// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 4;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

const USER_SELECT_NONE = {
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
};

const BOX_SHADOW_COLOR = 'rgba(0,0,0,0.1)';

export default {
  wrapper: {
    ...BORDER_RADIUS,
    height: 281,
    width: 175,
    backgroundColor: COLORS.BLACK.DEFAULT,
    ...USER_SELECT_NONE,
    cursor: 'pointer',
    paddingLeft: 16,
    paddingRight: 16,
    overflowY: 'hidden',
  },
  instruction: {
    marginTop: 24,
    textAlign: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.WHITE.DEFAULT,
    minHeight: 30,
    wordBreak: 'break-word',
  },
  cardsContainer: {
    marginTop: 16,
  },
  card: {
    boxShadow: `0px 2px 4px 0px ${BOX_SHADOW_COLOR}`,
    ...BORDER_RADIUS,
    backgroundColor: COLORS.WHITE.DEFAULT,
    paddingLeft: 8,
    paddingTop: 18,
    paddingRight: 8,
    paddingBottom: 18,
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    textAlign: 'center',
    minHeight: 50,
    wordBreak: 'break-all',
  },
  cardPlaceholder: {
    height: 50,
    backgroundColor: COLORS.WHITE.DEFAULT,
    opacity: 0.5,
    ...BORDER_RADIUS,
  },
};
