import { t } from 'i18next';

import Enum from 'Models/Enum';
import App from 'Models/App';
import type { KnowledgeContent } from 'Models/react/KnowledgeContent/KnowledgeContent';
import User from 'Services/userService';
import type { KnowledgeGridState } from './reducer';

type ArchivedObj = {
  archived: boolean | null;
};
type ThematicObj = {
  thematicId: number;
};
type OrFilter = {
  or: Array<ArchivedObj>;
};
type ArchivedFilter = { archived: true };
type AndFilter = OrFilter | ThematicObj | ArchivedFilter;

export type KnowledgeQueryFilter = {
  where: {
    and: Array<AndFilter>;
  };
  text?: ReadonlyArray<string>;
  status?: number;
  segmentation?: ReadonlyArray<{ id: number }>;
  activity?: ReadonlyArray<{ id: number }>;
  contributor?: { ids: ReadonlyArray<number> };
  translation?: { statusId: number };
};

const ARCHIVED_STATUS = Enum.activityStatus.ARCHIVED;

// eslint-disable-next-line complexity
export const buildQueryFilterFromState = (state: KnowledgeGridState): KnowledgeQueryFilter => {
  const {
    selectedSegmentationIds,
    selectedContributorsIds,
    selectedTextValues,
    selectedActivityIds,
    selectedTranslationStatusIds,
    selectedContentStatusIds,
    selectedThematicIds,
  } = state.filter;

  const orFilter = { or: [{ archived: null }, { archived: false }] };
  const archivedFilter: ArchivedFilter = { archived: true };
  const andFilter = selectedThematicIds.length ? [orFilter, { thematicId: selectedThematicIds[0] }] : [orFilter];
  const queryFilter: KnowledgeQueryFilter = {
    where: {
      and: andFilter,
    },
    text: selectedTextValues,
  };

  if (selectedContentStatusIds.length) {
    const [status] = selectedContentStatusIds;
    queryFilter.status = status;

    // Remove filter for archived false/null if searching archived knowledges
    if (selectedContentStatusIds[0] === ARCHIVED_STATUS) {
      queryFilter.where.and = queryFilter.where.and.filter((f) => f !== orFilter).concat(archivedFilter);
    }
  }
  if (selectedSegmentationIds.length) {
    queryFilter.segmentation = selectedSegmentationIds.map((id) => ({ id }));
  }
  if (selectedActivityIds.length) {
    queryFilter.activity = selectedActivityIds.map((id) => ({ id }));
  }
  if (selectedContributorsIds.length) {
    queryFilter.contributor = { ids: selectedContributorsIds };
  }
  if (selectedTranslationStatusIds.length) {
    queryFilter.translation = { statusId: selectedTranslationStatusIds[0] };
  }

  return queryFilter;
};

export const hasUserWriteRole = (): boolean =>
  User.meSync().hasOneRole([Enum.Role.ROOT, Enum.Role.MASTER, Enum.Role.ADMIN, Enum.Role.WRITER]);

export const getLanguageSegmentation = (segIds: ReadonlyArray<number>): { id: number } => {
  const { userSegmentationItems } = App as any;
  const filteredSegmentation = userSegmentationItems().toJSON();
  const groups = filteredSegmentation.map((segItem: any) => segItem.group);

  return groups
    .filter((seg: any) => segIds.indexOf(seg?.id) !== -1)
    .find((seg: any) => seg.dimension.id === Enum.dimensionTypes.LANGUAGE);
};

export const getIndicatorText = (pendingTranslation: boolean, contents: ReadonlyArray<KnowledgeContent>): string => {
  if (pendingTranslation) {
    return t('contents:status.pending_translation');
  }

  if (
    contents.filter((content) => !content.archived).some((content) => content.statusId !== Enum.contentStatus.VALIDATED)
  ) {
    return t('contents:status.require_validation');
  }

  return '';
};

const translationPath = '/translation';

export const getKnowledgeRoute = (id: number, pendingTranslation: boolean) =>
  `/knowledge/${id}${pendingTranslation ? translationPath : ''}`;

const KNOWLEDGE_FETCH_LIMIT = 42;
const CARD_MIN_WIDTH = 167;
const LINES = 6;
const PADDING = 32;
const GAP = 16;

export const getKnowledgeFetchLimit = (innerWidth: number): number => {
  if (!innerWidth) return KNOWLEDGE_FETCH_LIMIT;

  const cardAmountWithPadding = Math.floor(innerWidth / CARD_MIN_WIDTH);
  const pxToRemove = cardAmountWithPadding * GAP + PADDING * 2;
  const finalCardAmount = Math.floor((innerWidth - pxToRemove) / CARD_MIN_WIDTH);

  return finalCardAmount * LINES;
};
