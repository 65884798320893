import React from 'react';

import { ContributorView } from './ContributorView/ContributorView';
import { ContributorAlertView } from './ContributorAlertView/ContributorAlertView';

import styles from './Contributor.style';

export const Contributor = ({ is2FAActivated }: { is2FAActivated: boolean }) => (
  <div style={styles.wrapper}>
    <ContributorAlertView component={ContributorView} props={{ is2FAActivated }} />
  </div>
);
