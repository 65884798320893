import m from 'm';
import T from 'types';
import KEY from 'keys';

var component = {};

component.controller = function controller(args) {
  var self = this;

  // Allows no error reporting.
  args.error = {
    display: m.prop(false),
    msg    : m.prop(false),
  };

  self.check = function check(value) {
    args.displayErr(false);

    if (!value.length)
      args.error.display(false);
    else if (args.check && !args.check.test(value))
      args.error.display(true);
    else
      args.error.display(false);

    args.value(value);
  };

  self.validate = T.is.ft(args.validate)
    ? args.validate
    : function validate(value) {
      if (!value.length)
        args.error.display(false);
      else if (args.validate && !args.validate.test(value))
        args.error.display(true);
    };

  self.onkeyup = function onkeyup(e) {
    var key = e.which;

    if (key === KEY.ENTER) {
      if (!args.value().length)
        args.error.msg('Cannot be empty');
      else if (!self.validate(args.value))
        args.error.msg('Must be unique');
      else if (args.onenterkey) {
        args.onenterkey({
          value: args.value,
          index: args.index,
        });
      }
    }
  };
};

component.view = function view(c, args, value) {
  var readonly = args && args.readonly;

  args.displayErr = args.displayErr || m.prop(false);

  // Allow for no placeholder
  args.placeholder = args.placeholder || '';

  // Neutralize errors if not specified.
  args.error = args.error || {
    display: m.prop(false),
    msg    : m.prop(false),
  };

  var inputArgs = {
    type       : args.type || 'text',
    placeholder: args.placeholder,
    oninput    : m.withAttr('value', c.check),
    onblur     : m.withAttr('value', c.validate),
    value      : args.value(),
    maxlength  : args.max ? args.max : '',
    onkeydown  : args.onkeydown || null,
    onkeyup    : c.onkeyup,
  };

  if (readonly)
    inputArgs.readonly = true;

  return m('.input', {
    class: [
      args.class || 'input--none',
      'popup__input__text',
      'popup__input',
    ].join(' '),
  }, [
    m('input.input__value', inputArgs),
    m('.input__type'),
    m('.input__error', {
      class: args.error.msg().length && args.error.display() && args.displayErr() ? '' : 'hide',
    }, args.error.msg()),
    m('.input__maxlength', args.max
      ? [args.value().length, args.max].join(' / ')
      : ''),
  ]);
};

export default component;
