import {
  GAME_TYPE,
  Game,
  GameType,
  Image,
  isPartialGameErrorText,
  isPartialGameMultipleChoiceQuestion,
  isPartialGameNoInteraction,
  isPartialGameOpenQuestion,
  isPartialGameOrderingCards,
  isPartialGamePictureSpot,
  isPartialGameSwipingCards,
} from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DeepPartial } from 'ts-essentials';

import SContentEditionBar from 'Components/structural/SContentEditionBar/SContentEditionBar';
import MModal from 'Components/modal/MModal/MModal';
import { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';
import { SPageLoader } from 'Components/structural';

import { useAppDispatch } from 'Libs/redux/store';
import { useTypedSelector } from 'Libs/redux/utils';
import { redirect } from 'Libs/routing/redirect';
import Enum from 'Models/Enum';
import { contentMetadataSelector, selectById } from 'Store/entities/content/content.selectors';
import { fetchContentById } from 'Store/entities/content/content.thunks';
import { updateContent, validateContent } from 'Store/entities/content/content.hook';
import { ROLES, getCurrentUserRoles } from 'Services/role/roleService';

import { enqueueBasicAlert } from 'Pages/AlertManager';

import { ErrorTextForm } from '../GameplaysForm/ErrorTextForm';
import { MCQForm } from '../GameplaysForm/MCQForm';
import { NoInteractionForm } from '../GameplaysForm/NoInteractionForm';
import { OpenQuestionForm } from '../GameplaysForm/OpenQuestionForm';
import { OrderingCardsForm } from '../GameplaysForm/OrderingCardsForm';
import { PictureSpotForm } from '../GameplaysForm/PictureSpotForm';
import { SwipingCardsForm } from '../GameplaysForm/SwipingCardsForm';
import { GameplayChoice } from './GameplayChoice';
import { Panel } from './Panel';
import { getContentId } from './contentEdition.utils';

import styles from './ContentEdition.style';

export type BaseContentFormValues = {
  name: string;
  identifier: string;
  thematic: number;
  image: Image | PickerSelectedImage | null;
  knowMore?: string;
};

export type ContentFormValues = BaseContentFormValues & {
  game?: DeepPartial<Game> | null;
};

enum ContentDataStatus {
  WAITING_DATA,
  LOADING_DATA,
  LOADED_DATA,
}

export const ContentEdition = () => {
  const userRoles = getCurrentUserRoles();
  const dispatch = useAppDispatch();
  const contentId = getContentId();
  const [loading, setLoading] = useState<ContentDataStatus>(
    contentId ? ContentDataStatus.WAITING_DATA : ContentDataStatus.LOADED_DATA,
  );

  const { loading: requestLoading } = useTypedSelector(contentMetadataSelector);
  const content = useTypedSelector(selectById(contentId));

  useEffect(() => {
    if (requestLoading && loading === ContentDataStatus.WAITING_DATA) {
      setLoading(ContentDataStatus.LOADING_DATA);
    }
    if (!requestLoading && loading === ContentDataStatus.LOADING_DATA) {
      setLoading(ContentDataStatus.LOADED_DATA);
    }
  }, [requestLoading]);

  const [visibleGameplayModal, displayGameplayModal] = useState(false);

  useEffect(() => {
    if (contentId) {
      dispatch(fetchContentById({ contentId }));
    }
  }, [contentId]);

  // this will be moved to action bar, for now i just take the first available language
  const contentLanguage: keyof typeof Enum.Languages = content?.statuses
    ? (Object.keys(content.statuses)[0] as keyof typeof Enum.Languages)
    : ('en-US' as const);

  const { setValue, watch, setError, clearErrors, ...restOfFormMethods } = useForm<ContentFormValues>({
    defaultValues: {
      name: content?.name?.[contentLanguage] || '',
      identifier: content?.identifier || '',
      thematic: content?.thematic.id || 0,
      image: content?.coverImage || null,
      knowMore: content?.knowMore || '',
      game: content?.game,
    },
  });

  useEffect(() => {
    if (content) {
      setValue('name', content.name[contentLanguage] || '');
      setValue('identifier', content?.identifier || '');
      setValue('thematic', content.thematic.id);
      setValue('image', content?.coverImage || null);
      setValue('game', content?.game);
    }
  }, [content]);

  const gameFormValues = watch('game');

  const resetGameplay = () => {
    setValue('game', null);
  };

  const handleSaveContent = async () => {
    const formValues = watch();
    if (contentId) {
      try {
        await updateContent(contentId, {
          ...content,
          name: { [contentLanguage]: formValues.name },
          identifier: formValues.identifier,
          thematic: { id: formValues.thematic },
          ...(formValues.image ? { coverImage: formValues.image } : {}),
          knowMore: formValues.knowMore,
          ...(formValues.game ? { game: formValues.game } : {}),
        });
        enqueueBasicAlert({
          id: 'content_edition_success',
          status: 'success',
          text: t('contents:edition.update_success'),
        });
        clearErrors();
      } catch (e) {
        enqueueBasicAlert({
          id: 'content_edition_success',
          status: 'error',
          title: t('contents:edition.update_fail'),
        });
      }
    }
  };

  const isMasterAdmin = userRoles.some((role) => role.name === ROLES.MASTER_ADMIN);
  const isWriter = userRoles.some((role) => role.name === ROLES.WRITER);

  const onContentValidation = async () => {
    if (isMasterAdmin && contentId) {
      try {
        await validateContent(contentId, contentLanguage);
        enqueueBasicAlert({
          id: 'content_edition_success',
          status: 'success',
          text: t('contents:edition.update_success'),
          priority: 'low',
        });
        clearErrors();
      } catch (e: any) {
        setError('root', e?.params);
        enqueueBasicAlert({
          id: 'content_edition_success',
          status: 'error',
          title: t('contents:edition.update_fail'),
          priority: 'low',
        });
      }
    }
    if (userRoles.some((role) => role.name === ROLES.ADMIN)) {
      return true;
    }
  };

  if (loading !== ContentDataStatus.LOADED_DATA) return <SPageLoader />;

  return (
    <FormProvider
      {...restOfFormMethods}
      watch={watch}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
    >
      <div style={styles.container}>
        <SContentEditionBar
          title={watch('name')}
          identifier={watch('identifier')}
          // TO DO: last saved date/hour
          lastSavedDate="19:34"
          style={styles.editionBar}
          onBack={() => redirect('/content')}
          onContentSave={handleSaveContent}
          validateButtonLabel={isMasterAdmin ? 'Validate' : 'Ask for validation'}
          onValidate={onContentValidation}
          onRequestTranslation={() => console.log('request')}
          // TO DO: selected language and language options
          selectedLanguage={{
            id: 1,
            countryCode: 'fr',
            text: 'Français',
            status: {
              type: 'valid',
              text: 'Ready',
            },
          }}
          languageOptions={[
            {
              id: 1,
              countryCode: 'fr',
              text: 'Français',
              status: {
                type: 'valid',
                text: 'Ready',
              },
              onClick: (id: number) => console.log(id),
            },
          ]}
          // TO DO: archive content and see contributors
          onArchiveContent={() => console.log('archive', watch('identifier'))}
          onSeeContributors={() => console.log('contributors', watch('identifier'))}
        />
        <Panel language={contentLanguage} />
        <MModal
          onCloseModal={() => displayGameplayModal(false)}
          visible={visibleGameplayModal}
          type="dialog"
          title={t('gameplays:change_gameplay.title')}
          description={t('gameplays:change_gameplay.description')}
          labelActionButton={t('gameplays:change_gameplay.confirm')}
          labelSecondButton={t('gameplays:change_gameplay.cancel')}
          onAction={() => {
            displayGameplayModal(false);
            resetGameplay();
          }}
          onSecondAction={() => displayGameplayModal(false)}
          showExitCross
          actionButtonType="destructive"
        />

        {!watch('game.type') && (
          <GameplayChoice
            gameplayType={watch('game.type') || GAME_TYPE.MCQ}
            selectGameplayType={(gameplay: GameType) => {
              setValue('game.type', gameplay);
            }}
          />
        )}
        {watch('game.type') && isPartialGameMultipleChoiceQuestion(watch('game') as DeepPartial<Game>) && (
          <MCQForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {watch('game.type') && isPartialGameOpenQuestion(watch('game') as DeepPartial<Game>) && (
          <OpenQuestionForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {watch('game.type') && isPartialGameOrderingCards(watch('game') as DeepPartial<Game>) && (
          <OrderingCardsForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {watch('game.type') && isPartialGameSwipingCards(watch('game') as DeepPartial<Game>) && (
          <SwipingCardsForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {watch('game.type') && isPartialGameErrorText(watch('game') as DeepPartial<Game>) && (
          <ErrorTextForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {watch('game.type') && isPartialGameNoInteraction(watch('game') as DeepPartial<Game>) && (
          <NoInteractionForm
            language={contentLanguage}
            isGameplayAlreadyChosen={!!content?.game?.type}
            editGameplay={() => {
              displayGameplayModal(true);
            }}
          />
        )}
        {gameFormValues && isPartialGamePictureSpot(gameFormValues) && <PictureSpotForm />}
      </div>
    </FormProvider>
  );
};
