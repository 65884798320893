import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  instruction: {
    ...TYPOGRAPHY.BODY3,
    marginTop: 16,
  },
  instructionStrong: {
    ...TYPOGRAPHY.BODY3,
    marginTop: 16,
    fontWeight: 'bold',
  },
});

export default styleSheet;
