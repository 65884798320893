import { difference } from 'lodash';
import type { RawReactNode, RawReactNodes, RawReactNodeMap } from 'Libs/ts/types';

export type Key = string;
export type Keys = Key[];

export const getDiffKeys = (a: Keys, b: Keys): [Keys, Keys] => [difference(a, b), difference(b, a)];

export const applyClassNameToNode = (node: RawReactNode, classToAdd: string, classToRemove?: string): RawReactNode => {
  const { props = {}, ...rest } = node;

  if (!props.className || typeof props.className !== 'string')
    return { ...rest, props: { ...props, className: classToAdd } };

  const className = props.className
    .split(' ')
    .filter((c: string) => c !== classToRemove)
    .concat(classToAdd)
    .join(' ');

  return { ...rest, props: { ...props, className } };
};

export const hasClassname = (node: RawReactNode, classname?: string): boolean =>
  Boolean(classname && node.props.className && node.props.className.includes(classname));

export const transformRawToMap = (array: RawReactNodes): RawReactNodeMap =>
  array.reduce(
    (acc, value) => ({
      ...acc,
      [value.key]: value,
    }),
    {},
  );
