import m from 'm';
import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

var component = {};

component.view = function view() {
  return m('.document-page', [
    m('.document-page__header', [
      m(menu),
    ]),
    m(reactCreator, {
      component: 'DocumentList',
      props    : {},
      style: {
        height: '100%',
        paddingTop: '54px',
      },
    }),
  ]);
};

export default component;
