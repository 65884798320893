// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
/**
 * A drawer modal that slides from the right of the page
 *
 * - style: custom style to override the component's style
 * - visible: boolean that controles the display of a modal
 * - title: the title of a modal
 * - children: the components that will be contained in the drawer
 * - footer: a component that will be positionned at the bottom of the drawer
 * - onClose: function that is called when we close the modal
 */

import * as React from 'react';
import { createPortal } from 'react-dom';

import UIconButton from 'Components/unit/UIconButton/UIconButton';

import styles from './MDrawer.style';


type Props = {|
  style?: Object,
  visible: boolean,
  title: string,
  children: React.Node,
  onClose: Function,
  footer: React.Node,
|};

export class MDrawer extends React.PureComponent<Props> {
  _containerRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    style: undefined,
    children: null,
    footer: null,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.removeMDrawer);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.removeMDrawer);
  }

  render() {
    const { visible, children, title, onClose, style, footer } = this.props;
    const animStyle = visible ? styles.slideIn : styles.slideOut;

    return (
      createPortal(
        <div
          ref={this._containerRef}
          style={{ ...styles.wrapperContainer, ...animStyle, ...style, height: window.innerHeight }}
        >
          <div style={styles.wrapper}>
            <div style={styles.close}>
              <UIconButton
                icon="close-light"
                onClick={onClose}
                ghost
              />
            </div>
            <div style={styles.header}>
              <div style={styles.title}>
                {title}
              </div>
            </div>
            {children}
            <div style={styles.footer}>
              {footer}
            </div>
          </div>
        </div>,

        // $FlowFixMe we'll always have a body
        document.body)
    );
  }

  removeMDrawer = ({ target }: {target: EventTarget}) => {
    const { onClose, visible } = this.props;
    const drawerRef = this._containerRef.current;

    if (!(
      visible
      && drawerRef
      && target instanceof Node
      && !drawerRef.contains(target)
    ))
      return;

    onClose();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- used with ref by Drawer component
  scrollContentToTop = () => {
    if (!this._containerRef.current)
      return;

    this._containerRef.current.scrollTop = 0;
  };
}

export default MDrawer;
