import m from 'm';
import Promise from 'bluebird';
import popup from 'services/popupService';
import Model from 'models/Model';
import Enum from 'models/Enum';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import translationColumn from 'components/gaia.translation.column';

const component = {};

component.controller = function controller() {
  const self = this;
  const SegmentationDimension = Model('SegmentationDimension');
  const Category = Model('Category');

  self.saving = m.prop(false);
  self.modified = m.prop(false);
  self.saved = m.prop(false);
  self.languages = m.prop([]);
  self.polyglotLabels = m.prop([]);
  self.polyglotLabelRow = m.prop([]);
  self.categories = m.prop([]);
  self.categoriesRow = m.prop([]);

  SegmentationDimension
    .getFull()
    .then(function setLanguages(_dimensions) {
      const languageDimension = _dimensions.items.find((d) => d.typeId() === Enum.dimensionTypes.LANGUAGE);

      const defaultLanguageId = languageDimension.groups().items
        .findIndex((segment) => segment.isDefault());

      const defaultLanguage = languageDimension.groups().items.splice(defaultLanguageId, 1)[0];

      languageDimension.groups().items.unshift(defaultLanguage);

      if (languageDimension)
        self.languages(languageDimension.groups().items);

      return getCategories();
    });

  self.save = function save() {
    self.saving(true);

    const promises = [
      self.categories().save(),
    ];

    return Promise.all(promises)
      .tap(self.saving.bind(null, false))
      .tap(self.saved.bind(null, true))
      .tap(self.modified.bind(null, false))
      .then(getCategories);

  };

  self.warningSave = function warningSave(action) {
    if (!self.modified())
      return action();

    return popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit without saving? All unsaved modifications will be lost.',
      confirm: 'Don\'t save',
      cancel : 'Cancel',
      action : action,
    });
  };

  function getCategories() {
    Category.findForTranslation()
      .tap(self.categories)
      .then(formatLabels.bind(null, self.categoriesRow));
  }

  function formatLabels(row, _labels) {
    const columns = self.languages().map(function toColumn(language) {
      const items = _labels.map(formatWithLabel);
      const label = language.label();
      const isDefault = language.isDefault();

      return {
        default: isDefault,
        title  : label,
        key    : language.id(),
        items,
      };

      function formatWithLabel(category) {
        const goodLabel = category.getLabel(language.id());
        const placeholder = category.getLabel().label();

        return {
          value      : goodLabel.label,
          placeholder: placeholder,
        };
      }
    });

    row(columns);
    m.redraw();
  }

  return self;
};

component.view = function view(c) {
  var saveButton = m(button, {
    value   : c.modified() ? 'Save' : 'Saved',
    class   : 'button--save',
    action  : c.save,
    disabled: !c.modified(),
    loading : c.saving(),
  });

  return m('.gaia__translation', [
    m('.header', [
      m(menu, {
        confirm: c.warningSave,
      }),
      m(gaiaMenu, {
        confirm: c.warningSave,
      }),
    ]),
    m('.gaia__translation__container', [

      m('.gaia__translation__header', [
        m('.translation__header__title', [
          'Category Labels Translation',
        ]),
        m('.translation__header__actions', [saveButton]),
      ]),

      m('.gaia__translation__row', {
        class: !c.categories().length ? 'hidden' : '',
      }, [
        m('.gaia__translation__row__content', [
          c.categoriesRow().map(function displayRow(rowItem) {
            return m(translationColumn, {
              class   : 'gaia__translation__row__item',
              title   : rowItem.title,
              readonly: rowItem.default,
              items   : rowItem.items,
              modified: c.modified,
            });
          }),
        ]),
      ]),
      m('.gaia__translation__footer', [
        m('.translation__header__actions', [saveButton]),
      ]),
    ]),
  ]);

};

export default component;
