import { useCallback, useMemo, useState } from 'react';

import type { IconPropsType } from 'Components/structural/SPageHeader/SPageHeader';

type CreateModalProps = {
  refreshAudienceGroups: () => Promise<void>;
};

export const useConfigCreateAudienceGroupModal = ({ refreshAudienceGroups }: CreateModalProps) => {
  const [showCreateAudienceGroupModal, setShowCreateAudienceGroupModal] = useState(false);

  const handleCreateGroupModalToggle = useCallback(
    () => setShowCreateAudienceGroupModal((oldState) => !oldState),
    [],
  );

  const handleOnCreateGroupConfirm = useCallback(() => {
    refreshAudienceGroups();
    // TODO: add new audience group to list
    handleCreateGroupModalToggle();
  }, [handleCreateGroupModalToggle, refreshAudienceGroups]);

  const createButtonProps = useMemo(
    (): IconPropsType => ({
      iconName: 'plus',
      iconType: 'accentuated',
      onIconClick: handleCreateGroupModalToggle,
    }),
    [handleCreateGroupModalToggle],
  );

  return {
    showCreateAudienceGroupModal,
    createButtonProps,
    handleCreateGroupModalToggle,
    handleOnCreateGroupConfirm,
  };
};
