import { COLORS, LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const NUMBER_OF_CARDS_PER_LINE = 5;

const styleSheet = createStyleSheet({
  gridContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  grid: {
    backgroundColor: 'inherit',
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: `repeat(${NUMBER_OF_CARDS_PER_LINE}, minmax(167px, 1fr))`,
    padding: '32px 32px 0 32px',
    transition: 'margin-top 0.2s',
    position: 'relative',
    width: '1000px',
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  contentItemWrapper: {
    height: '220px',
    position: 'relative',
    cursor: 'pointer',
  },
  contentItem: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    height: '100%',
    width: '100%',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
    position: 'relative',
    height: '108px',
  },
  newContentCard: {
    height: '100%',
    width: '100%',
  },
  pageLoader: {
    height: '100%',
    position: 'relative',
  },
  filterBoxStyle: {
    paddingBottom: '14px',
  },
});

export default styleSheet;
