import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';

import MFileUpload from 'Components/modal/MFileUpload/MFileUpload';
import { TableMessage } from 'Components/structural/STable/types';
import { useTypedDispatch } from 'Libs/redux/utils';
import { isFeatureEnabled } from 'Services/feature-flags';
import App from 'Models/App';
import { configurations } from 'Models/Enum';
import { enqueueBasicAlert } from 'Pages/AlertManager';
import { FILE_MIME_TYPES } from 'Utils/Enum';

import { AmbassadorList } from '../AmbassadorList/AmbassadorList';
import { GlobalCodeSection } from '../GlobalCodeSection/GlobalCodeSection';
import { UploadListSection } from '../UploadListSection/UploadListSection';

import styles from './AmbassadorView.style';
import { actions } from '../redux';

const DEFAULT_AMBASSADOR_FOLLOWEES_LIMIT = 25;
const RANGE = 20;

export const AmbassadorView = () => {
  const withAmbassadorUpdateList = isFeatureEnabled('ambassador-update-list');

  const AMBASSADOR_FOLLOWEES_LIMIT = useMemo(
    () => (App as any).configurations().get(configurations.AMBASSADOR_FOLLOWEES_LIMIT),
    [],
  );

  const UPLOAD_ERROR: Record<string, string> = useMemo(
    () => ({
      'T-PT-UAL-001': t('config_ambassadors:ambassador_view.upload.errors.T-PT-UAL-001'),
      'T-PT-UAL-002': t('config_ambassadors:ambassador_view.upload.errors.T-PT-UAL-002'),
      'T-PT-UAL-003': t('config_ambassadors:ambassador_view.upload.errors.T-PT-UAL-003', {
        helpLink: t('config_ambassadors:ambassador_view.upload.helpLink'),
      }),
      'T-PT-UAL-004': `Some teams exceed the ${
        AMBASSADOR_FOLLOWEES_LIMIT?.value() ?? DEFAULT_AMBASSADOR_FOLLOWEES_LIMIT
      } player limit.\nIf you wish to increase this limit, please contact support.`,
      'T-PT-UAL-005': t('config_ambassadors:ambassador_view.upload.errors.T-PT-UAL-005'),
      'T-PT-UAL-006': t('config_ambassadors:ambassador_view.upload.errors.T-PT-UAL-006'),
    }),
    [AMBASSADOR_FOLLOWEES_LIMIT, t],
  );

  const cancelGetAll = useRef<null | (() => void)>(null);

  const dispatch = useTypedDispatch();

  const [uploadTeamsModalVisibility, setUploadTeamsModalVisibility] = useState(false);
  const [tableMessage, setTableMessage] = useState<TableMessage>();
  const [showMoreError, setShowMoreError] = useState('');
  const [order, setOrder] = useState<Array<string>>([]);
  const [texts, setTexts] = useState<Array<string>>([]);

  const fetchAmbassadors = useCallback(
    async (params: { from: number; to: number; isShowMore: boolean }) => {
      if (cancelGetAll.current) cancelGetAll.current();

      setTableMessage(undefined);
      setShowMoreError('');

      const { from, to, isShowMore } = params;
      const action = actions.getAll({ from, to, order, texts });

      cancelGetAll.current = action.cancel;

      try {
        await dispatch(action).promise;
      } catch (error) {
        if (!isShowMore) {
          setTableMessage({
            type: 'error',
            message: t('config_ambassadors:ambassador_view.fetch_ambassadors_error'),
          });
        }

        setShowMoreError(t('config_ambassadors:ambassador_view.fetch_ambassadors_error'));
      }
    },
    [dispatch, order, texts, t],
  );

  useEffect(() => {
    fetchAmbassadors({ from: 0, to: RANGE, isShowMore: false });
  }, [fetchAmbassadors]);

  useEffect(() => {
    if (!cancelGetAll.current) return;

    return cancelGetAll.current;
  }, []);

  const handleOpenUploadTeamsModal = () => {
    setUploadTeamsModalVisibility(true);
  };

  const handleCloseUploadTeamsModal = () => {
    setUploadTeamsModalVisibility(false);
  };

  const handleDone = () => {
    fetchAmbassadors({ from: 0, to: RANGE, isShowMore: false });
    handleCloseUploadTeamsModal();
    enqueueBasicAlert({
      id: 'UPLOAD_TEAMS_SUCCESS',
      text: t('config_ambassadors:ambassador_view.upload.done'),
      icon: 'success-circle',
      status: 'success',
      priority: 'low',
    });
  };

  return (
    <div style={styles.parent}>
      <div style={styles.firstBlock}>
        <div style={styles.title}>{t('config_ambassadors:ambassador_view.title')}</div>
        <div style={styles.subtitle}>{t('config_ambassadors:ambassador_view.subtitle')}</div>
      </div>
      <div style={styles.secondBlock}>
        <AmbassadorList
          fetchAmbassadors={fetchAmbassadors}
          tableMessage={tableMessage}
          showMoreError={showMoreError}
          onSort={setOrder}
          onSearch={setTexts}
          ambassadorsFetchAmount={RANGE}
        />
      </div>
      <div style={styles.thirdBlock}>
        <GlobalCodeSection />
      </div>
      <div style={styles.fourthBlock}>
        {withAmbassadorUpdateList && (
          <UploadListSection
            style={styles.uploadWrapper}
            onAction={handleOpenUploadTeamsModal}
            linkLearnMore={t('config_ambassadors:ambassador_view.upload.helpLink')}
          />
        )}
      </div>
      <MFileUpload
        title={t('config_ambassadors:ambassador_view.upload.title')}
        description={t('config_ambassadors:ambassador_view.upload.description')}
        onEndUpload={handleDone}
        onClose={handleCloseUploadTeamsModal}
        uploadErrors={UPLOAD_ERROR}
        uploadUrl="/api/players/team/upload"
        visible={uploadTeamsModalVisibility}
        format={['csv', 'xls', 'xlsx']}
        maxFileSize={2000000}
        allowedMimeTypes={[FILE_MIME_TYPES.XSL, FILE_MIME_TYPES.XSLX, FILE_MIME_TYPES.CSV]}
      />
    </div>
  );
};
