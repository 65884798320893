// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapperT: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    display: 'flex',
  },
  inputWrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    borderRadius: '4px 0px 0px 4px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
    borderRight: 'none',
  },
  textField: {
    height: '100%',
    width: '60%',
    padding: 0,
    border: 'none',
    outline: 'none',
    marginLeft: 12,
    ...TYPOGRAPHY.H2,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    marginRight: 16,
    marginLeft: 10,
    cursor: 'pointer',
  },
  inputButton: {
    padding: '10px 16px',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-block',
    borderRadius: '0px 3px 3px 0px',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    ...TYPOGRAPHY.BODY2,
  },
  descriptionWrapper: {
    marginTop: 8,
    marginLeft: 8,
  },
  description: {
    margin: 0,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  descriptionCaption: {
    margin: 0,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  linkText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.ACCENTUATED,
    textAlign: 'left',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    cursor: 'pointer',
  },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 16,
    height: 40,
  },
  messageWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 8,
    marginTop: 8,
  },
  messageText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: 8,

  },
  warningText: {
    color: COLORS.WARNING.DEFAULT,
  },
  infoIcon: {
    cursor: 'pointer',
    margin: 10,
  },
};
