// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 10px',
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    marginBottom: 4,
  },
  labelText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBottom: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  mailText: {
    ...TYPOGRAPHY.BODY4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};
