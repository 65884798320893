import { mergeDeep } from 'Libs/mergeDeep';
import Enum from 'Models/Enum';

import type { UndefinableId } from 'Libs/ts/types';

import type { OrderingCardsItemType, OrderingCardsItemToSend } from './OrderingCardsItem';
import type { GameItemType } from '../GameItem';
import type { AlertValidationField, ContentValidationResult } from '../Alert';

import createGameItem from '../GameItem';
import { createOrderingCardsItem } from './OrderingCardsItem';
import { FIELD_MESSAGES } from '../Alert';

export type OrderingCardsType = Readonly<{
  id: number;
  question: GameItemType;
  topLabel: GameItemType;
  bottomLabel: GameItemType;
  explanation: GameItemType;
  cards: ReadonlyArray<OrderingCardsItemType>;
}>;

export type OrderingCardsToSend = UndefinableId<OrderingCardsType>;

export const MIN_ITEMS = 3;

const defaultState: OrderingCardsType = {
  id: 0,
  question: createGameItem(),
  topLabel: createGameItem(),
  bottomLabel: createGameItem(),
  explanation: createGameItem(),
  cards: [
    { ...createOrderingCardsItem(), key: 0 },
    { ...createOrderingCardsItem(), key: 1 },
    { ...createOrderingCardsItem(), key: 2 },
  ],
};

export function createOrderingCards(values?: any): OrderingCardsType {
  if (!values) {
    return defaultState;
  }

  const newCards = values.cards.map((card: Partial<OrderingCardsItemType>, i: number) => ({
    ...createOrderingCardsItem(card),
    key: i,
  }));

  const content: OrderingCardsType = {
    id: values.id,
    question: createGameItem(values.question),
    topLabel: createGameItem(values.topLabel),
    bottomLabel: createGameItem(values.bottomLabel),
    explanation: createGameItem(values.explanation),
    cards: newCards,
  };

  return mergeDeep(defaultState, content);
}

export function validateOrderingCards(orderingCards: OrderingCardsType): ContentValidationResult {
  const { cards, question, bottomLabel, topLabel, explanation } = orderingCards;

  const result: Array<AlertValidationField> = [];

  if (!question.data) {
    result.push('noInstruction');
  }
  if (!explanation.data) {
    result.push('noExplanation');
  }
  if (!topLabel.data) {
    result.push('noTopLabel');
  }
  if (!bottomLabel.data) {
    result.push('noBottomLabel');
  }

  if (cards.length < MIN_ITEMS) {
    result.push('noEnoughCards');
  }

  const emptyItemNb = cards.reduce((count, card) => (!card.cardContent.data ? count + 1 : count), 0);

  if (emptyItemNb > 0) {
    result.push('noCardsContent');
  }

  return { fields: result, params: { emptyItemNb } };
}

export function makeOCValidationMessage(validation: ContentValidationResult): string {
  const { fields, params } = validation;
  const emptyItemNb = params && params.emptyItemNb;

  const fieldMessage = fields.length
    ? fields
        .map((field) =>
          field === 'noCardsContent' && emptyItemNb
            ? `${FIELD_MESSAGES[field]}, (${emptyItemNb} empty item(s) remaining). Fill or delete any empty items.`
            : FIELD_MESSAGES[field],
        )
        .join('\n')
    : '';

  return fieldMessage;
}

export function prepareOrderingCards(gameplay: OrderingCardsToSend, knowledgeId: number): any {
  const { INSTRUCTION, OC_ANSWER, HELPER, TEXT } = Enum.gameItemTypes;
  const {
    question: oldQuestion,
    topLabel: oldTopLabel,
    bottomLabel: oldBottomLabel,
    explanation: oldExplanation,
    cards: cardsWithoutOrder,
  } = gameplay;

  const cards = cardsWithoutOrder.map((card: OrderingCardsItemToSend, i: number) => {
    const { cardContent: oldContent, id } = card;
    const cardContent = {
      ...oldContent,
      knowledgeId,
      typeId: OC_ANSWER,
    };

    const newCard = { cardContent, id, order: i + 1 };

    if (newCard.id === 0) {
      delete newCard.id;
    }

    return newCard;
  });

  const explanation = { ...oldExplanation, knowledgeId, typeId: TEXT };
  const topLabel = { ...oldTopLabel, knowledgeId, typeId: HELPER };
  const bottomLabel = { ...oldBottomLabel, knowledgeId, typeId: HELPER };
  const question = { ...oldQuestion, knowledgeId, typeId: INSTRUCTION };

  const newGameplay = {
    id: gameplay.id,
    question,
    topLabel,
    bottomLabel,
    explanation,
    cards,
  };

  if (newGameplay.id === 0) {
    delete newGameplay.id;
  }

  return newGameplay;
}
