import React, { MouseEventHandler } from 'react';

import UFlag from 'Components/unit/UFlag/UFlag';

import styles from './UDropdownCountryItem.style';

export type UDropdownCountryItemProps = {
  onClick: MouseEventHandler;
  countryCode: string;
  text: string;
  selected: boolean;
  style?: React.CSSProperties;
};

type UDropdownCountryItemState = {
  isHover: boolean;
};

/**
 * Dropdown Country Item
 *
 * Props:
 * - onClick: Function called when we click on the button
 * - countryCode: The flag country code
 * - text: string to display next to the flag
 * - selected: Specify that this is the selected value
 * - style: override component's style
 */
export class UDropdownCountryItem extends React.PureComponent<UDropdownCountryItemProps, UDropdownCountryItemState> {
  static defaultProps = {
    onClick: () => {},
    countryCode: 'fr',
    text: '',
    selected: false,
    style: undefined,
  };

  state = {
    isHover: false,
  };

  render() {
    const { style, countryCode, text } = this.props;
    const conditionalStyle = this.makeConditionalStyle();

    return (
      <div
        style={{ ...styles.wrapper, ...conditionalStyle, ...style }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={this.handleClick}
      >
        <UFlag countryCode={countryCode} size={16} style={styles.flag} />
        <div style={styles.text}>{text}</div>
        <div style={styles.fill} />
      </div>
    );
  }

  handleClick: MouseEventHandler = (e) => {
    const { onClick, selected } = this.props;

    if (!selected) {
      onClick(e);
    }
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  makeConditionalStyle = () => {
    const { selected } = this.props;
    const { isHover } = this.state;

    if (selected) return styles.wrapperSelected;

    if (isHover) return styles.wrapperHovered;

    return {};
  };
}

export default UDropdownCountryItem;
