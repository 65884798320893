import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionDialogConfirmation } from 'Hooks/useActionDialogConfirmation/useActionDialogConfirmation';
import { useAppDispatch } from 'Libs/redux/store';
import { actions, useModuleSelector } from 'Pages/Courses/redux';
import { DoNotShowAgainPrompt } from '../components/DoNotShowAgainPrompt/DoNotShowAgainPrompt';

export const useAudienceGroupListProps = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const courseId = useModuleSelector((state) => state.edition.course?.id);
  const isCoursePublished = useModuleSelector((state) => state.edition.course?.statuses.published);
  const askConfirmationBeforeAudienceAssignement = useModuleSelector(
    (state) => state.askConfirmationPreferenceMapping[courseId || -1],
  );

  const onGroupAssignment = useCallback(
    (newGroupIds: number[]) => {
      dispatch(actions.updateCourseGroupIds({ groupsIds: newGroupIds }));
    },
    [dispatch],
  );

  const handleCheckConfirmationPreferenceDispatch = useCallback(
    (checked: boolean) => {
      dispatch(
        actions.setAskForConfirmationPreference({
          courseId: courseId || -1,
          shouldAsk: !checked,
        }),
      );
    },
    [courseId, dispatch],
  );

  const { dialog: confirmBeforeAudienceAssignmentDialog, askForConfirmation: askConfirmBeforeAudienceAssignment } =
    useActionDialogConfirmation({
      title: t('audiences:update_audience_modal.dialog_confirmation.title'),
      description: t('audiences:update_audience_modal.dialog_confirmation.description'),
      primaryActionLabel: t('audiences:update_audience_modal.dialog_confirmation.confirm'),
      secondaryActionLabel: t('audiences:update_audience_modal.dialog_confirmation.cancel'),
      children: <DoNotShowAgainPrompt onClick={handleCheckConfirmationPreferenceDispatch} />,
    });

  const askConfirmationBeforeGroupAssignmentIfNeeded = useCallback(
    (cb: () => void) => {
      if (isCoursePublished && askConfirmationBeforeAudienceAssignement) {
        askConfirmBeforeAudienceAssignment(cb);
        return;
      }

      cb();
    },
    [askConfirmBeforeAudienceAssignment, askConfirmationBeforeAudienceAssignement, isCoursePublished],
  );

  return {
    confirmBeforeAudienceAssignmentDialog,
    audienceGroupListProps: {
      askConfirmationBeforeGroupAssignmentIfNeeded,
      onGroupAssignment,
    },
  };
};
