import React, { useMemo } from 'react';
import type { FunctionComponent, CSSProperties } from 'react';

import STooltip, { TooltipPosition } from 'Components/structural/STooltip/STooltip';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './ULabel.style';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';

export type ULabelTooltipProps = {
  tooltipContent: string;
  tooltipPosition: TooltipPosition;
  tooltipMaxWidth?: number;
};

export type LabelSize = 'XS' | 'S' | 'M' | 'L';

export type ULabelProps = {
  icon?: IconName;
  iconColor?: string;
  iconSize?: number;
  tooltip?: ULabelTooltipProps;
  size?: LabelSize;
  required?: boolean;
  htmlFor?: string;
  style?: CSSProperties;
};

const tooltipId = 'uicontab-tooltip';

const labelMap = {
  XS: TYPOGRAPHY.BODY4,
  S: TYPOGRAPHY.BODY4_BOLD,
  M: TYPOGRAPHY.H2_SEMIBOLD,
  L: TYPOGRAPHY.HERO_M,
};

export const ULabel: FunctionComponent<ULabelProps> = ({
  icon,
  iconColor,
  iconSize = 10,
  children,
  size = 'S',
  htmlFor,
  required,
  tooltip,
  style,
}) => {
  const tooltipStyle = useMemo(() => (tooltip && required ? {} : styles.tooltip), [tooltip, required]);

  const labelStyle = useMemo(() => labelMap[size], [size]);

  return (
    <div style={styles.wrapper}>
      {icon && <UIcon name={icon} size={iconSize} color={iconColor ?? COLORS.TEXT.DEFAULT} />}
      <label
        htmlFor={htmlFor}
        style={{
          ...styles.label,
          ...labelStyle,
          ...style,
        }}
      >
        {children}
        {required && <span style={styles.star}>*</span>}
      </label>
      {tooltip && (
        <div data-tip data-for={tooltipId} style={tooltipStyle}>
          <UIcon name="info-what" size={10} color={COLORS.TEXT.SECONDARY_DEFAULT} />
          <STooltip
            id={tooltipId}
            content={tooltip.tooltipContent}
            maxWidth={tooltip.tooltipMaxWidth}
            position={tooltip.tooltipPosition}
          />
        </div>
      )}
    </div>
  );
};

export default ULabel;
