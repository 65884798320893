import React, { CSSProperties, FunctionComponent, useMemo } from 'react';

import styles from './StickyContainer.style';

type StickyContainerProps = {
  active: boolean;
  contentCount: number; // Include contentCount prop
  style?: CSSProperties;
};

export const StickyContainer: FunctionComponent<StickyContainerProps> = ({ children, contentCount, active, style }) => {
  const containerStyle = useMemo(() => {
    const applyContentStyle = !active && contentCount > 0;

    return {
      ...styles.container,
      ...(active ? styles.activeContainer : {}),
      ...(applyContentStyle ? styles.containerWithContent : {}), // Assuming you have a containerWithContent style
      ...style,
    };
  }, [active, contentCount, style]);

  return <div style={containerStyle}>{children}</div>;
};
