// @flow

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {

    // INFO allow scrolling this view independently of alerts
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  tableContainer: {
    width : '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
  },
  tableWrapper: {
    width: 640,
    minHeight: 400,

    paddingTop: 24,
    paddingBottom: 32,
    paddingLeft: 30,
    paddingRight: 30,

    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  bottomSpace: {
    height: 130,
    width: '100%',
  },
};
