// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display a icon with text in a tab
 *
 * Props:
 *  - style: For overriding the component's style
 *  - color: Color of the icon and border-left when compoennt is selected
 *  - content: Text displayed in the component
 *  - icon: Name of the icon used in the component
 *  - onClick: Function called when we click on the checkbox
 *  - isSelected: Set if the component is selected
 */

import * as React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';

import { COLORS } from 'Components/foundation';

import styles from './UTabListItemVertical.style';

type Props = {|
  style?: Object,
  color: string,
  content: string,
  icon: string,
  onClick: MouseEventHandler,
  isSelected: boolean,
|};

type State = {|
  isHover: boolean,
|};

export class UTabListItemVertical extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    isSelected: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  render() {
    const { style, content, icon, color, isSelected } = this.props;

    const colorComp = isSelected ? color : COLORS.TEXT.SECONDARY_DEFAULT;
    const conditionalStyle = this.makeConditionalStyle();

    return (
      <div
        style={{ ...styles.wrapper, ...conditionalStyle, ...style }}
        onClick={this.handleClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
      >
        <UIcon
          name={icon}
          style={styles.icon}
          color={colorComp}
          size={28}
        />
        {content}
      </div>
    );
  }

  handleClick = (e: MouseEvent) => {
    const { onClick, isSelected } = this.props;

    if (isSelected)
      return;

    onClick(e);
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  makeConditionalStyle = () => {
    const { isSelected, color } = this.props;
    const { isHover } = this.state;

    if (isSelected) {
      return {
        ...styles.selected,
        borderLeft: `4px solid ${color}`,
      };
    }
    if (isHover)
      return styles.hovered;

    return {};
  };
}

export default UTabListItemVertical;
