import m from 'm';
import { t } from 'i18next';
import Bus from 'services/pubSubService';
import activityIcon from 'components/activity.icon';

import Enum from 'models/Enum';
import EnumMedia from 'models/MediaHandlerEnum';
import Image from 'models/Image';

import mediaSelector from 'components/activity.media.selector';
import inputText from 'components/new.new.input.text';
import segmentationInput from 'components/segmentation.input';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.updateActivity = function updateActivity() {
    args.activity().updateSimple(args.activity())
      .then(function applyChanges(updatedActivity) {
        var finalActivity = args.activity().clone();

        finalActivity.active(updatedActivity.active());
        Bus.trigger('activity:save', finalActivity);
      });
  };

  self.switchPicture = function switchPicture(image) {
    if (!image) {
      if (args.activity().coverId())
        return args.activity().cover();

      return new Image({ url: 'https://sparted-prod-common.s3.eu-west-1.amazonaws.com/interseason-card-cover.png', id: Enum.activityTypeEnum.INTERSEASON });
    }

    console.log('IMAGE SAVED', image.toJSON());

    args.activity().cover(image);
    args.activity().coverId(image.id());
    m.redraw();
    args.activity().subActivity().save()
      .then(function applyChanges(saved) {
        console.log('savedDailySerie', saved);
        m.redraw();
      });
  };

};

component.view = function view(c, args) {
  var activity = args.activity();

  return m('.activityInfos.activityInfos--interseason', [
    m('.activityInfos__top', [
      m('.activityInfos__left', [
        m('.activityInfos__img'),
        m(mediaSelector, {
          image    : c.switchPicture,
          cropProps: {
            ...EnumMedia.COMMON.cropProps,
            ...EnumMedia.CAMPAIGN,
          },
        }),
      ]),
      m('.activityInfos__right', [
        m('.activityInfos__type', [
          m(activityIcon, {
            class       : 'activityInfos__type__ico',
            activityType: activity.typeId(),
            size        : 'badge',
            rounded     : true,
          }),
          m('.activityInfos__type__value', t('interseason:interseason_card')),
        ]),
        m('.activityInfos__interseasonLegend', t('interseason:when_there_is_no_campaign_planned')),
        m('.activityInfos__input.activityInfos__input--title', [
          m(inputText, {
            model      : activity,
            path       : 'name',
            placeholder: t('interseason:activity_title'),
            max        : 20,
          }),
        ]),
        m('.activityInfos__input.activityInfos__input--desc.activityInfos__input--interseason', [
          m(inputText, {
            model      : activity,
            path       : 'description',
            placeholder: t('interseason:short_description_of_your_activity'),
            max        : activity.getDescriptionLimit(),
          }),
        ]),
        m('.activityInfos__interseasonLegend', t('interseason:when_an_active_campaign_is_planned_for_the_player')),
        m('.activityInfos__input.activityInfos__input--title', [
          m(inputText, {
            model      : activity.interseasonCard(),
            path       : 'availableContentTitle',
            placeholder: t('interseason:activity_title'),
            max        : 20,
          }),
        ]),
        m('.activityInfos__input.activityInfos__input--desc.activityInfos__input--interseason', [
          m(inputText, {
            model      : activity.interseasonCard(),
            path       : 'availableContentDescription',
            placeholder: t('interseason:short_description_of_your_activity'),
            max        : activity.getDescriptionLimit(),
          }),
        ]),
      ]),
    ]),
    m('.activityInfos__segmentation', [
      m(segmentationInput, {
        entity         : activity,
        languageUnicity: true,
      }),
    ]),
  ]);
};

export default component;
