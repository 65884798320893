// @flow

import * as React from 'react';
import { t } from 'i18next';

import UDaySelector from 'Components/unit/UDaySelector/UDaySelector';
import styles from './SDaysSelector.style';


type Props = {|
  style: ?Object,
  selectedDays: {| +[number]: boolean |},
  onChange: ({| +[number]: boolean |}) => mixed,
|};

type State = {|
  ALL_DAYS: {
    [number]: string,
  }
|};

export class SDaysSelector extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
  };

  state = {
    ALL_DAYS: {
      [1]: t('structural_component:s_day_selector.monday'),
      [2]: t('structural_component:s_day_selector.tuesday'),
      [3]: t('structural_component:s_day_selector.wednesday'),
      [4]: t('structural_component:s_day_selector.thursday'),
      [5]: t('structural_component:s_day_selector.friday'),
      [6]: t('structural_component:s_day_selector.saturday'),
      [7]: t('structural_component:s_day_selector.sunday'),
    }
  }

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderDaysSelector()}
      </div>
    );
  }

  renderDaysSelector = () => {
    const { selectedDays } = this.props;

    return Object.keys(this.state.ALL_DAYS).map<React.Element<typeof UDaySelector>>((id, i, array) => {
      // eslint-disable-next-line no-magic-numbers
      const marginRight = (array.length - 1) === i ? null : 3;
      const idKey = Number(id);

      return (
        <UDaySelector
          key={id}
          label={this.state.ALL_DAYS[idKey]}
          isSelected={selectedDays[idKey]}
          onClick={this.handleClick.bind(this, idKey)}
          style={{ marginRight }}
        />
      );
    });
  };

  handleClick = (id: number) => {
    const { onChange, selectedDays } = this.props;

    const newSelectedDays = {
      ...selectedDays,
      [id]: !selectedDays[id],
    };

    onChange(newSelectedDays);
  };
}

export default SDaysSelector;
