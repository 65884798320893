/**
 * This component is a wrapper aroung MImageCrop, it can also show a image preview.
 *
 * Optional props:
 * - onChange: function called when the user finish the process of selecting an image
 * - dropAre: optionnal boolean enabling dropArea styling
 * - image: optionnal image url
 * - size: optionnal size - S, M, L
 * - style: optionnal css property override for the outermost container
 */

import { css } from 'aphrodite';
import { t } from 'i18next';
import type { CSSProperties, Reducer } from 'react';
import React, { useCallback, useMemo, useReducer } from 'react';

import { Video as SharedVideo } from '@sparted/shared-library/business/types';

import type { ImageInstance } from 'Models/Image';
import EnumMedia from 'Models/MediaHandlerEnum';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import MImageCrop from 'Components/modal/MImageCrop/MImageCrop';
import MVideoUpload, { OnSaveVideoArgs, VideoProvider } from 'Components/modal/MVideoUpload/MVideoUpload';
import UIconButton from 'Components/unit//UIconButton/UIconButton';
import ULabel, { LabelSize } from 'Components/unit//ULabel/ULabel';
import { UErrorTextHighlight } from 'Components/unit/UErrorTextHighlight/UErrorTextHighlight';
import UIcon from 'Components/unit/UIcon/UIcon';
import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import UButton from '../UButton/UButton';
import styles, {
  DROPAREA_ICON_SIZES,
  ILLUSTRATION_SIZES,
  IMAGE_PICKER_ILLUSTRATION_COLOR_SCHEME,
  IMAGE_PICKER_SIZES,
  getWrapperStylesheet,
} from './UMediaPicker.style';
import {
  getPickerSelectedImageShorthand,
  getPickerSelectedVideoShorthand,
} from './utils/pickerSelectedMediaShorthand.utils';
import Video, { VIDEO_TYPE_TO_TYPE_ID_MAPPING } from 'Models/Video';

export type PickerSelectedImage = {
  id: number;
  url: string;
  cdn: string;
};

export type PickerSelectedVideo = {
  id: number;
  url: string;
  type: SharedVideo['type'];
  thumbnailUrl: string;
};

export type ShowableVideo = {
  type: SharedVideo['type'];
  url: string;
};

export const isShowableVideo = (video: any): video is ShowableVideo => {
  return video?.type && video?.url;
};

type MediaType = 'image' | 'video' | 'imageOrVideo';

type ImageMediaStrategy = {
  mediaType: 'image';
  onChange?: (image: PickerSelectedImage) => void;
};

type VideoMediaStrategy = {
  mediaType: 'video';
  onChange?: (video: PickerSelectedVideo) => void;
  providers: VideoProvider[];
};

type ImageOrVideoMediaStrategy = {
  mediaType: 'imageOrVideo';
  onChange?: (
    arg: { type: 'image'; media: PickerSelectedImage } | { type: 'video'; media: PickerSelectedVideo },
  ) => void;
  providers: VideoProvider[];
};

export type UMediaPickerProps = {
  textLinkPosition?: 'horizontal' | 'vertical';
  image?: string;
  video?: {
    type: SharedVideo['type'];
    url: string;
  };
  label?: string;
  labelSize?: LabelSize;
  withContainer?: boolean;
  isLabelRequired?: boolean;
  onClearMedia?: () => void;
  textLink?: string;
  size?: 'XS' | 'S' | 'M' | 'L';
  dropArea?: boolean;
  style?: CSSProperties;
  textLinkStyle?: CSSProperties;
  textLinkTypography?: keyof typeof TYPOGRAPHY;
  hideModalOverlay?: boolean;
  media: ImageMediaStrategy | VideoMediaStrategy | ImageOrVideoMediaStrategy;
};

const noop = () => {};

type UMediaPickerState = {
  isHover: boolean;
  pickerVisibility: boolean;
  mediaTypeState: MediaType;
};

type UMediaPickerActions =
  | { type: 'OPEN_PICKER' }
  | { type: 'OPEN_VIDEO_PICKER' }
  | { type: 'OPEN_IMAGE_PICKER' }
  | { type: 'CLOSE_PICKER' }
  | { type: 'HOVER' }
  | { type: 'LEAVE' }
  | { type: 'HOVER_CROSS' }
  | { type: 'LEAVE_CROSS' }
  | { type: 'UNPICK_MEDIA_TYPE' };

const reducer = (state: UMediaPickerState, action: UMediaPickerActions) => {
  switch (action.type) {
    case 'OPEN_VIDEO_PICKER':
      return {
        ...state,
        pickerVisibility: true,
        mediaTypeState: 'video' as MediaType,
      };
    case 'OPEN_IMAGE_PICKER':
      return {
        ...state,
        pickerVisibility: true,
        mediaTypeState: 'image' as MediaType,
      };
    case 'UNPICK_MEDIA_TYPE':
      return {
        ...state,
        mediaTypeState: 'imageOrVideo' as MediaType,
      };
    case 'OPEN_PICKER':
      return {
        ...state,
        pickerVisibility: true,
      };
    case 'CLOSE_PICKER':
      return {
        ...state,
        pickerVisibility: false,
      };
    case 'HOVER':
      return {
        ...state,
        isHover: true,
      };
    case 'LEAVE':
      return {
        ...state,
        isHover: false,
      };
    default:
      return state;
  }
};

const initializeReducerState = (mediaType: MediaType) => ({
  isHover: false,
  pickerVisibility: false,
  mediaTypeState: mediaType,
});

export const UMediaPicker = ({
  image,
  video,
  media,
  withContainer = false,
  label,
  labelSize = 'S',
  onClearMedia,
  isLabelRequired = false,
  dropArea = false,
  size = 'S',
  hideModalOverlay = false,
  textLink = undefined,
  textLinkPosition = 'horizontal',
  textLinkStyle = {},
  textLinkTypography = 'BODY3',
  style = {},
}: UMediaPickerProps) => {
  const { mediaType, onChange } = media;

  const [state, dispatch] = useReducer<Reducer<UMediaPickerState, UMediaPickerActions>, MediaType>(
    reducer,
    mediaType,
    initializeReducerState,
  );
  const { isHover, pickerVisibility, mediaTypeState } = state;

  const handleClearMedia = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onClearMedia?.();
      dispatch({ type: 'UNPICK_MEDIA_TYPE' });
    },
    [onClearMedia],
  );

  const coverUrl = useMemo(() => {
    if (image) {
      return image;
    }
    if (video) {
      return new Video({
        url: video.url,
        typeId: VIDEO_TYPE_TO_TYPE_ID_MAPPING[video.type],
      }).getOriginalThumbnailSync();
    }
    return;
  }, [image, video]);

  const handleChange = useMemo(() => {
    if (mediaType === 'imageOrVideo' && mediaTypeState === 'image') {
      return (newImage: ImageInstance) => {
        onChange?.({
          type: 'image',
          media: getPickerSelectedImageShorthand(newImage),
        });
        dispatch({ type: 'CLOSE_PICKER' });
      };
    }

    if (mediaType === 'imageOrVideo' && mediaTypeState === 'video') {
      return (newVideo: OnSaveVideoArgs) => {
        onChange?.({
          type: 'video',
          media: getPickerSelectedVideoShorthand(newVideo),
        });
        dispatch({ type: 'CLOSE_PICKER' });
      };
    }

    if (mediaType === 'image') {
      return (newImage: ImageInstance) => {
        onChange?.(getPickerSelectedImageShorthand(newImage));
        dispatch({ type: 'CLOSE_PICKER' });
      };
    }

    if (mediaType === 'video') {
      return (newVideo: OnSaveVideoArgs) => {
        onChange?.(getPickerSelectedVideoShorthand(newVideo));
        dispatch({ type: 'CLOSE_PICKER' });
      };
    }
  }, [mediaType, onChange, mediaTypeState]);

  const dualMediaTypeMode = media.mediaType === 'imageOrVideo' && !coverUrl;

  const wrapperStylesheet = useMemo(
    () => getWrapperStylesheet(dropArea, coverUrl, dualMediaTypeMode),
    [dropArea, coverUrl, dualMediaTypeMode],
  );
  const glowStyle = useMemo(
    () => ({
      ...styles.glowEffect,
      width: DROPAREA_ICON_SIZES[size] / 2,
      height: DROPAREA_ICON_SIZES[size] / 3,
    }),
    [size],
  );

  const textLinkAlignment = useMemo(
    () => (textLinkPosition === 'horizontal' ? styles.textLinkHorizontal : styles.textLinkVertical),
    [textLinkPosition],
  );

  const containerStyle = useMemo(() => (withContainer ? styles.withContainer : undefined), [withContainer]);

  if (dualMediaTypeMode && size !== 'L') {
    return (
      <UErrorTextHighlight variant="invalid">
        {t('unit_components:u_media_picker.dual_requires_xl_size')}
      </UErrorTextHighlight>
    );
  }

  return (
    <div style={containerStyle}>
      {label && (
        <div style={styles.label}>
          <ULabel size={labelSize} required={isLabelRequired}>
            {label}
          </ULabel>
        </div>
      )}
      <div style={textLinkPosition === 'horizontal' ? styles.row : styles.column}>
        <div
          onMouseEnter={() => dispatch({ type: 'HOVER' })}
          onMouseLeave={() => dispatch({ type: 'LEAVE' })}
          className={css(wrapperStylesheet.wrapper)}
          style={{
            ...IMAGE_PICKER_SIZES[size],
            ...style,
          }}
          onClick={() => dispatch({ type: 'OPEN_PICKER' })}
        >
          {isHover && coverUrl && onClearMedia && (
            <div style={styles.closeIcon}>
              <UIconButton
                size="S"
                type="standard-light"
                style={styles.closeRadius}
                icon="close"
                onClick={handleClearMedia}
              />
            </div>
          )}
          {!coverUrl && !dropArea && !dualMediaTypeMode && (
            <UIllustration
              illustration="course"
              width={ILLUSTRATION_SIZES[size].width}
              height={ILLUSTRATION_SIZES[size].height}
              color={IMAGE_PICKER_ILLUSTRATION_COLOR_SCHEME}
              style={styles.illustration}
            />
          )}
          {dropArea && !coverUrl && !dualMediaTypeMode && (
            <>
              <div style={glowStyle} />
              <UIcon
                style={styles.dropAreaIcon}
                color={COLORS.TEXT.SECONDARY_DEFAULT}
                size={DROPAREA_ICON_SIZES[size]}
                name={mediaType === 'image' ? 'image-upload' : 'video-upload'}
              />
            </>
          )}
          {dualMediaTypeMode && (
            <div style={styles.dualMediaTypeInputWrapper}>
              <UButton
                type="standard-light"
                style={styles.dualMediaTypeInputButton}
                leftIcon="image-upload"
                onClick={() => dispatch({ type: 'OPEN_IMAGE_PICKER' })}
                textAndIconStyle={styles.dualMediaTypeInputText}
                text={t('unit_components:u_media_picker.image')}
              />
              <UButton
                type="standard-light"
                style={styles.dualMediaTypeInputButton}
                leftIcon="video-upload"
                onClick={() => dispatch({ type: 'OPEN_VIDEO_PICKER' })}
                textAndIconStyle={styles.dualMediaTypeInputText}
                text={t('unit_components:u_media_picker.video')}
              />
            </div>
          )}
        </div>
        {textLink && (
          <UTextLink
            text={textLink}
            onClick={() => dispatch({ type: 'OPEN_PICKER' })}
            target="_self"
            style={{ ...textLinkStyle, ...textLinkAlignment }}
            typography={textLinkTypography}
          />
        )}
        {dropArea && pickerVisibility && mediaType !== 'video' && mediaTypeState === 'image' && (
          <MImageCrop
            cropProps={EnumMedia.COURSES}
            onCancel={() => dispatch({ type: 'CLOSE_PICKER' })}
            onSave={handleChange}
            onImageSelection={noop}
            hideOverlay={hideModalOverlay}
          />
        )}
        {dropArea && pickerVisibility && mediaType !== 'image' && mediaTypeState === 'video' && (
          <MVideoUpload
            providers={media.providers}
            visible
            onSave={handleChange as (video: OnSaveVideoArgs) => void}
            onClose={() => dispatch({ type: 'CLOSE_PICKER' })}
            uploadThumbnail
          />
        )}
      </div>
    </div>
  );
};

export default UMediaPicker;
