import { mergeDeep } from 'Libs/mergeDeep';

import type { Action } from 'Libs/redux/types';
import type { DeepReadonly } from 'Libs/utils/types/object';

import { createOrderingCards, createOrderingCardsItem } from '../../redux/models/OrderingCards';
import createGameItem from '../../redux/models/GameItem';
import type { OrderingCardsType } from '../../redux/models/OrderingCards';

export type OrderingCardsState = DeepReadonly<OrderingCardsType>;

const defaultOrderingCards = createOrderingCards();

export const defaultState: OrderingCardsState = {
  ...defaultOrderingCards,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: OrderingCardsState = defaultState, action: Action): OrderingCardsState => {
  switch (action.type) {
    case 'CONTENT_OC_SET_QUESTION':
      return mergeDeep(state, { question: { data: action.question } });
    case 'CONTENT_OC_SET_TOP_LABEL':
      return mergeDeep(state, { topLabel: { data: action.topLabel } });
    case 'CONTENT_OC_SET_BOTTOM_LABEL':
      return mergeDeep(state, { bottomLabel: { data: action.bottomLabel } });
    case 'CONTENT_OC_SET_EXPLANATION':
      return mergeDeep(state, { explanation: { data: action.explanation } });
    case 'CONTENT_OC_ADD_ITEM': {
      const current = state.cards;
      const key = current.length > 0 ? Math.max(...current.map((x) => x.key)) + 1 : 0;
      const cardContent = createGameItem();
      const newItem = createOrderingCardsItem({
        id: 0,
        key,
        cardContent,
      });

      const newItems = current.concat(newItem);

      return mergeDeep(state, { cards: newItems });
    }
    case 'CONTENT_OC_REMOVE_ITEM': {
      const MIN_NB_CARDS = 3;

      if (state.cards.length <= MIN_NB_CARDS) {
        return state;
      }

      const newItems = state.cards.filter((x) => x.key !== action.key);

      return mergeDeep(state, { cards: newItems });
    }
    case 'CONTENT_OC_SET_ITEM_DATA': {
      const items = state.cards.map((x) =>
        x.key === action.key ? { ...x, cardContent: { ...x.cardContent, data: action.text } } : x,
      );

      return mergeDeep(state, { cards: items });
    }
    default:
      return state;
  }
};
