import type { CSSProperties } from 'react';

import { COLORS } from 'Components/foundation';

import type { InputStateStatus } from 'Hooks/useInputStatus/useInputStatus';

import type { IconName } from 'Components/foundation/icons';
import type { MessageStatus, ValueChangeFeedbackStatus } from './SInputContainer';

import styles from './SInputContainer.style';

type StatusIcon = {
  name: IconName;
  color: string;
};

export const ICON_BY_STATUS: Record<MessageStatus, StatusIcon> = {
  success: {
    name: 'success-circle',
    color: COLORS.SUCCESS.DEFAULT,
  },
  error: {
    name: 'error',
    color: COLORS.ERROR.DARK,
  },
  warning: {
    name: 'alert',
    color: COLORS.WARNING.DEFAULT,
  },
};

const STYLE_BY_INPUT_STATUS = {
  focused: styles.focusedInput,
  disabled: styles.disabled,
  hovered: styles.hoveredInput,
  errored: styles.hoveredInput,
  none: {},
} as const;

export const getInputStateStyle = (inputStateStatus: InputStateStatus, ghost: boolean) => {
  const specificStyle: CSSProperties = STYLE_BY_INPUT_STATUS[inputStateStatus];

  return !ghost ? { ...styles.inputWrapper, ...specificStyle } : { ...styles.ghostInputWrapper, ...specificStyle };
};

export const getTextStyle = (valueChangeFeedbackStatus: ValueChangeFeedbackStatus) => {
  switch (valueChangeFeedbackStatus) {
    case 'error':
      return { color: COLORS.ERROR.DARK };
    case 'success':
      return { color: COLORS.SUCCESS.DEFAULT };
    case 'loading':
      return { color: COLORS.TEXT.SECONDARY_DEFAULT };
    case 'warning':
      return { color: COLORS.WARNING.DEFAULT };
    default:
      return { color: COLORS.TEXT.SECONDARY_DEFAULT };
  }
};
