import { fetchFactory } from 'Libs/http/utils';

export type AudienceGroupMeta = {
  currentSequence: number;
};

export type GetAudienceGroupMetaFn = () => Promise<AudienceGroupMeta>;

export const getAudienceGroupMeta: GetAudienceGroupMetaFn = async () => {
  const api = fetchFactory<AudienceGroupMeta>({
    route: '/api/audiences/groups/meta',
    method: 'GET',
  });

  return api.fetchFn();
};
