import Model from 'models/Model';
import Enum from 'models/Enum';

var OrderingCards = {
  endpoint  : 'api/OrderingCards',
  attributes: {
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    topLabel: {
      type : 'pointer',
      Model: 'GameItem',
    },
    bottomLabel: {
      type : 'pointer',
      Model: 'GameItem',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    cards: {
      type : 'collection',
      Model: 'OrderingCardsItem',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_OrderingCards, toCompare) {
      const jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;
      const gisToCheck = [
        'question',
        'topLabel',
        'bottomLabel',
        'explanation',
      ];

      let clone = this.clone();

      clone = clone.toJSON();

      let isEqual = isGameItemEqual();

      if (isEqual)
        isEqual = JSON.stringify(jsonToCompare.cards) === JSON.stringify(clone.cards);

      function isGameItemEqual() {
        for (const giKey of gisToCheck) {
          if (clone[giKey].data !== jsonToCompare[giKey].data)
            return false;
        }

        return true;
      }

      return isEqual;
    },
    sortCards: function sortCards() {
      this.cards.sort((cardA, cardB) => cardA.order - cardB.order);

      return this;
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_OrderingCards, knowledgeId) {
      return {
        question: {
          typeId     : Enum.gameItemTypes.QUESTION,
          knowledgeId: knowledgeId,
        },
        topLabel: {
          typeId     : Enum.gameItemTypes.SHORT,
          knowledgeId: knowledgeId,
        },
        bottomLabel: {
          typeId     : Enum.gameItemTypes.SHORT,
          knowledgeId: knowledgeId,
        },
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
};

export default Model.register('OrderingCards', OrderingCards);
