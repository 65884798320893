import { mergeDeep } from 'Libs/mergeDeep';

import { MESSAGE_TYPES } from 'Components/utils/Enum';

import Enum from 'Models/Enum';
import { getCdnFromS3Url } from 'Libs/cdn';
import { AudienceTargetLabelIdType } from 'Libs/ts/types';

export type SingleTargetType = Readonly<{
  players: ReadonlyArray<any>;
  total: number;
  numTargetedPlayers: number;
  registered: number;
  loading: boolean;
  deleteError: boolean;
  needsRefresh: boolean;
}>;

export type Segmentation = Readonly<{
  id: number;
  label: string;
  color: string;
  dimId: number;
  dimType: number;
}>;

export type TargetPlayerId = number | string;
export type TargetPlayerStatus = boolean | string;
export type SegmentForTable = Readonly<{
  value: string;
  color: string;
}>;

export type TargetPlayer = Readonly<{
  id: TargetPlayerId;
  firstName: string;
  lastName: string;
  email: string;
  status: TargetPlayerStatus;
  createdAt: string;
  globalScore: number;
  extraInfo: string;
  segmentation: ReadonlyArray<Segmentation>;
  profilePicture: string;
  warning: boolean;
}>;

export type AudienceTargetType = Readonly<{
  MAGIC_CODE: SingleTargetType;
  EMAIL_LIST: SingleTargetType;
  SEGMENTATION: SingleTargetType;
}>;

function sortDimension(a: Segmentation, b: Segmentation) {
  if (a.dimId > b.dimId) return 1;
  if (a.dimId < b.dimId) return -1;
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;

  return 0;
}

function getSegment(segment: Segmentation): SegmentForTable {
  return {
    value: segment.label,
    color: segment.color,
  };
}

function getRowMessage(player: TargetPlayer) {
  if (!player.warning) {
    return null;
  }

  return {
    content: 'Targeting mismatch (language), this player will not be targeted by this campaign.',
    type: MESSAGE_TYPES.WARNING,
  };
}

export const createPlayer = function createPlayer(player: TargetPlayer) {
  const rowPlayer = { ...player };

  // Unregistered players
  if (!player.id) {
    rowPlayer.id = player.email;
    rowPlayer.status = false;
    rowPlayer.segmentation = [];
  } else {
    // Format date
    const date = new Date(player.createdAt);
    const [createdAt] = date.toISOString().split('T');

    rowPlayer.createdAt = createdAt;
  }

  if (rowPlayer.profilePicture) {
    rowPlayer.profilePicture = getCdnFromS3Url(rowPlayer.profilePicture, { transformation: { width: 28, height: 28 } });
  }

  // Sort segmentation by dimension order and label
  if (rowPlayer.segmentation.length > 0) {
    rowPlayer.segmentation = [...rowPlayer.segmentation].sort(sortDimension);
  }

  return {
    id: rowPlayer.id,
    dataAccessor: rowPlayer.email,
    rowMessage: getRowMessage(rowPlayer),
    profilePicture: {
      id: `${rowPlayer.id}_profilePicture`,
      type: 'UTableCellProfilePicture',
      params: {
        picture: rowPlayer.profilePicture || '',
      },
      wrapperStyle: {
        display: 'flex',
        flex: 1,
      },
    },
    lastName: {
      id: `${rowPlayer.id}_lastName`,
      type: 'UTableCellUser',
      params: {
        name: `${rowPlayer.lastName || ''} ${(rowPlayer.firstName || '').trim()}`,
        email: rowPlayer.email,
        isValidated: rowPlayer.status === 'active',
      },
    },
    createdAt: {
      id: `${rowPlayer.id}_createdAt`,
      type: 'UTableCellText',
      params: {
        value: rowPlayer.createdAt || '',
        type: 'date',
      },
      wrapperStyle: {
        display: 'flex',
        flex: 1,
      },
    },
    segmentation: {
      id: `${rowPlayer.id}_segmentation`,
      type: 'UTableCellSegment',
      params: {
        items: rowPlayer.segmentation.map<SegmentForTable>(getSegment),
      },
    },
  };
};

function createTarget(values?: any) {
  const defaultState = {
    players: [],
    total: 0,
    numTargetedPlayers: 0,
    registered: 0,
    loading: false,
    deleteError: false,
    needsRefresh: false,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep(defaultState, values);
}

export default function createAudienceTarget(values?: any): AudienceTargetType {
  const defaultTarget: SingleTargetType = createTarget();

  const defaultState: AudienceTargetType = {
    MAGIC_CODE: defaultTarget,
    EMAIL_LIST: defaultTarget,
    SEGMENTATION: defaultTarget,
  };

  if (!values) {
    return defaultState;
  }

  const targetModeId = values.targetModeId as AudienceTargetLabelIdType;

  return mergeDeep(defaultState, {
    [Enum.audienceTargetLabels[targetModeId]]: createTarget(values),
  });
}
