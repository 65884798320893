import React from 'react';

import { PdfBlockForCourse, useExtractPdfPages } from 'Libs/pdf-extraction/pdf-to-course';

import { useModuleSelector } from 'Pages/Courses/redux';
import SPDFBlocksSelector from 'Components/structural/SPDFBlocksSelector/SPDFBlocksSelector';

import styles from './PDFViewer.styles';

type PdfViewerProps = {
  handleBlockSelect: (block: PdfBlockForCourse) => void;
};

const PDFViewer = ({ handleBlockSelect }: PdfViewerProps) => {
  const pages = useModuleSelector((state) => state.edition.pdfPages);

  const { extractedPages } = useExtractPdfPages({ pages });

  return (
    <div style={styles.container}>
      <SPDFBlocksSelector pdfPages={extractedPages} handleBlockSelect={handleBlockSelect} />
    </div>
  );
};

export default PDFViewer;
