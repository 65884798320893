// @flow

/**
 * Display a card to preview an ordering cards gameplay
 *
 * Props:
 * - instruction: the ordering cards instruction to display on top of all items
 * - cards: ordering cards items to display
 * - onClick: callback triggered when we click on the preview
 */

import * as React from 'react';

import styles from './SOrderingCardsPreview.style';

type OCPreviewItem = {|
  id: string,
  content: string,
|};

type Props = {|

  // required
  instruction: string,
  cards: Array<OCPreviewItem>,

  // optional
  onClick?: () => mixed,
  style?: Object,
|};

const ITEMS_TO_DISPLAY = 3;
const REG_REMOVE_QUOTE = /(^("|')|("|')$)/g;

export class SOrderingCardsPreview extends React.PureComponent<Props> {
  static defaultProps = {
    onClick: undefined,
    style: undefined,
  };

  render() {
    const { onClick, style } = this.props;

    return (
      <div
        data-testid='ORDERING_CARDS_PREVIEW_CONTAINER'
        onClick={onClick}
        style={{
          ...styles.wrapper,
          ...style,
        }}
      >
        { this.renderInstruction() }
        { this.renderItems() }
      </div>
    );
  }

  renderInstruction = () => {
    const { instruction } = this.props;
    const instructionWithQuote = instruction.replace(REG_REMOVE_QUOTE, '');

    return (
      <div style={styles.instruction}>
        { `"${instructionWithQuote}"` }
      </div>
    );
  };

  renderItems = () => {
    const { cards } = this.props;
    const cardsToRender = this.getCards();

    return (
      <div style={styles.cardsContainer}>
        { cardsToRender }
        {
          cards.length > ITEMS_TO_DISPLAY
            ? <div style={styles.cardPlaceholder} />
            : null
        }
      </div>
    );
  };

  getCards = (): Array<React.Element<'div'>> => {
    const { cards } = this.props;

    return cards.slice(0, ITEMS_TO_DISPLAY).map((card: OCPreviewItem) => {
      const { id, content } = card;

      return (
        <div key={id} style={styles.card}>
          { content }
        </div>
      );
    });
  };
}

export default SOrderingCardsPreview;
