import { t } from 'i18next';
import { ValueOf } from 'ts-essentials';

import { GAMEPLAY_TYPES } from 'Components/utils/Enum';

import { ContentFilters, ContentState } from './content.types';

export const CONTENT_STATUS = {
  ARCHIVED: 'archived',
  VALIDATED: 'validated',
  DRAFT: 'draft',
  WAITING_TRANSLATION: 'waiting-translation',
  WAITING_VALIDATION: 'waiting-validation',
} as const;

export const CONTENT_STATUS_ID_MAP = Object.values(CONTENT_STATUS).reduce((map, value, index) => {
  map[value] = index;
  return map;
}, {} as Record<ValueOf<typeof CONTENT_STATUS>, number>);

export const CONTENT_ID_STATUS_MAP = Object.values(CONTENT_STATUS).reduce((map, value, index) => {
  map[index] = value;
  return map;
}, {} as Record<number, ValueOf<typeof CONTENT_STATUS>>);

export const getIndicatorText = (status: typeof CONTENT_STATUS[keyof typeof CONTENT_STATUS]): string => {
  switch (status) {
    case CONTENT_STATUS.WAITING_TRANSLATION:
      return t('contents:status.pending_translation');
    case CONTENT_STATUS.WAITING_VALIDATION:
      return t('contents:status.require_validation');
    case CONTENT_STATUS.DRAFT:
      return t('contents:status.draft');
    default:
      return '';
  }
};

const preEncodeArray = (arrayFilter: ReadonlyArray<string>): string => {
  return arrayFilter.toString();
};

export type ContentQueryFilters = {
  status?: Readonly<ValueOf<typeof CONTENT_STATUS>>;
  archived?: Readonly<'true' | 'false'>;
  languageCode?: Readonly<string>;
  thematicId?: Readonly<string>;
  name?: Readonly<string>;
};

const buildStatus = (selected: ContentFilters['selectedContentStatuses']) => {
  if (selected.length === 0) {
    return;
  }
  const [status] = selected;
  if (status === CONTENT_STATUS.ARCHIVED) {
    return;
  }
  return status;
};

const buildArchived = (selected: ContentFilters['selectedContentStatuses']) => {
  if (selected.length === 0) {
    return 'false';
  }
  const [status] = selected;
  if (status === CONTENT_STATUS.ARCHIVED) {
    return 'true';
  }
  return 'false';
};

const buildLanguageCodes = (selected: ContentFilters['selectedLanguageCodes']) => {
  if (selected.length === 0) {
    return;
  }
  return preEncodeArray(selected);
};

const buildThematic = (selected: ContentFilters['selectedThematicIds']) => {
  if (selected.length === 0) {
    return;
  }
  return selected[0].toString();
};

const buildName = (selected: ContentFilters['selectedTexts']) => {
  if (selected.length === 0) {
    return;
  }
  return preEncodeArray(selected);
};

export const buildQueryFilterFromFilters = (filters: ContentState['filters']): ContentQueryFilters => {
  const { selectedContentStatuses, selectedLanguageCodes, selectedThematicIds, selectedTexts } = filters;
  const queryFilter: ContentQueryFilters = {
    status: buildStatus(selectedContentStatuses),
    archived: buildArchived(selectedContentStatuses),
    languageCode: buildLanguageCodes(selectedLanguageCodes),
    thematicId: buildThematic(selectedThematicIds),
    name: buildName(selectedTexts),
  };

  const queryFilterKeys = Object.keys(queryFilter) as (keyof typeof queryFilter)[];
  queryFilterKeys.forEach((key) => queryFilter[key] === undefined && delete queryFilter[key]);

  return queryFilter;
};
