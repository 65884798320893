/* eslint-disable complexity */
import m from 'm';
import KEY from 'keys';
import Enum from 'models/Enum';
import reactCreator from 'components/react.creator';
import { COLORS } from 'Components/foundation';

var component = {};

var nextTooltipId = 0;

component.controller = function controller(args) {
  var self = this;

  var gi = args.gameItem();
  var maxFieldSize = args.maxFieldSize || gi.getSizeLimitation();

  self.tooltipId = `input-game-item-tooltip-id-${nextTooltipId++}`;

  if (gi.data())
    gi.data(gi.data().substr(0, maxFieldSize));

  self.config = function config(_args, elem, isInit, ctx) {
    if (isInit && args.alwaysResize) {
      resize(_args, {
        target: elem,
      });
    }

    if (isInit)
      return;

    setTimeout(resize.bind(null, args, {
      target: elem,
    }));
    window.addEventListener('resize', resizeBindedGlobal, false);

    ctx.onunload = function removeListeners() {
      window.removeEventListener('resize', resizeBindedGlobal, false);
    };

    function resizeBindedGlobal() {
      resize(args, {
        target: elem,
      });
    }
  };

  self.onkeydown = function onkeydown(_args, e) {
    if (!_args.editable) {
      e.preventDefault();

      return false;
    }

    if (e.keyCode === KEY.ENTER && !_args.supportEnter) {
      var inputs = document.getElementsByClassName('input-game-item__input');
      var same = false;

      e.preventDefault();
      e.srcElement.blur();
      for (var i = 0; i < inputs.length; i++) {
        if (e.srcElement.isSameNode(inputs[i]))
          same = true;
        else if (same) {
          inputs[i].focus();
          break;
        }
      }

      return false;
    }

    return true;
  };

  self.oninput = function oninput(_args, e) {
    if (!_args.editable) {
      e.preventDefault();

      return;
    }
    _args.gameItem().data(e.target.value);

    if (!args.alwaysResize)
      resize(_args, e);
  };

  self.checkInputContent = function checkInputContent(_args, e) {
    var value = e.target.value.trim();

    _args.gameItem().data(value);
    setTimeout(resize.bind(null, _args, e));
  };

  self.renderMaxLength = function renderMaxLength(currentSize) {
    var text = `${currentSize} / ${maxFieldSize}`;
    var counterClass = [
      args.format === Enum.QCMFormat.twoSBS ? 'input-game-item__counter--twoSBS' : '',
      args.counterClass || 'input-game-item__counter',
    ].join(' ');

    if (!args.warningMax || currentSize <= args.warningMax) {
      return m('div', {
        class: counterClass,
      }, m('div', { class: !args.counterClass ? 'input-game-item__counter__txt' : '' }, text));
    }

    var infoIconWithTooltip = m(`.input-game-item__maxlength__icon-container[data-tip][data-for=${self.tooltipId}]`, [
      m(reactCreator, {
        component: 'UIcon',
        props    : {
          name : 'info-what',
          color: COLORS.WARNING.DEFAULT,
          size : 16,
        },
        style: {

          // INFO to center vertically
          display: 'flex',
          marginLeft: '8px',
        },
      }),
      m(reactCreator, {
        component: 'STooltip',
        props    : {
          id : self.tooltipId,
          position: 'left',
          content : args.warningText,
        },
      }),
    ]);

    return m('.input-game-item__maxlength--warning', {
      class:  counterClass,
    }, [
      text,
      infoIconWithTooltip,
    ]);
  };

  function resize(_args, e) {
    const elem = e.target;
    const dim = _args.stickToWidth ? 'width' : 'height';
    const scrollDim = _args.stickToWidth ? 'scrollWidth' : 'scrollHeight';

    if (!_args.stickToWidth || _args.gameItem().data()) {
      /*
      ** The following is a trick that was used to implement auto-resize on textarea.
      ** by setting the elem height to 0px it forces an update of its
      ** scrollHeight (the height above which there is no need for scrolling)
      ** Then this value is used as the actual height, sadly this
      ** can result in focus jumping when the elem is set at 0px
      ** preventShrink will fix that issue at the cost of no longer shrinker automaticaly
      */
      if (!_args.preventShrink)
        elem.style[dim] = '0px';

      elem.style[dim] = `${elem[scrollDim]}px`;
    }

    if (_args.stickToWidth) {
      if (_args.gameItem().data())
        elem.removeAttribute('size');
      else
        elem.size = (_args.placeholder || '').length + 1;
    }
  }
};

component.view = function view(c, args) {
  var gi = args.gameItem();
  var tag = args.stickToWidth ? 'input' : 'textarea';
  var maxFieldSize = args.maxFieldSize || gi.getSizeLimitation();
  var text = gi.data() ? gi.data().substr(0, maxFieldSize) : '';

  return m('div', {
    class: args.containerClass || 'input-game-item',
  }, [
    m(tag, {
      config     : c.config.bind(null, args),
      placeholder: args.placeholder || '',
      value      : gi.data() ? gi.data().substr(0, maxFieldSize) : '',
      oninput    : c.oninput.bind(null, args),
      onkeydown  : c.onkeydown.bind(null, args),
      maxlength  : maxFieldSize,
      disabled   : !args.editable,
      onblur     : c.checkInputContent.bind(null, args),
      class      : args.inputClass || 'input-game-item__input',
    }),
    c.renderMaxLength(text.length),
  ]);
};

export default component;
