// @flow

import { StyleSheet } from 'aphrodite';

import { ANIMATIONS } from 'Components/foundation';


const translateYKeyFrame = ANIMATIONS.MOVEMENTS.APPEAR_FROM_BOTTOM;
const fadeInKeyFrame = ANIMATIONS.TRANSFORMATIONS.FADE_IN;

export default StyleSheet.create({
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
  },
  wrapperAlert: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99,
    position: 'absolute',
    top: 16,
    right: 16,
  },
  containerAlert: {
    maxHeight: 200,
    marginBottom: 10,
  },
  containerAlertHide: {
    opacity: 0,
    marginBottom: 0,
    maxHeight: 0,
    bottom: 0,
    transition: ANIMATIONS.SPEED.SLOW,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },

  // Animation
  containerAlertDisplay: {
    animationName: [fadeInKeyFrame, translateYKeyFrame],
    animationDuration: ANIMATIONS.SPEED.SLOW,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },
});
