import m from 'm';
import { t } from 'i18next';

import moment from 'moment';
import User from 'services/userService';
import Enum from 'models/Enum';
import Model from 'models/Model';
import menu from 'pages/menu';
import analyticsMenu from 'components/analytics.menu';

const getReports = () => [
  {
    id: Enum.ReportsType.ACTIVE_CAMPAIGN_LEADERBOARDS,
    title: t('analytics:reports.active_campaign_leaderboards'),
    subtitle: t('analytics:reports.active_campaign_leaderboards_subtitle'),
    event: 'active-campaign-leaderboards',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN, Enum.Role.ANALYST],
  },
  {
    id: Enum.ReportsType.CONTENT_ANALYTICS,
    title: t('analytics:reports.content_analytics'),
    subtitle: t('analytics:reports.content_analytics_subtitle'),
    event: 'content-analytics',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN],
  },
  {
    id: Enum.ReportsType.CONTENT_ANALYTICS_PER_PLAYER,
    title: t('analytics:reports.content_analytics_per_player'),
    subtitle: t('analytics:reports.content_analytics_per_player_subtitle'),
    event: 'content-analytics-per-player',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN],
  },
  {
    id: Enum.ReportsType.BATTLE_ANALYTICS,
    title: t('analytics:reports.battle_analytics'),
    subtitle: t('analytics:reports.battle_analytics_subtitle'),
    event: 'battle-analytics',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN],
  },
  {
    id: Enum.ReportsType.INCOMPLETE_USERS,
    title: t('analytics:reports.incomplete_users'),
    subtitle: t('analytics:reports.incomplete_users_subtitle'),
    event: 'incomplete-users',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN],
  },
  {
    id: Enum.ReportsType.CONTENT_COMPLETION,
    title: t('analytics:reports.content_completion_per_player'),
    subtitle: t('analytics:reports.content_completion_per_player_subtitle'),
    event: 'content-completion-per-player',
    authorizedRoles: [Enum.Role.MASTER, Enum.Role.ADMIN],
  },
];

var component = {};

component.controller = function controller() {
  var self = this;
  var reports = getReports();

  var Analytics = Model('Analytics');
  var user = User.meSync();
  var startDate = moment().add(-1, 'months').format('YYYY-MM-DD');
  var endDate = moment().format('YYYY-MM-DD');

  self.reports = reports.reduce((availableReports, report) => {
    user.roles().forEach((role) => {
      if (role.id() === Enum.Role.ROOT || report.authorizedRoles.includes(role.id())) {
        report.startDate = m.prop(startDate);
        report.endDate = m.prop(endDate);
        report.loading = m.prop(false);

        availableReports[report.id] = report;
      }
    });

    return availableReports;
  }, {});

  self.onDateChange = function onDateChange(date, event) {
    date(moment(event.target.value).format('YYYY-MM-DD'));
  };

  self.downloadReport = function downloadReport(reportId) {
    var beginTime = new Date();
    var currentReport = self.reports[reportId];

    if (currentReport.loading()) return;

    currentReport.loading(true);

    Analytics.getReports(reportId, {
      startDate: currentReport.startDate(),
      endDate: currentReport.endDate(),
    }).then(handleLoading);

    function handleLoading() {
      var elapsedTime = moment.duration(moment().diff(beginTime));

      elapsedTime = elapsedTime.asMilliseconds();

      setTimeout(
        function waitBeforeLoadingEnd() {
          currentReport.loading(false);
          m.redraw();
        },
        elapsedTime > 1000 ? elapsedTime : 1000,
      );
    }
  };
};

component.view = function view(c) {
  return m('.analytics-page', [
    m('.analytics-page__menu', [m(menu), m(analyticsMenu)]),
    m('.analytics-page__body .analytics-page__body--reports', [
      m('.analytics__reports__container', [
        m('.analytics__reports__title', t('analytics:reports.reports_title')),
        m('.analytics__reports__subtitle', t('analytics:reports.download_personalized_reports')),
        m('.analytics__reports__body', [displayReports(c)]),
      ]),
    ]),
  ]);
};

function displayReports(c) {
  return Object.entries(c.reports || {}).map(([id, report]) => {
    return m('.analytics__reports__content', [
      m('.analytics__reports__content__title', report.title),
      m('.analytics__reports__content__subtitle', report.subtitle),
      m('.analytics__reports__content__date__container', [
        m('.analytics__reports__content__date', [
          m('.analytics__reports__content__date__label', t('analytics:reports.from')),
          m('input.analytics__reports__content__date__input', {
            type: 'date',
            value: report.startDate(),
            onchange: c.onDateChange.bind(null, report.startDate),
            max: report.endDate(),
          }),
        ]),
        m('.analytics__reports__content__date', [
          m('.analytics__reports__content__date__label', t('analytics:reports.to')),
          m('input.analytics__reports__content__date__input', {
            type: 'date',
            value: report.endDate(),
            onchange: c.onDateChange.bind(null, report.endDate),
            min: report.startDate(),
          }),
        ]),
      ]),
      m(
        '.analytics__reports__content__button',
        {
          onclick: c.downloadReport.bind(null, id),
        },
        [
          m.hide(!report.loading(), '.analytics__reports__content__button__loader'),
          m.hide(
            report.loading(),
            '.analytics__reports__content__button__label',
            {
              'data-tracking-action': 'export',
              'data-tracking-context': report.event,
            },
            t('analytics:reports.download'),
          ),
        ],
      ),
    ]);
  });
}

export default component;
