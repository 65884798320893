import { createStyleSheet } from 'Components/foundation/stylesheets';

import { COLORS, ELEVATIONS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 12;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

const USER_SELECT_NONE = {
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
} as const;

const styleSheet = createStyleSheet({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...BORDER_RADIUS,
    height: 206,
    width: 167,
    minWidth: 167,
    minHeight: 206,
    position: 'relative',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  headerContainer: {
    flexGrow: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: BORDER_RADIUS_VALUE,
    borderTopRightRadius: BORDER_RADIUS_VALUE,
    position: 'relative',
  },
  disabledOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: LEVELS.LOCAL_OFFSET_2,
    ...BORDER_RADIUS,
    backgroundColor: '#000000',
    opacity: 0.3,
    pointerEvents: 'none',
    ...USER_SELECT_NONE,
    textAlign: 'center',
  },
  alreadyUsedOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: LEVELS.LOCAL_OFFSET,
    ...BORDER_RADIUS,
    backgroundColor: COLORS.WHITE.DEFAULT,
    opacity: 0.5,
  },
  indicator: {
    position: 'absolute',
    zIndex: LEVELS.LOCAL_OFFSET,
    whiteSpace: 'nowrap',
    bottom: '50%',
    right: '50%',
    transform: 'translate(50%, 50%)',
  },
  statusIndicator: {
    marginLeft: 6,
  },
  hoverLayout: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderTopLeftRadius: BORDER_RADIUS_VALUE,
    borderTopRightRadius: BORDER_RADIUS_VALUE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  optionContainer: {
    paddingTop: 12,
    position: 'absolute',
    top: 0,
    right: -22,
    marginRight: 10,
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  selectorWrapper: {
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  optionsDropdownWrapper: {
    position: 'absolute',
    right: 8,
    top: 34,
    zIndex: LEVELS.LOCAL_OFFSET_3,
  },
  dropdownBox: {
    minWidth: 130,
  },
  previewContainer: {
    alignSelf: 'center',
    justifySelf: 'flex-end',
    marginBottom: 8,
    padding: 8,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.WHITE.DEFAULT,
    opacity: 0.5,
    ...USER_SELECT_NONE,
    cursor: 'pointer',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  previewHovered: {
    opacity: 1,
  },
  contentContainer: {
    padding: 10,
    height: 106,
    position: 'relative',
  },
  contentId: {
    marginBottom: 4,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
  },
  title: {
    marginBottom: 18,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    maxHeight: 36,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.DEFAULT,
    fontWeight: 400,
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 10,
    width: 'calc(100% - 20px)',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  locale: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
  },
  imageContainer: {
    ...BORDER_RADIUS,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

export const customBoxShadow = {
  locked: `0px 0px 0px 1px ${COLORS.TEXT.SECONDARY_DEFAULT}`,
  selected: `0px 0px 0px 2px ${COLORS.PRIMARY.DEFAULT}`,
  disabled: `0px 0px 0px 2px ${COLORS.ERROR.DEFAULT}`,
  hover: ELEVATIONS.LIGHT_HIGH,
};
export default styleSheet;
