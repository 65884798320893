import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  inputLabel: {
    ...TYPOGRAPHY.BODY3,
  },
  input: {
    paddingTop: 13,
  },
});
