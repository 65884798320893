import { t } from 'i18next';
import React, { useCallback } from 'react';
import { DeepPartial } from 'ts-essentials';

import { ErrorText, GAME_TYPE, LanguageCode } from '@sparted/shared-library/business/types';

import { VIDEO_PROVIDERS } from 'Components/modal/MVideoUpload/utils';
import SErrorTextAreas from 'Components/structural/SErrorTextAreas/SErrorTextAreas';
import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';
import STextArea from 'Components/structural/STextArea/STextArea';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker, { ShowableVideo, isShowableVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import Enum from 'Models/Enum';

import sharedStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';
import { BaseContentFormValues } from '../ContentEdition/ContentEdition';
import { useFormContext } from 'react-hook-form';

const TEXT_AREA_HEIGHT = '128';

type ErrorTextFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

const EXPLANATION_CHAR_LIMIT = 500;

export const ErrorTextForm = ({ language, isGameplayAlreadyChosen, editGameplay }: ErrorTextFormProps) => {
  const { watch, setValue } = useFormContext<BaseContentFormValues & { game: DeepPartial<ErrorText> }>();

  const onNewGameMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.gameMedia.video', newMedia.media);
      }
      return setValue('game.gameMedia.image', newMedia.media);
    },
    [setValue],
  );

  const onNewExplanationMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.explanationMedia.video', newMedia.media);
      }
      return setValue('game.explanationMedia.image', newMedia.media);
    },
    [setValue],
  );

  const onCorrectTextChange = useCallback(
    (text: string) => {
      setValue(`game.correctText.${language}`, text);
    },
    [setValue],
  );
  const onWrongTextChange = useCallback(
    (text: string) => {
      setValue(`game.wrongText.${language}`, text);
    },
    [setValue],
  );
  const onExplanationTextChange = useCallback(
    (text: string) => {
      setValue(`game.explanation.${language}`, text);
    },
    [setValue],
  );

  return (
    <div style={sharedStyles.wrapper}>
      <div style={sharedStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.ERROR_TEXT}
          title={t('gameplays:error_text.label')}
          description={t('gameplays:error_text.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />
        <div style={sharedStyles.section}>
          <ULabel style={sharedStyles.label}>{t('gameplays:image')}</ULabel>
          <div style={{ ...sharedStyles.imageTextSection, ...sharedStyles.sectionSeparation }}>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('game.gameMedia.image.url') || ''}
              video={
                isShowableVideo(watch('game.gameMedia.video'))
                  ? (watch('game.gameMedia.video') as ShowableVideo)
                  : undefined
              }
              media={{
                mediaType: 'imageOrVideo',
                onChange: onNewGameMedia,
                providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
              }}
              onClearMedia={() => {
                setValue('game.gameMedia', {});
              }}
            />
          </div>
        </div>
        <div style={sharedStyles.section}>
          <ULabel style={sharedStyles.label}>{t('gameplays:error_text.label')}</ULabel>
          <div style={sharedStyles.titleSectionSeparation}>
            <SErrorTextAreas
              correctValue={watch(`game.correctText.${language}`)}
              wrongValue={watch(`game.wrongText.${language}`)}
              onChangeCorrect={onCorrectTextChange}
              onChangeWrong={onWrongTextChange}
            />
          </div>
        </div>
        <div style={sharedStyles.section}>
          <ULabel style={sharedStyles.label}>{t('gameplays:explanation')}</ULabel>
          <div style={{ ...sharedStyles.imageTextSection }}>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('game.explanationMedia.image.url') || ''}
              video={
                isShowableVideo(watch('game.explanationMedia.video'))
                  ? (watch('game.explanationMedia.video') as ShowableVideo)
                  : undefined
              }
              media={{
                mediaType: 'imageOrVideo',
                onChange: onNewExplanationMedia,
                providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
              }}
              onClearMedia={() => {
                setValue('game.explanationMedia', {});
              }}
            />
            <STextArea
              canResize={false}
              placeholder={t('gameplays:error_text.explanation_placeholder')}
              height={TEXT_AREA_HEIGHT}
              onChange={onExplanationTextChange}
              style={sharedStyles.imageTextArea}
              value={watch(`game.explanation.${language}`) || ''}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              maxLength={EXPLANATION_CHAR_LIMIT}
              textAreaTypo="BODY2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
