import { DndContext, DragOverlay } from '@dnd-kit/core';
import { createLinkedList } from '@sparted/shared-library/linked-list';
import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';

import { UDropBar } from 'Components/unit/UDropBar/UDropBar';
import { MicroKnowledgeVideo } from 'Pages/Courses/utils/mk-mapped-linked-list';
import type {
  MicroKnowledgeChapter,
  MicroKnowledgeItem,
  MicroKnowledgeListType,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import { HandleReorderMk } from 'Pages/Courses/utils/edition-handlers';

import { checkItemReachability, useMkDragAndDrop } from '../../hooks/useMkDragAndDrop';
import { MkItemWrapper } from './wrappers';
import styles from './MicroKnowledgeList.style';

export type MicroKnowledgesListProps = {
  microKnowledgeMap: MicroKnowledgeListType;

  // Handler passed to wrappers
  onToggleCollapse: ({ id }: { id: string }) => void;
  onInsert: ({ targetId }: { targetId: string }) => void;
  onTitleChange: (item: MicroKnowledgeItem) => void;
  onContentChange: (item: MicroKnowledgeItem) => void;
  onImageChange: (item: MicroKnowledgeItem) => void;
  onVideoChange: (item: MicroKnowledgeVideo) => void;

  // Options menu callbacks
  onDelete: ({ id }: { id: string }) => void;
  // eslint-disable-next-line react/no-unused-prop-types -- Will be used in the future
  onDuplicate: ({ id }: { id: string }) => void;
  onUpdateType: (item: MicroKnowledgeItem) => void;
  onReorder: (params: HandleReorderMk) => void;
};

/*
 * MicroKnowledgeList:
 * Component which displays all the MicroKnowledges
 * Props:
 * - microKnowledges: All the microKnowledges data
 * - onToggleCollapse: (The chevron icon) callback to modify the chapter's chevron
 * - onInsert: (The plus icon) callback to add a new SChapter or a SText
 * - onTitleChange: Callback to modify a mk's title,
 * - onContentChange: Callback to modify the Text's content,
 * - onUpdateType: Used for the option menu.
 * - onDelete: Used for the option menu.
 * - onDuplicate: Used for the option menu.
 */
export const MicroKnowledgeList = ({ microKnowledgeMap, onReorder, ...listeners }: MicroKnowledgesListProps) => {
  const { items, headId } = microKnowledgeMap;
  const { onToggleCollapse } = listeners;

  const microKnowledgeArray = useMemo(
    () => createLinkedList(headId, items).reduce((acc, item) => acc.concat(item), [] as MicroKnowledgeItem[]),
    [headId, items],
  );

  const {
    activeItem,
    overItem,
    dragDirection,
    attributes: dndAttributes,
    listeners: dndListeners,
  } = useMkDragAndDrop({ mkList: microKnowledgeMap, microKnowledgeArray, onReorder, onToggleCollapse });

  return (
    <DndContext {...dndAttributes} {...dndListeners}>
      {createPortal(
        <div style={styles.overlay}>
          <DragOverlay dropAnimation={null}>
            {activeItem && <MkItemWrapper item={activeItem} shouldHideChild={false} {...listeners} />}
          </DragOverlay>
        </div>,
        document.body,
      )}
      {microKnowledgeArray.map((item) => {
        const shouldHideChild = Boolean(
          item.chapterId && (items[item.chapterId] as MicroKnowledgeChapter)?.isCollapsed,
        );

        const isDropBarVisible =
          activeItem && checkItemReachability(items, activeItem.id, item.id) && overItem?.id === item.id;

        const isChildDropBar =
          item.chapterId !== null || (item.type === 'chapter' && !item.isCollapsed && dragDirection > 0);

        const dropBarStyle = { ...styles.dropBar, ...(isChildDropBar ? styles.childDropBar : undefined) };

        return (
          <div key={item.id} style={{ display: 'flex', flexDirection: 'column' }}>
            {isDropBarVisible && dragDirection < 0 && <UDropBar style={dropBarStyle} />}
            <MkItemWrapper item={item} shouldHideChild={shouldHideChild} {...listeners} />
            {isDropBarVisible && dragDirection > 0 && <UDropBar style={dropBarStyle} />}
          </div>
        );
      })}
    </DndContext>
  );
};

export default MicroKnowledgeList;
