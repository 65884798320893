/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import deviceScreen from 'components/device.screen';
import mediaSelector from 'components/content.media.selector';
import contentHeader from 'components/content.header';
import inputGameItem from 'components/input.game.item';
import toggleButton from 'components/toggle.button';
import EnumMedia from 'models/MediaHandlerEnum';

const component = {};
const cropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.NOINTERACTION_FRONT,
};
const secondCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.NOINTERACTION_BACK,
};

component.controller = function controller(args) {
  const self = this;
  const isEmpty = !args.content().game().longText().data();

  self.choicePage = m.prop(isEmpty);
  self.secondMedia = m.prop(args.content().game().mediaTypeBottomId());

  if (!args.knowledge().knowMoreId())
    self.choicePage(false);

  self.getAIConfig = function getAIConfig() {
    if (!args.aiEnabled())
      return null;

    const { game: nic, id } = args.content();

    return {
      targetTexts: [
        nic().longText,
      ],
      contentId: id,
    };
  };

  self.changePage = function choicePage(choice) {
    self.choicePage(choice);
    m.redraw();
  };

  self.setTextAndChangePage = function setTextAndChangePage(choice) {
    args.content().game().title().data(args.knowledge().title());
    args.content().game().longText().data(args.knowledge().knowMore().data());
    self.choicePage(choice);
    m.redraw();
    setTimeout(function test() {
      self.resizeNic(document.getElementsByClassName('nic__bottom')[0]);
      m.redraw();
    }, 1);
  };

  self.toggleSecondMedia = function toggleSecondMedia() {
    args.content().game().mediaTypeBottomId(0);
  };

  self.resizeNic = function resizeNic(elem) {
    if (elem) {
      const textarea = elem.getElementsByClassName('input-game-item__input')[1];
      const height = textarea.scrollHeight + 100;

      elem.style.height = height + 'px';
    }
  };
};

// eslint-disable-next-line complexity
component.view = function view(c, args) {
  const noInteractionContent = args.content().game();
  const emptyContent = !noInteractionContent.title().data() || !noInteractionContent.longText().data();
  const extendedClass = args.aiEnabled() ? '-with-ai' : '';

  return m('.content-edition', {
    config: c.configSize,
  }, [
    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:no_interaction_content.label'),
        gameplayName   : 'NO_INTERACTION_CONTENT',
        explanationText: args.content().getDirective(args.knowledge()),
      }),
      m('.content-edition__page-container.content-edition__page-container--nic', [
        m('.content-edition__page.content-edition__page--ghost', [
          m(deviceScreen, {}, []),
        ]),
        m('.content-edition__page.content-edition__page--ghost', [
          m(deviceScreen, {}, []),
        ]),
        m('.content-edition__page.content-edition__page--front.content-edition__page--nic', [
          m(deviceScreen, {}, [
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : noInteractionContent.imageTop,
              video    : noInteractionContent.videoTop,
              type     : noInteractionContent.mediaTypeTopId,
              class    : 'content-media-selector--nic' + extendedClass,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: cropProps,
              getAIConfig: c.getAIConfig,
            }),
            m('.nic__bottom', {
              class : c.secondMedia() ? 'nic__bottom--choice' : '',
              config: c.resizeNic(),
            }, [
              m.hide(!c.choicePage(), '.nic__bottom__choice__content', [
                m('button.nic__bottom__choice__content__button', {
                  disabled: !args.editable(),
                  onclick : c.changePage.bind(null, false),
                }, t('gameplays:no_interaction_content.text_placeholder')),
                m.hide(!args.knowledge().knowMore().data(), '.nic__bottom__choice__button__content__text', 'Or'),
                m.hide(!args.knowledge().knowMore().data(), 'button.nic__bottom__choice__content__button', {
                  onclick: c.setTextAndChangePage.bind(null, false),
                }, t('gameplays:no_interaction_content.know_more_placeholder')),
              ]),
              m.hide(c.choicePage(), '.nic__bottom__edit__content', [
                m('.nic__bottom__edit__content__title', [
                  m(inputGameItem, {
                    gameItem   : noInteractionContent.title,
                    placeholder: t('gameplays:no_interaction_content.title_placeholder'),
                    editable   : args.editable(),
                    class      : 'input-game-item__input--nic',
                    alwaysResize: true,
                  }),
                ]),
                m('.nic__bottom__edit__content__longText', [
                  m(inputGameItem, {
                    gameItem    : noInteractionContent.longText,
                    placeholder : t('gameplays:no_interaction_content.text_placeholder'),
                    editable    : args.editable(),
                    class       : 'input-game-item__input--nic',
                    supportEnter: true,
                    alwaysResize: true,
                    preventShrink: true,
                  }),
                ]),
                m(!emptyContent
                  ? 'button.content-edition__page__nic__button'
                  : 'button.content-edition__page__nic__button.content-edition__page__nic__button--hide',
                t('gameplays:no_interaction_content.got_it')),
              ]),
            ]),
            m.hide(!c.secondMedia(), '.nic__bottom__media', {
              class: !noInteractionContent.longText().data() ? 'nic__bottom__media--no-button' : '',
            }, [
              m(mediaSelector, {
                images   : args.knowledge().originalImages,
                image    : noInteractionContent.imageBottom,
                type     : noInteractionContent.mediaTypeBottomId,
                class    : 'content-media-selector--nic' + extendedClass,
                editable : args.editable(),
                knowledge: args.knowledge,
                cropProps: secondCropProps,
              }),
            ]),
          ]),
        ]),
      ]),
      m('.content-edition__commands.content-edition__commands--nic', [
        m(toggleButton, {
          disabled: !args.editable(),
          value   : c.secondMedia,
          onclick : c.toggleSecondMedia,
          class   : 'activity-info-toggle toggle__button__nic',
        }),
        m('.content-edition__page__nic__switch__label', t('gameplays:no_interaction_content.second_media')),
      ]),
    ]),
  ]);
};

export default component;
