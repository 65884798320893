// @flow

/**
 * Display a QR code depending of a string
 *
 * - qrContent: data contained in the QR code
 * - correctionLevel: @see Correction
 * - size: manage the QR code size
 * - title: title displayed above the QR code
 * - color: color of the QR code scheme
 * - backgroundColor: color behind the QR code scheme
 * - imageSettings: @see ImageSettings, available to display a picture in the foreground
 * - style: override base style
 */

import * as React from 'react';

import QRCode from 'qrcode.react';

import styles from './SQRCodeIndicator.style';

import { COLORS } from 'Components/foundation';

/*
* Manage scannability of a QR Code despite there being dirt or damage.
* @see https://scanova.io/blog/blog/2018/07/26/qr-code-error-correction/
*/
export type QRCodeCorrection = 'L' | 'M' | 'Q' | 'H';

/*
* Image settings Type
*
* - src: the url of the image
* - x: the image position on the abscissa, by default the package center
* - y: the image position on the ordinate, by default the package center
* - height: the image height, by default 10% of the QR code size
* - width: the image width, by default 10% of the QR code size
* - excavate: if true dig around the image, by default false
*/
export type QRCodeImageSettings = {|
  src:	string,
  x?:	number,
  y?:	number,
  height?:	number,
  width?: number,
  excavate?: boolean,
|};

type Props = {|
  qrContent: string,
  correctionLevel: QRCodeCorrection,
  size: number,
  title: ?string,
  color: string,
  backgroundColor: string,
  imageSettings: ?QRCodeImageSettings,
  style: Object,
|};

export class SQRCodeIndicator extends React.Component<Props> {
  static defaultProps = {
    correctionLevel: 'M',
    size: 180,
    title: undefined,
    color: COLORS.TEXT.DEFAULT,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    imageSettings: undefined,
    style: undefined,
  };

  render() {
    const { style, size } = this.props;
    const minWidth = size + styles.qrContainer.paddingLeft + styles.qrContainer.paddingRight;

    return (
      <div style={{ ...styles.wrapper, minWidth, ...style }}>
        { this.renderTitle() }
        { this.renderQRCode() }
      </div>
    );
  }

  renderTitle = () => {
    const { title } = this.props;

    if (!title)
      return null;

    return (
      <p style={styles.title}>
        { title }
      </p>
    );
  };

  renderQRCode = () => {
    const {
      title,
      size,
      qrContent,
      correctionLevel,
      color,
      backgroundColor,
      imageSettings,
    } = this.props;

    const qrCodeStyle = {};

    if (title)
      qrCodeStyle.paddingTop = 24;

    return (
      <div style={{ ...styles.qrContainer, ...qrCodeStyle }}>
        <QRCode
          value={qrContent}
          size={size}
          bgColor={backgroundColor}
          fgColor={color}
          level={correctionLevel}
          renderAs='svg'
          imageSettings={imageSettings}
        />
      </div>
    );
  };
}

export default SQRCodeIndicator;
