import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import { isFeatureEnabled } from 'Services/feature-flags';

export const TRANSLATION_FLAG = 'translated-bo';

export type I18nSupportedLanguages = Record<string, { label: string; countryCode: string }>;

export const i18nSupportedLanguages: I18nSupportedLanguages = {
  'en-US': {
    label: 'English',
    countryCode: 'us',
  },
  'fr-FR': {
    label: 'Français',
    countryCode: 'fr',
  },
};

export const initI18n = async () => {
  const i18nWithConfig = i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .use(
      resourcesToBackend((language: string, namespace: string) => {
        return import(`./locales/${language}/${namespace}.json`);
      }),
    );

  await i18nWithConfig.init({
    fallbackLng: 'en-US',
    lng: isFeatureEnabled(TRANSLATION_FLAG) ? undefined : 'en-US',
    debug: true,
    supportedLngs: Object.keys(i18nSupportedLanguages),
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    compatibilityJSON: 'v4',
  });

  await i18n.loadNamespaces([
    'activities',
    'analytics',
    'audiences',
    'analytics',
    'api-errors',
    'config_categories',
    'config_players',
    'config_contributors',
    'config_ambassadors',
    'contents',
    'courses',
    'documents',
    'gameplays',
    'home',
    'mithril_components',
    'structural_component',
    'unit_components',
    'content_translation',
    'modal_components',
    'thematic',
    'menu',
    'thats_all_folks',
    'welcome_card',
    'interseason',
    'breaking_news',
    'segmentation',
  ]);

  return i18n;
};

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default initI18n;
