import React from 'react';
import { t } from 'i18next';

import { SFooter } from 'Components/structural/SFooter/SFooter';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import { useAppDispatch } from 'Libs/redux/store';
import { fetchNextActivityPage } from 'Store/entities/activity/activity.thunks';
import { useTypedSelector } from 'Libs/redux/utils';

export const LoadMore = () => {
  const dispatch = useAppDispatch();

  const { next, loading } = useTypedSelector((state) => state.entities.activity.search);

  const onClick = () => {
    if (!next || loading) return;

    dispatch(fetchNextActivityPage(next));
  };

  if (!next) {
    return null;
  }

  return (
    <SFooter>
      <UTextLink text={t('activities:list.load_more')} onClick={onClick} type="secondary" rightIcon="bottom-chevron" />
    </SFooter>
  );
};
