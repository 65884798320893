// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  globalModal: {
    height: 559,
    margin: 'auto',
    overflow: 'unset',
  },
  body: {
    overflow: 'unset',
  },
  wrapperModalContent: {
    display: 'flex',
    borderTop: `${COLORS.GREY_MEDIUM.DEFAULT} 1px solid`,
    borderBottom: `${COLORS.GREY_MEDIUM.DEFAULT} 1px solid`,
    height: '100%',
  },
  wrapperModalContentNoBorders: {
    display: 'flex',
    height: '100%',
  },
  tabPanel: {
    minWidth: 222,
    paddingTop: 17,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
  },
  tabText: {
    margin: '0 0 8px 20px',
    ...TYPOGRAPHY.BODY4,
    display: 'inline-block',
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  contentPanel: {
    padding: '8px 16px 8px 48px',
    display: 'flex',
  },
  title: {
    ...TYPOGRAPHY.H1,
    marginBottom: 26,
    marginTop: 24,
  },
  description: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  picture: {
    width: 257,
    height: 363,
  },
  contentText: {
    marginRight: 16,
  },
};

