type Column = {
  width?: string;
};

type Instance = {
  getHooks: () => {
    getRowProps: any[];
    getHeaderGroupProps: any[];
  };
  columns: Column[];
};

const useInstance = (instance: Instance) => {
  const { getRowProps, getHeaderGroupProps } = instance.getHooks();

  const { columns } = instance;
  const widths: string[] = [];

  const isGoodWidthFormat = (columnWidth: string) => {
    if (typeof columnWidth !== 'string') return false;

    const widthTrimmed = columnWidth.trim();
    const hasPx = widthTrimmed.includes('px');
    const hasPct = widthTrimmed.includes('%');

    return hasPx || hasPct;
  };

  columns.forEach((column) => {
    const width = column.width && isGoodWidthFormat(column.width) ? column.width : '1fr';

    widths.push(width);
  });

  const rowStyles = {
    style: {
      display: 'grid',
      gridTemplateColumns: widths.join(' '),
    },
  };

  getRowProps.push(() => rowStyles);
  getHeaderGroupProps.push(() => rowStyles);
};

export const useGridLayout = (hooks: any) => {
  hooks.useInstance.push(useInstance);
};

useGridLayout.pluginName = 'useGridLayout';
