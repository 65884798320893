// @flow

import { ImageHandler } from '../../flowTypes';
import type { ImageBody, ImageSourceParams, ImageHandleArgs } from '../../flowTypes';

const CSE_ID = '011790101795153574935:mowzmvtqfny';
const API_KEY = 'AIzaSyAQRf0u4Vj-zc0En1F0Dj9ZFZ9GKY3vv9A';
const SEARCH_URL = 'https://www.googleapis.com/customsearch/v1?';

const RANDOM_SEARCH = 'aesthetic photography';

export class Google implements ImageHandler {
  fetchImages = ({ keyword, page, params }: ImageHandleArgs): Promise<Array<ImageBody>> => {
    const terms = keyword.split(',');

    // Handle case of multiple keywords are passed to search engine
    if (terms.length > 1)
      keyword = terms.map((s) => s.trim()).join(' AND ');

    const url = this._getQuery(keyword, page, params);

    return fetch(url)
      .then((res) => res.json())
      .then(this._getExpectedObjects)
      .catch(this._handleError);
  }

  getRandomImages = (): Promise<Array<ImageBody>> => {
    return this.fetchImages({ keyword: RANDOM_SEARCH, page: 1 });
  }

  sendDownloadData = (): Promise<any> => {
    return Promise.resolve();
  }

  getSourceName = () => {
    return 'Google';
  }

  _getExpectedObjects = (res: Object): Array<ImageBody> => {
    return res.items
      .filter((elem, index, self) => self.findIndex((img) => img.title === elem.title) === index)
      .map(this._getRelevantKeys);
  }

  _getRelevantKeys = (result: Object): ImageBody => {
    return {
      title: result.title || '',
      user : null,
      urls : {
        small: result.image.thumbnailLink,
        big  : result.link,
      },
      size: {
        width : result.image.width,
        height: result.image.height,
      },
    };
  }

  _getQuery = (query: string, start: number, params?: ImageSourceParams): string => {
    let options = '';
    const nbResultPerPage = 10;

    options += `cx=${CSE_ID}`;
    options += `&key=${API_KEY}`;
    options += `&q=${query || RANDOM_SEARCH}`;
    options += `&start=${(start - 1) * nbResultPerPage + 1}`;
    options += '&searchType=image';

    const googleParams = params && params[this.getSourceName()];

    if (googleParams) {
      const paramKeys = Object.keys(googleParams);

      paramKeys.forEach((key) => {
        // $FlowIssue flow limitation, it doesn't understand that 'key' is not undefined here
        options += `&${key}=${googleParams[key]}`;
      });
    }

    return SEARCH_URL + options;
  }

  _handleError = () => {
    return [];
  }
}

export default Google;
