import m from 'mithril';
import { t } from 'i18next';

import browser from 'libs/browserDetect';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.focused = m.prop(false);
  self.inputTypeDate = m.prop(!(browser.name.toLowerCase() === 'firefox' && parseInt(browser.version) < 57));
  self.invalidText = m.prop(null);

  function parseDate(date) {
    var splitted = date.split('/');

    if (splitted.length != 3)
      return null;

    var day = parseInt(splitted[0]);
    var month = parseInt(splitted[1]);
    var year = parseInt(splitted[2]);

    // if any is true
    if ([
      day != splitted[0],
      month != splitted[1],
      year != splitted[2],
      day <= 0 || day > 31,
      month <= 0 || month > 12,
      splitted[2].length != 4,
    ].indexOf(true) >= 0)
      return null;

    return new Date(Date.UTC(year, month - 1, day));
  }

  self.formatDate = function formatDate(date) {
    if (!date)
      return '';

    var day = date.getUTCDate().toString().padStart(2, '0');
    var month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    var year = date.getUTCFullYear().toString().padStart(4, '0');

    if (self.inputTypeDate())
      return [year, month, day].join('-');

    return [day, month, year].join('/');
  };

  self.changeDate = function changeDate(e) {
    var text = e.target.value;

    if (text === '' && args.acceptEmpty) {
      self.invalidText(null);
      args.value(null);

      if (args.onchange)
        args.onchange();

      return;
    }

    var date = self.inputTypeDate() ? new Date(text) : parseDate(text);

    if (!date || isNaN(date.getTime())) {
      self.invalidText(text);

      return;
    }

    self.invalidText(null);
    args.value(date);

    if (args.onchange)
      args.onchange();
  };
};

component.view = function view(c, args) {
  var text = c.invalidText();
  var isValid = text === null;

  if (isValid || c.inputTypeDate())
    text = c.formatDate(args.value());

  return m('.input-date', [
    m('.input-date-title', args.label),
    m('.input-date-entry', {
      class: [!args.value() && !c.focused() ? 'input-date-entry--icon' : '', !isValid ? 'input-date--invalid' : ''].join(' '),
    }, [
      m('input.input-date-enty__input', {
        type       : c.inputTypeDate() && (args.value() || c.focused()) ? 'date' : 'text',
        value      : text,
        oninput    : c.inputTypeDate() && c.changeDate,
        onchange   : !c.inputTypeDate() && c.changeDate,
        placeholder: args.placeholder || 'dd/mm/yyyy',
        onfocus    : function setTypeToDate() {
          this.type = 'date';
          c.focused(true);
        },
        onblur: function resetType() {
          c.focused(false);

          if (!args.value())
            this.type = 'text';
        },
      }),
      m.hide(isValid, '.input-date-entry__error', t('mithril_components:input_date.invalid_date')),
    ]),
  ]);
};
export default component;
