/* eslint-disable complexity, no-magic-numbers */

/*
 * ARGS:
 * images
 * image
 * type
 * actionAddPicture
 */

import m from 'm';
import Enum from 'models/Enum';

// default value in device.screen.js
const SCREEN_RATIO = 1 / 1.60;

// use picture spot ratio of the wider device
// TODO extract this value from MediaHandlerEnum when it becomes available
var MAX_PICTURE_SPOT_RATIO = 320/405;

var component = {};

component.controller = function controller() {
  var self = this;

  self.checkExistingImage = function checkExistingImage(args) {
    return (args.image && args.type() === Enum.mediaType.Image && args.image().id());
  };
};

// eslint-disable-next-line complexity
component.view = function view(c, args) {
  var checkExistingImage = c.checkExistingImage(args);

  // stop at top of question container
  var height = 0.82;

  var overlayBarHeight = (height - SCREEN_RATIO / MAX_PICTURE_SPOT_RATIO) / 2;

  return m('.content-media-selector', {
    class: args.minimize ? '' : 'content-media-selector--picture-spot',
  }, [
    m('.content-media-selector__display', {
      class: args.minimize ? '' : 'content-media-selector__display--picture-spot',
      style: {
        height         : `${height * 100}%`,
        backgroundImage: 'url(' + args.image().cdn() + ')',
      },
    }, checkExistingImage ? [
      m('.content-media-selector__display--picture-spot__overlay_bar', {
        style: {
          top   : 0,
          height: `${overlayBarHeight * 100}%`,
        },
      }),
      m('.content-media-selector__display--picture-spot__overlay_bar', {
        style: {
          bottom: 0,
          height: `${overlayBarHeight * 100}%`,
        },
      }),
    ] : []),
    m('.content-media-selector__selection', {
      class: [
        'content-media-selector--picture-spot__selection',
        checkExistingImage ? '' : 'content-media-selector__selection--empty',
      ].join(' '),
    }, [
      m('.content-media-selector__selection__instruction', [
        m('.content-media-selector__selection__instruction__txt', checkExistingImage
          ? 'CHANGE'
          : 'ADD'),
      ]),
      m('.content-media-selector__selection__action', {
        class  : 'content-media-selector__selection__action--image',
        onclick: args.onImageEdit,
      }, [
        m('.content-media-selector__selection__action__txt', checkExistingImage
          ? 'for an image'
          : 'an image'),
      ]),
    ]),
  ]);
};

export default component;
