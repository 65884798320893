import type { CSSProperties } from 'react';
import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import { StyleSheet } from 'aphrodite';
import { CSSProperties as AphroditeCSSProperties } from 'aphrodite'; // Import AphroditeCSSProperties
import { TooltipPosition } from './STooltip';

type ExtendedCSSProperties = AphroditeCSSProperties & {
  ':after'?: CSSProperties;
};

export const arrowWidth: Record<string, CSSProperties> = {
  top: {
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    marginLeft: -5,
  },
  right: {
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    marginTop: -5,
  },
  bottom: {
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    marginLeft: -5,
  },
  left: {
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    marginTop: -5,
  },
};

export const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
  },
  tooltipContent: {
    paddingTop: '10px',
    paddingRight: '16px',
    paddingBottom: '11px',
    paddingLeft: '16px',
    borderRadius: '8px',
    wordBreak: 'break-word',
    opacity: 1,
  },
  tooltipTypography: {
    ...TYPOGRAPHY.BODY4,
    fontWeight: 400,
    color: '',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100vh',
  },
  arrowWidth,
});
export function getTooltipStyle(position: TooltipPosition, maxWidth: number, style: CSSProperties) {
  if (!position) {
    position = 'top';
  }
  const positionCapitalized = position.charAt(0).toUpperCase() + position.slice(1);
  const arrowBorderWidth = `border${positionCapitalized}Width` as keyof CSSProperties;

  return StyleSheet.create({
    content: {
      ...styleSheet.tooltipContent,
      ...styleSheet.tooltipTypography,
      fontWeight: 'normal',
      maxWidth,
      ':after': {
        [arrowBorderWidth]: '7px',
        ...arrowWidth[position],
        ...style,
      } as ExtendedCSSProperties,
    },
  });
}
export default styleSheet;
