import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  modal: {
    margin: 'auto',
  },
  publishContainer: {
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
  },
  imagePickerContainer: {
    height: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '16px',
  },
  successContainer: {
    padding: '20px',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  successIllustration: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  errorIllustration: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  successTitle: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '24px',
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
  },
  paragraph: {
    ...TYPOGRAPHY.BODY3,
  },
  errorParagraph: {
    textAlign: 'center',
    marginLeft: 35,
    marginRight: 35,
    ...TYPOGRAPHY.BODY3,
  },
  errorTitle: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '40.5px',
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
  },
  lastEditedBy: {
    ...TYPOGRAPHY.BODY4,
    textAlign: 'right',
    color: COLORS.BLUE_GRAY_DARKEST.DEFAULT,
    marginTop: 8,
  },
});

export default styleSheet;
