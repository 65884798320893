import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  modal: {
    margin: 0,
    padding: 0,
    minWidth: 400,
    minHeight: 252,
  },
  contentWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});

export default styleSheet;
