// @flow

/**
 * Displays a user table cell
 *
 * Props:
 * - name: user's name
 * - email: user's email
 * - isValidated: define if the user status icon should be displayed
 * - style: override the component's style
 */

import * as React from 'react';

import UStatusIndicator from 'Components/unit/UStatusIndicator/UStatusIndicator';


import styles from './UTableCellUser.style';


type Props = {|
  name?: string,
  email?: string,
  isValidated?: boolean,
  style?: Object,
|};

export class UTableCellUser extends React.Component<Props> {
  static defaultProps = {
    name: '',
    email: '',
    isValidated: false,
    style: undefined,
  };

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderName()}
        {this.renderEmail()}
      </div>
    );
  }

  renderName = () => {
    const { name, isValidated } = this.props;
    const style = {
      ...styles.name,
      marginRight: isValidated ? '9px' : '0px',
    };

    return (
      <div style={styles.nameContainer}>
        <div style={style}>
          {name}
        </div>
        {this.renderActiveStatusIcon()}
      </div>
    );
  };

  renderEmail = () => {
    const { name, email } = this.props;
    const style = {
      ...styles.email,
      marginBottom: name ? '0px' : '1px',
    };

    return (
      <div style={style}>
        {email}
      </div>
    );
  };

  renderActiveStatusIcon = () => {
    const { isValidated, name } = this.props;

    if (!isValidated || !name)
      return null;

    return (
      <div>
        <UStatusIndicator
          type="valid"
        />
      </div>
    );
  };
}

export default UTableCellUser;
