// @flow

import React, { useCallback, useState, useMemo } from 'react';
import { t } from 'i18next';
import type { Source } from 'Libs/flowTypes';
import Enum from 'Models/Enum';
import App from 'Models/App';

import MModal from 'Components/modal/MModal/MModal';
import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';

import type { SegmentationGroupItemType } from '../../../redux/models/SegmentationGroupItem';

import styles from './TranslateModal.style';

type Props = {|
  visible: boolean,
  onAction: (ids: Array<number>) => mixed,
  onCancel: () => mixed,
  isActionLoading: boolean,
  activityLanguageId: number,
|};

const getLanguages = (activityLanguageId: number): $ReadOnlyArray<SegmentationGroupItemType> => {
  const filteredSegmentation: Array<any> = App.userSegmentationItems().toJSON();
  const groups = filteredSegmentation
    .map(segItem => segItem.group)
    .filter((group, i, a) => a.findIndex((y) => y.id === group.id) === i)
    .filter(group => group.dimension.id === Enum.dimensionTypes.LANGUAGE)
    .filter(group => group.id !== activityLanguageId);

  return groups.map(group => ({
    id: (group.id: number),
    label: (group.label: string),
    dimension: {
      id: (group.dimension.id: number),
      color: (group.dimension.color: string),
      typeId: (group.dimension.typeId: number),
    },
  }));
};
const SUGGESTIONS_OPTIONS = {
  enable: true,
  enableTextSuggestion: false,
  enableOnEmptyInput  : false,
  showAllOnFocus: true,
};
const DIMENSIONS = [{ id: Enum.dimensionTypes.LANGUAGE, label: 'Language' }];

export const TranslateModal = ({ visible, onAction, isActionLoading, onCancel, activityLanguageId }: Props) => {
  const [ids, setIds] = useState([]);
  const languages = useMemo(() => getLanguages(activityLanguageId), [activityLanguageId]);
  const onValidate = useCallback(() => onAction(ids), [onAction, ids]);
  const onDismiss = useCallback(() => {
    onCancel();

    setIds([]);
  }, [onCancel]);
  const handleFiltersChanged = useCallback((source: Source) => {
    const newIds = source.filters[0].items
      .filter(({ selected }) => selected)
      .map(item => item.id);

    setIds(newIds);
  }, []);
  const sources = useMemo(() => {
    const filterIds = ids
      .reduce((acc, id) => ({ ...acc, [id]: true }), {});
    const items = languages.map((segGroup) => ({
      category: 'segment',
      id: (segGroup.id: number),
      value: (segGroup.label: string),
      selected: filterIds[segGroup.id] === true,
      color: (segGroup.dimension.color: string),
      dimensionId: segGroup.dimension.id,
    }));
    const filters = [{ category: 'segment', type: 'multiselect', items }];

    return [{ icon: '', name: '', filters }];
  }, [ids, languages]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('activities:modals.translate.title')}
      labelActionButton={t('activities:modals.translate.button')}
      labelSecondButton={t('activities:modals.translate.cancel')}
      actionButtonType="accentuated"
      disableActionButton={ids.length === 0}
      isActionLoading={isActionLoading}
    >
      <div style={styles.content}>
        <div style={styles.label}>{t('activities:modals.translate.select_label')}</div>
        <SOmnibox
          placeholder={t('activities:modals.translate.select_placeholder')}
          onFilterChanged={handleFiltersChanged}
          sources={sources}
          initShowFilters={false}
          filtersText={t('activities:modals.translate.select_filters_text')}
          filterMultiText={false}
          suggestionOptions={SUGGESTIONS_OPTIONS}
          dimensions={DIMENSIONS}
        />
        <div style={styles.explanationTitle}>{t('activities:modals.translate.explanation_title')}</div>
        <div style={styles.explanation}>{t('activities:modals.translate.explanation')}</div>
      </div>
    </MModal>
  );
};
