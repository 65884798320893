import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
  },
  title: {
    display: 'flex',
    backgroundColor: 'transparent',
    marginLeft: '18px',
    verticalAlign: 'top',
    minHeight: 32,
    width: 300,
    minWidth: 300,
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    paddingLeft: '24px',
    marginTop: '7px',
    marginBottom: '7px',
    marginRight: '24px',
    width: 300,
    minWidth: 300,
  },
  image: {
    marginLeft: 24,
    minWidth: 30,
  },
  imageLabel: {
    minWidth: '133px',
  },
});

export default styleSheet;
