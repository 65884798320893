import type { FilterCoursesResponse, CoursesState } from 'Pages/Courses/redux/types';

export type SingleCourse = CoursesState['list']['items'][number];

export const mapCoursesResponseToState = ({ items }: FilterCoursesResponse): SingleCourse[] =>
  items.map(({ course, revision }) => ({
    course: {
      id: course.id,
      dataId: course.dataId,
      title: course.title,
      language: course.language,
      coverImageUrl: course.coverImageUrl,
      statuses: {
        draft: course.statuses.draft,
        published: course.statuses.published,
      },
      lastEditor: course.lastEditor,
    },
    revision: {
      editionId: revision.editionId,
    },
  }));
