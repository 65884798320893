/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-multi-comp */
// @flow

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { COLORS } from 'Components/foundation';
import { GAMEPLAY_KEYS } from 'Components/utils/Enum';

import SGreyInput from 'Components/structural/SGreyInput/SGreyInput';
import UButton from 'Components/unit/UButton/UButton';
import UIcon from 'Components/unit/UIcon/UIcon';
import UCircularButton from 'Components/unit/UCircularButton/UCircularButton';

import type { OrderingCardsType, OrderingCardsItemType } from '../../../../redux/models/OrderingCards';
import { actions } from '../../../redux';

import styles from './OCItemsForm.style';

type ChangeCallback = (key: number) => (text: string) => any;
type DeleteCallback = (key: number) => () => any;

const MIN_NB_CARDS = 3;

export const OCItemsForm = () => {
  const { t } = useTranslation();

  const content = useTypedSelector(state => state.pages.content.content);
  const orderingCards = content[GAMEPLAY_KEYS.ORDERING_CARDS];

  return (
    <div style={styles.wrapper}>
      <div style={styles.OCItemsFormTitle}>
        {t('gameplays:ordering_cards.items_title')}
      </div>
      <div style={styles.explanation}>
          {t('gameplays:ordering_cards.items_subtitle')}
      </div>
      {renderItems(orderingCards, t)}
    </div>
  );
};

function renderItems(orderingCards: OrderingCardsType, t) {
  const dispatch = useTypedDispatch();
  const canEdit = useTypedSelector(state => state.pages.content.canEditContent);
  const onAddClick = useCallback(() => dispatch(actions.addOCItem()), [dispatch]);
  const onChange = useCallback((key: number) => {
    return (text: string) => dispatch(actions.setOCItemData(key, text));
  }, [dispatch]);
  const onDeleteClick = useCallback((key: number) => {
    return () => dispatch(actions.removeOCItem(key));
  }, [dispatch]);

  const { cards } = orderingCards;
  const isLocked = cards.length <= MIN_NB_CARDS;

  return (
    <div style={styles.form}>
      { cards.map((item, i) => renderItem(item, i, onChange, onDeleteClick, canEdit, isLocked, t)) }
      { canEdit
        ? (
          <UButton
            type='accentuated'
            text={t('gameplays:ordering_cards.add_item')}
            onClick={onAddClick}
            style={styles.addButton}
          />
        )
        : null }
    </div>
  );
}

function renderItem(
  item: OrderingCardsItemType,
  i: number,
  onChange: ChangeCallback,
  onDelete: DeleteCallback,
  canEdit: boolean,
  isLocked: boolean,
  t,
) {
  const { cardContent, key } = item;

  return (
    <div key={key} style={styles.cardRow}>
      <SGreyInput
        placeholder={t('gameplays:ordering_cards.item_placeholder', { index: i + 1 })}
        value={cardContent.data}
        maxLength={90}
        height={64}
        onChange={onChange(key)}
        disabled={!canEdit}
      />
      {renderAction(item, onDelete, canEdit, isLocked)}
    </div>
  );
}

function renderAction(
  item: OrderingCardsItemType,
  onDelete: DeleteCallback,
  canEdit: boolean,
  isLocked: boolean,
) {
  const { key } = item;

  if (!canEdit)
    return <div style={styles.disabledIcon} />;

  if (isLocked) {
    return (
      <div style={styles.actionLocked}>
        <UIcon
          name='lock'
          size={24}
          color={COLORS.GREY_MEDIUM.DEFAULT}
        />
      </div>
    );
  }

  return (
    <div style={styles.action}>
      <UCircularButton
        icon='delete'
        onClick={onDelete(key)}
      />
    </div>
  );
}

export default OCItemsForm;
