import styles from 'Components/unit/UGalleryItem/UGalleryItem.style';
import OverlayText from 'Components/unit/UGalleryItem/OverlayText';
import React, { useState } from 'react';
import { ImageGalleryType } from 'Libs/ts/types';

type OverlayProps = {
  source?: string;
  width: number;
  image: ImageGalleryType;
  isSelected: boolean;
};

const Overlay = ({ source, width, image, isSelected }: OverlayProps) => {
  const [isLinkHover, setIsLinkHover] = useState(false);

  const linearGradient = image.linkText ? styles.linearGradient : {};

  if (!isSelected) return null;

  return (
    <div style={{ ...styles.containOverlay, ...linearGradient }}>
      <OverlayText
        source={source}
        width={width}
        image={image}
        isLinkHover={isLinkHover}
        setIsLinkHover={setIsLinkHover}
      />
    </div>
  );
};

export default Overlay;
