/**
 * WARNING This file appears in multiple locations:
 *  - back-office/src/libs/regex.js
 *  - backend/loopback/libs/regex.js
 *  - scripts/utils/regex.js
 */

'use strict';

const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const extractEmail = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
const url = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
const AISpecialChars = /[{}~§¥£€$◇■□●○•°※¤《》¡¿₩$&+,:;=?@#|'<>.^*()%!"`“‘ ́\/\\_]|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/gi;
const AIaccentsAlone = /^[\u00C0-\u00FF]+$/;
const AIcomposedAccentsAlone = /^([a-z|A-Z][\u0300-\u036F])+$/;
const VALID_LINK_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

module.exports = {
  email                 : email,
  extractEmail          : extractEmail,
  url                   : url,
  AISpecialChars        : AISpecialChars,
  AIaccentsAlone        : AIaccentsAlone,
  AIcomposedAccentsAlone: AIcomposedAccentsAlone,
  VALID_LINK_REGEX      : VALID_LINK_REGEX,
};
