/* eslint-disable complexity */
import m from 'm';
import Enum from 'models/Enum';
import questionGameplayPreview from 'components/content.preview.question';
import inputGameItem from 'components/input.game.item';

var component = {};

component.view = function view(c, args) {
  var game = args.content.game();
  var propositions = game.propositions();
  var options = game.options();
  var question = game.question().data();

  return m('.preview-swiping-cards', [
    m('.preview-swiping-cards__container', [
      m.hide(!question, '.preview-swiping-cards__question', [
        m(questionGameplayPreview, {
          question: question,
        }),
      ]),
      m('.preview-swiping-cards__options', [
        m('.preview-swiping-cards__option.preview-swiping-cards__option--left', options.at(0) && options.at(0).data() || ''),
        m('.preview-swiping-cards__option.preview-swiping-cards__option--right', options.at(1) && options.at(1).data() || ''),
        m.hide(options.length <= 2, '.preview-swiping-cards__option.preview-swiping-cards__option--top', options.at(2) && options.at(2).data() || ''),
        m.hide(options.length <= 3, '.preview-swiping-cards__option.preview-swiping-cards__option--bottom', options.at(3) && options.at(3).data() || ''),
      ]),
      m('.preview-swiping-cards__stack', propositions.map(renderProposition)),
    ]),
  ]);

  function renderProposition(proposition, i) {
    return m('.preview-swiping-cards__stack__card', {
      class       : 'preview-swiping-cards__stack__card--' + i,
      'data-index': i,
      key         : proposition.id(),
    }, [
      m('.preview-swiping-cards__stack__card__value', [
        renderPropositionValue(proposition),
      ]),
    ]);
  }

  function renderPropositionValue(proposition) {
    if (!proposition)
      return '';
    if (proposition.typeId() === Enum.swipingCardsItemType.TEXT_AND_IMAGE) {
      return m('.preview-swiping-cards__stack__card__value__image', {
        style: {
          backgroundImage: 'url(' + proposition.image().cdn() + ')',
        },
      }, [
        m('.swiping-cards-image-container', [
          m('.swiping-cards-text-and-image-input-container', [
            m('.input-game-item', [
              m(inputGameItem, {
                gameItem   : proposition.text,
                placeholder: 'Enter your text here...',
                editable   : false,
              }),
            ]),
          ]),
        ]),
      ]);
    }
    if (proposition.typeId() === Enum.swipingCardsItemType.TEXT) {
      return m('.preview-swiping-card__stack__card__value__text__container', [
        m('.preview-swiping-cards__stack__card__value__text', proposition.text().data()),
      ]);
    }
    if (proposition.typeId() === Enum.swipingCardsItemType.IMAGE) {
      return m('.preview-swiping-cards__stack__card__value__image', {
        style: {
          backgroundImage: 'url(' + proposition.image().cdn() + ')',
        },
      });
    }

    return '';
  }
};

export default component;
