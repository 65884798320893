/* eslint-disable react/default-props-match-prop-types */
import React, { CSSProperties } from 'react';
import type { MouseEventHandler } from 'react';

import { COLORS } from 'Components/foundation';
import UChips from 'Components/unit/UChips/UChips';
import UIcon from 'Components/unit/UIcon/UIcon';
import UProfilePicture from 'Components/unit/UProfilePicture/UProfilePicture';

import type {
  TextSuggestion,
  CampaignSuggestion,
  UserSuggestion,
  SegmentSuggestion,
  Suggestion,
} from '../USuggestionBox/USuggestionBox';

import styles from './USuggestionItem.style';

export type USuggestionItemProps = {
  suggestion: Suggestion;
  style: CSSProperties;
  active: boolean;
  onClick: MouseEventHandler;
  onMouseMove: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
};

const renderText = ({ value }: TextSuggestion) => <div style={styles.textValue}>{value}</div>;

const renderCampaign = ({ id, name }: CampaignSuggestion) => (
  <>
    <div style={styles.campaignId}>{`#${id}`}</div>
    <div style={styles.campaignName}>{name}</div>
  </>
);

const renderUser = ({ name, picturePath }: UserSuggestion) => (
  <>
    <div style={styles.userProfilePicture}>
      <UProfilePicture picturePath={picturePath} size={22} />
    </div>
    <div style={styles.userName}>{name}</div>
  </>
);

const renderSegment = ({ name, color }: SegmentSuggestion) => (
  <div style={styles.segmentChip}>
    <UChips type="standard" value={name} color={color} />
  </div>
);

/**
 * Suggestion item.
 *
 * Props:
 * - suggestion: The suggestion
 * - active: highlight the suggestion
 * - onClick: click handler
 * - onMouseMove: mousemove handler
 * - onMouseLeave: mouseleave handler
 */
export class USuggestionItem extends React.PureComponent<USuggestionItemProps> {
  static defaultProps = {
    style: undefined,
    active: false,
  };

  renderChild = () => {
    const { suggestion } = this.props;

    switch (suggestion.category) {
      case 'campaign':
        return renderCampaign(suggestion);
      case 'user':
        return renderUser(suggestion);
      case 'segment':
        return renderSegment(suggestion);
      case 'text':
      default:
        return renderText(suggestion);
    }
  };

  render() {
    const { style: propStyle, onClick, onMouseMove, onMouseLeave, active } = this.props;
    const wrapperActiveStyle = {
      backgroundColor: active ? COLORS.WHITE.HOVER : 'transparent',
    };
    const iconActiveStyle = {
      opacity: active ? 1 : 0,
    };

    return (
      <div
        data-testid="suggestion-item"
        style={{ ...styles.wrapper, ...wrapperActiveStyle, ...propStyle }}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <div style={styles.container}>
          {this.renderChild()}
          <UIcon
            name="keyboard-return"
            size={14}
            color={COLORS.TEXT.SECONDARY_DEFAULT}
            style={{ ...styles.icon, ...iconActiveStyle }}
          />
        </div>
      </div>
    );
  }
}

export default USuggestionItem;
