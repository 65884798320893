import React from 'react';
import type { FunctionComponent } from 'react';
import { css } from 'aphrodite';

import SCourseCard from 'Components/structural/SCourseCard/SCourseCard';
import type { AudienceGroupCourse } from 'Features/audience/services/audience-group-courses-list.service';
import Enum from 'Models/Enum';

import styles, { scrollBarStyle } from './CourseListFromAudience.style';

export type CourseListFromAudienceProps = {
  courses: AudienceGroupCourse[],
};

export const CourseListFromAudience: FunctionComponent<CourseListFromAudienceProps> = ({
  courses,
}) => (
  <div className={css(scrollBarStyle.scrollbar)}>
    <div style={styles.courseListContainer}>
      {courses.map((course) => (
        <SCourseCard
          key={course.id}
          id={course.id}
          title={course.title}
          size="S"
          language={(Enum.Languages as any)[course.language]}
          image={course.coverImageUrl}
          isStatic
          showStatusIndicator={false}
          status="published"
        />
      ))}
    </div>
  </div>
);
