import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { css } from 'aphrodite';

import UIcon from 'Components/unit/UIcon/UIcon';
import ULabel, { LabelSize, ULabelTooltipProps } from 'Components/unit/ULabel/ULabel';
import { COLORS } from 'Components/foundation';
import type { IconName } from 'Components/foundation/icons';

import styles from './UToggle.style';

type ToggleType = 'default' | 'large';

type Props = {
  onChange: (toggled: boolean) => void;
  toggled: boolean;
  type: ToggleType;
  outsideLabel?: string;
  outsideLabelSize?: LabelSize;
  outsideLabelIcon?: IconName;
  outsideLabelTooltip?: ULabelTooltipProps;
  isRequired?: boolean;
  unToggledLabel: string;
  toggledLabel: string;
  isLocked: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isClickable: boolean;
  style: React.CSSProperties;
};

type State = {
  isHover: boolean;
};

const wrapperStyles = {
  default: styles.wrapperDefault,
  large: styles.wrapperLarge,
};

const circleStyles = {
  default: styles.circleDefault,
  large: styles.circleLarge,
};

const toggledWrapperStyles = {
  default: styles.toggledWrapperDefault,
  large: styles.toggledWrapperLarge,
};

const toggledCircleStyles = {
  default: styles.toggledCircleDefault,
  large: styles.toggledCircleLarge,
};

const disabledCircleStyles = {
  default: styles.defaultCircleDisabled,
  large: styles.largeCircleDisabled,
};

const renderLoader = () => {
  return <CircleSpinner size={16} color={COLORS.WHITE.DEFAULT} loading />;
};

const renderLockedIcon = (type: ToggleType, isLocked: boolean, toggled: boolean) => {
  if (!isLocked || !toggled || type !== 'large') return null;

  return (
    <div className={css(styles.icon)}>
      <UIcon name="lock" size={14} color={COLORS.SUCCESS.DEFAULT} />
    </div>
  );
};

export class UToggle extends React.PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    type: 'default',
    outsideLabelIcon: undefined,
    outsideLabel: '',
    outsideLabelSize: 'S',
    isRequired: false,
    outsideLabelTooltip: undefined,
    unToggledLabel: '',
    toggledLabel: '',
    isLocked: false,
    isLoading: false,
    isDisabled: false,
    isClickable: true,
    style: undefined,
  };

  state: State = {
    isHover: false,
  };

  render() {
    const {
      type,
      outsideLabelSize,
      outsideLabelIcon,
      outsideLabelTooltip,
      isRequired,
      style,
      toggled,
      isDisabled,
      isClickable,
      isLocked,
      outsideLabel,
    } = this.props;
    const { isHover } = this.state;
    const cursorStyle = isHover && !isLocked ? styles.pointer : styles.default;
    const wrapperDisabledStyle = isDisabled ? styles.wrapperDisabled : styles.none;
    const wrapperClickableStyle = !isClickable ? styles.wrapperNotCickable : styles.none;
    const labelStyle = outsideLabelIcon ? css(styles.withIcon) : css(styles.labelStyle);

    const circleDisabledStyle = isDisabled ? disabledCircleStyles[type] : styles.none;
    const toggledWrapperStyle = toggled ? toggledWrapperStyles[type] : styles.none;
    const toggledCircleStyle = toggled ? toggledCircleStyles[type] : styles.none;

    return (
      <div className={css(styles.defaultToggleWrapper)}>
        <div
          onClick={this.handleClick}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleLeave}
          style={{ ...style, ...cursorStyle }}
          className={css(
            styles.wrapper,
            wrapperStyles[type],
            toggledWrapperStyle,
            wrapperDisabledStyle,
            wrapperClickableStyle,
          )}
        >
          {this.renderLabels()}
          <div className={css(styles.circle, circleStyles[type], toggledCircleStyle, circleDisabledStyle)}>
            {renderLockedIcon(type, isLocked, toggled)}
          </div>
        </div>
        {outsideLabel && type === 'default' && (
          <div className={labelStyle}>
            <ULabel size={outsideLabelSize} icon={outsideLabelIcon} tooltip={outsideLabelTooltip} required={isRequired}>
              {outsideLabel}
            </ULabel>
          </div>
        )}
      </div>
    );
  }

  renderLabels = () => {
    const { type, toggledLabel, unToggledLabel, toggled, isLoading, isDisabled } = this.props;
    const toggledClassName = toggled ? styles.fadeIn : styles.fadeOut;
    const unToggledClassName = toggled ? styles.fadeOut : styles.fadeIn;
    const isDisabledColor = isDisabled && type === 'large' ? styles.labelDisabled : styles.none;

    if (type !== 'large') return null;

    return (
      <div className={css(styles.label, isDisabledColor)}>
        <div className={css(styles.labelContentWrapper, styles.toggledLabel, toggledClassName)}>
          <div className={css(styles.labelContent)}>{isLoading ? renderLoader() : toggledLabel}</div>
        </div>
        <div className={css(styles.labelContentWrapper, styles.unToggledLabel, unToggledClassName)}>
          <div className={css(styles.labelContent)}>{isLoading ? renderLoader() : unToggledLabel}</div>
        </div>
      </div>
    );
  };

  handleClick = () => {
    const { onChange, isLocked, toggled, isDisabled, isClickable } = this.props;

    if (!isLocked && !isDisabled && isClickable) onChange(!toggled);
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };
}

export default UToggle;
