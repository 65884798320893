import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 32,
    paddingRight: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: COLORS.GREY_DARK.DEFAULT,

    marginTop: 16,
    marginBottom: 8,
    position: 'relative',
  },
  wrapperSourceBox: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  sourceBox: {
    display: 'flex',
    cursor: 'pointer',
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  rod: {
    height: 18,
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderWidth: 0.5,
    borderStyle: 'solid',
    marginRight: 4,
  },
  inputWrapper: {
    flexGrow: 1,
    marginLeft: 6,
  },
  input: {
    ...TYPOGRAPHY.BODY3,
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  wrapperDropDownSources: {
    zIndex: LEVELS.LOCAL_OFFSET,
    position: 'absolute',
    top: 35,
    left: -1,
  },
  filterText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    cursor: 'pointer',
    paddingLeft: 12,
    paddingTop: 1,
  },
  filterBox: {
    paddingTop: 4,
    paddingBottom: 10,
  },
  chipsWrapper: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  chipsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    borderRadius: 8,
  },
  chips: {
    marginRight: 8,
    marginTop: 4,
  },
  suggestionWrapper: {
    width: '100%',
    height: 0,
    position: 'relative',
    zIndex: LEVELS.LOCAL_OFFSET_3,
  },
  suggestionContainer: {
    width: '100%',
    position: 'absolute',
    top: -4,
  },
  hideMargins: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export default styles;
