import React, { useEffect, useMemo, useState } from 'react';

import SLandingPage from 'Components/structural/SLandingPage/SLandingPage';

import { validateToken } from 'Libs/jwt';
import { getCdnUrl } from 'Libs/cdn';
import { tracking } from 'Services/trackingService';
import Config from 'Services/instanceConfig';

import { SignUpForm } from './components/SignUpForm/SignUpForm';
import { InviteExpirationScreen } from './components/InviteExpirationScreen/InviteExpirationScreen';

const TOKEN_URL_EMPLACEMENT = 2;

const extractTwoFactorTokenFromPathname = (pathname: string) => pathname.split('/')[TOKEN_URL_EMPLACEMENT];

export const SignUp = () => {
  const { pathname } = window.location;
  const token = useMemo(() => extractTwoFactorTokenFromPathname(pathname), [pathname]);
  const [isTokenValid] = useState(validateToken(token));

  useEffect(() => {
    tracking.contributorsAcceptInvite();
  }, []);

  if (!isTokenValid) {
    return <InviteExpirationScreen />;
  }

  return (
    <SLandingPage instanceLogo={getCdnUrl(Config.instanceId, '/logo.png')}>
      <SignUpForm instanceName={Config.instanceId} token={token} />
    </SLandingPage>
  );
};
