import m from 'm';
import inputGameItem from 'components/input.game.item';

var component = {};

component.controller = function controller() {

};

component.view = function view(c, args) {

  return m('.input-explanation-text', {
    class: args.class ? args.class : '',
  }, [
    m(inputGameItem, {
      gameItem    : args.gameItem,
      placeholder : args.placeholder || 'Enter a question',
      editable    : args.editable,
      supportEnter: true,
      inputClass: 'input-game-item__input--explanation-text',
      containerClass: 'input-game-item--explanation-text',
      counterClass: 'input-game-item__counter--explanation-text',
      warningMax : 256,
      warningText: 'A short answer text is easier and faster to read and understand',
    }),
  ]);
};

export default component;
