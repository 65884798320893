import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    display: 'flex',
    gap: '4px',
  },
  input: {
    width: '48px',
  },
  innerInput: {
    textAlign: 'center',
    cursor: 'text',
  },
});

export default styleSheet;
