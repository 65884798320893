// @flow

import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    width: '90%',
    maxWidth: 1200,
    margin: 'auto',
    paddingBottom: 12,
    marginTop: 64,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  containerCallout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  buttonCallout: {
    marginLeft: 56,
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  contentCallout: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonDraft: {
    marginRight: 8,
    height: 32,
  },
  buttonPreview: {
    marginRight: 8,
  },
  callout: {
    marginTop: 32,
  },
  textTranslation: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBottom: 2,
  },
  textKnowledgeTitle: {
    ...TYPOGRAPHY.H1,
  },
  titleCallout: {
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
  },
  noticeCallout: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 8,
  },
  containerButtons: {
    display: 'flex',
  },
};
