import type { AsyncActionType, ApiActionType } from 'Libs/redux/utils';
import { asyncAction, apiAction } from 'Libs/redux/utils';

import type { AlertScope } from './models/Alert';

type KnowledgeTranslationAutoTranslateAction = ApiActionType<'KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE'>;

type KnowledgeTranslationSaveAction = AsyncActionType<'KNOWLEDGE_TRANSLATION_SAVE'>;

type KnowledgeTranslationValidateAction = AsyncActionType<'KNOWLEDGE_TRANSLATION_VALIDATE'>;

type KnowledgeTranslationRemoveAlertAction = {
  type: 'KNOWLEDGE_TRANSLATION_REMOVE_ALERT';
  scope: AlertScope;
};

type KnowledgeTranslationResetAction = {
  type: 'KNOWLEDGE_TRANSLATION_RESET';
};

export const actions = {
  save: (savePromise: () => Promise<any>): KnowledgeTranslationSaveAction =>
    asyncAction({
      type: 'KNOWLEDGE_TRANSLATION_SAVE',
      promise: savePromise(),
    }),

  validateTranslation: (validatePromise: () => Promise<any>): KnowledgeTranslationValidateAction =>
    asyncAction({
      type: 'KNOWLEDGE_TRANSLATION_VALIDATE',
      promise: validatePromise(),
    }),

  autoTranslate: (
    items: ReadonlyArray<{ id: number; value: string }>,
    sourceLanguageId: number,
    targetLanguageId: number,
  ): KnowledgeTranslationAutoTranslateAction => {
    const payload = { items, sourceLanguageId, targetLanguageId };

    return apiAction({
      type: 'KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE',
      route: '/api/automatedTranslationRequests',
      method: 'POST',
      payload,
    });
  },

  removeAlert: (scope: AlertScope): KnowledgeTranslationRemoveAlertAction => ({
    type: 'KNOWLEDGE_TRANSLATION_REMOVE_ALERT',
    scope,
  }),

  reset: (): KnowledgeTranslationResetAction => ({
    type: 'KNOWLEDGE_TRANSLATION_RESET',
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type KnowledgeTranslationAction = ReturnType<ActionsFn>;
