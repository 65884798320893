// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  headerBar: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    ...TYPOGRAPHY.BODY3,
  },
  headerBarHovered: {
    backgroundColor: COLORS.GREY_LIGHT.HOVER,
  },
  icon: {
    transition: 'transform 250ms ease',
    transform: 'rotate(-90deg)',
  },
  iconSelected: {
    transform: 'rotate(0deg)',
  },
  childrenWrapperSelected: {
    paddingBottom: 16,
  },
};
