import m from 'm';
import Enum from 'models/Enum';
import Activity from 'models/Activity';
import multistep from 'components/popup.multistep';
import chooseType from 'components/popup.newActivity.type';
var component = {};

component.controller = function controller(args) {
  var self = this;

  self.activity = new Activity();
  self.activity.typeId(Enum.activityTypeEnum.DAILY_SERIE);

  self.finish = function saveActivity() {
    switch (self.activity.typeId()) {
      case Enum.activityTypeEnum.DAILY_SERIE:
        console.log('[newActivity]', 'Redirecting to DailySerie creation page.');
        args.close();
        m.route('/activity/dailyserie/new');
        break;
      case Enum.activityTypeEnum.WELCOME:
        console.log('[newActivity]', 'Redirecting to WelcomeCard creation page.');
        args.close();
        m.route('/activity/welcome/new');
        break;
      case Enum.activityTypeEnum.INTERSEASON:
        console.log('[newActivity]', 'Redirecting to Interseason creation page.');
        args.close();
        m.route('/activity/interseason/new');
        break;
      case Enum.activityTypeEnum.BREAKING_NEWS:
        console.log('[newActivity]', 'Redirecting to Interseason creation page.');
        args.close();
        m.route('/activity/breakingnews/new');
        break;
      case Enum.activityTypeEnum.THATS_ALL_FOLKS:
        console.log('[newActivity]', 'Redirecting to THATS_ALL_FOLKS creation page.');
        args.close();
        m.route('/activity/thatsAllFolks/new');
        break;
      default:
    }
  };

  self.selectActivityType = function selectActivityType() {

    switch (self.activity.typeId()) {
      case Enum.activityTypeEnum.DAILY_SERIE:
      case Enum.activityTypeEnum.WELCOME:
      case Enum.activityTypeEnum.INTERSEASON:
      case Enum.activityTypeEnum.THATS_ALL_FOLKS:
        self.steps.splice(1);
        break;
      default:
        self.steps.splice(2);
        break;
    }
  };

  self.steps = [{
    name     : 'New Activity',
    subtitle : 'What kind of activity do you want to create?',
    component: m(chooseType, {
      activity  : self.activity,
      selectType: self.selectActivityType,
    }),
  }];
};

component.view = function view(c, args) {
  return m(multistep, {
    steps: c.steps,
    close: c.finish,
    step : args.step,
  });
};

export default component;
