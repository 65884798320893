import { TYPOGRAPHY, COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  container: {
    display: 'inline-flex',
    alignItems: 'center',

    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',

    border: '1px solid',
    borderRadius: '8px',

    backgroundColor: COLORS.WHITE.DEFAULT,
    cursor: 'pointer',
    userSelect: 'none',
  },
  // Selected, unselected, disabled and centered are meant to be spread into container
  selected: {
    borderColor: COLORS.PRIMARY.DEFAULT,
    backgroundColor: COLORS.PRIMARY.LIGHT,
  },
  unselected: {
    borderColor: COLORS.GREY_DARK.DEFAULT,
  },
  disabled: {
    borderColor: COLORS.GREY_DARK.OUTLINE_DISABLED,
    cursor: 'not-allowed',
  },
  centered: {
    justifyContent: 'center',
  },

  text: {
    ...TYPOGRAPHY.BODY4_SEMIBOLD,
    flexShrink: 1,
  },
  startAlignedText: {
    textAlign: 'start',
  },
  // disableText and wrapText are meant to be spread into text
  disabledText: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  wrapText: {
    overflowWrap: 'break-word',
  },
  icon: {
    fill: COLORS.BLACK.DEFAULT,
    marginRight: 8,
    flexShrink: 0,
  },

  // radioIcon, disableIcon and selectedIcon are meant to be spread into icon
  radioIcon: {
    fill: COLORS.GREY_DARKER.DEFAULT,
  },
  selectedRadioIcon: {
    fill: COLORS.PRIMARY.DEFAULT,
  },
  disabledIcon: {
    fill: COLORS.TEXT.DISABLED_DEFAULT,
  },
});
