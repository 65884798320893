import Model from 'models/Model';
import { t } from 'i18next';

const gameplay_descriptions = {
  1:() => t('gameplays:qcm.description'),
  2:() => t('gameplays:sqcm.description'),
  3:() => t('gameplays:error_text.description'),
  4:() => t('gameplays:open_question.description'),
  5:() => t('gameplays:no_interaction_content.description'),
  6:() => t('gameplays:picture_spot.description'),
  7:() => t('gameplays:swiping_cards.description'),
  8:() => t('gameplays:ordering_cards.description'),
}; 

const gameplay_points_rules = {
  1:() => t('gameplays:qcm.points_rules'),
  2:() => t('gameplays:sqcm.points_rules'),
  3:() => t('gameplays:error_text.points_rules'),
  4:() => t('gameplays:open_question.points_rules'),
  5:() => t('gameplays:no_interaction_content.points_rules'),
  6:() => t('gameplays:picture_spot.points_rules'),
  7:() => t('gameplays:swiping_cards.points_rules'),
  8:() => t('gameplays:ordering_cards.points_rules'),
}; 

const labels = { 
  1:() => t('gameplays:qcm.label'),
  2:() => t('gameplays:sqcm.label'),
  3:() => t('gameplays:error_text.label'),
  4:() => t('gameplays:open_question.label'),
  5:() => t('gameplays:no_interaction_content.label'),
  6:() => t('gameplays:picture_spot.label'),
  7:() => t('gameplays:swiping_cards.label'),
  8:() => t('gameplays:ordering_cards.label'),
};

var Gameplay = {
  endpoint  : 'api/Gameplays',
  attributes: {
    label: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    text: {
      type: 'string',
    },
    pointsEarningRules: {
      type: 'string',
    },
    color: {
      type: 'string',
    },
    videoUrl: {
      type: 'string',
    },
  },
  methods: {
    youtubeVideoId: function youtubeVideoId() {
      var self = this;

      if (self.videoUrl() ==='')
        return null;
      var matchVideoId = /\?.*v=([^&]*)/.exec(self.videoUrl());

      if (matchVideoId)
        return (matchVideoId[1]);

      matchVideoId = /be\/(.*)\?/.exec(self.videoUrl());

      if (matchVideoId)
        return (matchVideoId[1]);

      return null;
    },
    name: function name() {
      var self = this;

      return self.label().toLowerCase().replace(/ /g, '');
    },
    translatedLabel: function translatedLabel() {
      var self = this;

      if(!self.id())
        return null;

      return labels[self.id()]();
    },
    description: function description() {
      var self = this;

      if(!self.id())
        return null;

      return gameplay_descriptions[self.id()]();
    },
    pointsEarningRules: function pointsEarningRules() {
      var self = this;

      if(!self.id())
        return null;

      return gameplay_points_rules[self.id()]();
    }
  },
};

export default Model.register('Gameplay', Gameplay);
