// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    width: 540,
  },
  label: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 8,
  },
};
