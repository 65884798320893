import { COLORS, ELEVATIONS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  images: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    maxHeight: '100%',
    boxShadow: ELEVATIONS.LIGHT_TINY,
  },

  image: {
    position: 'relative',
  },

  block: {
    position: 'absolute',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: COLORS.PRIMARY.DEFAULT,
    borderRadius: 4,
    cursor: 'pointer',
  },
});

export default styleSheet;
