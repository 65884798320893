const LOW = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';

const HIGH = '0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12)';

const MEDIUM = '0 2px 5px rgba(0,0,0,0.18), 0 3px 10px rgba(0,0,0,0.11)';

const LIGHT_TINY = '0 0px 4px rgba(0, 30, 87, 0.12)';

const LIGHT_LOW = '0 0px 8px rgba(0, 30, 87, 0.16)';

const LIGHT_HIGH = '0px 4px 22px rgba(0, 30, 87, 0.16)';

export default {
  LOW,
  HIGH,
  MEDIUM,
  LIGHT_TINY,
  LIGHT_LOW,
  LIGHT_HIGH,
};
