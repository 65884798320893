import m from 'm';
import Enum from 'models/Enum';

var component = {};

component.view = function view(c, args) {
  var title = args.content.game().title().data();
  var image = args.content.game().mediaTypeTopId() === Enum.mediaType.Image
    ? args.content.game().imageTop().cdn()
    : args.content.game().videoTop().getOriginalThumbnailSync();
  var text = args.content.game().longText().data();

  return m('.preview-nic', [
    m('.preview-nic__image', {
      style: {
        backgroundImage: 'url(' + image + ')',
      },
    }),
    m('.preview-nic__body', [
      m('.preview-nic__body-title', title),
      m('.preview-nic__body-text', text),
      m('.preview-nic__body-filtre'),
      m('.preview-nic__body-three-dot'),
    ]),
  ]);
};
export default component;
