import m from 'm';
import popup from 'components/popup';

const popupService = {};
const handler = document.querySelector('#m_popup');

const newDesign = {
  'selectGameplay': true,
  'leaveEditionConfirmation': true,
  'contributor': true,
  'confirm': true,
  'info': true,
};

popupService.open = function openPopup(args) {
  const popupToUse = popup;

  handler.style.zIndex = args.zIndex || 100;
  args.close = popupService.close;

  m.startComputation();
  m.diffMount(handler, m(popupToUse, args));
  m.endComputation();
};

popupService.close = function closePopup() {
  m.startComputation();
  m.diffMount(handler, null);
  m.endComputation();

  handler.style.zIndex = -1;
};

window.addEventListener('popstate', () => {
  popupService.close();
});

export default popupService;
