/* eslint-disable react/default-props-match-prop-types */
// @flow

/**
 * Displays a user tag as a chips
 *
 * Props:
 * - value: the user role
 * - category: category of the parent of the chips
 * - color: the color of the chips
 * - type: can select which kind of user value we want
 * - aggregateItems: items to render in the dropdown if aggregated mode
 * - categoryIcon: the icon to display in standard mode
 * - onAction: trigger close chips callback
 * - isCloseVisible: if the button to close in standard mode is visible
 * - style: can override the component's style
 */

import * as React from 'react';

import { COLORS } from 'Components/foundation';
import { ICONS } from 'Components/foundation/icons';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './UChips.style';

type Props = {|
  value: string,
  category?: string,
  color?: string,
  type: string,
  categoryIcon: string,
  onAction: Function,
  isCloseVisible?: boolean,
  aggregateItems: Array<Object>,
  style: ?Object,
|};

type State = {|
  isDropdownBoxOpen: boolean,
|};

const CHIPS = {
  TYPES: {
    STANDARD : 'standard',
    COMPACT : 'compact',
    AGGREGATED: 'aggregated',
  },
  ACTIONS: {
    standard: 'close',
    aggregated: 'collapse',
  },
  PADDING_ICON: 8,
};

export class UChips extends React.PureComponent<Props, State> {
  node: ?Object = null;

  static defaultProps = {
    color: styles.mode[CHIPS.TYPES.STANDARD].defaultColor,
    style: undefined,
    onAction: () => {},
    isCloseVisible: false,
    categoryIcon: '',
    category: '',
    aggregateItems: [],
  };

  constructor(props: Props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.state = {
      isDropdownBoxOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const {
      value,
      type,
      color,
      categoryIcon,
      style: propStyle,
    } = this.props;

    const chipsTypeStyle = styles.mode[type]
      || styles.mode[CHIPS.TYPES.STANDARD];

    const style = this.getChipsStyleByIconVisibility(
      chipsTypeStyle,
      type,
      categoryIcon,
    );

    style.backgroundColor = color;
    style.height = chipsTypeStyle.height;

    return (
      <div
        ref={this.setWrapperRef}
        data-testid='chips'
        style={{
          ...styles.wrapper,
          ...style,
          ...propStyle,
        }}
      >
        {this.renderPreText(type, categoryIcon)}
        <span style={{
          ...styles.selectionDisable,
          ...chipsTypeStyle.typography,
        }}
        >
          {value}
        </span>
        {this.renderButton(type)}
        {this.renderDropdownBox()}
      </div>
    );
  }

  renderIcon = (categoryIcon: string, size: number, color: string) => {
    const category = categoryIcon in ICONS ? categoryIcon : 'edit';

    return (
      <UIcon
        name={category}
        size={size}
        color={color}
        style={styles.categoryIcon}
      />
    );
  };

  renderNumberBox = () => {
    const { aggregateItems } = this.props;

    return (
      <span style={{
        ...styles.numberBox,
        ...styles.selectionDisable,
      }}
      >
        <span style={styles.aggregationNumber}>
          {aggregateItems.length}
        </span>
      </span>
    );
  };

  renderPreText = (type: string, categoryIcon: string) => {
    if (categoryIcon && type === CHIPS.TYPES.STANDARD) {
      return this.renderIcon(
        categoryIcon,
        styles.categoryIcon.size,
        styles.categoryIcon.color
      );
    }

    if (type === CHIPS.TYPES.AGGREGATED)
      return this.renderNumberBox();

    return null;
  };

  renderButton = (chipsType: string) => {
    const actionName = CHIPS.ACTIONS[chipsType];

    if (!this.getActionVisibilityByChipsType(chipsType))
      return null;

    return (
      <a
        data-testid='chips-button'
        onClick={this.handleClick}
        style={{
          ...styles.actionIcon,
        }}
      >
        <UIcon
          name={styles.actionIcons[actionName].name}
          size={styles.actionIcons[actionName].size}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
        />
      </a>
    );
  };

  renderDropdownBox = () => {
    const { aggregateItems } = this.props;
    const { isDropdownBoxOpen } = this.state;

    if (!isDropdownBoxOpen || !aggregateItems.length)
      return null;

    return (
      <UDropdownBox
        items={aggregateItems}
        renderItem={this.renderItem}
        style={styles.dropdownbox}
      />
    );
  };

  renderItem = (item: Object) => {
    const { onAction, category, categoryIcon } = this.props;
    const { id, value, color, isCloseVisible } = item;

    const chipsProps = {
      id,
      category,
      value,
      color,
      isCloseVisible: isCloseVisible === undefined
        ? true
        : isCloseVisible,
      type: 'standard',
      onAction: onAction.bind(null, category, id),
      categoryIcon,
    };

    return (
      <UDropdownItem
        key={id}
        id={id}
        chipsProps={chipsProps}
        chipsMode="delete"
      />
    );
  };

  handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
    const { onAction, type } = this.props;

    if (type === CHIPS.TYPES.AGGREGATED) {
      event.stopPropagation();
      this.setState((prevState) => {
        return {
          isDropdownBoxOpen: !prevState.isDropdownBoxOpen,
        };
      });
    } else{
      onAction();
  };}

  handleClickOutside = (event: MouseEvent) => {
    if (this.node && !this.node.contains(event.target))
      this.setState({ isDropdownBoxOpen: false });
  };

  setWrapperRef = (node: Object) => {
    const { aggregateItems } = this.props;

    if (aggregateItems.length)
      this.node = node;
  };

  getActionVisibilityByChipsType = (chipsType: string) => {
    const { isCloseVisible } = this.props;

    const visibility = {
      [CHIPS.TYPES.STANDARD]: isCloseVisible,
      [CHIPS.TYPES.AGGREGATED]: true,
    };

    return visibility[chipsType];
  };

  getPaddingLeftByCategoryIconVisibility = (
    categoryIcon: string,
    type: string,
    chipsTypeStyle: Object
  ) => {
    const { padding } = chipsTypeStyle;

    return categoryIcon && type === CHIPS.TYPES.STANDARD
      ? CHIPS.PADDING_ICON
      : padding.paddingLeft;
  };

  getPaddingRightByActionIconVisibility = (
    chipsTypeStyle: Object,
    type: string,
  ) => {
    const { isCloseVisible } = this.props;
    const padding = { ...chipsTypeStyle.padding };

    if (type === CHIPS.TYPES.STANDARD && isCloseVisible === true
    || type === CHIPS.TYPES.AGGREGATED)
      return CHIPS.PADDING_ICON;

    return padding.paddingRight;
  };

  getChipsStyleByIconVisibility = (
    chipsTypeStyle: Object,
    type: string,
    categoryIcon: string
  ) => {
    const style = {
      ...chipsTypeStyle.padding,
      height: chipsTypeStyle.height,
      color: COLORS.TEXT.DEFAULT,
    };

    style.paddingLeft = this.getPaddingLeftByCategoryIconVisibility(
      categoryIcon,
      type,
      chipsTypeStyle
    );

    style.paddingRight = this.getPaddingRightByActionIconVisibility(
      chipsTypeStyle,
      type,
    );

    return style;
  };
}

export default UChips;
