import { t } from 'i18next';

import { mergeDeep } from 'Libs/mergeDeep';
import { MessageType } from 'Libs/ts/types';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { CategoryType } from 'ModelsReact/Category/Category';

export type CategoryTableData = Readonly<{
  entries: ReadonlyArray<any>; // ReadonlyArray<Row>
  total: number;
  loading: boolean;
}>;

export type CategoryDataAccessor = DeepReadonly<{
  id: number;
  data: {
    name: string;
    nbDocuments: number;
  };
}>;

type CategoryData = Readonly<
  CategoryType & {
    documentsCount: number;
  }
>;

type RowMessage = Readonly<null | {
  type: MessageType;
  content: string;
}>;


// !TODO: Change prototype to :  function createCategoryRow(category: CategoryData, message?: RowMessage): Row
export const createCategoryRow = function createCategoryRow(category: CategoryData, message?: RowMessage): any {
  const { locked } = category;

  let lockedInfo = null; // LockedTooltipInfo

  const lockedMessage = t('config_categories:categories_table.table_data');


  if (locked) {
    lockedInfo = {
      position: 'top',
      maxWidth: 320,
      content: lockedMessage,
    };
  }

  return {
    id: category.id,
    dataAccessor: {
      name: category.label,
      nbDocuments: category.documentsCount,
    },
    rowMessage: message,
    label: {
      id: `${category.id}_label`,
      type: 'UTableCellText',
      params: {
        type: 'text',
        value: category.label || '',
        editable: true,
        onSubmit: undefined,
        onReset: undefined,
      },
      wrapperStyle: {
        display: 'flex',
        flex: 1,
      },
    },
    locked,
    lockedInfo,
  };
};

function createTableData(values?: any) {
  const defaultState = {
    entries: [],
    total: 0,
    loading: false,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep(defaultState, values);
}

export default function createCategoryTableData(values?: any): CategoryTableData {
  const defaultEntries: CategoryTableData = createTableData();

  if (!values) {
    return defaultEntries;
  }

  return mergeDeep(defaultEntries, createTableData(values));
}
