// @flow

import { COLORS, ELEVATIONS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    borderRadius: 4,
    position: 'relative',
    ...TYPOGRAPHY.BODY3,
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 9px 0 15px',
    height: 32,
    alignItems: 'center',
    fontWeight: 700,
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  dateContext: {
    fontWeight: 400,
    marginLeft: 8,
  },
  picker: {
    zIndex: LEVELS.DROPDOWN,
    position: 'absolute',
    left: 0,
    backgroundColor: COLORS.WHITE.DEFAULT,
    minWidth: 270,
    minHeight: 265,
    boxShadow: ELEVATIONS.LOW,
  },
};
