// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 12;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

export default {
  wrapper: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    ...BORDER_RADIUS,
  },
  qrContainer: {
    paddingTop: 30,
    paddingLeft: 38,
    paddingBottom: 30,
    paddingRight: 38,
  },
  title: {
    paddingTop: 21,
    paddingLeft: 38,
    paddingRight: 38,
    textAlign: 'center',
    wordBreak: 'break-word',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
};
