import m from 'm';
import _ from 'lodash';
import Enum from 'models/Enum';
import popup from 'services/popupService';
import Checkbox from 'components/checkbox';
import radioSelect from 'components/radio.select';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.settings = args.settings;

  self.informationLabels = {
    'email'      : 'Their email',
    'facebook'   : 'Facebook connect',
    'linkedin'   : 'linkedIn connect',
    'mobile'     : 'Mobile phone',
    'corporateId': 'Corporate ID',
  };

  // Authentication
  self.authenticationTypeId = m.prop(null);
  self.authenticationTypes = [{
    label: m.prop('No verification'),
    id   : m.prop(Enum.AuthenticationTypes.noAuth),
  },
  {
    label   : m.prop('e-mail'),
    id      : m.prop(Enum.AuthenticationTypes.email),
    inactive: false,
  },
  {
    label   : m.prop('SMS'),
    id      : m.prop(Enum.AuthenticationTypes.sms),
    inactive: true,
  },
  {
    label   : m.prop('With an external ID'),
    id      : m.prop(Enum.AuthenticationTypes.externalId),
    inactive: true,
  },
  ];

  // Restriction
  self.restrictionTypeId = m.prop(null);
  self.restrictionTypes = [{
    label: m.prop('No Restriction (free access)'),
    icon : '/assets/img/ico-small-lock-open.svg',
    id   : m.prop(Enum.RestrictionTypes.noRestriction),
  },
  {
    label: m.prop('White List'),
    id   : m.prop(Enum.RestrictionTypes.whiteList),
    icon : '/assets/img/ico-small-list.svg',
  },
  {
    label   : m.prop('e-mail/domain'),
    id      : m.prop(Enum.RestrictionTypes.emailDomain),
    icon    : '/assets/img/ico-small-email.svg',
    inactive: true,
  },
  {
    label   : m.prop('With an external ID'),
    id      : m.prop(Enum.RestrictionTypes.corporateId),
    icon    : '/assets/img/ico-small-corporateid.svg',
    inactive: true,
  },
  {
    label: m.prop('Magic Code Only'),
    id   : m.prop(Enum.RestrictionTypes.code),
    icon : '/assets/img/ico-small-password.svg',
  },
  ];

  // White list
  self.whiteListTypeId = m.prop(null);
  self.whiteListTypes = [{
    label: m.prop('Unique'),
    id   : m.prop('uniq'),
  },
  {
    label   : m.prop('Segmented'),
    id      : m.prop('segmented'),
    inactive: true,
  },
  ];

  self.changeRestriction = function changeRestriction(selected) {
    if (selected.id() === Enum.RestrictionTypes.whiteList)
      console.log('whiteList!');
  };

  self.fileHandler = function fileHandler(replace, e) {

    var target = e.target;
    var file = target.files[0];

    if (file) {
      self.settings.uploadWhiteList(file, _.noop, {
        replace: replace,
      })
        .then(function openPopup(response) {
          popup.open({
            type    : 'info',
            title   : 'Success!',
            subtitle: ' ',
            content : response.count + ' email adressed has been white-listed.',
            button  : 'Ok',
          });
        })
        .catch(function couCou(err) {
          alert('upload failed', JSON.stringify(err));
        });
    }
  };

  self.destroy = function destroy() {
    self.settings.destroy()
      .then(function removeFromList() {
        args.removeRule(args.index);
      })
      .then(m.redraw);
  };

  self.save = function save() {
    self.settings.save()
      .then(function openPopup() {
        popup.open({
          type    : 'info',
          title   : 'Success!',
          subtitle: ' ',
          content : 'Settings have been saved',
          button  : 'Ok',
        });
      });
  };

};

component.view = function view(c, args) {
  c.restrictionTypes.map(function addSubBlock(restriction) {
    restriction.subBlock = generateSubChoices(c, args, restriction);

    return restriction;
  });

  return m('.detail__page.detail__page--section', [
    m('.detail__page__card', [
      m('.detail__page__panel .detail__page__panel--half', [
        m('.detail__signup', [
          setInformations(c, 'email'),
          setInformations(c, 'facebook', true),
          setInformations(c, 'linkedin', true),
          setInformations(c, 'mobile', true),
          setInformations(c, 'corporateId', true),
        ]),
      ]),
    ]),
  ]);
};

function generateSubChoices(c, args, restriction) {
  if (restriction.id() === Enum.RestrictionTypes.whiteList) {
    c.whiteListTypes.map(function setSublocks(type) {

      if (type.id() === 'uniq') {
        type.subBlock = m('upload', [
          uploadButton(c, args),
          uploadButton(c, args, true),
          m('a', {
            href: '/api//AuthorizedEmails/download',
          }, 'Download list .csv'),
        ]);
      }

      return type;
    });

    return m(radioSelect, {
      items        : c.whiteListTypes,
      value        : c.whiteListTypeId,
      displayPath  : 'label',
      selectionPath: 'id',
      block        : true,
      onselect     : c.changeWhiteListType,
    });
  }
}

function uploadButton(c, args, replace) {

  var text = replace ? 'Replace all from .csv file' : 'Add from .csv file';
  var type = replace ? 'button--delete' : 'button--action';

  return m('label.button ' + type + ' radio-item__sub-block--aligned button__upload', [
    m('.button__elem', [
      m('.button__value', text),
    ]),
    m('input.hide', {
      type    : 'file',
      onchange: c.fileHandler.bind(null, replace),
      accept  : '.csv',
    }),
  ]);
}

function setInformations(c, type, inactive) {
  return m('.detail__signup__check .detail__signup__check--inactive', {
    onclick: function disable(e) {
      inactive ? e.preventDefault() : null;
    },
    style: {
      opacity: inactive ? 0.3 : 1,
    },
  }, [
    type !== 'email' ? m(Checkbox) : '',
    m('.detail__signup__ico .detail__signup__ico--' + type),
    m('.detail__signup__info', c.informationLabels[type]),
  ]);
}

export default component;
