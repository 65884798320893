import m from 'm';

import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

import { isFeatureEnabled } from 'services/feature-flags';

const component = {};

const DOLPHIN_CONTENTS_FLAG_NAME = 'dolphin-contents';

component.view = function view() {
  return m('.activity-page', [
    m('.header', [m(menu)]),
    isFeatureEnabled(DOLPHIN_CONTENTS_FLAG_NAME)
      ? m(reactCreator, {
        component: 'DolphinActivityList',
        style: { height: '100%' },
      })
      : null,
  ]);
};

export default component;
