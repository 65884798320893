// @flow

import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import { COLORS } from 'Components/foundation';
import SCompactCallout from 'Components/structural/SCompactCallout/SCompactCallout';

import { actions, getLanguageDetail } from '../../../redux';
import type { AlertValidationField } from '../../../redux/models/Alert';

import styles from './SchedulingValidityCallout.style';

type Props = {|
  languageId: number,
  reason: AlertValidationField,
|};

const makeCalloutMessage = (languageLabel: string, reason: AlertValidationField) => {
  switch (reason) {
    case 'emptyDay': return t('activities:overview.empty_daily_session');
    default: return t('activities:overview.missing_translations', { languageLabel });
  }
};

export const SchedulingValidityCallout = ({ languageId, reason }: Props) => {
  const language = useMemo(() => getLanguageDetail(languageId), [languageId]);

  const dispatch = useTypedDispatch();
  const handleClick = useCallback(() => {
    dispatch(actions.selectLanguage(languageId));

    window.history.replaceState({}, null, `${window.location.pathname}/scheduling`);
  }, [languageId, dispatch]);

  return (
    <SCompactCallout
      color={COLORS.WARNING.DEFAULT}
      content={makeCalloutMessage(language.label, reason)}
      icon="arrow-right"
      label={t('activities:overview.validity_cta')}
      onClick={handleClick}
      style={styles.callout}
    />
  );
};
