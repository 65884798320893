import Enum from 'Models/Enum';
import i18next from 'i18next';

import {
  getAvailableSegmentationGroupsMapFromApp,
  getLanguageSegmentationGroupItemsFromApp,
} from 'Services/segmentation/segmentationService';

const LANGUAGE_SEG_TYPE_ID = 1;
const ENGLISH_LANGUAGE_CODE = 'en-US';

export type Language = {
  id: number;
  isDefault: boolean;
  languageName: string;
  languageCode: keyof typeof Enum.Languages;
  countryCode: string;
  availableGroupId: number | null;
};

export const getLanguagesAvailableWithCode = (): Language[] => {
  const availableSegmentationGroups = getAvailableSegmentationGroupsMapFromApp();
  const languageSegmentationGroupItems = getLanguageSegmentationGroupItemsFromApp();

  // Assuring that the group items are only language related by checking the presence of `availableGroupId`..
  // ..and that the typeId === LANGUAGE_SEG_TYPE_ID
  const filteredLanguagesAvailables = languageSegmentationGroupItems.filter(
    ({ availableGroupId }) =>
      availableGroupId && availableSegmentationGroups[availableGroupId]?.typeId === LANGUAGE_SEG_TYPE_ID,
  );

  return filteredLanguagesAvailables.map(({ id, label, isDefault, availableGroupId }) => {
    const languageCode = availableSegmentationGroups[availableGroupId!].data as Language['languageCode'];
    const countryCode = Enum.Languages[languageCode];

    return {
      id,
      isDefault,
      availableGroupId,
      languageName: label,
      languageCode,
      countryCode,
    };
  });
};

export const getLanguagesFromCode = (code: keyof typeof Enum.Languages): Language => {
  const languages = getLanguagesAvailableWithCode();
  return languages.find(({ languageCode }) => languageCode === code) as Language;
};

export const getCurrentBOLanguageOrDefault = (): Language => {
  const boLanguage = i18next.language;
  const languages = getLanguagesAvailableWithCode();

  const language =
    languages.find(({ languageCode }) => languageCode === boLanguage) || languages.find(({ isDefault }) => isDefault);

  if (!language) {
    throw Error('No bo language nor default language found');
  }
  return language;
};

export type MultilingualLabel = {
  [key in keyof typeof Enum.Languages]?: string;
};

/**
 * The rule is :
 * - If the BO language or default language is available we return the translated text in the BO language
 * - If not, we return the translated text in English
 * - If neither of these conditions are filled, e return them in the first available language alphabetically
 */
export const getTranslatedLabel = (multilingualLabel: MultilingualLabel) => {
  const { languageCode } = getCurrentBOLanguageOrDefault();

  const translatedTextInBOLanguage = multilingualLabel[languageCode];
  if (translatedTextInBOLanguage) {
    return translatedTextInBOLanguage;
  }

  const translatedTextInEnglish = multilingualLabel[ENGLISH_LANGUAGE_CODE];

  if (translatedTextInEnglish) {
    return translatedTextInEnglish;
  }

  const firstAvailableLanguage = Object.keys(multilingualLabel).sort()[0] as keyof typeof Enum.Languages;

  return multilingualLabel[firstAvailableLanguage];
};
