/* eslint-disable complexity */
import m from 'm';
import { t } from 'i18next';
import _ from 'lodash';
import T from 'types';
import popup from 'services/popupService';
import Bus from 'services/pubSubService';
import button from 'components/button';
import toggleButton from 'components/toggle.button';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.moreOpened = m.prop(false);

  self.modified = function modified() {
    var newActivity = args.activity.toJSON();
    var oldActivity = args.original && args.original.toJSON();

    // remove the campaign from the new campaign because it can be set with default attributes
    if (newActivity.breakingNewsCard && newActivity.breakingNewsCard.campaign)
      delete newActivity.breakingNewsCard.campaign;

    delete newActivity.active;

    // check if we're in creation mode
    if (oldActivity)
      delete oldActivity.active;

    return !_.isEqual(newActivity, oldActivity);
  };

  self.updateActivity = function updateActivity(_args) {
    _args = _args || args;

    return _args.activity.updateSimple(_args.activity)
      .then(function applyChanges(updatedActivity) {
        var finalActivity = _args.activity.clone();

        finalActivity.active(updatedActivity.active());
        Bus.trigger('activity:save', finalActivity);
      });
  };

  self.updateToogle = function updateToogle(_args) {
    _args = _args || args;

    return _args.activity.updateSimple(_args.activity)
      .then(function applyChanges(updatedActivity) {

        _args.activity.active(updatedActivity.active());
        Bus.trigger('activity:save', _args.activity);
      });
  };

  self.activeActivity = function activeActivity(_args, _isToogle) {
    _args = _args || args;

    var activityStatus = isActivityReady();

    if (!activityStatus.isReady) {
      args.activity.active(false);

      return popup.open({
        type    : 'info',
        subtitle: t('mithril_components:activity_toolbar.solve_the_following_issue_first'),
        title   : t('mithril_components:activity_toolbar.activity_not_ready'),
        content : activityStatus.errMessage,
      });
    }
    if (_isToogle) return self.updateToogle(_args);

    return self.updateActivity(_args);

    function isActivityReady() {
      var result = true;

      return { isReady: result, errMessage: null };
    }
  };

  self.onPopupClose = function onPopupClose() {
    m.route(m.route());
  };

  self.confirmArchiveActivity = function confirmArchiveActivity(_args) {

    if (args.activity.active()) {
      popup.open({
        type    : 'confirm',
        title   : t('mithril_components:activity_toolbar.sorry_cant_do_that'),
        subtitle: t('mithril_components:activity_toolbar.deactivate_the_ability_if_you_want_to_delete_it'),
        content: t('mithril_components:activity_toolbar.we_highly_recommend_not_to_delete_the_activity'),
        confirm : t('mithril_components:activity_toolbar.alright'),
        action  : function action() {},
      });

      return;
    }

    popup.open({
      type    : 'confirm',
      title   : t('mithril_components:activity_toolbar.oh_really'),
      subtitle: t('mithril_components:activity_toolbar.your_are_about_to_delete_the_activity', {
        name: args.activity.name(),
      }),
      content : t('mithril_components:activity_toolbar.all_tracking_information_for_this_activity_will_be_lost'),
      confirm : t('mithril_components:activity_toolbar.archive'),
      action  : archiveActivity.bind(null, _args.activity),
    });

    function archiveActivity(_activity) {
      _activity.archived(true);

      self.updateActivity(_args)
        .then(function handleResult() {
          console.log('[activity.toolbar]', 'Done archiving', _activity.name());
        });
    }

  };

  self.confirmUnarchiveActivity = function confirmArchiveActivity(_args) {

    popup.open({
      type    : 'confirm',
      title   : t('mithril_components:activity_toolbar.oh_really'),
      subtitle: t('mithril_components:activity_toolbar.you_are_about_to_unarchive_the_activity', { name: args.activity.name() }),
      content : t('mithril_components:activity_toolbar.this_activity_will_be_back_in_the_list'),
      confirm : t('mithril_components:activity_toolbar.unarchive'),
      action  : unarchiveActivity.bind(null, _args.activity),
    });

    function unarchiveActivity(_activity) {
      _activity.archived(false);

      self.updateActivity(_args)
        .then(function handleResult() {
        });
    }

  };

  self.duplicateActivity = function duplicateActivity() {
    var _activity = args.activity;

    _activity.duplicate()
      .then(function handleResult(dupe) {
        m.route(dupe.getRoute());
      });
  };

  self.back = function back() {
    if (args.back)
      args.back();
    else
      m.route('/activity');
  };

};

component.view = function view(c, args) {
  const ACTIVITY_STATUS_LABEL = {
    live: t('mithril_components:activity_toolbar.live'),
    inactive: t('mithril_components:activity_toolbar.inactive'),
    upcoming: t('mithril_components:activity_toolbar.upcoming'),
    finished: t('mithril_components:activity_toolbar.finished'),
    archived: t('mithril_components:activity_toolbar.archived'),
  }
  
  var activity = args.activity;
  var isNew = !activity.id();
  var status = activity.status();

  return m('.activity-toolbar', [
    m(button, {
      class : 'button--action button--ico ico--left ico--back',
      action: c.back,
      value : t('mithril_components:activity_toolbar.back'),
    }),
    m('.activity__status__toggle-container', [
      m('.activity__status__toggle', {
        title: isNew ? t('mithril_components:activity_toolbar.this_activity_has_not_been_saved') : '',
        class: isNew ? 'activity__status__toggle--disabled' : '',
      }, [
        m(toggleButton, {
          disabled: isNew,
          value   : activity.active,
          onclick : c.activeActivity.bind(null, args, true),
          class   : 'activity-toggle',
        }),
        m('.activity-active', activity.active() ? t('mithril_components:activity_toolbar.active') : t('mithril_components:activity_toolbar.inactive')),
      ]),
    ]),
    m('.activity-status', {
      class: [
        'activity--' + status,
      ].join(' '),
    }, [
      ACTIVITY_STATUS_LABEL[status]
    ]),
    m('.activity-toolbar__right', [
      m('.toolbar__actions.activity-more.activity-more--bottomLeft', {
        onclick     : c.moreOpened.bind(null, !c.moreOpened()),
        onmouseleave: c.moreOpened.bind(null, false),
        class       : c.moreOpened() ? 'show' : '',
      }, [
        '...',
        m('.activity-more__hoverbox.hoverbox', [
          m('.hoverbox__handler', [
            m(button, {
              disabled: isNew || args.activity.pendingTranslation(),
              class   : 'hoverbox__elem button--duplicate button--duplicate-knowledge button--w100',
              value   : t('mithril_components:activity_toolbar.duplicate'),
              action  : c.duplicateActivity,
            }),
            args.activity.archived()
              ? m(button, {
                disabled: isNew,
                class   : 'hoverbox__elem button--unarchive button--w100',
                value   : t('mithril_components:activity_toolbar.unarchive'),
                action  : c.confirmUnarchiveActivity.bind(null, args),
              })
              : '', !args.activity.archived()
              ? m(button, {
                class   : 'hoverbox__elem button--archive button--archive-knowledge button--w100',
                value   : t('mithril_components:activity_toolbar.archive_activity'),
                action  : c.confirmArchiveActivity.bind(null, args),
                disabled: isNew
                || args.activity.archived(),
              })
              : '',
          ]),
        ]),
      ]),
      m(button, {
        class: [
          'activity-toolbar__button--save',
          'button--action',
        ].join(' '),
        action  : args.saveActivity,
        value   : activity.id() ? t('mithril_components:activity_toolbar.save') : t('mithril_components:activity_toolbar.create'),
        loading : (args.saving !== undefined) ? args.saving : false,
        disabled: (args.canSave !== undefined) ? !args.canSave : !c.modified(),
      }),
    ]),
  ]);
};

export default component;
