import React, { useCallback, useMemo, useEffect, useState } from 'react';
import sha256 from 'sha256';
import { t } from 'i18next';
import m from 'mithril';

import instance from 'Services/instanceConfig';
import Enum from 'Models/Enum';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

import { FilterState } from 'Libs/filter/types';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { useSearchParams } from 'Hooks/useSearchParams/useSearchParams';

import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import SActivityListItem from 'Components/structural/SActivityListItem/SActivityListItem';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import SDivider from 'Components/structural/SDivider/SDivider';
import { SFooter } from 'Components/structural/SFooter/SFooter';
import MActivityCreationModal from 'Components/modal/MActivityCreationModal/MActivityCreationModal';
import UButton from 'Components/unit/UButton/UButton';
import UIcon from 'Components/unit/UIcon/UIcon';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { COLORS } from 'Components/foundation';

import { actions } from './redux';
import type { ActivityListItemType } from './redux/models/ActivityListItem';

import { ArchiveActivityModal } from './components/ArchiveActivityModal/ArchiveActivityModal';
import { CampaignForm, CampaignMode } from './components/CampaignForm/CampaignForm';

import { getActivitiesDefinition } from './ActivitiesDescription';
import styles from './ActivityList.style';

type ItemType = {
  activity: ActivityListItemType;
  index: number;
  arr: ActivityListItemType[];
  onArchiveClick: (id: number, archived: boolean) => void;
};

type ActivityListPropsType = {
  onArchiveClick: (id: number, archived: boolean) => void;
};

const RANGE = 10;

const EmptyComponent = () => {
  return (
    <>
      <UIcon name="search" color={COLORS.BLUE_GRAY_DARK.DEFAULT} size={48} style={styles.emptySearchIcon} />
      <div style={styles.emptySearchText}>{t('activities:list.no_activity_found')}</div>
    </>
  );
};

const LoadMoreButton = () => {
  const dispatch = useTypedDispatch();
  const isLoadingMore = useTypedSelector((state) => state.pages.activityList.isLoadingMore);
  const activities = useTypedSelector((state) => state.pages.activityList.activities);
  const isEmpty = useMemo(() => activities.length === 0, [activities]);
  const counter = useTypedSelector((state) => state.pages.activityList.counter);
  const canShowMore = useMemo(() => !isEmpty && counter - activities.length > 0, [activities, counter, isEmpty]);

  const handleLoadMore = useCallback(() => {
    const action = actions.getFullPaginated(activities.length, RANGE);

    dispatch(action);
  }, [dispatch, activities]);

  if (!canShowMore) return null;

  return (
    <div style={styles.loadMore}>
      <SFooter>
        <UTextLink
          text={t('activities:list.load_more')}
          onClick={handleLoadMore}
          type="secondary"
          rightIcon="bottom-chevron"
        />
      </SFooter>
    </div>
  );
};

const ActivityItem = ({ activity, index, arr, onArchiveClick }: ItemType) => {
  const activityStyle = useMemo(() => {
    if (!index) return { ...styles.listItem, ...styles.firstListItem };

    return styles.listItem;
  }, [index]);

  let title = t('activities:list.archive_activity'),
    color = COLORS.ERROR.DEFAULT,
    archived = false;

  if (activity.activityStatus === 'Archived') {
    title = t('activities:list.unarchive_activity');
    color = COLORS.TEXT.DEFAULT;
    archived = true;
  }

  const callback = useCallback(() => {
    onArchiveClick(activity.multilingualId, archived);
  }, [onArchiveClick, activity.multilingualId, archived]);

  const options = useMemo(() => {
    return [
      {
        title,
        callback,
        color,
      },
    ];
  }, [title, callback, color]);

  const handleClick = useCallback(() => {
    m.route(activity.route);
  }, [activity]);

  const handleAnalyticsClick = useCallback((activityIds: Readonly<number[]>) => {
    const url =
      'https://analytics.sparted.com/app?instance=' + encodeInstanceId() + '&activityId=' + activityIds.join(';');

    window.open(url, '_blank');
  }, []);

  return (
    <div key={`activityItemContainer_${activity.multilingualId}`}>
      <SActivityListItem
        key={`activityListItem_${activity.multilingualId}`}
        activityType={activity.activityType}
        id={activity.multilingualId}
        activityIds={activity.activityIds}
        title={activity.title}
        titlePlaceholder={t('activities:list.activity_title_placeholder')}
        activityStatus={activity.activityStatus}
        activityStatusLabel={activity.activityStatusLabel}
        startDate={activity.startDate}
        endDate={activity.endDate}
        onClick={handleClick}
        onAnalyticsClick={handleAnalyticsClick}
        options={options}
        languages={activity.languages}
        image={activity.image}
        style={activityStyle}
        audience={activity.audience}
        audiencePlaceholder={t('activities:list.item.audience.no_target_mode')}
        audienceEmptySegmentsLabel={t('activities:list.item.audience.empty_segments_label')}
        hasPlayerPlayed={activity.hasPlayerPlayed}
        views={activity.views}
        likes={activity.likes}
      />
      {index + 1 < arr.length && arr[index].activityStatus === 'Live' && arr[index + 1].activityStatus !== 'Live' ? (
        <SDivider style={styles.divider} />
      ) : null}
    </div>
  );
};

const ActivitiesList = ({ onArchiveClick }: ActivityListPropsType) => {
  const activities = useTypedSelector((state) => state.pages.activityList.activities);

  const nodes = activities.map((activity, idx, arr) => (
    <ActivityItem
      key={activity.multilingualId}
      activity={activity}
      index={idx}
      arr={arr}
      onArchiveClick={onArchiveClick}
    />
  ));

  return <>{nodes}</>;
};

const ListBody = () => {
  const dispatch = useTypedDispatch();

  const isLoading = useTypedSelector((state) => state.pages.activityList.isLoading);
  const activities = useTypedSelector((state) => state.pages.activityList.activities);
  const isFiltering = useTypedSelector((state) => state.pages.activityList.isFiltering);
  const isCreationLoading = useTypedSelector((state) => state.pages.activityList.isCreationLoading);
  const isArchiveLoading = useTypedSelector((state) => state.pages.activityList.isArchiveLoading);

  const [isVisible, setVisible] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [campaignMode, setCampaignMode] = useState<CampaignMode>('manual');
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [activityIdToArchive, setActivityIdToArchive] = useState(0);
  const [isFirstStep, setFirstStep] = useState(true);
  const params = useSearchParams(window.location, 'newActivity');

  const disableActionButton = useMemo(() => {
    return !isFirstStep && !(campaignName && selectedLanguage);
  }, [campaignName, selectedLanguage, isFirstStep]);

  useEffect(() => {
    if (!isArchiveLoading) setActivityIdToArchive(0);
  }, [isArchiveLoading]);

  const handleArchiveClick = useCallback(
    (id: number, archived: boolean) => {
      if (!archived) setActivityIdToArchive(id);
      else dispatch(actions.updateArchiveStatus(id, false));
    },
    [dispatch],
  );

  const handleActionArchive = useCallback(
    (success: boolean) => {
      if (!success) setActivityIdToArchive(0);
      else dispatch(actions.updateArchiveStatus(activityIdToArchive, true));
    },
    [dispatch, activityIdToArchive],
  );

  const handleCreationClick = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSecondAction = useCallback(() => {
    setVisible(false);
    setFirstStep(true);
    setCampaignName('');
    setSelectedLanguage(0);
  }, []);

  const handleBackPress = useCallback(() => {
    setFirstStep(true);
  }, []);

  const handleNameChange = useCallback((value: string) => {
    setCampaignName(value);
  }, []);

  const handleSelectLanguage = useCallback((value: SegmentationGroupItemType) => {
    setSelectedLanguage(value.id);
  }, []);

  const handleSelectCampaignMode = useCallback((mode: CampaignMode) => {
    setCampaignMode(mode);
  }, []);

  const handleActivityCreation = useCallback(
    (type: number) => {
      if (type === Enum.activityTypeEnum.DAILY_SERIE) {
        if (isFirstStep) {
          setFirstStep(false);

          return;
        }

        dispatch(actions.createMultiligualCampaign(campaignName, selectedLanguage, campaignMode === 'random'));

        return;
      }

      const newUrl = {
        [Enum.activityTypeEnum.WELCOME]: '/activity/welcome/new',
        [Enum.activityTypeEnum.INTERSEASON]: '/activity/interseason/new',
        [Enum.activityTypeEnum.BREAKING_NEWS]: '/activity/breakingnews/new',
        [Enum.activityTypeEnum.THATS_ALL_FOLKS]: '/activity/thatsAllFolks/new',
      };

      m.route(newUrl[type]);

      handleSecondAction();
    },
    [isFirstStep, handleSecondAction, dispatch, campaignName, campaignMode, selectedLanguage],
  );

  // get params from Homepage
  useEffect(() => {
    if (params === 'campaign') {
      setVisible(true);
      setFirstStep(false);
    }
  }, [params]);

  const activitiesDefinition = useMemo(() => getActivitiesDefinition(), []);

  if (isLoading) {
    return (
      <div style={styles.pageLoader}>
        <SPageLoaderWithTimer isLoading={isLoading} style={styles.pageLoader} />
      </div>
    );
  }

  return (
    <>
      <UButton
        style={styles.createButton}
        text={t('activities:list.create_activity')}
        type="accentuated"
        onClick={handleCreationClick}
        trackingAction="create"
        trackingContext="activity"
      />

      {!activities.length && isFiltering ? (
        <EmptyComponent />
      ) : (
        <>
          {activities.length && activities[0].activityStatus === 'Live' ? (
            <div style={styles.liveTitle}>{t('activities:list.live_activities')}</div>
          ) : null}
          <ActivitiesList onArchiveClick={handleArchiveClick} />
          <LoadMoreButton />
          <ArchiveActivityModal
            visible={!!activityIdToArchive}
            onAction={handleActionArchive}
            isArchiveLoading={isArchiveLoading}
          />
        </>
      )}
      <MActivityCreationModal
        visible={isVisible}
        isLoading={isCreationLoading}
        onClose={handleCloseModal}
        activities={activitiesDefinition}
        labelTab={t('activities:modals.activity_creation_modal.tab_label')}
        labelSecondButton={t('activities:modals.activity_creation_modal.cancel')}
        labelActionButton={
          isFirstStep
            ? t('activities:modals.activity_creation_modal.action1')
            : t('activities:modals.activity_creation_modal.action2')
        }
        labelNextAction={t('activities:modals.activity_creation_modal.next')}
        title={
          isFirstStep
            ? t('activities:modals.activity_creation_modal.title1')
            : t('activities:modals.activity_creation_modal.title2')
        }
        onCreate={handleActivityCreation}
        onSecondAction={handleSecondAction}
        isFirstStep={isFirstStep}
        disableActionButton={disableActionButton}
      >
        <CampaignForm
          name={campaignName}
          selectedLanguageId={selectedLanguage}
          onNameChange={handleNameChange}
          onLanguageSelect={handleSelectLanguage}
          onCampaignModeSelect={handleSelectCampaignMode}
          selectedCampaignMode={campaignMode}
          onBack={handleBackPress}
        />
      </MActivityCreationModal>
    </>
  );
};

export const ActivityList = () => {
  const dispatch = useTypedDispatch();

  const segmentationIds = useTypedSelector((state) => state.pages.activityList.selectedSegmentationIds);
  const isCreationLoading = useTypedSelector((state) => state.pages.activityList.isCreationLoading);
  const selectedCampaign = useTypedSelector((state) => state.pages.activityList.selectedCampaign);
  const statusId = useTypedSelector((state) => state.pages.activityList.selectedActivityStatusId);
  const textValues = useTypedSelector((state) => state.pages.activityList.selectedTextValues);
  const counter = useTypedSelector((state) => state.pages.activityList.counter);
  const isFiltering = useTypedSelector((state) => state.pages.activityList.isFiltering);
  const selectedSegmentationIds = useTypedSelector((state) => state.pages.activityList.selectedSegmentationIds);
  const selectedActivityTypeId = useTypedSelector((state) => state.pages.activityList.selectedActivityTypeId);
  const selectedActivityStatusId = useTypedSelector((state) => state.pages.activityList.selectedActivityStatusId);
  const selectedTargetModeId = useTypedSelector((state) => state.pages.activityList.selectedTargetModeId);
  const selectedActivityLanguageIds = useTypedSelector((state) => state.pages.activityList.selectedActivityLanguageIds);
  const selectedTextValues = useTypedSelector((state) => state.pages.activityList.selectedTextValues);
  const description = useMemo(() => {
    if (!isFiltering) {
      if (!counter) return t('activities:list.filter.no_activity_label');

      return t('activities:list.filter.activity_created', { count: counter });
    }

    return t('activities:list.filter.activity_found', { count: counter });
  }, [isFiltering, counter]);

  const audienceModeId = useTypedSelector((state) => state.pages.activityList.selectedTargetModeId);
  const languageIds = useTypedSelector((state) => state.pages.activityList.selectedActivityLanguageIds);

  const filters: Array<FilterState> = useMemo(() => {
    return [
      { category: 'text', values: textValues.concat() },
      { category: 'segment', ids: segmentationIds.concat() },
      { category: 'language', ids: languageIds.concat() },
      { category: 'activityType', ids: [selectedActivityTypeId] },
      { category: 'activityStatus', ids: [statusId] },
      { category: 'audienceMode', ids: [audienceModeId] },
    ];
  }, [segmentationIds, selectedActivityTypeId, statusId, textValues, audienceModeId, languageIds]);

  useEffect(() => {
    const action = actions.getFullPaginated(0, RANGE);

    dispatch(action);

    return action.cancel;
  }, [
    dispatch,
    selectedSegmentationIds,
    selectedActivityTypeId,
    selectedActivityStatusId,
    selectedTargetModeId,
    selectedActivityLanguageIds,
    selectedTextValues,
  ]);

  useEffect(() => {
    if (!isCreationLoading && selectedCampaign) {
      m.route(`/activity/dailyserie/${selectedCampaign}`);
    }
  }, [selectedCampaign, isCreationLoading]);

  // reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  const handleFilterChange = useCallback(
    (selectedFilters: Array<FilterState>) => {
      dispatch(actions.updateFilters(selectedFilters));
    },
    [dispatch],
  );

  return (
    <div style={styles.wrapper}>
      <SFilterBox
        title={t('activities:list.filter.title')}
        description={description}
        placeholder={t('activities:list.filter.placeholder')}
        filters={filters}
        onChange={handleFilterChange}
        count={counter}
      />
      <div style={styles.bodyWrapper}>
        <div style={styles.listWrapper}>
          <ListBody />
        </div>
      </div>
    </div>
  );
};

function encodeInstanceId() {
  const salt = 'sparted42';

  return sha256(instance.instanceId + salt);
}
