// @flow

import * as React from 'react';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { t } from 'i18next';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UDatePicker.style';


type Props = {|
  style: ?Object,
  date: Date | null,
  onClick: Date => mixed,
  disabled?: boolean,
|};

type State = {|
  isSelected: boolean,
|};

const DIST_INPUT_TO_PICKER = 4;
const HEIGHT_PICKER_INPUT = 32;
const HEIGHT_PICKER = 299;
const POSITION_PICKER = HEIGHT_PICKER_INPUT + DIST_INPUT_TO_PICKER;


export class UDatePicker extends React.PureComponent<Props, State> {
  pickerInput: Object;

  static defaultProps = {
    style: undefined,
    disabled: false,
  };

  constructor(props: Props) {
    super(props);

    this.pickerInput = React.createRef();

    this.state = {
      isSelected: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickLeave, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickLeave, false);
  }

  render() {
    const { style, disabled } = this.props;
    const { isSelected } = this.state;

    const borderColor = isSelected ? COLORS.GREY_DARK.OUTLINE_FOCUSED : COLORS.GREY_DARK.DEFAULT;
    const borderStyle = {
      border: `1px solid ${borderColor}`,
    };

    const wrapperStyle = {
      ...styles.dateWrapper,
      ...(disabled ? styles.disabled : {}),
    };

    return (
      <div
        ref={this.pickerInput}
        style={{ ...styles.wrapper, ...borderStyle, ...style }}
      >
        <div data-testid="DATE_PICKER_WRAPPER" onClick={this.handleClick} style={wrapperStyle}>
          <div>
            {this.getFormattedDate()}
            {this.getDateContext()}
          </div>
          <UIcon
            color={COLORS.TEXT.SECONDARY_DEFAULT}
            name="calendar"
            size={16}
          />
        </div>
        {this.renderPicker()}
      </div>
    );
  }

  renderPicker = () => {
    const { date } = this.props;
    const { isSelected } = this.state;

    const pickerDate = date || new Date();

    if (!isSelected)
      return null;

    const modifiersStyles = {
      all: {
        height: 34,
        width: 34,
        padding: 'unset',
      },
    };
    const modifiers = {
      all: () => true,
    };
    const positionElement = {};

    if (this.pickerInput.current)
      positionElement[this.getPositionReference()] = POSITION_PICKER;

    return (
      <div style={{ ...styles.picker, ...positionElement }}>
        <DayPicker
          onDayClick={this.handleDayClick}
          selectedDays={pickerDate}
          month={pickerDate}
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
        />
      </div>
    );
  };

  handleDayClick = (day: Date) => {
    const { onClick } = this.props;

    onClick(day);
    this.setState({
      isSelected: false,
    });
  };

  handleClick = () => {
    const { isSelected } = this.state;
    const { disabled } = this.props;

    if (disabled)
      return;

    this.setState({ isSelected: !isSelected });
  };

  handleClickLeave = (e: MouseEvent) => {
    if (this.pickerInput.current.contains(e.target))
      return;

    this.setState({ isSelected: false });
  };

  getFormattedDate = () => {
    const { date } = this.props;

    return date === null
      ? '— / — / —'
      : moment(date).format('DD / MM / YYYY');
  };

  getDateContext = () => {
    const { date } = this.props;

    const nowDate = new Date();

    let color = '',
      dateContext = '';

    if (moment(date).isBefore(moment(nowDate), 'days')) {
      color = COLORS.WARNING.DEFAULT;
      dateContext = t('unit_components:u_date_picker.past_date');
    }

    if (moment(date).isSame(moment(nowDate), 'days')) {
      color = COLORS.TEXT.SECONDARY_DEFAULT;
      dateContext = t('unit_components:u_date_picker.today');
    }

    return (
      <span style={{ ...styles.dateContext, color }}>
        {dateContext}
      </span>
    );
  };

  getPositionReference = () => {
    const positionTopOfPickerInput = this.pickerInput.current.getBoundingClientRect().top;
    const pageHeight = window.innerHeight;
    const spaceUnderPickerInput = pageHeight - positionTopOfPickerInput - HEIGHT_PICKER_INPUT;

    const isPlaceUnderInput = spaceUnderPickerInput > HEIGHT_PICKER + DIST_INPUT_TO_PICKER;
    const isPlaceAboveInput = positionTopOfPickerInput > HEIGHT_PICKER;

    if (isPlaceUnderInput || (!isPlaceUnderInput && !isPlaceAboveInput))
      return 'top';

    return 'bottom';
  };
}

export default UDatePicker;
