import React from 'react';

import SDailySessionInfo, { SDailySessionInfoProps } from 'Components/structural/SDailySessionInfo/SDailySessionInfo';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import styles from './Header.style';

type HeaderProps = SDailySessionInfoProps & {
  onDelete?: () => void;
  showDeleteButton?: boolean;
};

export const Header = ({
  variant,
  contentCount,
  sessionNumber,
  startDate,
  showDeleteButton,
  onDelete,
}: HeaderProps) => (
  <div style={styles.container}>
    <SDailySessionInfo
      variant={variant}
      contentCount={contentCount}
      sessionNumber={sessionNumber}
      startDate={startDate}
    />
    {showDeleteButton && onDelete && (
      <UIconButton ghost type="standard-dark" size="L" icon="delete" style={styles.icon} onClick={onDelete} />
    )}
  </div>
);
