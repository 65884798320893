import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  clearAllButton: {
    height: '26px',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginRight: 4,
  },
  chipDiv: {
    marginTop: 20,
  },
  chipList: {
    display: 'inline',
  },
  filterSelect: {
    marginRight: 14,
  },
});

export default styleSheet;
