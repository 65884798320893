/* eslint-disable complexity */
import m from 'm';
import menu from 'pages/menu';
import analyticsMenu from 'components/analytics.menu';
import reactCreator from 'components/react.creator';

var component = {};

component.controller = function controller() {
  //
};

component.view = function view(c) {
  return m('.nps-page', [
    m('.analytics-page__menu', [
      m(menu),
      m(analyticsMenu),
    ]),
    m(reactCreator, {
      component: 'NPS',
      style    : { height: '100%' },
      props    : {},
    }),
  ]);
};

export default component;
