// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    padding: '0 20px 32px 20px',
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  label: {
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
    marginBottom: 16,
    display: 'block',
  },
};
