import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UCheckbox, { CheckBoxStatus } from 'Components/unit/UCheckbox/UCheckbox';

import styles from './DoNotShowAgainPrompt.style';
export type DoNotShowAgainPromptProps = {
  onClick: (checked: boolean) => void;
};

export const DoNotShowAgainPrompt = ({ onClick }: DoNotShowAgainPromptProps) => {
  const { t } = useTranslation();

  const [checkboxStatus, setCheckboxStatus] = useState<CheckBoxStatus>('unchecked');

  const handleClick = useCallback(
    (newStatus: CheckBoxStatus) => {
      setCheckboxStatus(newStatus);
      onClick(newStatus === 'checked');
    },
    [onClick],
  );

  return (
    <div style={styles.wrapper}>
      <UCheckbox checkBoxStatus={checkboxStatus} onClick={handleClick} style={styles.checkbox} type="accentuated" />
      <p style={styles.text}>{t('courses:do_not_show_prompt')}</p>
    </div>
  );
};
