import { SwipingCardPosition } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import type { CSSProperties } from 'react';
import React from 'react';

import STextArea from 'Components/structural/STextArea/STextArea';
import STooltip from 'Components/structural/STooltip/STooltip';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker, { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';
import URadioButton from 'Components/unit/URadioButton/URadioButton';
import { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from 'Pages/DolphinContent/GameplaysForm/gameplay.style';

import styles from './SSwipingCard.style';
import { swipingCardsIconMap } from './SSwipingCard.utils';

const tooltipId = 'swiping-card-delete-tooltip';

export type SSwipingCardRadioOptions = {
  id: SwipingCardPosition;
  label: string;
};

export type SSwipingCardProps = {
  style?: CSSProperties;
  title: string;
  selected: SwipingCardPosition;
  radioOptions: SSwipingCardRadioOptions[];
  image?: string;
  onImageChange?: (image: PickerSelectedImage) => void;
  onTextAreaChange?: (text: string) => void;
  onDelete: () => void;
  onSelect: (id: SwipingCardPosition) => void;
  maxLength?: number;
  textAreaValue?: string;
  disableDelete: boolean;
  disableDeleteTooltipText?: string;
};

export const SSwipingCard = ({
  style = {},
  title,
  image,
  selected,
  maxLength,
  radioOptions,
  onImageChange,
  onSelect,
  onTextAreaChange,
  onDelete,
  textAreaValue,
  disableDelete,
  disableDeleteTooltipText,
}: SSwipingCardProps) => (
  <div style={{ ...styles.wrapper, ...style }}>
    <div style={styles.topRow}>
      <ULabel size="M">{title}</ULabel>
      <div data-tip data-for={tooltipId}>
        <UIconButton icon="delete" onClick={onDelete} ghost disabled={disableDelete} />
        {disableDelete && disableDeleteTooltipText && (
          <STooltip content={disableDeleteTooltipText} id={tooltipId} position="top" maxWidth={500} />
        )}
      </div>
    </div>
    <div style={styles.coverRow}>
      <div style={styles.coverColumn}>
        <UMediaPicker dropArea size="L" image={image} media={{ mediaType: 'image', onChange: onImageChange }} />
      </div>
      <div style={styles.textAreaWrapper}>
        <STextArea
          textAreaTypo="BODY2"
          canResize={false}
          placeholder={t('gameplays:swiping_cards.text_placeholder')}
          height="72"
          onChange={onTextAreaChange}
          value={textAreaValue}
          maxLength={maxLength}
          style={styles.textArea}
          radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
        />
      </div>
    </div>
    <div style={styles.radioOptions}>
      {radioOptions.map((option, index) => (
        <div key={option.id} style={styles.radioWrapper}>
          <URadioButton
            selected={selected === option.id}
            onSelect={() => onSelect(option.id)}
            iconLabel={swipingCardsIconMap.get(radioOptions.length)?.[index]}
            label={option.label}
            size="S"
            type="accentuated"
          />
        </div>
      ))}
    </div>
  </div>
);

export default SSwipingCard;
