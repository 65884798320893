import './libs/polyfills.js';
import './bootstrap';
import './initModels';

import LS from 'Services/localStorageService';
import { fetchFactory } from 'Libs/http/utils';

import m from 'm';
import Bus from 'services/pubSubService';
import User from 'services/userService';
import Config from 'services/instanceConfig';
import routeConfig from 'services/routeConfig';
import { initFeatureFlags } from 'services/feature-flags';
import { initUserIdentity, initTracking } from 'services/trackingService';
import { initLou, identifyLouUser } from 'services/louIntegration';
import Router from 'services/router';
import App from 'models/App';

import { initAppConfiguration } from 'Store/entities/configuration/utils';


import Sentry from './libs/sentry/Sentry';
import initI18n from './libs/i18n/i18n';
import { initMomentLanguages } from './libs/i18n/moment-i18n';

const isCI = process.env.CI === 'true';

initAppConfiguration();


const executeThirdPartyPromise = async (promiseFn) => {
  try {
    return await promiseFn();
  } catch (error) {
    console.error('[main]', 'Error while initializing third party', error);
    Sentry.captureException(error);
    return;
  }
}

const initThirdParties = async () => {
  if (!isCI) {
    await Promise.allSettled([
      executeThirdPartyPromise(initTracking),
      executeThirdPartyPromise(initLou),
    ]);
  }

  await executeThirdPartyPromise(initFeatureFlags);
  await executeThirdPartyPromise(initI18n);
}

init();

function init() {
  initMomentLanguages();

  App.init(new Router(m, routeConfig));

  // Start the mocking conditionally.
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');

    worker.start({ onUnhandledRequest: 'bypass' });
  }

  initThirdParties()
    .then(User.isLogged)
    .then(authenticatedRouter)
    .catch((error) => {
      if (error) {
        console.log('[main]', 'Error while initializing the app', error);
        Sentry.captureException(error);
      }

      return unauthenticatedRouter();
    });
}

function authenticatedRouter() {
  console.debug('[main]', 'Logged in!');

  const getUserPromise = User
    .refresh()
    .tap(identifyUserInMixin);

  return App
    .start()
    .then(() => Sentry.init(Config.instanceId))
    .then(() => getUserPromise)
    .then(function initOrNot(user) {
      App.router.authenticatedRouter();

      Bus.off('login', authenticatedRouter);
      Bus.on('logout', unauthenticatedRouter);
    });
}

function unauthenticatedRouter(err) {
  App.router.unauthenticatedRouter();

  Bus.off('logout', unauthenticatedRouter);
  Bus.on('login', authenticatedRouter);
}

// eslint-disable-next-line complexity
function identifyUserInMixin(user) {
  if (user && user.id && !isCI) {
    const [roleIdProp] = user.roles().map((role) => role.id).sort();
    const roleId = roleIdProp();

    identifyLouUser({
      uniqueId: `${Config.instanceId}-${user.id()}`,
      instanceId: Config.instanceId,
      roleId,
    });

    initUserIdentity({
      id: user.id(),
      createdAt: user.createdAt().toString(),
      globalScore: user.globalScore(),
      isAdmin: user.isAdmin(),
      isMasterAdmin: user.isMasterAdmin(),
      workLocation: user.workLocation(),
    });
  }
}
