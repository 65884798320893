import { TYPOGRAPHY } from 'Components/foundation';
import COLORS from 'Components/foundation/colors';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  formWrapper: {
    width: 400,
  },
  instruction: {
    ...TYPOGRAPHY.H2,
    marginTop: 16,
  },
  phoneInput: {
    marginTop: 57,
    width: 270,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 114,
    alignItems: 'center',
  },
  submitButton: {
    marginRight: 24,
    height: 'fit-content',
  },
  errorMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
    maxWidth: 175,
  },
});

export default styleSheet;
