import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const baseBorder = {
  display: 'inline-flex',
  padding: '4px 8px',
  alignItems: 'flex-start',
  gap: '10px',
  borderRadius: '8px',
  border: '1px solid',
  marginRight: '4px',
  marginLeft: '4px',
  ...TYPOGRAPHY.BODY2,
};

export default createStyleSheet({
  borderValid: {
    ...baseBorder,
    color: COLORS.SUCCESS.DARKER,
    borderColor: COLORS.SUCCESS.DARKER,
  },
  borderInvalid: {
    ...baseBorder,
    color: COLORS.ERROR.DARK,
    borderColor: COLORS.ERROR.DARK,
  },
});
