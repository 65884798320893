import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  content: {
    ...TYPOGRAPHY.BODY3,
  },
  contentBold: {
    fontWeight: 'bold',
  },
  warning: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  warningTop: {
    marginTop: 16,
  },
  hideWarning: {
    display: 'none',
  },
});

export default styleSheet;
