import m from 'm';
import { t } from 'i18next';
import button from 'components/button';

var component = {};

component.controller = function controller() {};

component.view = function view(c, args) {
  return m('.popup.popup--info', [
    m('.popup__body', [
      m('.popup__title', args.title || t('mithril_components:popup_info.title')),
      m('label', [
        m('.popup__subtitle', args.subtitle),
      ]),
      m('.popup__body__content', args.content),
    ]),
    m('.popup__footer.clearFix', [
      m(button, {
        value : args.button ||  t('mithril_components:popup_info.confirm'),
        class : 'button--ok button button--action',
        action: function action() {
          if (args.action)
            args.action();
          args.close();
        },
      }),
    ]),
  ]);
};

export default component;
