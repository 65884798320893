import Model from 'models/Model';

var PictureSpotItem = {
  endpoint  : 'api/PictureSpotItems',
  attributes: {
    x: {
      type: 'number',
    },
    y: {
      type: 'number',
    },
    r: {
      type: 'number',
    },
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    description: {
      type : 'pointer',
      Model: 'GameItem',
    },
    pictureSpotId: {
      type: 'number',
    },
  },
  methods: {},
};

export default Model.register('PictureSpotItem', PictureSpotItem);
