// @flow

/**
 * This component display a cell with a profile picture
 *
 * Props:
 *  - picture: Path of image
 *  - style: Props to override the style
 */

import * as React from 'react';

import UProfilePicture from 'Components/unit/UProfilePicture/UProfilePicture';

import styles from './UTableCellProfilePicture.style';


type Props = {|
  picture?: string,
  style?: Object,
|};


export class UTableCellProfilePicture extends React.Component<Props> {
  static defaultProps = {
    picture: '',
    style: undefined,
  };

  render() {
    const { picture, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <UProfilePicture
          picturePath={picture}
          size={28}
        />
      </div>
    );
  }
}

export default UTableCellProfilePicture;
