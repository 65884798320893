import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';
import { handle } from 'redux-pack';

import type { Action } from 'Libs/redux/types';
import { getErrorMessage } from 'Libs/redux/utils';

export type LoginState = DeepReadonly<{
  email: string;
  error: string | Record<string, string[]>;
  loading: boolean;
}>;

export const defaultState: LoginState = {
  email: '',
  error: '',
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: LoginState = defaultState, action: Action): LoginState => {
  switch (action.type) {
    case 'ADMIN_LOGIN_SET_EMAIL': {
      const { email } = action;

      return mergeDeep(state, { email });
    }

    case 'ADMIN_LOGIN': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { error: '', loading: true }),
        success: (prevState) => mergeDeep(prevState, { error: '' }),
        failure: (prevState) => {
          const error =
            action.payload?.message?.error?.name === 'AdminLoginFailedError'
              ? "Sorry, we couldn't log you in, your email or password is incorrect."
              : getErrorMessage(action);

          return mergeDeep(prevState, { error });
        },
        finish: (prevState) => mergeDeep(prevState, { loading: false }),
      });
    }

    default:
      return state;
  }
};
