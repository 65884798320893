import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    paddingBottom: 32,
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  subContent: {
    marginTop: 16,
    paddingLeft: 20,
    paddingRight: 20,
  },
  warningLabel: {
    ...TYPOGRAPHY.BODY3,
  },
  warningSubLabel: {
    ...TYPOGRAPHY.BODY4,
    fontWeight: 400,
    color: COLORS.ALERT.ERROR,
  },
});
