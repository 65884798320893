import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { css } from 'aphrodite';
import { COLORS } from 'Components/foundation';
import { getTooltipStyle } from './STooltip.style';

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';

export type Props = Readonly<{
  id?: string;
  position?: TooltipPosition;
  content?: string;
  delay?: number;
  maxWidth?: number;
  backgroundColor?: string;
  color?: string;
  style?: React.CSSProperties;
}>;

const STooltip: React.FC<Props> = ({
  id,
  position = 'top',
  content,
  delay = 0,
  maxWidth = 200,
  backgroundColor = COLORS.BLACK.DEFAULT,
  color = COLORS.WHITE.DEFAULT,
  style = {},
}) => {
  if (!content) return null;

  const tooltipStyle = getTooltipStyle(position, maxWidth, style);

  const textFormated = useMemo(() => {
    return content.split('\n').map((item, index) => (
      <p key={`${item}-${index}`} style={{ color, backgroundColor, maxWidth, ...style }}>
        {item}
      </p>
    ));
  }, [content, color, backgroundColor, maxWidth, style]);

  return (
    <ReactTooltip
      id={id || 'default-id'}
      scrollHide
      multiline
      type="dark"
      effect="solid"
      delayShow={delay || 0}
      place={position}
      delayHide={150}
      className={css(tooltipStyle.content)}
      clickable
      textColor={color}
      backgroundColor={backgroundColor}
    >
      <span>{textFormated}</span>
    </ReactTooltip>
  );
};

export default STooltip;
