import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import styles from './UAnswerZone.style';

export type UAnswerZoneProps = {
  style?: CSSProperties;
  size: number;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const UAnswerZone = ({ style = {}, size, onMouseDown }: UAnswerZoneProps) => {
  const sizeStyle = useMemo(
    () => ({
      height: size,
      width: size,
      transform: `translate(-50%, -50%)`,
    }),
    [size],
  );

  return <div onMouseDown={onMouseDown} style={{ ...styles.wrapper, ...style, ...sizeStyle }} />;
};

export default UAnswerZone;
