// @flow

/* eslint-disable complexity */
/* eslint-disable flowtype/require-compound-type-alias */
/* eslint-disable key-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-multi-comp */

import React, { useCallback, useMemo } from 'react';
import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions, useModuleSelector } from '../redux';

import styles from './KnowledgeTranslationAlertView.style';

type Props<P> = {|
  component: React$AbstractComponent<P, any>,
  props: P,
|};

const ALL_SCOPES: Array<AlertScope> = [
  'saveSuccess',
  'saveError',
  'validateError',
  'autoTranslateError',
];

export const IDS: { [AlertScope]: number } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [(x: any)]: i }), {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  'saveSuccess': 'success',
  'saveError': 'error',
  'validateError': 'error',
  'autoTranslateError': 'error',
};

const TITLES = {
  saveSuccess: '',
  saveError: 'Something went wrong',
  validateError: 'Something went wrong',
  autoTranslateError: 'Something went wrong',
};

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const message = params.message || '';

  switch (scope) {
    case 'saveSuccess': return 'The draft was succesfully saved.';
    case 'saveError': return `The draft could not be saved.\n${message}`;
    case 'validateError': return `The translation could not be confirmed.\n${message}`;
    case 'autoTranslateError': return `The translation request has failed.\n${message}`;

    default: return '';
  }
};

const makeAlertsFromStateAlerts = (alerts) => alerts
  .map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

export const KnowledgeTranslationAlertView = <P>({ component, props }: Props<P>) => {
  const stateAlerts = useModuleSelector(state => state.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    // $FlowFixMe withAlert's typing is too narrow concerning props
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return (
    <AlertHOC alerts={alerts} onClose={onClose} {...props} />
  );
};
