/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
// @flow

import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';

import type { Filter, Source } from 'Libs/flowTypes';
import { useTypedDispatch } from 'Libs/redux/utils';
import App from 'Models/App';
import Enum from 'Models/Enum';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import createSegmentationGroupItem from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';
import UButton from 'Components/unit/UButton/UButton';
import UToggle from 'Components/unit/UToggle/UToggle';

import { actions, useModuleSelector } from '../../redux';
import styles from './PlayersManageModal.style';

const getSegmentation = (): { [groupId: number]: SegmentationGroupItemType } => {
  const filteredSegmentation: Array<any> = App.userSegmentationItems().toJSON();
  const groups = filteredSegmentation
    .map(segItem => segItem.group);

  return groups.reduce((map, group) => {
    map[group.id] = createSegmentationGroupItem(group);

    return map;
  }, {});
};

const getDimensions = (): Array<{ id: number, label: string }> => {
  return App.segmentationDimensions().items.map(dimension => {
    return ({
      id: dimension.id(),
      label: dimension.getLabel().label(),
    });
  });
};

const PlayerSegmentationForm = () => {
  const dispatch = useTypedDispatch();
  const appSegmentation = getSegmentation();
  const dimensions = getDimensions();
  const playerToManage = useModuleSelector((state) => state.managePlayerModal.playerToManage);

  const { segmentation } = playerToManage;

  const filters: Array<Filter> = useMemo(() => {
    const currentIds = segmentation.map(({ id }) => id);
    const items = Object.keys(appSegmentation).map((groupIdKey: string) => {
      const groupId = parseInt(groupIdKey);
      const { id, label, dimension } = appSegmentation[groupId];
      const { color, id: dimensionId } = dimension;
      const selected = currentIds.includes(id);

      return { category: 'segment', id, value: label, selected, color, dimensionId };
    });

    return [
      { category: 'segment', type: 'multiselect', items },
    ];
  }, [segmentation, appSegmentation]);

  const sources: Array<Source> = [{
    icon: '',
    name: '',
    filters,
  }];

  const handleUpdateSegmentation = useCallback((source: Source) => {
    const filterSegment = source.filters.find((filter) => filter.category === 'segment') || { items: []};

    const result: Array<SegmentationGroupItemType> = filterSegment.items
      .filter(({ selected }) => selected)
      .map(({ id }) => {
        return createSegmentationGroupItem(appSegmentation[id]);
      });

    if (result.length)
      dispatch(actions.setModalError(''));

    return dispatch(actions.setPlayerSegmentation(result));
  }, [dispatch, appSegmentation]);

  const suggestionOptions = {
    enable: true,
    enableOnEmptyInput: false,
    enableTextSuggestion: false,
    maxSuggestionBoxHeight: 190,
  };

  return (
    <div style={styles.segmentationWrapper}>
      <p style={styles.segmentationLabel}>{'Segmentation'}</p>
      <SOmnibox
        sources={sources}
        placeholder={t('config_players:segmentation_placeholder')}
        onFilterChanged={handleUpdateSegmentation}
        suggestionOptions={suggestionOptions}
        dimensions={dimensions}
        filterMultiText={false}
      />
    </div>
  );
};

const PlayerEmailForm = () => {
  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { email } = playerToManage;

  const handleUpdateEmail = useCallback((newEmail: string) => {
    return dispatch(actions.setPlayerEmail(newEmail));
  }, [dispatch]);

  return (
    <SInput
      value={email}
      placeholder="email@domain.com"
      type="small"
      label="Email"
      onChange={handleUpdateEmail}
      style={styles.emailInput}
      errorMessage={email ? '' : t('config_players:player_email_form')}
    />
  );
};

const PlayerFirstNameForm = () => {
  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { firstName } = playerToManage;

  const handleUpdateFirstName = useCallback((newFirstName: string) => {
    return dispatch(actions.setPlayerFirstName(newFirstName));
  }, [dispatch]);

  return (
    <SInput
      value={firstName}
      placeholder="John"
      type="small"
      label="First Name"
      onChange={handleUpdateFirstName}
      style={styles.firstNameInput}
      errorMessage={firstName ? '' : t('config_players:player_first_name_form')}
    />
  );
};

const PlayerLastNameForm = () => {
  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { lastName } = playerToManage;

  const handleUpdateLastName = useCallback((newLastName: string) => {
    return dispatch(actions.setPlayerLastName(newLastName));
  }, [dispatch]);

  return (
    <SInput
      value={lastName}
      placeholder="Doe"
      type="small"
      label="Last Name"
      onChange={handleUpdateLastName}
      style={styles.lastNameInput}
      errorMessage={lastName ? '' : t('config_players:player_last_name_form')}
    />
  );
};

export const PlayerExtraInfoForm = () => {
  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { extraInfo } = playerToManage;

  const handleUpdateExtraInfo = useCallback((pseudo: string) => {
    return dispatch(actions.setPlayerExtraInfo(pseudo));
  }, [dispatch]);

  return (
    <SInput
      value={extraInfo}
      placeholder="john"
      type="small"
      label="Pseudo"
      onChange={handleUpdateExtraInfo}
      style={styles.extraInfoInput}
    />
  );
};

const PlayerStatusForm = () => {

  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { active } = playerToManage;

  if (active) {
    return (
      <div style={{ marginTop: 25 }}>
        <p style={styles.modalLabel}>{t('config_players:player_status_form.modal_label')}</p>
        <div>
          <UButton
            style={{ marginTop: 20 }}
            text={t('config_players:player_status_form.deactivate_button')}
            type="destructive"
            onClick={async () => {
              await dispatch(actions.deactivatePlayer());
              await dispatch(actions.updatePlayer(Enum.playerValidations.REJECTED));
            }}
            ghost
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 25 }}>
      <p style={styles.modalLabel}>{t('config_players:player_status_form.modal_label')}</p>
      <div>
        <UButton
          style={{ marginTop: 20 }}
          text={t('config_players:player_status_form.activate_button')}
          type="accentuated"
          onClick={async () => {
            await dispatch(actions.reactivatePlayer());
            await dispatch(actions.updatePlayer(Enum.playerValidations.VALIDATED));
          }}
          ghost
        />
      </div>
    </div>
  );
};

const PlayerVerificationForm = () => {
  const dispatch = useTypedDispatch();
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);

  const { adminValidationId, active } = playerToManage;

  const handleToggleChange = useCallback((value) => {
    dispatch(actions.setPlayerVerification(value));
  }, [dispatch]);

  // eslint-disable-next-line complexity
  const getVerification = useCallback(() => {
    if (adminValidationId === Enum.playerValidations.PENDING)
      return false;
    else if (adminValidationId === Enum.playerValidations.VALIDATED)
      return true;

    return false;
  }, [adminValidationId]);

  return (
    <div style={{ marginTop: 10 }}>
      <p style={styles.modalLabel}>{t('config_players:player_verification_form.verified')}</p>
      <div>
        <UToggle
          isDisabled={!active}
          toggled={getVerification()}
          style={{ display: 'inline-block', top: '1em', marginTop: '10px' }}
          onChange={handleToggleChange}
        />
      </div>
    </div>
  );
};

export const PlayersManageModal = () => {
  const dispatch = useTypedDispatch();
  const labelError = useModuleSelector(state => state.managePlayerModal.error);
  const visible = useModuleSelector(state => state.managePlayerModal.isOpen.isManageModalOpen || false);
  const playerToManage = useModuleSelector(state => state.managePlayerModal.playerToManage);
  const firstName = useModuleSelector(state => state.managePlayerModal.playerToManage.firstName);
  const lastName = useModuleSelector(state => state.managePlayerModal.playerToManage.lastName);
  const email = useModuleSelector(state => state.managePlayerModal.playerToManage.email);
  const segmentation = useModuleSelector(state => state.managePlayerModal.playerToManage.segmentation);
  const originalPlayer = useModuleSelector(state => state.managePlayerModal.originalPlayer);
  const isActionButtonDisabled = JSON.stringify(originalPlayer) === JSON.stringify(playerToManage)
    || !firstName || !lastName || !email;

  let modalTitle = t('config_players:edit_player_information.modal_title');

  let labelActionButton = t('config_players:edit_player_information.label_action_button');

  function checkPlayerSegmentation(_segmentation) {
    const languageSegment = _segmentation.find((segment) => {
      return segment.dimension.typeId === Enum.dimensionTypes.LANGUAGE;
    });

    if (languageSegment)
      return true;

    return false;
  }

  // eslint-disable-next-line complexity
  const getModalError = useCallback(() => {
    if (!segmentation.length || !checkPlayerSegmentation(segmentation))
      return t('config_players:edit_player_information.modal_error');

    return '';
  }, [segmentation, t]);

  const handleSavePlayer = useCallback(() => {
    const res = getModalError();

    if (res === '')
      return Promise.resolve(dispatch(actions.updatePlayer(0)));

    dispatch(actions.setModalError(res));

    return null;
  }, [dispatch, getModalError]);

  const handleSaveEnd = useCallback(
    (res: any) => {
      const { error } = res;

      if (!error) {
        dispatch(actions.setModalError(''));

        return dispatch(actions.togglePlayerModal({ isManageModalOpen: false }));
      }

      dispatch(actions.setModalError(t('config_players:edit_player_information.handle_save_end')));

      return null;
    },
    [dispatch, t],
  );

  const handleCloseModal = useCallback(
    () => dispatch(actions.togglePlayerModal({ isManageModalOpen: false })),
    [dispatch],
  );

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleSavePlayer}
      onActionEnd={handleSaveEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={modalTitle}
      labelActionButton={labelActionButton}
      labelSecondButton={t('config_players:edit_player_information.cancel_label_action_button')}
      actionButtonType="accentuated"
      labelError={labelError}
      disableActionButton={isActionButtonDisabled}
    >
      <div >
        <PlayerEmailForm />
        <PlayerFirstNameForm />
        <PlayerLastNameForm />
        <PlayerExtraInfoForm />
        <PlayerSegmentationForm />
        <PlayerVerificationForm />
        <PlayerStatusForm />
      </div>
    </MModal>
  );
};
