import m from 'm';
import Enum from 'models/Enum';
import contentPreviewImage from 'components/content.preview.image';
import contentPreviewQuestion from 'components/content.preview.question';

var component = {};

component.view = function view(c, args) {
  var question = args.content.game().question().data();
  var image = args.content.game().mediaTypeFrontId() === Enum.mediaType.Image
    ? args.content.game().imageFront().cdn({transformation: {width: 300}})
    : args.content.game().videoFront().getOriginalThumbnailSync();
  var choices = args.content.game().qcmChoices();
  var format = args.content.game().formatId();

  return m('.preview-qcm', [
    m(contentPreviewImage, {
      image: image,
    }),
    m(contentPreviewQuestion, {
      question: question,
    }),
    m('.preview-qcm__block-choices', [
      choices.map(function displayChoices(choice) {
        return m('.preview-qcm__block-choice', {
          class: format === Enum.QCMFormat.twoSBS ? 'preview-qcm__block-choice-twoSBS'
            : format === Enum.QCMFormat.twoUpDown ? 'preview-qcm__block-choice-twoUpDown'
              : format === Enum.QCMFormat.three ? 'preview-qcm__block-choice-three'
                : format === Enum.QCMFormat.four ? 'preview-qcm__block-choice-four'
                  : 'hide',
        }, [
          m('.preview-qcm__choice', choice.gameItem().data()),
        ]);
      }),
    ]),
  ]);
};
export default component;
