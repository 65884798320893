import { StyleSheet as Aphrodite } from 'aphrodite';

const styleSheet = Aphrodite.create({
  'modal': {
    minWidth: 400,
    width: 'fit-content',
  },
  'courseListFromAudienceContainer': {
    padding: '0 51px',
  },
  'courseListFromAudienceContainerLoading': {
    position: 'absolute',
    left: 'calc(50% - 9px)',
    top: 'calc(50% + 9px)',
  },
});

export default styleSheet;
