import React from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './STextIndicator.style';

export type IndicatorStatus = 'evenly' | 'warning' | 'odd' | 'total';
export type OddRangeValues = {
  from: number;
  to: number;
};

export type SLocalIndicatorProps = {
  style?: CSSProperties;
  status: IndicatorStatus;
  total: number | null;
  oddRange?: OddRangeValues;
};

const SUCCESS = COLORS.SUCCESS.DARKER;
const EVENLY = COLORS.GREY_DARKER.DEFAULT;
const WARNING = COLORS.WARNING.DARK;

const getStatusText = (total: number | null, oddRange?: OddRangeValues) => ({
  evenly: t('structural_component:s_text_indicator.explanation_evenly'),
  odd: t('structural_component:s_text_indicator.explanation_odd', {
    from: oddRange?.from || 0,
    to: oddRange?.to || 0,
  }),
  total: t('structural_component:s_text_indicator.total', { count: total || 0 }),
  warning: t('structural_component:s_text_indicator.warning'),
});

/**
 * STextIndicator
 * @desc This component is used to display a text indicator with an icon
 * @param style - Style of the component
 * @param status - Status of the indicator
 * @param total - Total of contents
 * @param oddRange - Range of odd contents
 **/
export const STextIndicator = ({ style = {}, status, total, oddRange }: SLocalIndicatorProps) => {
  const text = getStatusText(total, oddRange)[status];

  return (
    <div style={style}>
      {(status === 'evenly' || status === 'warning') && (
        <div>
          <UIcon
            name="chat-bubble-warning"
            size={18}
            color={status === 'evenly' ? EVENLY : WARNING}
            style={styles.icon}
          />
          <div style={status === 'evenly' ? styles.evenly : styles.warning}>{text}</div>
        </div>
      )}
      {(status === 'odd' || status === 'total') && (
        <div>
          <UIcon name="check-save" size={18} color={SUCCESS} style={styles.icon} />
          <div style={styles.success}>{text}</div>
        </div>
      )}
    </div>
  );
};

export default STextIndicator;
