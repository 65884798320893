import React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from '../STextArea.style';

export const TextAreaErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
  if (!errorMessage) return null;

  return (
    <div style={styles.errorMessage}>
      <UIcon color={COLORS.ERROR.DEFAULT} style={styles.errorIcon} name="error" size={14} />
      {errorMessage}
    </div>
  );
};
