import React from 'react';

import { IconName } from 'Components/foundation/icons';
import ULabel, { LabelSize } from 'Components/unit/ULabel/ULabel';

import styles from '../STextArea.style';

export const TextAreaLabelAndCount = ({
  maxLength = 0,
  value,
  label,
  labelSize,
  labelIcon,
  labelIconColor,
  isLabelRequired,
  labelIconSize,
}: {
  maxLength?: number;
  value: string;
  label: string;
  labelSize: LabelSize;
  labelIcon?: IconName;
  labelIconColor?: string;
  isLabelRequired?: boolean;
  labelIconSize?: number;
}) => {
  return (
    <div style={styles.labelAndCountWrapper}>
      {label && (
        <ULabel
          icon={labelIcon}
          iconColor={labelIconColor}
          required={isLabelRequired}
          size={labelSize}
          iconSize={labelIconSize}
        >
          {label}
        </ULabel>
      )}
      {maxLength > 0 && (
        <div
          style={{
            ...styles.count,
            ...(maxLength === value.length && styles.countReached),
            ...styles.labelAndCountWrapper,
          }}
        >{`${value.length}/${maxLength}`}</div>
      )}
    </div>
  );
};
