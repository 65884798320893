import { apiAction } from 'Libs/redux/utils';
import type { ApiActionType } from 'Libs/redux/utils';
import { store } from 'Libs/redux/store';
import { FilterState } from 'Libs/filter/types';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import type { AlertScope } from './models/Alert';
import type { PlayerType } from './reducer';
import createPlayer from './models/Player';

type FetchPlayersAction = ApiActionType<'PLAYERS_GET_ALL'>;
type GetPlayersCountAction = ApiActionType<'GET_PLAYERS_COUNT'>;
type PlayersGetExport = ApiActionType<'PLAYERS_GET_EXPORT'>;
type PlayerUpdateAction = ApiActionType<'PLAYER_UPDATE'>;
type PlayerDeactivateAction = ApiActionType<'PLAYER_DEACTIVATE'>;
type PlayerReactivateAction = ApiActionType<'PLAYER_REACTIVATE'>;

type PlayersUpdateFilters = {
  type: 'PLAYERS_UPDATE_FILTERS';
  selectedFilters: Array<FilterState>;
};

type PlayerRemoveAlertAction = {
  type: 'PLAYER_REMOVE_ALERT';
  scope: AlertScope;
};

type IsModalOpenType = {
  isManageModalOpen?: boolean;
};

type PlayerSetModalErrorAction = {
  type: 'PLAYER_SET_MODAL_ERROR';
  error: string;
};
type PlayerToggleModalAction = {
  type: 'PLAYER_TOGGLE_MODAL';
  isOpen: IsModalOpenType;
  player?: PlayerType;
};

type PlayerSetEmailAction = {
  type: 'PLAYER_SET_EMAIL';
  email: string;
};

type PlayerSetFirstNameAction = {
  type: 'PLAYER_SET_FIRST_NAME';
  firstName: string;
};

type PlayerSetLastNameAction = {
  type: 'PLAYER_SET_LAST_NAME';
  lastName: string;
};

type PlayerSetExtraInfoAction = {
  type: 'PLAYER_SET_EXTRA_INFO';
  extraInfo: string;
};

type PlayerSetSegmentationAction = {
  type: 'PLAYER_SET_SEGMENTATION';
  segmentation: Array<SegmentationGroupItemType>;
};

type PlayerSetVerificationAction = {
  type: 'PLAYER_SET_VERIFICATION';
  isVerified: boolean;
};

export const actions = {
  fetchPlayers: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    options: Object,
    start: number,
    length: number,
    isShowMore: boolean,
    shouldDisplay: boolean,
    isDisplayAll: boolean,
  ): FetchPlayersAction =>
    apiAction({
      type: 'PLAYERS_GET_ALL',
      route: `/api/Players/filtered?start=${start}&length=${length}&filter=${JSON.stringify(options)}`,
      method: 'GET',
      meta: { isShowMore, shouldDisplay, isDisplayAll },
    }),

  updatePlayer: (adminValidationId: number): PlayerUpdateAction => {
    const state = store.getState();

    let { playerToManage } = state.pages.players.managePlayerModal;
    const { id } = playerToManage;

    if (adminValidationId > 0) {
      playerToManage = { ...playerToManage, adminValidationId };
    }

    const payload = createPlayer(playerToManage);

    return apiAction({
      type: 'PLAYER_UPDATE',
      route: `/api/Players/full/${id}`,
      method: 'PUT',
      payload,
    });
  },

  reactivatePlayer: (): PlayerReactivateAction => {
    const state = store.getState();
    const { playerToManage } = state.pages.players.managePlayerModal;
    const { id } = playerToManage;

    return apiAction({
      type: 'PLAYER_REACTIVATE',
      route: `/api/Players/${id}/reactivate`,
      method: 'PUT',
    });
  },

  deactivatePlayer: (): PlayerDeactivateAction => {
    const state = store.getState();
    const { playerToManage } = state.pages.players.managePlayerModal;
    const { id } = playerToManage;

    return apiAction({
      type: 'PLAYER_DEACTIVATE',
      route: `/api/Players/${id}/deactivate`,
      method: 'PUT',
    });
  },

  togglePlayerModal: (isOpen: IsModalOpenType, player?: PlayerType): PlayerToggleModalAction => ({
    type: 'PLAYER_TOGGLE_MODAL',
    isOpen,
    player,
  }),

  setPlayerEmail: (email: string): PlayerSetEmailAction => ({
    type: 'PLAYER_SET_EMAIL',
    email,
  }),

  setPlayerFirstName: (firstName: string): PlayerSetFirstNameAction => ({
    type: 'PLAYER_SET_FIRST_NAME',
    firstName,
  }),

  setPlayerLastName: (lastName: string): PlayerSetLastNameAction => ({
    type: 'PLAYER_SET_LAST_NAME',
    lastName,
  }),

  setPlayerExtraInfo: (extraInfo: string): PlayerSetExtraInfoAction => ({
    type: 'PLAYER_SET_EXTRA_INFO',
    extraInfo,
  }),

  setPlayerSegmentation: (segmentation: Array<SegmentationGroupItemType>): PlayerSetSegmentationAction => ({
    type: 'PLAYER_SET_SEGMENTATION',
    segmentation,
  }),

  setPlayerVerification: (isVerified: boolean): PlayerSetVerificationAction => ({
    type: 'PLAYER_SET_VERIFICATION',
    isVerified,
  }),

  setModalError: (error: string): PlayerSetModalErrorAction => ({
    type: 'PLAYER_SET_MODAL_ERROR',
    error,
  }),

  // eslint-disable-next-line @typescript-eslint/ban-types
  getPlayersCount: (options: Object): GetPlayersCountAction =>
    apiAction({
      type: 'GET_PLAYERS_COUNT',
      route: `/api/Players/count/filtered?filter=${JSON.stringify(options)}`,
      method: 'GET',
    }),

  updateFilters: (selectedFilters: Array<FilterState>): PlayersUpdateFilters => ({
    type: 'PLAYERS_UPDATE_FILTERS',
    selectedFilters,
  }),

  // eslint-disable-next-line @typescript-eslint/ban-types
  getPlayersExport: (options?: Object): PlayersGetExport =>
    apiAction({
      type: 'PLAYERS_GET_EXPORT',
      route: `/api/players/download?filter=${JSON.stringify(options)}&responseType=arraybuffer`,
      method: 'GET',
    }),

  removeAlert: (scope: AlertScope): PlayerRemoveAlertAction => ({
    type: 'PLAYER_REMOVE_ALERT',
    scope,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type PlayersAction = ReturnType<ActionsFn>;
