import m from 'm';
import popup from 'services/popupService';
import Collection from 'models/Collection';
import Model from 'models/Model';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import gaiaGiftCard from 'components/gaia.gift.card';
import button from 'components/button';
import reactCreator from 'components/react.creator';

const CACHE = {};

var component = {};

component.controller = function controller() {
  var self = this;

  var Gift = new Model('Gift');

  self.segmentationIds = m.prop(CACHE.segmentationIds || []);
  self.textValues = m.prop(CACHE.textValues || []);

  self.gifts = m.prop(new Collection(new Model('Gift'), []));

  Gift.filter()
    .then(self.gifts)
    .then(m.redraw);

  self.exportOrders = function exportOrders() {
    // mini-hack: without this setTimeout 0, the global click listener for tracking is processing this click too late
    setTimeout(() => {
      popup.open({
        type : 'exportOrders',
        title: 'This gift is not valid',
      });
    }, 0);

    // GiftOrder.exportOrders();
  };

  self.refreshGifts = function refreshGifts() {
    Gift.filterBy({
      text        : self.textValues(),
      segmentation: self.segmentationIds(),
    })
      .then(self.gifts)
      .then(m.redraw);
  };

  self.onFilterBoxChange = function onFilterBoxChange(filters) {
    filters.forEach((filter) => {
      if (filter.category === 'segment')
        CACHE.segmentationIds = self.segmentationIds(filter.ids);
      if (filter.category === 'text')
        CACHE.textValues = self.textValues(filter.values);
    });

    self.refreshGifts();
  };
};

component.view = function view(c) {
  var sortedGifts = c.gifts().sort(Model('Gift').sortByStatus);

  return m('.gaia-gift-grid', [
    m(menu),
    m(gaiaMenu, {
      confirm: c.confirmPageExit,
    }),
    m('.player-page__body', {
      style: {
        'margin-top': 0,
      },
    }, [
      m('.filter__box', [
        m(reactCreator, {
          component: 'SFilterBox',
          key      : 'filterbox-gaia-gifts',
          props    : {
            title      : 'Gifts',
            description: 'Overview of the items of your giftshop',
            placeholder: 'Find gifts',
            filters    : [
              { category: 'segment', ids: c.segmentationIds() },
              { category: 'text', values: c.textValues() },
            ],
            onChange: c.onFilterBoxChange,
            count   : null,
          },
        }),
      ]),
    ]),
    m('.gaia-gift-grid__body', [
      m('.gaia-gift-grid__body__header', [
        m('.gaia-gift-grid__header__title', [
          m('span.gift-label', 'Total: '),
          m('span.gift-label.gift-label--image', c.gifts().length + ' gifts'),
        ]),
        m('.gaia-gift-grid__header__commands', {
          'data-tracking-action': 'export',
          'data-tracking-context': 'gift',
        }, [
          m(button, {
            action: c.exportOrders,
            value : 'Export orders details (.csv)',
            class : 'button--action button--download button--border',
          }),
        ]),
      ]),
      m('.gaia-gift-grid__body__grid', [
        m('a.gaia__gift__grid__item.gaia__gift__grid__item--add', {
          href  : '/gaia/gifts/new',
          config: m.route,
        }, 'Add a gift'),
        sortedGifts.map(displayGift),
      ]),
    ]),
  ]);

  function displayGift(gift) {
    return m('a.gaia__gift__grid__item', {
      href  : gift.getRoute(),
      config: m.route,
    }, [
      m(gaiaGiftCard, {
        gift: gift,
      }),
    ]);
  }
};

export default component;
