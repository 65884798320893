import React, { useCallback, useEffect } from 'react';
import { t } from 'i18next';

import SSelect from 'Components/structural/SSelect/SSelect';

import type { RoleType } from 'Models/react/Role/Role';

import { useModuleSelector } from '../../../redux';

import styles from './ContributorRoleForm.style';

type ContributorRoleProps = {
  onChangeRole: (id: number) => void;
  selectedRole: number;
};

const ADMIN_ROLE_ID = 2;

export const ContributorRoleForm = ({ onChangeRole, selectedRole }: ContributorRoleProps) => {
  const contributorToManage = useModuleSelector((state) => state.manageContributorModal.contributorToManage);
  const rolesAvailable = useModuleSelector((state) => state.rolesAvailable);
  const translatedRoles = rolesAvailable.map(({ id, name }: RoleType) => ({
    id,
    label: t(`config_contributors:contributors.modal.roles.${name.toLowerCase()}`),
    value: id.toString(),
  }));

  useEffect(() => {
    if (contributorToManage.roles[0]) {
      // 0 is the only available role (Admin)
      onChangeRole(contributorToManage.roles[0].id);
    } else onChangeRole(rolesAvailable[ADMIN_ROLE_ID].id);
  }, [rolesAvailable, contributorToManage]);

  const handleSelection = useCallback(
    (selection) => {
      const { value } = selection;

      const idSelected = parseInt(value, 10);
      const role = rolesAvailable.find((item: RoleType) => item.id === idSelected);

      if (!role) {
        return null;
      }

      onChangeRole(role.id);
    },
    [rolesAvailable],
  );

  return (
    <>
      <SSelect
        type="small"
        label={t('config_contributors:contributors.modal.role')}
        placeholder={t('config_contributors:contributors.modal.select_item')}
        selected={translatedRoles.find((role) => role.id === selectedRole)}
        items={translatedRoles}
        onSelect={handleSelection}
        style={styles.roleInput}
      />
    </>
  );
};
