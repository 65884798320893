import { StyleSheet as Aphrodite } from 'aphrodite';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const scrollBarStyle = Aphrodite.create({
  scrollbar: {
    'maxHeight': 448,
    'overflowY': 'auto',
    '::-webkit-scrollbar-thumb': {
      background: '#c6c6c6',
      border: 'solid 12px transparent',
      borderRadius: 16,
      backgroundClip: 'padding-box',
      width: 4,
    },
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 32,
    },
  },
});

const styleSheet = createStyleSheet({
  courseListContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(308px, 1fr))',
    gridAutoFlow: 'row',
    gridGap: 16,
    justifyItems: 'center',
    maxWidth: 956,
    padding: '8px 0',
    margin: 4,
  },
});

export default styleSheet;
