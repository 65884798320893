/*
 ** # Header for content edition
 ** args = {
 **   title: String // (optional) title
 **   gameplay: String // gameplay slug (ex: 'QCM', ...) same as 'in Enum.gameplay'
 **   explanationText: String // Explanation text corresponding to the scenario current step
 ** };
 */

import m from 'm';

var component = {};

component.controller = function controller(args) {

};

component.view = function view(c, args) {
  var title = args.title || 'Content';
  var gameplayLabel = args.gameplay;
  var gameplayName = args.gameplayName;
  var explanationText = args.explanationText;

  return m('.content-header', [
    m('.content-header__explanation', m('.content-header__explanation__label', explanationText)),
    m('.content-header__bottom', [
      m('.content-header__title', title),
      m('.content-header__type', [
        m('.content-header__type__label', gameplayLabel),
        m('.content-header__type__icon__container.content-header__type__icon__container__' + gameplayName, [
          m('.content-header__type__icon.content-header__type__icon__' + gameplayName),
        ]),
      ]),
    ]),
  ]);
};

export default component;
