import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { FunctionComponent, CSSProperties } from 'react';

import { ActivityStatusType, ActivityType, AudienceType, OptionType } from './SActivityListItem.types';
import { Image } from './components/Image';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import styles, { getStyleWrapper } from './SActivityListItem.style';

export type SActivityListItemProps = {
  hasPlayerPlayed: boolean;
  activityType: ActivityType;
  id: number;
  activityStatus: ActivityStatusType;
  activityStatusLabel: string;
  title: string;
  titlePlaceholder: string;
  onAnalyticsClick: (activityIds: ReadonlyArray<number>) => void;
  activityIds: ReadonlyArray<number>;
  options: Array<OptionType>;
  audience: AudienceType | null;
  audiencePlaceholder?: string;
  audienceEmptySegmentsLabel: string;
  languages: ReadonlyArray<string>;
  startDate: string;
  endDate: string;
  likes?: number;
  views?: number;
  style?: CSSProperties;
  image?: string | null;
  onClick: (id: number) => void;
};

/**
 * Activity Item to be displayed in the new activity grid
 *
 * Props:
 *  - activityType: Type of the activity
 *  - id: Id of the activity
 *  - activityIds: Array of activity id which compose this activity.
 *  - title: Title of the activity
 *  - titlePlaceholder: A placeholder if the activity has no title
 *  - activityStatus: Status of the activity
 *  - activityStatusLabel: Label of the status of the activity
 *  - startDate: Start date of the activity
 *  - endDate: End date of the activity
 *  - image: Image of the activity
 *  - audience: Object with the audience of the activity
 *  - audiencePlaceholder: A placeholder if the activity has no audience
 *  - audienceEmptySegmentsLabel: A text to display when the audience mode is segmentation and no seg is selected
 *  - languages: An array of language the activity support
 *  - options: An object options with callback to do actions on the activity
 *  - onAnalyticsClick: Call when click on the analytics icon
 *  - onClick: Call when click on the item
 *  - hasPlayerPlayed: boolean indicate at least one player has played this activity
 *  - style: Override component's style
 *  - views : Number of views
 *  - likes : Number of likes
 */
export const SActivityListItem: FunctionComponent<SActivityListItemProps> = ({
  style,
  titlePlaceholder = 'Title of your activity',
  title = '',
  audience = null,
  audiencePlaceholder = 'No target mode',
  audienceEmptySegmentsLabel = 'No segment selected',
  activityStatus,
  image,
  onClick,
  id,
  onAnalyticsClick = () => {},
  hasPlayerPlayed,
  activityType,
  activityStatusLabel,
  activityIds,
  options,
  startDate,
  endDate,
  languages,
  likes,
  views,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleHover = useCallback(() => {
    setIsHover(true);
  }, [setIsHover]);

  const handleLeave = useCallback(() => {
    setIsHover(false);
  }, [setIsHover]);

  const handleClickItem = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const wrapperStyle = useMemo(
    () => ({
      ...styles.wrapper,
      ...getStyleWrapper(isHover),
      ...style,
    }),
    [isHover, style],
  );

  return (
    <div
      style={wrapperStyle}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      onClick={handleClickItem}
      data-test-id="activity-list-item"
    >
      <div style={styles.containerImage}>
        <Image image={image} />
      </div>
      <div style={styles.containerInfos}>
        <Header
          hasPlayerPlayed={hasPlayerPlayed}
          activityType={activityType}
          id={id}
          activityStatus={activityStatus}
          activityStatusLabel={activityStatusLabel}
          title={title}
          titlePlaceholder={titlePlaceholder}
          onAnalyticsClick={onAnalyticsClick}
          activityIds={activityIds}
          options={options}
          isOptionOpen={isOptionOpen}
          setIsOptionOpen={setIsOptionOpen}
          wrapperRef={wrapperRef}
        />
        <Footer
          activityType={activityType}
          activityStatus={activityStatus}
          startDate={startDate}
          endDate={endDate}
          languages={languages}
          audience={audience}
          audiencePlaceholder={audiencePlaceholder}
          audienceEmptySegmentsLabel={audienceEmptySegmentsLabel}
          likes={likes}
          views={views}
        />
      </div>
    </div>
  );
};

export default SActivityListItem;
