/**
 * Displays a shortcut card
 *
 * Props:
 * - illustration: 'campaign' | 'knowledge' | 'breakingNews',
 * - title: string,
 * - body: string,
 * - link: link that will be opened when clicking the Learn More button
 * - onLearnMorePress: function called when the Learn more link is clicked
 * - onNavigationClick: function called when the Let's go button is clicked
 * - onNavigationEnd: function used in case of UButtonWithLoading
 * - disabled?: boolean,
 * - align: string to ('left' | 'center')
 * - size: size of the component ('100%' | '50%' | '33%')
 * - style: to override component's style
 * - trackingAction?: tracking action
 * - trackingContext?: tracking context
 */

import React, { useState, useCallback, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import type { IllustrationName } from 'Components/foundation/illustrations';

import SBackgroundHeader, { SBackgroundHeaderProps } from 'Components/structural/SBackgroundHeader/SBackgroundHeader';
import UButton from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import { COLORS } from 'Components/foundation';
import { TrackingProps } from 'Services/trackingService';

import styles from './SShortcutCard.style';

export interface SShortcutCardProps extends TrackingProps {
  illustration: IllustrationName;
  title: string;
  body: string;
  link?: string;
  onLearnMorePress?: null | (() => void);
  onNavigationClick?: null | (() => void);
  onNavigationEnd?: null | (() => void);
  status?: SBackgroundHeaderProps['status'];
  align?: 'left' | 'center';
  size?: '100%' | '50%' | '33%';
  style?: CSSProperties;
}

export const SShortcutCard = ({
  illustration,
  title,
  body,
  link = '',
  onLearnMorePress,
  onNavigationClick,
  onNavigationEnd = null,
  trackingAction,
  trackingContext,
  status = 'active',
  align = 'center',
  size = '50%',
  style = {},
}: SShortcutCardProps) => {
  const [isHover, setIsHover] = useState(false);

  const handleHover = useCallback(() => {
    if (status === 'active') {
      setIsHover(true);
    }
  }, [status]);

  const handleLeave = useCallback(() => setIsHover(false), []);

  const buttonDisabled = useMemo(() => status !== 'active' && status !== 'validated', [status]);
  const buttonStyle = useMemo(() => {
    if (status === 'active') {
      return 'accentuated';
    }

    return 'standard';
  }, [status]);

  const hoverStyle = useMemo(
    () => ({
      borderColor: isHover && status === 'active' ? COLORS.BLUE_GRAY_DARKER.DEFAULT : 'transparent',
    }),
    [status, isHover],
  );

  const trackingProps = useMemo(
    () =>
      trackingAction !== undefined && trackingContext !== undefined
        ? {
            trackingAction,
            trackingContext,
          }
        : {},
    [trackingAction, trackingContext],
  );

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={{
        ...styles.wrapper,
        alignItems: align === 'center' ? align : 'flex-start',
        width: size,
        ...hoverStyle,
        ...style,
      }}
    >
      <div style={styles.mainWrapper}>
        <SBackgroundHeader illustration={illustration} status={status} />
        <div
          style={{
            ...styles.middleWrapper,
            alignSelf: align === 'center' ? align : undefined,
            textAlign: align,
          }}
        >
          <p style={styles.title}>{title}</p>
          <p style={styles.body}>{body}</p>
        </div>
      </div>
      <div
        style={{
          ...styles.buttonWrapper,
          alignSelf: align === 'center' ? align : 'flex-end',
        }}
      >
        {status !== 'disabled' && (
          <>
            {onLearnMorePress && (
              <UTextLink
                text={t('structural_component:s_shortcut_card.learn_more')}
                onClick={onLearnMorePress}
                disabled={buttonDisabled}
                type={buttonStyle}
                link={link}
                rightIcon="external-link"
              />
            )}
            {onNavigationEnd && onNavigationClick && (
              <UButtonWithLoading
                text={t('structural_component:s_shortcut_card.lets_go')}
                onClick={onNavigationClick as () => Promise<void>}
                type={buttonStyle}
                onRequestEnd={onNavigationEnd}
                disabled={buttonDisabled}
                {...trackingProps}
              />
            )}
            {!onNavigationEnd && onNavigationClick && (
              <UButton
                text={t('structural_component:s_shortcut_card.lets_go')}
                onClick={onNavigationClick}
                type={buttonStyle}
                disabled={buttonDisabled}
                {...trackingProps}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SShortcutCard;
