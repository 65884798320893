import { useCallback, useEffect, useMemo, useState } from 'react';

import { getAudienceGroupFactory } from 'Features/audience/services/audience-group-list.service';
import type { Language } from 'Features/audience/types/language';
import { useAppDispatch } from 'Libs/redux/store';
import type { FilterItem } from 'Libs/filter/types';
import { enqueueBasicAlert } from 'Pages/AlertManager/redux/utils';

import { AudienceGroupStatus } from './useAudienceGroupListFiltersConfig';

export type ServiceProps = {
  entityId: number;
  status: AudienceGroupStatus;
  title: string | null;
  selectedSort: FilterItem;
  language: Language;
};

export type LoadAudienceGroupsArgs = {
  selectedSort: FilterItem;
  status: AudienceGroupStatus;
  title: string | null;
  offset: number;
  limit?: number;
};

export const useAudienceGroupListService = ({ entityId, status, title, selectedSort, language }: ServiceProps) => {
  const [noContent, setNoContent] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  const getAudienceGroup = useMemo(() => getAudienceGroupFactory(dispatch), [dispatch]);

  const loadAudienceGroups = useCallback(
    async (
      id: number,
      {
        selectedSort: selectedSortArg,
        status: statusArg,
        title: titleArg,
        offset: offsetArg,
        limit: limitArg,
      }: LoadAudienceGroupsArgs,
    ) => {
      setLoading(true);

      const payloadRes = await getAudienceGroup({
        entityId: id,
        limit: limitArg ?? limit,
        selectedSort: selectedSortArg,
        status: statusArg,
        title: titleArg,
        offset: offsetArg,
        ruleType: 'segmentation',
        includeMetaGroupAll: true,
        language,
      }).catch(() => {
        enqueueBasicAlert({
          id: 'COURSE_EDITION_AUDIENCE_FETCH_ERROR',
          text: 'Error while retrieving audience groups.',
          title: 'Something went wrong',
          icon: 'alert',
          status: 'error',
          priority: 'low',
        });
      });

      setLoading(false);

      if (payloadRes) {
        const { offset: resOffset, total: resTotal, limit: resLimit } = payloadRes;

        setTotal(resTotal);

        // IF REFRESH
        if (resLimit !== limit) {
          setOffset(resLimit);
        } else {
          const newOffset = resOffset + limit;
          setOffset(newOffset < resTotal ? newOffset : resTotal);
        }
      }
    },
    [getAudienceGroup, language, limit],
  );

  useEffect(() => {
    if (noContent && total > 1) {
      setNoContent(false);
    }
  }, [noContent, total]);

  const loadNextBatchAudienceGroups = useCallback(
    () => loadAudienceGroups(entityId, { offset, selectedSort, status, title }),
    [entityId, loadAudienceGroups, offset, selectedSort, status, title],
  );

  const refreshAudienceGroups = useCallback(
    async () =>
      loadAudienceGroups(entityId, {
        offset: 0,
        limit: offset + 1,
        selectedSort,
        status,
        title,
      }),
    [entityId, loadAudienceGroups, offset, selectedSort, status, title],
  );

  return {
    noContent,
    total,
    limit,
    offset,
    loading,
    setNoContent,
    setTotal,
    setLimit,
    setOffset,
    setLoading,
    loadAudienceGroups,
    loadNextBatchAudienceGroups,
    refreshAudienceGroups,
  };
};
