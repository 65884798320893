import m from 'm';

var component = {};

/*
 *  args = {
 *    fullscreen
 *    className
 *    loading
 *    background
 *    light
 *    fill
 *  };
 */
component.view = function view(c, args) {
  return m('.loader__container', {
    class: [
      args.fullscreen ? 'loader__container--fullscreen' : '',
      args.fill ? 'loader__container--fill' : '',
      args.className || '',
      args.loading ? '' : 'loader__container--hidden',
    ].join(' '),
  }, [
    m('.loader__with__dots', {
      class: args.background ? 'loader__with__dots--withBackground' : '',
    }, [
      m('.loader__dot.loader__dot--1', {
        class: args.light ? 'loader__dot--light' : '',
      }),
      m('.loader__dot.loader__dot--2', {
        class: args.light ? 'loader__dot--light' : '',
      }),
      m('.loader__dot.loader__dot--3', {
        class: args.light ? 'loader__dot--light' : '',
      }),
    ]),
  ]);
};

export default component;
