import { createSelector } from 'reselect';

import type { AlertManagerState, Alert } from './reducer';

export const getBasicAlerts = (alertManager: AlertManagerState) => alertManager.basic;
export const getToastAlerts = (alertManager: AlertManagerState) => alertManager.toast;

export const transformMapIntoArray = <T>(mapObj: { [key: string]: T }): ReadonlyArray<T> =>
  Object.keys(mapObj).reduce((acc: ReadonlyArray<T>, key) => [...acc, mapObj[key]], []) || [];

export const getBasicAlertsAsArray: (s: AlertManagerState) => ReadonlyArray<Alert> = createSelector(
  getBasicAlerts,
  (alerts) => transformMapIntoArray(alerts),
);

export const getToastAlertsAsArray: (s: AlertManagerState) => ReadonlyArray<Alert> = createSelector(
  getToastAlerts,
  (alerts) => transformMapIntoArray(alerts),
);
