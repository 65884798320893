import React, { useMemo, useState, CSSProperties, useEffect } from 'react';
import { t } from 'i18next';

import { fetchFactory, isApiError } from 'Libs/http/utils';
import SUploadDropArea from 'Components/structural/SUploadDropArea/SUploadDropArea';
import MModal from 'Components/modal/MModal/MModal';
import { COLORS } from 'Components/foundation';
import UButton from 'Components/unit/UButton/UButton';

import styles from './MFileUpload.style';

export type MFileUploadProps = {
  title: string;
  description: string;
  maxFileSize: number;
  allowedMimeTypes: Array<string>;
  format: Array<string>;
  uploadErrors: Record<string, string>;
  uploadUrl: string;
  visible: boolean;
  onClose: () => void;
  onEndUpload: <T>(result: T) => void;
  style?: CSSProperties;
  withMockedUpload?: boolean;
};

const bytesToMegabytes = (bytes: number) => Math.round(bytes / (1024 * 1024));

const mock = {
  id: 123,
  metadata: {
    title: 'yo',
    language: 'fr-FR',
    pageCount: 6,
  },
  pages: [
    {
      id: 6,
      pageNumber: 6,
      previewImage:
        'https://sparted-ai.s3.eu-west-1.amazonaws.com/pdf-extraction/0b88a0d6-d8f4-4c6e-b7e3-ac68e3539a79/pdf/6.jpg',
      width: 419.52801513671875,
      height: 595.2760009765625,
      blocks: [
        {
          id: 'block-1',
          type: 'text',
          data: 'Des services adapt\u00e9s \u00e0 vos projets ',
          bounds: [42.51969909667969, 523.6159057617188, 375.0757141113281, 556.8558959960938],
        },
        {
          id: 'block-2',
          type: 'text',
          data: 'Notre offre de services r\u00e9pond \u00e0 vos besoins, quelle que soit votre taille et votre secteur d\u2019activit\u00e9. \n Nos \u00e9quipes commerciales vous accompagnent pour analyser vos projets de transport et d\u00e9terminer vos besoins, r\u00e9aliser les simulations budg\u00e9taires et vous accompagner lors des diff\u00e9rentes \u00e9tapes jusqu\u2019\u00e0 la r\u00e9alisation. ',
          // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
          bounds: [42.52040100097656, 459.5057067871094, 366.84349060058594, 516.2236938476562],
        },
        {
          id: 'block-3',
          type: 'image',
          data: 'https://sparted-ai.s3.eu-west-1.amazonaws.com/pdf-extraction/d5c56745-d359-47a2-9680-93736606b44b/figures/fileoutpart7.png',
          bounds: [31.684600830078125, 90.70823669433594, 213.4785919189453, 267.3970947265625],
        },
        {
          id: 'block-4',
          type: 'text',
          text: 'Des circulations',
          bounds: [42.52040100097656, 58.35780334472656, 109.03047180175781, 73.3157958984375],
        },
      ],
    },
    {
      id: 7,
      pageNumber: 7,
      previewImage:
        'https://sparted-ai.s3.eu-west-1.amazonaws.com/pdf-extraction/0b88a0d6-d8f4-4c6e-b7e3-ac68e3539a79/pdf/7.jpg',
      width: 419.52801513671875,
      height: 595.2760009765625,
      blocks: [],
    },
  ],
};

export const MFileUpload = ({
  title,
  description,
  maxFileSize,
  allowedMimeTypes,
  format,
  visible,
  uploadErrors,
  uploadUrl,
  onClose,
  onEndUpload,
  style,
  withMockedUpload = false,
}: MFileUploadProps) => {
  const modalStyle = useMemo(
    () => ({ ...(withMockedUpload ? styles.modalWithMock : styles.modal), ...style }),
    [style],
  );
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState('');
  const [isMock, setIsMock] = useState(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const formatList = `${format.slice(0, -1).join(', ')} or ${format.slice(-1)}`;
  const defaultLabel = t('modal_components:m_file_upload.default_label', {
    formatList,
    maxFileSize: bytesToMegabytes(maxFileSize),
  });
  const warnSentence2 = t('modal_components:m_file_upload.warn_sentence2', { formatList });
  const errorIncorrectFormat2 = t('modal_components:m_file_upload.error_incorrect_format2', { formatList });

  const handleUpload = async () => {
    const formData = new FormData();

    if (isMock) {
      setIsMock(false);
      return onEndUpload({ pdf: mock });
    }

    if (!uploadedFile) return;

    formData.append('file', uploadedFile);

    setIsUploadInProgress(true);
    const { fetchFn, abortController: controller } = fetchFactory({
      route: uploadUrl,
      method: 'POST',
      payload: formData,
      multipart: true,
    });

    try {
      setAbortController(controller);

      const result = await fetchFn();

      onEndUpload(result);
    } catch (error) {
      if (!isApiError(error) || !error.errorCode) {
        console.error(error);
      } else {
        setUploadError(uploadErrors[error.errorCode] || error.message);
      }
    } finally {
      setIsUploadInProgress(false);
      setUploadedFile(null);
    }
  };

  const handleAbortUpload = () => {
    abortController?.abort();
    setIsUploadInProgress(false);
  };

  const handleFileRemove = () => {
    if (isUploadInProgress) {
      handleAbortUpload();
    }

    setUploadedFile(null);
  };

  const handleDrop = (file: File) => {
    setUploadError('');
    setUploadedFile(file);
  };

  const handleClose = () => {
    handleFileRemove();
    setUploadError('');
    onClose();
  };

  const handleClickMock = () => {
    setIsMock(true);
    handleUpload();
  };

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, []);

  return (
    <MModal
      title={title}
      titleStyle={styles.title}
      description={description}
      descriptionStyle={styles.description}
      footerStyle={styles.footer}
      labelActionButton={t('modal_components:m_file_upload.label_action_button')}
      labelSecondButton={t('modal_components:m_file_upload.label_second_button')}
      onCloseModal={handleClose}
      onAction={handleUpload}
      onSecondAction={isUploadInProgress ? handleAbortUpload : handleClose}
      visible={visible}
      type="standard"
      showExitCross
      style={modalStyle}
      bodyStyle={styles.body}
      isActionLoading={isUploadInProgress}
      disableActionButton={!uploadedFile}
    >
      <div style={styles.contentWrapper}>
        <SUploadDropArea
          backgroundColor={COLORS.BLUE_GRAY_LIGHT.DEFAULT}
          dotsColor={COLORS.PRIMARY.DEFAULT}
          defaultSentence={t('modal_components:m_file_upload.default_sentence')}
          defaultLabel={defaultLabel}
          dropSentence={t('modal_components:m_file_upload.drop_sentence')}
          warnSentence1={t('modal_components:m_file_upload.warn_sentence')}
          warnSentence2={warnSentence2}
          errorIncorrectFormat1={t('modal_components:m_file_upload.error_incorrect_format1')}
          errorIncorrectFormat2={errorIncorrectFormat2}
          errorSizeTooBig={t('modal_components:m_file_upload.error_size_too_big')}
          errorManyFiles={t('modal_components:m_file_upload.error_many_files')}
          uploadingSentence=""
          uploadError={uploadError}
          allowedMimeTypes={allowedMimeTypes}
          onDrop={handleDrop}
          maxFileSize={maxFileSize}
          fileName={uploadedFile?.name}
          removeFile={handleFileRemove}
        />
        {withMockedUpload && (
          <UButton type="accentuated" text="mock" onClick={handleClickMock} style={styles.mockButton} />
        )}
      </div>
    </MModal>
  );
};

export default MFileUpload;
