export const activityStatus = {
  WAITING_FOR_TRANSLATION: -1,
  ARCHIVED: -2,
  UPCOMING: 1,
  LIVE: 2,
  FINISHED: 3,
  INACTIVE: 4,
};

export const activityTypeEnum = {
  DailySerie: 1,
  DAILY_SERIE: 1,
  OneTimeCourse: 2,
  ONE_TIME_COURSE: 2,
  CustomProgress: 3,
  CUSTOM_PROGRESS: 3,
  BreakingNews: 4,
  BREAKING_NEWS: 4,
  RANKING: 5,
  Welcome: 7,
  WELCOME: 7,
  ThatsAllFolks: 8,
  THATS_ALL_FOLKS: 8,
  Interseason: 9,
  INTERSEASON: 9,
  FINISHER: 10,
};

export const dailySerieTypeEnum = {
  Sequential: 1,
  SEQUENTIAL: 1,
  Synchronous: 2,
  SYNCHRONOUS: 2,
};

export const audienceTargetMode = {
  NO_CHOICE: 0,
  MAGIC_CODE: 1,
  EMAIL_LIST: 2,
  SEGMENTATION: 3,
} as const;

export const audienceTargetLabels = {
  1: 'MAGIC_CODE',
  2: 'EMAIL_LIST',
  3: 'SEGMENTATION',
} as const;

export const periodStatus = {
  Inactive: 0,
  Upcoming: 1,
  Live: 2,
  Finished: 3,
};

export const contentStatus = {
  ARCHIVED: -2,
  DRAFT: 2,
  TO_VALIDATE: 3,
  VALIDATED: 4,
};

export const knowledgeStatus = {
  ARCHIVED: -2,
  DRAFT: 1,
  TO_VALIDATE: 2,
  VALIDATED: 9,
  WAITING_FOR_TRANSLATION: 10,
};

export const DocumentStatus = {
  ARCHIVED: -2,
  UNCATEGORIZED: -1,
  UNAVAILABLE: 1,
  AVAILABLE: 2,
};

export const translationStatus = {
  NONE: 0,
  PENDING: 1,
  COMPLETE: 2,
};
export const translationType = {
  SOURCE: 1,
  TARGET: 2,
};

export const contributionType = {
  Created: 1,
  Edited: 2,

  // Deleted             : 3,
  TranslationRequested: 4,
  TranslationValidated: 5,
  ValidationRequested: 6,
  Validated: 7,
};

export const gameplay = {
  QCM: 1,
  SQCM: 2,
  ERROR_TEXT: 3,
  OPEN_QUESTION: 4,
  NO_INTERACTION_CONTENT: 5,
  PICTURE_SPOT: 6,
  SWIPING_CARDS: 7,
  ORDERING_CARDS: 8,
};

export const gameplayName = {
  1: 'QCM',
  2: 'SQCM',
  3: 'ERROR TEXT',
  4: 'OPEN QUESTION',
  5: 'NO INTERACTION CONTENT',
  6: 'PICTURE SPOT',
  7: 'SWIPING CARDS',
  8: 'ORDERING CARDS',
};

export const mediaType = {
  Image: 1,
  Video: 2,
};

export const videoType = {
  Youtube: 1,
  Kumullus: 2,
  ApiVideo: 3,
};

export const Role = {
  UNAUTHENTICATED: -1,
  ROOT: 1,
  MASTER: 2,
  WRITER: 3,
  TRANSLATOR: 4,
  ADMIN: 5,
  PLAYER: 6,
  ANALYST: 7,
};

export const AuthenticationTypes = {
  // TODO: DELETE
  noAuth: 0,
  email: 1,
  sms: 2,
  externalID: 3,
  NO_AUTH: 0,
  EMAIL: 1,
  SMS: 2,
  EXTERNAL: 3,
};

export const RestrictionTypes = {
  // TODO: DELETE
  noRestriction: 0,
  whiteList: 1,
  emailDomain: 2,
  corporateId: 3,
  code: 4,
  NO_RESTRICTION: 0,
  WHITELIST: 1,
  EMAIL_DOMAIN: 2,
  EXTERNAL_SSO: 3,
  CODE: 4,
};

export const QCMFormat = {
  twoSBS: 1,
  twoUpDown: 2,
  three: 3,
  four: 4,
};

export const gameItemTypes = {
  QUESTION: 1,
  ANSWER: 2,
  TEXT: 3,
  LONG_TEXT: 4,
  TITLE: 5,
  SHORT: 6,
  INSTRUCTION: 7,
  HELPER: 8,
  OC_ANSWER: 9,
};

export const playerValidations = {
  PENDING: 1,
  VALIDATED: 2,
  REJECTED: 3,
};

export const dimensionTypes = {
  LANGUAGE: 1,
  COUNTRY: 2,
  STORE: 3,
  ROLE: 4,
  HIDDEN_TAG: 5,
};

export const segmentationBehavior = {
  SIGNUP_ALWAYS_ASKED_BEFORE: 1,
  NO_CREATION_ALLOWED: 2,
  LANGUAGE: 3,
  COUNTRY: 4,
  STORE: 5,
  PRESET_SEGMENTATION_GROUP: 6,
};

export const SEGMENTATION_BEHAVIOR = segmentationBehavior;

export const configurations = {
  NOTIFICATION_TIME: 1,
  SIGNUP_OPEN_INPUT_ACTIVE: 2,
  RECRUITEMENT_ANALYTICS_GAP: 3,
  SIGNUP_OPEN_INPUT_LABEL: 4,
  SIGNUP_OPEN_INPUT_MANDATORY: 5,
  SETUP_CODE_AVAILABLE: 6,
  OFFLINE_NB_DAYS_DOWNLOAD: 7,
  BATTLE_ACTIVE: 8,
  BATTLE_MAXIMUM_NUMBER: 9,
  BATTLE_LIMIT_SEGMENTATION: 10,
  APPLICATION_LOCKOUT: 11,
  APPLICATION_LOCKOUT_MAX_ATTEMPTS: 12,
  APPLICATION_LOCKOUT_FRAME_TIME: 13,
  APPLICATION_LOCKOUT_LOCK_TIME: 14,
  BACK_OFFICE_LOCKOUT: 15,
  BACK_OFFICE_LOCKOUT_MAX_ATTEMPTS: 16,
  BACK_OFFICE_LOCKOUT_FRAME_TIME: 17,
  BACK_OFFICE_LOCKOUT_LOCK_TIME: 18,
  BACK_OFFICE_KUMULLUS_VIDEO_UPLOAD: 21,
  AMBASSADOR_FOLLOWEES_LIMIT: 23,
  BACK_OFFICE_2FA_ACTIVATED: 24,
  CUSTOM_PLAYER_EMAIL_ACTIVATED: 25,
  CUSTOM_PLAYER_EMAIL_TITLE: 26,
  CUSTOM_PLAYER_EMAIL_DESCRIPTION: 27,
  CUSTOM_PLAYER_EMAIL_BUTTON_LABEL: 28,
  CUSTOM_PLAYER_EMAIL_BUTTON_LINK: 29,
  BACK_OFFICE_IP_WHITELIST: 30,
  MINIMUM_MK_VIEW_TIME: 31,
  TWICPICS_CACHE_VERSION: 32,
};

export const swipingCardsItemType = {
  TEXT: 1,
  IMAGE: 2,
  TEXT_AND_IMAGE: 3,
};

export const imageTypeId = {
  ORIGINAL: 1,
  CROPPED: 2,
  PROFILE: 3,
  ASSETS: 4,
};

export const ReportsType = {
  ACTIVE_CAMPAIGN_LEADERBOARDS: 0,
  CONTENT_ANALYTICS: 1,
  CONTENT_ANALYTICS_PER_PLAYER: 2,
  BATTLE_ANALYTICS: 3,
  INCOMPLETE_USERS: 4,
  CONTENT_COMPLETION: 5,
};

export const ContentActions = {
  SAVE: 0,
  PREVIEW: 1,
  VALIDATE: 2,
};

export const AvailableLanguages = {
  ENGLISH: 1,
  FRENCH: 2,
  SPANISH: 3,
  ITALIAN: 4,
  GERMAN: 5,
  RUSSIAN: 6,
  DUTCH: 7,
  PORTUGUESE_BRASIL: 8,
  CHINESE: 9,
  HEBREW: 10,
  CZECH: 11,
  KOREAN: 12,
  JAPANESE: 13,
  TRAD_CHINESE: 14,
  BULGARIAN: 15,
  CROATIAN: 16,
  DANISH: 17,
  FINISH: 18,
  GREEK: 19,
  HUNGARIAN: 20,
  NORWEGIAN: 21,
  POLISH: 22,
  PORTUGUESE: 23,
  ROMANIAN: 24,
  SWEDISH: 25,
  TURKISH: 26,
  SLOVENIAN_SK: 27,
  SLOVENIAN_SL: 28,
  ESTONIAN: 29,
  INDONESIAN: 30,
  ARABIC: 31,
  LITHUANIAN: 32,
  LATVIAN: 34,
  THAI: 35,
};

export const Languages = {
  'en-US': 'us',
  'fr-FR': 'fr',
  'es-ES': 'es',
  'it-IT': 'it',
  'de-DE': 'de',
  'ru-RU': 'ru',
  'nl-NL': 'nl',
  'pt-BR': 'br',
  'zh-CN': 'cn',
  'he-IL': 'il',
  'cs-CZ': 'cz',
  'ko-KR': 'kr',
  'ja-JP': 'jp',
  'zh-TW': 'tw',
  'bg-BG': 'bg',
  'hr-HR': 'hr',
  'da-DK': 'dk',
  'fi-FI': 'fi',
  'el-GR': 'gr',
  'hu-HU': 'hu',
  'nb-NO': 'no',
  'pl-PL': 'pl',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'sv-SE': 'se',
  'tr-TR': 'tr',
  sk: 'sk',
  sl: 'sl',
  'et-EE': 'ee',
  'id-ID': 'id',
  ar: 'ar',
  lt: 'lt',
  'lv-LV': 'lv',
  'th-TH': 'th',
};

export const HTTP_CODE = {
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
};

export enum AuthenticationSteps {
  USER_SESSION = 'user-session',
  TWO_FACTOR_CREDENTIALS_MISSING = 'two-factor-credentials-missing',
  TWO_FACTOR_CREDENTIALS_REQUEST = 'two-factor-credentials-request',
  TWO_FACTOR_CHALLENGE = 'two-factor-challenge',
  TWO_FACTOR_CHALLENGE_SUCCESS = 'two-factor-challenge-success',
}

export enum AuthenticationContext {
  LOGIN = 'login',
  SIGN_UP = 'sign-up',
}

export default {
  activityStatus,
  activityTypeEnum,
  dailySerieTypeEnum,
  audienceTargetLabels,
  audienceTargetMode,
  periodStatus,
  contentStatus,
  knowledgeStatus,
  DocumentStatus,
  translationStatus,
  translationType,
  contributionType,
  gameplay,
  gameplayName,
  mediaType,
  videoType,
  Role,
  AuthenticationTypes,
  RestrictionTypes,
  QCMFormat,
  gameItemTypes,
  playerValidations,
  dimensionTypes,
  segmentationBehavior,
  SEGMENTATION_BEHAVIOR,
  configurations,
  swipingCardsItemType,
  imageTypeId,
  ReportsType,
  ContentActions,
  AvailableLanguages,
  Languages,
  HTTP_CODE,
};
