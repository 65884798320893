import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const ELLIPSIS = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
} as const;

const styles = createStyleSheet({
  wrapper: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
  },
  container: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    flexShrink: 0,
    marginLeft: 'auto',
  },
  textValue: {
    ...TYPOGRAPHY.BODY3,
    ...ELLIPSIS,
    fontWeight: 'bold',
  },
  campaignId: {
    ...TYPOGRAPHY.BODY4,
    ...ELLIPSIS,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    width: 38,
    flexShrink: 0,
  },
  campaignName: {
    ...TYPOGRAPHY.BODY3,
    ...ELLIPSIS,
  },
  userProfilePicture: {
    width: 38,
    flexShrink: 0,

    // The following is needed to vertically center the default profile picture
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    ...TYPOGRAPHY.BODY3,
    ...ELLIPSIS,
  },
  segmentChip: {
    overflow: 'hidden',
  },
});

export default styles;
