// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import SInput from 'Components/structural/SInput/SInput';

import { actions, useSelectedActivity, useDefaultActivity } from '../../../redux';

import styles from './DailySerieNameForm.style';


export const DailySerieNameForm = () => {
  const DEFAULT_PLACEHOLDER = t('activities:overview.form.name_input_placeholder');
  const defaultActivity = useDefaultActivity();
  const selectedActivity = useSelectedActivity();

  const placeholder = selectedActivity.isDefault
    ? DEFAULT_PLACEHOLDER
    : defaultActivity.name || DEFAULT_PLACEHOLDER;

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setName(value)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>
        {t('activities:overview.form.name_input_title')}
        <span style={styles.asterisk}>
          {'*'}
        </span>
      </div>

      <SInput
        type="large"
        placeholder={placeholder}
        maxLength={40}
        value={selectedActivity.name}
        onChange={onChange}
      />
    </div>
  );
};
