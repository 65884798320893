import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  pageLoader: {
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: 780,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 100,
  },
  divider: {
    marginTop: -4,
    marginBottom: 32,
  },
  createButton: {
    alignSelf: 'flex-end',
    marginTop: 48,
    marginBottom: 16,
  },
  listItem: {
    marginBottom: 20,
  },
  liveTitle: {
    ...TYPOGRAPHY.H2,
    color: COLORS.SUCCESS.DEFAULT,
    fontWeight: 'bold',
  },
  firstListItem: {
    marginTop: 16,
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
    paddingTop: 28,
    paddingBottom: 20,
    position: 'relative',
  },
  emptySearchText: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 16,
    textAlign: 'center',
  },
  emptySearchIcon: {
    alignSelf: 'center',
    marginTop: 15,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  asterisk: {
    color: '#FD4E5A',
  },
});
