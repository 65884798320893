/* eslint-disable react/default-props-match-prop-types */
// @flow

/**
 * Display a loader with spinner and label
 *
 * Props:
 *  - style: For overriding the component's style
 *  - label: The text of the loader
 */

import * as React from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import { COLORS } from 'Components/foundation';

import styles from './SPageLoader.style';

type Props = {|
  style: Object,
  label: string,
|};

export class SPageLoader extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, label } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <CircleSpinner
          size={18}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
          loading
        />
        <span style={styles.label}>
          {label}
        </span>
      </div>
    );
  }
}

export default SPageLoader;
