import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const EmptyStateAudienceIllustration = ({ width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 378 234" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M259.117 11.094L265.586 14.1328L271.933 10.4128C273.07 9.74489 274.436 10.6548 274.24 11.9524L273.149 19.1743L278.43 23.9652C279.383 24.8318 278.882 26.4453 277.61 26.6949L270.465 28.1234L267.382 34.8089C266.835 35.9958 265.172 36.1057 264.57 34.9598L261.247 28.6168L254.065 27.9569C252.775 27.8389 252.231 26.2771 253.144 25.3185L258.23 19.9685L256.871 12.8758C256.631 11.6096 257.948 10.5459 259.117 11.094Z"
      fill={COLORS.PRIMARY.MEDIUM}
    />
    <path
      opacity="0.3"
      d="M93.8913 207.678L100.783 195.898C101.596 194.507 100.501 192.799 98.8836 192.937L84.7632 194.138C83.2276 194.269 82.39 195.981 83.2476 197.236L90.4766 207.815C91.3059 209.029 93.1445 208.955 93.8913 207.678Z"
      fill={COLORS.PRIMARY.MEDIUM}
    />
    <rect
      opacity="0.7"
      width="22.9778"
      height="22.9798"
      rx="11.4889"
      transform="matrix(0.930679 -0.365837 0.380542 0.924764 21 87.4062)"
      fill={COLORS.PRIMARY.MEDIUM}
    />
    <rect
      opacity="0.7"
      width="11.7646"
      height="11.7657"
      rx="5.88231"
      transform="matrix(0.930679 -0.365837 0.380542 0.924764 360 134.304)"
      fill={COLORS.PRIMARY.MEDIUM}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.855 113.569C147.999 113.379 157.785 103.475 157.785 91.2857C157.785 78.9777 147.808 69 135.5 69C123.191 69 113.214 78.9777 113.214 91.2857C113.214 103.475 123 113.379 135.144 113.569H129.324C111.196 113.569 96.5 128.265 96.5 146.393V155.327C96.5 156.88 97.7596 158.14 99.3135 158.14H171.687C173.24 158.14 174.5 156.88 174.5 155.327V146.393C174.5 128.265 159.804 113.569 141.676 113.569H135.855Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.855 113.569C147.999 113.379 157.785 103.475 157.785 91.2857C157.785 78.9777 147.808 69 135.5 69C123.191 69 113.214 78.9777 113.214 91.2857C113.214 103.475 123 113.379 135.144 113.569H129.324C111.196 113.569 96.5 128.265 96.5 146.393V155.327C96.5 156.88 97.7596 158.14 99.3135 158.14H171.687C173.24 158.14 174.5 156.88 174.5 155.327V146.393C174.5 128.265 159.804 113.569 141.676 113.569H135.855Z"
      fill="url(#paint0_linear_67_129942)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.855 113.569C257.999 113.379 267.785 103.475 267.785 91.2857C267.785 78.9777 257.808 69 245.5 69C233.191 69 223.214 78.9777 223.214 91.2857C223.214 103.475 233 113.379 245.144 113.569H239.324C221.196 113.569 206.5 128.265 206.5 146.393V155.327C206.5 156.88 207.76 158.14 209.314 158.14H281.687C283.24 158.14 284.5 156.88 284.5 155.327V146.393C284.5 128.265 269.804 113.569 251.676 113.569H245.855Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.855 113.569C257.999 113.379 267.785 103.475 267.785 91.2857C267.785 78.9777 257.808 69 245.5 69C233.191 69 223.214 78.9777 223.214 91.2857C223.214 103.475 233 113.379 245.144 113.569H239.324C221.196 113.569 206.5 128.265 206.5 146.393V155.327C206.5 156.88 207.76 158.14 209.314 158.14H281.687C283.24 158.14 284.5 156.88 284.5 155.327V146.393C284.5 128.265 269.804 113.569 251.676 113.569H245.855Z"
      fill="url(#paint1_linear_67_129942)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.855 126.569C204.999 126.379 214.785 116.475 214.785 104.286C214.785 91.9777 204.808 82 192.5 82C180.191 82 170.214 91.9777 170.214 104.286C170.214 116.475 180 126.379 192.144 126.569H186.324C168.196 126.569 153.5 141.265 153.5 159.393V167.14C153.5 169.349 155.291 171.14 157.5 171.14H227.5C229.709 171.14 231.5 169.349 231.5 167.14V159.393C231.5 141.265 216.804 126.569 198.676 126.569H192.855Z"
      fill={COLORS.PRIMARY.DEFAULT}
    />
    <defs>
      <linearGradient
        id="paint0_linear_67_129942"
        x1="135.5"
        y1="80.1425"
        x2="135.501"
        y2="158.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={COLORS.PRIMARY.MEDIUM} stopOpacity="0.5" />
        <stop offset="1" stopColor={COLORS.PRIMARY_LIGHT.DEFAULT} stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_67_129942"
        x1="224"
        y1="124"
        x2="274.5"
        y2="159.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={COLORS.PRIMARY.MEDIUM} stopOpacity="0.5" />
        <stop offset="1" stopColor={COLORS.PRIMARY_LIGHT.DEFAULT} stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyStateAudienceIllustration;
