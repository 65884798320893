import React, { useMemo } from 'react';
import { css } from 'aphrodite';
import { t } from 'i18next';

import type { CSSProperties, FunctionComponent } from 'react';

import { IconName } from 'Components/foundation/icons';
import { COLORS } from 'Components/foundation';

import UIcon from 'Components/unit/UIcon/UIcon';

import styles, { rotationAnimation } from './UAutoSave.style';

export type UAutoSaveStatus = 'saving' | 'saved' | 'alert';

export type UAutoSaveProps = {
  status: UAutoSaveStatus;
  hideText?: boolean;
  style?: CSSProperties;
};

type UAutoSaveTextAndIcon = {
  text: string;
  icon: IconName;
};

/**
 * Display the current saving status of a resource
 * - status: The current saved status (saving, saved, alert)
 */
export const UAutoSave: FunctionComponent<UAutoSaveProps> = ({ status, hideText = false, style = {} }) => {
  const { icon, text } = useMemo((): UAutoSaveTextAndIcon => {
    switch (status) {
      case 'saving':
        return { icon: 'refresh', text: t('unit_components:auto_save.saving') };
      case 'alert':
        return { icon: 'alert', text: t('unit_components:auto_save.unsaved') };
      default:
        return { icon: 'check-save', text: t('unit_components:auto_save.saved') };
    }
  }, [status]);

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div data-test-id="icon-container" className={status === 'saving' ? css(rotationAnimation) : undefined}>
        <UIcon style={styles.icon} color={COLORS.GREY_DARK.DEFAULT} size={12} name={icon} />
      </div>
      {!hideText && (
        <div data-test-id="save-status-text" style={styles.text}>
          {text}
        </div>
      )}
    </div>
  );
};

export default UAutoSave;
