import { useEffect, useRef } from 'react';

const noop = () => {};

export type UseIntervalHook = (callback: () => void, intervalInMs?: number | null) => void;

/**
 * Hook to ease usage of the window.setInterval api in a react context
 *
 * @param callback - Function callback every `intervalInMs`
 * @param intervalInMs - Delay between callback calls. Stops the interval if set to undefined
 */
export const useInterval: UseIntervalHook = (callback, intervalInMs) => {
  const savedCallback = useRef(callback);

  // Store lastest callback in ref to avoid issues with closures
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!intervalInMs) {
      return noop;
    }

    const id = setInterval(() => savedCallback.current(), intervalInMs);

    return () => {
      clearInterval(id);
    };
  }, [intervalInMs]);
};

export default useInterval;
