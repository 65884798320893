// @flow

import React, { useMemo, useCallback, useEffect } from 'react';
import { t } from 'i18next';

import { useDispatch } from 'react-redux';

import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { NPSHeader } from '../NPSHeader/NPSHeader';
import { NPSStats } from '../NPSStats/NPSStats';

import { useModuleSelector, actions } from '../redux';

import styles from './NPSView.style';

export const NPSView = () => {
  const dispatch = useDispatch();

  const nbPromoters = useModuleSelector((state) => state.stats.nbPromoters);
  const nbPassives = useModuleSelector((state) => state.stats.nbPassives);
  const nbDetractors = useModuleSelector((state) => state.stats.nbDetractors);
  const nbPlayersExposed = useModuleSelector((state) => state.stats.nbPlayersExposed);
  const nbDaysBetweenScores = useModuleSelector((state) => state.stats.nbDaysBetweenScores);

  const currentScore = useModuleSelector((state) => state.stats.currentScore);
  const previousScore = useModuleSelector((state) => state.stats.previousScore);

  const differenceWithPreviousScore = useMemo(() => {
    if (currentScore === null || previousScore === null)
      return 0;

    return currentScore - previousScore;
  }, [previousScore, currentScore]);

  const handleDownloadReportClick = useCallback(() => {
    dispatch(
      actions.getReport()
    );
  }, [dispatch]);

  const total = nbPromoters + nbPassives + nbDetractors;

  useEffect(() => {
    dispatch(
      actions.getStats()
    );
  }, [dispatch]);

  return (
    <div style={styles.container}>
      <NPSHeader
        nbAnswers={total}
        downloadButtonDisabled={currentScore === null}
        onDownloadReportClick={handleDownloadReportClick}
        nbPlayersExposed={nbPlayersExposed}
      />
      {currentScore !== null && (
        <NPSStats
          score={currentScore}
          differenceWithPreviousScore={differenceWithPreviousScore}
          nbDaysBetweenScores={nbDaysBetweenScores}
          nbPromoters={nbPromoters}
          nbPassives={nbPassives}
          nbDetractors={nbDetractors}
        />
      )}
      <UTextLink
        text={t('analytics:nps.view.learn_more')}
        rightIcon="external-link"
        link="https://support.sparted.com/hc/en-us/articles/4405997985042"
      />
    </div>
  );
};

export default NPSView;
