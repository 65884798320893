import m from 'm';
import { t } from 'i18next';
import Bus from 'services/pubSubService';

import EnumMedia from 'models/MediaHandlerEnum';
import Enum from 'models/Enum';
import Image from 'models/Image';

import activityIcon from 'components/activity.icon';
import inputText from 'components/new.new.input.text';
import segmentationInput from 'components/segmentation.input';
import toggleButton from 'components/toggle.button';
import mediaSelector from 'components/activity.media.selector';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.updateActivity = function updateActivity() {
    args.activity().updateSimple(args.activity())
      .then(function applyChanges(updatedActivity) {
        var finalActivity = args.activity().clone();

        finalActivity.active(updatedActivity.active());
        Bus.trigger('activity:save', finalActivity);
      });
  };

  self.switchPicture = function switchPicture(image) {
    if (!image) {
      if (args.activity().coverId())
        return args.activity().cover();

      return new Image({ url: 'https://sparted-prod-common.s3.eu-west-1.amazonaws.com/thats-all-folks-card-cover.png', id: Enum.activityTypeEnum.THATS_ALL_FOLKS });
    }

    console.log('IMAGE SAVED', image.toJSON());

    args.activity().cover(image);
    args.activity().coverId(image.id());
    m.redraw();
    args.activity().subActivity().save()
      .then(function applyChanges(saved) {
        console.log('savedDailySerie', saved);
        m.redraw();
      });
  };

};

component.view = function view(c, args) {
  var activity = args.activity();

  return m('.activityInfos.activityInfos--thatsAllFolks', [
    m('.activityInfos__top', [
      m('.activityInfos__left', [
        m('.activityInfos__img'),
        m(mediaSelector, {
          image    : c.switchPicture,
          cropProps: {
            ...EnumMedia.COMMON.cropProps,
            ...EnumMedia.THATS_ALL_FOLKS,
          },
        }),
      ]),
      m('.activityInfos__right', [
        m('.activityInfos__type', [
          m(activityIcon, {
            class       : 'activityInfos__type__ico',
            activityType: activity.typeId(),
            size        : 'badge',
            rounded     : true,
          }),
          m('.activityInfos__type__value', t('thats_all_folks:thats_all_folks_card')),
        ]),
        m('.activityInfos__input.activityInfos__input--title', [
          m(inputText, {
            model      : activity,
            path       : 'name',
            placeholder: t('thats_all_folks:label_to_display'),
            max        : 30,
          }),
        ]),
        m('.activityInfos__input.activityInfos__input--medium-size.activityInfos__input--thatsAllFolks', [
          m(inputText, {
            model      : activity,
            path       : 'description',
            placeholder: t('thats_all_folks:short_text_to_display'),
            max        : activity.getDescriptionLimit(),
          }),
        ]),
        m('.activityInfos__line', [
          m('.activityInfos__line__icon.activityInfos__line__icon--url-icon'),
          m('.activityInfos__line__label', t('thats_all_folks:external_link')),
          m('.activityInfos__line__input', m(toggleButton, {
            value: activity.thatsAllFolksCard().displayUrl,
            class: 'activity-info-toggle',
          })),
        ]),
        m('.activityInfos__input', {
          class: activity.thatsAllFolksCard().displayUrl() ? '' : 'hidden',
        }, [
          m(inputText, {
            model      : activity.thatsAllFolksCard(),
            path       : 'url',
            placeholder: 'URL',
          }),
        ]),
        m('.activityInfos__input', {
          class: activity.thatsAllFolksCard().displayUrl() ? '' : 'hidden',
        }, [
          m(inputText, {
            model      : activity.thatsAllFolksCard(),
            path       : 'buttonLabel',
            placeholder: t('thats_all_folks:button_label'),
            max        : 25,
          }),
        ]),
      ]),
    ]),
    m('.activityInfos__segmentation', [
      m(segmentationInput, {
        entity         : activity,
        languageUnicity: true,
      }),
    ]),
  ]);
};

export default component;
