import m from 'mithril';
import { t } from 'i18next';

import inputDate from 'components/input.date';

var component = {};

component.controller = function controller() {};

component.view = function view(c, args) {
  return m('.activity-frame', [
    m('.activity-frame__block', [
      m('.activity-date', [
        m('.activity-frame__icon.activity-date__icon'),
        m('.activity-frame__title.activity-date__title', t('mithril_components:activity_date.title')),
        m('.activity-date__date-container', [
          m('.activity-date__date-entry', [
            m(inputDate, {
              label   : t('mithril_components:activity_date.from'),
              value   : args.activity.startDate,
              onchange: args.onStartChange,
            }),
          ]),
          m('.activity-date__date-entry', [
            m(inputDate, {
              label   : t('mithril_components:activity_date.to'),
              value   : args.activity.endDate,
              onchange: args.onEndChange,
            }),
          ]),
        ]),
      ]),
    ]),
  ]);
};

export default component;
