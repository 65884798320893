import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';
import { AudienceTargetLabelIdType } from 'Libs/ts/types';
import { SegmentationGroupItemType } from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';

import Enum from 'Models/Enum';
import { store } from 'Libs/redux/store';
import type { ContentType } from './models/Content';
import type { ActivityType } from './models/Activity';
import type { AlertScope, AlertParams } from './models/Alert';

import { getDefaultActivity } from './selectors';
import { getDailySerieEndDate } from '../date.utils';
import type { ActivityState } from './reducer';

type ActivityFetchByIdAction = ApiActionType<'ACTIVITY_ML_FETCH_BY_ID'>;
type ActivityExportAction = ApiActionType<'ACTIVITY_ML_EXPORT'>;
type ActivityDuplicateAction = ApiActionType<'ACTIVITY_ML_DUPLICATE'>;
type ActivityArchiveAction = ApiActionType<'ACTIVITY_ML_ARCHIVE'>;
type ActivityAskTranslationAction = ApiActionType<'ACTIVITY_ML_ASK_TRANSLATION'>;
type ActivityAskTranslationBatchAction = ApiActionType<'ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH'>;
type ActivityActivateAction = ApiActionType<'ACTIVITY_ML_ACTIVATE'>;
type ActivitySaveAction = ApiActionType<'ACTIVITY_ML_SAVE'>;
type ActivityIsAudienceMagicCodeUniqueAction = ApiActionType<'ACTIVITY_ML_IS_MAGIC_CODE_UNIQUE'>;
type ActivityGetAudienceTargetAction = ApiActionType<'ACTIVITY_ML_GET_AUDIENCE_TARGET'>;
type ActivityExportTargetAction = ApiActionType<'ACTIVITY_ML_EXPORT_AUDIENCE_TARGET'>;
type ActivityRemovePlayerFromAudienceAction = ApiActionType<'ACTIVITY_ML_REMOVE_PLAYER_FROM_AUDIENCE'>;
type ActivityGetMultilingualContentsAction = ApiActionType<'ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS'>;

type ActivityRemoveLanguageAction = {
  type: 'ACTIVITY_ML_REMOVE_LANGUAGE';
  languageId: number;
};

type ActivityResetAction = {
  type: 'ACTIVITY_ML_RESET';
};

type ActivityAddLanguageAction = {
  type: 'ACTIVITY_ML_ADD_LANGUAGE';
  languageId: number;
};

type ActivitySelectLanguageAction = {
  type: 'ACTIVITY_ML_SELECT_LANGUAGE';
  languageId: number;
};

type ActivitySetSetStartDateAction = {
  type: 'ACTIVITY_ML_SET_START_DATE';
  startDate: string;
};

type ActivitySetWeekDaysAction = {
  type: 'ACTIVITY_ML_SET_WEEK_DAYS';
  weekDays: ReadonlyArray<{ id: number }>;
};

type ActivitySetTypeIdAction = {
  type: 'ACTIVITY_ML_SET_TYPE_ID';
  typeId: number;
};

type ActivitySetTargetModeIdAction = {
  type: 'ACTIVITY_ML_SET_TARGET_MODE_ID';
  targetModeId: number;
};

type ActivitySetNumExtraDaysAction = {
  type: 'ACTIVITY_ML_SET_NUM_EXTRA_DAYS';
  numExtraDays: number;
};

type ActivityAddDisabledDayAction = {
  type: 'ACTIVITY_ML_ADD_DISABLED_DAY';
  date: string | null;
};

type ActivitySetDisabledDayAction = {
  type: 'ACTIVITY_ML_SET_DISABLED_DAY';
  key: number;
  date: string | null;
};

type ActivityRemoveDisabledDayAction = {
  type: 'ACTIVITY_ML_REMOVE_DISABLED_DAY';
  key: number;
};

type ActivityAddEmptyDailySerieDayAction = {
  type: 'ACTIVITY_ML_ADD_EMPTY_DAILY_SERIE_DAY';
};

type ActivityRemoveDailySerieDayAction = {
  type: 'ACTIVITY_ML_REMOVE_DAILY_SERIE_DAY';
  key: number;
};

type ActivitySetLock = {
  type: 'ACTIVITY_ML_SET_LOCK';
  lock: boolean;
};

type ActivitySetName = {
  type: 'ACTIVITY_ML_SET_NAME';
  name: string;
};

type ActivitySetDescription = {
  type: 'ACTIVITY_ML_SET_DESCRIPTION';
  description: string;
};

type ActivitySetCover = {
  type: 'ACTIVITY_ML_SET_COVER';
  id: number;
  url: string;
};

type ActivitySetSpecificRuleActive = {
  type: 'ACTIVITY_ML_SET_SPECIFIC_RULE_ACTIVE';
  specificRuleActive: boolean;
};

type ActivitySetSpecificRuleText = {
  type: 'ACTIVITY_ML_SET_SPECIFIC_RULE_TEXT';
  specificRuleText: string;
};

type ActivityAddContentsToDailySerieDayAction = {
  type: 'ACTIVITY_ML_ADD_CONTENTS_TO_DAILY_SERIE_DAY';
  dayKey: number;
  contents: Array<ContentType>;
};

type ActivityAddContentToDailySerieDayToIndexAction = {
  type: 'ACTIVITY_ML_ADD_CONTENT_TO_DAILY_SERIE_DAY_TO_INDEX';
  dayIndex: number;
  contentIndex: number;
  content: ContentType;
};

type ActivityRemoveContentFromDailySerieDayAction = {
  type: 'ACTIVITY_ML_REMOVE_CONTENT_FROM_DAILY_SERIE_DAY';
  contentKey: number;
  dayKey: number;
};

type ActivitySetAlertAction = {
  type: 'ACTIVITY_ML_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};

type ActivityMoveContentInDailySerieDays = {
  type: 'ACTIVITY_ML_MOVE_CONTENT_IN_DAILY_SERIE_DAYS';
  oldContentIndex: number;
  oldDayIndex: number;
  newContentIndex: number;
  newDayIndex: number;
};

type ActivityRemoveAlertAction = {
  type: 'ACTIVITY_ML_REMOVE_ALERT';
  scope: AlertScope;
};

type ActivitySetMagicCodeForAudienceAction = {
  type: 'ACTIVITY_ML_SET_MAGIC_CODE_FOR_AUDIENCE';
  magicCode: string | null;
};

type ActivitySetAudienceSegmentationAction = {
  type: 'ACTIVITY_ML_SET_SEGMENTATION_FOR_AUDIENCE';
  segmentation: ReadonlyArray<SegmentationGroupItemType>;
};

type ActivityEmailToAudienceAction = {
  type: 'ACTIVITY_ML_ADD_EMAILS_TO_AUDIENCE';
  emails: Array<string>;
};

type ConvertActivityToRandomAction = {
  type: 'CONVERT_ACTIVITY_ML_TO_RANDOM';
};

type ConvertRandomToActivity = {
  type: 'CONVERT_RANDOM_TO_ACTIVITY_ML';
};

type UpdateSessionsRandomActivity = {
  type: 'UPDATE_SESSIONS_RANDOM_ACTIVITY_ML';
  payload: { numberOfSessions: number };
};

type ActivityAddContentToRandomDailySerieToIndexAction = {
  type: 'ACTIVITY_ML_ADD_CONTENT_TO_RANDOM_DAILY_SERIE_TO_INDEX';
  numberOfSessions: number;
  contents: ContentType[];
};

type ActivityRemoveContentFromRandomDailySerieAction = {
  type: 'ACTIVITY_ML_REMOVE_CONTENT_FROM_RANDOM_DAILY_SERIE';
  content: ContentType;
  numberOfSessions: number;
};

export const prepareActivityForServer = (state: ActivityState) =>
  state.activities.map((activity: ActivityType) => {
    const newActivity: any = JSON.parse(JSON.stringify(activity));
    const { days } = newActivity.dailySerie;

    newActivity.dailySerie.days = days.map((day: any, index: number) => {
      const dailySerieContents = day.dailySerieContents.map((dsContent: any, indexDsContent: number) => ({
        ...dsContent,
        position: indexDsContent + 1,
      }));

      return {
        ...day,
        order: index + 1,
        dailySerieContents,
      };
    });

    newActivity.dailySerie.dailySerieDayDisabled = activity.dailySerie.dailySerieDayDisabled.filter((day) => day.date);
    newActivity.endDate = getDailySerieEndDate(newActivity.dailySerie, newActivity.startDate);

    delete newActivity.isLocked;

    if (newActivity.dailySerie.specificRuleText === '') newActivity.dailySerie.specificRuleActive = false;

    return newActivity;
  });

export const prepareAudienceForServer = (state: ActivityState) => {
  const mode = state.audience.mode as AudienceTargetLabelIdType;

  return {
    mode,
    config: state.audience[Enum.audienceTargetLabels[mode]],
  };
};

export const actions = {
  fetchById: (id: number): ActivityFetchByIdAction =>
    apiAction({
      type: 'ACTIVITY_ML_FETCH_BY_ID',
      route: `/api/activities/fullMultilingual/${id}`,
      method: 'GET',
    }),

  reset: (): ActivityResetAction => ({
    type: 'ACTIVITY_ML_RESET',
  }),

  selectLanguage: (languageId: number): ActivitySelectLanguageAction => ({
    type: 'ACTIVITY_ML_SELECT_LANGUAGE',
    languageId,
  }),

  addLanguage: (languageId: number): ActivityAddLanguageAction => ({
    type: 'ACTIVITY_ML_ADD_LANGUAGE',
    languageId,
  }),

  setStartDate: (startDate: string): ActivitySetSetStartDateAction => ({
    type: 'ACTIVITY_ML_SET_START_DATE',
    startDate,
  }),

  setWeekDays: (weekDays: ReadonlyArray<{ id: number }>): ActivitySetWeekDaysAction => ({
    type: 'ACTIVITY_ML_SET_WEEK_DAYS',
    weekDays,
  }),

  setTypeId: (typeId: number): ActivitySetTypeIdAction => ({
    type: 'ACTIVITY_ML_SET_TYPE_ID',
    typeId,
  }),

  setTargetModeId: (targetModeId: number): ActivitySetTargetModeIdAction => ({
    type: 'ACTIVITY_ML_SET_TARGET_MODE_ID',
    targetModeId,
  }),

  setNumExtraDays: (numExtraDays: number): ActivitySetNumExtraDaysAction => ({
    type: 'ACTIVITY_ML_SET_NUM_EXTRA_DAYS',
    numExtraDays,
  }),

  addDisabledDay: (date: string | null): ActivityAddDisabledDayAction => ({
    type: 'ACTIVITY_ML_ADD_DISABLED_DAY',
    date,
  }),

  setDisabledDay: (key: number, date: string | null): ActivitySetDisabledDayAction => ({
    type: 'ACTIVITY_ML_SET_DISABLED_DAY',
    key,
    date,
  }),

  removeDisabledDay: (key: number): ActivityRemoveDisabledDayAction => ({
    type: 'ACTIVITY_ML_REMOVE_DISABLED_DAY',
    key,
  }),

  addEmptyDailySerieDay: (): ActivityAddEmptyDailySerieDayAction => ({
    type: 'ACTIVITY_ML_ADD_EMPTY_DAILY_SERIE_DAY',
  }),

  removeDailySerieDay: (key: number): ActivityRemoveDailySerieDayAction => ({
    type: 'ACTIVITY_ML_REMOVE_DAILY_SERIE_DAY',
    key,
  }),

  exportActivity: (id: number): ActivityExportAction =>
    apiAction({
      type: 'ACTIVITY_ML_EXPORT',
      route: `/api/activities/${id}/export`,
      method: 'GET',
    }),

  duplicate: (id: number): ActivityDuplicateAction =>
    apiAction({
      type: 'ACTIVITY_ML_DUPLICATE',
      route: `/api/activities/${id}/duplicateMultilingual`,
      method: 'POST',
    }),

  setLock: (lock: boolean): ActivitySetLock => ({
    type: 'ACTIVITY_ML_SET_LOCK',
    lock,
  }),

  setName: (name: string): ActivitySetName => ({
    type: 'ACTIVITY_ML_SET_NAME',
    name,
  }),

  setDescription: (description: string): ActivitySetDescription => ({
    type: 'ACTIVITY_ML_SET_DESCRIPTION',
    description,
  }),

  setCover: (id: number, url: string): ActivitySetCover => ({
    type: 'ACTIVITY_ML_SET_COVER',
    id,
    url,
  }),

  setSpecificRuleActive: (specificRuleActive: boolean): ActivitySetSpecificRuleActive => ({
    type: 'ACTIVITY_ML_SET_SPECIFIC_RULE_ACTIVE',
    specificRuleActive,
  }),

  setSpecificRuleText: (specificRuleText: string): ActivitySetSpecificRuleText => ({
    type: 'ACTIVITY_ML_SET_SPECIFIC_RULE_TEXT',
    specificRuleText,
  }),

  addContentsToDailySerieDay: (
    dayKey: number,
    contents: Array<ContentType>,
  ): ActivityAddContentsToDailySerieDayAction => ({
    type: 'ACTIVITY_ML_ADD_CONTENTS_TO_DAILY_SERIE_DAY',
    dayKey,
    contents,
  }),

  addContentToDailySerieDayToIndex: (
    dayIndex: number,
    contentIndex: number,
    content: ContentType,
  ): ActivityAddContentToDailySerieDayToIndexAction => ({
    type: 'ACTIVITY_ML_ADD_CONTENT_TO_DAILY_SERIE_DAY_TO_INDEX',
    dayIndex,
    contentIndex,
    content,
  }),

  removeContentFromDailySerieDay: (
    contentKey: number,
    dayKey: number,
  ): ActivityRemoveContentFromDailySerieDayAction => ({
    type: 'ACTIVITY_ML_REMOVE_CONTENT_FROM_DAILY_SERIE_DAY',
    contentKey,
    dayKey,
  }),

  setAlert: (scope: AlertScope, params: AlertParams = Object.freeze({})): ActivitySetAlertAction => ({
    type: 'ACTIVITY_ML_SET_ALERT',
    scope,
    params,
  }),

  moveContentInDailySerieDays: (
    oldContentIndex: number,
    oldDayIndex: number,
    newContentIndex: number,
    newDayIndex: number,
  ): ActivityMoveContentInDailySerieDays => ({
    type: 'ACTIVITY_ML_MOVE_CONTENT_IN_DAILY_SERIE_DAYS',
    oldContentIndex,
    oldDayIndex,
    newContentIndex,
    newDayIndex,
  }),

  removeAlert: (scope: AlertScope): ActivityRemoveAlertAction => ({
    type: 'ACTIVITY_ML_REMOVE_ALERT',
    scope,
  }),

  activate: (active: boolean): ActivityActivateAction => {
    const state = store.getState();
    const defaultActivity = getDefaultActivity(state);

    const payload = { multilingualId: defaultActivity.multilingualId, status: active };

    return apiAction({
      type: 'ACTIVITY_ML_ACTIVATE',
      route: '/api/activities/activeStatus',
      method: 'PUT',
      payload,
    });
  },

  archive: (archived: boolean): ActivityArchiveAction => {
    const state = store.getState();
    const { multilingualId } = state.pages.activity.activities[0];
    const payload = { multilingualId, status: archived };

    return apiAction({
      type: 'ACTIVITY_ML_ARCHIVE',
      route: '/api/activities/archiveStatus',
      method: 'PUT',
      payload,
    });
  },

  askTranslation: (ids: Array<number>): ActivityAskTranslationAction => {
    const state = store.getState();
    const activity = state.pages.activity.activities[0];

    const payload = { multilingualId: activity.id, languages: ids };

    return apiAction({
      type: 'ACTIVITY_ML_ASK_TRANSLATION',
      route: '/api/activities/askTranslation',
      method: 'POST',
      payload,
    });
  },

  askTranslationBatch: (
    knowledgeIds: ReadonlyArray<number>,
    languageIds: ReadonlyArray<number>,
  ): ActivityAskTranslationBatchAction => {
    const payload = { knowledgeIds, languages: languageIds };

    return apiAction({
      type: 'ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH',
      route: '/api/knowledge/askTranslationBatch',
      method: 'POST',
      payload,
    });
  },

  getMultilingualContents: (
    contentIds: ReadonlyArray<number>,
    languageIds: ReadonlyArray<number>,
  ): ActivityGetMultilingualContentsAction =>
    apiAction({
      type: 'ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS',
      route: '/api/contents/multilingualContents',
      method: 'GET',
      query: { contentIds, languageIds },
    }),

  save: (): ActivitySaveAction => {
    const state = store.getState();
    const defaultActivity = getDefaultActivity(state);
    const activities: ReadonlyArray<ActivityType> = prepareActivityForServer(state.pages.activity);
    const audience = prepareAudienceForServer(state.pages.activity);
    const payload = { activities, audience };

    return apiAction({
      type: 'ACTIVITY_ML_SAVE',
      route: `/api/activities/fullMultilingual/${defaultActivity.multilingualId}`,
      method: 'PUT',
      payload,
    });
  },

  setMagicCode: (magicCode: string | null): ActivitySetMagicCodeForAudienceAction => ({
    type: 'ACTIVITY_ML_SET_MAGIC_CODE_FOR_AUDIENCE',
    magicCode,
  }),

  setAudienceSegmentation: (
    segmentation: ReadonlyArray<SegmentationGroupItemType>,
  ): ActivitySetAudienceSegmentationAction => ({
    type: 'ACTIVITY_ML_SET_SEGMENTATION_FOR_AUDIENCE',
    segmentation,
  }),

  isAudienceMagicCodeUnique: (): ActivityIsAudienceMagicCodeUniqueAction =>
    apiAction({
      type: 'ACTIVITY_ML_IS_MAGIC_CODE_UNIQUE',
      route: '/api/accesscontrols/existingEnabledCodes',
      method: 'GET',
    }),

  addEmailsToAudience: (emails: Array<string>): ActivityEmailToAudienceAction => ({
    type: 'ACTIVITY_ML_ADD_EMAILS_TO_AUDIENCE',
    emails,
  }),

  // eslint-disable-next-line @typescript-eslint/ban-types
  getAudienceTarget: (id: number, from: number, to: number, filter: Object): ActivityGetAudienceTargetAction =>
    apiAction({
      type: 'ACTIVITY_ML_GET_AUDIENCE_TARGET',
      route: '/api/activities/targetAudience',
      method: 'POST',
      payload: {
        from,
        to,
        filter,
        id,
      },
      meta: { filter },
    }),

  exportTargetAudience: (id: number): ActivityExportTargetAction =>
    apiAction({
      type: 'ACTIVITY_ML_EXPORT_AUDIENCE_TARGET',
      route: `/api/activities/${id}/audience/target/export`,
      method: 'GET',
    }),

  removePlayerFromAudience: (id: number, playerArray: Array<any>): ActivityRemovePlayerFromAudienceAction =>
    apiAction({
      type: 'ACTIVITY_ML_REMOVE_PLAYER_FROM_AUDIENCE',
      route: `/api/activities/${id}/removePlayerFromAudience`,
      method: 'DELETE',
      payload: { emails: playerArray },
    }),

  removeLanguage: (languageId: number): ActivityRemoveLanguageAction => ({
    type: 'ACTIVITY_ML_REMOVE_LANGUAGE',
    languageId,
  }),

  convertActivityToRandom: (): ConvertActivityToRandomAction => ({
    type: 'CONVERT_ACTIVITY_ML_TO_RANDOM',
  }),

  convertActivityToManual: (): ConvertRandomToActivity => ({
    type: 'CONVERT_RANDOM_TO_ACTIVITY_ML',
  }),

  updateSessionsRandomActivity: (numberOfSessions: number): UpdateSessionsRandomActivity => ({
    type: 'UPDATE_SESSIONS_RANDOM_ACTIVITY_ML',
    payload: { numberOfSessions },
  }),

  addContentToRandomDailySerieToIndex: (
    numberOfSessions: number,
    contents: ContentType[],
  ): ActivityAddContentToRandomDailySerieToIndexAction => ({
    type: 'ACTIVITY_ML_ADD_CONTENT_TO_RANDOM_DAILY_SERIE_TO_INDEX',
    numberOfSessions,
    contents,
  }),

  removeContentFromRandomDailySerie: (
    content: ContentType,
    numberOfSessions: number,
  ): ActivityRemoveContentFromRandomDailySerieAction => ({
    type: 'ACTIVITY_ML_REMOVE_CONTENT_FROM_RANDOM_DAILY_SERIE',
    content,
    numberOfSessions,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type ActivityAction = ReturnType<ActionsFn>;
