import Model from 'models/Model';

var SwipingCardsItemType = {
  endpoint  : 'api/SwipingCardsItemTypes',
  attributes: {
    label: {
      type: 'string',
    },
  },
  methods: {},
};

export default Model.register('SwipingCardsItemType', SwipingCardsItemType);
