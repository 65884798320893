import React, { useEffect, useState, useCallback } from 'react';

import UButton from 'Components/unit/UButton/UButton';
import SInput from 'Components/structural/SInput/SInput';

import { tracking } from 'Services/trackingService';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { actions } from '../../redux';

import styles from './ResetPasswordForm.style';

type Props = {
  instanceName: string;
};

const DEFAULT_WAITING_TIME = 30;

type ResetPasswordButtonProps = {
  onSubmit: () => void;
  remainingTime: number;
  isDisabled: boolean;
};

const ResetPasswordButton = ({ onSubmit, remainingTime, isDisabled }: ResetPasswordButtonProps) => {
  const isEmailSent = useTypedSelector((state) => state.pages.resetPassword.isEmailSent);
  const isLoading = useTypedSelector((state) => state.pages.resetPassword.loading);
  const error = useTypedSelector((state) => state.pages.resetPassword.error);

  if (isEmailSent) {
    if (remainingTime > 0) {
      return (
        <>
          <UButton
            text="Email sent"
            onClick={onSubmit}
            loading={isLoading}
            style={styles.button}
            disabled
            type="accentuated"
            ghost
          />
          <div style={styles.remainingTimeMsg}>{`Try again in ${remainingTime}s`}</div>
        </>
      );
    }

    return (
      <>
        <UButton
          text="I have not received the link"
          onClick={onSubmit}
          loading={isLoading}
          disabled={isDisabled}
          style={styles.button}
          ghost
          type="accentuated"
        />
        <div style={styles.errorMsg}>{error}</div>
      </>
    );
  }

  return (
    <>
      <UButton
        type="accentuated"
        text="Email me the reset link"
        onClick={onSubmit}
        loading={isLoading}
        disabled={isDisabled}
        style={styles.button}
      />
      <div style={styles.errorMsg}>{error}</div>
    </>
  );
};

export const ResetPasswordForm = ({ instanceName }: Props) => {
  const dispatch = useTypedDispatch();
  const isEmailSent = useTypedSelector((state) => state.pages.resetPassword.isEmailSent);
  const email = useTypedSelector((state) => state.pages.resetPassword.email);

  const [isDisabled, setIsDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);

  const onEmailChange = useCallback((_email) => dispatch(actions.setEmail(_email)), [dispatch]);
  const onSubmit = useCallback(() => {
    tracking.contributorsForgetPassword();

    // Start timer if email is already sent
    if (isEmailSent) {
      setRemainingTime(DEFAULT_WAITING_TIME);
    }

    if (email) {
      dispatch(actions.adminResetPassword(email));
    }
  }, [dispatch, email, isEmailSent]);

  useEffect(() => {
    const emailParam = window.location.href.split('?email=');

    if (emailParam && emailParam[1]) {
      dispatch(actions.setEmail(emailParam[1]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (email.length) {
      return setIsDisabled(false);
    }

    return setIsDisabled(true);
  }, [email]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const instruction = `Don't worry, happens to the best of us.
Enter your email adress and we will send you
a password reset link.
`;

  return (
    <div style={styles.resetPasswordWrapper}>
      <div style={styles.instanceName}>{instanceName}</div>

      <div style={styles.title}>Reset password</div>

      <div style={styles.instruction} dangerouslySetInnerHTML={{ __html: instruction }} />

      <div style={styles.inputWrapper}>
        <SInput
          type="small"
          placeholder="email@domain.com"
          value={email}
          onChange={onEmailChange}
          onSubmit={onSubmit}
          autofocus
        />
      </div>

      {isEmailSent && (
        <div style={styles.validation}>
          {`if ${email} is linked to
an account an email will be sent.

Check your Inbox (and spams).
This may take a few moments.`}
        </div>
      )}

      <div style={styles.buttonWrapper}>
        <ResetPasswordButton onSubmit={onSubmit} remainingTime={remainingTime} isDisabled={isDisabled} />
      </div>
    </div>
  );
};
