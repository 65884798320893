import React, { useCallback } from 'react';

import SActionInfoBoxPage from 'Components/structural/SActionInfoBoxPage/SActionInfoBoxPage';
import UButton from 'Components/unit/UButton/UButton';
import UImage from 'Components/unit/UImage/UImage';

import styles from './UpdatePasswordExpirationScreen.style';

export const UpdatePasswordExpirationScreen = () => {
  const onButtonClick = useCallback(() => {
    window.location.href = `${window.location.origin}/login`;
  }, []);

  return (
    <SActionInfoBoxPage>
      <UImage src="https://sparted-prod-common.s3-eu-west-1.amazonaws.com/email-link-expired.svg" />
      <h1 style={styles.title}>Your password reset link expired</h1>
      <span style={styles.description}>A password reset link expires after 24h.</span>
      <UButton type="accentuated" text="Back to login" onClick={onButtonClick} style={styles.button} />
    </SActionInfoBoxPage>
  );
};
