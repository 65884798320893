import { DeepPartial } from 'ts-essentials';

import { fetchFactory } from 'Libs/http/utils';

import Enum from 'Models/Enum';
import { fromEntityContentMapper } from './content.mapper';
import { ContentEntity, UpdateContentResponse } from './content.types';

export const updateContent = async (contentId: number, content: DeepPartial<ContentEntity>) => {
  const payload = fromEntityContentMapper(content);

  const { fetchFn } = fetchFactory<UpdateContentResponse>({
    route: `/api/contents/${contentId}`,
    method: 'PATCH',
    payload,
  });
  return fetchFn();
};

export const validateContent = async (contentId: number, languageCode: keyof typeof Enum.Languages) => {
  const { fetchFn } = fetchFactory<UpdateContentResponse>({
    route: `/api/contents/${contentId}/validate`,
    method: 'PATCH',
    payload: { languageCode },
  });

  return fetchFn();
};

export const createContent = async (content: DeepPartial<ContentEntity>) => {
  const payload = fromEntityContentMapper(content);
  const { fetchFn } = fetchFactory<{ id: number }>({
    route: '/api/contents',
    method: 'POST',
    payload,
  });

  return fetchFn();
};
