import { fetchFactory } from 'Libs/http/utils';
import type { HTTPMethod } from 'Libs/http/utils';

import type { AsyncActionType } from './asyncAction';

export type ApiActionType<T extends string> = AsyncActionType<T> & {
  cancel: () => void,
};

export type ApiActionParams<T> = {
  type: T,
  route: string,
  method: HTTPMethod,
  payload?: any,
  query?: any,
  meta?: any,
  multipart?: boolean,
};

type ApiActionFn = <T extends string>(params: ApiActionParams<T>) => ApiActionType<T>;

export const apiAction: ApiActionFn = (params) => {
  const { type, route, query, payload, method, meta, multipart } = params;

  const { fetchFn, abortController } = fetchFactory({ route, query, payload, method, multipart });

  const promise = Promise.resolve().then(fetchFn);

  return {
    type,
    promise,
    cancel: () => abortController.abort(),

    // This field will be populated by redux-pack
    payload: undefined,
    meta: meta || {},
  };
};
