import m from 'mithril';
import { t } from 'i18next';
import button from 'components/button';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.action = function action() {
    args.action();
    args.close();
  };
};

component.view = function view(c, args) {
  return m('.confirmation.popup', [
    m('.popup__body', [
      m('.popup__title', args.title || t('mithril_components:popup_confirmation.title')),
      m('label', [
        m('.popup__subtitle', args.subtitle || t('mithril_components:popup_confirmation.subtitle')),
      ]),
      m('.popup__body__content', args.content),
    ]),
    m('.popup__footer.clearFix', [
      m(button, {
        value : args.cancel || t('mithril_components:popup_confirmation.cancel'),
        class : 'button--action button--left button--border button--cancel',
        action: args.close,
      }),
      m(button, {
        value : args.confirm || t('mithril_components:popup_confirmation.confirm'),
        class : 'button--action button--right',
        action: c.action,
      }),
    ]),
  ]);
};

export default component;
