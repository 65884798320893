import m from 'm';
import Promise from 'bluebird';
import { t } from 'i18next';

import SegmentationDimension from 'models/SegmentationDimension';
import Bus from 'services/pubSubService';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import segmentationBranch from 'components/segmentation.branch';
import User from 'services/userService';
import reactCreator from 'components/react.creator';

const MENUBAR_HEIGTH = 54;
const GAIA_BAR_HEIGTH = 54;
const BOTTOM_PADDING = 75;
const CALLOUT_HEIGHT = 102 + 24 + 24;

const component = {};

component.controller = function controller() {
  var self = this;

  self.dimensions = m.prop([]);
  self.dimConfigurated = m.prop(false);
  self.displayLockedCallout = m.prop(!User.meSync().isMasterAdmin());

  SegmentationDimension
    .getDimensionsNested()
    .then(function handleRes(res) {
      console.log('self.dimension', res.toJSON());
      self.dimensions(res);
      m.redraw();
    });

  self.error = m.prop('');
  Bus.subscribe('general:error', displayError);
  Bus.subscribe('general:noError', hideError);

  self.onunload = function onunload() {
    Bus.unsubscribe('general:error', displayError);
    Bus.unsubscribe('general:noError', hideError);
  };

  self.createDimension = function createDimension() {
    var deepestDimensionOrder = 0;

    if (!User.meSync().isMasterAdmin())
      return;

    function recursivelyFindDeepestDimension(dimensions) {
      dimensions.forEach(function getOrder(dimension) {
        if (dimension.order() > deepestDimensionOrder)
          deepestDimensionOrder = dimension.order();

        recursivelyFindDeepestDimension(dimension.children());
      });
    }
    recursivelyFindDeepestDimension(self.dimensions());

    var newDimension = new SegmentationDimension({
      order: deepestDimensionOrder + 1,
    });

    self.dimensions().push(newDimension);
  };

  self.moveDimension = function moveDimension(dim, up) {
    var switchDimension;

    if (!User.meSync().isMasterAdmin())
      return;

    self.dimensions().forEach(function getSwitch(dimension) {
      // Check if we need to move the column to the right
      if (up && dim.order() < dimension.order()
        && (!switchDimension
          || (dimension.order() < switchDimension.order())
        )
      )
        switchDimension = dimension;

      // Check if we need to move the column to the left
      if (!up && dim.order() > dimension.order()
        && (!switchDimension
          || (dimension.order() > switchDimension.order())
        )
      )
        switchDimension = dimension;
    });

    if (!switchDimension)
      return undefined;

    var switchOrder = switchDimension.order();

    switchDimension.order(dim.order());
    dim.order(switchOrder);

    return self.reorderDimensions();
  };

  self.reorderDimensions = function reorderDimensions() {
    var promises = [];
    var count = 1;

    function recursivelyOrderDimensions(dimensions) {
      dimensions
        .sort(function order(a, b) {
          return a.order() - b.order();
        });

      dimensions.forEach(function orderDimension(dimension) {
        dimension.order(count);
        promises.push(dimension.save());
        count++;

        dimension.children()
          .sort(function order(a, b) {
            return a.order() - b.order();
          });

        recursivelyOrderDimensions(dimension.children());
      });
    }
    recursivelyOrderDimensions(self.dimensions());

    return Promise.all(promises);
  };

  self.isThereCreation = function isThereCreation() {
    var res = false;

    self.dimensions().forEach(function findCreation(dim) {
      if (!dim.id())
        res = true;
    });

    return res;
  };

  self.configPage = function configPage(elem) {
    var columns = document.querySelectorAll('.segmentation-page__dimension-container');

    // Make sure it is an array
    columns = Array.prototype.slice.call(columns, 0);

    columns.forEach(function setSize(item) {
      const bodyHeight = document.body.clientHeight;
      const newHeight = bodyHeight - MENUBAR_HEIGTH - GAIA_BAR_HEIGTH - BOTTOM_PADDING - CALLOUT_HEIGHT;

      item.style.height = `${newHeight}px`;
    });

    if (!document.querySelector('.segmentation-dimension__column')
      || !document.querySelector('.segmentation-dimension__column')
      || !document.querySelector('.segmentation-dimension__column__header')
      || !document.querySelector('.segmentation-dimension-children-container')
      || !document.querySelector('.segmentation-dimension__column')
      || self.dimConfigurated()
    )
      return;

    document.querySelector('.segmentation-page__new-dimension').style.height = document.querySelector('.segmentation-dimension-children-container').clientHeight + 'px';
    document.querySelector('.segmentation-dimension__column--creation').style.height = document.querySelector('.segmentation-dimension__column').clientHeight + 'px';
    self.dimConfigurated(true);
  };

  function displayError(err) {
    self.error(err);
  }

  function hideError() {
    self.error('');
  }

};

component.view = function view(c) {
  c.dimensions()
    .sort(function order(a, b) {
      return a.order() - b.order();
    });

  return m('.segmentation-page', {
    config: c.configPage,
  }, [
    m(menu),
    m(gaiaMenu),
    m('.segmentation-page__dimension-container', [
      ...(c.displayLockedCallout() ? [
        m(reactCreator, {
          component: 'SCallout',
          props: {
            type: 'warning',
            titleIcon: 'lock',
            title: t('segmentation:locked_callout.title'),
            description: t('segmentation:locked_callout.description'),
            style: {
              maxWidth: '1000px',
              marginTop: '24px',
              marginBottom: '24px',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          },
        }),
        ] : [
          m('.segmentation-page__dimension-container__margin')
        ]),
      c.dimensions().map(function displayDimension(dim, i) {
        return m(segmentationBranch, {
          dimension      : dim,
          moveDimension  : c.moveDimension,
          key            : dim.id() || dim._uuid,
          orderDimensions: c.reorderDimensions,
          isLastBranch   : i === c.dimensions().length - 1,
          isThereCreation: c.isThereCreation,
          collection     : c.dimensions,
        });
      }),
      m('.segmentation-page__new-dimension', {
        class: c.isThereCreation() ? 'hide' : '',
      }, [
        m('.segmentation-dimension__column.segmentation-dimension__column--creation', [
          m('.segmentation-page__new-dimension__commands', [
            m('.segmentation-page__new-dimension__command.segmentation-page__new-dimension__command--add', {
              onclick: c.createDimension,
            }, '+ Add a dimension'),
          ]),
        ]),
      ]),
    ]),
  ]);
};
export default component;
