// @flow

import { COLORS } from 'Components/foundation';

export default {
  container: {
    width: 1000,
    margin: 'auto',
    marginTop: 80,

    paddingTop: 40,
    paddingBottom: 48,
    paddingLeft: 30,
    paddingRight: 30,

    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
};
