/**
 * Display a the restriction parameter's information
 *
 * Props:
 *  - title: The title to display in the restriction parameter header
 *  - id: The restriction parameter index (displayed in the title)
 *  - parameterLabel: Display a label on top of restriction parameter input
 *  - configuration: An object to setup restriction parameter input @see Configuration
 *  - source: @see Source in Libs/ts/types, needed by @see SOmnibox
 *  - onFilterChanged: Handle the filters change in Omnibox input
 *  - filterLabel: Display a label on top of the Omnibox input
 *  - deletable: Boolean which manage the deletion icon
 *  - onDelete: Callback when clicking on the deletion icon
 *  - style: can override the component style
 *  - dimensions: array to cluster segments by dimensions
 */

import React, { CSSProperties, useCallback } from 'react';

import SCodeInput from 'Components/structural/SCodeInput/SCodeInput';
import SInput from 'Components/structural/SInput/SInput';
import SDivider from 'Components/structural/SDivider/SDivider';
import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';
import UButton from 'Components/unit/UButton/UButton';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import type { Source } from 'Libs/ts/types';
import { FilterCategoryWithLabel, FilterState, GenericFilterCategory, isTextFilter } from 'Libs/filter/types';

import styles from './SRestrictionParameter.style';

const RESTRICTION_TYPES = Object.freeze({
  MAGIC_CODE: 'magic_code',
  EMAIL_DOMAIN: 'email_domain',
  EMAIL_LIST: 'email_list',
});
const SEGMENTATION_SELECTION_SUGGESTION_OPTIONS = Object.freeze({
  enable: true,
  enableOnEmptyInput: false,
  enableTextSuggestion: false,
});

/**
 * XxxXxxConfiguration: Define the configuration for each type of restriction
 *
 * - type: The type of the configuration
 * then three possibilities:
 * - 'magic_code': @see SCodeInput props to understand which props will do what
 * - 'email_domain': @see SInput props to understand which props will do what
 * - 'email_list': @see UButton props to understand which props will do what
 */

export type CodeChangeFeedbackStatus = 'loading' | 'warning' | 'saved' | 'none';
export type MagicCodeConfiguration = {
  type: 'magic_code';
  onButtonClick: (value: string) => void;
  onIconClick: (value: string) => void;
  onInputChange: React.ChangeEventHandler;
  buttonText: string;
  value?: string;
  buttonDisabled?: boolean;
  placeholder?: string;
  maxLengthValue?: number;
  codeChangeFeedbackStatus: CodeChangeFeedbackStatus;
  codeChangeFeedbackMessage: string;
  onBlur: React.ChangeEventHandler;
};

export type EmailDomainConfiguration = {
  type: 'email_domain';
  placeholder: string;
  onChange: (input: string) => unknown;
  errorMessage?: string;
  value?: string;
};

export type EmailListConfiguration = {
  type: 'email_list';
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export type Configuration = MagicCodeConfiguration | EmailDomainConfiguration | EmailListConfiguration;

/**
 * ParameterConfiguration
 */
interface ParameterConfigurationProps {
  configuration: Configuration;
}
const ParameterConfiguration = ({ configuration }: ParameterConfigurationProps) => {
  const sCodeInputStyle = styles[`parameterInput__${configuration.type}`];

  switch (configuration.type) {
    case RESTRICTION_TYPES.MAGIC_CODE: {
      const { type, ...props } = configuration;

      return <SCodeInput {...props} style={sCodeInputStyle} />;
    }
    case RESTRICTION_TYPES.EMAIL_DOMAIN: {
      const { type, ...props } = configuration;

      return <SInput {...props} type="small" style={sCodeInputStyle} />;
    }
    case RESTRICTION_TYPES.EMAIL_LIST: {
      const { type, ...props } = configuration;

      return <UButton {...props} type="accentuated" style={sCodeInputStyle} />;
    }
    default:
      return null;
  }
};

/**
 * SRestrictionProps
 */
export interface SRestrictionProps {
  title: string;
  id: number;
  parameterLabel: string;
  configuration: Configuration;
  source: Source;
  onFilterChanged: (filters: Array<FilterState>) => unknown;
  filterLabel: string;
  deletable?: boolean;
  onDelete?: (id: number) => unknown;
  style?: CSSProperties;
  dimensions?: Array<{ id: number; label: string }>;
}

export const SRestrictionParameter = ({
  title,
  id,
  parameterLabel,
  configuration,
  source,
  onFilterChanged,
  filterLabel,
  deletable = true,
  onDelete = () => {},
  style = {},
  dimensions = [],
}: SRestrictionProps) => {
  /**
   * CALLBACKS
   */
  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(id);
    }
  }, [onDelete, id]);
  const handleFilterChanged = useCallback(
    (sourceParam: Source) => {
      const result = sourceParam.filters.reduce((acc: FilterState[], filter) => {
        if (isTextFilter(filter)) {
          return acc;
        }

        return acc.concat({
          // there is a typeguard that doesn't work as expected so ...
          category: filter.category as GenericFilterCategory | FilterCategoryWithLabel,
          ids: filter.items.filter(({ selected }) => selected).map((item) => item.id),
        });
      }, []);

      onFilterChanged(result);
    },
    [onFilterChanged],
  );

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div style={styles.headerContainer}>
        <div style={styles.titleWrapper}>
          <h2 style={styles.title}>{`${title}${id}`}</h2>
          <p style={styles.subtitle}>(sub-segmentation)</p>
        </div>
        {deletable && <UIconButton onClick={handleDelete} icon="delete" ghost />}
      </div>
      <div style={styles.bodyContainer} data-test-id="restriction-input-container">
        <p style={styles.parameterLabel}>{parameterLabel}</p>
        <ParameterConfiguration configuration={configuration} />
        <SDivider />
        <div>
          <p style={styles.filterLabel}>{filterLabel}</p>
          <SOmnibox
            sources={[source]}
            placeholder="Click here to add a segment"
            onFilterChanged={handleFilterChanged}
            filtersText="Filters"
            suggestionOptions={SEGMENTATION_SELECTION_SUGGESTION_OPTIONS}
            dimensions={dimensions}
            filterMultiText={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SRestrictionParameter;
