import React, { ReactNode } from 'react';

import style from './UErrorTextHighlight.style';

export type UErrorTextHighlightProps = {
  children: ReactNode;
  variant?: 'valid' | 'invalid';
};
export const UErrorTextHighlight = ({ variant = 'valid', children }: UErrorTextHighlightProps) => {
  const componentStyle = variant === 'valid' ? style.borderValid : style.borderInvalid;

  return <span style={componentStyle}>{children}</span>;
};
