import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  title: {
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
    lineHeight: '24px',
    color: COLORS.BLUE_GRAY_DARKEST.DEFAULT,
  },
  titleContainer: {
    maxWidth: '456px',
    marginBottom: '16px',
  },
  container: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    border: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    borderRadius: '12px',
    padding: '48px 32px',
    display: 'flex',
  },
});

export default styleSheet;
