type ValueType =
  | boolean
  | string
  | number
  | null
  | void
  | Array<any>
  | ReadonlyArray<any>;

// Note that our DeepPartial definition essentially differs from the
// one from @reduxjs/toolkit in so far that it handles arrays as values
// instead of objects
export type DeepPartial<T> = T extends ValueType
  ? T
  : { [P in keyof T]?: DeepPartial<T[P]> };

// https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript/8511350#8511350
const isObject = (a: any) => typeof a === 'object' && a !== null;
const isValueType = (a: any): a is ValueType => (
  !isObject(a) || Array.isArray(a)
);

export const mergeDeep = <T>(a: T, b: DeepPartial<T>): T => {
  if (isValueType(a) || isValueType(b)) {
    return b as T;
  }

  return (Object.keys(b) as (keyof T)[]).reduce((acc, key) => {
    if (!(key in (a as Object))) {
      return acc;
    }

    if (b[key] === a[key]) {
      return acc;
    }

    const merged = mergeDeep(a[key], (b[key] as DeepPartial<T[keyof T]>));

    return { ...acc, [key]: merged };
  }, a);
};
