import { createStyleSheet } from 'Components/foundation/stylesheets';

export const styles = createStyleSheet({
  default: {
    height: 72,
  },
  childrenWrapper: {
    paddingTop: 24,
    paddingBottom: 48,
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
