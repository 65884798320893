import m from 'm';
import reactCreator from 'components/react.creator';

const component = {};

component.view = function view() {
  return m(reactCreator, {
    component: 'SignUp',
  });
};

export default component;
