import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
  },
  sSubtleInput: {
    backgroundColor: 'transparent',
    display: 'flex',
    marginLeft: '18px',
    height: 32,
    width: 300,
  },
  chevron: {
    cursor: 'pointer',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '10px',
  },
  chevronRotated: {
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '10px',
  },
  noChevron: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '10px',
    visibility: 'hidden',
  },
});

export default styleSheet;
