import m from 'mithril';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.selectItem = function selectItem(_args, item) {
    if (item.inactive)
      return;

    if (_args.selectionPath) {
      console.log('BEFORE:', _args.value());
      console.log('GIVE:', item[_args.selectionPath]());
      _args.value(item[_args.selectionPath]());
      console.log('AFTER:', _args.value());
    } else
      _args.value = item;
    if (_args.onselect)
      _args.onselect(item);
  };

  self.isSelected = function isSelected(_args, item) {
    if (_args.selectionPath)
      return _args.value() === item[_args.selectionPath]();

    return _args.value === item;
  };

};

component.view = function view(c, args) {
  return m('.radio-list', args.items.map(displayItem));

  function displayItem(item) {
    return m('.radio-item', {
      onclick: c.selectItem.bind(null, args, item),
      class  : itemClassGenerator(item),
    }, [
      m('.radio-item__selector', [
        m('.radio-item__selected'),
      ]),
      item.icon ? m('img.radio-item__ico', {
        src: item.icon,
      }) : '',
      m('.radio-item__label', displayItemPath(item)),
      m.hide(!(c.isSelected(args, item) && item.subBlock), '.radio-item__sub-block', [
        item.subBlock,
      ]),
    ]);
  }

  function itemClassGenerator(item) {
    var klass = '';

    klass += c.isSelected(args, item) ? ' radio-item--selected' : '';
    klass += args.block ? ' radio-item--block' : '';
    klass += item.inactive ? ' radio-item--inactive' : '';

    return klass;
  }

  function displayItemPath(item) {
    if (args.displayPath)
      return item[args.displayPath]();

    return item;
  }
};

export default component;
