import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    height: '100%',
    width: '100%',
    paddingBottom: 72,
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    paddingTop: 24,
    paddingLeft: 24,
  },
  list: {
    width: '100%',
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  insertButtonWrapper: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styleSheet;
