import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  item: {
    flexGrow: 1,
  },
  mr: {
    marginRight: '8px',
  },
});
