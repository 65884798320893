import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './AmbassadorRemoveModal.style';

type Props = {
  playerName: string;
  visible: boolean;
  onAction: (success: boolean) => void;
  loading: boolean;
};

export const AmbassadorRemoveModal = ({ playerName, visible, onAction, loading }: Props) => {
  const onValidate = useCallback(() => onAction(true), [onAction]);
  const onDismiss = useCallback(() => onAction(false), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('config_ambassadors:ambassador_remove_modal.title')}
      labelActionButton={t('config_ambassadors:ambassador_remove_modal.label_action_button')}
      labelSecondButton={t('config_ambassadors:ambassador_remove_modal.label_second_button')}
      actionButtonType="destructive"
      isActionLoading={loading}
    >
      <div style={{ ...styles.content, whiteSpace: 'pre-line' }}>
        {t('config_ambassadors:ambassador_remove_modal.content', { playerName })}
      </div>
    </MModal>
  );
};
