import { getCdnFromS3Url } from 'Libs/cdn';
import { mergeDeep } from 'Libs/mergeDeep';

export type PlayerType = Readonly<{
  id: number;
  adminValidationId: number;
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  extraInfo: string;
  profilePicture: any;
  globalScore: number;
  segmentation: any;
  createdAt: any;
}>;

export default function createPlayer(values?: any): PlayerType {
  const defaultState: PlayerType = {
    id: 0,
    adminValidationId: 1,
    active: true,
    email: '',
    firstName: '',
    lastName: '',
    globalScore: 0,
    profilePicture: { url: null },
    segmentation: [],
    createdAt: null,
    extraInfo: '',
  };

  if (!values) {
    return defaultState;
  }

  const profilePicture = {
    url:
      values.profilePicture && values.profilePicture.url
        ? getCdnFromS3Url(values.profilePicture.url, { asThumbnail: true })
        : '',
  };

  return mergeDeep(defaultState, { ...values, profilePicture });
}
