// @flow

import Promise from 'bluebird';
import { KeywordExtraction } from 'Services/AIServices';
import { Google } from 'Libs/imageLibs';
import Image from 'Models/Image';

import type { KeywordItem, ExtractionResponse, ExtractionMethod } from 'Services/AIServices/keywordExtractionService';
import type { ImageBody } from 'Libs/flowTypes';

type AutoSelectResponse = {|
  keywords: Array<KeywordItem>,
  method?: ExtractionMethod,
  image: Object,
  uuid: string,
  toleranceThreshold: number,
|};

type AutoSelectionParams = {
  text: string,
  isStopped: () => boolean,
  language?: number,
  imageIndexToRetrieve?: number,
};

const minTimeLoading = 2000;

const MAX_KEYWORDS_TO_EXTRACT = 3;

class InterruptionError extends Error {
  constructor(...params) {
    super(...params);

    if (Error.captureStackTrace)
      Error.captureStackTrace(this, InterruptionError);

    this.name = 'InterruptionError';
  }
}

export const autoSelectImageFromLib = function autoSelectImageFromLib({
  text,
  isStopped,
  language = 1,
  imageIndexToRetrieve = 0,
}: AutoSelectionParams): Promise<AutoSelectResponse> {
  const autoSelectResult: AutoSelectResponse = {
    keywords: [],
    method: undefined,
    image: '',
    uuid: '',
    toleranceThreshold: 0,
  };

  return Promise
    .all([
      autoSelection(),
      Promise.delay(minTimeLoading),
    ])
    .then(() => {
      return autoSelectResult;
    })
    .catch(handleError);

  function autoSelection() {
    return KeywordExtraction.extractKeywords(text, language, MAX_KEYWORDS_TO_EXTRACT)
      .tap(checkActionStopped)
      .then(({ results, extractionMethodType, uuid, threshold }: ExtractionResponse) => {
        if (!results || !results.length)
          throw new Error('No keyword found for the text entered.');

        autoSelectResult.keywords = results;
        autoSelectResult.method = extractionMethodType;
        autoSelectResult.uuid = uuid;
        autoSelectResult.toleranceThreshold = threshold;
      })
      .tap(checkActionStopped)

      // Search image with keywords return by keywordExtractionService
      .then(() => {
        const { keywords } = autoSelectResult;
        const handler = new Google();
        const params = { Google: { imgType: 'photo', imgSize: 'xlarge' }};
        const terms = keywords.map((item: KeywordItem) => item.keyword).join(', ');

        return handler.fetchImages({ keyword: terms, page: 1, params });
      })
      .tap(checkActionStopped)
      .then((results: Array<ImageBody>) => {
        if (!results || !results.length)
          throw new Error('No images found for the keywords extracted.');

        const imageIndex = Math.min(imageIndexToRetrieve, results.length);

        return results[imageIndex];
      })
      .tap(checkActionStopped)

      // Store image on S3 service
      .then(({ urls: { big }, size: { height, width }}: ImageBody) => {
        const tab = big.split('/');
        const filename = tab[tab.length - 1];
        const args = { url: big, filename, height, width };

        return Image.createFromMediaLib(args);
      })
      .tap(checkActionStopped)
      .then((imageUploaded: Object) => {
        autoSelectResult.image = imageUploaded;
      })
      .catch((err) => Promise.reject(err));
  }

  /**
  * Call the callback to check if the user stopped the auto selection process
  */
  function checkActionStopped() {
    if (isStopped())
      throw new InterruptionError('Action was interrupted by client');
  }

  /**
  * Silent user interruption errors and throw standard errors
  */
  function handleError(error: Error) {
    if (error instanceof InterruptionError)
      return { error };

    console.debug(error);

    throw new Error('No images found for the text entered.');
  }
};
