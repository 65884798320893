/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
// @flow

import React, { useEffect, useMemo, useCallback } from 'react';
import { t } from 'i18next';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/flowTypes';
import Enum from 'Models/Enum';

import type { CategoryType } from 'ModelsReact/Category/Category';
import Category from 'Models/Category';

import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';

import { DocumentListAlertView } from './DocumentListAlertView/DocumentListAlertView';

import { DocumentListDropZone } from './components/DocumentListDropZone/DocumentListDropZone';
import { DocumentListItems } from './components/DocumentListItems/DocumentListItems';
import { DocumentModal } from './components/DocumentModal/DocumentModal';
import { NotificationModal } from './components/NotificationModal/NotificationModal';

import { actions } from './redux';
import styles from './DocumentList.style.js';

const Page = () => {
  const dispatch = useTypedDispatch();
  const loading = useTypedSelector(state => state.pages.documentList.loading);
  const list = useTypedSelector(state => state.pages.documentList);
  const counter = useTypedSelector(state => state.pages.documentList.counter);
  const textValues = useTypedSelector(state => state.pages.documentList.selectedTextValues);
  const segmentationIds = useTypedSelector(state => state.pages.documentList.selectedSegmentationIds);
  const categoryId = useTypedSelector(state => state.pages.documentList.selectedCategoryId);
  const documentStatusId = useTypedSelector(state => state.pages.documentList.selectedDocumentStatusId);
  const documentModalVisible = useTypedSelector(state => state.pages.documentList.documentModalVisible);
  const documentModal = useTypedSelector(state => state.pages.documentList.documentModal);
  const documentFile = useTypedSelector(state => state.pages.documentList.documentFile);
  const updated = useTypedSelector(state => state.pages.documentList.updated);
  const notificationModalVisible = useTypedSelector(state => state.pages.documentList.notificationModalVisible);

  const filters: Array<FilterState> = useMemo(() => {
    return [
      { category: 'text', values: textValues.concat() },
      { category: 'segment', ids: segmentationIds.concat() },
      { category: 'category', ids: [categoryId]},
      { category: 'documentStatus', ids: [documentStatusId]},
    ];
  }, [segmentationIds, textValues, categoryId, documentStatusId]);

  const handleFilterChange = useCallback((selectedFilters: Array<FilterState>) => {
    dispatch(actions.updateFilters(selectedFilters));
  }, [dispatch]);

  const handleDocumentModalVisibility = useCallback((visibility: boolean) => {
    dispatch(actions.setDocumentModalVisibility(visibility));
  }, [dispatch]);

  const handleNotificationModalVisibility = useCallback((visibility: boolean) => {
    dispatch(actions.setNotificationModalVisibility(visibility));
  }, [dispatch]);

  const handleDocumentModalSave = useCallback(() => {
    if (!documentModal)
      return;

    if (documentModal.id !== 0)
      dispatch(actions.update(documentModal));
    else
      dispatch(actions.add(documentModal, documentFile));

  }, [dispatch, documentModal, documentFile]);

  const handleNotificationModalSend = useCallback(() => {
    if (documentModal && documentModal.id)
      dispatch(actions.sendNotification(documentModal.id));

  }, [dispatch, documentModal]);

  useEffect(() => {
    const firstAction = actions.fetch();
    const secondAction = actions.getViews();
    
    dispatch(firstAction);
    dispatch(secondAction);

    return () => {
      firstAction.cancel;
      secondAction.cancel;
    }
  }, [
    dispatch,
    segmentationIds,
    textValues,
    categoryId,
    documentStatusId,
  ]);

  useEffect(() => {
    async function loadCategories() {
      const categories: Array<CategoryType> = (await Category.findForFilter());
      const action = actions.loadCategories(categories);

      dispatch(action);
    }
    loadCategories();
  }, [dispatch]);

  if (loading) {
    return (
      <SPageLoaderWithTimer
        isLoading={loading}
      />
    );
  }

  return (
    <div style={styles.documentWrapper}>
      <NotificationModal
        visible={notificationModalVisible}
        onClose={handleNotificationModalVisibility.bind(null, false)}
        onSendNotification={handleNotificationModalSend}
      />

      <DocumentModal
        visible={documentModalVisible}
        onClose={handleDocumentModalVisibility.bind(null, false)}
        onSave={handleDocumentModalSave}
      />

      <div style={styles.filter}>
        <SFilterBox
          key={updated}
          style={styles.filterWrapper}
          title={t('documents:page.title')}
          description={t('documents:page.description')}
          placeholder={t('documents:page.placeholder')}
          filters={filters}
          count={counter}
          onChange={handleFilterChange}
        />
      </div>

      <div style={styles.documentListWrapper}>
        <DocumentDropZone />
        <DocumentListItems />
      </div>
    </div>
  );
};

const DocumentDropZone = () => {
  const documentStatusId = useTypedSelector(state => state.pages.documentList.selectedDocumentStatusId);

  if (documentStatusId === Enum.DocumentStatus.ARCHIVED)
    return null;

  return (
    <DocumentListDropZone />
  );
};

export const DocumentList = () => {
  return (
    <div style={styles.wrapper}>
      <DocumentListAlertView component={Page} />
    </div>
  );
};
