import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  tab: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    paddingBottom: 6,
    cursor: 'pointer',
  },
  tabCompact: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    paddingBottom: 4,
  },
  selectedTab: {
    color: COLORS.TEXT.DEFAULT,
    borderBottom: `2px solid ${COLORS.BLACK.DEFAULT}`,
  },
});

export default styleSheet;
