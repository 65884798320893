import React from 'react';

import styles from 'Components/structural/STable/STable.style';

type EmptyNoticeProps = {
  isEmpty: boolean;
  forceLoading: boolean;
  notice: string;
};

function EmptyNotice({ isEmpty, forceLoading, notice }: EmptyNoticeProps) {
  if (!isEmpty || forceLoading) return null;

  return <div style={styles.emptyNotice}>{notice}</div>;
}

export default EmptyNotice;
