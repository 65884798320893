import React, { useMemo, useCallback, useState, CSSProperties } from 'react';
import { withSize } from 'react-sizeme';
import type { Crop } from 'react-image-crop';

import UCropTool from 'Components/unit/UCropTool/UCropTool';
import SCropPreview from 'Components/structural/SCropPreview/SCropPreview';

import styles from './SCropToolPanel.style';

const PANEL_PREVIEW_BORDER_WIDTH = 1;
const PANEL_PREVIEW_WIDTH = 252;

const SIZE_TO_TAKE_OFF_IF_PREVIEW = PANEL_PREVIEW_BORDER_WIDTH + PANEL_PREVIEW_WIDTH;

interface Device {
  label: string;
  ratio: number;
}

interface Size {
  width: number;
  height: number;
}

export interface SCropToolPanelProps {
  image: string;
  loadedOptimizedImage: HTMLImageElement;
  noticePreview: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCropEnd: Function;
  highDefinitionSize: Size;
  size: Size;
  titlePreview: string;
  deviceList?: Array<Device>;
  isPreview?: boolean;
  style?: CSSProperties;
}

interface State {
  previewImage: string;
}

/**
 * Display and handle logic between UCropTool and SCropPreview
 *
 * Props:
 *  - deviceList: Array of devices (label and ratio)
 *  - isPreview: Display the preview
 *  - image: The image to crop
 *  - highDefinitionSize: width and height of the original high quality image
 *  - loadedOptimizedImage: loaded cdn image
 *  - size: Given by the withSize HOC
 *  - style: Override component's style
 *  - titlePreview: Title of the preview container
 *  - noticePreview: Notice of the preview container
 *  - onCropEnd: Call when the image is crop
 */
export const SCropToolPanel = ({
  image,
  loadedOptimizedImage,
  noticePreview,
  onCropEnd,
  highDefinitionSize,
  size,
  titlePreview,
  isPreview = true,
  deviceList = [],
  style = {},
}: SCropToolPanelProps) => {
  /**
   * Dynamic attrs
   */
  const widthMax = useMemo(
    () => (isPreview ? size.width - SIZE_TO_TAKE_OFF_IF_PREVIEW : size.width),
    [isPreview, size],
  );
  /**
   * Store
   */
  const [state, setState] = useState<State>({ previewImage: '' });
  /**
   * Callbacks
   */
  const handleCrop = useCallback(
    (crop: Crop, img: string) => {
      onCropEnd(crop);
      setState({ previewImage: img });
    },
    [onCropEnd],
  );
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div style={styles.leftPanel}>
        <UCropTool
          image={image}
          highDefinitionSize={highDefinitionSize}
          loadedOptimizedImage={loadedOptimizedImage}
          onCropEnd={handleCrop}
          widthMax={widthMax}
          heightMax={size.height}
        />
      </div>
      {isPreview && (
        <div style={styles.rightPanel}>
          <div style={styles.previewText}>
            <h3 style={styles.title}>{titlePreview}</h3>
            <p style={styles.subText}>{noticePreview}</p>
          </div>
          <div style={styles.cropPreviewPanel}>
            {deviceList.map((el: Device) => (
              <SCropPreview
                key={`${el.label}-${el.ratio}`}
                picturePath={state.previewImage}
                ratio={el.ratio}
                title={el.label}
                style={styles.cropPreview}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default withSize({ monitorHeight: true })(SCropToolPanel);
