import { mergeDeep } from 'Libs/mergeDeep';

import createContent from './Content';
import type { ContentType } from './Content';

export type DailySerieContentType = Readonly<{
  key: number;
  content: ContentType;
  contentId: number;
}>;

export default function createDailySerieContent(values?: any): DailySerieContentType {
  const defaultState: DailySerieContentType = {
    key: 0,
    content: createContent(),
    contentId: 0,
  };

  if (!values) {
    return defaultState;
  }

  const content = createContent(values.content);

  return mergeDeep(defaultState, { ...values, content });
}
