import { GAME_TYPE, LanguageCode, MultipleChoiceQuestion } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { DeepPartial } from 'ts-essentials';
import { useFormContext } from 'react-hook-form';

import Enum from 'Models/Enum';

import { translateApiErrors } from 'Services/api-error/api-error.service';
import { ContentValidationError } from 'Store/entities/content/content.types';

import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';
import ULabel from 'Components/unit/ULabel/ULabel';
import { VIDEO_PROVIDERS } from 'Components/modal/MVideoUpload/utils';
import SGameplayListItemsSection, {
  SGameplayListItemProps,
} from 'Components/structural/SGameplayListItemsSection/SGameplayListItemsSection';
import STextArea from 'Components/structural/STextArea/STextArea';
import UMediaPicker, { ShowableVideo, isShowableVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import { UFieldError } from 'Components/unit/UFieldError/UFieldError';

import { EXPLANATION_CHAR_LIMIT, QUESTION_CHAR_LIMIT } from './gameplay.constants';
import generalStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';
import { BaseContentFormValues } from '../ContentEdition/ContentEdition';

type MCQFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

export const MCQForm = ({ language, isGameplayAlreadyChosen, editGameplay }: MCQFormProps) => {
  const { watch, setValue, formState } = useFormContext<
    BaseContentFormValues & { game: DeepPartial<MultipleChoiceQuestion> }
  >();

  const { root: errors } = formState.errors as unknown as { root: ContentValidationError };

  const onChoiceChange = (items: SGameplayListItemProps[]) => {
    setValue(
      'game.choices',
      items.map((item) => ({
        text: {
          [language]: item.value,
        },
        correct: item.selected || false,
      })),
    );
  };

  const onNewGameMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.gameMedia.video', newMedia.media);
      }
      setValue('game.gameMedia.image', newMedia.media);
    },
    [setValue],
  );

  const onNewExplanationMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.explanationMedia.video', newMedia.media);
      }
      return setValue('game.explanationMedia.image', newMedia.media);
    },
    [setValue],
  );

  return (
    <div style={generalStyles.wrapper}>
      <div style={generalStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.MCQ}
          title={t('gameplays:mcq.label')}
          description={t('gameplays:mcq.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:question')}</ULabel>
          <div style={{ ...generalStyles.imageTextSection, ...generalStyles.sectionSeparation }}>
            <div>
              <UMediaPicker
                dropArea
                size="L"
                image={watch('game.gameMedia.image.url') || ''}
                video={
                  isShowableVideo(watch('game.gameMedia.video'))
                    ? (watch('game.gameMedia.video') as ShowableVideo)
                    : undefined
                }
                media={{
                  mediaType: 'imageOrVideo',
                  onChange: onNewGameMedia,
                  providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
                }}
                onClearMedia={() => {
                  setValue('game.gameMedia', {});
                }}
              />
              <UFieldError errorMessage={translateApiErrors(errors?.game?.gameMedia)} />
            </div>
            <STextArea
              canResize={false}
              placeholder={t('gameplays:mcq.question_placeholder')}
              height="80"
              onChange={(text) => {
                setValue(`game.question.${language}`, text);
              }}
              style={generalStyles.imageTextArea}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              value={watch(`game.question.${language}`) || ''}
              maxLength={QUESTION_CHAR_LIMIT}
              errorMessage={translateApiErrors(errors?.game?.question)}
            />
          </div>
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:mcq.choices')}</ULabel>
          <ULabel style={generalStyles.subLabel}>{t('gameplays:mcq.choices_sublabel')}</ULabel>

          <SGameplayListItemsSection
            variant="check"
            type="standard"
            items={
              watch('game.choices')
                ? watch('game.choices')!.map((choice) => ({
                    value: choice?.text?.[language] || '',
                    selected: choice?.correct,
                  }))
                : []
            }
            onItemsChange={onChoiceChange}
            style={generalStyles.sectionSeparation}
            textLinkText={t('gameplays:mcq.add_choice')}
            textLinkTooltipText={t('gameplays:mcq.too_many_choices')}
          />
          <UFieldError errorMessage={translateApiErrors({ ...errors?.game?.choices, ...errors?.gameValidation })} />
        </div>
        <div style={generalStyles.section}>
          <ULabel style={generalStyles.label}>{t('gameplays:explanation')}</ULabel>
          <div style={generalStyles.imageTextSection}>
            <div>
              <UMediaPicker
                dropArea
                size="L"
                image={watch('game.explanationMedia.image.url') || ''}
                video={
                  isShowableVideo(watch('game.explanationMedia.video'))
                    ? (watch('game.explanationMedia.video') as ShowableVideo)
                    : undefined
                }
                media={{
                  mediaType: 'imageOrVideo',
                  onChange: onNewExplanationMedia,
                  providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
                }}
                onClearMedia={() => {
                  setValue('game.explanationMedia', {});
                }}
              />

              <UFieldError errorMessage={translateApiErrors(errors?.game?.explanationMedia)} />
            </div>
            <STextArea
              canResize={false}
              placeholder={t('gameplays:mcq.explanation_placeholder')}
              height="130"
              onChange={(text) => {
                setValue(`game.explanation.${language}`, text);
              }}
              style={generalStyles.imageTextArea}
              value={watch(`game.explanation.${language}`) || ''}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              maxLength={EXPLANATION_CHAR_LIMIT}
              errorMessage={translateApiErrors(errors?.game?.explanation)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
