import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  errorMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DEFAULT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    gap: 6,
  },
});

export default styles;
