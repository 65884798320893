/* eslint-disable complexity */
import { t } from 'i18next';

import request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';
import Enum from 'models/Enum';

var Knowledge = {
  endpoint  : 'api/Knowledge/full',
  attributes: {
    title: {
      type: 'string',
    },
    knowledgeTitleId: {
      type: 'number',
    },
    knowledgeTitle: {
      type : 'pointer',
      Model: 'GameItem',
    },
    cover: {
      type : 'pointer',
      Model: 'Image',
    },
    cover_id: {
      type: 'number',
    },
    originId: {
      type: 'number',
    },
    customId: {
      type      : 'string',
      defaultsTo: null,
    },
    locale: {
      type      : 'string',
      defaultsTo: null,
    },
    knowMoreId: {
      type: 'number',
    },
    knowMore: {
      type : 'pointer',
      Model: 'GameItem',
    },
    origin: {
      type : 'pointer',
      Model: 'Knowledge',
    },
    pendingTranslation: {
      type: 'boolean',
    },
    archived: {
      type      : 'boolean',
      defaultsTo: false,
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    explanationText: {
      type: 'string',
    },
    explanationId: {
      type: 'number',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    referenceText: {
      type: 'string',
    },
    leadIn: {
      type: 'string',
    },
    thematicId: {
      type: 'number',
    },
    thematic: {
      type : 'pointer',
      Model: 'Thematic',
    },
    statusId: {
      type: 'number',
    },
    status: {
      type : 'pointer',
      Model: 'KnowledgeStatus',
    },
    contents: {
      type : 'collection',
      Model: 'Content',
    },
    gameItems: {
      type : 'collection',
      Model: 'GameItem',
    },
    contributions: {
      type : 'collection',
      Model: 'Contribution',
    },
    images: {
      type : 'collection',
      Model: 'Image',
    },
    videos: {
      type : 'collection',
      Model: 'Video',
    },
    updatedAt: {
      type: 'date',
    },
  },
  methods: {
    getRoute: function getRoute() {
      var self = this;

      const translationPath = '/translation';

      return '/knowledge/' + self.id() + (self.pendingTranslation() ? translationPath : '');
    },
    linkImage: function linkImage(_Knowledge, id, options) {
      var path = '/api/knowledge/' + this.id() + '/images/' + id + '/add';

      return request.put(path, options || {})
        .then(function instanciate(image) {
          var Image = Model('Image');

          return new Image(image);
        });
    },
    refreshImages: function refreshImages() {
      return request.get('/api/Knowledge/' + this.id() + '/images');
    },
    cover: function _cover() {
      var Image = Model('Image');
      var coverId = this.cover_id();
      var cover = this.images().get(coverId);

      if (!cover && this.images().length)
        cover = this.images().at(0);

      if (!cover)
        cover = new Image();

      return cover;
    },
    originalImages: function originalImages() {
      return this.images().filter(function filterOutCropped(image) {
        return !image.typeId() || image.typeId() === 1;
      });
    },
    croppedImages: function croppedImages() {
      return this.images().filter(function filterCropped(image) {
        return image.typeId() === 2;
      });
    },
    askTranslation: function askTranslation(_Knowledge, languages) {

      var languageIds = languages.map(l => l.id());

      return request.post('/api/knowledge/askTranslation', {
        knowledgeId: this.id(),
        languages  : languageIds,
      })
        .then(function transformResponse(response) {
          return new Collection('Knowledge', Knowledge, response);
        });
    },
    validateTranslation: function askTranslation(_Knowledge) {
      return request.post('/api/knowledge/validateTranslation', {
        knowledgeId: this.id(),
      })
        .then(function transformResponse(response) {
          return new _Knowledge(response);
        });
    },
    getTargetLanguage: function getTargetLanguage() {
      var language = this.segmentation().first({
        dimensionId: 1,
      });

      return language ? language.label() : '';
    },
    getOriginLanguage: function getOriginLanguage() {
      var language = this.origin().segmentation().first({
        dimensionId: 1,
      });

      return language ? language.label() : '';
    },
    getItemsInDimension: function getItemsInDimension(_Knowledge, dimId) {
      return this.segmentation().find({
        dimensionId: dimId,
      });
    },
    getDistinctGameItemByTypeId: function getGameItemPreset(_Knowledge, typeId) {
      var items = this.gameItems().find({
        typeId: typeId,
      });

      return getDistincts(items).map(function returnData(o) {
        return o.data();
      });

      function getDistincts(gameItems) {
        var uniques = [];

        gameItems.forEach(function addIfUnique(gameItem) {
          if (!isAlreadyAdded(gameItem) && gameItem.data())
            uniques.push(gameItem);

          function isAlreadyAdded(gi) {
            return uniques.filter((_gi) => _gi.data() === gi.data()).length;
          }
        });

        return uniques;
      }
    },
    setLanguage: function setLanguage(_Knowledge, value) {
      this.segmentation().searchAndRemove({
        dimensionId: Enum.dimensionTypes.LANGUAGE,
      });
      this.segmentation().items.push(value);
    },
    isTitleValid: function isTitleValid() {
      return this.knowledgeTitle().data().length > 0;
    },
    isThematicValid: function isThematicValid() {
      return this.thematicId() !== 0;
    },
    isLanguageValid: function isLanguageValid() {
      return this.segmentation().find({
        dimensionId: Enum.dimensionTypes.LANGUAGE,
      }).length > 0;
    },
    validate: function validate() {
      var error;

      if (!this.isTitleValid())
        error = new Error(t('contents:content_details.errors.title'));
      else if (!this.isThematicValid())
        error = new Error(t('contents:content_details.errors.thematic'));
      else if (!this.isLanguageValid())
        error = new Error(t('contents:content_details.errors.language'));

      return error;
    },
    getContent: function getContent(_Knowledge, gameplayId) {
      var content = this.contents().first({
        gameplayId: gameplayId,
      });

      return content ? content.game() : null;
    },
  },
  classMethods: {
    filter: function filter(_Knowledge, inputFilter, start, length) {
      var payload = {};
      var path = '/api/Knowledge/full/paginated';

      payload.start = start;
      payload.length = length;

      if (inputFilter)
        payload.filter = inputFilter;

      return request.get(path, payload)
        .then(function turnToModelList(raw) {
          return {
            count     : raw.count,
            knowledges: new Collection('Knowledge', Knowledge, raw.result),
            more      : length === raw.result.length,
          };
        });
    },
    count: function count(_Knowledge, inputFilter) {
      var path = '/api/Knowledge/count/filtered';
      var payload = {};

      if (inputFilter)
        payload.filter = inputFilter;

      return request
        .get(path, payload)
        .then(function returnResult(result) {
          return result;
        });
    },
    countBy: function countBy(_Knowledge, filterByInput) {
      var filter = _Knowledge.buildFilter(filterByInput);

      return _Knowledge.count(filter);
    },
    buildFilter: function buildFilter(_Knowledge, filterByInput) {
      var filter = {
        where: {
          and: [],
        },
      };

      var text = filterByInput.text;
      var segmentation = filterByInput.segmentation;
      var contributor = filterByInput.contributor;
      var translation = filterByInput.translation;
      var thematicId = filterByInput.thematic;
      var statusId = filterByInput.status;
      var fromDate = filterByInput.fromDate;
      var toDate = filterByInput.toDate;
      var activity = filterByInput.activity;

      if (text)
        filter.text = text;

      if (thematicId) {
        filter.where.and.push({
          thematicId: thematicId,
        });
      }

      if (statusId === Enum.contentStatus.ARCHIVED) {
        filter.where.and.push({
          archived: true,
        });
      } else {
        filter.where.and.push({
          or: [{
            archived: false,
          },
          {
            archived: null,
          },
          ],
        });
        if (statusId !== null)
          filter.status = statusId;
      }

      if (segmentation && segmentation.length)
        filter.segmentation = segmentation.map((id) => ({ id }));

      if (contributor && contributor.length > 0)
        filter.contributor = { ids: contributor };

      if (translation && translation.statusId !== null) {
        filter.translation = {
          statusId: (translation.statusId >= 0) ? translation.statusId : undefined,
        };
      }

      if (fromDate) {
        filter.where.and.push({
          createdAt: {
            gte: fromDate,
          },
        });
      }

      if (toDate) {
        filter.where.and.push({
          createdAt: {
            lte: toDate,
          },
        });
      }

      if (activity && activity.length)
        filter.activity = activity.map((id) => ({ id }));

      return filter;
    },

    filterBy: function filterBy(_Knowledge, filterByInput, start, length) {
      var filter = _Knowledge.buildFilter(filterByInput);

      return _Knowledge.filter(filter, start, length);
    },

    itemAfter: function itemAfter(_Knowledge, knowledge, knowledges) {

      var index = knowledges.map(function getNext(item, i) {
        if (item.id() === knowledge.id())
          return i;

        return 0;
      }).reduce(function add(total, number) {
        return total + number;
      }, 0);

      if (index + 1 < knowledges.length)
        return knowledges.at(index + 1).id();

      return null;
    },

    itemBefore: function itemBefore(_Knowledge, knowledge, knowledges) {

      var index = knowledges.map(function getPrev(item, i) {
        if (item.id() === knowledge.id())
          return i;

        return 0;
      }).reduce(function add(total, number) {
        return total + number;
      }, 0);

      if (index - 1 >= 0)
        return knowledges.at(index - 1).id();

      return null;
    },

    getKnowledgeImages: function getKnowledgeImages(_Knowledge, thematic, start, length) {
      var path = '/api/Knowledge/KnowledgeImages';
      var payload = {
        start : start || 0,
        length: length || 0,
      };

      if (thematic)
        payload.thematic = thematic;

      return request
        .get(path, payload)
        .then(function turnToModelList(result) {
          result.images = new Collection(Model('Image'), result.images);

          return result;
        });
    },
  },
};

export default Model.register('Knowledge', Knowledge);
