import { useMemo, useCallback, useState } from 'react';
import { store } from 'Libs/redux/store';
import { BaseCourse, LastEditor } from '../redux';
import { getCourseStatus } from '../utils/course.utils';
import { getPublishHandler, PublishHandlerConfig } from '../utils/edition-handlers';

export type PublicationModalState = {
  isOpened: boolean;
  isPublished: boolean;
};

export const usePublishCourseWithModal = (
  context: PublishHandlerConfig['context'],
  course?: BaseCourse,
  lastEditor?: LastEditor,
) => {
  const [publishModalState, openPublishDialog] = useState<PublicationModalState>({
    isOpened: false,
    isPublished: false,
  });

  const makePublishHandler = useCallback(
    (published: boolean) => {
      if (!course?.id) {
        return () => Promise.resolve();
      }

      return getPublishHandler(store, {
        context,
        courseId: course.id,
        published,
      });
    },
    [course, context],
  );

  const publishDialogCourseData = useMemo(() => {
    if (!course || !lastEditor) return null;

    return {
      id: course.id,
      language: course.language,
      title: course.title,
      image: course.coverImageUrl,
      status: getCourseStatus(course.statuses),
      lastEditDate: new Date(lastEditor.editionDate || 0),
      lastEditUsername: `${lastEditor.firstName} ${lastEditor.lastName}`,
    };
  }, [course, lastEditor]);

  const handlePublish = useMemo(() => makePublishHandler(true), [makePublishHandler]);
  const handlePublishButton = useCallback(() => {
    openPublishDialog({ isOpened: true, isPublished: true });
    return Promise.resolve();
  }, [openPublishDialog]);
  const closePublishModal = useCallback(
    () => openPublishDialog({ isOpened: false, isPublished: true }),
    [openPublishDialog],
  );
  const handleUnpublish = useMemo(() => makePublishHandler(false), [makePublishHandler]);
  const handleUnpublishButton = useCallback(() => {
    openPublishDialog({ isOpened: true, isPublished: false });
    return Promise.resolve();
  }, [openPublishDialog]);

  return {
    publishModalState,
    publishDialogCourseData,
    handlePublish,
    handlePublishButton,
    closePublishModal,
    handleUnpublish,
    handleUnpublishButton,
  };
};
