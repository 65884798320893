export type ThematicPolyglotTranslatedLabel = Readonly<{
  id: number,
  label: string,
  languageId: number,
  polyglotLabelId: number,
}>;

export type ThematicPolyglotLabel = Readonly<{
  id: number,
  label: string | null,
  translatedLabels: ReadonlyArray<ThematicPolyglotTranslatedLabel>,
}>;

export type Thematic = Readonly<{
  ancestorId: number,
  archived: boolean,
  icon: string,
  id: number,
  level: number,
  name: string,
  polyglotLabel: ThematicPolyglotLabel,
}>;

const defaultThematicPolyglotLabel = {
  id: 0,
  label: '',
  translatedLabels: [],
};

const defaultThematic = {
  ancestorId: 0,
  archived: false,
  icon: '',
  id: 0,
  level: 0,
  name: '',
  polyglotLabel: defaultThematicPolyglotLabel,
};

export const createThematicPolyglotLabel = (values?: any): ThematicPolyglotLabel => {
  if (!values || !Object.keys(values).length) {
    return defaultThematicPolyglotLabel;
  }

  return {
    id: values.id,
    label: values.label,
    translatedLabels:
      values.translatedLabels.map((label: ThematicPolyglotTranslatedLabel) => ({
        id: label.id,
        label: label.label,
        languageId: label.languageId,
        polyglotLabelId: label.polyglotLabelId,
      })) || [],
  };
};

export const createThematicItem = (values?: any): Thematic => {
  if (!values || !Object.keys(values).length) {
    return defaultThematic;
  }

  return {
    ancestorId: values.ancestorId,
    archived: values.archived,
    icon: values.icon,
    id: values.id,
    level: values.level,
    name: values.name,
    polyglotLabel: createThematicPolyglotLabel(values.polyglotLabel),
  };
};
