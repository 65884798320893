import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  outerContainer: {
    padding: '8px',
    backgroundColor: COLORS.WHITE.DEFAULT,

    border: 'solid 1px',
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderRadius: '12px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    height: '43px',
    padding: '8px 24px',
    backgroundColor: COLORS.WHITE.DEFAULT,

    borderRadius: '8px',
  },
  innerContainerWithContent: {
    backgroundColor: COLORS.PRIMARY_LIGHT.DEFAULT,
  },
  activeInnerContainer: {
    backgroundColor: COLORS.SUCCESS.LIGHTER,
  },
});

export default styleSheet;
