import React from 'react';
import type { CSSProperties } from 'react';
import { GameType } from '@sparted/shared-library/business/types';

import UIconTab from 'Components/unit/UIconTab/UIconTab';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';
import { TooltipPosition } from 'Components/structural/STooltip/STooltip';

import styles from './SGameplayHeader.style';

const UIconButtonWithTooltip = withTooltip(UIconButton);

export type SGameplayHeaderProps = {
  style?: CSSProperties;
  iconType: GameType;
  title: string;
  description: string;
  isEditDisabled?: boolean;
  tooltip?: {
    tooltipContent: string;
    tooltipPosition: TooltipPosition;
    tooltipMaxWidth?: number;
  };
  onEditClick: () => void;
};

const noop = () => {};

export const SGameplayHeader = ({
  style = {},
  iconType,
  title,
  tooltip = {
    tooltipContent: '',
    tooltipPosition: 'top',
    tooltipMaxWidth: 200,
  },
  description,
  isEditDisabled,
  onEditClick,
}: SGameplayHeaderProps) => {
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div style={styles.iconTabSection}>
        <UIconTab locked={true} iconType={iconType} onClick={noop} selected={true} />
      </div>
      <div style={styles.column}>
        <div style={styles.row}>
          <p style={styles.title}>{title}</p>
          <div style={styles.iconButtonWrapper}>
            <UIconButtonWithTooltip
              ghost
              size="S"
              icon="edit-sml"
              onClick={onEditClick}
              disabled={isEditDisabled}
              tooltipPosition={tooltip.tooltipPosition}
              tooltipContent={tooltip.tooltipContent}
              tooltipEnabled={isEditDisabled && tooltip}
              tooltipMaxWidth={tooltip.tooltipMaxWidth}
            />
          </div>
        </div>
        <div style={styles.row}>
          <p style={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SGameplayHeader;
