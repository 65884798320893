// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display a checkbock with a text
 *
 * Props:
 *  - style: For overriding the component's style
 *  - onClick: Function called when we click on the checkbox
 *  - content: String displayed beside the checkbox
 *  - checkBoxStatus: Status of the checkbox
 */

import * as React from 'react';

import UCheckbox from 'Components/unit/UCheckbox/UCheckbox';

import styles from './SCheckboxListItem.style';


type Props = {|
  style?: Object,
  onClick: (statusType: string) => void,
  content: string,
  checkBoxStatus: string,
|};

export class SCheckboxListItem extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
    checkBoxStatus: 'unchecked',
  };

  render() {
    const { style, content, checkBoxStatus, onClick } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <UCheckbox
          onClick={onClick}
          type='accentuated'
          checkBoxStatus={checkBoxStatus}
          style={styles.checkbox}
        />
        <span style={styles.content}>
          {content}
        </span>
      </div>
    );
  }
}

export default SCheckboxListItem;
