/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Displays a SMediaUpload component
 *
 *  - imageUrl: url used to display the image
 *  - onEditClick: callback to edit apply on the icon button
 *  - onUploadClick: callback apply on the button
 *  - uploadButtonText: text to display in the button
 *  - placeholderContent: text to display in the placeholder
 *  - placeholderSubtext: subtext to display in the placeholder
 *  - style: Used to override the style
 */


import * as React from 'react';

import styles from './SMediaUpload.style';

import UIcon from 'Components/unit/UIcon/UIcon';
import UButton from 'Components/unit/UButton/UButton';
import UCircularButton from 'Components/unit/UCircularButton/UCircularButton';

import { COLORS } from 'Components/foundation';

type Props = {|

  // optional
  imageUrl: string,
  onEditClick: () => void,
  onUploadClick: ?() => void,
  uploadButtonText: string,
  style: Object,
  overlayContent: string,
  overlaySubtext: string,
|};

export class SMediaUpload extends React.PureComponent<Props> {
  static defaultProps = {
    onEditClick: () => {},
    onUploadClick: undefined,
    uploadButtonText: 'Add image',
    overlayContent: '',
    overlaySubtext: '',
    imageUrl: '',
    style: undefined,
  };

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        { this.renderContent() }
      </div>
    );
  }

  renderContent = () => {
    const { imageUrl, onEditClick, overlayContent } = this.props;

    if (!imageUrl)
      return this.renderPlaceholder();

    return (
      <div
        onClick={onEditClick}
        style={{
          ...styles.imageContainer,
          backgroundImage: `url('${imageUrl}')`,
        }}
      >
        {!overlayContent ? this.renderPicture() : this.renderOverlay()}
      </div>
    );
  };

  renderPicture = () => {
    return (
      <div style={styles.iconButtonWrapper}>
        <UCircularButton icon='edit' onClick={this.handleIconClick} />
      </div>
    );
  };

  renderOverlay = () => {
    const { overlaySubtext, overlayContent } = this.props;

    return (
      <>
        <div style={styles.overlay} />
        <span style={styles.overlayContent}>
          {overlayContent}
        </span>
        <span style={styles.overlaySubtext}>
          {overlaySubtext}
        </span>
        <div style={styles.iconButtonWrapper}>
          <UCircularButton icon='edit' onClick={this.handleIconClick} />
        </div>
      </>
    );
  };

  renderPlaceholder = () => {
    const { uploadButtonText } = this.props;

    return (
      <div style={styles.imagePlaceholder}>
        <div style={styles.iconPlaceholderWrapper}>
          <UIcon
            name='image'
            size={50}
            color={COLORS.GREY_MEDIUM.DEFAULT}
          />
        </div>
        <div style={styles.uploadButtonWrapper}>
          <UButton text={uploadButtonText} onClick={this.handleButtonClick} />
        </div>
      </div>
    );
  };

  handleButtonClick = () => {
    const { onUploadClick } = this.props;

    if (onUploadClick)
      onUploadClick();
  };

  handleIconClick = (event: MouseEvent) => {
    const { onEditClick } = this.props;

    event.stopPropagation();

    onEditClick();
  };
}

export default SMediaUpload;
