import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { redirect } from 'Libs/routing/redirect';

import SShortcutCard from 'Components/structural/SShortcutCard/SShortcutCard';

type KnowledgeBlockProps = {
  title: string;
  size: '100%' | '50%' | '33%';
  status?: 'disabled' | 'active' | 'locked';
  align?: 'left' | 'center';
  link?: string;
  style?: CSSProperties;
};

const noop = () => {};
const redirectToKnowledge = () => redirect('/knowledge/new?thematicId&languageSegmentationId');

const KnowledgeBlock = ({ title, style, size, link, status = 'active', align = 'center' }: KnowledgeBlockProps) => {
  const { t } = useTranslation();

  return (
    <SShortcutCard
      illustration="knowledge"
      title={title}
      body={t('home:start_here_block.knowledge_description')}
      link={link}
      onLearnMorePress={noop}
      onNavigationClick={redirectToKnowledge}
      size={size}
      status={status}
      align={align}
      style={style}
      trackingAction="create"
      trackingContext="knowledge-from-homepage"
    />
  );
};

export default KnowledgeBlock;
