// @flow

import React from 'react';

import styles from './Leaderboard.style';

import { LeaderboardAlertView } from './components/LeaderboardAlertView/LeaderboardAlertView';
import { LeaderboardView } from './components/LeaderboardView/LeaderboardView';

export const Leaderboard: React$ComponentType<{||}> = () => {

  return (
    <div style={styles.wrapper}>
      <LeaderboardAlertView component={LeaderboardView} />
    </div>
  );
};
