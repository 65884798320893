import { t } from 'i18next';

import { Filter, FilterCategory, GenericFilterCategoryAndText, isCategoryWithLabel } from './types';

export const makeMultiSelectLabel = (category: GenericFilterCategoryAndText) => {
  switch (category) {
    case 'campaign':
      return t('unit_components:u_filter_select.labels.multi_select.campaign');
    case 'user':
      return t('unit_components:u_filter_select.labels.multi_select.user');
    case 'segment':
      return t('unit_components:u_filter_select.labels.multi_select.segment');
    case 'thematic':
      return t('unit_components:u_filter_select.labels.multi_select.thematic');
    case 'activityType':
      return t('unit_components:u_filter_select.labels.multi_select.activity_type');
    case 'activityStatus':
      return t('unit_components:u_filter_select.labels.multi_select.activity_status');
    case 'contentStatus':
      return t('unit_components:u_filter_select.labels.multi_select.content_status');
    case 'translationStatus':
      return t('unit_components:u_filter_select.labels.multi_select.translation_status');
    case 'category':
      return t('unit_components:u_filter_select.labels.multi_select.category');
    case 'documentStatus':
      return t('unit_components:u_filter_select.labels.multi_select.document_status');
    case 'language':
      return t('unit_components:u_filter_select.labels.multi_select.language');
    case 'audienceGroup':
      return t('unit_components:u_filter_select.labels.multi_select.audience_group');
    case 'sort':
      return t('unit_components:u_filter_select.labels.multi_select.sort');
    default:
      return category;
  }
};

export const makeNoChoiceLabel = (category: GenericFilterCategoryAndText | string) => {
  switch (category) {
    case 'campaign':
      return t('unit_components:u_filter_select.labels.choice.campaign');
    case 'user':
      return t('unit_components:u_filter_select.labels.choice.user');
    case 'segment':
      return t('unit_components:u_filter_select.labels.choice.segment');
    case 'thematic':
      return t('unit_components:u_filter_select.labels.choice.thematic');
    case 'activityType':
      return t('unit_components:u_filter_select.labels.choice.activity_type');
    case 'activityStatus':
      return t('unit_components:u_filter_select.labels.choice.activity_status');
    case 'contentStatus':
      return t('unit_components:u_filter_select.labels.choice.content_status');
    case 'dolphinContentStatus':
      return t('unit_components:u_filter_select.labels.choice.content_status');
    case 'translationStatus':
      return t('unit_components:u_filter_select.labels.choice.translation_status');
    case 'category':
      return t('unit_components:u_filter_select.labels.choice.category');
    case 'documentStatus':
      return t('unit_components:u_filter_select.labels.choice.document_status');
    case 'audienceMode':
      return t('unit_components:u_filter_select.labels.choice.audience_mode');
    case 'sort':
      return t('unit_components:u_filter_select.labels.choice.sort');
    default:
      return category;
  }
};

export const FILTER_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  DATE: 'date',
};

export const getCategoryLabel = (filter: Filter, defaultName?: string | null): string => {
  const { type, category } = filter;

  if (isCategoryWithLabel(category)) {
    return category.label;
  }

  if (type === FILTER_TYPE.MULTISELECT) {
    return makeMultiSelectLabel(category);
  }

  return defaultName || makeNoChoiceLabel(category);
};

export const getCategoryValue = (category: FilterCategory): string => {
  if (isCategoryWithLabel(category)) {
    return category.value;
  }

  return category;
};
