export type OCAlertValidationField =
  | 'noEnoughCards'
  | 'noCardsContent'
  | 'noInstruction'
  | 'noTopLabel'
  | 'noBottomLabel';

export type OCAlertValidationParams = Readonly<{
  emptyItemNb?: number;
}>;

export const OC_FIELD_MESSAGES = Object.freeze({
  noInstruction: '- You must add an instruction.',
  noEnoughCards: '- You must have at least three items to put in order.',
  noCardsContent: '- Ordering items cannot be empty',
  noTopLabel: '- You must add a top label.',
  noBottomLabel: '- You must add a bottom label.',
});
