/* eslint-disable flowtype/require-compound-type-alias */
/* eslint-disable key-spacing */
// @flow

import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Enum from 'Models/Enum';
import LS from 'Services/localStorageService';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { actions } from 'Pages/Content/redux';

import MGameplayPreview from 'Components/modal/MGameplayPreview/MGameplayPreview';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import { GAMEPLAY_ID_TO_CONTENT_KEY } from 'Components/utils/Enum';

import { ContentAlertView } from './ContentAlertView/ContentAlertView';
import ContentActionBar from './ContentActionBar/ContentActionBar';
import { OrderingCards } from './OrderingCards/OrderingCards';

import styles from './Content.style.js';

type ContentProps = {|
  id: number,
  gameplayType: number,
  knowledgeId: number,
|};

const ID_URL_EMPLACEMENT = 4;

const COMPONENT_BY_TYPE: { [id: number]: React$AbstractComponent<any, any> } = {
  [Enum.gameplay.QCM]: null,
  [Enum.gameplay.SQCM]: null,
  [Enum.gameplay.ERROR_TEXT]: null,
  [Enum.gameplay.OPEN_QUESTION]: null,
  [Enum.gameplay.NO_INTERACTION_CONTENT]: null,
  [Enum.gameplay.PICTURE_SPOT]: null,
  [Enum.gameplay.SWIPING_CARDS]: null,
  [Enum.gameplay.ORDERING_CARDS]: OrderingCards,
};

function userHasOneRole(user, rolesToFind) {
  const roles = user.roles.map(item => item.id);

  return roles.some(role => rolesToFind.includes(role));
}

function getAccessFromUser(user) {
  const { WRITER, ADMIN, MASTER, ROOT } = Enum.Role;

  const canEdit = userHasOneRole(user, [WRITER, ADMIN, MASTER, ROOT]);
  const canEditValid = userHasOneRole(user, [ADMIN, MASTER, ROOT]);

  return { canEdit, canEditValid };
}

export const Content = ({ id, gameplayType, knowledgeId }: ContentProps) => {
  const {t} = useTranslation()

  const [currentUser, setCurrentUser] = useState({});
  const dispatch = useTypedDispatch();
  const contentId = useTypedSelector(state => state.pages.content.content.id);
  const statusId = useTypedSelector(state => state.pages.content.content.statusId);
  const loading = useTypedSelector(state => state.pages.content.loading);
  const showPreview = useTypedSelector(state => state.pages.content.showPreview);
  const knowledgeTitle = useTypedSelector(state => state.pages.content.knowledgeInfo.title);
  const knowledgeStateId = useTypedSelector(state => state.pages.content.knowledgeInfo.id);
  const knowledgePendingTranslation = useTypedSelector(state => state.pages.content.knowledgeInfo.pendingTranslation);
  const contentPreviewUrl = useTypedSelector(state => state.pages.content.content.previewUrl);

  // fetch content or initialize a new one
  useEffect(() => {

    // initialize a new one
    if (!id && !contentId) {
      dispatch(actions.setupNewContent(gameplayType));

      return () => {};
    }

    // already exist in state
    if (contentId)
      return () => {};

    // exist and not in state
    const action = actions.fetchById(id);

    dispatch(action);

    return action.cancel;
  }, [id, contentId, gameplayType, dispatch]);

  // fetch knowledge to get title and to link content to it
  useEffect(() => {
    if (!knowledgeId || knowledgeStateId)
      return () => {};

    const action = actions.fetchKnowledgeInfo(knowledgeId);

    dispatch(action);

    return action.cancel;
  }, [knowledgeId, knowledgeStateId, dispatch]);

  // fetch current user to setup access
  useEffect(() => {
    function updateAccess(user) {
      const { canEdit, canEditValid } = getAccessFromUser(user);
      const isContentValid = statusId === Enum.contentStatus.VALIDATED;
      const canAskValidation = getAskValidationRight(user);

      // set edition of content, typically save or edit inputs
      dispatch(actions.setCanEditRole(isContentValid ? canEditValid : canEdit));

      // set validation right level
      dispatch(actions.setCanEditValidRole(canEditValid));
      dispatch(actions.setCanAskValidationRole(canAskValidation));

      return () => {};
    }

    function getAskValidationRight(user: Object): boolean {
      const isUserTranslator = userHasOneRole(user, [Enum.Role.TRANSLATOR]);

      let cansAskValidation = true;

      // avoid to valid content in a non translated content
      if (isUserTranslator && !knowledgePendingTranslation)
        cansAskValidation = false;

      return cansAskValidation;
    }

    function fetchUser() {
      const user = LS.getSync('user');

      setCurrentUser(user);
    }

    if (currentUser.id)
      return updateAccess(currentUser);

    fetchUser();

    return () => {};
  }, [currentUser, statusId, setCurrentUser, knowledgePendingTranslation, dispatch]);

  // reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  // change url on creation
  useEffect(() => {
    if (!id && contentId !== 0) {
      const pathElems = window.location.pathname.split('/')
        .filter((_, i) => i <= ID_URL_EMPLACEMENT);

      pathElems[ID_URL_EMPLACEMENT] = contentId;
      window.history.replaceState({}, null, pathElems.join('/'));
    }
  }, [contentId, id]);

  const onCloseContentPreview = useCallback(() => dispatch(actions.setPreviewVisibility(false)), [dispatch]);
  const gameplayComponent = COMPONENT_BY_TYPE[gameplayType];

  if (!gameplayComponent) {
    const notImplemented = `The gameplay of id: ${gameplayType} is not implemented`;

    return (
      <div>
        { notImplemented }
      </div>
    );
  }

  if (loading) {
    return (
      <SPageLoaderWithTimer
        isLoading={loading}
        style={styles.pageLoader}
      />
    );
  }

  return (
    <div style={styles.wrapper}>
      <ContentActionBar />
      <ContentAlertView component={gameplayComponent} />
      <MGameplayPreview
        visible={showPreview}
        title={GAMEPLAY_ID_TO_CONTENT_KEY[gameplayType]}
        subtitle={knowledgeTitle}
        url={contentPreviewUrl}
        qrCodeLabel={t('gameplays:scan_preview')}
        onCloseModal={onCloseContentPreview}
      />
    </div>
  );
};
