import React, { FunctionComponent, ReactNode } from 'react';

import SLandingPage from 'Components/structural/SLandingPage/SLandingPage';
import { getCdnUrl } from 'Libs/cdn';
import Config from 'Services/instanceConfig';

import styles from './LandingPageWrapper.style';

const instanceLogo = getCdnUrl(Config.instanceId, '/logo.png');

type LandingPageWrapperProps = {
  children: ReactNode;
};

export const LandingPageWrapper: FunctionComponent<LandingPageWrapperProps> = ({ children }) => (
  <SLandingPage instanceLogo={instanceLogo}>
    <div style={styles.instanceName}>{Config.instanceId}</div>

    <div style={styles.title}>Double Authentication</div>

    {children}
  </SLandingPage>
);

export default LandingPageWrapper;
