import { createStyleSheet } from 'Components/foundation/stylesheets';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  iconTabSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    ...TYPOGRAPHY.HERO_L_BOLD,
  },
  description: {
    ...TYPOGRAPHY.H3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  iconButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 4,
  },
});

export default styleSheet;
