import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const CampaignIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.8556 9.79101C37.0775 9.38095 37.4133 9.04382 37.8224 8.82019C38.2316 8.59657 38.6967 8.49602 39.1617 8.53063L67.8148 13.9457C69.6821 14.1231 70.7464 16.4852 69.8128 18.3524L61.8302 32.1421C61.6006 32.6652 61.1938 33.0906 60.6815 33.3433C60.1692 33.596 59.5841 33.66 59.0293 33.5239L31.4872 28.389C31.0815 28.2334 30.7135 27.9935 30.4074 27.6851C30.1013 27.3768 29.8641 27.007 29.7115 26.6003C29.5588 26.1935 29.4942 25.759 29.5219 25.3254C29.5495 24.8918 29.6689 24.469 29.872 24.0849L36.8556 9.79101Z"
      fill={color.DEFAULT}
    />
    <path
      d="M30.4066 6.34628L5.59406 11.7155C3.60911 12.145 2.3482 14.1023 2.77772 16.0873L11.472 56.2659C11.9015 58.2508 13.8588 59.5118 15.8438 59.0822L40.6563 53.713C42.6413 53.2835 43.9022 51.3262 43.4726 49.3412L34.7784 9.16262C34.3489 7.17767 32.3915 5.91676 30.4066 6.34628Z"
      fill={color.DEFAULT}
      stroke={color.DEFAULT}
      strokeWidth="1.82992"
    />
    <path
      d="M44.9286 14.7219L39.1085 38.4709C38.7943 39.753 39.5789 41.047 40.861 41.3612L56.4579 45.1835C57.74 45.4977 59.034 44.7131 59.3482 43.431L65.1683 19.682C65.4825 18.3999 64.6979 17.1059 63.4158 16.7917L47.8189 12.9694C46.5368 12.6552 45.2428 13.4398 44.9286 14.7219Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      d="M45.2384 13.9625L39.4183 37.7115C39.1041 38.9936 39.8887 40.2877 41.1708 40.6019L56.7677 44.4242C58.0498 44.7384 59.3438 43.9537 59.658 42.6717L65.4781 18.9226C65.7923 17.6406 65.0077 16.3465 63.7257 16.0323L48.1287 12.21C46.8466 11.8958 45.5526 12.6805 45.2384 13.9625Z"
      stroke={color.DEFAULT}
      strokeWidth="2.07266"
    />
    <path
      d="M41.8731 9.2856L36.8105 31.5686C36.5372 32.7715 37.3592 33.9837 38.6464 34.2762L54.3058 37.8339C55.593 38.1264 56.858 37.3883 57.1313 36.1853L62.1939 13.9024C62.4672 12.6994 61.6452 11.4872 60.358 11.1948L44.6986 7.63703C43.4114 7.34458 42.1464 8.08267 41.8731 9.2856Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      d="M42.2625 9.43907L36.8452 33.2832C36.5527 34.5704 37.3592 35.851 38.6464 36.1435L54.3058 39.7012C55.593 39.9936 56.8735 39.1872 57.166 37.9L62.5832 14.0558C62.8757 12.7686 62.0693 11.4881 60.7821 11.1956L45.1227 7.63789C43.8355 7.34545 42.5549 8.15186 42.2625 9.43907Z"
      stroke={color.DEFAULT}
      strokeWidth="2.07266"
    />
    <path
      d="M37.7024 3.47105L32.4582 28.2707C32.0529 30.1877 33.2783 32.0704 35.1953 32.4758L51.4818 35.9197C53.3988 36.3251 55.2814 35.0997 55.6868 33.1827L60.931 8.38302C61.3364 6.46601 60.111 4.58334 58.194 4.17797L41.9075 0.733984C39.9905 0.32861 38.1078 1.55405 37.7024 3.47105Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path d="M39.7152 2.20248L37.793 11.2928L58.0807 15.5829L60.003 6.49257L39.7152 2.20248Z" fill={color.MEDIUM} />
    <path
      d="M49.5249 8.01712L50.2811 10.902L53.082 11.9384C53.1979 11.976 53.3006 12.0461 53.3777 12.1405C53.4549 12.2348 53.5032 12.3494 53.517 12.4705C53.5308 12.5916 53.5094 12.7141 53.4555 12.8233C53.4015 12.9326 53.3172 13.024 53.2127 13.0867L50.6733 14.6739L50.5705 17.6429C50.5644 17.7644 50.5244 17.8818 50.4552 17.9819C50.3859 18.0819 50.29 18.1606 50.1784 18.2091C50.0668 18.2577 49.9439 18.274 49.8235 18.2565C49.7031 18.2389 49.59 18.1881 49.4969 18.1097L47.1815 16.2424L44.2872 17.0453C44.1706 17.0792 44.0468 17.0799 43.9298 17.0474C43.8128 17.0149 43.7072 16.9505 43.6248 16.8613C43.5424 16.7721 43.4865 16.6617 43.4633 16.5425C43.4401 16.4233 43.4506 16.3 43.4936 16.1864L44.6047 13.3855L42.9241 10.9114C42.8538 10.8121 42.8135 10.6948 42.8079 10.5733C42.8024 10.4518 42.8318 10.3313 42.8928 10.2261C42.9538 10.1208 43.0437 10.0353 43.1519 9.97972C43.26 9.92411 43.3819 9.90074 43.5029 9.91239L46.5092 10.1085L48.3765 7.77439C48.4536 7.68133 48.5547 7.6111 48.6688 7.57133C48.783 7.53156 48.9058 7.52374 49.024 7.54874C49.1423 7.57373 49.2515 7.63058 49.3397 7.71314C49.428 7.79569 49.492 7.90081 49.5249 8.01712V8.01712Z"
      fill={color.DEFAULT}
    />
    <path
      d="M38.7164 3.68545L33.4722 28.4851C33.1853 29.8422 34.0527 31.1749 35.4098 31.4619L51.6963 34.9058C53.0533 35.1928 54.386 34.3253 54.673 32.9683L59.9172 8.16861C60.2042 6.81158 59.3367 5.47885 57.9797 5.19189L41.6932 1.74791C40.3361 1.46094 39.0034 2.32841 38.7164 3.68545Z"
      stroke={color.DEFAULT}
      strokeWidth="2.07266"
    />
    <path
      d="M32.1058 23.4169L24.1998 59.8297C23.7589 61.8604 25.0477 63.864 27.0784 64.3049L51.1833 69.5386C53.2139 69.9795 55.2175 68.6907 55.6584 66.6601L63.5644 30.2472C64.0054 28.2165 62.7166 26.2129 60.6859 25.772L36.581 20.5383C34.5503 20.0974 32.5467 21.3862 32.1058 23.4169Z"
      fill={color.DEFAULT}
      stroke={color.DEFAULT}
      strokeWidth="1.86726"
      strokeMiterlimit="10"
    />
    <path
      d="M29.5907 19.4555L21.6847 55.8683C21.2438 57.899 22.5325 59.9026 24.5632 60.3435L48.6681 65.5772C50.6988 66.0181 52.7024 64.7293 53.1433 62.6987L61.0493 26.2858C61.4902 24.2552 60.2014 22.2515 58.1708 21.8106L34.0659 16.577C32.0352 16.1361 30.0316 17.4248 29.5907 19.4555Z"
      fill={color.HOVER}
      stroke={color.DEFAULT}
      strokeWidth="1.86726"
    />
    <path
      d="M41.7477 32.4503L39.0309 35.7647L34.7081 35.4099C34.5359 35.3971 34.3634 35.4325 34.21 35.5119C34.0566 35.5914 33.9282 35.7118 33.8392 35.8599C33.7502 36.0079 33.704 36.1778 33.7057 36.3505C33.7075 36.5233 33.7572 36.6921 33.8492 36.8383L36.2113 40.4328L34.5588 44.3821C34.4935 44.5433 34.4746 44.7196 34.5041 44.8911C34.5337 45.0625 34.6106 45.2223 34.7261 45.3524C34.8416 45.4824 34.9912 45.5776 35.1579 45.6273C35.3246 45.6769 35.5019 45.6789 35.6698 45.6332L39.8524 44.5501L43.1482 47.351C43.2799 47.4672 43.4418 47.5438 43.6152 47.5721C43.7886 47.6004 43.9664 47.5792 44.1283 47.5109C44.2902 47.4427 44.4295 47.3301 44.5303 47.1862C44.6311 47.0423 44.6892 46.8729 44.698 46.6975L44.9127 42.4308L48.6006 40.2088C48.7452 40.1168 48.8616 39.9868 48.9372 39.833C49.0128 39.6792 49.0447 39.5076 49.0293 39.337C49.0138 39.1663 48.9517 39.0032 48.8498 38.8655C48.7478 38.7278 48.6099 38.6208 48.4512 38.5562L44.4086 36.9971L43.3909 32.8331C43.3534 32.6604 43.2677 32.5019 43.1437 32.376C43.0196 32.2502 42.8625 32.1621 42.6904 32.122C42.5183 32.0819 42.3384 32.0915 42.1715 32.1496C42.0046 32.2077 41.8577 32.312 41.7477 32.4503Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path d="M22.2535 58.76L24.737 62.6813Z" fill={color.HOVER} />
    <path d="M22.2535 58.76L24.737 62.6813" stroke={color.DEFAULT} strokeWidth="1.86726" />
    <path d="M60.4578 23.3287L63.0439 27.4087Z" fill={color.HOVER} />
    <path d="M60.4578 23.3287L63.0439 27.4087" stroke={color.DEFAULT} strokeWidth="1.86726" />
    <path
      d="M25.4652 42.9536L22.6643 56.0711C22.517 56.8033 22.6528 57.564 23.0444 58.1999C23.436 58.8358 24.0542 59.2995 24.7743 59.4976L48.4792 64.5578C48.4792 64.5578 36.7901 60.4219 31.0389 55.5016C25.2877 50.5814 25.4652 42.9536 25.4652 42.9536Z"
      fill={color.MEDIUM}
    />
  </svg>
);

export default CampaignIllustration;
