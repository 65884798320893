// @flow

/* eslint-disable react/no-multi-comp */

import React, { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { FilterState } from 'Libs/flowTypes';
import { useTypedDispatch } from 'Libs/redux/utils';
import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';

import { actions } from './redux';
import { useModuleSelector } from './redux/hooks';
import { hasUserWriteRole, getKnowledgeFetchLimit } from './redux/utils';
import styles from './KnowledgeGrid.style';
import { KnowledgeGridBody } from './components/KnowledgeGridBody/KnowledgeGridBody';

export const getKnowledgeGridFilterDescription = (count: number, isFiltering: boolean, t: TFunction<"translation", undefined>): string => {  
  if (isFiltering)
    return t('contents:contents_list.filters.results_match', {count});

  return t('contents:contents_list.filters.results', {count});
};

const KnowledgeFilter: React$ComponentType<{||}> = () => {
  const {t} = useTranslation();
  const dispatch = useTypedDispatch();
  const count = useModuleSelector((knowledgeGrid) => knowledgeGrid.count);
  const isFiltering = useModuleSelector((knowledgeGrid) => knowledgeGrid.isFiltering);

  const {
    selectedSegmentationIds,
    selectedActivityIds,
    selectedContributorsIds,
    selectedTextValues,
    selectedTranslationStatusIds,
    selectedContentStatusIds,
    selectedThematicIds,
  } = useModuleSelector((knowledgeGrid) => knowledgeGrid.filter);

  const description = 
    getKnowledgeGridFilterDescription(count, isFiltering, t);

  const filters = useMemo(() => [
    { category: 'segment', ids: [...selectedSegmentationIds]},
    { category: 'campaign', ids: [...selectedActivityIds]},
    { category: 'user', ids: [...selectedContributorsIds]},
    { category: 'thematic', ids: [...selectedThematicIds]},
    { category: 'contentStatus', ids: [...selectedContentStatusIds]},
    { category: 'translationStatus', ids: [...selectedTranslationStatusIds]},
    { category: 'text', values: [...selectedTextValues]},
  ], [
    selectedActivityIds,
    selectedContentStatusIds,
    selectedContributorsIds,
    selectedSegmentationIds,
    selectedTextValues,
    selectedThematicIds,
    selectedTranslationStatusIds,
  ]);

  // If the user has no knowledge and is not filtering we should not show the knowledge count
  const shoudShowKnowledgeCount = useMemo(() => isFiltering || count > 0, [count, isFiltering]);

  const handleFilterBoxChange = useCallback((filterValues: Array<FilterState>) => {
    dispatch(actions.updateFilters(filterValues));
  }, [dispatch]);

  useEffect(() => {
    // We fetch one less on the first fetch to account for the creation card, this ensures lines of 7 cards
    const modifier = hasUserWriteRole() ? 1 : 0 ;
    const action = actions.getKnowledgeFullPaginated(0, getKnowledgeFetchLimit(window.innerWidth) - modifier);

    dispatch(action);

    return action.cancel;
  }, [
    dispatch,
    selectedActivityIds,
    selectedContentStatusIds,
    selectedContributorsIds,
    selectedSegmentationIds,
    selectedTextValues,
    selectedThematicIds,
    selectedTranslationStatusIds,
  ]);

  return (
    <SFilterBox
      title={t('contents:contents_list.title')}
      description={description}
      placeholder={t('contents:contents_list.filters.placeholder')}
      filters={filters}
      onChange={handleFilterBoxChange}
      count={shoudShowKnowledgeCount ? count : null}
    />
  );
};

export const KnowledgeGrid: React$ComponentType<{||}> = () => (
  <div style={styles.wrapper}>
    <KnowledgeFilter />
    <div style={styles.gridWrapper}>
      <KnowledgeGridBody />
    </div>
  </div>
);
