import React from 'react';
import { useResizeColumns, useSortBy, useTable, Column } from 'react-table';

import { useGridLayout } from 'Components/structural/STable/HookPlugins/useGridLayout';
import TableHeader from 'Components/structural/STable/components/TableHeader';
import EmptyRow from 'Components/structural/STable/components/EmptyRow';
import EmptyNotice from 'Components/structural/STable/components/EmptyNotice';
import TableRows from 'Components/structural/STable/components/TableRows';
import ShowMore from 'Components/structural/STable/components/ShowMore';
import { OriginalRow, TableMessage } from 'Components/structural/STable/types';

type FunctionTableProps = {
  columns: Column<OriginalRow>[];
  data: OriginalRow[];
  notice: string;
  isEmpty: boolean;
  emptyRowText: string;
  canShowMore: boolean;
  onShowMoreClicked: React.MouseEventHandler<HTMLButtonElement>;
  showMoreText: string;
  isTableLoading: boolean;
  isContentLoading: boolean;
  loadingText: string;
  tableMessage: TableMessage;
  showMoreError: string;
};

export function Table(props: FunctionTableProps) {
  const {
    columns,
    data,
    isEmpty,
    notice,
    emptyRowText,
    tableMessage,
    canShowMore,
    showMoreError,
    onShowMoreClicked,
    showMoreText,
    isTableLoading,
    isContentLoading,
    loadingText,
  } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<OriginalRow>(
    {
      columns,
      data,
    },
    useGridLayout,
    useResizeColumns,
    useSortBy,
  );

  return (
    <div>
      <TableHeader
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        isContentLoading={isContentLoading}
        tableMessage={tableMessage}
      />
      <EmptyRow isEmpty={isEmpty} isTableLoading={isTableLoading} emptyRowText={emptyRowText} />
      <EmptyNotice isEmpty={isEmpty} forceLoading={isTableLoading} notice={notice} />
      <TableRows
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        rows={rows}
        isTableLoading={isTableLoading}
        isContentLoading={isContentLoading}
        loadingText={loadingText}
        isEmpty={isEmpty}
      />
      <ShowMore
        canShowMore={canShowMore}
        onShowMoreClicked={onShowMoreClicked}
        showMoreError={showMoreError}
        showMoreText={showMoreText}
        loadingText={loadingText}
        isContentLoading={isContentLoading}
      />
    </div>
  );
}

export default Table;
