// @flow

/**
 * Render a Phone Frame
 *
 * Props:
 *  - url: The url render in the frame
 *  - toggleReload: Handle IFrame refresh
 *  - style: custom style to override the default
 */

import * as React from 'react';

import styles from './SPhoneFrame.style';


type DomIFrameElement = null | HTMLIFrameElement;

type Props = {|
  url: string,
  toggleReload?: boolean,
  style?: Object,
|};

export class SPhoneFrame extends React.Component<Props> {
  _iframe: { current: DomIFrameElement } = {};

  static defaultProps = {
    toggleReload: false,
    style: undefined,
  };

  constructor(props: Props) {
    super(props);

    this._iframe = React.createRef<HTMLIFrameElement>();
  }

  componentDidUpdate(prevProps: Props) {
    const { toggleReload, url } = this.props;

    if (toggleReload !== prevProps.toggleReload && this._iframe.current)
      this._iframe.current.contentWindow.location = url;
  }

  render() {
    const { url, style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <iframe
          ref={this._iframe}
          src={url}
          style={styles.iframe}
        />
      </div>
    );
  }
}

export default SPhoneFrame;
