import { t } from 'i18next';

import Enum from 'Models/Enum';

const campaignPicture = 'assets/img/activities-gameplay/BO-New-Activity-modal-export-Campaign.png';
const breakingNewsPicture = 'assets/img/activities-gameplay/BO-New-Activity-modal-export-Breaking-News.png';
const welcomeCardPicture = 'assets/img/activities-gameplay/BO-New-Activity-modal-export-Welcome-Card.png';
const interseasonPicture = 'assets/img/activities-gameplay/BO-New-Activity-modal-export-Interseason.png';
const thatsAllFoksPicture = 'assets/img/activities-gameplay/BO-New-Activity-modal-export-Thats-All-Folks.png';

export const getActivitiesDefinition = () => [
  {
    type: Enum.activityTypeEnum.DAILY_SERIE,
    content: t('activities:list.activity_descriptions.campaign.tab_name'),
    icon: 'campaign',
    color: '#E82824',
    title: t('activities:list.activity_descriptions.campaign.title'),
    description: t('activities:list.activity_descriptions.campaign.description'),
    picture: campaignPicture,
  },
  {
    type: Enum.activityTypeEnum.BREAKING_NEWS,
    content: t('activities:list.activity_descriptions.breaking_news.tab_name'),
    icon: 'breaking-news',
    color: '#F1CB56',
    title: t('activities:list.activity_descriptions.breaking_news.title'),
    description: t('activities:list.activity_descriptions.breaking_news.description'),
    picture: breakingNewsPicture,
  },
  {
    type: Enum.activityTypeEnum.WELCOME,
    icon: 'welcome-card',
    content: t('activities:list.activity_descriptions.welcome_card.tab_name'),
    color: '#3E68C1',
    title: t('activities:list.activity_descriptions.welcome_card.title'),
    description: t('activities:list.activity_descriptions.welcome_card.description'),
    picture: welcomeCardPicture,
  },
  {
    type: Enum.activityTypeEnum.INTERSEASON,
    content: t('activities:list.activity_descriptions.interseason.tab_name'),
    icon: 'interseason',
    color: '#E14B0E',
    title: t('activities:list.activity_descriptions.interseason.title'),
    description: t('activities:list.activity_descriptions.interseason.description'),
    picture: interseasonPicture,
  },
  {
    type: Enum.activityTypeEnum.THATS_ALL_FOLKS,
    content: t('activities:list.activity_descriptions.thats_all_folks.tab_name'),
    icon: 'thats-all-folks',
    color: '#E14B0E',
    title: t('activities:list.activity_descriptions.thats_all_folks.title'),
    description: t('activities:list.activity_descriptions.thats_all_folks.description'),
    picture: thatsAllFoksPicture,
  },
];
