import m from 'm';
import App from 'models/App';
import Model from 'models/Model';
import settingsComponent from 'components/settings.authentication.old';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import Button from 'components/button';

var component = {};

component.controller = function controller() {
  var self = this;

  self.settingsList = App.AuthenticationSettings();

  self.addSettings = function addSettings() {
    // var settings = App.AuthenticationSettings().at(0).clone();

    // settings.id(undefined);
    self.settingsList.push(new (Model('AuthenticationSettings'))());
    m.redraw();
  };

  self.removeRule = function remove(index) {
    self.settingsList.removeAt(index);
    m.redraw();
  };
};

component.view = function view(c) {
  return m('.game-level-page', [
    m('.header', [
      m(menu),
      m(gaiaMenu),
    ]),
    m('.detail__page.detail__page--section', [
      m('.game-level-header', [
        m('.game-level-header__title', {
          style: 'margin-bottom: 10px',
        }, 'Sign up'),
      ]),
    ]),
    c.settingsList.map(function displaySettings(settings, index) {
      return m(settingsComponent, {
        settings  : settings,
        index     : index,
        removeRule: c.removeRule,
      });
    }),
    m('.detail__page', [
      m('.game-level-header', [
        m(Button, {
          class : 'button--action detail__page__save',
          action: c.addSettings,
          value : 'Add Authentication settings',
        }),
      ]),
    ]),
  ]);
};

export default component;
