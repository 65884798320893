import React from 'react';
import { useSelector } from 'react-redux';

import SCampaignInfo from 'Components/structural/SCampaignInfo/SCampaignInfo';

import styles from './Info.style';
import { getActivityEndDateForDisplay, useModuleSelector } from '../../../redux';

const useEndDate = () => useSelector(getActivityEndDateForDisplay);

export const Info = () => {
  const numContents = useModuleSelector((state) =>
    state.activities[0].dailySerie.days.reduce((acc, x) => acc + x.dailySerieContents.length, 0),
  );
  const numSessions = useModuleSelector((state) => state.activities[0].dailySerie.days.length);
  const numExtraDays = useModuleSelector((state) => state.activities[0].dailySerie.numExtraDays);

  const startDate = useModuleSelector((state) => state.activities[0].startDate);
  const active = useModuleSelector((state) => state.activities[0].active);

  const endDate = useEndDate();

  return (
    <SCampaignInfo
      active={active}
      contentCount={numContents}
      sessionCount={numSessions}
      extraDays={numExtraDays}
      startDate={startDate}
      endDate={endDate}
      stickyContainerStyle={styles.campaignHeader}
      stickyStyle={styles.stickySCampaignInfo}
      style={styles.sCampaignInfo}
    />
  );
};
