// @flow

import Promise from 'bluebird';
import request from 'Services/requestService';
import regex from 'Libs/regex';

export type ExtractionMethod = 'rake' | 'textrank';

export type KeywordItem = {|
  score: number,
  keyword: string,
|};

export type ExtractionResponse = {|
  extractionMethodType: ExtractionMethod,
  results: Array<KeywordItem>,
  threshold: number,
  uuid: string,
|};

const MIN_CHAR_NUMBER = 50;
const MIN_CHAR_ERROR = `You have to provide a text with at least ${MIN_CHAR_NUMBER} chars to retrieve keywords.`;

const THRESHOLD_BY_AI_METHOD = {
  'rake': 1,
  'textrank': 1,
};

const DEFAULT_NB_BY_AI_METHOD = {
  'rake': 5,
  'textrank': 5,
};

const isWordUseful = (keyword: string) => {
  const isFullOfAccent = regex.AIaccentsAlone.test(keyword) || regex.AIcomposedAccentsAlone.test(keyword);

  return keyword && keyword !== '-' && !isFullOfAccent;
};

export class KeywordExtraction {
  static _baseUrl = '/api/smart-keywords-extraction';

  /**
  * Retrieve keyword from text by using keyword extraction service
  * @param {string} text The text where we want to retrieve keywords
  * @param {number} language The id of the language @see AvailableSegmentationGroupItem
  * @param {number} limit The keyword max number to retrieve
  */
  static extractKeywords(
    text: string,
    language: number = 1,
    limit: number = 0,
  ): Promise<ExtractionResponse> {
    if (!(text && text.length > MIN_CHAR_NUMBER))
      return Promise.reject(new Error(MIN_CHAR_ERROR));

    const url = request.buildUrl(`${this._baseUrl}/get`, { language, text });

    return request
      .get(url)
      .then((response: ExtractionResponse) => {
        const { results, extractionMethodType } = response;
        const keywordsLimit = limit || DEFAULT_NB_BY_AI_METHOD[extractionMethodType];
        const threshold = THRESHOLD_BY_AI_METHOD[extractionMethodType];

        // Avoid to loop each time on array of keyword to verify if we pushed the keyword cleaned
        const keywordsMap: {[keyword: string]: number} = {};

        /**
         * Will only keep keywords with a relative accuracy for the text we processed
         * and will clean special characters that can trouble image search
         */
        const keywordsAccurate = results.reduce((keywordsCleaned: Array<KeywordItem>, item: KeywordItem) => {
          const { score, keyword } = item;

          const keywordCleaned = keyword.replace(regex.AISpecialChars, '').trim();

          if (score >= threshold && !keywordsMap[keywordCleaned] && isWordUseful(keywordCleaned)) {
            keywordsMap[keywordCleaned] = 1;
            keywordsCleaned.push({ ...item, keyword: keywordCleaned });
          }

          return keywordsCleaned;
        }, []);

        if (keywordsLimit && keywordsAccurate.length > keywordsLimit)
          return { ...response, results: keywordsAccurate.slice(0, keywordsLimit), threshold };

        return { ...response, results: keywordsAccurate, threshold };
      })
      .catch((error) => {
        console.debug(error.message);

        throw new Error('Keyword extraction failed.');
      });
  }
}

export default KeywordExtraction;
