import React, { Component, createRef } from 'react';
import { t } from 'i18next';
import Sortable, { MoveEvent, SortableEvent } from 'sortablejs';
import { memoize } from 'lodash';

import Content from 'Models/Content';
import Enum from 'Models/Enum';

import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import SLegacyContentCard from 'Components/structural/SContentCard/legacy/SLegacyContentCard';
import MDrawer from 'Components/modal/MDrawer/MDrawer';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UButton from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import { GAMEPLAY_ID_TO_STRING } from 'Components/utils/Enum';

import type { FilterState } from 'Libs/filter/types';

import createContent from '../../../redux/models/Content';
import type { ContentType } from '../../../redux/models/Content';
import style from './Drawer.style';

export type DrawerProps = {
  languageId: number;
  onSelect: (arr: ContentType[]) => any;
  onNewContentPress: () => void;
  visible: boolean;
  onClose: () => void;
  onContentDrag: (a: number, b: number, c: ContentType) => void;
  onContentMove: (a: number) => void;
  contentIsDragging: boolean;
  selectedContentIds: number[];
};

type DrawerState = {
  contents: ContentType[];
  contentOffset: number;
  selection: Record<number, boolean>;
  thematicIds: number[];
  textValues: string[];
  segmentationIds: number[];
  total: number;
  canShowOverlay: boolean;
};

const CONTENT_LIMIT = 21;
const NB_COLUMN = 3;
const noop = () => {};

export class Drawer extends Component<DrawerProps, DrawerState> {
  selectedItems: ContentType[] = [];

  loading = false;

  containerRef = createRef<MDrawer>();

  unmount = false;

  initialized = false;

  sortableContainer: Sortable | null = null;

  state: DrawerState = {
    contents: [],
    contentOffset: 0,
    selection: {},
    thematicIds: [],
    textValues: [],
    segmentationIds: [],
    total: 0,
    canShowOverlay: true,
  };

  componentDidMount() {
    const { languageId } = this.props;

    this.unmount = false;
    this.createSortable();

    if (languageId) {
      this.fetchContents(0);
    }
  }

  componentDidUpdate(prevProps: DrawerProps, prevState: DrawerState) {
    const { visible, languageId } = this.props;
    const { visible: oldVisible, languageId: oldLanguageId } = prevProps;
    const { contents } = this.state;
    const { contents: oldContents } = prevState;

    if (!oldLanguageId && languageId) {
      this.fetchContents(0);
    }

    if (contents !== oldContents) {
      this.createSortable();
    }

    if (visible && !oldVisible) {
      this.scrollContentToTop();
    }
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  handleClose = () => {
    const { onClose } = this.props;

    this.handleClearSelection();

    onClose();
  };

  handleClearSelection = () => {
    this.selectedItems = [];

    this.setState({ selection: {} });
  };

  handleFiltersChanged = (filters: FilterState[]) => {
    const { thematicIds, textValues, segmentationIds } = this.state;

    let newThematicsIds = thematicIds.slice();
    let newSegmentationIds = segmentationIds.slice();
    let newTextValues = textValues.slice();

    filters.forEach((filter) => {
      if (filter.category === 'text') {
        newTextValues = filter.values;
      }

      if (filter.category === 'thematic') {
        newThematicsIds = filter.ids;
      }

      if (filter.category === 'segment') {
        newSegmentationIds = filter.ids;
      }
    });

    this.setState(
      {
        contentOffset: 0,
        textValues: newTextValues,
        thematicIds: newThematicsIds,
        segmentationIds: newSegmentationIds,
      },
      () => {
        this.fetchContents(0);
      },
    );
  };

  handleSelectContent = memoize((item: ContentType) => (isSelected: boolean) => {
    const { selection } = this.state;

    if (selection[item.id] && !isSelected) {
      const index = this.selectedItems.findIndex((elem) => elem.id === item.id);

      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    } else if (!selection[item.id] && isSelected) {
      this.selectedItems.push(item);
    }

    this.setState({ selection: { ...selection, [item.id]: isSelected } });
  });

  handleSelectPress = () => {
    const { onSelect } = this.props;

    onSelect([...this.selectedItems]);

    this.handleClearSelection();
  };

  handleLoadMore = () => {
    const { contentOffset } = this.state;

    this.setState({ contentOffset: contentOffset + CONTENT_LIMIT });

    return this.fetchContents(contentOffset + CONTENT_LIMIT);
  };

  handleMove = (evt: MoveEvent) => {
    const { onContentMove } = this.props;
    const { to } = evt;

    const dayIndex = parseInt(to.id, 10) - 1;

    onContentMove(dayIndex);

    return true;
  };

  // eslint-disable-next-line class-methods-use-this
  handleDraggingStart = () => {
    if (Sortable.ghost) {
      Sortable.ghost.style.opacity = '1';
    }

    // HACK to have the cursor grabbing while dragging the element
    const page = document.getElementsByTagName('html')[0];
    const contents = document.querySelectorAll<HTMLDivElement>('.ReactDraggable');

    page.style.cursor = 'grabbing';
    contents.forEach((content) => {
      // eslint-disable-next-line no-param-reassign
      content.style.cursor = 'grabbing';
    });
  };

  handleDraggingEnd = (evt: SortableEvent) => {
    const { onContentDrag, onContentMove } = this.props;
    const { contents } = this.state;
    const { oldDraggableIndex = 0, newIndex = 0, to } = evt;

    const newDraggableIndex = newIndex - 1;

    onContentMove(-1);

    // Replace the clone by the original item
    evt.clone.replaceWith(evt.item);

    const page = document.getElementsByTagName('html')[0];
    const ReactDraggables = document.querySelectorAll<HTMLDivElement>('.ReactDraggable');

    page.style.cursor = 'auto';
    ReactDraggables.forEach((content) => {
      // eslint-disable-next-line no-param-reassign
      content.style.cursor = 'grab';
    });

    if (to.id === 'drawer-container') {
      return;
    }

    const content = contents[oldDraggableIndex];
    const dayIndex = parseInt(to.id, 10) - 1;

    onContentDrag(dayIndex, newDraggableIndex, content);
  };

  handleChoose = () => {
    this.setState({ canShowOverlay: false });
  };

  handleUnChoose = () => {
    this.setState({ canShowOverlay: true });
  };

  getFilters = memoize(
    (segmentationIds, thematicIds, textValues): FilterState[] => [
      { category: 'segment', ids: segmentationIds },
      { category: 'thematic', ids: thematicIds },
      { category: 'text', values: textValues },
    ],
    (segmentationIds: number[], thematicIds: number[], textValues: string[]) => {
      const cacheKey = [...segmentationIds, ...thematicIds, ...textValues];

      return JSON.stringify(cacheKey);
    },
  );

  getGameplayType = memoize((gameplayId: number) => [GAMEPLAY_ID_TO_STRING[gameplayId]]);

  createSortable = () => {
    if (this.sortableContainer) {
      this.sortableContainer.destroy();
      this.sortableContainer = null;
    }

    const container = document.getElementById('drawer-container');

    if (!container) {
      return;
    }

    const options: Sortable.Options = {
      group: {
        name: 'sortable-drawer',
        pull: 'clone',
        put: false,
        revertClone: false,
      },
      sort: false,
      ghostClass: 'ghost-drawer',
      animation: 250,
      forceFallback: true,
      filter: '.nonDraggable',
      onStart: this.handleDraggingStart,
      onEnd: this.handleDraggingEnd,
      onMove: this.handleMove,
      onChoose: this.handleChoose,
      onUnchoose: this.handleUnChoose,
    } as const;

    this.sortableContainer = Sortable.create(container, options);
  };

  renderSearch = () => {
    const { textValues, thematicIds, segmentationIds } = this.state;
    const { visible } = this.props;

    // FIXME rendering the SFilterBox can be long with a lot of filters
    // So we delay its loading until the first time it is shown
    if (!this.initialized && !visible) {
      return null;
    }

    this.initialized = true;

    const filters = this.getFilters(segmentationIds, thematicIds, textValues);
    const description = t('activities:scheduling.drawer.description');

    return (
      <SFilterBox
        key="filterbox-content-scheduling"
        placeholder={t('activities:scheduling.drawer.search_placeholder')}
        description={description}
        filters={filters}
        onChange={this.handleFiltersChanged}
        count={null}
        type="compact"
        style={style.filter}
        containerStyle={style.containerFilter}
        showLanguageInSeg={false}
      />
    );
  };

  renderContents = () => {
    const { contents } = this.state;

    if (!contents.length) {
      return !this.loading ? this.renderEmptyLibrary() : null;
    }

    return (
      <div style={style.containerContent} id="drawer-container">
        {contents.map(this.renderContent)}
      </div>
    );
  };

  renderEmptyLibrary = () => {
    const { onNewContentPress } = this.props;

    return (
      <div style={style.empty}>
        <div style={style.emptyTitle}>
          {this.isFiltering()
            ? t('activities:scheduling.drawer.search_empty')
            : t('activities:scheduling.drawer.empty_library')}
        </div>
        <div style={style.emptySubTitle}>
          {this.isFiltering()
            ? t('activities:scheduling.drawer.search_empty_subtitle')
            : t('activities:scheduling.drawer.empty_library_subtitle')}
        </div>
        {this.isFiltering() ? null : (
          <UButton
            text={t('activities:scheduling.drawer.create_content')}
            type="accentuated"
            onClick={onNewContentPress}
            style={style.newContentButton}
          />
        )}
      </div>
    );
  };

  renderContent = (content: ContentType, index: number) => {
    const { contentIsDragging, selectedContentIds } = this.props;
    const { selection, canShowOverlay } = this.state;

    const types = this.getGameplayType(content.gameplayId);

    return (
      <SLegacyContentCard
        key={content.id}
        id={`#${content.knowledgeCustomId || content.knowledgeId}`}
        canShowOverlay={canShowOverlay && !contentIsDragging}
        types={types}
        imageUrl={content.imageUrl}
        title={content.title}
        displayOption={false}
        locale={content.locale}
        style={(index + 1) % NB_COLUMN ? style.contentCard : style.thirdContentCard}
        selected={selection[content.id]}
        selectable
        previewable={false}
        onSelect={this.handleSelectContent(content)}
        canBeDragged
        alreadyUsed={selectedContentIds.includes(content.id)}
      />
    );
  };

  renderLoadMore = () => {
    const { contents, total } = this.state;

    if (!contents.length || total === contents.length) {
      return null;
    }

    return (
      <div style={style.loadMore}>
        <UButtonWithLoading
          text={t('activities:scheduling.drawer.load_more')}
          onClick={this.handleLoadMore}
          onRequestEnd={noop}
          ghost
        />
      </div>
    );
  };

  fetchContents = (contentOffset: number) => {
    const { languageId } = this.props;
    const { thematicIds, textValues, contents, segmentationIds } = this.state;
    const segmentationFilter = [...segmentationIds, languageId];
    const thematicId = thematicIds[thematicIds.length - 1] || null;
    const filter = {
      offset: contentOffset,
      length: CONTENT_LIMIT,
      contentFilter: { where: { and: [{ archived: false }, { statusId: Enum.contentStatus.VALIDATED }] } },
      knowledgeFilter: {
        where: { pendingTranslation: { neq: true } },
      },
      segmentation: segmentationFilter,
      ...(thematicId ? { thematicId } : {}),
      ...(textValues.length ? { txtSearch: textValues } : {}),
    };

    this.loading = true;

    return Content.filter(filter).then(({ results: newContents, count }: { results: ContentType[]; count: number }) => {
      if (this.unmount) {
        return;
      }

      const normalizedContents = newContents.map(createContent);

      this.loading = false;

      this.setState({
        contents: contentOffset ? contents.concat(normalizedContents) : normalizedContents,
        total: count,
      });
    });
  };

  isFiltering = () => {
    const { thematicIds, textValues, segmentationIds } = this.state;

    return thematicIds.length || textValues.length || segmentationIds.length > 1;
  };

  scrollContentToTop = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollContentToTop();
    }
  };

  renderCount() {
    if (!this.selectedItems.length) {
      return null;
    }

    return (
      <div style={style.selection}>
        <div style={style.selectionText}>
          <UIconButton icon="close" size="M" onClick={this.handleClearSelection} ghost type="standard-light" />
          <div style={style.selectedItems}>
            {t('activities:scheduling.drawer.selected_items', {
              count: this.selectedItems.length,
            })}
          </div>
        </div>
        <UButton
          text={t('activities:scheduling.drawer.select')}
          onClick={this.handleSelectPress}
          style={style.selectButton}
        />
      </div>
    );
  }

  render() {
    const { visible } = this.props;

    return (
      <MDrawer
        ref={this.containerRef}
        onClose={this.handleClose}
        title={t('activities:scheduling.drawer.title')}
        visible={visible}
        footer={this.renderCount()}
      >
        <div style={style.container}>
          {this.renderSearch()}
          {this.renderContents()}
          {this.renderLoadMore()}
        </div>
      </MDrawer>
    );
  }
}

export default Drawer;
