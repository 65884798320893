import Enum from 'Models/Enum';

import type { ActivityType } from './redux/models/Activity';
import type { AlertValidationField } from './redux/models/Alert';

// eslint-disable-next-line complexity
export const checkActivityIsValid = (
  activity: ActivityType,
  targetModeId: number,
): Readonly<AlertValidationField[]> => {
  const result: Array<AlertValidationField> = [];

  if (!activity.name) result.push('noName');

  // INFO stop checks here if activity is not active
  if (!activity.active) return result;

  if (!activity.description) result.push('noDescription');

  if (!activity.coverId) result.push('noCover');

  if (!targetModeId) result.push('noTargetMode');

  const hasEmptyDay = activity.dailySerie.days.find((day) => !day.dailySerieContents.length);

  if (hasEmptyDay) result.push('emptyDay');

  const hasNonValidateContent = activity.dailySerie.days.some((day) =>
    day.dailySerieContents.some(
      (dsContent) => !dsContent.content.archived && dsContent.content.statusId !== Enum.contentStatus.VALIDATED,
    ),
  );

  if (hasNonValidateContent) result.push('nonValidateContent');

  return result;
};

export const checkActivityCanBeTranslated = (activity: ActivityType): Readonly<AlertValidationField[]> => {
  const result: Array<AlertValidationField> = [];

  const hasNonValidateContent = activity.dailySerie.days.some((day) =>
    day.dailySerieContents.some((dsContent) => dsContent.content.statusId !== Enum.contentStatus.VALIDATED),
  );

  if (hasNonValidateContent) result.push('nonValidateContent');

  return result;
};
