import m from 'm';
import KEY from 'keys';
import T from 'types';

var component = {};

/*
    placeholder: 'placeholder',
    value      : Model,
    list       : Collection,
    path       : 'path',
 */
component.controller = function controller(args) {
  var self = this;
  var moduleElem = null;

  self.isOpened = false;

  self.onunload = function onunload() {
    document.removeEventListener('click', onclick, false);
  };

  self.moduleConfig = function moduleConfig(elem, init) {
    if (!init)
      moduleElem = elem;
  };

  self.open = function open() {
    if (!self.isOpened)
      document.addEventListener('click', onclick, false);
    self.isOpened = true;
  };

  self.close = function close(e) {
    if (e)
      e.stopPropagation();

    document.removeEventListener('click', onclick, false);
    self.isOpened = false;
  };

  self.toggle = function toggle(e) {
    if (self.isOpened)
      self.close(e);
    else
      self.open(e);
  };

  function onclick(e) {
    var target = e.target;

    if (!moduleElem.contains(target)) {
      e.preventDefault();

      self.close();
      m.redraw();
    }
  }

  self.select = function selectItem(item, e) {
    args.value(item);
    self.close(e);
    m.redraw();
  };

};

component.view = function view(c, args) {
  return m('.select', {
    config : c.moduleConfig,
    onclick: c.open,
    class  : args.class,
  }, [
    m('.filteringSelect__arrow', {
      class  : c.isOpened ? 'filteringSelect__arrow--opened' : '',
      onclick: c.toggle,
    }),
    m('.select__value.input__value', {
      class: !args.value()[args.path]() ? 'select__value--placeholder' : '',
    }, !args.value()[args.path]() ? args.placeholder : args.value()[args.path]()),
    m('.filteringSelect__opened', {
      class: c.isOpened ? '' : 'hide',
    }, [
      m('.filteringSelect__list', args.list.map(function mapList(item) {
        return m('.filteringSelect__item', {
          onclick: c.select.bind(null, item),
        }, item[args.path]());
      })),
    ]),
  ]);
};

export default component;
