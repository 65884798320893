import m from 'm';
import reactCreator from 'components/react.creator';
import { COLORS } from 'Components/foundation';

var component = {};

var nextTooltipId = 0;

component.controller = function controller(args) {
  var self = this;

  self.tooltipId = `input-textarea-tooltip-id-${nextTooltipId++}`

  // Disable error display by default
  self.error = args.error || {
    display: m.prop(false),
  };

  self.check = function check(e) {
    var elem = e.target;
    var cursorPosition = this.selectionEnd;
    var isAtEnd = cursorPosition === this.value.length;
    var diff = this.value.length - args.value().length;
    var MAX_HEIGHT = 200;

    args.displayErr(false);

    if (!this.value.length)
      self.error.display(false);
    else if (args.check && !args.check.test(this.value))
      self.error.display(true);
    else
      self.error.display(false);

    if (!isAtEnd && this.value.length > args.max) {
      this.value = args.value();
      cursorPosition -= diff;
      this.setSelectionRange(cursorPosition, cursorPosition);
    } else if (args.max && this.value.length > args.max)
      this.value = this.value.substring(0, args.max);

    if (args.resize) {
      var newHeight = (elem.scrollHeight + 5) > 50 ? elem.scrollHeight + 5 : 50;

      if (newHeight > MAX_HEIGHT)
        newHeight = MAX_HEIGHT;
      elem.style.height = '0px';
      elem.style.height = newHeight + 'px';
    }

    args.value(this.value);
  };

  self.validateAndResize = function validateteAndResize(e) {
    var value = e.target.value.trim();

    args.value(value);
    self.validate(value);
    self.resize(e);
  };

  self.validate = function validate(value) {
    if (!value.length)
      self.error.display(false);
    else if (args.validate && !args.validate.test(value))
      self.error.display(true);
  };

  self.resize = function resize(e) {
    var elem = e.target;

    elem.style.height = '50px';
  };

  self.renderMaxLength = function renderMaxLength() {
    var text = `${args.value().length} / ${args.max}`

    if (!args.warningMax || args.value().length <= args.warningMax)
      return m('.textarea__maxlength', text);


    var infoIconWithTooltip = m(`.textarea__maxlength__icon-container[data-tip][data-for=${self.tooltipId}]`, [
      m(reactCreator, {
        component: 'UIcon',
        props    : {
          name : 'info-what',
          color: COLORS.WARNING.DEFAULT,
          size : 16,
        },
        style: {
          // INFO to center vertically
          display: 'flex',
          marginLeft: '8px',
        }
      }),
      m(reactCreator, {
        component: 'STooltip',
        props    : {
          id : self.tooltipId,
          position: 'left',
          content : args.warningText,
        },
      })
    ]);

    return m('.textarea__maxlength.textarea__maxlength--warning', [
      text,
      infoIconWithTooltip
    ]);
  }
};

component.view = function view(c, args) {
  args.displayErr = args.displayErr || m.prop(false);

  return m('.input', {
    class: [
      args.class || 'input--none',
      'newKnowledge__explanation',
    ].join(' '),
  }, [
    m('.container__textarea', [
      m('textarea.input__value', {
        placeholder: args.placeholder,
        oninput    : c.check,
        onfocus    : c.check,
        onblur     : c.validateAndResize,
        value      : args.value(),
        disabled   : !args.editable,
      }),
      m('.input__type'),
      c.renderMaxLength()
    ]),
  ]);
};

export default component;
