/* eslint-disable @typescript-eslint/indent -- Does not recognize default props indent */
/**
 * Display a @SPageLoader in a page
 *
 * Props:
 *  - style: Override component's style
 *  - label: Text displayed in loader
 *  - isLoading: Set if the loader is displayed
 *  - minTimeLoading: Define the min time loading
 *
 */

import React, { CSSProperties } from 'react';
import { t } from 'i18next';

import SPageLoader from 'Components/structural/SPageLoader/SPageLoader';

import styles from './SPageLoaderWithTimer.style';

type Props = {
  style?: CSSProperties;
  label?: string;
  isLoading: boolean;
  minTimeLoading?: number;
};

type State = {
  isTimerRunning: boolean;
};

export class SPageLoaderWithTimer extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    label: null,
    minTimeLoading: 500,
  };

  state = { isTimerRunning: false };

  // eslint-disable-next-line react/sort-comp
  timer: ReturnType<typeof setTimeout> | undefined;

  componentDidMount() {
    this.comparePropsAndRunTimer({
      ...this.props,
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps: Props) {
    this.comparePropsAndRunTimer(prevProps);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  comparePropsAndRunTimer(prevProps: Props) {
    const { isLoading, minTimeLoading } = this.props;

    if (prevProps.isLoading !== isLoading && isLoading) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => this.setState({ isTimerRunning: false }), minTimeLoading);
      this.setState({ isTimerRunning: true });
    }
  }

  render() {
    const { isTimerRunning } = this.state;
    const { isLoading, label, style } = this.props;

    if (!(isLoading || isTimerRunning)) {
      return null;
    }

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <SPageLoader style={{ marginBottom: 16 }} label={label || t('structural_component:s_page_loader.label')} />
      </div>
    );
  }
}

export default SPageLoaderWithTimer;
