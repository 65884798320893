import m from 'm';
import Enum from 'models/Enum';
import contentPreviewImage from 'components/content.preview.image';
import contentPreviewQuestion from 'components/content.preview.question';

var component = {};

component.view = function view(c, args) {
  var image = args.content.game().mediaTypeFrontId() === Enum.mediaType.Image
    ? args.content.game().imageFront().cdn({transformation: {width: 300}})
    : args.content.game().videoFront().getOriginalThumbnailSync();
  var question = args.content.game().question().data();

  return m('.preview-open-question', [
    m(contentPreviewImage, {
      image: image,
    }),
    m(contentPreviewQuestion, {
      question: question,
    }),
    m('.preview-open-question__body', [
      m('.preview-open-question__answer', 'Your answer'),
      m('.preview-open-question__block-submit-answer', [
        m('.preview-open-question__text-submit-answer', 'Submit answer'),
      ]),
    ]),
  ]);
};
export default component;
