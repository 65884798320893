import React, { useMemo, memo, useCallback } from 'react';
import { css } from 'aphrodite';

import SToast from 'Components/structural/SToast/SToast';
import Transition from 'Components/hoc/Transition/Transition';
import type { Alert } from 'Pages/AlertManager/redux';
import { useAppDispatch } from 'Libs/redux/store';

import { removeToastAlert } from '../redux/utils';
import styles from './ToastAlertList.style';

export type ToastAlertListProps = {
  alerts: ReadonlyArray<Alert>;
};

type HandlerParam = {
  id: string;
  actionCallbacks?: {
    close?: string;
  };
};

const ToastAlertList = ({ alerts }: ToastAlertListProps) => {
  const dispatch = useAppDispatch();
  const handleClose = useCallback(
    ({ id, actionCallbacks }: HandlerParam) =>
      () => {
        removeToastAlert(id);

        if (actionCallbacks?.close) {
          dispatch({ type: actionCallbacks?.close });
        }
      },
    [dispatch],
  );
  const reversedAlerts = useMemo(() => [...alerts].reverse(), [alerts]);

  return (
    <div className={css(styles.listWrapper)}>
      <Transition
        entryAnimationCss={css(styles.containerAlertIn)}
        exitAnimationCss={css(styles.containerAlertOut)}
        exitTimeout={300}
      >
        {reversedAlerts.map(({ id, status, title, priority, actionCallbacks }) => (
          <div key={id} className={css(styles.containerAlert)}>
            <SToast
              title={title || ''}
              // @ts-expect-error -- ToastALertList does not use warning status declared on Alert type in reducer
              status={status || 'success'}
              priority={priority || 'low'}
              onClick={handleClose({ id, actionCallbacks })}
            />
          </div>
        ))}
      </Transition>
    </div>
  );
};

export default memo<ToastAlertListProps>(ToastAlertList);
