import React from 'react';

import styles from 'Components/structural/STable/STable.style';

type TitleProps = {
  title: string | null;
};

const Title = ({ title }: TitleProps) => {
  if (!title) return null;

  return (
    <h1 style={styles.headingTitle} className="stable--heading-title" dangerouslySetInnerHTML={{ __html: title }} />
  );
};

export default Title;
