import { createStyleSheet } from 'Components/foundation/stylesheets';

import { TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  title: {
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
  },
  description: {
    ...TYPOGRAPHY.BODY3,
    maxWidth: '375px',
    marginTop: '8px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    marginLeft: '24px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  illustrationContainer: {
    position: 'relative',
  },
  illustration: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export default styleSheet;
