import React from 'react';
import type { CSSProperties } from 'react';

import type { IllustrationName } from 'Components/foundation/illustrations';

import UBackground from 'Components/unit/UBackground/UBackground';
import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UIcon from 'Components/unit/UIcon/UIcon';
import styles, { BACKGROUND_HEADER_THEMES, BackgroundHeaderThemeName } from './SBackgroundHeader.style';

export type SBackgroundHeaderProps = {
  illustration?: IllustrationName;
  text?: string;
  status?: BackgroundHeaderThemeName;
  showIcon?: boolean;
  size?: 'L' | 'M';
  style?: CSSProperties;
};

export const SBackgroundHeader = ({
  illustration,
  text,
  size = 'L',
  status = 'active',
  showIcon = true,
  style = {},
}: SBackgroundHeaderProps) => {
  const height = size === 'L' ? 74 : 48;
  const containerStyle = { ...styles.container, ...style };

  const { backgroundColorScheme, textColor, disableIllustration, icon, iconColor, illustrationColorScheme } =
    BACKGROUND_HEADER_THEMES[status];

  const textStyle = { ...styles.text, color: textColor };

  const illustrationContainerStyle = {
    ...styles.illustration,
    ...(size === 'M' ? styles.illustrationSmall : {}),
  };

  return (
    <div style={containerStyle}>
      <UBackground
        width="100%"
        height={height}
        color={backgroundColorScheme}
        name="shape-background"
        style={styles.background}
      />
      {illustration && (
        <div style={illustrationContainerStyle}>
          <UIllustration
            illustration={illustration}
            color={illustrationColorScheme}
            disabled={disableIllustration}
            size="M"
          />
        </div>
      )}
      {text && <h3 style={textStyle}>{text}</h3>}
      {showIcon && icon && iconColor && <UIcon name={icon} color={iconColor} size={16} style={styles.icon} />}
    </div>
  );
};

export default SBackgroundHeader;
