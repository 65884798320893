// @flow

/* eslint-disable key-spacing */

import { ANIMATIONS, COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapperContainer: {
    position: 'absolute',
    top: 0,
    right: -597,
    width: 597,
    boxShadow: '-3px 0 6px rgba(0,0,0,0.08)',
    borderColor: '#EBEBEB',
    backgroundColor: COLORS.WHITE.DEFAULT,
    overflowY: 'auto',
    zIndex: 10000,
  },
  wrapper: {
    position: 'relative',
  },
  header: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 10,
  },
  slideIn: {
    right: 0,
    transition: `right 0.3s ${ANIMATIONS.EASING.DECELERATE}`,
  },
  slideOut: {
    right: -597,
    transition: `right 0.25s ${ANIMATIONS.EASING.ACCELERATE}`,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
    marginTop: 54,
    marginBottom: 8,
  },
  close: {
    position: 'sticky',
    display: 'inline-flex',
    top: 28,
    paddingRight: 28,
    left: '100%',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
};
