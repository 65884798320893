import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const BORDER_RADIUS_VALUE = 8;

const BORDER_RADIUS_TOP = {
  MozBorderTopLeftRadius: BORDER_RADIUS_VALUE,
  WebkitBorderTopLeftRadius: BORDER_RADIUS_VALUE,
  BorderTopLeftRadius: BORDER_RADIUS_VALUE,
  MozBorderTopRightRadius: BORDER_RADIUS_VALUE,
  WebkitBorderTopRightRadius: BORDER_RADIUS_VALUE,
  BorderTopRightRadius: BORDER_RADIUS_VALUE,
};

const BORDER_RADIUS_BOTTOM = {
  MozBorderBottomLeftRadius: BORDER_RADIUS_VALUE,
  WebkitBorderBottomLeftRadius: BORDER_RADIUS_VALUE,
  BorderBottomLeftRadius: BORDER_RADIUS_VALUE,
  MozBorderBottomRightRadius: BORDER_RADIUS_VALUE,
  WebkitBorderBottomRightRadius: BORDER_RADIUS_VALUE,
  BorderBottomRightRadius: BORDER_RADIUS_VALUE,
};

const styleSheet = createStyleSheet({
  wrapper: {
    ...BORDER_RADIUS_TOP,
    ...BORDER_RADIUS_BOTTOM,
    border: `1px solid ${COLORS.BLUE_GRAY_DARKER.DEFAULT}`,
    flex: 1,
  },
  headerContainer: {
    ...BORDER_RADIUS_TOP,
    backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 51,
    padding: '0 20px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    padding: 0,
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.DEFAULT,
  },
  subtitle: {
    ...TYPOGRAPHY.BODY3,
    margin: 0,
    marginLeft: 10,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  bodyContainer: {
    ...BORDER_RADIUS_BOTTOM,
    backgroundColor: COLORS.WHITE.DEFAULT,
    padding: '16px 20px',
  },
  parameterLabel: {
    margin: 0,
    padding: 0,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  parameterInput__magic_code: {
    marginTop: 8,
    marginBottom: 12,
  },
  parameterInput__email_domain: {
    marginTop: 8,
    marginBottom: 12,
    width: 361,
  },
  parameterInput__email_list: {
    marginTop: 16,
    marginBottom: 12,
  },
  filterLabel: {
    margin: 0,
    padding: 0,
    marginTop: 12,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
});

export default styleSheet;
