/* eslint-disable react/jsx-curly-brace-presence */
// @flow

/**
 * Display a modal to select an activity
 *
 * Props:
 *  - activities: Array of object to define activity content
 *  - isLoading: Boolean that indicates if the button is loading
 *  - onClose: Call on close of the modal
 *  - visible : Set if the modal is visible
 *  - style: To override the component's style
 *  - onCreate: Callback to execute with the selected type id
 *
 */

import { memoize } from 'lodash';
import * as React from 'react';

import Enum from 'Models/Enum';

import MModal from 'Components/modal/MModal/MModal';
import UTabListItemVertical from 'Components/unit/UTabListItemVertical/UTabListItemVertical';
import UImage from 'Components/unit/UImage/UImage';

import styles from './MActivityCreationModal.style';

export type ActivityType = {|
  type: number,
  content: string,
  icon: string,
  color: string,
  title: string,
  description: string,
  picture: string,
|};

type Props = {|
  style?: Object,
  visible: boolean,
  isLoading: boolean,
  onClose: () => void,
  onSecondAction: () => void,
  activities: Array<ActivityType>,
  labelTab: string,
  labelSecondButton: string,
  labelActionButton: string,
  labelNextAction: string,
  isFirstStep: boolean,
  disableActionButton: boolean,
  title: string,
  children?: React.Node,
  onCreate: (typeId: number) => void,
|};

type State = {|
  activeTab: number,
|};

type CallbackVoidType = () => void;

export class MActivityCreationModal extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    children: null,
  };

  constructor(props: Props) {
    const { activities } = props;

    super(props);

    this.state = {
      activeTab: activities[0].type,
    };
  }

  render() {
    const {
      visible,
      style,
      onClose,
      isLoading,
      activities,
      labelSecondButton,
      labelActionButton,
      labelNextAction,
      title,
      isFirstStep,
      onSecondAction,
      disableActionButton,
    } = this.props;
    const { activeTab } = this.state;

    const wrapperStyle = !isFirstStep ? styles.wrapperModalContentNoBorders : styles.wrapperModalContent;

    const currentActivity = activities.filter(activity => activity.type === activeTab)[0];
    const halfStep = isFirstStep && (activeTab === Enum.activityTypeEnum.DAILY_SERIE);

    return (
      <div style={style}>
        <MModal
          visible={visible}
          onCloseModal={onClose}
          title={title}
          labelSecondButton={labelSecondButton}
          labelActionButton={halfStep ? labelNextAction : labelActionButton}
          onAction={this.handleCreate(currentActivity.type)}
          onSecondAction={onSecondAction}
          isActionLoading={isLoading}
          style={styles.globalModal}
          bodyStyle={styles.body}
          disableActionButton={disableActionButton}
          showExitCross={!isFirstStep}
        >
          <div style={wrapperStyle}>
            {this.renderBody(currentActivity)}
          </div>
        </MModal>
      </div>
    );
  }

  renderBody = (currentActivity: ActivityType) => {
    const { labelTab, isFirstStep, children } = this.props;

    if (!isFirstStep)
      return children;

    return (
      <>
        <div style={styles.tabPanel}>
          <span style={styles.tabText}>
            {labelTab}
          </span>
          {this.renderTabs()}
        </div>
        {this.renderContent(currentActivity)}
      </>
    );
  };

  renderContent = (currentActivity: ActivityType) => {
    const textFormatted = currentActivity.description.split('\n').map(item => (
      <React.Fragment key={item}>
        <p>
          {item}
        </p>
        <br />
      </React.Fragment>
    ));

    return (
      <div style={styles.contentPanel}>
        <div style={styles.contentText}>
          <div style={styles.title}>
            {currentActivity.title}
          </div>
          <div style={styles.description}>
            {textFormatted}
          </div>
        </div>
        <UImage
          style={styles.picture}
          src={currentActivity.picture}
        />
      </div>
    );
  };

  renderTabs = () => {
    const { activities } = this.props;
    const { activeTab } = this.state;

    return activities.map<React.Element<typeof UTabListItemVertical>>(activity => {
      const isActive = activity.type === activeTab;

      return (
        <UTabListItemVertical
          key={activity.type}
          color={activity.color}
          icon={activity.icon}
          content={activity.content}
          onClick={this.handleTabClick(activity.type)}
          isSelected={isActive}
        />
      );
    });
  };

  handleTabClick = memoize<[number], CallbackVoidType>((type: number) => () => {
    this.setState({ activeTab: type });
  });

  handleCreate = memoize<[number], CallbackVoidType>((type: number) => () => {
    const { onCreate } = this.props;

    onCreate(type);
  });
}

export default MActivityCreationModal;
