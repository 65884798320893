import React from 'react';

import SLandingPage from 'Components/structural/SLandingPage/SLandingPage';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import { getCdnUrl } from 'Libs/cdn';
import Config from 'Services/instanceConfig';

import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';

import styles from './ResetPassword.style';

const TARGET = {
  BLANK: '_blank',
  SELF: '_self',
} as const;

export const ResetPassword = () => (
  <SLandingPage instanceLogo={getCdnUrl(Config.instanceId, '/logo.png')}>
    <UTextLink text="Back to login" link="/login" target={TARGET.SELF} leftIcon="arrow-left" style={styles.back} />
    <ResetPasswordForm instanceName={Config.instanceId} />
  </SLandingPage>
);
