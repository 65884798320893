import Model from 'models/Model';

var ContributionType = {
  endpoint  : 'api/ContributionTypes',
  attributes: {
    label: {
      type: 'string',
    },
  },
};

export default Model.register('ContributionType', ContributionType);
