import { useEffect, useState } from 'react';

export type AudienceGroupListState = 'empty' | 'tutorial' | 'partial' | 'all';

type AudienceGroupListStateArgs = {
  total: number;
  offset: number;
  noContent: boolean | null;
};

export const useAudienceGroupListState = ({
  total,
  offset,
  noContent,
}: AudienceGroupListStateArgs) => {
  const [audienceGroupListState, setState] = useState<AudienceGroupListState>('empty');

  useEffect(() => {
    if (total === 0) {
      return setState('empty');
    }
    if (noContent) {
      return setState('tutorial');
    }
    if (offset < total) {
      return setState('partial');
    }
    return setState('all');
  }, [noContent, offset, total]);

  return { audienceGroupListState };
};
