// @flow

import React from 'react';
import { t } from 'i18next';

import SValidityCheckListItem from 'Components/structural/SValidityCheckListItem/SValidityCheckListItem';

import {
  getCampaignInfoErrors,
  getSchedulingErrors,
  getAudienceErrors,
  useDefaultActivity,
  useModuleSelector,
} from '../../../redux';
import { SchedulingValidityCallout } from '../SchedulingValidityCallout/SchedulingValidityCallout';

import styles from './DailySerieValidityCheckList.style';

export const DailySerieValidityCheckList = () => {
  const campaignInfoErrors = useModuleSelector(getCampaignInfoErrors);
  const schedulingErrors = useModuleSelector(getSchedulingErrors);
  const audienceErrors = useModuleSelector(getAudienceErrors);

  const defaultActivity = useDefaultActivity();
  const schedulingIsEmpty = defaultActivity.dailySerie.days.length === 1
    && !defaultActivity.dailySerie.days[0].dailySerieContents.length;

  return (
    <div>
      <div style={styles.title}>
        {t('activities:overview.title')}
      </div>
      <div style={styles.comment}>
        {t('activities:overview.description')}
      </div>
      <div style={styles.listItem}>
        <SValidityCheckListItem
          title={t('activities:overview.campaign_validity_title')}
          description={t('activities:overview.campaign_validity_label')}
          isActive={!campaignInfoErrors.length}
        />
      </div>
      <div style={styles.listItem}>
        <SValidityCheckListItem
          title={t('activities:overview.scheduling_validity_title')}
          description={t('activities:overview.scheduling_validity_label')}
          isActive={!schedulingIsEmpty && !schedulingErrors.length}
        />
      </div>
      {!schedulingIsEmpty && schedulingErrors.length ? (
        <div style={styles.calloutContainer}>
          {schedulingErrors.map(({ languageId, reason }) =>
            <SchedulingValidityCallout key={languageId} languageId={languageId} reason={reason} />)}
        </div>
      ) : null}
      <div style={styles.listItem}>
        <SValidityCheckListItem
          title={t('activities:overview.audience_validity_title')}
          description={t('activities:overview.audience_validity_label')}
          isActive={!audienceErrors.length}
        />
      </div>
    </div>
  );
};
