import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: COLORS.WHITE.DEFAULT,
    borderRadius: '4px',
    minWidth: '250px',
    padding: '32px 30px',
  },
  title: {
    ...TYPOGRAPHY.H1,
    marginBottom: 28,
  },
  subtitle: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 24,
  },
});

export default styleSheet;
