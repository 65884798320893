import m from 'm';
var component = {};
var DEFAULT_RATIO = 1.60;
var HUNDRED = 100;

/*
 * ARGS:
 * - ratio:  You can give it a custom screen ratio (default: 1.6)
 */

component.controller = function controller(args) {
  var self = this;

  self.configSize = function configSize(elem, isInit, ctx) {
    if (isInit)
      return;

    window.addEventListener('resize', resize, false);
    ctx.onunload = function removeListeners() {
      window.removeEventListener('resize', resize, false);
    };
    resize();

    function resize() {
      var height = elem.offsetHeight;
      var ratio = args.ratio || DEFAULT_RATIO;

      elem.style.width = (height / ratio) + 'px';
      elem.style.fontSize = (height / HUNDRED) + 'px';
    }
  };
};

component.view = function view(c, args, body) {
  return m('.device-screen', {
    config: c.configSize,
  }, body);
};

export default component;
