/**
 * A card that displays the preview of a course
 *
 * Props:
 *  - id: number representing the id of the course
 *  - title: string to set as title of the card
 *  - status: status of the course ('published' | 'pending' | 'unpublished' | 'deleted')
 *  - size: size of the card ('S' | 'M')
 *  - language: string representing the flag to display cf language of the course, must be in countryCode format (ex 'fr')
 *  - onClick: function triggered when the card is clicked
 *  - onRestore: function triggered when the status is deleted and user wants to restore the card
 *  - image: string to set the course image, if not provided a placeholder will be displayed
 *  - isStatic: boolean, if set to true, interactions are not possible (hover, click...)
 *  - style: to override component's style
 */

import React, { useState, useCallback, useMemo } from 'react';
import { css } from 'aphrodite';
import { t } from 'i18next';

import type { CSSProperties } from 'react';

import { COLORS, ELEVATIONS } from 'Components/foundation';

import UIllustration from 'Components/unit/UIllustration/UIllustration';
import UFlag from 'Components/unit/UFlag/UFlag';
import UStatusIndicator from 'Components/unit/UStatusIndicator/UStatusIndicator';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import UImage from 'Components/unit/UImage/UImage';
import SOptionsMenu from 'Components/structural/SOptionsMenu/SOptionsMenu';

import { styles, extendedStyles, OPTIONS_MENU_LEFT_OFFSET } from './SCourseCard.style';

export type CourseStatus = 'published' | 'pending' | 'unpublished' | 'deleted';
export type CourseCardSize = 'S' | 'M';
export type CourseAction = 'delete' | 'duplicate' | 'publish' | 'rename' | 'unpublish' | 'update';

type SCourseCardPropsBase = {
  id: number;
  title: string;
  language: string;
  onAction?: (courseId: number, action: CourseAction) => void;
  onClick?: (id: number) => void;
  onRestore?: (id: number) => void;
  image?: string;
  isStatic?: boolean;
  style?: CSSProperties;
  showStatusIndicator?: boolean;
};

export type SCourseCardPropsDeleted = SCourseCardPropsBase & {
  status: 'deleted';
  size: 'S';
};

export type SCourseCardPropsNotDeleted = SCourseCardPropsBase & {
  status: 'published' | 'pending' | 'unpublished';
  size: CourseCardSize;
};

export type SCourseCardProps = SCourseCardPropsNotDeleted | SCourseCardPropsDeleted;

const ILLUSTRATION_SIZE = {
  S: {
    width: 48,
    height: 51,
  },
  M: {
    width: 67,
    height: 72,
  },
};

const ILLUSTRATION_COLORS = {
  DEFAULT: {
    DEFAULT: COLORS.PRIMARY_LIGHT.OUTLINE,
    MEDIUM: COLORS.PRIMARY.LIGHT,
  },
  DELETED: {
    DEFAULT: COLORS.GREY_DARK.OUTLINE_FOCUSED,
    MEDIUM: COLORS.GREY_DARK.DEFAULT,
  },
};

export const SCourseCard = ({
  id,
  title,
  status,
  size,
  language,
  onClick,
  onRestore,
  image,
  isStatic = false,
  style = {},
  onAction = () => {},
  showStatusIndicator = true,
}: SCourseCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleHover = useCallback(() => {
    if (!isStatic) {
      setIsHover(true);
    }
  }, [isStatic]);

  const handleLeave = useCallback(() => setIsHover(false), []);

  const handleClick = useCallback(() => {
    if (!isStatic && onClick) {
      onClick(id);
    }
  }, [onClick, id, isStatic]);

  const handleRestore = useCallback(() => {
    if (onRestore) {
      onRestore(id);
    }
  }, [id, onRestore]);

  const statusIndicatorInfo = useMemo(
    () =>
      ({
        published: {
          showStatusIndicator,
          statusIndicatorType: 'valid',
          statusIndicatorText: t('courses:course_card.published'),
          statusIndicatorColor: '',
        },
        pending: {
          showStatusIndicator,
          statusIndicatorType: 'warning',
          statusIndicatorText: t('courses:course_card.pending_update'),
          statusIndicatorColor: '',
        },
        unpublished: {
          showStatusIndicator: showStatusIndicator && isHover,
          statusIndicatorType: 'invalid',
          statusIndicatorText: t('courses:course_card.unpublished'),
          statusIndicatorColor: '',
        },
        deleted: {
          showStatusIndicator: false,
        },
      } as const),
    [isHover, showStatusIndicator],
  );

  const makeOnClick = useCallback(
    (courseAction: CourseAction) => () => {
      onAction(id, courseAction);
    },
    [onAction, id],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renameOptionsInfos = useMemo(
    () =>
      ({
        id: 'rename',
        onClick: makeOnClick('rename'),
        text: 'Rename',
        icon: 'edit',
        mode: 'standard',
      } as const),
    [makeOnClick],
  );

  const duplicateOptionsInfos = useMemo(
    () =>
      ({
        id: 'duplicate',
        onClick: makeOnClick('duplicate'),
        text: t('courses:course_card.duplicate'),
        icon: 'copy',
        mode: 'standard',
      } as const),
    [makeOnClick],
  );

  const publishOptionsInfos = useMemo(
    () =>
      ({
        id: 'publish',
        onClick: makeOnClick('publish'),
        text: t('courses:course_card.publish'),
        icon: 'success-circle',
        mode: 'accentuated',
      } as const),
    [makeOnClick],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteOptionsInfos = useMemo(
    () =>
      ({
        id: 'delete',
        onClick: makeOnClick('delete'),
        text: t('courses:course_card.delete'),
        icon: 'delete',
        mode: 'destructive',
      } as const),
    [makeOnClick],
  );

  const unpublishOptionsInfos = useMemo(
    () =>
      ({
        id: 'unpublish',
        onClick: makeOnClick('unpublish'),
        text: t('courses:course_card.unpublish'),
        icon: 'error',
        mode: 'warning',
      } as const),
    [makeOnClick],
  );

  const updateOptionsInfos = useMemo(
    () =>
      ({
        id: 'update',
        onClick: makeOnClick('update'),
        text: t('courses:course_card.update'),
        icon: 'success-circle',
        mode: 'accentuated',
      } as const),
    [makeOnClick],
  );

  const optionMenuInfo = useMemo(
    () => ({
      published: [
        duplicateOptionsInfos,
        unpublishOptionsInfos,
        // deleteOptionsInfos,
      ],
      pending: [
        duplicateOptionsInfos,
        updateOptionsInfos,
        // deleteOptionsInfos,
      ],
      unpublished: [
        // renameOptionsInfos,
        duplicateOptionsInfos,
        publishOptionsInfos,
        // deleteOptionsInfos,
      ],
    }),
    [unpublishOptionsInfos, updateOptionsInfos, publishOptionsInfos],
  );

  const statusColor = useMemo(
    () => (status === 'deleted' ? ILLUSTRATION_COLORS.DELETED : ILLUSTRATION_COLORS.DEFAULT),
    [status],
  );

  const titleColorStyle = useMemo(
    (): CSSProperties =>
      status === 'deleted' ? { color: COLORS.GREY_DARK.OUTLINE_DISABLED } : { color: COLORS.TEXT.DEFAULT },
    [status],
  );

  const statusIndicatorStyle = useMemo(
    (): CSSProperties =>
      status === 'unpublished' && isHover
        ? { backgroundColor: COLORS.WHITE.DEFAULT, boxShadow: ELEVATIONS.LIGHT_LOW }
        : {},
    [isHover, status],
  );

  const leftContainerStyle = useMemo((): CSSProperties => {
    if (status === 'deleted') {
      return {
        ...styles.leftContainer,
        ...styles[`leftContainer${size}`],
        ...styles.deletedBackground,
      };
    }

    const backgroundColor = isHover ? COLORS.WHITE.DEFAULT : COLORS.PRIMARY.LIGHTER;

    return {
      ...styles.leftContainer,
      ...styles[`leftContainer${size}`],
      backgroundColor,
    };
  }, [isHover, status, size]);

  const rightContainerStyle = useMemo((): CSSProperties => {
    const backgroundColor = isHover && status !== 'deleted' ? COLORS.PRIMARY.LIGHTER : COLORS.WHITE.DEFAULT;

    return status === 'deleted'
      ? {
          ...styles.rightContainer,
          ...styles.deletedRightContainer,
          ...styles[`rightContainer${size}`],
          backgroundColor,
        }
      : {
          ...styles.rightContainer,
          ...styles[`rightContainer${size}`],
          backgroundColor,
        };
  }, [isHover, status, size]);

  const aphroditeStyle = useMemo(
    (): string | undefined => (status !== 'deleted' && !isStatic ? css(extendedStyles.wrapper) : undefined),
    [status, isStatic],
  );

  const wrapperStyle = useMemo(
    () =>
      status === 'deleted'
        ? {
            ...styles.deletedWrapper,
            ...styles[`wrapper${size}`],
            ...style,
          }
        : {
            ...styles.wrapper,
            ...styles[`wrapper${size}`],
            ...style,
          },
    [size, status, style],
  );

  const imageStyle = useMemo(
    () => (status === 'deleted' ? { ...styles.courseImg, ...styles.deletedCourseImg } : styles.courseImg),
    [status],
  );

  return (
    <div
      style={wrapperStyle}
      className={aphroditeStyle}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      onClick={handleClick}
    >
      <div style={leftContainerStyle}>
        {image ? (
          <UImage
            src={image}
            transformation={{
              height: (wrapperStyle.height as number) * 4,
              width: (leftContainerStyle.width as number) * 4,
            }}
            style={imageStyle}
            alt="Course cover"
          />
        ) : (
          <UIllustration
            illustration="course"
            height={ILLUSTRATION_SIZE[size].height}
            width={ILLUSTRATION_SIZE[size].width}
            color={statusColor}
          />
        )}
      </div>
      <div style={rightContainerStyle}>
        <div style={styles.baseWrapper}>
          {status !== 'deleted' && <UFlag countryCode={language} size={size === 'S' ? 13 : 16} />}
          {status !== 'deleted' && statusIndicatorInfo[status].showStatusIndicator && (
            <UStatusIndicator
              hideText={!isHover}
              size={size === 'S' ? 'medium' : 'large'}
              type={statusIndicatorInfo[status].statusIndicatorType}
              text={statusIndicatorInfo[status].statusIndicatorText}
              style={statusIndicatorStyle}
              containerStyle={styles.statusIndicatorContainer}
            />
          )}
          {isHover && status !== 'deleted' && (
            <SOptionsMenu
              size={size === 'S' ? 'M' : 'L'}
              options={optionMenuInfo[status]}
              style={{ ...styles.optionsMenu, ...OPTIONS_MENU_LEFT_OFFSET[size] }}
            />
          )}
          {status === 'deleted' && (
            <UTextLink
              text="Restore"
              type="accentuated"
              leftIcon="restore"
              iconSize="medium"
              marginLeft=""
              marginRight=""
              style={styles.optionsWrapperDeleted}
              onClick={handleRestore}
            />
          )}
        </div>
        <p style={{ ...styles.title, ...styles[`title${size}`], ...titleColorStyle }}>{title}</p>
      </div>
    </div>
  );
};

export default SCourseCard;
