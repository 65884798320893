// @flow

/**
 * Displays the logo of the client
 *
 * Props:
 * - style: For overriding the component's style
 * - height: For the img height
 * - maxWidth: For the img maximum width
 * - onClick: Function called when we click on the button
 * - logoSrc: URL of the client logo
 */

import * as React from 'react';

import UImage from 'Components/unit/UImage/UImage';

import styles from './UClientLogo.style';

export type Props = {|
  style: Object,
  height: number,
  maxWidth: number,
  onClick: Function,
  logoSrc: string,
|};

export const UClientLogo = ({ style, height, maxWidth, onClick, logoSrc }: Props) => {

  return (
    <div
      style={{ ...styles.wrapper, ...style }}
      onClick={onClick}
    >
      <UImage style={{ ...styles.img, height, maxWidth }} src={logoSrc} />
    </div>
  );
};

UClientLogo.defaultProps = {
  height : 34,
  maxWidth: 280,
  style: {},
};

export default UClientLogo;
