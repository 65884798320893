import Request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';
import App from 'models/App';

var Thematic = {
  endpoint  : 'api/Thematics',
  attributes: {
    name: {
      type: 'string',
    },
    ancestorId: {
      type      : 'number',
      defaultsTo: null,
    },
    level: {
      type: 'number',
    },
    icon: {
      type: 'string',
    },
    available: {
      type: 'boolean',
    },
    archived: {
      type      : 'boolean',
      defaultsTo: false,
    },
    polyglotLabel: {
      type : 'pointer',
      Model: 'PolyglotLabel',
    },
    polyglotLabelId: {
      type: 'number',
    },
  },
  methods: {
    save: function save(_Thematic) {
      var promise;

      if (!this.id())
        promise = Request.post('api/Thematics/full/', this.toJSON());
      else
        promise = Request.put('api/Thematics/full/' + this.id(), this.toJSON());

      return promise.then(function returnEntity(res) {
        return new _Thematic(res);
      });
    },
    getLabel: function getLabel(_Thematic, languageId) {
      if (!languageId)
        languageId = App.defaultLanguage().id();

      return this.polyglotLabel().getLabel(languageId);
    },
    archive: function archive(_Thematic, thematic) {
      thematic.archived(true);

      return thematic.save();
    },
  },
  classMethods: {
    findValid: findValid,
  },
};

function findValid(_Thematic) {
  return Request
    .get(Thematic.endpoint + '/valid')
    .then(function instanciate(res) {
      return new Collection(_Thematic, res);
    });
}

export default Model.register('Thematic', Thematic);
