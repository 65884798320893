// @flow

import React, { useMemo } from 'react';
import { t } from 'i18next';

import STooltip from 'Components/structural/STooltip/STooltip';
import UButton from 'Components/unit/UButton/UButton';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './NPSHeader.style';

type Props = {|
  onDownloadReportClick: Function,
  nbAnswers: number,
  downloadButtonDisabled: boolean,
  nbPlayersExposed: number | null,
|};

export const NPSHeader = ({ onDownloadReportClick, nbAnswers, downloadButtonDisabled, nbPlayersExposed }: Props) => {
  const formatedNbAnswers = nbAnswers.toLocaleString('en');
  const formatedNbPlayersExposed = nbPlayersExposed?.toLocaleString('en');

  const npsSubtitle = useMemo(() => {
    return formatedNbPlayersExposed
      ? t('analytics:nps.header.subtitle_with_count', {
          number1: formatedNbAnswers,
          number2: formatedNbPlayersExposed,
        })
      : t('analytics:nps.header.subtitle_without_count', { number: nbAnswers });
  }, [formatedNbAnswers, formatedNbPlayersExposed, nbAnswers, t]);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>{t('analytics:nps.header.styles_title')}</div>
        <div style={styles.subtitle} data-testid="NPS_SUBTITLE">
          {npsSubtitle}
          {formatedNbPlayersExposed && (
            <>
              <div data-for="nb-answers-players-exposed-tooltip" data-tip style={styles.iconWrapper}>
                <STooltip
                  id="nb-answers-players-exposed-tooltip"
                  position="right"
                  content={t('analytics:nps.header.number_of_players_tooltip')}
                  maxWidth={255}
                />
                <UIcon name="info" size={12} />
              </div>
            </>
          )}
        </div>
      </div>
      <div style={styles.summary}>
        <div style={styles.instruction}>
          <div style={styles.instructionText}>{t('analytics:nps.header.instruction_text')}</div>
          <div style={styles.instructionText}>{t('analytics:nps.header.instruction_text_secondline')}</div>
        </div>
        <div data-for="download-report-tooltip" data-tip>
          {downloadButtonDisabled && (
            <STooltip
              id="download-report-tooltip"
              position="top"
              content={t('analytics:nps.header.not_enough_players')}
              // Ensure the text is on one line
              maxWidth={1000}
            />
          )}
          <UButton
            text={t('analytics:nps.header.download_nps_report')}
            type="accentuated"
            leftIcon="download"
            disabled={downloadButtonDisabled}
            onClick={onDownloadReportClick}
            trackingAction="export"
            trackingContext="nps"
          />
        </div>
      </div>
    </div>
  );
};
