// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  modalStyle: {
    content: {
      padding: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: '0 auto',
      border: 'none',
      backgroundColor: COLORS.WHITE.DEFAULT,
      height: '100%',
    },
    overlay: {
      position: 'fixed',
      zIndex: 100000,
    },
    margin: 0,
    padding: 0,
  },
  bodyContainer: {
    display: 'flex',
    height: '100%',
  },
  informationPanel: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 30,
    paddingRight: 32,
    width: 316,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    marginTop: 36,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...TYPOGRAPHY.H1,
    color: COLORS.TEXT.DEFAULT,
    fontWeight: 'bold',
  },
  subtitle: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 4,
  },
  qrCodeContainer: {
    marginTop: 50,
  },
  qrCode: {
    width: 177,
  },
  previewPanel: {
    flexGrow: 1,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneFrameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  refreshButton: {
    marginTop: 20,
  },
};
