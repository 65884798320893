import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import SDisplayControlItems from 'Components/structural/SDisplayControlItems/SDisplayControlItems';
import SPageHeader from 'Components/structural/SPageHeader/SPageHeader';
import type { IconPropsType } from 'Components/structural/SPageHeader/SPageHeader';
import UFilterSelect from 'Components/unit/UFilterSelect/UFilterSelect';
import type { USecondaryTabLabelProps } from 'Components/unit/USecondaryTabLabel/USecondaryTabLabel';
import type { Filter } from 'Libs/filter/types';
import { State } from 'Libs/redux/reducer';
import { useTypedSelector } from 'Libs/redux/utils';

import { EntityType } from '../types';
import styleSheet from './AudienceGroupListHeader.style';

const ENTITY_NB_PLAYERS_TARGETED_MAP: Record<EntityType, (state: State) => number | null> = {
  course: (state) => state.pages.courses.edition.numberTargetedPlayers,
};

export type AudienceGroupListHeaderProps = {
  entityType: EntityType;
  createButtonProps: IconPropsType;
  nameFilterValue: string;
  statusFilterProps: USecondaryTabLabelProps[];
  sortValue: Filter;
  handleFilterName: (value: string) => void;
  handleSort: (filter: Filter) => void;
};

export const AudienceGroupListHeader: FunctionComponent<AudienceGroupListHeaderProps> = ({
  entityType,
  createButtonProps,
  nameFilterValue,
  statusFilterProps,
  sortValue,
  handleFilterName,
  handleSort,
}) => {
  const numberPlayersTargeted = useTypedSelector<number | null>(ENTITY_NB_PLAYERS_TARGETED_MAP[entityType]);

  const { t } = useTranslation();

  return (
    <>
      <SPageHeader
        title={t('audiences:audience_list.title')}
        iconProps={createButtonProps}
        subtitle={t('audiences:audience_list.course_targeting_players', { count: numberPlayersTargeted || 0 })}
        // dynamicText={dynamicText}
        subtitleSize="L"
        horizontal
      />
      <SDisplayControlItems
        filterValue={nameFilterValue}
        filterPlaceholder={t('audiences:audience_list.research_course')}
        onFilterChange={handleFilterName}
        inputOpened={Boolean(nameFilterValue && nameFilterValue.length > 0)}
        tabs={statusFilterProps}
      />
      <UFilterSelect
        allowNoChoice={false}
        filter={sortValue}
        onFilterChanged={handleSort}
        style={styleSheet.filterSelect}
      />
    </>
  );
};
