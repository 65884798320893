import React, { useMemo, useCallback } from 'react';

import { COLORS } from 'Components/foundation';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';
import SMicroKnowledgeListItem from 'Components/structural/SMicroKnowledgeListItem/SMicroKnowledgeListItem/SMicroKnowledgeListItem';
import UIconButton from 'Components/unit/UIconButton/UIconButton';

import styles from './SChapterMicroKnowledgeItem.style';
import { MicroKnowledgeItemSharedProps } from '../types/shared-props';
import { t } from 'i18next';

const CHAPTER_COLOR = COLORS.PRIMARY_LIGHT.DEFAULT;

export type SChapterMicroKnowledgeItemProps = MicroKnowledgeItemSharedProps & {
  onToggleCollapse?: (id: string) => void;
  isCollapsed?: boolean;
  collapsable: boolean;
};

/*
 * SChapterMicroKnowledgeItem:
 * Props:
 * - id: number to distinguish one Chapter from another
 * - title: The title of the chapter
 * - onTitleChange: callback to modify the title
 * - onAdd: (The plus icon) callback to add a new SChapter or a SText
 * - onToggleCollapse: (The chevron icon) callback to modify the chapter's chevron
 * - isCollapsed: boolean which states whether the chapter's children are collapsed or not
 * - collapsable: boolean which states whether the component is collapsable or not
 * - style: to modify the component's style
 * - options: The options which the SOptionsMenu displays
 */

export const SChapterMicroKnowledgeItem = ({
  id,
  title,
  onTitleChange,
  onToggleCollapse,
  onAdd,
  isCollapsed,
  collapsable,
  style,
  options,
}: SChapterMicroKnowledgeItemProps) => {
  const chevronStyle = useMemo(() => {
    if (collapsable) {
      return isCollapsed ? styles.chevron : styles.chevronRotated;
    }

    return styles.noChevron;
  }, [collapsable, isCollapsed]);

  const handleToggle = useCallback(() => onToggleCollapse?.(id), [onToggleCollapse, id]);
  const handleAdd = useCallback(() => onAdd?.(id), [onAdd, id]);
  const handleTitleChange = useCallback((newValue: string) => onTitleChange?.(id, newValue), [onTitleChange, id]);

  return (
    <SMicroKnowledgeListItem
      id={id}
      contextMenuOptions={options}
      backgroundColor={CHAPTER_COLOR}
      style={style}
      onAdd={handleAdd}
    >
      <SSubtleInput
        inputType="input"
        style={styles.sSubtleInput}
        value={title}
        placeholder={t('courses:course_edition.type_chapter')}
        maxLength={40}
        minWidth={150}
        maxWidth="30%"
        onChange={handleTitleChange}
      />
      <UIconButton
        icon="bottom-chevron"
        size="M"
        style={chevronStyle}
        ghost
        onClick={handleToggle}
        isTriggeredOnMouseDown
      />
    </SMicroKnowledgeListItem>
  );
};

export default SChapterMicroKnowledgeItem;
