import { mergeDeep } from 'Libs/mergeDeep';

export type GameItemType = Readonly<{
  id?: number;
  data: string;
  typeId: number;
  knowledgeId: number;
}>;

const defaultState: GameItemType = {
  id: undefined,
  data: '',
  typeId: 0,
  knowledgeId: 0,
};

export default function createGameItem(values?: any): GameItemType {
  if (!values) {
    return defaultState;
  }

  const gameItem: GameItemType = {
    id: values.id,
    data: values.data,
    typeId: values.typeId,
    knowledgeId: values.knowledgeId,
  };

  return mergeDeep(defaultState, gameItem);
}
