/**
 * Display two buttons next to each other to publish/update a course or unpublish it
 *
 * Props:
 * - courseId: The id of the course
 * - showPublish: To show the publish button or not
 * - showUnpublish: To show the unpublish button or not
 * - publishDisabled: Disable the publish button
 * - unpublishDisabled: Disable the unpublish button
 * - onPublish: Callback when clicking the publish button
 * - onPublishEnd: Callback when the publish action is done
 * - onUnpublish Callback when clicking the unpublish button
 * - onUnpublishEnd: Callback when the unpublish action is done
 */
import React, { useCallback, useMemo, useState } from 'react';
import type { FunctionComponent, CSSProperties } from 'react';
import { t } from 'i18next';

import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';

import styles from './SPublicationButtons.style';

type SPublicationButtonsAction = 'publish' | 'unpublish';
type SPublicationButtonsCallback = {
  publish: () => Promise<void>;
  unpublish: () => Promise<void>;
};
type SPublicationButtonsLoadingState = Record<SPublicationButtonsAction, boolean>;

export type SPublicationButtonsProps = {
  style?: CSSProperties;
  courseId: number;
  showPublish: boolean;
  showUnpublish: boolean;
  publishDisabled: boolean;
  unpublishDisabled: boolean;
  onPublish: (courseId: number) => Promise<void>;
  onPublishEnd?: () => Promise<void>;
  onUnpublish: (courseId: number) => Promise<void>;
  onUnpublishEnd?: () => Promise<void>;
};

export const SPublicationButtons: FunctionComponent<SPublicationButtonsProps> = ({
  courseId,
  showPublish,
  showUnpublish,
  publishDisabled,
  unpublishDisabled,
  onPublish,
  onUnpublish,
  onPublishEnd = () => Promise.resolve(),
  onUnpublishEnd = () => Promise.resolve(),
  style = {},
}) => {
  const [loading, setLoading] = useState<SPublicationButtonsLoadingState>({
    publish: false,
    unpublish: false,
  });

  const shouldExecuteHandler = useCallback(
    (action: SPublicationButtonsAction) => {
      const whileUnpublishing = action === 'publish' && loading.unpublish;
      const whilePublishing = action === 'unpublish' && loading.publish;

      /**
       * If we click on unpublish and the publish is already loading
       * or vice versa, don't execute the function
       */
      return !whilePublishing && !whileUnpublishing;
    },
    [loading],
  );

  const handleClick = useCallback(
    (action: SPublicationButtonsAction, isLoading: boolean, callbacks: SPublicationButtonsCallback) => {
      if (!shouldExecuteHandler(action)) {
        return Promise.resolve();
      }

      setLoading((prev) => ({
        ...prev,
        [action]: isLoading,
      }));

      const { publish, unpublish } = callbacks;

      return action === 'publish' ? publish() : unpublish();
    },
    [shouldExecuteHandler],
  );

  const handleAction = useCallback(
    (action: SPublicationButtonsAction) =>
      handleClick(action, true, {
        publish: () => onPublish(courseId),
        unpublish: () => onUnpublish(courseId),
      }),
    [onPublish, onUnpublish, handleClick, courseId],
  );

  const handleRequestEnd = useCallback(
    (action: SPublicationButtonsAction) =>
      handleClick(action, false, {
        publish: onPublishEnd,
        unpublish: onUnpublishEnd,
      }),
    [onPublishEnd, onUnpublishEnd, handleClick],
  );

  const handlePublish = useCallback(() => handleAction('publish'), [handleAction]);
  const handleUnpublish = useCallback(() => handleAction('unpublish'), [handleAction]);

  const handlePublishEnd = useCallback(() => handleRequestEnd('publish'), [handleRequestEnd]);
  const handleUnpublishEnd = useCallback(() => handleRequestEnd('unpublish'), [handleRequestEnd]);

  const wrapperStyle = useMemo(
    () => ({
      ...styles.wrapper,
      ...style,
    }),
    [style],
  );

  const publicationText = useMemo(
    () =>
      showPublish && showUnpublish
        ? t('structural_component:s_publication_button.update')
        : t('structural_component:s_publication_button.publish'),
    [showPublish, showUnpublish],
  );

  const publishButtonStyle = useMemo(
    () => (showPublish && showUnpublish ? { marginLeft: '5px' } : {}),
    [showPublish, showUnpublish],
  );

  const unpublishButtonStyle = useMemo(
    () => (showPublish && showUnpublish ? { marginRight: '5px' } : {}),
    [showPublish, showUnpublish],
  );

  return (
    <div style={wrapperStyle}>
      {showUnpublish && (
        <UButtonWithLoading
          text={t('structural_component:s_publication_button.unpublish')}
          type="standard"
          disabled={unpublishDisabled}
          onClick={handleUnpublish}
          onRequestEnd={handleUnpublishEnd}
          style={unpublishButtonStyle}
          ghost
        />
      )}
      {showPublish && (
        <UButtonWithLoading
          text={publicationText}
          type="accentuated"
          onClick={handlePublish}
          onRequestEnd={handlePublishEnd}
          disabled={publishDisabled}
          style={publishButtonStyle}
        />
      )}
    </div>
  );
};

export default SPublicationButtons;
