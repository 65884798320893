import { createSlice } from '@reduxjs/toolkit';

import { mergeDeep } from 'Libs/mergeDeep';

import {
  Queue,
  QueueItem,
  QueueState,
  FlushAction,
  UpdateStatusAction,
  UpdateItemAction,
  PushAction,
  PopAction,
} from './types';

export enum QueueStatus {
  IDLE = 'idle',
  BUSY = 'busy',
  STOPPED = 'stopped',
}

export enum QueueContexts {
  COURSE_EDITION = 'course-edition',
}

const initQueue = (): Queue => ({
  queue: [],
  status: QueueStatus.STOPPED,
});

export const defaultState: QueueState = {
  [QueueContexts.COURSE_EDITION]: initQueue(),
};

const slice = createSlice({
  name: 'QUEUE',
  initialState: defaultState,
  reducers: {
    flush: (state, { payload: { context } }: FlushAction) => mergeDeep(state, { [context]: initQueue() }),
    updateStatus: (state, { payload: { context, status } }: UpdateStatusAction) => mergeDeep(state, { [context]: { status } }),
    updateItem: (state, { payload: { context, item, index = 0 } }: UpdateItemAction) => {
      const { queue } = state[context];

      const newItem = mergeDeep<QueueItem<any>>(queue[index], item);
      const newQueue = [...queue.slice(0, index), newItem, ...queue.slice(index + 1)];

      return mergeDeep(state, { [context]: { queue: newQueue } });
    },
    pop: (state, { payload: { context } }: PopAction) => {
      const currentQueue = state[context];
      const [, ...newQueue] = currentQueue.queue;

      return mergeDeep(state, {
        [context]: {
          queue: newQueue,
        },
      });
    },
    push: (state, { payload: { context, item } }: PushAction) => mergeDeep(state, {
      [context]: {
        queue: [...state[context].queue, item],
      },
    }),
  },
});

export const { reducer, actions } = slice;
