import { StyleSheet } from 'aphrodite';

import { ANIMATIONS, COLORS, ELEVATIONS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default StyleSheet.create({
  // Wrappers
  wrapper: {
    position: 'relative',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  wrapperDefault: {
    backgroundColor: COLORS.GREY_DARK.DEFAULT,
    borderRadius: 7.5,
    width: 29,
    height: 15,
    transition: ANIMATIONS.SPEED.VERY_FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
  },
  wrapperLarge: {
    backgroundColor: COLORS.GREY_DARK.DEFAULT,
    borderRadius: 17,
    width: 110,
    height: 32,
    transition: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
  },
  wrapperDisabled: {
    backgroundColor: COLORS.GREY_DARK.DISABLED,
    cursor: 'default',
  },
  wrapperNotCickable: {
    cursor: 'not-allowed',
  },

  // Circles
  circle: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  circleDefault: {
    width: 11,
    height: 11,
    transition: ANIMATIONS.SPEED.VERY_FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
    left: 2,
    top: 2,
  },
  circleLarge: {
    width: 26,
    height: 26,
    transition: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
    boxShadow: ELEVATIONS.LIGHT_LOW,
    left: 3,
    top: 3,
  },
  defaultCircleDisabled: {
    backgroundColor: COLORS.GREY_DARK.DEFAULT,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  largeCircleDisabled: {
    boxShadow: 'unset',
  },

  // Toggle
  toggledWrapperDefault: {
    backgroundColor: COLORS.BLACK.DEFAULT,
    transition: ANIMATIONS.SPEED.VERY_FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
  },
  toggledWrapperLarge: {
    backgroundColor: COLORS.SUCCESS.DEFAULT,
    transition: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
  },
  toggledCircleDefault: {
    transform: 'translateX(14px)',
    transition: ANIMATIONS.SPEED.VERY_FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
    right: 2,
  },
  toggledCircleLarge: {
    transform: 'translateX(78px)',
    transition: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.SPEED.NORMAL,
    right: 3,
  },
  defaultToggleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelStyle: {
    marginLeft: 4,
  },
  withIcon: {
    marginLeft: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
  default: {
    cursor: 'default',
  },

  // Label
  label: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.WHITE.DEFAULT,
    fontWeight: 700,
    position: 'absolute',
    zIndex: LEVELS.LOCAL_OFFSET,
    width: 110,
    height: 32,
  },
  labelContentWrapper: {
    position: 'absolute',
    width: 75,
    height: 32,
  },
  toggledLabel: {
    left: 6,
  },
  unToggledLabel: {
    right: 6,
  },
  labelContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  labelDisabled: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },

  // Icon
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 14,
    height: 14,
  },

  // Animation
  fadeIn: {
    opacity: 1,
    transitionProperty: 'opacity',
    transitionDuration: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },
  fadeOut: {
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: ANIMATIONS.SPEED.FAST,
    transitionTimingFunction: ANIMATIONS.EASING.STANDARD,
  },

  // None
  none: {},
});
