import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const KnowledgeIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.1828 13.3018L11.9246 17.9319C8.8759 18.4694 6.84021 21.3767 7.37778 24.4254L15.2962 69.3329C15.8337 72.3816 18.741 74.4172 21.7897 73.8797L48.0479 69.2496C51.0966 68.7121 53.1323 65.8048 52.5947 62.7561L44.6763 17.8487C44.1388 14.8 41.2315 12.7643 38.1828 13.3018Z"
      fill={color.DEFAULT}
    />
    <path
      d="M32.8799 5.8644L25.3331 48.6645C24.8863 51.1982 26.5782 53.6144 29.1119 54.0611L55.3701 58.6912C57.9038 59.1379 60.32 57.4461 60.7667 54.9124L68.3136 12.1123C68.7603 9.57857 67.0685 7.16239 64.5348 6.71563L38.2766 2.0856C35.7429 1.63884 33.3267 3.33067 32.8799 5.8644Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      d="M70.2097 12.328C70.5068 10.6181 70.1133 8.86016 69.1157 7.44004C68.118 6.01992 66.5978 5.05361 64.8884 4.75318L38.6323 0.123097C36.9238 -0.174165 35.1671 0.218019 33.7473 1.2137C32.3274 2.20938 31.3602 3.72734 31.0575 5.43492L23.4827 48.4786C23.1881 50.1868 23.5813 51.9422 24.5765 53.3615C25.5718 54.7807 27.0882 55.7485 28.7946 56.0534L55.0602 60.6835C56.7701 60.9806 58.528 60.5871 59.9481 59.5895C61.3682 58.5918 62.3345 57.0715 62.6349 55.3622L70.2097 12.328ZM25.3291 48.668L32.9039 5.87047C33.008 5.26663 33.2304 4.68932 33.5583 4.17173C33.8863 3.65414 34.3133 3.20649 34.8149 2.85452C35.3164 2.50254 35.8826 2.25319 36.4809 2.1208C37.0791 1.98841 37.6977 1.97558 38.3009 2.08307L64.5286 6.72263C65.1319 6.82786 65.7085 7.05101 66.2255 7.3793C66.7424 7.7076 67.1896 8.13459 67.5414 8.63587C67.8931 9.13714 68.1426 9.70285 68.2755 10.3006C68.4085 10.8984 68.4222 11.5166 68.316 12.1197L60.7412 54.9172C60.5265 56.1364 59.8362 57.2203 58.8223 57.9305C57.8084 58.6408 56.5539 58.9192 55.3347 58.7046L29.0786 54.0745C27.8652 53.8533 26.7891 53.1599 26.0862 52.1464C25.3833 51.1329 25.1111 49.882 25.3291 48.668Z"
      fill={color.DEFAULT}
    />
    <path
      d="M57.3799 39.6445H57.2095L33.8981 35.5636C33.6469 35.5197 33.4236 35.3778 33.2771 35.1691C33.1306 34.9605 33.073 34.7022 33.1169 34.4511C33.1609 34.2 33.3028 33.9766 33.5114 33.8301C33.7201 33.6836 33.9784 33.626 34.2295 33.6699L57.5409 37.7792C57.792 37.8006 58.0244 37.9208 58.1868 38.1135C58.3493 38.3061 58.4286 38.5555 58.4073 38.8066C58.3859 39.0577 58.2657 39.2901 58.073 39.4525C57.8804 39.615 57.6311 39.6943 57.3799 39.6729V39.6445Z"
      fill={color.DEFAULT}
    />
    <path
      d="M56.4994 44.6155H56.3384L33.027 40.5062C32.9037 40.4856 32.7856 40.4407 32.6797 40.3742C32.5738 40.3077 32.4821 40.2209 32.4099 40.1188C32.3377 40.0167 32.2865 39.9013 32.2591 39.7793C32.2318 39.6572 32.2289 39.531 32.2506 39.4078C32.2723 39.2853 32.3179 39.1683 32.3849 39.0634C32.4519 38.9586 32.5388 38.868 32.6408 38.7967C32.7428 38.7255 32.8579 38.6751 32.9794 38.6483C33.1009 38.6216 33.2265 38.6191 33.3489 38.6409L56.6603 42.7502C56.7847 42.7608 56.9057 42.7957 57.0166 42.8531C57.1274 42.9104 57.2259 42.989 57.3063 43.0844C57.3868 43.1798 57.4476 43.2901 57.4854 43.4091C57.5233 43.528 57.5373 43.6532 57.5267 43.7775C57.5162 43.9019 57.4812 44.0229 57.4238 44.1337C57.3665 44.2446 57.2879 44.343 57.1925 44.4235C57.0971 44.5039 56.9868 44.5648 56.8678 44.6026C56.7489 44.6404 56.6237 44.6545 56.4994 44.6439V44.6155Z"
      fill={color.DEFAULT}
    />
    <path
      d="M46.3018 47.9484C46.2453 47.9578 46.1878 47.9578 46.1313 47.9484L32.1464 45.4866C31.8952 45.4427 31.6719 45.3007 31.5254 45.0921C31.3789 44.8835 31.3213 44.6252 31.3652 44.3741C31.4092 44.1229 31.5511 43.8996 31.7597 43.7531C31.9684 43.6066 32.2266 43.549 32.4778 43.5929L46.4627 46.0642C46.7082 46.1081 46.9264 46.2471 47.07 46.451C47.2136 46.6548 47.271 46.9071 47.2297 47.153C47.1939 47.3739 47.0811 47.5749 46.9112 47.7205C46.7414 47.8661 46.5255 47.9468 46.3018 47.9484Z"
      fill={color.DEFAULT}
    />
    <path
      d="M55.3631 58.6951L28.9555 54.3302C27.7666 54.12 26.7099 53.4465 26.0175 52.4575C25.325 51.4686 25.0536 50.2452 25.2628 49.0562L26.3422 42.949C26.3422 42.949 26.1433 46.2062 29.9307 49.7948C33.8885 53.5537 55.3631 58.6951 55.3631 58.6951Z"
      fill={color.LIGHTER}
    />
    <path
      d="M68.316 12.1197C68.4222 11.5166 68.4084 10.8984 68.2755 10.3006C68.1426 9.70285 67.8931 9.13714 67.5413 8.63586C67.1896 8.13459 66.7424 7.70759 66.2254 7.3793C65.7085 7.05101 65.1319 6.82786 64.5286 6.72263L38.2725 2.09254C37.6692 1.98505 37.0507 1.99787 36.4524 2.13026C35.8542 2.26266 35.288 2.51201 34.7864 2.86398C34.2849 3.21596 33.8578 3.66361 33.5299 4.18119C33.2019 4.69878 32.9795 5.27609 32.8755 5.87993L30.5367 19.1358L65.9773 25.385L68.316 12.1197Z"
      fill={color.MEDIUM}
    />
    <path
      d="M50.9224 12.4984L52.2859 17.0622L56.8497 18.5677C57.0391 18.6221 57.2087 18.7301 57.3381 18.8787C57.4676 19.0273 57.5513 19.2101 57.5793 19.4051C57.6073 19.6002 57.5784 19.7992 57.496 19.9782C57.4136 20.1571 57.2812 20.3085 57.1148 20.414L53.1664 23.0558V27.79C53.1625 27.9826 53.1053 28.1704 53.001 28.3324C52.8968 28.4945 52.7497 28.6244 52.576 28.7078C52.4023 28.7912 52.2089 28.8248 52.0173 28.8049C51.8256 28.7849 51.6433 28.7122 51.4905 28.5948L47.7031 25.6785L43.073 27.1367C42.891 27.1845 42.6995 27.1826 42.5185 27.1312C42.3375 27.0797 42.1736 26.9806 42.044 26.8442C41.9143 26.7078 41.8237 26.5391 41.7815 26.3557C41.7393 26.1723 41.7472 25.9809 41.8042 25.8016L43.3855 21.3609L40.545 17.5072C40.4302 17.3517 40.3618 17.1667 40.3479 16.9739C40.3339 16.7811 40.3749 16.5883 40.4661 16.4178C40.5573 16.2473 40.6949 16.1062 40.8631 16.0108C41.0312 15.9153 41.2229 15.8695 41.416 15.8786L46.2166 16.0301L49.0571 12.2427C49.1649 12.0776 49.3174 11.9465 49.4968 11.8646C49.6763 11.7827 49.8753 11.7535 50.0707 11.7802C50.2661 11.807 50.4498 11.8887 50.6006 12.0159C50.7514 12.143 50.863 12.3103 50.9224 12.4984Z"
      fill={color.DEFAULT}
    />
  </svg>
);

export default KnowledgeIllustration;
