// @flow

import React, { useCallback, useState, useMemo } from 'react';
import { t } from 'i18next';

import { getCdnFromS3Url } from 'Libs/cdn';
import { useTypedDispatch } from 'Libs/redux/utils';
import EnumMedia from 'Models/MediaHandlerEnum.js';

import MImageCrop from 'Components/modal/MImageCrop/MImageCrop';
import SMediaUpload from 'Components/structural/SMediaUpload/SMediaUpload';

import {
  actions,
  useModuleSelector,
  useSelectedActivity,
  useDefaultActivity,
  getLanguageDetail,
} from '../../../redux';

import styles from './DailySerieOverviewForm.style';

// eslint-disable-next-line complexity
export const DailySerieOverviewForm = () => {
  const [imageModalVisible, setImageModalVisible] = useState(false);

  const dispatch = useTypedDispatch();
  const addImage = useCallback(() => setImageModalVisible(true), []);

  const onImageSelection = useCallback(() => {}, []);
  const onCancel = useCallback(() => setImageModalVisible(false), []);
  const onSave = useCallback(result => {
    setImageModalVisible(false);

    const id = (result.id(): number);
    const url = getCdnFromS3Url((result.url(): string), { asThumbnail: true });

    dispatch(actions.setCover(id, url));
  }, [dispatch]);

  const selectedActivity = useSelectedActivity();
  const { isDefault, coverUrl } = selectedActivity;
  const defaultCoverUrl = useDefaultActivity().coverUrl;
  const selectedLanguageId = useModuleSelector(state => state.selectedLanguageId);
  const { label, data } = useMemo(() => getLanguageDetail(selectedLanguageId), [selectedLanguageId]);
  const showOverlay = !isDefault && !coverUrl;
  const overlayContent = showOverlay ? t('activities:overview.form.translated_image_overlay_title', {
    languageLabel: label,
    languageCode: data,
  }) : '';
  const overlaySubtext = showOverlay ? t('activities:overview.form.translated_image_overlay_label'): '';

  return (
    <div style={styles.wrapper}>
      <div style={styles.textContainer}>
        <div style={styles.title}>
          {t('activities:overview.form.title')}
        </div>
        <div style={styles.comment}>
          {t('activities:overview.form.description')}
        </div>
      </div>

      <div>
        <div style={styles.imageText}>
          {t('activities:overview.form.choose_cover_image')}
          <span style={styles.asterisk}>
            {'*'}
          </span>
        </div>
        <SMediaUpload
          style={styles.image}
          imageUrl={coverUrl || defaultCoverUrl}
          onUploadClick={addImage}
          onEditClick={addImage}
          overlayContent={overlayContent}
          overlaySubtext={overlaySubtext}
          uploadButtonText={t('activities:overview.form.add_image')}
        />
      </div>

      {imageModalVisible ? (
        <MImageCrop
          cropProps={EnumMedia.CAMPAIGN}
          onImageSelection={onImageSelection}
          onCancel={onCancel}
          onSave={onSave}
        />
      ) : null}
    </div>
  );
};
