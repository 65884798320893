import * as React from 'react';
import { Translation } from 'react-i18next';
import { ValueOf } from 'ts-essentials';

import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import UChips from 'Components/unit/UChips/UChips';
import UIcon from 'Components/unit/UIcon/UIcon';
import UIndicator from 'Components/unit/UIndicator/UIndicator';
import type { TrackingProps } from 'Services/trackingService';
import { getTrackingDatasetFromProps } from 'Services/trackingService';

import { DROPDOWN_ITEM_TYPES } from './UDropDownItem.constants';
import styles from './UDropdownItem.style';

type chipsModeType = 'select' | 'delete';

type DropdownItemType = ValueOf<typeof DROPDOWN_ITEM_TYPES>;

export type UDropdownItemProps = Readonly<{
  id: number;
  color?: string;
  children?: React.ReactNode;
  chipsProps?: any;
  chipsMode: chipsModeType;
  icon?: IconName;
  indicatorText?: string;
  nested?: number;
  selected?: boolean;
  style?: React.CSSProperties;
  text?: string;
  onClick?: (id: number) => void;
  onHover?: (id: number) => void;
  removeMouseFocus?: boolean;
  itemIndication?: string;
  stopClickPropagation?: boolean;
  type?: DropdownItemType;
}> &
  TrackingProps;

type UDropdownItemState = {
  isHover: boolean;
  showChildren: boolean;
};

const COLOR_TYPE = {
  [DROPDOWN_ITEM_TYPES.STANDARD]: { color: COLORS.GREY_DARKER.DEFAULT },
  [DROPDOWN_ITEM_TYPES.ACCENTUATED]: { color: COLORS.TEXT.ACCENTUATED },
  [DROPDOWN_ITEM_TYPES.WARNING]: { color: COLORS.WARNING.DARK },
  [DROPDOWN_ITEM_TYPES.DESTRUCTIVE]: { color: COLORS.ERROR.DARKER },
};

/**
 * Item use in a Dropdown box
 *
 * Props:
 *  - id: an id to the item
 *  - text: text inside the item
 *  - selected: true if the item is selected
 *  - icon: name of the icon
 *  - indicatorText: text of the indicator
 *  - nested: how much this component is nested
 *  - onClick: called when click on the item
 *  - style: override component's style
 *  - color: color of the text, this props override the type styling
 *  - children: nested item
 *  - chipsProps: props given to the component chips
 *  - chipsMode: mode of the chips
 *  - onHover: function called on component hover
 *  - removeMouseFocus: remove the mouse focus on the component (remove cursor and focus's style)
 *  - itemIndication: add an indication in the component near to the default text
 *  - type: presets of styling, standard | accentuated | destructive | warning
 */
export class UDropdownItem extends React.Component<UDropdownItemProps, UDropdownItemState> {
  static defaultProps = {
    id: 0,
    color: undefined,
    children: undefined,
    chipsProps: undefined,
    chipsMode: 'select',
    icon: '',
    indicatorText: '',
    nested: 0,
    selected: false,
    style: undefined,
    text: '',
    onClick: () => {},
    onHover: () => {},
    removeMouseFocus: false,
    itemIndication: '',
    stopClickPropagation: false,
    type: DROPDOWN_ITEM_TYPES.STANDARD,
  };

  state = {
    isHover: false,
    showChildren: false,
  };

  // Performance
  shouldComponentUpdate(nextProps: UDropdownItemProps, nextState: UDropdownItemState) {
    const { children } = this.props;

    if (children || nextProps.children) return true;

    const copyOldProps = JSON.stringify(this.props);
    const copyNextProps = JSON.stringify(nextProps);

    if (copyOldProps === copyNextProps && nextState === this.state) return false;

    return true;
  }

  render() {
    const { style: propStyle, chipsMode, selected, removeMouseFocus } = this.props;

    const backgroundColor = this.getBackgroundColor();

    let cursor = !selected && chipsMode === 'select' ? 'pointer' : 'default';

    if (removeMouseFocus) cursor = 'none';

    const style: React.CSSProperties = {
      backgroundColor,
      cursor,
    };

    return (
      <div data-testid="dropdown-item" style={propStyle} {...getTrackingDatasetFromProps(this.props)}>
        <div style={styles.wrapper}>
          {this.renderNestedContainer()}
          <div
            onClick={this.handleClick}
            onMouseEnter={this.handleHover}
            onMouseLeave={this.handleLeave}
            style={{ ...styles.globalContainer, ...style }}
          >
            {this.renderItem()}
          </div>
          {this.renderFoldIcon()}
        </div>
        {this.renderChildren()}
      </div>
    );
  }

  renderItem = () => {
    const { chipsProps } = this.props;

    if (chipsProps) {
      return (
        <div style={{ ...styles.wrapperChips }}>
          <UChips {...chipsProps} />
        </div>
      );
    }
    return (
      <>
        {this.renderContainerText()}
        {this.renderSelected()}
        {this.renderIndicator()}
      </>
    );
  };

  renderNestedContainer = () => {
    const { nested } = this.props;

    if (!nested || nested <= 0) return null;

    const items = [];

    for (let i = 0; i < nested; i++) items.push(<div key={i} style={styles.nested} />);

    return <div style={styles.nestedContainer}>{items}</div>;
  };

  renderContainerText = () => {
    const { text, color, itemIndication, icon, type } = this.props;

    const wrapperStyle = {
      ...(icon ? styles.innerContainerTextWithIcon : styles.innerContainerText),
      justifyContent: itemIndication ? 'space-between' : 'initial',
    };

    const styleText = {
      ...TYPOGRAPHY.BODY4,
      ...(color ? { color } : COLOR_TYPE[type || 'standard']),
    };

    return (
      <div style={styles.containerText}>
        {this.renderIcon()}
        <div style={wrapperStyle}>
          <div style={styleText}>{text}</div>
          {this.renderItemIndication()}
        </div>
      </div>
    );
  };

  renderIcon = () => {
    const { color, icon, type } = this.props;

    if (!icon) return null;

    const iconColor = color || COLOR_TYPE[type || 'standard'].color;

    return (
      <div style={styles.iconContainer}>
        <UIcon color={iconColor} name={icon} size={10} />
      </div>
    );
  };

  renderIndicator = () => {
    const { indicatorText } = this.props;

    if (!indicatorText) return null;

    return <UIndicator text={indicatorText} style={styles.indicator} />;
  };

  renderSelected = () => {
    const { selected } = this.props;

    if (!selected) return null;

    return (
      <div style={styles.containerSelected}>
        <Translation>{(t) => t('unit_components:u_dropdown_item.selected')}</Translation>
      </div>
    );
  };

  renderFoldIcon = () => {
    const { children, type } = this.props;
    const { showChildren } = this.state;
    const icon = !showChildren ? 'plus' : 'mixed-check';

    if (!children) return null;

    return (
      <div onClick={this.handleClickFold} style={styles.foldIcon}>
        <UIcon
          name={icon}
          size={9}
          color={COLOR_TYPE[type || 'standard'].color}
          style={styles.icon as React.CSSProperties}
        />
      </div>
    );
  };

  renderChildren = () => {
    const { children } = this.props;
    const { showChildren } = this.state;

    if (!children || !showChildren) return null;

    return children;
  };

  renderItemIndication = () => {
    const { itemIndication } = this.props;

    if (!itemIndication) return null;

    return <div style={styles.containerItemIndication}>{itemIndication}</div>;
  };

  handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const { selected, onClick, chipsProps, chipsMode, id, stopClickPropagation } = this.props;

    if (stopClickPropagation) e.stopPropagation();

    if (onClick && !selected && (!chipsProps || chipsMode === 'select')) onClick(id);
  };

  handleClickFold = () => {
    const { showChildren } = this.state;

    this.setState({ showChildren: !showChildren });
  };

  handleHover = () => {
    const { onHover, id } = this.props;

    this.setState({ isHover: true }, () => (onHover ? onHover(id) : null));
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  getBackgroundColor = () => {
    const { selected, chipsMode, removeMouseFocus } = this.props;
    const { isHover } = this.state;

    if (removeMouseFocus) return 'transparent';

    return selected
      ? COLORS.GREY_MEDIUM.DEFAULT
      : isHover && chipsMode === 'select'
      ? COLORS.WHITE.HOVER
      : 'transparent';
  };
}

export default UDropdownItem;
