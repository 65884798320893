// @flow

/**
 * Display a container who can take a button
 *
 * Props:
 *  - children: The components that will be contained in the wrapper
 *  - labelButton: Text displayed in the button
 *  - onButtonClick: Callback called on button press
 *  - style: For overriding the component's style
 *
 */

import * as React from 'react';

import styles from './SLargeCallout.style';

type Props = {|
  style: Object,
  children: React.Node,
  color: string,
|};

export class SLargeCallout extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, children, color } = this.props;
    const styleWrapper = {
      borderColor: color,
    };
    const overlayStyle = {
      backgroundColor: color,
    };

    return (
      <div style={{ ...styles.wrapper, ...styleWrapper, ...style }}>
        {children}
        <div style={{ ...overlayStyle, ...styles.overlay }} />
      </div>
    );
  }
}

export default SLargeCallout;
