import m from 'm';
import { t } from 'i18next';

import T from 'types';
import User from 'services/userService';
import App from 'models/App';

var component = {};

component.controller = function controller() {
  var self = this;

  self.options = App.router.buildRouteOptionForUser({
    '/analytics/dashboard': { name: t('analytics:menu.dashboard'), event: 'dashboard' },
    '/analytics/reports': { name: t('analytics:menu.reports'), event: 'report' },
    '/analytics/nps': { name: t('analytics:menu.nps'), event: 'nps' },
    '/analytics/leaderboards': { name: t('analytics:menu.leaderboard'), event: 'leaderboard' },
  });

  self.goToPath = function goToPath(path, args, e) {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.which === 2)
      return;

    e.preventDefault();

    if (args && args.confirm)
      return args.confirm(() => m.route(path));

    m.route(path);
  };

  return self;
};

component.view = function view(c, args) {
  const mainRoute = App.router.getMainRoute(c.options, 2);

  let children = {};

  if (T.is.obj(c.options[mainRoute]) && c.options[mainRoute].children)
    children = c.options[mainRoute].children;

  return m('.gaia-menu', [
    m('.gaia-main-menu', [
      Object.entries(c.options).map(([route, option]) => {
        const selected = mainRoute === route;
        const { name, event } = option;

        return m('a.gaia-main-menu__item', {
          config: m.route,
          href  : route,
          class : selected
            ? 'gaia-main-menu__item--selected'
            : '',
          onclick: c.goToPath.bind(null, route, args),
          'data-tracking-action': 'navigate',
          'data-tracking-context': `menu-analytics-${event}`,
        }, name);
      }),
    ]),
    m('.gaia-secondary-menu', {
      class: Object.keys(children).length ? '' : 'gaia-secondary-menu--hidden',
    }, [
      Object.entries(children).map(([route, option]) => {
        const selected = m.route() === route;

        return m('a.gaia-secondary-menu__item', {
          config: m.route,
          href  : route,
          class : selected
            ? 'gaia-secondary-menu__item--selected'
            : '',
          onclick: c.goToPath.bind(null, route, args),
        }, option.name);
      }),
    ]),
  ]);
};

export default component;
