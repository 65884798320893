import { v4 as uuidv4 } from 'uuid';
import { t } from 'i18next';
import { createLinkedList } from '@sparted/shared-library/linked-list';

import { StoreType } from 'Libs/redux/store';
import { InferPushQueueItem, QueueProcessHandler } from 'Libs/queue/redux/utils';
import type { RequireAtLeastOne } from 'Libs/utils/types/object';

import type { CourseEdited } from 'Pages/Courses/redux';

import { actions, updateCoursePublishStatus } from 'Pages/Courses/redux';
import { sendCommands } from 'Pages/Courses/redux/thunks';

import {
  isMkChapter,
  editMkItemInlist,
  insertMkItemInList,
  deleteMkItemInList,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import type {
  MicroKnowledgeListType,
  MicroKnowledgeItem,
  MicroKnowledgeChapter,
  MicroKnowledgeText,
  MicroKnowledgeImage,
  MicroKnowledgeVideo,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import type { CourseFormData, ToEditCourseData } from 'Pages/Courses/types';

import {
  createDeleteCommands,
  createEditCourseDataCommands,
  createInsertCommands,
  createReorderCommands,
  createUpdateCommands,
  findInsertionTargetId,
} from './commands';
import type { Command, CommandsRequest, CreateUpdateCommandData, EditionPreparedUpdateCommandData } from './commands';

export type MkQueueHandler = QueueProcessHandler<Omit<CommandsRequest, 'editionId'>>;

type ConflictResponse = {
  errorCode: 'CE-IU-AC-003' | 'CE-IU-AC-006';
};

export const isConflictResponse = (error: any): error is ConflictResponse =>
  error?.errorCode === 'CE-IU-AC-003' || error?.errorCode === 'CE-IU-AC-006';

export const getHandleMkQueueItemProcess =
  (store: StoreType): MkQueueHandler =>
  async (queueItem) => {
    const { course: { id } = {}, revision: { editionId } = {} } = store.getState().pages.courses.edition;

    if (!id || !editionId) {
      return Promise.reject(
        new Error('handleMkQueueItemProcess: Course.id & Course.revision.editionId should not be undefined'),
      );
    }

    const payload = {
      commands: queueItem.payload.commands,
      editionId,
    };

    const dispatchedAction = store.dispatch(
      sendCommands({
        id,
        payload,
      }),
    );

    try {
      const result = await dispatchedAction.unwrap();

      store.dispatch(actions.setDraftStatus({ draft: true, courseId: id }));

      return result;
    } catch (err: unknown) {
      if (isConflictResponse(err)) {
        store.dispatch(actions.setHasEditionIdMismatchError({ hasEditionIdMismatchError: true }));
        return Promise.reject();
      }

      store.dispatch(actions.setHasEditionError({ hasEditionError: true }));
      return Promise.reject();
    }
  };

export type EditionHandlerServices = {
  // Using store to avoid having to subscribe to a specific state and have useless re-renders when it changes
  store: StoreType;
  enqueue: (item: InferPushQueueItem<MkQueueHandler>) => void;
};

type ContextData = {
  microKnowledges: MicroKnowledgeListType;
  course: CourseEdited;
};
type EditionHandlerConfig<P, PreparedData = P> = {
  getCommands: (context: ContextData, preparedData: PreparedData) => Command[];
  getNewState: (
    context: ContextData,
    preparedData: PreparedData,
  ) => {
    newMicroKnowledges?: MicroKnowledgeListType;
    newCourseData?: Omit<ToEditCourseData, 'dataId'>;
  };
  prepareData?: (context: ContextData, params: P) => PreparedData;
  config?: {
    shouldSendCommands?: boolean;
  };
};

const defaultEditionHandlerFactoryConfig = {
  shouldSendCommands: true,
};

export const editionHandlerFactory =
  <P, PreparedData = P>({
    getCommands,
    getNewState,
    // By default we return params as PreparedData. Therefore it need a casting
    prepareData = (_, params: P) => params as unknown as PreparedData,
  }: EditionHandlerConfig<P, PreparedData>) =>
  ({ store, enqueue }: EditionHandlerServices, config = defaultEditionHandlerFactoryConfig) =>
  (params: P) => {
    const { shouldSendCommands } = { ...defaultEditionHandlerFactoryConfig, ...config };

    const { microKnowledges, course } = store.getState().pages.courses.edition;

    if (!course) {
      throw new Error("Course should not be undefined if you're trying to edit it.");
    }

    const preparedData = prepareData({ microKnowledges, course }, params);

    // Handle commands
    if (shouldSendCommands) {
      const commands = getCommands({ microKnowledges, course }, preparedData);
      enqueue({ origin: 'COURSE_EDITION', payload: { commands } });
    }

    // Handle local state updates
    const { newMicroKnowledges, newCourseData } = getNewState({ microKnowledges, course }, preparedData);

    return [
      ...(newMicroKnowledges ? [store.dispatch(actions.setMkList(newMicroKnowledges))] : []),
      ...(newCourseData ? [store.dispatch(actions.updateCourse(newCourseData))] : []),
    ];
  };

export type HandleMkDeleteParams = { id: string };
export const getHandleDeleteMk = editionHandlerFactory<HandleMkDeleteParams>({
  getCommands: ({ microKnowledges }, { id }) => createDeleteCommands(microKnowledges, id),
  getNewState: ({ microKnowledges }, { id }) => {
    const itemToDelete = microKnowledges.items[id];

    return {
      newMicroKnowledges: deleteMkItemInList(microKnowledges, itemToDelete),
    };
  },
});

export const getHandleUpdateMk = editionHandlerFactory<CreateUpdateCommandData, EditionPreparedUpdateCommandData>({
  prepareData: ({ microKnowledges }, itemData) => {
    const originalItem = microKnowledges.items[itemData.id];
    const switchedToChapter = isMkChapter(itemData) && !isMkChapter(originalItem);

    if (!switchedToChapter) {
      return {
        itemToUpdate: itemData,
        switched: {
          toChapter: switchedToChapter,
        },
      };
    }

    const uuid = uuidv4();

    return {
      itemToUpdate: {
        ...itemData,
        nextId: uuid,
      },
      optionnalInsertion: {
        id: uuid,
        chapterId: itemData.id,
        type: 'text',
        content: '',
        title: '',
        nextId: originalItem.nextId,
        previousId: itemData.id,
      },
      switched: {
        toChapter: switchedToChapter,
      },
    };
  },
  getCommands: ({ microKnowledges }, itemData) => createUpdateCommands(microKnowledges, itemData),
  getNewState: ({ microKnowledges }, itemData) => {
    const { itemToUpdate, optionnalInsertion } = itemData;
    const oldItem = microKnowledges.items[itemToUpdate.id];

    const newItem = {
      ...oldItem,
      ...itemToUpdate,
    } as MicroKnowledgeItem;

    const listWithInsertedItem = optionnalInsertion
      ? insertMkItemInList(microKnowledges, optionnalInsertion)
      : microKnowledges;
    const listWithChapter = editMkItemInlist(listWithInsertedItem, oldItem, newItem);

    return {
      newMicroKnowledges: listWithChapter,
    };
  },
});

type PartialMicroKnowledgeWithoutId =
  | (PartialWithTypeWithoutId<MicroKnowledgeChapter> & Pick<MicroKnowledgeChapter, 'isCollapsed'>)
  | (PartialWithTypeWithoutId<MicroKnowledgeText> & Pick<MicroKnowledgeText, 'content'>)
  | (PartialWithTypeWithoutId<MicroKnowledgeImage> & Pick<MicroKnowledgeImage, 'content'>)
  | PartialWithTypeWithoutId<MicroKnowledgeVideo>;

type PartialWithTypeWithoutId<T extends MicroKnowledgeItem> = Partial<Omit<T, 'id' | 'type'>> & { type: T['type'] };

const isPartialMicroKnowledgeWithoutId = (arg: any): arg is PartialMicroKnowledgeWithoutId =>
  arg?.type === 'chapter' || arg?.type === 'text' || arg?.type === 'image' || arg?.type === 'video';

export type HandleInsertMkParam =
  | ({
      targetId: string | null;
    } & PartialMicroKnowledgeWithoutId)
  | {
      targetId: string | null;
    };

export const getHandleInsertMk = editionHandlerFactory<HandleInsertMkParam, MicroKnowledgeItem>({
  prepareData: ({ microKnowledges }, { targetId, ...optionalMk }): MicroKnowledgeItem => {
    const uuid = uuidv4();
    const { headId, items } = microKnowledges;

    // targetId is the id of the item the user clicked.
    // actualTargetId is the id where the new mk should be insert after. It accounts for chapters.
    // Clicking the + on a open chapter should insert after its last child.
    const actualTargetId = targetId
      ? findInsertionTargetId(microKnowledges, targetId)
      : createLinkedList(headId, items).find(({ nextId }) => nextId === null)?.id || null;

    const originalTarget = microKnowledges.items[targetId!];
    const target = microKnowledges.items[actualTargetId!];

    const insertInChapter =
      (isMkChapter(originalTarget) && !originalTarget.isCollapsed) || Boolean(originalTarget?.chapterId);
    const potentialChapterId = target?.chapterId || targetId;

    const itemToInsert = {
      id: uuid,
      chapterId: insertInChapter ? potentialChapterId : null,
      title: isPartialMicroKnowledgeWithoutId(optionalMk) && optionalMk.title ? optionalMk.title : '',
    };

    const mkData = isPartialMicroKnowledgeWithoutId(optionalMk)
      ? optionalMk
      : {
          content: '',
          type: 'text' as const,
        };

    const order = {
      previousId: target ? target.id : null,
      nextId: target ? target.nextId : null,
    };

    return { ...mkData, ...itemToInsert, ...order };
  },
  getCommands: ({ microKnowledges }, itemToInsert) => createInsertCommands(microKnowledges, itemToInsert),
  getNewState: ({ microKnowledges }, itemToInsert) => ({
    newMicroKnowledges: insertMkItemInList(microKnowledges, itemToInsert),
  }),
});

export type HandleEditCourseDataParam = {
  courseDataId: number;
} & RequireAtLeastOne<CourseFormData>;
export const getHandleCourseUpdate = editionHandlerFactory<HandleEditCourseDataParam>({
  getCommands: (_, { courseDataId, ...dataToUpdate }) => createEditCourseDataCommands(courseDataId, dataToUpdate),
  getNewState: (_, { image, title, description, languageCountry }) => ({
    newCourseData: {
      ...(title ? { title } : {}),
      ...(image ? { coverImageUrl: image?.cdn } : {}),
      ...(description ? { description } : {}),
      ...(languageCountry ? { language: languageCountry } : {}),
    },
  }),
});

export type PublishHandlerConfig = {
  courseId: number;
  published: boolean;
  context: 'edition' | 'list';
};

export const getPublishHandler = (store: StoreType, config: PublishHandlerConfig) => async () => {
  const { courseId, published, context } = config;
  const { pages } = store.getState();

  const coursesState = pages.courses;
  const editionId =
    context === 'edition'
      ? coursesState.edition.revision?.editionId
      : coursesState.list.items.find(({ course }) => course.id === courseId)?.revision.editionId;

  if (!editionId) {
    throw new Error(t('courses:errors.cannot_publish_editionId'));
  }

  try {
    await store.dispatch(updateCoursePublishStatus({ courseId, published, editionId })).unwrap();
  } catch (err: unknown) {
    throw new Error(t('courses:errors.cannot_publish_empty'));
  }
};

// should just calculate order
export type HandleReorderMk = { nextOrderedMks: Omit<MicroKnowledgeItem, 'previousId' | 'nextId'>[] };
export const getHandleReorderMk = editionHandlerFactory<
  HandleReorderMk,
  { nextOrderedMks: MicroKnowledgeItem[] | null }
>({
  prepareData: (ctx, { nextOrderedMks }) => {
    const completeNextOrderedMks = nextOrderedMks.map((item, idx) => {
      const previousId = nextOrderedMks[idx - 1]?.id ?? null;
      const nextId = nextOrderedMks[idx + 1]?.id ?? null;

      return {
        ...item,
        previousId,
        nextId,
      } as MicroKnowledgeItem;
    });

    return {
      nextOrderedMks: completeNextOrderedMks,
    };
  },
  getNewState: (ctx, { nextOrderedMks }) => ({
    ...(nextOrderedMks
      ? {
          newMicroKnowledges: {
            headId: nextOrderedMks[0].id,
            items: nextOrderedMks.reduce<Record<string, MicroKnowledgeItem>>(
              (acc, mk) => ({ ...acc, [mk.id]: mk }),
              {},
            ),
          },
        }
      : undefined),
  }),
  getCommands: ({ microKnowledges }, { nextOrderedMks }) => createReorderCommands(microKnowledges, nextOrderedMks),
});

// TODO: SHOULD IMPLEMENT LATER
export const handleDuplicateMk = () => {};
