import Model from 'models/Model';

var WeekDay = {
  endpoint  : 'api/WeekDays',
  attributes: {
    label: {
      type: 'string',
    },
  },
};

export default Model.register('WeekDay', WeekDay);
