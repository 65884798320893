import React, { useMemo, useCallback } from 'react';
import { t } from 'i18next';

import type { Source } from 'Libs/ts/types';

import { GroupFormData, Segmentation } from 'Features/audience/services/create-audience-group.service';
import SInput from 'Components/structural/SInput/SInput';
import SOmnibox, { SuggestionOptions } from 'Components/structural/SOmnibox/SOmnibox';
import ULabel from 'Components/unit/ULabel/ULabel';

import { getDimensions } from './utils/get-dimensions';
import type { Dimension } from './utils/get-dimensions';
import { getSegmentationSource } from './utils/get-segmentation-source';
import styles from './AudienceGroupForm.style';

type SelectedSegmentationMap = { [key: number]: boolean };

export type AudienceGroupFormProps = {
  values: GroupFormData;
  onChange: (groupFormData: GroupFormData) => void;
  errors?: {
    nameInput?: string;
  };

  // Used to avoid dependencies on App model in tests
  segmentationDimensions?: Dimension[];
  segmentationSource?: Source;
};

const suggestionsOptions: SuggestionOptions = {
  enable: true,
  enableOnEmptyInput: false,
  enableTextSuggestion: false,
  disableMaxSuggestionsCap: true,
  showAllOnFocus: true,
  showCategoryName: false,
};

const VALIDATION_RULES = {
  NAME: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 72,
  },
};

export const AudienceGroupForm = ({
  onChange,
  values,
  errors,
  segmentationDimensions,
  segmentationSource,
}: AudienceGroupFormProps) => {
  const dimensions = useMemo(() => segmentationDimensions || getDimensions(), [segmentationDimensions]);
  const rawSource = useMemo(() => segmentationSource || getSegmentationSource(), [segmentationSource]);

  const { name, segmentations } = values;

  // Compute the `selected` field in the filter items
  const sources: Source[] = useMemo(() => {
    const selectedSegmentationMap: SelectedSegmentationMap = segmentations.reduce(
      (acc, { id }) => ({ ...acc, [id]: true }),
      {},
    );

    return [
      {
        ...rawSource,
        filters: rawSource?.filters.map((filter) => ({
          ...filter,
          items: filter?.items.map((filterItem) => ({
            ...filterItem,
            selected: selectedSegmentationMap[filterItem.id] || false,
          })),
        })),
      },
    ];
  }, [rawSource, segmentations]);

  const handleNameChange = useCallback(
    (newName: string) => {
      onChange({ name: newName, segmentations });
    },
    [onChange, segmentations],
  );

  const handleFilterChange = useCallback(
    (newSource: Source) => {
      const newSegmentations = newSource.filters[0].items.reduce<Segmentation[]>(
        (acc, { id, selected }) => (selected ? [...acc, { id }] : acc),
        [],
      );

      onChange({ name, segmentations: newSegmentations });
    },
    [onChange, name],
  );

  return (
    <>
      <ULabel htmlFor="audience-segmentation-input" required>
        {t('audiences:audience_group_form.segments')}
      </ULabel>
      <SOmnibox
        id="audience-segmentation-input"
        placeholder={t('audiences:audience_group_form.segments_placeholder')}
        dimensions={dimensions}
        sources={sources}
        onFilterChanged={handleFilterChange}
        suggestionOptions={suggestionsOptions}
        hideMargins
        hideFiltersButton
        style={styles.omnibox}
      />
      <SInput
        id="audience-name-input"
        label={t('audiences:audience_group_form.name')}
        placeholder={t('audiences:audience_group_form.name_placeholder')}
        type="small"
        onChange={handleNameChange}
        minLength={VALIDATION_RULES.NAME.MIN_LENGTH}
        maxLength={VALIDATION_RULES.NAME.MAX_LENGTH}
        errorMessage={errors?.nameInput}
        value={name}
        required
      />
    </>
  );
};

export default AudienceGroupForm;
