// @flow

/* eslint-disable key-spacing */

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    marginTop: 35,
  },
  OCItemsFormTitle: {
    ...TYPOGRAPHY.H1,
    marginBottom: 8,
  },
  explanation: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginTop: 4,
  },
  cardRow: {
    marginTop: 26,
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    marginTop: 20,
  },
  action: {
    marginLeft: 22,
  },
  actionLocked: {
    marginLeft: 26,
  },
  disabledIcon: {
    height: 24,
    width: 24,
    marginLeft: 26,
  },
};
