import { mergeDeep } from 'Libs/mergeDeep';

import type { GameItemType } from './GameItem';

import createGameItem from './GameItem';

export type KnowledgeInfoType = Readonly<{
  id: number;
  customId: string | null;
  pendingTranslation: boolean;
  archived: boolean;
  title: string;
  explanation: GameItemType;
}>;

const defaultState: KnowledgeInfoType = {
  id: 0,
  customId: null,
  pendingTranslation: false,
  archived: false,
  title: '',
  explanation: createGameItem(),
};

const makeTitle = (values: any): string => values?.knowledgeTitle?.data || '';

export default function createKnowledgeInfo(values?: any): KnowledgeInfoType {
  if (!values) {
    return defaultState;
  }

  const knowledgeInfo: KnowledgeInfoType = {
    id: values.id,
    customId: values.customId,
    pendingTranslation: values.pendingTranslation,
    archived: values.archived,
    title: makeTitle(values),
    explanation: createGameItem(values.explanation),
  };

  return mergeDeep(defaultState, knowledgeInfo);
}
