// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  form: {
    padding: 30,
    width: 370,
    borderTop: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    overflowY: 'auto',
  },
  formGroup: {
    marginBottom: 25,
  },
  label: {
    display: 'flex',
    paddingBottom: 8,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
  },
  categorySelect: {
    height: 32,
  },
  segmentationWrapper: {
    marginTop: -16,
  },
  segmentationInput: {
    minWidth: '',
    width: '100%',
  },
  notice: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 8,
    marginBottom: 37,
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 40,
  },
};
