import m from 'm';
import App from 'models/App';
import Enum from 'models/Enum';
import Player from 'models/Player';
import Bus from 'services/pubSubService';
import input from 'components/new.input.text';
import multi from 'components/new.filtering.multi.select';
import button from 'components/button';
import segmentationInput from 'components/segmentation.input';
import passwordManager from 'libs/passwordManager';
import User from 'services/userService';

var component = {};

// eslint-disable-next-line complexity
component.controller = function controller(args) {
  var self = this;
  var loggedUser = User.meSync();
  var isLoggedUserRoot = loggedUser.isRoot();
  var isLoggedUserMaster = loggedUser.isMasterAdmin();
  var isEditedUserRoot = args.user.isRoot();

  self.canEditSegs = m.prop(isLoggedUserRoot || (isLoggedUserMaster && !isEditedUserRoot));

  self.error = {
    msg    : m.prop(''),
    display: m.prop(false),
  };

  self.user = args.user || new Player();
  self.workLocationEnabled = m.prop(false);

  var workLocationConfig = m.prop(App.configurations().find({
    id: Enum.configurations.SIGNUP_OPEN_INPUT_ACTIVE,
  }).at(0));

  if (workLocationConfig().value())
    self.workLocationEnabled(true);

  var randomPassword = passwordManager.generatePassword();

  if (!self.user.realm())
    self.user.realm(args.realm || 'BackOffice');

  if (!self.user.password())
    self.user.password(randomPassword);

  // if (!self.user.id()) {
  //   // Fill with mock values
  //   self.user.email(random + '@sparted.com');

  //   var theRolesIWant = App.roles().filter(function getWriter(item) {
  //     return item.name() === 'Writer';
  //   });
  // }

  self.submit = function submit() {

    if (!self.user.firstName().length)
      self.error.msg('I cannot find anybody that has no first name...');
    else if (!self.user.lastName().length)
      self.error.msg('You did not specify a Last Name');
    else if (!self.user.email().length)
      self.error.msg('Please enter an email address');
    else if (!self.user.roles().length && self.user.realm() === 'BackOffice')
      self.error.msg('You must specify at least one role');
    else
      self.error.msg('');

    if (self.error.msg().length)
      Bus.trigger('popup:error', self.error.msg());

    else {
      self.user.save()
        .then(function triggerEvent(reply) {
          console.log('User saved', reply);
          if (args.user)
            args.user = reply;
          else
            Bus.trigger('user:add', self.user);
          args.close();
        })
        .catch(function displayError(err) {
          console.error('Error creating user', err);
          Bus.trigger('popup:error', err.message.error.message);
        });
    }
  };

  function noError() {
    self.error.display(false);
    Bus.trigger('popup:noError');
  }
};

component.view = function view(c, args) {
  function prettyPlaceholder(coll) {
    if (coll.length > 1)
      return coll.length + ' items selected.';
    if (coll.length > 0)
      return coll.length + ' item selected.';

    return 'No ' + coll.type + 's assigned...';
  }

  return m('.newUser.popup', [
    m('.popup__body', [
      m('.popup__title', args.user ? args.user.firstName() + ' ' + args.user.lastName() : 'New User'),
      m('.newUser__subtitle', 'Believe me or not, all fields are mandatory...'),
      m('label.newUser__label', [
        m('span.newUser__legend', 'First Name:'),
        m(input, {
          path       : 'firstName',
          model      : c.user,
          error      : c.error,
          placeholder: 'John',
          class      : [
            'margin__bottom',
          ].join(' '),
        }),
      ]),
      m('label.newUser__label', [
        m('span.newUser__legend', 'Last Name:'),
        m(input, {
          path       : 'lastName',
          model      : c.user,
          error      : c.error,
          placeholder: 'Doe',
          class      : [
            'margin__bottom',
          ].join(' '),
        }),
      ]),
      m('label.newUser__label', [
        m('span.newUser__legend', 'User Email:'),
        m(input, {
          type       : 'mail',
          path       : 'email',
          model      : c.user,
          error      : c.error,
          placeholder: 'johndoe@any.com',
          class      : [
            'margin__bottom',
          ].join(' '),
        }),
      ]),
      m('label.newUser__label', {
        class: (c.workLocationEnabled() && c.user.realm() === 'FrontOffice') ? '' : 'hidden',
      }, [
        m('span.newUser__legend', (App.signupOpenTextLabel() || 'Work Location') + ':'),
        m(input, {
          path       : 'extraInfo',
          model      : c.user,
          error      : c.error,
          placeholder: 'eg: Store Paris 15',
          class      : [
            'margin__bottom',
          ].join(' '),
        }),
      ]),
      c.user.realm() === 'BackOffice'
      ? m('label.newUser__label', [
        m('span.newUser__legend', 'User Role(s):'),
        m(multi, {
          values     : c.user.roles,
          list       : App.roles(),
          path       : 'name',
          placeholder: prettyPlaceholder(c.user.roles()),
          class      : [
            'margin__bottom',
          ].join(' '),
        }),
      ])
      : '',
      m('label.newUser__label', [
        m('span.newUser__legend--segmentation', 'Segmentation:'),
        m(segmentationInput, {
          index    : 1,
          entity   : c.user,
          editableSegs: c.canEditSegs(),
        }),
      ]),
    ]),
    m('.popup__footer', [
      m(button, {
        value : 'Cancel',
        class : 'button--cancel',
        action: args.close,
      }),
      m(button, {
        value : c.user.id() ? 'Save' : 'Add User',
        class : 'button--ok',
        action: c.submit,
      }),
    ]),
  ]);
};

export default component;
