import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';

import type { CSSProperties } from 'react';

import SMicroKnowledgeListItem from 'Components/structural/SMicroKnowledgeListItem/SMicroKnowledgeListItem/SMicroKnowledgeListItem';
import UMediaPicker, { PickerSelectedVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';

import { COLORS } from 'Components/foundation';

import UTextLink from 'Components/unit/UTextLink/UTextLink';
import { VIDEO_PROVIDERS } from 'Components/modal/MVideoUpload/utils';
import Enum from 'Models/Enum';
import { MicroKnowledgeItemSharedProps } from '../types/shared-props';

import styles from './SVideoMicroKnowledgeItem.style';

const MICROKNOWLEDGE_COLOR = COLORS.WHITE.DEFAULT;

export type SVideoMicroKnowledgeItemProps = MicroKnowledgeItemSharedProps & {
  video?: { thumbnailUrl: string; id: number; url: string };
  videoLabel?: string;
  onVideoChange?: (video: PickerSelectedVideo) => void;
  onTitleChange?: (id: string, newContent: string) => void;
  isChild?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
};

export const SVideoMicroKnowledgeItem = ({
  id,
  video = undefined,
  title,
  videoLabel,
  backgroundColor = MICROKNOWLEDGE_COLOR,
  isChild = false,
  options,
  style = {},
  onTitleChange,
  onVideoChange,
  onAdd,
}: SVideoMicroKnowledgeItemProps) => {
  const [automaticScroll, setAutomaticScroll] = useState(false);

  const handleAdd = useCallback(() => onAdd?.(id), [onAdd, id]);

  const handleTitleChange = useCallback((newValue: string) => onTitleChange?.(id, newValue), [onTitleChange, id]);

  const handleVideoChange = useCallback(
    (newVideo: PickerSelectedVideo) => {
      onVideoChange?.(newVideo);
    },
    [onVideoChange],
  );

  useEffect(() => {
    setAutomaticScroll(false);
  }, [automaticScroll]);

  return (
    <SMicroKnowledgeListItem
      id={id}
      isChild={isChild}
      contextMenuOptions={options}
      backgroundColor={backgroundColor}
      style={style}
      onAdd={handleAdd}
    >
      <SSubtleInput
        inputType="input"
        style={styles.title}
        value={title}
        placeholder={t('courses:course_edition.type_title')}
        maxLength={40}
        onChange={handleTitleChange}
        debounced
      />
      <UMediaPicker
        size="XS"
        dropArea
        image={video?.thumbnailUrl}
        style={styles.video}
        textLink={videoLabel}
        textLinkStyle={styles.videoLabel}
        textLinkTypography="BODY2"
        media={{
          mediaType: 'video',
          onChange: handleVideoChange,
          providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube]],
        }}
      />
      {video && <UTextLink text={video.url} link={video.url} style={styles.url} typography="BODY2" />}
    </SMicroKnowledgeListItem>
  );
};

export default SVideoMicroKnowledgeItem;
