import React, { ChangeEvent, useCallback } from 'react';
import type { CSSProperties } from 'react';

import ULabel from 'Components/unit/ULabel/ULabel';

import styles from './USizeSelectorBar.style';

export type USizeSelectorBarProps = {
  style?: CSSProperties;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  label?: string;
  value: number;
};

/**
 * USizeSelectorBar
 * min: minimum length of the input
 * max: maximum length of the input
 * onChange: callback when the value of the input changes
 * label: label of the input
 * value: value of the input
 */
export const USizeSelectorBar = ({ style, min = 0, max = 100, value, label, onChange }: USizeSelectorBarProps) => {
  const handleValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: valueChanged } = event.target;

      onChange(Number(valueChanged));
    },
    [onChange],
  );

  return (
    <div style={style}>
      <ULabel size="XS">{label}</ULabel>
      <input style={styles.rangeInput} type="range" min={min} max={max} value={value} onChange={handleValue} />
    </div>
  );
};

export default USizeSelectorBar;
