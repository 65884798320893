import mixpanel from 'mixpanel-browser';
import Config from 'Services/instanceConfig';
import { findNodeWithDatasetAttribute } from 'Services/nodeTreeHelpers';

export type ButtonEvent = 'save' | 'delete' | 'create' | 'navigate' | 'export' | 'load' | 'skip';

type SimpleEventMapDefinition = {
  [key: string]: string;
};

type EventTrackingFunctionMap = {
  [key: string]: (params?: Object) => void;
};

type IdentityParams = {
  id: number;
  createdAt: string;
  globalScore: string;
  workLocation: string;
  isAdmin: boolean;
  isMasterAdmin: boolean;
};

type TrackingParam = {
  trackingAction?: ButtonEvent;
  trackingContext?: string;
};

export type TrackingProps = TrackingParam;

type TrackingDataSet = TrackingParam | TrackingProps | Readonly<TrackingParam> | Readonly<TrackingProps>;

const TRACKING_TOKEN = {
  PROD: process.env.SPARTED_MIXPANEL_PROD_TOKEN || '',
  DEV: process.env.SPARTED_MIXPANEL_DEV_TOKEN || '',
};

export const simpleEventsMap: SimpleEventMapDefinition = {
  contributorsConfirmInviteModal: 'contributors-confirm-invite-modal',
  contributorsAcceptInvite: 'contributors-accept-invite',
  contributorsResendInvite: 'contributors-resend-invite',
  contributorsDeleteInvite: 'contributors-delete-invite',
  contributorsSignUp: 'contributors-signup',
  contributorsForgetPassword: 'contributors-forget-password',
  contributorsUpdatePassword: 'contributors-update-password',
  userSendFeedbackAfterKeywordExtraction: 'smart-keywords-extraction-feedback',
  knowledgeAutoTranslated: 'knowledge-auto-translated',
};

export const trackEventWithLocation = (event: string, params: Object = {}) => {
  if (process.env.CI === 'true') return;
  try {
    mixpanel.track(event, {
      ...params,
      pathname: window.location.pathname,
    });
  } catch (e) {
    console.error('Error while tracking event', e);
  }
};

const MAX_DEPTH_LOOKUP = 5;

export const clickTrackingHandler = ({ target }: MouseEvent) => {
  const node = findNodeWithDatasetAttribute(target, MAX_DEPTH_LOOKUP, 'trackingAction');

  if (!node) return;

  const eventName = node.dataset.trackingAction;
  const context = node.dataset.trackingContext;

  trackEventWithLocation(eventName, { context });
};

export const initClickTracking = () => {
  window.addEventListener('click', clickTrackingHandler);
};

export const initUrlObserver = (element: Node) => {
  let previousUrl = '';

  const observer = new MutationObserver(() => {
    const { location } = window;

    if (location.href === previousUrl) return;

    previousUrl = location.href;
    trackEventWithLocation('url');
  });

  observer.observe(element, { childList: true, subtree: true });
};

export const initUserIdentity = ({
  id,
  createdAt,
  globalScore,
  workLocation,
  isAdmin,
  isMasterAdmin,
}: IdentityParams) => {
  try {
    mixpanel.identify(`${Config.instanceId}-${id}`);
    mixpanel.register({
      user_created_at: createdAt,
      user_last_login: new Date().toString(),
      user_global_score: globalScore,
      user_is_admin: isAdmin,
      user_is_masterAdmin: isMasterAdmin,
      user_work_location: workLocation,
    });
  } catch (e) {
    console.error('Error while initializing user identity', e);
  }
};

export const initTracking = () => {
  try {
    const isDev = process.env.NODE_ENV !== 'production';
    const token = isDev ? TRACKING_TOKEN.DEV : TRACKING_TOKEN.PROD;

    mixpanel.init(token, {
      debug: isDev,
    });
    mixpanel.register({ instance_id: Config.instanceId });

    initClickTracking();

    if (document.body && MutationObserver) {
      initUrlObserver(document.body);
    }
  } catch (e) {
    console.error('Error while initializing tracking', e);
  }
};

export const generateSimpleEventTrackingFunctions = (eventMap: SimpleEventMapDefinition): EventTrackingFunctionMap =>
  Object.keys(eventMap).reduce(
    (acc, key) => ({
      ...acc,
      [key]: (params: Object) => trackEventWithLocation(eventMap[key], params),
    }),
    {},
  );

export const tracking: EventTrackingFunctionMap = {
  ...generateSimpleEventTrackingFunctions(simpleEventsMap),
};

export const getTrackingDatasetFromProps = ({ trackingAction, trackingContext }: TrackingDataSet) =>
  trackingAction && trackingContext
    ? {
        'data-tracking-action': trackingAction,
        'data-tracking-context': trackingContext,
      }
    : {};
