import m from 'm';
import { t } from 'i18next';

import Enum from 'models/Enum';
import EnumMedia from 'models/MediaHandlerEnum';
import { autoSelectImageFromLib } from 'libs/AIManager';
import popup from 'services/popupService';
import { tracking } from 'services/trackingService';
import reactCreator from 'components/react.creator';
import { VIDEO_PROVIDERS } from 'components/react/modal/MVideoUpload/utils';

const component = {};
const CHAR_COUNT_FOR_ACTIVATION = 50;
const AI_IMAGE_SELECTION_STATUS = {
  IDLE: 0,
  LOADING: 1,
  SUCCEED: 2,
  FAILED: 3,
  FINISHED: 4,
};

const { FRENCH, ENGLISH } = Enum.AvailableLanguages;
const AI_SUPPORTED_LANGUAGE = [FRENCH, ENGLISH];

component.controller = function controller(args) {
  var self = this;

  self.imageSource = m.prop('Unsplash');
  self.aiInterruptProcess = m.prop(false);
  self.aiSelectionCurrentStatus = m.prop(AI_IMAGE_SELECTION_STATUS.IDLE);
  self.aiResponse = m.prop(null);
  self.aiErrorMessage = m.prop('');
  self.imageModalKeywords = m.prop('');
  self.tooltipId = `ai-disable-explanation${args.mediaPositionFront ? '-front' : ''}`;
  self.aiDisabled = m.prop(false);
  self.aiDisabledExplanation = m.prop('');

  self.linkImage = function linkImage(_args, image) {
    _args.knowledge().linkImage(image.id())
      .then(function addItToKnowledge(_image) {
        _args.knowledge().images().push(_image);
      });
  };

  self.changeImage = function changeImage(_args, image) {
    self.linkImage.bind(null, args);
    _args.image(image);
    _args.type(Enum.mediaType.Image);
    _args.knowledge().linkImage(image.id());
    self.closeImagePopup();
  };

  self.addVideo = function addVideo(_args, params) {
    _args.video(params.video);
    _args.type(Enum.mediaType.Video);
  };

  self.isVideoUploadModalVisible = m.prop(false);

  self.openVideoUploadModal = function openVideoUploadModal() {
    self.isVideoUploadModalVisible(true);
    m.redraw();
  };

  self.closeVideoUploadModal = function closeVideoUploadModal() {
    self.isVideoUploadModalVisible(false);
    m.redraw();
  };

  self.isImagePopupVisible = m.prop(false);

  self.openImagePopup = function openImagePopup(event, sourceToSelect) {
    self.imageSource(sourceToSelect || 'Unsplash');
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.imageModalKeywords('');
    self.isImagePopupVisible(false);
    m.redraw();
  };

  self.setAiActivation = function setAiActivation(_args, aiConfig) {
    const { targetTexts } = aiConfig;

    const targetGameItem = targetTexts.find((gi) => {
      const { TEXT, LONG_TEXT } = Enum.gameItemTypes;

      return [TEXT, LONG_TEXT].includes(gi().typeId());
    });

    const targetText = targetGameItem && targetGameItem().data();
    const isActivated = targetText && targetText.length >= CHAR_COUNT_FOR_ACTIVATION;

    self.aiDisabled(!isActivated);

    const disabledText = !isActivated
      ? t('mithril_components:content_media_selector.ai_requirements', { minChars: CHAR_COUNT_FOR_ACTIVATION })
      : '';

    self.aiDisabledExplanation(disabledText);
  };

  self.autoSelectImage = function autoSelectImage(_args) {
    const { targetTexts } = _args.getAIConfig();
    const { LOADING, FINISHED, SUCCEED, FAILED } = AI_IMAGE_SELECTION_STATUS;
    const language = _args.knowledge().segmentation()
      .filter((s) => AI_SUPPORTED_LANGUAGE.includes(s.availableGroupId()))?.[0];
    const languageId = language && language.availableGroupId();
    const textToSend = targetTexts.map((text) => text().data()).join(' ');

    const timePersistingError = 3000;

    if (self.isAILoadingVisible())
      return false;

    self.aiInterruptProcess(false);
    self.aiResponse(null);
    self.aiSelectionCurrentStatus(LOADING);
    const imageIndexToRetrieve =  _args.mediaPositionFront ? 0 : 1;

    return autoSelectImageFromLib({
      text: textToSend,
      isStopped: self.isAIProcessInterrupted,
      imageIndexToRetrieve,
      language: languageId,
    })
      .then((autoSelectionRes) => {

        // We return here error from client interruption
        if (autoSelectionRes.error) {
          self.aiSelectionCurrentStatus(FINISHED);

          return null;
        }

        const { image, keywords } = autoSelectionRes;

        self.aiSelectionCurrentStatus(SUCCEED);
        self.aiResponse(autoSelectionRes);
        self.imageModalKeywords(keywords.map((item) => item.keyword).join(', '));
        _args.image(image);
        _args.type(Enum.mediaType.Image);

        return m.redraw();
      })
      .catch((err) => {
        self.aiSelectionCurrentStatus(FAILED);
        self.aiErrorMessage(err.message);
        m.redraw();

        setTimeout(() => {
          self.aiSelectionCurrentStatus(FINISHED);
          self.aiErrorMessage('');
          m.redraw();
        }, timePersistingError);
      });
  };

  self.isAIProcessInterrupted = function isInterrupted() {
    return self.aiInterruptProcess() === true;
  };

  self.onAILoaderClose = function onAILoaderClose() {
    self.aiSelectionCurrentStatus(AI_IMAGE_SELECTION_STATUS.FINISHED);
    self.aiInterruptProcess(true);
    m.redraw();
  };

  self.isAILoadingVisible = function isAILoadingVisible() {
    const { LOADING, FAILED } = AI_IMAGE_SELECTION_STATUS;

    return [LOADING, FAILED].includes(self.aiSelectionCurrentStatus());
  };

  self.isAIFeedbackVisible = function isAIFeedbackVisible() {
    return self.aiSelectionCurrentStatus() === AI_IMAGE_SELECTION_STATUS.SUCCEED;
  };

  self.sendAIFeedback = function sendAIFeedback(accepted, _args) {
    const { languageId, contentId } = args.getAIConfig();
    const { uuid, image, method, toleranceThreshold } = self.aiResponse();
    const data = {
      uuid,
      accepted,
      languageId,
      contentId: contentId(),
      imageId: image.id(),
      toleanceThresold: toleranceThreshold,
      keywordExtractionMethodId: method,
    };

    if (accepted)
      _args.knowledge().linkImage(image.id());

    tracking.userSendFeedbackAfterKeywordExtraction(data);

    return Promise.resolve(accepted);
  };

  self.actionAfterFeedback = function actionAfterFeedback(accepted) {
    self.aiSelectionCurrentStatus(AI_IMAGE_SELECTION_STATUS.FINISHED);

    if (!accepted)
      return self.openImagePopup({}, 'Google');

    self.imageModalKeywords('');

    return m.redraw();
  };
};

// eslint-disable-next-line complexity
component.view = function view(c, args) {
  const AI_PROCESSING_MESSAGES = [
    t('mithril_components:content_media_selector.ai_step_1'),
    t('mithril_components:content_media_selector.ai_step_2'),
    t('mithril_components:content_media_selector.ai_step_3'),
  ];

  const isVideo = args.type() === Enum.mediaType.Video && args.video && args.video().id();
  const imagePopup =  m(reactCreator, {
    component: 'MImageCrop',
    props    : {
      onCancel           : c.closeImagePopup,
      onSave             : c.changeImage.bind(null, args),
      cropProps          : args.cropProps,
      defaultSearchText  : c.imageModalKeywords,
      defaultSearchSource: c.imageSource,
    },
  });

  const videoUploadModal = m(reactCreator, {
    component: 'MVideoUpload',
    props: {
      providers: [
        VIDEO_PROVIDERS[Enum.videoType.Youtube],
        VIDEO_PROVIDERS[Enum.videoType.ApiVideo],
      ],
      visible: true,
      onSave: c.addVideo.bind(null, args),
      onClose: () => c.closeVideoUploadModal(),
      uploadThumbnail: true,
    },
  });

  let aiConfig = null;

  if (c.aiSelectionCurrentStatus() !== AI_IMAGE_SELECTION_STATUS.FINISHED)
    aiConfig = args.getAIConfig && args.getAIConfig();

  if (aiConfig)
    c.setAiActivation(args, aiConfig);

  return m('.content-media-selector', {
    class: [
      args.class ? args.class : '',
      args.mediaPositionFront ? 'front' : '',
      c.isAILoadingVisible() ? 'content-media-selector--ai-loading' : '',
      c.isAIFeedbackVisible() ? 'content-media-selector--feedback-step' : '',
    ].join(' '),
  }, [
    c.isVideoUploadModalVisible() ? videoUploadModal : null,
    m('.content-media-selector__display', {
      style: {
        backgroundImage: 'url(' + (args.image && args.type() === Enum.mediaType.Image ? args.image().cdn({transformation: {width: 500}}) : '') + ')',
      },
    }, [
      m('iframe', {
        class          : isVideo ? '' : 'hidden',
        src            : args.video ? args.video().getIframeSrcUrl() : '',
        frameborder    : '0',
        allowfullscreen: false,
      }),
    ]),
    m.hide(!args.editable, '.content-media-selector__selection__wrapper', [
      m('.content-media-selector__selection', {
        class: [
          isFilled(args) || c.isAIFeedbackVisible() ? '' : 'content-media-selector__selection--empty',
          !aiConfig ? 'content-media-selector__selection--centered' : '',
        ].join(' '),
      }, [
        m('.content-media-selector__selection__standard-selection--wrapper', [
          m('.content-media-selector__selection__instruction', [
            m('.content-media-selector__selection__instruction__txt', isFilled(args)
              ? t('mithril_components:content_media_selector.change')
              : t('mithril_components:content_media_selector.add')),
          ]),
          m.hide(!args.video, '.content-media-selector__selection__action', {
            class  : 'content-media-selector__selection__action--video',
            onclick: c.openVideoUploadModal,
          }, [
            m('.content-media-selector__selection__action__txt', isFilled(args)
              ? t('mithril_components:content_media_selector.for_video')
              : t('mithril_components:content_media_selector.video')),
          ]),
          m('.content-media-selector__selection__action', {
            class  : 'content-media-selector__selection__action--image',
            onclick: c.openImagePopup,
          }, [
            m('.content-media-selector__selection__action__txt', isFilled(args)
              ? t('mithril_components:content_media_selector.for_image')
              : t('mithril_components:content_media_selector.image')),
            c.isImagePopupVisible() ? imagePopup : null,
          ]),
        ]),
        m.hide(!aiConfig, `.content-media-selector__selection__action--ai-selector[data-tip][data-for=${c.tooltipId}]`, [
          m(reactCreator, {
            component: 'UButton',
            props    : {
              text: t('mithril_components:content_media_selector.choose_image_for_me'),
              type: 'accentuated',
              onClick: c.autoSelectImage.bind(null, args),
              disabled: c.aiDisabled,
            },
          }),
          m(reactCreator, {
            component: 'STooltip',
            props    : {
              id : c.tooltipId,
              position: 'top',
              content : c.aiDisabledExplanation,
            },
          }),
        ]),
      ]),
      c.isAILoadingVisible()
        ? m('.content-media-selector__selection__action--ai-loader', [
          m(reactCreator, {
            component: 'SImageAiSelectionLoader',
            props    : {
              title : t('mithril_components:content_media_selector.image_lookup'),
              processingMessages: AI_PROCESSING_MESSAGES,
              errorTitle : t('mithril_components:content_media_selector.image_lookup_error'),
              errorDescription: c.aiErrorMessage,
              notice: t('mithril_components:content_media_selector.image_lookup_notice'),
              onClose: c.onAILoaderClose,
              style: {
                height: '100%',
              },
            },
          }),
        ])
        : null,
      m.hide(!c.isAIFeedbackVisible(), '.content-media-selector__feedback__action--wrapper', [
        m('.content-media-selector__feedback__action--container', [
          m(reactCreator, {
            component: 'UButtonWithLoading',
            props    : {
              text: t('mithril_components:content_media_selector.accept_image'),
              type: 'accentuated',
              onClick: c.sendAIFeedback.bind(null, true, args),
              onRequestEnd: c.actionAfterFeedback,
            },
          }),
          m(reactCreator, {
            component: 'UButtonWithLoading',
            props    : {
              text: t('mithril_components:content_media_selector.reject_image'),
              onClick: c.sendAIFeedback.bind(null, false, args),
              onRequestEnd: c.actionAfterFeedback,
              style: {
                marginLeft: 8,
              },
            },
          }),
        ]),
      ]),
    ]),
  ]);
};

function isFilled(args) {
  return (args.image && args.type() === Enum.mediaType.Image && args.image().id())
    || (args.video && args.type() === Enum.mediaType.Video && args.video().id());
}

export default component;
