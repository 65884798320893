import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const styleSheet = createStyleSheet({
  box: {
    margin: '0 16px 24px 16px',
  },
  title: {
    marginBottom: 8,
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
  },
  subtitle: {
    ...TYPOGRAPHY.H2,
    marginBottom: 8,
  },
  element: {
    marginBottom: 20,
  },
  contentElement: {
    marginBottom: 8,
  },
  input: {
    width: 50,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLORS.GREY_DARK.DEFAULT,
    marginRight: 8,
  },
  internalInputStyle: {
    textAlign: 'center',
  },
  daySelector: {
    marginLeft: -4,
  },
  disabledDayContainer: {
    marginBottom: 10,
    display: 'flex',
  },
  disabledDayDatePicker: {
    flexGrow: 1,
    marginRight: 16,
  },
});

export default styleSheet;
