// @flow

/**
  * Display a modal to prevent empty content to be translated
  *
  * Props:
  *   - style: custom style to override the modal's style
  *   - visible: if the modal should be displayed, by default false
  *   - onAction: callback triggered on click on buttons
 */

import * as React from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './MEmptyContentsTranslation.style';


type Props = {|
  style: Object,
  visible: boolean,
  onAction: () => void,
|};


export class MEmptyContentsTranslation extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, onAction, visible } = this.props;

    return (
      <MModal
        labelActionButton={t('activities:modals.empty_contents_translation.button')}
        labelSecondButton={t('activities:modals.empty_contents_translation.cancel')}
        onAction={onAction}
        onCloseModal={onAction}
        onSecondAction={onAction}
        title={t('activities:modals.empty_contents_translation.title')}
        visible={visible}
        style={{ ...styles.modal, ...style }}
        bodyStyle={styles.contentWrapper}
      >
        {t('activities:modals.empty_contents_translation.content')}
      </MModal>
    );
  }
}

export default MEmptyContentsTranslation;
