import m from 'm';
import KEY from 'keys';
import Thematic from 'models/Thematic';
import buttonIcon from 'components/thematic.button.icon';
import popup from 'services/popupService';
import App from 'models/App';

var component = {};

component.controller = function conroller(args) {

  var self = this;

  self.error = m.prop('');

  self.thematics = App.thematics();

  self.thematic = args.thematic;

  if (args.ancestor !== undefined) {
    self.thematic = new Thematic();
    self.thematic.ancestorId(args.ancestor);
    self.thematic.level(args.level);
  }

  self.edit = args.edit || m.prop(false);

  self.dropList = m.prop(false);

  self.save = function save() {

    self.thematic.getLabel().label(self.thematic.getLabel().label().replace(/\s+/, ' ').trim());
    self.thematic.name(self.thematic.getLabel().label());

    if (!self.thematic.getLabel().label())
      self.error('Cannot be empty');
    else if (!args.validate(self.thematic))
      self.error('Must be unique');
    else {
      var localID = self.thematic.id();

      self.thematic.save()
        .then(function saveThematicLocally(what) {
          // console.log('Server replied with', what.toJSON());

          if (localID === undefined) {
            args.onSave(what);
            self.thematic = new Thematic();
            self.thematic.ancestorId(args.ancestor);
            self.thematic.level(args.level);
          } else
            self.edit(false);
        })
        .catch(function errorSavingLocally(err) {
          console.error('Error saving locally', err);
        });
    }
  };

  self.config = function config(elem, init) {
    if (init)
      return;

    elem.focus();
  };

  /*
   ** Recursive function who fill an array with all childs of a thematic and return it.
   */
  self.getThematicsToArchive = function getThematicsToArchive(thematic) {
    var thematicsArray = [thematic];
    var nextThematics = self.thematics.find({
      ancestorId: thematic.id(),
    }).toArray();

    if (nextThematics) {
      nextThematics.forEach(function getChild(element) {
        thematicsArray = getThematicsToArchive(element).concat(thematicsArray);
      });
    }

    return thematicsArray;
  };

  /*
   ** Archive thematics on trash button clicked.
   ** If a thematic owns childs, we archive them too.
   */
  self.archive = function archive() {
    var thematicsArray = self.getThematicsToArchive(self.thematic);

    thematicsArray.forEach(function archiveChilds(thematic) {
      thematic
        .archive(thematic)
        .then(args.onDelete.bind(null, thematic))
        .catch(function errorArchiveThematics(err) {
          popup.open({
            type    : 'info',
            title   : 'Error during archiving of thematics',
            subtitle: ' ',
            content: err.message.error.message,
            zIndex: 100200,
          });
        });
    });
  };

  self.send = function send(e) {
    if (e.which >= KEY.A && e.which <= KEY.Z)
      self.error('');

    if (e.which === KEY.ENTER)
      self.save();
  };
};

component.view = function view(c, args) {

  function noop() {}

  function editionMode() {
    return m('.thematic__input', [
      m('.', [
        m('input.input__value.thematic__input__value', {
          class: args.active && !args.last && c.thematic.getLabel().label()
            ? 'thematic--selected'
            : '',
          type       : 'text',
          placeholder: args.placeholder || 'New ' + args.type,
          value      : c.thematic.getLabel().label(),
          maxlength  : args.max ? args.max : '',
          onkeydown  : c.send,
          oninput    : m.withAttr('value', c.thematic.getLabel().label),
          config     : args.focus ? c.config : noop,
          onblur     : function onblur() {
            if (c.thematic.getLabel().label()) {
              c.edit(false);
              c.save();
            }
            c.error('');
          },
        }),

        /*
         ** On hovering input, trash is display.
         ** If the thematic is active, the trash is white, otherwrise the trash is red.
         ** On Trash click, we ask to archive the thematic.
         */
        args.active && !args.last && c.thematic.getLabel().label()
          ? m('.thematic__button__label__image')
          : '',
        m.hide(!c.thematic.getLabel().label(), '.thematic__delete__button', {
          class: args.active && !args.last && c.thematic.getLabel().label()
            ? 'thematic__delete__button--white'
            : 'thematic__delete__button--red',
          onclick: popup.open.bind(null, {
            type   : 'confirm',
            title  : 'Warning!',
            content: 'Do you really want to remove this thematic?',
            action : c.archive,
            confirm: 'Delete',
            zIndex: 100200,
          }),
        }),
      ]),
      m('.thematic__input__error', {
        class: c.error().length ? '' : 'hide',
      }, c.error()),
    ]);
  }

  return m('.thematic__button', {
    class  : args.active ? 'thematic__button--active' : '',
    onclick: args.onclick ? args.onclick : noop,
  }, [
    c.thematic.ancestorId() === null
      ? m(buttonIcon, {
        dropList: c.dropList,
      type    : args.type,
      index   : args.index,
      icon    : c.thematic.icon,
      save    : c.save,
      query   : c.thematic.getLabel().label,
      })
      : '',
    editionMode(),
  ]);
};

export default component;
