/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-inline-comments */
// @flow

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { GAMEPLAY_KEYS } from 'Components/utils/Enum';
import SInput from 'Components/structural/SInput/SInput';

import type { OrderingCardsType } from '../../../../redux/models/OrderingCards';
import { actions } from '../../../redux';

import styles from './OCHelperForm.style';

export const OCHelperForm = () => {
  const { t } = useTranslation();
  const content = useTypedSelector(state => state.pages.content.content);
  const canEdit = useTypedSelector(state => state.pages.content.canEditContent);

  const orderingCards = content[GAMEPLAY_KEYS.ORDERING_CARDS];

  return (
    <div style={styles.wrapper}>
      <div style={styles.helperFormTitle}>
        {t('gameplays:ordering_cards.helpers_label')}
      </div>
      <div style={styles.explanationTipsWrapper}>
        <div style={styles.explanation}>
          {t('gameplays:ordering_cards.helpers_subtitle')}
        </div>
        <div style={styles.tips}>
          {t('gameplays:ordering_cards.helpers_tips')}
        </div>
      </div>
      <div style={styles.form}>
        {renderTopLabel(orderingCards, canEdit)}
        {renderBottomLabel(orderingCards, canEdit)}
      </div>
    </div>
  );
};

function renderTopLabel(orderingCards: OrderingCardsType, canEdit: boolean) {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setOCTopLabel(value)), [dispatch]);
  const topLabel = orderingCards.topLabel.data;

  return (
    <div style={styles.topLabelForm}>
      <div style={styles.formLabel}>
        {t('gameplays:ordering_cards.top_label')}
      </div>
      <SInput
        type='mini'
        placeholder={t('gameplays:ordering_cards.top_label_placeholder')}
        value={topLabel}
        maxLength={30}
        onChange={onChange}
        disabled={!canEdit}
      />
    </div>
  );
}

function renderBottomLabel(orderingCards: OrderingCardsType, canEdit: boolean) {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setOCBottomLabel(value)), [dispatch]);
  const topLabel = orderingCards.bottomLabel.data;

  return (
    <div style={styles.bottomLabelForm}>
      <div style={styles.formLabel}>
        {t('gameplays:ordering_cards.bottom_label')}
      </div>
      <SInput
        type='mini'
        placeholder={t('gameplays:ordering_cards.bottom_label_placeholder')}
        value={topLabel}
        maxLength={30}
        onChange={onChange}
        disabled={!canEdit}
      />
    </div>
  );
}

export default OCHelperForm;
