import Model from 'models/Model';

var GameItemType = {
  endpoint  : 'api/GameItemTypes',
  attributes: {
    label: {
      type: 'string',
    },
  },
};

export default Model.register('GameItemType', GameItemType);
