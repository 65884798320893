import { LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    flex: 1,
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  box: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  image: {
    borderRadius: 12,
  },
  withVisibility: {
    width: 400,
    position: 'relative',
  },
  noDisplay: {
    display: 'none',
  },
  closeIcon: {
    zIndex: LEVELS.LOCAL_OFFSET,
    position: 'relative',
    right: '-8px',
    top: '10px',
    display: 'flex',
    justifyContent: 'end',
  },
  closeRadius: {
    borderRadius: '50%',
  },
  selectorBar: {
    marginTop: 12,
  },
  mediaPicker: {
    marginTop: 20,
    borderRadius: 12,
  },
});

export default styleSheet;
