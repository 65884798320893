import m from 'm';

var component = {};

/*
  args = {
    wide : Boolean - set the width to 400px
    left : Boolean - set the arrow to left
    delay: Boolean - delay the animation
    click: Boolean/Function - open on click, call the function if click is a function
    square: Boolean - ease the border radius
  }
*/
component.controller = function controller(args) {
  var self = this;

  self.clicked = m.prop(false);

  self.clickOnIt = function clickOnIt(e) {
    if (!args.click)
      return;
    e.stopPropagation();
    e.preventDefault();

    self.clicked(!self.clicked());
    m.redraw();
  };

  self.configInput = function configInput(elem, isInit, ctx) {
    if (isInit || !args.click)
      return;

    document.addEventListener('mouseup', mouseup, false);

    ctx.onunload = function onunload() {
      document.removeEventListener('mouseup', mouseup, false);
    };

    function mouseup(e) {
      if (!elem.contains(e.target)) {
        self.clicked(false);
        m.redraw();
      }
    }
  };
};

component.view = function view(c, args, content) {
  return m('.popover__container', {
    onclick: c.clickOnIt,
    class  : [
      args.class ? args.class : '', !args.click || c.clicked() ? 'popover--to-open' : '',
    ].join(' '),
  }, [
    m('.popover', {
      style: {
        'background-color': args.background ? args.background : '',
      },
      class: [
        args.click ? 'popover--onclick' : '',
        args.isSelectionList ? 'popover--selection' : '',
        args.left ? 'popover--left' : '',
        args.delay ? 'popover--delay' : '',
        args.square ? 'popover--square' : '',
        args.alignRight ? 'popover--alignRight' : '',
      ].join(' '),
      config: c.configInput,
    }, [
      m('.popover__body', {
        class: args.wide ? 'popover__body--wide' : '',
      }, content),
    ]),
  ]);
};

export default component;
