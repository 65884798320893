import React, { memo, useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import type { CSSProperties, FunctionComponent } from 'react';
import { t } from 'i18next';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UAutoSave from 'Components/unit/UAutoSave/UAutoSave';
import UMediaPicker from 'Components/unit/UMediaPicker/UMediaPicker';
import ULanguageMenu from 'Components/unit/ULanguageMenu/ULanguageMenu';
import UStatusIndicator from 'Components/unit/UStatusIndicator/UStatusIndicator';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';
import SDivider from 'Components/structural/SDivider/SDivider';
import STooltip from 'Components/structural/STooltip/STooltip';
import STabList from 'Components/structural/STabList/STabList';

import { isFeatureEnabled } from 'Services/feature-flags';
import type { UAutoSaveStatus } from 'Components/unit/UAutoSave/UAutoSave';
import type { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';
import type { CourseFormData } from 'Pages/Courses/types';

import SPublicationButtons from 'Components/structural/SPublicationButtons/SPublicationButtons';

import { getStyles } from './CourseEditionBar.style';

const noop = () => {};

type PublicationButtonsStatus = {
  publish: { disabled: boolean; visible: boolean };
  unpublish: { disabled: boolean; visible: boolean };
};

export type AvailableLanguage = {
  countryCode: string;
  languageCode: string;
  languageName: string;
};

export type CourseEditionBarProps = {
  title: string;
  courseId: number;
  languageCountry: string;
  hasPendingUpdates: boolean;
  saveStatus: UAutoSaveStatus;
  hasPublishedVersion: boolean;
  availableLanguages: ReadonlyArray<AvailableLanguage>;
  canPublish: boolean;
  maxTitleLength?: number;
  imageUrl?: string;
  tabs?: string[];
  selectedTab?: number;
  style?: CSSProperties;
  publicationDate?: string | null;
  onBack: () => void;
  onEditClick: () => void;
  onPublish: () => Promise<void>;
  onUnpublish: () => Promise<void>;
  onCourseUpdate: (udpate: CourseFormData) => void;
  onChangeTab?: (index: number) => void;
  isCourseStarted: boolean;
  onDownloadProgressExport: () => void,
};

/**
 * A bar containing the information and available action about the current course
 * - maxTitleLength: The max with of the title in characters
 * - title: The title of the course
 * - courseId: The id of the course
 * - imageUrl: The URL of the course image
 * - saveStatus: The save status of the course (saving | saved | alert)
 * - languageCountry: The country of the selected language
 * - hasPendingUpdates: If the course has been modified
 * - availableLangugages: List of language the user can select
 * - hasPublishedVersion: If the course has already been published
 * - canPublish: used to disable the publish button if false
 * - tabs: Name of the tabs (to display)
 * - selectedTab: Index of the selected tab
 * - onBack: Callback when clicking the back button
 * - onPublish: Callback when clicking the publish button
 * - onEditClick: Callback when clicking the edit button
 * - onUnpublish: Callback when clicking the unpublish button
 * - onCourseUpdate: Callback update a course data
 * - onChangeTab: Callback to change tab
 */
export const CourseEditionBar: FunctionComponent<CourseEditionBarProps> = ({
  title,
  courseId,
  imageUrl,
  saveStatus,
  languageCountry,
  hasPendingUpdates,
  availableLanguages,
  hasPublishedVersion,
  canPublish,
  maxTitleLength = 40,
  tabs = [],
  selectedTab = 0,
  style = {},
  publicationDate,
  onBack,
  onPublish,
  onEditClick,
  onUnpublish,
  onCourseUpdate,
  onChangeTab = noop,
  isCourseStarted,
  onDownloadProgressExport,
}) => {
  const canSeeAudience = isFeatureEnabled('course-audience');
  const styles = getStyles({ canSeeAudience });

  const { publish, unpublish } = useMemo(
    (): PublicationButtonsStatus => ({
      publish: {
        disabled: !hasPendingUpdates,
        visible: true,
      },
      unpublish: {
        disabled: false,
        visible: hasPublishedVersion,
      },
    }),
    [hasPendingUpdates, hasPublishedVersion],
  );

  const handleImageChange = useCallback(
    (selectedImage: PickerSelectedImage) => {
      onCourseUpdate({ title, image: selectedImage });
    },
    [onCourseUpdate, title],
  );

  const handleTitleChange = useCallback(
    (newTitle: string) => {
      onCourseUpdate({ title: newTitle });
    },
    [onCourseUpdate],
  );

  const makeLanguageClickHandler = useCallback(
    (countryCode: string) => () => {
      onCourseUpdate({
        title,
        languageCountry: countryCode,
      });
    },
    [onCourseUpdate, title],
  );

  const languageMenuItems = useMemo(
    () =>
      availableLanguages.map(
        (language) =>
          ({
            type: 'language',
            key: language.languageCode,
            props: {
              countryCode: language.countryCode,
              text: language.languageName,
              selected: languageCountry === language.languageCode,
              onClick: makeLanguageClickHandler(language.languageCode),
            },
          } as const),
      ),
    [languageCountry, makeLanguageClickHandler, availableLanguages],
  );

  const rootStyle = useMemo(
    () => ({
      ...styles.wrapper,
      ...style,
    }),
    [style],
  );
  const statusText = useMemo(() => {
    if (publicationDate && hasPublishedVersion) {
      const date = new Date(publicationDate).toLocaleDateString('en-GB');

      return t('courses:edition_bar.status_text', { date });
    }

    return '';
  }, [publicationDate, hasPublishedVersion]);

  const tooltipId = useMemo(() => uuid(), []);
  const tooltipContent = useMemo(() => {
    return isCourseStarted
      ? t('courses:edition_bar.download_player_progression')
      : t('courses:edition_bar.no_player_has_read_this_course_yet');
  }, [isCourseStarted]);

  return (
    <div style={rootStyle}>
      <div style={styles.editBar}>
        <div style={styles.sideContainer}>
          <UIconButton test-id="back-button" icon="arrow-left" type="standard-dark" ghost onClick={onBack} />
          <UMediaPicker
            image={imageUrl}
            dropArea
            style={styles.imagePicker}
            media={{ mediaType: 'image', onChange: handleImageChange }}
          />
          <SSubtleInput
            value={title}
            inputType="input"
            maxLength={maxTitleLength}
            onChange={handleTitleChange}
            placeholder={t('courses:edition_bar.title_placeholder')}
            autoResizeInput
            minWidth={110}
            compact
            typo="BODY1"
            debounced
            styleInput={styles.titleInput}
          />
          <UIconButton
            test-id="edit-button"
            icon="edit-sml"
            type="standard-dark"
            size="S"
            ghost
            onClick={onEditClick}
            style={styles.editButton}
          />
          <ULanguageMenu countryCode={languageCountry} options={languageMenuItems} />
        </div>

        <div style={styles.sideContainer}>
          <UAutoSave status={saveStatus} hideText={false} />
          <SDivider direction="vertical" style={styles.divider} />
          {hasPublishedVersion && (
            <UStatusIndicator type="valid" size="medium" text={statusText} containerStyle={styles.publishIndicator} />
          )}
          <div data-tip data-for={tooltipId} style={styles.downloadButton}>
            <UIconButton
              ghost
              icon="download"
              disabled={!isCourseStarted}
              onClick={onDownloadProgressExport}
              style={styles.downloadButton}
            />
            <STooltip id={tooltipId} content={tooltipContent} position="bottom" />
          </div>
          <SPublicationButtons
            courseId={courseId}
            showPublish={publish.visible}
            showUnpublish={unpublish.visible}
            publishDisabled={publish.disabled || !canPublish}
            unpublishDisabled={unpublish.disabled}
            onPublish={onPublish}
            onUnpublish={onUnpublish}
          />
        </div>
      </div>

      {tabs.length > 0 && canSeeAudience && (
        <div style={styles.tabBar}>
          <STabList
            tabs={tabs}
            selectedItem={selectedTab}
            align="center"
            onChangeTab={onChangeTab}
            style={styles.tabList}
          />
        </div>
      )}
    </div>
  );
};

export default memo(CourseEditionBar);
