import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const TrashIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.6799 33.45L53.9999 71.92C53.8132 72.4695 53.428 72.9295 52.9199 73.21C52.4155 73.4936 51.8225 73.5758 51.2599 73.44L27.6699 67.75C27.1059 67.6117 26.6142 67.2669 26.292 66.7836C25.9698 66.3004 25.8406 65.7139 25.9299 65.14L32.1799 25.14C32.2332 24.8237 32.3488 24.5212 32.5199 24.25C32.693 23.9788 32.9213 23.7471 33.1899 23.57C33.4553 23.3925 33.7552 23.2732 34.0699 23.22C34.3794 23.15 34.7005 23.15 35.0099 23.22L65.0699 30.48C65.3752 30.5626 65.6608 30.7054 65.9099 30.9C66.1604 31.0947 66.3679 31.3392 66.5194 31.618C66.6708 31.8968 66.7629 32.2039 66.7899 32.52C66.8189 32.8343 66.7814 33.1511 66.6799 33.45Z"
      fill={color.DEFAULT}
    />
    <path
      d="M49 16.92C48.901 16.7015 48.7695 16.4992 48.61 16.32L46.31 13.92C45.8967 13.5192 45.4032 13.2104 44.8621 13.0139C44.3209 12.8174 43.7442 12.7377 43.17 12.78L14.46 15C13.8868 15.0489 13.3292 15.2122 12.82 15.48C12.3196 15.7591 11.8782 16.1327 11.52 16.58C11.1685 17.0332 10.9135 17.5536 10.7707 18.1091C10.628 18.6646 10.6005 19.2435 10.69 19.81L16.69 57.15C16.7937 57.8198 17.0579 58.4545 17.46 59C17.6714 59.273 17.9129 59.5213 18.18 59.74L21 62C21.4604 62.3515 22.0319 62.5254 22.61 62.49L46.76 60.63C47.3392 60.5847 47.8801 60.3229 48.2749 59.8966C48.6696 59.4703 48.8893 58.911 48.89 58.33L49.11 17.82C49.1311 17.5157 49.0938 17.2102 49 16.92Z"
      fill={color.DEFAULT}
    />
    <path
      d="M44.86 15.42C44.6482 15.2166 44.3961 15.0599 44.12 14.96C43.8834 14.8717 43.6325 14.8276 43.38 14.83H43.26L14.6 17C14.3057 17.0171 14.0189 17.0991 13.76 17.24C13.4992 17.3851 13.2684 17.5785 13.08 17.81C12.8971 18.0475 12.7644 18.3196 12.69 18.61C12.6247 18.8997 12.6247 19.2003 12.69 19.49L18.69 56.82C18.746 57.181 18.8865 57.5236 19.1 57.82C19.2172 57.9622 19.3478 58.0928 19.49 58.21C19.9121 58.5435 20.4434 58.7076 20.98 58.67L43.35 57C43.8913 56.9552 44.3963 56.7099 44.7663 56.3122C45.1362 55.9146 45.3444 55.3931 45.35 54.85L45.53 17C45.5376 16.7043 45.4796 16.4106 45.36 16.14C45.2428 15.8692 45.0729 15.6244 44.86 15.42V15.42Z"
      fill={color.HOVER}
    />
    <path
      d="M45.24 4.38999C45.1864 4.13245 45.0826 3.888 44.9344 3.67064C44.7863 3.45328 44.5967 3.26729 44.3765 3.12331C44.1564 2.97933 43.91 2.88019 43.6515 2.83158C43.3929 2.78296 43.1274 2.78582 42.87 2.83999L32.25 5.05999C31.6868 3.85474 30.7919 2.83492 29.67 2.11999C28.6216 1.43193 27.4076 1.03815 26.1549 0.97981C24.9022 0.921469 23.6569 1.20071 22.549 1.78835C21.4412 2.37599 20.5116 3.25044 19.8573 4.32029C19.2031 5.39014 18.8483 6.61609 18.83 7.86999L9.58997 9.80999C9.32229 9.85237 9.06602 9.94871 8.83671 10.0932C8.6074 10.2376 8.40985 10.4272 8.25603 10.6503C8.10222 10.8734 7.99536 11.1255 7.94194 11.3912C7.88853 11.6569 7.88967 11.9307 7.94529 12.1959C8.00092 12.4611 8.10987 12.7123 8.26553 12.9342C8.4212 13.156 8.62033 13.3439 8.85083 13.4864C9.08133 13.629 9.33839 13.7232 9.60641 13.7633C9.87443 13.8035 10.1478 13.7887 10.41 13.72L43.69 6.71999C44.2017 6.61149 44.6506 6.3068 44.9403 5.87124C45.2301 5.43568 45.3377 4.90393 45.24 4.38999V4.38999ZM23.37 6.31999C23.8098 5.66236 24.4917 5.20511 25.2671 5.04778C26.0424 4.89046 26.8486 5.0458 27.51 5.47999C27.7107 5.61117 27.8954 5.76557 28.06 5.93999L23 6.99999C23.0934 6.7583 23.2178 6.52973 23.37 6.31999V6.31999Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.53 23.93C28.6386 23.9161 28.7488 23.924 28.8543 23.9534C28.9597 23.9828 29.0582 24.033 29.144 24.101C29.2297 24.169 29.3009 24.2535 29.3535 24.3495C29.4061 24.4455 29.4389 24.5511 29.45 24.66L30.86 43C30.8598 43.2167 30.7779 43.4253 30.6305 43.5841C30.4831 43.743 30.2813 43.8403 30.0652 43.8568C29.8492 43.8732 29.6349 43.8074 29.4652 43.6727C29.2955 43.538 29.183 43.3441 29.15 43.13L27.74 24.79C27.7346 24.6815 27.7509 24.573 27.7878 24.4708C27.8248 24.3686 27.8816 24.2748 27.9551 24.1948C28.0286 24.1147 28.1173 24.0501 28.216 24.0047C28.3147 23.9592 28.4214 23.9338 28.53 23.93V23.93Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 26.06C21.2159 26.0148 21.4408 26.0552 21.6274 26.1728C21.814 26.2903 21.9476 26.4758 22 26.69L25.31 43.24C25.3285 43.3473 25.3251 43.4572 25.3001 43.5631C25.2752 43.669 25.2291 43.7688 25.1647 43.8566C25.1003 43.9443 25.0189 44.0182 24.9253 44.0737C24.8317 44.1293 24.7279 44.1655 24.62 44.18C24.404 44.2279 24.1777 44.1886 23.9905 44.0706C23.8032 43.9527 23.6701 43.7656 23.62 43.55L20.26 27C20.2432 26.8892 20.2493 26.776 20.2779 26.6676C20.3065 26.5592 20.3571 26.4578 20.4264 26.3697C20.4958 26.2816 20.5825 26.2087 20.6811 26.1554C20.7798 26.1021 20.8883 26.0697 21 26.06V26.06Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.35 24.87C36.4594 24.8687 36.5679 24.8892 36.6692 24.9305C36.7705 24.9717 36.8626 25.0328 36.9399 25.1101C37.0173 25.1875 37.0784 25.2795 37.1196 25.3808C37.1608 25.4821 37.1814 25.5906 37.18 25.7L36.44 42.56C36.4221 42.7802 36.3192 42.9847 36.1531 43.1303C35.9869 43.2759 35.7707 43.3511 35.55 43.34C35.4407 43.3413 35.3321 43.3208 35.2308 43.2796C35.1295 43.2383 35.0375 43.1772 34.9601 43.0999C34.8828 43.0226 34.8217 42.9305 34.7805 42.8292C34.7392 42.7279 34.7187 42.6194 34.72 42.51L35.46 25.65C35.4779 25.4298 35.5809 25.2253 35.747 25.0797C35.9131 24.9341 36.1294 24.8589 36.35 24.87Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      d="M25.09 53.45C19.36 51.07 16.6992 44.4453 16.6992 44.4453L18.63 56.86C18.6793 57.2228 18.8204 57.567 19.04 57.86C19.1537 58.0021 19.2809 58.1327 19.42 58.25C19.8434 58.581 20.3737 58.7447 20.91 58.71L41.2227 57.1562C41.2227 57.1562 31 55.89 25.09 53.45Z"
      fill={color.MEDIUM}
    />
  </svg>
);

export default TrashIllustration;
