import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  title: {
    marginTop: 19,
    ...TYPOGRAPHY.H1,
  },
  descriptionWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.BLACK.DEFAULT,
  },
});

export default styleSheet;
