import { TYPOGRAPHY, COLORS } from 'Components/foundation';
import { StyleSheet } from 'Components/foundation/stylesheets';

const styles: StyleSheet = {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: 31,
    width: '100%',
  },
  wrapperChips: {
    paddingTop: 2.5,
    paddingBottom: 2.5,
    paddingLeft: 10,
    paddingRight: 24,
    textAlign: 'left',
    width: '100%',
  },
  globalContainer: {
    display: 'flex',
    flexGrow: 2,
    height: 31,
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',

    paddingTop: 8,
    paddingBottom: 8,
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: 34,
    marginLeft: 10,
  },
  innerContainerText: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  innerContainerTextWithIcon: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginLeft: 8,
  },
  iconContainer: {
    width: 10,
  },
  containerSelected: {
    marginRight: 10,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  containerItemIndication: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 20,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
  },
  indicator: {
    marginRight: 10,
  },
  nestedContainer: {
    display: 'flex',
    marginRight: 5,
  },
  nested: {
    width: 11,
    borderRight: '1px solid #ededed',
  },
  foldIcon: {
    cursor: 'pointer',
    paddingLeft: 5,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    pointerEvents: 'none',
  },
};

export default styles;
