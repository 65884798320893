import React, { useCallback } from 'react';

import SLandingPage from 'Components/structural/SLandingPage/SLandingPage';
import { getCdnUrl } from 'Libs/cdn';
import Config from 'Services/instanceConfig';

import { LoginForm } from './components/LoginForm/LoginForm';
import { useModuleSelector } from './redux';

export const Login = () => {
  const email = useModuleSelector((state) => state.email);

  const onResetPassword = useCallback(() => {
    window.location.replace(`/reset_password?email=${email}`);
  }, [email]);

  return (
    <SLandingPage instanceLogo={getCdnUrl(Config.instanceId, '/logo.png')}>
      <LoginForm instanceName={Config.instanceId} onResetPassword={onResetPassword} />
    </SLandingPage>
  );
};
