import m from 'mithril';

var component = {};

/*
  args: {
    value  : <boolean> that controls the state,
    onclick: <function> executed each change of state,
    disabled: <boolean>,
  }
*/
component.controller = function controller(args) {
  var self = this;

  self.change = function change(checked) {
    args.value(checked);
    if (args.onclick)
      args.onclick();
  };

};

component.view = function view(c, args) {
  return m('label', [
    m('input', {
      type    : 'checkbox',
      checked : args.value(),
      onchange: m.withAttr('checked', c.change),
      disabled: args.disabled,
    }),
    m('.checkbox', {
      class: args.disabled ? 'checkbox--disabled' : '',
    }, [
      m('svg[viewBox="0 0 136 105"].checkbox__placeholder', [
        m('path.checkbox__path.checkbox__path--placeholder[d="M12 46 L53 88 L124 13"]'),
      ]),
      m('svg[viewBox="0 0 136 105"].checkbox__check', [
        m('path.checkbox__path[d="M12 46 L53 88 L124 13"]'),
      ]),
    ]),
  ]);
};

export default component;
