import React from 'react';

import styles from './UCircle.style';

type UCircleProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const UCircle = ({ children, style = {} }: UCircleProps) => {
  return (
    <div style={{ ...styles.circle, ...style }} data-test-id="UCircle">
      {children}
    </div>
  );
};
