import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';

import Enum from 'Models/Enum';
import { store } from 'Libs/redux/store';

import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import type { DocumentType } from 'ModelsReact/Document/Document';
import type { CategoryType } from 'ModelsReact/Category/Category';
import type { FilterState } from 'Libs/filter/types';
import type { AlertScope, AlertParams } from './models/Alert';

type DocumentListFetchAction = ApiActionType<'DOCUMENT_LIST_FETCH'>;
type DocumentListArchiveAction = ApiActionType<'DOCUMENT_LIST_ARCHIVE'>;
type DocumentAdd = ApiActionType<'DOCUMENT_ADD'>;
type DocumentUpdate = ApiActionType<'DOCUMENT_UPDATE'>;
type CategoryAdd = ApiActionType<'CATEGORY_ADD'>;
type DocumentSendNotificationAction = ApiActionType<'DOCUMENT_SEND_NOTIFICATION'>;

type DocumentSetAlertAction = {
  type: 'DOCUMENT_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};

type DocumentRemoveAlertAction = {
  type: 'DOCUMENT_REMOVE_ALERT';
  scope: AlertScope;
};

type DocumentListUpdateFiltersAction = {
  type: 'DOCUMENT_LIST_UPDATE_FILTERS';
  selectedFilters: Array<FilterState>;
};

type DocumentModalSetVisibility = {
  type: 'DOCUMENT_MODAL_SET_VISIBILITY';
  visibility: boolean;
  document?: DocumentType;
  file?: Blob;
};

type DocumentModalSetTitle = {
  type: 'DOCUMENT_MODAL_SET_TITLE';
  title: string;
};

type DocumentModalSetAvailability = {
  type: 'DOCUMENT_MODAL_SET_AVAILABILITY';
  available: boolean;
};

type CategoryLoad = {
  type: 'CATEGORY_LOAD';
  categories: Array<CategoryType>;
};

type DocumentModalSetCategory = {
  type: 'DOCUMENT_MODAL_SET_CATEGORY';
  categoryId: number;
};

type DocumentModalSetSegmentation = {
  type: 'DOCUMENT_MODAL_SET_SEGMENTATION';
  segmentation: Array<SegmentationGroupItemType>;
};

type DocumentModalSetError = {
  type: 'DOCUMENT_MODAL_SET_ERROR';
  error: string;
};

type NotificationModalSetVisibility = {
  type: 'NOTIFICATION_MODAL_SET_VISIBILITY';
  visibility: boolean;
};

export const DOCUMENTS_PAGINATION = 20;

export const makeFilter = (state: any) => {
  const filter: any = {
    segmentation: state.pages.documentList.selectedSegmentationIds,
    search: state.pages.documentList.selectedTextValues,
    archived: false,
  };

  if (state.pages.documentList.selectedCategoryId > 0) {
    filter.category = state.pages.documentList.selectedCategoryId;
  }

  if (state.pages.documentList.selectedSegmentationIds.length) {
    filter.segmentation = state.pages.documentList.selectedSegmentationIds.map((segId: number) => ({ id: segId }));
  }

  if (state.pages.documentList.selectedCategoryId === Enum.DocumentStatus.UNCATEGORIZED) {
    filter.uncategorized = true;
  }

  if (
    state.pages.documentList.selectedDocumentStatusId === Enum.DocumentStatus.AVAILABLE ||
    state.pages.documentList.selectedDocumentStatusId === Enum.DocumentStatus.UNAVAILABLE
  ) {
    filter.available = state.pages.documentList.selectedDocumentStatusId === Enum.DocumentStatus.AVAILABLE;
  }

  if (state.pages.documentList.selectedDocumentStatusId === Enum.DocumentStatus.ARCHIVED) {
    filter.archived = true;
  }

  return filter;
};

export const actions = {
  fetch: (from = 0, to: number = DOCUMENTS_PAGINATION): DocumentListFetchAction => {
    const state = store.getState();
    const filter = makeFilter(state);

    return apiAction({
      type: 'DOCUMENT_LIST_FETCH',
      route: '/api/documents/full',
      method: 'GET',
      query: { filter, from, to },
      meta: {
        more: from > 0,
      },
    });
  },

  getViews: (): ApiActionType<'DOCUMENT_LIST_GET_VIEWS'> =>
    apiAction({
      type: 'DOCUMENT_LIST_GET_VIEWS',
      route: '/api/user-engagement/view/document',
      method: 'GET',
    }),

  archive: (id: number, archived = false): DocumentListArchiveAction => {
    const payload = { id, archived };

    return apiAction({
      type: 'DOCUMENT_LIST_ARCHIVE',
      route: `/api/documents/full/${id}`,
      method: 'PUT',
      payload,
      meta: {
        archived,
      },
    });
  },

  add: (document: DocumentType, file: Blob): DocumentAdd => {
    const payload = new FormData();

    payload.append('document', JSON.stringify(document));
    payload.append('file', file);

    return apiAction({
      type: 'DOCUMENT_ADD',
      route: '/api/documents/full',
      method: 'POST',
      multipart: true,
      payload,
    });
  },

  addCategory: (label: string): CategoryAdd => {
    const payload = { label };

    return apiAction({
      type: 'CATEGORY_ADD',
      route: '/api/categories/full',
      method: 'POST',
      payload,
    });
  },

  update: (document: DocumentType): DocumentUpdate => {
    const payload: any = { ...document };

    return apiAction({
      type: 'DOCUMENT_UPDATE',
      route: `/api/documents/full/${payload.id}`,
      method: 'PUT',
      payload,
    });
  },

  updateFilters: (selectedFilters: Array<FilterState>): DocumentListUpdateFiltersAction => ({
    type: 'DOCUMENT_LIST_UPDATE_FILTERS',
    selectedFilters,
  }),

  setAlert: (scope: AlertScope, params: AlertParams = Object.freeze({})): DocumentSetAlertAction => ({
    type: 'DOCUMENT_SET_ALERT',
    scope,
    params,
  }),

  removeAlert: (scope: AlertScope): DocumentRemoveAlertAction => ({
    type: 'DOCUMENT_REMOVE_ALERT',
    scope,
  }),

  loadCategories: (categories: Array<CategoryType>): CategoryLoad => ({
    type: 'CATEGORY_LOAD',
    categories,
  }),

  setDocumentModalVisibility: (
    visibility: boolean,
    document?: DocumentType,
    file?: any,
  ): DocumentModalSetVisibility => ({
    type: 'DOCUMENT_MODAL_SET_VISIBILITY',
    visibility,
    document,
    file,
  }),

  setDocumentModalTitle: (title: string): DocumentModalSetTitle => ({
    type: 'DOCUMENT_MODAL_SET_TITLE',
    title,
  }),

  setDocumentModalAvailability: (available: boolean): DocumentModalSetAvailability => ({
    type: 'DOCUMENT_MODAL_SET_AVAILABILITY',
    available,
  }),

  setDocumentModalCategory: (categoryId: number): DocumentModalSetCategory => ({
    type: 'DOCUMENT_MODAL_SET_CATEGORY',
    categoryId,
  }),

  setDocumentModalSegmentation: (segmentation: Array<SegmentationGroupItemType>): DocumentModalSetSegmentation => ({
    type: 'DOCUMENT_MODAL_SET_SEGMENTATION',
    segmentation,
  }),

  setDocumentModalError: (error: string): DocumentModalSetError => ({
    type: 'DOCUMENT_MODAL_SET_ERROR',
    error,
  }),

  setNotificationModalVisibility: (visibility: boolean): NotificationModalSetVisibility => ({
    type: 'NOTIFICATION_MODAL_SET_VISIBILITY',
    visibility,
  }),

  sendNotification: (documentId: number): DocumentSendNotificationAction =>
    apiAction({
      type: 'DOCUMENT_SEND_NOTIFICATION',
      route: '/api/documents/sendNotificationToPlayers',
      method: 'POST',
      payload: {
        documentId,
      },
    }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type DocumentListAction = ReturnType<ActionsFn>;
