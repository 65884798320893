import React, { useCallback } from 'react';
import { t } from 'i18next';

import { tracking } from 'Services/trackingService';
import { useTypedDispatch } from 'Libs/redux/utils';

import MModal from 'Components/modal/MModal/MModal';

import { actions, useModuleSelector } from '../../../redux';

import styles from './ContributorRemoveModal.style';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';

export const ContributorRemoveModal = () => {
  const dispatch = useTypedDispatch();
  const contributorToManage = useModuleSelector((state) => state.manageContributorModal.contributorToManage);
  const labelError = useModuleSelector((state) => state.manageContributorModal.error);
  const visible = useModuleSelector((state) => state.manageContributorModal.isOpen.isDeleteModalOpen || false);

  const { id, email, firstName, lastName, status } = contributorToManage;

  const isActionButtonDisabled = !id;
  const isInvited = status === 'inactive';
  const playerName = isInvited ? '' : `${firstName} ${lastName}`.trim();

  let contentText = t('config_contributors:contributors.modal.remove_contributor_description', {
    playerName,
  });

  if (isInvited) {
    contentText = t('config_contributors:contributors.modal.cancel_invitation_description', {
      email,
    });
  }

  const warningStyle = {
    ...styles.warning,
    ...(isInvited ? styles.hideWarning : styles.warningTop),
  };

  const handleRemoveContributor = useCallback(
    () => dispatch(actions.removeContributor(contributorToManage.id)),
    [dispatch, contributorToManage],
  );

  const handleRemoveEnd = useCallback(
    (res: any) => {
      const { error } = res;

      if (isInvited) {
        tracking.contributorsDeleteInvite();
      }

      if (!error) {
        dispatch(actions.toggleContributorModal({ isDeleteModalOpen: false }));
      }
    },
    [dispatch, isInvited],
  );

  const handleCloseModal = useCallback(
    () => dispatch(actions.toggleContributorModal({ isDeleteModalOpen: false })),
    [dispatch],
  );

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleRemoveContributor}
      onActionEnd={handleRemoveEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={
        isInvited
          ? t('config_contributors:contributors.modal.cancel_invitation_title')
          : t('config_contributors:contributors.modal.remove_contributor_title')
      }
      labelActionButton={
        isInvited
          ? t('config_contributors:contributors.modal.cancel_invitation_button')
          : t('config_contributors:contributors.modal.remove_contributor_button')
      }
      labelSecondButton={t('config_contributors:contributors.modal.cancel_button')}
      actionButtonType="destructive"
      disableActionButton={isActionButtonDisabled}
      labelError={labelError}
    >
      <div>
        <UMarkdown markdown={contentText} style={styles.content} />
        <div style={warningStyle}>{t('config_contributors:contributors.modal.warning_text')}</div>
      </div>
    </MModal>
  );
};
