// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  modal: {
    width: 400,
    height: 'fit-content',
    maxHeight: 650,
    margin: 'auto',
  },
  contentWrapper: {
    padding: '0 20px',
    ...TYPOGRAPHY.BODY3,
  },
  warning: {
    paddingTop: 16,
    color: COLORS.ERROR.DEFAULT,
    marginBottom: 32,
  },
  allCheckbox: {
    marginTop: 16,
  },
};

