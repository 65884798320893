import React, { MouseEventHandler } from 'react';

import { IconName } from 'Components/foundation/icons';
import UFlag from 'Components/unit/UFlag/UFlag';
import UProgressBar from 'Components/unit/UProgressBar/UProgressBar';
import UStatusIndicator, { UStatusType } from 'Components/unit/UStatusIndicator/UStatusIndicator';

import styles from './UDropdownLanguageItem.style';

export type UDropdownLanguageItemProps = {
  style?: React.CSSProperties;
  onClick: MouseEventHandler;
  countryCode: string;
  text: string;
  isDefault?: boolean;
  selected?: boolean;
  translationPercent?: number | null;
  defaultLabel?: string;
  switchToLabel?: string;
  status?: UStatusType;
  statusText?: string;
  validLabel?: string;
  iconOverride?: IconName;
};

type UDropdownLanguageItemState = {
  isHover: boolean;
};

const COMPLETE = 100;

/**
 * Dropdown language item
 *
 * Props:
 * - onClick: Function called when we click on the button
 * - countryCode: The flag country code
 * - text: The country text
 * - isDefault: Specify that this is the default value
 * - selected: Specify that this is the selected value
 * - translationPercent: Add translation progress information
 * - defaultLabel: Label for "default" text
 * - switchToLabel: Label for "Switch to" text
 * - validLabel: Label displayed when translation percent is completed
 * - status: Translation status icon to be displayed instead of a percentage. Does nothing if a percentage is specified
 * - statusText: text to be displayed next to the Translation status icon
 */
export class UDropdownLanguageItem extends React.PureComponent<UDropdownLanguageItemProps, UDropdownLanguageItemState> {
  static defaultProps = {
    style: undefined,
    isDefault: false,
    selected: false,
    translationPercent: null,
    status: null,
    statusText: undefined,
    validLabel: '',
  };

  state = {
    isHover: false,
  };

  render() {
    const { style, countryCode, text, isDefault, defaultLabel } = this.props;
    const conditionalStyle = this.makeConditionalStyle();

    return (
      <div
        style={{ ...styles.wrapper, ...conditionalStyle, ...style }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        onClick={this.handleClick}
      >
        <UFlag countryCode={countryCode} size={16} style={styles.flag} />
        <div style={styles.text}>{text}</div>
        {isDefault ? <div style={styles.captionText}>{`(${defaultLabel})`}</div> : null}
        <div style={styles.fill} />
        {this.renderRightPart()}
        {this.renderStatusIndicator()}
      </div>
    );
  }

  renderStatusIndicator() {
    const { translationPercent, status, statusText, iconOverride } = this.props;
    if (translationPercent !== null || status == null) {
      return null;
    }
    return (
      <div style={styles.indicatorWrapper}>
        <UStatusIndicator
          type={status}
          size={'medium'}
          hideText={false}
          text={statusText}
          style={styles.indicatorIcon}
          icon={iconOverride}
        />
      </div>
    );
  }

  renderHoveredText() {
    const { switchToLabel } = this.props;

    return <div style={styles.hoveredText}>{switchToLabel}</div>;
  }

  renderValidText() {
    const { validLabel } = this.props;

    return (
      <div style={styles.indicatorWrapper}>
        {validLabel}
        <UStatusIndicator type="valid" style={styles.indicatorIcon} />
      </div>
    );
  }

  // eslint-disable-next-line complexity
  renderRightPart() {
    const { selected, translationPercent } = this.props;
    const { isHover } = this.state;

    if (translationPercent === COMPLETE) return this.renderValidText();

    const bar =
      translationPercent !== null ? (
        <div style={styles.barContainer}>
          <UProgressBar percent={translationPercent} />
        </div>
      ) : null;

    if (selected) return bar;

    if (isHover) return this.renderHoveredText();

    return bar;
  }

  handleClick: MouseEventHandler = (e) => {
    const { onClick, selected } = this.props;

    if (!selected) onClick(e);
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  makeConditionalStyle = () => {
    const { selected } = this.props;
    const { isHover } = this.state;

    if (selected) return styles.wrapperSelected;

    if (isHover) return styles.wrapperHovered;

    return styles.wrapperDefault;
  };
}

export default UDropdownLanguageItem;
