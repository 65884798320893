/**
 * Displays an SPhoneInput
 *
 * Props:
 * - defaultCountry: Default country string (format: fr, de, es)
 * - onChange: Function call to submit object with payload after typing and validation
 * - valueChangeFeedbackMessage: String to set under the input as a message
 * - valueChangeFeedbackStatus: String to set the status ('none' | 'error')
 * - defaultPhoneNumber: String to display a default value in the input (format: national number)
 * - label: String to set an input label
 * - placeholder: String to set a placeholder
 * - inputType: String to indicate the type of input to display ('mini' | 'small' | 'large')
 * - style: Object to override component's style
 * - isMandatory: boolean to know if the input was mandatory or not
 */

import React, { useState, useCallback, useMemo, CSSProperties } from 'react';
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber, getCountries, getCountryCallingCode } from 'react-phone-number-input';

import type { CountryCode } from 'libphonenumber-js/types';

import useInputStatus from 'Hooks/useInputStatus/useInputStatus';

import SInputContainer from 'Components/structural/SInputContainer/SInputContainer';
import ULanguageMenu from 'Components/unit/ULanguageMenu/ULanguageMenu';

import styles from './SPhoneInput.style';

// TODO when migrating other input harmonizing it
export type InputType = 'micro' | 'mini' | 'small' | 'large';
export type ValueChangeFeedbackStatus = 'loading' | 'warning' | 'error' | 'success' | 'none';

type CountryInfo = { countryCode: string; dialCode: string };

export type PhoneNumberChangeParams = {
  value: string;
  dialCode: string;
  phoneNumber: string;
  phoneCountryCode: CountryCode;
  isValid: boolean;
};

type PhoneNumberChangeHandler = (params: PhoneNumberChangeParams) => void;

export type SPhoneInputProps = {
  defaultCountry?: string;
  onChange: PhoneNumberChangeHandler;
  valueChangeFeedbackStatus: ValueChangeFeedbackStatus;
  valueChangeFeedbackMessage: string;
  defaultPhoneNumber?: string;
  label?: string;
  placeholder?: string;
  inputType?: InputType;
  style?: CSSProperties;
  isMandatory?: boolean;
};

const UNSUPPORTED_COUNTRIES = ['ac', 'gp', 'mf', 'sj', 'nc', 'pm', 're', 'wf', 'yt', 'sh', 'ta', 'bq'];

const getDialCode = (countryCode: CountryCode) =>
  countryCode ? `+${getCountryCallingCode(countryCode.toUpperCase() as CountryCode)}` : '+33';

const getDefaultExp = (defaultCountry: string) => (defaultCountry ? defaultCountry.toLowerCase() : 'fr');

const isValidCountry = (country: string) => !UNSUPPORTED_COUNTRIES.includes(country);

export const SPhoneInput = ({
  defaultCountry = 'fr',
  onChange = () => {},
  valueChangeFeedbackStatus = 'none',
  valueChangeFeedbackMessage = '',
  defaultPhoneNumber = '',
  label = '',
  placeholder = '',
  inputType = 'small',
  style = {},
  isMandatory = false,
}: SPhoneInputProps) => {
  const [value, setValue] = useState(defaultPhoneNumber || '');
  const [countryCode, setCountryCode] = useState(getDefaultExp(defaultCountry));
  const [dialCode, setDialCode] = useState(getDialCode(countryCode as CountryCode));

  const mandatoryLabel = useMemo(() => label && `${label}  *`, [label]);

  // Handlers
  const { handleFocus, stateStatus } = useInputStatus({});
  const handleChange = useCallback(
    (data) => {
      const inputNumber = data || '';

      setValue(inputNumber);
      onChange({
        value: inputNumber,
        dialCode: dialCode.substring(1),
        phoneNumber: inputNumber.substring(dialCode.length),
        phoneCountryCode: countryCode.toUpperCase() as CountryCode,
        isValid: isValidPhoneNumber(inputNumber),
      });
    },
    [countryCode, dialCode, onChange],
  );

  const countries = useMemo((): CountryInfo[] => {
    const countriesFromLib = getCountries();

    return countriesFromLib.reduce(
      (acc: CountryInfo[], country: CountryCode) =>
        isValidCountry(country.toLowerCase())
          ? [
              ...acc,
              {
                countryCode: country.toLowerCase(),
                dialCode: `+${getCountryCallingCode(country)}`,
              },
            ]
          : acc,
      [],
    );
  }, []);

  const countryAsOptions = useMemo(() => {
    const options = countries.map(
      (country, index) =>
        ({
          type: 'country',
          key: index.toString(),
          props: {
            text: country.dialCode,
            countryCode: country.countryCode,
            selected: country.countryCode === countryCode,
            onClick: () => {
              setCountryCode(country.countryCode);
              setDialCode(country.dialCode);
              setValue('');
            },
          },
        } as const),
    );

    return options;
  }, [countries, countryCode]);

  return (
    <SInputContainer
      valueChangeFeedbackStatus={valueChangeFeedbackStatus || 'none'}
      valueChangeFeedbackMessage={valueChangeFeedbackMessage}
      hideCounter
      inputStateStatus={stateStatus}
      style={{ ...styles.inputWidth, ...style }}
      maxLength={0}
      currentCount={value.length || 0}
      label={isMandatory ? mandatoryLabel : label}
    >
      <ULanguageMenu countryCode={countryCode} dialCode={dialCode} options={countryAsOptions} style={styles.language} />
      <Input
        country={(countryCode && countryCode.toUpperCase()) as CountryCode}
        name="inputContainer"
        value={value}
        style={{ ...styles.textField, ...styles[inputType] }}
        onChange={handleChange}
        className="structural-inputs"
        placeholder={placeholder}
        onFocus={handleFocus(true)}
        onBlur={handleFocus(false)}
      />
    </SInputContainer>
  );
};

export default SPhoneInput;
