/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Displays a SDivider component
 *
 * - text: Text displayed
 * - style: Used to override the style
 */

import * as React from 'react';

import styles from './SDivider.style';

type Direction =
  | 'vertical'
  | 'horizontal'
;

type Props = {|
  text: string,
  style: Object,
  direction: Direction,
|};

export class SDivider extends React.Component<Props> {
  static defaultProps = {
    text: '',
    style: undefined,
    direction: 'horizontal',
  };

  render() {
    const { style } = this.props;
    const directionStyle = this.getDirectionStyle();

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {this.renderText()}
        <div style={directionStyle} />
      </div>
    );
  }

  renderText = () => {
    const { text } = this.props;

    if (!text)
      return null;

    return (
      <div style={styles.text}>
        {text}
      </div>
    );
  };

  getDirectionStyle = () => {
    const { direction, text } = this.props;

    if (direction === 'vertical')
      return text ? styles.verticalDividerWithText : styles.verticalDivider;

    return styles.horizontalDivider;
  };
}

export default SDivider;
