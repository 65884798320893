import { CSSProperties } from 'react';

import { UCheckboxProps } from 'Components/unit/UCheckbox/UCheckbox';
import { CallbackOption } from 'Components/structural/STable/components/CallbackOptions';
import type { Row } from 'react-table';
import { TooltipPosition } from 'Components/structural/SCounter/SCounter';

export type TableMessage = {
  message: string;
  type: Message['type'];
};

export type RowMessage = {
  content: string;
  type: Message['type'];
};

export type Message = {
  content: string;
  type: 'error' | 'success' | 'warning';
};

type EmptyHeaderParams = {
  style?: CSSProperties;
};

export type CheckBoxHeaderParams = {
  onClick: UCheckboxProps['onClick'];
  checkBoxStatus: UCheckboxProps['checkBoxStatus'];
  style?: CSSProperties;
  isDisabled?: boolean;
};

type Align = 'left' | 'center';

export type CellHeaderParams = {
  text: string;
  onAction: (...args: any[]) => any;
  canBeSorted?: boolean;
  align?: Align;
  style?: CSSProperties;
};

export enum HeaderType {
  EmptyHeader = 'EmptyHeader',
  CellHeader = 'UTableCellHeader',
  CheckBoxHeader = 'UCheckbox',
}

type CoreHeader = {
  id: string;
  accessor: string;
  wrapperStyle?: CSSProperties;
  isVisible?: boolean;
  disableResizing?: boolean;
  width?: string;
  minWidth?: number;
  maxWidth?: number;
  disableSorting?: boolean;
};

type EmptyHeader = CoreHeader & {
  type: HeaderType.EmptyHeader;
  headerParams?: EmptyHeaderParams;
};

type CellHeader = CoreHeader & {
  type: HeaderType.CellHeader;
  headerParams: CellHeaderParams;
};

type CheckBoxHeader = CoreHeader & {
  type: HeaderType.CheckBoxHeader;
  headerParams: CheckBoxHeaderParams;
};

export type Header = CellHeader | CheckBoxHeader | EmptyHeader;

export type LockedTooltipInfo = null | {
  content: string;
  position: TooltipPosition;
  maxWidth?: number;
};

export type OriginalRow = {
  id: string | number;
  locked?: boolean;
  callbackOptions: CallbackOption[];
  dataAccessor: any;
  lockedInfo?: LockedTooltipInfo;
  rowMessage?: RowMessage;
};

export type STableRow = Row<OriginalRow>;

export enum TABLE_ACTION_TYPES {
  SEARCH = 'SEARCH',
  EXPORT = 'EXPORT',
  CREATE = 'CREATE',
}
