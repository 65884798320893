import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 435,
    justifyContent: 'center',
    padding: '40px',
    height: '100%',
  },
  title: {
    ...TYPOGRAPHY.HERO_L_BOLD,
    marginBottom: '32px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstFormContainer: {
    display: 'flex',
    marginTop: '32px',
    marginBottom: '32px',
  },
  idSection: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
  },
  idField: {
    marginBottom: '24px',
  },
  thematicSection: {
    marginBottom: '24px',
  },
  thematicLabel: {
    marginBottom: '8px',
  },
  thematicSelector: {
    marginBottom: '24px',
  },
  thematicButton: {
    marginLeft: '8px',
  },
});
