import request from 'services/requestService';

var uploader = {};

function percentProgressWrapper(cb) {
  if (!cb) return;

  return function (e) {
    var percent = Math.floor((e.loaded / e.total) * 100);

    cb(percent);
  };
}

uploader.upload = function upload(file, type, typeId, progressCallback) {
  if (type.toLowerCase() === 'image')
    var path = 'api/images/upload';

  else
    throw new Error('Type ' + type + ' not supported for upload.');

  return request.upload(path, file, percentProgressWrapper(progressCallback), {
    typeId: typeId,
  })
    .then(function sendResponse(response) {
      if (response.result)
        return response.result[0];

      return response;
    });

};

export default uploader;
