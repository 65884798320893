import React, { FC } from 'react';
import { t } from 'i18next';

import UButton from 'Components/unit/UButton/UButton';

import styles from '../ActivityList.style';

export type CreateActivityButtonProps = {
  onClick: () => void;
};

export const CreateActivityButton: FC<CreateActivityButtonProps> = ({ onClick }) => {
  return (
    <UButton
      style={styles.createActivityButton}
      text={t('activities:list.create_activity')}
      type="accentuated"
      onClick={onClick}
      trackingAction="create"
      trackingContext="activity"
    />
  );
};
