import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 1000,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 32,
  },
});

export default styleSheet;
