import React, { useRef, useState, useMemo, useCallback, RefObject, useEffect, ChangeEvent } from 'react';
import { t } from 'i18next';

import type { FunctionComponent, CSSProperties, KeyboardEvent } from 'react';

import useInputStatus from 'Hooks/useInputStatus/useInputStatus';

import { COLORS } from 'Components/foundation';

import OutsideClickWrapper from 'Components/hoc/OutsideClickWrapper/OutsideClickWrapper';

import UIcon from 'Components/unit/UIcon/UIcon';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import SInputContainer from 'Components/structural/SInputContainer/SInputContainer';

import styles, { inputContainerStyle } from './SSelect.style';

export type SSelectItem = {
  id: number;
  label: string;
  value: string;
};

export type SSelectType = 'small' | 'large';

export type SSelectProps = {
  onSelect: (item: SSelectItem) => unknown;

  items: ReadonlyArray<SSelectItem>;
  required?: boolean;
  label?: string;
  selected?: SSelectItem | null;
  placeholder?: string;
  type?: SSelectType;
  style?: CSSProperties;
  canSearch?: boolean;
  onCreate?: (item: SSelectItem) => void;
};

const KeyInteractionType = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  TAB: 'Tab',
};

const getKey = (item: SSelectItem) => `${item.id}-${item.value}`;

const REGEX_ACCENTS = /[\u0300-\u036f]/g;

const filterByValue = (item: SSelectItem, value: string, strict = false) => {
  const itemWithoutAccents = item.label.normalize('NFD').replace(REGEX_ACCENTS, '');
  const valueWithoutAccents = value.normalize('NFD').replace(REGEX_ACCENTS, '');

  return strict
    ? itemWithoutAccents.toLowerCase() === valueWithoutAccents.toLowerCase()
    : itemWithoutAccents.toLowerCase().startsWith(valueWithoutAccents.toLowerCase());
};

/**
 * Display a select input
 *
 * Props:
 * - selected: The item selected by user, can be null if user has not selected item yet
 * - placeholder: The text to display when selection is null
 * - items: The items to display in the item box
 * - label: Set a input label
 * - onSelect: Function called when an item is selected
 * - type: Defines if the component should be small or large
 * - canSearch: Boolean that enable or disable the search
 * - onCreate: Callback called after an itemn creation
 * - style: override style of the component
 */
export const SSelect: FunctionComponent<SSelectProps> = ({
  items,
  style,
  label = '',
  type = 'large',
  required = false,
  selected = null,
  canSearch = false,
  placeholder = t('structural_component:s_select.select_an_item'),
  onSelect,
  onCreate = () => {},
}) => {
  // States
  const [filteredItems, setFilteredItems] = useState<SSelectItem[]>(items.map((item) => ({ ...item })));

  const [value, setValue] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [isMouseUsed, setIsMouseUsed] = useState(true);
  const [isWriting, setIsWriting] = useState(false);

  const itemRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});

  const { handleFocus, stateStatus } = useInputStatus({ autofocus: false });

  const focused = useMemo(() => stateStatus === 'focused', [stateStatus]);

  // Handlers
  const handleToggleExpand = useCallback(() => {
    if (!expanded && filteredItems.length) {
      setActiveIndex(0);
    }

    const newExpanded = !expanded;

    setExpanded(newExpanded);
    handleFocus(newExpanded)({} as any);
  }, [expanded, filteredItems, handleFocus]);

  const handleMouseMove = useCallback(() => {
    setIsMouseUsed(true);
  }, []);

  const closeSelectionMenu = useCallback(
    (inputLabel = '') => {
      handleFocus(false)({} as any);
      setExpanded(false);
      setIsWriting(false);
      setFilteredItems(items.map((item) => ({ ...item })));
      setValue(inputLabel || (selected ? selected.label : ''));
    },
    [selected, items, handleFocus],
  );

  const handleUpAndDown = useCallback(
    (key: string, event: KeyboardEvent) => {
      const authorizedKeys = [KeyInteractionType.DOWN, KeyInteractionType.UP];

      if (!authorizedKeys.includes(key)) {
        return;
      }

      event.preventDefault();

      setActiveIndex((prevActiveIndex) => {
        const offset = key === KeyInteractionType.UP ? -1 : 1;
        const newIndex = prevActiveIndex + offset;

        /**
         * Handles looping through the selection items:
         * - clicking up on the first item select the last item
         * - clicking down on the last item select the first item
         */
        if (key === KeyInteractionType.DOWN) {
          return newIndex === filteredItems.length ? 0 : newIndex;
        }

        return newIndex === -1 ? filteredItems.length - 1 : newIndex;
      });
    },
    [filteredItems],
  );

  const handleEnter = useCallback(
    (key: string, event: KeyboardEvent) => {
      if (key !== KeyInteractionType.ENTER) {
        return;
      }

      event.preventDefault();

      if (!expanded) {
        handleToggleExpand();
        return;
      }

      const item = filteredItems[activeIndex];

      if (item) {
        onSelect(item);
        closeSelectionMenu(item.label);
      }

      if (item && item.id === -1) {
        onCreate?.(item);
      }
    },
    [expanded, filteredItems, activeIndex, handleToggleExpand, onSelect, closeSelectionMenu, onCreate],
  );

  const handleEscapeAndTab = useCallback(
    (key: string, event: KeyboardEvent) => {
      const authorizedKeys = [KeyInteractionType.ESCAPE, KeyInteractionType.TAB];

      if (!authorizedKeys.includes(key)) {
        return;
      }

      const isTabKey = key === KeyInteractionType.TAB;

      // handle tab navigation between items
      if (isTabKey && expanded) {
        event.preventDefault();
        handleUpAndDown(KeyInteractionType.DOWN, event);
        return;
      }

      // pass over without preventing default tab behavior
      if (isTabKey && !expanded) {
        return;
      }

      event.preventDefault();

      closeSelectionMenu();
    },
    [closeSelectionMenu, expanded, handleUpAndDown],
  );

  const handlers = useMemo(
    () => ({
      [KeyInteractionType.UP]: handleUpAndDown,
      [KeyInteractionType.DOWN]: handleUpAndDown,
      [KeyInteractionType.ENTER]: handleEnter,
      [KeyInteractionType.ESCAPE]: handleEscapeAndTab,
      [KeyInteractionType.TAB]: handleEscapeAndTab,
    }),
    [handleUpAndDown, handleEnter, handleEscapeAndTab],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      const isHandledKey = Object.values(KeyInteractionType).includes(key);

      if (!isHandledKey) {
        return;
      }

      handlers[key](key, event);

      setIsMouseUsed(false);
    },
    [handlers],
  );

  const makeItemClickHandler = useCallback(
    (item: SSelectItem) => () => {
      onSelect(item);

      if (item.id === -1) {
        onCreate?.(item);
      }

      closeSelectionMenu(item.label);
    },
    [onCreate, onSelect, closeSelectionMenu],
  );

  const makeHoverHandler = useCallback(
    (index: number) => () => {
      setActiveIndex(index);
    },
    [],
  );

  const handleClickOutside = useCallback(
    (isClickedOutside: boolean) => {
      if (isClickedOutside) {
        closeSelectionMenu();
      }
    },
    [closeSelectionMenu],
  );

  const handleInputValue = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value: enteredValue } = target;

      const newFilteredItems = !enteredValue
        ? items.map((item) => ({ ...item }))
        : items.filter((item) => filterByValue(item, enteredValue));

      const matchStrictly = newFilteredItems.filter((item) => filterByValue(item, enteredValue, true));
      const createItemObject = {
        id: -1,
        label: enteredValue,
        value: enteredValue,
      };

      if (!matchStrictly.length && enteredValue) {
        newFilteredItems.push(createItemObject);
      }

      const newActiveIndex =
        !newFilteredItems.length || (newFilteredItems.length === 1 && !matchStrictly.length)
          ? newFilteredItems.length - 1
          : 0;

      setIsWriting(true);
      setValue(enteredValue);
      setFilteredItems(newFilteredItems);
      setActiveIndex(newActiveIndex);
    },
    [items],
  );

  const scrollIntoActiveItems = useCallback(() => {
    const item = filteredItems[activeIndex];

    if (item) {
      const ref = itemRefs.current[getKey(item)];

      ref?.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [filteredItems, activeIndex]);

  useEffect(() => {
    // INFO disable auto-scroll when mouse hovering to prevent side effects
    if (!isMouseUsed) {
      scrollIntoActiveItems();
    }
  }, [scrollIntoActiveItems, isMouseUsed]);

  const inputProperties = useMemo(() => {
    if (!focused && selected) {
      return { placeHolder: placeholder, content: selected.label };
    }

    if (focused && !isWriting && canSearch && value) {
      return { content: '', placeHolder: value };
    }

    return { content: value, placeHolder: placeholder };
  }, [value, focused, selected, isWriting, canSearch, placeholder]);

  return (
    <OutsideClickWrapper
      onClick={handleClickOutside}
      style={{
        ...styles.wrapper,
        ...style,
      }}
    >
      <div data-test-id="input-container-wrapper" onClick={handleToggleExpand}>
        <SInputContainer
          label={label}
          required={required}
          inputStateStatus={stateStatus}
          childrenStyle={inputContainerStyle[type]}
        >
          <input
            type="text"
            className="structural-inputs"
            value={inputProperties.content}
            placeholder={inputProperties.placeHolder}
            onKeyDown={handleKeyDown}
            onChange={handleInputValue}
            autoComplete="off"
            readOnly={!expanded || !canSearch}
            style={{
              cursor: canSearch ? 'text' : 'pointer',
              width: '100%',
            }}
          />
          <div style={styles.iconContainer}>
            <UIcon name="arrow-down" size={8} color={COLORS.TEXT.SECONDARY_DEFAULT} />
          </div>
        </SInputContainer>
      </div>
      {expanded && (
        <div
          onKeyDown={handleKeyDown}
          onMouseMove={handleMouseMove}
          // INFO tabIndex is required to handle keydown events
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          style={styles.itemBox}
          data-test-id="SSELECT_LIST_REF"
        >
          {filteredItems.length === 1 && filteredItems[0].id === -1 && (
            <div
              data-test-id="SSELECT_NOT_FOUND"
              style={{
                ...styles.item,
                ...styles.notFound,
              }}
            >
              No result found
            </div>
          )}
          {filteredItems.map((item, idx) => {
            const key = getKey(item);
            const ref = React.createRef<HTMLDivElement>();

            itemRefs.current[key] = ref;

            return (
              <div
                data-test-id="SSELECT_ITEM"
                ref={ref}
                key={key}
                style={{
                  ...styles.item,
                  ...(idx === activeIndex ? styles.itemActive : {}),
                }}
              >
                <UDropdownItem
                  id={idx}
                  text={item.id === -1 ? `Create "${value}"` : item.label}
                  style={styles.item}
                  removeMouseFocus={!isMouseUsed}
                  itemIndication={filteredItems.length === 1 && filteredItems[0].id === -1 ? 'press enter' : ''}
                  onClick={makeItemClickHandler(item)}
                  onHover={makeHoverHandler(idx)}
                />
              </div>
            );
          })}
        </div>
      )}
    </OutsideClickWrapper>
  );
};

export default SSelect;
