import m from 'm';
import Bus from 'services/pubSubService';
import button from 'components/button';
import Player from 'models/Player';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.email = m.prop(args.email || null);
  self.sent = m.prop(false);

  self.askReset = function askReset() {
    return Player.askPasswordReset(self.email())
      .then(self.sent.bind(null, true))
      .catch(function displayError(err) {
        Bus.trigger('popup:error', err.msg);
      })
      .finally(m.redraw);

  };

};

component.view = function view(c, args) {
  return m('.popup.resetPassword__popup', [
    m('.popup__body', [
      m('.popup__title', 'Reset password'),
      m('.popup__subtitle', 'An email will be sent to you in order to reset your password.'),
      m('.popup__body__content', {
        class: c.sent() ? 'hidden' : '',
      }, [
        m('.resetPassword__popup__instruction', 'Type the mail associated to your admin account'),
        m('input.resetPassword__popup__input', {
          value      : c.email(),
          oninput    : m.withAttr('value', c.email),
          placeholder: 'Your email',
        }),
      ]),
      m('.popup__body__content', {
        class: c.sent() ? '' : 'hidden',
      }, [
        m('.resetPassword__popup__confirm', 'We just sent you an email'),
      ]),
    ]),
    m('.popup__footer.clearFix', [!c.sent()
      ? m(button, {
        value : 'Send',
        class : 'button--right button--action button--ok',
        action: c.askReset,
      })
      : m(button, {
        value : 'Ok',
        class : 'button--right button--action button--ok',
        action: args.close,
      }),
    ]),
  ]);
};

export default component;
