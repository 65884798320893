import { combineReducers } from 'redux';

import type { ActivityListState } from 'Pages/ActivityList/redux/reducer';
import type { ActivityState } from 'Pages/Activity/redux/reducer';
import type { AlertManagerState } from 'Pages/AlertManager/redux/reducer';
import type { AmbassadorState } from 'Pages/Ambassador/redux/reducer';
import type { CategoryListState } from 'Pages/CategoryList/redux/reducer';
import type { ContentState } from 'Pages/Content/redux/reducer';
import type { ContributorListState } from 'Pages/Contributor/redux/reducer';
import type { CoursesState } from 'Pages/Courses/redux';
import type { DocumentListState } from 'Pages/DocumentList/redux/reducer';
import type { HomepageState } from 'Pages/Homepage/redux';
import type { KnowledgeGridState } from 'Pages/KnowledgeGrid/redux/reducer';
import type { KnowledgeTranslationState } from 'Pages/KnowledgeTranslation/redux/reducer';
import type { LeaderboardState } from 'Pages/Leaderboard/redux/reducer';
import type { LoginState } from 'Pages/Login/redux/reducer';
import type { NpsState } from 'Pages/NPS/redux/reducer';
import type { PlayersState } from 'Pages/Players/redux/reducer';
import type { ResetPasswordState } from 'Pages/ResetPassword/redux/reducer';
import type { SignUpState } from 'Pages/SignUp/redux/reducer';
import type { TwoFactorState } from 'Pages/TwoFactor/redux/reducer';
import type { UpdatePasswordState } from 'Pages/UpdatePassword/redux/reducer';
import type { QueueState } from 'Libs/queue/redux';

import { reducer as activity, defaultState as activityState } from 'Pages/Activity/redux/reducer';
import { reducer as activityList, defaultState as activityListState } from 'Pages/ActivityList/redux/reducer';
import { reducer as alertManager, defaultState as alertState } from 'Pages/AlertManager/redux/reducer';
import { reducer as ambassador, defaultState as ambassadorState } from 'Pages/Ambassador/redux/reducer';
import { reducer as categoryList, defaultState as categoryListState } from 'Pages/CategoryList/redux/reducer';
import { reducer as content, defaultState as contentState } from 'Pages/Content/redux/reducer';
import { reducer as contributor, defaultState as contributorState } from 'Pages/Contributor/redux/reducer';
import { reducer as courses, defaultState as coursesState } from 'Pages/Courses/redux/slice';
import { reducer as documentList, defaultState as documentListState } from 'Pages/DocumentList/redux/reducer';
import { reducer as homepage, defaultState as homepageState } from 'Pages/Homepage/redux/slice';
import { reducer as knowledgeGrid, defaultState as knowledgeGridState } from 'Pages/KnowledgeGrid/redux/reducer';
import { reducer as knowledgeTranslation, defaultState as knowledgeTranslationState } from 'Pages/KnowledgeTranslation/redux/reducer';
import { reducer as leaderboard, defaultState as leaderboardState } from 'Pages/Leaderboard/redux/reducer';
import { reducer as login, defaultState as loginState } from 'Pages/Login/redux/reducer';
import { reducer as nps, defaultState as npsState } from 'Pages/NPS/redux/reducer';
import { reducer as players, defaultState as playersState } from 'Pages/Players/redux/reducer';
import { reducer as resetPassword, defaultState as resetPasswordState } from 'Pages/ResetPassword/redux/reducer';
import { reducer as signUp, defaultState as signUpState } from 'Pages/SignUp/redux/reducer';
import { reducer as twoFactor, defaultState as twoFactorState } from 'Pages/TwoFactor/redux/reducer';
import { reducer as updatePassword, defaultState as updatePasswordState } from 'Pages/UpdatePassword/redux/reducer';

import { Entities, entityReducers, entityState } from 'Store/entities/entities.reducer';

import { reducer as queue, defaultState as queueState } from 'Libs/queue/redux/slice';

import type { Action } from './types';

export type Pages = {
  activity: ActivityState,
  activityList: ActivityListState,
  alertManager: AlertManagerState,
  ambassador: AmbassadorState,
  categoryList: CategoryListState,
  content: ContentState,
  contributor: ContributorListState,
  courses: CoursesState,
  documentList: DocumentListState,
  homepage: HomepageState,
  knowledgeGrid: KnowledgeGridState,
  knowledgeTranslation: KnowledgeTranslationState,
  leaderboard: LeaderboardState,
  login: LoginState,
  nps: NpsState,
  players: PlayersState,
  resetPassword: ResetPasswordState,
  signUp: SignUpState,
  twoFactor: TwoFactorState,
  updatePassword: UpdatePasswordState,
};

export type Services = {
  queue: QueueState,
};

export type State = {
  pages: Pages,
  services: Services,
  entities: Entities,
};

export const defaultState: State = {
  pages: {
    activity: { ...activityState },
    activityList: { ...activityListState },
    alertManager: { ...alertState },
    ambassador: { ...ambassadorState },
    categoryList: { ...categoryListState },
    content: { ...contentState },
    contributor: { ...contributorState },
    courses: { ...coursesState },
    documentList: { ...documentListState },
    homepage: { ...homepageState },
    knowledgeGrid: { ...knowledgeGridState },
    knowledgeTranslation: { ...knowledgeTranslationState },
    leaderboard: { ...leaderboardState },
    login: { ...loginState },
    nps: { ...npsState },
    players: { ...playersState },
    resetPassword: { ...resetPasswordState },
    signUp: { ...signUpState },
    twoFactor: { ...twoFactorState },
    updatePassword: { ...updatePasswordState },
  },
  services: {
    queue: { ...queueState },
  },
  entities: entityState,
};

export const reducer = combineReducers<State, Action>({
  pages: combineReducers<Pages, Action>({
    activity,
    activityList,
    alertManager,
    ambassador,
    categoryList,
    content,
    contributor,
    courses,
    documentList,
    homepage,
    knowledgeGrid,
    knowledgeTranslation,
    leaderboard,
    login,
    nps,
    players,
    resetPassword,
    signUp,
    twoFactor,
    updatePassword,
  }),
  services: combineReducers<Services, Action>({
    queue,
  }),
  entities: entityReducers,
});
