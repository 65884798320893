// @flow

import React from 'react';
import { useTranslation } from 'react-i18next'; 
import MModal from 'Components/modal/MModal/MModal';

import styles from './ContentSaveModal.style';

type Props = {|
  visible: boolean,
  onAction: () => Promise<any>,
  onActionEnd: () => mixed,
  onDismiss: () => mixed,
  onClose: () => mixed,
|};

export const ContentSaveModal = ({
  visible,
  onAction,
  onActionEnd,
  onDismiss,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return (
    <MModal
      visible={visible}
      onCloseModal={onClose}
      onAction={onAction}
      onActionEnd={onActionEnd}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('contents:save_modal.title')}
      labelActionButton={t('contents:save_modal.save')}
      labelSecondButton={t('contents:save_modal.leave')}
      actionButtonType="accentuated"
    >
      <div style={styles.content}>
      {t('contents:save_modal.content')}
      </div>
    </MModal>
  );
};
