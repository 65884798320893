import React, { useCallback, useState } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import { summarizeText } from 'Libs/ia/summarize';
import { isFeatureEnabled } from 'Services/feature-flags';

import { COLORS } from 'Components/foundation';

import SMicroKnowledgeListItem from 'Components/structural/SMicroKnowledgeListItem/SMicroKnowledgeListItem/SMicroKnowledgeListItem';
import SSubtleInput from 'Components/structural/SSubtleInput/SSubtleInput';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import { enqueueBasicAlert } from 'Pages/AlertManager';
import { useModuleSelector as useCourseModuleSelector } from 'Pages/Courses/redux';

import { MicroKnowledgeItemSharedProps } from '../types/shared-props';
import { styles } from './STextMicroKnowledgeItem.style';

const MICROKNOWLEDGE_COLOR = COLORS.WHITE.DEFAULT;

export type STextMicroKnowledgeItemProps = MicroKnowledgeItemSharedProps & {
  isChild?: boolean;
  content: string;
  backgroundColor?: CSSProperties['backgroundColor'];
  onContentChange?: (id: string, newContent: string) => void;
};

/*
 * STextMicroKnowledgeItemItem:
 * Props:
 * - id: number to distinguish one MicroKnowledge from another
 * - isChild: boolean that states whether actual component is child or not
 * - title: The title of the component
 * - content: The content of the component
 * - backgroundColor: color which is applied to the container
 * - onContentChange: callback to modify the content
 * - onAdd: (The plus icon onClick) callback to add a new Chapter or a SText
 * - onTitleChange: callback to modify the title
 * - style: to modify the component's style
 * - options: The options array for the SOptionsMenu
 */

export const STextMicroKnowledgeItem = ({
  id,
  backgroundColor = MICROKNOWLEDGE_COLOR,
  isChild = false,
  title,
  content,
  onContentChange,
  onTitleChange,
  onAdd,
  style,
  options,
}: STextMicroKnowledgeItemProps) => {
  const withSummarize = isFeatureEnabled('summarize-text');
  const [allowScroll, setAllowScroll] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const course = useCourseModuleSelector((state) => state.edition.course);

  const onFocusChange = useCallback(() => {
    setAllowScroll(true);
    setIsFocus(true);
  }, []);

  const onBlurChange = useCallback(() => {
    setAllowScroll(false);
    setIsFocus(false);
  }, []);

  const handleAdd = useCallback(() => onAdd?.(id), [onAdd, id]);

  const handleTitleChange = useCallback((newValue: string) => onTitleChange?.(id, newValue), [onTitleChange, id]);

  const handleContentChange = useCallback((newValue: string) => onContentChange?.(id, newValue), [onContentChange, id]);

  const textareaMaxHeight = isFocus ? '200px' : '43px';

  const textStyle = content.length > 500 ? { color: COLORS.ALERT.ERROR } : { color: COLORS.BLUE_GRAY.DEFAULT };

  const handleSummarize = async () => {
    const summarizedText = await summarizeText({ text: content, languageCode: course?.language || 'en-US' });
    if (summarizedText.error) {
      enqueueBasicAlert({
        id: 'COURSE_SUMMARIZATION_ERROR',
        text: 'Error while summarization',
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    }
    if (summarizedText.result) {
      handleContentChange(summarizedText.result.text);
    }
  };
  return (
    <SMicroKnowledgeListItem
      id={id}
      isChild={isChild}
      contextMenuOptions={options}
      backgroundColor={backgroundColor}
      style={style}
      onAdd={handleAdd}
    >
      <SSubtleInput
        inputType="input"
        style={styles.title}
        value={title}
        placeholder={t('courses:course_edition.type_title')}
        maxLength={40}
        onChange={handleTitleChange}
        debounced
      />
      <SSubtleInput
        inputType="textarea"
        style={styles.content}
        value={content}
        placeholder={t('courses:course_edition.type_content')}
        maxLength={withSummarize ? undefined : 500}
        maxHeight={textareaMaxHeight}
        onChange={handleContentChange}
        onFocus={onFocusChange}
        onBlur={onBlurChange}
        allowScroll={allowScroll}
        /**
         * When allow scroll is set to false, reset the
         * textarea scroll.
         */
        forceTextareaScroll={!allowScroll}
        debounced
      />
      {withSummarize && content.length > 500 && (
        <>
          {content.length > 500 && (
            <UButtonWithLoading
              text="Summarize"
              onClick={handleSummarize}
              onRequestEnd={() => {}}
              style={{ marginRight: 8 }}
            />
          )}
          <div style={{ ...textStyle, marginRight: 8 }}>{content.length}/500</div>
        </>
      )}
    </SMicroKnowledgeListItem>
  );
};

export default STextMicroKnowledgeItem;
