import React from 'react';
import Enum from 'Models/Enum';

import { useModuleSelector } from '../redux';

import { AudienceEmailList } from './components/AudienceEmailList/AudienceEmailList';
import { AudienceMagicCode } from './components/AudienceMagicCode/AudienceMagicCode';
import { AudienceNavigationBlock } from './components/AudienceNavigationBlock/AudienceNavigationBlock';
import { AudienceSegments } from './components/AudienceSegments/AudienceSegments';

import styles from './Audience.style';

export const Audience = () => {
  const mode = useModuleSelector((state) => state.audience.mode);
  const isTargeModeReset = useModuleSelector((state) => state.isTargetModeReset);

  const componentsByMode: { [key: number]: React.ComponentType } = {
    [Enum.audienceTargetMode.MAGIC_CODE]: AudienceMagicCode,
    [Enum.audienceTargetMode.SEGMENTATION]: AudienceSegments,
    [Enum.audienceTargetMode.EMAIL_LIST]: AudienceEmailList,
  };

  const ComponentToRender = componentsByMode[mode];

  return (
    <div style={styles.wrapper}>
      <div style={styles.box}>{!mode || isTargeModeReset ? <AudienceNavigationBlock /> : <ComponentToRender />}</div>
    </div>
  );
};
