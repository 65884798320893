import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  dateContainer: {
    display: 'inline-flex',
    marginRight: '40px',
    gap: '8px',
  },
  dates: {
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
  },
});
