// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  scoreDifference: {
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 24,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 6,
    paddingLeft: 8,
  },
  scoreDifferencePositive: {

    // Used for 13% opacity
    backgroundColor: COLORS.SUCCESS.DEFAULT + '21',
  },
  scoreDifferenceNegative: {

    // Used for 13% opacity
    backgroundColor: COLORS.ERROR.DEFAULT + '21',
  },
  difference: {
    ...TYPOGRAPHY.BODY3,
    marginLeft: 6,
    marginRight: 2,
  },
  daysBetweenScores: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
};
