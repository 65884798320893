import { useEffect, useState } from 'react';

import type { Filter } from 'Libs/filter/types';

export const resetItemsSelection = (filters: Filter[]) =>
  filters.map((filter) => ({
    ...filter,
    items: filter.items.map((filterItem) => ({ ...filterItem, selected: false })),
  }));

export const getAllSelectedItems = (filters: Filter[]) =>
  filters
    .reduce((acc, filter) => [...acc, ...(filter?.items || [])], [] as Filter['items'])
    .filter((item) => item?.selected);

export const areSomeItemsSelected = (filters: Filter[]) => getAllSelectedItems(filters)?.length > 0;

export const didFilterConfigChange = (oldFilters: Filter[], newFilters: Filter[]) => {
  const oldFilterCategories = oldFilters.map((filter) => filter.category).join('');
  const newFilterCategories = newFilters.map((filter) => filter.category).join('');

  if (oldFilterCategories !== newFilterCategories) {
    return true;
  }

  const oldFilterItems = oldFilters.map((filter) => filter.items.map((item) => item.id).join('')).join('');
  const newFilterItems = newFilters.map((filter) => filter.items.map((item) => item.id).join('')).join('');

  if (oldFilterItems !== newFilterItems) {
    return true;
  }

  return false;
};

export const toggleItemSelection = (items: Filter['items'], itemId: number) => {
  return items.map((item) => {
    if (item.id !== itemId) {
      return item;
    }

    return {
      ...item,
      selected: !item.selected,
    };
  });
};

export const useSFiltersSelectGroup = (filters: Filter[], onFiltersChanged: (filters: Filter[]) => void) => {
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>(filters);

  useEffect(() => {
    if (didFilterConfigChange(selectedFilters, filters)) {
      setSelectedFilters(filters);
    }
  }, [filters]);

  const handleSelectFilter = (filter: Filter, filterIndex: number) => {
    setSelectedFilters((oldFilters) => {
      const firstPart = oldFilters.slice(0, filterIndex);
      const secondPart = oldFilters.slice(filterIndex + 1);
      const newFilters = [...firstPart, filter, ...secondPart];

      onFiltersChanged(newFilters);

      return newFilters;
    });
  };

  const handleDeleteFilter = (filterIndex: number, itemToDeleteId: number) => {
    setSelectedFilters((oldFilters) => {
      const newFilters = oldFilters.map((oldFilter, index) => {
        if (filterIndex !== index) {
          return oldFilter;
        }

        return {
          ...oldFilter,
          items: toggleItemSelection(oldFilter.items, itemToDeleteId),
        };
      });

      onFiltersChanged(newFilters);

      return newFilters;
    });
  };

  const resetFilters = () => {
    const newFilters = resetItemsSelection(filters);
    setSelectedFilters(newFilters);
    onFiltersChanged(newFilters);
  };

  return {
    selectedFilters,
    setSelectedFilters,
    handleSelectFilter,
    handleDeleteFilter,
    resetFilters,
    areItemsSelected: areSomeItemsSelected(selectedFilters),
  };
};
