import { mergeDeep } from 'Libs/mergeDeep';

import createPlayer from './Player';
import type { PlayerType } from './Player';

export type AmbassadorType = Readonly<{
  id: number;
  player: PlayerType;
}>;

export default function createAmbassador(values?: any): AmbassadorType {
  const defaultState: AmbassadorType = {
    id: 0,
    player: createPlayer(),
  };

  if (!values) {
    return defaultState;
  }

  const player = createPlayer(values.player);

  return mergeDeep(defaultState, { ...values, player });
}
