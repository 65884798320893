import { KeyboardEvent } from 'react';

const Keys = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
  TAB: 'Tab',
};

const handleKeyUpAndDown = (event: KeyboardEvent<HTMLDivElement>, dropdownListId: string, inputId: string) => {
  event.preventDefault();

  // If the focus is currently on the input html element, focus the first element in the dropdownlist
  if (event.currentTarget.tagName === 'INPUT' && event.key !== Keys.UP) {
    const dropdownlist = document.getElementById(dropdownListId);

    if (!dropdownlist?.childNodes.length) {
      return;
    }

    const firstChild = dropdownlist.childNodes[0] as HTMLElement;

    return firstChild?.focus?.();
  }

  const direction = event.key === Keys.UP ? 'previousSibling' : 'nextSibling';
  const nextElement = document.activeElement?.[direction] as HTMLElement;

  // Refocus input if there is no previous element
  if (!nextElement && event.key === Keys.UP) {
    const input = document.getElementById(inputId);
    return input?.focus();
  }

  return nextElement?.focus?.();
};

const handleConfirmation = (event: KeyboardEvent<HTMLDivElement>, onSelect: (id: number) => void) => {
  if (event.currentTarget.tagName === 'INPUT') {
    return;
  }

  event.preventDefault();

  const id = parseInt((document.activeElement as HTMLElement)?.dataset.itemId || '', 10);

  if (isNaN(id)) return;

  return onSelect(id);
};

type KeyHandlerFactoryParams = {
  dropdownListId: string;
  inputId: string;
  onSelect: (id: number) => void;
  onCancel: () => void;
};

export const keyHandlersFactory = ({
  dropdownListId,
  inputId,
  onSelect,
  onCancel,
}: KeyHandlerFactoryParams): Record<string, (evt: KeyboardEvent<HTMLDivElement>) => void> => ({
  [Keys.UP]: (evt) => handleKeyUpAndDown(evt, dropdownListId, inputId),
  [Keys.DOWN]: (evt) => handleKeyUpAndDown(evt, dropdownListId, inputId),
  [Keys.TAB]: (evt) => handleKeyUpAndDown(evt, dropdownListId, inputId),
  [Keys.ENTER]: (evt) => handleConfirmation(evt, onSelect),
  [Keys.SPACE]: (evt) => handleConfirmation(evt, onSelect),
  [Keys.ESCAPE]: onCancel,
});
