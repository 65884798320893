import { isEqual } from 'lodash';

import { AudienceGroup } from 'Features/audience/services/update-audience-group.service';
import { GroupFormData } from 'Features/audience/services/create-audience-group.service';

import { ChipItem } from 'Components/structural/STruncatedChipList/STruncatedChipList';

import { AudienceGroupEntity } from 'Store/entities/audience-group/audience-group.types';


export const areSegmentationsEqual = (formDataSegmentations: GroupFormData['segmentations'], audienceSegmentation?: ChipItem[]) => {
  const formDataSegmentationsId = formDataSegmentations.map((segmentation) => segmentation.id).sort();
  const audienceSegmentationsId = audienceSegmentation?.map((segmentation) => segmentation.id).sort();

  return isEqual(formDataSegmentationsId, audienceSegmentationsId);
};

export const isUpdateFormValid = (formData: GroupFormData, audienceGroup: AudienceGroupEntity) => {
  if (!formData.name || formData.name === '') return false;
  if (formData.segmentations.length === 0) return false;

  if (formData.name === audienceGroup.name && areSegmentationsEqual(formData.segmentations, audienceGroup.segmentations)) return false;

  return true;
};

type ErrorFromApi = {
  errorCode?: string,
  params?: {
    groupName?: string
  }
} | null;

export type Errors = {
  name?: string,
  segmentation?: string,
  general?: string,
} | null;

export const formatErrors = (result: AudienceGroup | undefined, error?: ErrorFromApi): Errors => {
  if (error?.errorCode === 'AUD-AGU-AGU-005') {
    return {
      name: 'This is name is already used',
    };
  }

  if (error?.errorCode === 'AUD-AGU-AGU-004') {
    return {
      segmentation: `An identical group has been detected: “${error?.params?.groupName}”. Please add or remove a segment to create a new group.`,
    };
  }

  if (!result || error) {
    return {
      general: 'An unknown error occured. Please try again.',
    };
  }

  return null;
};
