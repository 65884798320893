import React from 'react';

import styles from 'Components/structural/STable/STable.style';
import Error from 'Components/structural/STable/components/Error';
import { OriginalRow, TableMessage } from 'Components/structural/STable/types';
import { HeaderGroup } from 'react-table';

type TableHeaderProps = {
  getTableProps: () => object;
  headerGroups: HeaderGroup<OriginalRow>[];
  isContentLoading: boolean;
  tableMessage?: TableMessage;
};

function TableHeader({ getTableProps, headerGroups, isContentLoading, tableMessage }: TableHeaderProps) {
  const tableClickableStyle = isContentLoading ? styles.headerNotClickable : {};
  const headerGroupProps = headerGroups[0].getHeaderGroupProps();

  return (
    <div {...getTableProps()} style={{ ...styles.table, ...tableClickableStyle }}>
      <Error tableMessage={tableMessage} />
      <div
        {...headerGroupProps}
        style={{
          ...headerGroupProps.style,
          ...styles.header,
        }}
      >
        {headerGroups[0].headers.map((column) => {
          return (
            <div {...column.getHeaderProps()} style={column.getHeaderProps().style} key={column.id}>
              {column.render('Header')}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TableHeader;
