/**
 * Displays a radio button
 *
 * Props:
 * - label: The label of the button
 * - type: Button type (standard, accentuated or disabled)
 * - size: The size of the button
 * - style: can override the component's style
 * - selected: boolean that checks if button is active or inactive
 * - onSelect: function which controls the select action
 */

import React, { useMemo, MouseEventHandler } from 'react';
import type { CSSProperties, FunctionComponent } from 'react';

import ULabel from 'Components/unit/ULabel/ULabel';

import { COLORS } from 'Components/foundation';

import styles from './URadioButton.style';
import { IconName } from 'Components/foundation/icons';

type URadioButtonType = 'standard' | 'accentuated';
type URadioButtonSize = 'S' | 'M' | 'L';

export type URadioButtonProps = {
  label?: string;
  iconLabel?: IconName;
  type?: URadioButtonType;
  size?: URadioButtonSize;
  style?: CSSProperties;
  selected?: boolean;
  disabled?: boolean;
  onSelect?: MouseEventHandler;
};

const wrapperSize = {
  S: styles.buttonSizeS,
  M: styles.buttonSizeM,
  L: styles.buttonSizeL,
};

const insideSize = {
  S: styles.buttonInsideSizeS,
  M: styles.buttonInsideSizeM,
  L: styles.buttonInsideSizeL,
};

const WRAPPER_STYLE = {
  standard: {
    size: wrapperSize,
    style: {
      ...styles.radioButton,
      border: `1px solid ${COLORS.GREY_DARKER.DEFAULT}`,
    },
  },
  accentuated: {
    size: wrapperSize,
    style: {
      ...styles.radioButton,
      border: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    },
  },
};

const INSIDE_STYLE = {
  standard: {
    style: {
      ...styles.insideButton,
      ...styles.standardButton,
      ...styles.radioButton,
    },
    size: insideSize,
  },
  accentuated: {
    style: {
      ...styles.insideButton,
      ...styles.accentuatedButton,
      ...styles.radioButton,
    },
    size: insideSize,
  },
};

export const URadioButton: FunctionComponent<URadioButtonProps> = ({
  type = 'standard',
  label,
  size = 'M',
  style,
  iconLabel,
  selected = false,
  disabled = false,
  onSelect = () => {},
}) => {
  const disabledWrapper = useMemo(
    () =>
      disabled
        ? {
            ...styles.radioButtonDisabled,
            border: `1px solid ${COLORS.DISABLED.DEFAULT}`,
          }
        : null,
    [disabled],
  );
  const isButtonActive = useMemo(() => (!selected ? styles.isInactive : null), [selected]);
  const disabledStyle = useMemo(
    () => ({
      ...styles.insideButton,
      ...styles.radioButton,
      ...styles.radioButtonDisabled,
    }),
    [],
  );
  const disabledInside = useMemo(() => {
    if (disabled) {
      if (selected) {
        return {
          ...disabledStyle,
          ...styles.disabledButton,
        };
      }
      return disabledStyle;
    }

    return null;
  }, [disabled, selected, disabledStyle]);
  const wrapperStyle = useMemo(
    () => ({
      ...WRAPPER_STYLE[type].style,
      ...WRAPPER_STYLE[type].size[size],
      ...disabledWrapper,
    }),
    [type, size, disabledWrapper],
  );
  const insideStyle = useMemo(
    () => ({
      ...INSIDE_STYLE[type].style,
      ...INSIDE_STYLE[type].size[size],
      ...isButtonActive,
      ...disabledInside,
    }),
    [type, size, isButtonActive, disabledInside],
  );

  return (
    <div style={styles.linearWrapper}>
      <div
        onClick={!disabled ? onSelect : undefined}
        style={{ ...styles.clickableArea, ...(disabled ? styles.disabledClickableArea : {}) }}
      >
        <div
          style={{
            ...style,
            ...wrapperStyle,
          }}
        >
          <div style={{ ...insideStyle }} />
        </div>
      </div>
      <ULabel icon={iconLabel} iconSize={16} size="S">
        {label}
      </ULabel>
    </div>
  );
};

export default URadioButton;
