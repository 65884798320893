import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import type { StyleSheet } from 'Components/foundation/stylesheets';

const paddingRightPannel = 30;

const stylesheet: StyleSheet = {
  wrapper: {
    display: 'flex',
    height: '100%',
    borderTop: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    width: '100%',
  },
  leftPanel: {
    flexGrow: 1,
  },
  rightPanel: {
    borderLeft: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 252,
    maxWidth: 252,
    width: 252,
    padding: '24px 0 0 0',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  previewText: {
    padding: `0 ${paddingRightPannel}px`,
  },
  cropPreviewPanel: {
    overflowY: 'auto',
  },
  cropPreview: {
    marginBottom: 16,
    padding: `0 ${paddingRightPannel}px`,
  },
  title: {
    ...TYPOGRAPHY.H2,
    marginBlockStart: 'unset',
    marginBlockEnd: 4,
    fontWeight: 'normal',
  },
  subText: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginBlockStart: 'unset',
    marginBlockEnd: 20,
  },
};

export default stylesheet;
