// @flow

/* eslint-disable complexity */
/* eslint-disable flowtype/require-compound-type-alias */
/* eslint-disable key-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-multi-comp */

import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions } from '../redux/actions';

import styles from './DocumentListAlertView.style';

type Props = {|
  component: React$AbstractComponent<any, any>,
|};

const ALL_SCOPES: Array<AlertScope> = [
  'fetchError',
  'archiveError',
  'archiveSuccess',
];

export const IDS: { [AlertScope]: number } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [(x: any)]: i }), {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  'fetchError': 'error',
  'archiveError': 'error',
  'archiveSuccess': 'success',
};

const TITLES = {
  fetchError: t('documents:document_list_alert_view.titles_fetch_error'),
  archiveError: t('documents:document_list_alert_view.titles_archive_error'),
  archiveSuccess: '',
};

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const message = params.message || '';

  switch (scope) {
  case 'fetchError':
    return t('documents:document_list_alert_view.case_fetch_error', { message });
  case 'archiveError':
    return t('documents:document_list_alert_view.case_archive_error', {
      message,
      archived: params.archived ? '' : 'un',
    });
  case 'archiveSuccess':
    return t('documents:document_list_alert_view.case_archive_success', { archived: params.archived ? '' : 'un' });

    default: return '';
  }
};

const makeAlertsFromStateAlerts = (alerts) => alerts
  .map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

export const DocumentListAlertView = ({ component }: Props) => {
  const stateAlerts = useTypedSelector(state => state.pages.documentList.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts), [stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return (
    <AlertHOC alerts={alerts} onClose={onClose} />
  );
};
