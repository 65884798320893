import { createStyleSheet } from 'Components/foundation/stylesheets';

import { CORNERS, COLORS, ELEVATIONS, TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  container: {
    width: '100%',
    padding: '24px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: CORNERS.CORNER4,
    boxShadow: ELEVATIONS.LIGHT_TINY,
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    borderWidth: '1px',
    borderStyle: 'solid',
    minWidth: '350px',
    position: 'relative',
    transition: 'box-shadow 300ms, background-color 150ms',
    cursor: 'pointer',
  },
  containerSelected: {
    backgroundColor: COLORS.PRIMARY_LIGHT.DEFAULT,
  },
  containerHover: {
    boxShadow: ELEVATIONS.LIGHT_LOW,
  },
  containerDisabled: {
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    top: '16px',
    right: '16px',
    position: 'absolute',
  },
  mainContainer: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainerDisabled: {
    opacity: 0.75,
  },
  groupInformation: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '16px',
  },
  name: {
    ...TYPOGRAPHY.HERO_M,
    marginBottom: '4px',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  targeting: {
    ...TYPOGRAPHY.BODY4_SEMIBOLD,
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '4px',
  },
  description: {
    ...TYPOGRAPHY.BODY3,
    marginTop: '8px',
  },
  segmentationContainer: {
    marginTop: '16px',
  },
});

export default styleSheet;
