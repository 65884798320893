import {
  useSensors,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  useSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { useCallback, useState } from 'react';

import {
  getChildrenOfChapter,
  type MicroKnowledgeListType,
  type MicroKnowledgeChapter,
  type MicroKnowledgeItem,
} from 'Pages/Courses/utils/mk-mapped-linked-list';
import { type HandleReorderMk } from 'Pages/Courses/utils/edition-handlers';

const MOUSE_SENSOR_DISTANCE = 5;

export type MicroKnowledgeItemWithoutOrder = Omit<MicroKnowledgeItem, 'previousId' | 'nextId'>;

export const checkItemReachability = (items: Record<string, MicroKnowledgeItem>, activeId: string, itemId: string) => {
  const chapterId = items[itemId].chapterId;

  const isActiveItemChapter = items[activeId].type === 'chapter';
  const isNotActiveItem = activeId !== itemId;
  const isChapterChild = Boolean(chapterId);
  const isParentChapterUncollapsed = chapterId !== null && !(items[chapterId] as MicroKnowledgeChapter).isCollapsed;

  return (
    isNotActiveItem && (!isActiveItemChapter || !isChapterChild) && (!isChapterChild || isParentChapterUncollapsed)
  );
};

const computeNewChapterId = (
  items: Record<string, MicroKnowledgeItem>,
  activeId: string,
  overId: string,
  deltaY: number,
) => {
  const activeItem = items[activeId];
  const overItem = items[overId];

  const isActiveItemChapter = activeItem.type === 'chapter';
  const isOverUncollapsedChapter = overItem.type === 'chapter' && !overItem.isCollapsed;
  const newActiveChapterId =
    (!isActiveItemChapter && (isOverUncollapsedChapter && deltaY > 0 ? overItem.id : overItem.chapterId)) || null;

  return newActiveChapterId;
};

const computeNewActiveItem = (
  items: Record<string, MicroKnowledgeItem>,
  activeId: string,
  overId: string,
  deltaY: number,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { previousId: _previousId, nextId: _nextId, ...prevActiveItem } = items[activeId];

  const newActiveChapterId = computeNewChapterId(items, activeId, overId, deltaY);
  const activeItem = { ...prevActiveItem, chapterId: newActiveChapterId };

  return activeItem;
};

const completeReachableToFull = (
  mkList: MicroKnowledgeListType,
  reachable: MicroKnowledgeItemWithoutOrder[],
  draggedId: string,
) => {
  const { items } = mkList;

  return reachable.flatMap((partialMk, idx) => {
    const mk = items[partialMk.id];

    const next = reachable[idx + 1] ?? null;

    if (mk.type === 'chapter' && (!next || next.chapterId !== partialMk.id)) {
      const childrenIds = getChildrenOfChapter(mkList, mk);
      const children = childrenIds.reduce<MicroKnowledgeItemWithoutOrder[]>((children, id) => {
        if (id === draggedId) {
          return children;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { previousId: _previousId, nextId: _nextId, ...item } = items[id];

        return [...children, item];
      }, []);

      return [partialMk, ...children];
    }

    return partialMk;
  });
};

export type MkDragAndDropProps = {
  mkList: MicroKnowledgeListType;
  microKnowledgeArray: MicroKnowledgeItem[];
  onReorder: (params: HandleReorderMk) => void;
  onToggleCollapse: ({ id }: { id: string }) => void;
};

export const useMkDragAndDrop = ({ mkList, microKnowledgeArray, onReorder, onToggleCollapse }: MkDragAndDropProps) => {
  const { items } = mkList;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: MOUSE_SENSOR_DISTANCE },
    }),
  );

  const [activeItem, setActiveItem] = useState<MicroKnowledgeItem | null>(null);
  const [overItem, setOverItem] = useState<MicroKnowledgeItem | null>(null);
  const [dragDirection, setDragDirection] = useState<-1 | 0 | 1>(0);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      setActiveItem(items[active.id]);

      const activeItem = items[active.id];

      if (activeItem.type === 'chapter' && !activeItem.isCollapsed) {
        onToggleCollapse({ id: activeItem.id });
      }
    },
    [items, setActiveItem, onToggleCollapse],
  );

  const handleDragOver = useCallback(
    ({ active, over, delta }: DragOverEvent) => {
      if (over) {
        setOverItem(items[over.id]);

        const activeItem = items[active.id];
        const overItem = items[over.id];

        if (activeItem.type === 'chapter' && overItem.type === 'chapter' && !overItem.isCollapsed && delta.y > 0) {
          onToggleCollapse({ id: overItem.id });
        }

        if (activeItem.type !== 'chapter' && overItem.type === 'chapter' && overItem.isCollapsed) {
          onToggleCollapse({ id: overItem.id });
        }
      }

      setDragDirection(Math.sign(delta.y) as -1 | 0 | 1);
    },
    [items, setOverItem],
  );

  const handleDragCancel = useCallback(() => {
    setActiveItem(null);
    setOverItem(null);
    setDragDirection(0);
  }, [setActiveItem, setOverItem, setDragDirection]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over, delta } = event;

      const activeId = active.id.toString();
      const overId = over?.id?.toString() ?? null;

      if (overId && active.id !== overId && checkItemReachability(items, activeId, overId)) {
        const reachable: MicroKnowledgeItemWithoutOrder[] = microKnowledgeArray
          .filter(({ id }) => checkItemReachability(items, activeId, id))
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(({ previousId, nextId, ...rest }) => rest);

        const overIdx = reachable.findIndex((item) => item.id === overId);

        const newActiveItem = computeNewActiveItem(items, activeId, overId, delta.y);

        reachable.splice(overIdx + (delta.y > 0 ? 1 : 0), 0, newActiveItem);

        const nextOrderedMks = completeReachableToFull(mkList, reachable, activeId);

        onReorder({ nextOrderedMks });
      }

      setActiveItem(null);
      setOverItem(null);
      setDragDirection(0);
    },
    [mkList, items, microKnowledgeArray, setActiveItem, setOverItem],
  );

  return {
    activeItem,
    overItem,
    dragDirection,
    attributes: { sensors },
    listeners: {
      onDragStart: handleDragStart,
      onDragOver: handleDragOver,
      onDragCancel: handleDragCancel,
      onDragEnd: handleDragEnd,
    },
  };
};
