import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TableMessage } from 'Components/structural/STable/types';
import { CSSProperties } from 'react';

export const tableMessageColor: Record<TableMessage['type'], CSSProperties> = {
  error: {
    color: COLORS.ERROR.DEFAULT,
  },
  warning: {
    color: COLORS.WARNING.DEFAULT,
  },
  success: {
    color: COLORS.SUCCESS.DEFAULT,
  },
};

export const rowMessageColor = {
  error: COLORS.ERROR.DEFAULT,
  success: COLORS.SUCCESS.DEFAULT,
  warning: COLORS.WARNING.DEFAULT,
};

const styles = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  table: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    position: 'relative',
  },
  tableMessage: {
    ...TYPOGRAPHY.BODY4,
    position: 'absolute',
    top: -19,
    margin: 0,
  },
  header: {
    borderBottom: '1px solid #E2E2E2',
  },
  resizer: {
    display: 'inline-block',
    width: '2px',
    height: 31,
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
  },
  rowWrapper: {
    position: 'relative',
    borderBottom: '1px solid #E2E2E2',
  },
  layoutNoTargeted: {
    position: 'absolute',
    pointerEvents: 'none',
    top: 1,
    height: '95%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, .6)',
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  rowMessageContainer: {
    ...TYPOGRAPHY.BODY4,
    padding: '3px 10px 2px 10px',
  },
  tooltipTriggerWrapper: {
    position: 'relative',
  },
  emptyRow: {
    display: 'flex',
    padding: '0px 8px',
    height: 50,
    alignItems: 'center',
    ...TYPOGRAPHY.BODY3,
    fontWeight: 400,
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  lockedRowWrapper: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: COLORS.WHITE.DEFAULT,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 16,
    minWidth: 68,
    height: '100%',
    top: 0,
    right: 0,
  },
  emptyNotice: {
    display: 'flex',
    height: 34,
    justifyContent: 'center',
    ...TYPOGRAPHY.BODY3,
    fontWeight: 400,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    alignItems: 'flex-end',
  },
  headingWrapper: {
    display: 'flex',
    height: 44,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  headingTitleSubtitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headingTitle: {
    ...TYPOGRAPHY.H1,
    fontWeight: 400,
    margin: 0,
    display: 'inline-block',
  },
  headerNotClickable: {
    pointerEvents: 'none',
  },
  headingExplanation: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    margin: 0,
  },
  explanationWithoutTitle: {
    marginLeft: 0,
  },
  tableActionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableAction: {
    display: 'flex',
  },
  tableActionSpace: {
    marginLeft: 16,
  },
  showMoreContainer: {
    display: 'flex',
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  showMoreLoading: {
    height: 32,
  },
  showMoreIdle: {
    flexDirection: 'column',
  },
  errorShowMoreMargin: {
    marginTop: 10,
  },
  showMoreError: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 0,
    marginBottom: 10,
  },
  loadingMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  loader: {
    marginLeft: 10,
  },
  rowsLoadingContainer: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bodyLoading: {
    backgroundColor: '#FFF',
    pointerEvents: 'none',
    opacity: 0.3,
  },
  callbackOption: {
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  remove: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export default styles;
