import { LanguagesCodeType } from 'Libs/ts/types';
import App from 'Models/App';

type LanguageDetailType = {
  id: number;
  label: string;
  data: LanguagesCodeType | '';
};

export type LanguageAvailableType = {
  id: number;
  label: string;
  availableGroupId: number;
};

function getLanguagesAvailableFromApp() {
  const { languages } = App as any;

  return languages().items;
}

function getAvailableSegmentationsGroupsFromApp() {
  const { AvailableSegmentationGroups } = App as any;

  return AvailableSegmentationGroups().items;
}

export const getLanguageDetail = (languageId: number): LanguageDetailType => {
  const languagesAvailable = getLanguagesAvailableFromApp();
  const availableSegmentationGroups = getAvailableSegmentationsGroupsFromApp();

  const languageAvailable = languagesAvailable.find((language: any) => language.id() === languageId);

  if (!languageAvailable) {
    return {
      id: languageId,
      label: '',
      data: '',
    };
  }

  const availableSegmentationGroup = availableSegmentationGroups.find(
    (segmentation: any) => segmentation.id() === languageAvailable.availableGroupId(),
  );

  return {
    id: languageId,
    label: languageAvailable.label(),
    data: availableSegmentationGroup.data(),
  };
};

export const getAvailableSegmentationGroups = (): Map<number, string> => {
  const availableSegmentationGroups = getAvailableSegmentationsGroupsFromApp();
  const availableGroupItemMap = new Map();

  availableSegmentationGroups.forEach((availableGroupItem: any) => {
    availableGroupItemMap.set(availableGroupItem.id(), availableGroupItem.data());
  });

  return availableGroupItemMap;
};

export const getLanguagesAvailable = (): ReadonlyArray<LanguageAvailableType> => {
  const languagesAvailable = getLanguagesAvailableFromApp();

  return languagesAvailable.map((language: any) => ({
    id: language.id(),
    label: language.label(),
    availableGroupId: language.availableGroupId(),
  }));
};
