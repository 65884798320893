import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import { tracking } from 'Services/trackingService';

import { useTypedDispatch } from 'Libs/redux/utils';

import { actions, useModuleSelector } from '../../../redux';

import styles from './ContributorResendInviteModal.style';

export const ContributorResendInviteModal = () => {
  const dispatch = useTypedDispatch();
  const contributorToManage = useModuleSelector((state) => state.manageContributorModal.contributorToManage);
  const labelError = useModuleSelector((state) => state.manageContributorModal.error);
  const visible = useModuleSelector((state) => state.manageContributorModal.isOpen.isResendInviteModalOpen || false);

  const { id, email } = contributorToManage;

  const isActionButtonDisabled = !id;

  const handleResendInviteContributor = useCallback(
    () => dispatch(actions.resendInviteContributor(contributorToManage)),
    [dispatch, contributorToManage],
  );

  const handleResendInviteEnd = useCallback(
    (res: any) => {
      const { error } = res;

      tracking.contributorsResendInvite();

      if (!error) {
        dispatch(actions.toggleContributorModal({ isResendInviteModalOpen: false }));
      }
    },
    [dispatch],
  );

  const handleCloseModal = useCallback(
    () => dispatch(actions.toggleContributorModal({ isResendInviteModalOpen: false })),
    [dispatch],
  );

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleResendInviteContributor}
      onActionEnd={handleResendInviteEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={t('config_contributors:contributors.modal.resend_invite_title')}
      labelActionButton={t('config_contributors:contributors.modal.resend_invite_button')}
      labelSecondButton={t('config_contributors:contributors.modal.resend_invite_cancel_button')}
      actionButtonType="accentuated"
      disableActionButton={isActionButtonDisabled}
      labelError={labelError}
    >
      <div>
        {t('config_contributors:contributors.modal.resend_invite_description')}
        <span style={styles.email}>{email}</span>?
      </div>
    </MModal>
  );
};
