/*
 * ARGS:
 * - content  : the full content
 * - knowledge: the full knowledge
 */

import m from 'm';
import { t } from 'i18next';
import Enum from 'models/Enum';
import Model from 'models/Model';
import deviceScreen from 'components/device.screen';
import mediaSelector from 'components/content.media.selector';
import contentHeader from 'components/content.header';
import inputQuestion from 'components/input.question';
import inputExplanationText from 'components/input.explanation.text';
import inputGameItem from 'components/input.game.item';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

var frontCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.SQCM_FRONT,
};

var backCropProps = {
  ...EnumMedia.COMMON.cropProps,
  ...EnumMedia.SQCM_BACK,
};

component.controller = function controller(args) {
  var self = this;
  var SQCMChoice = Model('SQCMChoice');

  self.isAnswer = function isAnswer(_args, choice) {
    return choice.rightAnswer();
  };

  self.toggleGoodAnswer = function toggleGoodAnswer(_args, selectedChoice, choices) {
    if (args.editable()) {
      choices.forEach(function cleanAndToggle(choice) {
        choice.rightAnswer(false);
      });
      selectedChoice.rightAnswer(!selectedChoice.rightAnswer());
    }
  };

  self.formatAnswers = function formatAnswers(_args) {
    var sqcm = _args.content().game();
    var MAX = 3;

    // add Choices if needed
    for (var j = 0; j < MAX; j++) {
      if (!sqcm.sqcmChoices().at(j)) {
        var newChoice = new SQCMChoice({
          sqcmId  : sqcm.id(),
          gameItem: {
            typeId     : Enum.gameItemTypes.ANSWER,
            knowledgeId: _args.knowledge().id(),
          },
        });

        sqcm.sqcmChoices().push(newChoice);
      }
    }
    if (!_args.content().id())
      _args.content().presetFromKnowledge(_args.knowledge());
  };

  self.formatAnswers(args);
};

component.view = function view(c, args) {
  var sqcm = args.content().game();


  return m('.content-edition', {
    config: c.configSize,
  }, [

    m('.content-edition__container', [
      m(contentHeader, {
        title          : args.title,
        gameplay       : t('gameplays:sqcm.label'),
        gameplayName   : 'SQCM',
        explanationText: args.content().getDirective(),
      }),
      m('.content-edition__page-container', [
        m('.content-edition__page.content-edition__page--front', [
          m(deviceScreen, {}, [
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : sqcm.imageFront,
              video    : sqcm.videoFront,
              type     : sqcm.mediaTypeFrontId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: frontCropProps,
              mediaPositionFront: true,
            }),
            m(inputQuestion, {
              gameItem   : sqcm.question,
              placeholder: t('gameplays:qcm.question_placeholder'),
              editable   : args.editable(),
            }),
            m('.content-edition__page__qcm-workspace', [
              displayChoices(c, args),
            ]),
          ]),
        ]),
        m('.content-edition__page.content-edition__page--back', [
          m(deviceScreen, {}, [
            m(inputExplanationText, {
              gameItem   : sqcm.explanation,
              placeholder: t('gameplays:qcm.explanation_placeholder'),
              editable   : args.editable(),
            }),
            m(mediaSelector, {
              images   : args.knowledge().originalImages,
              image    : sqcm.imageBack,
              video    : sqcm.videoBack,
              type     : sqcm.mediaTypeBackId,
              editable : args.editable(),
              knowledge: args.knowledge,
              cropProps: backCropProps,
            }),
          ]),
        ]),
      ]),
    ]),
  ]);
};

function displayChoices(c, args) {
  var choices = args.content().game().sqcmChoices();
  var allChoicesFilled = choices.items.every(function checkFilledData(choice) {
    return choice.gameItem().data().length;
  });

  return m('.qcm-workspace__choices',
    choices.map(displayChoice)
  );

  function displayChoice(choice) {
    return m('.qcm-workspace__choice.qcm-workspace__choice--3', [
      m.hide(!allChoicesFilled, '.qcm-workspace__choice__marker', {
        class: [
          c.isAnswer(args, choice) ? 'qcm-workspace__choice__marker--highlighted' : '', !args.editable() ? 'qcm-workspace__choice__marker--disabled' : '',
        ].join(' '),
        onclick: c.toggleGoodAnswer.bind(null, args, choice, choices),
      }, []),
      m(inputGameItem, {
        gameItem   : choice.gameItem,
        placeholder: t('gameplays:qcm.choice_placeholder'),
        editable   : args.editable(),
      }),
    ]);
  }
}

export default component;
