// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  modal: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: '50%',
    width: 400,
    margin: '0px auto',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  content: {
    ...TYPOGRAPHY.BODY3,
  },
};
