/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { COLORS } from 'Components/foundation';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import Enum from 'Models/Enum';

import UButton from 'Components/unit/UButton/UButton';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SDocumentListItem from 'Components/structural/SDocumentListItem/SDocumentListItem';

import type { DocumentType } from 'ModelsReact/Document/Document';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';
import { SFooter } from 'Components/structural/SFooter/SFooter';

import { actions } from '../../redux';
import { DOCUMENTS_PAGINATION } from '../../redux/actions';

import styles from './DocumentListItems.style';

type DocumentListItemType = { document: DocumentType };

const DocumentListItem = ({ document }: DocumentListItemType) => {
  const dispatch = useTypedDispatch();
  const openings = useTypedSelector(state => state.pages.documentList.openings);
  const openingCount = openings.find((doc) => doc.documentId === document.id)?.count || 0;

  const segmentation = document.segmentation.map((seg: SegmentationGroupItemType) => {
    return {
      id: seg.id,
      value: seg.label,
      color: seg.dimension.color,
    };
  });

  const categoryTitle = !document.category
    ? t('documents:document_list_items.category_title')
    : document.category.label;

  let openDocumentAction = () => {
    dispatch(actions.setDocumentModalVisibility(true, document));
  };

  const availableOptions = [
    {
      title: t('documents:document_list_items.available_options_edit'),
      callback: openDocumentAction,
    },
  ];

  if (document.archived) {
    availableOptions.push({
      title: t('documents:document_list_items.available_options_unarchive'),
      callback: () => dispatch(actions.archive(document.id, false)),
    });
  } else {
    availableOptions.push({
      title: t('documents:document_list_items.available_options_archive'),
      color: COLORS.ERROR.DEFAULT,
      callback: () => dispatch(actions.archive(document.id, true)),
    });
  }

  return (
    <SDocumentListItem
      openingsCount={openingCount}
      style={styles.listItem}
      id={document.id}
      categoryTitle={categoryTitle}
      documentTitle={document.title}
      isAvailable={document.available}
      chips={segmentation}
      options={availableOptions}
      onClick={openDocumentAction}
    />
  );
};

const DocumentList = () => {
  const documents = useTypedSelector(state => state.pages.documentList.documents);

  return documents.map<React$Element<'div'>>((document) => {
    return (
      <div key={document.id}>
        <DocumentListItem document={document} />
      </div>
    );
  });
};

const LoadMore = () => {
  const dispatch = useTypedDispatch();
  const isloadingMore = useTypedSelector(state => state.pages.documentList.loadingMore);
  const counter = useTypedSelector(state => state.pages.documentList.counter);
  const documents = useTypedSelector(state => state.pages.documentList.documents);

  const handleLoadMore = useCallback(() => {
    const action = actions.fetch(documents.length, documents.length + DOCUMENTS_PAGINATION);

    dispatch(action);
  }, [dispatch, documents]);

  if (counter <= documents.length)
    return null;

  return (
    <div style={styles.loadMoreWrapper}>
      <SFooter>
        <UTextLink
          text={t('documents:document_list_items.load_more_wrapper')}
          onClick={handleLoadMore}
          type="secondary"
          rightIcon="bottom-chevron"
        />
      </SFooter>
    </div>
  );
};

export const DocumentListItems = () => {
  const counter = useTypedSelector(state => state.pages.documentList.counter);
  const documents = useTypedSelector(state => state.pages.documentList.documents);
  const isFiltering = useTypedSelector(state => state.pages.documentList.isFiltering);
  const documentStatusId = useTypedSelector(state => state.pages.documentList.selectedDocumentStatusId);

  let title = t('documents:document_list_items.title');

  if (isFiltering && documentStatusId === Enum.DocumentStatus.ARCHIVED)
   title = t('documents:document_list_items.archived_documents_title');

  // Empty state
  if (!isFiltering && !documents.length)
    return null;

  // Not found
  if (isFiltering && !documents.length) {
    return (
      <>
        <div style={styles.availableDocumentsTitle}>{t('documents:document_list_items.no_documents_found')}</div>

        <div style={styles.availableDocuments}>
          {t('documents:document_list_items.start_uploading_documents_today')}
        </div>
      </>
    );
  }

  return (
    <>
      <div style={styles.availableDocumentsTitle}>{title}</div>

      <div style={styles.availableDocuments}>{t('documents:document_list_items.counter_documents',{counter})}</div>

      <DocumentList />
      <LoadMore />
    </>
  );
};
