import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const BreakingNewIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="61.7297"
      height="38.6808"
      rx="3.78205"
      transform="matrix(0.993994 -0.109434 0.115665 0.993288 0 22.7201)"
      fill={color.DEFAULT}
    />
    <rect
      x="11.3799"
      y="10.9957"
      width="59.4855"
      height="36.395"
      rx="2.64744"
      fill={COLORS.WHITE.DEFAULT}
      stroke={color.DEFAULT}
      strokeWidth="2.26923"
    />
    <rect
      x="18.6451"
      y="18.0256"
      width="18.0735"
      height="17.4144"
      rx="1.13462"
      fill={color.HOVER}
      stroke={color.DEFAULT}
      strokeWidth="2.26923"
    />
    <path d="M18.2371 41.4955H37.8533" stroke={color.DEFAULT} strokeWidth="2.26923" strokeLinecap="round" />
    <path d="M44.392 41.4955H64.0082" stroke={color.DEFAULT} strokeWidth="2.26923" strokeLinecap="round" />
    <path d="M44.392 35.1686H64.0082" stroke={color.DEFAULT} strokeWidth="2.26923" strokeLinecap="round" />
    <path d="M44.392 28.8418H64.0082" stroke={color.DEFAULT} strokeWidth="2.26923" strokeLinecap="round" />
    <path d="M44.392 22.5149H64.0082" stroke={color.DEFAULT} strokeWidth="2.26923" strokeLinecap="round" />
  </svg>
);

export default BreakingNewIllustration;
