import React from 'react';

import STooltip from 'Components/structural/STooltip/STooltip';
import { STableRow } from 'Components/structural/STable/types';

type LockedInfoProps = {
  id: string;
  lockedInfo: STableRow['original']['lockedInfo'];
};

const LockedInfo = ({ id, lockedInfo }: LockedInfoProps) => {
  if (!(lockedInfo && lockedInfo.content)) return null;

  return <STooltip id={id} {...lockedInfo} />;
};

export default LockedInfo;
