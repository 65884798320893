import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';

import Enum from 'Models/Enum';

import { ROLES_AVAILABLE_BY_EDITOR_ROLE } from 'Components/utils/Enum';

import STable, { TableAction } from 'Components/structural/STable/STable';

import { actions, useModuleSelector } from '../redux';

import { ContributorManageModal } from './components/ContributorManageModal/ContributorManageModal';
import { ContributorResendInviteModal } from './components/ContributorResendInviteModal/ContributorResendInviteModal';
import { ContributorRemoveModal } from './components/ContributorRemoveModal/ContributorRemoveModal';
import { ContributorSelfEditModal } from './components/ContributorSelfEditModal/ContributorSelfEditModal';

import styles from './ContributorList.style';
import type { ContributorType } from '../redux/models/Contributor';
import { Header, HeaderType, OriginalRow, TABLE_ACTION_TYPES } from 'Components/structural/STable/types';

type Props = {
  editorRoleId: number;
  editorId: number;
  is2FAActivated: boolean;
};

export const ContributorList = ({ editorRoleId, editorId, is2FAActivated }: Props) => {
  const dispatch = useTypedDispatch();
  const cancelGetAll = useRef(() => {});

  const contributors = useModuleSelector((state) => state.contributors);
  const isTableLoading = useModuleSelector((state) => state.isFetchAllLoading);
  const editorRights = ROLES_AVAILABLE_BY_EDITOR_ROLE[editorRoleId];

  const order = useRef<string>('');
  const query = useRef<string>('');

  const getAll = useCallback(async () => {
    cancelGetAll.current();

    const action = actions.fetchAll({ query: query.current, order: order.current });

    cancelGetAll.current = action.cancel;

    await dispatch(action);
  }, [dispatch]);

  // Fetch all admins on mount
  useEffect(() => {
    void getAll();

    return cancelGetAll.current;
  }, [getAll]);

  // reset state on unmount
  useEffect(
    () => () => {
      dispatch(actions.reset());
    },
    [dispatch],
  );

  /**
   * Build STable Props
   */
  const title = t('config_contributors:contributors.contributors_filter.title');
  const handleSort = useCallback(
    (column: string, columnOrder: string) => {
      order.current = columnOrder === 'none' ? '' : `${column} ${columnOrder}`;

      return getAll();
    },
    [getAll],
  );

  const handleSearch = useCallback(
    (value: string) => {
      query.current = value;

      return getAll();
    },
    [getAll],
  );

  const handleSendInvite = useCallback(
    () => dispatch(actions.toggleContributorModal({ isManageModalOpen: true })),
    [dispatch],
  );

  const handleResendInviteContributor = useCallback(
    ({ data: { contributor } }) =>
      dispatch(actions.toggleContributorModal({ isResendInviteModalOpen: true }, { ...contributor })),
    [dispatch],
  );

  const handleRemoveContributor = useCallback(
    ({ data: { contributor } }) =>
      dispatch(actions.toggleContributorModal({ isDeleteModalOpen: true }, { ...contributor })),
    [dispatch],
  );

  const handleEditContributor = useCallback(
    ({ data: { contributor } }) => {
      const { id } = contributor;
      const openModalConfig = id === editorId ? { isSelfEditModalOpen: true } : { isManageModalOpen: true };

      return dispatch(actions.toggleContributorModal(openModalConfig, { ...contributor }));
    },
    [editorId, dispatch],
  );

  const tableActions = useMemo<TableAction[]>(
    () => [
      {
        id: 'table-action-1',
        type: TABLE_ACTION_TYPES.SEARCH,
        params: {
          placeholder: t('config_contributors:contributors.table.actions.search'),
          placeholderOnExpand: t('config_contributors:contributors.table.actions.placeholder_on_expand'),
          onAction: handleSearch,
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
      {
        id: 'table-action-2',
        type: TABLE_ACTION_TYPES.CREATE,
        params: {
          text: t('config_contributors:contributors.table.actions.send_invite'),
          onAction: handleSendInvite,
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
    ],
    [handleSearch, handleSendInvite],
  );

  const columns: Header[] = useMemo(() => {
    const isContributorsRestricted = contributors.some(
      (contributor: ContributorType) => contributor.segmentation.length,
    );

    const restrictToColumn: Header = {
      id: '2',
      type: HeaderType.CellHeader,
      headerParams: {
        text: t('config_contributors:contributors.table.columns.access_restricted_to'),
        align: 'left',
        onAction: () => null,
      },
      width: '180px',
      disableResizing: true,
      disableSorting: true,
      accessor: 'segmentation',
    };

    const coreColumns: Header[] = [
      {
        id: '0',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_contributors:contributors.table.columns.name'),
          align: 'left',
          onAction: handleSort,
        },
        width: '175px',
        disableResizing: true,
        accessor: 'name',
      },
      {
        id: '1',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_contributors:contributors.table.columns.role'),
          align: 'left',
          onAction: handleSort,
        },
        width: '180px',
        disableResizing: true,
        accessor: 'role',
      },
      {
        id: '3',
        type: HeaderType.EmptyHeader,
        disableResizing: true,
        disableSorting: true,
        accessor: 'invitePending',
      },
    ];

    if (isContributorsRestricted) {
      coreColumns.splice(2, 0, restrictToColumn);
    }

    return coreColumns;
  }, [contributors, handleSort]);

  const data: OriginalRow[] = useMemo(
    () =>
      contributors
        .filter((contributor: ContributorType) => {
          const canSeeContributor =
            editorRights.includes(contributor.roles?.[0]?.id) || editorRoleId === Enum.Role.ROOT;

          return canSeeContributor;
        })
        .map((contributor: ContributorType) => {
          const { id, email, firstName, lastName, status, roles, segmentation, isEditionAllowed } = contributor;
          const isInvited = status === 'inactive';
          const isEditorHasRight = editorRights.includes(roles?.[0]?.id) || editorRoleId === Enum.Role.ROOT;

          const callbackOptions = [];

          if (isEditorHasRight && isEditionAllowed) {
            if (isInvited) {
              callbackOptions.push({
                icon: 'share',
                id: 'action_resend_invite_id',
                callback: handleResendInviteContributor,
              });
            } else {
              callbackOptions.push({
                icon: 'edit',
                id: 'action_edit_id',
                callback: handleEditContributor,
              });
            }

            callbackOptions.push({
              icon: 'delete',
              id: 'action_delete_id',
              callback: handleRemoveContributor,
            });
          }

          return {
            id,
            dataAccessor: { contributor },
            name: {
              id: `${id}_name`,
              type: 'UTableCellUser',
              params: {
                name: isInvited ? '' : `${firstName} ${lastName}`.trim(),
                email,
              },
            },
            role: {
              id: `${id}_role`,
              type: 'UTableCellText',
              params: {
                value: roles[0] ? t(`config_contributors:contributors.modal.roles.${roles[0].name.toLowerCase()}`) : '',
                type: 'text',
              },
            },
            segmentation: {
              id: `${id}_segment`,
              type: 'UTableCellSegment',
              params: {
                items: [...segmentation]
                  .sort((segA, segB) => segA.dimension.id - segB.dimension.id)
                  .map(({ label: value, dimension: { color } }) => ({ value, color })),
                style: styles.segmentCell,
              },
            },
            invitePending: {
              id: `${id}_invite_pending`,
              type: 'UTableCellText',
              params: {
                value: status === 'inactive' ? t('config_contributors:contributors.table.data.invite_pending') : '',
                type: 'text',
                textStyle: styles.invitePending,
                style: styles.alignText,
              },
            },
            callbackOptions,
          };
        }),
    [
      editorRoleId,
      editorRights,
      contributors,
      handleResendInviteContributor,
      handleEditContributor,
      handleRemoveContributor,
    ],
  );

  return (
    <div style={styles.wrapper}>
      <STable
        title={title}
        columns={columns}
        data={data}
        total={1}
        tableActions={tableActions}
        isTableLoading={isTableLoading}
      />
      <ContributorManageModal editorRoleId={editorRoleId} is2FAActivated={is2FAActivated} />
      <ContributorResendInviteModal />
      <ContributorRemoveModal />
      <ContributorSelfEditModal is2FAActivated={is2FAActivated} />
    </div>
  );
};
