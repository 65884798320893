import { StyleSheet as Aphrodite } from 'aphrodite';

import { createStyleSheet } from 'Components/foundation/stylesheets';

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export const { rotationAnimation } = Aphrodite.create({
  rotationAnimation: {
    animationName: {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(180deg)',
      },
    },
    animationDuration: '600ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
});

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: '8px',
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styleSheet;
