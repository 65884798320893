import Model from 'models/Model';

var TranslatedLabel = {
  endpoint  : 'api/TranslatedLabels',
  attributes: {
    label: {
      type: 'string',
    },
    languageId: {
      type: 'number',
    },
    polyglotLabelId: {
      type: 'number',
    },
  },
};

export default Model.register('TranslatedLabel', TranslatedLabel);
