import { t } from 'i18next';

import {
  ActivityStatusType,
  AudienceType as LegacyAudienceType,
} from 'Components/structural/SActivityListItem/SActivityListItem.types';
import { Activity } from 'Store/entities/activity/activity.type';
import { getLanguagesAvailableWithCode } from 'Services/language/languageService';

export const legacyActivityTypeIdMap: Record<Activity['type'], number> = {
  campaign: 1,
};

// This does not support multiple audiences.
export const getLegacyActivityAudience = (activity: Activity): LegacyAudienceType => {
  const audience = activity.audiences?.[0];

  if (audience?.type === 'segmentation') {
    return {
      segments:
        audience?.segments?.map((segment) => ({
          value: segment.label,
        })) || [],
    };
  }

  if (audience?.type === 'magic-code') {
    return {
      magicCode: audience.segments?.[0].label || '',
      label: t('activities:list.item.audience.magic_code'),
    };
  }

  if (audience?.type === 'email-list') {
    return {
      labelEmail: t('activities:list.item.audience.email_list'),
    };
  }

  if (audience?.type === 'all') {
    return {
      segments: [],
    };
  }

  // TODO: add breaking news audience by campaign support

  return {
    segments: [],
  };
};

type AudienceType = 'all' | 'segmentation' | 'magic-code' | 'email-list';

export const audienceModeTypeIdToAudienceType = (audienceModeTypeId: number): AudienceType => {
  switch (audienceModeTypeId) {
    case 1:
      return 'magic-code';
    case 2:
      return 'email-list';
    case 3:
      return 'segmentation';
    default:
      return 'all';
  }
};

export const mapLegacyActivityTypeToActivityType = (legacyActivityType: number): Activity['type'] | undefined => {
  switch (legacyActivityType) {
    case 1:
      return 'campaign';
  }
};

export const mapActivityStatus = (status: Activity['status']): ActivityStatusType => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'inactive':
      return 'Inactive';
    case 'archived':
      return 'Archived';
    case 'upcoming':
      return 'Upcoming';
    case 'live':
      return 'Live';
    case 'finished':
      return 'Finished';
  }
};

export const mapActivityStatusLabel = (status: Activity['status']): string => {
  switch (status) {
    case 'draft':
      return t('activities:list.item.status.draft');
    case 'inactive':
      return t('activities:list.item.status.inactive');
    case 'archived':
      return t('activities:list.item.status.archived');
    case 'upcoming':
      return t('activities:list.item.status.upcoming');
    case 'live':
      return t('activities:list.item.status.live');
    case 'finished':
      return t('activities:list.item.status.finished');
  }
};

export const mapLegacyActivityStatusId = (statusId: number): Activity['status'] => {
  switch (statusId) {
    case -2:
      return 'archived';
    case 1:
      return 'upcoming';
    case 2:
      return 'live';
    case 3:
      return 'finished';
    case 4:
      return 'inactive';
    default:
      return 'draft';
  }
};

export const mapLanguageIdsToLanguageCodes = (languageIds: number[]): string[] => {
  const languages = getLanguagesAvailableWithCode();

  const codes = languageIds.map((id) => {
    const language = languages.find((lang) => lang.id === id);

    return language?.languageCode;
  });

  return codes.filter(Boolean) as string[];
};
