import React from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';
import styles from 'Components/structural/SUploadDropArea/SUploadDropArea.style';

export type DraggingMessageProps = {
  isDragError: boolean;
  warnSentence1: string;
  warnSentence2: string;
  dropSentence: string;
};
export const DraggingMessage = ({ isDragError, warnSentence1, warnSentence2, dropSentence }: DraggingMessageProps) => {
  if (isDragError) {
    return (
      <div style={styles.dropSentence}>
        <div style={{ ...styles.icon, borderColor: COLORS.ERROR.DARKER }}>
          <UIcon color={COLORS.ERROR.DARKER} size={20} name="close" />
        </div>
        {warnSentence1}
        <div style={styles.defaultLabel}>{warnSentence2}</div>
      </div>
    );
  }

  return (
    <div style={styles.dropSentence} data-test-id="droparea-drop-message">
      <div style={{ ...styles.icon, borderColor: COLORS.SUCCESS.DARK }}>
        <UIcon color={COLORS.SUCCESS.DARK} size={20} name="check" />
      </div>
      {dropSentence}
    </div>
  );
};

export default DraggingMessage;
