// @flow

/**
 * SLandingPage
 * Required
 * - children: children form
 * - instanceLogo: instance logo path
 *
 * Optional
 * - spartedLogo: custom right sparted logo path
 * - rightPrimaryText: custom right primary text
 * - rightSecondaryText: custom right secondary text
 */

import * as React from 'react';
import { getCdnUrl } from 'Libs/cdn';

import { UImage } from 'Components/unit/UImage/UImage';

import styles from './SLandingPage.style';


type Props = {|

  // Required
  children: React.Node,
  instanceLogo: string,

  // Optional
  spartedLogo: string,
  rightPrimaryText: string,
  rightSecondaryText: string,
  style: Object,
|};

export class SLandingPage extends React.PureComponent<Props> {
  static defaultProps = {
    spartedLogo: getCdnUrl('common', '/sparted-logo-baseline-white.png'),
    rightPrimaryText: 'Start using your \n all-in-one mobile learning solution!',
    rightSecondaryText: `Create and deliver just-in-time \n learning content to wide audiences

    Multiply your employees engagement with a beautiful and exciting learning experience

    Impact your employees performance \n with tailor-made content`,
    style: undefined,
  };

  render() {
    const {
      style,
      children,
      instanceLogo,
      spartedLogo,
      rightPrimaryText,
      rightSecondaryText,
    } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={styles.leftWrapper}>
          <div style={styles.leftContentWrapper}>
            <UImage style={styles.instanceLogo} src={instanceLogo} />
            {children}
          </div>
        </div>
        <div style={styles.rightWrapper}>
          <div style={styles.rightContentWrapper}>
            <UImage style={styles.spartedLogo} src={spartedLogo} />
            <div style={styles.rightPrimaryText} dangerouslySetInnerHTML={{ __html: rightPrimaryText }} />
            <div style={styles.rightSecondaryText} dangerouslySetInnerHTML={{ __html: rightSecondaryText }} />
          </div>
        </div>
      </div>
    );
  }
}

export default SLandingPage;
