import React, { useEffect, useState } from 'react';
import type { CSSProperties } from 'react';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import SInput from 'Components/structural/SInput/SInput';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import styles from './SCounter.style';

const UIconButtonWithTooltip = withTooltip(UIconButton);

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';

type SCounterTooltipProps = {
  tooltipContent: string;
  tooltipPosition: TooltipPosition;
  tooltipEnabled?: boolean;
  tooltipMaxWidth?: number;
};

export type SCounterProps = {
  count: number;
  disabled?: boolean;
  tooltip?: SCounterTooltipProps;
  onChange: (newCount: number) => void;
  minCount?: number;
  maxCount?: number;
  style?: CSSProperties;
};

export const SCounter = ({
  count,
  disabled,
  tooltip,
  minCount = 0,
  maxCount = 999,
  onChange,
  style = {},
}: SCounterProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (value: number) => {
    if (value >= maxCount) {
      onChange(maxCount);
      return;
    }

    onChange(value);
  };

  useEffect(() => {
    if (count < minCount && !isFocused) {
      handleChange(minCount);
    }
  }, [count, isFocused, minCount]);

  const onBlur = () => setIsFocused(false);
  const onFocus = () => setIsFocused(true);

  const handleDecrement = () => handleChange(count - 1);
  const handleIncrement = () => handleChange(count + 1);
  const handleManualInput = (value: string) => handleChange(parseInt(value, 10) || 0);

  const displayCount = count >= minCount ? String(count) : '';
  const containerStyle = {
    ...styles.container,
    ...style,
  };

  return (
    <div style={containerStyle}>
      {tooltip ? (
        <UIconButtonWithTooltip
          size="L"
          icon="mixed-check"
          type="standard-dark"
          disabled={disabled}
          onClick={handleDecrement}
          tooltipPosition={tooltip.tooltipPosition}
          tooltipContent={tooltip.tooltipContent}
          tooltipEnabled={tooltip.tooltipEnabled}
          tooltipMaxWidth={tooltip.tooltipMaxWidth}
        />
      ) : (
        <UIconButton size="L" icon="mixed-check" type="standard-dark" disabled={disabled} onClick={handleDecrement} />
      )}
      <SInput
        type="small"
        contentType="number"
        hideCounter
        value={displayCount}
        onChange={handleManualInput}
        onBlur={onBlur}
        onFocus={onFocus}
        style={styles.input}
        extraInputStyle={styles.innerInput}
      />
      <UIconButton
        size="L"
        icon="plus"
        type={count <= minCount ? 'accentuated' : 'standard-dark'}
        disabled={count >= maxCount}
        onClick={handleIncrement}
      />
    </div>
  );
};

export default SCounter;
