import { apiAction } from 'Libs/redux/utils/apiAction';
import type { ApiActionType } from 'Libs/redux/utils/apiAction';

import { createSessionAfterApiAction } from 'Services/session.service';

import { AuthenticationSteps } from 'Models/Enum';

type Register2FAPhoneNumber = ApiActionType<'2FA_REGISTER_PHONE'>;

type Resend2FACode = ApiActionType<'2FA_RESEND_CODE'>;

type Verify2FACode = ApiActionType<'2FA_VERIFY_CODE'>;

type Set2FAInvitationTokenAction = {
  type: '2FA_SET_INVITATION_TOKEN';
  invitationToken: string;
};

type Set2FAPhoneNumberAction = {
  type: '2FA_SET_PHONE_NUMBER';
  phoneNumber: string;
};

type Reset2FAInformationAction = {
  type: '2FA_RESET';
};

type Reset2FAErrorAction = {
  type: '2FA_RESET_ERROR';
};

type Set2FAPhoneCountryCodeAction = {
  type: '2FA_SET_PHONE_COUNTRY_CODE';
  phoneCountryCode: string;
};

export const actions = {
  registerPhone: (invitationToken: string, phoneNumber: string, phoneCountryCode: string): Register2FAPhoneNumber =>
    apiAction({
      type: '2FA_REGISTER_PHONE',
      route: '/api/authentication/two-factor/contributors/phone-number',
      method: 'PUT',
      payload: { invitationToken, phoneNumber, phoneCountryCode },
    }),

  resendCode: (twoFactorToken: string): Resend2FACode =>
    apiAction({
      type: '2FA_RESEND_CODE',
      route: '/api/authentication/two-factor/code',
      method: 'POST',
      payload: { twoFactorToken },
    }),

  verifyCode: (twoFactorToken: string, code: string, trusted: boolean, device?: string): Verify2FACode => {
    const action = apiAction({
      type: '2FA_VERIFY_CODE',
      route: '/api/authentication/two-factor/contributors/session',
      method: 'POST',
      payload: { twoFactorToken, code, trusted, device },
    });

    // FIXME can this be done in the reducer instead?
    // This is the only place to do that because we are mocking store in page so all the store behavior are tested in reducer.test
    // @ts-expect-error async action return a vanilla promise type instead of bluebird
    action.promise?.tap?.((res) => {
      const { type, token, user } = res;

      return type === AuthenticationSteps.TWO_FACTOR_CHALLENGE_SUCCESS
        ? createSessionAfterApiAction(user, token)
        : Promise.resolve();
    });

    return action;
  },
  setInvitationToken: (invitationToken: string): Set2FAInvitationTokenAction => ({
    type: '2FA_SET_INVITATION_TOKEN',
    invitationToken,
  }),
  setPhoneNumber: (phoneNumber: string): Set2FAPhoneNumberAction => ({
    type: '2FA_SET_PHONE_NUMBER',
    phoneNumber,
  }),
  setPhoneCountryCode: (phoneCountryCode: string): Set2FAPhoneCountryCodeAction => ({
    type: '2FA_SET_PHONE_COUNTRY_CODE',
    phoneCountryCode,
  }),
  reset: (): Reset2FAInformationAction => ({
    type: '2FA_RESET',
  }),
  resetError: (): Reset2FAErrorAction => ({
    type: '2FA_RESET_ERROR',
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type TwoFactorAction = ReturnType<ActionsFn>;
