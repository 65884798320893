import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import Enum from 'Models/Enum';

import SLargeRadio from 'Components/structural/SLargeRadio/SLargeRadio';
import SInput from 'Components/structural/SInput/SInput';

import { actions, useModuleSelector } from '../../../redux';
import styles from '../panel.style';

const MAX_NUM_EXTRA_DAYS = 1000;

export const DailySerieTypeForm = () => {
  const largeRadioItems = useMemo(
    () => [
      {
        id: '1',
        text: t('activities:scheduling.side_panel.schedule_flexible'),
        tooltip: t('activities:scheduling.side_panel.schedule_flexible_tooltip'),
        delay: 1000,
        style: { display: 'flex', justifyContent: 'center' },
        hideIcon: true,
      },
      {
        id: '2',
        text: t('activities:scheduling.side_panel.schedule_intense'),
        tooltip: t('activities:scheduling.side_panel.schedule_intense_tooltip'),
        delay: 1000,
        style: { display: 'flex', justifyContent: 'center' },
        hideIcon: true,
      },
    ],
    [],
  );

  const activeButtonId = useModuleSelector((state) => String(state.activities[0].dailySerie.typeId));

  const dispatch = useTypedDispatch();
  const onClick = useCallback(
    (id) => {
      dispatch(actions.setTypeId(parseInt(id, 10)));
    },
    [dispatch],
  );

  const numExtraDays = useModuleSelector((state) => state.activities[0].dailySerie.numExtraDays);

  const onChange = useCallback(
    (value: string) => {
      const number = parseInt(value, 10);
      const days = Number.isInteger(number) ? Math.min(MAX_NUM_EXTRA_DAYS, Math.max(0, number)) : 0;

      dispatch(actions.setNumExtraDays(days));
    },
    [dispatch],
  );

  const isSequential = useModuleSelector(
    (state) => state.activities[0].dailySerie.typeId === Enum.dailySerieTypeEnum.SEQUENTIAL,
  );

  return (
    <div style={styles.box}>
      <h4 style={styles.title}>{t('activities:scheduling.side_panel.schedule_mode')}</h4>

      <div style={styles.contentElement}>
        <SLargeRadio items={largeRadioItems} activeItemId={activeButtonId} onClick={onClick} />
      </div>

      {isSequential ? (
        <div style={{ ...styles.element, display: 'flex', alignItems: 'center' }}>
          <SInput
            style={styles.input}
            extraInputStyle={styles.internalInputStyle}
            type="mini"
            value={numExtraDays.toString() || ''}
            placeholder="0"
            onChange={onChange}
          />
          {t('activities:scheduling.side_panel.extra_playable_days_label')}
        </div>
      ) : null}
    </div>
  );
};

export default DailySerieTypeForm;
