import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterState } from 'Libs/filter/types';
import { useAppDispatch } from 'Libs/redux/store';
import { useTypedSelector } from 'Libs/redux/utils';

import Enum from 'Models/Enum';
import User from 'Services/userService';

import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import { SFooter } from 'Components/structural/SFooter/SFooter';
import SNewContentCard from 'Components/structural/SNewContentCard/SNewContentCard';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import UTextLink from 'Components/unit/UTextLink/UTextLink';

import { contentMetadataSelector, selectAll } from 'Store/entities/content/content.selectors';
import { contentActions } from 'Store/entities/content/content.slice';
import { searchContents } from 'Store/entities/content/content.thunks';
import { ContentEntity } from 'Store/entities/content/content.types';

import { ContentCreationModal } from '../ContentCreationModal/ContentCreationModal';
import { ContentItem } from './ContentItem';
import styles, { NUMBER_OF_CARDS_PER_LINE } from './ContentList.style';
import { useContentFilters, useFormatFiltersForStore } from './hooks';
import { getContentFilterDescription } from './utils';

const NUMBER_OF_LINES_PER_PAGE = 4;
const CONTENT_PAGE_LIMIT = NUMBER_OF_LINES_PER_PAGE * NUMBER_OF_CARDS_PER_LINE;

export const ContentList = () => {
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(0);
  const [displayNewContentModal, setDisplayNewContentModal] = useState(false);

  const { loading, total, isFiltering } = useTypedSelector(contentMetadataSelector);
  const shouldShowCount = useMemo(() => isFiltering || total > 0, [total, isFiltering]);
  const description = useMemo(() => getContentFilterDescription(total, isFiltering), [isFiltering, total]);
  const hasWriteRole = User.meSync().hasOneRole([Enum.Role.ROOT, Enum.Role.MASTER, Enum.Role.ADMIN, Enum.Role.WRITER]);

  const contents = useTypedSelector<ContentEntity[]>((state) => selectAll(state.entities.content));

  const { filters, storeFilters } = useContentFilters();
  const fetchContents = () => {
    dispatch(
      searchContents({
        offset,
        limit: hasWriteRole && offset === 0 ? CONTENT_PAGE_LIMIT - 1 : CONTENT_PAGE_LIMIT,
        filters: storeFilters,
      }),
    );
  };
  useEffect(() => {
    fetchContents();
  }, [offset, ...filters]);

  const formatFiltersForStore = useFormatFiltersForStore();
  const handleFilterBoxChange = useCallback(
    (filterValues: FilterState[]) => {
      const newFilters = formatFiltersForStore(filterValues);
      dispatch(contentActions.updateFilters(newFilters));
    },
    [dispatch],
  );

  const canLoadMore = total > contents.length;

  const handlePress = useCallback(() => {
    if (loading) return;
    const nextOffset = hasWriteRole && offset === 0 ? CONTENT_PAGE_LIMIT - 1 : CONTENT_PAGE_LIMIT;
    setOffset((prev) => prev + nextOffset);
  }, [dispatch, contents.length]);

  const shouldRenderLoader = loading && contents.length === 0;
  return (
    <>
      <SFilterBox
        title={t('contents:contents_list.title')}
        description={description}
        placeholder={t('contents:contents_list.filters.placeholder')}
        filters={filters}
        onChange={handleFilterBoxChange}
        count={shouldShowCount ? total : null}
        style={styles.filterBoxStyle}
      />
      {shouldRenderLoader && (
        <div style={styles.pageLoader}>
          <SPageLoaderWithTimer isLoading={loading} style={styles.pageLoader} />
        </div>
      )}
      <div style={styles.gridContainer}>
        <div style={styles.grid}>
          {hasWriteRole && (
            <SNewContentCard
              onClick={() => {
                setDisplayNewContentModal(true);
              }}
              style={styles.newContentCard}
              type={total > 0 ? 'standard' : 'accentuated'}
              label={total > 0 ? '' : t('contents:contents_list.add_first_content')}
              data-test-id="new-content-card"
            />
          )}
          {contents.map((content) => (
            <ContentItem key={content.id} content={content} />
          ))}
        </div>
      </div>
      <ContentCreationModal
        visible={displayNewContentModal}
        onClose={() => {
          setDisplayNewContentModal(false);
        }}
        fetchContents={fetchContents}
      />
      <SFooter style={styles.loadMoreWrapper}>
        {canLoadMore && (
          <UTextLink
            text={t('contents:contents_list.load_more')}
            onClick={handlePress}
            type="secondary"
            rightIcon="bottom-chevron"
          />
        )}
      </SFooter>
    </>
  );
}
