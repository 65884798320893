import React, { useEffect, useState, useCallback } from 'react';

import { useService } from 'Hooks/useService';
import { getAudienceGroupCoursesList } from 'Features/audience/services/audience-group-courses-list.service';
import { CourseListFromAudience } from 'Features/audience/ui/components/CourseListFromAudience/CourseListFromAudience';

export const useCourseListFromAudience = () => {
  const [audienceGroupId, setAudienceGroupId] = useState<number | null>(null);

  const { loading, response: courses, call: fetchCourseListAndReturnLength, clean } = useService({ service: getAudienceGroupCoursesList });

  useEffect(() => {
    if (audienceGroupId) {
      fetchCourseListAndReturnLength({ audienceGroupId });
      return;
    }
    clean();
  }, [audienceGroupId, clean, fetchCourseListAndReturnLength]);

  const computeCourseListFromAudience = useCallback(() => {
    if (!courses || courses.length === 0) {
      return null;
    }

    return (
      <CourseListFromAudience courses={courses} />
    );
  }, [courses]);

  return {
    courses,
    loading,
    setAudienceGroupId,
    CourseListFromAudience: computeCourseListFromAudience,
  };
};
