import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  wrapper: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT,
  },
});

export default styles;
