import m from 'm';
import Promise from 'bluebird';
import popup from 'services/popupService';
import App from 'models/App';
import Enum from 'models/Enum';
import ApiKey from 'models/ApiKey';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import toggleButton from 'components/toggle.button';
import inputNumber from 'components/input.number';
import { getCdnUrl } from 'libs/cdn';
import moment from 'moment';

var component = {};
var MIN_ATTEMPS = 2;
var MAX_ATTEMPS = 20;
var MIN_FRAME_TIME = 1;
var MAX_FRAME_TIME = 59;
var MIN_LOCK_TIME = 1;
var MAX_LOCK_TIME = 59;

component.controller = function controller() {
  var self = this;

  var lockoutEnabled = App.configurations().get(Enum.configurations.BACK_OFFICE_LOCKOUT);
  var maxAttempts = App.configurations().get(Enum.configurations.BACK_OFFICE_LOCKOUT_MAX_ATTEMPTS);
  var frameTime = App.configurations().get(Enum.configurations.BACK_OFFICE_LOCKOUT_FRAME_TIME);
  var lockTime = App.configurations().get(Enum.configurations.BACK_OFFICE_LOCKOUT_LOCK_TIME);
  var kumullusVideo = App.configurations().get(Enum.configurations.BACK_OFFICE_KUMULLUS_VIDEO_UPLOAD);

  self.configLockoutEnabled = m.prop(lockoutEnabled.value());
  self.configMaxAttempts = m.prop(maxAttempts.value());
  self.configFrameTime = m.prop(frameTime.value());
  self.configLockTime = m.prop(lockTime.value());
  self.configKumullusVideoEnabled = m.prop(kumullusVideo.value());
  self.configLockoutEnabledDescription = m.prop(lockoutEnabled.description());
  self.configMaxAttemptsDescription = m.prop(maxAttempts.description());
  self.configFrameTimeDescription = m.prop(frameTime.description());
  self.configLockTimeDescription = m.prop(lockTime.description());
  self.configKumullusVideoDescription = m.prop(kumullusVideo.description());

  self.keys = [];
  ApiKey.find()
    .then(function keysResult(res) {
      self.keys = res;
      m.redraw();
    });

  self.confirmDestroyApiKey = function confirmDestroyApiKey(apiKey) {
    popup.open({
      type    : 'confirm',
      title   : 'Warning !!',
      subtitle: 'You are going to delete this api key.',
      content : 'A deleted api key cannot be retrieve. Be carefull to delete a useless api key.',
      confirm : 'Understood',
      action  : self.destroyApiKey.bind(null, apiKey),
    });
  };

  self.destroyApiKey = function destroyApiKey(apiKey) {
    apiKey.destroy().then(function redrawKeys() {
      m.redraw();
    });
  };

  self.openDocumentation = function openDocumentation() {
    window.open(getCdnUrl('common', '/api-documentation/OpenApiDoc.pdf'), '_blank');
  };

  self.createApiKey = function createApiKey() {
    ApiKey.createKey()
      .then(function redrawKeys(newKey) {
        self.keys.push(newKey);
        m.redraw();
      });
  };

  self.validateMaxAttempts = function validateMaxAttempts(value) {
    return self.configMaxAttempts() + value >= MIN_ATTEMPS
      && self.configMaxAttempts() + value <= MAX_ATTEMPS;
  };

  self.validateMaxFrameTime = function validateMaxAttempts(value) {
    return self.configFrameTime() + value >= MIN_FRAME_TIME
      && self.configFrameTime() + value <= MAX_FRAME_TIME;
  };

  self.validateMaxLockTime = function validateMaxAttempts(value) {
    return self.configLockTime() + value >= MIN_LOCK_TIME
      && self.configLockTime() + value <= MAX_LOCK_TIME;
  };

  self.saving = m.prop(false);
  self.modified = m.prop(false);
  self.saved = m.prop(false);

  self.save = function save() {
    self.saving(true);
    m.redraw();
    lockoutEnabled.value(self.configLockoutEnabled());
    maxAttempts.value(self.configMaxAttempts());
    frameTime.value(self.configFrameTime());
    lockTime.value(self.configLockTime());
    kumullusVideo.value(self.configKumullusVideoEnabled());

    return Promise.all([
      lockoutEnabled.save(),
      maxAttempts.save(),
      frameTime.save(),
      lockTime.save(),
      kumullusVideo.save(),
    ]).then(function redraw() {
      self.saving(false);
      self.modified(false);
      m.redraw();
    });
  };

  self.warningSave = function warningSave(action) {
    if (!self.modified())
      return action();

    function wrappedAction() {
      self.modified(false);
      action();
    }
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit without saving?',
      confirm: 'Don\'t save',
      cancel : 'Cancel',
      action : wrappedAction,
    });
  };
};

component.view = function view(c) {
  var saveButton = m(button, {
    value   : c.modified() ? 'Save' : 'Saved',
    class   : 'button--save',
    action  : c.save,
    disabled: !c.modified(),
    loading : c.saving(),
  });

  return m('.gaia__page', [
    m('.header', [
      m(menu, {
        confirm: c.warningSave,
      }),
      m(gaiaMenu, {
        confirm: c.warningSave,
      }),
    ]),
    m('.gaia__body', [
      m('.gaia__toolbar', [
        m('.gaia__title__container', [
          m('.gaia__title', 'Back Office'),
          m('.gaia__subtitle', 'Security password'),
        ]),
        m('.gaia__toolbar--right', [saveButton]),
      ]),
      m('.gaia__body__container', {
        key: 'backOffice',
      }, [
        m.hide(c.configLockoutEnabled(), '.gaia__configuration__line__overlay'),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--security'),
            m('.gaia__configuration__line__title-container__label', 'Additional Sign-In security measures'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Additional Sign-In security measures'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configLockoutEnabledDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(toggleButton, {
              value  : c.configLockoutEnabled,
              onclick: c.modified.bind(null, true),
            }),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--attempts'),
            m('.gaia__configuration__line__title-container__label', 'Maximum number of password attempts'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Maximum number of password attempts'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configMaxAttemptsDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configMaxAttempts,
              modified : c.modified,
              validator: c.validateMaxAttempts,
              minValue : MIN_ATTEMPS,
              maxValue : MAX_ATTEMPS,
            }),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--session'),
            m('.gaia__configuration__line__title-container__label', 'Time frame for entering passwords'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Time frame for entering passwords'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configFrameTimeDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configFrameTime,
              modified : c.modified,
              validator: c.validateMaxFrameTime,
              minValue : MIN_FRAME_TIME,
              maxValue : MAX_FRAME_TIME,
            }),
            m('.gaia__configuration__line__input-suffix', 'Min'),
          ]),
        ]),
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--penalty'),
            m('.gaia__configuration__line__title-container__label', 'Account temporary lock'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Account temporary lock'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configLockTimeDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(inputNumber, {
              value    : c.configLockTime,
              modified : c.modified,
              validator: c.validateMaxLockTime,
              minValue : MIN_LOCK_TIME,
              maxValue : MAX_LOCK_TIME,
            }),
            m('.gaia__configuration__line__input-suffix', 'Min'),
          ]),
        ]),
      ]),
      m('.gaia__subtitle', 'Medias'),
      m('.gaia__body__container', [
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--kumullus'),
            m('.gaia__configuration__line__title-container__label', 'Kumullus videos'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Kumullus videos'),
                m('.gaia__configuration__line__title-container__info__text-text', c.configKumullusVideoDescription()),
              ]),
            ]),
          ]),
          m('.gaia__configuration__line__input-container', [
            m(toggleButton, {
              value  : c.configKumullusVideoEnabled,
              onclick: c.modified.bind(null, true),
            }),
          ]),
        ]),
      ]),
      m('.gaia__subtitle', 'API keys'),
      m('.gaia__body__container', [
        m('.gaia__configuration__line', [
          m('.gaia__configuration__line__title-container', [
            m('.gaia__configuration__line__title-container__icon.gaia__configuration__line__title-container__icon--key'),
            m('.gaia__configuration__line__title-container__label', 'Tracking API keys'),
            m('.gaia__configuration__line__title-container__info', [
              m('.gaia__configuration__line__title-container__info__text', [
                m('.gaia__configuration__line__title-container__info__text-title', 'Tracking API keys'),
                m('.gaia__configuration__line__title-container__info__text-text', 'Want to build something that integrates with and extends your SPARTED application? Register a new integration to get started developing on the SPARTED Open API.'),
              ]),
            ]),
          ]),
          m(button, {
            value : 'Documentation',
            action: c.openDocumentation,
            class : 'button--border button--action gaia__configuration__button__documentation',
          }),
        ]),
        displayKeys(c),
        m(button, {
          value : '+ Create new api key',
          action: c.createApiKey,
          class : 'button--border button--action gaia__configuration__line__bottom--newkey',
        }),
      ]),
      m('.gaia__toolbar.gaia__toolbar--bottom', [
        m('.gaia__toolbar--right', [saveButton]),
      ]),
    ]),
  ]);
};

function displayKeys(c) {
  return c.keys.map(function displayKey(key, index) {
    return m(index % 2 === 0 ? '.gaia__configuration__api__line' : '.gaia__configuration__api__line--colored', [
      m('.gaia__configuration__line__title-api-container', [
        m('.gaia__configuration__line__title-api-container__label', 'API key ' + (index + 1)),
        m(button, {
          action: c.confirmDestroyApiKey.bind(null, key),
          value : '',
          class : [
            'gaia__configuration__line__title-api-container__destroy',
          ].join(' '),
        }),
      ]),
      m('.gaia__configuration__line__infos-api-container', [
        m('.gaia__configuration__line__infos-api-container__date', moment(key.createdAt()).format('DD/MM/YYYY')),
        m('.gaia__configuration__line__infos-api-container__key', key.apiKey()),
      ]),
    ]);
  });
}

export default component;
