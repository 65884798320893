import { COLORS, ELEVATIONS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: COLORS.WHITE.DEFAULT,
    height: 45,
    borderRadius: 8,
    border: '1px solid',
    borderColor: COLORS.BLUE_GRAY.LIGHT,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'auto',
  },
  hover: {
    boxShadow: ELEVATIONS.LIGHT_TINY,
  },
  selected: {
    borderColor: COLORS.PRIMARY.DEFAULT,
  },
  noneVariant: {
    marginLeft: 7,
  },
  optionsMenu: {
    position: 'relative',
  },
  inputStyle: {
    paddingRight: 8,
    width: '100%',
  },
  radioButton: {
    marginLeft: 8,
  },
  uCheckBox: {
    marginLeft: 9,
  },
  noSelectable: {
    marginLeft: 8,
  },
  icon: {
    marginLeft: 8,
    marginRight: 8,
    flexShrink: 0,
  },
});

export default styleSheet;
