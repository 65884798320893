import Model from 'models/Model';

var Role = {
  endpoint  : 'api/PlayerRoles',
  attributes: {
    name: {
      type  : 'string',
      unique: true,
    },
  },
};

export default Model.register('Role', Role);
