// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  uploadDropZoneTitle: {
    marginTop: '56px',
    marginBottom: '32px',
    ...TYPOGRAPHY.H1,
  },
};
