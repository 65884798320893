import { encodeSearchObject } from '@sparted/shared-library/http';

import type { SearchObject, SearchQuery, SearchSort } from '@sparted/shared-library/types/search';

import type { CommandsRequest } from 'Pages/Courses/utils/commands';
import type { CourseFormData, DuplicateCourseData } from 'Pages/Courses/types';

import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils/helpers';

import { ContributorType } from 'Pages/Contributor/redux/models/Contributor';
import {
  CourseSort,
  CommandResponse,
  FetchCourseResponse,
  PublicationResponse,
  FetchFilterCourseArg,
  CreateCourseResponse,
  FilterCoursesResponse,
  UpdatePublishStatusArgs,
  FetchAudienceMetadataArgs,
  FetchAudienceMetadataResponse,
  FetchPdfCourseResponse,
  DuplicateCourseResponse,
} from './types';

export const getCourseSearchParams = (args: FetchFilterCourseArg) => {
  const { limit, offset, status, title, sort, audienceGroupId, languageCode } = args;

  const sortObjectMap: Record<CourseSort, SearchSort> = {
    alphabetical: { title: 'asc' },
    lastEditionDate: { editionDate: 'desc' },
  };
  const queryObject: SearchQuery = {
    ...(status && status !== 'all' ? { publicationStatus: status } : {}),
    ...(title ? { title } : {}),
    ...(audienceGroupId && audienceGroupId.length > 0 ? { audienceGroupId } : {}),
    ...(languageCode && languageCode.length > 0 ? { languageCode } : {}),
  };
  const searchProperties: SearchObject = {
    sort: sort ? sortObjectMap[sort] : undefined,
    query: queryObject,
    limit,
    offset,
  };

  return encodeSearchObject(searchProperties);
};

export const fetchFilteredCourses = createAsyncAction<FilterCoursesResponse, FetchFilterCourseArg>(
  'courses/list/fetchFilteredCourses',
  async (args) => {
    const urlParams = getCourseSearchParams(args);

    const { fetchFn } = fetchFactory<FilterCoursesResponse>({
      route: `/api/courses?${urlParams}`,
      method: 'GET',
    });

    return fetchFn();
  },
);

export const fetchNextCoursePage = createAsyncAction('courses/list/fetchNextCoursePage', async (nextPage: string) => {
  const { fetchFn } = fetchFactory<FilterCoursesResponse>({
    route: nextPage,
    method: 'GET',
  });

  return fetchFn();
});

export const createCourse = createAsyncAction(
  'courses/edition/createCourse',
  (formData: CourseFormData & { currentUser: ContributorType }) => {
    const { title, languageCountry, description, image } = formData;

    const { fetchFn } = fetchFactory<CreateCourseResponse>({
      route: '/api/courses',
      method: 'POST',
      payload: {
        title,
        language: languageCountry,
        description,
        imageId: image?.id,
      },
    });

    return fetchFn();
  },
);

export const duplicateCourse = createAsyncAction(
  'courses/edition/duplicateCourse',
  (formData: DuplicateCourseData & { currentUser: ContributorType }) => {
    const { id, currentUser } = formData;
    const { fetchFn } = fetchFactory<DuplicateCourseResponse>({
      route: '/api/courses/duplicate',
      method: 'POST',
      payload: {
        editorEmail: currentUser.email,
        courseId: id,
      },
    });
    return fetchFn();
  },
);

export const sendCommands = createAsyncAction(
  'courses/edition/sendCommands',
  (formData: { id: number; payload: CommandsRequest; context?: 'edit' | 'list' }) => {
    const { id, payload } = formData;

    const { fetchFn } = fetchFactory<CommandResponse>({
      route: `/api/courses/${id}/commands`,
      method: 'POST',
      payload,
    });

    return fetchFn();
  },
);

export const fetchDraftCourse = createAsyncAction('courses/edition/fetchDraftCourse', (id: number) => {
  const { fetchFn } = fetchFactory<FetchCourseResponse>({
    route: `/api/courses/${id}/draft`,
    method: 'GET',
  });

  return fetchFn();
});

export const updateCoursePublishStatus = createAsyncAction<PublicationResponse, UpdatePublishStatusArgs>(
  'courses/publishing/updateCoursePublishStatus',
  ({ courseId, published, editionId }) => {
    const { fetchFn } = fetchFactory<PublicationResponse>({
      route: `/api/courses/${courseId}/publication-status`,
      payload: { published, editionId },
      method: 'PUT',
    });

    return fetchFn();
  },
);

export const fetchAudienceMetadata = createAsyncAction<FetchAudienceMetadataResponse, FetchAudienceMetadataArgs>(
  'courses/audience/fetchAudienceMetadata',
  ({ groupIds, languageIds }) => {
    const urlParams = new URLSearchParams({
      groupIds: groupIds.join(','),
      languageIds: languageIds.join(','),
    });

    const { fetchFn } = fetchFactory<FetchAudienceMetadataResponse>({
      route: `/api/audiences/meta?${urlParams.toString()}`,
      method: 'GET',
    });

    return fetchFn();
  },
);

export const fetchPdf = createAsyncAction('courses/edition/fetchPdf', (id: number) => {
  const { fetchFn } = fetchFactory<FetchPdfCourseResponse>({
    route: `/api/courses/${id}/pdfs/`,
    method: 'GET',
  });

  return fetchFn();
});

export const downloadCourseProgressExport = createAsyncAction('courses/progress/downloadExport', (id: number) => {
  const { fetchFn } = fetchFactory<FetchPdfCourseResponse>({
    route: `/api/course-progression/${id}/export`,
    method: 'GET',
  });

  return fetchFn();
});
