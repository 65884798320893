/* eslint-disable complexity */
/* eslint-disable flowtype/require-compound-type-alias */
/* eslint-disable key-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-multi-comp */
// @flow

import React, { useCallback, useMemo } from 'react';
import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import Enum from 'Models/Enum';

import { makeValidationMessage } from '../redux/models/Content';
import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions } from '../redux/actions';

import styles from './ContentAlertView.style';

type Props = {|
  component: React$AbstractComponent<any, any>,
|};

const CONTENT_STATUS = Enum.contentStatus;

const ALL_SCOPES: $ReadOnlyArray<AlertScope> = [
  'validationError',
  'fetchError',
  'fetchKnowledgeError',
  'saveError',
  'archiveError',
  'archiveSuccess',
  'changeStatusSuccess',
  'changeStatusError',
];

export const IDS: { [AlertScope]: number } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [(x: any)]: i }), {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES
  .reduce((acc, x, i) => ({ ...acc, [i]: x }), {});

const TYPES = {
  'validationError': 'error',
  'fetchError': 'error',
  'fetchKnowledgeError': 'error',
  'saveError': 'error',
  'archiveError': 'error',
  'archiveSuccess': 'success',
  'changeStatusError': 'error',
  'changeStatusSuccess': 'success',
};

const TITLES = {
  validationError: 'This content is not ready',
  fetchError: 'Something went wrong',
  fetchKnowledgeError: 'Something went wrong',
  saveError: 'Something went wrong',
  activateError: 'Something went wrong',
  archiveError: 'Something went wrong',
  archiveSuccess: '',
  changeStatusError: 'Something went wrong',
  changeStatusSuccess: '',
};

const VALIDATION_SUCCESS_MESSAGE = {
  [CONTENT_STATUS.DRAFT]: 'The content was successfully unvalidated.',
  [CONTENT_STATUS.TO_VALIDATE]: 'This content is now pending validation.',
  [CONTENT_STATUS.VALIDATED]: 'The content was successfully validated.',
};

const VALIDATION_ERROR_MESSAGE = {
  [CONTENT_STATUS.DRAFT]: 'The content could not be unvalidated.',
  [CONTENT_STATUS.TO_VALIDATE]: 'The content could not be requested for validation.',
  [CONTENT_STATUS.VALIDATED]: 'The content could not be validated.',
};

export const makeAlertMessage = (
  scope: AlertScope,
  params: AlertParams,
  gameplayId?: number,
) => {
  const serverMessage = params.message || '';

  switch (scope) {

    case 'validationError': {
      const { fields: resultFields, validationParams } = params;
      const fields = resultFields || [];

      if (!gameplayId)
        return '';

      return makeValidationMessage({ fields, params: validationParams }, gameplayId);
    }
    case 'fetchError': return `The content could not be loaded.\n${serverMessage}`;
    case 'fetchKnowledgeError': return `The knowledge could not be loaded.\n${serverMessage}`;
    case 'saveError': return `The content could not be saved.\n${serverMessage}`;
    case 'archiveError': return `The content could not be ${params.archived ? '' : 'un'}archived.\n${serverMessage}`;
    case 'archiveSuccess': return `The content was successfully ${params.archived ? '' : 'un'}archived.`;
    case 'changeStatusSuccess': {
      const { statusId } = params;
      const message = statusId
        ? VALIDATION_SUCCESS_MESSAGE[statusId]
        : 'The content\'s status was successfully changed.';

      return message;
    }
    case 'changeStatusError': {
      const { statusId } = params;
      const message = statusId
        ? VALIDATION_ERROR_MESSAGE[statusId]
        : 'The content\'s status could not be changed.';

      return `${message}\n${serverMessage}`;
    }

    default: return '';
  }
};

const makeAlertsFromStateAlerts = (gameplayId, alerts) => alerts
  .map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params, gameplayId),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

export const ContentAlertView = ({ component }: Props) => {
  const gameplayId = useTypedSelector(state => state.pages.content.content.gameplayId);
  const stateAlerts = useTypedSelector(state => state.pages.content.alerts);
  const alerts = useMemo(() => makeAlertsFromStateAlerts(gameplayId, stateAlerts), [gameplayId, stateAlerts]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return (
    <AlertHOC alerts={alerts} onClose={onClose} />
  );
};
