import { store } from 'Libs/redux/store';

import type { Action } from 'Libs/redux/types';
import { actions, SupportedAlertTypes } from './actions';
import type { Alert } from './reducer';

type ReduxStore = typeof store;

const globalDispatch = (action: Action, reduxStore: ReduxStore = store) => reduxStore.dispatch(action);

const remove = (alertType: SupportedAlertTypes) => (id: string) => globalDispatch(actions.remove(alertType, id));
const removeBasicAlert = remove(SupportedAlertTypes.BASIC);
const removeToastAlert = remove(SupportedAlertTypes.TOAST);

const enqueue =
  (alertType: SupportedAlertTypes) =>
  (params: Alert, timeout = -1) => {
    globalDispatch(actions.enqueue(alertType, params));

    if (timeout !== -1) {
      setTimeout(() => remove(alertType)(params.id), timeout);
    }
  };

const enqueueBasicAlert = enqueue(SupportedAlertTypes.BASIC);
const enqueueToastAlert = enqueue(SupportedAlertTypes.TOAST);

const reset = () => globalDispatch(actions.reset());

export {
  globalDispatch,
  enqueue,
  enqueueBasicAlert,
  enqueueToastAlert,
  remove,
  removeBasicAlert,
  removeToastAlert,
  reset,
};
