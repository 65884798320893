/**
 * Displays a secondary tab label
 *
 * Props:
 * - onClick: Function called on click
 * - text: text shown in the tab label
 * - active: state representing if tab is active or not
 * - disabled: state representing if tab is disabled or not
 * - style: can override the component's style
 */

import React, { useState, useCallback, useMemo } from 'react';
import type { CSSProperties } from 'react';

import styles from './USecondaryTabLabel.style';

const MAX_LENGTH = 25;

export type USecondaryTabLabelProps = {
  text: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
};

const truncateText = (str: string, len: number) => (str.length <= len ? str : str.slice(0, len));

const noop = () => {};

export const USecondaryTabLabel = ({
  onClick,
  text,
  style: propStyle = undefined,
  disabled = false,
  active = false,
}: USecondaryTabLabelProps) => {
  const [hover, setHover] = useState(false);
  const activeStyle = useMemo(() => (active && !disabled ? styles.active : styles.container), [active, disabled]);
  const disabledStyle = useMemo(() => (disabled ? styles.disabled : styles.container), [disabled]);
  const isHovered = useMemo(
    () => (hover && !disabled && !active ? styles.hover : styles.container),
    [active, disabled, hover],
  );
  const handleMouseIn = useCallback(() => setHover(true), []);
  const handleMouseOut = useCallback(() => setHover(false), []);
  const styleObject = useMemo(
    () => ({ ...propStyle, ...activeStyle, ...disabledStyle, ...isHovered }),
    [activeStyle, disabledStyle, isHovered, propStyle],
  );

  const textStyle = useMemo(() => {
    if (active && !disabled) {
      return styles.activeText;
    }

    if (disabled) {
      return styles.disabledText;
    }

    return styles.text;
  }, [active, disabled]);

  return (
    <div
      onMouseOver={handleMouseIn}
      onMouseOut={handleMouseOut}
      onBlur={noop}
      onFocus={noop}
      style={styleObject}
      onClick={onClick}
    >
      <p style={textStyle}>{truncateText(text, MAX_LENGTH)}</p>
    </div>
  );
};

export default USecondaryTabLabel;
