import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { actions } from '../../redux';

import styles from './CategoryDeletionModal.style';

export const CategoryDeletionModal = () => {
  const dispatch = useTypedDispatch();
  const categoryToDelete = useTypedSelector((state) => state.pages.categoryList.categoryToDelete);
  const deletionError = useTypedSelector((state) => state.pages.categoryList.deletionError);
  const isVisible = useTypedSelector((state) => state.pages.categoryList.isDeletionModalOpen);
  const warningLabel = t('config_categories:category_deletion_modal.warning_label', { label: categoryToDelete.label });
  const warningSubLabel = t('config_categories:category_deletion_modal.warning_sublabel', {
    count: categoryToDelete.documentsCount,
  });

  const subContentStyle = {
    ...styles.subContent,
    display: categoryToDelete.documentsCount ? 'default' : 'none',
  };

  const handleDeleteCategory = useCallback(
    () => Promise.resolve(dispatch(actions.delete(categoryToDelete.id))),
    [dispatch, categoryToDelete],
  );

  const handleDeleteEnd = useCallback(
    (res: any) => {
      const { error } = res;

      if (!error) dispatch(actions.toggleDeletionModal(false));
    },
    [dispatch],
  );

  const handleCloseModal = useCallback(() => dispatch(actions.toggleDeletionModal(false)), [dispatch]);

  return (
    <MModal
      visible={isVisible}
      onCloseModal={handleCloseModal}
      onAction={handleDeleteCategory}
      onActionEnd={handleDeleteEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={t('config_categories:category_deletion_modal.title')}
      labelActionButton={t('config_categories:category_deletion_modal.label_action_button')}
      labelSecondButton={t('config_categories:category_deletion_modal.label_second_button')}
      actionButtonType="destructive"
      labelError={deletionError}
    >
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <span style={styles.warningLabel}>{warningLabel}</span>
        </div>
        <div style={subContentStyle}>
          <span style={styles.warningSubLabel}>{warningSubLabel}</span>
        </div>
      </div>
    </MModal>
  );
};
