import moment from 'moment';
import { handle } from 'redux-pack';
import { arrayToMap } from '@sparted/shared-library/array';

import Enum from 'Models/Enum';
import { getErrorMessage } from 'Libs/redux/utils';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { AudienceTargetLabelIdType, AudienceTargetLabelType } from 'Libs/ts/types';
import type { Action } from 'Libs/redux/types';

import createActivity, { findDefaultActivity, isActivityLocked } from './models/Activity';

import type { ActivityType } from './models/Activity';

import createContent from './models/Content';
import type { ContentType } from './models/Content';

import type { AlertScope, AlertType } from './models/Alert';
import type { AudienceTargetType } from './models/AudienceTarget';
import type { AudienceType } from './models/Audience';

import createAudienceTarget, { createPlayer } from './models/AudienceTarget';
import createAudience, { createMagicCodeConfig, createEmailConfig, createSegmentationConfig } from './models/Audience';

import createDailySerieContent from './models/DailySerieContent';
import { calculateContentRepartition } from './models/DailySerie';

export type MultilingualContentsMapType = {
  [contentMultilingualId: number]: {
    [languageId: number]: ContentType;
  };
};

export type ActivityState = DeepReadonly<{
  isFetchLoading: boolean;
  isSaveLoading: boolean;
  isArchiveLoading: boolean;
  isActivateLoading: boolean;
  isTranslateLoading: boolean;
  isRefreshMultilingualLoading: boolean;
  isAskKnowledgeTranslationLoading: boolean;
  isLocked: boolean;
  activities: ReadonlyArray<ActivityType>;
  originalActivities: ReadonlyArray<ActivityType>;
  alerts: ReadonlyArray<AlertType>;
  isTargetModeReset: boolean;
  selectedLanguageId: number;
  multilingualContentsMap: MultilingualContentsMapType;
  audience: AudienceType;
  originalAudience: AudienceType;
  audienceTarget: AudienceTargetType;
}>;

const defaultActivities = [createActivity()];
const defaultAudience = createAudience();
const defaultAudienceTarget = createAudienceTarget();

export const defaultState: ActivityState = {
  isFetchLoading: false,
  isSaveLoading: false,
  isArchiveLoading: false,
  isActivateLoading: false,
  isTranslateLoading: false,
  isRefreshMultilingualLoading: false,
  isAskKnowledgeTranslationLoading: false,
  isLocked: false,
  activities: defaultActivities,
  originalActivities: defaultActivities,
  alerts: [],
  isTargetModeReset: false,
  selectedLanguageId: 0,
  multilingualContentsMap: Object.freeze({}),
  audience: defaultAudience,
  originalAudience: defaultAudience,
  audienceTarget: defaultAudienceTarget,
};

// Here ML stands for Multilingual

const makeLoadingFlag = (type: Action['type'], value: boolean) => {
  switch (type) {
    case 'ACTIVITY_ML_FETCH_BY_ID':
      return { isFetchLoading: value };
    case 'ACTIVITY_ML_SAVE':
      return { isSaveLoading: value };
    case 'ACTIVITY_ML_ARCHIVE':
      return { isArchiveLoading: value };
    case 'ACTIVITY_ML_ACTIVATE':
      return { isActivateLoading: value };
    case 'ACTIVITY_ML_ASK_TRANSLATION':
      return { isTranslateLoading: value };
    case 'ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH':
      return { isAskKnowledgeTranslationLoading: value };
    case 'ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS':
      return { isRefreshMultilingualLoading: value };
    default:
      return {};
  }
};

const makeServerAlertParams = (type: Action['type'], prevState: ActivityState) => {
  switch (type) {
    case 'ACTIVITY_ML_ACTIVATE':
      return { active: !prevState.activities[0].active };
    case 'ACTIVITY_ML_ARCHIVE':
      return { archived: !prevState.activities[0].archived };
    default:
      return {};
  }
};

const ERROR_SCOPE = {
  ACTIVITY_ML_FETCH_BY_ID: 'fetchError',
  ACTIVITY_ML_SAVE: 'saveError',
  ACTIVITY_ML_ACTIVATE: 'activateError',
  ACTIVITY_ML_DUPLICATE: 'duplicateError',
  ACTIVITY_ML_ARCHIVE: 'archiveError',
  ACTIVITY_ML_ASK_TRANSLATION: 'translateError',
  ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH: 'knowledgesTranslateError',
  ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS: 'refreshContentError',
} as const;

const SUCCESS_SCOPE = {
  ACTIVITY_ML_FETCH_BY_ID: '',
  ACTIVITY_ML_SAVE: 'saveSuccess',
  ACTIVITY_ML_ACTIVATE: '',
  ACTIVITY_ML_DUPLICATE: 'duplicateSuccess',
  ACTIVITY_ML_ARCHIVE: 'archiveSuccess',
  ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH: 'knowledgesTranslateSuccess',
  ACTIVITY_ML_ASK_TRANSLATION: 'translateSuccess',
  ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS: '',
} as const;

const makeNextKey = (array: ReadonlyArray<{ key: number }>) =>
  array.length > 0 ? Math.max(...array.map((x) => x.key)) + 1 : 0;

const copyAndInsertAtIndex = <T>(array: ReadonlyArray<T>, index: number, elem: T): ReadonlyArray<T> => {
  const result = [...array];

  result.splice(index, 0, elem);

  return result;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ActivityState = defaultState, action: Action): ActivityState => {
  switch (action.type) {
    case 'ACTIVITY_ML_FETCH_BY_ID':
    case 'ACTIVITY_ML_ACTIVATE':
    case 'ACTIVITY_ML_DUPLICATE':
    case 'ACTIVITY_ML_ARCHIVE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            ...makeLoadingFlag(action.type, true),
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE[action.type])
              .filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),
          }),
        success: (prevState) => {
          const { payload } = action;
          const activities: ReadonlyArray<ActivityType> = payload.activities.map((a: any) => createActivity(a));
          const defaultActivity = findDefaultActivity(activities);
          const isLocked = isActivityLocked(defaultActivity, moment());

          const alerts = SUCCESS_SCOPE[action.type]
            ? prevState.alerts.concat([
                {
                  scope: SUCCESS_SCOPE[action.type] as AlertScope,
                  params: makeServerAlertParams(action.type, prevState),
                },
              ])
            : prevState.alerts;

          const selectedLanguageId = state.selectedLanguageId || defaultActivity.languageId;

          const audience = createAudience(payload.audience);

          return mergeDeep(
            // INFO: Set to null to ensure mergeDeep assigns the sames reference to both objects
            {
              ...prevState,
              audience: null,
              originalAudience: null,
            } as unknown as ActivityState,
            {
              activities,
              originalActivities: activities,
              isLocked,
              alerts,
              selectedLanguageId,
              audience,
              originalAudience: audience,
            },
          );
        },

        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
                ...makeServerAlertParams(action.type, prevState),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },

        finish: (prevState) => mergeDeep(prevState, makeLoadingFlag(action.type, false)),
      });

    case 'ACTIVITY_ML_SAVE':
      return handle(state, action, {
        start: (prevState) => {
          const targetModeId = prevState.audience.mode as AudienceTargetLabelIdType;
          const targetModelLabel = Enum.audienceTargetLabels[targetModeId];
          const originalTargetModeId = prevState.originalAudience.mode;
          const targetLoading = targetModeId !== originalTargetModeId;

          return mergeDeep(prevState, {
            ...makeLoadingFlag(action.type, true),
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE[action.type])
              .filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),

            audienceTarget: { [targetModelLabel]: { isFetchLoading: targetLoading } },
          });
        },
        success: (prevState) => {
          const { payload } = action;
          const activities: ReadonlyArray<ActivityType> = payload.activities.map((a: any) => createActivity(a));
          const defaultActivity = findDefaultActivity(activities);
          const isLocked = isActivityLocked(defaultActivity, moment());

          const audience = createAudience(payload.audience);
          const targetModeId = audience.mode as AudienceTargetLabelIdType;
          const targetModelLabel = Enum.audienceTargetLabels[targetModeId];

          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: makeServerAlertParams(action.type, prevState),
            },
          ]);

          return mergeDeep(
            // INFO: Set to null to ensure mergeDeep assigns the sames reference to both objects
            {
              ...prevState,
              activities: null,
              originalActivities: null,
              audience: null,
              originalAudience: null,
            } as unknown as ActivityState,
            {
              activities,
              originalActivities: activities,
              isLocked,
              audienceTarget: { [targetModelLabel]: { needsRefresh: true } },
              audience,
              originalAudience: audience,
              alerts,
            },
          );
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
                ...makeServerAlertParams(action.type, prevState),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, makeLoadingFlag(action.type, false)),
      });

    case 'ACTIVITY_ML_KNOWLEDGES_ASK_TRANSLATION_BATCH':
    case 'ACTIVITY_ML_ASK_TRANSLATION':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            ...makeLoadingFlag(action.type, true),
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE[action.type])
              .filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),
          }),

        success: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE[action.type],
              params: makeServerAlertParams(action.type, prevState),
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },

        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
                ...makeServerAlertParams(action.type, prevState),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, makeLoadingFlag(action.type, false)),
      });

    case 'ACTIVITY_ML_GET_MULTILINGUAL_CONTENTS':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            ...makeLoadingFlag(action.type, true),
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]),
          }),

        success: (prevState) => {
          const { payload } = action;
          const multilingualContentIds = Object.keys(payload);

          const multilingualContentsMap: MultilingualContentsMapType = arrayToMap(multilingualContentIds, {
            selectKey: (id) => id,
            dataModifier: (multilingualContentId) => {
              const languageIds = Object.keys(payload[multilingualContentId]);

              return arrayToMap(languageIds, {
                selectKey: (id) => id,
                dataModifier: (languageId) => createContent(payload[multilingualContentId][languageId]),
              });
            },
          });

          // INFO mergeDeep cannot be used here
          // as the keys of the map are not always in the original map
          return {
            ...prevState,
            multilingualContentsMap,
          };
        },

        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
                ...makeServerAlertParams(action.type, prevState),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, makeLoadingFlag(action.type, false)),
      });

    case 'ACTIVITY_ML_SET_TYPE_ID': {
      const activities = state.activities.map((a) => {
        const numExtraDays = action.typeId === Enum.dailySerieTypeEnum.SYNCHRONOUS ? 0 : a.dailySerie.numExtraDays;

        return mergeDeep(a, { dailySerie: { typeId: action.typeId, numExtraDays } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_SET_TARGET_MODE_ID': {
      if (!action.targetModeId) {
        return mergeDeep(state, { isTargetModeReset: true });
      }

      return mergeDeep(state, {
        isTargetModeReset: false,
        audience: { mode: action.targetModeId },
      });
    }

    case 'ACTIVITY_ML_SET_MAGIC_CODE_FOR_AUDIENCE': {
      const code = action.magicCode;
      const newCode = code === null ? createMagicCodeConfig() : createMagicCodeConfig(code.trim());

      return mergeDeep(state, { audience: { MAGIC_CODE: newCode } });
    }

    case 'ACTIVITY_ML_ADD_EMAILS_TO_AUDIENCE': {
      return mergeDeep(state, {
        audience: { EMAIL_LIST: createEmailConfig(action.emails.map((email) => ({ email }))) },
      });
    }

    case 'ACTIVITY_ML_GET_AUDIENCE_TARGET':
      return handle(state, action, {
        start: (prevState) => {
          const targetModelLabel = Enum.audienceTargetLabels[prevState.audience.mode as AudienceTargetLabelIdType];

          return mergeDeep(prevState, {
            audienceTarget: { [targetModelLabel]: { needsRefresh: false, deleteError: false } },
          });
        },
        success: (prevState) => {
          const targetModelLabel = Enum.audienceTargetLabels[
            prevState.audience.mode as AudienceTargetLabelIdType
          ] as AudienceTargetLabelType;
          const currentPlayers = prevState.audienceTarget[targetModelLabel].players;
          const currentTotal = prevState.audienceTarget[targetModelLabel].total;
          const newPlayers = action.payload.players.players.map(createPlayer);
          const { total } = action.payload.count;

          const players = action.payload.toAdd ? currentPlayers.concat(newPlayers) : newPlayers;
          const numTargetedPlayers = action.meta.filter.search ? currentTotal : total;

          const audienceTarget = createAudienceTarget({
            targetModeId: prevState.audience.mode,
            players,
            numTargetedPlayers,
            total,
            registered: action.payload.count.registered,
          });

          return mergeDeep(prevState, { audienceTarget });
        },
        finish: (prevState) => {
          const targetModelLabel = Enum.audienceTargetLabels[prevState.audience.mode as AudienceTargetLabelIdType];

          return mergeDeep(prevState, {
            audienceTarget: { [targetModelLabel]: { isFetchLoading: false, needsRefresh: false } },
          });
        },
      });

    case 'ACTIVITY_ML_IS_MAGIC_CODE_UNIQUE':
      return handle(state, action, {
        success: (prevState) => {
          const { magicCode } = prevState.audience.MAGIC_CODE;
          const { payload: codes } = action;

          let warningOn = false;

          if (codes.length) {
            const formattedCodes = codes.map((a: any) => a.code.toLowerCase());

            warningOn = magicCode !== '' && formattedCodes.includes(magicCode.toLowerCase());
          }

          return mergeDeep(prevState, { audience: { isMagicCodeWarningOn: warningOn } });
        },
      });

    case 'ACTIVITY_ML_RESET':
      return defaultState;

    case 'ACTIVITY_ML_SELECT_LANGUAGE':
      return mergeDeep(state, { selectedLanguageId: action.languageId });

    case 'ACTIVITY_ML_ADD_LANGUAGE': {
      const defaultActivity = state.activities.filter((a) => a.isDefault)[0];
      const secondaryActivity = mergeDeep(defaultActivity, {
        id: 0,
        isDefault: false,
        languageId: action.languageId,
        name: '',
        description: '',
        coverId: 0,
        coverUrl: '',
        dailySerie: {
          id: 0,
          activityId: 0,
        },
      });

      return mergeDeep(state, { activities: state.activities.concat(secondaryActivity) });
    }

    case 'ACTIVITY_ML_SET_START_DATE': {
      const activities = state.activities.map((activity) => mergeDeep(activity, { startDate: action.startDate }));

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_SET_WEEK_DAYS': {
      const activities = state.activities.map((activity) =>
        mergeDeep(activity, { dailySerie: { weekDays: action.weekDays } }),
      );

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_SET_NUM_EXTRA_DAYS': {
      const activities = state.activities.map((activity) =>
        mergeDeep(activity, { dailySerie: { numExtraDays: action.numExtraDays } }),
      );

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_ADD_DISABLED_DAY': {
      const activities = state.activities.map((a) => {
        const current = a.dailySerie.dailySerieDayDisabled;
        const key = makeNextKey(current);
        const disabledDay = { key, date: action.date };
        const dailySerieDayDisabled = current.concat(disabledDay);

        return mergeDeep(a, { dailySerie: { dailySerieDayDisabled } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_SET_DISABLED_DAY': {
      const activities = state.activities.map((a) => {
        const dailySerieDayDisabled = a.dailySerie.dailySerieDayDisabled.map((x) =>
          x.key === action.key ? { ...x, date: action.date } : x,
        );

        return mergeDeep(a, { dailySerie: { dailySerieDayDisabled } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_REMOVE_DISABLED_DAY': {
      const activities = state.activities.map((a) => {
        const dailySerieDayDisabled = a.dailySerie.dailySerieDayDisabled.filter((x) => x.key !== action.key);

        return mergeDeep(a, { dailySerie: { dailySerieDayDisabled } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_ADD_EMPTY_DAILY_SERIE_DAY': {
      const activities = state.activities.map((a) => {
        const current = a.dailySerie.days;

        const key = makeNextKey(current);
        const dailySerieDay = { key, dailySerieContents: [] };
        const days = current.concat(dailySerieDay);

        return mergeDeep(a, { dailySerie: { days } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_REMOVE_DAILY_SERIE_DAY': {
      const activities = state.activities.map((a) => {
        const days = a.dailySerie.days.filter((x) => x.key !== action.key);

        // ensure always at least one day
        const nonEmptyDays = days.length > 0 ? days : [{ key: 0, dailySerieContents: [] }];

        return mergeDeep(a, { dailySerie: { days: nonEmptyDays } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_ADD_CONTENTS_TO_DAILY_SERIE_DAY': {
      const activities = state.activities.map((a) => {
        const days = a.dailySerie.days.map((day) => {
          if (day.key !== action.dayKey) {
            return day;
          }

          const current = day.dailySerieContents;
          const key = makeNextKey(current);

          const dsContents = action.contents.map((content, i) => ({ key: key + i, content, contentId: content.id }));
          const dailySerieContents = current.concat(dsContents);

          return { key: day.key, dailySerieContents };
        });

        return mergeDeep(a, { dailySerie: { days } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_REMOVE_CONTENT_FROM_DAILY_SERIE_DAY': {
      const activities = state.activities.map((a) => {
        const days = a.dailySerie.days.map((day) => {
          if (day.key !== action.dayKey) {
            return day;
          }

          const dailySerieContents = day.dailySerieContents.filter((content) => content.key !== action.contentKey);

          return { ...day, dailySerieContents };
        });

        return mergeDeep(a, { dailySerie: { days } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_ADD_CONTENT_TO_DAILY_SERIE_DAY_TO_INDEX': {
      const { dayIndex, contentIndex, content } = action;

      const activities = state.activities.map((a) => {
        const days = a.dailySerie.days.map((day, currentIndex) => {
          if (dayIndex !== currentIndex) {
            return day;
          }

          const current = day.dailySerieContents;
          const key = makeNextKey(current);
          const dsContent = { content, contentId: content.id, key };
          const dailySerieContents = copyAndInsertAtIndex(current, contentIndex, dsContent);

          return { ...day, dailySerieContents };
        });

        return mergeDeep(a, { dailySerie: { days } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_MOVE_CONTENT_IN_DAILY_SERIE_DAYS': {
      const { oldContentIndex, oldDayIndex, newContentIndex, newDayIndex } = action;

      const activities = state.activities.map((a) => {
        let originalContent = createDailySerieContent();

        const daysAfterRemove = a.dailySerie.days.map((day, dayIndex) => {
          if (oldDayIndex !== dayIndex) {
            return day;
          }

          const dailySerieContents = day.dailySerieContents.filter((content, contentIndex) => {
            if (contentIndex === oldContentIndex) {
              originalContent = content;
            }

            return contentIndex !== oldContentIndex;
          });

          return { ...day, dailySerieContents };
        });

        // Prevent a bug with the dragging which would give an index without content
        if (!originalContent.contentId) {
          return a;
        }

        const daysAfterAdding = daysAfterRemove.map((day, dayIndex) => {
          if (newDayIndex !== dayIndex) {
            return day;
          }

          const current = day.dailySerieContents;
          const key = makeNextKey(current);
          const dsContent = { ...originalContent, key };
          const dailySerieContents = copyAndInsertAtIndex(current, newContentIndex, dsContent);

          return { ...day, dailySerieContents };
        });

        return mergeDeep(a, { dailySerie: { days: daysAfterAdding } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_SET_LOCK':
      return mergeDeep(state, { isLocked: action.lock });

    case 'ACTIVITY_ML_SET_NAME':
      return mergeDeep(state, {
        activities: state.activities.map((a) =>
          a.languageId === state.selectedLanguageId ? mergeDeep(a, { name: action.name }) : a,
        ),
      });

    case 'ACTIVITY_ML_SET_DESCRIPTION':
      return mergeDeep(state, {
        activities: state.activities.map((a) =>
          a.languageId === state.selectedLanguageId ? mergeDeep(a, { description: action.description }) : a,
        ),
      });

    case 'ACTIVITY_ML_SET_COVER':
      return mergeDeep(state, {
        activities: state.activities.map((a) =>
          a.languageId === state.selectedLanguageId ? mergeDeep(a, { coverId: action.id, coverUrl: action.url }) : a,
        ),
      });

    case 'ACTIVITY_ML_SET_SEGMENTATION_FOR_AUDIENCE': {
      return mergeDeep(state, {
        audience: { SEGMENTATION: createSegmentationConfig(action.segmentation) },
      });
    }

    case 'ACTIVITY_ML_REMOVE_PLAYER_FROM_AUDIENCE':
      return handle(state, action, {
        success: (prevState) => {
          const needsRefresh = action.payload && action.payload.length;
          const targetModelLabel = Enum.audienceTargetLabels[prevState.audience.mode as AudienceTargetLabelIdType];

          if (!needsRefresh) {
            return prevState;
          }

          return mergeDeep(prevState, { audienceTarget: { [targetModelLabel]: { needsRefresh: true } } });
        },
        failure: (prevState) => {
          const targetModelLabel = Enum.audienceTargetLabels[prevState.audience.mode as AudienceTargetLabelIdType];

          return mergeDeep(prevState, { audienceTarget: { [targetModelLabel]: { deleteError: true } } });
        },
      });
    case 'ACTIVITY_ML_SET_SPECIFIC_RULE_ACTIVE':
      return mergeDeep(state, {
        activities: state.activities.map((a) =>
          a.languageId === state.selectedLanguageId
            ? mergeDeep(a, { dailySerie: { specificRuleActive: action.specificRuleActive } })
            : a,
        ),
      });

    case 'ACTIVITY_ML_SET_SPECIFIC_RULE_TEXT':
      return mergeDeep(state, {
        activities: state.activities.map((a) =>
          a.languageId === state.selectedLanguageId
            ? mergeDeep(a, { dailySerie: { specificRuleText: action.specificRuleText } })
            : a,
        ),
      });

    case 'ACTIVITY_ML_SET_ALERT': {
      const newAlert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === newAlert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? newAlert : err))
        : state.alerts.concat([newAlert]);

      return mergeDeep(state, { alerts });
    }

    case 'ACTIVITY_ML_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'ACTIVITY_ML_REMOVE_LANGUAGE': {
      const { languageId } = action;
      const { activities } = state;
      const newActivities = activities.filter((activity) => activity.languageId !== languageId);

      return mergeDeep(state, { activities: newActivities });
    }

    case 'CONVERT_ACTIVITY_ML_TO_RANDOM': {
      const activities = state.activities.map((activity) => {
        const newDailySerie = calculateContentRepartition(activity.dailySerie, activity.dailySerie.days.length);

        return mergeDeep(activity, { dailySerie: { ...newDailySerie, random: true } });
      });

      return mergeDeep(state, { activities });
    }

    case 'CONVERT_RANDOM_TO_ACTIVITY_ML': {
      const activities = state.activities.map((activity) => {
        const contents = activity.dailySerie.days.flatMap(({ dailySerieContents }) => dailySerieContents);

        const newFirstDayWithAllContents = { ...activity.dailySerie.days[0], dailySerieContents: contents };
        const otherDaysWithoutContent = activity.dailySerie.days
          .slice(1)
          .map((day) => ({ ...day, dailySerieContents: [] }));
        const newDays = [newFirstDayWithAllContents, ...otherDaysWithoutContent];

        return mergeDeep(activity, { dailySerie: { days: newDays, random: false } });
      });

      return mergeDeep(state, { activities });
    }

    case 'UPDATE_SESSIONS_RANDOM_ACTIVITY_ML': {
      const { numberOfSessions } = action.payload;

      const activities = state.activities.map((activity) => {
        const newDailySerie = calculateContentRepartition(activity.dailySerie, numberOfSessions);

        return mergeDeep(activity, { dailySerie: { ...newDailySerie, random: true } });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_ADD_CONTENT_TO_RANDOM_DAILY_SERIE_TO_INDEX': {
      const { numberOfSessions, contents } = action;

      const activities = state.activities.map((activity) => {
        const newContents = contents.map((content) => ({ content, contentId: content.id, key: 0 }));

        // insert the new contents in the first day
        const dailySerieContents = newContents.reduce((acc, content) => {
          return copyAndInsertAtIndex(acc, 0, content);
        }, activity.dailySerie.days[0].dailySerieContents);

        // rebuild the first day with the new contents
        const firstDay = { ...activity.dailySerie.days[0], dailySerieContents };

        // get the other days
        const otherDays = activity.dailySerie.days.slice(1);

        // rebuild the daily serie before processing content repartition
        const dailySerie = { ...activity.dailySerie, days: [firstDay, ...otherDays] };

        const newDailySerie = calculateContentRepartition(dailySerie, numberOfSessions);

        return mergeDeep(activity, { dailySerie: newDailySerie });
      });

      return mergeDeep(state, { activities });
    }

    case 'ACTIVITY_ML_REMOVE_CONTENT_FROM_RANDOM_DAILY_SERIE': {
      const { numberOfSessions, content } = action;

      const activities = state.activities.map((activity) => {
        const flattenedContents = activity.dailySerie.days
          .flatMap(({ dailySerieContents }) => dailySerieContents)
          .filter(({ content: activityContent }) => activityContent.multilingualId !== content.multilingualId);

        const newDay = { ...activity.dailySerie.days[0], dailySerieContents: flattenedContents };
        const newDailySerieWithFlattenedContents = { ...activity.dailySerie, days: [newDay] };

        const newDailySerie = calculateContentRepartition(newDailySerieWithFlattenedContents, numberOfSessions);

        return mergeDeep(activity, { dailySerie: newDailySerie });
      });

      return mergeDeep(state, { activities });
    }

    default:
      return state;
  }
};
