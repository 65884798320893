/* eslint-disable complexity */
// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedSelector } from 'Libs/redux/utils';

import MModal from 'Components/modal/MModal/MModal';

import { DocumentPreview } from './components/DocumentModalPreview/DocumentModalPreview';
import { DocumentModalForm } from './components/DocumentModalForm/DocumentModalForm';

import styles from './DocumentModal.style';

type ModalProps = {|
  visible: boolean,
  onClose: () => mixed,
  onSave: () => mixed,
|};

export const DocumentModal = ({ visible, onClose, onSave }: ModalProps) => {
  const id = useTypedSelector((state) => state.pages.documentList.documentModal?.id);
  const title = useTypedSelector((state) => state.pages.documentList.documentModal?.title);
  const categoryId = useTypedSelector((state) => state.pages.documentList.documentModal?.categoryId);
  const modalError = useTypedSelector((state) => state.pages.documentList.documentModalError);
  const fileError = useTypedSelector((state) => state.pages.documentList.documentFileError);
  const isUploading = useTypedSelector((state) => state.pages.documentList.loadingUpload);
  const document = useTypedSelector((state) => state.pages.documentList.documentModal);
  const documents = useTypedSelector((state) => state.pages.documentList.documents);

  const onDismiss = useCallback(() => onClose(), [onClose]);
  const onValidate = useCallback(() => {
    if (isUploading) return;

    onSave();
  }, [onSave, isUploading]);

  const oldDocument = documents.find((doc) => doc.id === id);
  const isEdited = JSON.stringify(document) !== JSON.stringify(oldDocument);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="standard"
      title={id ? title || '' : t('documents:document_modal.upload_new_document_title')}
      labelActionButton={
        id
          ? t('documents:document_modal.label_action_button_save')
          : t('documents:document_modal.label_action_button_upload')
      }
      labelSecondButton={t('documents:document_modal.label_second_button')}
      disableActionButton={!title || !categoryId || !isEdited || fileError}
      actionButtonType="accentuated"
      isActionLoading={isUploading}
      labelError={modalError}
      trackingAction="create"
      trackingContext="upload-pdf"
    >
      <div style={styles.content}>
        <DocumentPreview />
        <DocumentModalForm />
      </div>
    </MModal>
  );
};
