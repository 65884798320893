/**
 * Text with an external link
 *
 * Props:
 *  - text: text wording
 *  - type: Button link type either standard or accentuated
 *  - loading: overrides the link with null + displays CircleSpinner
 *  - disabled: is state disabled
 *  - link: external link target
 *  - mode: style mode (cf: @MODE)
 *  - target: open link in specific target (cf: @TARGET) default=_blank
 *  - leftIcon: icon on the left side
 *  - rightIcon: icon on the right side
 *  - iconSize: size of the icon based on string "small" and "medium"
 *  - marginLeft: margin left value type number or string
 *  - marginRight: margin right value type number or string
 */

import React, { useMemo } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CircleSpinner } from 'react-spinners-kit';

import type { CSSProperties, MouseEventHandler } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import { IconName } from 'Components/foundation/icons';
import { getTrackingDatasetFromProps } from 'Services/trackingService';
import type { TrackingProps } from 'Services/trackingService';
import styles from './UTextLink.style';

export const MODE = {
  REGULAR: 'regular',
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
};

export const TARGET = {
  BLANK: '_blank',
  SELF: '_self',
} as const;

export const ICON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const STYLE_MODE = {
  [MODE.REGULAR]: {
    font: TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.DEFAULT,
    iconSize: 10,
    margin: 8,
  },
  [MODE.SMALL]: {
    font: TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    iconSize: 10,
    margin: 6,
  },
  [MODE.MEDIUM]: {
    font: TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.DEFAULT,
    iconSize: 14,
    margin: 8,
  },
  [MODE.BIG]: {
    font: TYPOGRAPHY.BODY2_SEMIBOLD,
    color: COLORS.TEXT.DEFAULT,
    iconSize: 16,
    margin: 8,
  },
};

export type TextLinkType = 'standard' | 'secondary' | 'accentuated' | 'warning' | 'standard-light';
type IconSizeType = typeof ICON_SIZE[keyof typeof ICON_SIZE];
type Target = typeof TARGET[keyof typeof TARGET];
type Mode = typeof MODE[keyof typeof MODE];

export interface UTextLinkProps extends TrackingProps {
  text: string;
  typography?: keyof typeof TYPOGRAPHY;
  type?: TextLinkType;
  disabled?: boolean;
  link?: string;
  mode?: Mode;
  target?: Target;
  leftIcon?: IconName;
  rightIcon?: IconName;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onActionEnd?: Function;
  loading?: boolean;
  loadingLabel?: string;
  iconSize?: IconSizeType;
  marginLeft?: number | string;
  marginRight?: number | string;
  colorOverride?: string;
}

export const UTextLink = ({
  text,
  colorOverride,
  type = 'standard',
  typography = 'BODY3',
  disabled = false,
  link = undefined,
  leftIcon = undefined,
  rightIcon = undefined,
  mode = MODE.REGULAR,
  target = TARGET.BLANK,
  loading = false,
  onClick = () => {},
  onActionEnd = undefined,
  loadingLabel = 'Sending..',
  iconSize = ICON_SIZE.SMALL,
  marginLeft = '14px',
  marginRight = '14px',
  style = undefined,
  trackingAction,
  trackingContext,
}: UTextLinkProps) => {
  const typoStyle = useMemo(
    (): CSSProperties => ({
      ...TYPOGRAPHY[typography],
    }),
    [typography],
  );

  const colorStyle = useMemo(() => {
    if (disabled) {
      return { color: COLORS.TEXT.DISABLED_DEFAULT };
    }

    if (type === 'accentuated') {
      return { color: colorOverride || COLORS.TEXT.ACCENTUATED };
    }

    if (type === 'warning') {
      return { color: colorOverride || COLORS.WARNING.DARK };
    }

    if (type === 'secondary') {
      return { color: colorOverride || COLORS.TEXT.SECONDARY_HOVER };
    }

    if (type === 'standard-light') {
      return { color: colorOverride || COLORS.WHITE.DEFAULT };
    }

    return { color: colorOverride || COLORS.TEXT.DEFAULT };
  }, [disabled, type, colorOverride]);

  const extendedStyles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          textDecoration: 'none',
          ...STYLE_MODE[mode].font,
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          ':hover': {
            textDecoration: 'underline',
            color: colorStyle.color,
          },
        },
        wrapperDisabled: {
          ...STYLE_MODE[mode].font,
          display: 'inline-flex',
          alignItems: 'center',
          ':hover': {
            textDecoration: 'underline',
            color: colorStyle.color,
          },
        },
        wrapperLoading: {
          ...STYLE_MODE[mode].font,
          color: colorStyle.color,
          display: 'inline-flex',
          alignItems: 'center',
          ':hover': {
            textDecoration: 'none',
          },
        },
      }),
    [mode, colorStyle],
  );

  const hrefStyle = useMemo(() => {
    if (disabled) {
      return {
        ...extendedStyles.wrapperDisabled,
        ...STYLE_MODE[mode].font,
        ...styles.isTextLinkDisabled,
        ...style,
      };
    }

    if (type === 'accentuated') {
      return {
        ...STYLE_MODE[mode].font,
        ...styles.isAccentuated,
        ...style,
      };
    }

    return {
      ...STYLE_MODE[mode].font,
      ...style,
    };
  }, [extendedStyles, disabled, type, mode, style]);

  const trackingProps = useMemo(
    () =>
      trackingAction !== undefined && trackingContext !== undefined
        ? getTrackingDatasetFromProps({
            trackingAction,
            trackingContext,
          })
        : {},
    [trackingAction, trackingContext],
  );

  return loading ? (
    <>
      <div
        className={css(extendedStyles.wrapperLoading)}
        style={{
          ...colorStyle,
          ...styles.loadingDisabled,
          marginLeft,
          marginRight,
          ...style,
        }}
      >
        {loadingLabel}
      </div>
      <div style={{ display: 'inline-block' }}>
        <CircleSpinner size={14} color={colorStyle.color} loading={loading} />
      </div>
    </>
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={!onActionEnd ? link : '#'}
      target={!onActionEnd ? target : undefined}
      style={{
        marginLeft,
        marginRight,
        ...hrefStyle,
      }}
      className={css(extendedStyles.wrapper)}
      onClick={onClick}
      {...trackingProps}
    >
      {leftIcon && (
        <UIcon
          style={{ marginRight: STYLE_MODE[mode].margin }}
          color={colorStyle.color}
          size={STYLE_MODE[iconSize || mode].iconSize}
          name={leftIcon}
        />
      )}
      <div style={{ ...typoStyle, ...colorStyle }}>{text}</div>
      {rightIcon && (
        <UIcon
          style={{ marginLeft: STYLE_MODE[mode].margin }}
          color={colorStyle.color}
          size={STYLE_MODE[iconSize || mode].iconSize}
          name={rightIcon}
        />
      )}
    </a>
  );
};

export default UTextLink;
