// For z index that should only impact rendering inside a component
const LOCAL_OFFSET = 1;

/** @deprecated */
const LOCAL_OFFSET_2 = 2;
/** @deprecated */
const LOCAL_OFFSET_3 = 3;

// Dropdowns need to render over other components
const DROPDOWN = 100;

export default {
  DROPDOWN,
  LOCAL_OFFSET,
  LOCAL_OFFSET_2,
  LOCAL_OFFSET_3,
};
