import { DeepPartial } from 'ts-essentials';
import { ContentEntity, ContentForApiPayload, ContentFromApi } from './content.types';

export const fromApiContentMapper = (content: ContentFromApi): ContentEntity => {
  return {
    id: content.id,
    identifier: content.metadata.identifier || null,
    name: content.metadata.name,
    explanation: content.metadata.explanation || null,
    coverImage: content.metadata.coverImage,
    knowMore: content.metadata.knowMore || null,
    thematic: content.metadata.thematic,
    statuses: content.statuses,
    game: content.game || null,
  };
};

export const fromEntityContentMapper = (content: DeepPartial<ContentEntity>): ContentForApiPayload => ({
  metadata: {
    identifier: content.identifier,
    name: content.name,
    coverImage: content.coverImage?.id ? { id: content.coverImage.id } : null,
    thematic: content.thematic?.id ? { id: content.thematic?.id } : null,
    explanation: content.explanation,
    knowMore: content.knowMore,
  },
  statuses: content.statuses,
  game: content.game || undefined,
});
