import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  contentWrapper: {
    paddingRight: '36px',
    paddingLeft: '36px',
  },
  selectedThematicSection: {
    marginBottom: '24px',
  },
  modalStyle: {
    height: 'inherit',
  },
});

export default styleSheet;
