import request from 'services/requestService';
import Model from 'models/Model';

var AuthenticationSettings = {
  endpoint  : 'api/AuthenticationSettings/full',
  attributes: {
    informations: {
      type: 'object',
    },
    emailValidation: {
      type: 'boolean',
    },
    authenticationId: {
      type: 'number',
    },
    restrictionId: {
      type: 'number',
    },
    ssoIdentification: {
      type: 'boolean',
    },
    facebookIdentification: {
      type: 'boolean',
    },
    linkedinIdentification: {
      type: 'boolean',
    },
    mobileIdentification: {
      type: 'boolean',
    },
    corporateIdIdentification: {
      type: 'boolean',
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    accessControls: {
      type : 'collection',
      Model: 'AccessControl',
    },
  },
  methods: {
    uploadWhiteList: function uploadWhiteList(_AuthenticationSettings, file, progress, options) {
      var path = 'api/AuthorizedEmails/upload';

      return request.upload(path, file, progress, options)
        .then(function returnResponse(response) {
          console.log(response);
          if (response.result) {
            if (response.result.length)
              return response.result[0];

            return response.result;
          }

          return response;
        });
    },

  },
};

export default Model.register('AuthenticationSettings', AuthenticationSettings);
