import i18n from 'i18next';

import { mergeDeep } from 'Libs/mergeDeep';

import passwordManager from 'Libs/passwordManager';

import Role from 'ModelsReact/Role/Role';
import SegmentationGroupItem from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

import type { RoleType } from 'ModelsReact/Role/Role';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

export type ContributorType = Readonly<{
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  deleted: boolean;
  emailVerified: boolean;
  l10n: string;
  roles: ReadonlyArray<RoleType>;
  segmentation: ReadonlyArray<SegmentationGroupItemType>;
  status: string;
  active: boolean;
  realm: string;
  isEditionAllowed: boolean;
  phoneNumber?: string;
  phoneCountryCode?: string;
  language?: string;
}>;

export type ContributorToSend = Readonly<{
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  deleted: boolean;
  emailVerified: boolean;
  l10n: string;
  roles: ReadonlyArray<RoleType>;
  segmentation: ReadonlyArray<SegmentationGroupItemType>;
  status: string;
  active?: boolean;
  realm?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
  language?: string;
}>;

export default function createContributor(values?: any): ContributorType {
  const defaultState: ContributorType = {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    deleted: false,
    emailVerified: false,
    l10n: '',
    roles: [],
    segmentation: [],
    status: '',
    active: false,
    realm: 'BackOffice',
    isEditionAllowed: false,
    phoneNumber: '',
    phoneCountryCode: '',
    language: '',
  };

  if (!values) {
    return defaultState;
  }

  const roles = values.roles.map((role: RoleType) => Role(role));
  const segmentation = values.segmentation.map((seg: SegmentationGroupItemType) => SegmentationGroupItem(seg));
  const phoneNumber = values.phoneNumber || '';
  const phoneCountryCode = values.phoneCountryCode || '';
  const language = values.language || i18n.language;

  return mergeDeep<ContributorType>(defaultState, {
    ...values,
    roles,
    segmentation,
    phoneNumber,
    phoneCountryCode,
    language,
  });
}

export function prepareContributor(contributor: ContributorType): ContributorToSend {
  // eslint-disable-next-line no-unused-vars
  const { id, email, isEditionAllowed, phoneNumber, phoneCountryCode, ...rest } = contributor;

  const emailTrimmed = email.trim();
  const realm = 'BackOffice';
  const contributorPhoneNumber = phoneNumber || '';
  const contributorPhoneCountryCode = phoneCountryCode || '';

  // We need to split the id from the creation payload to avoid to create a contributor with an id 0
  if (id === 0) {
    const password = passwordManager.generatePassword();

    return {
      ...rest,
      email: emailTrimmed,
      password,
      realm,
      phoneNumber: contributorPhoneNumber,
      phoneCountryCode: contributorPhoneCountryCode,
    };
  }

  return {
    ...rest,
    id,
    email,
    realm,
    phoneNumber: contributorPhoneNumber,
    phoneCountryCode: contributorPhoneCountryCode,
  };
}
