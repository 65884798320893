import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  title: {
    marginTop: 19,
    ...TYPOGRAPHY.H1,
  },
  description: {
    marginTop: 16,
    display: 'flex',
    alignSelf: 'center',
    ...TYPOGRAPHY.BODY3,
    color: COLORS.BLACK.DEFAULT,
  },
  button: {
    marginTop: 32,
    marginBottom: 64,
  },
});

export default styleSheet;
