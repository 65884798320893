import { apiAction } from 'Libs/redux/utils';
import type { ApiActionType } from 'Libs/redux/utils';
import { enqueueBasicAlert, removeBasicAlert } from 'Pages/AlertManager';

type AmbassadorGetAllAction = ApiActionType<'AMBASSADOR_GET_ALL'>;
type AmbassadorRemoveAction = ApiActionType<'AMBASSADOR_REMOVE'>;
type AmbassadorGetGlobalCodeAction = ApiActionType<'AMBASSADOR_GET_GLOBAL_CODE'>;
type AmbassadorSetGlobalCodeAction = ApiActionType<'AMBASSADOR_SET_GLOBAL_CODE'>;
type AmbassadorGetAmbassadorReport = ApiActionType<'AMBASSADOR_GET_AMBASSADOR_REPORT'>;

type AmbassadorEditGlobalCodeAction = {
  type: 'AMBASSADOR_EDIT_GLOBAL_CODE';
  globalCode: string;
};

const ALERTS = {
  removeError: 'AMBASSADOR_REMOVE_ERROR',
  getCodeError: 'AMBASSADOR_GET_CODE_ERROR',
  setCodeError: 'AMBASSADOR_SET_CODE_ERROR',
  getReportError: 'AMBASSADOR_GET_REPORT_ERROR',
};

const setAmbassadorCodeErrors: Record<string, string> = {
  'IAM-AC-SAC-002': 'This code is already in use',
};

const getAmbassadorCodeErrors: Record<string, string> = {
  'IAM-AC-GAC-001': 'No ambassador code found',
};

const defaultError = (code: string) =>
  code
    ? `An unexpected error has occured. Please try again later or contact the support with the following error code : ${code}`
    : 'An unexpected error has occured. Please try again later or contact the support.';

export const actions = {
  editGlobalCode: (globalCode: string): AmbassadorEditGlobalCodeAction => ({
    type: 'AMBASSADOR_EDIT_GLOBAL_CODE',
    globalCode,
  }),

  getAll: (filter: { from: number; to: number; texts: Array<string>; order: Array<string> }): AmbassadorGetAllAction =>
    apiAction({
      type: 'AMBASSADOR_GET_ALL',
      route: '/api/ambassadors/all',
      method: 'GET',
      query: { filter },
      meta: { filter },
    }),

  remove: (id: number): AmbassadorRemoveAction => {
    const action = apiAction({
      type: 'AMBASSADOR_REMOVE',
      route: `/api/ambassadors/full/${id}`,
      method: 'DELETE',
      meta: { id },
    });

    removeBasicAlert(ALERTS.removeError);

    action.promise.catch((err) => {
      enqueueBasicAlert({
        id: ALERTS.removeError,
        text: `Error while removing ambassador.\n${err.message}`,
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    });

    return action;
  },

  getGlobalCode: (): AmbassadorGetGlobalCodeAction => {
    const action = apiAction({
      type: 'AMBASSADOR_GET_GLOBAL_CODE',
      route: '/api/ambassadors/global-code',
      method: 'GET',
    });

    removeBasicAlert(ALERTS.getCodeError);

    action.promise.catch((err) => {
      enqueueBasicAlert({
        id: ALERTS.getCodeError,
        text: getAmbassadorCodeErrors[err.errorCode] ?? defaultError(err.errorCode),
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    });

    return action;
  },

  setGlobalCode: (globalCode: string): AmbassadorSetGlobalCodeAction => {
    const action = apiAction({
      type: 'AMBASSADOR_SET_GLOBAL_CODE',
      route: '/api/ambassadors/global-code',
      method: 'PUT',
      payload: { code: globalCode },
    });

    removeBasicAlert(ALERTS.setCodeError);

    action.promise.catch((err) => {
      enqueueBasicAlert({
        id: ALERTS.setCodeError,
        text: setAmbassadorCodeErrors[err.errorCode] ?? setAmbassadorCodeErrors.default,
        title: 'Magic Code could not be saved',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    });

    return action;
  },

  getAmbassadorReport: (): AmbassadorGetAmbassadorReport => {
    const action = apiAction({
      type: 'AMBASSADOR_GET_AMBASSADOR_REPORT',
      route: '/api/ambassadors/xlsx-report',
      method: 'GET',
    });

    removeBasicAlert(ALERTS.getReportError);

    action.promise.catch(() => {
      enqueueBasicAlert({
        id: ALERTS.getReportError,
        text: 'Data could not be downloaded',
        status: 'error',
        priority: 'low',
        icon: 'alert',
      });
    });

    return action;
  },
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type AmbassadorAction = ReturnType<ActionsFn>;
