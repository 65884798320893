import m from 'm';
import Promise from 'bluebird';
import button from 'components/button';
import Video from 'models/Video';
import Image from 'models/Image';
import Bus from 'services/pubSubService';
import Enum from 'models/Enum';

var component = {};

component.controller = function controller(args) {
  var self = this;

  var config = {
    [Enum.videoType.Youtube]: {
      title           : 'Youtube',
      showTutorial    : true,
      shortInstruction: 'Right click on the video on youtube and copy the URL',
    },
    [Enum.videoType.Kumullus]: {
      title           : 'Kumullus',
      showTutorial    : false,
      shortInstruction: '',
    },
  };

  self.url = m.prop(null);
  self.collapse = m.prop(true);
  self.videoType = m.prop(args.videoType);
  self.config = m.prop(config[args.videoType]);
  self.uploading = m.prop(false);

  self.save = function save() {
    var video;

    self.uploading(true);

    if (!Video.isValidURL(self.url(), self.videoType())) {
      Bus.trigger('popup:error', 'The url is not a valid ' + self.config().title + ' url');

      self.uploading(false);

      return false;
    }

    video = new Video({
      url   : self.url().trim(),
      typeId: self.videoType(),
    });

    if (!args.uploadVideoCover) {
      return video.save()
        .then(args.action)
        .then(args.close);
    }

    return video.getOriginalThumbnail()
      .then(function saveImageFromVideoAndVideo(thumbnail) {
        var isKumullus = video.typeId() === Enum.videoType.Kumullus;
        var prefix = isKumullus ? 'kumullus' : 'youtube';
        var ext = isKumullus ? '.png' : '.jpg';

        var image = {
          url     : thumbnail,
          filename: `${prefix}-${video.token()}${ext}`,
          height  : 0,
          width   : 0,
          type    : Enum.imageTypeId.ORIGINAL,
        };

        return Promise.props({
          image: Image.createFromMediaLib(image),
          video: video.save(),
        });
      })
      .then(args.action)
      .tap(() => self.uploading(false))
      .then(args.close);
  };
};

component.view = function view(c, args) {

  function displayVideo(url) {
    return m('iframe', {
      allowfullscreen: true,
      src            : url,
      frameborder    : '0',
    });
  }

  return m('.popup.popup--video-upload', [
    m('.popup__body', [
      m('.popup__title', 'Add a ' + c.config().title + ' video'),
      m('.popup__subtitle', 'It\'s simple!'),
      m('.video__instruction__container', [
        m.hide(c.collapse(), '.video__instruction__title', [
          'Create a google account.',
        ]),
        m.hide(c.collapse(), '.video__instruction__detail', [
          m('.video__instruction__item', [
            '1. Go to ',
            m('a', {
              href  : 'https://www.youtube.com/upload',
              target: '_',
            }, 'Youtube upload page'),
          ]),
          m('.video__instruction__item', [
            '2. Login to your google account or create one.',
          ]),
        ]),
        m.hide(c.collapse(), '.video__instruction__title', [
          'Upload you video.',
        ]),
        m.hide(c.collapse(), '.video__instruction__detail', [
          m('.video__instruction__item', [
            'Go to ',
            m('a', {
              href  : 'https://www.youtube.com/upload',
              target: '_',
            }, 'Youtube upload page'),
          ]),
        ]),
        m.hide(c.collapse(), '.video__instruction__tuto', [
          displayVideo('https://www.youtube.com/embed/aHocQeo9ibY'),
          //displayVideo("https://www.youtube.com/embed/rXnI0N5djxA"),
        ]),
        m.hide(c.collapse(), '.video__instruction__title', [
          'Manage privacy',
        ]),
        m.hide(c.collapse(), '.video__instruction__detail', [
          m('.video__instruction__item', [
            'Be sure to set the privacy to \'unlisted\' or \'public\'',
          ]),
        ]),
        m.hide(c.collapse(), '.video__instruction__tuto', [
          displayVideo('https://www.youtube.com/embed/zaSM_xcmkOI'),
        ]),
        m.hide(c.collapse(), '.video__instruction__title', [
          'Adjust / trim the video',
        ]),
        m.hide(c.collapse(), '.video__instruction__detail', [
          m('.video__instruction__item', [
            'Make sure the video is not too long. Less than 30 seconds is ideal',
          ]),
          m('.video__instruction__item', [
            'Youtube provide really user friendly tools to trim uploaded videos',
          ]),
        ]),
        m.hide(c.collapse(), '.video__instruction__tuto', [
          displayVideo('https://www.youtube.com/embed/rXnI0N5djxA'),
        ]),
        m('.video__instruction__title', [
          'Enter a ' + c.config().title + ' video URL',
          c.config().showTutorial
            ? m('span.instructions', {
              onclick: c.collapse.bind(null, !c.collapse()),
            }, (c.collapse() ? '+ Show' : '+ Hide') + ' upload instructions')
            : '',
        ]),
        m('.video__instruction__detail', [
          m('.video__instruction__item', [
            c.config().shortInstruction,
          ]),
        ]),
        m('.video__instruction__detail', [
          m('input.video__url__input', {
            placeholder: 'Paste url here',
            onchange   : m.withAttr('value', c.url),
          }),
        ]),

      ]),
    ]),
    m('.popup__footer.clearFix', [
      m(button, {
        value  : args.button || 'Add video',
        class  : 'button--ok',
        action : c.save,
        loading: c.uploading(),
      }),
    ]),
  ]);
};

export default component;
