/* eslint-disable no-magic-numbers */
// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */

/**
 * Display images in masonry theme
 *
 * Props:
 *  - items: Array of object image
 *  - onClick: Call when click on an image
 *  - size: Given by the withSize HOC
 *  - style: Override component's style
 *  - placeholderSentence: sentence of the placeholder
 *  - extraData: Used to indicate to rebuild the masonry. Same logic as FlatList
 *  - onScrollEnd: Call when reach the bottom of the masonry
 *  - source: Source of the images
 *  - loading: Display a loading state
 */

import * as React from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry,
} from 'react-virtualized';
import ImageMeasurer from 'react-virtualized-image-measurer';
import { withSize } from 'react-sizeme';

import UGalleryItem from 'Components/unit/UGalleryItem/UGalleryItem';
import type { ImageGalleryType } from 'Libs/flowTypes';

import styles from './SImageGallery.style';



type Props = {|
  style: ?Object,
  items: Array<ImageGalleryType>,
  onClick: Function,
  size: Object,
  placeholderSentence: string,
  extraData: any,
  loading: boolean,
  source: string,
|};

type State = {|
  heightComponent: ?number,
  indexSelected: ?number,
|};

const COL_COUNT = 3;
const MARGIN_COMPONENT = 34;
const PADDING_CONTAINER = 6;
const SPACE_LOST = MARGIN_COMPONENT + PADDING_CONTAINER;
const DEFAULT_HEIGHT = 120;
const SPACER = 16;

const DEFAULT_HEIGHT_CONTAINER = 900;


export class SImageGallery extends React.Component<Props, State> {
  colHeight: Array<number> = [0, 0, 0];
  widthColumn: number;
  cache: Object;
  cellPositioner: Object;
  masonRef: ?Object = undefined;

  static defaultProps = {
    style: undefined,
    source: '',
  };

  constructor(props: Props) {
    super(props);

    const { size: { width }} = props;

    this.widthColumn = (width - SPACE_LOST - (SPACER * (COL_COUNT - 1)))
      / COL_COUNT;

    // Default sizes help Masonry decide how many images to batch-measure
    this.cache = new CellMeasurerCache({
      defaultHeight: DEFAULT_HEIGHT,
      defaultWidth: this.widthColumn,
      fixedWidth: true,
    });

    // Our masonry layout will use 3 columns with a 16px gutter between
    this.cellPositioner = createMasonryCellPositioner({
      cellMeasurerCache: this.cache,
      columnCount: COL_COUNT,
      columnWidth: this.widthColumn,
      spacer: SPACER,
    });
  }

  state = {
    heightComponent: undefined,
    indexSelected: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    const { extraData } = this.props;

    if (prevProps.extraData === extraData)
      return;

    this.colHeight = [0, 0, 0];

    this.cache.clearAll();
    this.cellPositioner.reset({
      cellMeasurerCache: this.cache,
      columnCount: COL_COUNT,
      columnWidth: this.widthColumn,
      spacer: SPACER,
    });

    if (this.masonRef && this.masonRef.clearCellPositions)
      this.masonRef.clearCellPositions();

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ indexSelected: undefined });
  }

  render() {
    const { style, items, loading } = this.props;

    if (loading)
      return this.renderLoadingState();

    if (!items || !items.length)
      return this.renderPlaceHolder();

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <ImageMeasurer
          items={items}
          image={this.getImageFromItem}
          defaultHeight={DEFAULT_HEIGHT}
          defaultWidth={this.widthColumn}
        >
          {({ itemsWithSizes }: Object) => {
            const { size: { width }} = this.props;
            const { heightComponent } = this.state;

            const height = heightComponent || DEFAULT_HEIGHT_CONTAINER;

            if (!itemsWithSizes.length)
              return null;

            return (
              <Masonry
                ref={this.setMasonry}
                cellCount={itemsWithSizes.length}
                cellMeasurerCache={this.cache}
                cellPositioner={this.cellPositioner}
                cellRenderer={this.renderItem.bind(null, itemsWithSizes)}
                height={height}
                width={width - SPACE_LOST}
              />
            );
          }}
        </ImageMeasurer>
      </div>
    );
  }

  renderItem = (
    itemsWithSizes: Object,
    { index, key, parent, style }: Object,
  ) => {
    const { indexSelected } = this.state;
    const { source } = this.props;
    const { item, size } = itemsWithSizes[index];
    const height = this.widthColumn
      * (size.height / size.width) || DEFAULT_HEIGHT;
    const isSelected = index === indexSelected;

    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        index={index}
        parent={parent}
      >
        <UGalleryItem
          style={style}
          image={item}
          height={height}
          width={this.widthColumn}
          onMount={this.handleHeight.bind(null, height)}
          onClick={this.handleClick.bind(null, index)}
          isSelected={isSelected}
          source={source}
        />
      </CellMeasurer>
    );
  };

  renderPlaceHolder = () => {
    const { placeholderSentence, style } = this.props;

    return (
      <div style={{ ...styles.placeholder, ...style }}>
        {placeholderSentence}
      </div>
    );
  };

  renderLoadingState = () => {
    return (
      <div style={styles.loaderContainer}>
        <div style={styles.loaderColumn}>
          {this.renderGalleryItemLoading(274)}
          {this.renderGalleryItemLoading(274)}
        </div>
        <div style={styles.loaderColumn}>
          {this.renderGalleryItemLoading(133)}
          {this.renderGalleryItemLoading(197)}
          {this.renderGalleryItemLoading(197)}
        </div>
        <div style={styles.loaderColumn}>
          {this.renderGalleryItemLoading(197)}
          {this.renderGalleryItemLoading(109)}
          {this.renderGalleryItemLoading(221)}
        </div>
      </div>
    );
  };

  renderGalleryItemLoading = (height: number) => {
    return (
      <UGalleryItem
        image={{}}
        height={height}
        width={this.widthColumn}
        style={styles.galleryItemLoading}
        isSelected={false}
      />
    );
  };

  handleClick = (
    index: number,
    image: ImageGalleryType,
    isSelected: boolean,
  ) => {
    const { onClick, items } = this.props;
    const indexSelected = isSelected ? index : undefined;

    this.setState({ indexSelected });
    onClick(items[index], isSelected);
  };

  handleHeight = (height: number) => {
    this.colHeight[this.getIndexColumnToPutImage()] += (height + SPACER);

    const highest = Math.max.apply(Math, this.colHeight);

    this.setState({ heightComponent: highest });
  };

  getImageFromItem = (item: ImageGalleryType) => {
    return item.displayUrl;
  };

  getIndexColumnToPutImage = () => {
    return this.colHeight.indexOf(Math.min.apply(Math, this.colHeight));
  };

  setMasonry = (node: any) => {
    this.masonRef = node;
  };
}

export default withSize()(SImageGallery);
