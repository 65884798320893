import User from 'Services/userService';

import type { AsyncActionType } from 'Libs/redux/utils';
import { asyncAction } from 'Libs/redux/utils';

type UpdatePasswordApiAction = AsyncActionType<'ADMIN_UPDATE_PASSWORD'>;

export const actions = {
  updatePassword: (token: string, password: string): UpdatePasswordApiAction =>
    asyncAction({
      type: 'ADMIN_UPDATE_PASSWORD',
      promise: User.password(token, password),
    }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type UpdatePasswordAction = ReturnType<ActionsFn>;
