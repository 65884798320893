import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import Enum from 'Models/Enum';

import SNavigationBloc from 'Components/structural/SNavigationBloc/SNavigationBloc';

import { actions } from '../../../redux';
import styles from './AudienceNavigationBlock.style';

const OPTIONS = Enum.audienceTargetMode;

export const AudienceNavigationBlock = () => {
  const modeOptions = {
    [OPTIONS.MAGIC_CODE]: {
      title: t('activities:audience.magic_code_title'),
      description: t('activities:audience.magic_code_description'),
    },
    [OPTIONS.EMAIL_LIST]: {
      title: t('activities:audience.email_list_title'),
      description: t('activities:audience.email_list_description'),
    },
    [OPTIONS.SEGMENTATION]: {
      title: t('activities:audience.segmentation_title'),
      description: t('activities:audience.segmentation_description'),
    },
  };

  const audienceTargetModes = [
    Enum.audienceTargetMode.MAGIC_CODE,
    Enum.audienceTargetMode.EMAIL_LIST,
    Enum.audienceTargetMode.SEGMENTATION,
  ];

  const dispatch = useTypedDispatch();
  const onOptionClick = useCallback((option) => () => dispatch(actions.setTargetModeId(option)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.titlesWrapper}>
        <div style={styles.title}>{t('activities:audience.navigation_title')}</div>
        <div style={styles.subtitle}>{t('activities:audience.navigation_subtitle')}</div>
      </div>
      <div style={styles.audienceOptionList}>
        {audienceTargetModes.map((option, index) => {
          const containerStyle = index === audienceTargetModes.length - 1 ? styles.lastBlock : styles.block;

          return (
            <div key={option} style={containerStyle}>
              <SNavigationBloc {...modeOptions[option]} onClick={onOptionClick(option)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
