// @flow

import { useEffect, useRef, useMemo } from 'react';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { actions, useModuleSelector, getDefaultActivity } from '../redux';

export const ContentUpdater = () => {
  const dispatch = useTypedDispatch();
  const activities = useModuleSelector((state) => state.activities);
  const defaultActivity = useTypedSelector(getDefaultActivity);
  const isAskKnowledgeTranslationLoading = useModuleSelector((state) => state.isAskKnowledgeTranslationLoading);
  const isActivityLoaded = useModuleSelector((state) => !state.isFetchLoading);

  // INFO store value inside a ref to make sure it does not trigger useEffect all the time
  const contentIdsRef = useRef<Array<number>>([]);

  contentIdsRef.current = useMemo(() => {
    const contentIdsLocal = [];

    defaultActivity.dailySerie.days.forEach((day) => {
      day.dailySerieContents.forEach((dsContent) => {
        contentIdsLocal.push(dsContent.contentId);
      });
    });

    return contentIdsLocal;
  }, [defaultActivity]);

  // INFO store value inside a ref to make sure it does not trigger useEffect all the time
  const languageIdsRef = useRef<Array<number>>([]);

  languageIdsRef.current = useMemo(() => {
    return activities.map((activity) => activity.languageId);
  }, [activities]);

  const days = defaultActivity.dailySerie.days;
  const contentIdsHash = contentIdsRef.current.join(',');
  const languageIdsHash = languageIdsRef.current.join(',');

  useEffect(() => {
    if (!isActivityLoaded || isAskKnowledgeTranslationLoading || !contentIdsRef.current.length)
      return () => {};

    const action = actions.getMultilingualContents(contentIdsRef.current, languageIdsRef.current);

    dispatch(action);

    return action.cancel;
  }, [
    dispatch,
    isAskKnowledgeTranslationLoading,
    isActivityLoaded,
    contentIdsRef,
    languageIdsRef,
    contentIdsHash,
    languageIdsHash,
  ]);

  return null;
};
