import m from 'm';
import { t } from 'i18next';

import T from 'types';

import App from 'models/App';

import { isFeatureEnabled } from 'services/feature-flags';

const component = {};

const AUDIENCE_CONFIGURATION_PAGE = 'audience-configuration-page';

component.controller = function controller() {
  const self = this;

  const labelTranslationChildren = {
    '/gaia/translation/segmentation': { name: t('menu:segmentation_labels'), event: 'label-translation-segmentation' },
    '/gaia/translation/signup': { name: t('menu:signup_labels'), event: 'label-translation-signup' },
    '/gaia/translation/thematic': { name: t('menu:thematic_labels'), event: 'label-translation-thematic' },
    '/gaia/translation/category': { name: t('menu:category_labels'), event: 'label-translation-category' },
    '/gaia/translation/email-section': { name: t('menu:email_section'), event: 'label-translation-email-section' },
  };

  self.options = App.router.buildRouteOptionForUser({
    '/gaia/players': { name: t('menu:app_players'), event: 'players' },
    '/gaia/ambassadors': { name: t('menu:ambassadors'), event: 'ambassador' },
    '/gaia/contributors': { name: t('menu:bo_contributors'), event: 'bo-contributors' },
    '/gaia/translation/segmentation': {
      name: t('menu:labels_translation'),
      event: 'label-translation',
      children: labelTranslationChildren,
    },
    ...(isFeatureEnabled(AUDIENCE_CONFIGURATION_PAGE) ? { '/gaia/audience/groups': {
      name: t('menu:audience'),
      event: 'audience',
      children: {
        '/gaia/audience/groups': { name: t('menu:audience_groups'), event: 'audience_groups' },
        '/gaia/audience/segmentation/values': { name: t('menu:segmentation'), event: 'segmentation-values' },
        '/gaia/audience/segmentation/translation': { name: t('menu:translation'), event: 'segmentation-translation' },
      },
    }} : {
      '/gaia/segmentation/values': {
        name: t('menu:segmentation'),
        event: 'segmentation',
        children: {
          '/gaia/segmentation/values': { name: t('menu:values'), event: 'segmentation-values' },
          '/gaia/segmentation/translation': { name: t('menu:translation'), event: 'segmentation-translation' },
        },
      },
    }),
    '/gaia/thematics': { name: t('menu:thematics'), event: 'thematics' },
    '/gaia/categories': { name: t('menu:categories'), event: 'categories' },
    '/gaia/configuration/application': {
      name: t('menu:configuration'),
      event: 'configuration',
      children: {
        '/gaia/configuration/application': { name: t('menu:application'), event: 'configuration-application' },
        '/gaia/configuration/backoffice': { name: t('menu:back_office'), event: 'configuration-backoffice' },
      },
    },
    '/gaia/signup/segmented': { name: t('menu:sign_up'), event: 'signup' },
    '/gaia/gifts': { name: t('menu:gifts'), event: 'gifts' },
  });

  self.goToPath = function goToPath(path, args, e) {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.which === 2)
      return;

    e.preventDefault();

    if (args && args.confirm)
      return args.confirm(() => m.route(path));

    m.route(path);
  };

  return self;
};

// eslint-disable-next-line complexity
component.view = function view(c, args) {
  const mainRoute = App.router.getMainRoute(c.options, 2);

  let children = {};

  if (T.is.obj(c.options[mainRoute]) && c.options[mainRoute].children)
    children = c.options[mainRoute].children;

  return m('.gaia-menu', [
    m('.gaia-main-menu', [
      Object.entries(c.options).map(([route, option]) => {
        const selected = mainRoute === route;
        const { name, event } = option;

        return m('a.gaia-main-menu__item', {
          config: m.route,
          href: route,
          class: selected
            ? 'gaia-main-menu__item--selected'
            : '',
          onclick: c.goToPath.bind(null, route, args),
          'data-tracking-action': 'navigate',
          'data-tracking-context': `menu-configuration-${event}`,
        }, name);
      }),
    ]),
    m('.gaia-secondary-menu', {
      class: Object.keys(children).length ? '' : 'gaia-secondary-menu--hidden',
    }, [
      Object.entries(children).map(([route, option]) => {
        const selected = m.route() === route;
        const { name, event } = option;

        return m('a.gaia-secondary-menu__item', {
          config: m.route,
          href: route,
          class: selected
            ? 'gaia-secondary-menu__item--selected'
            : '',
          onclick: c.goToPath.bind(null, route, args),
          'data-tracking-action': 'navigate',
          'data-tracking-context': `menu-configuration-${event}`,
        }, name);
      }),
    ]),
  ]);
};

export default component;
