import m from 'm';

import menu from 'pages/menu';
import reactCreator from 'components/react.creator';

import { isFeatureEnabled } from 'services/feature-flags';
import { getLanguagesAvailableWithCode } from 'services/language/languageService';

const component = {};

const HOMEPAGE_FLAG_NAME = 'homepage';

component.view = function view() {
  const languages = getLanguagesAvailableWithCode();

  return m('.page', [
    m('.header', [
      m(menu),
    ]),
    isFeatureEnabled(HOMEPAGE_FLAG_NAME)
      ? m(reactCreator, {
        component: 'Homepage',
        props: {
          languages,
        },
        style: {
          height: '100%',
          paddingTop: '50px',
        },
      })

      /* TODO: Delete this condition when removing the flag homepage */
      /* Can't use FeatureFlags in routerConfig because routes are loaded before, so this hack is needed */
      : m.route('/knowledge'),
  ]);
};

export default component;
