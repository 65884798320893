import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: COLORS.GREY_LIGHT.DEFAULT,
    borderWidth: '3px',
    minHeight: 30,
    maxHeight: 340,
    minWidth: 30,
    maxWidth: 340,
  },
});

export default styleSheet;
