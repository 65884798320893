import m from 'm';
import { t } from 'i18next';

import App from 'models/App';
import Enum from 'models/Enum';
import Image from 'models/Image';
import Video from 'models/Video';
import popup from 'services/popupService';
import responsivePicture from 'components/responsivePicture';
import EnumMedia from 'models/MediaHandlerEnum';
import reactCreator from 'components/react.creator';
import { VIDEO_PROVIDERS } from 'components/react/modal/MVideoUpload/utils';

const component = {};

component.controller = function controller(args) {
  const self = this;

  const kumullusVideo = App.configurations().get(Enum.configurations.BACK_OFFICE_KUMULLUS_VIDEO_UPLOAD);

  self.type = m.prop();
  self.kumullusEnabled = m.prop(kumullusVideo.value());

  if (args.type && args.type())
    self.type(args.type());
  else if (args.video && args.video() && args.video().id())
    self.type(Enum.mediaType.Video);
  else if (args.image && args.image() && args.image().id())
    self.type(Enum.mediaType.Image);

  self.setImage = function setImage(_args, image) {
    image = new Image(image);
    self.type(Enum.mediaType.Image);
    if (_args.type)
      _args.type(self.type());
    if (_args.image)
      _args.image(image);
    if (!args.type && _args.video)
      _args.video(new Video());

    self.closeImagePopup();
  };

  self.setVideo = function setVideo(_args, medias) {
    let image = medias.image;
    const video = medias.video;

    self.type(Enum.mediaType.Video);
    if (_args.type)
      _args.type(self.type());
    if (_args.video)
      _args.video(video);
    if (!args.type && _args.image) {
      image = image || new Image();
      _args.image(image);
    }

    popup.close();
    m.redraw();
  };

  self.isVideoUploadModalVisible = m.prop(false);

  self.openVideoUploadModal = function openVideoUploadModal() {
    self.isVideoUploadModalVisible(true);
    m.redraw();
  };

  self.closeVideoUploadModal = function closeVideoUploadModal() {
    self.isVideoUploadModalVisible(false);
    m.redraw();
  };

  self.isImagePopupVisible = m.prop(false);

  self.openImagePopup = function openImagePopup() {
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.isImagePopupVisible(false);
    m.redraw();
  };
};

component.view = function view(c, args) {
  const imagePopup =  m(reactCreator, {
    component: 'MImageCrop',
    props    : {
      onCancel        : c.closeImagePopup,
      onSave          : c.setImage.bind(null, args),
      onImageSelection: () => {},
      cropProps       : args.cropProps,
    },
  });

  const videoUploadModal = m(reactCreator, {
    component: 'MVideoUpload',
    props: {
      providers: [
        VIDEO_PROVIDERS[Enum.videoType.Youtube],
        VIDEO_PROVIDERS[Enum.videoType.ApiVideo],
      ],
      visible: true,
      onSave: c.setVideo.bind(null, args),
      onClose: () => c.closeVideoUploadModal(),
      uploadThumbnail: true,
    },
  });

  return m('.media-selector', {
    class: args.class,
  }, [
    c.isImagePopupVisible() ? imagePopup : null,
    c.isVideoUploadModalVisible() ? videoUploadModal : null,
    display(),
    input(),
  ]);

  function display() {
    if (c.type() === Enum.mediaType.Image) {
      return m('.media-selector__display .media-selector__display--image', [
        m(responsivePicture, {
          picture: args.image,
        }),
      ]);
    }

    if (c.type() === Enum.mediaType.Video) {
      return m('.media-selector__display .media-selector__display--video', [
        m('iframe', {
          src            : args.video().getIframeSrcUrl(),
          frameborder    : '0',
          allowfullscreen: false,
        }),
      ]);
    }

    return undefined;
  }

  function input() {
    let inputVideo = '';

    let inputKumullus = '';

    let inputImage = '';

    if (args.video && args.video()) {
      inputVideo = m('.media-selector__input__video', {
        onclick: c.openVideoUploadModal,
      }, [
        m('.media-selector__input__video__icon'),
        m('.media-selector__input__video__text', (!c.type() ? t('mithril_components:activity_media_selector.a_video') : t('mithril_components:activity_media_selector.for_video'))),
      ]);
    }

    if (args.video && args.video() && c.kumullusEnabled()) {
      inputKumullus = m('.media-selector__input__video', {
        onclick: popup.open.bind(null, {
          type            : 'uploadVideo',
          videoType       : Enum.videoType.Kumullus,
          uploadVideoCover: true,
          action          : c.setVideo.bind(null, args),
        }),
      }, [
        m('.media-selector__input__kumullus__icon'),
        m('.media-selector__input__video__text', (!c.type() ? t('mithril_components:activity_media_selector.a_kumullus_video') : t('mithril_components:activity_media_selector.for_kumullus_video'))),
      ]);
    }

    if (args.image && args.image()) {
      inputImage = m('.media-selector__input__image', {
        onclick: c.openImagePopup,
      }, [
        m('.media-selector__input__image__icon'),
        m('.media-selector__input__image__text', (!c.type() ? t('mithril_components:activity_media_selector.an_image') : t('mithril_components:activity_media_selector.for_image'))),
      ]);
    }

    return m('.media-selector__input', {
      class: (args.image && args.image() && !args.image().id())
        ? 'media-selector__input--show'
        : '',
    }, [
      m('.media-selector__input__inner', [
        m('.media-selector__input__add-or-change', !c.type() ? t('mithril_components:activity_media_selector.add') : t('mithril_components:activity_media_selector.change')),
        m('.media-selector__input__media_container', [
          m('.media-selector__input__media_basic', [
            inputVideo,
            inputImage,
          ]),
          m('.media-selector__input__media_kumullus', inputKumullus),
        ]),
      ]),
    ]);
  }
};

export default component;
