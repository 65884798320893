import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';

import { GAMEPLAY_ID_TO_CONTENT_KEY, GAMEPLAY_KEYS } from 'Components/utils/Enum';
import Enum from 'Models/Enum';
import { store } from 'Libs/redux/store';
import type { KnowledgeInfoType } from './models/Knowledge';
import type { ContentType, GameplayObjectType } from './models/Content';
import type { AlertScope, AlertParams } from './models/Alert';

import { prepareOrderingCards } from './models/OrderingCards';
import { OrderingCardAction } from '../OrderingCards/redux/actions';

type ContentFetchByIdAction = ApiActionType<'CONTENT_FETCH_BY_ID'>;
type ContentFetchKnowledgeInfo = ApiActionType<'CONTENT_FETCH_KNOWLEDGE_INFO'>;
type ContentSaveAction = ApiActionType<'CONTENT_SAVE'>;
type ContentSetStatusAction = ApiActionType<'CONTENT_SET_STATUS'>;
type ContentArchive = ApiActionType<'CONTENT_ARCHIVE'>;

type ContentResetAction = {
  type: 'CONTENT_RESET';
};
type ContentSetupNewContent = {
  type: 'CONTENT_SETUP_NEW_CONTENT';
  gameplayId: number;
};
type ContentSetPreviewVisibility = {
  type: 'CONTENT_SET_PREVIEW_VISIBILITY';
  visibility: boolean;
};
type ContentSetEditRole = {
  type: 'CONTENT_SET_EDIT_ROLE';
  value: boolean;
};
type ContentSetEditValidRole = {
  type: 'CONTENT_SET_EDIT_VALID_ROLE';
  value: boolean;
};
type ContentSetAskValidationRole = {
  type: 'CONTENT_SET_ASK_VALIDATION_ROLE';
  value: boolean;
};
type ContentSetAlertAction = {
  type: 'CONTENT_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};
type ContentRemoveAlertAction = {
  type: 'CONTENT_REMOVE_ALERT';
  scope: AlertScope;
};

const PREPARE_BY_TYPE: { [id: number]: ((content: GameplayObjectType, knowledgeId: number) => any) | null } = {
  [Enum.gameplay.QCM]: null,
  [Enum.gameplay.SQCM]: null,
  [Enum.gameplay.ERROR_TEXT]: null,
  [Enum.gameplay.OPEN_QUESTION]: null,
  [Enum.gameplay.NO_INTERACTION_CONTENT]: null,
  [Enum.gameplay.PICTURE_SPOT]: null,
  [Enum.gameplay.SWIPING_CARDS]: null,
  [Enum.gameplay.ORDERING_CARDS]: prepareOrderingCards,
};

function prepareContentForServer(content: ContentType, knowledge: KnowledgeInfoType) {
  const contentCloned: any = JSON.parse(JSON.stringify(content));
  const gameplayKey = GAMEPLAY_ID_TO_CONTENT_KEY[contentCloned.gameplayId];

  // reset gameplay keys not used in this content, to not send false data to the server
  const newContent = Object.values(GAMEPLAY_KEYS).reduce(
    (contentAcc, key) => ({ ...contentAcc, [key]: key !== gameplayKey ? null : contentCloned[key] }),
    contentCloned,
  );

  const prepareMethod = PREPARE_BY_TYPE[newContent.gameplayId];

  if (prepareMethod) {
    newContent[gameplayKey] = prepareMethod(newContent[gameplayKey], knowledge.id);
  }

  if (newContent.id === 0) {
    delete newContent.id;
  }

  newContent.knowledgeId = knowledge.id;
  delete newContent.updatedAt;

  return newContent;
}

export const actions = {
  setupNewContent: (gameplayId: number): ContentSetupNewContent => ({
    type: 'CONTENT_SETUP_NEW_CONTENT',
    gameplayId,
  }),

  fetchById: (id: number): ContentFetchByIdAction =>
    apiAction({
      type: 'CONTENT_FETCH_BY_ID',
      route: `/api/contents/${id}/full`,
      method: 'GET',
    }),

  reset: (): ContentResetAction => ({
    type: 'CONTENT_RESET',
  }),

  fetchKnowledgeInfo: (knowledgeId: number): ContentFetchKnowledgeInfo =>
    apiAction({
      type: 'CONTENT_FETCH_KNOWLEDGE_INFO',
      route: `/api/knowledge/full/${knowledgeId}`,
      method: 'GET',
    }),

  save: (): ContentSaveAction => {
    const state = store.getState();
    const { content, knowledgeInfo } = state.pages.content;
    const newContent = prepareContentForServer(content, knowledgeInfo);

    return apiAction({
      type: 'CONTENT_SAVE',
      route: '/api/contents/full',
      method: 'POST',
      payload: newContent,
    });
  },

  archive: (archived: boolean): ContentArchive => {
    const state = store.getState();
    const { content, knowledgeInfo } = state.pages.content;
    const newContent = prepareContentForServer(content, knowledgeInfo);

    newContent.archived = archived;

    return apiAction({
      type: 'CONTENT_ARCHIVE',
      route: '/api/contents/full',
      method: 'POST',
      payload: newContent,
      meta: { archived },
    });
  },

  setStatus: (newStatus: number): ContentSetStatusAction => {
    const state = store.getState();
    const {
      content: { id },
    } = state.pages.content;
    const payload = { newContentStatusId: newStatus };

    return apiAction({
      type: 'CONTENT_SET_STATUS',
      route: `/api/contents/${id}/changeStatus`,
      method: 'PUT',
      payload,
      meta: { statusId: newStatus },
    });
  },

  setPreviewVisibility: (visibility: boolean): ContentSetPreviewVisibility => ({
    type: 'CONTENT_SET_PREVIEW_VISIBILITY',
    visibility,
  }),

  setCanEditRole: (value: boolean): ContentSetEditRole => ({
    type: 'CONTENT_SET_EDIT_ROLE',
    value,
  }),

  setCanEditValidRole: (value: boolean): ContentSetEditValidRole => ({
    type: 'CONTENT_SET_EDIT_VALID_ROLE',
    value,
  }),

  setCanAskValidationRole: (value: boolean): ContentSetAskValidationRole => ({
    type: 'CONTENT_SET_ASK_VALIDATION_ROLE',
    value,
  }),

  setAlert: (scope: AlertScope, params: AlertParams = Object.freeze({})): ContentSetAlertAction => ({
    type: 'CONTENT_SET_ALERT',
    scope,
    params,
  }),

  removeAlert: (scope: AlertScope): ContentRemoveAlertAction => ({
    type: 'CONTENT_REMOVE_ALERT',
    scope,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
type Action = ReturnType<ActionsFn>;

export type ContentAction = OrderingCardAction | Action;
