type WordWithPosition = { word: string; originalStartPosition: number };
/**
 * @param text should be a text with a first character different from whitespace
 * @returns an array with each and their original starting position
 */
export const splitTextIntoWords = (text: string): WordWithPosition[] => {
  const nonDestructiveSplit = text.split(/(?<=[ ])/g); // If certain characters need to be removed from words, keep them here
  const { words } = nonDestructiveSplit.reduce<{ cursor: number; words: WordWithPosition[] }>(
    (acc, word) => {
      return {
        cursor: acc.cursor + word.length,
        words: acc.words.concat({ word: word.trim(), originalStartPosition: acc.cursor }), // If certain characters need to be removed from words, remove them after trimming here
      };
    },
    { cursor: 0, words: [] },
  );
  return words;
};