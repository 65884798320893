// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';


export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '50px',
    width: '100%',
    backgroundColor: 'transparent',
    padding: '0px 8px',
    justifyContent: 'center',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  name: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  email: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '1px',
  },
};
