import React from 'react';
import { t } from 'i18next';

import { useActionDialogConfirmation } from 'Hooks/useActionDialogConfirmation/useActionDialogConfirmation';

import { TYPOGRAPHY } from 'Components/foundation';

export const useManualCampaignActivationConfirmation = () => {
  const { askForConfirmation, dialog } = useActionDialogConfirmation({
    primaryActionLabel: t('activities:modals.activate_manual_campaign.button'),
    secondaryActionLabel: t('activities:modals.activate_manual_campaign.cancel'),
    title: t('activities:modals.activate_manual_campaign.title'),
    description: t('activities:modals.activate_manual_campaign.description'),
    primaryActionButtonType: 'accentuated',
    style: { width: 520, ...TYPOGRAPHY.BODY2 },
    showExitCross: true,
    children: (
      <p style={{ ...TYPOGRAPHY.BODY3, marginTop: 8 }}>{t('activities:modals.activate_manual_campaign.disclaimer')}</p>
    ),
  });

  return {
    askForConfirmation,
    dialog,
  };
};
