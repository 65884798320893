import m from 'm';
import Enum from 'models/Enum';

var component = {};

component.controller = function controller() {
  var self = this;
  var TYPES = {};

  TYPES[Enum.activityTypeEnum.THATS_ALL_FOLKS] = 'thats-all';
  TYPES[Enum.activityTypeEnum.BREAKING_NEWS] = 'breaking-news';
  TYPES[Enum.activityTypeEnum.DAILY_SERIE] = 'daily-series';
  TYPES[Enum.activityTypeEnum.INTERSEASON] = 'interseason';
  TYPES[Enum.activityTypeEnum.WELCOME] = 'welcome';
  TYPES[Enum.activityTypeEnum.RANKING] = 'ranking';
  TYPES[Enum.activityTypeEnum.BATTLE] = 'battle';

  self.TYPES = TYPES;

  return self;
};

component.view = function view(c, args) {
  var size = args.size || 'small';
  var iconStyleType = 'thats-all';

  iconStyleType = c.TYPES[args.activityType];

  return m('.activity-icon-container.activity-icon-container--' + size, {
    class: args.class,
  }, [
    m('.activity-icon', {
      class: [
        args.iconStyle === 'image'
        ? 'activity-image--' + iconStyleType
        : 'activity-icon--' + iconStyleType,
        args.rounded
        ? 'activity-icon--rounded'
        : '',
      ].join(' '),
    }),
  ]);
};

export default component;
