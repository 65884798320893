import m from 'm';
import Promise from 'bluebird';
import popup from 'services/popupService';
import Model from 'models/Model';
import Enum from 'models/Enum';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import translationColumn from 'components/gaia.translation.column';

var component = {};

component.controller = function controller() {
  var self = this;
  var SegmentationDimension = Model('SegmentationDimension');
  var PolyglotLabel = Model('PolyglotLabel');
  var Thematic = Model('Thematic');

  self.saving = m.prop(false);
  self.modified = m.prop(false);
  self.saved = m.prop(false);
  self.languages = m.prop([]);
  self.polyglotLabels = m.prop([]);
  self.polyglotLabelRow = m.prop([]);
  self.thematics = m.prop([]);
  self.thematicsRow = m.prop([]);

  SegmentationDimension
    .getFull()
    .then(function setLanguages(_dimensions) {
      var languageDimension = _dimensions.filter(getLanguages)[0];

      var indexOfDefault;

      languageDimension.groups().forEach(function findDefault(l, key) {
        if (l.isDefault())
          indexOfDefault = key;
      });

      var defaultLanguage = languageDimension.groups().items.splice(indexOfDefault, 1);

      languageDimension.groups().items.unshift(defaultLanguage[0]);

      if (languageDimension)
        self.languages(languageDimension.groups());

      getThematics();
    });

  self.save = function save() {
    self.saving(true);

    self.saving(true);
    var promises = [
      self.thematics().save(),
    ];

    return Promise.all(promises)
      .tap(self.saving.bind(null, false))
      .tap(self.saved.bind(null, true))
      .tap(self.modified.bind(null, false))
      .then(getThematics);

  };

  self.warningSave = function warningSave(action) {
    if (!self.modified())
      return action();
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit without saving? All unsaved modifications will be lost.',
      confirm: 'Don\'t save',
      cancel : 'Cancel',
      action : action,
    });
  };

  function getThematics() {
    Thematic.findValid()
      .tap(self.thematics)
      .then(formatLabels.bind(null, self.thematicsRow));
  }

  function formatLabels(row, _labels) {
    var columns = self.languages().map(function toColumn(language) {
      var items = _labels.map(formatWithLabel);
      var label = language.label();
      var isDefault = language.isDefault();

      return {
        default: isDefault,
        title  : label,
        items  : items,
        key    : language.id(),
      };

      function formatWithLabel(o) {
        var goodLabel = o.getLabel(language.id());
        var placeholder = o.getLabel().label();

        return {
          value      : goodLabel.label,
          placeholder: placeholder,
        };
      }
    });

    row(columns);
    m.redraw();
  }

  function getLanguages(o) {
    return o.typeId() === Enum.dimensionTypes.LANGUAGE;
  }

  return self;
};

component.view = function view(c, args) {
  var saveButton = m(button, {
    value   : c.modified() ? 'Save' : 'Saved',
    class   : 'button--save',
    action  : c.save,
    disabled: !c.modified(),
    loading : c.saving(),
  });

  return m('.gaia__translation', [
    m('.header', [
      m(menu, {
        confirm: c.warningSave,
      }),
      m(gaiaMenu, {
        confirm: c.warningSave,
      }),
    ]),
    m('.gaia__translation__container', [

      m('.gaia__translation__header', [
        m('.translation__header__title', [
          'Thematic Labels Translation',
        ]),
        m('.translation__header__actions', [saveButton]),
      ]),

      m('.gaia__translation__row', {
        class: !c.thematics().length ? 'hidden' : '',
      }, [
        m('.gaia__translation__row__content', [
          c.thematicsRow().map(function displayRow(rowItem) {
            return m(translationColumn, {
              class   : 'gaia__translation__row__item',
              title   : rowItem.title,
              readonly: rowItem.default,
              items   : rowItem.items,
              modified: c.modified,
            });
          }),
        ]),
      ]),
      m('.gaia__translation__footer', [
        m('.translation__header__actions', [saveButton]),
      ]),
    ]),
  ]);

};

export default component;
