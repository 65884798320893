import { handle } from 'redux-pack';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { Action } from 'Libs/redux/types';

export type ResetPasswordState = DeepReadonly<{
  email: string;
  isEmailSent: boolean;
  loading: boolean;
  error: string;
}>;

export const defaultState: ResetPasswordState = {
  email: '',
  isEmailSent: false,
  loading: false,
  error: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ResetPasswordState = defaultState, action: Action): ResetPasswordState => {
  switch (action.type) {
    case 'ADMIN_RESET_PASSWORD_SET_EMAIL': {
      const { email } = action;

      return mergeDeep(state, {
        email,
        isEmailSent: false,
        error: '',
      });
    }

    case 'ADMIN_RESET_PASSWORD': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { error: '', loading: true }),
        success: (prevState) => mergeDeep(prevState, { isEmailSent: true, loading: false, error: '' }),
        failure: (prevState) => {
          const errorMessage =
            action.payload?.error?.name === 'InvalidEmailBadParametersError'
              ? 'Please type your email in the format email@domain.com'
              : 'Something went wrong, please try again later.';

          return mergeDeep(prevState, {
            loading: false,
            error: errorMessage,
          });
        },
      });
    }

    default:
      return state;
  }
};
