interface AsyncActionInterface {
  payload: any,
}

export type AsyncActionType<T extends string> = {
  type: T,
  promise: Promise<any>,

  // INFO These actions are going to be modified by redux-pack.
  //
  // The best would be to mutate the action type conditionnally
  // based on the presence of the 'promise' field,
  // and to distinguish dispatchable actions from mutable actions.
  //
  // However it is not easily doable with Flow and adds a lot of complexity.
  // As a workaround, we declare a 'payload' field to this dispatchable action type.
  payload: any,

  // INFO meta is used to pass extra param to an async action
  meta: any,
};

export type AsyncActionParams<T extends string> = {
  type: T,
  promise: Promise<any>,
  meta?: any,
};

export function asyncAction<T extends string>(params: AsyncActionParams<T>): AsyncActionType<T> {
  return {
    type: params.type,
    promise: params.promise,

    // This field will be populated by redux-pack
    payload: undefined,
    meta: params.meta || {},
  };
}

const getMessage = (message?: string | string[]): string => {
  const defaultMessage = 'Something went wrong, please try again later.';

  if (!message) {
    return defaultMessage;
  }

  return Array.isArray(message) ? message.shift() || defaultMessage : message;
};

export const getErrorMessage = (action: AsyncActionInterface): string => (
  getMessage(action.payload.error?.message || action.payload.message)
);
