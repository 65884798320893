// @flow

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    minHeight: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
};
