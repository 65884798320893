import { createStyleSheet } from 'Components/foundation/stylesheets';

import { TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  leftWrapper: {
    display: 'inline-block',
    marginLeft: 19,
  },
  rightWrapper: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 16,
    marginTop: 2,
  },
  dottedWrapper: {
    border: 'dashed 1.5px',
    borderColor: 'black',
  },
  inputStyle: {
    marginBottom: 18,
  },
  bottomBlock: {
    marginTop: 16,
  },
  title: {
    ...TYPOGRAPHY.BODY4,
    marginBottom: 8,
    fontWeight: 700,
  },
  asterisk: {
    color: '#FD4E5A',
  },
  descriptionLabel: {
    marginLeft: 20,
  },
  descriptionContent: {
    margin: 'auto',
    width: '91%',
  },
});

export default styleSheet;
