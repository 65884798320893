import React, { FunctionComponent } from 'react';
import { COLORS } from 'Components/foundation';

import type { IllustrationSVGType } from 'Components/foundation/illustrations';

export const CampaignRoundIllustration: FunctionComponent<IllustrationSVGType> = ({ color, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="26" fill={COLORS.WHITE.DEFAULT} />
    <path
      d="M25.4047 13.6887C25.5095 13.4923 25.668 13.3309 25.8613 13.2238C26.0545 13.1167 26.2741 13.0685 26.4937 13.0851L40.0243 15.6782C40.9061 15.7632 41.4087 16.8943 40.9678 17.7885L37.1983 24.392C37.0898 24.6425 36.8977 24.8462 36.6558 24.9672C36.4139 25.0883 36.1376 25.1189 35.8756 25.0537L22.8696 22.5947C22.6781 22.5202 22.5043 22.4053 22.3597 22.2577C22.2152 22.11 22.1032 21.933 22.0311 21.7382C21.959 21.5434 21.9285 21.3353 21.9415 21.1277C21.9546 20.92 22.011 20.7175 22.1069 20.5336L25.4047 13.6887Z"
      fill={color.DEFAULT}
    />
    <path
      d="M22.3593 12.039L10.6423 14.6101C9.70493 14.8158 9.10949 15.7531 9.31232 16.7036L13.4179 35.9441C13.6208 36.8946 14.5451 37.4985 15.4824 37.2928L27.1994 34.7216C28.1368 34.5159 28.7322 33.5786 28.5294 32.6281L24.4237 13.3876C24.2209 12.4371 23.2966 11.8333 22.3593 12.039Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7288 15.0159C10.0201 15.1714 9.5614 15.8834 9.71747 16.6148L13.8231 35.8553C13.9792 36.5867 14.6871 37.0428 15.3958 36.8872L27.1129 34.3161C27.8216 34.1606 28.2803 33.4485 28.1242 32.7171L24.0186 13.4766C23.8625 12.7452 23.1546 12.2892 22.4459 12.4447L10.7288 15.0159ZM8.90719 16.7926C8.65759 15.623 9.38977 14.4604 10.5557 14.2046L22.2727 11.6334C23.4387 11.3775 24.5793 12.1291 24.8289 13.2988L28.9345 32.5393C29.1841 33.709 28.4519 34.8715 27.286 35.1274L15.569 37.6985C14.403 37.9544 13.2624 37.2028 13.0128 36.0331L8.90719 16.7926Z"
      fill={color.DEFAULT}
    />
    <path
      d="M29.2169 16.0499L26.4685 27.4226C26.3201 28.0366 26.6906 28.6563 27.296 28.8067L34.6613 30.6371C35.2667 30.7876 35.8778 30.4119 36.0261 29.7979L38.7745 18.4251C38.9229 17.8112 38.5524 17.1915 37.947 17.041L30.5817 15.2106C29.9763 15.0602 29.3652 15.4359 29.2169 16.0499Z"
      fill={COLORS.GREY_LIGHT.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0708 27.1723C26.9821 27.5394 27.2045 27.8999 27.5528 27.9865L34.918 29.8169C35.2663 29.9034 35.6279 29.6881 35.7166 29.321L38.465 17.9482C38.5537 17.5811 38.3313 17.2206 37.983 17.1341L30.6178 15.3037C30.2695 15.2171 29.9079 15.4325 29.8192 15.7996L27.0708 27.1723ZM27.3321 28.8998C26.4695 28.6854 25.9509 27.8065 26.1589 26.9457L28.9073 15.5729C29.1153 14.7121 29.9759 14.176 30.8385 14.3904L38.2037 16.2208C39.0662 16.4351 39.5849 17.314 39.3768 18.1748L36.6284 29.5476C36.4204 30.4084 35.5598 30.9445 34.6973 30.7302L27.3321 28.8998Z"
      fill={color.DEFAULT}
    />
    <path
      d="M27.774 13.4466L25.3833 24.1174C25.2543 24.6934 25.6424 25.2739 26.2503 25.414L33.645 27.1177C34.2528 27.2577 34.8502 26.9043 34.9793 26.3282L37.3699 15.6575C37.499 15.0814 37.1108 14.5009 36.503 14.3609L29.1083 12.6572C28.5004 12.5171 27.9031 12.8706 27.774 13.4466Z"
      fill={COLORS.GREY_LIGHT.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8575 25.0439C25.7749 25.4126 26.0034 25.7691 26.3529 25.8496L33.7477 27.5533C34.0972 27.6339 34.4552 27.4125 34.5378 27.0438L37.0959 15.6254C37.1785 15.2567 36.9501 14.9002 36.6005 14.8197L29.2058 13.116C28.8562 13.0354 28.4982 13.2569 28.4156 13.6255L25.8575 25.0439ZM26.1475 26.7665C25.2814 26.567 24.7483 25.6971 24.9419 24.8329L27.5 13.4146C27.6936 12.5505 28.5451 11.9995 29.4112 12.1991L36.8059 13.9028C37.672 14.1023 38.2052 14.9723 38.0116 15.8364L35.4534 27.2547C35.2598 28.1189 34.4084 28.6698 33.5422 28.4702L26.1475 26.7665Z"
      fill={color.DEFAULT}
    />
    <path
      d="M25.8046 10.6622L23.3282 22.5381C23.1367 23.4561 23.7154 24.3577 24.6207 24.5518L32.3115 26.201C33.2168 26.3952 34.1058 25.8083 34.2972 24.8903L36.7736 13.0144C36.9651 12.0964 36.3864 11.1949 35.4812 11.0008L27.7903 9.35152C26.8851 9.1574 25.996 9.74423 25.8046 10.6622Z"
      fill={COLORS.GREY_LIGHT.DEFAULT}
    />
    <path
      opacity="0.5"
      d="M26.755 10.0547L25.8473 14.4078L35.4276 16.4622L36.3353 12.1091L26.755 10.0547Z"
      fill={color.MEDIUM}
    />
    <path
      d="M31.3874 12.8392L31.7445 14.2207L33.0672 14.717C33.1219 14.735 33.1704 14.7686 33.2068 14.8138C33.2433 14.8589 33.2661 14.9138 33.2726 14.9718C33.2791 15.0298 33.269 15.0884 33.2435 15.1408C33.2181 15.1931 33.1783 15.2369 33.1289 15.2669L31.9297 16.027L31.8812 17.4487C31.8783 17.5069 31.8594 17.5631 31.8267 17.611C31.794 17.6589 31.7487 17.6966 31.6961 17.7199C31.6434 17.7431 31.5853 17.751 31.5284 17.7425C31.4716 17.7341 31.4182 17.7098 31.3742 17.6722L30.2808 16.7781L28.9141 17.1626C28.859 17.1788 28.8006 17.1791 28.7453 17.1636C28.6901 17.148 28.6402 17.1171 28.6013 17.0744C28.5624 17.0317 28.536 16.9788 28.525 16.9217C28.5141 16.8647 28.519 16.8056 28.5393 16.7512L29.064 15.41L28.2704 14.2252C28.2372 14.1777 28.2181 14.1215 28.2155 14.0633C28.2129 14.0051 28.2268 13.9474 28.2556 13.897C28.2844 13.8466 28.3269 13.8057 28.3779 13.779C28.429 13.7524 28.4866 13.7412 28.5437 13.7468L29.9634 13.8407L30.8451 12.723C30.8816 12.6784 30.9293 12.6448 30.9832 12.6257C31.0371 12.6067 31.0951 12.6029 31.1509 12.6149C31.2068 12.6269 31.2583 12.6541 31.3 12.6936C31.3417 12.7332 31.3719 12.7835 31.3874 12.8392V12.8392Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2663 22.7393C24.1826 23.1407 24.4362 23.5245 24.8178 23.6063L32.5087 25.2556C32.8903 25.3374 33.2754 25.0905 33.3591 24.6891L35.8355 12.8132C35.9192 12.4118 35.6656 12.028 35.284 11.9462L27.5932 10.2969C27.2116 10.2151 26.8265 10.462 26.7428 10.8634L24.2663 22.7393ZM24.626 24.5262C23.726 24.3332 23.1603 23.4406 23.3476 22.5423L25.824 10.6664C26.0114 9.76808 26.8849 9.18412 27.785 9.37712L35.4758 11.0264C36.3759 11.2194 36.9416 12.112 36.7542 13.0102L34.2778 24.8861C34.0905 25.7844 33.2169 26.3684 32.3169 26.1754L24.626 24.5262Z"
      fill={color.DEFAULT}
    />
    <path
      d="M23.1616 20.2136L19.4283 37.6507C19.2201 38.6232 19.8286 39.5827 20.7876 39.7938L32.1704 42.3001C33.1294 42.5112 34.0755 41.894 34.2837 40.9216L38.0171 23.4845C38.2253 22.512 37.6167 21.5526 36.6578 21.3414L25.2749 18.8352C24.316 18.624 23.3699 19.2412 23.1616 20.2136Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8416 37.7419C19.6813 38.4907 20.1505 39.2203 20.8762 39.3801L32.259 41.8863C32.9848 42.0461 33.71 41.5796 33.8704 40.8307L37.6038 23.3936C37.7641 22.6448 37.2949 21.9152 36.5692 21.7554L25.1863 19.2492C24.4606 19.0894 23.7353 19.5559 23.575 20.3048L19.8416 37.7419ZM20.699 40.2078C19.5068 39.9453 18.7588 38.7559 19.0149 37.5599L22.7483 20.1227C23.0044 18.9267 24.1714 18.1589 25.3635 18.4214L36.7464 20.9277C37.9386 21.1902 38.6865 22.3796 38.4305 23.5756L34.6971 41.0128C34.441 42.2088 33.274 42.9766 32.0818 42.7141L20.699 40.2078Z"
      fill={color.DEFAULT}
    />
    <path
      d="M21.974 18.3166L18.2406 35.7538C18.0324 36.7262 18.641 37.6857 19.5999 37.8968L30.9828 40.4031C31.9417 40.6142 32.8879 39.9971 33.0961 39.0246L36.8295 21.5875C37.0377 20.6151 36.4291 19.6556 35.4702 19.4445L24.0873 16.9382C23.1284 16.7271 22.1822 17.3442 21.974 18.3166Z"
      fill={color.HOVER}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.654 35.8448C18.4937 36.5937 18.9629 37.3232 19.6886 37.483L31.0714 39.9892C31.7971 40.149 32.5224 39.6825 32.6828 38.9336L36.4162 21.4965C36.5765 20.7477 36.1073 20.0181 35.3816 19.8583L23.9987 17.3521C23.273 17.1923 22.5477 17.6588 22.3874 18.4077L18.654 35.8448ZM19.5113 38.3107C18.3192 38.0482 17.5712 36.8588 17.8273 35.6628L21.5607 18.2257C21.8168 17.0296 22.9838 16.2619 24.1759 16.5243L35.5588 19.0306C36.751 19.2931 37.4989 20.4825 37.2428 21.6785L33.5095 39.1157C33.2534 40.3117 32.0864 41.0795 30.8942 40.817L19.5113 38.3107Z"
      fill={color.DEFAULT}
    />
    <path
      d="M27.7149 24.5396L26.4319 26.1268L24.3906 25.9569C24.3093 25.9508 24.2278 25.9677 24.1554 26.0057C24.0829 26.0438 24.0223 26.1015 23.9803 26.1724C23.9382 26.2433 23.9164 26.3246 23.9173 26.4073C23.9181 26.4901 23.9415 26.5709 23.985 26.6409L25.1004 28.3622L24.3201 30.2534C24.2893 30.3307 24.2803 30.4151 24.2943 30.4972C24.3082 30.5793 24.3445 30.6558 24.3991 30.7181C24.4536 30.7804 24.5243 30.8259 24.603 30.8497C24.6817 30.8735 24.7655 30.8744 24.8447 30.8525L26.8199 30.3339L28.3762 31.6752C28.4384 31.7308 28.5149 31.7675 28.5967 31.781C28.6786 31.7946 28.7626 31.7844 28.839 31.7517C28.9155 31.7191 28.9813 31.6652 29.0288 31.5963C29.0764 31.5274 29.1039 31.4462 29.108 31.3622L29.2095 29.319L30.9509 28.2549C31.0192 28.2109 31.0742 28.1486 31.1099 28.075C31.1456 28.0014 31.1606 27.9192 31.1534 27.8375C31.1461 27.7557 31.1167 27.6776 31.0686 27.6117C31.0205 27.5457 30.9553 27.4945 30.8804 27.4636L28.9714 26.7169L28.4908 24.7229C28.4731 24.6402 28.4326 24.5643 28.3741 24.504C28.3155 24.4438 28.2413 24.4016 28.16 24.3824C28.0787 24.3632 27.9938 24.3678 27.915 24.3956C27.8362 24.4234 27.7668 24.4734 27.7149 24.5396Z"
      fill={COLORS.GREY_LIGHT.DEFAULT}
    />
    <path d="M18.5093 37.1385L19.682 39.0163L18.5093 37.1385Z" fill={color.HOVER} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.323 39.2405L18.1503 37.3627L18.8682 36.9143L20.041 38.7921L19.323 39.2405Z"
      fill={color.DEFAULT}
    />
    <path d="M36.55 20.1714L37.7713 22.1252L36.55 20.1714Z" fill={color.HOVER} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4124 22.3496L36.1912 20.3958L36.909 19.9471L38.1302 21.9009L37.4124 22.3496Z"
      fill={color.DEFAULT}
    />
    <path
      d="M20.0258 29.5693L18.7031 35.851C18.6336 36.2016 18.6978 36.5659 18.8827 36.8704C19.0676 37.1749 19.3595 37.397 19.6995 37.4918L30.8935 39.915C30.8935 39.915 25.3737 37.9344 22.6578 35.5782C19.942 33.2221 20.0258 29.5693 20.0258 29.5693Z"
      fill={color.MEDIUM}
    />
  </svg>
);
