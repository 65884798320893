// @flow

import React from 'react';
import MModal from 'Components/modal/MModal/MModal';
import { t } from 'i18next';

import styles from './ConfirmModal.style';

type Props = {|
  visible: boolean,
  onAction: () => mixed,
  onClose: () => void,
  isActionLoading: boolean,
|};

export const ConfirmModal = ({ visible, onAction, isActionLoading, onClose }: Props) => {
  return (
    <MModal
      visible={visible}
      onCloseModal={onClose}
      onAction={onAction}
      onSecondAction={onClose}
      type="dialog"
      title={t('content_translation:confirm_modal.title')}
      labelActionButton={t('content_translation:confirm_modal.confirm')}
      labelSecondButton={t('content_translation:confirm_modal.cancel')}
      actionButtonType="accentuated"
      isActionLoading={isActionLoading}
    >
      <div style={styles.content}>
      {t('content_translation:confirm_modal.message')}
      </div>
    </MModal>
  );
};
