import { t } from 'i18next';
import m from 'm';

var component = {};

// eslint-disable-next-line complexity
component.view = function view(c, args) {
  return m('.input-image', {
    onclick: args.editable ? args.onEdit : function noop() {},
    style  : {
      backgroundImage: 'url(' + args.image().cdn({transformation: {width: 300}}) + ')',
    },
    class: [!args.editable ? 'input-image--disabled' : ''].join(' '),
  },
  m.hide(!args.editable, '.input-image__container', [
    m(args.image().id() ? '.input-image--hover' : 'hide'),
    m('.input-image__div-text', [
      m('.input-image__divided', [
        m(args.image().id() ? '.input-image__icon--selected' : '.input-image__icon'),
      ]),
      m('.input-image__divided', [
        m(args.image().id() ? '.input-image__text--selected' : '.input-image__text',
          args.image().id() ? t('contents:content_image.change_image') :  t('contents:content_image.add_image')),
      ]),
    ]),
  ])
  );
};

export default component;
