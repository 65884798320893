import { debounce } from 'lodash';
import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import type { ChangeEvent } from 'react';

import { useAfterMountEffect } from 'Hooks/useAfterMountEffect/useAfterMountEffect';

type ConditionalDebounceValueProps<T> = {
  value: T,
  time?: number,
  debounced?: boolean,
  onChange?: (newValue: T) => void;
};

export function useDebouncedCallback<T extends (...args: any) => any>(callback: T, wait?: number) {
  const debouncedCallback = useMemo(() => debounce(callback, wait), [callback, wait]);

  return debouncedCallback;
}

export function useDebouncedValue<T>(inputValue: T, time = 500) {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  // Every time input value has changed - set interval before it's actually commited
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputValue, time]);

  return debouncedValue;
}

export function useConditionalDebouncedValue<T>({
  value,
  time = 500,
  debounced = false,
  onChange = undefined,
}: ConditionalDebounceValueProps<T>) {
  const [realTimeValue, setRealTimeValue] = useState(value);
  const debouncedValue = useDebouncedValue(realTimeValue, time);
  const cachedValue = useRef(debouncedValue);

  useEffect(() => {
    setRealTimeValue(value);
  }, [value]);

  useAfterMountEffect(() => {
    if (debounced && cachedValue.current !== debouncedValue) {
      cachedValue.current = debouncedValue;
      onChange?.(debouncedValue);
    }
  }, [debounced, debouncedValue]);

  const handleChange = useCallback(({ target: { value: newValue } }: ChangeEvent<any>) => {
    setRealTimeValue(newValue);

    if (!debounced) {
      onChange?.(newValue);
    }
  }, [onChange, debounced]);

  return {
    handleChange,
    realTimeValue,
  };
}
