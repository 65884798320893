import React, { CSSProperties } from 'react';
import UIcon from 'Components/unit/UIcon/UIcon';
import STooltip from 'Components/structural/STooltip/STooltip';
import { COLORS } from 'Components/foundation';
import type { UIconProps } from 'Components/unit/UIcon/UIcon';

import styles from './UTopActionBar.style';

const MAX_TITLE_LENGTH = 40;

const STATUS = Object.freeze({
  DISABLED: 'disabled',
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  WARNING: 'warning',
  IMPORTANT: 'important',
});

const STATUS_STYLE = {
  [STATUS.DISABLED]: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  [STATUS.INACTIVE]: {
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  [STATUS.ACTIVE]: {
    color: COLORS.SUCCESS.DEFAULT,
  },
  [STATUS.WARNING]: {
    color: COLORS.WARNING.DEFAULT,
  },
  [STATUS.IMPORTANT]: {
    color: COLORS.ALERT.ERROR,
  },
};

export type StatusType = keyof typeof STATUS_STYLE;
export type HelperType = {
  icon: UIconProps['name'];
  content: string;
};

type UTopActionBarProps = {
  id: number | string;
  title: string;
  status: StatusType;
  statusWording: string;
  placeholder?: string;
  isTranslated?: boolean;
  helper?: HelperType;
  style?: CSSProperties;
};

/**
 * Top Action Bar
 *
 * Props:
 * - id: the id of an entity
 * - title: the title of an entity
 * - status: status of an entity
 * - statusWording: status wording
 * - placeholder: string to display when title is empty
 * - isTranslated: true / false
 * - helper: option to display helper icon
 */
export class UTopActionBar extends React.PureComponent<UTopActionBarProps> {
  static defaultProps = {
    style: undefined,
    isTranslated: false,
    placeholder: 'Campaign name',
    helper: undefined,
  };

  render() {
    const { title, placeholder, isTranslated, style } = this.props;
    const titleLimit = title && title.length > MAX_TITLE_LENGTH ? title.slice(0, MAX_TITLE_LENGTH) + '...' : title;

    return (
      <div style={style}>
        <div style={styles.titleWrapper}>
          {this.renderId()}
          <div style={title ? styles.title : styles.placeholder}>{titleLimit || placeholder || 'Campaign name'}</div>
          <div style={styles.translated}>{isTranslated ? ' (translated)' : ''}</div>
        </div>
        <div>{this.renderStatus()}</div>
      </div>
    );
  }

  renderId = () => {
    const { id } = this.props;

    if (!id) return null;

    return <div style={styles.badge}>{'#' + id}</div>;
  };

  renderStatus() {
    const { status, statusWording } = this.props;
    const { color } = STATUS_STYLE[status];

    return (
      <div style={{ ...styles.status, color }}>
        <div style={{ ...styles.dot, backgroundColor: color }} />
        <div>{statusWording}</div>
        <div style={styles.helper}>{this.renderHelper()}</div>
      </div>
    );
  }

  renderHelper() {
    const { status, helper } = this.props;
    const { color } = STATUS_STYLE[status];
    const id = 'TOP_ACTION_BAR_HELPER';

    if (!helper) return '';

    return (
      <div data-tip data-for={id} style={styles.topActionBarHelper}>
        <UIcon name={helper.icon} size={13} color={color} />
        <STooltip id={id} position="right" content={helper.content} />
      </div>
    );
  }
}

export default UTopActionBar;
