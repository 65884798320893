import Model from 'models/Model';

var AudienceTargetMode = {
  endpoint  : 'api/AudienceTargetModes',
  attributes: {
    label: {
      type: 'string',
    },
    enabled: {
      type: 'boolean',
    },
  },
};

export default Model.register('AudienceTargetMode', AudienceTargetMode);
