
const POSITION = 8;

const T = {
  is: {
    obj: (val) => _type(val) === 'Object',
    arr: (val) => _type(val) === 'Array',
    str: (val) => _type(val) === 'String',
    ft: (val) => _type(val) === 'Function',
    nb: (val) => _type(val) === 'Number',
    date: (val) => _type(val) === 'Date',
  },
  capitalize: (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`,
  lowercase: (str) => str.toLowerCase(),
};

function _type(val) {
  return {}.toString.call(val).slice(POSITION, -1);
}

export default T;
