import { t } from 'i18next';

import Enum from 'Models/Enum';
import { ContentType } from 'Pages/Activity/redux/models/Content';
import { ActivityType } from 'Pages/Activity/redux/models/Activity';
import { MultilingualContentsMapType } from 'Pages/Activity/redux';
import { GAMEPLAY_ID_TO_STRING } from 'Components/utils/Enum';

export const CONTENT_STATUS = {
  DEFAULT: 0,
  REQUIRES_TRANSLATION: 1,
  PENDING_TRANSLATION: 2,
  REQUIRES_VALIDATION: 3,
} as const;

export const CONTENT_STATUS_TEXT = {
  0: '',
  1: t('contents:status.require_translation'),
  2: t('contents:status.pending_translation'),
  3: t('contents:status.require_validation'),
} as const;

export type ContentStatusType = keyof typeof CONTENT_STATUS_TEXT;

type GetContentMenuOptionsParams = {
  knowledgeId: number;
  content: ContentType;
  contentKey: number;
  dayKey: number;
  contentStatus: ContentStatusType;
  setContentToTranslate: (content: ContentType) => void;
  removeContentFromDailySerieDay: ({
    content,
    dayKey,
    contentKey,
  }: {
    content: ContentType;
    dayKey: number;
    contentKey: number;
  }) => void;
  selectedActivity: ActivityType;
  contentSelectionLocked?: boolean;
};

export const getContentMenuOptions = ({
  knowledgeId,
  content,
  contentKey,
  dayKey,
  contentStatus,
  setContentToTranslate,
  removeContentFromDailySerieDay,
  selectedActivity,
  contentSelectionLocked,
}: GetContentMenuOptionsParams) => {
  if (contentStatus === CONTENT_STATUS.PENDING_TRANSLATION) {
    return [
      {
        id: 0,
        text: t('activities:scheduling.go_to_translation'),
        onClick: () => {
          window.open(`/knowledge/${knowledgeId}/translation`, '_blank');
        },
      },
    ];
  }

  const translationOption = {
    id: 1,
    text: t('activities:scheduling.ask_translation'),
    onClick: () => {
      setContentToTranslate(content);
    },
  };

  const editContentOption = {
    id: 2,
    text:
      contentStatus === CONTENT_STATUS.REQUIRES_VALIDATION && !selectedActivity.isDefault
        ? t('activities:scheduling.go_to_content')
        : t('activities:scheduling.edit_content'),
    onClick: () => {
      window.open(`/knowledge/${knowledgeId}/content/${content.id}`, '_blank');
    },
  };

  const removeOption = {
    id: 3,
    text: t('activities:scheduling.remove_content'),
    onClick: () => {
      removeContentFromDailySerieDay({ contentKey, dayKey, content });
    },
  };

  const options = [
    ...(contentStatus === CONTENT_STATUS.REQUIRES_TRANSLATION ? [translationOption] : []),
    editContentOption,
    ...(selectedActivity.isDefault && !contentSelectionLocked ? [removeOption] : []),
  ];

  return options;
};

type GetContentStatusParams = {
  contentTarget: ContentType;
  selectedActivity: ActivityType;
  knowledgePendingTranslation: boolean;
  requiresValidation: boolean;
};
export const getContentStatus = ({
  contentTarget,
  selectedActivity,
  knowledgePendingTranslation,
  requiresValidation,
}: GetContentStatusParams): ContentStatusType => {
  if (contentTarget.languageId !== selectedActivity.languageId) {
    return CONTENT_STATUS.REQUIRES_TRANSLATION;
  }
  if (knowledgePendingTranslation) {
    return CONTENT_STATUS.PENDING_TRANSLATION;
  }
  if (requiresValidation) {
    return CONTENT_STATUS.REQUIRES_VALIDATION;
  }
  return CONTENT_STATUS.DEFAULT;
};

type GetContentStatusAttributesParams = {
  selectedActivity: ActivityType;
  content: ContentType;
  contentKey: number;
  dayKey?: number;
  draggedContentToDayIndex?: number;
  setContentToTranslate: (content: ContentType) => void;
  removeContentFromDailySerieDay: ({
    content,
    dayKey,
    contentKey,
  }: {
    content: ContentType;
    dayKey: number;
    contentKey: number;
  }) => void;
  multilingualContentMap: MultilingualContentsMapType;
  contentSelectionLocked?: boolean;
};

export const getContentStatusAttributes = ({
  selectedActivity,
  content,
  contentKey,
  dayKey = 0,
  setContentToTranslate,
  removeContentFromDailySerieDay,
  draggedContentToDayIndex = 0,
  multilingualContentMap,
  contentSelectionLocked,
}: GetContentStatusAttributesParams) => {
  const multilingualContent = multilingualContentMap[content.multilingualId];

  /**
   * `multilingualContentsMap` always have the most up to date contents and should only be used when
   * adding new content to the campaign. Old campaigns should always display the state that they were
   * saved in.
   */
  const contentTarget =
    content.languageId === selectedActivity.languageId || !multilingualContent
      ? content
      : multilingualContent[selectedActivity.languageId];

  const {
    knowledgeId,
    knowledgeCustomId,
    gameplayId,
    title,
    imageUrl,
    locale,
    statusId,
    knowledgePendingTranslation,
    knowledgeArchived,
  } = contentTarget;

  const requiresValidation = statusId !== Enum.contentStatus.VALIDATED;
  const contentStatus = getContentStatus({
    contentTarget,
    selectedActivity,
    knowledgePendingTranslation,
    requiresValidation,
  });

  const isDisabled = contentStatus !== CONTENT_STATUS.DEFAULT;
  const indicatorText = CONTENT_STATUS_TEXT[contentStatus];

  const options = getContentMenuOptions({
    knowledgeId,
    content,
    contentKey,
    dayKey,
    contentStatus,
    setContentToTranslate,
    removeContentFromDailySerieDay,
    selectedActivity,
    contentSelectionLocked,
  });

  const aContentIsDragged = draggedContentToDayIndex !== -1;
  const types = [GAMEPLAY_ID_TO_STRING[gameplayId]];

  const statusIndicatorType = knowledgeArchived ? ('warning' as const) : null;
  const statusIndicatorTooltip = knowledgeArchived ? t('activities:scheduling.archived') : '';

  return {
    options,
    isDisabled,
    indicatorText,
    knowledgeCustomId,
    knowledgeId,
    aContentIsDragged,
    imageUrl,
    title,
    types,
    locale,
    statusIndicatorType,
    statusIndicatorTooltip,
  };
};
