/**
 * Displays an icon button and add prop loading
 *
 * Props:
 * - icon: the name of the icon to display
 * - onClick: Function called when the button is clicked
 * - onRequestEnd: Function called at the end of the loading
 * - type: string to set the icon button type ('standard-dark' | 'standard-light' | 'accentuated' | 'destructive' | 'validation' | 'warning')
 * - size: string to set the icon button size ('S' | 'L' | 'M')
 * - disabled: boolean to disable interaction with the icon
 * - ghost: boolean to trigger ghost mode
 * - stopClickPropagation : boolean to stop click propagation
 * - style: For overriding the component's style
 * - trackingAction: for tracking (optionnal)
 * - trackingContext: for tracking (optionnal)
 */

import Promise from 'bluebird';

import withLoading from 'Components/hoc/withLoading/withLoading';
import { IconName } from 'Components/foundation/icons';
import UIconButton from 'Components/unit/UIconButton/UIconButton';

import type { UIconButtonType, UIconButtonSize } from 'Components/unit/UIconButton/UIconButton';
import type { TrackingProps } from 'Services/trackingService';

export type UIconButtonWithLoadingPropsType = {
  icon: IconName;
  onClick: () => Promise<unknown>;
  onRequestEnd: () => void;
  type?: UIconButtonType;
  size?: UIconButtonSize;
  disabled?: boolean;
  ghost?: boolean;
  isLoading?: boolean;
  isRound?: boolean;
  stopClickPropagation?: boolean;
  style?: React.CSSProperties;
} & TrackingProps;

const UIconButtonWithLoading = withLoading({
  timeBeforeLoading: 100,
  minTimeLoading: 200,
})(UIconButton);

export default UIconButtonWithLoading;
