// @flow

import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    borderRadius: 4,
    maxWidth: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',

    ...TYPOGRAPHY.BODY3,
  },
  mode: {
    standard: {
      defaultColor: COLORS.CHIPS.DEFAULT,
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 12,
        paddingRight: 12,
      },
      typography: {
        ...TYPOGRAPHY.BODY3,
      },
      height: 26,
    },
    compact: {
      defaultColor: COLORS.CHIPS.DEFAULT,
      padding: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
      },
      typography: {
        ...TYPOGRAPHY.BODY4,
      },
      height: 17,
    },
    aggregated: {
      defaultColor: COLORS.TEXT.DEFAULT,
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 10,
      },
      typography: {
        ...TYPOGRAPHY.BODY3,
      },
      height: 26,
    },
  },
  numberBox: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    minWidth: 20,
    paddingLeft: 4,
    paddingRight: 4,
    textAlign: 'center',
    marginRight: 6,
    borderRadius: 3,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...TYPOGRAPHY.BODY4,
  },
  actionIcon: {
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: 10,
    cursor: 'pointer',
  },
  actionIcons: {
    close: {
      size: 8,
      name: 'close',
    },
    collapse: {
      size: 8,
      name: 'arrow-down',
    },
  },
  categoryIcon: {
    size: 14,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginRight: 8,
  },
  selectionDisable: {
    userSelect: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  aggregationNumber: {
    height: 15,
    marginTop: 1,
  },
  dropdownbox: {
    position: 'absolute',
    top: 30,
    left: 0,
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
};
