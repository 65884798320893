import BluebirdPromise from 'bluebird';

import React, { useCallback, useRef, useState } from 'react';
import type { ComponentType } from 'react';

export type WithLoadingProps = {
  onClick: () => BluebirdPromise<unknown> | Promise<unknown>;
  onRequestEnd: (params: unknown) => void;
};

type WithLoadingParameters = {
  timeBeforeLoading: number;
  minTimeLoading: number;
};

export default function withLoading(parameters: WithLoadingParameters) {
  return function getComponent<P extends Record<string, any>>(ComponentToWrap: ComponentType<P & WithLoadingProps>) {
    const WrappedWithLoading = (props: P & WithLoadingProps) => {
      const { onClick, onRequestEnd } = props;

      const [loading, setLoading] = useState(false);
      const loadingRef = useRef(false);

      const handleError = useCallback((error: Error) => {
        setLoading(false);
        loadingRef.current = false;

        return { error };
      }, []);

      const handlePress = useCallback(() => {
        const { timeBeforeLoading, minTimeLoading } = parameters;

        if (loadingRef.current) {
          return BluebirdPromise.resolve();
        }

        const promise = onClick();
        const bluebirdPromise = promise instanceof BluebirdPromise ? promise : BluebirdPromise.resolve(promise);

        return bluebirdPromise
          .timeout(timeBeforeLoading)
          .catch(BluebirdPromise.TimeoutError, async () => {
            setLoading(true);
            loadingRef.current = true;

            const [result] = await BluebirdPromise.all([promise, BluebirdPromise.delay(minTimeLoading)]);

            setLoading(false);
            loadingRef.current = false;

            return result;
          })
          .catch(handleError)
          .then(onRequestEnd);
      }, [onClick, onRequestEnd, handleError]);

      return <ComponentToWrap {...props} loading={loading} onClick={handlePress} />;
    };

    WrappedWithLoading.displayName = `${ComponentToWrap.displayName}WithLoading`;

    return WrappedWithLoading;
  };
}
