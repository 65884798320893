import React, { CSSProperties } from 'react';

import { PdfBlockForCourse, PdfPageForCourse } from 'Libs/pdf-extraction/pdf-to-course';

import styles from './SPDFBlocksSelector.style';
import { PDFPage } from './PDFPage';

export type SPDFBlocksSelectorProps = {
  pdfPages: PdfPageForCourse[];
  style?: CSSProperties;
  handleBlockSelect: (block: PdfBlockForCourse) => void;
};

const SPDFBlocksSelector = ({ pdfPages, style, handleBlockSelect }: SPDFBlocksSelectorProps) => {
  const handleBlockClick = (block: PdfBlockForCourse) => {
    handleBlockSelect(block);
  };

  return (
    <div style={style}>
      <div style={styles.images}>
        {pdfPages.map((page, index) => (
          <PDFPage key={index} page={page} handleBlockClick={handleBlockClick} />
        ))}
      </div>
    </div>
  );
};

export default SPDFBlocksSelector;
