import m from 'm';

var component = {};

component.view = function view(c, args) {
  return m('.question-gameplay-preview', [
    m('.question-gameplay-preview-text', args.question),
  ]);
};
export default component;
