import COLORS from 'Components/foundation/colors';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  formWrapper: {
    width: 400,
  },
  instructionWrapper: {
    marginTop: 16,
  },
  instruction: {
    ...TYPOGRAPHY.BODY3,
  },
  phoneNumber: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 'bold',
  },
  codeInputContainer: {
    display: 'flex',
    marginTop: 55,
  },
  codeInput: {
    width: 200,
  },
  resendCode: {
    marginLeft: 16,
  },
  trustChoiceWrapper: {
    marginTop: 39,
    display: 'flex',
    alignItems: 'center',
  },
  trustChoiceInstruction: {
    marginLeft: 8,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 59,
    alignItems: 'center',
  },
  submitButton: {
    marginRight: 24,
    height: 'fit-content',
  },
  errorMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
    maxWidth: 175,
  },
});

export default styleSheet;
