/* eslint-disable complexity */
import * as React from 'react';
import m from 'm';
import moment from 'moment';
import i18n from 'i18next';

import { getCdnUrl } from 'libs/cdn';
import { TRANSLATION_FLAG, i18nSupportedLanguages } from 'libs/i18n/i18n';

import Enum from 'models/Enum';
import User from 'services/userService';
import Bus from 'services/pubSubService';
import Config from 'services/instanceConfig';
import { isFeatureEnabled } from 'services/feature-flags';
import App from 'models/App';


import popover from 'components/popover';
import reactCreator from 'components/react.creator';
import ULoggedItem from 'Components/unit/ULoggedItem/ULoggedItem';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';

const COURSES_FLAG_NAME = 'courses-list';
const HOMEPAGE = 'homepage';
const DOLPHIN_CONTENTS_FLAG_NAME = 'dolphin-contents';

const PLAYBOOK_URL_LINK = 'https://www.notion.so/sparted/Client-Playbook-519fe9d609b9462ca0d27e87e6e89229';
const LIBRARY_URL_LINK = 'https://www.notion.so/sparted/Off-the-Shelf-Content-Catalog-03c309f4b4114c3da3d8a20ffee9b71b';

var component = {};

const { t } = i18n;;

const languageMenuOptions = (currentLanguage) => Object.keys(i18nSupportedLanguages).map((language) => {
  return ({
    type: 'language',
    key: language,
    props: {
      text: i18nSupportedLanguages[language].label,
      onClick: () => {
        moment.locale(language);
        i18n.changeLanguage(language);
        window.location.reload();
      },
      countryCode: i18nSupportedLanguages[language].countryCode,
      key: language,
      selected : currentLanguage === language,
    },
  })
});

component.controller = function controller(args) {
  var self = this;

  const roleIds = User.meOrUnauthenticated().roles().map((role) => role.id());

  /* TODO: Delete this condition when removing the flag homepage OR courses-list */
  let mainRoute = {
    ...(isFeatureEnabled(HOMEPAGE) ? { '/home': { name: t('menu:home'), event: 'homepage' } } : {}),
    ...(isFeatureEnabled(COURSES_FLAG_NAME) ? { '/courses': { name: t('menu:courses'), event: 'courses' } } : {}),
    '/knowledge': { name: t('menu:contents'), event: 'content' },
    ...(isFeatureEnabled(DOLPHIN_CONTENTS_FLAG_NAME) ? {'/content': { name: t('menu:dolphin.contents'), event: 'content' }} : {}),
    ...(isFeatureEnabled(DOLPHIN_CONTENTS_FLAG_NAME) ? {'/dolphin-activity': { name: t('menu:dolphin.activity'), event: 'activity' }} : {}),
    '/activity': { name: t('menu:activities'), event: 'activity' },
    '/analytics/dashboard': { name: t('menu:analytics'), event: 'analytics' },
    '/document': { name: t('menu:documents'), event: 'documents' },
    '/gaia/players': { name: t('menu:configuration'), event: 'configuration' },
  };

  self.options = App.router.buildMainRouteOptionForUser(mainRoute);

  self.externalLinks = [];

  if (roleIds.some((role) =>
    [Enum.Role.ROOT, Enum.Role.MASTER, Enum.Role.ADMIN].includes(role)))
    self.externalLinks.push({ icon: 'playbook', tooltip: t('menu:playbook'), link: PLAYBOOK_URL_LINK, event: 'playbook' });

  if (roleIds.some((role) =>
    [Enum.Role.ROOT, Enum.Role.MASTER, Enum.Role.ADMIN].includes(role))) {
    self.externalLinks.push({
      icon: 'content-catalog',
      tooltip: t('menu:off_the_shelf_content'),
      link: LIBRARY_URL_LINK,
      event: 'content-catalog',
    });
  }

  self.actived = m.prop(false);
  self.isDropDownActive = m.prop(false);
  self.initial = m.prop('');
  self.user = m.prop(null);
  self.items = m.prop([
    { key: '0', name: 'ULoggedItem', label: t('menu:logged_in_as') },
    {
      key: '1',
      name: 'UDropdownItem',
      text: t('menu:ressources'),
      onClick: () => window.open(`https://app-ressources.sparted.com/${Config.instanceId}`),
      trackingAction: 'navigate',
      trackingContext: 'menu-profile-resources',
    },
    {
      key: '2',
      name: 'UDropdownItem',
      text: t('menu:help_center'),
      onClick: () => window.open('https://support.sparted.com'),
      trackingAction: 'navigate',
      trackingContext: 'menu-profile-helpcenter',
    },
    {
      key: '3',
      name: 'UDropdownItem',
      text: t('menu:log_out'),
      onClick: () => self.logout(),
      trackingAction: 'navigate',
      trackingContext: 'menu-profile-logout',
    },
  ]);

  User
    .me()
    .tap(self.user)
    .then(function redraw() {
      m.redraw();
    });

  self.logout = function logout() {
    User.logout()
      .then(Bus.trigger.bind(Bus, 'logout'));
  };

  self.clickItem = function clickItem(route, e) {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.which === 2)
      return;

    e.preventDefault();

    if (args && args.confirm)
      args.confirm(() => m.route(route));
    else
      m.route(route);
  };

  self.clearFilter = function clearFilter() {
    args.clearFilter();
    self.actived(false);
  };

  const removeDropdownBox = (event) => {
    const UProfileContainer = document.querySelector('#u-profile-container');

    if (UProfileContainer && !UProfileContainer.contains(event.target)) {
      self.isDropDownActive(false);
      m.redraw();
    }
  };

  self.config = function config(_, isInit) {
    if (!isInit)
      document.addEventListener('click', removeDropdownBox);
  };

  self.onunload = function onunload() {
    document.removeEventListener('click', removeDropdownBox);
  };

  self.handleClick = () => {
    self.isDropDownActive(!self.isDropDownActive());
    m.redraw();
  };

  self.openExternalLink = (link) => {
    window.open(link, '_blank');
  };

  self.logoSrc = Config.instanceId === 'local'
    ? getCdnUrl('test', '/logo.png')
    : getCdnUrl(Config.instanceId, '/logo.png');

  self.handleClickLogo = () => {
    // Without this setTimeout, the page unmounts too fast and the global click listener..
    // .. for tracking fires too late to pickup the trackingAction
    setTimeout(() => m.route('/home'), 0);
  };
};

component.view = function view(c, args) {
  const mainRoute = App.router.getMainRoute(c.options);
  var email = t('menu:loading');
  var user = c.user();

  if (user) {
    email = user.email();
    const initFirstName = user.firstName().charAt(0);
    const initLastName = user.lastName().split(/[^a-zA-Z]/).map((n) => n[0]).join('');

    c.initial(initFirstName + initLastName);
  }

  const currentLanguage = i18n.language;
  return m('nav.menu', {
    config: c.config,
  }, [
    m('a.logo', {
      'data-tracking-action': 'navigate',
      'data-tracking-context': 'menu-logo',
    }, [
      m(reactCreator, {
        component: 'UClientLogo',
        props: {
          style: {},
          height: 34,
          width: 280,
          onClick: c.handleClickLogo,
          logoSrc: c.logoSrc,
        },
      }),
    ]),
    m('ul.menu__list', [
      Object.entries(c.options).map(([route, option]) => {
        const selected = mainRoute === route;
        const { isGranted, name, event } = option;

        return m('li.menu__item', {
          class: [
            selected ? 'active' : '',
            !isGranted ? 'menu__item--forbidden' : '',
          ].join(' '),
        }, [
          m.hide(isGranted, '.menu__item__popover', [
            m(popover, {
              delay: true,
            }, [
              m('.menu__item__popover__text', t('menu:your_role_does_not_allow_you_to_access_this_page')),
            ]),
          ]),
          isGranted
            ? m('a', {
              href: route,
              onclick: c.clickItem.bind(null, route),
              'data-tracking-action': 'navigate',
              'data-tracking-context': `menu-${event}`,
            }, name)
            : m('a', name),
        ]);
      }),
      c.externalLinks.length > 0 ? m('li.menu__item#external-links-spacer') : null,
      c.externalLinks.map(({ icon, link, tooltip, event }, i) => {
        return m(
          'li.menu__item.external-links'.concat((c.externalLinks.length - 1) === i ? '.last' : ''),
          {
            ['data-tip']: true,
            ['data-for']: 'external-links-' + i,
            'data-tracking-action': 'navigate',
            'data-tracking-context': `menu-${event}`,
          }, [
          m(reactCreator, {
            component: 'UIconButton',
            props: {
              onClick: c.openExternalLink.bind(null, link),
              icon: icon,
              ghost: true,
              style: {
                display: 'flex',
                marginLeft: 6,
                marginRight: 6,
              },
            },
          }),
          m(reactCreator, {
            component: 'STooltip',
            props: {
              id: 'external-links-' + i,
              position: 'bottom',
              content: tooltip,
            },
          }),
        ]);
      }),
      isFeatureEnabled(TRANSLATION_FLAG) ?
      m('li.menu__item#menu-item-language-button', [
      m(reactCreator, {
        component: 'ULanguageMenu',
        props: {
          options: languageMenuOptions(currentLanguage),
          countryCode: i18nSupportedLanguages[currentLanguage].countryCode,
          style: {
            position: 'relative',
            margin: '0 8px 0 6px'
          },
        },
      })
    ]) : null,
      m('li.menu__item#menu-item-u-profile', [
        m(reactCreator, {
          component: 'UProfile',
          props: {
            onClick: c.handleClick,
            initials: c.initial,
            style: {
              position: 'relative',
              margin: '0 22px 0 16px',
            },
          },
        }),
        displayDropdownBox(),
      ]),
    ]),
  ]);

  function renderItem(item, key) {
    let componentObject;

    switch (item.name) {
      case 'ULoggedItem':
        componentObject = {
          ...item,
          key: key,
          mail: email,
        };

        return (
          <ULoggedItem
            {...componentObject}
          />
        );
      case 'UDropdownItem':
        componentObject = {
          ...item,
          key: key,
        };

        return (
          <UDropdownItem
            {...componentObject}
          />
        );

      default:
        return null;
    }
  }

  function displayDropdownBox() {
    if (!c.isDropDownActive())
      return null;

    return (
      m('div#userDropdownBox', [
        m(reactCreator, {
          component: 'UDropdownBox',
          props: {
            items: c.items,
            renderItem: renderItem,
            style: {
              position: 'absolute',
              top: 49,
              right: 24,
            },
          },
        }),
      ])
    );
  }
};

export default component;
