import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const styles = createStyleSheet({
  container: {
    minWidth: 56,
    maxWidth: 219,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    alignItems: 'center',
    padding: '6px 12px 6px 12px',
    marginRight: 16,
    ...TYPOGRAPHY.BODY2,
    color: COLORS.BLUE_GRAY_DARKER.DEFAULT,
  },
  active: {
    backgroundColor: 'rgb(225, 230, 239, 0.6)',
    borderRadius: 4,
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: 'transparent',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  hover: {
    display: 'inline-block',
    borderRadius: 4,
    backgroundColor: '#F4F5F5',
    cursor: 'pointer',
    ...TYPOGRAPHY.BODY2,
    color: COLORS.BLUE_GRAY_DARKER.DEFAULT,
  },
  activeText: {
    userSelect: 'none',
    ...TYPOGRAPHY.BODY2,
    color: COLORS.BLUE_GRAY_DARKEST.DEFAULT,
  },
  disabledText: {
    userSelect: 'none',
    ...TYPOGRAPHY.BODY2,
    color: COLORS.PRIMARY.DISABLED,
  },
  text: {
    userSelect: 'none',
    ...TYPOGRAPHY.BODY2,
    color: COLORS.BLUE_GRAY_DARKER.DEFAULT,
  },
});

export default styles;
