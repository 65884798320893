import { t } from 'i18next';
import _ from 'lodash';
import m from 'm';

import activityDate from 'components/activity.date';
import activityHeader from 'components/activity.header.new';
import activityToolbar from 'components/activity.toolbar';
import button from 'components/button';
import hourStepper from 'components/hour.stepper';
import inputTextArea from 'components/new.input.textarea';
import toggleButton from 'components/toggle.button';
import App from 'models/App';
import Enum from 'models/Enum';
import Model from 'models/Model';
import menu from 'pages/menu';
import popup from 'services/popupService';

var component = {};

component.controller = function controller() {
  var self = this;
  var Activity = Model('Activity');

  self.loading = m.prop(true);
  self.isSaving = m.prop(false);
  self.isBreakingNewsCampaignToggle = m.prop(false);

  var breakingNewsCardId = m.route.param('id');

  var activityType = App.activityTypes().get(Enum.activityTypeEnum.BREAKING_NEWS);

  var blankBreakingNewsCard = {
    id       : undefined,
    typeId   : Enum.activityTypeEnum.BREAKING_NEWS,
    startDate: new Date().setUTCHours(1, 0, 0, 0),
    endDate  : new Date().setUTCHours(23, 59, 59, 0),
  };

  self.activity = m.prop(new Activity(blankBreakingNewsCard));
  self.originalActivity = m.prop(null);

  if (breakingNewsCardId === 'new') {
    self.activity(new Activity(blankBreakingNewsCard));
    self.activity().type(activityType);
    doneLoading();
  } else {
    Activity
      .get(breakingNewsCardId)
      .then(self.activity)
      .finally(doneLoading);
  }

  function doneLoading() {
    self.isBreakingNewsCampaignToggle(Boolean(self.activity().breakingNewsCard().campaignId()));
    self.originalActivity(self.activity().clone());
    self.loading(false);
    m.redraw();
  }

  self.back = function back() {
    m.route('/activity');
  };

  self.openPopup = function openPopup(errorMsg) {
    if (errorMsg.subtitle && errorMsg.content) {
      popup.open({
        type    : 'info',
        title   : t('breaking_news:error.cannot_create'),
        subtitle: '',
        content : errorMsg.content,
      });

      return false;
    }

    return true;
  };

  self.onEndDateChange = function onEndDateChange() {
    self.activity().endDate().setUTCHours(23, 59, 59);
  };

  self.modified = function modified() {
    var newActivity = self.activity().toJSON();
    var oldActivity = self.originalActivity() && self.originalActivity().toJSON();

    delete newActivity.active;

    if (newActivity.breakingNewsCard.campaign)
      delete newActivity.breakingNewsCard.campaign;

    // check if we're in creation mode
    if (oldActivity)
      delete oldActivity.active;

    return !_.isEqual(newActivity, oldActivity);
  };

  self.saveActivity = function saveActivity() {
    var activity = self.activity();
    var breakingNewsCard = activity.breakingNewsCard();
    var video = breakingNewsCard.videoCover();
    var image = breakingNewsCard.cover();

    if (video && video.id()) {
      activity.cover(image);
      activity.coverId(image.id());
      breakingNewsCard.coverId(image.id());
      breakingNewsCard.videoCoverId(video.id());
      activity.videoCoverId(video.id());
      activity.videoCover(video);
    } else {
      breakingNewsCard.videoCover(null);
      breakingNewsCard.videoCoverId(null);
      activity.videoCoverId(null);
      activity.videoCover(null);
      activity.coverId(activity.cover().id());
    }

    if (breakingNewsCard.campaignId())
      activity.segmentation([]);

    var validated = activity.validate(self.isBreakingNewsCampaignToggle());

    if (self.openPopup(validated)) {
      self.isSaving(true);

      return activity
        .save(true)
        .tap(function savedActivity(updated) {
          var newRoute = updated.getRoute();

          self.isSaving(false);
          m.route(newRoute);
        });
    }
  };
};

component.view = function view(c, args) {
  return m('.welcome-page', [
    m('.header', [
      m(menu),
    ]),
    m('.home__loader', {
      class: c.loading() ? '' : 'hidden',
    }),
    c.loading()
    ? ''
    : m('.welcome-detail-page', {
      class: c.loading() ? 'hidden' : '',
    }, [
      m('.welcome-body', [
        m(activityToolbar, {
          activity    : c.activity(),
          original    : c.originalActivity(),
          saving      : c.isSaving(),
          saveActivity: c.saveActivity,
        }),
        m(activityHeader, {
          activity                    : c.activity,
          video                       : c.activity().breakingNewsCard().videoCover,
          isBreakingNewsCampaignToggle: c.isBreakingNewsCampaignToggle,
        }),
        m(activityDate, {
          activity   : c.activity(),
          onEndChange: c.onEndDateChange,
        }),
        m('.activity-frame', [
          m('.activity-frame__block', [
            m('.activity-frame__icon.activity-notification__icon'),
            m('.activity-frame__title', t('breaking_news:notification.title')),
            m('.activity-frame__inline-block', [
              m(toggleButton, {
                value: c.activity().breakingNewsCard().notificationActive,
              }),
            ]),
            m('.activity-frame__inline-block', {
              class: c.activity().breakingNewsCard().notificationActive() ? '' : 'hidden',
            }, [
              m('.activity-frame__icon.activity-hour__icon'),
              m('.activity-frame__subtitle', t('breaking_news:notification.subtitle')),
              m('.activity-frame__comment', t('breaking_news:notification.comment')),
              m('.activity-frame__inline-block', [
                m(hourStepper, {
                  date: c.activity().breakingNewsCard().notificationTime,
                  step: m.prop(15),
                }),
              ]),
              m('.activity-frame__inline-block', [
                m('.activity-frame__icon.activity-recurring-hour__icon'),
                m('.activity-frame__subtitle', t('breaking_news:notification.recurring')),
                m('.activity-frame__comment', t('breaking_news:notification.recurring_comment')),
                m('.activity-frame__inline-block', [
                  m(toggleButton, {
                    value: c.activity().breakingNewsCard().recurringNotification,
                  }),
                ]),
              ]),
            ]),
          ]),
          m('.activity-frame__block', [
            m('.activity-frame__icon.activity-text__icon'),
            m('.activity-frame__title', t('breaking_news:long_text.title')),
            m('.activity-frame__inline-block', [
              m(toggleButton, {
                value: c.activity().breakingNewsCard().longTextActive,
              }),
            ]),
            m('.activity-frame__title-comment', {
              class: c.activity().breakingNewsCard().longTextActive() ? '' : 'hidden',
            }, t('breaking_news:long_text.comment')),
            m('.activity-frame__block', {
              class: c.activity().breakingNewsCard().longTextActive() ? '' : 'hidden',
            }, [
              m('.activity-breaking-news__text-area', [
                m(inputTextArea, {
                  placeholder: t('breaking_news:long_text.placeholder'),
                  value      : c.activity().breakingNewsCard().text,
                  max        : 10000,
                }),
              ]),
            ]),
          ]),
        ]),
        m('.activity__footer', {
          config: c.activityHasChanged,
        }, [
          c.activity().typeId() === Enum.activityTypeEnum.DAILY_SERIE
          ? ''
          : m(button, {
            class   : 'activity-toolbar__button--save button--action',
            action  : c.saveActivity,
            value   : c.activity().id() ? t('breaking_news:save') : t('breaking_news:create'),
            disabled: !c.modified(),
          }),
        ]),
      ]),
    ]),
  ]);
};

export default component;
