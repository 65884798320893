import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';

type AdminResetPasswordAction = ApiActionType<'ADMIN_RESET_PASSWORD'>;

type AdminResetSetEmail = {
  type: 'ADMIN_RESET_PASSWORD_SET_EMAIL';
  email: string;
};

export const actions = {
  setEmail: (email: string): AdminResetSetEmail => ({
    type: 'ADMIN_RESET_PASSWORD_SET_EMAIL',
    email,
  }),

  adminResetPassword: (email: string, l10n = 'en-US'): AdminResetPasswordAction => {
    const payload = { email, l10n };

    return apiAction({
      type: 'ADMIN_RESET_PASSWORD',
      route: '/api/Players/askPasswordResetBack',
      method: 'POST',
      payload,
    });
  },
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type ResetPasswordAction = ReturnType<ActionsFn>;
