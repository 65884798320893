/**
 * filterPlaceholder: string for filter placeholder
 * filterValue: filter value string
 * dimension: dimension array value
 * tabs: config for the tabs button
 * onFilterChange: function triggered when filters are modified
 * inputOpened: boolean for opening the input
 * style: style overwrite
 */

import React, { CSSProperties, useState, useCallback, useMemo } from 'react';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';
import SDivider from 'Components/structural/SDivider/SDivider';
import USecondaryTabLabel, { USecondaryTabLabelProps } from 'Components/unit/USecondaryTabLabel/USecondaryTabLabel';
import SSecondaryTabList from 'Components/structural/SSecondaryTabList/SSecondaryTabList';

import type { Source } from 'Libs/ts/types';

import styles from './SDisplayControlItems.style';

export type SDisplayControlItemsProps = {
  filterValue: string;
  filterPlaceholder: string;
  tabs: Array<USecondaryTabLabelProps>;
  onFilterChange: (value: string) => void;
  inputOpened?: boolean;
  style?: CSSProperties;
};

const suggestionOptions = {
  enable: true,
  enableOnEmptyInput: false,
  enableTextSuggestion: false,
};

export const SDisplayControlItems = ({
  filterValue,
  filterPlaceholder,
  tabs,
  onFilterChange,
  inputOpened = false,
  style = {},
}: SDisplayControlItemsProps) => {
  const [searchInputOpen, setSearchInputOpen] = useState(inputOpened);

  const handleSearchInput = useCallback(() => setSearchInputOpen(!searchInputOpen), [searchInputOpen]);

  const handleFilterChange = useCallback(
    (filter: Source) => onFilterChange(filter.filters[0].items[0].value),
    [onFilterChange],
  );

  const omniboxSources = useMemo(
    (): Source[] => [
      {
        icon: undefined,
        name: 'text-source',
        filters: [
          {
            category: 'text',
            type: 'text',
            items: [
              {
                category: 'text',
                value: filterValue,
                id: 1,
              },
            ],
          },
        ],
      },
    ],
    [filterValue],
  );

  const handleClose = useCallback(() => {
    handleSearchInput();
    onFilterChange('');
  }, [handleSearchInput, onFilterChange]);

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      {!searchInputOpen ? (
        <UIconButton style={styles.iconButton} ghost onClick={handleSearchInput} size="M" icon="search" />
      ) : (
        <div style={styles.omniboxWrapper}>
          <SOmnibox
            style={styles.omnibox}
            placeholder={filterPlaceholder}
            suggestionOptions={suggestionOptions}
            filterMultiText={false}
            sources={omniboxSources}
            hideMargins
            automaticSearch
            onFilterChanged={handleFilterChange}
          />
          <UIconButton style={styles.iconButton} ghost onClick={handleClose} size="S" icon="close" />
        </div>
      )}
      <SDivider style={styles.divider} direction="vertical" />
      <div style={searchInputOpen ? styles.secondarylistWrapperAlternative : styles.secondarylistWrapper}>
        <SSecondaryTabList style={styles.secondaryTabList}>
          {tabs.map((tab) => (
            <USecondaryTabLabel
              key={tab.text}
              text={tab.text}
              onClick={tab.onClick}
              disabled={tab.disabled}
              active={tab.active}
            />
          ))}
        </SSecondaryTabList>
      </div>
    </div>
  );
};

export default SDisplayControlItems;
