import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 1000,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 32,
  },
  pageHeader: {
    paddingTop: 100,
  },
  audienceGroupList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 24,
    marginTop: 44,
    marginBottom: 32,
  },
  nextBatch: {
    marginBottom: 32,
  },
  loadingContainer: {
    margin: '64px auto',
  },
  optionsWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    marginTop: '16px',
  },
  select: {
    marginRight: 0,
    display: 'flex',
  },
});

export default styleSheet;
