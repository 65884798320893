import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';

import { store } from 'Libs/redux/store';

import { prepareCategory } from 'ModelsReact/Category/Category';
import type { AlertScope, AlertParams } from './models/Alert';
import type { CategoryDataAccessor } from './models/CategoryTableData';

type CategoryListFetchAction = ApiActionType<'CATEGORY_LIST_FETCH'>;
type CategoryCreateAction = ApiActionType<'CATEGORY_CREATE'>;
type CategoryEditAction = ApiActionType<'CATEGORY_EDIT'>;
type CategoryDeleteAction = ApiActionType<'CATEGORY_DELETE'>;

type CategorySetAlertAction = {
  type: 'CATEGORY_SET_ALERT';
  scope: AlertScope;
  params: AlertParams;
};

type CategoryRemoveAlertAction = {
  type: 'CATEGORY_REMOVE_ALERT';
  scope: AlertScope;
};

type CategoryResetAction = {
  type: 'CATEGORY_RESET';
};

type CategoryToggleCreationModal = {
  type: 'CATEGORY_TOGGLE_CREATION_MODAL';
  isOpen: boolean;
};

type CategoryCreationEditLabel = {
  type: 'CATEGORY_CREATION_EDIT_LABEL';
  value: string;
};

type CategoryListResetRowErrors = {
  type: 'CATEGORY_LIST_RESET_ROW_ERRORS';
};

type CategoryToggleDeletionModal = {
  type: 'CATEGORY_TOGGLE_DELETION_MODAL';
  isOpen: boolean;
  category?: CategoryDataAccessor;
};

export const CATEGORIES_PAGINATION = 20;

export const actions = {
  fetch: (filter: Record<string, any> = {}, isInit = false): CategoryListFetchAction =>
    apiAction({
      type: 'CATEGORY_LIST_FETCH',
      route: '/api/categories/full',
      method: 'GET',
      query: { filter },
      meta: { isInit },
    }),

  create: (): CategoryCreateAction => {
    const state = store.getState();
    const { categoryToCreate } = state.pages.categoryList;

    const payload = prepareCategory(categoryToCreate);

    return apiAction({
      type: 'CATEGORY_CREATE',
      route: '/api/categories/full',
      method: 'POST',
      payload,
    });
  },

  edit: (categoryId: number, value: string): CategoryEditAction => {
    const payload = { label: value.trim() };

    return apiAction({
      type: 'CATEGORY_EDIT',
      route: `/api/categories/full/${categoryId}`,
      method: 'PUT',
      payload,
      meta: { categoryId },
    });
  },

  delete: (categoryId: number): CategoryDeleteAction =>
    apiAction({
      type: 'CATEGORY_DELETE',
      route: `/api/categories/full/${categoryId}`,
      method: 'DELETE',
      meta: { categoryId },
    }),

  reset: (): CategoryResetAction => ({
    type: 'CATEGORY_RESET',
  }),

  setAlert: (scope: AlertScope, params: AlertParams = Object.freeze({})): CategorySetAlertAction => ({
    type: 'CATEGORY_SET_ALERT',
    scope,
    params,
  }),

  removeAlert: (scope: AlertScope): CategoryRemoveAlertAction => ({
    type: 'CATEGORY_REMOVE_ALERT',
    scope,
  }),

  toggleCreationModalStatus: (isOpen: boolean): CategoryToggleCreationModal => ({
    type: 'CATEGORY_TOGGLE_CREATION_MODAL',
    isOpen,
  }),

  editLabelForCreation: (label: string): CategoryCreationEditLabel => ({
    type: 'CATEGORY_CREATION_EDIT_LABEL',
    value: label,
  }),

  resetRowErrors: (): CategoryListResetRowErrors => ({
    type: 'CATEGORY_LIST_RESET_ROW_ERRORS',
  }),

  toggleDeletionModal: (isOpen: boolean, category?: CategoryDataAccessor): CategoryToggleDeletionModal => ({
    type: 'CATEGORY_TOGGLE_DELETION_MODAL',
    isOpen,
    category,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type CategoryListAction = ReturnType<ActionsFn>;
