import React, { CSSProperties, ReactNode } from 'react';

import styles from './SFooter.styles';

export type SFooterProps = {
  children?: ReactNode;
  style?: CSSProperties;
};

export const SFooter = ({ children, style = {} }: SFooterProps) => {
  if (!children) {
    return <div style={{ ...styles.default, ...style }} />;
  }

  return <div style={{ ...styles.childrenWrapper, ...style }}>{children}</div>;
};
