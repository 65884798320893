import { StyleSheet as Aphrodite } from 'aphrodite';
import { CSSProperties } from 'react';

import { createStyleSheet } from 'Components/foundation/stylesheets';

import { COLORS, TYPOGRAPHY, CORNERS as BORDERS } from 'Components/foundation';
import { UIconButtonType } from 'Components/unit/UIconButton/UIconButton';
import type { UButtonType } from 'Components/unit/UButton/UButton.utils';
import { TextLinkType } from 'Components/unit/UTextLink/UTextLink';
import type { SCalloutType } from './SCallout';

export const createCloseAnimation = (duration: string) => {
  const { close } = Aphrodite.create({
    close: {
      animationName: {
        from: {
          opacity: 1,
        },
        to: {
          opacity: 0,
        },
      },
      animationDuration: duration,
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
    },
  });

  return close;
};

const styleSheet = createStyleSheet({
  illustrationWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    ...BORDERS.BORDER_RADIUS(BORDERS.CORNER3),
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minHeight: '48px',
    opacity: 1,
    justifyContent: 'space-between',
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minHeight: '48px',
    opacity: 1,
    maxWidth: '966px',
    minWidth: '403px',
    justifyContent: 'space-between',
    width: '100%',
  },
  closeButtonContainer: {
    marginTop: '8px',
    marginRight: '8px',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
  },
  centeredCloseButtonContainer: {
    marginTop: '0px',
    marginRight: '16px',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '696px',
    alignContent: 'center',
    justifyContent: 'center',
  },

  centeredActionButtonContainer: {
    alignItems: 'center',
    marginRight: '16px',
  },
  titleContainer: {
    height: '24px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...TYPOGRAPHY.HERO_S_BOLD,
    marginBottom: '8px',
  },
  titleBanner: {
    ...TYPOGRAPHY.H1_BOLD,
    marginBottom: '8px',
  },
  description: {
    ...TYPOGRAPHY.BODY3,
  },
  normalButton: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px',
    marginRight: '0px',
  },
  normalButtonWithoutClose: {
    marginRight: '16px',
  },
  bannerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    marginBottom: '24px',
  },
  buttonWrapperWithoutTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  illustration: {
    marginRight: '16px',
    height: '52px',
    width: '52px',
  },
});

export default styleSheet;

export const backgroundColorStyle: Record<SCalloutType, CSSProperties> = {
  accentuated: {
    // 10% Transparent
    backgroundColor: `${COLORS.PRIMARY.DEFAULT}1A`,
    outlineColor: COLORS.PRIMARY.DEFAULT,
    outlineStyle: 'solid',
    outlineWidth: '1px',
    outlineOffset: '-1px',
  },
  warning: {
    backgroundColor: COLORS.WARNING.LIGHTER,
  },
  standard: {
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  'standard-dark': {
    backgroundColor: COLORS.GREY_DARKER.DEFAULT,
  },
};

export const titleColorStyle: Record<SCalloutType, CSSProperties> = {
  accentuated: {
    color: COLORS.PRIMARY.DEFAULT,
  },
  warning: {
    color: COLORS.WARNING.DARK,
  },
  standard: {
    color: COLORS.TEXT.DEFAULT,
  },
  'standard-dark': {
    color: COLORS.WHITE.DEFAULT,
  },
};
export const iconButtonType: Record<SCalloutType, UIconButtonType> = {
  accentuated: 'accentuated',
  warning: 'standard-dark',
  standard: 'standard-dark',
  'standard-dark': 'standard-light',
};
export const descriptionColor: Record<SCalloutType, CSSProperties> = {
  accentuated: { color: COLORS.TEXT.SECONDARY_DEFAULT },
  warning: { color: COLORS.TEXT.SECONDARY_DEFAULT },
  standard: { color: COLORS.TEXT.SECONDARY_DEFAULT },
  'standard-dark': { color: COLORS.WHITE.DEFAULT },
};

type ActionButtonTypeProps = {
  type: UButtonType;
  ghost: boolean;
};
export const actionButton: Record<SCalloutType, ActionButtonTypeProps> = {
  warning: { type: 'warning', ghost: false },
  accentuated: { type: 'accentuated', ghost: false },
  standard: { type: 'standard', ghost: true },
  'standard-dark': { type: 'standard-light', ghost: true },
};

export const textLink: Record<SCalloutType, TextLinkType> = {
  warning: 'warning',
  accentuated: 'accentuated',
  standard: 'standard',
  'standard-dark': 'standard-light',
};

type ContentContainerStyleProps = {
  title?: string;
};

export const getContentContainerStyle = ({ title }: ContentContainerStyleProps): CSSProperties =>
  title
    ? {
        marginTop: '28px',
        marginBottom: '24px',
        marginLeft: '24px',
        marginRight: '24px',
      }
    : {
        marginTop: '24px',
        marginBottom: '24px',
        marginLeft: '24px',
        marginRight: '16px',
      };
