import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import MModal from 'Components/modal/MModal/MModal';
import type { IconName } from 'Components/foundation/icons';

import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';
import { UButtonType } from 'Components/unit/UButton/UButton';

import styles from './MActionDialog.style';

const noop = () => {};

export type MActionDialogProps = {
  visible: boolean;
  title: string;
  description: string;
  primaryActionLabel: string;
  primaryActionButtonType?: UButtonType;
  secondaryActionLabel?: string;
  showExitCross?: boolean;
  iconName?: IconName;
  children?: JSX.Element;
  style?: CSSProperties;

  onPrimaryAction: () => void;
  onSecondaryAction?: () => void;
  onClose?: () => void;
};

/**
 * Component used to force an action from the user. Ex: refresh the page.
 *
 * visible: controls visibility of the modal
 * title: shown at the top of the modal
 * description: shown in the middle of the modal. THIS SUPPORTS MARKDOWN
 * primaryActionLabel: text for the primary button
 * secondaryActionLabel: text for the secondary button
 * iconName: icon name for the icon shown in the primary button
 * style: css properties for the modal
 * onPrimaryAction: called when clicking on the primary button, should handle closing too
 * onSecondaryAction: called when clicking the secondary button
 */
export const MActionDialog = ({
  visible,
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  showExitCross,
  iconName,
  primaryActionButtonType = 'accentuated',
  children,
  style = {},

  onPrimaryAction,
  onSecondaryAction,
  onClose = noop,
}: MActionDialogProps) => {
  const modalStyle = useMemo(() => ({ ...styles.modal, ...style }), [style]);

  return (
    <MModal
      labelActionButton={primaryActionLabel}
      actionButtonLeftIcon={iconName}
      labelSecondButton={secondaryActionLabel}
      onAction={onPrimaryAction}
      onCloseModal={onClose}
      onSecondAction={onSecondaryAction}
      actionButtonType={primaryActionButtonType}
      showExitCross={showExitCross}
      title={title}
      type="dialog"
      visible={visible}
      style={modalStyle}
    >
      <div style={styles.contentWrapper}>
        <UMarkdown markdown={description} />
        {children}
      </div>
    </MModal>
  );
};

export default MActionDialog;
