import React, { useCallback, useState, useMemo, useEffect } from 'react';
import i18n, { t } from 'i18next';

import { CONTRIBUTOR_ACCESS_STATUS } from 'Utils/Enum';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import SPhoneInput, { PhoneNumberChangeParams } from 'Components/structural/SPhoneInput/SPhoneInput';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import SSelect from 'Components/structural/SSelect/SSelect';

import { tracking } from 'Services/trackingService';

import { useTypedDispatch } from 'Libs/redux/utils';

import { actions, useModuleSelector } from '../../../redux';

import { ContributorAdvancedOptionsForm } from '../ContributorAdvancedOptionsForm/ContributorAdvancedOptionsForm';
import { ContributorRoleForm } from '../ContributorRoleForm/ContributorRoleForm';

import styles from './ContributorManageModal.style';

type ValueChangeFeedbackStatus = 'loading' | 'warning' | 'error' | 'success' | 'none';

export type Props = {
  editorRoleId: number;
  is2FAActivated: boolean;
};

export type SelectedLanguage = {
  [key: string]: {
    id: number;
    label: string;
    value: string;
  };
};

export const ContributorManageModal = ({ editorRoleId, is2FAActivated }: Props) => {
  const [valueChangeFeedbackStatus, setValueChangeFeedbackStatus] = useState<ValueChangeFeedbackStatus>('none');
  const [valueChangeFeedbackMessage, setValueChangeFeedbackMessage] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const dispatch = useTypedDispatch();
  const labelError = useModuleSelector((state) => state.manageContributorModal.error);
  const visible = useModuleSelector((state) => state.manageContributorModal.isOpen.isManageModalOpen || false);
  const contributorToManage = useModuleSelector((state) => state.manageContributorModal.contributorToManage);
  const originalContributor = useModuleSelector((state) => state.manageContributorModal.originalContributor);
  const rolesAvailable = useModuleSelector((state) => state.rolesAvailable);
  const { id, firstName, lastName, status } = useMemo(() => contributorToManage, [contributorToManage]);
  const isCreation = !id;

  const [email, setEmail] = useState('');

  const handleUpdateEmail = useCallback((newEmail: string) => {
    setEmail(newEmail);
  }, []);

  const isActionButtonDisabled = contributorToManage.email === email;

  useEffect(() => {
    setValueChangeFeedbackStatus('none');
    setValueChangeFeedbackMessage('');
  }, [visible]);

  const isPhoneNumberFormVisible = useMemo(
    () => status === CONTRIBUTOR_ACCESS_STATUS.ACTIVE && is2FAActivated,
    [is2FAActivated, status],
  );

  let modalTitle = t('config_contributors:contributors.modal.invite_new');

  let labelActionButton = t('config_contributors:contributors.modal.send_invite');

  if (!isCreation) {
    modalTitle = firstName && lastName ? `${firstName} ${lastName}` : 'Edit contributor information';
    labelActionButton = 'Save';
  }

  const handlePhoneNumberError = useCallback(() => {
    setValueChangeFeedbackStatus('error');
    setValueChangeFeedbackMessage(t('config_contributors:contributors.modal.phone_number_not_valid'));
  }, [t]);

  const handleResetPhoneNumberError = useCallback(() => {
    setValueChangeFeedbackStatus('none');
    setValueChangeFeedbackMessage('');
  }, []);

  const getSelectedLanguage: SelectedLanguage = useMemo(
    () => ({
      'en-US': {
        id: 1,
        label: t('config_contributors:contributors.modal.english'),
        value: 'en-US',
      },
      'fr-FR': {
        id: 2,
        label: t('config_contributors:contributors.modal.french'),
        value: 'fr-FR',
      },
    }),
    [t],
  );

  const [role, setRole] = useState(0);
  const [language, setSelectedLanguage] = useState(getSelectedLanguage[i18n.language]);
  const { phoneNumber: currentPhoneNumber, phoneCountryCode: currentPhoneCountryCode } = useMemo(
    () => contributorToManage,
    [contributorToManage],
  );
  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber ? currentPhoneNumber : '');
  const [phoneCountryCode, setPhoneCountryCode] = useState(currentPhoneCountryCode ? currentPhoneCountryCode : '');

  const handleSaveContributor = useCallback(() => {
    if (isCreation) {
      tracking.contributorsConfirmInviteModal();
      return Promise.resolve(
        dispatch(
          actions.invite({
            email,
            editorRoleId,
            role: rolesAvailable.find((item) => item.id === role),
            language: language.value,
            contributorToManage,
          }),
        ),
      );
    }

    if (!isPhoneNumberValid && originalContributor.phoneNumber !== phoneNumber) {
      handlePhoneNumberError();

      return Promise.resolve();
    }

    return Promise.resolve(
      dispatch(
        actions.update({
          phoneNumber,
          phoneCountryCode,
          role: rolesAvailable.find((item) => item.id === role),
          contributorToManage,
        }),
      ),
    );
  }, [
    dispatch,
    isCreation,
    editorRoleId,
    isPhoneNumberValid,
    phoneNumber,
    handlePhoneNumberError,
    email,
    role,
    language,
    rolesAvailable,
    contributorToManage,
  ]);

  const handleSaveEnd = useCallback(
    (res: any) => {
      if (!res) {
        return null;
      }

      const { error } = res;

      if (!error) {
        setEmail('');
        setRole(0);
        setSelectedLanguage(getSelectedLanguage[i18n.language]);
        dispatch(actions.toggleContributorModal({ isManageModalOpen: false }));

        return dispatch(actions.fetchAll());
      }

      return null;
    },
    [dispatch],
  );

  const errorMap = useCallback(
    (message) => {
      if (message === 'Please use the following format: email@domain.com.')
        return t('config_contributors:contributors.modal.email_error');
      else if (message === labelError) {
        return t('config_contributors:contributors.modal.email_already_used', { email: labelError.split(' ')[0] });
      }

      return t('config_contributors:contributors.alert.types.fetch_all_error');
    },
    [labelError],
  );

  const handleCloseModal = useCallback(() => {
    return dispatch(actions.toggleContributorModal({ isManageModalOpen: false }));
  }, [dispatch]);

  const handleLanguageSelection = useCallback((selection) => {
    setSelectedLanguage(selection);
  }, []);

  const languageOptions = Object.values(getSelectedLanguage);

  const canUpdate = contributorToManage.id;

  const onPhoneNumberChange = useCallback(
    (phoneNumberInformation: PhoneNumberChangeParams) => {
      const { value, phoneCountryCode, isValid } = phoneNumberInformation;

      if (valueChangeFeedbackStatus !== 'none') {
        handleResetPhoneNumberError();
      }

      setIsPhoneNumberValid(isValid);

      setPhoneNumber(value);
      setPhoneCountryCode(phoneCountryCode);
    },
    [dispatch, valueChangeFeedbackStatus],
  );

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleSaveContributor}
      onActionEnd={handleSaveEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={modalTitle}
      labelActionButton={labelActionButton}
      labelSecondButton={t('config_contributors:contributors.modal.cancel')}
      actionButtonType="accentuated"
      disableActionButton={isActionButtonDisabled}
      labelError={labelError ? errorMap(labelError) : ''}
    >
      <div>
        {/* If contrib cannot update, you can still invite */}
        {!canUpdate && (
          <SInput
            value={email}
            placeholder="email@domain.com"
            type="small"
            label="Email"
            onChange={handleUpdateEmail}
            style={styles.emailInput}
          />
        )}
        <ContributorRoleForm selectedRole={role} onChangeRole={setRole} />
        {/* If contrib cannot update, you can still invite */}
        {!canUpdate && (
          <SSelect
            type="small"
            label={t('config_contributors:contributors.modal.contributor_language')}
            placeholder={t('config_contributors:contributors.modal.select_item')}
            selected={language}
            style={styles.languageSelect}
            items={languageOptions}
            onSelect={handleLanguageSelection}
          />
        )}
        {isPhoneNumberFormVisible && (
          <SPhoneInput
            defaultCountry={currentPhoneCountryCode ? currentPhoneCountryCode.toLowerCase() : ''}
            defaultPhoneNumber={currentPhoneNumber}
            valueChangeFeedbackStatus={valueChangeFeedbackStatus}
            valueChangeFeedbackMessage={valueChangeFeedbackMessage}
            onChange={onPhoneNumberChange}
            label={t('config_contributors:contributors.modal.phone_number')}
            style={styles.phoneInput}
          />
        )}
        <UTextLink
          text={t('config_contributors:contributors.modal.learn')}
          link={t('config_contributors:contributors.modal.add_admin_link')}
          rightIcon="external-link"
          style={styles.learnAboutLink}
        />
        <ContributorAdvancedOptionsForm />
      </div>
    </MModal>
  );
};
