import { apiAction } from 'Libs/redux/utils';
import { AuthenticationSteps } from 'Models/Enum';
import { createSessionAfterApiAction } from 'Services/session.service';

import type { ApiActionType } from 'Libs/redux/utils';

type AdminLoginAction = ApiActionType<'ADMIN_LOGIN'>;

type AdminLoginSetEmail = {
  type: 'ADMIN_LOGIN_SET_EMAIL';
  email: string;
};

export const actions = {
  setEmail: (email: string): AdminLoginSetEmail => ({
    type: 'ADMIN_LOGIN_SET_EMAIL',
    email,
  }),
  login: (email: string, password: string): AdminLoginAction => {
    const action = apiAction({
      type: 'ADMIN_LOGIN',
      route: '/api/authentication/contributors/session',
      method: 'POST',
      payload: { email, password },
    });

    // This is the only place to do that because we are mocking store in page so all the store behavior are tested in reducer.test
    // @ts-expect-error async action return a vanilla promise type instead of bluebird
    action.promise?.tap?.((res) => {
      const { type, token, user } = res;

      return type === AuthenticationSteps.USER_SESSION ? createSessionAfterApiAction(user, token) : Promise.resolve();
    });

    return action;
  },
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type LoginAction = ReturnType<ActionsFn>;
