import { TYPOGRAPHY, COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  containerName: {
    backgroundColor: COLORS.PRIMARY.DEFAULT,
    opacity: 0.5,
    borderRadius: '50%',
    height: 38,
    width: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  initial: {
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: COLORS.WHITE.DEFAULT,
    WebkitUserSelect: 'none',
  },
});

export default styles;
