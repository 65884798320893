import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils';
import { GetContributionInfosResponse } from './types';

export const getContributionInfos = createAsyncAction('homepage/getContributionInfos', () => {
  const { fetchFn } = fetchFactory<GetContributionInfosResponse>({
    route: '/api/onboarding/contributors-tutorial/infos',
    method: 'GET',
  });

  return fetchFn();
});
