import React from 'react';
import { t } from 'i18next';

import SSummary from 'Components/structural/SSummary/SSummary';
import { useActionDialogConfirmation } from 'Hooks/useActionDialogConfirmation/useActionDialogConfirmation';
import { TYPOGRAPHY } from 'Components/foundation';

export const useRandomCampaignActivationConfirmation = () => {
  const { askForConfirmation, dialog } = useActionDialogConfirmation({
    primaryActionLabel: t('activities:modals.activate_random_campaign.button'),
    secondaryActionLabel: t('activities:modals.activate_random_campaign.cancel'),
    title: t('activities:modals.activate_random_campaign.title'),
    description: t('activities:modals.activate_random_campaign.description'),
    primaryActionButtonType: 'warning',
    style: { width: 520, ...TYPOGRAPHY.BODY2 },
    showExitCross: true,
    children: (
      <div style={{ ...TYPOGRAPHY.BODY2, marginTop: 16 }}>
        <SSummary
          title={t('activities:modals.activate_random_campaign.summary.title')}
          showHeaderIcon={false}
          items={[
            {
              text: t('activities:modals.activate_random_campaign.summary.warning1'),
              valid: false,
              icon: 'delete-circled-outline',
            },
            {
              text: t('activities:modals.activate_random_campaign.summary.warning2'),
              valid: false,
              icon: 'delete-circled-outline',
            },
            {
              text: t('activities:modals.activate_random_campaign.summary.warning3'),
              valid: false,
              icon: 'delete-circled-outline',
            },
            {
              text: t('activities:modals.activate_random_campaign.summary.warning4'),
              valid: true,
              icon: 'check-circled-outline',
            },
          ]}
        />
        <p style={{ ...TYPOGRAPHY.BODY3, marginTop: 8 }}>
          {t('activities:modals.activate_random_campaign.disclaimer')}
        </p>
      </div>
    ),
  });

  return {
    askForConfirmation,
    dialog,
  };
};
