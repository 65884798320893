// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    marginBottom: 40,
  },
  title: {
    ...TYPOGRAPHY.BODY1,
    marginBottom: 20,
  },
  asterisk: {
    color: '#FD4E5A',
  },
};
