// @flow

import React, { useCallback, useMemo } from 'react';
import { t } from 'i18next';

import withAlert from 'Components/hoc/withAlert/withAlert';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { AlertScope, AlertParams } from '../redux/models/Alert';
import { actions, useModuleSelector } from '../redux';

import styles from './NPSAlertView.style';

type Props = {|
  component: React$AbstractComponent<any, any>,
|};

const ALL_SCOPES: Array<AlertScope> = ['getStatsError', 'getReportError'];

export const IDS: { [AlertScope]: number } = ALL_SCOPES.reduce((acc, x, i) => {
  return { ...acc, [(x: any)]: i };
}, {});

export const SCOPES: { [number]: AlertScope } = ALL_SCOPES.reduce((acc, x, i) => {
  return { ...acc, [i]: x };
}, {});

const TYPES = {
  getStatsError: 'error',
  getReportError: 'error',
};

export const makeAlertMessage = (scope: AlertScope, params: AlertParams) => {
  const message = params.message || '';

  switch (scope) {
    case 'getStatsError':
      return t('analytics:nps.alert_view.error_while_fetching', { message });
    case 'getReportError':
      return t('analytics:nps.alert_view.error_while_generating', { message });

    default:
      return '';
  }
};

const makeAlertsFromStateAlerts = (alerts, TITLES) =>
  alerts.map((alert) => ({
    id: IDS[alert.scope],
    title: TITLES[alert.scope],
    message: makeAlertMessage(alert.scope, alert.params),
    icon: TYPES[alert.scope] === 'success' ? 'success-circle' : 'alert',
    type: TYPES[alert.scope],
  }));

const TITLES = {
  getStatsError: t('analytics:nps.alert_view.something_went_wrong'),
  getReportError: t('analytics:nps.alert_view.something_went_wrong'),
};

export const NPSAlertView = ({ component }: Props) => {
  const stateAlerts = useModuleSelector((state) => state.alerts);

  const alerts = useMemo(() => makeAlertsFromStateAlerts(stateAlerts, TITLES), [stateAlerts, TITLES]);

  const dispatch = useTypedDispatch();
  const onClose = useCallback((id) => dispatch(actions.removeAlert(SCOPES[id])), [dispatch]);

  const AlertHOC = useMemo(() => {
    return withAlert({ wrapperStyle: styles.wrapper })(component);
  }, [component]);

  return <AlertHOC alerts={alerts} onClose={onClose} />;
};
