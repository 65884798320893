import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { activityAdapter } from './activity.selectors';
import { ActivitySearchResponse, ActivityState } from './activity.type';
import { fetchNextActivityPage, searchActivities } from './activity.thunks';

export const activityState: ActivityState = activityAdapter.getInitialState({
  search: {
    total: 0,
    loading: false,
    next: null,
    error: undefined,
  },
});

export const { actions: activityActions, reducer: activityReducer } = createSlice({
  name: 'activity',
  initialState: activityState,
  reducers: {},
  extraReducers: (builder) => {
    const handlePendingSearch = (state: ActivityState) => {
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          error: undefined,
        },
      };
    };

    builder.addCase(searchActivities.pending, handlePendingSearch);
    builder.addCase(fetchNextActivityPage.pending, handlePendingSearch);

    const handleSearchRejection = (state: ActivityState, error: any) => {
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          error: error?.error?.message || 'unknown error',
        },
      };
    };

    builder.addCase(searchActivities.rejected, handleSearchRejection);
    builder.addCase(fetchNextActivityPage.rejected, handleSearchRejection);

    const handleSearchFulfillment = (state: ActivityState, { payload }: PayloadAction<ActivitySearchResponse>) => {
      const { items, offset } = payload;

      const newState: ActivityState = {
        ...state,
        search: {
          total: payload.total,
          next: payload.next,
          loading: false,
          error: undefined,
        },
      };

      return offset === 0 ? activityAdapter.setAll(newState, items) : activityAdapter.setMany(newState, items);
    };

    builder.addCase(searchActivities.fulfilled, handleSearchFulfillment);
    builder.addCase(fetchNextActivityPage.fulfilled, handleSearchFulfillment);
  },
});
