import _ from 'lodash';
import { t } from 'i18next';

import Enum from 'models/Enum';
import Model from 'models/Model';

var QCM = {
  endpoint  : 'api/QCMs',
  attributes: {
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    qcmChoices: {
      type : 'collection',
      Model: 'QCMChoice',
    },
    choices: {
      type : 'collection',
      Model: 'GameItem',
    },
    answers: {
      type : 'collection',
      Model: 'GameItem',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    imageFront: {
      type : 'pointer',
      Model: 'Image',
    },
    imageBack: {
      type : 'pointer',
      Model: 'Image',
    },
    videoFront: {
      type : 'pointer',
      Model: 'Video',
    },
    videoBack: {
      type : 'pointer',
      Model: 'Video',
    },
    mediaTypeFrontId: {
      type: 'number',
    },
    mediaTypeBackId: {
      type: 'number',
    },
    formatId: {
      type: 'number',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_QCM, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },
    getNbrightAnswer: function getNbrightAnswer() {
      return this.qcmChoices().filter(function isRightAnswer(choice) {
        return choice.rightAnswer();
      }).length;
    },
    getNbChoicesRequired: function getNbChoicesRequired() {
      switch (this.formatId()) {
        case Enum.QCMFormat.twoSBS:
        case Enum.QCMFormat.twoUpDown:
          return 2;
        case Enum.QCMFormat.three:
          return 3;
        case Enum.QCMFormat.four:
          return 4;
        default:
          return 3;
      }
    },
    clearAnswers: function clearAnswers() {
      this.answers().items = [];

      return this;
    },

    mediaFront: function mediaFront(_QCM, media) {
      if (!media)
        return this.getMedia('Front');

      return this.setMedia('Front', media);
    },

    mediaBack: function mediaBack(_QCM, media) {
      if (!media)
        return this.getMedia('Back');

      return this.setMedia('Back', media);
    },

    getMedia: function getMedia(_QCM, panel) {
      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel]();

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel]();
    },

    setMedia: function setMedia(_QCM, panel, media) {
      var mediaType = media._type;

      this['mediaType' + panel + 'Id'](Enum.mediaType[mediaType]);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel](media);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel](media);
    },

    toggleAnswer: function toggleAnswer(_QCM, gameItem) {
      var answer = this.answers().get(gameItem.id());

      console.log('[QCM]', 'Setting as answer', gameItem);

      if (!answer)
        this.answers().push(gameItem);
      else
        this.answers().removeAt(answer._i);
    },

    isRightAnswer: function isRightAnswer(_QCM, gameItem) {
      if (!gameItem.id())
        return false;

      return !!this.answers().get(gameItem.id());
    },

    replaceChoice: function replaceChoice(_Content, index, replacing) {
      if (this.qcmChoices().at(index))
        this.qcmChoices().replaceAt(index, replacing);
      else
        this.qcmChoices().push(replacing);
    },

    validate: function validate() {
      if (this.qcmChoices().length < 2)
        throw new Error(t('gameplays:validation.at_least_two_qcm'));

      for (var i = 0, l = this.qcmChoices().length; i < l; i++) {
        if (!this.qcmChoices().at(i).gameItem().data())
          throw new Error(t('gameplays:validation.qcm_missing_answer', {index: i + 1}));
      }

      if (this.qcmChoices().filter(function filterAnswers(choice) {
        return choice.rightAnswer();
      }).length < 1)
        throw new Error(t('gameplays:validation.at_least_one_correct'));

      if (!this.explanation().data())
        throw new Error(t('gameplays:validation.explanation_missing'));
      if (!this.question().data())
        throw new Error(t('gameplays:validation.question_missing'));
      if (!this.mediaFront() || !this.mediaFront().url())
        throw new Error(t('gameplays:validation.first_media_missing'));
      if (!this.mediaBack() || !this.mediaBack().url())
        throw new Error(t('gameplays:validation.second_media_missing'));

      return true;
    },

    countUseOfGameItem: function countUseOfGameItem(_QCM, giId) {
      var count = 0;

      count += countInCollection(this.qcmChoices());
      if (this.question().id() === giId)
        count += 1;
      if (this.explanation().id() === giId)
        count += 1;

      return count;

      function countInCollection(collection) {
        var iteration = collection.filter(function checkId(item) {
          return item.id() === giId;
        });

        return iteration.length;
      }
    },

    deleteGameItem: function deleteGameItem(_QCM, giId) {
      var GameItem = Model('GameItem');

      deleteInCollection(this.qcmChoices());
      deleteInCollection(this.answers());

      if (this.question().id() === giId)
        this.question(new GameItem());
      if (this.explanation().id() === giId)
        this.question(new GameItem());

      function deleteInCollection(collection) {
        collection.searchAndRemove({
          id: giId,
        });
      }
    },
    presetFromKnowledge: function presetFromKnowledge(_QCM, knowledge) {
      var sqcm;
      var openQuestion;

      this.explanation().data(knowledge.explanation().data());
      sqcm = knowledge.getContent(Enum.gameplay.SQCM);

      if (sqcm)
        return this.presetFromSQCM(sqcm, knowledge);

      openQuestion = knowledge.getContent(Enum.gameplay.OPEN_QUESTION);

      if (openQuestion)
        return this.presetFromOpenQuestion(openQuestion, knowledge);

      return null;
    },
    presetFromSQCM: function presetFromSQCM(_QCM, sqcm, knowledge) {
      this.question().data(sqcm.question().data());

      this.qcmChoices().forEach(function presetIfNeeded(choice, key) {
        var sqcmChoice = sqcm.sqcmChoices().at(key);

        if (sqcmChoice) {
          choice.gameItem().data(sqcmChoice.gameItem().data());
          choice.rightAnswer(sqcmChoice.rightAnswer());
        }
      });

      this.explanation().data(sqcm.explanation().data());
      this.formatId(Enum.QCMFormat.three);

      this.mediaTypeFrontId(sqcm.mediaTypeFrontId());
      this.mediaTypeBackId(sqcm.mediaTypeBackId());
      this.imageFront(sqcm.imageFront());
      this.imageBack(sqcm.imageBack());
      this.videoFront(sqcm.videoFront());
      this.videoBack(sqcm.videoBack());
    },
    presetFromOpenQuestion: function presetFromOpenQuestion(_QCM, openQuestion, knowledge) {
      this.question().data(openQuestion.question().data());
      if (this.qcmChoices().at(0)) {
        this.qcmChoices().at(0).gameItem().data(openQuestion.answer().data());
        this.qcmChoices().at(0).rightAnswer(true);
      }

      this.explanation().data(openQuestion.explanation().data());
      this.formatId(Enum.QCMFormat.twoUpDown);
      this.mediaTypeFrontId(openQuestion.mediaTypeFrontId());
      this.mediaTypeBackId(openQuestion.mediaTypeBackId());
      this.imageFront(openQuestion.imageFront());
      this.imageBack(openQuestion.imageBack());
      this.videoFront(openQuestion.videoFront());
      this.videoBack(openQuestion.videoBack());
    },
    getDirective: function getDirective(c, args) {
      var self = this;

      if (!this.mediaFront() || !this.mediaFront().url())
        return t('gameplays:directives.add_media');
      if (!this.mediaBack() || !this.mediaBack().url())
        return t('gameplays:directives.add_media_explanation');
      if (!self.question().data())
        return t('gameplays:directives.add_question');
      if (!self.formatId())
        return t('gameplays:directives.answer_format');
      if (allAnswersCompleted() !== self.qcmChoices().length)
        return t('gameplays:directives.fill_answers');
      if (!countAnswers())
        return t('gameplays:directives.one_correct_answer');
      if (!self.explanation().data())
        return t('gameplays:directives.add_explanation');

      return null;

      function allAnswersCompleted() {
        return self.qcmChoices().filter(function isComplete(choice) {
          return choice.gameItem().data();
        }).length;
      }

      function countAnswers() {
        return self.qcmChoices().filter(function isComplete(choice) {
          return choice.rightAnswer();
        }).length;
      }
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_QCM, knowledgeId) {
      return {
        question: {
          typeId     : Enum.gameItemTypes.QUESTION,
          knowledgeId: knowledgeId,
        },
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
};

export default Model.register('QCM', QCM);
