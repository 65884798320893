/**
 * Displays an icon profile with user initials
 *
 * Props:
 * - onClick: Function called on click
 * - initial: initial of the current user
 * - style: can override the component's style
 */

import React, { useMemo } from 'react';
import type { CSSProperties, FunctionComponent, MouseEvent } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './UProfile.style';

export type UProfileProps = {
  onClick: (event: MouseEvent) => void;
  initials: string;
  style?: CSSProperties;
};

const MAX_INITIALS_LENGTH = 3;

export const UProfile: FunctionComponent<UProfileProps> = ({ onClick, initials, style: propStyle = {} }) => {
  const initialsLimitedAt3Charaters = useMemo(() => initials.substring(0, MAX_INITIALS_LENGTH), [initials]);

  return (
    <div style={{ ...styles.container, ...propStyle }} onClick={onClick} id="u-profile-container">
      <div style={styles.containerName}>
        <span style={styles.initial}>{initialsLimitedAt3Charaters}</span>
      </div>
      <UIcon name="bottom-chevron" color={COLORS.TEXT.SECONDARY_DEFAULT} size={9} />
    </div>
  );
};

export default UProfile;
