import { handle } from 'redux-pack';

import { getErrorMessage } from 'Libs/redux/utils';
import { mergeDeep } from 'Libs/mergeDeep';

import type { Action } from 'Libs/redux/types';
import type { AlertType } from './models/Alert';

export type TranslatedItem = {
  id: number;
  value: string;
};

export type KnowledgeTranslationState = {
  // Loader
  loadingSave: boolean;
  loadingAutoTranslate: boolean;
  loadingValidation: boolean;

  translatedItems: ReadonlyArray<TranslatedItem>;

  // Alerts
  alerts: ReadonlyArray<AlertType>;
};

export const defaultState: KnowledgeTranslationState = {
  loadingSave: false,
  loadingAutoTranslate: false,
  loadingValidation: false,
  alerts: [],
  translatedItems: [],
};

const ERROR_SCOPE = {
  KNOWLEDGE_TRANSLATION_SAVE: 'saveError',
  KNOWLEDGE_TRANSLATION_VALIDATE: 'validateError',
  KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE: 'autoTranslateError',
} as const;

const SUCCESS_SCOPE = {
  KNOWLEDGE_TRANSLATION_SAVE: 'saveSuccess',
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: KnowledgeTranslationState = defaultState, action: Action): KnowledgeTranslationState => {
  switch (action.type) {
    case 'KNOWLEDGE_TRANSLATION_SAVE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            loadingSave: true,
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE.KNOWLEDGE_TRANSLATION_SAVE)
              .filter(({ scope }) => scope !== SUCCESS_SCOPE.KNOWLEDGE_TRANSLATION_SAVE),
          }),
        success: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: SUCCESS_SCOPE.KNOWLEDGE_TRANSLATION_SAVE,
              params: {},
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE.KNOWLEDGE_TRANSLATION_SAVE,
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loadingSave: false }),
      });

    case 'KNOWLEDGE_TRANSLATION_VALIDATE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            loadingValidation: true,
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE.KNOWLEDGE_TRANSLATION_VALIDATE),
          }),
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE.KNOWLEDGE_TRANSLATION_VALIDATE,
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loadingValidation: false }),
      });

    case 'KNOWLEDGE_TRANSLATION_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            loadingAutoTranslate: true,
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE.KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE),
          }),
        success: (prevState) => {
          const { results } = action.payload;

          return mergeDeep(prevState, { translatedItems: results });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE.KNOWLEDGE_TRANSLATION_AUTO_TRANSLATE,
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loadingAutoTranslate: false }),
      });

    case 'KNOWLEDGE_TRANSLATION_RESET':
      return defaultState;

    default:
      return state;
  }
};
