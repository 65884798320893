import React, { FC, useState } from 'react';
import { t } from 'i18next';
import m from 'mithril';

import MActivityCreationModal from 'Components/modal/MActivityCreationModal/MActivityCreationModal';
import { getActivitiesDefinition } from 'Pages/ActivityList/ActivitiesDescription';
import Enum from 'Models/Enum';

export type CreateActivityModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const CreateActivityModal: FC<CreateActivityModalProps> = ({ visible, onClose }) => {
  const [isChoosingActivityType, setIsChoosingActivityType] = useState(true);

  const disableActionButton = false;
  const onCreate = (type: number) => {
    if (type === Enum.activityTypeEnum.DAILY_SERIE) {
      if (isChoosingActivityType) {
        setIsChoosingActivityType(false);

        return;
      }

      // TODO: dispatch campaign creation here

      return;
    }

    const newUrl = {
      // TODO: replace with correct dolphin urls
      [Enum.activityTypeEnum.WELCOME]: '/activity/welcome/new',
      [Enum.activityTypeEnum.INTERSEASON]: '/activity/interseason/new',
      [Enum.activityTypeEnum.BREAKING_NEWS]: '/activity/breakingnews/new',
      [Enum.activityTypeEnum.THATS_ALL_FOLKS]: '/activity/thatsAllFolks/new',
    };

    m.route(newUrl[type]);

    onClose();
  };

  const onSecondAction = () => {
    onClose();
    setIsChoosingActivityType(true);
  };

  return (
    <MActivityCreationModal
      visible={visible}
      isLoading={false}
      onClose={onClose}
      activities={getActivitiesDefinition()}
      labelTab={t('activities:modals.activity_creation_modal.tab_label')}
      labelSecondButton={t('activities:modals.activity_creation_modal.cancel')}
      labelActionButton={
        isChoosingActivityType
          ? t('activities:modals.activity_creation_modal.action1')
          : t('activities:modals.activity_creation_modal.action2')
      }
      labelNextAction={t('activities:modals.activity_creation_modal.next')}
      title={
        isChoosingActivityType
          ? t('activities:modals.activity_creation_modal.title1')
          : t('activities:modals.activity_creation_modal.title2')
      }
      onCreate={onCreate}
      onSecondAction={onSecondAction}
      isFirstStep={isChoosingActivityType}
      disableActionButton={disableActionButton}
    >
      <div>Campaign creation form goes here</div>
    </MActivityCreationModal>
  );
};
