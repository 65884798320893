// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';
import STextArea from 'Components/structural/STextArea/STextArea';

import { actions, useDefaultActivity, useSelectedActivity } from '../../../redux';

import styles from './DailySerieDescriptionForm.style';


export const DailySerieDescriptionForm = () => {
  const DEFAULT_PLACEHOLDER = t('activities:overview.form.description_input_placeholder');
  const defaultActivity = useDefaultActivity();
  const selectedActivity = useSelectedActivity();

  const placeholder = selectedActivity.isDefault
    ? DEFAULT_PLACEHOLDER
    : defaultActivity.description || DEFAULT_PLACEHOLDER;

  const dispatch = useTypedDispatch();
  const onChange = useCallback(value => dispatch(actions.setDescription(value)), [dispatch]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>
        {t('activities:overview.form.description_input_title')}
        <span style={styles.asterisk}>
          {'*'}
        </span>
      </div>

      <STextArea
        height={100}
        placeholder={placeholder}
        maxLength={120}
        value={selectedActivity.description}
        onChange={onChange}
      />
    </div>
  );
};
