/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Display a dropDown box
 *
 * Props:
 *  - items: Array containing the name & props of all component we will display
 *  - renderItem: Function call for each items
 *  - style: override component's style
 */

import * as React from 'react';
import { List } from 'react-virtualized';

import styles from './UDropdownBox.style';


type Props = {|

  // required
  items: $ReadOnlyArray<Object>,
  renderItem: (any, any) => React.Element<any>,

  // optional
  style: ?Object,
  virtualized?: boolean,
  itemHeight?: number,
|};

export class UDropdownBox extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
    virtualized: false,
    itemHeight: 0,
  };

  render() {
    const { style, items, virtualized, renderItem, itemHeight } = this.props;

    const contents = virtualized ? (
      <List

        // INFO Set list height smaller than parent to prevent additional scroll bar
        height={340}
        width={350}
        rowCount={items.length}
        rowHeight={itemHeight}
        rowRenderer={this.rowRenderer}
      />
    ) : items.map(renderItem);

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        {contents}
      </div>
    );
  }

  rowRenderer = ({ key, index, style }: { key: number, index: number, style: any }) => {
    const { items, renderItem } = this.props;

    return (
      <div key={key} style={style}>
        {renderItem(items[index])}
      </div>
    );
  };
}

export default UDropdownBox;
