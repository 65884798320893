import m from 'm';
import contentPreviewQuestion from 'components/content.preview.question';

var component = {};

component.view = function view(c, args) {
  var question = '';
  var image = args.content.game().image().cdn();

  if (args.content.game().spots().items.length > 0)
    question = args.content.game().spots().items[0].question().data();

  return m('.preview-picture-spot', [
    m('.preview-picture-spot__image', {
      style: {
        backgroundImage: 'url(' + image + ')',
      },
    }),
    m(contentPreviewQuestion, {
      question: question,
    }),
  ]);
};
export default component;
