import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  audienceGroupList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 24,
    marginTop: 44,
    marginBottom: 32,
  },
  nextBatch: {
    marginBottom: 32,
  },
  loadingContainer: {
    margin: '64px auto',
  },
});

export default styleSheet;
