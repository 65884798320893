// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';


export default {
  wrapperProgress: {
    display: 'flex',
  },
  containerProgress: {
    display: 'flex',
    paddingTop: 4,
    paddingBottom: 3,
    height: 13,
    width: '100%',
  },
  progressBackground: {
    height: 6,
    width: '100%',
    borderRadius: 2,
    overflow: 'hidden',
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  progress: {
    height: 6,
    backgroundColor: COLORS.PRIMARY.DEFAULT,
  },
  textPercent: {
    ...TYPOGRAPHY.CAPTION,
    marginLeft: 6,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  label: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: 4,
    textOverflow: 'ellipsis',
    height: 15,
  },
};
