import Promise from 'bluebird';
import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { tracking } from 'Services/trackingService';

import SInput from 'Components/structural/SInput/SInput';

import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import UCheckbox from 'Components/unit/UCheckbox/UCheckbox';
import STooltip from 'Components/structural/STooltip/STooltip';
import UTextCountdownWithLoading from 'Components/unit/UTextCountdownWithLoading/UTextCountdownWithLoading';

import { useModuleSelector } from 'Pages/TwoFactor/redux/hooks';
import { actions } from 'Pages/TwoFactor/redux/actions';

import { AuthenticationContext } from 'Models/Enum';

import LandingPageWrapper from '../LandingPageWrapper/LandingPageWrapper';

import styles from './CodeForm.style';

// TODO: remove this when UCheckbox is migrated to ts
type CheckBoxStatusType = 'checked' | 'unchecked' | 'mixed';

export type CodeFormProps = {
  onSwitchToPhone: () => void;
};

const EXPECTED_CODE_LENGTH = 6;

export const CodeForm = ({ onSwitchToPhone }: CodeFormProps) => {
  const dispatch = useDispatch();
  const currentPhoneNumber = useModuleSelector((state) => state.phoneNumber);
  const twoFactorToken = useModuleSelector((state) => state.twoFactorToken);
  const requestError = useModuleSelector((state) => state.error);
  const context = useModuleSelector((state) => state.context);

  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [trustStatus, setTrustStatus] = useState<CheckBoxStatusType>('checked');

  const stripRegex = useMemo(() => /[^\d]/g, []);
  const isLoginContext = useMemo(() => context === AuthenticationContext.LOGIN, [context]);

  const instruction = useMemo(
    () =>
      isLoginContext
        ? 'The double authentication has been activated on your instance. We just sent you a validation code by SMS to: '
        : 'We just sent you a validation code by SMS to: ',
    [isLoginContext],
  );

  const canEditPhoneNumber = useModuleSelector((state) => state.invitationToken.length > 0);

  const handleCodeInputChange = useCallback((value: string) => {
    setIsCodeValid(value.length === EXPECTED_CODE_LENGTH);
    setCode(value);
  }, []);

  const handleLogin = useCallback(() => {
    if (!isCodeValid) {
      return Promise.resolve();
    }

    const isDeviceTrusted = trustStatus === 'checked';
    const deviceInfo = isDeviceTrusted ? window.navigator.userAgent : undefined;
    const verifyCodeAction = actions.verifyCode(twoFactorToken, code, isDeviceTrusted, deviceInfo);

    dispatch(verifyCodeAction);

    return Promise.resolve(verifyCodeAction.promise);
  }, [dispatch, code, isCodeValid, trustStatus, twoFactorToken]);

  const handleLoginEnd = useCallback(async (res) => {
    const { error: payloadError } = res;

    if (payloadError) {
      return;
    }

    const event = new Event('user.login');

    await document.dispatchEvent(event);

    tracking.contributorsSignUp();

    window.location.reload();
  }, []);

  const handleResendCode = useCallback(() => {
    const action = dispatch(actions.resendCode(twoFactorToken));

    return Promise.resolve(action.promise);
  }, [dispatch, twoFactorToken]);

  const onClickEditPhoneNumber = useCallback(() => {
    // reset phone number on mount
    // to prevent mixing it up with stripped phone number coming from the server
    dispatch(actions.setPhoneNumber(''));
    onSwitchToPhone();
  }, [dispatch, onSwitchToPhone]);

  const onTrustChange = useCallback((newStatus) => setTrustStatus(newStatus), []);

  return (
    <LandingPageWrapper>
      <div style={styles.formWrapper}>
        <div style={styles.instructionWrapper}>
          <span style={styles.instruction}>{instruction}</span>
          <span style={styles.phoneNumber}>{currentPhoneNumber}</span>
        </div>

        {canEditPhoneNumber ? (
          <UTextLink text="Edit phone number" type="accentuated" marginLeft={0} onClick={onClickEditPhoneNumber} />
        ) : (
          <div>
            <span style={styles.instruction}>Make sure your phone is online. </span>
            <div data-for="no-code-received-tooltip" data-tip>
              <STooltip
                id="no-code-received-tooltip"
                position="right"
                content="Please contact an administrator for further help"
                maxWidth={255}
              />
              <span>No code received?</span>
            </div>
          </div>
        )}

        <div style={styles.codeInputContainer}>
          <SInput
            value={code}
            type="large"
            placeholder="123456"
            label="Enter the code"
            regex={stripRegex}
            onChange={handleCodeInputChange}
            autoComplete="off"
            maxLength={EXPECTED_CODE_LENGTH}
            hideCounter
            errorMessage={requestError}
            style={styles.codeInput}
          />
          <UTextCountdownWithLoading
            countdownFrom={15}
            countdownOnFirstRender
            onClick={handleResendCode}
            text="Resend code"
            type="accentuated"
            style={styles.resendCode}
          />
        </div>

        <div style={styles.trustChoiceWrapper}>
          <UCheckbox onClick={onTrustChange} checkBoxStatus={trustStatus} type="accentuated" />
          <span style={styles.trustChoiceInstruction}>Trust this browser</span>
        </div>

        <div style={styles.buttonWrapper}>
          <UButtonWithLoading
            text="Login"
            onClick={handleLogin}
            onRequestEnd={handleLoginEnd}
            type="accentuated"
            disabled={!isCodeValid}
            style={styles.submitButton}
          />
        </div>
      </div>
    </LandingPageWrapper>
  );
};

export default CodeForm;
