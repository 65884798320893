/**
 * Page header displaying a title, optional subtitle and clickable icon
 *
 * Props:
 *  - title: string to display as a title
 *  - subtitle: string to display as a subtitle
 *  - type: type of the page header ('accentuated' | 'standard' | 'compact')
 *  - iconProps: object containing the name of the icon to display, the icon type, disabled, ghost booleans and the functions to trigger onClick and onRequestEnd
 *  - style: to override component's style
 */

import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UIconButtonWithLoading from 'Components/unit/UIconButton/UIconButtonWithLoading';
import { TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import UIcon from 'Components/unit/UIcon/UIcon';
import UButton from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import type { UIconButtonType, UIconButtonSize } from 'Components/unit/UIconButton/UIconButton';

import styles from './SPageHeader.style';

export type SPageHeaderType = 'accentuated' | 'standard' | 'compact';
export type SubtitleSizeType = 'S' | 'M' | 'L';

export type IconPropsType = {
  iconName: IconName;
  onIconClick: () => any;
  iconType?: UIconButtonType;
  disabled?: boolean;
  ghost?: boolean;
  onRequestEnd?: () => void;
};

export type SPageHeaderProps = {
  title: string;
  buttonLabel?: string;
  dynamicText?: string;
  subtitle?: string;
  subtitleIcon?: IconName;
  subtitleSize?: SubtitleSizeType;
  subtitleCustomStyle?: CSSProperties;
  horizontal?: boolean;
  type?: SPageHeaderType;
  iconProps?: IconPropsType;
  style?: CSSProperties;
};

export type PageHeaderIconType = {
  buttonLabel: string;
  iconProps: IconPropsType;
  iconSize: UIconButtonSize;
  iconStyle: CSSProperties;
};

const { TITAN_L_SEMIBOLD, TITAN_XL_BOLD, HERO_S_SEMIBOLD, TITAN_M, BODY3, H2 } = TYPOGRAPHY;

const TYPE_STYLE = {
  accentuated: {
    iconSize: 'M',
    titleTypo: TITAN_XL_BOLD,
    subtitleTypo: TITAN_M,
    subtitleMargin: {
      S: 10,
      M: 10,
      L: 8,
    },
  },
  standard: {
    iconSize: 'M',
    titleTypo: TITAN_L_SEMIBOLD,
    subtitleTypo: TITAN_M,
    subtitleMargin: {
      S: 6,
      M: 5,
      L: 3,
    },
  },
  compact: {
    iconSize: 'S',
    titleTypo: HERO_S_SEMIBOLD,
    subtitleTypo: BODY3,
    subtitleMargin: {
      S: 3,
      M: 1,
      L: -1,
    },
  },
} as const;

const SUBTITLE_SIZE_STYLE = {
  S: BODY3,
  M: H2,
  L: TITAN_M,
} as const;

type ButtonProps = {
  buttonLabel: string;
  onRequestEnd?: () => void;
  commonProps: {
    icon: IconName;
    type?: UIconButtonType;
    onClick: () => Promise<unknown>;
    size: UIconButtonSize;
    style: CSSProperties;
    ghost?: boolean;
    disabled?: boolean;
  };
};

const DynamicButton = ({ buttonLabel = '', commonProps, onRequestEnd }: ButtonProps) => {
  if (
    buttonLabel &&
    // UIconButtonType and UButtonType props are different
    commonProps.type !== 'standard-dark' &&
    commonProps.type !== 'standard-light' &&
    commonProps.type !== 'validation' &&
    commonProps.type !== 'warning'
  ) {
    return onRequestEnd ? (
      <UButtonWithLoading {...commonProps} type={commonProps.type} onRequestEnd={onRequestEnd} text={buttonLabel} />
    ) : (
      <UButton {...commonProps} type={commonProps.type} leftIcon={commonProps.icon} text={buttonLabel} />
    );
  }

  return onRequestEnd ? (
    <UIconButtonWithLoading {...commonProps} onRequestEnd={onRequestEnd} />
  ) : (
    <UIconButton {...commonProps} />
  );
};

const PageHeaderIcon = ({ buttonLabel, iconProps, iconSize, iconStyle }: PageHeaderIconType) => {
  const { iconName, onIconClick, iconType, disabled, ghost, onRequestEnd } = iconProps;
  const commonProps = useMemo(
    () => ({
      icon: iconName,
      onClick: onIconClick,
      size: iconSize,
      type: iconType,
      style: iconStyle,
      ghost,
      disabled,
    }),
    [disabled, ghost, iconName, iconStyle, iconSize, iconType, onIconClick],
  );

  return <DynamicButton commonProps={commonProps} buttonLabel={buttonLabel} onRequestEnd={onRequestEnd} />;
};

export const SPageHeader = ({
  title,
  buttonLabel = '',
  subtitle = undefined,
  type = 'standard',
  dynamicText = '',
  iconProps = undefined,
  subtitleIcon = undefined,
  horizontal = false,
  subtitleSize = 'M',
  subtitleCustomStyle = undefined,
  style = undefined,
}: SPageHeaderProps) => {
  const wrapperPositionStyle = useMemo(
    () => (!horizontal ? { ...styles.wrapperVertical } : { ...styles.wrapperHorizontal, display: 'flex' }),
    [horizontal],
  );
  const wrapperStyle = useMemo(
    () => ({
      ...wrapperPositionStyle,
      ...styles[`${type}Wrapper`],
      ...style,
    }),
    [style, type, wrapperPositionStyle],
  );
  const iconSubtitleMarginStyle = useMemo(
    () => (subtitleSize === 'S' || subtitleSize === 'M' ? styles.iconSubtitleSM : styles.iconSubtitleL),
    [subtitleSize],
  );
  const subtitleIconSizeStyle = useMemo(() => (subtitleSize === 'S' || subtitleSize === 'M' ? 12 : 16), [subtitleSize]);
  const subtitleMarginStyle = useMemo(
    () => ({
      display: 'flex',
      marginBottom: TYPE_STYLE[type].subtitleMargin[subtitleSize],
    }),
    [type, subtitleSize],
  );
  const subtitleTypeWidth = useMemo(
    () => ({
      maxWidth: type === 'compact' ? 524 : 724,
    }),
    [type],
  );
  const subtitleMainStyle = useMemo(
    () => (subtitleCustomStyle ? { ...subtitleTypeWidth, ...subtitleCustomStyle } : { ...subtitleTypeWidth }),
    [subtitleCustomStyle, subtitleTypeWidth],
  );

  return (
    <div style={wrapperStyle}>
      <div style={styles.titleWrapper}>
        <p style={{ ...TYPE_STYLE[type].titleTypo, ...styles.title }}>{title}</p>
        {iconProps && (
          <PageHeaderIcon
            buttonLabel={buttonLabel}
            iconProps={iconProps}
            iconSize={TYPE_STYLE[type].iconSize}
            iconStyle={styles[`${type}Icon`]}
          />
        )}
      </div>
      <div style={subtitleMarginStyle}>
        {subtitle && subtitleIcon && (
          <UIcon style={iconSubtitleMarginStyle} size={subtitleIconSizeStyle} name={subtitleIcon} />
        )}
        {subtitle && (
          <p
            style={{
              ...subtitleMainStyle,
              ...SUBTITLE_SIZE_STYLE[subtitleSize],
            }}
          >
            {subtitle}
            <span style={styles.dynamicText}>{` ${dynamicText}`}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default SPageHeader;
