// @flow

import React, { useMemo } from 'react';

import { NPSGraph } from '../NPSGraph/NPSGraph';

import { NPSScoreDifference } from '../NPSScoreDifference/NPSScoreDifference';

import styles from './NPSStats.style';

type Props = {|
  score: number,
  differenceWithPreviousScore: number,
  nbDaysBetweenScores: number,
  nbPromoters: number,
  nbPassives: number,
  nbDetractors: number,
|};

export const NPSStats = ({
  score,
  differenceWithPreviousScore,
  nbDaysBetweenScores,
  nbPromoters,
  nbPassives,
  nbDetractors,
}: Props) => {
  const scoreStyle = useMemo(() => {
    const colorStyle = score > 0
      ? styles.scorePositive
      : styles.scoreNegative;

    return {
      ...styles.score,
      ...colorStyle,
    };
  }, [score]);

  const scoreLabel = `${score > 0 ? '+' : ''}${score}`;

  return (
    <div style={styles.container}>
      <div style={styles.scoreContainer}>
        <div style={scoreStyle}>
          {scoreLabel}
        </div>
        {differenceWithPreviousScore !== 0 && (
          <NPSScoreDifference
            nbDaysBetweenScores={nbDaysBetweenScores}
            scoreDifference={differenceWithPreviousScore}
          />
        )}
      </div>
      <NPSGraph
        nbDetractors={nbDetractors}
        nbPassives={nbPassives}
        nbPromoters={nbPromoters}
      />
    </div>
  );
};
