// @flow

/**
 * Displays a text table cell
 *
 * Props:
 * - value: value displayed
 * - type: type of the value
 * - editable: switch to edit mode and display an @see SInput
 * - maxLength: Define a text length the user can input
 * - onSubmit: Function to call when the user finished to input characters
 * - style: override the component's style
 * - textStyle: override the text's style
 */

import * as React from 'react';

import styles from './UTableCellText.style';

import SInput from 'Components/structural/SInput/SInput';

type Type = 'text' | 'number' | 'date';

type TextType = string | number;

type Props = {|

  // required
  type: Type,
  value: TextType,

  // optional
  editable: boolean,
  maxLength: number,
  onSubmit: (string) => any,
  onReset: () => any,
  style: Object,
  textStyle: Object,
|};

type State = {|
  isHovered: boolean,
  editMode: boolean,
  value: string,
|};

const ALIGN = { text: 'flex-start', number: 'center', date: 'center' };

const KeyInteractionType = {
  ESC: 'Escape',
  TAB: 'Tab',
};

const keyboardReturnIconProps = {
  icon: 'keyboard-return',
};

export class UTableCellText extends React.PureComponent<Props, State> {
  wrapperRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    editable: false,
    maxLength: 120,
    onSubmit: () => {},
    onReset: () => {},
    style: undefined,
    textStyle: undefined,
  };

  constructor(props: Props) {
    const { value: propsValue } = props;

    super(props);

    const value = typeof propsValue === 'string' ? propsValue : '';

    this.state = {
      value,
      isHovered: false,
      editMode: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { type, style } = this.props;
    const { isHovered, editMode } = this.state;

    const editModeStyle = editMode ? styles.editMode : {};
    const hoverStyle = isHovered && !editMode ? styles.hover : {};
    const contentContainerStyle = {
      ...styles[type],

      // $FlowFixMe this is due to use the spread operator on more than one variable that depend on a condition
      ...hoverStyle,
      ...editModeStyle,
    };

    return (
      <div
        ref={this.wrapperRef}
        style={{ ...styles.wrapper, justifyContent: ALIGN[type], ...style }}
      >
        <div
          style={contentContainerStyle}
          onClick={this.handleEdit}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleLeave}
          onKeyDown={this.handleKeyDown}
        >
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent = () => {
    const { type, value, textStyle } = this.props;

    if (type === 'text')
      return this.renderTextContent();

    return (
      <span style={textStyle}>
        {value}
      </span>
    );
  };

  renderTextContent = () => {
    const { textStyle, editable, value: propsValue } = this.props;
    const { editMode, value } = this.state;

    // Have a stateless behavior when not editable
    // to allow dynamic updates
    if (!editable) {
      return (
        <span style={textStyle}>
          {propsValue}
        </span>
      );
    }

    if (editMode)
      return this.renderInput(value);

    return (
      <span style={textStyle}>
        {value}
      </span>
    );
  };

  renderInput = (value: string) => {
    const { maxLength } = this.props;

    return (
      <SInput
        type='small'
        value={value}
        maxLength={maxLength}
        onChange={this.handleInputChange}
        onSubmit={this.handleCloseEditMode}
        onIconClick={this.handleCloseEditMode}
        onFocus={this.handleSelection}
        icon={keyboardReturnIconProps}
        autofocus
        hideCounter
      />
    );
  };

  handleInputChange = (value: string) => {
    this.setState({ value });
  };

  handleEdit = () => {
    const { editable, type } = this.props;

    if (editable && type === 'text')
      this.setState({ editMode: true });
  };

  handleHover = () => {
    const { editable, type } = this.props;

    if (editable && type === 'text')
      this.setState({ isHovered: true });
  };

  handleLeave = () => {
    const { editable, type } = this.props;

    if (editable && type === 'text')
      this.setState({ isHovered: false });
  };

  handleClickOutside = (event: MouseEvent) => {
    const { editMode } = this.state;

    if (editMode && this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target))
      this.handleEscapeKey();
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const { editMode } = this.state;
    const { key } = event;

    if (!editMode)
      return null;

    switch (key) {
      case KeyInteractionType.ESC:
      case KeyInteractionType.TAB:
        return this.handleEscapeKey();
      default:
        return null;
    }
  };

  handleSelection = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.target.select();
  };

  handleEscapeKey = () => {
    const { onReset, value } = this.props;
    const textValue: string = (value: any);

    this.setState({ editMode: false, value: textValue });

    onReset();
  };

  handleCloseEditMode = () => {
    const { onSubmit, onReset, value: propsValue } = this.props;
    const { value } = this.state;

    if (propsValue === value)
      return this.setState({ editMode: false }, onReset);

    return Promise.resolve(onSubmit(value))
      .then((res) => {
        if (!res || !res.error)
          this.setState({ editMode: false, value });
      });
  };
}

export default UTableCellText;
