// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 8px 18px 8px',
    borderBottom: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
    position: 'relative',
  },
  wrapperDefault: {
    borderTop: `1px solid ${COLORS.BLUE_GRAY_MEDIUM.DEFAULT}`,
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
  },
  containerLanguage: {
    borderWidth: 1,
    borderColor: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerFlagLabel: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  containerLabel: {
    display: 'flex',
    alignItems: 'baseline',
    overflow: 'hidden',
  },
  label: {
    ...TYPOGRAPHY.H2,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: 20,
  },
  defaultLabel: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginLeft: 8,
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  containerProgressBar: {
    marginTop: 20,
  },
  optionDropDown: {
    position: 'absolute',
    top: 42,
    right: 0,
  },
};
