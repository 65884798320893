import { Moment } from 'moment';

import Enum from 'Models/Enum';

import { mergeDeep } from 'Libs/mergeDeep';
import { getCdnFromS3Url } from 'Libs/cdn';

import createDailySerie from './DailySerie';
import type { DailySerieType } from './DailySerie';

export type ActivityType = Readonly<{
  id: number;
  typeId: number;
  name: string;
  description: string;
  active: boolean;
  archived: boolean;
  pendingTranslation: boolean;
  startDate: string;
  endDate: string;
  dailySerie: DailySerieType;
  updatedAt: string;
  originId: number | null;
  coverId: number;
  coverUrl: string;
  languageId: number;
  multilingualId: number;
  isDefault: boolean;
  lastActivated: string | null;
}>;

export default function createActivity(values?: any): ActivityType {
  const defaultState: ActivityType = {
    id: 0,
    typeId: Enum.activityTypeEnum.DAILY_SERIE,
    name: '',
    description: '',
    active: false,
    archived: false,
    pendingTranslation: false,
    originId: 0,
    coverId: 0,
    coverUrl: '',
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    dailySerie: createDailySerie(),
    languageId: 0,
    multilingualId: 0,
    isDefault: true,
    lastActivated: null,
  };

  if (!values) {
    return defaultState;
  }

  const dailySerie = createDailySerie(values.dailySerie, values.startDate, values.endDate);
  const coverUrl = values.cover ? getCdnFromS3Url(values.cover.url, { asThumbnail: true }) : '';

  return mergeDeep(defaultState, { ...values, dailySerie, coverUrl });
}

export const findDefaultActivity = (activities: ReadonlyArray<ActivityType>) => {
  const activity = activities.find((a) => a.isDefault);
  if (!activity) {
    throw new Error('No default activity found');
  }

  return activity;
};

// Enddate in the state is not accurate
export const isActivityInProgress = (activity: ActivityType, momentDate: Moment, endDate: string = activity.endDate) =>
  momentDate.isAfter(activity.startDate) && momentDate.isBefore(endDate);

export const isActivityLocked = (activity: ActivityType, momentDate: Moment, endDate: string = activity.endDate) => {
  if (!activity.active) {
    return false;
  }

  return isActivityInProgress(activity, momentDate, endDate);
};
