import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import { getTrackingDatasetFromProps, TrackingProps } from 'Services/trackingService';

import UIcon from 'Components/unit/UIcon/UIcon';
import { TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';

import { ICON_SIZE } from '../UTextLink/UTextLink';

import styles, { BACKGROUND_COLOR, COLOR, colorMapping, getExtraStyle } from './UButton.style';
import { BUTTON_TYPES, UButtonType as OriginalUButtonType } from './UButton.utils';

export type UButtonType = OriginalUButtonType;

export interface UButtonProps extends TrackingProps {
  text?: string;
  typography?: keyof typeof TYPOGRAPHY;
  type?: UButtonType;
  disabled?: boolean;
  link?: string;
  leftIcon?: IconName | '';
  rightIcon?: IconName | '';
  style?: React.CSSProperties | null;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onActionEnd?: Function;
  loading?: boolean;
  loadingLabel?: string;
  iconSize?: typeof ICON_SIZE[keyof typeof ICON_SIZE];
  marginLeft?: number | string;
  marginRight?: number | string;
  rightIconSize?: number;
  leftIconSize?: number;
  progress?: number;
  ghost?: boolean;
  textAndIconStyle?: React.CSSProperties | null;
}

const MAX_PROGRESS = 100;

export const UButton = ({
  onClick,
  disabled = false,
  type = BUTTON_TYPES.STANDARD,
  style: propStyle = undefined,
  loading = false,
  progress: propsProgress = 0,
  rightIcon,
  leftIcon,
  text,
  rightIconSize = 16,
  leftIconSize = 16,
  ghost = false,
  typography,
  trackingAction,
  trackingContext,
  textAndIconStyle = {},
}: UButtonProps) => {
  const [isHover, setIsHover] = useState(false);

  const progress = useMemo(() => Math.min(MAX_PROGRESS, Math.max(0, propsProgress)), [propsProgress]);
  const progressStyle = useMemo(() => ({ ...styles.progressZone, width: `${progress}%` }), [progress]);

  const isButtonActive = useMemo(() => !disabled && !progress && !loading, [disabled, progress, loading]);

  const isBorderVisible = useMemo(() => ghost && !disabled && !isHover, [ghost, disabled, isHover]);

  const textStyle = useMemo(() => ({ opacity: loading ? 0 : 1 }), [loading]);

  const trackingDataset = useMemo(
    () => getTrackingDatasetFromProps({ trackingAction, trackingContext }),
    [trackingAction, trackingContext],
  );

  const extraStyle = useMemo(
    () => getExtraStyle(isBorderVisible, isButtonActive, type, rightIcon, leftIcon, loading),
    [isBorderVisible, isButtonActive, leftIcon, loading, rightIcon, type],
  );

  const { backgroundColor, color } = useMemo(() => {
    const colorVariant = colorMapping(isHover, disabled, ghost);
    return {
      backgroundColor: BACKGROUND_COLOR[type][colorVariant],
      color: textAndIconStyle?.color ?? COLOR[type][colorVariant],
    };
  }, [isHover, disabled, ghost, type]);

  const style = useMemo(
    () => ({
      ...styles.wrapper,
      backgroundColor,
      color,
      ...extraStyle,
      ...propStyle,
      ...(typography ? TYPOGRAPHY[typography] : {}),
    }),
    [backgroundColor, color, extraStyle, propStyle],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isButtonActive && onClick) {
        onClick(event);
      }
    },
    [isButtonActive, onClick],
  );

  const handleHover = useCallback(() => {
    if (isButtonActive) {
      setIsHover(true);
    }
  }, [isButtonActive]);

  const handleLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <button
      type="button"
      data-test-id="button"
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      onFocus={handleHover}
      onBlur={handleLeave}
      style={style}
      disabled={disabled}
      {...trackingDataset}
    >
      <div style={styles.container} data-test-id="container">
        {progress > 0 && (
          <div style={styles.progressContainer}>
            <div style={progressStyle} />
          </div>
        )}

        <span style={{ ...styles.contentContainer, ...textStyle, ...textAndIconStyle }}>
          {leftIcon && (
            <UIcon data-test-id="left-icon" name={leftIcon} size={leftIconSize} color={color} style={styles.iconLeft} />
          )}
          {text}
          {rightIcon && (
            <UIcon
              data-test-id="right-icon"
              name={rightIcon}
              size={rightIconSize}
              color={color}
              style={styles.iconRight}
            />
          )}
        </span>

        <div style={styles.loaderContainer}>
          <CircleSpinner size={16} color={color} loading={loading} />
        </div>
      </div>
    </button>
  );
};

export default UButton;
