import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export const STYLE_BY_SIZE = {
  iconButton: createStyleSheet({
    default: {
      padding: '6px 8px',
      cursor: 'pointer',
    },
    mini: {
      paddingRight: 7,
    },
    small: {
      paddingRight: 11,
    },
    large: {
      paddingRight: 15,
    },
  }),
  withoutIcon: createStyleSheet({
    mini: {
      paddingRight: 7,
    },
    small: {
      paddingRight: 11,
    },
    large: {
      paddingRight: 15,
    },
  }),
};

const styles = createStyleSheet({
  micro: {
    textAlign: 'center',
    width: '100%',
    padding: '2px 7px',
  },
  mini: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
  },
  small: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 11,
  },
  large: {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 15,
  },
  textField: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    paddingRight: 0,
    outline: 'none',
    ...TYPOGRAPHY.BODY3,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconGhostDisabled: {
    marginRight: 8,
  },
  textDisabled: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  errorMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DARK,
    position: 'absolute',
    left: 0,
    bottom: -20,
  },
});

export default styles;
