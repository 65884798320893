import { useRef, useCallback, useEffect } from 'react';

/**
 * Execute a function with a timeout. Provides API to execute
 * and clear the timeout manually.
 *
 * Automatically clears the timeout on unmount or if executing
 * the callback again before the given duration.
 * ```
 * const timeoutCallback = useTimeoutCallback(
 *   useCallback(() => {
 *     console.log('Log after 2.5s');
 *   }, []),
 *   2500,
 * );
 * timeoutCallback.exec();
 * timeoutCallback.clear();
 * ```
 */
export const useTimeoutCallback = (callback: () => void, delay: number) => {
  const timeoutId = useRef<null | ReturnType<typeof setTimeout>>(null);

  const clear = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  const exec = useCallback(
    () => {
      clear();

      timeoutId.current = setTimeout(() => {
        callback();
      }, delay);
    },
    [clear, callback, delay],
  );

  useEffect(() => clear, [clear]);

  return { exec, clear };
};
