// @flow

import React, { useCallback } from 'react';
import type { ElementConfig } from 'react';
import { t } from 'i18next';

import { useTypedDispatch } from 'Libs/redux/utils';

import SSpecialtyButton from 'Components/structural/SSpecialtyButton/SSpecialtyButton';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import { actions, useModuleSelector, useSelectedActivity } from '../../../redux';

type SSpecialtyButtonType = $Exact<ElementConfig<typeof SSpecialtyButton>>;

export const AddDailySessionButton = () => {
  const dispatch = useTypedDispatch();
  const onClick = useCallback(() => dispatch(actions.addEmptyDailySerieDay()), [dispatch]);
  const isLocked = useModuleSelector(state => state.isLocked);
  const activity = useSelectedActivity();

  const SSpecialtyButtonWithTooltip = withTooltip<SSpecialtyButtonType>(SSpecialtyButton);

  return (
    <SSpecialtyButtonWithTooltip
      text={t('activities:scheduling.add_daily_session')}
      onClick={onClick}
      disabled={isLocked || !activity.isDefault}
      tooltipPosition="top"
      tooltipContent={t('activities:scheduling.add_daily_session_tooltip')}
      tooltipEnabled={!activity.isDefault}
    />
  );
};
