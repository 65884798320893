import Model from 'models/Model';

var WelcomePanel = {
  endpoint  : 'api/WelcomePanels',
  attributes: {

    title: {
      type: 'string',
    },
    text: {
      type: 'string',
    },
    order: {
      type: 'number',
    },
    image: {
      type : 'pointer',
      Model: 'Image',
    },
    imageId: {
      type: 'number',
    },
    welcomeCardId: {
      type: 'number',
    },
  },
};

export default Model.register('WelcomePanel', WelcomePanel);
