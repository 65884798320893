import React from 'react';

import LockedInfo from 'Components/structural/STable/components/LockedInfo';
import styles from 'Components/structural/STable/STable.style';
import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';
import { STableRow } from 'Components/structural/STable/types';

type LockedRowProps = {
  row: STableRow['original'];
};

const LockedRow = ({ row }: LockedRowProps) => {
  const { lockedInfo } = row;
  const id = `stable--locked-row-${row.id}`;

  return (
    <div style={styles.lockedRowWrapper}>
      <div
        data-tip
        data-for={id}
        data-event="mouseenter"
        data-event-off="mouseleave"
        style={styles.tooltipTriggerWrapper}
      >
        <UIcon name="lock" color={COLORS.BLACK.DISABLED} size={16} />
      </div>
      <LockedInfo id={id} lockedInfo={lockedInfo} />
    </div>
  );
};

export default LockedRow;
