import inputGameItem from 'components/input.game.item';
import m from 'm';

var component = {};

component.controller = function controller() {

};

component.view = function view(c, args) {
  return m('div', {
    class: [
      args.class || '',
      'input-question',
    ].join(' '),
  }, [
    m(inputGameItem, {
      gameItem   : args.gameItem,
      placeholder: args.placeholder || 'Enter a question',
      editable   : args.editable,
      inputClass: 'input-game-item__input--question-text',
      containerClass: 'input-game-item--question-text',
      counterClass: 'input-game-item__counter--question-text',
      warningMax : 80,
      warningText: 'A short question is easier to understand and answer to',
      maxFieldSize: args.maxFieldSize
    }),
  ]);
};

export default component;
