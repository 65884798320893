import React, { useCallback } from 'react';

import type { FunctionComponent, MouseEvent } from 'react';

import UCheckbox, { UCheckboxProps } from 'Components/unit/UCheckbox/UCheckbox';
import URadioButton from 'Components/unit/URadioButton/URadioButton';

export type UAbstractSelectVariant = 'radio' | 'check' | 'none';
export type UAbstractSelectSize = 'S' | 'M' | 'L';

type SelectVariantSpecificProps = {
  variant: UAbstractSelectVariant;
};
type AbstractSelectBaseProps<T extends SelectVariantSpecificProps> = T & {
  onSelect: (event: MouseEvent) => void;
  selected: boolean;
  disabled: boolean;
  type: 'standard' | 'accentuated';
};
type CheckSelectProps = AbstractSelectBaseProps<{
  /**
   * The check variant uses the `UCheckbox` component.
   */
  variant: 'check';
}>;
type RadioSelectProps = AbstractSelectBaseProps<{
  /**
   * The check variant uses the `URadioButton` component.
   */
  variant: 'radio';
  /**
   * Only available for the radio type. The `UCheckbox` does not
   * support multiple sizes.
   */
  size?: UAbstractSelectSize;
}>;
type NoneSelectProps = AbstractSelectBaseProps<{
  /**
   * The none variant does not use a component.
   */
  variant: 'none';
}>;

export type UAbstractSelectProps = CheckSelectProps | RadioSelectProps | NoneSelectProps;

export const UAbstractSelect: FunctionComponent<UAbstractSelectProps> = ({
  onSelect,
  selected,
  disabled,
  type,
  ...variantProps
}) => {
  const handleSelect = useCallback(
    (eventOrCheckStatus?: string | MouseEvent, event?: MouseEvent) => {
      if (typeof eventOrCheckStatus === 'string' && event) {
        onSelect(event);
      }

      if (eventOrCheckStatus && typeof eventOrCheckStatus !== 'string') {
        onSelect(eventOrCheckStatus);
      }
    },
    [onSelect],
  );

  switch (variantProps.variant) {
    case 'check':
      return (
        <UCheckbox
          onClick={handleSelect as unknown as UCheckboxProps['onClick']}
          isDisabled={disabled}
          checkBoxStatus={selected ? 'checked' : 'unchecked'}
          type={type}
        />
      );
    case 'radio':
      return (
        <URadioButton
          onSelect={handleSelect}
          size={variantProps.size || 'M'}
          selected={selected}
          disabled={disabled}
          type={type}
        />
      );
    case 'none':
      return <></>;
    default:
      throw new Error('UEntityCardCore: Unknown selector variant');
  }
};

export default UAbstractSelect;
