import type { ApiActionType } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/filter/types';
import { apiAction } from 'Libs/redux/utils';

import Enum from 'Models/Enum';

import { store } from 'Libs/redux/store';

type ActivityListGetFullPaginatedAction = ApiActionType<'ACTIVITY_LIST_GET_FULL_PAGINATED'>;
type ActivityListUpdateArchiveStatus = ApiActionType<'ACTIVITY_LIST_UPDATE_ARCHIVE_STATUS'>;
type ActivityListCreateMultilingualCampaign = ApiActionType<'ACTIVITY_LIST_CREATE_MULTILINGUAL_CAMPAIGN'>;

type ActivityListUpdateFiltersAction = {
  type: 'ACTIVITY_LIST_UPDATE_FILTERS';
  selectedFilters: Array<FilterState>;
};

type ActivityListResetAction = {
  type: 'ACTIVITY_LIST_RESET';
};

export const actions = {
  updateFilters: (selectedFilters: Array<FilterState>): ActivityListUpdateFiltersAction => ({
    type: 'ACTIVITY_LIST_UPDATE_FILTERS',
    selectedFilters,
  }),
  getFullPaginated: (offset: number, limit: number): ActivityListGetFullPaginatedAction => {
    const state = store.getState();

    const filter = {
      segmentationIds: state.pages.activityList.selectedSegmentationIds,
      typeId: state.pages.activityList.selectedActivityTypeId,
      status: state.pages.activityList.selectedActivityStatusId,
      targetModeId: state.pages.activityList.selectedTargetModeId,
      languageIds: state.pages.activityList.selectedActivityLanguageIds,
      skip: offset,
      limit,
      texts: state.pages.activityList.selectedTextValues,
    };

    return apiAction({
      type: 'ACTIVITY_LIST_GET_FULL_PAGINATED',
      route: '/api/activities/paginatedMultilingual',
      method: 'GET',
      query: { filter },
      meta: {
        toAdd: offset > 0,
      },
    });
  },
  updateArchiveStatus: (multilingualId: number, status: boolean): ActivityListUpdateArchiveStatus => {
    const payload = { multilingualId, status };

    return apiAction({
      type: 'ACTIVITY_LIST_UPDATE_ARCHIVE_STATUS',
      route: '/api/activities/archiveStatus',
      method: 'PUT',
      payload,
    });
  },
  createMultiligualCampaign: (
    name: string,
    languageId: number,
    random: boolean,
  ): ActivityListCreateMultilingualCampaign => {
    const payload = { name, languageId, typeId: Enum.activityTypeEnum.DAILY_SERIE, random };

    return apiAction({
      type: 'ACTIVITY_LIST_CREATE_MULTILINGUAL_CAMPAIGN',
      route: '/api/activities/fullMultilingual',
      method: 'POST',
      payload,
    });
  },
  reset: (): ActivityListResetAction => ({
    type: 'ACTIVITY_LIST_RESET',
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type ActivityListAction = ReturnType<ActionsFn>;
