import MCoursePublication from 'Components/modal/MCoursePublication/MCoursePublication';
import SCourseCard, { CourseAction } from 'Components/structural/SCourseCard/SCourseCard';
import { useAppDispatch } from 'Libs/redux/store';
import { redirect } from 'Libs/routing/redirect';
import Enum from 'Models/Enum';
import { usePublishCourseWithModal } from 'Pages/Courses/hooks/course-publish';
import { CourseListItem } from 'Pages/Courses/redux/types/state';
import { getCourseStatus } from 'Pages/Courses/utils/course.utils';
import React, { useCallback } from 'react';
import { duplicateCourse, sendCommands } from 'Pages/Courses/redux/thunks';
import { actions } from 'Pages/Courses/redux/slice';
import { createEditCourseDataCommands } from 'Pages/Courses/utils/commands';
import { getSync } from 'Services/localStorageService';
import { ContributorType } from 'Pages/Contributor/redux/models/Contributor';
import { trackEventWithLocation } from 'Services/trackingService';
import { enqueueToastAlert, removeToastAlert } from 'Pages/AlertManager';

type CourseItemProps = {
  course: CourseListItem['course'];
  coursesLength: number;
  editionId: string;
};

const REMOVE_TOAST_ALERT_TIMEOUT = 3500;

export const CourseItem = ({ course, coursesLength, editionId }: CourseItemProps) => {
  const dispatch = useAppDispatch();

  const {
    publishModalState,
    publishDialogCourseData,
    handlePublish,
    handlePublishButton,
    handleUnpublish,
    handleUnpublishButton,
    closePublishModal,
  } = usePublishCourseWithModal('list', course, course.lastEditor);

  const handleCourseDuplication = useCallback(async () => {
    const currentUser: ContributorType = getSync('user');
    const promise = dispatch(duplicateCourse({ id: course.id, currentUser }));

    try {
      const { id } = await promise.unwrap();

      trackEventWithLocation('duplicate', { context: 'course' });
      redirect(`/course/${id}/microknowledges`);
      enqueueToastAlert({
        id: 'COURSE_ITEM_DUPLICATE_SUCCESS',
        title: 'Entity successfully duplicated',
        status: 'success',
        priority: 'low',
      });

      setTimeout(handleRemoveToastAlert, REMOVE_TOAST_ALERT_TIMEOUT);
    } catch (e) {
      // eslint-disable-next-line no-console -- Error is already handled with redux
      console.error(e);
    }
  }, []);

  const handleCourseAction = useCallback(
    (_courseId: number, action: CourseAction) => {
      if (action === 'duplicate') return handleCourseDuplication();

      if (action === 'publish' || action === 'update') return handlePublishButton();

      return handleUnpublishButton();
    },
    [handlePublishButton, handleUnpublishButton, handleCourseDuplication],
  );

  const handleRemoveToastAlert = useCallback(() => {
    removeToastAlert('COURSE_ITEM_DUPLICATE_SUCCESS');
  }, []);

  const handleOnConfirm = useCallback(
    (isPublished: boolean) => {
      if (isPublished) {
        return handlePublish;
      }
      return handleUnpublish;
    },
    [handlePublish, handleUnpublish],
  );

  const navigateDetailView = useCallback((id: number) => redirect(`/course/${id}/microknowledges`), []);
  const { statuses } = course;
  const status = getCourseStatus(statuses);

  const handleUpdateImage = useCallback(
    async (localImage) => {
      const command = createEditCourseDataCommands(course.dataId, {
        image: localImage,
      });

      const preparedData = {
        payload: {
          commands: command,
          editionId,
        },
        id: course.id,
        context: 'list' as const,
      };

      dispatch(actions.updateCourseInList({ id: course.id, coverImageUrl: localImage.url }));
      await dispatch(sendCommands(preparedData)).unwrap();
    },
    [course.dataId, course.id, dispatch, editionId],
  );

  return (
    <>
      <SCourseCard
        key={course.id}
        id={course.id}
        title={course.title}
        size={coursesLength > 6 ? 'S' : 'M'}
        language={(Enum.Languages as any)[course.language]}
        image={course.coverImageUrl}
        onAction={handleCourseAction}
        onClick={navigateDetailView}
        status={status}
      />
      {publishDialogCourseData && (
        <MCoursePublication
          data-test-id="publication-modal"
          visible={publishModalState.isOpened}
          courseData={publishDialogCourseData}
          isPublished={publishModalState.isPublished}
          onConfirm={handleOnConfirm(publishModalState.isPublished)}
          onClose={closePublishModal}
          onImageSelect={handleUpdateImage}
        />
      )}
    </>
  );
};
