import type { CSSProperties } from 'react';
import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

type SummaryTheme = {
  borderColor: string;
  iconColor: string;
  validTextStyle: CSSProperties;
  invalidTextStyle: CSSProperties;
  invalidIconColor?: string;
};

export type SummaryStatus = 'validated' | 'warning' | 'locked';
export const SUMMARY_THEMES: Record<SummaryStatus, SummaryTheme> = {
  validated: {
    borderColor: COLORS.PRIMARY.DEFAULT,
    iconColor: COLORS.BLACK.DEFAULT,
    invalidIconColor: COLORS.BLACK.DEFAULT,
    validTextStyle: {},
    invalidTextStyle: {},
  },
  warning: {
    borderColor: COLORS.SECONDARY.HOVER,
    iconColor: COLORS.BLACK.DEFAULT,
    invalidIconColor: COLORS.SECONDARY.DARK,
    validTextStyle: {},
    invalidTextStyle: {
      ...TYPOGRAPHY.BODY3_SEMIBOLD,
      color: COLORS.SECONDARY.DARK,
    },
  },
  locked: {
    borderColor: COLORS.TEXT.DISABLED_DEFAULT,
    iconColor: COLORS.TEXT.DISABLED_DEFAULT,
    invalidIconColor: COLORS.TEXT.DISABLED_DEFAULT,
    validTextStyle: {
      color: COLORS.TEXT.DISABLED_DEFAULT,
    },
    invalidTextStyle: {
      color: COLORS.TEXT.DISABLED_DEFAULT,
    },
  },
};

const styleSheet = createStyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '225px',
    gap: '16px',
    padding: '6px 6px 14px 6px',

    borderRadius: '12px',
    border: '2px solid',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px 8px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  text: {
    ...TYPOGRAPHY.BODY3,
  },
});

export default styleSheet;
