/* eslint-disable complexity */
import m from 'm';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import reactCreator from 'components/react.creator';

var component = {};

component.controller = function controller() {
  //
};

component.view = function view(c) {
  return m('.ambassadors-page', [
    m('.ambassadors-page__menu', [
      m(menu),
      m(gaiaMenu),
    ]),
    m(reactCreator, {
      component: 'Ambassador',
      style    : { height: '100%' },
      props    : {},
    }),
  ]);
};

export default component;
