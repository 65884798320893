import LS from 'Services/localStorageService';

export type HTTPMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';

export type HTTPFetchOptions = {
  route: string;
  method: HTTPMethod;

  payload?: any;
  query?: any;
  multipart?: boolean;
};

export type ApiError = {
  message: string;
  statusCode: number;
  errorCode?: string;
};

export type FetchFactoryReturn<T> = {
  fetchFn: () => Promise<T>;
  abortController: AbortController;
};

enum ContentTypes {
  JSON = 'application/json',
  TEXT = 'text/', // This is only the start of the content type and should be completed with:
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const handleXLSXResponse = async (res: Response) => {
  const blob = await res.blob();

  const contentDisposition = res.headers.get('content-disposition');
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  const filename = contentDisposition ? contentDisposition.split('"')[1] : 'file.xlsx';

  a.href = url;
  a.download = filename;

  if (document.body) {
    document.body.appendChild(a);
  }

  a.click();
  a.remove();
};

const parseBody = (res: Response) => {
  if (res.status === 204) {
    return Promise.resolve();
  }

  const contentType = res.headers.get('Content-Type') || '';

  if (contentType.startsWith(ContentTypes.JSON)) {
    return res.json();
  }

  if (contentType.startsWith(ContentTypes.TEXT)) {
    return res.text();
  }

  if (contentType === ContentTypes.XLSX) {
    return handleXLSXResponse(res);
  }

  return Promise.resolve();
};

const buildQueryString = (queryObj: Record<string, unknown>) => {
  const params = Object.keys(queryObj).map((q) => {
    const value = typeof queryObj[q] !== 'string' ? JSON.stringify(queryObj[q]) : (queryObj[q] as string);

    return `${encodeURIComponent(q)}=${encodeURIComponent(value)}`;
  });

  return params.length ? `?${params.join('&')}` : '';
};

const handleResponse = async (res: Response) => {
  const body = await parseBody(res);

  return !res.ok ? Promise.reject(body) : body;
};

const isApiError = (error: any): error is ApiError =>
  typeof error === 'object' && error !== null && 'message' in error && 'statusCode' in error;

const fetchFactory = <T>({ route, query, payload, method, multipart }: HTTPFetchOptions): FetchFactoryReturn<T> => {
  const isSingleObjectPayload = typeof payload === 'object' && !multipart;

  const token = LS.token();
  const headers = new Headers({
    // Sometimes skipping content-type force browser to recompute file upload boundary
    // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
    ...(isSingleObjectPayload ? { 'Content-Type': ContentTypes.JSON } : {}),
    ...(token && token !== '' ? { Authorization: token } : {}),
  });

  const stringifiedPayload = isSingleObjectPayload ? JSON.stringify(payload) : null;

  const body = multipart ? payload : stringifiedPayload;

  // eslint-disable-next-line no-restricted-globals
  const domain = location.origin;
  const requestUrl = `${domain}${route}${buildQueryString(query || {})}`;
  const controller = new AbortController();
  const { signal } = controller;

  const opts = {
    method,
    headers,
    signal,
    body,
  };

  return {
    // INFO this promise can fail:
    //   - in case of a network error
    //     (see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch)
    //   - in case of a HTTP error (see handleResponse)
    fetchFn: () => fetch(requestUrl, opts).then(handleResponse),
    abortController: controller,
  };
};

export { buildQueryString, isApiError, handleResponse, handleXLSXResponse, fetchFactory, parseBody };
