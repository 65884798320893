// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    ...TYPOGRAPHY.BODY2,
    cursor: 'pointer',
    alignItems: 'center',
    padding: '16px 20px 16px 20px',
  },
  icon: {
    marginRight: 16,
    minWidth: 28,
  },
  hovered: {
    backgroundColor: COLORS.GREY_LIGHT.HOVER,
  },
  selected: {
    padding: '16px 20px 16px 16px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    cursor: 'initial',
  },
};
