import Promise from 'bluebird';
import T from 'types';
import LS from 'services/localStorageService';
import iconv from 'iconv-lite';

var STATUS = {
  VALID: 200,
};

export default {
  get      : get,
  post     : post,
  put      : put,
  destroy  : destroy,
  upload   : upload,
  buildUrl : buildUrl,
  builQuery: builQuery,
};

/////////////
// Helpers //
/////////////

function get(url, data = undefined, progress = undefined) {
  return _request('GET', url, data, progress);
}

function post(url, data = undefined, progress = undefined) {
  return _request('POST', url, data, progress);
}

function put(url, data = undefined, progress = undefined) {
  return _request('PUT', url, data, progress);
}

function destroy(url, data = undefined, progress = undefined) {
  return _request('DELETE', url, data, progress);
}

function upload(url, data, progress, options) {
  return _request('POST', url, {
    file   : true,
    content: data,
    options: options,
  }, progress);
}

function buildUrl(url, query) {
  var domain = location.origin;
  var isRoute = url.search('https?:\/\/') === -1;

  if (isRoute)
    url = url[0] === '/' ? domain + url : domain + '/' + url;

  if (query)
    url += '?' + builQuery(query);

  return url;
}

/*
 * Turns:
 * {
 *   name : value
 *   name2: value2
 * }
 * Into:
 *   name=value&name2=value2
 */
function builQuery(data) {
  return Object.keys(data).map(function (key) {
    return [key, data[key]].map(encodeURIComponent).join('=');
  }).join('&');
}

/////////////
// Private //
/////////////

function _request(method, path, data, onProgress) {
  return new Promise(function xhrPromise(resolve, reject) {
    var url = buildUrl(path);
    var xhr = new XMLHttpRequest();
    var dataStr = '';

    if (method === 'GET' && data && T.is.obj(data)) {
      url += '?' + Object.keys(data).map(function mapData(k) {
        var name = encodeURIComponent(k);
        var value = encodeURIComponent(T.is.obj(data[k]) || T.is.arr(data[k])
          ? JSON.stringify(data[k])
          : data[k]
        );

        return [name, value].join('=');
      }).join('&');
    }

    xhr.open(method, url);

    if (data && data.responseType)
      xhr.responseType = data.responseType;

    if (method !== 'GET' && data && T.is.obj(data)) {
      if (data.file) {
        // handle file upload
        var formData = new window.FormData();

        formData.append('file', data.content);
        if (data.options)
          formData.append('options', JSON.stringify(data.options));
        dataStr = formData;
      } else {
        dataStr = JSON.stringify(data);
        xhr.setRequestHeader('Content-type', 'application/json');
      }
    }

    if (data && data.token)
      xhr.setRequestHeader('Authorization', data.token);
    else if (LS.token() && LS.token() !== '')
      xhr.setRequestHeader('Authorization', LS.token());

    xhr.onload = onLoad;
    xhr.onerror = onError;
    xhr.onprogress = onProgress;
    xhr.setRequestHeader('Cache-Control', 'no-cache');

    xhr.send(dataStr);

    /** @this xhr */
    function onLoad() {
      var res;

      try {
        res = JSON.parse(this.response);
      } catch (e) {
        if (e)
          res = this.response;
      }

      if (Math.floor(xhr.status / 100) !== Math.floor(STATUS.VALID / 100)) {
        reject({
          status : this.status,
          type   : this.statusText,
          message: res,
        });
      } else {
        var contentType = xhr.getResponseHeader('content-type');

        if (contentType && (contentType.slice(0, 9) === 'text/csv;' || contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
          return resolve(downloadFile(this.response, contentType));

        resolve(JSON.parse(this.response || '{}'));
      }
    }

    /** @this xhr */
    function onError() {
      var res;

      try {
        res = JSON.parse(this.response);
      } catch (e) {
        if (e)
          res = this.response;
      }

      var returnValue = {
        status : this.status,
        type   : this.statusText,
        message: res,
      };

      // FIXME: Never gets printed to the console. onError not called ?
      console.log('[request]', 'Error in request', returnValue);

      reject(returnValue);
    }

    function downloadFile(response, contentType) {
      var fileName = xhr.getResponseHeader('content-disposition').split('"')[1];
      var blob;

      if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        blob = new Blob([response], { type: contentType });

      else {
        blob = new Blob([iconv.encode(response, 'utf16')], {
          type: 'text/csv;charset=utf-16le',
        });
      }

      var a = document.createElement('a');

      a.style = 'display: none';
      document.body.appendChild(a);

      var blobUrl = window.URL.createObjectURL(blob);

      a.href = blobUrl;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  });
}
