import React, { CSSProperties, useEffect, ChangeEvent, useState, useCallback, MouseEvent } from 'react';

import { AllowedMimeType } from 'Libs/ts/types';
import { IMAGES_MIME_TYPES } from 'Components/utils/Enum';
import { COLORS } from 'Components/foundation';
import DraggingMessage from 'Components/structural/SUploadDropArea/components/DraggingMessage';
import ErrorMessage, { ERROR_DROP } from 'Components/structural/SUploadDropArea/components/ErrorMessage';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';

import styles from './SUploadDropArea.style';
import UIcon from '../../unit/UIcon/UIcon';

export type SUploadDropAreaProps = {
  defaultSentence: string;
  defaultLabel: string;
  dropSentence: string;
  warnSentence1: string;
  warnSentence2: string;
  errorIncorrectFormat1: string;
  errorIncorrectFormat2: string;
  errorSizeTooBig: string;
  errorManyFiles: string;
  uploadingSentence: string;
  allowedMimeTypes: Array<string>;
  maxFileSize: number;
  onDrop: (file: File) => void;
  fileName?: string;
  removeFile?: () => void;
  uploadError?: string;
  uploadProgress?: number;
  backgroundColor?: string;
  dotsColor?: string;
  style?: CSSProperties;
};

// Default image mime types
const DEFAULT_ALLOWED_MIME_TYPES: Array<AllowedMimeType> = [
  IMAGES_MIME_TYPES.GIF,
  IMAGES_MIME_TYPES.PNG,
  IMAGES_MIME_TYPES.JPEG,
];

// Max size file is 20MB
const DEFAULT_MAX_SIZE_FILE = 20000000;
const MAXIMUM_CHARS = 50;
const LAST_CHARS_TO_DISPLAY = 4;

const shortenFileName = (fileName: string) => {
  const extension = fileName.split('.').pop();
  if (!extension) return fileName;

  const nameWithoutExtension = fileName.slice(0, -1 * (extension.length + 1));

  if (!fileName || nameWithoutExtension.length < MAXIMUM_CHARS) return fileName;

  const nameMaxChars = nameWithoutExtension.length - (MAXIMUM_CHARS - LAST_CHARS_TO_DISPLAY + 3);
  const firstPart = nameWithoutExtension.substring(0, nameMaxChars);
  const lastPart = nameWithoutExtension.slice(-LAST_CHARS_TO_DISPLAY);
  return `${firstPart}...${lastPart}.${extension}`;
};

export const SUploadDropArea = ({
  defaultSentence,
  defaultLabel,
  dropSentence,
  warnSentence1,
  warnSentence2,
  errorIncorrectFormat1,
  errorIncorrectFormat2,
  errorSizeTooBig,
  errorManyFiles,
  uploadingSentence,
  allowedMimeTypes = DEFAULT_ALLOWED_MIME_TYPES,
  maxFileSize = DEFAULT_MAX_SIZE_FILE,
  onDrop,
  fileName = '',
  removeFile,
  uploadError,
  uploadProgress = undefined,
  backgroundColor = COLORS.GREY_LIGHT.DEFAULT,
  dotsColor = COLORS.GREY_DARK.DEFAULT,
  style,
}: SUploadDropAreaProps) => {
  const dropRef = React.useRef<HTMLDivElement>(null);
  const dragCounter = React.useRef(0);
  const [dragging, setDragging] = useState(false);
  const [isDragError, setIsDragError] = useState(false);
  const [dropErrorNr, setDropErrorNr] = useState<number | undefined>(undefined);

  const backgroundColorStyle = { backgroundColor: dragging || fileName ? backgroundColor : COLORS.WHITE.DEFAULT };
  const borderColorStyle = dragging
    ? { borderColor: dotsColor, borderStyle: 'solid' }
    : { borderColor: COLORS.GREY_DARK.DEFAULT };

  const firstWord = defaultSentence.substr(0, defaultSentence.indexOf(' '));
  const lastSentence = defaultSentence.substr(defaultSentence.indexOf(' '), defaultSentence.length);

  const hasFileName = fileName && !isDragError && !dragging && (uploadProgress === 0 || uploadProgress === undefined);
  const isDefaultState = !dragging && !uploadProgress && (!fileName || isDragError);

  const handleRemoveFile = () => {
    if (!removeFile) return;
    removeFile();
  };

  const handleDragIn = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const files = e?.dataTransfer?.items;

      dragCounter.current += 1;

      if (files && files.length > 0) {
        const isDragInError = !allowedMimeTypes.includes(files[0].type);

        setIsDragError(isDragInError);
        setDragging(true);
      }
    },
    [allowedMimeTypes],
  );

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;

    if (dragCounter.current > 0) return;

    setDragging(false);
  };

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const checkFile = useCallback(
    (files: FileList): number | undefined => {
      const file = files[0];
      if (files.length > 1) return ERROR_DROP.TOO_MANY_FILES;
      if (!allowedMimeTypes.includes(file.type)) return ERROR_DROP.UNSUPPORTED_FILE;
      if (file.size && file.size > maxFileSize) return ERROR_DROP.SIZE_TOO_BIG;

      return undefined;
    },
    [allowedMimeTypes, maxFileSize],
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter.current = 0;

      const files = e?.dataTransfer?.files;

      if (!files) return;

      const dropError = checkFile(files);
      setDragging(false);
      setDropErrorNr(dropError);

      if (dropError === undefined) {
        onDrop(files[0]);
      }
    },
    [checkFile, onDrop],
  );

  const handleUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) return;

    const uploadErrorNr = checkFile(files);

    setDropErrorNr(uploadErrorNr);

    if (uploadErrorNr === undefined) {
      onDrop(files[0]);
    }
  };

  const handleSelectionReset = (e: MouseEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value = '';
  };

  const cleanUp = useCallback(() => {
    const div = dropRef.current;

    div?.removeEventListener('dragenter', handleDragIn);
    div?.removeEventListener('dragleave', handleDragOut);
    div?.removeEventListener('dragover', handleDrag);
    div?.removeEventListener('drop', handleDrop);
  }, [handleDragIn, handleDrop]);

  useEffect(() => {
    cleanUp();
    const div = dropRef.current;

    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleDrop);

    return cleanUp;
  }, [cleanUp, handleDragIn, handleDrop]);

  return (
    <div
      ref={dropRef}
      data-test-id="droparea-wrapper"
      style={{ ...styles.wrapper, ...backgroundColorStyle, ...borderColorStyle, ...style }}
    >
      {isDefaultState && (
        <div style={styles.wrapperDefault}>
          <div style={{ ...styles.icon, borderColor: COLORS.TEXT.ACCENTUATED }}>
            <UIcon color={COLORS.TEXT.ACCENTUATED} size={20} name="upload" />
          </div>
          <div style={styles.defaultSentence}>
            <label htmlFor="file">
              <UTextLink
                type="accentuated"
                text={firstWord}
                style={styles.firstWordDefault}
                marginLeft={0}
                marginRight={0}
              />
              <input
                type="file"
                id="file"
                name="file"
                style={styles.inputUpload}
                onClick={handleSelectionReset}
                onChange={handleUploadFiles}
              />
            </label>
            {lastSentence}
          </div>
          <div style={styles.defaultLabel}>{defaultLabel}</div>
          {(dropErrorNr || dropErrorNr === 0) && (
            <ErrorMessage
              errorIncorrectFormat1={errorIncorrectFormat1}
              errorIncorrectFormat2={errorIncorrectFormat2}
              errorSizeTooBig={errorSizeTooBig}
              errorManyFiles={errorManyFiles}
              dropErrorNr={dropErrorNr}
            />
          )}
        </div>
      )}
      {dragging && !uploadProgress && (
        <DraggingMessage
          isDragError={isDragError}
          warnSentence1={warnSentence1}
          warnSentence2={warnSentence2}
          dropSentence={dropSentence}
        />
      )}
      {uploadError && (
        <div style={styles.errorSentence} data-test-id="droparea-upload-error">
          <UMarkdown markdown={uploadError} />
        </div>
      )}
      {uploadProgress !== undefined && uploadProgress > 0 && (
        <div data-test-id="droparea-upload-progress">
          <div style={styles.uploadText}>{uploadingSentence}</div>
          <div style={{ ...styles.upload, width: `${uploadProgress}%` }} />
        </div>
      )}
      {hasFileName && (
        <div style={styles.renderFile} data-test-id="droparea-file-name">
          <div style={styles.iconText}>
            <div style={styles.fileIcon}>
              <UIcon color={COLORS.TEXT.ACCENTUATED} size={32} name="pdf" />
            </div>
            <div style={styles.fileName}>{shortenFileName(fileName)}</div>
          </div>
          <div onClick={handleRemoveFile} style={styles.removeFile} data-test-id="droparea-remove-file">
            <UIcon color={COLORS.BLACK.DEFAULT} size={13} name="close" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SUploadDropArea;
