/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Display a Selector Toggle component
 *
 * - onClick: Function called on component click
 * - selected: Define if the component should be selected or not at mount
 * - style: Override the style
 */

import * as React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './USelectorToggle.style';


type Props = {|

  // Require
  onClick: (isSelected: boolean) => mixed,

  // Optional
  selected: boolean,
  style: Object,
  enableHover: boolean,
  hasContrastBackground: boolean,
|};

type State = {|
  isSelected: boolean,
  isHover: boolean,
|};

export class USelectorToggle extends React.Component<Props, State> {
  static defaultProps = {
    selected: false,
    style: undefined,
    enableHover: true,
    hasContrastBackground:false,
  };

  constructor(props: Props) {
    super(props);

    const { selected, enableHover } = this.props;

    this.state = {
      isSelected: selected,
      isHover: !enableHover,
    };
  }

  render() {
    const { style, hasContrastBackground } = this.props;
    const { isSelected, isHover } = this.state;
    const unselectedStyle = hasContrastBackground ? styles.unselectedContrastWrapper : styles.unselectedWrapper
    const backgroundStyle = isSelected ? styles.selectedWrapper : unselectedStyle;
    const hoverStyle = !isSelected && !isHover ? styles.unselectedIdle : null;
    const cursor = isHover ? 'pointer' : 'default';

    return (
      <div
        onClick={this.handleClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        style={{ ...styles.wrapper, cursor, ...hoverStyle, ...style }}
      >
        <div style={{ ...styles.wrapperCircle, ...backgroundStyle }}>{this.renderIcon()}</div>
      </div>
    );
  }

  renderIcon = () => {
    const { isSelected } = this.state;

    if (!isSelected) return null;

    return <UIcon name="check" size={12} color={COLORS.WHITE.DEFAULT} />;
  };

  handleClick = () => {
    const { onClick } = this.props;
    const { isSelected } = this.state;

    this.setState({ isSelected: !isSelected }, () => onClick(!isSelected));
  };

  handleHover = () => {
    if (!this.props.enableHover) return;
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    if (!this.props.enableHover) return;
    this.setState({ isHover: false });
  };
}

export default USelectorToggle;
