// @flow

import * as RSentry from '@sentry/react';

// FIXME since the restructuration, this version is always 0.0.0
import packageJson from '../../../package.json';

const DSN = 'https://ad2391d3d7b94425af72bf01324bf523@o95855.ingest.sentry.io/5686880';

const BUNDLE_ENVIRONMENT_MAP = {
  'test': 'test',
  'test2': 'test',
  'test3': 'test',
  'test4': 'test',
  'check': 'beta',
  'hippolyte': 'test',
  'joan': 'test',
  'micka': 'test',
  'florent': 'test',
  'alex': 'test',
  'joachim': 'test',
};

class Sentry {
  initialized = false;
  RSentry = null;

  init(instanceId: string) {
    const devMode = process.env.NODE_ENV === 'development';

    if (devMode || this.initialized)
      return Promise.resolve();

    this.RSentry = RSentry;
    this.initialized = true;

    const { version } = packageJson;
    const environment = BUNDLE_ENVIRONMENT_MAP[instanceId] || 'production';

    return Promise.resolve(RSentry
      .init({
        dsn: DSN,
        release: `backoffice@${version}`,
        integrations: (integrations) =>  {
          // The integration TryCatch does not render the React when an error is occured so it is disabled
          return integrations.filter((integration) => integration.name !== "TryCatch");
        },
        environment,
      })
    );
  }

  registerContext(playerId: ?number, instanceId: ?string) {
    RSentry.configureScope((scope) => {
      scope.setUser({ id: playerId ? playerId.toString() : '' });
      scope.setTag('instance', instanceId || '');
    });
  }

  captureException(exception: Error, options: Object) {
    if (this.initialized)
      RSentry.captureException(exception, options);
  }
}

export default new Sentry();
