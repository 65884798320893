// @flow

import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    flex: 1,
    height: 25,
    width: '100%',
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
};
