import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { redirect } from 'Libs/routing/redirect';

import SShortcutCard from 'Components/structural/SShortcutCard/SShortcutCard';

type BreakingNewsBlockProps = {
  title: string;
  size: '100%' | '50%' | '33%';
  status?: 'disabled' | 'active' | 'locked';
  align?: 'left' | 'center';
  link?: string;
  style?: CSSProperties;
};

const noop = () => {};
const redirectToBreakingNews = () => redirect('/activity/breakingnews/new');

const BreakingNewsBlock = ({
  title,
  style,
  size,
  link,
  status = 'active',
  align = 'center',
}: BreakingNewsBlockProps) => {
  const { t } = useTranslation();
  return (
    <SShortcutCard
      illustration="breaking-new"
      title={title}
      body={t('home:start_here_block.breaking_news_description')}
      link={link}
      onLearnMorePress={noop}
      onNavigationClick={redirectToBreakingNews}
      size={size}
      status={status}
      align={align}
      style={style}
      trackingAction="create"
      trackingContext="breakingnews-from-homepage"
    />
  );
};

export default BreakingNewsBlock;
