import React from 'react';
import { t } from 'i18next';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from '../ActivityList.style';

export const EmptyList = () => {
  return (
    <>
      <UIcon name="search" color={COLORS.BLUE_GRAY_DARK.DEFAULT} size={48} style={styles.emptySearchIcon} />
      <div style={styles.emptySearchText}>{t('activities:list.no_activity_found')}</div>
    </>
  );
};
