// get instance Id from hostname
var hostname = window.location.hostname;
var match = hostname.match(/([a-zA-Z0-9]*)\.sparted\.com/);
var instanceId = 'local';
var appName = '';

if (match && match[1])
  instanceId = match[1];

var appContainer = document.head.querySelector('[name="appName"]');

if (appContainer)
  appName = appContainer.content;

export default {
  instanceId: instanceId,
  appName   : appName || instanceId,
};
