import _ from 'lodash';
import { t } from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';

var ErrorText = {
  endpoint  : 'api/ErrorTexts',
  attributes: {
    originalText: {
      type : 'pointer',
      Model: 'GameItem',
    },
    substitutes: {
      type : 'collection',
      Model: 'GameItem',
    },
    substitutePositions: {
      type: 'object',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    imageFront: {
      type : 'pointer',
      Model: 'Image',
    },
    imageBack: {
      type : 'pointer',
      Model: 'Image',
    },
    videoFront: {
      type : 'pointer',
      Model: 'Video',
    },
    videoBack: {
      type : 'pointer',
      Model: 'Video',
    },
    mediaTypeFrontId: {
      type: 'number',
    },
    mediaTypeBackId: {
      type: 'number',
    },
    originalTextId: {
      type: 'number',
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_ErrorText, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },

    mediaFront: function mediaFront(_ErrorText, media) {
      if (!media)
        return this.getMedia('Front');

      return this.setMedia('Front', media);
    },

    mediaBack: function mediaBack(_ErrorText, media) {
      if (!media)
        return this.getMedia('Back');

      return this.setMedia('Back', media);
    },

    getMedia: function getMedia(_ErrorText, panel) {
      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel]();

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel]();
    },

    setMedia: function setMedia(_ErrorText, panel, media) {
      var mediaType = media._type;

      this['mediaType' + panel + 'Id'](Enum.mediaType[mediaType]);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel](media);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel](media);
    },

    question: function question() {
      var count = this.substitutes().length;
      var instructions = t('gameplays:error_text.question', { count });

      return {
        data: function data() {
          return instructions;
        },
      };
    },

    toggleAnswer: function toggleAnswer(_ErrorText, gameItem) {
      var answer = this.answers().get(gameItem.id());

      if (!answer)
        this.answers().push(gameItem);
      else
        this.answers().removeAt(answer._i);
    },

    isRightAnswer: function isRightAnswer(_ErrorText, gameItem) {
      if (!gameItem.id())
        return false;

      return !!this.answers().get(gameItem.id());
    },

    validate: function validate() {
      var self = this;

      if (!this.originalText())
        throw new Error(t('gameplays:validation.original_text_missing'));

      if (!this.substitutes()
        || this.substitutes().length < 1
        || !areSubstitutesGood())
        throw new Error(t('gameplays:validation.substitutes_text_missing'));

      if (this.substitutes().length > 4)
        throw new Error(t('gameplays:validation.too_many_differences'));

      if (!this.explanation().data())
        throw new Error(t('gameplays:validation.explanation_missing'));
      if (!this.question().data())
        throw new Error(t('gameplays:validation.question_missing'));
      if (!this.mediaFront() || !this.mediaFront().url())
        throw new Error(t('gameplays:validation.first_media_missing'));
      if (!this.mediaBack() || !this.mediaBack().url())
        throw new Error(t('gameplays:validation.second_media_missing'));

      return true;

      function areSubstitutesGood() {
        var words = self.originalText().data().split(' ');
        var result = true;
        var subPos = self.substitutePositions();
        var tmp = 0;

        for (var pos in subPos) {
          if (subPos.hasOwnProperty(pos)) {
            var id = subPos[pos];
            var gi = id
              ? self.substitutes().get(id)
              : self.substitutes().at(tmp);

            if (!gi.data() || gi.data() === words[pos])
              result = false;
            tmp++;
          }
        }

        return result;
      }
    },

    countUseOfGameItem: function countUseOfGameItem(giId) {
      var count = 0;

      count += countInCollection(this.substitutes());
      if (this.originalText().id() === giId)
        count += 1;
      if (this.explanation().id() === giId)
        count += 1;

      return count;

      function countInCollection(collection) {
        var iteration = collection.filter(function checkId(item) {
          return item.id() === giId;
        });

        return iteration.length;
      }
    },

    deleteGameItem: function deleteGameItem(_ErrorText, giId) {
      var GameItem = Model('GameItem');

      deleteInCollection(this.substitutes());

      if (this.originalText().id() === giId)
        this.originalText(new GameItem());

      // TODO: I suspect something wrong here... to check later.
      if (this.explanation().id() === giId)
        this.question(new GameItem());

      function deleteInCollection(collection) {
        collection.searchAndRemove({
          id: giId,
        });
      }
    },

    presetFromKnowledge: function presetFromKnowledge(_ErrorText, knowledge) {
      var explanations, explanationText;

      explanations = knowledge.getDistinctGameItemByTypeId(Enum.gameItemTypes.TEXT);
      explanationText = explanations[0] || knowledge.explanation().data() || '';

      if (!this.explanation().data())
        this.explanation().data(explanationText);

      if (!this.originalText().data()) {
        var formattedExplanationText = explanationText.replace(new RegExp('\\n', 'g'), ' ').slice(0, 100);

        this.originalText().data(formattedExplanationText);
      }

      var crops = knowledge.croppedImages();

      if ((!this.mediaTypeFrontId() || this.mediaTypeFrontId() === Enum.mediaType.Image) && !this.imageFront().id()) {
        this.mediaTypeFrontId(Enum.mediaType.Image);
        this.imageFront(crops[0] || this.imageFront());
      }
      if ((!this.mediaTypeBackId() || this.mediaTypeBackId() === Enum.mediaType.Image) && !this.imageBack().id()) {
        this.mediaTypeBackId(Enum.mediaType.Image);
        this.imageBack(crops[1] || this.imageBack());
      }
    },

    getFalsyText: function getFalsyText(_ErrorText) {
      var oText = this.originalText();
      var positions = this.substitutePositions();
      var substitutes = this.substitutes();

      if (!oText.data())
        return [];

      var words = oText.data().split(' ');
      var tmp = 0;

      for (var pos in positions) {
        if (positions.hasOwnProperty(pos)) {
          var id = positions[pos];
          var sub = id
            ? substitutes.get(id)
            : substitutes.at(tmp);

          if (sub)
            words[pos] = sub.data();
          tmp++;
        }
      }

      return words;
    },
    getDirective: function getDirective(_ErrorText, data) {
      var self = this;

      if (!this.mediaFront() || !this.mediaFront().url())
        return t('gameplays:directives.add_media');
      if (!this.mediaBack() || !this.mediaBack().url())
        return t('gameplays:directives.add_media');
      if (!self.explanation().data())
        return t('gameplays:directives.enter_explanation');
      if (!self.originalText().data())
        return t('gameplays:directives.right_sentence');
      if (data.state === 'missmatch')
        return t('gameplays:directives.sentence_missmatch');
      if (data.state === 'boundaries')
        return t('gameplays:directives.sentence_boundaries');
      if (data.state !== 'correct')
        return t('gameplays:directives.finish_editing');

      return '';
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_QCM, knowledgeId) {
      return {
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
        originalText: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
};

export default Model.register('ErrorText', ErrorText);
