// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';
import { getCdnUrl } from 'Libs/cdn';

export default {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  leftWrapper: {
    display: 'flex',
    minWidth: 550,
    maxWidth: 550,
    height: '100%',
    background: COLORS.WHITE.DEFAULT,
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.15)',
  },
  leftContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 100,
  },
  rightWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#295075',
    backgroundImage: `url(${getCdnUrl('common', '/sparted-landing-background.svg')}), linear-gradient(to right, #295075, #173150)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  rightContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
  },
  instanceLogo: {
    height: 40,
    marginBottom: 56,
    objectFit: 'contain',
    objectPosition: '0 0',
  },
  spartedLogo: {
    width: 136,
  },
  rightPrimaryText: {
    ...TYPOGRAPHY.HERO_XL,
    fontWeight: 700,
    color: COLORS.WHITE.DEFAULT,
    textAlign: 'center',
    marginTop: 75,
    whiteSpace: 'pre-wrap',
  },
  rightSecondaryText: {
    ...TYPOGRAPHY.H2,
    color: COLORS.WHITE.DEFAULT,
    textAlign: 'center',
    marginTop: 32,
    whiteSpace: 'pre-wrap',
  },
};
