import React, { useCallback, useState, useEffect } from 'react';
import type { CSSProperties, FunctionComponent } from 'react';

import { useTimeoutCallback } from 'Hooks/useTimeoutCallback/useTimeoutCallback';

import MModalGroup, { MModalGroupItem } from 'Components/modal/MModalGroup/MModalGroup';
import UIllustration from 'Components/unit/UIllustration/UIllustration';
import SCourseCard from 'Components/structural/SCourseCard/SCourseCard';

import Enum from 'Models/Enum';
import styles from './MCourseDelete.style';

export type MCourseDeleteStep = 'confirm' | 'success';
export type MCourseDeleteProps = {
  courseId: number;
  courseTitle: string;
  courseLanguage: string;
  courseImage?: string;
  courseStatus: 'published' | 'pending' | 'unpublished';
  visible: boolean;
  onConfirm: (courseId: number) => Promise<void>;
  onCancel: () => void;
  style?: CSSProperties;
};

export const SUCCESS_MODAL_TIMEOUT = 1500;

/**
 * Display a modal to confirm a course deletion
 *
 * Props:
 * - style: custom style to override the modal's style
 * - visible: if the modal is visible on the screen or not
 * - onConfirm: function called when the action button is pressed
 * - onCancel: function called when the close or cancel buttons are pressed
 */
export const MCourseDelete: FunctionComponent<MCourseDeleteProps> = ({
  visible,
  courseId,
  courseTitle,
  courseImage,
  courseStatus,
  courseLanguage,
  onCancel,
  onConfirm,
  style = {},
}) => {
  const [step, setStep] = useState<MCourseDeleteStep>('confirm');

  const handleRequestEnd = useCallback((result: any) => {
    if (!result?.error) {
      setStep('success');
    }

    return Promise.resolve();
  }, []);

  const hideSuccessModal = useTimeoutCallback(
    useCallback(() => {
      onCancel();
    }, [onCancel]),
    SUCCESS_MODAL_TIMEOUT,
  );

  const handleAction = useCallback(() => onConfirm(courseId), [courseId, onConfirm]);
  const handleCancel = useCallback(() => {
    const { clear } = hideSuccessModal;

    clear();
    onCancel();
  }, [onCancel, hideSuccessModal]);

  const handleSuccessStepDuration = useCallback(
    (stepEnd: string) => {
      if (stepEnd !== 'success') {
        return;
      }

      hideSuccessModal.exec();
    },
    [hideSuccessModal],
  );

  useEffect(() => {
    // Reset the modal step when hiding the modal
    if (!visible) {
      setStep('confirm');
    }
  }, [visible]);

  return (
    <MModalGroup visible={visible} currentStep={step} onClose={handleCancel} onStepEnd={handleSuccessStepDuration}>
      <MModalGroupItem
        step="confirm"
        style={style}
        labelActionButton="Delete"
        labelSecondButton="Cancel"
        onAction={handleAction}
        onActionEnd={handleRequestEnd}
        onSecondAction={handleCancel}
        title="You are about to delete this course:"
        actionButtonType="destructive"
        type="dialog"
        showExitCross
      >
        <div style={styles.confirmContainer}>
          <SCourseCard
            style={styles.courseCard}
            id={courseId}
            title={courseTitle}
            language={(Enum.Languages as any)[courseLanguage]}
            status={courseStatus}
            image={courseImage}
            size="S"
            isStatic
          />
          <div style={styles.warning}>Deleting it will unpublish and permanently erase it.</div>
        </div>
      </MModalGroupItem>
      <MModalGroupItem step="success" style={style} type="dialog" showExitCross>
        <div style={styles.successContainer}>
          <div style={styles.successLabel}>Your course has been successfully deleted</div>
          <div style={styles.successIllustration}>
            <UIllustration illustration="trash" validated />
          </div>
        </div>
      </MModalGroupItem>
    </MModalGroup>
  );
};

export default MCourseDelete;
