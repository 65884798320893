import React from 'react';

import type { CSSProperties, FunctionComponent } from 'react';

import { COLORS } from 'Components/foundation';

import UChips from 'Components/unit/UChips/UChips';

import styles from './STruncatedChipList.style';

export type ChipItem = {
  id: number;
  label: string;
  color?: string;
};
export type STruncatedChipListProps = {
  style?: CSSProperties;
  chips: ChipItem[];
  /**
   * The max number of chips displayed. If `chips.length` is above max,
   * it will aggregate the remaining chips into a dropdown. If undefined,
   * it will display all chips.
   */
  max?: number;
  /**
   * The label of the aggregated dropdown. Default to 'More'.
   */
  aggregatedDropdownLabel?: string;
  showCloseButtonOnChips?: boolean;
  onChipClose?: (id: any) => void;
};

export const STruncatedChipList: FunctionComponent<STruncatedChipListProps> = ({
  style = {},
  max,
  chips,
  aggregatedDropdownLabel = 'More',
  showCloseButtonOnChips = false,
  onChipClose = () => {},
}) => {
  if (max && max > 0 && chips.length > max) {
    return (
      <div style={style}>
        <UChips
          value={aggregatedDropdownLabel}
          type="aggregated"
          color={COLORS.GREY_MEDIUM.DEFAULT}
          aggregateItems={chips.map((chip) => ({
            isCloseVisible: showCloseButtonOnChips,
            value: chip.label,
            color: chip.color,
            id: chip.id,
          }))}
          onAction={(_category: string, id: number) => {
            onChipClose(id);
          }}
          style={styles.chipStyle}
        />
      </div>
    );
  }

  return (
    <div style={style}>
      {chips.map((chip) => (
        <UChips
          value={chip.label}
          color={chip.color}
          key={chip.id}
          isCloseVisible={showCloseButtonOnChips}
          onAction={() => onChipClose(chip.id)}
          type="standard"
          style={styles.chipStyle}
        />
      ))}
    </div>
  );
};

export default STruncatedChipList;
