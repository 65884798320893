import m from 'm';
import Enum from 'models/Enum';
import contentPreviewImage from 'components/content.preview.image';
import contentPreviewQuestion from 'components/content.preview.question';

var component = {};

component.view = function view(c, args) {
  var question = args.content.game().question().data();
  var image = args.content.game().mediaTypeFrontId() === Enum.mediaType.Image
    ? args.content.game().imageFront().cdn({transformation: {width: 300}})
    : args.content.game().videoFront().getOriginalThumbnailSync();
  var choices = args.content.game().sqcmChoices();
  var lastChoice = choices.at(choices.length - 1);

  return m('.preview-qcm', [
    m(contentPreviewImage, {
      image: image,
    }),
    m(contentPreviewQuestion, {
      question: question,
    }),
    m('.preview-qcm__block-choices', [
      m('.preview-qcm__block-choice.preview-qcm__block-choice-three', [
        m('.preview-qcm__choice', lastChoice.gameItem().data()),
      ]),
      m('.preview-qcm__block-choice.preview-qcm__block-choice-three.preview-qcm__block-choice--blank'),
      m('.preview-qcm__block-choice.preview-qcm__block-choice-three.preview-qcm__block-choice--blank'),
    ]),
  ]);
};
export default component;
