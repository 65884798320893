import React, { useEffect, useState, useCallback } from 'react';
import Promise from 'bluebird';

import { tracking } from 'Services/trackingService';

import { AuthenticationSteps } from 'Models/Enum';

import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import SInput from 'Components/structural/SInput/SInput';
import SPasswordInput from 'Components/structural/SPasswordInput/SPasswordInput';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { redirect } from 'Libs/routing';

import { actions } from '../../redux';

import styles from './SignUpForm.style';

type Props = {
  instanceName: string;
  token: string;
};

export const SignUpForm = ({ token, instanceName }: Props) => {
  const dispatch = useTypedDispatch();

  const error = useTypedSelector((state) => state.pages.signUp.error);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onFirstNameChange = useCallback((_firstName) => setFirstName(_firstName), []);
  const onLastNameChange = useCallback((_lastName) => setLastName(_lastName), []);
  const onPasswordChange = useCallback((_password) => setPassword(_password), []);
  const onValidationChange = useCallback((validation) => setIsPasswordValid(validation), []);

  const onSubmit = useCallback(() => {
    if (firstName && lastName && isPasswordValid) {
      const activateAction = actions.activate(token, firstName, lastName, password);

      dispatch(activateAction);

      return Promise.resolve(activateAction.promise);
    }

    return Promise.resolve();
  }, [dispatch, token, firstName, lastName, password, isPasswordValid]);

  const onActivationEnd = useCallback(
    async (res) => {
      const { error: payloadError, type } = res;

      if (payloadError) {
        return;
      }

      const event = new Event('user.login');

      await document.dispatchEvent(event);

      if (type === AuthenticationSteps.TWO_FACTOR_CREDENTIALS_REQUEST) {
        redirect(`/two-factor/enter-phone?invitationToken=${token}`, { shouldReplaceHistory: true });

        return;
      }

      tracking.contributorsSignUp();

      window.location.reload();
    },
    [token],
  );

  useEffect(() => {
    if (firstName.length && lastName.length && isPasswordValid) {
      return setIsButtonDisabled(false);
    }

    return setIsButtonDisabled(true);
  }, [firstName, lastName, isPasswordValid]);

  return (
    <div style={styles.signupWrapper}>
      <div style={styles.welcome}>Welcome</div>
      <div style={styles.instruction}>
        <span>{'Join the '}</span>
        <span style={styles.instanceName}>{instanceName}</span>
        <span>{' team'}</span>
      </div>
      <div style={styles.instructionMore}>Create your personal account before entering your back office.</div>
      <form autoComplete="off" style={styles.inputWrapper}>
        <SInput
          type="small"
          name="firstName"
          placeholder="First Name"
          value={firstName}
          onChange={onFirstNameChange}
          autoComplete="off"
          autofocus
        />
        <SInput
          style={styles.lastNameInput}
          type="small"
          name="lastName"
          placeholder="Last Name"
          value={lastName}
          onChange={onLastNameChange}
          autoComplete="off"
        />
        <SPasswordInput
          style={styles.passwordInput}
          placeholder="Password"
          value={password}
          requireMinCharacters={8}
          requireMinNumber={1}
          requireMinUppercase={1}
          requireMinSpecialCharacters={1}
          onChange={onPasswordChange}
          onChecksValidationChange={onValidationChange}
          // It's the safest way to force user to use new password (MDN doc: https://tinyurl.com/4bvu2dte)
          autoComplete="new-password"
        />
      </form>
      <div style={styles.buttonWrapper}>
        <UButtonWithLoading
          type="accentuated"
          text="Enter your back office"
          onClick={onSubmit}
          onRequestEnd={onActivationEnd}
          disabled={isButtonDisabled}
          style={styles.button}
        />
        <div style={styles.errorMsg}>{error}</div>
      </div>
    </div>
  );
};
