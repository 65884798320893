import { createSlice } from '@reduxjs/toolkit';
import { ThematicState } from './thematic.types';
import { thematicAdapter } from './thematic.selectors';
import { fetchThematic } from './thematic.thunks';

export const thematicState: ThematicState = thematicAdapter.getInitialState();

export const { actions: thematicActions, reducer: thematicReducer } = createSlice({
  name: 'thematics',
  initialState: thematicState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchThematic.fulfilled, (state, payload) => {
      return thematicAdapter.setAll(state, payload.payload);
    });
  },
});
