// @flow

/**
 * You can document your component here
 */

import * as React from 'react';

import styles from './UDropdownDivider.style';


type Props = {|
  style?: Object,
|};

export class UDropdownDivider extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.wrapper, ...style }} />
    );
  }
}

export default UDropdownDivider;
