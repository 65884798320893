import m from 'm';
import KEY from 'keys';
import T from 'types';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.opened = m.prop(false);

  self.active = 0;

  self.check = function check(e) {
    var key = e.which;

    if (key === KEY.ENTER)
      self.select(self.filtered[self.active]);
    else if (key === KEY.DOWN)
      self.active += self.active < self.filtered.length - 1 ? 1 : 0;
    else if (key === KEY.UP)
      self.active -= self.active ? 1 : 0;
  };

  self.select = function select(value) {
    args.value(value);
    self.active = 0;
    if (args.onselect)
      args.onselect();
  };

  self.getValueFromItem = function getValueFromItem(item) {
    var path = args.path;
    var value;

    if (path)
      value = T.is.ft(item[path]) ? item[path]() : item[path];
    else
      value = T.is.ft(item) ? item() : item;

    value = value.label ? value.label() : value;

    return value;
  };

  self.value = function getValue() {
    // value needs to be a m.prop()

    // 1. value = m.prop('')
    // 2. value[path] = m.prop('')
    // 3. value()[path] = m.prop('')

    // 1
    var value = args.value;
    var path = args.path;

    if (value === undefined || value === null) {
      console.error('select:value invalid', args);

      return m.prop('');
    }

    if (path) {
      if (T.is.ft(value)) {
        if (value() === undefined || value() === null) {
          // console.log('select:value undefined', args);
          value = m.prop('');
        } else

          // 3
          value = value()[path] || m.prop('');

      } else

        // 2
        value = value[path] || m.prop('');
    }

    if (value().label)
      value = value().label || m.prop('');

    if (!T.is.ft(value)) {
      console.error('Value is not a function', value);

      return value;
    }

    return value();
  };
};

component.view = function view(c, args) {
  return m('.aduur', {
    class: [
      'popup__input',
      'popup__input__text',
      args.class || 'input--none',
    ].join(' '),
    onclick: c.opened.bind(null, !c.opened()),
  }, [
    m('.aduur__arrow'),
    m('input.aduur__value', {
      value      : c.value(),
      placeholder: args.placeholder,
      class      : c.value() !== '' ? 'value' : 'placeholder',
    }),
    m('.aduur__opened', {
      class: c.opened() ? '' : 'hide',
    }, [
      m('.aduur__list', [
        args.data.map(function iterateData(item, i) {
          return m('.aduur__item', {
            class  : c.active === i ? 'active' : '',
            onclick: c.select.bind(null, item),
          },
            c.getValueFromItem(item)
          );
        }),
      ]),
    ]),
  ]);
};

export default component;
