// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './ArchiveActivityModal.style';

type Props = {
  visible: boolean,
  onAction: (success: boolean) => mixed,
  isArchiveLoading: boolean,
};

export const ArchiveActivityModal = ({ visible, onAction, isArchiveLoading }: Props) => {
  const onDismiss = useCallback(() => onAction(false), [onAction]);
  const onValidate = useCallback(() => onAction(true), [onAction]);

  return (
    <MModal
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('activities:modals.archive_activity.title')}
      labelActionButton={t('activities:modals.archive_activity.button')}
      labelSecondButton={t('activities:modals.archive_activity.cancel')}
      actionButtonType="destructive"
      isActionLoading={isArchiveLoading}
    >
      <div style={styles.content}>
        {t('activities:modals.archive_activity.content')}
      </div>
    </MModal>
  );
};
