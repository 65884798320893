// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: 16,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  text: {
    marginRight: 16,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 400,
  },
  horizontalDivider: {
    display: 'flex',
    flex: 1,
    height: 1,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    transform: 'translateY(1px)',
  },
  verticalDivider: {
    display: 'inline-block',
    width: '1px',
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    height: 24,
    margin: '0px 4px 0px 4px',
  },
  verticalDividerWithText: {
    display: 'inline-block',
    width: '1px',
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
    height: 16,
  },
};
