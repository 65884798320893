import React from 'react';

import { AddDailySessionButton } from '../AddDailySessionButton/AddDailySessionButton';
import { Info } from '../Info/Info';
import { TranslationInfo } from '../TranslationInfo/TranslationInfo';
import { Schedule } from '../Schedule/Schedule';

import styles from './SchedulingContent.style';

export const SchedulingContent = () => (
  <>
    <Info />
    <div style={styles.wrapper}>
      <TranslationInfo />
      <Schedule />
      <AddDailySessionButton />
    </div>
  </>
);
