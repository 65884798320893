// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  input: {
    backgroundColor: 'transparent',
    height: '28px',
    width: '75px',
    marginLeft: '8px',
    border: 'none',
    outline: 'none',
    ...TYPOGRAPHY.BODY3,
  },
  expanded: {
    width: '150px',
  },
};
