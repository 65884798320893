import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const styles = createStyleSheet({
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  textAreaWrapper: {
    width: '100%',
    minWidth: '30px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
  },
  textAreaField: {
    minHeight: '100px',
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    paddingRight: 0,
    outline: 'none',
    resize: 'vertical',
    padding: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  focused: {
    borderColor: COLORS.GREY_DARK.OUTLINE_FOCUSED,
  },
  noResize: {
    resize: 'none',
  },
  errorMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DEFAULT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  errorIcon: {
    marginRight: 6,
  },
  textDisabled: {
    color: COLORS.TEXT.DISABLED_DEFAULT,
  },
  disabled: {
    borderColor: COLORS.GREY_DARK.OUTLINE_DISABLED,
  },
  labelAndCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  count: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    fontWeight: 400,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'end',
  },
  countReached: {
    color: COLORS.ERROR.DARK,
  },
});

export default styles;
