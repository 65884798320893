import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'Pages/Homepage/Homepage.style';

import BreakingNewsBlock from './BreakingNewsBlock';
import CampaignBlock from './CampaignBlock';
import KnowledgeBlock from './KnowledgeBlock';

const KNOWLEDGE_URL = 'https://support.sparted.com/hc/en-us/articles/360019613699-Game-Manual';
const CAMPAIGN_URL =
  'https://support.sparted.com/hc/en-us/articles/360015533011-The-essentials-for-creating-a-good-campaign';
const BREAKING_NEWS_URL = 'https://support.sparted.com/hc/en-us/articles/5900726700562';

const FinalState = () => {
  const { t } = useTranslation();

  return (
    <>
      <KnowledgeBlock
        title={t('home:start_here_block.knowledge_title')}
        size="33%"
        link={KNOWLEDGE_URL}
        align="left"
        style={styles.blockMargin}
      />
      <CampaignBlock
        title={t('home:start_here_block.campaign_title')}
        size="33%"
        link={CAMPAIGN_URL}
        align="left"
        style={styles.blockMargin}
      />
      <BreakingNewsBlock
        title={t('home:start_here_block.breaking_news_title')}
        size="33%"
        align="left"
        link={BREAKING_NEWS_URL}
      />
    </>
  );
};

export default FinalState;
