
    var KEYS = {
      DELETE: 8,
      TAB   : 9,
      ENTER : 13,
      ESCAPE: 27,
      SPACE : 32,
      LEFT  : 37,
      UP    : 38,
      RIGHT : 39,
      DOWN  : 40,
      SUPPR : 46,
      A     : 65,
      C     : 67,
      V     : 86,
      X     : 88,
      Z     : 90,
    };

    KEYS.isSelectAll = function isSelectAll(e) {
      return e.keyCode === KEYS.A && e.metaKey === true;
    };

    KEYS.isUndo = function isUndo(e) {
      return e.keyCode === KEYS.Z && e.metaKey === true;
    };

    KEYS.isCut = function isCut(e) {
      return e.keyCode === KEYS.X && e.metaKey === true;
    };

    KEYS.isCopy = function isCopy(e) {
      return e.keyCode === KEYS.C && e.metaKey === true;
    };

    KEYS.isPaste = function isPaste(e) {
      return e.keyCode === KEYS.V && e.metaKey === true;
    };

    export default KEYS;

