import App from 'Models/App';

export type AppModel = any;

type SegmentationGroupItem = {
  id: number;
  isDefault: boolean;
  label: string;
  availableGroupId: number | null;
};

type AvailableSegmentationGroup = {
  id: number;
  typeId: number;
  label: string;
  data: string;
};

type AvailableSegmentationGroups = Record<string, AvailableSegmentationGroup>;

export const getAvailableSegmentationGroupsFromApp = (mithrilAppModel: AppModel = App): AvailableSegmentationGroup[] => {
  const { AvailableSegmentationGroups } = mithrilAppModel;

  return AvailableSegmentationGroups().items.map(
    (item: any) => item.toJSON(),
  );
};

export const getLanguageSegmentationGroupItemsFromApp = (mithrilAppModel: AppModel = App): SegmentationGroupItem[] => {
  const { languages } = mithrilAppModel;

  return languages().items.map(
    (item: any) => item.toJSON(),
  );
};

export const getAvailableSegmentationGroupsMapFromApp = (mithrilAppModel: AppModel = App): AvailableSegmentationGroups => {
  const availableSegmentationGroups = getAvailableSegmentationGroupsFromApp(mithrilAppModel);

  return availableSegmentationGroups.reduce((acc, availableGroupItem) => ({
    ...acc,
    [availableGroupItem.id]: availableGroupItem,
  }), {});
};
