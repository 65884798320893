import m from 'm';
import { t } from 'i18next';
import App from 'models/App';
import Enum from 'models/Enum';
import button from 'components/button';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.tabs = m.prop(processGameplays());
  self.selectedTab = m.prop(selectTab(args.gameplayId));

  function processGameplays() {
    return App.gameplays()
      .filter(isGamePlayUsable);
  }

  function isGamePlayUsable(gp) {
    var BLACKLISTED = [
      Enum.gameplay.SQCM,
    ];

    return BLACKLISTED.indexOf(gp.id()) < 0;
  }

  function selectTab(gameplayId) {
    if (gameplayId === undefined)
      return 0;

    for (var i = 0; i < self.tabs().length; i++) {
      if (self.tabs()[i].id() === gameplayId)
        return i;
    }

    return 0;
  }

  self.use = function use(_args, gameplayId) {
    _args.close();
    m.route([
      '/knowledge/',
      _args.knowledgeId,
      '/content/new?gameplayId=',
      gameplayId,
    ].join(''));
  };
};

component.view = function view(c, args) {
  var tabs = c.tabs();

  return m('.popup__select-gameplay', [
    m('.popup__select-gameplay__top', [
      m('.popup__select-gameplay__title', t('gameplays:select_gameplay_modal.title')),
      m('.popup__select-gameplay__tabs', [
        makeTabs(tabs),
        m('.popup__select-gameplay__tabs__border', {
          style: {
            backgroundColor: currentColor(),
          },
        }),
      ]),
    ]),
    m('.popup__select-gameplay__content', makeTabsContent(tabs)),
  ]);

  function currentColor() {
    var tab = tabs[c.selectedTab()];

    if (!tab?.color)
      return '#999999';

    return tab.color();
  }

  function makeTabs(_tabs) {
    return _tabs.map(makeTab);

    function makeTab(tab, n) {
      const isCurrent = c.selectedTab() === n;

      return m('.popup__select-gameplay__tab.popup__select-gameplay__tab--' + tab.name(), {
        style: !isCurrent ? undefined : {
          backgroundColor: currentColor(),
        },
        onclick: c.selectedTab.bind(null, n),
      }, m('.popup__select-gameplay__tab__ico.popup__select-gameplay__tab__ico--' + tab.name()));
    }
  }

  function makeTabsContent(_tabs) {
    return _tabs.map(makeContent).filter(notNull);

    function notNull(o) {
      return o !== null;
    }

    function makeContent(tab, n) {
      if (c.selectedTab() !== n)
        return null;

      return [
        makeLeftSide(tab),
        makeRightSide(tab),
        m('.clearFix'),
      ];
    }

    function makeLeftSide(tab) {
      var videoInfo = m('.popup__select-gameplay__tab__content__video__infos', [
        m('.popup__select-gameplay__tab__content__video__infos__title', [
          t('gameplays:rules'),
        ]),
        m('pre.popup__select-gameplay__tab__content__video__infos__points-earning-rules', tab.pointsEarningRules()),
      ]);

      var youtubeVideoEmbed = null;

      if (tab.youtubeVideoId()) {
        youtubeVideoEmbed = m('iframe.popup__select-gameplay__tab__content__video__embbed', {
          src        : 'https://youtube.com/embed/' + tab.youtubeVideoId(),
          frameborder: 0,
        });
      } else {
        youtubeVideoEmbed = m('.popup__select-gameplay__tab__content__video__embbed--no-video', [
          m('.popup__select-gameplay__tab__content__video__embbed--no-video__message', t('gameplays:select_gameplay_modal.video_not_available'),),
        ]);
      }

      return m('.popup__select-gameplay__tab__content__left', [
        m('.popup__select-gameplay__tab__content__video', [
          youtubeVideoEmbed,
          videoInfo,
        ]),
      ]);
    }

    function makeRightSide(tab) {
      var alreadyCreatedInKnowledge = args.currentGameplays.indexOf(tab.id()) > -1;
      var useThisGameplayBtn;

      if (alreadyCreatedInKnowledge) {
        useThisGameplayBtn = m(button, {
          class: 'button--action popup__select-gameplay__tab__content__button button--deactivated',
          value:  t('gameplays:select_gameplay_modal.already_used'),
        });
      } else {
        useThisGameplayBtn = m(button, {
          class : 'button--action popup__select-gameplay__tab__content__button',
          value : t('gameplays:select_gameplay_modal.use'),
          action: c.use.bind(null, args, tab.id()),
        });
      }

      return m('.popup__select-gameplay__tab__content__right', [
        m('.popup__select-gameplay__tab__content__title', tab.translatedLabel()),
        m('.popup__select-gameplay__tab__content__description', tab.description()),
        m('.popup__select-gameplay__tab__content__text', tab.text()),
        useThisGameplayBtn,
      ]);
    }
  }
};

export default component;
