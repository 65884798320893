/* eslint-disable complexity */
import Model from 'models/Model';
import Collection from 'models/Collection';
import Enum from 'models/Enum';
import request from 'services/requestService';
import _ from 'lodash';

var Player = {
  endpoint  : 'api/Players/full',
  attributes: {
    firstName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    email: {
      type  : 'string',
      unique: true,
    },
    emailVerified: {
      type: 'boolean',
    },
    username: {
      type  : 'string',
      unique: true,
    },
    password: {
      type: 'string',
    },
    realm: {
      type: 'string',
    },
    l10n: {
      type: 'string',
    },
    inactive: {
      type: 'boolean',
    },
    status: {
      type: 'string',
    },
    roles: {
      type : 'collection',
      Model: 'Role',
    },
    segmentation: {
      type : 'collection',
      Model: 'SegmentationGroupItem',
    },
    globalScore: {
      type: 'number',
    },
    active: {
      type: 'boolean',
    },
    adminValidationId: {
      type: 'number',
    },
    workLocation: {
      type: 'string',
    },
    extraInfo: {
      type: 'string',
    },
    createdAt: {
      type: 'date',
    },
    updatedAt: {
      type: 'date',
    },
    profilePictureId: {
      type: 'number',
    },
    profilePicture: {
      type : 'pointer',
      Model: 'Image',
    },
  },
  methods: {

    // TODO: Should be renamed to hasAnyRole
    hasOneRole: function hasOneRole(_Player, roles) {
      var userRoles = this.roles().map(function getIDs(o) {
        return o.id();
      });

      var common = _.intersection(userRoles, roles);

      var hasOne = common.length > 0;

      return hasOne;
    },
    fullName: function fullName() {
      var self = this;
      var full = self.firstName() + ' ' + self.lastName();

      return full.trim();
    },
    shortName: function fullName() {
      var self = this;
      var firstName = self.firstName() || '';
      var lastName = self.lastName() || '';

      if (lastName)
        lastName = lastName[0].toUpperCase() + '.';

      var shortName = [firstName, lastName].join(' ');

      return shortName.trim();
    },
    isAdmin: function isAdmin() {
      return this.hasOneRole([Enum.Role.ADMIN, Enum.Role.MASTER, Enum.Role.ROOT]);
    },
    isMasterAdmin: function isAdmin() {
      return this.hasOneRole([Enum.Role.MASTER, Enum.Role.ROOT]);
    },
    isRoot: function isRoot() {
      return this.hasOneRole([Enum.Role.ROOT]);
    },
    deactivate: function deactivate(_Player) {
      var self = this;

      return request.put('api/Players/' + self.id() + '/deactivate')
        .then(function handleResponse(user) {
          return new _Player(user);
        })
        .catch(function handleError(error) {
          console.log('[ERROR] Player.deactivate => id: ' + self.id(), error);
        });
    },
    reactivate: function reactivate(_Player) {
      var self = this;

      return request.put('api/Players/' + self.id() + '/reactivate')
        .then(function handleResponse(user) {
          return new _Player(user);
        })
        .catch(function handleError(error) {
          console.log('[ERROR] Player.reactivate => id: ' + self.id(), error);
        });
    },
  },
  classMethods: {
    findAdmins: function findAdmins(_Player) {
      return request
        .get('/api/Players/admins')
        .then(function createCollection(admins) {
          return {
            editionAllowed  : new Collection(_Player, admins.editionAllowed),
            editionForbidden: new Collection(_Player, admins.editionForbidden),
          };
        });
    },
    exportPlayers: function exportPlayers(_Player, filterInput, deactivated, order, segmentation) {
      var options = {
        where: {
          and: [
            { realm: 'FrontOffice' },
            { deleted: false },
          ],
        },
        include: [{
          relation: 'segmentation',
          scope   : {
            include: ['dimension', 'members'],
          },
        },
        'adminValidation',
        ],

      };

      if (filterInput.length) {
        options.where.and.push({
          or: filterInput.map((inputText) => {
            return {
              or: [
                {
                  firstName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  lastName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  email: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  workLocation: {
                    regexp: '/' + inputText + '/i',
                  },
                },
              ],
            };
          }),
        });
      }
      if (!deactivated) {
        options.where.and.push({
          active: true,
        });
      }

      if (order)
        options.order = order.name + ' ' + (order.asc ? 'ASC' : 'DESC');

      if (segmentation && segmentation.length)
        options.segmentation = segmentation.map((id) => ({ id }));

      return request.get('/api/players/download', { filter: options, responseType: 'arraybuffer' });
    },
    findContributors: function findContributors(_Player) {
      return request.get('api/Players/contributors')
        .then(function createCollection(contributors) {
          return new Collection('Player', Player, contributors);
        });
    },
    emailVerification: function emailVerification(_Player, token) {
      return request.post('api/Players/verifyMail', {
        token: token,
      });
    },

    passwordReset: function passwordReset(_PLayer, token, password) {
      return request.post('api/Players/resetPassword', {
        token   : token,
        password: password,
      });
    },
    askPasswordReset: function askPasswordReset(_Player, email) {
      var localization = 'en-US';

      return request
        .post('api/Players/askPasswordResetBack', {
          l10n : localization,
          email: email,
        });
    },
    findMe: function findMe(_Player) {
      return request
        .get('api/Players/me')
        .then(function handleResponse(response) {
          var player = new _Player(response.user);

          return player;
        });
    },
    findPlayerPaginated: function findPlayerPaginated(_Player, filterInput, deactivated, order, segmentation, start, length) {
      var options = {
        where: {
          and: [
            { realm: 'FrontOffice' },
            { deleted: false },
          ],
        },
        include: [{
          relation: 'segmentation',
          scope   : {
            include: ['dimension', 'members'],
          },
        },
        'adminValidation',
        'profilePicture',
        ],

      };

      if (filterInput.length) {
        options.where.and.push({
          or: filterInput.map((inputText) => {
            return {
              or: [
                {
                  firstName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  lastName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  email: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  workLocation: {
                    regexp: '/' + inputText + '/i',
                  },
                },
              ],
            };
          }),
        });
      }

      if (!deactivated) {
        options.where.and.push({
          active: true,
        });
      }

      if (order)
        options.order = order.name + ' ' + (order.asc ? 'ASC' : 'DESC');

      if (segmentation && segmentation.length)
        options.segmentation = segmentation.map((id) => ({ id }));

      return request.get('api/Players/filtered?filter=' + JSON.stringify(options) + '&start=' + start + '&length=' + length)
        .then(function createCollection(results) {
          var response = {};

          response.players = new Collection('Player', Player, results);
          response.more = length === results.length;

          return response;
        });
    },
    countBy: function countBy(_Player, filterInput, deactivated, order, segmentation) {
      var options = {
        where: {
          and: [
            { realm: 'FrontOffice' },
            { deleted: false },
          ],
        },
        include: [{
          relation: 'segmentation',
          scope   : {
            include: ['dimension', 'members'],
          },
        },
        'adminValidation',
        ],

      };

      if (filterInput.length) {
        options.where.and.push({
          or: filterInput.map((inputText) => {
            return {
              or: [
                {
                  firstName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  lastName: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  email: {
                    regexp: '/' + inputText + '/i',
                  },
                },
                {
                  workLocation: {
                    regexp: '/' + inputText + '/i',
                  },
                },
              ],
            };
          }),
        });
      }

      if (!deactivated) {
        options.where.and.push({
          active: true,
        });
      }

      if (order)
        options.order = order.name + ' ' + (order.asc ? 'ASC' : 'DESC');

      if (segmentation && segmentation.length)
        options.segmentation = segmentation.map((id) => ({ id }));

      return request.get('api/Players/count/filtered?filter=' + JSON.stringify(options));

    },
    getWithToken: function getWithToken(_Player, token) {
      return request.get('api/Players/withToken?token=' + token)
        .then(function createPlayer(data) {
          return new _Player(data);
        });
    },
  },
};

export default Model.register('Player', Player);
