// @flow

/**
 * ProfilePicture
 *
 * Props:
 *  - style: override component's style
 *  - size: the size of the component
 *  - picturePath: the path of the picture
 */

import * as React from 'react';

import styles from './UProfilePicture.style';
import ProfilePlaceholder from 'Components/svg/profile-placeholder';


type Props = $ReadOnly<{|
  style?: ?Object,
  size: number,
  picturePath: ?string,
|}>;

export class UProfilePicture extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, size, picturePath } = this.props;
    const sizeStyle = {
      height: size,
      width: size,
    };

    if (picturePath) {
      return (
        <div style={{
          ...styles.containPicture,
          ...sizeStyle,
          backgroundImage: `url('${picturePath}')`,
          ...style,
        }}
        />
      );
    }

    return (
      <ProfilePlaceholder
        height={size}
        width={size}
      />
    );
  }
}

export default UProfilePicture;
