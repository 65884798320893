import { getCdnFromS3Url } from 'Libs/cdn';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

export type PlayerType = DeepReadonly<{
  id: number;
  rank: string;
  firstName: string;
  lastName: string;
  score: string;
  profilePicture: {
    url: string;
  };
}>;

export default function createPlayer(values?: any): PlayerType {
  const defaultState: PlayerType = {
    id: 0,
    rank: '',
    firstName: '',
    lastName: '',
    score: '',
    profilePicture: {
      url: '',
    },
  };

  if (!values) {
    return defaultState;
  }

  const { firstName, lastName } = values.player;
  const score = Number(values.score).toLocaleString('en');

  const profilePicture = {
    url:
      values.player.profilePicture && values.player.profilePicture.url
        ? getCdnFromS3Url(values.player.profilePicture.url, { asThumbnail: true })
        : '',
  };

  return mergeDeep(defaultState, { ...values, firstName, lastName, profilePicture, score });
}
