import { LEVELS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'inline-block',
  },
  illustrationBackgroundWrapper: {
    position: 'relative',
  },
  illustrationWrapper: {
    zIndex: LEVELS.LOCAL_OFFSET,
    position: 'relative',
  },
  validatedBackground: {
    position: 'absolute',
    zIndex: 0,
  },
  validatedBackgroundL: {
    top: 30,
    right: 2,
  },
  validatedBackgroundM: {
    top: 20,
    right: 1,
  },
  validatedBackgroundS: {
    top: 8,
    right: 3,
  },
});

export default styleSheet;
