import m from 'm';
import { t } from 'i18next';

import Bus from 'services/pubSubService';
import activityIcon from 'components/activity.icon';
import inputText from 'components/new.new.input.text';
import inputTextarea from 'components/new.input.textarea';
import segmentationInput from 'components/segmentation.input';
import EnumMedia from 'models/MediaHandlerEnum';
import mediaSelector from 'components/activity.media.selector';

import Image from 'models/Image';
import Enum from 'models/Enum';
import reactCreator from 'components/react.creator';
import entityInput from 'components/entity.input';
import Model from 'models/Model';

var component = {};

var Activity = Model('Activity');

component.controller = function controller(args) {
  var self = this;

  self.activities = m.prop([]);
  self.activityFilter = m.prop([]);
  self.maxSelection = m.prop(1);
  self.uploading = m.prop(false);
  self.isBreakingNewsCampaignToggle = args.isBreakingNewsCampaignToggle || m.prop(false);

  if (args.activity().breakingNewsCard().campaignId())
    self.activityFilter = m.prop([new Activity(args.activity().breakingNewsCard().campaign())]);

  self.updateActivity = function updateActivity() {
    args.activity().updateSimple(args.activity)
      .then(function applyChanges(updatedActivity) {
        var finalActivity = args.activity().clone();

        finalActivity.active(updatedActivity.active());
        Bus.trigger('activity:save', finalActivity);
      });
  };

  self.switchPicture = function switchPicture(image) {
    if (!image) {
      if (args.activity().typeId() === Enum.activityTypeEnum.BREAKING_NEWS && !args.activity().coverId() && !args.activity().videoCoverId())
        return new Image({ url: 'https://sparted-prod-common.s3.eu-west-1.amazonaws.com/breaking-news-card-cover.png', id: Enum.activityTypeEnum.BREAKING_NEWS });
      if (args.activity().typeId() === Enum.activityTypeEnum.WELCOME && !args.activity().coverId())
        return new Image({ url: 'https://sparted-prod-common.s3.eu-west-1.amazonaws.com/welcome-card-cover.png', id: Enum.activityTypeEnum.WELCOME });
      if (args.activity().typeId() === Enum.activityTypeEnum.DAILY_SERIE && !args.activity().coverId())
        return new Image({ url: 'assets/img/home-dailyserie-cover.png', id: Enum.activityTypeEnum.DAILY_SERIE });
      if (args.activity().coverId())
        return args.activity().cover();

      if (args.activity().videoCoverId())
        return args.activity().videoCover();

      return null;
    }

    if (args.activity().typeId() === Enum.activityTypeEnum.BREAKING_NEWS
      && args.activity().breakingNewsCard().videoCover()) {
      args.activity().breakingNewsCard().cover(image);
      args.activity().breakingNewsCard().coverId(image.id());
    }

    args.activity().cover(image);
    args.activity().coverId(image.id());

    return m.redraw();
  };

  self.handleSegmentsChange = function handleSegmentsAdded(_args) {
    var originalSegments = [];

    if (args.activity().typeId() !== Enum.activityTypeEnum.DAILY_SERIE)
      return;

    if (args.originalActivity()) {
      originalSegments = args.originalActivity().segmentation().items
        .filter((i) => i.dimension().typeId !== Enum.dimensionTypes.LANGUAGE);
    }

    var languageSegments = _args.activity().segmentation().items
      .filter((i) => i.dimension().typeId === Enum.dimensionTypes.LANGUAGE);

    args.activity().segmentation().items = [
      ...languageSegments,
      ...originalSegments,
    ];
  };

  self.getCropProps = function getCropProps() {
    var activity = args.activity();
    var props = {};

    switch (activity.typeId()) {
      case Enum.activityTypeEnum.BREAKING_NEWS:
        props = EnumMedia.BREAKING_NEWS;
        break;

      case Enum.activityTypeEnum.WELCOME:
        props = EnumMedia.WELCOME_CARDS;
        break;

      case Enum.activityTypeEnum.DAILY_SERIE:
        props = EnumMedia.CAMPAIGN;
        break;
      default:
        break;
    }

    return props;
  };

  self.removeBreakingNewsAttributes = function removeBreakingNewsAttributes() {
    args.activity().segmentation().items = [];
    args.activity().breakingNewsCard().campaignId(null);
    args.activity().breakingNewsCard().campaign(null);
  };

  self.onToggle = function onToggle() {
    self.isBreakingNewsCampaignToggle(!self.isBreakingNewsCampaignToggle());
    self.activityFilter([]);

    self.removeBreakingNewsAttributes();

    m.redraw();
  };

  self.onSelect = function onSelect() {
    var campaign = self.activityFilter().length ? self.activityFilter()[0] : null;

    if (campaign) {
      args.activity().breakingNewsCard().campaignId(campaign.id());
      args.activity().breakingNewsCard().campaign(campaign);
      args.activity().segmentation().items = [];
    } else
      self.removeBreakingNewsAttributes();

    m.redraw();
  };

  self.loadActivities = function loadActivities() {
    return Activity
      .findForInput()
      .then(function assignActivities(activities) {
        self.activities(activities);
        m.redraw();
      });
  };

  self.loadActivities();
};

component.view = function view(c, args) {
  var activity = args.activity();
  var title = args.title;
  var description = args.description;

  const activityTypeMapping = {
    1: t('mithril_components:activity_header_new.daily_serie'),
    2: '', // Unused
    3: '', // Unused
    4: t('mithril_components:activity_header_new.breaking_news'),
    5: t('mithril_components:activity_header_new.ranking'),
    7: t('mithril_components:activity_header_new.welcome'),
    8: t('mithril_components:activity_header_new.thats_all_folks'),
    9: t('mithril_components:activity_header_new.interseason'),
    10: t('mithril_components:activity_header_new.finisher'),
  };

  return m('.activityInfos', [
    m('.activityInfos__top', [
      m('.activityInfos__left', [
        m(mediaSelector, {
          image    : c.switchPicture,
          video    : args.video,
          cropProps: c.getCropProps(),
        }),
      ]),
      m('.activityInfos__right', [
        m('.activityInfos__type', [
          m(activityIcon, {
            class       : 'activityInfos__type__ico',
            activityType: activity.typeId(),
            size        : 'badge',
            rounded     : true,
          }),
          m('.activityInfos__type__value', activityTypeMapping[activity.typeId()]),
        ]),
        m('.activityInfos__input.activityInfos__input--title', [
          title || m(inputText, {
            value      : activity.name,
            placeholder: t('mithril_components:activity_header_new.title_placeholder'),
            max        : 40,
          }),
        ]),
        m('.activityInfos__input.activityInfos__input--desc', [
          description || m(inputTextarea, {
            placeholder: t('mithril_components:activity_header_new.description_placeholder'),
            value      : activity.description,
            max        : activity.getDescriptionLimit(),
          }),
        ]),
      ]),
    ]),
    m('', renderTargetItems()),
    m('', renderFilter()),
  ]);

  function renderTargetItems() {
    switch (activity.typeId()) {
      case Enum.activityTypeEnum.DAILY_SERIE:
        return m('.activityInfos__language__label', t('mithril_components:activity_header_new.language_label'));
      case Enum.activityTypeEnum.BREAKING_NEWS:
        return [
          m('.activityInfos__audience__label', t('mithril_components:activity_header_new.audience_label')),
          m('.activityInfos__target__toggle', [
            m(reactCreator, {
              component: 'UToggle',
              props    : {
                type    : 'default',
                toggled : c.isBreakingNewsCampaignToggle,
                onChange: c.onToggle,
              },
            }),
          ]),
          m('.activityInfos__target__label', t('mithril_components:activity_header_new.target_label')),
        ];
      default:
        return null;
    }
  }

  function renderFilter() {
    return c.isBreakingNewsCampaignToggle()
      ? m('.activityInfos__campaign', [
        m(entityInput, {
          selection   : c.activityFilter,
          onselect    : c.onSelect,
          model       : 'Activity',
          displayPath : 'name',
          entities    : c.activities,
          placeholder : t('mithril_components:activity_header_new.campaign_placeholder'),
          maxSelection: c.maxSelection,
        }),
      ])
      : m('.activityInfos__segmentation', {
        class: activity.typeId() === Enum.activityTypeEnum.DAILY_SERIE
          ? 'activityInfos__segmentation--dailyserie'
          : 'activityInfos__segmentation--no-dailyserie',
      }, [
        m(segmentationInput, {
          entity         : activity,
          languageUnicity: true,
          oninput        : c.handleSegmentsChange.bind(null, args),
          onlyLanguage   : activity.typeId() === Enum.activityTypeEnum.DAILY_SERIE,
        }),
      ]);
  }
};

export default component;
