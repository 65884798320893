import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  iconWrapper: {
    display: 'inline-block',
    marginTop: '2.5px',
    marginBottom: '2.5px',
  },
  small: {
    height: '8px',
    width: '8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  medium: {
    height: '12px',
    width: '12px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  large: {
    height: '16px',
    width: '16px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediumText: {
    ...TYPOGRAPHY.BODY4,
    display: 'inline-block',
  },
  largeText: {
    ...TYPOGRAPHY.BODY3,
    display: 'inline-block',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styleSheet;
