import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  list: {
    display: 'flex',
    flexDirection: 'row',
    gap: '28px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '160px',
    gap: '8px',
  },
  text: {
    ...TYPOGRAPHY.H1,
  },
});
