import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import { useDetectSticky } from 'Hooks/useDetectSticky';

import { DateBlock } from './components/DateBlock';
import { CountersBlock, CountersBlockProps } from './components/CountersBlock';
import { DefaultContainer } from './components/DefaultContainer';
import { StickyContainer } from './components/StickyContainer';

import styles from './SCampaignInfo.style';

export type SCampaignInfoProps = {
  active: boolean;
  contentCount: number;
  sessionCount: number;
  extraDays: number;

  startDate?: string;
  endDate?: string;

  style?: CSSProperties;
  stickyContainerStyle?: CSSProperties;
  stickyStyle?: CSSProperties;
};

export const SCampaignInfo = ({
  active,
  contentCount,
  sessionCount,
  extraDays,
  startDate,
  endDate,
  style = {},
  stickyContainerStyle = {},
  stickyStyle = {},
}: SCampaignInfoProps) => {
  const [isSticky, ref] = useDetectSticky();

  const countersConfig: CountersBlockProps['counters'] = useMemo(
    () => [
      {
        icon: 'gameplay',
        text: t('structural_component:s_campaign_info.content', { count: contentCount }),
      },
      {
        icon: 'campaign-daily-session',
        text: t('structural_component:s_campaign_info.session', { count: sessionCount }),
      },
      {
        icon: 'plus-circled',
        text: t('structural_component:s_campaign_info.extra_days', { count: extraDays }),
      },
    ],
    [contentCount, extraDays, sessionCount],
  );

  const Container = isSticky ? StickyContainer : DefaultContainer;

  return (
    <div ref={ref} style={{ ...styles.sticky, ...stickyContainerStyle }}>
      <Container
        active={active}
        contentCount={contentCount}
        style={{ ...styles.container, ...style, ...(isSticky ? stickyStyle : {}) }}
      >
        <DateBlock startDate={startDate} endDate={endDate} />
        <CountersBlock counters={countersConfig} />
      </Container>
    </div>
  );
};

export default SCampaignInfo;
