import _ from 'lodash';

export const generateCode = function generateCode(): string {
  const DEFAULT_CODE_LENGHT = 6;
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const nums = '12345678910';
  const threshold = 0.5;

  let code = '';

  while (code.length < DEFAULT_CODE_LENGHT) {
    if (Math.random() > threshold) {
      code += _.sample(letters)! + _.sample(letters)!;
    } else {
      code += _.sample(nums)! + _.sample(nums)!;
    }
  }

  return code;
};
