// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    padding: '8px 0',
    textAlign: 'center',
    fontWeight: 700,
    ...TYPOGRAPHY.BODY4,
  },
};
