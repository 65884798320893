import { mergeDeep } from 'Libs/mergeDeep';

import { generateCode } from 'Libs/audienceManager';
import Enum from 'Models/Enum';
import createSegmentationGroupItem, {
  SegmentationGroupItemType,
} from 'Models/react/SegmentationGroupItem/SegmentationGroupItem';

export type AudienceMagicCodeConfigType = Readonly<{
  magicCode: string;
}>;

export type AudienceEmailConfigType = Readonly<{
  emailList: ReadonlyArray<{ email: string }>;
}>;

export type AudienceSegmentConfigType = Readonly<{
  segmentation: ReadonlyArray<SegmentationGroupItemType>;
}>;

export type AudienceType = Readonly<{
  mode: number;
  MAGIC_CODE: AudienceMagicCodeConfigType;
  EMAIL_LIST: AudienceEmailConfigType;
  SEGMENTATION: AudienceSegmentConfigType;
  isMagicCodeWarningOn: boolean;
}>;

export const createMagicCodeConfig = function createMagicCodeConfig(values?: string): AudienceMagicCodeConfigType {
  return {
    magicCode: values || values === '' ? values : generateCode(),
  };
};

export const createEmailConfig = function createEmailConfig(
  values?: ReadonlyArray<{ email: string }>,
): AudienceEmailConfigType {
  return {
    emailList: values || [],
  };
};

export const createSegmentationConfig = function createSegmentationConfig(
  values?: ReadonlyArray<SegmentationGroupItemType>,
): AudienceSegmentConfigType {
  return {
    segmentation: values ? values.map(createSegmentationGroupItem) : [],
  };
};

export default function createAudience(values?: {
  mode: number;
  config: AudienceMagicCodeConfigType & AudienceEmailConfigType & AudienceSegmentConfigType;
}): AudienceType {
  const defaultState: AudienceType = {
    mode: Enum.audienceTargetMode.NO_CHOICE,
    MAGIC_CODE: createMagicCodeConfig(),
    EMAIL_LIST: createEmailConfig(),
    SEGMENTATION: createSegmentationConfig(),
    isMagicCodeWarningOn: false,
  };

  if (!values) {
    return defaultState;
  }

  return mergeDeep(defaultState, {
    mode: values.mode,
    MAGIC_CODE: createMagicCodeConfig(values.config.magicCode),
    EMAIL_LIST: createEmailConfig(values.config.emailList),
    SEGMENTATION: createSegmentationConfig(values.config.segmentation),
  });
}
