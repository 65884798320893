import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import { GAMEPLAY_KEYS } from 'Components/utils/Enum';

import type { Action } from 'Libs/redux/types';

import type { ContentState as State } from './contentReducer';
import { reducer as contentReducer, defaultState as defaultContentState } from './contentReducer';
import { reducer as orderingCardsReducer } from '../OrderingCards/redux';

export type ContentState = DeepReadonly<State>;

export const defaultState: ContentState = defaultContentState;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ContentState = defaultState, action: Action) => {
  const newContentState = contentReducer(state, action);

  return mergeDeep(newContentState, {
    content: {
      [GAMEPLAY_KEYS.ORDERING_CARDS]: orderingCardsReducer(
        newContentState.content[GAMEPLAY_KEYS.ORDERING_CARDS],
        action,
      ),
    },
  });
};
