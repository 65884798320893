// @flow

/**
  * Display a modal that handle all content translation
  *
  * Props:
  *   - style: custom style to override the modal's style
  *   - visible: if the modal should be displayed, by default false
  *   - isLoading: boolean to define if the confirm button is loading
  *   - onClose: callback triggered on modal closing
  *   - onConfirm: callback triggered on click on the confirm button
 */

import * as React from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';

import styles from './MAllLanguageTranslation.style.js';


type Props = {|
  style: Object,
  visible: boolean,
  isLoading: boolean,

  onConfirm: () => void,
  onClose: () => void,
|};


export class MAllLanguageTranslation extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const {
      visible,
      style,
      isLoading,
      onClose,
      onConfirm,
    } = this.props;

    return (
      <MModal
        labelActionButton={t('activities:modals.all_languages_translation.button')}
        labelSecondButton={t('activities:modals.all_languages_translation.cancel')}
        onAction={onConfirm}
        onCloseModal={onClose}
        onSecondAction={onClose}
        title={t('activities:modals.all_languages_translation.title')}
        visible={visible}
        isActionLoading={isLoading}
        style={{ ...styles.modal, ...style }}
        bodyStyle={styles.contentWrapper}
      >
        <p style={styles.headingContent}>
          {t('activities:modals.all_languages_translation.content')}
        </p>
        <p style={styles.footerContent}>
          {t('activities:modals.all_languages_translation.disclaimer')}
        </p>
      </MModal>
    );
  }
}

export default MAllLanguageTranslation;
