import { handle } from 'redux-pack';

import { getErrorMessage } from 'Libs/redux/utils';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { Action } from 'Libs/redux/types';

import Enum from 'Models/Enum';
import { CONTENT_ACTIONS, GAMEPLAY_ID_TO_CONTENT_KEY } from 'Components/utils/Enum';

import createKnowledgeInfo from './models/Knowledge';
import { createContent } from './models/Content';

import type { KnowledgeInfoType } from './models/Knowledge';
import type { ContentType } from './models/Content';
import type { AlertType, AlertParams, AlertScope } from './models/Alert';

type ActionType = 'validate' | 'save' | 'preview';

export type ActionConfigType = {
  type: ActionType;
  enabled: boolean;
};

export type ValidationConfig = {
  canValidate: boolean;
  canAskValidation: boolean;
};

export type ContentState = DeepReadonly<{
  loading: boolean;
  canEditContent: boolean;
  canEditValidContent: boolean;
  canAskForValidation: boolean;
  showPreview: boolean;
  isPreviewable: boolean;
  actionsConfig: ReadonlyArray<ActionConfigType>;
  alerts: ReadonlyArray<AlertType>;
  knowledgeInfo: KnowledgeInfoType;
  content: ContentType;
  originalContent: ContentType;
}>;

const defaultActionsConfig: ReadonlyArray<ActionConfigType> = [
  {
    type: CONTENT_ACTIONS.SAVE,
    enabled: true,
  },
  {
    type: CONTENT_ACTIONS.PREVIEW,
    enabled: true,
  },
  {
    type: CONTENT_ACTIONS.VALIDATE,
    enabled: false,
  },
];

const defaultContent = createContent();

export const defaultState: ContentState = {
  loading: false,
  showPreview: false,
  isPreviewable: false,
  canEditContent: false,
  canEditValidContent: false,
  canAskForValidation: false,
  actionsConfig: defaultActionsConfig,
  alerts: [],
  knowledgeInfo: createKnowledgeInfo(),
  content: defaultContent,
  originalContent: defaultContent,
};

const getValidationConfig = (
  statusId: number,
  canEditValidContent: boolean,
  userCanAskValidation: boolean,
): ValidationConfig => {
  const isDraft = statusId === Enum.contentStatus.DRAFT;
  const isToValidate = statusId === Enum.contentStatus.TO_VALIDATE;
  const canAskForValidation = isDraft && userCanAskValidation;

  return {
    canValidate: (isDraft || isToValidate) && canEditValidContent,
    canAskValidation: canAskForValidation,
  };
};

const makeLoadingFlag = (type: Action['type'], value: boolean) => {
  switch (type) {
    case 'CONTENT_FETCH_BY_ID':
      return { loading: value };
    default:
      return {};
  }
};

const makeServerAlertParams = (type: Action['type'], meta: any): AlertParams => {
  switch (type) {
    case 'CONTENT_SET_STATUS':
      return { statusId: meta.statusId };
    case 'CONTENT_ARCHIVE':
      return { archived: meta.archived };

    default:
      return {};
  }
};

const makeActionsConfig = (state: ContentState): ReadonlyArray<ActionConfigType> => {
  const {
    canEditContent,
    canEditValidContent,
    canAskForValidation,
    knowledgeInfo: { pendingTranslation },
    content: { statusId },
  } = state;
  const { VALIDATE, SAVE } = Enum.ContentActions;

  // can't use array spread because ref on object are maintained by spreading
  const actionsConfig = defaultActionsConfig.map((config) => ({ ...config }));
  const { canValidate, canAskValidation } = getValidationConfig(statusId, canEditValidContent, canAskForValidation);

  actionsConfig[SAVE].enabled = canEditContent;
  actionsConfig[VALIDATE].enabled = (canValidate || canAskValidation) && !pendingTranslation;

  return actionsConfig;
};

const ERROR_SCOPE = {
  CONTENT_FETCH_BY_ID: 'fetchError',
  CONTENT_FETCH_KNOWLEDGE_INFO: 'fetchKnowledgeError',
  CONTENT_SAVE: 'saveError',
  CONTENT_SET_STATUS: 'changeStatusError',
  CONTENT_ARCHIVE: 'archiveError',
} as const;

const SUCCESS_SCOPE = {
  CONTENT_FETCH_BY_ID: null,
  CONTENT_SAVE: null,
  CONTENT_SET_STATUS: 'changeStatusSuccess',
  CONTENT_ARCHIVE: 'archiveSuccess',
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: ContentState = defaultState, action: Action): ContentState => {
  switch (action.type) {
    case 'CONTENT_SETUP_NEW_CONTENT': {
      const newContent: ContentType = { ...createContent(), gameplayId: action.gameplayId };

      return mergeDeep(state, { content: newContent, originalContent: newContent });
    }

    case 'CONTENT_FETCH_BY_ID':
    case 'CONTENT_SAVE':
    case 'CONTENT_SET_STATUS':
    case 'CONTENT_ARCHIVE':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            ...makeLoadingFlag(action.type, true),
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE[action.type])
              .filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),
          }),
        success: (prevState) => {
          const { payload } = action;
          const content = createContent(payload);
          const alerts = SUCCESS_SCOPE[action.type]
            ? prevState.alerts.concat([
                {
                  scope: SUCCESS_SCOPE[action.type] as AlertScope,
                  params: makeServerAlertParams(action.type, action.meta),
                },
              ])
            : prevState.alerts;

          let isPreviewable = false;

          if (content.id && content.statusId !== Enum.contentStatus.DRAFT) {
            isPreviewable = true;
          }

          const newState = mergeDeep(
            // INFO: Set to null to ensure mergeDeep assigns the sames reference to both objects
            {
              ...prevState,
              originalContent: null,
              content: null,
            } as unknown as ContentState,
            { content, originalContent: content, isPreviewable, alerts },
          );

          const actionsConfig = makeActionsConfig(newState);

          return mergeDeep(newState, { actionsConfig });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
                ...makeServerAlertParams(action.type, action.meta),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep<ContentState>(prevState, makeLoadingFlag(action.type, false)),
      });

    case 'CONTENT_RESET':
      return defaultState;

    case 'CONTENT_FETCH_KNOWLEDGE_INFO':
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            loading: true,
            alerts: prevState.alerts.filter(({ scope }) => scope !== ERROR_SCOPE[action.type]),
          }),
        success: (prevState) => {
          const { payload } = action;
          const { content } = prevState;
          const { id: contentId, gameplayId } = content;

          const knowledge = createKnowledgeInfo(payload);

          const gameplayKey = GAMEPLAY_ID_TO_CONTENT_KEY[gameplayId];
          const newExplanation = { ...knowledge.explanation };

          delete newExplanation.id;

          // Auto fill gameplay explanation with knowledge explanation at creation
          const newContent = !contentId
            ? mergeDeep(content, {
                [gameplayKey]: {
                  explanation: newExplanation,
                },
              })
            : content;

          return mergeDeep(prevState, {
            knowledgeInfo: knowledge,
            content: newContent,
          });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {
                message: getErrorMessage(action),
              },
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loading: false }),
      });

    case 'CONTENT_SET_PREVIEW_VISIBILITY':
      return mergeDeep(state, { showPreview: action.visibility });

    case 'CONTENT_SET_EDIT_ROLE': {
      const newState = mergeDeep(state, { canEditContent: action.value });

      // update config if can edit role is dispatch after fetch of content
      const actionsConfig = makeActionsConfig(newState);

      return mergeDeep(newState, { actionsConfig });
    }

    case 'CONTENT_SET_EDIT_VALID_ROLE':
      return mergeDeep(state, { canEditValidContent: action.value });

    case 'CONTENT_SET_ASK_VALIDATION_ROLE':
      return mergeDeep(state, { canAskForValidation: action.value });

    case 'CONTENT_SET_ALERT': {
      const newAlert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === newAlert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? newAlert : err))
        : state.alerts.concat([newAlert]);

      return mergeDeep(state, { alerts });
    }

    case 'CONTENT_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    default:
      return state;
  }
};
