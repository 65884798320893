import Promise from 'bluebird';
import T from 'types';
import request from 'services/requestService';
import Collection from 'models/Collection';

var initialTypes = {
  number: function nb() {
    return 0;
  },
  string: function str() {
    return '';
  },
  object: function obj() {
    return {};
  },
  array: function arr() {
    return [];
  },
  boolean: function bool() {
    return false;
  },
  date: function date() {
    return new Date();
  },
  collection: function collection() {
    return null;
  },
  pointer: function pointer() {
    return null;
  },
};

var constructors = {};

constructors._observers = {};

constructors.when = function when(model, cb) {
  this._observers[model] = this._observers[model] || [];

  this._observers[model].push(cb);
};

constructors.add = function add(model, Constructor) {
  this[model] = Constructor;
  this._observers[model] = this._observers[model] || [];

  for (var cb = this._observers[model].shift(); cb;) {
    cb(Constructor);
    cb = this._observers[model].shift();
  }
};

function factory(type, model) {

  if (!type)
    throw new Error('You cannot create a class without a type');

  if (constructors[type])
    return constructors[type];

  if (!model)
    throw new Error('You cannot create a class without a model, type: ' + type);

  function Model(attributes, breadcrumb) {
    var self = this;

    self._type = type;
    self.model = model;
    self._breadcrumb = breadcrumb || {};
    attributes = attributes || {};

    JSONToAttr(self, attributes);
  }

  Model.prototype.save = function save(newRemote) {
    var self = this;
    var promise = null;

    const payload = newRemote ? { activity: self.toJSON() } : self.toJSON();

    if (self.id() === undefined) {
      console.log('Saving...', self.toJSON());
      promise = request.post(model.endpoint, self.toJSON());
    } else
      promise = request.put(model.endpoint + '/' + self.id(), payload);

    return promise.then(function updateObj(json) {

      if (T.is.arr(json))
        json = json[0];

      // console.log(json);

      JSONToAttr(self, json, null, true);

      return self;
    });
  };

  Model.prototype.toJSON = function toJSON() {
    var self = this;
    var attr = self.model.attributes;
    var ret = {};
    var tmp = null;

    Object.keys(attr).forEach(function attrToJSON(key) {
      if (self[key]() !== undefined) {
        tmp = self[key]();

        if (factory.isModel(tmp))
          tmp = tmp.toJSON();

        if (factory.isCollection(tmp))
          tmp = tmp.toJSON();

        if (T.is.arr(tmp)) {
          tmp = tmp.map(function arrayToJSON(item) {
            if (factory.isModel(item))
              return item.toJSON();
            return item;
          });
        }

        ret[key] = tmp;
      }
    });

    ret.id = self.id();

    return ret;
  };

  /**
   * TODO:
   *   test object and array equality (without lodash)
   */
  Model.prototype.isEqual = function isEqual(toCompare) {
    var self = this;
    var attr = self.model.attributes;
    var isEqualResult = (self._type === toCompare._type);
    var keys = Object.keys(attr);
    var key = '';
    var thisTmp = null;
    var compTmp = null;

    for (var i = 0, l = keys.length; i < l && isEqualResult; i++) {
      key = keys[i];
      thisTmp = self[key]();
      compTmp = toCompare[key]();

      if (factory.isModel(thisTmp) || factory.isCollection(thisTmp))
        isEqualResult = thisTmp.isEqual(compTmp);
      else if (T.is.arr(thisTmp)) {
        isEqualResult = (thisTmp.length === compTmp.length);

        for (var j = 0, len = thisTmp.length; j < len && isEqualResult; j++) {
          if (factory.isModel(thisTmp[j]))
            isEqualResult = thisTmp[j].isEqual(compTmp[j]);
        }
      } else
        isEqualResult = (thisTmp === compTmp);
    }

    return isEqualResult;
  };

  Model.prototype.destroy = function destroy(cb) {
    var self = this;
    var promise = null;

    if (self.id() === undefined)
      return Promise.reject('An id is required');

    promise = request.destroy(model.endpoint + '/' + self.id());

    if (cb)
      return promise.then(cb);

    return promise;
  };

  Model.prototype.empty = function empty() {
    var self = this;

    Object.keys(model.attributes).forEach(function iterateOverAttr(item) {
      var customValue = model.attributes[item].defaultsTo;
      var valueType = model.attributes[item].type;
      var defaultValue = initialTypes[valueType]();
      var old = self[item]();
      var value = null;

      if (factory.isModel(old))
        value = old.clone().empty();

      else if (customValue === undefined)
        value = defaultValue;

      else
        value = customValue;

      self[item](value);
    });

    return self;
  };

  Model.prototype.clone = function clone() {
    var self = this;
    var tmp = new Model(self.toJSON());

    return tmp;
  };

  Model.find = function find(params) {
    return request
      .get(model.endpoint, params)
      .then(instanciate);
  };

  Model.get = function get(id, params) {
    return request
      .get(model.endpoint+'/'+id, params)
      .then(instanciateOne);
  };

  addClassMethods(type, model, Model);

  Model._type = type;
  Model.model = model;

  function instanciate(res) {
    res = T.is.arr(res) ? res : [res];

    if (Collection)
      return new Collection(type, model, res);
    return res.map(instanciateOne);
  }

  function instanciateOne(attributes) {
    return new Model(attributes);
  }

  return Model;
}

/* ----- STATIC METHODS ----- */

factory.isModel = function isModel(obj) {
  if (obj && obj._type)
    return obj instanceof constructors[obj._type];

  return false;
};

factory.isCollection = function isCollection(obj) {
  if (Collection)
    return obj instanceof Collection;
  throw new Error('Collection is not enabled (yet?)');
};

factory.register = function registerModel(type, model) {
  if (!constructors[type])
    constructors.add(type, factory(type, model));

  return constructors[type];
};

factory.new = function newInstanceOf(type, attributes) {
  return new constructors[type](attributes);
};

/* ----- UTILS ----- */

function JSONToAttr(instance, json, update, skipMethod) {
  var model = instance.model;

  Object.keys(model.attributes).forEach(function iterateOverAttr(item) {
    var value = json[item] || (instance[item] ? instance[item]() : undefined) ;
    var defaultValue = model.attributes[item].defaultsTo === undefined
      ? initialTypes[model.attributes[item].type]()
      : model.attributes[item].defaultsTo;
    var lazy = null;

    if (value === undefined)
      value = defaultValue;

    if (model.attributes[item].type === 'date')
      value = new Date(value);

    if (model.attributes[item].type === 'pointer' && !factory.isModel(value)) {
      if (!instance._breadcrumb[model.attributes[item].Model]) {
        if (constructors[model.attributes[item].Model]) {
          lazy = function lazy() {
            return new constructors[model.attributes[item].Model](value, joinInBreadcrumb(instance._breadcrumb, instance._type));
          };
        } else {
          throw new Error([
            'The',
            model.attributes[item].Model,
            'dependency is missing to build the attributes',
            item,
            'of the model',
            instance.type,
          ].join(' '));
        }
      } else
        value = undefined;
    }

    if (model.attributes[item].type === 'collection' && !factory.isCollection(value)) {
      if (!instance._breadcrumb[model.attributes[item].Model]) {
        if (constructors[model.attributes[item].Model]) {
          lazy = function _lazy() {
            var tmp = new constructors[model.attributes[item].Model]({}, joinInBreadcrumb(instance._breadcrumb, instance._type));

            return new Collection(tmp._type, tmp.model, value, joinInBreadcrumb(instance._breadcrumb, instance._type));
          };
        } else {
          throw new Error([
            'The',
            model.attributes[item].Model,
            'dependency is missing to build the attributes',
            item,
            'of the model',
            instance.type,
          ].join(' '));
        }
      }
    }

    if (update)
      instance[item](lazy || value);
    else
      instance[item] = prop(lazy || value);
  });

  instance._breadcrumb[instance._type] = true;

  if (model.methods && !skipMethod) {
    Object.keys(model.methods).forEach(function iterateOverMethods(method) {
      instance[method] = model.methods[method].bind(instance, constructors[instance._type]);
    });
  }

  if (update)
    instance.id(json.id);
  else
    instance.id = prop(json.id);
}

function addClassMethods(type, model, Model) {
  if (model.classMethods) {
    Object.keys(model.classMethods).forEach(function addClassMethod(method) {
      Model[method] = model.classMethods[method].bind(null, Model);
    });
  }
}

function prop(value) {
  return function propify() {
    if (arguments.length)
      value = arguments[0];

    if (typeof value === 'function')
      value = value();

    return value;
  };
}

function joinInBreadcrumb(breadcrumb, toAdd) {
  var ret = {};

  Object.keys(breadcrumb).forEach(function addToRet(key) {
    ret[key] = true;
  });

  ret[toAdd] = true;

  return ret;
}

export default factory;
