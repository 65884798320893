import { State } from 'Libs/redux/types';

import { QueueContexts, QueueStatus } from './slice';

export const getIsQueueLoading = (state: State, context: QueueContexts) => {
  const currentQueue = state.services.queue[context];

  return (
    Boolean(currentQueue.queue.length)
    && (currentQueue.status === QueueStatus.IDLE || currentQueue.status === QueueStatus.BUSY)
  );
};
