import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { fromApiContentMapper } from './content.mapper';
import { contentAdapter } from './content.selectors';
import { fetchContentById, searchContents, updateContent, validateContent } from './content.thunks';
import { ContentFilters, ContentState } from './content.types';

export const defaultFilter: ContentFilters = {
  selectedContentStatuses: [],
  selectedLanguageCodes: [],
  selectedThematicIds: [],
  selectedTexts: [],
};

export const contentState: ContentState = contentAdapter.getInitialState({
  metadata: {
    total: 0,
    loading: false,
    error: null,
    isFiltering: false,
  },
  filters: defaultFilter,
});

export const { actions: contentActions, reducer: contentReducer } = createSlice({
  name: 'contents',
  initialState: contentState,
  reducers: {
    updateFilters(state, action: PayloadAction<ContentFilters>) {
      const newFilters = action.payload;
      const isFiltering = Object.keys(newFilters).reduce(
        (acc, k) => acc || Boolean(newFilters[k as keyof ContentFilters].length),
        false,
      );

      return {
        ...state,
        metadata: {
          ...state.metadata,
          isFiltering,
        },
        filters: newFilters,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchContents.pending, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true,
          error: null,
        },
      };
    });
    builder.addCase(searchContents.rejected, (state, error) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false,
          error: error.error.message || 'unknown error',
        },
      };
    });
    builder.addCase(searchContents.fulfilled, (state, payload) => {
      const {
        payload: { items, offset },
      } = payload;

      const newState = {
        ...state,
        metadata: {
          ...state.metadata,
          total: payload.payload.total,
          loading: false,
          error: null,
        },
      };
      const formattedItems = items.map(fromApiContentMapper);
      return offset === 0
        ? contentAdapter.setAll(newState, formattedItems)
        : contentAdapter.setMany(newState, formattedItems);
    });
    builder.addCase(fetchContentById.pending, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true,
          error: null,
        },
      };
    });
    builder.addCase(fetchContentById.fulfilled, (state, { payload }) => {
      const newState = {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false,
          error: null,
        },
      };
      return contentAdapter.upsertOne(newState, fromApiContentMapper(payload));
    });
    builder.addCase(updateContent.fulfilled, (state, { payload, meta: { arg } }) => {
      // @ts-ignore Typescript is getting angry about undefined id in deep partial
      return contentAdapter.updateOne(state, { id: payload.id, changes: arg.content });
    });
    builder.addCase(validateContent.rejected, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          error: 'Validation failed',
        },
      };
    });
  },
});
