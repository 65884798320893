import React, { useMemo } from 'react';
import type { FunctionComponent, CSSProperties } from 'react';

import { COLORS } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './UStatusIndicator.style';

export const ALL_STATUS_TYPES = ['invalid', 'upcoming', 'valid', 'warning', 'error'] as const;
export type UStatusType = typeof ALL_STATUS_TYPES[number];

export type UStatusSize = 'small' | 'medium' | 'large';

export type UStatusIndicatorProps = {
  type: UStatusType;
  text?: string;
  hideText?: boolean;
  size?: UStatusSize;
  icon?: string;
  color?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
};

const SMALL = 6;
const MEDIUM = 8;
const LARGE = 10;

/**
 * Displays an icon
 *
 * Props:
 *  - type: type of the icon (valid/invalid/warning/upcoming)
 *  - text: string to be displayed next to the icon
 *  - hideText: boolean that determines if text is displayed or not
 *  - size: icon and text size (small/medium/large)
 *  - icon: name of the icon to be displayed
 *  - color: color of the text and icon
 *  - style: can override the component's style
 */
export const UStatusIndicator: FunctionComponent<UStatusIndicatorProps> = ({
  type = 'valid',
  text = '',
  icon = undefined,
  style: propStyle = {},
  containerStyle = {},
  hideText = false,
  size = 'small',
  color = undefined,
}) => {
  const typeStyles = useMemo(() => {
    const hasIconProp = Boolean(icon);
    const hasColorProp = Boolean(color);

    return {
      valid: {
        iconName: hasIconProp ? icon : 'check',
        backgroundColor: COLORS.SUCCESS.LIGHT,
        textColor: hasColorProp ? color : COLORS.SUCCESS.DARK,
        iconColor: hasColorProp ? color : COLORS.SUCCESS.DARK,
      },
      invalid: {
        iconName: hasIconProp ? icon : 'check',
        backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
        textColor: hasColorProp ? color : COLORS.TEXT.SECONDARY_DEFAULT,
        iconColor: hasColorProp ? color : COLORS.TEXT.SECONDARY_DEFAULT,
      },
      upcoming: {
        iconName: hasIconProp ? icon : 'check',
        backgroundColor: COLORS.PRIMARY.LIGHTER,
        textColor: hasColorProp ? color : COLORS.PRIMARY.DEFAULT,
        iconColor: hasColorProp ? color : COLORS.PRIMARY.MEDIUM,
      },
      warning: {
        iconName: hasIconProp ? icon : 'warning-mini',
        backgroundColor: COLORS.WARNING.LIGHT,
        textColor: hasColorProp ? color : COLORS.WARNING.DEFAULT,
        iconColor: hasColorProp ? color : COLORS.WARNING.DEFAULT,
      },
      error: {
        iconName: hasIconProp ? icon : 'warning-mini',
        backgroundColor: COLORS.ERROR.LIGHT,
        textColor: hasColorProp ? color : COLORS.ERROR.DARKER,
        iconColor: hasColorProp ? color : COLORS.ERROR.DARKER,
      },
    }[type];
  }, [color, icon, type]);

  const sizeStyles = useMemo(() => {
    const { textColor } = typeStyles;

    const values = {
      small: {
        sizeStyle: styles.small,
        textSize: SMALL,
        textStyle: undefined,
        iconWrapper: { ...styles.iconWrapper },
      },
      medium: {
        sizeStyle: styles.medium,
        textSize: MEDIUM,
        textStyle: {
          ...styles.mediumText,
          color: textColor,
          marginLeft: '4px',
        },
        iconWrapper: styles.iconWrapper,
      },
      large: {
        sizeStyle: styles.large,
        textSize: LARGE,
        textStyle: {
          ...styles.largeText,
          color: textColor,
          marginLeft: '6px',
        },
        iconWrapper: styles.iconWrapper,
      },
    };

    return values[size];
  }, [typeStyles, size]);

  const { backgroundColor, iconName, iconColor } = typeStyles;
  const { textSize, sizeStyle, iconWrapper, textStyle } = sizeStyles;

  const shouldShowText = !hideText && size !== 'small';

  const wrapperStyles = useMemo(() => ({ ...styles.container, ...containerStyle }), [containerStyle]);
  const iconStyles = useMemo(
    () => ({ backgroundColor, ...sizeStyle, ...propStyle }),
    [backgroundColor, propStyle, sizeStyle],
  );

  return (
    <div style={wrapperStyles}>
      <div style={iconWrapper}>
        <div style={iconStyles}>
          <UIcon name={iconName as IconName} size={textSize} color={iconColor} />
        </div>
      </div>
      {shouldShowText && <p style={textStyle}>{text}</p>}
    </div>
  );
};

export default UStatusIndicator;
