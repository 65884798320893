import m from 'mithril';

type RedirectOptions = {
  shouldReplaceHistory?: boolean
};

export const redirect = (url: string, { shouldReplaceHistory }: RedirectOptions = {}) => {
  const method = shouldReplaceHistory
    ? 'replaceState'
    : 'pushState';

  // INFO We call this method in advance
  // To ensure react components accessing the URL will have them
  // By the time they render, which does not seem to be true when calling m.route directly
  window.history[method]({}, '', url);

  // INFO As a consequence, we always set shouldReplaceHistory to true
  // To ensure the m.route call does not create another entry in the history
  m.route(url, {}, true);
};
