/*
 * Args:
 * date
 * step <in minutes>
 *
 */

import m from 'm';

var component = {};

component.controller = function controller() {

};

component.view = function view(c, args) {
  return m('.hour-stepper', [
    m('.hour-stepper__minus', {
      onclick: args.date.bind(null, new Date(args.date().getTime() - args.step() * 60 * 1000)),
    }, '-'),
    m('.hour-stepper__display', getDateFormat()),
    m('.hour-stepper__plus', {
      onclick: args.date.bind(null, new Date(args.date().getTime() + args.step() * 60 * 1000)),
    }, '+'),
  ]);

  function getDateFormat() {
    var hours = args.date().getUTCHours();
    var minutes = args.date().getUTCMinutes();

    if (hours.toString().length === 1)
      hours = '0' + hours;
    if (minutes.toString().length === 1)
      minutes = '0' + minutes;

    return hours + ' : ' + minutes;
  }
};

export default component;
