/* eslint-disable complexity */
import m from 'm';
import App from 'models/App';
import Model from 'models/Model';
import popup from 'services/popupService';
import User from 'services/userService';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import toggleButton from 'components/toggle.button';
import newInputMedia from 'components/new.input.media';
import inputText from 'components/input.text';
import segmentationInput from 'components/segmentation.input';
import inputDate from 'components/input.date';
import select from 'components/new.select';
import emailInput from 'components/email.input';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

component.controller = function controller() {
  var self = this;
  var Gift = Model('Gift');
  var giftId = m.route.param('id');

  self.gift = m.prop(new Gift({
    currencyId: 2,
    currency  : App.currencies().at(1).toJSON(),
  }));

  console.log('[gift]', 'default', self.gift().toJSON());

  self.loading = m.prop(true);
  self.stockVariation = m.prop(0);
  self.modified = m.prop(false);

  self.modifiedDate = function modifiedDate(state) {
    self.modified(state);
    if (self.gift().startDate().getTime() > self.gift().endDate().getTime())
      self.gift().endDate(new Date(self.gift().startDate()));
  };

  if (giftId && giftId !== 'new') {
    Model('Gift')
      .getFull(giftId)
      .then(self.gift)
      .then(function logReceivedGift() {
        console.log('[gift]', 'loaded', self.gift().toJSON());
      })
      .then(self.loading.bind(null, false))
      .then(m.redraw.bind(null, false));
  } else {
    self.loading(false);
    addMyEmail();
    self.gift().stock(null);
    self.gift().cost(null);
    self.gift().credits(null);
  }

  function addMyEmail() {
    User.me()
      .then(function setUserMail(user) {
        self.gift().mails().push(user.email());
        m.redraw();
      });
  }

  self.goBack = function goBack() {
    m.route('/gaia/gifts');
  };

  self.switchPicture = function switchPicture(image) {
    self.gift().picture(image);
    self.gift().pictureId(image.id());
  };

  self.currency = function currency(newCurrency) {

    if (newCurrency !== undefined) {
      self.gift().currencyId(newCurrency.id());

      return self.gift().currency(newCurrency);
    }

    return self.gift().currency();
  };

  self.save = function save(goBack) {
    self.gift().credits(parseInt(self.gift().credits()));
    self.gift().startDate().setUTCHours(0, 0, 0, 0);
    self.gift().endDate().setUTCHours(23, 59, 59, 999);

    var handleError = function (err) {
      popup.open({
        type   : 'info',
        title  : 'This gift is not valid',
        content: err.message ? err.message.error.message : err,
        button : 'Got it!',
      });
    };

    var error = self.errorInGift();

    if (error)
      return handleError(error);

    if (self.gift().id()) {
      return self.gift().updateFull()
        .then(function getGift(gift) {
          self.gift(gift);
          self.modified(false);
          if (goBack)
            self.goBack();
        })
        .catch(handleError);
    }

    return self.gift().createFull()
      .then(function getGift(gift) {
        self.gift(gift);
        self.modified(false);
        if (goBack)
          m.route(self.gift().getRoute());
      })
      .catch(handleError);
  };

  self.activate = self.save.bind(null, false);

  self.changeStock = function changeStock(increase) {
    var res = increase
      ? self.gift().stock() + parseInt(self.stockVariation() || 0)
      : self.gift().stock() - parseInt(self.stockVariation() || 0);

    self.gift().stock(res);

    if (self.gift().stock() < 0)
      self.gift().stock(0);

    self.stockVariation(0);

    self.modified(true);
  };

  self.checkBeforeExit = function checkBeforeExit(action) {
    if (!self.modified())
      return action();
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit this page? All unsaved modifications will be lost.',
      confirm: 'Quit',
      cancel : 'Don\'t quit',
      action : action,
    });
  };

  self.deleteGift = function deleteGift() {
    if (self.gift().orders().length) {
      return popup.open({
        type    : 'info',
        title   : 'Too late my dear!',
        subtitle: 'Your players seem faster than you...',
        button  : 'Alright',
        content : [
          'This gift has already been ordered by a player.',
          'However, you can still deactivate it or reajust the stocks.',
        ].join(' '),
      });
    }

    popup.open({
      type   : 'confirm',
      title  : 'Delete gift',
      content: 'Are you sure you want to delete this gift?',
      confirm: 'Delete',
      cancel : 'Cancel',
      action : deleteIt,
    });

    function deleteIt() {
      return self.gift().archive()
        .then(self.goBack);
    }
  };

  self.errorInGift = function errorInGift() {
    var error = null;

    if (!self.gift().name())
      error = 'You have to give the gift a name';

    if (!self.gift().description())
      error = 'You have to give the gift a description';

    if (self.gift().startDate().getTime() > self.gift().endDate().getTime())
      error = 'The validity period you entered is invalid';
    if (!self.gift().mails().length)
      error = 'You should specify at least one email to a person that manage orders.';
    if (!self.gift().stock())
      error = 'You should enter a stock';
    if (self.gift().stock() < 0)
      error = 'You should enter a valid stock';
    if (!self.gift().credits())
      error = 'You should enter a credits price';
    if (self.gift().credits() < 0)
      error = 'You should enter a valid credits price';
    if (!self.gift().cost())
      error = 'You should enter a cost';
    if (self.gift().cost() < 0)
      error = 'You should enter a valid cost';
    if (!self.gift().pictureId())
      error = 'You should add an image on this gift';

    return error;
  };
};

component.view = function view(c) {
  return m('.gaia-gift-edition', [
    c.loading()
      ? m('.loading')
      : [
        m('.header', [
          m(menu, {
            confirm: c.checkBeforeExit,
          }),
          m(gaiaMenu, {
            confirm: c.checkBeforeExit,
          }),
        ]),
        m('.gaia-gift-edition__body', [
          m('.gaia-gift-edition__body__header', [
            m('.gaia-gift-edition__header__left', [
              m(button, {
                value : '< Back',
                class : 'button--action ',
                action: c.checkBeforeExit.bind(null, c.goBack),
              }),
              m(toggleButton, {
                value  : c.gift().active,
                class  : 'activity-toggle',
                onclick: c.activate,
              }),
              m('.gaia-gift-edition__header_active-label',
                c.gift().active() ? 'Active' : 'Inactive'
              ),
            ]),
            m('.gaia-gift-edition__header__right', [
              m(button, {
                value: 'Delete',
                class: [
                  'button--delete',
                  'gaia-gift-edition__command-button', !c.gift().id() ? 'hidden' : '',
                ].join(' '),
                action: c.deleteGift,
              }),
              m(button, {
                value : 'Cancel',
                class : 'button--cancel button--border gaia-gift-edition__command-button',
                action: c.goBack,
              }),
              m(button, {
                value : c.gift().id() ? 'Save' : 'Create',
                class : 'button--action gaia-gift-edition__command-button',
                action: c.save.bind(null, true),
              }),
            ]),
          ]),
          m('.gaia-gift-edition__body__main', [
            m('.gaia-gift-edition__top', [
              m('.gaia-gift-edition__image', {
                class: c.gift().picture().id() ? 'gaia-gift-edition__image--chosen' : '',
                style: {
                  'background-image': 'url(' + c.gift().picture().cdn() + ')',
                },
              }, [
                m(newInputMedia, {
                  cropProps: {
                    ...EnumMedia.COMMON.cropProps,
                    ...EnumMedia.GIFT,
                  },
                  onSave     : c.switchPicture,
                  imageLoaded: c.gift().pictureId,
                }),
              ]),
              m('.gaia-gift-edition__general-info', [
                m(inputText, {
                  value      : c.gift().name,
                  placeholder: 'Name',
                  max        : 40,
                  class      : 'gift__input gift__input--title',
                  onkeydown  : c.modified.bind(null, true),
                }),
                m(inputText, {
                  value      : c.gift().description,
                  placeholder: 'Description',
                  max        : 80,
                  class      : 'gift__input--medium',
                  onkeydown  : c.modified.bind(null, true),
                }),
                m('.gaia-gift-edition__general-info__line', [
                  m('.gaia-gift-edition__general-info__line__label', 'Coins'),
                  m('.gaia-gift-edition__general-info__line__right', [
                    m('.gaia-gift-edition__general-info__credit-icon'),
                    m('input.gaia-gift-edition__general-info__price-input', {
                      pattern    : '[0-9]+',
                      type       : 'number',
                      value      : c.gift().credits(),
                      placeholder: '',
                      oninput    : m.withAttr('value', c.gift().credits),
                    }),
                  ]),
                ]),
                displayStockPart(),
              ]),
            ]),
            m('.gaia-gift-edition__bottom', [
              m('.gaia-gift-edition__bottom__segmentation', [
                m(segmentationInput, {
                  entity         : c.gift(),
                  languageUnicity: false,
                }),
              ]),
              m('.gaia-gift-edition__general-info__line', [
                m('.gaia-gift-edition__general-info__line__icon.gaia-gift-edition__general-info__line__icon--date'),
                m('.gaia-gift-edition__general-info__line__label', [
                  'Availability period:',
                  m(toggleButton, {
                    value   : c.gift().validityPeriod,
                    class   : 'gift-toggle',
                    disabled: false,
                    onclick : c.modified.bind(null, true),
                    key     : 1,
                  }),
                ]),
                m('.gaia-gift-edition__general-info__line__right', {
                  class: c.gift().validityPeriod() ? '' : 'hidden',
                }, [
                  m(inputDate, {
                    label   : 'From:',
                    value   : c.gift().startDate,
                    onchange: c.modifiedDate.bind(null, true),
                  }),
                  m(inputDate, {
                    label   : 'To:',
                    value   : c.gift().endDate,
                    onchange: c.modifiedDate.bind(null, true),
                  }),
                ]),
              ]),
              m('.gaia-gift-edition__general-info__line', [
                m('.gaia-gift-edition__general-info__line__icon.gaia-gift-edition__general-info__line__icon--euro'),
                m('.gaia-gift-edition__general-info__line__label', [
                  'Real cost:',
                  m('input.gaia-gift-edition__cost-input', {
                    type       : 'text',
                    value      : c.gift().cost(),
                    oninput    : m.withAttr('value', c.gift().cost),
                    placeholder: '',
                  }),
                  m(select, {
                    value      : c.currency,
                    list       : App.currencies(),
                    path       : 'displayName',
                    placeholder: 'Currency',
                  }),
                ]),
              ]),
              m('.gaia-gift-edition__general-info__line', [
                m('.gaia-gift-edition__general-info__line__icon.gaia-gift-edition__general-info__line__icon--mail'),
                m('.gaia-gift-edition__general-info__line__label', [
                  'Gift responsible(s) email(s):',
                ]),
                m('.gaia-gift-edition__general-info__line__right', [
                  m(emailInput, {
                    mails: c.gift().mails,
                  }),
                ]),
              ]),
              m('.gaia-gift-edition__general-info__line', [
                m('.gaia-gift-edition__general-info__line__icon.gaia-gift-edition__general-info__line__icon--gift'),
                m('.gaia-gift-edition__general-info__line__label', [
                  'Players can order several times the same gift:',
                  m(toggleButton, {
                    value  : c.gift().severalOrders,
                    class  : 'gift-toggle',
                    onclick: c.modified.bind(null, true),
                    key    : 2,
                  }),
                ]),
              ]),
            ]),
          ]),
          m('.gaia-gift-edition__save__bottom', [
            m(''),
            m(button, {
              value : c.gift().id() ? 'Save' : 'Create',
              class : 'button--action gaia-gift-edition__command-button',
              action: c.save.bind(null, true),
            }),
          ]),
        ]),
      ],
  ]);

  function displayStockPart() {
    if (!c.gift().id()) {
      return m('.gaia-gift-edition__general-info__line', [
        m('.gaia-gift-edition__general-info__line__label', 'Initial stock units:'),
        m('.gaia-gift-edition__general-info__line__right', [
          m('input.gaia-gift-edition__general-info__price-input', {
            type       : 'number',
            value      : c.gift().stock(),
            placeholder: '',
            oninput    : m.withAttr('value', c.gift().stock),
          }),
        ]),
      ]);
    }

    return m('.gaia-gift-edition__general-info__stock-panel', [
      m('.gaia-gift-edition__general-info__stock-panel__icon', []),
      m('.gaia-gift-edition__general-info__stock-panel__labels', [
        m('.gaia-gift-edition__general-info__stock-panel__label', 'Total allocated stock:'),
        m('.gaia-gift-edition__general-info__stock-panel__label', 'Ordered stock units:'),
        m('.gaia-gift-edition__general-info__stock-panel__label', 'Remaining stock units:'),
        m('.gaia-gift-edition__general-info__stock-panel__label', 'Adjust stock units:'),
      ]),
      m('.gaia-gift-edition__general-info__stock-panel__values', [
        m('.gaia-gift-edition__general-info__stock-panel__value', c.gift().stock()),
        m('.gaia-gift-edition__general-info__stock-panel__value', c.gift().orders().length),
        m('.gaia-gift-edition__general-info__stock-panel__value', c.gift().stock() - c.gift().orders().length),
        m('input.gaia-gift-edition__general-info__stock-panel__input', {
          type   : 'number',
          value  : c.stockVariation(),
          oninput: m.withAttr('value', c.stockVariation),
        }),
      ]),
      m('.gaia-gift-edition__general-info__stock-panel__commands', [
        m(button, {
          value : 'Remove',
          class : 'button--action button--border',
          action: c.changeStock.bind(null, false),
        }),
        m(button, {
          value : 'Add',
          class : 'button--action button--border',
          action: c.changeStock.bind(null, true),
        }),
      ]),
    ]);
  }
};
export default component;
