import { css } from 'aphrodite';
import React, { FunctionComponent } from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import type { TreeSelectItemWithChildren, TreeSelecteItemWithMeta } from '../STreeSelect';
import styles, { extendedStyles, DROPDOWN_ITEM_PADDING_LEFT, DROPDOWN_ITEM_PADDING_STEPS } from '../STreeSelect.style';

export type STreeSelectItemProps = {
  item: TreeSelectItemWithChildren | TreeSelecteItemWithMeta;
  level?: number; // Starts at 0
  collapsable?: boolean;
  onSelect: (id: number) => void;
  handleCollapse: (id: number) => void;
};

const hasChildren = (item: any): item is TreeSelectItemWithChildren => {
  return item?.children !== undefined && item?.children?.length > 0;
};

export const STreeSelectItem: FunctionComponent<STreeSelectItemProps> = ({
  item,
  level = 0,
  collapsable = true,
  onSelect,
  handleCollapse,
}) => {
  const paddingLeft = DROPDOWN_ITEM_PADDING_LEFT + DROPDOWN_ITEM_PADDING_STEPS * level;

  return (
    <>
      <div
        data-test-id="tree-select-item"
        data-item-id={item.id}
        tabIndex={-1}
        className={css(extendedStyles.dropdownItem)}
        style={{ paddingLeft }}
        onClick={() => onSelect(item.id)}
      >
        <p style={styles.dropdownItemLabel}>{item?.label}</p>
        {collapsable && hasChildren(item) && (
          <div
            data-test-id="tree-select-item-collapse-button"
            onClick={(evt) => {
              evt?.stopPropagation?.();
              handleCollapse(item.id);
            }}
          >
            <UIcon
              name={item.collapsed ? 'arrow-down' : 'arrow-up'}
              size={8}
              style={styles.icon}
              color={COLORS.TEXT.SECONDARY_DEFAULT}
            />
          </div>
        )}
      </div>
      {hasChildren(item) &&
        !item.collapsed &&
        item?.children?.map((child) => (
          <STreeSelectItem
            key={child.id}
            level={level + 1}
            item={child}
            collapsable={collapsable}
            onSelect={onSelect}
            handleCollapse={handleCollapse}
          />
        ))}
    </>
  );
};
