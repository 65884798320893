import React, { useMemo, useState } from 'react';
import { t } from 'i18next';

import { useTypedDispatch, useTypedSelector } from 'Libs/redux/utils';
import STable, { TableAction } from 'Components/structural/STable/STable';

import { actions } from '../../redux';
import type { CategoryDataAccessor } from '../../redux/models/CategoryTableData';
import { Header, HeaderType, OriginalRow, TABLE_ACTION_TYPES } from 'Components/structural/STable/types';
import { CallbackOption } from 'Components/structural/STable/components/CallbackOptions';

type FilterType = {
  search: string;
  order: Array<string>;
};

type OrderType = {
  [key: string]: string;
};

type ColumnType = string;
type ColumnOrderType = 'asc' | 'desc' | 'none';

export const CategoriesTable = () => {
  const dispatch = useTypedDispatch();

  const categories = useTypedSelector((state) => state.pages.categoryList.categoryTableData.entries);
  const total = categories.length;
  const isLoading = useTypedSelector((state) => state.pages.categoryList.loading);
  const [filter, setFilter] = useState<FilterType>({ search: '', order: [] });
  const [order, setOrder] = useState<OrderType>({});
  const title = t('config_categories:categories_table.title');

  const callbackOptions: CallbackOption[] = useMemo(() => {
    const handleRemoveCategory = (category: CategoryDataAccessor) => {
      return dispatch(actions.toggleDeletionModal(true, category));
    };

    return [
      {
        icon: 'delete',
        id: 'action_delete_id',
        name: 'removeCategory',
        callback: handleRemoveCategory,
      },
    ];
  }, [dispatch]);

  const data: OriginalRow[] = useMemo(() => {
    return categories.map((entry) => {
      const categoryId: number = entry.id as any;

      if (entry.label && typeof entry.label === 'object') {
        entry.label.params = {
          ...entry.label.params,
          onSubmit: (value: string) => dispatch(actions.edit(categoryId, value)),
          onReset: () => dispatch(actions.resetRowErrors()),
          callbackOptions,
        };
      }
      entry.callbackOptions = callbackOptions;

      return entry;
    });
  }, [dispatch, categories, callbackOptions]);

  const tableActions: TableAction[] = useMemo<TableAction[]>((): TableAction[] => {
    const handleCreate = () => {
      return dispatch(actions.toggleCreationModalStatus(true));
    };

    const handleSearch = (search: string) => {
      const newFilter = { ...filter, search };

      setFilter(newFilter);

      return dispatch(actions.fetch(newFilter));
    };

    return [
      {
        type: TABLE_ACTION_TYPES.SEARCH,
        id: 'table-action-1',
        params: {
          placeholder: t('config_categories:categories_table.placeholder'),
          placeholderOnExpand: t('config_categories:categories_table.placeholder_on_expand'),
          onAction: handleSearch,
          onRequestEnd: () => Promise.resolve(),
          onClick: () => Promise.resolve(),
        },
      },
      {
        type: TABLE_ACTION_TYPES.CREATE,
        id: 'table-action-2',
        params: {
          text: t('config_categories:categories_table.table_action_text'),
          onAction: handleCreate,
          onClick: () => Promise.resolve(),
          onRequestEnd: () => Promise.resolve(),
        },
      },
    ];
  }, [dispatch, filter]);

  const columns: Header[] = useMemo(() => {
    const handleSort = (column: ColumnType, columnOrder: ColumnOrderType) => {
      const currentFilter = filter;
      const currentOrder = order;

      if (columnOrder === 'none') delete currentOrder[column];
      else currentOrder[column] = columnOrder;

      setOrder(currentOrder);

      currentFilter.order = [];

      Object.keys(currentOrder).forEach(function add(key) {
        currentFilter.order.push(key + ' ' + currentOrder[key]);
      });

      return dispatch(actions.fetch(currentFilter));
    };

    return [
      {
        id: '0',
        type: HeaderType.CellHeader,
        headerParams: {
          text: t('config_categories:categories_table.columns_text'),
          align: 'left',
          onAction: handleSort,
        },
        width: '180px',
        disableResizing: true,
        accessor: 'label',
      },
    ];
  }, [dispatch, order, filter]);

  return (
    <STable
      title={title}
      columns={columns}
      data={data}
      total={total}
      tableActions={tableActions}
      isTableLoading={isLoading}
    />
  );
};
