import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch } from 'Libs/redux/store';
import type { InferPushQueueItem } from 'Libs/queue/redux/utils';
import { useActionDialogConfirmation } from 'Hooks/useActionDialogConfirmation/useActionDialogConfirmation';

import SPageHeader, { IconPropsType } from 'Components/structural/SPageHeader/SPageHeader';
import UTextLink from 'Components/unit/UTextLink/UTextLink';
import UButton from 'Components/unit/UButton/UButton';

import type { CreateUpdateCommandData } from 'Pages/Courses/utils/commands';
import {
  getHandleDeleteMk,
  getHandleInsertMk,
  getHandleReorderMk,
  getHandleUpdateMk,
  HandleInsertMkParam,
  HandleMkDeleteParams,
  HandleReorderMk,
  MkQueueHandler,
} from 'Pages/Courses/utils/edition-handlers';

import MicroKnowledgeList from 'Pages/Courses/Edition/components/MicroKnowledgeList/MicroKnowledgeList';
import { actions, useModuleSelector } from 'Pages/Courses/redux';

import { trackEventWithLocation } from 'Services/trackingService';

import styles from './Microknowledges.style';

export type MicroknowledgesProps = {
  push: (item: InferPushQueueItem<MkQueueHandler>) => void;
  uploadButtonVisible?: boolean;
  onClickUpload?: () => void;
  style?: CSSProperties;
  styleList?: CSSProperties;
};

export type PublicationModalState = {
  isOpened: boolean;
  isPublished: boolean;
};

type HandleChapterCollapseParams = { id: string };

export const Microknowledges: FunctionComponent<MicroknowledgesProps> = ({
  push,
  uploadButtonVisible,
  onClickUpload,
  style,
  styleList,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const mkList = useModuleSelector((state) => state.edition.microKnowledges);

  const loading = useModuleSelector((state) => state.edition.loading);

  const { dialog: chapterDeleteConfirmationDialog, askForConfirmation: askConfirmationForChapterDelete } =
    useActionDialogConfirmation({
      title: 'Warning',
      description:
        'If you delete this chapter, the micro-knowledges will be deleted too.\n\n Are you sure you want to delete this chapter?',
      primaryActionLabel: 'Delete',
      primaryActionButtonType: 'destructive',
      secondaryActionLabel: 'Keep the chapter',
    });

  const { dialog: chapterConversionConfirmationDialog, askForConfirmation: askConfirmationForChapterTypeConversion } =
    useActionDialogConfirmation({
      title: 'Warning',
      description:
        'If you change this line type, the micro-knowledges contained by it will no longer be contained in a chapter.\n\nAre you sure you want to make this change?',
      primaryActionLabel: 'Change for text',
      primaryActionButtonType: 'destructive',
      secondaryActionLabel: 'Keep as chapter',
    });

  const { dialog: imageConversionConfirmationDialog, askForConfirmation: askConfirmationForImageTypeConversion } =
    useActionDialogConfirmation({
      title: 'Warning',
      description:
        'If you change this line type, the micro-knowledges contained by it will no longer be contained in a chapter.\n\nAre you sure you want to make this change?',
      primaryActionLabel: 'Change for image',
      primaryActionButtonType: 'destructive',
      secondaryActionLabel: 'Keep as chapter',
    });

  const { dialog: videoConversionConfirmationDialog, askForConfirmation: askConfirmationForVideoTypeConversion } =
    useActionDialogConfirmation({
      title: 'Warning',
      description:
        'If you change this line type, the micro-knowledges contained by it will no longer be contained in a chapter.\n\nAre you sure you want to make this change?',
      primaryActionLabel: 'Change for video',
      primaryActionButtonType: 'destructive',
      secondaryActionLabel: 'Keep as chapter',
    });

  const [baseHandleDeleteMk, baseHandleUpdateMk, baseHandleInsertMk, baseHandleReorderMk] = useMemo(
    () => [
      getHandleDeleteMk({ store, enqueue: push }),
      getHandleUpdateMk({ store, enqueue: push }),
      getHandleInsertMk({ store, enqueue: push }),
      getHandleReorderMk({ store, enqueue: push }),
    ],
    [push],
  );

  const baseHandleDeleteMkWithTracking = useCallback(
    (params: HandleMkDeleteParams) => {
      baseHandleDeleteMk(params);
      trackEventWithLocation('delete', { context: 'micro-knowledge' });
    },
    [baseHandleDeleteMk],
  );

  const baseHandleUpdateMkWithTracking = useCallback(
    (params: CreateUpdateCommandData) => {
      baseHandleUpdateMk(params);
      if (mkList.items[params.id].type !== params.type) {
        trackEventWithLocation('update', {
          context: `micro-knowledge-${params.type}`,
        });
      }
    },
    [baseHandleUpdateMk, mkList.items],
  );

  const handleInsertMkWithTracking = useCallback(
    (params: HandleInsertMkParam) => {
      baseHandleInsertMk(params);
      trackEventWithLocation('create', { context: 'micro-knowledge' });
    },
    [baseHandleInsertMk],
  );

  const handleUpdateMk = useCallback(
    (params: CreateUpdateCommandData) => {
      if (mkList.items[params.id].type === 'chapter' && params.type === 'text') {
        askConfirmationForChapterTypeConversion(() => baseHandleUpdateMkWithTracking(params));
        return;
      }
      if (mkList.items[params.id].type === 'chapter' && params.type === 'image') {
        askConfirmationForImageTypeConversion(() => baseHandleUpdateMkWithTracking(params));
        return;
      }
      if (mkList.items[params.id].type === 'chapter' && params.type === 'video') {
        askConfirmationForVideoTypeConversion(() => baseHandleUpdateMkWithTracking(params));
        return;
      }

      baseHandleUpdateMkWithTracking(params);
    },
    [
      askConfirmationForChapterTypeConversion,
      askConfirmationForImageTypeConversion,
      baseHandleUpdateMkWithTracking,
      askConfirmationForVideoTypeConversion,
      mkList.items,
    ],
  );

  const handleDeleteMk = useCallback(
    (params: HandleMkDeleteParams) => {
      if (mkList.items[params.id].type === 'chapter') {
        askConfirmationForChapterDelete(() => baseHandleDeleteMkWithTracking(params));
        return;
      }
      baseHandleDeleteMkWithTracking(params);
    },
    [askConfirmationForChapterDelete, baseHandleDeleteMkWithTracking, mkList.items],
  );

  const handleChapterCollapse = useCallback(
    (params: HandleChapterCollapseParams) => dispatch(actions.toggleChapterCollapse(params)),
    [dispatch],
  );

  const handleInsertMkAtEndOfList = useCallback(
    () => handleInsertMkWithTracking({ targetId: null }),
    [handleInsertMkWithTracking],
  );

  // TODO: implement after V0
  const handleDuplicate = useCallback(() => {}, []);

  const handleReorder = useCallback(
    (args: HandleReorderMk) => {
      baseHandleReorderMk(args);
      trackEventWithLocation('reorder', { context: 'micro-knowledge' });
    },
    [baseHandleReorderMk],
  );

  // Boolean which control the creation of a default mk
  const [shouldTryToInsertDefaultMk, setShouldTryToInsertDefaultMk] = useState(true);

  const wrapperStyle = useMemo(() => ({ ...styles.wrapper, ...style }), [style]);
  const listStyle = useMemo(() => ({ ...styles.list, ...styleList }), [style]);

  // Mechanism to insert mk if list is empty at first navigation.
  useEffect(() => {
    // When mk is loaded and there is one item we should deactivate mecanism
    if (mkList?.headId) {
      setShouldTryToInsertDefaultMk(false);

      return;
    }

    if (loading || !shouldTryToInsertDefaultMk) {
      return;
    }

    setShouldTryToInsertDefaultMk(false);
    handleInsertMkAtEndOfList();
  }, [handleInsertMkAtEndOfList, loading, mkList?.headId, shouldTryToInsertDefaultMk]);

  const pageHeaderIconProps = useMemo(
    (): IconPropsType => ({
      iconName: 'plus',
      iconType: 'accentuated',
      onIconClick: handleInsertMkAtEndOfList,
    }),
    [handleInsertMkAtEndOfList],
  );

  return (
    <>
      <div style={wrapperStyle}>
        <div style={listStyle}>
          <div style={styles.containerHeader}>
            <SPageHeader
              title={t('courses:course_edition.title')}
              iconProps={pageHeaderIconProps}
              style={styles.header}
            />
            {uploadButtonVisible && (
              <UButton data-test-id="upload-pdf-button" type="accentuated" text="Upload PDF" onClick={onClickUpload} />
            )}
          </div>
          {mkList?.headId && (
            <>
              <MicroKnowledgeList
                microKnowledgeMap={mkList}
                onDelete={handleDeleteMk}
                onInsert={handleInsertMkWithTracking}
                onToggleCollapse={handleChapterCollapse}
                onContentChange={handleUpdateMk}
                onTitleChange={handleUpdateMk}
                onUpdateType={handleUpdateMk}
                onImageChange={handleUpdateMk}
                onVideoChange={handleUpdateMk}
                onDuplicate={handleDuplicate}
                onReorder={handleReorder}
              />
              <div style={styles.insertButtonWrapper}>
                <UTextLink
                  text={t('courses:course_edition.add_mk')}
                  leftIcon="plus"
                  iconSize="medium"
                  onClick={handleInsertMkAtEndOfList}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {chapterDeleteConfirmationDialog}
      {chapterConversionConfirmationDialog}
      {imageConversionConfirmationDialog}
      {videoConversionConfirmationDialog}
    </>
  );
};

export default Microknowledges;
