import { handle } from 'redux-pack';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { Action } from 'Libs/redux/types';

export type UpdatePasswordState = DeepReadonly<{
  loading: boolean;
  error: string;
}>;

export const defaultState: UpdatePasswordState = {
  loading: false,
  error: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: UpdatePasswordState = defaultState, action: Action): UpdatePasswordState => {
  switch (action.type) {
    case 'ADMIN_UPDATE_PASSWORD': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { error: '', loading: true }),
        success: (prevState) => mergeDeep(prevState, { error: '' }),
        failure: (prevState) => {
          const errorMessage =
            action.payload?.message?.error?.name === 'PasswordAlreadyUsedError'
              ? 'Your new password must be different from the two previous passwords used'
              : 'Something went wrong.';

          return mergeDeep(prevState, {
            loading: false,
            error: errorMessage,
          });
        },
      });
    }

    default:
      return state;
  }
};
