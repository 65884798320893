import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import type { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import type { State, Dispatch } from '../types';

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector;

export const useTypedDispatch = () => useDispatch<Dispatch>();

export const createModuleSelectorFactory =
  <Scope extends keyof State>(scope: Scope) =>
  <Key extends keyof State[Scope]>(key: Key) =>
  <T>(moduleSelector: (state: State[Scope][typeof key]) => T) =>
    useSelector((state: State) => moduleSelector(state[scope][key]));

export const moduleSelectorFactory = createModuleSelectorFactory('pages');
export const serviceSelectorFactory = createModuleSelectorFactory('services');
export const entitiesSelectorFactory = createModuleSelectorFactory('entities');

export const createAsyncAction = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, { state: State }>,
): AsyncThunk<Returned, ThunkArg, {}> => {
  // @ts-ignore -- TODO: fix this
  const wrappedPayloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg> = async (arg, thunkApi) => {
    try {
      return await payloadCreator(arg, thunkApi);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  };

  return createAsyncThunk(typePrefix, wrappedPayloadCreator);
};
