import React, { useMemo } from 'react';

import STooltip from 'Components/structural/STooltip/STooltip';
import UFlag from 'Components/unit/UFlag/UFlag';
import { UStatusIndicator } from 'Components/unit/UStatusIndicator/UStatusIndicator';
import UIcon from 'Components/unit/UIcon/UIcon';
import Enum from 'Models/Enum';
import { ActivityStatusType, ActivityType } from '../SActivityListItem.types';

import styles, { getLikesViewsMargin } from '../SActivityListItem.style';

type Props = {
  languages: ReadonlyArray<string>;
  activityStatus: ActivityStatusType;
  activityType: ActivityType;
  likes?: number;
  views?: number;
};

export const NB_LIMIT_FLAG = 3;

export const LanguagesLikesViews = ({ languages, activityStatus, activityType, likes, views }: Props) => {
  const nbLanguagesMore = languages.length - NB_LIMIT_FLAG;
  const languagesToDisplay = languages.slice(0, NB_LIMIT_FLAG);

  const isActivityPendingTranslation = activityStatus === 'Pending translation';
  const isActivityBreakingNews = activityType === Enum.activityTypeEnum.BREAKING_NEWS;
  const isActivityDailySerie = activityType === Enum.activityTypeEnum.DAILY_SERIE;

  const likesViewsWrapperStyle = useMemo(
    () => ({
      ...styles.containerLikesViews,
      ...getLikesViewsMargin(languages.length),
    }),
    [languages.length],
  );

  return (
    <div style={styles.wrapperFooterRight}>
      <div style={styles.containerFooterRight}>
        {languagesToDisplay.map((language, index) => {
          const size = !index ? 20 : 16;
          const style = !index && !isActivityPendingTranslation ? styles.mainFlag : styles.flag;

          return (
            <div key={language}>
              {isActivityPendingTranslation && !index ? <UStatusIndicator type="warning" /> : null}
              <div data-tip data-for={language} style={style}>
                <UFlag countryCode={language} size={size} />
              </div>
              <STooltip id={language} position="top" content={language} />
            </div>
          );
        })}
        {nbLanguagesMore > 0 ? (
          <>
            <div data-tip data-for={`${nbLanguagesMore}+`} style={styles.nbLanguagesMore}>
              {`${nbLanguagesMore}+`}
            </div>
            <STooltip
              id={`${nbLanguagesMore}+`}
              position="top"
              content={languages.slice(NB_LIMIT_FLAG, languages.length).join(', ')}
            />
          </>
        ) : null}
        {(isActivityBreakingNews || isActivityDailySerie) && (
          <div style={likesViewsWrapperStyle}>
            <div style={styles.textLikesViews} data-test-id="likes-nb">
              {`${likes ?? 0}`}
            </div>
            <UIcon name="candidates-like" size={16} />
            {isActivityBreakingNews && (
              <>
                <div style={{ ...styles.textLikesViews, ...styles.textViews }} data-test-id="views-nb">
                  {`${views ?? 0}`}
                </div>
                <UIcon name="eye" size={16} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
