import { ThematicEntity } from './thematic.types';

export const translateThematicsName = (languageId: number, thematics: ThematicEntity[]) =>
  thematics.map((thematic) => {
    const translatedLabel =
      thematic.polyglotLabel?.translatedLabels?.find((el) => el?.languageId === languageId)?.label || '';
    return {
      ...thematic,
      translatedLabel: translatedLabel && translatedLabel.length > 0 ? translatedLabel : thematic.name,
    };
  });
