import React, { useRef, useEffect, useMemo, useCallback, type ReactNode } from 'react';
import type {
  FocusEventHandler,
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  InputHTMLAttributes,
} from 'react';

import SInputContainer from 'Components/structural/SInputContainer/SInputContainer';
import UIconButton from 'Components/unit/UIconButton/UIconButton';

import useInputStatus from 'Hooks/useInputStatus/useInputStatus';

import type { UIconButtonType } from 'Components/unit/UIconButton/UIconButton';

import type { IconName } from 'Components/foundation/icons';

import styles, { STYLE_BY_SIZE } from './SInput.style';

export type InputIcon = {
  icon: IconName;
  type?: UIconButtonType;
  ghost?: boolean;
};
export type InputType = 'micro' | 'mini' | 'small' | 'large';
type AutoCompleteType = 'on' | 'off';

export type SInputProps = {
  // Require
  type: InputType;

  // Optional
  id?: string;
  regex?: RegExp;
  contentType?: string;
  name?: string;
  autoComplete?: AutoCompleteType;
  label?: string;
  value?: string;
  icon?: InputIcon;
  customIcon?: ReactNode;
  onIconClick?: (value: string) => any;
  onSubmit?: (value: string) => any;
  onChange?: (value: string) => any;
  placeholder?: string;
  errorMessage?: string;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  autofocus?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  hideCounter?: boolean;
  required?: boolean;
  style?: CSSProperties;
  extraInputStyle?: CSSProperties;
  inputHtmlAttributes?: InputHTMLAttributes<HTMLInputElement>;
};

const TYPE = {
  MICRO: 'micro',
  MINI: 'mini',
  SMALL: 'small',
  LARGE: 'large',
};

const ENTER_KEY_EVENT = 'Enter';

/**
 * Display a input
 *
 * Props:
 * - type: Handle the input type, modify style based on this type
 * - regex: Regular expression applied to the input value to eliminate character matching the regex
 *   - TIPS: if you want to eliminate all non numeric char use []
 *   - as follow: /[^\d]/g this will remove all non numeric char
 * - contentType: Allow to set the native HTML type to this input
 * - name: Set a input name
 * - autoComplete: Control the auto fill of browser
 * - label: Set a input label
 * - value: Set a value from props to input
 * - icon: object representing icon to display an icon button
 * - onIconClick: Function call to trigger an action on icon button
 * - onSubmit: Function call to submit text in state
 * - onChange: Function call to submit text in state after typing
 * - placeholder: String to set the input placeholder
 * - errorMessage: String to set a error feedback under the input
 * - maxLength: Specify a max number of characs
 * - disabled: disable the capacity to modify input
 * - autofocus: trigger the input focus at component render
 * - onFocus: Function call to do action at input focus
 * - hideCounter: Boolean which hides or shows the counter
 * - style: can override the component style
 */
export const SInput = ({
  type,
  id,
  contentType = 'text',
  regex = undefined,
  name = '',
  autoComplete = 'on',
  label = '',
  value = '',
  icon = undefined,
  customIcon,
  onIconClick = () => {},
  onSubmit = undefined,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  placeholder = '',
  errorMessage = '',
  maxLength = undefined,
  minLength = 0,
  hideCounter = false,
  autofocus = false,
  disabled = false,
  required = false,
  style = undefined,
  extraInputStyle,
  inputHtmlAttributes,
}: SInputProps) => {
  // We need to use a ref to handle autofocus 'cause autofocus prop on input
  // only works when the <input> is initially rendered
  const inputRef = useRef<HTMLInputElement>(null);

  // Styling
  const inputStyle: CSSProperties = useMemo(
    () => ({
      ...styles.textField,
      ...styles[type],
      ...(!icon && type !== 'micro' ? STYLE_BY_SIZE.withoutIcon[type] : {}),
      ...(disabled ? styles.textDisabled : {}),
      ...extraInputStyle,
    }),
    [icon, type, disabled, extraInputStyle],
  );

  const iconButtonStyle: CSSProperties = useMemo(
    () =>
      icon?.ghost
        ? {
            ...STYLE_BY_SIZE.iconButton.default,
            ...(type !== 'micro' ? STYLE_BY_SIZE.iconButton[type] : {}),
          }
        : styles.iconGhostDisabled,
    [type, icon],
  );

  // Handlers
  const { handleFocus, stateStatus } = useInputStatus({ onBlur, onFocus, autofocus, disabled });

  const handleClick = useCallback(
    (event: MouseEvent) => {
      handleFocus(true)(event as any);
    },
    [handleFocus],
  );

  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: valueChanged } = event.target;

      onChange(valueChanged.slice(0, maxLength || undefined));
    },
    [onChange, maxLength],
  );

  const handleIconClick = useCallback(() => onIconClick(value), [onIconClick, value]);

  const handleSubmit = useCallback(
    (event: KeyboardEvent) => (event.key === ENTER_KEY_EVENT && onSubmit ? onSubmit(value) : null),
    [value, onSubmit],
  );

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus, inputRef]);

  return (
    <SInputContainer
      id={id}
      label={label}
      currentCount={value ? value.length : 0}
      maxLength={maxLength}
      hideCounter={hideCounter}
      valueChangeFeedbackStatus={errorMessage ? 'error' : 'none'}
      valueChangeFeedbackMessage={errorMessage || ''}
      inputStateStatus={stateStatus}
      required={required}
      style={style}
    >
      <input
        id={id}
        ref={inputRef}
        type={contentType}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength || undefined}
        minLength={minLength || undefined}
        value={value}
        onChange={handleValueChange}
        onClick={handleClick}
        onBlur={handleFocus(false)}
        onFocus={handleFocus(true)}
        onKeyDown={handleSubmit}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
        disabled={disabled}
        autoComplete={autoComplete}
        className="structural-inputs"
        style={inputStyle}
        {...(regex ? { pattern: regex.source } : {})}
        {...inputHtmlAttributes}
      />
      {type !== TYPE.MICRO && icon && !customIcon && (
        <UIconButton {...icon} onClick={handleIconClick} size="S" disabled={disabled} style={iconButtonStyle} />
      )}
      {type !== TYPE.MICRO && customIcon}
    </SInputContainer>
  );
};

export default SInput;
