import { GrowthBook } from '@growthbook/growthbook';

import Config from 'Services/instanceConfig';

const growthbook = new GrowthBook({
  apiHost: process.env.GROWTHBOOK_API_HOST,
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY_BACK_OFFICE,
  features: {
    homepage: { defaultValue: true },
    'courses-list': { defaultValue: true },
    'course-edition': { defaultValue: true },
    'course-audience': { defaultValue: true },
    'ambassador-update-list': { defaultValue: true },
    'translated-bo': { defaultValue: false },
    'summarize-text': { defaultValue: false },
    'course-pdf-extraction': { defaultValue: false },
    'document-openings': { defaultValue: true },
    'audience-configuration-page': { defaultValue: true },
  },
});

export const initFeatureFlags = async (instance?: string) => {
  await growthbook.loadFeatures({ autoRefresh: true });

  growthbook.setAttributes({
    instance: instance ?? Config.instanceId,
  });
};

const isFeatureEnabled = (featureName: string) => {
  return growthbook.isOn(featureName);
};

export { isFeatureEnabled };
