// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

const BORDER_RADIUS_VALUE = 4;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'transparent',
    minHeight: 50,
    alignItems: 'center',
  },
  hover: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    cursor: 'pointer',
    ...BORDER_RADIUS,
  },
  editMode: {
    padding: 0,
  },
  text: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 400,
    padding: '7px 10px',
    minHeight: 32,
  },
  number: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 700,
  },
  date: {
    ...TYPOGRAPHY.BODY4,
    fontWeight: 400,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
};
