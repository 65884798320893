import { TYPOGRAPHY, COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const ELLIPSIS = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
} as const;

const styles = createStyleSheet({
  wrapper: {
    flex: 1,
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
    paddingTop: 4,
    paddingBottom: 4,
    overflowY: 'auto',

    // disable focus style
    outline: 'none',
  },
  headerContainer: {
    height: 33,
    display: 'flex',
    alignItems: 'flex-end',
  },
  header: {
    ...TYPOGRAPHY.BODY4,
    ...ELLIPSIS,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    paddingLeft: 16,
    paddingBottom: 4,
  },
});

export default styles;
