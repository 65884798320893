import { COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    paddingTop: 32,
    paddingBottom: 50,
  },
  box: {
    width: 1000,
    margin: 'auto',
    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
    minHeight: 400,
  },
});
