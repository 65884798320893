// @flow

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    height: '100%',
  },
  documentWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  filter: {
    width: '100%',
    minHeight: 193,
  },
  filterWrapper: {
    width: '100%',
  },
  documentListWrapper: {
    width: '940px',
    marginBottom: '87px',
  },
  uploadTitle: {
    marginTop: '56px',
    marginBottom: '32px',
    ...TYPOGRAPHY.H1,
  },
};
