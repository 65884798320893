import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  overlay: {
    opacity: '50%',
  },
  dropBar: {
    marginTop: 4,
    marginBottom: 4,
    width: 'calc(100% - 26px)',
    marginLeft: 26,
  },
  childDropBar: {
    width: 'calc(100% - 26px - 42px)',
    marginLeft: 26 + 42,
  },
});
