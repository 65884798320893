/* eslint-disable complexity */
// @flow

import React, { useCallback } from 'react';
import { t } from 'i18next';

import { useTypedSelector } from 'Libs/redux/utils';

import MModal from 'Components/modal/MModal/MModal';

import styles from './NotificationModal.style';

type ModalProps = {|
  visible: boolean,
  onClose: () => mixed,
  onSendNotification: () => mixed,
|};

export const NotificationModal = ({ visible, onClose, onSendNotification }: ModalProps) => {
  const isSending = useTypedSelector(state => state.pages.documentList.loadingNotification);
  const modalError = useTypedSelector(state => state.pages.documentList.notificationModalError);

  const onDismiss = useCallback(() => onClose(), [onClose]);
  const onValidate = useCallback(() => {
    if (isSending)
      return;

    onSendNotification();
  }, [onSendNotification, isSending]);

  return (
    <MModal
      style={styles.modal}
      visible={visible}
      onCloseModal={onDismiss}
      onAction={onValidate}
      onSecondAction={onDismiss}
      type="dialog"
      title={t('documents:notification_modal.notify_players_title')}
      labelActionButton={t('documents:notification_modal.label_action_button')}
      labelSecondButton={t('documents:notification_modal.label_second_button')}
      actionButtonType="accentuated"
      isActionLoading={isSending}
      labelError={modalError}
    >
      <div style={styles.content}>
        {t('documents:notification_modal.do_you_wish_to_notify')}
        <div style={styles.contentSecondary}>{t('documents:notification_modal.be_careful_about')}</div>
      </div>
    </MModal>
  );
};
