import Enum from 'Models/Enum';

import type { GameplayContentKeyType, GameplayType } from 'Libs/ts/types';

type RightMapType = {
  [roleId: number]: ReadonlyArray<number>;
};

const { WRITER, ADMIN, MASTER, ROOT, TRANSLATOR, ANALYST } = Enum.Role;

const GAMEPLAY_TYPES = {
  ERROR_TEXT: 'error_text',
  NO_INTERACTION_CONTENT: 'no_interaction',
  OPEN_QUESTION: 'open_question',
  PICTURE_SPOT: 'picture_spot',
  QCM: 'mcq',
  SQCM: 'sqcm',
  SWIPING_CARDS: 'swiping_cards',
  ORDERING_CARDS: 'ordering_cards',
} as const;

const GAMEPLAY_KEYS = {
  QCM: 'QCM',
  SQCM: 'SQCM',
  ERROR_TEXT: 'Error Text',
  OPEN_QUESTION: 'Open Question',
  NO_INTERACTION_CONTENT: 'No Interaction Content',
  PICTURE_SPOT: 'Picture Spot',
  SWIPING_CARDS: 'Swiping Cards',
  ORDERING_CARDS: 'Ordering Cards',
} as const;

const CONTENT_ACTIONS = {
  VALIDATE: 'validate',
  SAVE: 'save',
  PREVIEW: 'preview',
} as const;

// Be careful about the gameplay order
const GAMEPLAY: ReadonlyArray<GameplayType> = [
  'mcq',
  'sqcm',
  'open_question',
  'error_text',
  'swiping_cards',
  'picture_spot',
  'no_interaction',
  'ordering_cards',
];

const GAMEPLAY_ID_TO_STRING: { [id: number]: GameplayType } = {
  [Enum.gameplay.QCM]: 'mcq',
  [Enum.gameplay.SQCM]: 'sqcm',
  [Enum.gameplay.ERROR_TEXT]: 'error_text',
  [Enum.gameplay.OPEN_QUESTION]: 'open_question',
  [Enum.gameplay.NO_INTERACTION_CONTENT]: 'no_interaction',
  [Enum.gameplay.PICTURE_SPOT]: 'picture_spot',
  [Enum.gameplay.SWIPING_CARDS]: 'swiping_cards',
  [Enum.gameplay.ORDERING_CARDS]: 'ordering_cards',
} as const;

const GAMEPLAY_ID_TO_CONTENT_KEY: { [id: number]: GameplayContentKeyType } = {
  [Enum.gameplay.QCM]: GAMEPLAY_KEYS.QCM,
  [Enum.gameplay.SQCM]: GAMEPLAY_KEYS.SQCM,
  [Enum.gameplay.ERROR_TEXT]: GAMEPLAY_KEYS.ERROR_TEXT,
  [Enum.gameplay.OPEN_QUESTION]: GAMEPLAY_KEYS.OPEN_QUESTION,
  [Enum.gameplay.NO_INTERACTION_CONTENT]: GAMEPLAY_KEYS.NO_INTERACTION_CONTENT,
  [Enum.gameplay.PICTURE_SPOT]: GAMEPLAY_KEYS.PICTURE_SPOT,
  [Enum.gameplay.SWIPING_CARDS]: GAMEPLAY_KEYS.SWIPING_CARDS,
  [Enum.gameplay.ORDERING_CARDS]: GAMEPLAY_KEYS.ORDERING_CARDS,
} as const;

const MESSAGE_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const;

const DOCUMENT_FILE_TYPES = {
  PDF: 'pdf',
} as const;

const IMAGES_MIME_TYPES = {
  GIF: 'image/gif',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  XSLX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel',
  CSV: 'text/csv',
} as const;

const IMAGE_SOURCE_NAMES = {
  GOOGLE: 'Google',
  LIBRARY: 'Library',
  UNSPLASH: 'Unsplash',
} as const;

const IMAGE_LIB_SOURCE_NAMES = {
  GOOGLE: 'Google',
  UNSPLASH: 'Unsplash',
} as const;

const PDF_MIME_TYPES = {
  PDF: 'application/pdf',
} as const;

const FILE_MIME_TYPES = {
  XSL: 'application/vnd.ms-excel',
  XSLX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
} as const;

const ROLES_AVAILABLE_BY_EDITOR_ROLE: RightMapType = {
  [ROOT]: [ROOT, MASTER, ADMIN, WRITER, TRANSLATOR, ANALYST],
  [MASTER]: [MASTER, ADMIN, WRITER, TRANSLATOR, ANALYST],
  [ADMIN]: [ADMIN, WRITER, TRANSLATOR, ANALYST],
};

const CONTRIBUTOR_EDIT_RIGHTS: RightMapType = {
  ...ROLES_AVAILABLE_BY_EDITOR_ROLE,
  [ADMIN]: [WRITER, TRANSLATOR, ANALYST],
};

const CONTRIBUTOR_ACCESS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
} as const;

export {
  CONTENT_ACTIONS,
  CONTRIBUTOR_EDIT_RIGHTS,
  DOCUMENT_FILE_TYPES,
  GAMEPLAY,
  GAMEPLAY_ID_TO_STRING,
  GAMEPLAY_KEYS,
  GAMEPLAY_ID_TO_CONTENT_KEY,
  IMAGE_LIB_SOURCE_NAMES,
  IMAGE_SOURCE_NAMES,
  IMAGES_MIME_TYPES,
  MESSAGE_TYPES,
  PDF_MIME_TYPES,
  FILE_MIME_TYPES,
  ROLES_AVAILABLE_BY_EDITOR_ROLE,
  CONTRIBUTOR_ACCESS_STATUS,
  GAMEPLAY_TYPES,
};
