import m from 'm';
import { t } from 'i18next';

import WelcomePanel from 'models/WelcomePanel';
import welcomePanel from 'components/welcome.panel';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.addStep = function addStep() {
    args.welcomeCard.panels().push(new WelcomePanel({
      welcomeCardId: args.welcomeCard.id(),
      order        : args.welcomeCard.panels().length,
      title        : t('welcome_card:step_title_placeholder'),
      text         : t('welcome_card:step_text_placeholder'),
    }));
    orderPanels();
  };

  self.movePanel = function movePanel(panel, up) {
    var switchPanel;

    args.welcomeCard.panels().forEach(function getSwitch(pan) {
      if (up && panel.order() < pan.order() && (!switchPanel || (pan.order() < switchPanel.order())))
        switchPanel = pan;
      if (!up && panel.order() > pan.order() && (!switchPanel || (pan.order() > switchPanel.order())))
        switchPanel = pan;
    });
    if (!switchPanel)
      return undefined;

    var switchOrder = switchPanel.order();

    switchPanel.order(panel.order());
    panel.order(switchOrder);

    orderPanels();
    m.redraw();
  };

  function orderPanels() {
    args.welcomeCard.panels().items.sort(function sortByOrder(a, b) {
      if (a.order() > b.order())
        return 1;
      if (a.order() < b.order())
        return -1;

      return 0;
    }).map(giveOrder);
  }

  function giveOrder(panel, key) {
    panel.order(key + 1);
  }

  self.orderPanels = orderPanels;

  orderPanels();

};

component.view = function view(c, args) {
  return m('.welcome-panel-list', [
    m('.welcome-panel-list__header', t('welcome_card:cards')),
    m('.welcome-panel-list__list-panel-container', displayPanelList()),
  ]);

  function displayPanelList() {
    return [
      args.welcomeCard.panels().map(displayPanel),
      m('.welcome-panel-list__panel-container.welcome-panel-list__panel-container__add-one', {
        onclick: c.addStep,
      }, t('welcome_card:add_card')),
    ];
  }

  function displayPanel(panel) {
    return m('.welcome-panel-list__panel-container', [
      m('.welcome-panel-list__panel-container__order-container', [
        m('.welcome-panel-list__panel-container__order-change.welcome-panel-list__panel-container__order-down', {
          onclick: c.movePanel.bind(null, panel, false),
        }),
        m('.welcome-panel-list__panel-container__order-label', panel.order()),
        m('.welcome-panel-list__panel-container__order-change.welcome-panel-list__panel-container__order-up', {
          onclick: c.movePanel.bind(null, panel, true),
        }),
      ]),
      m(welcomePanel, {
        panel     : panel,
        nbPanels  : args.welcomeCard.panels().length,
        collection: args.welcomeCard.panels(),
        reorder   : c.orderPanels,
        key       : panel.id() || panel._uuid,
      }),
    ]);
  }
};

export default component;
