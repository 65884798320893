import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import { BUTTON_TYPES, UButtonType } from './UButton.utils';

const styleSheet = createStyleSheet({
  progressWrapper: {
    position: 'relative',
  },
  wrapper: {
    display: 'inline-block',
    borderRadius: 4,
    paddingTop: 7,
    paddingBottom: 7,
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    whiteSpace: 'nowrap',
    ...TYPOGRAPHY.BODY3_SEMIBOLD,
    position: 'relative',
    overflow: 'hidden',
  },
  ghost: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'tranparent',
    paddingTop: 6,
    paddingBottom: 6,
  },
  paddingIcon: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: LEVELS.LOCAL_OFFSET_2,
  },
  loaderContainer: {
    position: 'absolute',
    zIndex: LEVELS.LOCAL_OFFSET_3,
  },
  progressContainer: {
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: LEVELS.LOCAL_OFFSET,
  },
  progressZone: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  iconLeft: {
    marginRight: 8,
  },
  iconRight: {
    marginLeft: 8,
  },
  ghostButton_disabled: {
    opacity: 0.5,
  },
  loadingPaddingIcon: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default styleSheet as any;

export const BACKGROUND_COLOR = {
  [BUTTON_TYPES.STANDARD]: {
    ...COLORS.GREY_MEDIUM,
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.GREY_DARKER.HOVER,
  },
  [BUTTON_TYPES.ACCENTUATED]: {
    ...COLORS.PRIMARY,
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.PRIMARY.HOVER,
  },
  [BUTTON_TYPES.DESTRUCTIVE]: {
    ...COLORS.ERROR,
    DEFAULT: COLORS.ERROR.DARK,
    HOVER: COLORS.ERROR.DEFAULT,
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.ERROR.DEFAULT,
  },
  [BUTTON_TYPES.VALIDATION]: {
    DISABLED: COLORS.SUCCESS.DISABLED,
    DEFAULT: COLORS.SUCCESS.DARK,
    HOVER: COLORS.SUCCESS.DEFAULT,
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.SUCCESS.DEFAULT,
  },
  [BUTTON_TYPES.WARNING]: {
    DISABLED: COLORS.WARNING.DISABLED,
    DEFAULT: COLORS.WARNING.DEFAULT,
    HOVER: COLORS.WARNING.HOVER,
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.WARNING.HOVER,
  },
  [BUTTON_TYPES.STANDARD_LIGHT]: {
    ...COLORS.WHITE,
    DISABLED: 'transparent',
    GHOST: 'transparent',
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
};

export const COLOR = {
  [BUTTON_TYPES.STANDARD]: {
    DEFAULT: COLORS.TEXT.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.TEXT.DEFAULT,
    GHOST: COLORS.TEXT.DEFAULT,
    GHOST_BORDER: COLORS.GREY_DARKER.DEFAULT,
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
  [BUTTON_TYPES.ACCENTUATED]: {
    DEFAULT: COLORS.WHITE.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.WHITE.DEFAULT,
    GHOST: COLORS.TEXT.ACCENTUATED,
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
  [BUTTON_TYPES.DESTRUCTIVE]: {
    DEFAULT: COLORS.WHITE.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.WHITE.DEFAULT,
    GHOST: COLORS.ALERT.ERROR,
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
  [BUTTON_TYPES.VALIDATION]: {
    DEFAULT: COLORS.WHITE.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.WHITE.DEFAULT,
    GHOST: COLORS.SUCCESS.DARKER,
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
  [BUTTON_TYPES.WARNING]: {
    DEFAULT: COLORS.WHITE.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.WHITE.DEFAULT,
    GHOST: COLORS.WARNING.DARK,
    GHOST_HOVER: COLORS.WHITE.DEFAULT,
  },
  [BUTTON_TYPES.STANDARD_LIGHT]: {
    DEFAULT: COLORS.BLACK.DEFAULT,
    DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
    HOVER: COLORS.BLACK.DEFAULT,
    GHOST: COLORS.WHITE.DEFAULT,
    GHOST_HOVER: COLORS.BLACK.DEFAULT,
  },
};

export const colorMapping = (isHover: boolean, isDisabled: boolean, ghost: boolean) => {
  if (isDisabled) {
    return 'DISABLED';
  }
  if (isHover && ghost) {
    return 'GHOST_HOVER';
  }
  if (isHover) {
    return 'HOVER';
  }
  if (ghost) {
    return 'GHOST';
  }

  return 'DEFAULT';
};

export const getColor = (buttonType: UButtonType, isHover: boolean, isDisabled: boolean, ghost: boolean) => {
  const colorVariant = colorMapping(isHover, isDisabled, ghost);
  return COLOR[buttonType][colorVariant];
};

const BORDER_COLOR = {
  [BUTTON_TYPES.STANDARD]: COLORS.GREY_DARKER.DEFAULT,
  [BUTTON_TYPES.ACCENTUATED]: COLORS.PRIMARY.DEFAULT,
  [BUTTON_TYPES.DESTRUCTIVE]: COLORS.ALERT.ERROR,
  [BUTTON_TYPES.VALIDATION]: COLORS.SUCCESS.DARKER,
  [BUTTON_TYPES.WARNING]: COLORS.WARNING.DARK,
  [BUTTON_TYPES.STANDARD_LIGHT]: COLORS.WHITE.DEFAULT,
};

export const getExtraStyle = (
  isBorderVisible: boolean,
  isButtonActive: boolean,
  type: UButtonType,
  rightIcon?: string,
  leftIcon?: string,
  loading?: boolean,
) => {
  const cursor = isButtonActive ? 'pointer' : 'default';

  if (loading) {
    return {
      cursor,
      paddingLeft: 10,
      paddingRight: 10,
    };
  }

  const borderStyle = isBorderVisible
    ? {
        ...styleSheet.ghost,
        borderColor: BORDER_COLOR[type],
      }
    : {};

  const paddingLeft = leftIcon ? 8 : 12;
  const paddingRight = rightIcon ? 8 : 12;

  return {
    ...borderStyle,
    cursor,
    paddingLeft,
    paddingRight,
  };
};
