import { createStyleSheet } from 'Components/foundation/stylesheets';

import { ELEVATIONS, TYPOGRAPHY, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px',
    paddingBottom: '24px',
    borderRadius: '8px',
    boxShadow: ELEVATIONS.LIGHT_LOW,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  title: {
    ...TYPOGRAPHY.HERO_S_SEMIBOLD,
    marginTop: '50px',
    marginBottom: '8px',
  },
  body: {
    ...TYPOGRAPHY.BODY3,
    maxWidth: '560px',
  },
  buttonWrapper: {
    marginTop: '31px',
    display: 'flex',
  },
  middleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

export default styleSheet;
