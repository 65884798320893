import m from 'mithril';
import Promise from 'bluebird';
import Enum from 'models/Enum';
import User from 'services/userService';
import Role from 'models/Role';
import Player from 'models/Player';
import Thematic from 'models/Thematic';
import AuthenticationSettings from 'models/AuthenticationSettings';
import SegmentationDimension from 'models/SegmentationDimension';
import SegmentationItem from 'models/SegmentationItem';
import SegmentationGroupItem from 'models/SegmentationGroupItem';
import Configuration from 'models/Configuration';
import PolyglotLabel from 'models/PolyglotLabel';

import AudienceTargetMode from 'models/masterData/AudienceTargetMode';
import DailySerieType from 'models/masterData/DailySerieType';
import WeekDay from 'models/masterData/WeekDay';
import ActivityType from 'models/masterData/ActivityType';
import ContributionType from 'models/masterData/ContributionType';
import GameItemType from 'models/masterData/GameItemType';
import Gameplay from 'models/masterData/Gameplay';
import ContentStatus from 'models/masterData/ContentStatus';
import KnowledgeStatus from 'models/masterData/KnowledgeStatus';
import VideoType from 'models/masterData/VideoType';
import SegmentationType from 'models/masterData/SegmentationType';
import AuthenticationType from 'models/masterData/AuthenticationType';
import RestrictionType from 'models/masterData/RestrictionType';
import AvailableSegmentationGroup from 'models/masterData/AvailableSegmentationGroup';
import Currency from 'models/masterData/Currency';

var App = {};

App.init = function init(router) {
  App.router = router;
  App.roles = m.prop([]);
  App.contributors = m.prop([]);

  App.thematics = m.prop([]);
  App.languages = m.prop([]);
  App.userLanguages = m.prop([]);

  // Activities
  App.activityTypes = m.prop([]);
  App.audienceTargetModes = m.prop([]);
  App.dailySerieTypes = m.prop([]);
  App.weekDays = m.prop([]);
  App.activities = m.prop([]);
  App.contributionTypes = m.prop([]);
  App.gameItemTypes = m.prop([]);
  App.gameplays = m.prop([]);
  App.contentStatus = m.prop([]);
  App.knowledgeStatus = m.prop([]);
  App.currentThematics = m.prop([]);
  App.videoTypes = m.prop([]);
  App.AuthenticationTypes = m.prop([]);
  App.RestrictionTypes = m.prop([]);
  App.AuthenticationSettings = m.prop([]);
  App.segmentationTypes = m.prop([]);
  App.segmentationDimensions = m.prop([]);
  App.segmentationItems = m.prop([]);
  App.userSegmentationItems = m.prop([]);
  App.currencies = m.prop([]);
  App.configurations = m.prop([]);
  App.AvailableSegmentationGroups = m.prop([]);
  App.defaultLanguage = m.prop(null);
  App.currentKnowledgeStatus = m.prop(null);
  App.signupOpenTextLabel = m.prop();

  App.currentThematic = function currentThematic() {
    var thematics = App.currentThematics();
    var last = thematics.length - 1;

    return thematics[last];
  };
};

App.setAppProps = function setAppProps(record) {
  for (var key in record) {
    if (record.hasOwnProperty(key))
      App[key](record[key]);

  }
};

App.fetchThematics = async function fetchThematics() {
  const thematicFilter = {
    filter: {
      where: {
        or: [{ archived: false }, { archived: null }],
      },
    },
  };

  const thematics = await Thematic.find(thematicFilter);

  thematics.items.sort(
    (a, b) => a.name().toUpperCase() > b.name().toUpperCase()
      ? 1
      : -1
  );

  return thematics;

};

App.refreshThematics = async function refreshThematics() {
  App.setAppProps({
    thematics: await App.fetchThematics(),
  });
};

App.start = function start() {
  return Promise
    .props({
      roles                 : Role.find(),
      contributors          : Player.findContributors(),
      thematics             : App.fetchThematics(),
      activityTypes         : ActivityType.find(),
      audienceTargetModes   : AudienceTargetMode.find(),
      dailySerieTypes       : DailySerieType.find(),
      weekDays              : WeekDay.find(),
      contributionTypes     : ContributionType.find(),
      gameItemTypes         : GameItemType.find(),
      gameplays             : Gameplay.find(),
      contentStatus         : ContentStatus.find(),
      knowledgeStatus       : KnowledgeStatus.find(),
      videoTypes            : VideoType.find(),
      AuthenticationTypes   : AuthenticationType.find(),
      RestrictionTypes      : RestrictionType.find(),
      currencies            : Currency.find(),
      segmentationDimensions: SegmentationDimension.find({
        filter: {
          where: {
            typeId: { neq: Enum.dimensionTypes.HIDDEN_TAG },
          },
        },
      }),
      segmentationTypes: SegmentationType.find({
        filter: {
          where: {
            id: { neq: Enum.dimensionTypes.HIDDEN_TAG },
          },
        },
      }),
      configurations: Configuration.find()
        .tap(function getPolyglotLabel(configs) {
          var config = configs.find({
            id: Enum.configurations.SIGNUP_OPEN_INPUT_LABEL,
          }).at(0);

          return PolyglotLabel
            .getTranslatedLabel(config.data())
            .then(App.signupOpenTextLabel);
        }),
      languages: SegmentationDimension.find({
        filter: {
          where: {
            typeId: 1,
          },
          include: {
            relation: 'groups',
            scope   : {
              include: ['dimension', 'members'],
            },
          },
        },
      }).then(function setLanguages(dimensions) {
        return dimensions.at(0).groups();
      }),
      userLanguages: User.me()
        .then(function returnUserLanguages(currentUser) {
          return currentUser.segmentation().find({
            dimensionId: Enum.dimensionTypes.LANGUAGE,
          });
        }),
      defaultLanguage: SegmentationDimension.find({
        filter: {
          where: {
            typeId: 1,
          },
          include: {
            relation: 'groups',
            scope   : {
              where: {
                isDefault: true,
              },
            },
          },
        },
      }).then(function setDefault(dims) {
        var defaultLanguage = dims.at(0).groups().at(0);

        // console.log('[app]', 'Default language found', dims.at(0).groups(), defaultLanguage);
        return defaultLanguage;
      }),
      segmentationItems: SegmentationItem.getFull()
        .tap(User.refresh)
        .tap(function loadUserSegmentation(segmentationItems) {
          var playerSegmentation = User.meSync().segmentation();
          var filteredSegmentation = SegmentationGroupItem
            .filterWithUserSegmentation(segmentationItems, playerSegmentation);

          App.userSegmentationItems(filteredSegmentation);
        }),

      AuthenticationSettings: AuthenticationSettings.find({
        filter: {
          include: {
            relation: 'segmentation',
            scope   : {
              include: ['dimension', 'members'],
            },
          },
        },
      }),
      AvailableSegmentationGroups: AvailableSegmentationGroup.find(),
    })
    .then(App.setAppProps);
};

export default App;
