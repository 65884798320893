import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  content: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  bottomSpace: {
    paddingBottom: 200,
  },
});
