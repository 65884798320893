import React, { ChangeEvent, useCallback, useState } from 'react';

import { TYPOGRAPHY } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import { TextAreaErrorMessage } from 'Components/structural/STextArea/components/TextAreaErrorMessage';
import { TextAreaLabelAndCount } from 'Components/structural/STextArea/components/TextAreaLabelAndCount';
import { LabelSize } from 'Components/unit/ULabel/ULabel';
import sTextAreaStyles from 'Components/structural/STextArea/STextArea.style';

import { styles } from './ComparisonTextArea.style';
import { ComparisonSegment } from '../types';
import { normalizeTextInput } from '../utils/normalizeTextInput.utils';
import { ComparisonSegments } from './ComparisonSegments';

export type ComparisonTextAreaProps = {
  height: string;
  label: string;
  value: string;
  isLabelRequired?: boolean;
  labelIcon?: IconName;
  labelIconColor?: string;
  labelIconSize?: number;
  labelSize?: LabelSize;
  radiusWrapper?: number;
  textAreaTypo?: keyof typeof TYPOGRAPHY;
  placeholder?: string;
  errorMessage?: string;
  maxLength?: number;
  canResize: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  textAreaStyle: React.CSSProperties;
  variant: 'valid' | 'invalid';
  comparisonSegments: ComparisonSegment[];
};

export const ComparisonTextArea = ({
  height = '100',
  label = '',
  labelSize = 'S',
  labelIcon = undefined,
  radiusWrapper = 4,
  value = '',
  textAreaTypo = 'BODY3',
  placeholder = '',
  isLabelRequired = false,
  errorMessage = '',
  maxLength,
  canResize = true,
  onChange = () => {},
  disabled = false,
  style,
  labelIconColor,
  textAreaStyle,
  comparisonSegments,
  variant,
  labelIconSize,
}: ComparisonTextAreaProps) => {
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);

  const handleTextAreaFocus = () => setIsTextAreaFocused(true);

  const handleTextAreaBlur = useCallback(() => {
    const filteredText = normalizeTextInput(value);
    onChange(filteredText);
    setIsTextAreaFocused(false);
  }, [onChange, value]);

  const handleTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const text = event.target.value.slice(0, maxLength || undefined);
      if (!onChange) return;
      onChange(text);
    },
    [onChange],
  );

  const focusedStyle = isTextAreaFocused ? sTextAreaStyles.focused : {};
  const disabledStyle = disabled ? sTextAreaStyles.disabled : {};
  const radiusStyle = { borderRadius: radiusWrapper };

  const resizeStyle = !canResize ? sTextAreaStyles.noResize : {};
  const disabledTextAreaStyle = disabled ? sTextAreaStyles.textDisabled : {};
  const minHeightStyle: React.CSSProperties = {
    minHeight: `${height}px`,
  };

  const sharedTextAreaStyle = {
    ...sTextAreaStyles.textAreaField,
    ...radiusStyle,
    ...minHeightStyle,
    ...resizeStyle,
    ...disabledTextAreaStyle,
    ...TYPOGRAPHY[textAreaTypo],
    ...textAreaStyle,
  };

  return (
    <div style={{ ...sTextAreaStyles.wrapper, ...style }}>
      <TextAreaLabelAndCount
        maxLength={maxLength}
        value={value}
        label={label}
        labelSize={labelSize}
        labelIcon={labelIcon}
        labelIconColor={labelIconColor}
        isLabelRequired={isLabelRequired}
        labelIconSize={labelIconSize}
      />
      <div
        style={{
          ...sTextAreaStyles.textAreaWrapper,
          ...styles.textAreaWrapperForOverlay,
          ...focusedStyle,
          ...disabledStyle,
          ...radiusStyle,
        }}
      >
        <textarea
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          style={sharedTextAreaStyle}
          onChange={handleTextAreaChange}
          onClick={handleTextAreaFocus}
          onBlur={handleTextAreaBlur}
          disabled={disabled}
          className="structural-inputs"
        />
        {!isTextAreaFocused && (
          <div
            placeholder={placeholder}
            style={{ ...sharedTextAreaStyle, ...styles.textAreaWithHighlightOverlay }}
            className="structural-inputs"
          >
            <ComparisonSegments text={value} variant={variant} comparisonSegments={comparisonSegments} />
          </div>
        )}
      </div>
      <TextAreaErrorMessage {...{ errorMessage }} />
    </div>
  );
};
