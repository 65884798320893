// @flow

/* eslint-disable key-spacing */

export default {
  wrapper: {
    height: '100%',
  },
  pageLoader: {
    zIndex: 9,
  },
};
