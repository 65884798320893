import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  loginWrapper: {
    width: 260,
  },
  instanceName: {
    ...TYPOGRAPHY.BODY3,
    opacity: 0.2,
    letterSpacing: 4,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  welcome: {
    ...TYPOGRAPHY.TITAN_L,
    marginTop: 2,
  },
  instruction: {
    ...TYPOGRAPHY.H2,
    marginTop: 16,
  },
  inputWrapper: {
    width: 250,
  },
  emailInput: {
    marginTop: 32,
  },
  passwordInput: {
    marginTop: 16,
    marginBottom: 16,
  },
  resetPassword: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    background: 'inherit',
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 56,
    alignItems: 'center',
  },
  button: {
    width: 60,
    marginRight: 24,
    height: 'fit-content',
  },
  errorMsg: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ALERT.ERROR,
  },
});

export default styleSheet;
