import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    height: 4,
    width: '100%',
    backgroundColor: COLORS.PRIMARY.MEDIUM,
  },
});
