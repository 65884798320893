import { GAME_TYPE, LanguageCode, NoInteraction } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeepPartial } from 'ts-essentials';

import { VIDEO_PROVIDERS } from 'Components/modal/MVideoUpload/utils';
import SGameplayHeader from 'Components/structural/SGameplayHeader/SGameplayHeader';
import STextArea from 'Components/structural/STextArea/STextArea';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker, { ShowableVideo, isShowableVideo } from 'Components/unit/UMediaPicker/UMediaPicker';
import UToggle from 'Components/unit/UToggle/UToggle';

import Enum from 'Models/Enum';

import { BaseContentFormValues } from '../ContentEdition/ContentEdition';
import { NO_INTERACTION_TEXT_CHAR_LIMIT, NO_INTERACTION_TITlE_CHAR_LIMIT } from './gameplay.constants';
import sharedStyles, { CONTENT_TEXT_AREA_WRAPPER_RADIUS } from './gameplay.style';

type NoInteractionFormProps = {
  language: LanguageCode;
  isGameplayAlreadyChosen: boolean;
  editGameplay: () => void;
};

export const NoInteractionForm = ({ language, isGameplayAlreadyChosen, editGameplay }: NoInteractionFormProps) => {
  const { watch, setValue } = useFormContext<BaseContentFormValues & { game: DeepPartial<NoInteraction> }>();

  const [showExtraMedia, setShowExtraMedia] = useState(false);

  const onToggleShowExtraMedia = useCallback(() => {
    if (showExtraMedia) {
      setValue('game.secondMedia', {});
    }
    setShowExtraMedia((prev) => !prev);
  }, [showExtraMedia, setShowExtraMedia]);

  const onNewFirstMedia = useCallback(
    (newMedia) => {
      if (newMedia.type === 'video') {
        return setValue('game.firstMedia.video', newMedia.media);
      }
      return setValue('game.firstMedia.image', newMedia.media);
    },
    [setValue],
  );

  const onNewSecondMedia = useCallback(
    (newImage) => {
      return setValue('game.secondMedia.image', newImage);
    },
    [setValue],
  );
  const onTitleChange = useCallback(
    (text: string) => {
      setValue(`game.title.${language}`, text);
    },
    [setValue],
  );
  const onTextChange = useCallback(
    (text: string) => {
      setValue(`game.text.${language}`, text);
    },
    [setValue],
  );

  return (
    <div style={sharedStyles.wrapper}>
      <div style={sharedStyles.container}>
        <SGameplayHeader
          iconType={GAME_TYPE.NO_INTERACTION}
          title={t('gameplays:no_interaction_content.label')}
          description={t('gameplays:no_interaction_content.description')}
          onEditClick={editGameplay}
          isEditDisabled={isGameplayAlreadyChosen}
          tooltip={{
            tooltipContent: t('gameplays:change_gameplay.cannot_edit'),
            tooltipPosition: 'top',
          }}
        />
        <div style={sharedStyles.section}>
          <ULabel style={sharedStyles.label}>{t('gameplays:content')}</ULabel>
          <div style={{ ...sharedStyles.imageTextSection, ...sharedStyles.sectionSeparation }}>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('game.firstMedia.image.url') || ''}
              video={
                isShowableVideo(watch('game.firstMedia.video'))
                  ? (watch('game.firstMedia.video') as ShowableVideo)
                  : undefined
              }
              media={{
                mediaType: 'imageOrVideo',
                onChange: onNewFirstMedia,
                providers: [VIDEO_PROVIDERS[Enum.videoType.Youtube], VIDEO_PROVIDERS[Enum.videoType.ApiVideo]],
              }}
              onClearMedia={() => {
                setValue('game.firstMedia', {});
              }}
            />
            <STextArea
              canResize={false}
              placeholder={t('gameplays:no_interaction_content.title_placeholder')}
              height={'80'}
              onChange={onTitleChange}
              style={sharedStyles.imageTextArea}
              value={watch(`game.title.${language}`) || ''}
              radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
              maxLength={NO_INTERACTION_TITlE_CHAR_LIMIT}
              textAreaTypo="BODY2"
            />
          </div>
        </div>
        <div style={sharedStyles.section}>
          <STextArea
            canResize={false}
            placeholder={t('gameplays:no_interaction_content.text_placeholder2')}
            height="350"
            onChange={onTextChange}
            value={watch(`game.text.${language}`) || ''}
            radiusWrapper={CONTENT_TEXT_AREA_WRAPPER_RADIUS}
            maxLength={NO_INTERACTION_TEXT_CHAR_LIMIT}
            textAreaTypo="BODY2"
          />
        </div>
        <div style={sharedStyles.sectionSeparation}>
          <UToggle
            toggled={showExtraMedia}
            onChange={onToggleShowExtraMedia}
            outsideLabel={
              showExtraMedia
                ? t('gameplays:no_interaction_content.remove_extra_media')
                : t('gameplays:no_interaction_content.add_extra_media')
            }
            outsideLabelSize="XS"
          />
        </div>
        {showExtraMedia && (
          <div style={sharedStyles.sectionSeparation}>
            <div style={{ ...sharedStyles.imageTextSection }}>
              <UMediaPicker
                dropArea
                withContainer
                size="M"
                image={watch('game.secondMedia.image.url') || ''}
                media={{
                  mediaType: 'image',
                  onChange: onNewSecondMedia,
                }}
                onClearMedia={() => {
                  setValue('game.secondMedia', {});
                }}
                textLink={t('gameplays:no_interaction_content.import_media')}
                textLinkTypography={'BODY3_SEMIBOLD'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
