import React, { useMemo } from 'react';
import type { CSSProperties, FocusEventHandler } from 'react';

import { TYPOGRAPHY } from 'Components/foundation';

import useInputStatus from 'Hooks/useInputStatus/useInputStatus';
import { useConditionalDebouncedValue } from 'Hooks/useDebounced/useDebounced';

import UTextArea, { UTextAreaProps } from 'Components/unit/UTextArea/UTextArea';
import UInput, { UInputProps } from 'Components/unit/UInput/UInput';

import SInputContainer from 'Components/structural/SInputContainer/SInputContainer';

import styles from './SSubtleInput.style';

const inputComponentMap = {
  input: (props: UInputProps) => <UInput {...props} />,
  textarea: (props: UTextAreaProps) => <UTextArea autoresize {...props} />,
};

type FocusHandler = FocusEventHandler<HTMLInputElement> & FocusEventHandler<HTMLTextAreaElement>;

export type SSubtleInputProps = {
  value: string;
  onChange?: (newValue: string) => void;
  onFocus?: FocusHandler;
  onBlur?: FocusHandler;

  disabled?: boolean;
  autofocus?: boolean;
  bold?: boolean;
  compact?: boolean;
  hideCounter?: boolean;
  debounced?: boolean;
  debounceTime?: number;
  inputType?: 'input' | 'textarea';
  errorMessage?: string;
  allowScroll?: boolean;
  forceTextareaScroll?: boolean;

  /** No maxLength by default */
  maxLength?: number;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: CSSProperties;
  autoResizeInput?: boolean;

  /** No maxWidth by default */
  maxWidth?: number | string;
  minWidth?: number;

  maxHeight?: number | string;
  minHeight?: number | string;

  /** Defaults to H2 */
  typo?: keyof typeof TYPOGRAPHY;
};

/**
 * Subtle input is an input that shows itself as a text until hovered or focused
 *
 * Props:
 * - value: if present the SSubtleInput will be controlled, otherwise SSubtleInput will use an internal value
 * - placeholder: text shown if input is empty
 * - bold: Define the font weight of the content input as bold
 * - compact: Remove padding around input
 * - disabled: disable focus and edition
 * - autofocus: automatically focus this input
 * - hideCounter: boolean which control the visibility of the counter
 * - debounced: control wether the input use the debounce feature
 * - debounceTime: how much time onChange should be delayed if debounce is enabled
 * - inputType: default to 'textarea', if 'textarea' the input will wrap its text
 * - errorMessage: An error message to display below the input
 * - typo: typography for the text
 * - onChange: called when the user change the value
 * - onFocus: called when the user click on the input
 * - onBlur: called when the user click outside the input after focused it
 * - maxLength: limit maximum number of characters in value
 * - maxWidth: limit width of input
 * - maxHeight: limit height of input
 * - minWidth: define a minimal width for the input
 * - minHeight: define a minimal height for the input
 * - autoResizeInput: allow resize automatic for 'input' type
 * - forceTextareaScroll: allow to enable scroll every time on 'textarea' type
 * - style: styling override for the input
 */
export const SSubtleInput = ({
  value,
  placeholder = '',
  bold = false,
  compact = false,
  disabled = false,
  autofocus = false,
  hideCounter = false,
  inputType = 'textarea',
  errorMessage = '',
  typo = 'BODY2',
  onChange = undefined,
  onFocus = undefined,
  onBlur = undefined,
  minWidth = undefined,
  minHeight = undefined,
  maxLength = undefined,
  maxWidth = undefined,
  maxHeight = undefined,
  debounced = false,
  debounceTime = 500,
  autoResizeInput = false,
  forceTextareaScroll = false,
  allowScroll = true,
  style = {},
  styleInput = {},
}: SSubtleInputProps) => {
  const { handleFocus, handleHover, stateStatus } = useInputStatus({
    onFocus,
    onBlur,
    autofocus,
    disabled,
    isInError: errorMessage.length > 0,
  });

  const { handleChange, realTimeValue } = useConditionalDebouncedValue({
    value,
    debounced,
    time: debounceTime,
    onChange,
  });

  const InputComponent = useMemo(() => inputComponentMap[inputType], [inputType]);

  const inputStyle = useMemo(
    (): CSSProperties => ({
      ...styleInput,
      ...styles.input,
      width: autoResizeInput && inputType === 'input' ? 'auto' : '100%',
      boxSizing: autoResizeInput && inputType ? 'content-box' : undefined,
      ...TYPOGRAPHY[typo],
      fontWeight: bold ? 'bold' : 'normal',
      maxHeight,
    }),
    [inputType, bold, autoResizeInput, typo, maxHeight],
  );

  return (
    <SInputContainer
      currentCount={realTimeValue ? realTimeValue.length : 0}
      hideCounter={hideCounter}
      maxLength={maxLength}
      valueChangeFeedbackStatus={errorMessage ? 'error' : 'none'}
      valueChangeFeedbackMessage={errorMessage || ''}
      inputStateStatus={stateStatus}
      ghost
      childrenStyle={{
        width: inputStyle.width,
        // 1 less pixel on the padding to account for border. Component should have a base height of 32px
        padding: compact ? '1px 5px' : '5px 9px',
      }}
      style={{
        width: maxWidth,
        ...style,
      }}
      onFocus={handleFocus(true)}
      onBlur={handleFocus(false)}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
    >
      <InputComponent
        className="structural-inputs"
        style={{
          ...inputStyle,
          overflowY: allowScroll ? 'auto' : 'hidden',
          minHeight: inputType === 'textarea' ? minHeight : undefined,
        }}
        {...(inputType === 'input' ? { autoResize: autoResizeInput, minWidth } : {})}
        {...(inputType === 'textarea' ? { forceScroll: forceTextareaScroll } : {})}
        value={realTimeValue}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder}
        wrap="soft"
        rows={1}
      />
    </SInputContainer>
  );
};
export default SSubtleInput;
