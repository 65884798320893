import Enum from 'Models/Enum';

import { FilterState } from 'Libs/filter/types';
import { getErrorMessage } from 'Libs/redux/utils';
import { mergeDeep } from 'Libs/mergeDeep';
import type { DeepReadonly } from 'Libs/utils/types/object';

import type { CategoryType } from 'ModelsReact/Category/Category';
import type { DocumentOpenings, DocumentType } from 'ModelsReact/Document/Document';
import { handle } from 'redux-pack';
import type { Action } from 'Libs/redux/types';
import type { AlertType } from './models/Alert';

export type DocumentListState = DeepReadonly<{
  // Loader
  loading: boolean;
  loadingMore: boolean;
  loadingUpload: boolean;
  loadingNotification: boolean;

  // Filter
  selectedSegmentationIds: ReadonlyArray<number>;
  selectedTextValues: ReadonlyArray<string>;
  selectedCategoryId: number;
  selectedDocumentStatusId: number;
  isFiltering: boolean;
  counter: number;

  // Categories
  categories: ReadonlyArray<CategoryType>;

  // Documents
  documents: ReadonlyArray<DocumentType>;
  openings: ReadonlyArray<DocumentOpenings>;

  // Modals
  documentModalVisible: boolean;
  documentModalError: string;
  documentModal?: DocumentType;
  documentFile?: Blob;
  documentFileError: boolean;
  notificationModalVisible: boolean;
  notificationModalError: string;

  // Alerts
  alerts: ReadonlyArray<AlertType>;

  // Tricks to force SFilterBox to reload
  updated: number;
}>;

export const defaultState: DocumentListState = {
  loading: false,
  loadingMore: false,
  loadingUpload: false,
  loadingNotification: false,
  selectedSegmentationIds: [],
  selectedTextValues: [],
  selectedCategoryId: 0,
  selectedDocumentStatusId: 0,
  isFiltering: false,
  counter: 0,
  openings: [],
  categories: [],
  documents: [],
  documentModalVisible: false,
  documentModalError: '',
  documentModal: undefined,
  documentFile: undefined,
  documentFileError: false,
  notificationModalVisible: false,
  notificationModalError: '',
  alerts: [],
  updated: 0,
};

const ERROR_SCOPE = {
  DOCUMENT_LIST_FETCH: 'fetchError',
  DOCUMENT_LIST_ARCHIVE: 'archiveError',
  DOCUMENT_LIST_GET_VIEWS: 'getViewsError',
} as const;

const SUCCESS_SCOPE = {
  DOCUMENT_LIST_ARCHIVE: 'archiveSuccess',
} as const;

export const isDocumentMatchFilters = (
  document: DocumentType,
  selectedSegmentationIds: ReadonlyArray<number>,
  selectedTextValues: ReadonlyArray<string>,
  selectedCategoryId: number,
  selectedDocumentStatusId: number,
) => {
  const documentSeg = document.segmentation.map((seg) => seg.id);

  // Remove document from list if filter not matching
  if (
    (!document.available && selectedDocumentStatusId === Enum.DocumentStatus.AVAILABLE) ||
    (document.available && selectedDocumentStatusId === Enum.DocumentStatus.UNAVAILABLE)
  ) {
    // Filter by document status
    return false;
  }

  if (selectedCategoryId !== 0 && document.categoryId !== selectedCategoryId) {
    // Filter by category
    return false;
  }

  if (selectedTextValues.length && !selectedTextValues.some((search) => document.title.includes(search))) {
    // Filter by text
    return false;
  }

  if (selectedSegmentationIds.length && !selectedSegmentationIds.some((segId) => documentSeg.includes(segId))) {
    // Filter by segmentation
    return false;
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: DocumentListState = defaultState, action: Action): DocumentListState => {
  switch (action.type) {
    case 'DOCUMENT_LIST_FETCH':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, action.meta.more ? { loadingMore: true } : { loading: true }),
        success: (prevState) => {
          const { documents } = prevState;

          const currentDocuments = action.meta.more
            ? documents.concat(action.payload.documents)
            : action.payload.documents;

          return mergeDeep(prevState, {
            counter: action.payload.count,
            documents: currentDocuments,
          });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {},
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loading: false, loadingMore: false }),
      });

    case 'DOCUMENT_LIST_GET_VIEWS':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, action.meta.more ? { loadingMore: true } : { loading: true }),
        success: (prevState) => {
          const { documents } = prevState;
          const documentWithViews: { documentId: number; count: number }[] = action.payload.documents;

          return mergeDeep(prevState, {
            documents,
            openings: documentWithViews,
          });
        },
        failure: (prevState) => {
          const alerts = prevState.alerts.concat([
            {
              scope: ERROR_SCOPE[action.type],
              params: {},
            },
          ]);

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loading: false, loadingMore: false }),
      });

    case 'DOCUMENT_LIST_UPDATE_FILTERS': {
      let { selectedSegmentationIds, selectedTextValues, selectedCategoryId, selectedDocumentStatusId } = state;

      action.selectedFilters.forEach((filter: FilterState) => {
        if (filter.category === 'segment') {
          selectedSegmentationIds = filter.ids;
        }
        if (filter.category === 'text') {
          selectedTextValues = filter.values;
        }
        if (filter.category === 'category') {
          selectedCategoryId = filter.ids.length ? filter.ids[0] : 0;
        }
        if (filter.category === 'documentStatus') {
          selectedDocumentStatusId = filter.ids.length ? filter.ids[0] : 0;
        }
      });

      const isFiltering =
        selectedSegmentationIds.length > 0 ||
        selectedTextValues.length > 0 ||
        selectedCategoryId > 0 ||
        selectedDocumentStatusId !== 0;

      return mergeDeep(state, {
        selectedSegmentationIds,
        selectedTextValues,
        selectedCategoryId,
        selectedDocumentStatusId,
        isFiltering,
      });
    }

    case 'DOCUMENT_LIST_ARCHIVE': {
      return handle(state, action, {
        start: (prevState) =>
          mergeDeep(prevState, {
            loading: false,
            alerts: prevState.alerts
              .filter(({ scope }) => scope !== ERROR_SCOPE[action.type])
              .filter(({ scope }) => scope !== SUCCESS_SCOPE[action.type]),
          }),
        success: (prevState) => {
          const { payload } = action;

          let { documents, counter } = prevState;

          // Remove documents from the list
          documents = documents.filter((document) => document.id !== payload.id);
          counter -= 1;

          const alerts = SUCCESS_SCOPE[action.type]
            ? prevState.alerts.concat([
                {
                  scope: SUCCESS_SCOPE[action.type],
                  params: { archived: payload.archived },
                },
              ])
            : prevState.alerts;

          return mergeDeep(prevState, {
            documentModalVisible: false,
            documentModalError: '',
            documentModal: undefined,
            documentFile: undefined,
            alerts,
            documents,
            counter,
          });
        },
        failure: (prevState) => {
          const { meta } = action;
          const alerts = ERROR_SCOPE[action.type]
            ? prevState.alerts.concat([
                {
                  scope: ERROR_SCOPE[action.type],
                  params: { archived: meta.archived },
                },
              ])
            : prevState.alerts;

          return mergeDeep(prevState, { alerts });
        },
        finish: (prevState) => mergeDeep(prevState, { loading: false, loadingMore: false, loadingUpload: false }),
      });
    }

    case 'DOCUMENT_ADD': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { loadingUpload: true }),
        success: (prevState) => {
          const { payload } = action;

          const {
            updated,
            counter,
            documents,
            selectedSegmentationIds,
            selectedTextValues,
            selectedCategoryId,
            selectedDocumentStatusId,
          } = prevState;

          const matched = isDocumentMatchFilters(
            payload,
            selectedSegmentationIds,
            selectedTextValues,
            selectedCategoryId,
            selectedDocumentStatusId,
          );

          return mergeDeep(prevState, {
            // Force reload SFilterbox categories
            updated: updated + 1,
            loadingUpload: false,
            documentModalVisible: false,
            documentModalError: '',
            documentModal: payload,
            documentFile: undefined,
            notificationModalVisible: payload.available,
            documents: matched ? [payload].concat(documents) : documents,
            counter: matched ? counter + 1 : counter,
          });
        },
        failure: (prevState) =>
          mergeDeep(prevState, {
            loadingUpload: false,
            documentModalError: `An error occured, please try again. reason: ${getErrorMessage(action)}`,
          }),
        finish: (prevState) =>
          mergeDeep(prevState, {
            loading: false,
            loadingMore: false,
            loadingUpload: false,
            loadingNotification: false,
          }),
      });
    }

    case 'DOCUMENT_UPDATE': {
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { loadingUpload: true }),
        success: (prevState) => {
          const { payload } = action;

          const {
            updated,
            counter,
            documents,
            selectedSegmentationIds,
            selectedTextValues,
            selectedCategoryId,
            selectedDocumentStatusId,
          } = prevState;

          const oldDocuments = documents;
          const matched = isDocumentMatchFilters(
            payload,
            selectedSegmentationIds,
            selectedTextValues,
            selectedCategoryId,
            selectedDocumentStatusId,
          );
          const updatedDocument = matched ? payload : null;

          const documentsToDisplay = documents.reduce((docs, document) => {
            const documentToStore = document.id === payload.id ? updatedDocument : document;

            return documentToStore ? [...docs, documentToStore] : docs;
          }, [] as Array<DocumentType>);

          // Display the notificiation modal only if previously unavailable
          let isNotificationModalVisible = false;
          const oldDocument = oldDocuments.find((document) => document.id === payload.id);

          if (oldDocument && !oldDocument.available && payload.available) {
            isNotificationModalVisible = true;
          }

          return mergeDeep(prevState, {
            // Force reload SFilterbox categories
            updated: updated + 1,
            loadingUpload: false,
            documentModalVisible: false,
            documentModalError: '',
            documentModal: payload,
            documentFile: undefined,
            notificationModalVisible: isNotificationModalVisible,
            documents: documentsToDisplay,
            counter: !matched ? counter - 1 : counter,
          });
        },
        failure: (prevState) =>
          mergeDeep(prevState, {
            loadingUpload: false,
            documentModalError: `An error occured, please try again. reason: ${getErrorMessage(action)}`,
          }),
        finish: (prevState) =>
          mergeDeep(prevState, {
            loading: false,
            loadingMore: false,
            loadingUpload: false,
            loadingNotification: false,
          }),
      });
    }

    case 'DOCUMENT_SET_ALERT': {
      const newAlert = { scope: action.scope, params: action.params };

      // replace or append if element does not exist yet
      const alerts = state.alerts.find(({ scope }) => scope === newAlert.scope)
        ? state.alerts.map((err) => (err.scope === action.scope ? newAlert : err))
        : state.alerts.concat([newAlert]);

      return mergeDeep(state, { alerts });
    }

    case 'DOCUMENT_REMOVE_ALERT': {
      const alerts = state.alerts.filter(({ scope }) => scope !== action.scope);

      return mergeDeep(state, { alerts });
    }

    case 'DOCUMENT_MODAL_SET_VISIBILITY': {
      const { visibility, document, file } = action;

      return mergeDeep(state, {
        documentModalVisible: visibility,
        documentModalError: '',
        documentModal: document,
        documentFile: file,
        documentFileError: false,
      });
    }

    case 'DOCUMENT_MODAL_SET_TITLE': {
      const { title } = action;

      return mergeDeep(state, { documentModal: { title } });
    }

    case 'DOCUMENT_MODAL_SET_AVAILABILITY': {
      const { available } = action;

      return mergeDeep(state, { documentModal: { available } });
    }

    case 'DOCUMENT_MODAL_SET_CATEGORY': {
      const { categoryId } = action;

      return mergeDeep(state, { documentModal: { categoryId } });
    }

    case 'DOCUMENT_MODAL_SET_SEGMENTATION': {
      const { segmentation } = action;

      return mergeDeep(state, { documentModal: { segmentation } });
    }

    case 'DOCUMENT_MODAL_SET_ERROR': {
      const { error } = action;

      return mergeDeep(state, {
        documentFileError: true,
        documentModalError: error,
      });
    }

    case 'CATEGORY_LOAD': {
      const { categories } = action;

      return mergeDeep(state, { categories });
    }

    case 'CATEGORY_ADD':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { documentModalError: '' }),
        success: (prevState) => {
          const { categories } = prevState;
          const category = action.payload;

          return mergeDeep(prevState, {
            categories: categories.concat(category),
            documentModal: {
              categoryId: category.id,
              category,
            },
          });
        },
        failure: (prevState) =>
          mergeDeep(prevState, {
            documentModalError: 'Unable to create the category.',
          }),
      });

    case 'NOTIFICATION_MODAL_SET_VISIBILITY': {
      const { visibility } = action;

      return mergeDeep(state, {
        notificationModalVisible: visibility,
        notificationModalError: '',
      });
    }

    case 'DOCUMENT_SEND_NOTIFICATION':
      return handle(state, action, {
        start: (prevState) => mergeDeep(prevState, { loadingNotification: true }),
        success: (prevState) =>
          mergeDeep(prevState, {
            notificationModalVisible: false,
            notificationModalError: '',
          }),
        failure: (prevState) =>
          mergeDeep(prevState, {
            loadingNotification: false,
            notificationModalError: `An error occured, please try again. reason: ${getErrorMessage(action)}`,
          }),
        finish: (prevState) =>
          mergeDeep(prevState, {
            loading: false,
            loadingMore: false,
            loadingUpload: false,
            loadingNotification: false,
          }),
      });

    default:
      return state;
  }
};
