import React, { useMemo, useCallback } from 'react';
import { t } from 'i18next';

import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';
import { useTypedDispatch } from 'Libs/redux/utils';
import App from 'Models/App';

import { AudienceBackButton } from '../AudienceBackButton/AudienceBackButton';
import type FilterState from 'Libs/flowTypes';

import { AudienceTable } from '../AudienceTable/AudienceTable';

import { actions, useModuleSelector } from '../../../redux';

import styles from './AudienceSegments.style';
import { GenericFilterCategory, FilterCategoryWithLabel } from 'Libs/filter/types';

type Dimension = {
  id: number;
  color: string;
  typeId: number;
};

type Group = {
  id: number;
  label: string;
  dimension: {
    id: number;
    color: string;
    typeId: number;
  };
}

type SegItem = {
  group: Group;
}

type FilterState = {
  category: GenericFilterCategory | FilterCategoryWithLabel | 'text';
  ids?: number[];
};

const getSegmentations = (): Record<number, Group> => {
  const filteredSegmentation: Array<SegItem> = (App as any).userSegmentationItems().toJSON();

  return filteredSegmentation.reduce((map: Record<number, Group>, segItem) => {
    const group: Group = segItem.group;

    map[group.id] = {
      id: group.id,
      label: group.label,
      dimension: {
        id: group.dimension.id,
        color: group.dimension.color,
        typeId: group.dimension.typeId,
      },
    };

    return map;
  }, {});
};

export const AudienceSegments = () => {
  const appSegmentations = getSegmentations();
  const dispatch = useTypedDispatch();
  const audienceSegmentationIds = useModuleSelector((state) => {
    return state.audience.SEGMENTATION.segmentation.map((seg) => seg.id);
  });

  const filters = useMemo(() => {
    return [
      {
        category: 'segment' as GenericFilterCategory,
        ids: audienceSegmentationIds,
      },
    ];
  }, [audienceSegmentationIds]);

  const handleFilterChange = useCallback(
    (selectedFilters: Array<FilterState>) => {
      const filter = selectedFilters.find((selFilter) => selFilter.category === 'segment');

      if (!filter) {
        return;
      }

      const ids = filter.ids;
      if (!ids) {
        return;
      }
      const newSegmentation = ids.map((id) => appSegmentations[id]);

      dispatch(actions.setAudienceSegmentation(newSegmentation));
    },
    [dispatch, appSegmentations],
  );

  return (
    <div style={styles.wrapper}>
      <AudienceBackButton />
      <div style={styles.infoWrapper}>
        <div style={styles.title}>{t('activities:audience.segmentation.title')}</div>
        <div style={styles.subtitle}>{t('activities:audience.segmentation.subtitle')}</div>
        <SFilterBox
          filters={filters}
          placeholder={t('activities:audience.segmentation.placeholder')}
          onChange={handleFilterChange}
          count={null}
          type="compact"
          style={styles.segmentationInput}
          showSuggestionsOnFocus
        />
        <div style={styles.audienceTable}>
          <AudienceTable />
        </div>
      </div>
    </div>
  );
};
