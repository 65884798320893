type OrderingCardsSetQuestion = {
  type: 'CONTENT_OC_SET_QUESTION';
  question: string;
};

type OrderingCardsSetTopLabel = {
  type: 'CONTENT_OC_SET_TOP_LABEL';
  topLabel: string;
};

type OrderingCardsSetBottomLabel = {
  type: 'CONTENT_OC_SET_BOTTOM_LABEL';
  bottomLabel: string;
};

type OrderingCardsSetExplanation = {
  type: 'CONTENT_OC_SET_EXPLANATION';
  explanation: string;
};

type OrderingCardsAddItem = {
  type: 'CONTENT_OC_ADD_ITEM';
};

type OrderingCardsRemoveItem = {
  type: 'CONTENT_OC_REMOVE_ITEM';
  key: number;
};

type OrderingCardsSetItemData = {
  type: 'CONTENT_OC_SET_ITEM_DATA';
  key: number;
  text: string;
};

export const actions = {
  setOCQuestion: (question: string): OrderingCardsSetQuestion => ({
    type: 'CONTENT_OC_SET_QUESTION',
    question,
  }),
  setOCTopLabel: (topLabel: string): OrderingCardsSetTopLabel => ({
    type: 'CONTENT_OC_SET_TOP_LABEL',
    topLabel,
  }),
  setOCBottomLabel: (bottomLabel: string): OrderingCardsSetBottomLabel => ({
    type: 'CONTENT_OC_SET_BOTTOM_LABEL',
    bottomLabel,
  }),
  setOCExplanation: (explanation: string): OrderingCardsSetExplanation => ({
    type: 'CONTENT_OC_SET_EXPLANATION',
    explanation,
  }),
  addOCItem: (): OrderingCardsAddItem => ({
    type: 'CONTENT_OC_ADD_ITEM',
  }),
  removeOCItem: (key: number): OrderingCardsRemoveItem => ({
    type: 'CONTENT_OC_REMOVE_ITEM',
    key,
  }),
  setOCItemData: (key: number, text: string): OrderingCardsSetItemData => ({
    type: 'CONTENT_OC_SET_ITEM_DATA',
    key,
    text,
  }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type OrderingCardAction = ReturnType<ActionsFn>;
