import request from 'services/requestService';
import Model from 'models/Model';

var GiftOrder = {
  endpoint  : 'api/GiftOrders',
  attributes: {
    address: {
      type: 'string',
    },
    additionalAddress: {
      type: 'string',
    },
    country: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
    cost: {
      type: 'number',
    },
    credits: {
      type: 'number',
    },
    quantity: {
      type: 'number',
    },
    date: {
      type: 'date',
    },
  },
  classMethods: {
    exportOrders: function exportOrders(_GiftOrder, inPeriod, begin, end) {
      return request.get('api/GiftOrders/download', {
        inPeriod: inPeriod,
        begin   : begin,
        end     : end,
      });
    },
  },
};

export default Model.register('GiftOrder', GiftOrder);
