import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sortable from 'sortablejs';
import { t } from 'i18next';

import { getDateForDailySerieDay } from 'Libs/dailyserieManager';
import { useTypedSelector } from 'Libs/redux/utils';

import SCampaignInfo from 'Components/structural/SCampaignInfo/SCampaignInfo';
import { useModuleSelector } from 'Pages/Activity/redux/hooks';

import SSection from 'Components/structural/SSection/SSection';
import SDailySessionContainer from 'Components/structural/SDailySessionContainer/SDailySessionContainer';
import SCounter, { TooltipPosition } from 'Components/structural/SCounter/SCounter';
import SLegacyContentCard from 'Components/structural/SContentCard/legacy/SLegacyContentCard';
import { SNewContentCard } from 'Components/structural';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';
import STextIndicator, { IndicatorStatus, OddRangeValues } from 'Components/structural/STextIndicator/STextIndicator';

import {
  actions,
  getActivityEndDateForDisplay,
  getSelectedActivity,
  getDefaultActivity,
  getSelectedContentIds,
  getIsContentSelectionLocked,
} from '../../../redux';
import type { ContentType } from '../../../redux/models/Content';

import { getContentStatusAttributes } from '../../utils/content.utils';
import { useTranslateContent } from '../../hooks/useTranslateContent';

import Drawer from '../Drawer/Drawer';

import styles from './RandomizedContent.style';

const NewContentCardWithToolTip = withTooltip(SNewContentCard);
const SCounterTooltip = {
  tooltipPosition: 'top' as TooltipPosition,
  tooltipContent: 'You cannot create less than 1 session',
  tooltipMaxWidth: 218,
};

export const RandomizedContent = () => {
  const dispatch = useDispatch();

  const multilingualContentMap = useTypedSelector((state) => state.pages.activity.multilingualContentsMap);

  const { translationModal, setContentToTranslate } = useTranslateContent();

  const [openedDrawerKey, setOpenedDrawerKey] = useState<number | null>(null);
  const [indicatorStatus, setIndicatorStatus] = useState<IndicatorStatus>('evenly');
  const [oddRange, setOddRange] = useState<OddRangeValues>({ from: 0, to: 0 });

  const activity = useModuleSelector((state) => state.activities[0]);
  const { active, startDate } = activity;

  const defaultActivity = useTypedSelector(getDefaultActivity);
  const selectedActivity = useTypedSelector(getSelectedActivity);
  const isLocked = useTypedSelector((state) => state.pages.activity.isLocked);
  const contentSelectionLocked = useSelector(getIsContentSelectionLocked);

  const selectedContentIds = useTypedSelector(getSelectedContentIds);

  const handleNewContentClick = () => {
    window.open('/knowledge/new', '_blank');
  };

  const endDate = useTypedSelector(getActivityEndDateForDisplay);

  const [numSessions, setSession] = useState(activity.dailySerie.days.length);
  const minCount = numSessions <= 1;
  const extraDays = activity.dailySerie.numExtraDays;

  useEffect(() => {
    if (numSessions < 1) {
      return;
    }

    dispatch(actions.updateSessionsRandomActivity(numSessions));
  }, [numSessions]);

  const removeContentFromDailySerieDay = ({ content }: { content: ContentType }) =>
    dispatch(actions.removeContentFromRandomDailySerie(content, numSessions));

  const addContentToDailySerie = (numberOfSessions: number, contents: ContentType[]) =>
    dispatch(actions.addContentToRandomDailySerieToIndex(numberOfSessions, contents));

  const handleSelectContent = (key: number | null) => (contents: Array<ContentType>) => {
    setOpenedDrawerKey(null);

    if (key !== null) {
      addContentToDailySerie(numSessions, contents);
    }
  };

  const handleSessionChange = (numberOfSessions: number) => {
    setSession(numberOfSessions);
  };

  const handleContentDragFromDrawer = (dayIndex: number, contentIndex: number, content: ContentType) => {
    addContentToDailySerie(numSessions, [content]);
  };

  const flattenedContents = useMemo(
    () => activity.dailySerie.days.flatMap(({ dailySerieContents }) => dailySerieContents),
    [activity],
  );

  const optionsSortable = useMemo(
    () => ({
      group: {
        name: 'sortable-list',
        pull: true,
        put: true,
      },
      forceFallback: true,
      filter: '.nonDraggable',
      disabled: false,
      draggable: isLocked || !selectedActivity.isDefault ? '.none' : '.ReactDraggable',
    }),
    [isLocked, selectedActivity.isDefault],
  );

  useEffect(() => {
    const containerContent = document.getElementById('container-content');
    if (containerContent) {
      Sortable.create(containerContent, optionsSortable);
    }
  }, [optionsSortable]);

  const processDataForTextIndicator = useMemo(() => {
    const contentsPerSession = flattenedContents.length / numSessions;

    if (flattenedContents.length === 0) {
      setIndicatorStatus('evenly');
      return null;
    }

    if (numSessions >= 1 && Number.isInteger(contentsPerSession)) {
      setIndicatorStatus('total');
      return contentsPerSession;
    }

    if (numSessions < flattenedContents.length && numSessions >= 1 && !Number.isInteger(contentsPerSession)) {
      const from = Math.floor(contentsPerSession);
      const to = Math.ceil(contentsPerSession);

      setOddRange({ from, to });
      setIndicatorStatus('odd');
      return null;
    }

    setIndicatorStatus('warning');
    return null;
  }, [flattenedContents.length, numSessions]);

  const contentsType = useMemo(() => {
    if (flattenedContents.length) return 'standard';

    if (numSessions > 1) return 'accentuated';

    return 'first';
  }, [flattenedContents.length, numSessions]);

  return (
    <div>
      <SCampaignInfo
        active={active}
        contentCount={flattenedContents.length}
        sessionCount={numSessions}
        extraDays={extraDays}
        startDate={startDate}
        endDate={endDate}
        stickyContainerStyle={styles.campaignHeader}
        stickyStyle={styles.stickySCampaignInfo}
        style={styles.sCampaignInfo}
      />
      <div style={styles.container}>
        <SSection style={styles.sectionContainer} containerStyle={styles.sectionContainerInside}>
          <div style={styles.titleContainer}>
            <p style={styles.title}>
              {t('activities:scheduling.daily_session_number', { number: activity.dailySerie.days.length })}
            </p>
          </div>
          <div style={styles.counterContainer}>
            {!contentSelectionLocked && (
              <>
                <p style={styles.counterTitle}>{t('activities:scheduling.days_count')}</p>
                <SCounter
                  tooltip={{
                    ...SCounterTooltip,
                    tooltipEnabled: minCount,
                  }}
                  minCount={1}
                  style={styles.counter}
                  count={numSessions}
                  disabled={minCount}
                  onChange={handleSessionChange}
                />
              </>
            )}
            <STextIndicator status={indicatorStatus} total={processDataForTextIndicator} oddRange={oddRange} />
          </div>
          <>
            {activity.dailySerie.days.map((day, index) => (
              <SDailySessionContainer
                style={styles.dailySessionContainer}
                sessionNumber={index + 1}
                contentCount={day.dailySerieContents.length}
                startDate={getDateForDailySerieDay(activity.dailySerie, activity.startDate, index + 1).toString()}
                key={`random-${day.key}`}
                variant="random"
              />
            ))}
          </>
        </SSection>
        <SSection style={styles.sectionContainer} containerStyle={styles.sectionContainerInside}>
          <div style={styles.titleContainer}>
            <p style={styles.title}>{t('activities:scheduling.content_count', { count: flattenedContents.length })}</p>
          </div>
          <Drawer
            languageId={defaultActivity.languageId}
            visible={openedDrawerKey !== null}
            onClose={() => setOpenedDrawerKey(null)}
            onSelect={handleSelectContent(openedDrawerKey)}
            onNewContentPress={handleNewContentClick}
            onContentDrag={handleContentDragFromDrawer}
            onContentMove={() => {}}
            contentIsDragging={false}
            selectedContentIds={selectedContentIds}
          />
          {translationModal}
          <div style={styles.contentList} className="container-content" id="container-content">
            {!contentSelectionLocked && (
              <NewContentCardWithToolTip
                onClick={() => setOpenedDrawerKey(1)}
                type={contentsType}
                label={t('activities:scheduling.add_first_content')}
                tooltipPosition="top"
                tooltipContent={t('activities:scheduling.add_first_content_tooltip')}
                tooltipEnabled={isLocked || !selectedActivity.isDefault}
                disabled={isLocked || !selectedActivity.isDefault}
              />
            )}
            {flattenedContents.map(({ key: contentKey, content }) => {
              const {
                options,
                isDisabled,
                indicatorText,
                knowledgeCustomId,
                knowledgeId,
                imageUrl,
                title,
                types,
                locale,
                statusIndicatorType,
                statusIndicatorTooltip,
              } = getContentStatusAttributes({
                selectedActivity,
                content,
                contentKey,
                setContentToTranslate,
                removeContentFromDailySerieDay,
                multilingualContentMap,
                contentSelectionLocked,
              });

              return (
                <div className="nonDraggable" key={content.id}>
                  <SLegacyContentCard
                    id={`#${knowledgeCustomId || knowledgeId}`}
                    canShowOverlay={!openedDrawerKey}
                    canBeDragged={!contentSelectionLocked && !isLocked && selectedActivity.isDefault}
                    imageUrl={imageUrl}
                    title={title}
                    types={types}
                    locale={locale}
                    indicatorText={indicatorText}
                    locked={isLocked}
                    disabled={isDisabled}
                    selectable={false}
                    previewable={false}
                    displayOption={!isLocked}
                    options={options}
                    statusIndicatorType={statusIndicatorType}
                    statusIndicatorTooltip={statusIndicatorTooltip}
                  />
                </div>
              );
            })}
          </div>
        </SSection>
      </div>
    </div>
  );
};
