import { encodeSearchObject } from '@sparted/shared-library/http/encode-search-object';
import { SearchSortOrder } from '@sparted/shared-library/types/search';

import type { Language } from 'Features/audience/types/language';
import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils/helpers';
import type { FilterItem } from 'Libs/filter/types';

export type AudienceGroupStatus = 'all' | 'selected' | 'unselected';

export type FetchAudienceGroupsArgs = {
  entityId?: number;
  status: AudienceGroupStatus;
  title: string | null;
  sort?: FilterItem;
  limit: number;
  offset: number;
  language?: Language;
  ruleType?: 'segmentation' | 'all' | 'magic-code' | 'email-list';
  includeMetaGroupAll?: boolean;
};

export type FetchNextBatchAudienceGroupsArgs = {
  route: string;
};

export type SegmentationGroupItemResponse = {
  id: number;
  label: string;
  color: string;
  createdAt: string;
  updatedAt: string;
};

export type RuleResponse = {
  type: string;
  segmentationGroupItems: SegmentationGroupItemResponse[];
};

export type AudienceGroupResponse = {
  id: number;
  name: string;
  description?: string;
  selected: boolean;
  rules: RuleResponse[];
  metadata: {
    maxAudience: number;
    currentAudience: number;
  };
  createdAt: string;
  updatedAt: string;
};

export type FetchAudienceGroupsResponse = {
  limit: number;
  offset: number;
  next: string | null;
  previous: string | null;
  total: number;
  items: AudienceGroupResponse[];
};

export const fetchAudienceGroupsFn = ({
  entityId,
  status,
  title,
  limit,
  offset,
  sort,
  language,
  ruleType,
  includeMetaGroupAll,
}: FetchAudienceGroupsArgs) => {
  const { fetchFn } = fetchFactory<FetchAudienceGroupsResponse>({
    route: `/api/audiences/groups?${encodeSearchObject({
      limit,
      offset,
      query: {
        entityId: entityId?.toString() || '',
        status,
        ...(title ? { name: title } : {}),
        languageIds: language?.availableGroupId ? language.availableGroupId.toString() : '',
        ruleType: ruleType || '',
        includeMetaGroupAll: includeMetaGroupAll?.toString() || '',
      },
      ...(sort
        ? {
            [sort.field!]: sort.order!,
          }
        : {}),
    })}`,
    method: 'GET',
  });

  return fetchFn();
};

export const fetchAudienceGroups = createAsyncAction<FetchAudienceGroupsResponse, FetchAudienceGroupsArgs>(
  'audience/fetchAudienceGroups',
  fetchAudienceGroupsFn,
);

type AssignAudienceGroupToEntityResponse = {};

type AssignAudienceGroupToEntityArgs = {
  entityId: number;
  audienceGroupIds: number[];
};

export const assignAudienceGroupsToEntity = createAsyncAction<
  AssignAudienceGroupToEntityResponse,
  AssignAudienceGroupToEntityArgs
>('audience/assignmentToEntity', ({ entityId, audienceGroupIds }) => {
  const { fetchFn } = fetchFactory({
    route: `/api/audiences/assignment/course/${entityId}`,
    payload: {
      audienceGroupIds,
    },
    method: 'POST',
  });

  return fetchFn();
});
