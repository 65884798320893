import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import useDraggable from './hooks/useDraggable';

import USizeSelectorBar from 'Components/unit/USizeSelectorBar/USizeSelectorBar';
import UMediaPicker from 'Components/unit/UMediaPicker/UMediaPicker';
import UIconButton from 'Components/unit/UIconButton/UIconButton';

import styles from './SPictureSpotImage.style';

export type SPictureSpotImageProps = {
  style?: CSSProperties;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  initialX: number;
  initialY: number;
  initialZoneSize?: number;
  minAnswerZoneSize?: number;
  maxAnswerZoneSize?: number;
  onClearMedia?: () => void;
  onChangeAnswerZoneSize?: (size: number) => void;
  onPositionChange: (x: number, y: number) => void;
};

export const SPictureSpotImage = ({
  style,
  imageUrl,
  initialX,
  initialY,
  initialZoneSize,
  imageWidth = 400,
  imageHeight = 600,
  minAnswerZoneSize = 30,
  maxAnswerZoneSize = 340,
  onPositionChange,
  onChangeAnswerZoneSize,
  onClearMedia,
}: SPictureSpotImageProps) => {
  const { boxRef, containerRef, answerZoneSize, setAnswerZoneSize } = useDraggable(
    onPositionChange,
    initialZoneSize,
    initialX,
    initialY,
  );
  const [image, setImage] = useState<string | undefined>(imageUrl);
  const pickerContainerStyle = useMemo(() => (!image ? styles.withVisibility : styles.noDisplay), [image]);
  const pickerStyle = useMemo(
    () => ({ width: imageWidth, height: imageHeight, ...styles.mediaPicker }),
    [imageWidth, imageHeight],
  );
  const referencesContainerStyle = useMemo(() => (image ? styles.withVisibility : styles.noDisplay), [image]);
  const imageStyle = useMemo(
    () => ({
      background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('${image}') center/cover`,
      width: imageWidth,
      height: imageHeight,
      ...styles.image,
    }),
    [image, imageWidth, imageHeight],
  );

  const answerZoneStyle = useMemo(
    () => ({
      background: `url('${image}') center/cover`,
      width: imageWidth,
      height: imageHeight,
      cursor: 'grab',
      ...styles.image,
    }),
    [image, imageWidth, imageHeight],
  );

  const handleClearMedia = useCallback(() => {
    setImage(undefined);
    onClearMedia?.();
  }, [onClearMedia]);

  useEffect(() => {
    setImage(imageUrl);
  }, [imageUrl]);

  const onChangingAnswerZoneSize = useCallback(
    (value: number) => {
      setAnswerZoneSize(value);
      onChangeAnswerZoneSize?.(value);
    },
    [setAnswerZoneSize],
  );

  return (
    <>
      <div style={pickerContainerStyle}>
        <UMediaPicker
          dropArea
          size="L"
          media={{
            mediaType: 'image',
            onChange: (image) => setImage(image.url),
          }}
          style={pickerStyle}
        />
      </div>
      <div style={referencesContainerStyle}>
        <div style={styles.closeIcon}>
          <UIconButton
            size="S"
            type="standard-light"
            style={styles.closeRadius}
            icon="close"
            onClick={handleClearMedia}
          />
        </div>
        <div
          ref={containerRef}
          id="container"
          style={{
            ...styles.container,
            ...imageStyle,
            ...style,
          }}
        >
          <div ref={boxRef} style={answerZoneStyle} id="box" />
        </div>
        <USizeSelectorBar
          style={styles.selectorBar}
          label={t('gameplays:picture_spot.drag_answer_zone')}
          value={answerZoneSize}
          // divided by 2 because of the radius
          min={minAnswerZoneSize / 2}
          max={maxAnswerZoneSize / 2}
          onChange={onChangingAnswerZoneSize}
        />
      </div>
    </>
  );
};

export default SPictureSpotImage;
