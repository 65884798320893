/*
 * dimension      : the dimension to display
 * moveDimension  : the function allowing to move a dimension
 * orderDimensions: function reordering all the dimensions
 * isLastBranch   : is the last branch of the tree trunck
 */

import m from 'm';
import segmentationDimension from 'components/segmentation.dimension';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.selected = m.prop({});

  self.selected()[args.dimension.id()] = {
    item: undefined,
  };

  args.dimension.children()
    .forEach(function setDimSelector(dim) {
      self.selected()[dim.id()] = {
        item: undefined,
      };
    });

  self.moveChildren = function moveChildren(dim, up) {
    var switchDimension;
    var switchOrder;

    args.dimension.children().forEach(function getSwitch(dimension) {
      if (up && dim.order() < dimension.order() && (!switchDimension || (dimension.order() < switchDimension.order())))
        switchDimension = dimension;
      if (!up && dim.order() > dimension.order() && (!switchDimension || (dimension.order() > switchDimension.order())))
        switchDimension = dimension;
    });

    if (!switchDimension)
      return undefined;

    switchOrder = switchDimension.order();

    switchDimension.order(dim.order());
    dim.order(switchOrder);

    return args.orderDimensions();
  };

  self.getSelectedForDimOrcreateIt = function getSelectedForDimOrcreateIt(id) {
    if (!self.selected()[id]) {
      self.selected()[id] = {
        item: undefined,
      };
    }

    return self.selected()[id];
  };
};

component.view = function view(c, args) {
  return displayDimension(args.dimension, 0, args.isLastBranch, null, args.collection);

  function displayDimension(dim, depth, isLastChild, parentName, collection) {
    return m('.segmentation-dimension-children-container', [
      m(segmentationDimension, {
        dimension      : dim,
        depth          : depth,
        ancestor       : c.getSelectedForDimOrcreateIt(dim.parentId()),
        selected       : c.getSelectedForDimOrcreateIt(dim.id()),
        parentName     : parentName,
        moveDimension  : depth ? c.moveChildren : args.moveDimension,
        orderDimensions: args.orderDimensions,
        isLastChild    : isLastChild,
        isThereCreation: args.isThereCreation,
        key            : args.key,
        collection     : collection,
      }),
      displayChildren(dim, depth),
    ]);
  }

  function displayChildren(dim, depth) {
    dim.children()
      .sort(function order(a, b) {
        return a.order() - b.order();
      });

    if (!dim.children().items.length)
      return '';

    return dim.children()
      .map(function displayChild(child, i) {
        var isLastChild = args.isLastBranch && i === dim.children().length - 1;

        return displayDimension(child, depth + 1, isLastChild, dim.label(), dim.children);
      });
  }

};

export default component;
