import { COLORS } from 'Components/foundation';

export default {
  wrapper: {
    width: 640,
    minHeight: 400,

    paddingTop: 24,
    paddingBottom: 32,
    paddingLeft: 30,
    paddingRight: 30,

    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
};
