import React from 'react';
import type { CSSProperties } from 'react';
import Markdown from 'react-markdown';

import { css } from 'Libs/css/extended-styles';

import styles, { extendedStyles } from './UMarkdown.style';

export type UMarkdownProps = {
  markdown: string;
  style?: CSSProperties;
};

const markdownExtendedStyles = css(extendedStyles.link);

/**
 * Wrapper around the react-markdown library.
 *
 * markdown: text to interpret as markdown
 * style: style applied to the wrapper around the markdown component
 */
export const UMarkdown = ({ markdown, style = {} }: UMarkdownProps) => (
  <div style={{ ...styles.wrapper, ...style }}>
    <Markdown className={markdownExtendedStyles} linkTarget="_blank">
      {markdown}
    </Markdown>
  </div>
);

export default UMarkdown;
