import { COLORS, ELEVATIONS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 200,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 12,
    position: 'relative',
  },
  wrapperDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  defaultSentence: {
    ...TYPOGRAPHY.H3,
    color: COLORS.TEXT.DEFAULT,
    marginTop: 2,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 6,
  },
  firstWordDefault: {
    color: COLORS.TEXT.ACCENTUATED,
    cursor: 'pointer',
  },
  defaultLabel: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    margin: 2,
  },
  dropSentence: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    ...TYPOGRAPHY.H3,
    color: COLORS.TEXT.DEFAULT,
  },
  errorSentence: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.ERROR.DEFAULT,
    margin: 8,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 92,
    borderWidth: 1,
    borderStyle: 'solid',
    width: 45,
    height: 45,
    marginBottom: 14,
  },
  upload: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: COLORS.BLUE_GRAY_LIGHT.DEFAULT,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  uploadText: {
    position: 'relative',
    zIndex: LEVELS.LOCAL_OFFSET,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  inputUpload: {
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  renderFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    borderRadius: 8,
    boxShadow: ELEVATIONS.LIGHT_TINY,
    paddingRight: 16,
    gap: 16,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  iconText: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  fileIcon: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 19,
    paddingRight: 19,
    backgroundColor: COLORS.PRIMARY.LIGHT,
    height: 60,
  },
  fileName: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.DEFAULT,
  },
  removeFile: {
    cursor: 'pointer',
    height: 14,
  },
});

export default styleSheet;
