// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  drawer: {
    zIndex: 10000,
  },
  container: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 128,
  },
  abstract: {
    marginTop: 12,
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  current: {
    marginTop: 48,
    marginBottom: 25,
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
  },
  wrapperAddLanguages: {
    display: 'flex',
    marginTop: 32,
  },
  containerDropdownLanguages: {
    marginLeft: 5,
  },
  accordion: {
    marginTop: 20,
  },
  containerInAccordion: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 30,
  },
  sentenceAccordion: {
    width: 340,
  },
  buttonTranslateAllContents: {
    marginLeft: 28,
    height: 32,
  },
};
