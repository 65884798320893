import { Promise } from 'bluebird';
import React, { useEffect, useState, useCallback } from 'react';

import UButton from 'Components/unit/UButton/UButton';
import SInput from 'Components/structural/SInput/SInput';
import SPasswordInput from 'Components/structural/SPasswordInput/SPasswordInput';

import { AuthenticationSteps } from 'Models/Enum';
import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import { redirect, reload } from 'Libs/routing';

import { actions } from '../../redux';

import styles from './LoginForm.style';

type Props = {
  instanceName: string;
  onResetPassword: () => any;
};

export const LoginForm = ({ instanceName, onResetPassword }: Props) => {
  const dispatch = useTypedDispatch();

  const email = useTypedSelector((state) => state.pages.login.email);
  const error = useTypedSelector((state) => state.pages.login.error);
  const isLoading = useTypedSelector((state) => state.pages.login.loading);

  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const onEmailChange = useCallback((_email) => dispatch(actions.setEmail(_email)), [dispatch]);
  const onPasswordChange = useCallback((_password) => setPassword(_password), []);

  const onLoginEnd = useCallback((res) => {
    const { error: payloadError, type } = res;

    if (payloadError) {
      return;
    }

    const event = new Event('user.login');

    document.dispatchEvent(event);

    if (type === AuthenticationSteps.TWO_FACTOR_CREDENTIALS_REQUEST) {
      redirect('/two-factor/enter-phone', { shouldReplaceHistory: true });

      return;
    }

    if (type === AuthenticationSteps.TWO_FACTOR_CHALLENGE) {
      redirect('/two-factor/enter-code', { shouldReplaceHistory: true });

      return;
    }

    if (type === AuthenticationSteps.TWO_FACTOR_CREDENTIALS_MISSING) {
      redirect('/two-factor/email-sent', { shouldReplaceHistory: true });

      return;
    }

    reload();
  }, []);

  const onSubmit = useCallback(() => {
    if (email.length && password.length) {
      const loginAction = actions.login(email, password);

      dispatch(loginAction);

      return Promise.resolve(loginAction.promise).then(onLoginEnd);
    }

    return Promise.resolve();
  }, [dispatch, email, password, onLoginEnd]);

  useEffect(() => {
    if (email.length && password.length) {
      return setIsDisabled(false);
    }

    return setIsDisabled(true);
  }, [email, password]);

  return (
    <div style={styles.loginWrapper}>
      <div style={styles.instanceName}>{instanceName}</div>

      <div style={styles.welcome}>Welcome back</div>

      <div style={styles.instruction}>Log into your back office.</div>

      <div style={styles.inputWrapper}>
        <SInput
          style={styles.emailInput}
          type="small"
          placeholder="Email"
          value={email}
          onChange={onEmailChange}
          autofocus
        />
        <SPasswordInput
          style={styles.passwordInput}
          placeholder="Password"
          value={password}
          requireMinCharacters={0}
          requireMinNumber={0}
          requireMinUppercase={0}
          requireMinSpecialCharacters={0}
          onChange={onPasswordChange}
          onSubmit={onSubmit}
        />
      </div>

      <div>
        <button type="button" onClick={onResetPassword} style={styles.resetPassword}>
          Did you forget your password ?
        </button>
      </div>

      <div style={styles.buttonWrapper}>
        <UButton
          type="accentuated"
          text="Log in"
          onClick={onSubmit}
          loading={isLoading}
          disabled={isDisabled}
          style={styles.button}
        />
        <div style={styles.errorMsg}>
          {error && 'Your password or your email is invalid, please try again with the right credentials.'}
        </div>
      </div>
    </div>
  );
};
