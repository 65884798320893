import Promise from 'bluebird';
import Enum from 'models/Enum';
import Model from 'models/Model';
import request from 'services/requestService';
import LS from 'services/localStorageService';

var User = {};

User.password = function setPassword(token, pwd) {
  return request
    .put('api/Players/contributors/resetPassword', {
      password: pwd,
      token   : token,
    })
    .tap(function setToken(auth) {
      if (auth?.token?.id) {
        LS.token(auth.token.id);

        return User.refresh();
      }

      return LS.clear();
    })
    .catch(function eraseToken(err) {
      console.error('[error:user:password]', err);

      return Promise.reject(err);
    });
};

User.logout = function logout() {
  return LS.clear();
};

User.me = function me() {
  return LS
    .get('user')
    .then(instanciate);
};

User.meSync = function meSync() {
  var player = LS.getSync('user');

  return instanciate(player);
};

User.meOrUnauthenticated = function meOrUnauthenticated() {
  var player = { roles: [{ id: Enum.Role.UNAUTHENTICATED }]};

  try {
    player = LS.getSync('user');
  } catch (e) {}

  return instanciate(player);
};

User.get = function getMe() {
  return request
    .get('api/Players/me')
    .then(instanciate);
};

User.refresh = function refreshMe() {
  return request
    .get('api/Players/me')
    .then(function localUpdateMe(reply) {
      return LS.post('user', reply.user);
    })
    .then(instanciate)
    .catch(console.log.bind(console));
};

User.update = function updateMe(infos) {
  return request
    .put('api/Players/me', infos)
    .then(function localUpdateMe(reply) {
      return LS.post('user', reply.user);
    })
    .then(instanciate);
};

User.isLogged = function isLogged() {
  if (LS.token() !== null) {
    return request
      .get('api/thematics')
      .catch(function tokenGotRefused(err) {
        console.warn('Token is invalid.');

        LS.clear();

        return Promise.reject();
      });
  }

  return Promise.reject();
};

function instanciate(p) {
  var Player = Model('Player');

  return new Player(p);
}

export default User;
