import { AppModel } from 'Services/segmentation/segmentationService';
import LS from '../localStorageService';

import App from 'Models/App';

export const ROLES = {
  ROOT: 'Root',
  MASTER_ADMIN: 'MasterAdmin',
  WRITER: 'Writer',
  TRANSLATOR: 'Translator',
  ADMIN: 'Admin',
  ANALYST: 'Analyst',
  DASHBOARD: 'Dashboard',
};

type RolesKeys = keyof typeof ROLES;
type RolesValues = typeof ROLES[RolesKeys];

type Role = {
  created: string;
  modified: string;
  description: string | null;
  id: number;
  name: RolesValues;
};

export const getCurrentUserRoles = (): Role[] => {
  const player = LS.getSync<{ roles: Role[] }>('user');

  return player.roles;
};

export const getAllRoles = (mithrilAppModel: AppModel = App): Role[] => {
  const { roles } = mithrilAppModel;
  return roles().items.map((item: any) => item.toJSON());
};
