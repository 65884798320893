import { t } from 'i18next';
import type { CSSProperties } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { COLORS } from 'Components/foundation';
import { IconName } from 'Components/foundation/icons';
import { UDropdownBox } from 'Components/unit';
import UDropdownLanguageItem from 'Components/unit/UDropdownLanguageItem/UDropdownLanguageItem';
import UFlag from 'Components/unit/UFlag/UFlag';
import UIcon from 'Components/unit/UIcon/UIcon';
import { UStatusType } from 'Components/unit/UStatusIndicator/UStatusIndicator';

import styles, {
  FLAG_SIZE,
  HEIGHT_OF_DROPDOWN_LANGUAGE_ITEM,
  MARGIN_BETWEEN_GROUP_AND_DROPDOWN,
  MAXIMUM_LANGUAGES_BEFORE_SCROLLING,
} from './SFlagGroup.style';

export type TranslationStatus = {
  countryCode: string;
  status: ContentTranslationStatus;
  languageLabel: string;
  onClick?: () => void;
};

export type SFlagGroupProps = {
  style?: CSSProperties;
  translationStatuses: TranslationStatus[];
};

export type ContentTranslationStatus =
  | 'not_saved'
  | 'archived'
  | 'draft'
  | 'pending_translation'
  | 'pending_validation'
  | 'ready';

export const MAX_FLAGS_DISPLAYED = 3;

const TRANSLATION_STATUS_TO_STATUS_TYPE_MAP: Record<ContentTranslationStatus, UStatusType> = {
  not_saved: 'invalid',
  archived: 'invalid',
  draft: 'invalid',
  pending_translation: 'error',
  pending_validation: 'error',
  ready: 'valid',
};

const GET_TRANSLATION_STATUS_LABEL_MAP: () => Record<ContentTranslationStatus, string> = () => ({
  not_saved: t('contents:content_details.status.not_saved'),
  archived: t('contents:content_details.status.archived'),
  draft: t('contents:content_details.status.draft'),
  pending_translation: t('contents:content_details.status.pending_translation'),
  pending_validation: t('contents:content_details.status.pending_validation'),
  ready: t('contents:content_details.status.ready'),
});

const getIconOverride = (status: ContentTranslationStatus): IconName | undefined => {
  switch (status) {
    case 'draft':
      return 'edit-sml';
    case 'pending_translation':
      return 'language';
    default:
      return undefined;
  }
};

const MoreFlagsIndicator = ({ flagAmount }: { flagAmount: number }) => (
  <div style={styles.moreFlagsIndicator}>{`+${flagAmount}`}</div>
);

const checkForNonoperationalLanguages = (translationStatuses: TranslationStatus[]) => {
  return translationStatuses.find((translationStatus) => {
    const statusType = TRANSLATION_STATUS_TO_STATUS_TYPE_MAP[translationStatus.status];
    return statusType === 'error' || statusType === 'invalid';
  });
};

const checkIfEnoughRoomForDropDown = ({
  elementRef,
  numberOfLanguages,
}: {
  elementRef: React.MutableRefObject<null>;
  numberOfLanguages: number;
}) => {
  if (elementRef.current) {
    const heightOfDropdown =
      Math.min(MAXIMUM_LANGUAGES_BEFORE_SCROLLING, numberOfLanguages) * HEIGHT_OF_DROPDOWN_LANGUAGE_ITEM;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // @ts-ignore: ts cannot understand this is not never because of initialisation in the jsx
    const wrapperPosition = elementRef.current.getBoundingClientRect();
    if (wrapperPosition.bottom + heightOfDropdown + MARGIN_BETWEEN_GROUP_AND_DROPDOWN > viewportHeight) {
      // Not enough room to render dropdown below
      return false;
    }
  }
  // Enough room to render dropdown below, or default
  return true;
};

const renderFlagsAndIndicator = ( translationStatuses : TranslationStatus[] ) =>
  translationStatuses.map((translationStatus: TranslationStatus, index: number) => {

    // Never render more than 4 elements
    if (index > MAX_FLAGS_DISPLAYED) return null;

    // The fourth element just shos how many flags are hidden
    if (index === MAX_FLAGS_DISPLAYED) {
      return (
        <div key={translationStatus.countryCode} style={styles.moreFlagsIndicatorContainer}>
          <MoreFlagsIndicator
            key={translationStatus.countryCode}
            flagAmount={translationStatuses.length - MAX_FLAGS_DISPLAYED}
          />
        </div>
      );
    }

    // The first element is a flag
    return (
      <div key={translationStatus.countryCode} style={styles.flagContainer}>
        <UFlag countryCode={translationStatus.countryCode} size={FLAG_SIZE} withBorder={false} />
      </div>
    );
  });

const WarningBubble = () => (
  <div style={styles.warningBubble}>
    <UIcon size={8} color={COLORS.WARNING.DEFAULT} name="warning-mini" />
  </div>
);

const DropdownBoxWrapper = ({
  translationStatuses,
  isTooLow,
  translationStatusLabelMap,
}: {
  translationStatuses: TranslationStatus[];
  isTooLow: boolean;
  translationStatusLabelMap: Record<ContentTranslationStatus, string>;
}) => {
  return (
    <UDropdownBox
      style={{ ...styles.dropdownBox, ...(isTooLow && styles.dropupBox) }}
      items={translationStatuses}
      renderItem={(item: TranslationStatus) => (
        <UDropdownLanguageItem
          countryCode={item.countryCode}
          text={item.languageLabel}
          onClick={item.onClick ?? (() => null)}
          status={TRANSLATION_STATUS_TO_STATUS_TYPE_MAP[item.status]}
          statusText={translationStatusLabelMap[item.status]}
          iconOverride={getIconOverride(item.status)}
        />
      )}
    />
  );
};

/**
 * Display up to three flags and a more flags indicator if there are more
 */
export const SFlagGroup = ({ style = {}, translationStatuses }: SFlagGroupProps) => {
  const [isHover, setIsHover] = useState(false);
  const [isTooLow, setIsTooLow] = useState(false);

  const wrapperElementRef = useRef(null);

  const isWarningActive = useMemo(() => checkForNonoperationalLanguages(translationStatuses), [translationStatuses]);
  const translationStatusLabelMap = useMemo(GET_TRANSLATION_STATUS_LABEL_MAP, []);

  const handleHover = useCallback(() => {
    if (
      checkIfEnoughRoomForDropDown({ numberOfLanguages: translationStatuses.length, elementRef: wrapperElementRef })
    ) {
      setIsTooLow(false);
    } else {
      setIsTooLow(true);
    }
    setIsHover(true);
  }, [wrapperElementRef, translationStatuses]);

  return (
    <div
      id="hoverableZone"
      style={styles.hoverableZone}
      onMouseEnter={handleHover}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        ref={wrapperElementRef}
        style={{
          ...styles.wrapper,
          ...(isWarningActive && styles.warningWrapper),
          ...(!isWarningActive && !isHover && styles.restState),
          ...style,
        }}
      >
        {renderFlagsAndIndicator(translationStatuses)}
        {isWarningActive && <WarningBubble />}
        {isHover && <DropdownBoxWrapper {...{ isTooLow, translationStatusLabelMap, translationStatuses }} />}
      </div>
    </div>
  );
};

export default SFlagGroup;
