import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const BASIC_BORDER = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: COLORS.GREY_DARK.DEFAULT,
};

const BORDER_RADIUS = {
  borderRadius: '4px',
};

const styles = createStyleSheet({
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  inputWrapper: {
    width: '100%',
    minWidth: '30px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...BORDER_RADIUS,
    ...BASIC_BORDER,
  },
  childrenWrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
  },
  ghostInputWrapper: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    ...BORDER_RADIUS,
  },
  focusedInput: {
    borderColor: COLORS.GREY_DARK.OUTLINE_FOCUSED,
  },
  hoveredInput: {
    ...BASIC_BORDER,
  },
  textField: {
    width: '89px',
    border: 'none',
    backgroundColor: 'transparent',
    ...BORDER_RADIUS,
    paddingRight: 0,
    outline: 'none',
    ...TYPOGRAPHY.BODY3,
  },
  labelPlacement: {
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: '0.3',
  },
  description: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    marginLeft: 7,
  },
  errorMessage: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DARK,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    marginLeft: 5,
  },
  errorMessageWithDescription: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.ERROR.DARK,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 5,
  },
  withoutIcon: {
    marginLeft: 0,
  },
  inlineCounter: {
    alignSelf: 'center',
  },
  basicCounter: {
    position: 'relative',
  },
  labelAndCounterLine: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'space-between',
  },
  count: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    fontWeight: 400,
  },
  countReached: {
    color: COLORS.ERROR.DARK,
  },
  icon: {
    marginRight: 6,
    marginTop: 1,
  },
  micro: {
    textAlign: 'center',
    width: '100%',
    padding: '2px 7px',
  },
  mini: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
  },
  small: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 11,
  },
  large: {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 15,
  },
});

export default styles;
