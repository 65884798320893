import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    width: '100%',
    height: '100%',
  },
});

export default styleSheet;
