import React, { useEffect, useMemo, useState } from 'react';
import type { FunctionComponent, CSSProperties, ReactNode, UIEvent } from 'react';
import ReactModal from 'react-modal';

import BluebirdPromise from 'bluebird';
import { css } from 'aphrodite';

import type { TrackingProps } from 'Services/trackingService';

import { usePrevious } from 'Hooks/usePrevious/usePrevious';
import { useIsFirstRender } from 'Hooks/useIsFirstRender/useIsFirstRender';

import UButton from 'Components/unit/UButton/UButton';
import UButtonWithLoading from 'Components/unit/UButton/UButtonWithLoading';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import MModalOverlay from 'Components/modal/MModalOverlay/MModalOverlay';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';
import { UButtonType } from 'Components/unit/UButton/UButton.utils';
import { IconName } from 'Components/foundation/icons';

import styles, { enterAnimation, exitAnimation, ANIMATION_DURATION } from './MModal.style';

export { ANIMATION_DURATION } from './MModal.style';

export type MModalType = 'standard' | 'dialog';

export type MModalProps = TrackingProps & {
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
  className?: string;

  visible: boolean;
  title?: string;
  titleStyle?: CSSProperties;
  description?: string;
  descriptionStyle?: CSSProperties;

  // Primary button props
  labelActionButton?: string;
  actionButtonLeftIcon?: IconName;
  actionButtonRightIcon?: IconName;

  disableActionButton?: boolean;

  // Secondary button props
  labelSecondButton?: string;
  secondButtonLeftIcon?: IconName;
  secondButtonRightIcon?: IconName;

  onCloseModal: () => void;
  onAction?: () => BluebirdPromise<unknown> | Promise<unknown> | unknown;
  onSecondAction?: () => void;
  onActionEnd?: (params: unknown) => BluebirdPromise<unknown> | Promise<unknown> | unknown;
  onAfterClose?: () => void;
  onScroll?: (event: UIEvent<HTMLDivElement>) => void;
  labelError?: string;
  type?: MModalType;
  actionButtonType?: UButtonType;
  isActionLoading?: boolean;
  showExitCross?: boolean;
  hideOverlay?: boolean;

  header?: ReactNode;
  headerStyle?: CSSProperties;
  footerStyle?: CSSProperties;
};

/**
 * A simple Modal with entry and exit animation
 *
 * Props:
 *  - visible: boolean to display or hide the modal
 *  - title: title of the modal
 *  - header: any node we want to render as a header
 *  - children: what to render inside the modal
 *  - labelSecondButton: label for the secondary button
 *  - labelActionButton: label for the action button
 *  - disableActionButton: disable the action button
 *  - onCloseModal: Called when click outside the modal or on the cancel button
 *  - onAction: Called when click on the action button
 *  - onActionEnd: Called when the action finished loading
 *  - onSecondAction: Called when click on the second button
 *  - labelError: label to display an error
 *  - type: type of the modal (standard | dialog)
 *  - actionButtonType: type of the action button
 *  - isActionLoading: boolean that indicates if the button is loading
 *  - showExitCross: boolean that indicates if cross is displayed
 */
export const MModal: FunctionComponent<MModalProps> = ({
  style,
  className,
  bodyStyle,
  header,
  headerStyle,
  footerStyle,
  visible,
  title,
  titleStyle,
  description,
  descriptionStyle,
  children,
  labelActionButton,
  actionButtonLeftIcon,
  actionButtonRightIcon,
  labelSecondButton,
  secondButtonLeftIcon,
  secondButtonRightIcon,
  trackingAction,
  trackingContext,
  onAction,
  onActionEnd,
  onCloseModal,
  onSecondAction,
  disableActionButton = false,
  labelError = '',
  type = 'standard',
  actionButtonType = 'accentuated',
  isActionLoading = false,
  showExitCross = false,
  hideOverlay = false,
  onScroll = () => {},
  onAfterClose = () => {},
}) => {
  const prevVisible = usePrevious(visible);
  const isFirstRender = useIsFirstRender();

  const [isOpen, setIsOpen] = useState(visible);

  const dialogModalStyle = useMemo(() => (type === 'dialog' ? styles.dialogModal : {}), [type]);

  const modalStyle = useMemo(
    () => ({
      content: {
        ...styles.modalContent,
        ...dialogModalStyle,
        ...style,
      },
      overlay: styles.modalOverlay,
    }),
    [dialogModalStyle, style],
  );

  const headerStyles = useMemo(
    () => ({
      ...(type === 'dialog' ? styles.dialogHeader : styles.header),
      ...(showExitCross ? { paddingRight: 56 } : {}),
      ...headerStyle,
    }),
    [showExitCross, headerStyle],
  );

  const footerStyles = useMemo(
    () => ({
      ...(type === 'dialog' ? styles.dialogFooter : styles.footer),
      ...footerStyle,
    }),
    [footerStyle],
  );

  useEffect(() => {
    const duration = visible ? 0 : ANIMATION_DURATION;
    const timeoutId = setTimeout(() => {
      setIsOpen(visible);
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [visible]);

  useEffect(() => {
    if (prevVisible && !visible && !isFirstRender) {
      setTimeout(() => onAfterClose(), ANIMATION_DURATION);
    }
  }, [onAfterClose, prevVisible, visible, isFirstRender]);

  const animationClassName = useMemo(() => (visible ? css(enterAnimation) : css(exitAnimation)), [visible]);

  const contentStyle = useMemo(() => (type === 'dialog' ? styles.dialogContent : {}), [type]);

  return (
    <>
      {!hideOverlay && (
        /**
         * Not using the onClick props from the MModalOverlay because
         * the click event never reach it. The ReactModal overlay is
         * still in the DOM but transparent and catch the click event.
         */
        <MModalOverlay visible={visible} />
      )}
      {isOpen && (
        <ReactModal
          isOpen
          ariaHideApp={false}
          onRequestClose={onCloseModal}
          style={modalStyle}
          className={`${animationClassName} ${className}`}
        >
          {(title || description || header) && (
            <div style={headerStyles}>
              <div style={{ ...styles.title, ...titleStyle }}>{title}</div>
              {description && (
                <UMarkdown style={{ ...styles.description, ...descriptionStyle }} markdown={description} />
              )}
              {header && (
                <>
                  <div style={styles.spacer} />
                  {header}
                </>
              )}
            </div>
          )}

          {showExitCross && (
            <UIconButton ghost icon="close-light" size="M" onClick={onCloseModal} style={styles.exitCross} />
          )}

          <div style={{ ...styles.body, ...contentStyle, ...bodyStyle }} onScroll={onScroll}>
            {children}
          </div>

          {(onAction || onSecondAction) && (
            <div data-testid="modal-footer" style={footerStyles}>
              <span style={styles.error}>{labelError}</span>
              <div style={styles.buttonWrapper}>
                {onSecondAction && (
                  <UButton
                    text={labelSecondButton}
                    onClick={onSecondAction}
                    leftIcon={secondButtonLeftIcon}
                    rightIcon={secondButtonRightIcon}
                  />
                )}
                {onAction &&
                  (onActionEnd ? (
                    <UButtonWithLoading
                      text={labelActionButton}
                      type={actionButtonType}
                      style={styles.actionButton}
                      // can only be a promise if onActionEnd is defined
                      onClick={onAction as () => Promise<unknown>}
                      disabled={disableActionButton}
                      onRequestEnd={onActionEnd}
                      trackingAction={trackingAction}
                      trackingContext={trackingContext}
                      leftIcon={actionButtonLeftIcon}
                      rightIcon={actionButtonRightIcon}
                    />
                  ) : (
                    <UButton
                      text={labelActionButton}
                      type={actionButtonType}
                      style={styles.actionButton}
                      onClick={onAction}
                      disabled={disableActionButton}
                      loading={isActionLoading}
                      trackingAction={trackingAction}
                      trackingContext={trackingContext}
                      leftIcon={actionButtonLeftIcon}
                      rightIcon={actionButtonRightIcon}
                    />
                  ))}
              </div>
            </div>
          )}
        </ReactModal>
      )}
    </>
  );
};

export default MModal;
