import { fetchFactory } from 'Libs/http/utils';

type ImageResponse = {
  id: number;
  url: string;
};

export const saveImageFromUrl = async (url: string) => {
  const { fetchFn } = fetchFactory<ImageResponse>({
    route: '/api/images/save',
    method: 'POST',
    payload: {
      file: url,
    },
  });

  try {
    const result = await fetchFn();

    return { result };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return { error: err };
  }
};
