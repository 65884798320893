import { encodeSearchObject } from '@sparted/shared-library';
import { DeepPartial } from 'ts-essentials';

import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils';

import Enum from 'Models/Enum';
import { updateContent as updateContentHook, validateContent as validateContentHook } from './content.hook';
import {
  ContentEntity,
  ContentFromApi,
  ContentState,
  SearchContentsResponse,
  UpdateContentResponse,
} from './content.types';
import { buildQueryFilterFromFilters } from './content.utils';

export type SearchContentArgs = {
  offset: number;
  limit: number;
  includes?: string;
  sort?: { [key: string]: 'asc' | 'desc' };
  query?: {
    id: number;
  };
  filters: ContentState['filters'];
};
export const searchContents = createAsyncAction<SearchContentsResponse, SearchContentArgs>(
  'contents/search',
  (args) => {
    const queryFilters = buildQueryFilterFromFilters(args.filters);
    const { offset, limit, includes, sort } = args;
    const { fetchFn } = fetchFactory<SearchContentsResponse>({
      route: `/api/contents/search?${encodeSearchObject({
        limit,
        offset,
        sort: sort || { id: 'desc' },
        query: {
          ...queryFilters,
          includes: includes || '',
          id: args.query?.id?.toString() || '',
        },
      })}`,
      method: 'GET',
    });
    return fetchFn();
  },
);

type fetchContentByIdArgs = {
  contentId: number;
};
export const fetchContentById = createAsyncAction<ContentFromApi, fetchContentByIdArgs>(
  'contents/fetchById',
  ({ contentId }) => {
    const { fetchFn } = fetchFactory<ContentFromApi>({
      route: `/api/contents/${contentId}`,
      method: 'GET',
    });
    return fetchFn();
  },
);

type UpdateContentArgs = {
  contentId: number;
  content: DeepPartial<ContentEntity>;
};
export const updateContent = createAsyncAction<UpdateContentResponse, UpdateContentArgs>(
  'contents/update',
  async (args) => {
    return updateContentHook(args.contentId, args.content);
  },
);

type ValidateContentArgs = {
  contentId: number;
  languageCode: keyof typeof Enum.Languages;
};
export const validateContent = createAsyncAction<UpdateContentResponse, ValidateContentArgs>(
  'contents/validate',
  async (args) => {
    return validateContentHook(args.contentId, args.languageCode);
  },
);
