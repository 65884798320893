import { OnSaveVideoArgs } from 'Components/modal/MVideoUpload/MVideoUpload';
import { ImageInstance } from 'Models/Image';

export const getPickerSelectedImageShorthand = (newImage: ImageInstance) => ({
  id: newImage.id(),
  url: newImage.url(),
  cdn: newImage.cdn(),
});

export const getPickerSelectedVideoShorthand = (newVideo: OnSaveVideoArgs) => ({
  id: newVideo.video.id(),
  url: newVideo.video.url(),
  type: newVideo.video.getVideoType(),
  thumbnailUrl: newVideo.image?.url() ?? '',
});
