/* eslint-disable react/default-props-match-prop-types */
// @flow

/**
 * Display a code input
 *
 * Props:
 * - value: String to set the input value
 * - onButtonClick: Function call to trigger an action on the button
 * - onIconClick: Function call to trigger an action on the icon
 * - buttonText: String to set the button text
 * - buttonDisabled: Boolean to define if the button is disabled
 * - placeholder: String to set the input placeholder
 * - description: String to set a description in the bottom of the input
 * - linkText: String to set a link next to the input
 * - maxLengthValue: Number to set the max character number
  of the input value length
 * - onLinkClick: Function call to trigger an action on the link
 * - onInputChange: Function call to receive input value change
 * - style: can override the component style
 * - codeChangeFeedbackStatus: String to indicate what type of change feedback
 to display (loading | warning | saved | none)
 * - codeChangeFeedbackMessage: Text to display the change feedback
 */

import * as React from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';

import styles from './SCodeInput.style';


export type CodeChangeFeedbackStatus = 'loading' | 'warning' | 'saved' | 'none';
export type Props = {|

  // required
  onButtonClick: Function,
  onIconClick: Function,
  buttonText: string,
  onInputChange: (string) => mixed,

  // not required
  value: string,
  buttonDisabled: boolean,
  placeholder: string,
  description: string,
  linkText: string,
  maxLengthValue: number,
  inputGroupWidth: number,
  onLinkClick: Function,
  onBlur: Function,
  style: ?Object,
  codeChangeFeedbackStatus: CodeChangeFeedbackStatus,
  codeChangeFeedbackMessage: string,
|};
type State = {|
  isHover: boolean,
|};

// type ReadOnlyProps = $ReadOnly<Props>;

const INPUT_VALIDATION = /[^a-zA-Z0-9]+/gi;
const BACKGROUND_COLOR = COLORS.PRIMARY;
const MAX_LENGTH_VALUE = 10;
const DEFAULT_INPUT_GROUP_WIDTH = 290;

const COLOR = {
  DEFAULT: COLORS.WHITE.DEFAULT,
  DISABLED: COLORS.TEXT.DISABLED_DEFAULT,
  HOVER: COLORS.WHITE.DEFAULT,
};

// eslint-disable-next-line react/no-unsafe
export class SCodeInput extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    placeholder: '',
    buttonDisabled: false,
    description: '',
    linkText: '',
    onLinkClick: () => {},
    onBlur: () => {},
    maxLengthValue: MAX_LENGTH_VALUE,
    inputGroupWidth: DEFAULT_INPUT_GROUP_WIDTH,
    style: {},
    codeChangeFeedbackStatus: 'none',
    codeChangeFeedbackMessage: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  render() {
    const { style: propStyle, inputGroupWidth } = this.props;

    return (
      <div style={propStyle}>
        <div style={{ ...styles.wrapperT }}>
          <div
            style={{ ...styles.buttonWrapper, width: inputGroupWidth }}
          >
            <div style={{ ...styles.inputWrapper }}>
              { this.renderInput() }
              { this.renderIcon() }
            </div>
            { this.renderButton() }
          </div>
          { this.renderLink()}
        </div>
        { this.renderDescription() }
        { this.renderCodeChangeFeedback() }
      </div>
    );
  }

  renderIcon = () => {
    const { onIconClick } = this.props;

    return (
      <span
        style={{ ...styles.iconContainer }}
        onClick={onIconClick}
        data-test-id='scode-input-icon'
      >
        <UIcon
          name="refresh"
          size={14}
          color={COLORS.GREY_DARK.DEFAULT}
          style={{ ...styles.iconButton }}
        />
      </span>
    );
  };

  renderInput = () => {
    const { placeholder, maxLengthValue, onBlur } = this.props;

    let { value } = this.props;

    value = value.replace(INPUT_VALIDATION, '');

    if (value.length > maxLengthValue)
      value = value.slice(0, maxLengthValue);

    return (
      <input
        data-copy-id={`s-code-input-${value}`}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={this.handleInputValue}
        style={{ ...styles.textField }}
        className="structural-inputs"
        onBlur={onBlur}
      />
    );
  };

  renderButton = () => {
    const { buttonText, buttonDisabled } = this.props;

    const backgroundColor = this.getColorFromType(BACKGROUND_COLOR);
    const color = this.getColorFromType(COLOR);
    const style = {
      ...styles.inputButton,
      cursor: buttonDisabled ? 'default' : 'pointer',
      backgroundColor,
      color,
    };

    return (
      <div
        onClick={this.handleButtonClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        data-test-id='scode-input-button'
        style={style}
      >
        {buttonText}
      </div>
    );
  };

  renderDescription = () => {
    const { description, codeChangeFeedbackStatus } = this.props;

    if (!description)
      return null;

    return (
      <div style={{ ...styles.descriptionWrapper }}>
        <p style={codeChangeFeedbackStatus === 'none' ? { ...styles.description } : { ...styles.descriptionCaption }}>
          { description }
        </p>
      </div>
    );
  };

  renderLink = () => {
    const { linkText, onLinkClick } = this.props;

    if (!linkText)
      return null;

    return (
      <div style={styles.linkWrapper}>
        <p onClick={onLinkClick} style={{ ...styles.linkText }}>
          { linkText }
        </p>
        <UIcon
          name="info"
          size={12}
          color={COLORS.TEXT.ACCENTUATED}
          style={{ ...styles.infoIcon }}
        />
      </div>
    );
  };

  renderCodeChangeFeedbackIcon = () => {
    const { codeChangeFeedbackStatus } = this.props;

    if (codeChangeFeedbackStatus === 'loading') {
      return (
        <CircleSpinner
          size={10}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
          loading
        />
      );
    }

    if (codeChangeFeedbackStatus === 'saved') {
      return (
        <UIcon
          name='success-circle'
          size={10}
          color={COLORS.SUCCESS.DEFAULT}
        />
      );
    }

    if (codeChangeFeedbackStatus === 'warning') {
      return (
        <UIcon
          name="alert"
          size={14}
          color={COLORS.WARNING.DEFAULT}
        />
      );
    }

    return null;
  };

  renderCodeChangeFeedback = () => {
    const { codeChangeFeedbackStatus, codeChangeFeedbackMessage, description } = this.props;
    const warning = codeChangeFeedbackStatus === 'warning';

    if (codeChangeFeedbackStatus === 'none')
      return null;

    return (
      <div style={description ? { ...styles.messageWrapper, marginTop: 6 } : { ...styles.messageWrapper }}>
        { this.renderCodeChangeFeedbackIcon() }
        <p style={warning ? { ...styles.messageText, ...styles.warningText } : { ...styles.messageText }}>
          {codeChangeFeedbackMessage}
        </p>
      </div>
    );
  };

  // handles

  handleButtonClick = () => {
    const { buttonDisabled, onButtonClick, value } = this.props;

    if (!buttonDisabled)
      onButtonClick(value);
  };

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  handleInputValue = (event: Object) => {
    let value = event.target.value;
    const { onInputChange, maxLengthValue } = this.props;

    value = value.replace(INPUT_VALIDATION, '');

    if (value.length > maxLengthValue)
      value = value.slice(0, maxLengthValue);

    onInputChange(value);
  };

  // change hover button color

  getColorFromType = (colorType: Object) => {
    const { buttonDisabled } = this.props;
    const { isHover } = this.state;

    if (buttonDisabled)
      return colorType.DISABLED;
    else if (isHover)
      return colorType.HOVER;

    return colorType.DEFAULT;
  };
}

export default SCodeInput;
