import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {},
  container: {
    marginLeft: 24,
    marginRight: 24,
  },
  sCampaignInfo: {
    marginTop: 24,
    marginLeft: 24,
    marginRight: 24,
  },
  campaignHeader: {
    zIndex: 9,
  },
  stickySCampaignInfo: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  sectionContainer: {
    marginTop: 16,
  },
  sectionContainerInside: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 0,
  },
  section: {
    paddingTop: 24,
  },
  dailySessionSection: {},
  dailySessionContainer: {
    marginBottom: 8,
  },
  title: {
    ...TYPOGRAPHY.HERO_L_SEMIBOLD,
    lineHeight: '36px',
    color: COLORS.BLUE_GRAY_DARKEST.DEFAULT,
  },
  titleContainer: {
    maxWidth: 456,
    marginBottom: 16,
  },
  counterTitle: {
    ...TYPOGRAPHY.BODY1_SEMIBOLD,
  },
  counter: {
    marginLeft: 16,
    marginRight: 16,
  },
  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  contentList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: 16,
  },
});

export default styleSheet;
