import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const TEXT_AREA_HEIGHT = '115';

const styleSheet = createStyleSheet({
  wrapper: {
    flexGrow: 0,
  },
  textAreaWithHighlightWrapper: {
    position: 'relative',
    flex: 1,
  },
  textAreaWithHighlightOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  uncomparedText: {
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
  textArea: {
    lineHeight: '34px',
  },
  spacerBetweenAreas: {
    height: '24px',
  },
});

export default styleSheet;
