import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    margin: 'auto',
    width: 940,
  },
  infoWrapper: {
    paddingTop: 56,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: 8,
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  email: {
    display: 'flex',
    marginTop: 50,
    alignItems: 'center',
  },
  emailInput: {
    marginRight: 16,
    flex: 1,
  },
  audienceTable: {
    marginTop: 60,
    paddingBottom: 40,
  },
  fileHandler: {
    display: 'none',
  },
});
