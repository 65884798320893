import { t } from 'i18next';
import { memoize } from 'lodash';
import React, { CSSProperties } from 'react';

import ActivityModel from 'Models/Activity';
import AppModel from 'Models/App';
import CategoryModel from 'Models/Category';
import Enum from 'Models/Enum';

import type { Filter, FilterItem, FilterState, GenericFilterCategory } from 'Libs/filter/types';
import type { Player, SegmentationItemModel, Source } from 'Libs/ts/types';
import type { CategoryType } from 'Models/react/Category/Category';
import type { Thematic } from 'Models/react/Thematic/Thematic';
import { CONTENT_STATUS, CONTENT_STATUS_ID_MAP } from 'Store/entities/content/content.utils';
import { fetchAudienceGroupsFn } from 'Features/audience/stores/thunks/audience-group.thunks';

import SOmnibox from 'Components/structural/SOmnibox/SOmnibox';

import styles from './SFilterBox.style';
import {
  activityStatusLabel,
  activityTypeLabel,
  contentStatusLabel,
  documentStatusLabel,
  dolphinContentStatusLabel,
  getAudienceTargetModeLabel,
  translationStatusLabel,
} from './SFilterBoxLabels';

const App: any = AppModel;

type FilterType = 'compact' | 'standard';
type SelectedIds = { [id: number]: boolean };

type MakeFunction = (selectedIds: SelectedIds) => Promise<Filter> | Filter;

export type SFilterBoxProps = {
  title: string;
  description: string;
  placeholder: string;
  type: FilterType;
  count: number | null;
  filters: Array<FilterState>;
  onChange: (filterStates: FilterState[]) => unknown | void;
  showSuggestionsOnFocus: boolean;
  showFiltersOnInit: boolean;
  showLanguageInSeg: boolean;

  style?: CSSProperties;
  containerStyle?: CSSProperties;
};

type SFilterBoxState = {
  sources: Array<Source> | null;
};

export class SFilterBox extends React.PureComponent<SFilterBoxProps, SFilterBoxState> {
  dimension: Array<{ id: number; label: string }>;

  static defaultProps = {
    style: undefined,
    containerStyle: undefined,
    title: '',
    description: '',
    type: 'standard',
    showSuggestionsOnFocus: false,
    showFiltersOnInit: true,
    showLanguageInSeg: true,
  };

  constructor(props: SFilterBoxProps) {
    super(props);
    this.dimension = this.getDimensions();

    this.state = {
      sources: null,
    };
  }

  componentDidMount() {
    const { filters } = this.props;

    const handlers: Record<GenericFilterCategory, MakeFunction> = {
      segment: this.makeSegmentFilter,
      campaign: this.makeCampaignFilter,
      user: this.makeUserFilter,
      thematic: this.makeThematicFilter,
      activityType: this.makeActivityTypeFilter,
      activityStatus: this.makeActivityStatusFilter,
      contentStatus: this.makeContentStatusFilter,
      dolphinContentStatus: this.makeDolphinContentStatusFilter,
      translationStatus: this.makeTranslationStatusFilter,
      category: this.makeCategoryFilter,
      documentStatus: this.makeDocumentStatusFilter,
      language: this.makeLanguageFilter,
      audienceMode: this.makeAudienceModeFilter,
      audienceGroup: this.makeAudienceGroupFilter,
      sort: this.makeSortFilter,
    };

    const filtersPromise = filters.map((filterState) => {
      if (filterState.category === 'text') return this.makeTextFilter(filterState.values);

      const selectedIds = filterState.ids.reduce((acc, id) => ({ ...acc, [id]: true }), {});

      return handlers[filterState.category as GenericFilterCategory](selectedIds);
    });

    return Promise.all(filtersPromise).then((f) => {
      this.setState({ sources: [{ name: '', filters: f }] });
    });
  }

  render() {
    const { sources } = this.state;
    const { style, containerStyle, placeholder, filters, type, showSuggestionsOnFocus, showFiltersOnInit } = this.props;

    if (!sources) return null;

    const styleContainer = {
      ...(type === 'standard' ? styles.standardContainer : styles.compactContainer),
      ...containerStyle,
    };
    const suggestionOptions = this.getSuggestionOptions(filters, showSuggestionsOnFocus);

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <div style={styleContainer}>
          {this.renderTitle()}
          {this.renderDescription()}
          <SOmnibox
            placeholder={placeholder}
            onFilterChanged={this.handleFilterChanged}
            sources={sources}
            initShowFilters={showFiltersOnInit}
            filtersText={t('structural_component:s_filter_box.title')}
            filterMultiText={false}
            dimensions={this.dimension}
            suggestionOptions={suggestionOptions}
          />
        </div>
      </div>
    );
  }

  renderTitle = () => {
    const { title } = this.props;

    if (!title) return null;

    return <div style={styles.title}>{title}</div>;
  };

  renderDescription = () => {
    const { description } = this.props;

    if (!description) return null;

    return (
      <div style={styles.descriptionContainer}>
        {this.renderCount()}
        <div style={styles.label}>{description}</div>
      </div>
    );
  };

  renderCount = () => {
    const { count } = this.props;

    if (count === null) return null;

    return <div style={styles.count}>{count}</div>;
  };

  handleFilterChanged = (source: Source) => {
    const { onChange } = this.props;

    const result = source.filters.map(({ category, items }) => {
      if (category === 'text') {
        return {
          category,
          values: items.filter(({ selected }) => selected).map((item) => item.value),
        };
      }

      if (category === 'thematic') {
        return {
          category,
          ids: this.getSelectedThematicIds(items),
        };
      }

      return {
        category,
        ids: items.filter(({ selected }) => selected).map((item) => item.id),
      };
    });

    onChange(result);
  };

  getSuggestionOptions = memoize(
    (filters: Array<FilterState>, showSuggestionsOnFocus: boolean) => {
      return {
        enable: true,
        enableTextSuggestion: filters.some(({ category }) => category === 'text'),
        enableOnEmptyInput: false,
        showAllOnFocus: showSuggestionsOnFocus,
      };
    },
    (filters: Array<FilterState>, showSuggestionsOnFocus: boolean) => {
      const cacheKey = [...filters, showSuggestionsOnFocus];

      return JSON.stringify(cacheKey);
    },
  );

  getDimensions = (): Array<{ id: number; label: string }> => {
    return App.segmentationDimensions().items.map((dimension: any) => {
      return {
        id: dimension.id(),
        label: dimension.getLabel().label(),
      };
    });
  };

  getSelectedThematicIds = (items: Array<FilterItem>): number[] =>
    items
      .map((item) => {
        const ids = item.selected ? [item.id] : [];

        return item.children ? [...ids, ...this.getSelectedThematicIds(item.children)] : ids;
      })
      .reduce((acc, x) => [...acc, ...x], []);

  makeTextFilter = (values: Array<string>): Filter => {
    const items = values.map(
      (value, i) =>
        ({
          category: 'text',
          id: i,
          value,
          selected: true,
        } as const),
    );

    return { category: 'text', type: 'text', items };
  };

  makeSegmentFilter = (selectedIds: SelectedIds): Filter => {
    const { showLanguageInSeg } = this.props;
    const filteredSegmentation: SegmentationItemModel[] = App.userSegmentationItems().toJSON();

    let segmentations = filteredSegmentation;

    if (!showLanguageInSeg)
      segmentations = segmentations.filter((seg) => seg.group.dimensionId !== Enum.dimensionTypes.LANGUAGE);

    const items = segmentations
      .map((segItem) => segItem.group)
      .filter((x, i, a) => a.findIndex((y) => y.id === x.id) === i)
      .sort((a, b) => a.dimensionId - b.dimensionId)
      .map(
        (segGroup) =>
          ({
            category: 'segment',
            id: segGroup.id,
            value: segGroup.label,
            selected: selectedIds[segGroup.id] === true,
            color: segGroup.dimension.color,
            dimensionId: segGroup.dimensionId,
          } as const),
      );

    return { category: 'segment', type: 'multiselect', items };
  };

  makeCampaignFilter = async (selectedIds: SelectedIds): Promise<Filter> => {
    const activities: { startDate: number; multilingualId: number; name: string }[] = (
      await ActivityModel.findForInput()
    ).toJSON();

    const items = activities
      .concat()
      .sort((a, b) => b.startDate - a.startDate)
      .map((act) => {
        const selected = selectedIds[act.multilingualId] === true;
        const id = act.multilingualId;

        return {
          category: 'campaign',
          id: id,
          value: act.name,
          selected,
        } as const;
      });

    return { category: 'campaign', type: 'multiselect', items };
  };

  makeUserFilter = (selectedIds: SelectedIds): Filter => {
    const contributors: Player[] = App.contributors().toJSON();

    const items = contributors.map(
      (player) =>
        ({
          category: 'user',
          id: player.id,
          value: `${player.firstName} ${player.lastName}`,
          selected: selectedIds[player.id] === true,
        } as const),
    );

    return { category: 'user', type: 'multiselect', items };
  };

  makeThematicFilter = (selectedIds: SelectedIds): Filter => {
    const items = this.makeThematicFilterItems(null, selectedIds);

    return { category: 'thematic', type: 'select', items };
  };

  makeThematicFilterItems = (ancestorId: number | null, selectedIds: SelectedIds): Array<FilterItem> => {
    const thematics: Thematic[] = App.thematics().toJSON();

    const items = thematics
      .filter((thematic) => thematic.ancestorId === ancestorId)
      .map((thematic) => {
        const children = this.makeThematicFilterItems(thematic.id, selectedIds);

        return {
          category: 'thematic',
          id: thematic.id,
          value: thematic.name,
          selected: selectedIds[thematic.id] === true,
          children: children.length ? children : undefined,
        } as const;
      });

    return items;
  };

  makeActivityTypeFilter = (selectedIds: SelectedIds): Filter => {
    const items = [
      Enum.activityTypeEnum.DAILY_SERIE,
      Enum.activityTypeEnum.BREAKING_NEWS,
      Enum.activityTypeEnum.WELCOME,
      Enum.activityTypeEnum.THATS_ALL_FOLKS,
      Enum.activityTypeEnum.INTERSEASON,
    ].map(
      (i) =>
        ({
          category: 'activityType',
          id: i,
          value: activityTypeLabel(i),
          selected: selectedIds[i] === true,
        } as const),
    );

    return { category: 'activityType', type: 'select', items };
  };

  makeActivityStatusFilter = (selectedIds: SelectedIds): Filter => {
    const items = [
      Enum.activityStatus.UPCOMING,
      Enum.activityStatus.LIVE,
      Enum.activityStatus.FINISHED,
      Enum.activityStatus.INACTIVE,
      Enum.activityStatus.ARCHIVED,
    ].map(
      (i) =>
        ({
          category: 'activityStatus',
          id: i,
          value: activityStatusLabel(i),
          selected: selectedIds[i] === true,
        } as const),
    );

    return { category: 'activityStatus', type: 'select', items };
  };

  makeContentStatusFilter = (selectedIds: SelectedIds): Filter => {
    const items = [
      Enum.contentStatus.DRAFT,
      Enum.contentStatus.TO_VALIDATE,
      Enum.contentStatus.VALIDATED,
      Enum.contentStatus.ARCHIVED,
    ].map(
      (i) =>
        ({
          category: 'contentStatus',
          id: i,
          value: contentStatusLabel(i),
          selected: selectedIds[i] === true,
        } as const),
    );

    return { category: 'contentStatus', type: 'select', items };
  };

  makeDolphinContentStatusFilter = (selectedIds: SelectedIds): Filter => {
    const items = Object.values(CONTENT_STATUS).map(
      (status) =>
        ({
          category: 'dolphinContentStatus',
          id: CONTENT_STATUS_ID_MAP[status],
          value: dolphinContentStatusLabel(status),
          selected: selectedIds[CONTENT_STATUS_ID_MAP[status]] === true,
        } as const),
    );
    return { category: 'dolphinContentStatus', type: 'select', items };
  };

  makeTranslationStatusFilter = (selectedIds: SelectedIds): Filter => {
    const items = [Enum.translationStatus.PENDING, Enum.translationStatus.COMPLETE, Enum.translationStatus.NONE].map(
      (i) =>
        ({
          category: 'translationStatus',
          id: i,
          value: translationStatusLabel(i),
          selected: selectedIds[i] === true,
        } as const),
    );

    return { category: 'translationStatus', type: 'select', items };
  };

  makeCategoryFilter = async (selectedIds: SelectedIds): Promise<Filter> => {
    const categories: CategoryType[] = await CategoryModel.findForFilter();

    categories.push({
      id: Enum.DocumentStatus.UNCATEGORIZED,
      locked: false,
      label: t('structural_component:s_filter_box.uncategorized'),
    });

    const items = categories.concat().map(
      (cat) =>
        ({
          category: 'category',
          id: cat.id,
          value: cat.label,
          selected: selectedIds[cat.id] === true,
        } as const),
    );

    return { category: 'category', type: 'select', items };
  };

  makeDocumentStatusFilter = (selectedIds: SelectedIds): Filter => {
    const items = [Enum.DocumentStatus.AVAILABLE, Enum.DocumentStatus.UNAVAILABLE, Enum.DocumentStatus.ARCHIVED].map(
      (i) =>
        ({
          category: 'documentStatus',
          id: i,
          value: documentStatusLabel(i),
          selected: selectedIds[i] === true,
        } as const),
    );

    return { category: 'documentStatus', type: 'select', items };
  };

  makeLanguageFilter = (selectedIds: SelectedIds): Filter => {
    const languages: { label: () => string; id: () => number }[] = App.languages().items;

    const items = languages.map((lang) => {
      const langLabel = lang.label();
      const langId = lang.id();

      return {
        category: 'language',
        id: langId,
        value: langLabel,
        selected: selectedIds[langId] === true,
      } as const;
    });

    return { category: 'language', type: 'multiselect', items };
  };

  makeAudienceModeFilter = (selectedIds: SelectedIds): Filter => {
    const audienceModes: { label: () => string; id: () => number }[] = App.audienceTargetModes().items;

    const items = audienceModes.map((audienceMode) => {
      const audienceModeId = audienceMode.id();
      const audienceModeLabel = getAudienceTargetModeLabel(audienceModeId) || '';

      return {
        category: 'audienceMode',
        id: audienceModeId,
        value: audienceModeLabel,
        selected: selectedIds[audienceModeId] === true,
      } as const;
    });

    return { category: 'audienceMode', type: 'select', items };
  };

  makeAudienceGroupFilter = async (selectedIds: SelectedIds): Promise<Filter> => {
    const audienceGroups = await fetchAudienceGroupsFn({
      limit: 10000,
      offset: 0,
      title: null,
      status: 'all',
      includeMetaGroupAll: true,
    });

    const items = audienceGroups.items
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((audienceGroup) => {
        return {
          category: 'audienceGroup',
          id: audienceGroup.id,
          value: audienceGroup.name,
          selected: selectedIds[audienceGroup.id] === true,
        } as const;
      });

    return { category: 'audienceGroup', type: 'multiselect', items };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Arg is need to keep the same signature as other make*Filter methods
  makeSortFilter = (_: SelectedIds): Filter => ({ category: 'sort', type: 'select', items: [] });
}

export default SFilterBox;
