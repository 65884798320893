import { COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const style = createStyleSheet({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    height: '100%',
  },
});

export default style;
