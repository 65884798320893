import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  mask: {
    width: '100%',
    height: '100%',
    WebkitMaskSize: 'auto',
    maskSize: 'auto',
    maskRepeat: 'repeat-x',
    WebkitMaskRepeat: 'repeat-x',
    maskPosition: '4% 13%',
    WebkitMaskPosition: '4% 13%',
  },
});

export default styleSheet;
