import React, { memo, useCallback } from 'react';
import { css } from 'aphrodite';

import SAlert from 'Components/structural/SAlert/SAlert';
import Transition from 'Components/hoc/Transition/Transition';

import type { Alert } from 'Pages/AlertManager/redux';
import { useAppDispatch } from 'Libs/redux/store';

import { removeBasicAlert } from '../redux/utils';
import styles from './BasicAlertList.style';

export type BasicAlertListProps = {
  alerts: ReadonlyArray<Alert>;
};

type HandlerParam = {
  id: string;
  actionCallbacks?: {
    close?: string;
    success?: string;
    cancel?: string;
  };
};

const BasicAlertList = ({ alerts }: BasicAlertListProps) => {
  const dispatch = useAppDispatch();
  const handleClose = useCallback(
    ({ id, actionCallbacks }: HandlerParam) =>
      () => {
        if (actionCallbacks?.close) {
          dispatch({ type: actionCallbacks.close });
        }
        removeBasicAlert(id);
      },
    [dispatch],
  );

  const handleSuccess = useCallback(
    ({ id, actionCallbacks }: HandlerParam) =>
      () => {
        if (actionCallbacks?.success) {
          dispatch({ type: actionCallbacks.success });
        }
        handleClose({ id, actionCallbacks });
      },
    [handleClose, dispatch],
  );

  const handleCancel = useCallback(
    ({ id, actionCallbacks }: HandlerParam) =>
      () => {
        if (actionCallbacks?.cancel) {
          dispatch({ type: actionCallbacks.cancel });
        }

        handleClose({ id, actionCallbacks });
      },
    [handleClose, dispatch],
  );

  return (
    <div className={css(styles.listWrapper)}>
      <Transition
        entryAnimationCss={css(styles.containerAlertIn)}
        exitAnimationCss={css(styles.containerAlertOut)}
        exitTimeout={300}
      >
        {alerts.map(({ id, status, priority, text, title, icon, actionCallbacks }) => (
          <div key={id} className={css(styles.containerAlert)}>
            <SAlert
              title={title}
              priority={priority}
              text={text || ''}
              status={status || 'success'}
              icon={icon || 'success-circle'}
              onClose={handleClose({ id, actionCallbacks })}
              onSuccess={handleSuccess({ id, actionCallbacks })}
              onCancel={handleCancel({ id, actionCallbacks })}
              isCallToAction={Boolean(actionCallbacks?.success || actionCallbacks?.close)}
            />
          </div>
        ))}
      </Transition>
    </div>
  );
};

export default memo<BasicAlertListProps>(BasicAlertList);
