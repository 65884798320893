import { StyleSheet } from 'aphrodite';

import COLORS from 'Components/foundation/colors';
import { createStyleSheet } from 'Components/foundation/stylesheets';
import TYPOGRAPHY from 'Components/foundation/typography';

export const DROPDOWN_ITEM_PADDING_LEFT = 12;
export const DROPDOWN_ITEM_PADDING_STEPS = 8;

const styleSheet = createStyleSheet({
  container: {
    flex: 1,
    position: 'relative',
  },
  dropdown: {
    flex: 1,
    width: '100%',
    display: 'none',
    position: 'absolute',
    zIndex: 999,
    marginTop: 4,
    borderRadius: 4,
    border: `1px solid ${COLORS.CHIPS.DEFAULT}`,
    backgroundColor: COLORS.WHITE.DEFAULT,
    maxHeight: 300,
    overflow: 'auto',
  },
  activeDropdown: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropdownItemLabel: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.DEFAULT,
  },
  input: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 15,
    height: '100%',
  },
});

export const extendedStyles = StyleSheet.create({
  dropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: COLORS.GREY_MEDIUM.HOVER,
    },
    ':focus': {
      backgroundColor: COLORS.GREY_MEDIUM.HOVER,
    },
  },
});

export default styleSheet;
