import m from 'm';
import { t } from 'i18next';

import reactCreator from 'components/react.creator';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.isImagePopupVisible = m.prop(false);

  self.openImagePopup = function openImagePopup() {
    self.isImagePopupVisible(true);
    m.redraw();
  };

  self.closeImagePopup = function closeImagePopup() {
    self.isImagePopupVisible(false);
    m.redraw();
  };

  self.handleClick = function handleClick() {
    if (!self.isImagePopupVisible())
      self.openImagePopup();
  };

  self.handleSave = function handleSave(result) {
    args.onSave(result);
    self.closeImagePopup();
  };
};

component.view = function view(c, args) {
  var imagePopup =  m(reactCreator, {
    component: 'MImageCrop',
    props    : {
      onCancel        : c.closeImagePopup,
      onSave          : c.handleSave,
      onImageSelection: () => {},
      cropProps       : args.cropProps,
    },
  });

  if (args.disabled)
    return m('label.input__media.input__media--disabled');

  return m('label.input__media', [
    m('.input__media__button', {
      onclick: c.handleClick,
    },
    [
      m('svg[viewBox="0 0 86 62"].input__media__icon', [
        m('path[d="M68.5,0H7.5A7.49,7.49,0,0,0,0,7.5v33A7.49,7.49,0,0,0,7.5,48H58.06A14.94,14.94,0,0,1,76,34.34V7.5A7.49,7.49,0,0,0,68.5,0Zm3.82,9.09V28.91L63.07,22a1.07,1.07,0,0,0-1.14,0L50.67,30.4,29.36,14.56a0.93,0.93,0,0,0-.68-0.2,1,1,0,0,0-.41.14L3.7,29.53V9.1C3.67,6,5.38,3.48,8.95,3.46H67.16c3.57,0,5.14,2.48,5.16,5.57C72.32,9,72.32,9.07,72.32,9.09Z"]'),
        m('path[d="M51.54,7.12a5.77,5.77,0,1,0,5.69,5.78A5.77,5.77,0,0,0,51.54,7.12Z"]'),
        m('path[d="M82.19,39.81A13,13,0,1,0,86,49,13,13,0,0,0,82.19,39.81ZM81.46,50.36a2,2,0,0,1-1.52.64H75v5.06a2,2,0,1,1-4,.06s0,0,0-.06V51H65.94a2,2,0,1,1-.06-4H71V42.06A2,2,0,0,1,72.94,40H73a2,2,0,0,1,2,2.06V47h4.94A2,2,0,0,1,81.46,50.36"]'),
      ]),
      m('.input__media__label', args.imageLoaded() ? t('mithril_components:new_input_media.edit') : t('mithril_components:new_input_media.add')),
      c.isImagePopupVisible() ? imagePopup : null,
    ]),
  ]);
};

export default component;
