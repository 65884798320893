import m from 'm';
import KEY from 'keys';
import selectGameplay from 'components/popup.selectGameplay';
import leaveEditionConfirmation from 'components/popup.leaveEditionConfirmation';
import contributor from 'components/popup.contributor';
import confirm from 'components/popup.confirmation';
import info from 'components/popup.info';
import newUser from 'components/popup.newUser';
import newActivity from 'components/popup.newActivity';
import uploadVideo from 'components/popup.upload.video';
import translation from 'components/popup.askTranslation';
import resetPassword from 'components/popup.resetPassword';
import exportOrders from 'components/popup.export.orders';

var popups = {
  selectGameplay          : selectGameplay,
  leaveEditionConfirmation: leaveEditionConfirmation,
  contributor             : contributor,
  confirm                 : confirm,
  info                    : info,
  newUser        : newUser,
  newActivity    : newActivity,
  uploadVideo    : uploadVideo,
  translation    : translation,
  resetPassword  : resetPassword,
  exportOrders   : exportOrders,
};

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.popupConfig = function popupConfig(elem, isInit, ctx) {
    if (isInit)
      return;

    window.addEventListener('keyup', closeOnEscapeKey, false);

    ctx.onunload = function removeListener() {
      window.removeEventListener('keyup', closeOnEscapeKey, false);
    };
  };

  self.interceptEvent = function interceptEvent(e) {
    e.stopPropagation();
  };

  function closeOnEscapeKey(e) {
    var key = e.which;

    if (key === KEY.ESCAPE)
      args.close();
  }
};

component.view = function view(c, args) {
  return m('.popup__handler', {
    config: c.popupConfig,
  }, [
    m('.popup__overlay', {
      onclick: args.close.bind(null, args),
    }, [
      m('.popup__overlay__close', [
        m('.popup__wrapper', {
          onclick: c.interceptEvent,
          class  : args.tmpFix ? 'popup__wrapper__tmpFix' : '',
        }, [
          args.key
          ? m(popups[args.type], {
            key: args.key,
          }, args)
          : m(popups[args.type], args),
        ]),
        m('.popup__close'),
      ]),
    ]),
  ]);
};

export default component;
