import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'Pages/Homepage/Homepage.style';

import CampaignBlock from './CampaignBlock';
import KnowledgeBlock from './KnowledgeBlock';

const KNOWLEDGE_URL = 'https://support.sparted.com/hc/en-us/articles/360019613699-Game-Manual';
const CAMPAIGN_URL =
  'https://support.sparted.com/hc/en-us/articles/360015533011-The-essentials-for-creating-a-good-campaign';

const InitialState = () => {
  const { t } = useTranslation();
  return (
    <>
      <KnowledgeBlock
        title={t('home:start_here_block.knowledge_title')}
        size="100%"
        link={KNOWLEDGE_URL}
        style={styles.blockMargin}
      />
      <CampaignBlock
        title={t('home:start_here_block.campaign_title')}
        size="50%"
        status="locked"
        align="left"
        link={CAMPAIGN_URL}
      />
    </>
  );
};

export default InitialState;
