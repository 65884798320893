import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const BORDER_RADIUS_VALUE = 4;

const BORDER_RADIUS = {
  MozBorderRadius: BORDER_RADIUS_VALUE,
  WebkitBorderRadius: BORDER_RADIUS_VALUE,
  borderRadius: BORDER_RADIUS_VALUE,
};

export default createStyleSheet({
  contentWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    justifyContent: 'center',
  },
  subContentWrapper: {
    width: 620,
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  pageHeader: {
    marginTop: 82,
  },
  pageTitle: {
    ...TYPOGRAPHY.TITAN_L,
    margin: 0,
    fontWeight: 'normal',
  },
  pageSubtitle: {
    ...TYPOGRAPHY.H2,
    margin: 0,
    marginTop: 4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    fontWeight: 'normal',
  },
  categoryListWrapper: {
    ...BORDER_RADIUS,
    backgroundColor: COLORS.WHITE.DEFAULT,
    paddingTop: 24,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 40,
    marginTop: 32,
    marginBottom: 175,
  },
});
