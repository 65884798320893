import _ from 'lodash';
import { t } from 'i18next';
import Enum from 'models/Enum';
import Model from 'models/Model';

var SQCM = {
  endpoint  : 'api/SQCMs',
  attributes: {
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    sqcmChoices: {
      type : 'collection',
      Model: 'SQCMChoice',
    },
    choices: {
      type : 'collection',
      Model: 'GameItem',
    },
    answer: {
      type : 'pointer',
      Model: 'GameItem',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    imageFront: {
      type : 'pointer',
      Model: 'Image',
    },
    imageBack: {
      type : 'pointer',
      Model: 'Image',
    },
    videoFront: {
      type : 'pointer',
      Model: 'Video',
    },
    videoBack: {
      type : 'pointer',
      Model: 'Video',
    },
    mediaTypeFrontId: {
      type: 'number',
    },
    mediaTypeBackId: {
      type: 'number',
    },
  },

  methods: {
    isEqualTo: function isEqualTo(_SQCM, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },
    mediaFront: function mediaFront(_SQCM, media) {
      if (!media)
        return this.getMedia('Front');

      return this.setMedia('Front', media);
    },

    mediaBack: function mediaBack(_SQCM, media) {
      if (!media)
        return this.getMedia('Back');

      return this.setMedia('Back', media);
    },

    getMedia: function getMedia(_SQCM, panel) {
      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel]();

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel]();
    },

    setMedia: function setMedia(_SQCM, panel, media) {
      var mediaType = media._type;

      this['mediaType' + panel + 'Id'](Enum.mediaType[mediaType]);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel](media);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel](media);
    },

    validate: function validate() {
      if (this.sqcmChoices().length !== 3)
        throw new Error(t('gameplays:validation.should_be_3_choices'));

      for (var i = 0, l = this.sqcmChoices().length; i < l; i++) {
        if (!this.sqcmChoices().at(i).gameItem().data())
          throw new Error(t('gameplays:validation.answer_empty', {index: i + 1}));
      }

      var rightAnswers = this.sqcmChoices().filter(function filterRightAnswers(choice) {
        return choice.rightAnswer();
      });

      if (rightAnswers.length < 1)
        throw new Error(t('gameplays:validation.only_one_correct'));

      if (!this.explanation().data())
        throw new Error(t('gameplays:validation.explanation_missing'));
      if (!this.question().data())
        throw new Error(t('gameplays:validation.question_missing'));
      if (!this.mediaFront() || !this.mediaFront().url())
        throw new Error(t('gameplays:validation.first_media_missing'));
      if (!this.mediaBack() || !this.mediaBack().url())
        throw new Error(t('gameplays:validation.second_media_missing'));

      return true;
    },

    countUseOfGameItem: function countUseOfGameItem(_SQCM, giId) {
      var count = 0;

      count += countInCollection(this.sqcmChoices());
      if (this.question().id() === giId)
        count += 1;
      if (this.explanation().id() === giId)
        count += 1;

      return count;

      function countInCollection(collection) {
        var iteration = collection.filter(function checkId(item) {
          return item.id() === giId;
        });

        return iteration.length;
      }
    },

    deleteGameItem: function deleteGameItem(_SQCM, giId) {
      var GameItem = Model('GameItem');

      deleteInCollection(this.sqcmChoices());

      if (this.question().id() === giId)
        this.question(new GameItem());
      if (this.explanation().id() === giId)
        this.question(new GameItem());

      function deleteInCollection(collection) {
        collection.searchAndRemove({
          id: giId,
        });
      }
    },

    presetFromKnowledge: function presetFromKnowledge(_SQCM, knowledge) {
      var qcm;
      var openQuestion;

      this.explanation().data(knowledge.explanation().data());
      qcm = knowledge.getContent(Enum.gameplay.QCM);

      if (qcm && qcm.getNbrightAnswer() <= 1)
        return this.presetFromQCM(qcm, knowledge);

      openQuestion = knowledge.getContent(Enum.gameplay.OPEN_QUESTION);

      if (openQuestion)
        return this.presetFromOpenQuestion(openQuestion, knowledge);

      if (!this.explanation().data())
        this.explanation().data(knowledge.explanationText());

      return null;
    },
    presetFromQCM: function presetFromQCM(_SQCM, qcm, knowledge) {
      var rightAnswerIsSet = false;

      this.question().data(qcm.question().data());
      this.sqcmChoices().forEach(function presetIfNeeded(choice, key) {
        var qcmChoice = qcm.qcmChoices().at(key);

        if (!qcmChoice)
          return;
        if (key === 2 && !rightAnswerIsSet && !qcmChoice.rightAnswer())
          qcmChoice = qcm.qcmChoices().at(key + 1);
        if (qcmChoice) {
          choice.gameItem().data(qcmChoice.gameItem().data());
          choice.rightAnswer(qcmChoice.rightAnswer());
          if (qcmChoice.rightAnswer())
            rightAnswerIsSet = true;
        }
      });

      this.explanation().data(qcm.explanation().data());

      this.mediaTypeFrontId(qcm.mediaTypeFrontId());
      this.mediaTypeBackId(qcm.mediaTypeBackId());
      this.imageFront(qcm.imageFront());
      this.imageBack(qcm.imageBack());
      this.videoFront(qcm.videoFront());
      this.videoBack(qcm.videoBack());
    },
    presetFromOpenQuestion: function presetFromOpenQuestion(_SQCM, openQuestion, knowledge) {
      this.question().data(openQuestion.question().data());
      if (this.sqcmChoices().at(0)) {
        this.sqcmChoices().at(0).gameItem().data(openQuestion.answer().data());
        this.sqcmChoices().at(0).rightAnswer(true);
      }

      this.explanation().data(openQuestion.explanation().data());
      this.mediaTypeFrontId(openQuestion.mediaTypeFrontId());
      this.mediaTypeBackId(openQuestion.mediaTypeBackId());
      this.imageFront(openQuestion.imageFront());
      this.imageBack(openQuestion.imageBack());
      this.videoFront(openQuestion.videoFront());
      this.videoBack(openQuestion.videoBack());
    },
    getDirective: function getDirective() {
      var self = this;

      if (!this.mediaFront() || !this.mediaFront().url())
        return t('gameplays:directives.add_media');
      if (!this.mediaBack() || !this.mediaBack().url())
        return t('gameplays:directives.add_media_explanation');
      if (!self.question().data())
        return t('gameplays:directives.add_question');
      if (allAnswersCompleted() !== self.sqcmChoices().length)
        return t('gameplays:directives.fill_answer_options');
      if (!countAnswers())
        return t('gameplays:directives.one_correct_answer')
      if (!self.explanation().data())
        return t('gameplays:directives.enter_explanation')

      return '';

      function allAnswersCompleted() {
        return self.sqcmChoices().filter(function isComplete(choice) {
          return choice.gameItem().data();
        }).length;
      }

      function countAnswers() {
        return self.sqcmChoices().filter(function isComplete(choice) {
          return choice.rightAnswer();
        }).length;
      }
    },
  },

  classMethods: {
    initGameplay: function initGameplay(_SQCM, knowledgeId) {
      return {
        question: {
          typeId     : Enum.gameItemTypes.QUESTION,
          knowledgeId: knowledgeId,
        },
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
};

export default Model.register('SQCM', SQCM);
