import React from 'react';

import { COLORS } from 'Components/foundation';
import type { IllustrationSVGType } from 'Components/foundation/illustrations';

const CourseIllustration = ({ color, width, height }: IllustrationSVGType) => (
  <svg width={width} height={height} viewBox="0 0 67 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Card to the right */}
    <path
      d="M31.4115 16.5035L19.3781 58.3477C19.0436 59.5107 19.7106 60.726 20.8678 61.0621L49.8196 69.4714C50.9769 69.8075 52.1861 69.1372 52.5206 67.9741L64.554 26.13C64.8885 24.9669 64.2215 23.7516 63.0643 23.4155L34.1125 15.0063C32.9552 14.6701 31.746 15.3405 31.4115 16.5035Z"
      fill={color.DEFAULT}
      stroke={color.DEFAULT}
      strokeWidth="3.33333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25818 52.0404L9.85384 54.3217L8.60641 55.7553L6.01074 53.474L7.25818 52.0404Z"
      fill={color.DEFAULT}
    />
    <path
      d="M37.2119 3.7593L6.77621 6.36545C5.03827 6.51426 3.74943 8.05082 3.89751 9.79743L7.62686 53.7862C7.77494 55.5329 9.30386 56.8281 11.0418 56.6793L41.4774 54.0732C43.2154 53.9244 44.5042 52.3878 44.3561 50.6412L40.6268 6.65237C40.4787 4.90575 38.9498 3.61048 37.2119 3.7593Z"
      fill={color.DEFAULT}
    />
    {/* Card to the left */}
    <path
      d="M34.5027 2.17827L4.06703 4.78442C2.85047 4.88859 1.94829 5.96418 2.05194 7.18681L5.78129 51.1756C5.88495 52.3983 6.95519 53.3049 8.17175 53.2008L38.6074 50.5946C39.824 50.4905 40.7261 49.4149 40.6225 48.1922L36.8931 4.20342C36.7895 2.98079 35.7192 2.0741 34.5027 2.17827Z"
      fill={color.MEDIUM}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14753 5.73318C3.45236 5.79271 2.93682 6.40733 2.99605 7.10597L6.72541 51.0948C6.78464 51.7934 7.3962 52.3115 8.09138 52.252L38.527 49.6459C39.2222 49.5863 39.7377 48.9717 39.6785 48.2731L35.9492 4.28426C35.8899 3.58561 35.2784 3.0675 34.5832 3.12703L4.14753 5.73318ZM1.10796 7.26765C0.959884 5.52103 2.24872 3.98448 3.98666 3.83566L34.4223 1.22951C36.1602 1.0807 37.6892 2.37597 37.8372 4.12259L41.5666 48.1114C41.7147 49.858 40.4258 51.3946 38.6879 51.5434L8.25225 54.1495C6.51431 54.2983 4.98539 53.0031 4.83731 51.2565L1.10796 7.26765Z"
      fill={color.DEFAULT}
    />
    <path
      d="M24.725 17.4565L23.5308 3.2033L31.8803 2.48915L33.0942 16.7423L28.3273 14.2726L24.725 17.4565Z"
      fill={COLORS.WHITE.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8036 1.57843C32.304 1.53564 32.7442 1.90846 32.787 2.41129L34.0009 16.6645C34.0292 16.996 33.8759 17.3168 33.6008 17.502C33.3257 17.6871 32.9722 17.7074 32.6779 17.5549L28.4625 15.3709L25.3261 18.143C25.0681 18.371 24.7044 18.4335 24.3857 18.3046C24.0671 18.1756 23.8479 17.8773 23.819 17.5333L22.6248 3.2801C22.5827 2.77735 22.9539 2.33537 23.4541 2.29258L31.8036 1.57843ZM24.5138 4.03677L25.4803 15.5721L27.7271 13.5863C28.0081 13.3379 28.4119 13.2878 28.7445 13.4602L32.048 15.1717L31.052 3.47754L24.5138 4.03677Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9787 53.916L6.78418 4.44148L8.59675 4.28627L12.7913 53.7608L10.9787 53.916Z"
      fill={color.DEFAULT}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6854 1.94606L39.7746 4.79273L38.4939 6.19638L35.4048 3.34971L36.6854 1.94606Z"
      fill={color.DEFAULT}
    />
  </svg>
);

export default CourseIllustration;
