// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    height: 52,
    cursor: 'pointer',
    textAlign: 'center',
    ...TYPOGRAPHY.BODY2,
    borderRadius: 8,
    paddingTop: 15,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  disabled: {
    pointerEvents: 'none',
    color: COLORS.TEXT.DISABLED_DEFAULT,
    borderColor: COLORS.BLACK.DISABLED,
  },
  enabled: {
    pointerEvents: 'auto',
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    borderColor: COLORS.BLACK.DEFAULT,
  },
  hoverStyle: {
    paddingTop: 14,
    borderWidth: 2,
    color: COLORS.TEXT.DEFAULT,
    borderColor: COLORS.BLACK.DEFAULT,
  },
};
