import React, { useCallback } from 'react';

import { SContentCard } from 'Components/structural/SContentCard/SContentCard';
import { redirect } from 'Libs/routing';
import { getTranslatedLabel } from 'Services/language/languageService';
import { ContentEntity } from 'Store/entities/content/content.types';

import styles from './ContentList.style';
import { formatTranslationStatuses } from './utils';

export const ContentItem = ({ content }: { content: ContentEntity }) => {
  const handleContentClick = useCallback(
    (e) => {
      if (e && e.type === 'click') {
        e.preventDefault();
        redirect(`/content/${content.id}`);
      }
    },
    [content],
  );

  return (
    <a style={styles.contentItemWrapper} onClick={handleContentClick} data-testid="HREF_KNOWLEDGE_CARD">
      <SContentCard
        id={`#${content.identifier || content.id}`}
        previewable={false}
        selectable={false}
        displayOption={false}
        title={getTranslatedLabel(content.name) || ''}
        type={content.game?.type}
        translationStatuses={formatTranslationStatuses(content.statuses)}
        style={styles.contentItem}
        imageUrl={content?.coverImage?.url || ''}
        canShowOverlay={true}
      />
    </a>
  );
};
