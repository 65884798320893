import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  icon: {
    display: 'inline-block',
    position: 'relative',
    top: 1,
    marginRight: 8,
  },
  success: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.SUCCESS.DARKER,
    display: 'inline-block',
    position: 'relative',
    bottom: 2,
  },
  evenly: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.GREY_DARKER.DEFAULT,
    display: 'inline-block',
    position: 'relative',
    bottom: 2,
  },
  warning: {
    ...TYPOGRAPHY.BODY2,
    color: COLORS.WARNING.DARK,
    display: 'inline-block',
    position: 'relative',
    bottom: 2,
  },
});

export default styleSheet;
