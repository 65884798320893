import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY } from 'Components/foundation';

const styleSheet = createStyleSheet({
  innerContainer: {
    marginLeft: '24px',
    marginRight: '20px',
  },
  explanation: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: '16px',
  },
  callout: {
    marginBottom: '8px',
  },
  courseListBackButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '609px',
    marginBottom: 16,
  },
  courseListModalContainer: {
    marginBottom: 32,
    marginLeft: 20,
    marginRight: 20,
  },
  courseListContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoFlow: 'row',
    gridGap: 16,
  },
  buttonAndDisclaimerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '609px',
    marginBottom: 24,
  },
  backButtonStyle: {
    paddingLeft: '12px',
    paddingRight: '12px',
    marginRight: '16px',
  },
  container: {
    marginBottom: 32,
    marginLeft: 20,
    marginRight: 20,
  },
  disclaimer: {
    ...TYPOGRAPHY.BODY3,
  },
  modalForm: {
    margin: 0,
    width: 'fit-content',
    height: 508,
  },
  modalCourseList: {
    margin: 0,
    width: 'fit-content',
  },
});

export default styleSheet;
