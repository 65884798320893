import { COLORS, TYPOGRAPHY, ELEVATIONS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    padding: '14px 45px',
    borderRadius: 4,
    backgroundColor: COLORS.WHITE.DEFAULT,
    position: 'relative',
    width: 336,
    boxShadow: ELEVATIONS.LOW,
  },
  title: {
    ...TYPOGRAPHY.H2,
    color: 'inherit',
    fontWeight: 700,
    marginBottom: 8,
    marginLeft: '-1px',
  },
  textLow: {
    color: 'inherit',
  },
  textMedium: {
    color: COLORS.BLACK.DEFAULT,
    marginLeft: '-29px',
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: '-29px',
    marginTop: '12px',
  },
  cancelButton: {
    color: COLORS.TEXT.DEFAULT,
    backgroundColor: 'transparent',
    border: '1px solid #525659',
  },
  icon: {
    position: 'absolute',
    top: 13,
    left: 16,
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    padding: 6,
    top: 13,
    right: 10,
  },
  onError: {
    color: 'white',
    backgroundColor: COLORS.ERROR.DARK,
    marginRight: '10px',
  },
  onSuccess: {
    color: 'white',
    backgroundColor: COLORS.SUCCESS.DARK,
    marginRight: '10px',
  },
  onWarning: {
    color: 'white',
    backgroundColor: COLORS.WARNING.DEFAULT,
    marginRight: '10px',
  },
});

export default styleSheet;
