import React, { useState, useCallback } from 'react';

import Promise from 'bluebird';

import { tracking } from 'Services/trackingService';

import UButton from 'Components/unit/UButton/UButton';
import SPasswordInput from 'Components/structural/SPasswordInput/SPasswordInput';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';

import { actions } from '../../redux';

import styles from './UpdatePasswordForm.style';

type Props = {
  token: string;
  instanceName: string;
};

export const UpdatePasswordForm = ({ token, instanceName }: Props) => {
  const dispatch = useTypedDispatch();

  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const isLoading = useTypedSelector((state) => state.pages.updatePassword.loading);
  const error = useTypedSelector((state) => state.pages.updatePassword.error);

  const onPasswordChange = useCallback((_password) => setPassword(_password), []);
  const onPasswordChecksCompleted = useCallback(
    (isCompleted) => setIsButtonDisabled(!isCompleted),
    [setIsButtonDisabled],
  );

  const onSubmit = useCallback(
    () =>
      Promise.resolve(dispatch(actions.updatePassword(token, password))).then((payloadError) => {
        // @ts-ignore Need to check behavior before using payload.error
        if (!payloadError.error) {
          tracking.contributorsUpdatePassword();
          window.location.href = '/';
        }

        return Promise.resolve();
      }),
    [dispatch, token, password],
  );

  const title = 'New password';
  const instruction = 'Choose a new secure password';

  return (
    <div style={styles.wrapper}>
      <div style={styles.instanceName}>{instanceName}</div>
      <div style={styles.title}>{title}</div>
      <div style={styles.instruction}>{instruction}</div>
      <div style={styles.passwordInputWrapper}>
        <SPasswordInput
          value={password}
          autofocus
          placeholder="New Password"
          requireMinCharacters={8}
          requireMinNumber={1}
          requireMinUppercase={1}
          requireMinSpecialCharacters={1}
          onSubmit={onSubmit}
          onChange={onPasswordChange}
          onChecksValidationChange={onPasswordChecksCompleted}
          // It's the safest way to force user to use new password (MDN doc: https://tinyurl.com/4bvu2dte)
          autoComplete="new-password"
        />
      </div>
      <div style={styles.buttonWrapper}>
        <UButton
          type="accentuated"
          text="Confirm new password and log in"
          onClick={onSubmit}
          loading={isLoading}
          disabled={isButtonDisabled}
        />
      </div>
      <div style={styles.errorWrapper}>
        <div style={styles.error}>{error}</div>
      </div>
    </div>
  );
};
