import { createStyleSheet } from 'Components/foundation/stylesheets';
import { TYPOGRAPHY } from 'Components/foundation';

export default createStyleSheet({
  wrapper: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  checkbox: {
    marginRight: 6,
  },
  text: {
    ...TYPOGRAPHY.BODY3,
  },
});
