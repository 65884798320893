// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY } from 'Components/foundation';

export default {
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 32,
    ...TYPOGRAPHY.BODY3,
  },
};
