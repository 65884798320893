import React, { useMemo } from 'react';

import styles from './SPDFBlocksSelector.style';
import { PdfBlockForCourse, PdfPageForCourse } from 'Libs/pdf-extraction/pdf-to-course';

type PDFPageProps = {
  page: PdfPageForCourse;
  handleBlockClick: (block: PdfBlockForCourse) => void;
};

export const PDFPage = ({ page, handleBlockClick }: PDFPageProps) => (
  <div style={{ position: 'relative', width: '100%' }} key={page.pageNumber}>
    <img src={page.previewImage} alt="page" width="100%" height="100%" />
    {page.blocks.map((block) => {
      const { containerWidth, containerHeight } = useMemo(
        () => ({
          containerWidth: page.width,
          containerHeight: page.height,
        }),
        [page],
      );

      const { leftPercentage, bottomPercentage, widthPercentage, heightPercentage } = useMemo(
        () => ({
          leftPercentage: (block.bounds[0] / containerWidth) * 100,
          bottomPercentage: (block.bounds[1] / containerHeight) * 100,
          widthPercentage: ((block.bounds[2] - block.bounds[0]) / containerWidth) * 100,
          heightPercentage: ((block.bounds[3] - block.bounds[1]) / containerHeight) * 100,
        }),
        [block],
      );

      return (
        <div
          key={block.id}
          style={{
            left: `${leftPercentage}%`,
            bottom: `${bottomPercentage}%`,
            width: `${widthPercentage}%`,
            height: `${heightPercentage}%`,
            ...styles.block,
          }}
          onClick={() => handleBlockClick(block)}
          data-test-id={block.id}
        />
      );
    })}
  </div>
);
