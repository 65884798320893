import { PdfBlockForCourse } from 'Libs/pdf-extraction/pdf-to-course';

import { MicroKnowledgeItem } from './mk-mapped-linked-list';
import { saveImageFromUrl } from 'Libs/imageLibs/saveImage';

export const transformBlockToMicroKnowledge = async (
  block: PdfBlockForCourse,
): Promise<Partial<MicroKnowledgeItem | null>> => {
  if (block.type === 'image') {
    const res = await saveImageFromUrl(block.content);

    return {
      type: block.type,
      image: res.result
        ? {
            id: res.result.id,
            url: res.result.url,
          }
        : undefined,
    };
  }

  if (block.type === 'text') {
    return {
      type: block.type,
      content: block.content,
    };
  }

  return null;
};
