import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  filterSelect: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
});

export default styleSheet;
