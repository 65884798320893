import React, { useMemo } from 'react';
import type { FunctionComponent, ImgHTMLAttributes } from 'react';

import type { Transformation, Cdn } from '@sparted/shared-library/cdn';

import { twicpics } from 'Libs/cdn';

export type UImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  transformation?: Transformation;
};

const S3_URL_REGEX = /https:\/\/.*\.s3(\.eu-west-.?)?\.amazonaws\.com\/.*/u;

const isFromS3 = (source?: string) => {
  if (!source) return false;

  return S3_URL_REGEX.test(source);
};

const initializeSource = (cdn: Cdn, source?: string, transformation?: Transformation) => {
  if (!source) return undefined;

  const isS3Url = isFromS3(source);

  if (!isS3Url) {
    return source;
  }

  return cdn.getCdnFromS3Url(source, {
    transformation: transformation || {},
  });
};

export const UImage: FunctionComponent<UImageProps> = ({ src, transformation, onError = () => {}, ...imageProps }) => {
  const imageSource = useMemo(() => initializeSource(twicpics, src, transformation), [src, transformation]);

  if (!imageSource) {
    return null;
  }

  return <img {...imageProps} alt={imageProps.alt} src={imageSource} onError={onError} />;
};

export default UImage;
