import { TYPOGRAPHY, COLORS } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    width: 540,
    minHeight: 400,
    paddingBottom: 120,
    margin: 'auto',
  },
  titlesWrapper: {
    paddingTop: 60,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
  },
  subtitle: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  audienceOptionList: {
    marginTop: 32,
  },
  block: {
    marginBottom: 16,
  },
  lastBlock: {
    marginBottom: 0,
  },
});
