// @flow

import React, { useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';

import App from 'Models/App';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/flowTypes';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';
import UToggle from 'Components/unit/UToggle/UToggle';
import SInput from 'Components/structural/SInput/SInput';
import STooltip from 'Components/structural/STooltip/STooltip';
import SSelect from 'Components/structural/SSelect/SSelect';
import SDivider from 'Components/structural/SDivider/SDivider';
import SFilterBox from 'Components/structural/SFilterBox/SFilterBox';

import { DocumentModalFormArchive } from '../../components/DocumentModalFormArchive/DocumentModalFormArchive';
import { actions } from '../../../../redux';
import styles from './DocumentModalForm.style';

const getSegmentations = () => {
  const filteredSegmentation: Array<any> = App.userSegmentationItems().toJSON();
  const groups = filteredSegmentation
    .map(segItem => segItem.group);

  return groups.reduce((map, group) => {
    map[group.id] = {
      id: (group.id: number),
      label: (group.label: string),
      dimension: {
        id: (group.dimension.id: number),
        color: (group.dimension.color: string),
        typeId: (group.dimension.typeId: number),
      },
    };

    return map;
  }, {});
};

const DocumentModalFormSegmentationNotice = () => {
  const segmentation = useTypedSelector(state => state.pages.documentList.documentModal?.segmentation);

  if (segmentation && segmentation.length > 0)
    return null;

  return (
    <div style={styles.notice}>
      {t('documents:document_modal_form.notice')}
    </div>
  );
};

const DocumentModalFormLabel = ({ label, tooltip }: Object) => {
  const TOOLTIP_ID = `tooltip_${label}`;

  return (
    <div style={styles.label}>
      {label}

      <div
        style={styles.tooltip}
        data-for={TOOLTIP_ID}
        data-tip
      >
        <UIcon
          name='info'
          size={12}
          color={COLORS.TEXT.SECONDARY_DEFAULT}
        />
        <STooltip
          id={TOOLTIP_ID}
          position='top'
          content={tooltip}
        />
      </div>
    </div>
  );
};

export const DocumentModalForm = () => {
  const dispatch = useTypedDispatch();
  const appSegmentations = getSegmentations();

  const categories = useTypedSelector(state => state.pages.documentList.categories);
  const title = useTypedSelector(state => state.pages.documentList.documentModal?.title);
  const available = useTypedSelector(state => state.pages.documentList.documentModal?.available);
  const segmentation = useTypedSelector(state => state.pages.documentList.documentModal?.segmentation);
  const category = useTypedSelector(state => state.pages.documentList.documentModal?.category);
  const archived = useTypedSelector(state => state.pages.documentList.documentModal?.archived);

  const [selectedCategory, setSelectedCategory] = useState((category) ? {
    id: category.id,
    label: category.label,
    value: category.label,
  }: null);

  const filters: Array<FilterState> = useMemo(() => {
    let currentSegmentation = [];

    if (segmentation)
      currentSegmentation = segmentation.map((seg) => seg.id);

    return [
      { category: 'segment', ids: currentSegmentation },
    ];
  }, [segmentation]);

  const handleUpdateTitle = useCallback((newTitle: string) => {
    dispatch(actions.setDocumentModalTitle(newTitle));
  }, [dispatch]);

  const handleUpdateAvailability = useCallback((newAvailability: boolean) => {
    dispatch(actions.setDocumentModalAvailability(newAvailability));
  }, [dispatch]);

  const handleSelectedCategory = useCallback((currentCategory: Object) => {
    setSelectedCategory({
      id: currentCategory.id,
      label: currentCategory.value,
      value: currentCategory.value,
    });

    if (currentCategory.id === -1)
      return dispatch(actions.addCategory(currentCategory.value));

    return dispatch(actions.setDocumentModalCategory(currentCategory.id));
  }, [dispatch]);

  const handleUpdateSegmentation = useCallback((selectedFilters: Array<FilterState>) => {
    const filter = selectedFilters.filter(selFilter => selFilter.category === 'segment')[0];

    // $FlowFixMe
    const ids = filter.ids;
    const newSegmentation = ids.map(id => appSegmentations[id]);

    dispatch(actions.setDocumentModalSegmentation(newSegmentation));
  }, [dispatch, appSegmentations]);

  const formatCategories = () => {
    return categories.map((cat) => {
      return {
        id: cat.id,
        label: cat.label,
        value: cat.label,
      };
    });
  };

  return (
    <div style={styles.form}>
      <div style={styles.formGroup}>
        <DocumentModalFormLabel
          label={t('documents:document_modal_form.document_label')}
          tooltip={t('documents:document_modal_form.document_tooltip')}
        />

        <SInput
          type="small"
          placeholder={t('documents:document_modal_form.document_placeholder')}
          maxLength={50}
          value={title}
          onChange={handleUpdateTitle}
        />
      </div>

      <div style={styles.formGroup}>
        <DocumentModalFormLabel
          label={t('documents:document_modal_form.category_label')}
          tooltip={t('documents:document_modal_form.category_tooltip')}
        />

        <SSelect
          style={styles.categorySelect}
          type="small"
          items={formatCategories()}
          selected={selectedCategory}
          onSelect={handleSelectedCategory}
          placeholder={t('documents:document_modal_form.category_placeholder')}
          canSearch
        />
      </div>

      <div style={styles.formGroup}>
        <DocumentModalFormLabel
          label={t('documents:document_modal_form.audience_label')}
          tooltip={t('documents:document_modal_form.audience_tooltip')}
        />

        <SFilterBox
          style={styles.segmentationWrapper}
          containerStyle={styles.segmentationInput}
          filters={filters}
          showFiltersOnInit={false}
          placeholder={t('documents:document_modal_form.audience_placeholder')}
          onChange={handleUpdateSegmentation}
          count={null}
          type="compact"
        />

        <DocumentModalFormSegmentationNotice />
      </div>

      <SDivider />

      <div style={styles.toggle}>
        {t('documents:document_modal_form.toggle')}
        <UToggle
          isDisabled={archived}
          toggled={available || false}
          style={{ marginLeft: 24 }}
          onChange={handleUpdateAvailability}
        />
      </div>

      <DocumentModalFormArchive />
    </div>
  );
};
