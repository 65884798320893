// @flow

/**
 * Display a tiny input
 *
 * Props:
 *  - style: can override the component style
 *  - value: the default input value
 *  - width: width of the input
 *  - maxLength: max characters enable in input
 *  - onChange: call when input value is changed
 *  - onBlur: call when input is blured
 */

import * as React from 'react';

import styles from './UTinyInput.style';

import UIcon from 'Components/unit/UIcon/UIcon';
import { COLORS } from 'Components/foundation';


type Props = {|
  style?: ?Object,
  value: string,
  width: number,
  maxLength?: number,
  onChange: Function,
  onBlur?: Function,
|};

type State = {|
  value: string,
  isHovered: boolean,
  isFocused: boolean,
|};

export class UTinyInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    style: undefined,
    maxLength: 0,
    onBlur: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
      isHovered: false,
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;

    if (value !== prevProps.value)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value });
  }

  render() {
    const { style, width, maxLength } = this.props;
    const { value, isHovered, isFocused } = this.state;
    const additionalProp = maxLength ? { maxLength } : {};
    const borderBottom = isHovered || isFocused ? styles.inputBorderBottom : styles.inputTransparentBorderBottom;

    return (
      <div style={{ ...styles.wrapper, ...style }}>
        <input
          {...additionalProp}
          type="text"
          value={value}
          style={{ ...styles.input, ...borderBottom, width }}
          onChange={this.handleInputValue}
          onClick={this.handleInputSelect}
          onBlur={this.handleBlured}
          onFocus={this.handleFocus}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleLeave}
        />
        {this.renderPen()}
      </div>
    );
  }

  renderPen = () => {
    const { isHovered } = this.state;

    if (!isHovered)
      return null;

    return (
      <UIcon
        name='edit-sml'
        color={COLORS.TEXT.SECONDARY_DEFAULT}
        size={12}
      />
    );
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlured = () => {
    const { onBlur } = this.props;
    const { value } = this.state;

    this.setState({ isFocused: false });

    if (onBlur)
      onBlur(value);
  };

  handleHover = () => {
    this.setState({ isHovered: true });
  };

  handleLeave = () => {
    this.setState({ isHovered: false });
  };

  handleInputSelect = (e: Class<Object>) => {
    e.target.select();
  };

  handleInputValue = (event: Object) => {
    const { onChange } = this.props;
    const value = event.target.value;

    onChange(value);
    this.setState({ value });
  };
}

export default UTinyInput;
