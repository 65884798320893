// @flow

/* eslint-disable key-spacing */

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    marginBottom: 35,
    display: 'flex',
  },
  textContainer: {
    marginTop: 25,
    flexGrow: 1,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
    marginBottom: 6,
  },
  comment: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    whiteSpace: 'pre-wrap',
  },
  imageText: {
    ...TYPOGRAPHY.BODY4,
    paddingBottom: 10,
  },
  asterisk: {
    color: '#FD4E5A',
  },
  image: {
    width: 140,
  },
};
