import { createStyleSheet } from 'Components/foundation/stylesheets';

import { COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  wrapper: {
    height: '100%',
    width: '100%',
    paddingBottom: 72,
  },
  container: {
    position: 'fixed',
    top: 96,
    left: 0,
    display: 'flex',
    gap: 24,
    height: '90vh',
    width: '100%',
  },
  mkWrapper: {
    paddingTop: 32,
  },
  mkWrapperWithPdf: {
    width: '65%',
    height: 'auto',
    paddingBottom: 24,
    backgroundColor: COLORS.PRIMARY.LIGHT,
    borderStyle: 'solid',
    borderRadius: '8px',
    borderColor: COLORS.PRIMARY.DEFAULT,
    borderWidth: '1px',
    marginRight: 24,
    marginBottom: 24,
    marginTop: 32,
  },
  mkListWithPdf: {
    paddingRight: 24,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  header: {
    marginLeft: 24,
  },
  loader: {
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
});

export default styleSheet;
