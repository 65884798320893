import { COLORS, TYPOGRAPHY } from 'Components/foundation';

import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    width: 300,
  },
  title: {
    ...TYPOGRAPHY.H1,
    marginBottom: 32,
  },
  text: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 24,
  },
});
