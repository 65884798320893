import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { USecondaryTabLabelProps } from 'Components/unit/USecondaryTabLabel/USecondaryTabLabel';

export type AudienceGroupStatus = 'all' | 'selected' | 'unselected';

export const useAudienceGroupListFiltersConfig = () => {
  const [status, setStatus] = useState<AudienceGroupStatus>('all');
  const [title, setTitle] = useState<string | null>(null);

  const { t } = useTranslation();

  const displayControlTabs = useMemo(
    (): USecondaryTabLabelProps[] => [
      {
        text: t('audiences:audience_list.filters.all'),
        onClick: () => setStatus('all'),
        active: status === 'all',
        disabled: false,
      },
      {
        text: t('audiences:audience_list.filters.selected'),
        onClick: () => setStatus('selected'),
        active: status === 'selected',
        disabled: false,
      },
      {
        text: t('audiences:audience_list.filters.unselected'),
        onClick: () => setStatus('unselected'),
        active: status === 'unselected',
        disabled: false,
      },
    ],
    [status],
  );

  return {
    status,
    title,
    displayControlTabs,
    setStatus,
    setTitle,
  };
};
