// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    flex: 1,
  },
  imageContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
    height: 140,
    width: 140,
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0 8px',
  },
  imagePlaceholder: {
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    height: 140,
    width: 140,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconPlaceholderWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  uploadButtonWrapper: {
    flexShrink: 1,
    marginBottom: 16,
  },
  iconButtonWrapper: {
    position: 'absolute',
    right: -9,
    top: -9,
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.GREY_LIGHT.DEFAULT,
    opacity: '60%',
  },
  overlaySubtext: {
    ...TYPOGRAPHY.BODY4,
    fontWeight: 400,
    opacity: 0.8,
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    padding: '0 8px',
  },
  overlayContent: {
    ...TYPOGRAPHY.BODY3,
    fontWeight: 700,
    opacity: 0.8,
  },
};
