import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import SPhoneInput from 'Components/structural/SPhoneInput/SPhoneInput';
import { useTypedDispatch } from 'Libs/redux/utils';

import type { ValueChangeFeedbackStatus } from 'Components/structural/SPhoneInput/SPhoneInput';

import { actions, useModuleSelector } from '../../../redux';

import styles from './ContributorSelfEditModal.style';

type Props = {
  is2FAActivated: boolean;
};

const ERROR_MESSAGES = {
  RESET: '',
  INVALID_PHONE_NUMBER: t('config_contributors:contributors.self_edit_modal.invalid_phone_number_2fa'),
  EMPTY_PHONE_NUMBER: t('config_contributors:contributors.self_edit_modal.empty_phone_number'),
};
// eslint-disable-next-line complexity

export const ContributorSelfEditModal = ({ is2FAActivated }: Props) => {
  const dispatch = useTypedDispatch();

  const labelError = useModuleSelector((state) => state.manageContributorModal.error);
  const visible = useModuleSelector((state) => state.manageContributorModal.isOpen.isSelfEditModalOpen || false);
  const contributorToManage = useModuleSelector((state) => state.manageContributorModal.contributorToManage);
  const originalContributor = useModuleSelector((state) => state.manageContributorModal.originalContributor);
  const [valueChangeFeedbackStatus, setValueChangeFeedbackStatus] = useState<ValueChangeFeedbackStatus>('none');
  const [valueChangeFeedbackMessage, setValueChangeFeedbackMessage] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const { phoneNumber: currentPhoneNumber, phoneCountryCode: currentPhoneCountryCode } = useMemo(
    () => contributorToManage,
    [contributorToManage],
  );

  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber ? currentPhoneNumber : '');
  const [phoneCountryCode, setPhoneCountryCode] = useState(currentPhoneCountryCode ? currentPhoneCountryCode : '');

  const isPhoneNumberFormVisible = useMemo(() => is2FAActivated, [is2FAActivated]);

  const handlePhoneNumberErrors = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      case ERROR_MESSAGES.INVALID_PHONE_NUMBER:
        setValueChangeFeedbackStatus('error');
        setValueChangeFeedbackMessage(ERROR_MESSAGES.INVALID_PHONE_NUMBER);
        break;
      case ERROR_MESSAGES.EMPTY_PHONE_NUMBER:
        setValueChangeFeedbackStatus('error');
        setValueChangeFeedbackMessage(ERROR_MESSAGES.EMPTY_PHONE_NUMBER);
        break;
      default:
        setValueChangeFeedbackStatus('none');
        setValueChangeFeedbackMessage('');
        break;
    }
  }, []);

  const handleInvalidPhoneNumber = useCallback(() => {
    if (phoneNumber === '') {
      Promise.resolve(handlePhoneNumberErrors(ERROR_MESSAGES.EMPTY_PHONE_NUMBER));
    } else {
      Promise.resolve(handlePhoneNumberErrors(ERROR_MESSAGES.INVALID_PHONE_NUMBER));
    }
  }, [handlePhoneNumberErrors, phoneNumber]);

  const handleSaveEnd = useCallback(
    (res: any) => {
      if (!res) {
        return null;
      }

      const { error } = res;

      if (!error) {
        return dispatch(actions.toggleContributorModal({ isSelfEditModalOpen: false }));
      }

      return error;
    },
    [dispatch],
  );

  const handleCloseModal = useCallback(() => {
    handlePhoneNumberErrors(ERROR_MESSAGES.RESET);

    return dispatch(actions.toggleContributorModal({ isSelfEditModalOpen: false }));
  }, [dispatch, handlePhoneNumberErrors]);

  const [firstName, setFirstName] = useState(originalContributor.firstName || '');
  const [lastName, setLastName] = useState(originalContributor.lastName || '');

  const isActionButtonDisabled = useMemo(
    () =>
      originalContributor.firstName === firstName && originalContributor.lastName === lastName && !isPhoneNumberValid,
    [contributorToManage, firstName, lastName, isPhoneNumberValid],
  );

  useEffect(() => {
    if (originalContributor.firstName) {
      setFirstName(originalContributor.firstName);
    }
    if (originalContributor.lastName) {
      setLastName(originalContributor.lastName);
    }
    if (originalContributor.phoneNumber) {
      setPhoneNumber(originalContributor.phoneNumber);
    }
  }, [originalContributor]);

  const handleUpdateFirstName = useCallback((newFirstName: string) => setFirstName(newFirstName), []);

  const handleUpdateLastName = useCallback((newLastName: string) => setLastName(newLastName), []);

  const handleUpdatePhoneNumberObject = useCallback(
    (newPhoneNumberObject: any) => {
      if (newPhoneNumberObject.phoneNumber === '') {
        setIsPhoneNumberValid(false);
        handlePhoneNumberErrors(ERROR_MESSAGES.EMPTY_PHONE_NUMBER);
      } else {
        handlePhoneNumberErrors(ERROR_MESSAGES.RESET);
        setIsPhoneNumberValid(newPhoneNumberObject.isValid);
      }

      setPhoneNumber(newPhoneNumberObject.phoneNumber);
      setPhoneCountryCode(newPhoneNumberObject.phoneCountryCode);
    },
    [dispatch, handlePhoneNumberErrors],
  );

  const handleSaveContributor = useCallback(() => {
    if (isPhoneNumberFormVisible && !isPhoneNumberValid) {
      return Promise.resolve(handleInvalidPhoneNumber());
    }

    return Promise.resolve(
      dispatch(
        actions.update({
          firstName,
          lastName,
          phoneNumber,
          phoneCountryCode,
          contributorToManage,
        }),
      ),
    );
  }, [
    dispatch,
    handleInvalidPhoneNumber,
    isPhoneNumberFormVisible,
    isPhoneNumberValid,
    firstName,
    lastName,
    phoneNumber,
    phoneCountryCode,
    contributorToManage,
  ]);

  return (
    <MModal
      visible={visible}
      onCloseModal={handleCloseModal}
      onAction={handleSaveContributor}
      onActionEnd={handleSaveEnd}
      onSecondAction={handleCloseModal}
      type="dialog"
      title={t('config_contributors:contributors.self_edit_modal.title')}
      labelActionButton={t('config_contributors:contributors.self_edit_modal.save_button')}
      labelSecondButton={t('config_contributors:contributors.self_edit_modal.cancel_button')}
      actionButtonType="accentuated"
      disableActionButton={isActionButtonDisabled}
      labelError={labelError}
    >
      <div>
        <SInput
          value={firstName}
          placeholder={t('config_contributors:contributors.self_edit_modal.first_name_placeholder')}
          type="small"
          label={t('config_contributors:contributors.self_edit_modal.first_name_label')}
          onChange={handleUpdateFirstName}
          style={styles.firstNameInput}
        />
        <SInput
          value={lastName}
          placeholder={t('config_contributors:contributors.self_edit_modal.last_name_placeholder')}
          type="small"
          label={t('config_contributors:contributors.self_edit_modal.last_name_label')}
          onChange={handleUpdateLastName}
          style={styles.lastNameInput}
        />
        {isPhoneNumberFormVisible && (
          <SPhoneInput
            valueChangeFeedbackStatus={valueChangeFeedbackStatus}
            valueChangeFeedbackMessage={valueChangeFeedbackMessage}
            defaultCountry={phoneCountryCode}
            defaultPhoneNumber={phoneNumber}
            label={t('config_contributors:contributors.self_edit_modal.phone_number_label')}
            onChange={handleUpdatePhoneNumberObject}
            style={styles.phoneNumberInput}
            isMandatory
          />
        )}
      </div>
    </MModal>
  );
};

export default ContributorSelfEditModal;
