// @flow

// HTMLElement typeguard
export const isHTMLElement = (element: ?any): boolean %checks => element instanceof HTMLElement;

/**
 * Return the closest HTMLElement in maxDepth range possessing the dataset attribute
 * Depending on where a user click, the target is the span or a svg inside the button,
 * so we check a few parents to see if there is a trackingAction on them.
 */
export const findNodeWithDatasetAttribute = (element: ?any, maxDepth: number, attribute: string): ?HTMLElement => {
  if (isHTMLElement(element) && element.dataset[attribute]) return element;
  if (maxDepth === 0 || !element) return undefined;

  return findNodeWithDatasetAttribute(element.parentElement, maxDepth - 1, attribute);
};
