import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export default createStyleSheet({
  wrapper: {
    flex: 1,
    display: 'flex',
    height: 64,
    backgroundColor: 'white',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  wrapperInfos: {
    display: 'flex',
    flex: '1 1 0%',
    height: 64,
    whiteSpace: 'nowrap',
    zIndex: 6,
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  wrapperActions: {
    flex: '1 1 0%',
    display: 'flex',
    height: 64,
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 6,
    borderBottom: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
  },
  backButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    marginTop: 16,
    marginLeft: 20,
    marginBottom: 16,
    marginRight: 24,
  },
  saveButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    marginTop: 16,
    marginBottom: 16,
    marginRight: 24,
  },
  previewButton: {
    zIndex: LEVELS.LOCAL_OFFSET,
    marginTop: 16,
    marginBottom: 16,
    marginRight: 24,
  },
  topActionBar: {
    marginTop: 12,
  },
  optionButton: {
    zIndex: 7,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
  },
  optionDropDown: {
    position: 'absolute',
    top: 35,
    right: 0,
  },
  lastEdit: {
    whiteSpace: 'nowrap',
    marginRight: 16,
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  spacer: {
    width: 1,
    height: 44,
    marginRight: 24,
    backgroundColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  validationWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  validateButton: {
    marginRight: 24,
  },
});
