import { AnyAction, CombinedState, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';

import type { MiddlewareArray, PreloadedCombinedState, ReducerForState, State } from './types';
import { defaultState, reducer as combinedReducers } from './reducer';

export const getStoreMiddlewares = (): MiddlewareArray => [
  thunk,
  reduxPackMiddleware,
];

export const createSpartedStore = <S>(
  reducer: ReducerForState<S>,
  preloadedState?: PreloadedCombinedState<S>,
  devTools = false,
) => configureStore<CombinedState<S>, AnyAction, MiddlewareArray>({
    reducer,
    preloadedState,
    devTools,
    middleware: getStoreMiddlewares(),
  });

export const store = createSpartedStore<State>(
  combinedReducers,
  defaultState,
  Boolean(window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__),
);

export type RootState = State;
export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
