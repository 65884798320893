import App from 'Models/App';

export type Dimension = {
  id: number;
  label: string;
};

type RawMithrilDimension = {
  id: () => number;
  getLabel: () => {
    label: () => string;
  };
};

export const getDimensions = (): Dimension[] => (App as any).segmentationDimensions?.().items.map((dimension: RawMithrilDimension) => ({
  id: dimension.id(),
  label: dimension.getLabel().label(),
}));
