import m from 'm';
import { t } from 'i18next';

import newInputMedia from 'components/new.input.media';
import EnumMedia from 'models/MediaHandlerEnum';

var component = {};

component.controller = function controller(args) {
  var self = this;

  self.editTitle = m.prop(false);
  self.editText = m.prop(false);

  self.configInputs = function configInputs(elem, isInit) {
    if (isInit)
      return;
    elem.focus();
  };

  self.switchPicture = function switchPicture(image) {
    args.panel.image(image);
    args.panel.imageId(image.id());
  };

  self.removePanel = function removePanel() {
    args.collection.removeByUUID(args.panel._uuid);
    args.reorder();
  };

  self.inputTitle = function inputTitle(value) {
    args.panel.title(value.slice(0, 40));
  };
};

component.view = function view(c, args) {
  return m('.welcome-panel-container', [
    m('.welcome-panel__image-container', {
      style: {
        'background-image': 'url(' + args.panel.image().cdn() + ')',
      },
    }, [
      m('.welcome-panel__remove', {
        onclick: c.removePanel,
      }, t('welcome_card:remove')),
      m('.welcome-panel__input-media-display', {
        class: args.panel.image().id() ? 'welcome-panel__input-media-display--hide' : '',
      }, [
        m(newInputMedia, {
          cropProps: {
            ...EnumMedia.COMMON.cropProps,
            ...EnumMedia.WELCOME_CARDS,
          },
          onSave     : c.switchPicture,
          imageLoaded: args.panel.imageId,
        }),
      ]),
    ]),
    m('.welcome-panel__edit-container', [
      displayTitle(),
      displayText(),
    ]),
    m('.welcome-panel__footer-container', [
      displayPoints(),
      m('.welcome-panel__footer__swipe-left', t('welcome_card:swipe_left')),
      m('.welcome-panel__footer__page-number', args.panel.order() + '/' + args.nbPanels),
    ]),
  ]);

  function displayTitle() {
    if (c.editTitle()) {
      return m('input.welcome-panel__title', {
        type       : 'text',
        config     : c.configInputs,
        value      : args.panel.title(),
        oninput    : m.withAttr('value', c.inputTitle),
        onblur     : c.editTitle.bind(null, false),
        placeholder: t('welcome_card:step_title_placeholder'),
      });
    }

    return m('.welcome-panel__title', {
      onclick: c.editTitle.bind(null, true),
    }, args.panel.title());
  }

  function displayText() {
    if (c.editText()) {
      return m('textarea.welcome-panel__text', {
        type       : 'text',
        config     : c.configInputs,
        value      : args.panel.text(),
        oninput    : m.withAttr('value', args.panel.text),
        onblur     : c.editText.bind(null, false),
        placeholder: t('welcome_card:step_text_placeholder'),
      });
    }

    var list = args.panel.text().split('\n');

    return m('.welcome-panel__text', {
      onclick: c.editText.bind(null, true),
    }, list.map(function displayParagraph(para) {
      return m('.welcome-panel__text__paragraph', para);
    }));
  }

  function displayPoints() {
    var points = [];

    for (var i = 0; i < args.nbPanels; i++) {
      points.push(m('.welcome-panel__footer__point', {
        class: args.panel.order() === i + 1 ? 'welcome-panel__footer__point--focus' : '',
      }));
    }

    return m('.welcome-panel__footer__point-container', points);
  }
};

export default component;
