import { SearchQuery, encodeSearchObject } from '@sparted/shared-library';

import { fetchFactory } from 'Libs/http/utils';
import { createAsyncAction } from 'Libs/redux/utils';

import { ActivitySearchResponse } from './activity.type';

export type SearchActivityArgs = {
  offset: number;
  limit: number;
  sort?: { [key: string]: 'asc' | 'desc' };
  query?: {
    id?: number[];
    type?: 'campaign';
    title?: string[];
    description?: string[];
    audienceType?: ('all' | 'segmentation' | 'magic-code' | 'email-list')[];
    status?: ('draft' | 'inactive' | 'archived' | 'upcoming' | 'live' | 'finished')[];
    language?: string[];
    audienceGroup?: number[];
  };
};

export const searchActivities = createAsyncAction<ActivitySearchResponse, SearchActivityArgs>(
  'activity/search',
  (args) => {
    const { offset, limit, sort, query = {} } = args;

    const hasQuery = Object.keys(query).length > 0;

    const { fetchFn } = fetchFactory<ActivitySearchResponse>({
      route: `/api/activities/search?${encodeSearchObject({
        limit,
        offset,
        sort: sort || { id: 'desc' },
        query: hasQuery
          ? Object.entries(query).reduce((acc, [key, value]) => {
              if (value) {
                acc[key] = value.toString();
              }

              return acc;
            }, {} as SearchQuery)
          : undefined,
      })}`,
      method: 'GET',
    });
    return fetchFn();
  },
);

export const fetchNextActivityPage = createAsyncAction('activity/search/next-page', async (nextPage: string) => {
  const { fetchFn } = fetchFactory<ActivitySearchResponse>({
    route: nextPage,
    method: 'GET',
  });

  return fetchFn();
});
