import m from 'm';
import popup from 'services/popupService';
import menu from 'pages/menu';
import Enum from 'models/Enum';
import Model from 'models/Model';
import Collection from 'models/Collection';
import gaiaMenu from 'components/gaia.menu';
import button from 'components/button';
import translationColumn from 'components/gaia.translation.column';

var component = {};

component.controller = function controller() {
  var self = this;

  self.saving = m.prop(false);
  self.modified = m.prop(false);
  self.saved = m.prop(false);
  var SegmentationDimension = Model('SegmentationDimension');

  self.dimensions = m.prop(new Collection(SegmentationDimension));
  self.fakeDefaultColumn = {
    title  : 'French',
    default: true,
    items  : [{
      value      : m.prop(''),
      placeholder: 'Fake Small Data',
    },
    {
      value      : m.prop(''),
      placeholder: 'Fake Small Data Apprenti',
    },
    ],
  };

  self.fakeColumn = {
    title: 'English',
    items: [{
      value      : m.prop(''),
      placeholder: 'Fake Small Data',
    },
    {
      value      : m.prop(''),
      placeholder: 'Fake Small Data Apprenti',
    },
    ],
  };

  self.fakeRow = [
    self.fakeDefaultColumn,
    self.fakeColumn,
    self.fakeColumn,
    self.fakeColumn,
    self.fakeColumn,
    self.fakeColumn,
    self.fakeColumn,
    self.fakeColumn,
  ];

  SegmentationDimension
    .getFull()
    .then(self.dimensions)
    .tap(formatDimensions);

  function formatDimensions(_dimensions) {
    var languageDimension = _dimensions.filter(getLanguages)[0];
    var otherDimensions = _dimensions;

    var indexOfDefault;

    languageDimension.groups().forEach(function findDefault(l, key) {
      if (l.isDefault())
        indexOfDefault = key;
    });

    var defaultLanguage = languageDimension.groups().items.splice(indexOfDefault, 1);

    languageDimension.groups().items.unshift(defaultLanguage[0]);

    var languages = [];

    if (languageDimension)
      languages = languageDimension.groups();

    var columns = languages.map(function toColumn(language) {
      var items = otherDimensions.map(formatWithLabel);
      var label = language.label();
      var isDefault = language.isDefault();

      return {
        default: isDefault,
        title  : label,
        items  : items,
        key    : language.id(),
      };

      function formatWithLabel(o) {
        var goodLabel = o.getLabel(language.id());
        var placeholder = o.getLabel().label();

        return {
          value      : goodLabel.label,
          placeholder: placeholder,
        };
      }
    });

    self.fakeRow = columns;
    m.redraw();
  }

  self.warningSave = function warningSave(action) {
    if (!self.modified())
      return action();
    popup.open({
      type   : 'confirm',
      title  : 'Modifications not saved',
      content: 'Are you sure you want to quit without saving? All unsaved modifications will be lost.',
      confirm: 'Don\'t save',
      cancel : 'Cancel',
      action : action,
    });
  };

  function getLanguages(o) {
    return o.typeId() === Enum.dimensionTypes.LANGUAGE;
  }

  function allButLanguages(o) {
    return o.typeId() !== Enum.dimensionTypes.LANGUAGE;
  }

  self.save = function save() {
    self.saving(true);
    m.redraw();

    return self.dimensions().save()
      .then(function resetDimensions(savedDims) {
        return SegmentationDimension
          .getFull()
          .then(self.dimensions)
          .tap(self.saving.bind(null, false))
          .tap(self.modified.bind(null, false))
          .tap(self.saved.bind(null, true))
          .tap(formatDimensions);
      });
  };
};

component.view = function view(c, args) {
  var saveButton = m(button, {
    value   : c.modified() ? 'Save' : 'Saved',
    class   : 'button--save',
    action  : c.save,
    disabled: !c.modified(),
    loading : c.saving(),
  });

  return m('.gaia__translation', [
    m('.header', [
      m(menu, {
        confirm: c.warningSave,
      }),
      m(gaiaMenu, {
        confirm: c.warningSave,
      }),
    ]),
    m('.gaia__translation__container', [
      m('.gaia__translation__header', [
        m('.translation__header__title', [
          'Segmentation Labels Translation',
        ]),
        m('.translation__header__actions', [saveButton]),
      ]),
      m('.gaia__translation__row', [
        m('.gaia__translation__row__content', [
          c.fakeRow.map(function displayRow(rowItem, key) {
            return m(translationColumn, {
              class   : 'gaia__translation__row__item',
              title   : rowItem.title,
              readonly: rowItem.default,
              items   : rowItem.items,
              modified: c.modified,
              // key     : key,
            });
          }),
        ]),
      ]),
      m('.gaia__translation__footer', [
        m('.translation__header__actions', [saveButton]),
      ]),
    ]),
  ]);

};

export default component;
