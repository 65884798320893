import React from 'react';

import type { FunctionComponent } from 'react';
import { css } from 'aphrodite';

import Transition from 'Components/hoc/Transition/Transition';

import styles, { enterAnimation, exitAnimation, ANIMATION_DURATION } from './MModalOverlay.style';

export type MModalOverlayProps = {
  onClick?: () => void;
  visible: boolean;
};

export const MModalOverlay: FunctionComponent<MModalOverlayProps> = ({ onClick, visible }) => (
  <Transition
    entryAnimationCss={css(enterAnimation)}
    exitAnimationCss={css(exitAnimation)}
    exitTimeout={ANIMATION_DURATION}
  >
    {visible && <div onClick={onClick} style={styles.overlay} />}
  </Transition>
);

export default MModalOverlay;
