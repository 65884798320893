// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  graphData: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  graphBar: {
    borderRadius: 2,
    height: 28,
    minWidth: 1,
  },
  graphBarContainer: {
    marginRight: 8,
    alignSelf: 'center',
  },
  percentage: {
    ...TYPOGRAPHY.BODY3,
    alignSelf: 'center',
  },
  dataName: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',

    // Make it big enough to handle multi digits number
    width: 70,
  },
  dataAmount: {
    ...TYPOGRAPHY.H2,
    fontWeight: 700,
  },
  barWithPercent: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginLeft: 24,

    // Bar maxwidth + percentage width + graph bar margin right
    // eslint-disable-next-line no-magic-numbers
    width: 175 + 32 + 8,
  },
};
