import m from 'm';
import _ from 'lodash';
import KEY from 'keys';
import request from 'services/requestService';
import popup from 'services/popupService';

var component = {};

component.controller = function controller(args) {

  var self = this;
  var moduleElem = null;

  self.searching = m.prop(false);

  self.error = m.prop('');

  self.icon = args.icon;
  self.currentTab = m.prop(1);

  self.library = m.prop([]);

  self.query = m.prop(args.query() || '');

  self.filename = m.prop('');

  self.openPopup = function openPopup(file) {
    popup.open({
      type   : 'info',
      title  : 'Impossible to download the file "' + file.name + '"',
      content: 'Please, try with an icon smaller than 10KB.',
    });
  };

  self.fileHandler = function fileHandler(e) {
    var target = e.target;
    var file = target.files[0];
    var reader;

    if (file) {
      var filesize = ((file.size/1024)).toFixed(4); // KB

      self.filename(file.name);

      if (filesize >= 10) {
        self.openPopup(file);
        m.redraw();

        return;
      }

      reader = new FileReader();

      reader.onloadend = function onloadend(event) {
        args.icon(event.target.result);
        m.redraw();
      };
      reader.readAsDataURL(file);
    }
  };

  self.onkeydown = function onkeydown(e) {
    self.searching(false);
    if (e.which === KEY.ENTER)
      self.searchIcon();
  };

  self.searchIcon = function searchIcon() {
    self.library(null);
    self.searching(true);
    request.get('api/Thematics/icons?query=' + self.query())
      .then(function displayIcon(ret) {
        self.searching('done');
        self.library(ret.icons);
        self.selectElement(0);
        m.redraw();
      })
      .catch(function handleError() {
        self.library([]);
        console.log('Error');
        self.searching('done');
        m.redraw();
      });
  };

  self.selectElement = function selectElement(elem) {
    self.icon(self.library()[elem] || '');
    m.redraw();
  };

  self.moduleConfig = function moduleConfig(elem, init) {
    if (!init)
      moduleElem = elem;
  };

  self.open = function open() {
    self.searchIcon();
    args.dropList(args.index);
    self.currentTab(1);

    // Freeze scroll on Thematics column
    document.querySelector('.newThematics__column--left').style.overflowY = 'hidden';

    document.addEventListener('click', onclick, false);

  };

  self.close = function close() {
    args.dropList(false);
    args.save();

    // Unfreeze scroll on Thematics column
    var col = document.querySelector('.newThematics__column--left');

    if (col) col.style.overflowY = 'scroll';

    document.removeEventListener('click', onclick, false);

  };

  self.onunload = function onunload() {
    self.close();
  };

  function onclick(e) {
    var target = e.target;

    if (!moduleElem.contains(target)) {
      e.preventDefault();

      self.close();
      m.redraw();
    }
  }
};

component.view = function view(c, args) {

  function dropList() {
    return m('img.thematic__button__list#t_button_' + args.type + '_' + args.index, {
      class  : args.dropList() === args.index ? 'opened' : '',
      src    : c.icon() || '/assets/img/ico-add-content-gray.png',
      onclick: function onclick(e) {

        if (args.dropList() === args.index)
          c.close();
        else
          c.open();

        var list = e.target.id.slice('t_button'.length);
        var newX = e.clientX - e.layerX;
        var newY = e.clientY - e.layerY + 43;

        document.querySelector('#t_list' + list).style.left = newX + 'px';
        document.querySelector('#t_list' + list).style.top = newY + 'px';
      },
    });
  }

  function displayImages() {
    if (c.searching() === 'done' && (!c.library() || !c.library().length)) {
      return m('.thematic__list__body__tab__empty', [
        'Sorry, we do not have any icon related to',
        c.query(),
        'in stock... You should try another english term!',
      ].join(' '));
    }

    return _.map(c.library(), function mapOverLibrary(iconUrl, index) {
      return m('img.thematic__list__body__tab__item__square', {
        src    : iconUrl,
        onclick: c.selectElement.bind(null, index),
      });
    });
  }

  return m('.thematic__button__icon', {
    config: c.moduleConfig,
  }, [
    dropList(),
    m('.thematic__list#t_list_' + args.type + '_' + args.index, {
      class: args.dropList() === args.index ? 'opened' : '',
    }, [
      m('.thematic__list__header',
        'You can either upload a custom icon to attach to the thematic, ',
        'or better yet, pick one from our suggestions.'
      ),
      m('.thematic__list__body', [
        m('.thematic__list__body__col', [
          m('.thematic__list__body__head', {
            class  : c.currentTab() === 1 ? 'active' : 'dim',
            onclick: c.currentTab.bind(null, 1),
          }, 'Suggested'),
          m('.thematic__list__body__head', {
            class  : c.currentTab() === 2 ? 'active' : 'dim',
            onclick: c.currentTab.bind(null, 2),
          }, 'Your Computer'),
        ]),
        m('.thematic__list__body__tab', {
          class: c.currentTab() === 1 ? 'active' : '',
        }, [
          m('.thematic__input.input--search', [
            m('input.input__value.thematic__list__search', {
              type       : 'text',
              placeholder: 'Search for icons',
              onkeydown  : c.onkeydown,
              value      : c.query(),
              oninput    : m.withAttr('value', c.query),
            }),
            m('.input__type', {
              onclick: c.searchIcon,
            }),
          ]),
          m('.thematic__list__body__tab__icons', {
            class: c.library() === null
              ? 'loader--dark'
              : '',
          }, displayImages()),
        ]),
        m('.thematic__list__body__tab', {
          class: c.currentTab() === 2 ? 'active' : '',
        }, [
          m('.thematic__icon__upload', [
            m('label.thematic__icon__label', [
              m('img.thematic__button__list', {
                src: c.icon() || '/assets/img/ico-add-content-gray.png',
              }, [
                m('input.hide', {
                  type    : 'file',
                  onchange: c.fileHandler,
                  accept  : '.jpeg,.gif,.png',
                }),
              ]),
            ]),
            m('.thematic__icon__filename__container', [
              m('.thematic__icon__filename', c.filename() || 'Upload your icon here...'),
            ]),
            m('img.thematic__icon__delete', {
              src    : '/assets/img/ico-close-content-black.png',
              onclick: function onclick(e) {
                c.filename('');
                c.icon('');
              },
            }, 'x'),
          ]),
          // ]),
          // m('label.newKnowledge__addPicture', {
          //   style: {
          //     backgroundImage: args.icon()
          //       ? 'url(' + args.icon() + ')'
          //       : '',
          //     height: '100%',
          //   },
          // }, [
          //   m('.newKnowledge__addPicture__effect', {
          //     class: args.icon()
          //       ? 'newKnowledge__addPicture--overlay'
          //       : 'newKnowledge__addPicture--shadow',
          //   }, [
          //     m('.newKnowledge__addPicture__icon', args.icon()
          //         ? 'Change picture'
          //         : 'Add a thematic icon'),
          //     m('input.hide', {
          //       type    : 'file',
          //       onchange: c.fileHandler,
          //       accept  : '.jpeg,.gif,.png',
          //     }),
          //   ]),
        ]),
      ]),
    ]),
  ]);
};

export default component;
