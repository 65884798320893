const TRANSFORMATIONS = {
  FADE_IN: {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  FADE_OUT: {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
};

const MOVEMENTS = {
  APPEAR_FROM_BOTTOM: {
    '0%': { transform: 'translateY(10px)' },
    '100%': { transform: 'translateY(0px)' },
  },
  APPEAR_FROM_LEFT: {
    '0%': { transform: 'translateX(10px)' },
    '100%': { transform: 'translateX(0px)' },
  },
};

const SPEED_NUMBER = {
  VERY_SLOW: 500,
  SLOW: 300,
  NORMAL: 250,
  FAST: 200,
  VERY_FAST: 100,
};

const SPEED = {
  VERY_SLOW: `${SPEED_NUMBER.VERY_SLOW}ms`,
  SLOW: `${SPEED_NUMBER.SLOW}ms`,
  NORMAL: `${SPEED_NUMBER.NORMAL}ms`,
  FAST: `${SPEED_NUMBER.FAST}ms`,
  VERY_FAST: `${SPEED_NUMBER.VERY_FAST}ms`,
};

const EASING = {
  STANDARD: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  DECELERATE: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  ACCELERATE: 'cubic-bezier(0.4, 0.0, 1, 1)',
};

export default {
  TRANSFORMATIONS,
  SPEED_NUMBER,
  MOVEMENTS,
  SPEED,
  EASING,
};
