import React from 'react';
import styles, { rowMessageColor } from 'Components/structural/STable/STable.style';
import { STableRow } from 'Components/structural/STable/types';

const RowMessage = ({ rowMessage }: STableRow['original']) => {
  if (!rowMessage) return null;

  const { type, content } = rowMessage;

  if (!content) return null;

  const color: string = rowMessageColor[type];

  const style = {
    ...styles.rowMessageContainer,

    // Hexadecimal '19' represent 10% opacity
    backgroundColor: `${color}19`,
    color,
  };

  return <div style={style}>{content}</div>;
};

export default RowMessage;
