import { t } from 'i18next';
import React from 'react';

import { UIndicator } from 'Components/unit';

import styles from '../SContentCard.style';

export const AlreadyUsedOverlay = () => {
  return (
    <div style={styles.alreadyUsedContainer}>
      <div style={styles.alreadyUsedOverlay} data-test-id="alreadyUsedOverlay"></div>
      <div style={styles.alreadyUsedIndicatorContainer}>
        <UIndicator
          mode="contrasted"
          text={t('structural_component:s_content_card.already_used')}
          style={styles.alreadyUsedIndicator}
        />
      </div>
    </div>
  );
};
