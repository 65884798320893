import React, { useCallback, useState } from 'react';
import m from 'mithril';

import { t } from 'i18next';

import MModal from 'Components/modal/MModal/MModal';
import SMithrilCreator from 'Components/structural/SMithrilCreator/SMithrilCreator';
import { TYPOGRAPHY } from 'Components/foundation';

import initThematics from '../../popup.init.thematics';

import styles from './KnowledgeThematicsModal.style';

type ThematicMithrilModel = {
  name?: () => string | undefined
};

type KnowledgeThematicsModalProps = {
  onClose: () => void,
  visible: boolean,
  onConfirm: (selectedThematic?: ThematicMithrilModel) => void
};

/**
 * Display a modal that embed the thematic mithril component
 *
 * Props:
 * - onClose: Callback when closing the modal
 * - onConfirm: Callback when confirming action
 * - visible: Boolean to display or hide the modal
 */
export const KnowledgeThematicsModal = ({ onClose, visible, onConfirm }: KnowledgeThematicsModalProps) => {
  const [selectedThematic, setSelectedThematic] = useState<ThematicMithrilModel | undefined>();
  const handleAction = useCallback(() => { onConfirm(selectedThematic); }, [onConfirm, selectedThematic]);
  const close = useCallback(() => { onClose(); }, [onClose]);
  const handleSelectThematic = useCallback((thematic: ThematicMithrilModel) => { setSelectedThematic(thematic); }, []);

  return (
    <MModal
      labelActionButton={t('modal_components:thematic.confirmation')}
      labelSecondButton={t('modal_components:thematic.cancel')}
      onSecondAction={close}
      onCloseModal={close}
      onAction={handleAction}
      title={t('modal_components:thematic.thematics')}
      visible={visible}
      style={styles.modalStyle}
    >
      <div style={styles.contentWrapper}>
        <div style={styles.selectedThematicSection}>
          <span style={TYPOGRAPHY.H2}>
            {t('modal_components:thematic.selected_thematic')}
            {' '}
          </span>
          <span style={TYPOGRAPHY.H2_BOLD} data-test-id="selected-thematic-name">
            {selectedThematic?.name?.()}
          </span>
        </div>
        <SMithrilCreator
          mithril={m(initThematics, {
            onThematicSelected: handleSelectThematic,
          })}
        />
      </div>
    </MModal>
  );
};

export default KnowledgeThematicsModal;
