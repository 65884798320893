import Model from 'models/Model';

var Currency = {
  endpoint  : 'api/Currencies',
  attributes: {
    label: {
      type: 'string',
    },
    symbol: {
      type: 'string',
    },
  },
  methods: {
    displayName: function displayName() {
      return [this.symbol(), ' (', this.label(), ')'].join('');
    },
  },
};

export default Model.register('Currency', Currency);
