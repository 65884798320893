import React, { useEffect, useState } from 'react';

import LS from 'Services/localStorageService';

import { useTypedDispatch } from 'Libs/redux/utils';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import { useModuleSelector, actions } from '../redux';

import { ContributorList } from '../ContributorList/ContributorList';

import styles from './ContributorView.style';
import type { ContributorType } from '../redux/models/Contributor';

type Props = {
  is2FAActivated: boolean;
};

export const ContributorView = ({ is2FAActivated }: Props) => {
  const dispatch = useTypedDispatch();
  const loading = useModuleSelector((state) => state.loading);
  const [currentUser, setCurrentUser] = useState({} as ContributorType);

  // Fetch roles available depending of admin logged role
  useEffect(() => {
    function fetchUser() {
      const user: ContributorType = LS.getSync('user');

      setCurrentUser(user);
    }

    if (currentUser.roles) {
      const action = actions.fetchRoles(currentUser.roles?.[0].id);

      dispatch(action);

      return action.cancel;
    }

    fetchUser();

    return () => {};
  }, [currentUser, dispatch]);

  return (
    <>
      <SPageLoaderWithTimer isLoading={loading} style={styles.loader} />
      <div style={styles.wrapper}>
        <div style={styles.container}>
          <ContributorList
            editorRoleId={currentUser?.roles?.[0].id}
            editorId={currentUser?.id}
            is2FAActivated={is2FAActivated}
          />
        </div>
        <div style={styles.bottomSpace} />
      </div>
    </>
  );
};
