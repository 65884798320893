// @flow

/**
 * HOC which display alerts
 */

import React, { useState, useEffect, useCallback } from 'react';
import type { AbstractComponent } from 'react';
import { css } from 'aphrodite';

import SAlert from 'Components/structural/SAlert/SAlert';

import styles from './withAlert.style';

type Type =
  | 'error'
  | 'success'
;

type Alert = {|
  id: number,
  title: string,
  message: string,
  icon: string,
  type: Type,
  priority: string,
|};

type AlertLocal = {|
  ...Alert,
  deleted: boolean,
|};

type Props = {|
  alerts: Array<Alert>,
  onClose: (id: number) => mixed,
|};

type Parameters = {|
  wrapperStyle?: ?Object,
|};

const TIME_ANIMATION_DISAPPEAR = 300;

export default function withAlert(parameters?: Parameters = Object.freeze({})) {
  return function getComponent(WrappedComponent: AbstractComponent<Props>): AbstractComponent<Props> { // eslint-disable-line max-len
    const _withAlert = (props: Props) => {
      const { alerts: alertsProp, onClose } = props;
      const [alerts, setAlerts] = useState(alertsProp.map((alert) => ({
        ...alert,
        deleted: false,
      })));

      useEffect(() => {
        setAlerts(
          alertsProp.map((alert) => ({
            ...alert,
            deleted: false,
          }))
        );
      }, [alertsProp]);

      const handleCloseAlert = useCallback((id: number) => () => {
        const indexAlert = alerts.findIndex((alert: AlertLocal) => alert.id === id);
        const newAlerts = [...alerts];

        newAlerts[indexAlert] = {
          ...alerts[indexAlert],
          deleted: true,
        };

        setAlerts(newAlerts);
        setTimeout(() => onClose(id), TIME_ANIMATION_DISAPPEAR);
      }, [alerts, onClose]);

      return (
        <div style={parameters.wrapperStyle} className={css(styles.wrapper)}>
          <WrappedComponent
            {...props}
          />
          <div className={css(styles.wrapperAlert)}>
            {alerts.map((alert: AlertLocal) => {
              const { deleted } = alert;
              const style = deleted
                ? styles.containerAlertHide
                : styles.containerAlertDisplay;

              return (
                <div key={alert.id} className={css(styles.containerAlert, style)}>
                  <SAlert
                    status={alert.type}
                    priority="low"
                    icon={alert.icon}
                    title={alert.title}
                    text={alert.message}
                    onClose={handleCloseAlert(alert.id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return _withAlert;
  };
}
