import m from 'm';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import reactCreator from 'components/react.creator';

var component = {};

component.controller = function controller() {
};

component.view = function view() {
  return m('.category-page', [
    m('.categories-list-page__menu', [
      m(menu),
      m(gaiaMenu),
    ]),
    m(reactCreator, {
      component: 'CategoryList',
      props    : {},
      style: {
        width: '100%',
        height: '100%',
        paddingTop: '104px',
      },
    }),
  ]);
};

export default component;
