// @flow

import { TYPOGRAPHY } from 'Components/foundation';

const borderRadius = 4;

export default {
  wrapper: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius,
  },
  wrapperHovered: {
    cursor: 'pointer',
  },
  assets: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 21,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius,
  },
  label: {
    whiteSpace: 'nowrap',
    marginRight: 8,
    ...TYPOGRAPHY.CAPTION,
  },
  content: {
    ...TYPOGRAPHY.BODY3,
  },
};
