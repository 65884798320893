import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS } from 'Components/foundation';

const styles = createStyleSheet({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 14,
    width: 14,
    borderRadius: 3,
    border: 1,
    borderStyle: 'solid',
  },
  clickableArea: {
    padding: 8,
    cursor: 'pointer',
  },
  disabledClickableArea: {
    cursor: 'default',
  },
  label: {
    marginLeft: 8,
  },
  linearWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;

const disabledStyles = createStyleSheet({
  checkbox: {
    ...styles.checkbox,
    backgroundColor: COLORS.GREY_DARK.DISABLED,
    borderColor: COLORS.GREY_DARK.DISABLED,
  },
  icon: {
    fill: COLORS.TEXT.DISABLED_DEFAULT,
  },
});

const enabledStyles = createStyleSheet({
  checkbox: {
    ...styles.checkbox,
    backgroundColor: COLORS.GREY_DARK.DEFAULT,
    borderColor: COLORS.GREY_DARK.DEFAULT,
  },
  icon: {
    fill: COLORS.GREY_DARKER.DEFAULT,
  },
});

const accentuatedStyles = createStyleSheet({
  checkbox: {
    ...styles.checkbox,
    backgroundColor: COLORS.PRIMARY.DEFAULT,
    borderColor: COLORS.PRIMARY.DEFAULT,
  },
  icon: {
    fill: COLORS.WHITE.DEFAULT,
  },
});

export const stylesMap = {
  accentuated: accentuatedStyles,
  enabled: enabledStyles,
  disabled: disabledStyles,
};
