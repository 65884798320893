import Request from 'services/requestService';
import Model from 'models/Model';
import Collection from 'models/Collection';
import App from 'models/App';

const Category = {
  endpoint  : 'api/Categories',
  attributes: {
    label: {
      type: 'string',
    },
    locked: {
      type      : 'boolean',
      defaultsTo: false,
    },
    polyglotLabel: {
      type : 'pointer',
      Model: 'PolyglotLabel',
    },
    polyglotLabelId: {
      type: 'number',
    },
  },
  methods: {
    save: function save(_Category) {
      if (!this.id())
        return Promise.resolve();

      return Request
        .put('api/Categories/full/' + this.id(), this.toJSON())
        .then(function returnEntity(res) {
          return new _Category(res);
        });
    },
    getLabel: function getLabel(_Category, languageId) {
      if (!languageId)
        languageId = App.defaultLanguage().id();

      return this.polyglotLabel().getLabel(languageId);
    },
  },
  classMethods: {
    findForFilter: function findForFilter(_Category) {
      let defaultLanguageId = App.defaultLanguage().id();

      const filter = {
        filter: {
          include: [
            { relation: 'polyglotLabel',
              scope: {
                include: 'translatedLabels',
              },
            },
          ],
        },
      };

      return _Category.find(filter)
        .then((rawCategories) => {
          const categories = rawCategories.toJSON();
          const categoriesWithCorrectLabels = categories.map((category) => {
            const defaultTranslatedLabel = category.polyglotLabel.translatedLabels
              .filter((tl => tl.languageId === defaultLanguageId));

            if (defaultTranslatedLabel[0])
              category.label = defaultTranslatedLabel[0].label;

            return category;
          });

          // Sort in alphabetical ASC order
          return categoriesWithCorrectLabels
            .sort((a, b) => {
              if (!a.label) return -1;
              if (!b.label) return 1;

              return a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
            });
        });
    },
    findForTranslation: function findForTranslation(_Category) {
      const languageId = App.defaultLanguage().id();
      const filter = {
        filter: {
          include: [
            { relation: 'polyglotLabel', scope: { include: 'translatedLabels' }},
          ],
        },
      };

      return _Category
        .find(filter)

        // prevent change of default language, attach default translated label to category label to keep consistency
        .then(function fixCategoriesLabel(categories) {
          const res = categories.map((category) => {
            const { translatedLabels } = category.toJSON().polyglotLabel;
            const defaultLabel = translatedLabels.find((label) => label.languageId === languageId);

            category.label(defaultLabel.label);

            return category;
          });

          return new Collection('Category', Category, res);
        });
    },
  },
};

export default Model.register('Category', Category);
