import m from 'm';

import { t } from 'i18next';

import Bus from 'services/pubSubService';
import menu from 'pages/menu';
import gaiaMenu from 'components/gaia.menu';
import initThematics from 'components/popup.init.thematics';

var component = {};

component.controller = function controller() {
  var self = this;

  self.error = m.prop('');
  Bus.subscribe('general:error', displayError);
  Bus.subscribe('general:noError', hideError);

  self.onUnload = function onUnload() {
    Bus.unsubscribe('general:error', displayError);
    Bus.unsubscribe('general:noError', hideError);
  };

  function displayError(err) {
    self.error(err);
  }

  function hideError() {
    self.error('');
  }

};

component.view = function view(c) {

  return m('.segmentation-page', [
    m(menu),
    m(gaiaMenu),
    m('.segmentation-page-handler', [
      m('.segmentation__container', [
        m('.segmentation__container__title', t('thematic:thematics')),
        m('.segmentation__container__error', c.error()),
        m(initThematics),
      ]),
    ]),
  ]);
};
export default component;
