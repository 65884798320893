/* eslint-disable complexity */
import m from 'm';
import Enum from 'models/Enum';
import deviceScreen from 'components/device.screen';
import qcmPreview from 'components/qcm.preview';
import sqcmPreview from 'components/sqcm.preview';
import noInteractionContentPreview from 'components/no.interaction.content.preview';
import errorTextPreview from 'components/error.text.preview';
import swipingCardsPreview from 'components/swiping.cards.preview';
import openQuestionPreview from 'components/open.question.preview';
import pictureSpotPreview from 'components/picture.spot.preview';
import orderingCardsPreview from 'components/ordering.cards.preview';

const TYPES = Enum.gameplay;

var backgroundColor = [
  'gameplay-preview__title__background--archived',
  'PRESET_DELETED',
  'gameplay-preview__title__background--draft',
  'gameplay-preview__title__background--to_validate',
  'gameplay-preview__title__background--validated',
];

var circleColor = [
  'gameplay-preview__title__foreground__circle--archived',
  'PRESET_DELETED',
  'gameplay-preview__title__foreground__circle--draft',
  'gameplay-preview__title__foreground__circle--to_validate',
  'gameplay-preview__title__foreground__circle--validated',
];

const previewByType = {
  [TYPES.QCM]                   : qcmPreview,
  [TYPES.SQCM]                  : sqcmPreview,
  [TYPES.NO_INTERACTION_CONTENT]: noInteractionContentPreview,
  [TYPES.PICTURE_SPOT]          : pictureSpotPreview,
  [TYPES.ERROR_TEXT]            : errorTextPreview,
  [TYPES.SWIPING_CARDS]         : swipingCardsPreview,
  [TYPES.OPEN_QUESTION]         : openQuestionPreview,
  [TYPES.ORDERING_CARDS]        : orderingCardsPreview,
};

var component = {};

component.controller = function controller() {
  var self = this;

  self.gameplayFocus = m.prop(false);
};

component.view = function view(c, args) {
  var gameplay = args.content.gameplayId();

  return m('.gameplay-preview', {}, [
    drawHeader(),
    m('.gameplay-preview__body', [
      m(deviceScreen, {}, [
        previewByType[gameplay]
          ? m(previewByType[gameplay], {
            content: args.content,
          })
          : m(''),
      ]),
    ]),
  ]);

  function drawHeader() {

    var gameplayIconModifier;
    var gameplayIcon;

    switch (gameplay) {
      case Enum.gameplay.QCM:
        gameplayIconModifier = 'mcq';
        break;
      case Enum.gameplay.SQCM:
        gameplayIconModifier = 'smcq';
        break;
      case Enum.gameplay.SWIPING_CARDS:
        gameplayIconModifier = 'swiping-card';
        break;
      case Enum.gameplay.NO_INTERACTION_CONTENT:
        gameplayIconModifier = 'no-interaction';
        break;
      case Enum.gameplay.OPEN_QUESTION:
        gameplayIconModifier = 'open-text';
        break;
      case Enum.gameplay.PICTURE_SPOT:
        gameplayIconModifier = 'picture-spot';
        break;
      case Enum.gameplay.ERROR_TEXT:
        gameplayIconModifier = 'error-text';
        break;
      case Enum.gameplay.ORDERING_CARDS:
        gameplayIconModifier = 'ordering-cards';
        break;
      default:
        break;
    }

    gameplayIcon = m('.knowledge-header__icon-gameplay-' + gameplayIconModifier);

    return m('.gameplay-preview__title.clearFix', [
      m('.gameplay-preview__title__background', {
        class: getBackgroundColor(),
      }, [
        m('.gameplay-preview__title__background__circle', [
          m('.gameplay-preview__title__foreground__circle', {
            class: getCircleColor(),
          }),
        ]),
        m('.gameplay-preview__title__label.gameplay-preview__title__label__status', args.content.status().label()),
      ]),
      m('.gameplay-preview__content__right', [
        m('.gameplay-preview__title__label.gameplay-preview__title__label__name', {
          class: 'gameplay-preview__title__label__name--' + args.content.gameplay().label().replace(/ /g, '-').toLowerCase(),
        }, args.content.gameplay().translatedLabel()),
        m(`.knowledge-header__block-icon-gameplay.gameplay-${gameplayIconModifier}`, [
          gameplayIcon,
        ]),
      ]),
    ]);
  }

  function getBackgroundColor() {
    if (args.content.status().id() === Enum.contentStatus.ARCHIVED)
      return backgroundColor[0];

    return backgroundColor[args.content.status().id()];
  }

  function getCircleColor() {
    if (args.content.status().id() === Enum.contentStatus.ARCHIVED)
      return circleColor[0];

    return circleColor[args.content.status().id()];
  }
};

export default component;
