// @flow

export default {
  modal: {
    margin: 0,
    padding: 0,
  },
  dropAreaWrapper: {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 20,
  },
  displayOn: {
    display: 'block',
  },
  displayOff: {
    display: 'none',
  },
  cropPanel: {
    width: '100%',
    height: '100%',
  },
};
