/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Displays a Dots Menu component
 *
 * - mode: Component mode
 * - onClick: Action trigger on click
 * - style: To override the style
 */

import * as React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from 'Components/unit/UIcon/UIcon';

import styles from './UDotsMenu.style';

type Mode = 'dark' | 'light';
type Size = 'small' | 'medium';

type Props = {|

  // Require
  onClick: MouseEventHandler,

  // Optional
  size?: Size,
  mode?: Mode,
  style?: Object,
|};

type State = {|
  isHover: boolean,
|};

const SMALL = 16;
const MEDIUM = 20;

export class UDotsMenu extends React.Component<Props, State> {
  static defaultProps = {
    mode: 'light',
    style: undefined,
    size: 'small',
  };

  state = {
    isHover: false,
  };

  // eslint-disable-next-line complexity
  render() {
    const { onClick, mode, style, size } = this.props;
    const hoverStyle = this.getHoverStyle();
    const color = mode === 'light' ? COLORS.WHITE.DEFAULT: COLORS.TEXT.SECONDARY_DEFAULT;
    const iconSize = size === 'small' ? SMALL : MEDIUM;

    return (
      <div
        className="nonDraggable"
        onClick={onClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        style={{ ...styles.wrapper, ...hoverStyle, ...style }}
      >
        <UIcon
          name="dot-menu"
          color={color}
          size={iconSize}
          style={size === 'small' ? styles.small : styles.medium}
        />
      </div>
    );
  }

  handleHover = () => {
    this.setState({ isHover: true });
  };

  handleLeave = () => {
    this.setState({ isHover: false });
  };

  getHoverStyle = () => {
    const { isHover } = this.state;
    const { mode } = this.props;

    if (mode === 'light')
      return isHover ? styles.opacityLight : {};

    if (mode === 'dark')
      return isHover ? styles.opacityDark : {};

    return {};
  };
}

export default UDotsMenu;
