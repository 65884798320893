import Fuse from 'fuse.js';
import React from 'react';
import type { CSSProperties, ChangeEventHandler, KeyboardEventHandler } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import UFilterSelect from 'Components/unit/UFilterSelect/UFilterSelect';
import UChips from 'Components/unit/UChips/UChips';
import USuggestionBox from 'Components/unit/USuggestionBox/USuggestionBox';
import { COLORS } from 'Components/foundation';

import type { Source } from 'Libs/ts/types';
import { getCategoryLabel, getCategoryValue } from 'Libs/filter/utils';
import type { Filter, FilterItem, FilterCategory } from 'Libs/filter/types';

import type { Suggestion } from 'Components/unit/USuggestionBox/USuggestionBox';

import styles from './SOmnibox.style';

const cloneObject = <T extends object>(obj: T): T => JSON.parse(JSON.stringify(obj));

export type SuggestionOptions = {
  enable: boolean;
  enableOnEmptyInput: boolean;
  enableTextSuggestion: boolean;
  showAllOnFocus?: boolean;
  disableMaxSuggestionsCap?: boolean;
  showCategoryName?: boolean;
  maxSuggestionBoxHeight?: number;
};

type AggregateFilterItem = Omit<FilterItem, 'id'> & {
  aggregateItems: Chip[];
};

type Chip = AggregateFilterItem | FilterItem;

const isAggregateChip = (chip: any): chip is AggregateFilterItem => Boolean(chip.aggregateItems);

export type SOmniboxProps = {
  style?: CSSProperties;
  id?: string;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onFilterChanged: Function;
  sources: Array<Source>;
  filtersText: string;
  filterMultiText: boolean;
  initShowFilters: boolean;
  suggestionOptions: SuggestionOptions;
  automaticSearch: boolean;
  defaultIndexSourceSelected: number;
  dimensions?: Array<{ id: number; label: string }>;
  hideMargins?: boolean;
  hideFiltersButton?: boolean;
};

type SOmniboxState = {
  value: string;
  suggestions: Suggestion[];
  isDropdownSourcesOpen: boolean;
  isSuggestionBoxOpen: boolean;
  indexSourceSelected: number;
  source: Source;
  indexTextFilter: number;
  isInputFocused: boolean;
  showFilters: boolean;
};

const TIME_WAIT_SEND_TEXT_FILTER = 600;
const LIMIT_CHIPS_BEFORE_AGGREGATE = 3;
const FILTER_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  DATE: 'date',
} as const;
const MAX_SUGGESTIONS_PER_CATEGORY = 15;

const makeChipsProps = (item: Chip) => {
  const { category, color } = item;

  switch (category) {
    case 'segment':
      return { color };
    case 'user':
      return { categoryIcon: 'user' };
    case 'campaign':
      return { categoryIcon: 'filter-campaign' };
    case 'language':
      return { categoryIcon: 'language' };
    default:
      return {};
  }
};

const makeSuggestion = (item: FilterItem): Suggestion | null => {
  switch (item.category) {
    case 'campaign':
      return { category: 'campaign', id: item.id, name: item.value };
    case 'user':
      return { category: 'user', id: item.id, name: item.value };
    case 'segment':
      return { category: 'segment', id: item.id, name: item.value, color: item.color || undefined };
    default:
      return null;
  }
};

/**
 * An input with filters
 *
 * Props:
 *  - placeholder: placeholder
 *  - onFilterChanged: Called when change on the input or filters
 *  - sources: Sources where to filter in, sources are mandatory, even if only the input is used as a filter
 *    see libs/flowTypes.js > Source for the sources type
 *  - filtersText: text filters translated
 *  - filterMultiText: boolean to indicate it can have differents filters text
 *  - initShowFilters: display or not the filters at the mount of the component
 *  - style: override component's style
 *  - automaticSearch: Call onFilterChanged when the user write some text and stop writting for at least 600ms
 *    this props must be set to true for the input search to be active
 *  - dimensions: array to cluster segments by dimensions
 *  - suggestionOptions:
 *    - enable: boolean to activate suggestions as the user types
 *    - enableOnEmptyInput: display empty suggestion box as soon as user takes the focus
 *    - showAllOnFocus: displays all suggestions on focus
 *    - enableTextSuggestion: boolean to activate the text suggestion
 *    - maxSuggestionBoxHeight: control the height limit of the suggestion box
 */
export class SOmnibox extends React.PureComponent<SOmniboxProps, SOmniboxState> {
  timeoutID: NodeJS.Timeout | undefined;

  wrapperRef = React.createRef<HTMLDivElement>();

  suggestionBoxRef = React.createRef<USuggestionBox>();

  static defaultProps = {
    style: undefined,
    filtersText: '',
    filterMultiText: true,
    initShowFilters: false,
    defaultIndexSourceSelected: 0,
    suggestionOptions: {
      enable: false,
      enableOnEmptyInput: false,
      enableTextSuggestion: true,
      disableMaxSuggestionsCap: false,
      showAllOnFocus: false,
      showCategoryName: true,
    },
    automaticSearch: false,
    dimensions: [],
    hideMargins: false,
    hideFiltersButton: false,
  };

  constructor(props: SOmniboxProps) {
    super(props);

    const { sources, initShowFilters, defaultIndexSourceSelected } = props;
    const [defaultSource] = sources;

    let defaultValue = '';

    if (sources && !sources.length) {
      throw new Error('Please provide a source for the SOmnibox to prevent crash on use');
    }

    if (defaultSource?.filters?.[0]?.type === 'text') {
      defaultValue = defaultSource.filters?.[0]?.items?.[0]?.value || '';
    }

    this.state = {
      value: defaultValue,
      suggestions: [],
      isDropdownSourcesOpen: false,
      isSuggestionBoxOpen: false,
      indexSourceSelected: defaultIndexSourceSelected,
      source: sources[defaultIndexSourceSelected],
      indexTextFilter: 0,
      isInputFocused: false,
      showFilters: initShowFilters,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(_: SOmniboxProps, prevState: SOmniboxState) {
    const { suggestionOptions } = this.props;
    const { value } = this.state;

    if (suggestionOptions.enable) {
      if (value !== prevState.value) {
        const suggestions = this.makeSuggestions(value);

        // It is fine calling setState in componentDidUpdate as we check a condition before
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ suggestions });
      }

      // Highlight the first suggestion by default
      if (this.suggestionBoxRef.current && this.suggestionBoxRef.current.getActive() === null) {
        this.suggestionBoxRef.current.activateNext();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  renderInput = () => {
    const { placeholder, sources, hideMargins, hideFiltersButton, id } = this.props;
    const { value, isInputFocused } = this.state;

    const borderColor = {
      borderColor: isInputFocused ? COLORS.TEXT.DEFAULT : COLORS.TEXT.PLACEHOLDER_DEFAULT,
    };
    const marginLeftInput = sources.length <= 1 ? { marginLeft: 12 } : {};
    const margins = hideMargins ? styles.hideMargins : {};

    return (
      <div style={{ ...styles.wrapper, ...margins, ...borderColor }}>
        {this.renderSource()}
        <div style={{ ...styles.inputWrapper, ...marginLeftInput }}>
          <input
            id={id}
            data-testid="omnibox-input"
            type="text"
            value={value}
            placeholder={placeholder}
            style={styles.input}
            className="structural-inputs"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleInputValue}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            // Disable auto complete for Chrome
            autoComplete="off"
          />
        </div>
        {!hideFiltersButton && this.renderFilterText()}
      </div>
    );
  };

  renderSource = () => {
    const { sources } = this.props;
    const { indexSourceSelected } = this.state;

    if (sources.length <= 1) {
      return null;
    }

    const { icon } = sources[indexSourceSelected];

    return (
      <div style={styles.wrapperSourceBox}>
        <div onClick={this.handleClickSource} style={styles.sourceBox}>
          {icon && <UIcon name={icon} size={14} />}
        </div>
        {this.renderDropdownSources()}
        <div style={styles.rod} />
      </div>
    );
  };

  renderDropdownSources = () => {
    const { sources } = this.props;
    const { isDropdownSourcesOpen } = this.state;

    if (!isDropdownSourcesOpen) {
      return null;
    }

    return (
      <div style={styles.wrapperDropDownSources}>
        <UDropdownBox items={sources} renderItem={this.renderDropdownItemSources} />
      </div>
    );
  };

  renderDropdownItemSources = (item: Source, index: number) => {
    const { name, icon } = item;

    return <UDropdownItem key={name} text={name} icon={icon} onClick={() => this.handleChangeSource(index)} />;
  };

  renderFilterText = () => {
    const { filtersText } = this.props;

    if (!filtersText) {
      return null;
    }

    return (
      <div data-test-id="filters-button" style={styles.filterText} onClick={this.handleClickFilterText}>
        {filtersText}
      </div>
    );
  };

  renderFiltersBox = () => {
    const { source, showFilters } = this.state;

    let invisibleStyle = {};

    if (!source?.filters.length || !showFilters) {
      invisibleStyle = { display: 'none' };
    }

    return <div style={{ ...styles.filterBox, ...invisibleStyle }}>{source?.filters.map(this.renderFilter)}</div>;
  };

  renderFilter = (filter: Filter) => {
    if (filter.type === FILTER_TYPE.TEXT) {
      return null;
    }

    return (
      <UFilterSelect
        key={getCategoryValue(filter.category)}
        filter={filter}
        onFilterChanged={this.handleFilterChange}
      />
    );
  };

  renderChipsBox = () => {
    const chips = this.getChipsToDisplay();

    if (!chips.length) {
      return null;
    }

    return (
      <div data-testid="omnibox-chips-box" style={styles.chipsWrapper}>
        <div style={styles.chipsContainer}>{chips.map(this.renderChips)}</div>
      </div>
    );
  };

  renderChips = (item: Chip) => {
    const { category, value, dimensionId, dimensionLabel } = item;

    const isAggregate = isAggregateChip(item);

    const onAction = isAggregate ? this.handleDeleteChips : () => this.handleDeleteChips(category, item.id);

    const type = isAggregate ? 'aggregated' : 'standard';

    const chipsProps = makeChipsProps(item);

    let formattedValue = value;

    if (isAggregate && category === 'segment' && dimensionLabel) {
      formattedValue = dimensionLabel;
    }

    if (category === 'text' && !isAggregate) {
      formattedValue = `"${value}"`;
    }

    const formattedAggregateItems = isAggregate
      ? item.aggregateItems &&
        item.aggregateItems.map((x) => (x.category === 'text' ? { ...x, value: `"${x.value}"` } : x))
      : [];

    const key = !dimensionId
      ? getCategoryValue(category) + (item as FilterItem).id
      : dimensionId + getCategoryValue(category) + (item as FilterItem).id;

    return (
      <div key={key}>
        <UChips
          category={category}
          value={formattedValue}
          {...chipsProps}
          type={type}
          aggregateItems={formattedAggregateItems}
          isCloseVisible
          onAction={onAction}
          style={styles.chips}
        />
      </div>
    );
  };

  renderSuggestions = () => {
    const { suggestionOptions } = this.props;
    const { suggestions, isSuggestionBoxOpen } = this.state;

    if (!isSuggestionBoxOpen || (suggestions.length === 0 && !suggestionOptions.enableOnEmptyInput)) {
      return null;
    }

    const { maxSuggestionBoxHeight, showCategoryName } = suggestionOptions;

    return (
      <div style={styles.suggestionWrapper}>
        <div style={styles.suggestionContainer}>
          <USuggestionBox
            ref={this.suggestionBoxRef}
            suggestions={suggestions}
            maxSuggestionBoxHeight={maxSuggestionBoxHeight}
            showCategoryName={showCategoryName}
            onSelect={this.handleSelectSuggestion}
          />
        </div>
      </div>
    );
  };

  handleDeleteChips = (category: FilterCategory, id: number) => {
    const { source } = this.state;

    const newSource: Source = {
      ...source,
      filters: source.filters.map(({ items, ...restFilter }) => ({
        ...restFilter,
        items: restFilter.category === category ? this.deleteChips(items, id) : items,
      })),
    };

    this.setState({ source: newSource }, this.handleSetValueInSourceAndCallOnFilterChanged);
  };

  handleFilterChange = (newFilter: Filter) => {
    const { source } = this.state;

    const newSource: Source = {
      ...source,
      filters: source.filters.map((filter) => (filter.category === newFilter.category ? newFilter : filter)),
    };

    this.setState({ source: newSource }, this.handleSetValueInSourceAndCallOnFilterChanged);
  };

  handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (this.suggestionBoxRef.current) {
          this.suggestionBoxRef.current.activateNext();
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.suggestionBoxRef.current) {
          this.suggestionBoxRef.current.activatePrevious();
        }
        break;
      case 'Enter':
        event.preventDefault();
        this.handleEnter();
        break;
      case 'Escape':
        event.preventDefault();
        this.setState({ value: '' });
        break;
      default:
        break;
    }
  };

  handleEnter = () => {
    const { suggestionOptions, filterMultiText } = this.props;
    const { value } = this.state;

    if (suggestionOptions.enable) {
      const suggestion = this.suggestionBoxRef.current && this.suggestionBoxRef.current.getActive();

      if (suggestion !== null) {
        this.handleSelectSuggestion(suggestion);

        return;
      }
    }

    if (!value || !filterMultiText) {
      return;
    }

    this.addTextFilter(value, false);
  };

  handleChangeSource = (index: number) => {
    const { sources } = this.props;
    const newSource = sources[index];

    this.setState(
      {
        indexSourceSelected: index,
        isDropdownSourcesOpen: false,
        source: newSource,
        indexTextFilter: 0,
      },
      this.handleSetValueInSourceAndCallOnFilterChanged,
    );
  };

  handleInputValue: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { onFilterChanged, automaticSearch } = this.props;
    const { value } = event.target;

    const copySource = this.setValueInputInSource(value);

    this.setState({ value });

    if (!automaticSearch) {
      return;
    }

    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }

    this.timeoutID = setTimeout(() => {
      onFilterChanged(copySource);
    }, TIME_WAIT_SEND_TEXT_FILTER);
  };

  handleClickSource = () => {
    const { isDropdownSourcesOpen } = this.state;

    this.setState({ isDropdownSourcesOpen: !isDropdownSourcesOpen });
  };

  handleClickFilterText = () => {
    const { showFilters } = this.state;

    this.setState({ showFilters: !showFilters });
  };

  handleSelectSuggestion = (suggestion: Suggestion) => {
    const { isInputFocused } = this.state;

    this.addSuggestion(suggestion);

    // INFO leave suggestion box openable if input is still focused
    // to enable full keyboard navigation
    this.setState({ isSuggestionBoxOpen: isInputFocused });
  };

  handleFocus = () => {
    const { suggestionOptions } = this.props;
    const { suggestions, value } = this.state;

    const additionalState = {
      suggestions:
        suggestionOptions.enable && !value && suggestionOptions.showAllOnFocus && !suggestions.length
          ? this.makeSuggestions('')
          : suggestions,
    };

    this.setState({
      isInputFocused: true,
      isSuggestionBoxOpen: suggestionOptions.enable,
      ...additionalState,
    });
  };

  handleBlur = () => {
    this.setState({ isInputFocused: false });
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isDropdownSourcesOpen: false, isSuggestionBoxOpen: false });
    }
  };

  handleSetValueInSourceAndCallOnFilterChanged = () => {
    const { onFilterChanged } = this.props;
    const { value, source } = this.state;

    const copySource = value ? this.setValueInputInSource(value) : source;

    onFilterChanged(copySource);

    // clear input
    this.setState({ value: '' });
  };

  addSuggestion = (suggestion: Suggestion) => {
    if (suggestion.category === 'text') {
      this.addTextFilter(suggestion.value, true);

      return;
    }

    const { onFilterChanged } = this.props;
    const { source } = this.state;

    const hasSuggestion = source.filters.find((f) => f.category === suggestion.category);

    if (!hasSuggestion) {
      return;
    }

    const newSource = {
      ...source,
      filters: source.filters.map((filter) => {
        if (filter.category !== suggestion.category) {
          return filter;
        }

        return {
          ...filter,
          items: filter.items.map((item) => (item.id === suggestion.id ? { ...item, selected: true } : item)),
        };
      }),
    };

    onFilterChanged(newSource);
    this.setState({ source: newSource, value: '' });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  setValueInputInSource = (value: string): Object => {
    const { source, indexTextFilter } = this.state;

    const copySource = cloneObject(source);

    const newFilterText: Filter = {
      category: 'text',
      type: FILTER_TYPE.TEXT,
      items: [{ category: 'text', value, selected: true, id: indexTextFilter }],
    };

    const textFilterIndex = copySource.filters.findIndex((filter) => filter.category === 'text');

    if (textFilterIndex === -1) {
      copySource.filters.push(newFilterText);
    } else {
      copySource.filters[textFilterIndex].items[indexTextFilter] = {
        category: 'text',
        value,
        selected: true,
        id: indexTextFilter,
      };
    }

    return copySource;
  };

  getChipsToDisplay = () => {
    const { source } = this.state;
    const { dimensions } = this.props;

    const chipsToDisplay: Chip[] = [];

    source?.filters.forEach((filter) => {
      if (filter.type !== FILTER_TYPE.MULTISELECT && filter.type !== FILTER_TYPE.TEXT) {
        return;
      }

      const { items, category } = filter;

      const chipsForCategory = this.getChipsForCategory(items, [], category);

      // Aggregated chips
      if (category === 'segment' && dimensions) {
        dimensions.forEach((dimension) => {
          const chipsForDimension: Chip[] = chipsForCategory.filter((chips) => chips.dimensionId === dimension.id);

          const segmentationsInDimension = chipsForDimension.length;

          if (segmentationsInDimension > LIMIT_CHIPS_BEFORE_AGGREGATE) {
            const value = getCategoryLabel(filter);
            const colorDimension = chipsForDimension[0].color;

            chipsToDisplay.push({
              aggregateItems: chipsForDimension,
              value,
              category,
              dimensionId: dimension.id,
              dimensionLabel: dimension.label,
              color: colorDimension,
            });
          } else {
            chipsToDisplay.push(...chipsForDimension);
          }
        });
      } else if (chipsForCategory.length > LIMIT_CHIPS_BEFORE_AGGREGATE) {
        const value = getCategoryLabel(filter);

        chipsToDisplay.push({ aggregateItems: chipsForCategory, value, category });
      } else {
        chipsToDisplay.push(...chipsForCategory);
      }
    });

    return chipsToDisplay;
  };

  getChipsForCategory = (items: FilterItem[], chipsForCategory: Chip[], category: FilterCategory): Chip[] => {
    const chips: Chip[] = [
      ...chipsForCategory,
      ...items.reduce((acc, { value, color, id, children, selected, dimensionId, dimensionLabel }) => {
        if (selected) {
          return [
            ...acc,
            {
              category,
              value,
              color,
              id,
              dimensionId,
              dimensionLabel,
            } as Chip,
          ];
        }

        if (children) {
          return [...acc, ...this.getChipsForCategory(children, chipsForCategory, category)];
        }

        return acc;
      }, [] as Chip[]),
    ];

    return chips;
  };

  addTextFilter = (value: string, callOnFilterChanged: boolean) => {
    const { source, indexTextFilter } = this.state;

    const newFilterText: Filter = {
      category: 'text',
      type: FILTER_TYPE.TEXT,
      items: [{ category: 'text', value, selected: true, id: indexTextFilter }],
    };

    const copySource = cloneObject(source);

    const textFilterIndex = copySource.filters.findIndex((filter) => filter.category === 'text');

    if (textFilterIndex === -1) {
      copySource.filters.push(newFilterText);
    } else {
      copySource.filters[textFilterIndex].items.push({ category: 'text', value, selected: true, id: indexTextFilter });
    }

    this.setState(
      {
        source: copySource,
        value: '',
        indexTextFilter: indexTextFilter + 1,
      },
      callOnFilterChanged ? this.handleSetValueInSourceAndCallOnFilterChanged : undefined,
    );
  };

  deleteChips = (items: Array<FilterItem>, id: number): FilterItem[] => {
    const newChips = items.reduce((acc, item) => {
      const unSelectedItem = item.id === id ? { ...item, selected: false } : item;

      return item.children
        ? ([...acc, { ...unSelectedItem, children: this.deleteChips(item.children, id) }] as FilterItem[])
        : ([...acc, unSelectedItem] as FilterItem[]);
    }, [] as FilterItem[]);

    return newChips;
  };

  makeSuggestions = (value: string): Suggestion[] => {
    const { suggestionOptions } = this.props;
    const { source } = this.state;

    if (value === '' && !suggestionOptions.showAllOnFocus) {
      return [];
    }

    const allBooks = source.filters.map(({ items }) => items);

    const options = {
      keys: ['value'],
      shouldSort: true,
      threshold: 0.1,
    };

    const fuses = allBooks.map((books) => new Fuse<FilterItem>(books, options));

    const allMatches = value
      ? fuses.map((fuse) => fuse.search(value))
      : allBooks.map((book) => book.map((item) => ({ item })));

    const suggestionCap = suggestionOptions.disableMaxSuggestionsCap ? Infinity : MAX_SUGGESTIONS_PER_CATEGORY;

    const items = allMatches
      .map((matches) => matches.filter((_, i) => i < suggestionCap))
      .reduce((acc, x) => [...acc, ...x], [])
      .map(({ item }) => item);

    const suggestions = items.map(makeSuggestion).filter(Boolean) as Suggestion[];

    const defaultSuggestions: Array<Suggestion> = suggestionOptions.enableTextSuggestion
      ? [{ category: 'text', value }]
      : [];

    return [...defaultSuggestions, ...suggestions];
  };

  render() {
    const { style } = this.props;

    return (
      <div ref={this.wrapperRef} style={style}>
        {this.renderInput()}
        {this.renderSuggestions()}
        {this.renderFiltersBox()}
        {this.renderChipsBox()}
      </div>
    );
  }
}

export default SOmnibox;
