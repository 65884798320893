/* eslint-disable complexity */
import m from 'm';
import { t } from 'i18next';
import App from 'models/App';
import Model from 'models/Model';
import Collection from 'models/Collection';
import Bus from 'services/pubSubService';
import button from 'components/button';
import segmentationInput from 'components/segmentation.input';
import checkbox from 'components/checkbox';

var component = {};

var SegmentationGroupItem = Model('SegmentationGroupItem');

component.controller = function controller(args) {
  var self = this;

  var current = args.entity.segmentation();
  var allLanguages = App.languages();

  var all = SegmentationGroupItem.substractSegmentation(allLanguages, current);

  self.languages = m.prop(new Collection(SegmentationGroupItem));
  self.selectedLanguages = m.prop(new Collection(SegmentationGroupItem));
  self.loading = m.prop(false);
  self.isSelected = m.prop(false);
  self.refreshSegmentation = m.prop(false);
  self.doneTranslating = m.prop(false);

  self.toggleAllLanguages = function toggleAllLanguages(selected) {

    if (!arguments.length)
      return self.isSelected();

    if (selected) {
      self.selectedLanguages(all);
      self.isSelected(true);
    } else {
      self.selectedLanguages(new Collection(SegmentationGroupItem));
      self.isSelected(false);
    }

    self.refreshSegmentation(true);
    m.redraw();

    return self.isSelected();
  };

  self.refreshAllStatus = function refreshAllStatus() {
    var currentCount = self.selectedLanguages().length;
    var allSelected = currentCount === all.length;

    self.isSelected(allSelected);
  };

  self.action = function action() {

    if (!self.selectedLanguages().length) {
      Bus.trigger('popup:error', t('mithril_components:popup_ask_translation.select_one_language'));

      return;
    }

    self.loading(true);

    args.entity
      .askTranslation(self.selectedLanguages())
      .then(function handleEnd(entity) {
        self.loading(false);
        self.doneTranslating(true);
        m.redraw();
      })
      .catch(function handleError() {
        Bus.trigger('popup:error', t('mithril_components:popup_ask_translation.error_validated_content'));

        self.loading(false);
        m.redraw();
      });
  };

  self.finish = function finish() {
    if (args.onclose)
      args.onclose();
    if (args.onFinish)
      args.onFinish();
    args.close();
  };
};

component.view = function view(c, args) {
  return m('.popup.translation-popup', [
    m('.popup__body.popup__body--translation', {
      class: c.doneTranslating() ? 'hidden' : '',
    }, [
      m('.popup__title__translation', t('mithril_components:popup_ask_translation.choose_language')),
      m('.popup__subtitle__translation__content', [
        m('.popup__subtitle__translation__label.popup__subtitle__translation__label--icon', t('mithril_components:popup_ask_translation.select_languages')),
        m('.popup__subtitle__translation__checkbox__content', [
          m(checkbox, {
            value: c.toggleAllLanguages,
          }),
          m('.popup__subtitle__translation__label', t('mithril_components:popup_ask_translation.select_all')),
        ]),
      ]),
      m('.popup__body__content.popup__body__content--translation', [
        m('div', [
          m(segmentationInput, {
            segmentationToHide: args.entity.segmentation,
            refresh           : c.refreshSegmentation,
            oninput           : c.refreshAllStatus,
            onlyLanguage      : true,
            index             : 'translation',
            entity            : {
              segmentation: c.selectedLanguages,
            },
          }),
        ]),
      ]),

      m('.popup__rules__translation', [
        m('.popup__rules__translation__title',  t('mithril_components:popup_ask_translation.content_duplication')),
        m('.popup__rules__translation__content', args.info || t('mithril_components:popup_ask_translation.translation_explanation')),
      ]),

    ]),
    m('.popup__body.popup__body--translation', {
      class: [
        c.doneTranslating() ? '' : 'hidden',
        c.doneTranslating() ? 'popup__body--translation-done' : '',
      ].join(' '),
    }, [
      m('.popup__title__translation', {
        class: 'popup__title__translation--done',
      }, t('mithril_components:popup_ask_translation.done')),
      m('.popup__content__translation', [
        m('.popup__sentence__translation', 
        t('mithril_components:popup_ask_translation.done_sentence', {title: args.entity.name ? args.entity.name() : args.entity.title(), languages:  c.selectedLanguages().items.map((item) => item.label()).join(', ')}),
        ),
      ]),
    ]),
    m('.popup__footer.clearFix', [
      m(button, {
        value :  t('mithril_components:popup_ask_translation.cancel'),
        class : c.doneTranslating() ? 'hidden' : 'button--cancel',
        action: args.close,
      }),
      m(button, {
        value  : c.doneTranslating() ?  t('mithril_components:popup_ask_translation.ok') :  t('mithril_components:popup_ask_translation.ask_translation'),
        action : c.doneTranslating() ? c.finish : c.action,
        loading: c.loading(),
        class  : [
          c.doneTranslating()
            ? 'button__translation--right'
            : c.loading()
              ? ''
              : 'ico--next ico--right',
          'button--action', 'button--ico',
          'activity__btn', 'button--ok',
        ].join(' '),
      }),
    ]),
  ]);
};
export default component;
