import React from 'react';

import { COLORS } from 'Components/foundation';
import UIcon from '../UIcon/UIcon';

import styles from './UFieldError.style';

type UFieldErrorProps = {
  errorMessage?: string;
};

export const UFieldError = ({ errorMessage }: UFieldErrorProps) => {
  if (!errorMessage) return null;

  return (
    <div style={styles.errorMessage}>
      <UIcon color={COLORS.ERROR.DEFAULT} name="error" size={14} />
      {errorMessage}
    </div>
  );
};
