import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    height: '100%',
  },
  list: {
    maxWidth: 780,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    paddingBottom: 100,
    gap: 20,
  },
  emptySearchText: {
    ...TYPOGRAPHY.H2,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginTop: 16,
    textAlign: 'center',
  },
  emptySearchIcon: {
    marginTop: 15,
    alignSelf: 'center',
  },
  createActivityButton: {
    alignSelf: 'flex-end',
    marginTop: 48,
    marginBottom: 16,
  },
  loader: {
    marginTop: 100,
    height: '100%',
    position: 'relative',
  },
});

export default styleSheet;
