/* eslint-disable complexity */
import m from 'm';
import { t } from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import Promise from 'bluebird';
import model from 'models/Model';
import Enum from 'models/Enum';
import App from 'models/App';
import popup from 'services/popupService';
import userService from 'services/userService';
import menu from 'pages/menu';
import reactCreator from 'components/react.creator';
import knowledgeHeader from 'components/knowledge.header';
import contentPreview from 'components/content.preview';
import deviceScreen from 'components/device.screen';

var component = {};
var Knowledge = model('Knowledge');
var Player = model('Player');

component.controller = function controller() {
  var self = this;

  self.knowledge = m.prop(new Knowledge());
  self.originalKnowledge = m.prop();
  self.currentUser = m.prop(new Player());

  // knowledge actions access
  self.canAskForTranslationRole = m.prop(false);
  self.canArchiveKnowledgeRole = m.prop(false);
  self.canEditKnowledgeRole = m.prop(false);
  self.canValidateKnowledgeRole = m.prop(false);

  // toolbar props
  self.toolbarStatus = m.prop('disabled');
  self.toolbarStatusWording = m.prop('Not saved');
  self.toolbarTooltip = m.prop(undefined);
  self.knowledgeTitle = m.prop('');
  self.knowledgeId = m.prop(0);
  self.knowledgeIdToDisplay = m.prop('');
  self.actionsConfig = m.prop([{
    type   : 'save',
    enabled: true,
  }, {
    type   : 'preview',
    enabled: false,
  }, {
    type   : 'validate',
    enabled: false,
  }]);
  self.toolbarActions = m.prop([]);
  self.saveEnabled = m.prop(false);
  self.isTranslated = m.prop(false);
  self.backWording = m.prop('');
  self.lastSavedDate = m.prop('');

  // page props
  self.loading = m.prop(true);
  self.isNew = m.prop(false);
  self.instructions = m.prop(t('contents:content_details.instructions'));
  self.error = m.prop(false);
  self.editableHeader = m.prop(false);

  self.goToContentPage = function goToContentPage(contentId) {
    m.route(m.route() + '/content/' + contentId);
  };

  self.showLeaveEditionPopupThenGo = function showLeaveEditionPopupThenGo(go) {
    if (!self.isKnowledgeEdited())
      return go();

    return popup.open({
      type    : 'leaveEditionConfirmation',
      save    : saveThenGo,
      dontSave: closePopupThenGo,
      message : m('', [t('contents:content_details.did_not_save_1'), m('br'), t('contents:content_details.did_not_save_2')]),
    });

    function closePopupThenGo() {
      popup.close();
      go();
    }

    function saveThenGo() {
      self.saveKnowledge().then(closePopupThenGo);
    }
  };

  self.goBack = function goBack() {
    const translationPath = '/translation';

    var route = '';

    if (self.knowledge().id() && self.knowledge().pendingTranslation())
      route = '/knowledge/' + self.knowledge().id() + translationPath;
    else
      route = '/knowledge';

    self.showLeaveEditionPopupThenGo(() => m.route(route));
  };

  function startLoading() {
    self.loading(true);
    m.redraw();

    return Promise.resolve();
  }

  function stopLoading() {
    self.loading(false);
    m.redraw();

    return Promise.resolve();
  }

  function returnKnowledge() {
    var id = m.route.param('id');

    if (id === 'new') {
      self.isNew(true);

      var currentThematicId = m.route.param('thematicId');
      var languageSegmentationId = m.route.param('languageSegmentationId');
      const filteredSegmentation = App.userSegmentationItems();

      if (currentThematicId) {
        var currentThematic = App.thematics().get(parseInt(currentThematicId));

        self.knowledge().thematic(currentThematic);
        self.knowledge().thematicId(currentThematic.id());
      }

      var presetSeg = languageSegmentationId
        ? filteredSegmentation
          .map((segItem) => segItem.group())
          .find((seg) => seg.id() === parseInt(languageSegmentationId))
        : self.currentUser()
          .segmentation()
          .find({ dimensionId: Enum.dimensionTypes.LANGUAGE })
          .at(0);

      if (presetSeg)
        self.knowledge().segmentation().push(presetSeg);

      return Promise.resolve(self.knowledge());
    }

    return Promise.resolve(Knowledge.get(parseInt(id)));
  }

  function updateAccess() {
    const user = self.currentUser();
    const isInTranslation = self.knowledge().pendingTranslation();
    const actionsConfig = self.actionsConfig();
    const { WRITER, ADMIN, ROOT, MASTER } = Enum.Role;
    const { SAVE } = Enum.ContentActions;

    const canAskForTranslationRole = user.hasOneRole([ADMIN, MASTER, ROOT]);
    const canArchiveKnowledgeRole = user.hasOneRole([WRITER, ADMIN, MASTER, ROOT]);
    const canEditKnowledgeRole = user.hasOneRole([WRITER, ADMIN, MASTER, ROOT]);
    const canValidateKnowledgeRole = user.hasOneRole([ADMIN, MASTER, ROOT]);

    // change save access
    actionsConfig[SAVE].enabled = canEditKnowledgeRole && !self.knowledge().archived();

    // permit to edit knowledge in translation
    if (!canEditKnowledgeRole)
      self.canEditKnowledgeRole(isInTranslation);

    self.canAskForTranslationRole(canAskForTranslationRole);
    self.canArchiveKnowledgeRole(canArchiveKnowledgeRole);
    self.canEditKnowledgeRole(canEditKnowledgeRole);
    self.canValidateKnowledgeRole(canValidateKnowledgeRole);
    self.actionsConfig(actionsConfig);
  }

  function atLeastOneValidContent() {
    if (!self.knowledge().contents() || self.knowledge().contents().length < 1)
      return false;

    return self.knowledge().contents().items.some(function isContentValid(content) {
      return content.statusId() === Enum.contentStatus.VALIDATED;
    });
  }

  function loadKnowledge() {
    return returnKnowledge()
      .then(self.knowledge)
      .tap(function setKnowledgeInfo() {
        self.knowledgeId(self.knowledge().id());
        self.knowledgeTitle(self.knowledge().knowledgeTitle().data());
        self.knowledgeIdToDisplay(self.knowledge().customId() || self.knowledge().id() || '');
      })
      .tap(setOriginalKnowledge);
  }

  function loadMe() {
    return userService.me()
      .then(self.currentUser)
      .then(updateAccess);
  }

  function archiveKnowledge() {
    self.knowledge().archived(true);

    return self.knowledge().save()
      .then(() => m.route('/knowledge'));
  }

  function unarchiveKnowledge() {
    self.knowledge().archived(false);

    self.saveKnowledge()
      .then(self.handleSaveEnd);
  }

  function setOriginalKnowledge() {
    self.originalKnowledge(self.knowledge().toJSON());
  }

  self.saveKnowledge = function saveKnowledge() {
    var e = self.knowledge().validate();

    if (e && self.isNew()) {
      self.error(true);

      return Promise.resolve(self.instructions(e.message + t('contents:content_details.errors.end_of_sentence')));
    } else if (e) {
      popup.open({
        type   : 'info',
        title  : t('contents:content_details.errors.not_valid'),
        content: e.message,
      });

      return Promise.resolve();
    }

    return startLoading()
      .then(function saveKnowledgeThis() {
        return self.knowledge().save();
      });
  };

  self.handleSaveEnd = function handleSaveEnd(knowledgeUpdated) {
    if (knowledgeUpdated.error) {
      const subtitle = t('contents:content_details.errors.operation_not_possible');

      let title = t('contents:content_details.errors.error'),
        content = knowledgeUpdated.error.message.error.message;

      if (knowledgeUpdated.error.message.error.userCode === 51) {
        const segLanguage = self.knowledge().segmentation().find((seg) => {
          return seg.dimensionId() === Enum.dimensionTypes.LANGUAGE;
        });

        title = t('contents:content_details.errors.translation_conflict');
        content = t('contents:content_details.errors.translation_conflict_message', {language:  segLanguage.items[0].label()});
      }

      self.loading(false);

      popup.open({
        type    : 'info',
        title,
        subtitle,
        content,
      });

      return;
    }

    self.knowledgeId(knowledgeUpdated.id());
    self.knowledgeTitle(knowledgeUpdated.knowledgeTitle().data());
    self.knowledgeIdToDisplay(knowledgeUpdated.customId() || knowledgeUpdated.id() || '');

    // avoid to refresh via m.route
    if (self.isNew()) {
      self.isNew(false);

      const pathElems = window.location.pathname.split('/')
        .filter((_, i) => i <= 2);

      pathElems[2] = knowledgeUpdated.id();
      window.history.replaceState({}, null, pathElems.join('/'));
    }

    self.knowledge(knowledgeUpdated);
    setOriginalKnowledge();

    self.loading(false);
    m.redraw();
  };

  self.askForTranslationButton = function askForTranslationButton() {
    if (self.knowledge().archived()) {
      return popup.open({
        type    : 'info',
        title   : t('contents:content_details.errors.impossible_action'),
        subtitle: t('contents:content_details.errors.cant_translate_archived'),
        content : t('contents:content_details.errors.cant_translate_archived_content'),
      });
    }
    if (!atLeastOneValidContent()) {
      return popup.open({
        type    : 'info',
        title   :  t('contents:content_details.errors.impossible_action'),
        subtitle:  t('contents:content_details.errors.cant_translate_non_valid'),
        content :  t('contents:content_details.errors.cant_translate_non_valid_content'),
      });
    }

    return popup.open({
      type      : 'translation',
      entity    : self.knowledge(),
      entityType: 'knowledge',
      info      : t('contents:content_details.translation_info'),
    });
  };

  self.archiveKnowledgeButton = function archiveKnowledgeButton() {
    return popup.open({
      type    : 'confirm',
      action  : archiveKnowledge,
      confirm : t('contents:content_details.menu.archive'),
      title   : t('contents:content_details.menu.modal_title'),
      subtitle: t('contents:content_details.menu.subtitle_archive_modal'),
      content : t('contents:content_details.menu.content_archive_modal'),
    });
  };

  self.unarchiveKnowledgeButton = function unarchiveKnowledgeButton() {
    return popup.open({
      type    : 'confirm',
      action  : unarchiveKnowledge,
      confirm : t('contents:content_details.menu.unarchive'),
      title   : t('contents:content_details.menu.modal_title'),
      subtitle: t('contents:content_details.menu.subtitle_unarchive_modal'),
      content : t('contents:content_details.menu.content_unarchive_modal'),
    });
  };

  self.isKnowledgeEdited = function isKnowledgeEdited() {
    var original = self.originalKnowledge();

    if (!original)
      return false;

    return !_.isEqual(self.knowledge().toJSON(), original);
  };

  startLoading()
    .then(loadMe)
    .then(loadKnowledge)
    .then(stopLoading);
};

component.view = function view(c) {
  var pendingTranslation = c.knowledge().pendingTranslation();
  var toolbarActions = [];
  var anyValidContent = false;

  c.knowledge().contents().forEach(function checkValidContent(content) {
    if (content.statusId() === Enum.contentStatus.VALIDATED)
      anyValidContent = true;
  });

  var cantArchive = anyValidContent && !c.canValidateKnowledgeRole();

  if (!pendingTranslation) {
    if (c.canAskForTranslationRole() && !c.knowledge().archived()) {
      toolbarActions.push({
        title   : t('contents:content_details.menu.ask_translation'),
        callback: c.askForTranslationButton,
      });
    }
    if (c.canArchiveKnowledgeRole() && !c.knowledge().archived()) {
      toolbarActions.push({
        title   : t('contents:content_details.menu.archive'),
        callback: c.archiveKnowledgeButton || cantArchive,
      });
    } else if (c.canArchiveKnowledgeRole() && c.knowledge().archived()) {
      toolbarActions.push({
        title   : t('contents:content_details.menu.unarchive'),
        callback: c.unarchiveKnowledgeButton,
      });
    }
  }

  c.toolbarActions(toolbarActions);

  var header;
  var content = m('.content-page__placeholder', [
    m(reactCreator, {
      component: 'SPageLoaderWithTimer',
      props    : {
        isLoading: true,
        style    : { zIndex: 9 },
      },
      style: { height: '100%' },
    }),
  ]);

  if (!c.loading()) {
    c.editableHeader(c.canEditKnowledgeRole() && !c.knowledge().archived());

    header = m(knowledgeHeader, {
      knowledge : c.knowledge(),
      isCreation: c.isNew(),
      editable  : c.editableHeader,
      error     : c.error,
    });

    content = m('.knowledge-page__body', [
      m('.knowledge-page__body__grid', {
        class: c.knowledge().id() ? '' : 'hidden',
      }, [!pendingTranslation ? drawNewContentButton(c) : '',
        c.knowledge().contents().map(drawContent.bind(null, c)),
      ]),
      m('.knowledge-page__body__creation-instructions', {
        class: c.knowledge().id() ? 'hidden' : '',
      }, c.instructions()),
    ]);
  }

  const isKnowledgeEdited = c.isKnowledgeEdited();
  const statusObject = getStatus(c.knowledge());

  // Set value via prop because of xhr request
  c.toolbarStatus(statusObject.status);
  c.toolbarStatusWording(statusObject.statusWording);
  c.toolbarTooltip(statusObject.tooltip);

  c.saveEnabled(isKnowledgeEdited);
  c.backWording(pendingTranslation ? t('contents:content_details.back_to_translation') : t('contents:content_details.go_back'));
  c.isTranslated(pendingTranslation);
  c.lastSavedDate(getLastSavedDate(c.knowledge(), c.canEditKnowledgeRole()));

  return m('.knowledge-page', [
    m('.knowledge-page__header', [
      m(menu),
    ]),
    m('.knowledge-body', [
      m(reactCreator, {
        component: 'SContentActionBar',
        props    : {
          id           : c.knowledgeIdToDisplay,
          title        : c.knowledgeTitle,
          backWording  : c.backWording,
          onBack       : c.goBack,
          onSave       : c.saveKnowledge,
          onSaveEnd    : c.handleSaveEnd,
          canSave      : c.saveEnabled,
          options      : c.toolbarActions,
          status       : c.toolbarStatus,
          statusWording: c.toolbarStatusWording,
          helper       : c.toolbarTooltip,
          isTranslated : c.isTranslated,
          actionsConfig: c.actionsConfig,
          lastSaved    : c.lastSavedDate,
        },
      }),
      header,
      content,
    ]),
  ]);
};

function getLastSavedDate(knowledge, canEdit) {
  const id = knowledge.id();
  const updatedAt = knowledge.updatedAt();

  if (!id || !updatedAt || !canEdit)
    return '';

  return `${moment(updatedAt).calendar(null, {
    sameDay : `[${t('contents:content_details.saved.last_saved_at')}] LT`,
    nextDay : '',
    nextWeek: '',
    lastDay : `[${t('contents:content_details.saved.last_saved_yesterday')}] LT`,
    lastWeek: `[${t('contents:content_details.saved.saved_last')}] dddd`,
    sameElse: `[${t('contents:content_details.saved.last_saved')}] DD/MM/YYYY`,
  })}`;
}

function getStatus(knowledge) {
  const id = knowledge.id();
  const pendingTranslation = knowledge.pendingTranslation();
  const contents = knowledge.contents();
  const isArchived = knowledge.archived();
  const { DRAFT, TO_VALIDATE } = Enum.contentStatus;

  const statusObject = {
    status       : 'disabled',
    statusWording:  t('contents:content_details.status.not_saved'),
    tooltip      : undefined,
  };

  const allStatus = contents.items
    .filter(content => !content.archived())
    .map(content => content.statusId());

  if (!id) {
    statusObject.status = 'disabled';
    statusObject.statusWording = t('contents:content_details.status.not_saved');
  } else if (isArchived) {
    statusObject.status = 'inactive';
    statusObject.statusWording = t('contents:content_details.status.archived');
  } else if (!allStatus.length) {
    statusObject.status = 'disabled';
    statusObject.statusWording = t('contents:content_details.status.draft');
  } else if (pendingTranslation) {
    statusObject.status = 'important';
    statusObject.statusWording = t('contents:content_details.status.pending_translation');
  } else if (allStatus.some(status => status === TO_VALIDATE)) {
    statusObject.status = 'warning';
    statusObject.statusWording = t('contents:content_details.status.pending_validation');
  } else if (allStatus.some(status => status === DRAFT)) {
    statusObject.status = 'disabled';
    statusObject.statusWording = t('contents:content_details.status.draft');
  } else {
    statusObject.status = 'active';
    statusObject.statusWording = t('contents:content_details.status.ready');
  }

  return statusObject;
}

function drawNewContentButton(c) {
  function go() {
    var currentGameplays = c.knowledge().contents().map(function returnGameplayId(el) {
      return el.gameplayId();
    });

    popup.open({
      type            : 'selectGameplay',
      knowledgeId     : c.knowledge().id(),
      currentGameplays: currentGameplays,
    });
  }

  var newContentAction = c.canEditKnowledgeRole()
    ? c.showLeaveEditionPopupThenGo.bind(null, go)
    : function noop() {};

  return m('.content-grid__element', [
    m('.content-grid__element__add-button', {
      class: !c.canEditKnowledgeRole() ? 'content-grid__element__add-button--disabled' : '',
    }, [
      m(deviceScreen, {}, [
        m('.content-grid__element__add-button__text__handler', {
          onclick: newContentAction,
        }, [
          m('.content-grid__element__add-button__text', t('contents:content_details.gameplays.add_content')),
        ]),
      ]),
    ]),
  ]);
}

function drawContent(c, content) {
  var go = c.goToContentPage.bind(null, content.id());
  var editContentAction = c.showLeaveEditionPopupThenGo.bind(null, go);

  return m('.content-grid__element', {
    onclick: editContentAction,
  }, [
    m(contentPreview, {
      content: content,
    }),
  ]);
}

export default component;
