// @flow

import { COLORS, TYPOGRAPHY } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '1px 0',
  },
  input: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    outline: 'unset',
    border: 'unset',
    marginRight: 8,
    backgroundColor: 'transparent',
  },
  inputBorderBottom: {
    borderBottom: `1px ${COLORS.GREY_DARK.DEFAULT} dashed`,
  },
  inputTransparentBorderBottom: {
    borderBottom: '1px transparent solid',
  },
};
