import React, { useEffect } from 'react';
import type { FunctionComponent, ReactNode } from 'react';

import BasicAlertList from './BasicAlertList/BasicAlertList';
import ToastAlertList from './ToastAlertList/ToastAlertList';

import { useToastAlerts, useBasicAlerts, reset } from './redux';

export type AlertManagerProps = {
  children: ReactNode;
};

export const AlertManager: FunctionComponent<AlertManagerProps> = ({ children }: AlertManagerProps) => {
  const basicAlerts = useBasicAlerts();
  const toastAlerts = useToastAlerts();

  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  return (
    <>
      <BasicAlertList alerts={basicAlerts} />
      <ToastAlertList alerts={toastAlerts} />
      {children}
    </>
  );
};
