// @flow

/**
 * Display a text in full-width with background color
 *
 * Props:
 *  - content: Text displayed in the component
 *  - backgroundColor: Background color of the component
 *  - textColor: Color of the text
 *  - style: For overriding the component's style
 */

import * as React from 'react';

import styles from './SFullWidthCallout.style';


type Props = {|
  style: Object,
  content: string,
  backgroundColor: string,
  textColor: string,
|};


export class SFullWidthCallout extends React.PureComponent<Props> {
  static defaultProps = {
    style: undefined,
  };

  render() {
    const { style, content, backgroundColor, textColor } = this.props;
    const styleComp = {
      backgroundColor,
      color: textColor,
    };

    return (
      <div style={{ ...styles.wrapper, ...styleComp, ...style }}>
        {content}
      </div>
    );
  }
}

export default SFullWidthCallout;
