/* eslint-disable max-len */

import React from 'react';


export class ProfilePlaceholder extends React.Component {
  static width = 24;
  static height = 24;

  render() {
    const props = this.props;

    return (
      <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <defs>
          <circle id="prefix__a" cx={12} cy={12} r={12} />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="prefix__b" fill="#fff">
            <use href="#prefix__a" />
          </mask>
          <use fill="#EDEDED" fillRule="nonzero" href="#prefix__a" />
          <circle fill="#FFF" mask="url(#prefix__b)" cx={12} cy={8} r={4} />
          <rect
            fill="#FFF"
            mask="url(#prefix__b)"
            x={2.5}
            y={13}
            width={19}
            height={19}
            rx={9.5}
          />
          <path
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-1C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z"
            fill="#EDEDED"
            fillRule="nonzero"
            mask="url(#prefix__b)"
          />
        </g>
      </svg>
    );
  }
}

export default ProfilePlaceholder;
