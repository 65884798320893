import { mergeDeep } from 'Libs/mergeDeep';
import Enum from 'Models/Enum';
import { GAMEPLAY_ID_TO_CONTENT_KEY, GAMEPLAY_KEYS } from 'Components/utils/Enum';

import type { OrderingCardsType } from './OrderingCards';
import type { ContentValidationResult } from './Alert';

import { createOrderingCards, validateOrderingCards, makeOCValidationMessage } from './OrderingCards';

export type GameplayObjectType = OrderingCardsType;

type ValidationMethod = (gameplay: GameplayObjectType) => ContentValidationResult;
type CreationMethod = (values?: any) => GameplayObjectType;
type MessageBuilder = (validation: ContentValidationResult) => string;

type Gameplays = Readonly<{
  QCM: any;
  SQCM: any;
  'Error Text': any;
  'Open Question': any;
  'No Interaction Content': any;
  'Picture Spot': any;
  'Swiping Cards': any;
  'Ordering Cards': OrderingCardsType;
}>;

export type ContentType = Readonly<
  {
    id: number;
    gameplayId: number;
    statusId: number;
    updatedAt: string;
    archived: boolean;
    previewUrl: string;
  } & Gameplays
>;

const creationByGameplayType: { [type: number]: CreationMethod | (() => null) } = {
  [Enum.gameplay.QCM]: () => null,
  [Enum.gameplay.SQCM]: () => null,
  [Enum.gameplay.ERROR_TEXT]: () => null,
  [Enum.gameplay.OPEN_QUESTION]: () => null,
  [Enum.gameplay.NO_INTERACTION_CONTENT]: () => null,
  [Enum.gameplay.PICTURE_SPOT]: () => null,
  [Enum.gameplay.SWIPING_CARDS]: () => null,
  [Enum.gameplay.ORDERING_CARDS]: createOrderingCards,
};

const validateByGameplayType: { [type: number]: ValidationMethod } = {
  [Enum.gameplay.QCM]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.SQCM]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.ERROR_TEXT]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.OPEN_QUESTION]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.NO_INTERACTION_CONTENT]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.PICTURE_SPOT]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.SWIPING_CARDS]: () => ({ fields: [], params: {} }),
  [Enum.gameplay.ORDERING_CARDS]: validateOrderingCards,
};

const validationMessageByGameplayType: { [type: number]: MessageBuilder } = {
  [Enum.gameplay.QCM]: () => '',
  [Enum.gameplay.SQCM]: () => '',
  [Enum.gameplay.ERROR_TEXT]: () => '',
  [Enum.gameplay.OPEN_QUESTION]: () => '',
  [Enum.gameplay.NO_INTERACTION_CONTENT]: () => '',
  [Enum.gameplay.PICTURE_SPOT]: () => '',
  [Enum.gameplay.SWIPING_CARDS]: () => '',
  [Enum.gameplay.ORDERING_CARDS]: makeOCValidationMessage,
};

const defaultGameplays: Gameplays = {
  [GAMEPLAY_KEYS.QCM]: null,
  [GAMEPLAY_KEYS.SQCM]: null,
  [GAMEPLAY_KEYS.ERROR_TEXT]: null,
  [GAMEPLAY_KEYS.OPEN_QUESTION]: null,
  [GAMEPLAY_KEYS.NO_INTERACTION_CONTENT]: null,
  [GAMEPLAY_KEYS.PICTURE_SPOT]: null,
  [GAMEPLAY_KEYS.SWIPING_CARDS]: null,
  [GAMEPLAY_KEYS.ORDERING_CARDS]: createOrderingCards(),
};

const defaultState: ContentType = {
  id: 0,
  gameplayId: 0,
  statusId: 0,
  updatedAt: '',
  archived: false,
  previewUrl: '',
  ...defaultGameplays,
};

const createGameplays = (values: any): Gameplays => {
  const gameplayCreationMethod = creationByGameplayType[values.gameplayId];
  const gameplayKey = GAMEPLAY_ID_TO_CONTENT_KEY[values.gameplayId];

  if (!gameplayCreationMethod || !gameplayKey) {
    return defaultGameplays;
  }

  return {
    ...defaultGameplays,
    [gameplayKey]: gameplayCreationMethod(values[gameplayKey]),
  };
};

export function createContent(values?: any): ContentType {
  if (!values) {
    return defaultState;
  }

  const gameplays = createGameplays(values);

  const content: ContentType = {
    id: values.id,
    gameplayId: values.gameplayId,
    statusId: values.statusId,
    updatedAt: values.updatedAt,
    archived: values.archived,
    previewUrl: values.previewUrl,
    ...gameplays,
  };

  return mergeDeep(defaultState, content);
}

export function validateContent(content: ContentType): ContentValidationResult {
  const { gameplayId } = content;
  const gameplayValidationMethod = validateByGameplayType[gameplayId];
  const gameplayKey = GAMEPLAY_ID_TO_CONTENT_KEY[content.gameplayId];

  if (!gameplayValidationMethod || !gameplayKey) {
    return { fields: ['noMatchingGameplay'] };
  }

  return gameplayValidationMethod(content[gameplayKey]);
}

export function makeValidationMessage(validation: ContentValidationResult, gameplayId: number): string {
  const gameplayMessageBuilder = validationMessageByGameplayType[gameplayId];

  if (!gameplayMessageBuilder) {
    return '';
  }

  return gameplayMessageBuilder(validation);
}
