import Model from 'models/Model';
import Collection from 'models/Collection';
import requestService from 'services/requestService';

var SegmentationItem = {
  endpoint  : 'api/SegmentationItems',
  attributes: {
    label: {
      type: 'string',
    },
    parentId: {
      type: 'number',
    },
    group: {
      type : 'pointer',
      Model: 'SegmentationGroupItem',
    },
    groupId: {
      type: 'number',
    },
    _children: {
      type: 'array',
    },
    _parent: {
      type: 'object',
    },
  },
  methods: {
    children: function children(_SegmentationItem, toSet) {
      if (!toSet)
        return new Collection('SegmentationItem', SegmentationItem, this._children);
      this._children = toSet.items;
    },
    parent: function parent(_SegmentationItem, toSet) {
      if (!toSet)
        return new _SegmentationItem(this._parent);
      this._parent = toSet;
    },
  },
  classMethods: {
    getFull: function getFull(_SegmentationItem) {
      return requestService.get('/api/SegmentationItems/full')
        .then(function handleRes(res) {
          return new Collection('SegmentationItem', SegmentationItem, res);
        });
    },
  },
};

export default Model.register('SegmentationItem', SegmentationItem);
