// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  textContainer: {
    marginBottom: 24,
  },
  title: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
    marginBottom: 6,
  },
  comment: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    whiteSpace: 'pre-wrap',
  },
  listItem: {
    marginTop: 24,
  },
  calloutContainer: {
    marginTop: 16,
  },
};
