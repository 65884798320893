import type { ApiActionType } from 'Libs/redux/utils';
import { apiAction } from 'Libs/redux/utils';
import { AuthenticationSteps } from 'Models/Enum';
import { createSessionAfterApiAction } from 'Services/session.service';

type AdminActivateAction = ApiActionType<'ADMIN_ACTIVATE'>;

export const actions = {
  activate: (invitationToken: string, firstName: string, lastName: string, password: string): AdminActivateAction => {
    const action = apiAction({
      type: 'ADMIN_ACTIVATE',
      route: '/api/authentication/contributors/credentials',
      method: 'POST',
      payload: { invitationToken, firstName, lastName, password },
    });

    // This is the only place to do that because we are mocking store in page so all the store behavior are tested in reducer.test
    // @ts-expect-error async action return a vanilla promise type instead of bluebird
    action.promise?.tap?.((res) => {
      const { type, token, user } = res;

      return type === AuthenticationSteps.USER_SESSION ? createSessionAfterApiAction(user, token) : Promise.resolve();
    });

    return action;
  },
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type SignUpAction = ReturnType<ActionsFn>;
