// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  modal: {
    width: 400,
    height: 315,
    margin: 'auto',
  },
  contentWrapper: {
    padding: '0 20px',
  },
  headingContent: {
    ...TYPOGRAPHY.BODY3,
    marginBottom: 24,
  },
  footerContent: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.ALERT.ERROR,
  },
};

