/* eslint-disable no-console -- logging third party integration with lou */
// @flow

import { loadThirdPartyScript } from 'Libs/thirdParty';

type LouUserInfo = {|
  uniqueId: string,
  instanceId: string,
  roleId: number,
|};

export const LOU_LOG = {
  IDENTIFY: '[Lou] Identifying user',
  IDENTIFY_BEFORE_LOAD: '[Lou] Trying to identify user before Lou has finished loading',
  ERROR: '[Lou] Error:',
};

const LOU_SCRIPT_URL = '//run.louassist.com/v2.5.1-m?id=';
const LOU_API_ID = process.env.SPARTED_LOU_ID || '';
const LOU_SCRIPT_SRC = `${LOU_SCRIPT_URL}${LOU_API_ID}`;

export const MS_BEFORE_LOU_LOGIN_RETRY = 1000;

export const identifyLouUser = ({ uniqueId, instanceId, roleId }: LouUserInfo) => {
  if (window.LOU) {
    console.log(LOU_LOG.IDENTIFY);
    window.LOU.identify(uniqueId, { instanceId, roleId });

    return;
  }

  console.log(LOU_LOG.IDENTIFY_BEFORE_LOAD);
  setTimeout(() => identifyLouUser({ uniqueId, instanceId, roleId }), MS_BEFORE_LOU_LOGIN_RETRY);
};

export const initLou = () => loadThirdPartyScript('lou', LOU_SCRIPT_SRC)
  .catch((error) => console.log(LOU_LOG.ERROR, error));
