import React from 'react';
import moment from 'moment';
import { t } from 'i18next';
import type { CSSProperties } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import UMarkdown from 'Components/unit/UMarkdown/UMarkdown';

import styles from './SDailySessionInfo.style';

const formatDate = (date: string) => moment(date).format('dddd DD/MM/YYYY');

export type SDailySessionInfoProps = {
  sessionNumber: number;
  contentCount: number;
  startDate: string;

  variant?: 'manual' | 'random';
  style?: CSSProperties;
};

export const SDailySessionInfo = ({
  contentCount,
  sessionNumber,
  startDate,
  variant = 'manual',
  style = {},
}: SDailySessionInfoProps) => {
  const containerStyle = {
    ...styles.container,
    ...style,
  };

  const sessionTextStyle = {
    ...styles.sessionContainer,
    ...styles.sessionText,
    ...(variant === 'manual' ? styles.sessionTextManualVariant : {}),
  };

  return (
    <div style={containerStyle}>
      <h3>
        <UMarkdown
          style={sessionTextStyle}
          markdown={t('structural_component:s_daily_session_info.session', { sessionNumber })}
        />
      </h3>
      <div style={styles.dateContainer}>
        <UIcon name="campaign-daily-session" size={20} style={styles.icon} />
        <p>{t('structural_component:s_daily_session_info.available_on', { formatedDate: formatDate(startDate) })}</p>
      </div>
      {variant === 'random' && (
        <div style={styles.contentContainer}>
          <UIcon name="gameplay" size={20} style={styles.icon} />
          <span>{t('structural_component:s_daily_session_info.content', { count: contentCount })}</span>
        </div>
      )}
    </div>
  );
};

export default SDailySessionInfo;
