// @flow

/* eslint-disable max-len */

import React, { useMemo, useState, useCallback } from 'react';
import { t } from 'i18next';

import { useTypedSelector, useTypedDispatch } from 'Libs/redux/utils';
import MAllLanguageTranslation from 'Components/modal/MAllLanguageTranslation/MAllLanguageTranslation';
import SLargeCallout from 'Components/structural/SLargeCallout/SLargeCallout';
import UButton from 'Components/unit/UButton/UButton';
import { COLORS } from 'Components/foundation';

import { actions, useModuleSelector, getNbContentToTranslate, getDefaultActivity } from '../../../redux';

import styles from './TranslationInfo.style.js';

export const TranslationInfo = () => {
  const nbContentToTranslate = useTypedSelector((state) => getNbContentToTranslate(state));

  const isAskKnowledgeTranslationLoading = useModuleSelector((state) => state.isAskKnowledgeTranslationLoading);
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
  const onButtonClick = useCallback(() => setIsTranslationModalOpen(true), []);
  const onClose = useCallback(() => setIsTranslationModalOpen(false), []);

  const dispatch = useTypedDispatch();
  const selectedLanguageId = useModuleSelector((state) => state.selectedLanguageId);
  const defaultActivity = useTypedSelector(getDefaultActivity);
  const knowledgeIds = useMemo(() => {
    return defaultActivity.dailySerie.days.reduce((acc, day) => {
      return acc.concat(day.dailySerieContents.map((dsContent) => dsContent.content.knowledgeId));
    }, []);
  }, [defaultActivity]);
  const onConfirm = useCallback(() => {
    const action = actions.askTranslationBatch(knowledgeIds, [selectedLanguageId]);

    dispatch(action);

    // INFO it is an antipattern to access the internal 'promise' field of the action
    // instead of relying on the flags in redux state.
    // However we make an exception here as several components may dispatch this action
    // and it would require tracking each request in the redux state.
    action.promise.then(() => setIsTranslationModalOpen(false));
  }, [dispatch, selectedLanguageId, knowledgeIds]);

  if (!nbContentToTranslate)
    return null;

  return (
    <>
      <SLargeCallout
        color={COLORS.PRIMARY.DEFAULT}
        style={styles.largeCallout}
      >
        <div style={styles.containerCallout}>
          <div style={styles.textCallout}>
            <span style={styles.nbContentToTranslate}>
              {t('activities:scheduling.contents_requiring_translation', { count: nbContentToTranslate })}
            </span>
            <span style={styles.notice}>
              {t('activities:scheduling.translation_notice')}
            </span>
          </div>
          <UButton
            text={t('activities:scheduling.translate_contents', { count: nbContentToTranslate })}
            onClick={onButtonClick}
            type="accentuated"
            style={styles.button}
          />
        </div>
      </SLargeCallout>
      <MAllLanguageTranslation
        visible={isTranslationModalOpen}
        isLoading={isAskKnowledgeTranslationLoading}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
};
