import React from 'react';

import LockedRow from 'Components/structural/STable/components/LockedRow';
import { getCallbackOptionParams } from 'Components/structural/STable/utils';
import styles from 'Components/structural/STable/STable.style';
import { UCircularButton } from 'Components/unit';
import { STableRow } from 'Components/structural/STable/types';

export type CallbackOption = {
  icon: string;
  id: string | number;
  callback: (...args: any[]) => void;
};

type CallbackOptionsProps = {
  row: STableRow['original'];
  callbackOptions?: CallbackOption[];
};

function CallbackOptions({ row, callbackOptions }: CallbackOptionsProps) {
  const { locked } = row;

  if (locked) return <LockedRow row={row} />;

  if (!callbackOptions || !callbackOptions.length) return null;

  return (
    <div className="stable--callbackWrapper">
      {callbackOptions.map((callbackOption: CallbackOption) => {
        const optionParams = getCallbackOptionParams(row);

        return (
          <UCircularButton
            key={callbackOption.id}
            icon={callbackOption.icon}
            onClick={callbackOption.callback.bind(null, optionParams)}
            style={styles.callbackOption}
          />
        );
      })}
    </div>
  );
}

export default CallbackOptions;
