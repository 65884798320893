import { createStyleSheet } from 'Components/foundation/stylesheets';
import { COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  clickableArea: {
    cursor: 'pointer',
    padding: 8,
  },
  disabledClickableArea: {
    cursor: 'default',
  },
  radioButton: {
    borderRadius: '50%',
    cursor: 'pointer',
  },
  label: {
    marginLeft: 0,
  },
  linearWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioButtonDisabled: {
    borderRadius: '50%',
    userSelect: 'none',
    cursor: 'default',
  },
  insideButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  disabledButton: {
    backgroundColor: COLORS.DISABLED.DEFAULT,
  },
  accentuatedButton: {
    backgroundColor: COLORS.PRIMARY.DEFAULT,
  },
  standardButton: {
    backgroundColor: COLORS.GREY_DARKER.DEFAULT,
  },
  buttonSizeS: {
    position: 'relative',
    height: 14,
    width: 14,
  },
  buttonInsideSizeS: {
    height: 9,
    width: 9,
  },
  buttonSizeM: {
    position: 'relative',
    height: 18,
    width: 18,
  },
  buttonInsideSizeM: {
    height: 13,
    width: 13,
  },
  buttonSizeL: {
    position: 'relative',
    height: 24,
    width: 24,
  },
  buttonInsideSizeL: {
    height: 19,
    width: 19,
  },
  isInactive: {
    backgroundColor: 'transparent',
    borderStyle: 'none',
  },
});

export default styleSheet;
