import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QueueContexts, useQueue } from 'Libs/queue';
import { store, useAppDispatch } from 'Libs/redux/store';
import { redirect } from 'Libs/routing/redirect';
import { reload } from 'Libs/routing/reload';
import Enum from 'Models/Enum';

import { Language } from 'Services/language/languageService';
import { isFeatureEnabled } from 'Services/feature-flags';

import MActionDialog from 'Components/modal/MActionDialog/MActionDialog';
import MCourseForm from 'Components/modal/MCourseForm/MCourseForm';
import MCoursePublication from 'Components/modal/MCoursePublication/MCoursePublication';
import MFileUpload from 'Components/modal/MFileUpload/MFileUpload';
import SPageLoaderWithTimer from 'Components/structural/SPageLoaderWithTimer/SPageLoaderWithTimer';
import { PDF_MIME_TYPES } from 'Components/utils/Enum';

import type { CommandsRequest } from 'Pages/Courses/utils/commands';
import { transformBlockToMicroKnowledge } from 'Pages/Courses/utils/creation-handler';
import {
  getHandleCourseUpdate,
  getHandleInsertMk,
  getHandleMkQueueItemProcess,
} from 'Pages/Courses/utils/edition-handlers';

import AudienceGroupList from 'Features/audience/pages/AudienceGroupList/AudienceGroupList';
import { enqueueBasicAlert, removeBasicAlert } from 'Pages/AlertManager/redux/utils';
import { usePublishCourseWithModal } from 'Pages/Courses/hooks/course-publish';
import {
  actions,
  fetchAudienceMetadata,
  fetchDraftCourse,
  fetchPdf,
  downloadCourseProgressExport,
  useLastEditor,
  useModuleSelector,
} from 'Pages/Courses/redux';
import type { CourseFormData } from 'Pages/Courses/types';

import styles from './CourseEdition.styles';
import PDFViewer from './components/PDFViewer/PDFViewer';
import CourseEditionBar from './components/CourseEditionBar/CourseEditionBar';
import { geEditionBarHeight } from './components/CourseEditionBar/CourseEditionBar.style';
import { useAudienceGroupListProps } from './hooks/useAudienceGroupListProps';
import { useCourseEditionTabs } from './hooks/useCourseEditionTabs';
import { Microknowledges } from './tabs/Microknowledges/Microknowledges';
import { PdfBlockForCourse } from 'Libs/pdf-extraction/pdf-to-course';

const UPLOAD_ERROR: Record<string, string> = {};

export type CourseEditionProps = {
  languages: Language[];
};

export const CourseEdition: FunctionComponent<CourseEditionProps> = ({ languages }) => {
  const canSeeAudience = isFeatureEnabled('course-audience');
  const withPdfExtraction = isFeatureEnabled('course-pdf-extraction');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { tabs, currentTabIndex, idParam, currentTab, goToTab } = useCourseEditionTabs();

  const courseDataId = useModuleSelector((state) => state.edition.course?.dataId);
  const course = useModuleSelector((state) => state.edition.course);
  const groupIds = useModuleSelector((state) => state.edition.groupIds);
  const pages = useModuleSelector((state) => state.edition.pdfPages);
  const pdfIds = useModuleSelector((state) => state.edition.pdfIds);
  const pdfVisible = pages && pages.length > 0;

  const {
    id: courseId,
    title: courseTitle,
    description: courseDescription,
    language: courseLanguage,
    coverImageUrl,
  } = course || {};

  const queueHandler = useMemo(() => getHandleMkQueueItemProcess(store), []);
  const { push, flush, isQueueLoading } = useQueue<Omit<CommandsRequest, 'editionId'>>(
    QueueContexts.COURSE_EDITION,
    queueHandler,
  );

  // Reset page at unmount
  useEffect(
    () => () => {
      dispatch(actions.resetEdition());
      dispatch(actions.resetErrors());
      flush();
    },
    [dispatch, flush],
  );

  const loading = useModuleSelector((state) => state.edition.loading);
  const hasEditionIdMismatchError = useModuleSelector((state) => state.errors.hasEditionIdMismatchError);
  const hasEditionError = useModuleSelector((state) => state.errors.hasEditionError);

  // Check if the title is correct when set from modal
  const [hasTitleError, setHasTitleError] = useState(false);

  // Compute autosave status
  const saveSuccessfulStatus = hasEditionIdMismatchError || hasTitleError || hasEditionError ? 'alert' : 'saved';
  const saveStatus = isQueueLoading ? 'saving' : saveSuccessfulStatus;

  const isPublishButtonDisabled = hasTitleError || isQueueLoading;

  // Load course details and microknowledges
  useEffect(() => {
    if (courseId === idParam) {
      return () => {};
    }

    const promise = dispatch(fetchDraftCourse(idParam));

    promise.catch((e: any) => {
      // eslint-disable-next-line no-console -- Error is already handled with redux
      console.error('Something went wrong while retrieving course details', e);

      enqueueBasicAlert({
        id: 'COURSE_EDITION_FETCH_ERROR',
        text: t('courses:course_edition.error_text'),
        title: t('courses:course_edition.error_title'),
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, courseId, idParam]);

  const language = useMemo(
    () => languages.find((l) => l.languageCode === course?.language),
    [course?.language, languages],
  );
  const languageIds = useMemo(() => (language ? [language.availableGroupId!] : []), [language]);

  useEffect(() => {
    dispatch(
      fetchAudienceMetadata({
        languageIds,
        groupIds: groupIds as number[],
      }),
    );
  }, [dispatch, groupIds, languageIds]);

  const { modalTitle, modalButtonLabel } = useMemo(
    () =>
      courseId
        ? {
            modalTitle: t('courses:course_edition.update_modal_title'),
            modalButtonLabel: t('courses:course_edition.update_modal_button'),
          }
        : {
            modalTitle: t('courses:course_edition.create_modal_title'),
            modalButtonLabel: t('courses:course_edition.create_modal_button'),
          },
    [courseId],
  );

  const initialCourseData = useMemo(
    () => ({
      title: hasTitleError ? '' : courseTitle,
      description: courseDescription,
      image: coverImageUrl ? { url: coverImageUrl } : undefined,
      language: courseLanguage,
    }),
    [courseTitle, courseDescription, coverImageUrl, courseLanguage, hasTitleError],
  );

  const lastEditor = useLastEditor();

  const handleCourseUpdate = useMemo(() => getHandleCourseUpdate({ store, enqueue: push }), [push]);
  const handleInsertMk = getHandleInsertMk({ store, enqueue: push });

  const handleInsertBlockAsMk = async (block: PdfBlockForCourse) => {
    const mkToInsert = await transformBlockToMicroKnowledge(block);
    handleInsertMk({ targetId: null, ...mkToInsert });
  };

  const handleUpdateImage = useCallback(
    async (localImage) => {
      if (courseDataId) {
        handleCourseUpdate({
          courseDataId,
          image: localImage,
        });
      }
    },
    [courseDataId, handleCourseUpdate],
  );

  const {
    publishDialogCourseData,
    handlePublish,
    handleUnpublish,
    handleUnpublishButton,
    handlePublishButton,
    closePublishModal,
    publishModalState,
  } = usePublishCourseWithModal('edition', course, {
    ...lastEditor,
    editionDate: lastEditor?.lastEditedDate,
  });

  const [courseFormVisible, setCourseFormVisible] = useState(false);
  const handleCourseFormOpen = useCallback(() => setCourseFormVisible(true), []);
  const handleCourseFormClose = useCallback(() => setCourseFormVisible(false), []);

  const [courseFileVisibile, setCourseFileVisible] = useState(false);
  const handleCourseFileOpen = useCallback(() => setCourseFileVisible(true), []);
  const handleCourseFileClose = useCallback(() => setCourseFileVisible(false), []);

  const handleEndFileUpload = useCallback((result) => {
    handleCourseFileClose();
    const { pdf } = result;

    if (!pdf || !pdf.pages || !pdf.id) {
      enqueueBasicAlert({
        id: 'UPLOAD_PDF_ERROR',
        text: 'An error occured while uploading the pdf.',
        title: 'Something went wrong',
        icon: 'alert',
        status: 'error',
        priority: 'low',
      });

      return;
    }

    const { pages, id } = pdf;

    dispatch(actions.savePdfPages({ pages, id }));
  }, []);

  useEffect(() => {
    if (!pdfIds?.length || !course) return;

    dispatch(fetchPdf(course.id));
  }, [dispatch, pdfIds]);

  const mkWrapperStyle = useMemo(() => (!pdfVisible ? styles.mkWrapper : styles.mkWrapperWithPdf), [pdfVisible]);

  const containerStyle = useMemo(() => (!pdfVisible ? {} : styles.container), [pdfVisible]);

  const formattedCourseFormLanguages = useMemo(
    // format languages to use in MCourseForm
    () =>
      languages.map(({ languageCode, languageName, ...rest }) => ({
        ...rest,
        label: languageName,
        value: languageCode,
      })),
    [languages],
  );

  const handleBackButton = useCallback(() => redirect('/courses'), []);

  const handleCourseUpdateForm = useCallback(
    async (formData: CourseFormData) => {
      const { title, ...restFormData } = formData;

      removeBasicAlert('COURSE_EDITION_TITLE_ERROR');
      setHasTitleError(!title);

      if (!title) {
        enqueueBasicAlert({
          id: 'COURSE_EDITION_TITLE_ERROR',
          text: t('courses:course_edition.title_error_text'),
          title: t('courses:course_edition.title_error_title'),
          icon: 'alert',
          status: 'error',
          priority: 'low',
        });
      }

      if (courseDataId) {
        handleCourseUpdate({
          courseDataId,
          ...(title ? { title } : {}),
          ...(restFormData as CourseFormData),
        });
      }
    },
    [courseDataId, handleCourseUpdate],
  );

  const { audienceGroupListProps, confirmBeforeAudienceAssignmentDialog } = useAudienceGroupListProps();

  const handleDownloadExport = useCallback(() => {
    if (!course) {
      return;
    }
    dispatch(downloadCourseProgressExport(course.id));
  }, [course]);

  return (
    <>
      <SPageLoaderWithTimer isLoading={loading} style={styles.loader} />
      {course && (
        <>
          <CourseEditionBar
            saveStatus={saveStatus}
            courseId={course.id}
            title={course.title}
            imageUrl={course.coverImageUrl}
            languageCountry={Enum.Languages[course.language as keyof typeof Enum.Languages]}
            hasPendingUpdates={course.statuses?.draft}
            hasPublishedVersion={course.statuses?.published}
            availableLanguages={languages}
            tabs={tabs}
            selectedTab={currentTabIndex}
            onBack={handleBackButton}
            onPublish={handlePublishButton}
            onUnpublish={handleUnpublishButton}
            onEditClick={handleCourseFormOpen}
            onCourseUpdate={handleCourseUpdateForm}
            canPublish={!isPublishButtonDisabled}
            onChangeTab={goToTab}
            publicationDate={course.lastPublicationDate}
            isCourseStarted={course.started}
            onDownloadProgressExport={handleDownloadExport}
          />
          <div style={{ marginTop: geEditionBarHeight({ canSeeAudience }) }}>
            {currentTab === 'course-microknowledges' && (
              <div style={containerStyle}>
                {pdfVisible && <PDFViewer handleBlockSelect={handleInsertBlockAsMk} />}

                <Microknowledges
                  push={push}
                  uploadButtonVisible={withPdfExtraction && !pdfVisible}
                  onClickUpload={handleCourseFileOpen}
                  style={mkWrapperStyle}
                  styleList={!pdfVisible ? {} : styles.mkListWithPdf}
                />
              </div>
            )}

            {currentTab === 'course-audience' && canSeeAudience && (
              <AudienceGroupList
                entityId={course.id}
                assignedAudienceGroupIds={groupIds}
                language={language!}
                {...audienceGroupListProps}
              />
            )}
          </div>
        </>
      )}

      {courseFileVisibile && !!course?.id && (
        <MFileUpload
          title="Upload PDF list"
          description="Upload the pdf here."
          visible
          onClose={handleCourseFileClose}
          onEndUpload={(result) => handleEndFileUpload(result)}
          uploadUrl={`/api/courses/${course.id}/extract-pdf`}
          uploadErrors={UPLOAD_ERROR}
          format={['pdf']}
          maxFileSize={10000000}
          allowedMimeTypes={[PDF_MIME_TYPES.PDF]}
          withMockedUpload={withPdfExtraction}
        />
      )}

      {courseFormVisible && (
        <MCourseForm
          visible
          onClose={handleCourseFormClose}
          onConfirm={handleCourseUpdateForm}
          availableLanguages={formattedCourseFormLanguages}
          initialData={initialCourseData}
          modalTitle={modalTitle}
          modalConfirmButtonLabel={modalButtonLabel}
        />
      )}
      {publishDialogCourseData && courseDataId && publishModalState.isOpened && (
        <MCoursePublication
          visible={publishModalState.isOpened}
          courseData={publishDialogCourseData}
          isPublished={publishModalState.isPublished}
          onConfirm={publishModalState.isPublished ? handlePublish : handleUnpublish}
          onClose={closePublishModal}
          onImageSelect={handleUpdateImage}
          canPublish={!isQueueLoading}
        />
      )}
      <MActionDialog
        data-test-id="action-dialog-edition-id-mismatch"
        visible={hasEditionIdMismatchError}
        title={t('courses:course_edition.edition_mismatch_title')}
        description={t('courses:course_edition.edition_mismatch_description')}
        primaryActionLabel={t('courses:course_edition.refresh')}
        onPrimaryAction={reload}
        iconName="refresh"
        secondaryActionLabel={t('courses:course_edition.know_more')}
      />
      <MActionDialog
        data-test-id="action-dialog-unknown-error"
        visible={hasEditionError}
        title={t('courses:course_edition.unknown_error_title')}
        description={t('courses:course_edition.unknown_error_description')}
        primaryActionLabel={t('courses:course_edition.refresh')}
        onPrimaryAction={reload}
        iconName="refresh"
      />
      {confirmBeforeAudienceAssignmentDialog}
    </>
  );
};

export default CourseEdition;
