import { TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  inputWidth: {
    width: '194px',
  },
  language: {
    marginLeft: 16,
  },
  micro: {
    textAlign: 'center',
    width: '100%',
    padding: '2px 7px',
  },
  textField: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    outline: 'none',
    paddingRight: 16,
    paddingLeft: 5,
    ...TYPOGRAPHY.BODY3,
  },
  mini: {
    height: 24,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
  },
  small: {
    height: 30,
    paddingTop: 6,
    paddingBottom: 6,
  },
  large: {
    height: 42,
    paddingTop: 11,
    paddingBottom: 11,
  },
});

export default styleSheet;
