import { utc as moment } from 'moment';
import { DailySerieType } from 'Pages/Activity/redux/models/DailySerie';

// INFO This function is used both in common and client
export function getDailySerieEndDate(dailyserie: DailySerieType, startDate: string, numExtraDays = 0) {
  const weekDays = dailyserie.weekDays.map((weekDay) => weekDay.id);
  const disabledDays = dailyserie.dailySerieDayDisabled.map((day) => moment(day.date).format('YYYY-MM-DD'));
  const { days } = dailyserie;

  if (!weekDays.length || !days.length) {
    return moment(startDate).endOf('day');
  }

  let endDate: moment.Moment | string = moment(startDate).startOf('day').subtract(1, 'days');
  let nbDaysToPlay = days.length + numExtraDays;

  while (nbDaysToPlay > 0) {
    endDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

    const isDayDisabled = disabledDays.indexOf(endDate) !== -1 || weekDays.indexOf(moment(endDate).isoWeekday()) === -1;

    if (!isDayDisabled) {
      nbDaysToPlay -= 1;
    }
  }

  return moment(endDate).endOf('day');
}

// INFO This function is used both in common and client
export function getDailySerieNumExtraDays(dailyserie: DailySerieType, startDate: string, endDate: string) {
  const weekDays = dailyserie.weekDays.map((weekDay) => weekDay.id);
  const disabledDays = dailyserie.dailySerieDayDisabled.map((day) => moment(day.date).format('YYYY-MM-DD'));

  if (!weekDays.length) {
    return 0;
  }

  let date = getDailySerieEndDate(dailyserie, startDate, 0);
  let numExtraDays = 0;

  while (date.isBefore(moment(endDate), 'day')) {
    const dateString = moment(date).add(1, 'days').format('YYYY-MM-DD');
    const isDayDisabled = disabledDays.indexOf(dateString) !== -1
      || weekDays.indexOf(moment(dateString).isoWeekday()) === -1;

    if (!isDayDisabled) {
      numExtraDays += 1;
    }

    date = moment(date).add(1, 'days');
  }

  return numExtraDays;
}

// INFO This function is used both in common and client
export function getDateForDailySerieDay(dailyserie: DailySerieType, startDate: string, order: number) {
  const weekDays = dailyserie.weekDays.map((weekDay) => weekDay.id);
  const disabledDays = dailyserie.dailySerieDayDisabled.map((day) => moment(day.date).format('YYYY-MM-DD'));

  if (!weekDays.length) {
    return moment(startDate).startOf('day');
  }

  let dateIterator: moment.Moment | string = moment(startDate).startOf('day').subtract(1, 'days');
  let index = 0;

  while (index < order) {
    dateIterator = moment(dateIterator).add(1, 'days').format('YYYY-MM-DD');

    const isDayDisabled = disabledDays.indexOf(dateIterator) !== -1 || weekDays.indexOf(moment(dateIterator).isoWeekday()) === -1;

    if (!isDayDisabled) {
      index += 1;
    }
  }

  return moment(dateIterator).startOf('day');
}

export function getNbDaysPlayerHasBeenExposed(dailyserie: DailySerieType, startDate: string, endDate: string, playerTimezone: number) {
  const weekDays = dailyserie.weekDays.map((weekDay) => weekDay.id);
  const disabledDays = dailyserie.dailySerieDayDisabled.map((day) => moment(day.date).format('YYYY-MM-DD'));

  if (!weekDays.length) {
    return 0;
  }

  const playerDate = moment().subtract(playerTimezone, 'minutes').format('YYYY-MM-DD');

  if (moment(playerDate).isAfter(moment(endDate))) {
    return dailyserie.days.length;
  }

  let dateIterator = moment(startDate).startOf('day').subtract(1, 'days').format('YYYY-MM-DD');
  let nbDayExposed = 0;

  while (nbDayExposed < dailyserie.days.length && moment(dateIterator).isBefore(moment(playerDate))) {
    dateIterator = moment(dateIterator).add(1, 'days').format('YYYY-MM-DD');

    const isDayDisabled = disabledDays.indexOf(dateIterator) !== -1 || weekDays.indexOf(moment(dateIterator).isoWeekday()) === -1;

    if (!isDayDisabled) {
      nbDayExposed += 1;
    }
  }

  return nbDayExposed;
}
