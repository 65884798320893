import React, { useState, useEffect } from 'react';
import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { t } from 'i18next';

import SNewContentCard from 'Components/structural/SNewContentCard/SNewContentCard';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import { LeftBorderContainer } from './components/LeftBorderContainer';
import { SlimContainer } from './components/SlimContainer';
import { Header } from './components/Header';

import styles from './SDailySessionContainer.style';

const NewContentCardWithToolTip = withTooltip(SNewContentCard);

export type SDailySessionContainerProps = {
  sessionNumber: number;
  contentCount: number;
  startDate: string;

  isScheduleEmpty?: boolean;
  draggingOver?: boolean;
  disabledAdd?: boolean;
  disabledDelete?: boolean;
  locked?: boolean;
  variant?: 'manual' | 'random';
  style?: CSSProperties;

  onAdd?: () => any;
  onDelete?: () => any;
};

export const SDailySessionContainer: FunctionComponent<SDailySessionContainerProps> = ({
  contentCount,
  sessionNumber,
  startDate,
  variant = 'manual',
  children,
  locked,
  isScheduleEmpty,
  disabledAdd,
  disabledDelete,
  draggingOver,
  onAdd,
  onDelete,
  style,
}) => {
  const [mouseHover, setMouseHover] = useState(false);

  const onMouseHover = () => setMouseHover(true);
  const onMouseLeave = () => setMouseHover(false);

  const manualVariant = variant === 'manual';

  const [localIsScheduleEmpty, setLocalIsScheduleEmpty] = useState(false);

  useEffect(() => {
    setLocalIsScheduleEmpty(React.Children.count(children) === 0);
  }, [children]);

  const newContentCardType = localIsScheduleEmpty ? 'first_campaign_scheduling' : 'campaign_scheduling';

  const containerStyle = {
    ...(draggingOver ? styles.draggingOver : {}),
    ...style,
  };

  const showDeleteButton = mouseHover && !locked && !disabledDelete;
  const shouldHideAddButton = locked && !isScheduleEmpty;

  const Container = manualVariant ? LeftBorderContainer : SlimContainer;

  return (
    <Container onMouseEnter={onMouseHover} onMouseLeave={onMouseLeave} style={containerStyle}>
      <Header
        contentCount={contentCount}
        sessionNumber={sessionNumber}
        variant={variant}
        startDate={startDate}
        showDeleteButton={showDeleteButton}
        onDelete={onDelete}
      />
      {manualVariant && (
        <div id={String(sessionNumber)} className="container-content" style={styles.contentList}>
          {!shouldHideAddButton && (
            <NewContentCardWithToolTip
              onClick={onAdd}
              type={disabledAdd ? 'disabled' : newContentCardType}
              label={contentCount === 0 ? t('structural_component:s_daily_session_container.add_first_content') : ''}
              tooltipPosition="top"
              tooltipContent={t('structural_component:s_daily_session_container.add_first_content_tooltip')}
              tooltipEnabled={disabledAdd}
            />
          )}
          {children}
        </div>
      )}
    </Container>
  );
};

export default SDailySessionContainer;
