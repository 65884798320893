import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  back: {
    position: 'absolute',
    top: 72,
  },
});

export default styleSheet;
