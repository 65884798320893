import COLORS from './colors';

export type Typography = {
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
  color: string;
  fontWeight?: number;
};

export default {
  H1: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H1_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H1_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H2: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H2_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H2_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H3: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H3_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H3_BOLD: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
    fontWeight: 700,
  },
  BODY1: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '25px',
  },
  BODY1_SEMIBOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '25px',
  },
  BODY1_BOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '25px',
  },
  BODY2: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '20px',
  },
  BODY2_SEMIBOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '20px',
  },
  BODY2_BOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '20px',
  },
  BODY3: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '18px',
  },
  BODY3_SEMIBOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '18px',
  },
  BODY3_BOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '18px',
  },
  BODY4: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '15px',
  },
  BODY4_SEMIBOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '15px',
  },
  BODY4_BOLD: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: COLORS.TEXT.DEFAULT,
    lineHeight: '15px',
  },
  CAPTION: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_M: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_M_MEDIUM: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_M_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_M_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_L: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '25px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_L_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_L_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XL: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XL_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XL_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XXL: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '50px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XXL_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '50px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  TITAN_XXL_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '50px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_S: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_S_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_S_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_M: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_M_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_M_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_L: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_L_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_L_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_XL: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_XL_SEMIBOLD: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  HERO_XL_BOLD: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  INDICATOR: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
} as const;
