import React from 'react';
import type { CSSProperties } from 'react';
import { t } from 'i18next';

import UIconButton from 'Components/unit/UIconButton/UIconButton';
import SLanguageSelector, { LanguageOptions } from 'Components/structural/SLanguageSelector/SLanguageSelector';
import UButton from 'Components/unit/UButton/UButton';
import SOptionsMenu from 'Components/structural/SOptionsMenu/SOptionsMenu';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import styles from './SContentEditionBar.style';

const UIconButtonWithTooltip = withTooltip(UIconButton);

export type SContentEditionBarProps = {
  style?: CSSProperties;
  title: string;
  lastSavedDate: string;
  identifier: string;
  selectedLanguage: LanguageOptions[number];
  languageOptions: LanguageOptions;
  disabledTranslation?: boolean;
  onContentSave: () => void;
  onValidate: () => void;
  onArchiveContent: () => void;
  onSeeContributors: () => void;
  onRequestTranslation: () => void;
  onBack: () => void;
  validateButtonLabel?: string;
};

const getOptionsMenu = (
  onArchiveContent: SContentEditionBarProps['onArchiveContent'],
  onSeeContributors: SContentEditionBarProps['onSeeContributors'],
) => [
  {
    id: 1,
    text: t('content_translation:content_edition_bar.archive_content'),
    onClick: () => onArchiveContent(),
  },
  {
    id: 2,
    text: t('content_translation:content_edition_bar.see_contributors'),
    onClick: () => onSeeContributors(),
  },
];

export const SContentEditionBar = ({
  style = {},
  title,
  lastSavedDate,
  identifier,
  selectedLanguage,
  languageOptions,
  disabledTranslation = false,
  onContentSave,
  onValidate,
  onBack,
  onArchiveContent,
  onSeeContributors,
  onRequestTranslation,
  validateButtonLabel,
}: SContentEditionBarProps) => {
  const optionsObject = getOptionsMenu(onArchiveContent, onSeeContributors);

  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div style={styles.bar}>
        <div style={styles.sideContainer}>
          <UIconButton style={styles.arrow} icon="arrow-left" onClick={onBack} />
          <div style={styles.contentInfo}>
            <div style={styles.title}>{title}</div>
            <div style={styles.identifier}>{identifier}</div>
          </div>
          <div style={styles.languageWrapper}>
            <SLanguageSelector
              style={styles.languageSelector}
              selectedLanguage={selectedLanguage}
              languageOptions={languageOptions}
              onAction={onRequestTranslation}
            />
          </div>
        </div>
        <div style={styles.sideContainer}>
          <UIconButtonWithTooltip
            tooltipEnabled
            tooltipMaxWidth={330}
            tooltipPosition="bottom"
            tooltipContent={t('content_translation:content_edition_bar.validate_translation_tooltip')}
            disabled
            icon="eye"
            ghost
          />
          <div style={styles.wrapperDivider}>
            <UButton
              disabled={disabledTranslation}
              text={validateButtonLabel}
              style={styles.translationButton}
              onClick={onValidate}
            />
          </div>
          <div style={styles.lastSavedSection}>
            {t('content_translation:content_edition_bar.last_saved', { lastSavedDate })}
          </div>
          <UButton type="accentuated" text="Save" style={styles.saveButton} onClick={onContentSave} />
          <SOptionsMenu style={styles.optionsMenu} options={optionsObject} />
        </div>
      </div>
    </div>
  );
};

export default SContentEditionBar;
