import { COLORS, LEVELS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

export const inputContainerStyle = createStyleSheet({
  small: {
    height: 30,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 11,
    paddingRight: 21,
  },
  large: {
    height: 40,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 15,
    paddingRight: 21,
  },
});

const styles = createStyleSheet({
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  headerContainer: {
    width: '100%',
    minWidth: '30px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
    cursor: 'pointer',
  },
  headerContent: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    ...TYPOGRAPHY.BODY3,
    flex: 1,
    backgroundColor: 'transparent',
  },
  focusedHeader: {
    borderColor: COLORS.GREY_DARK.OUTLINE_FOCUSED,
  },
  iconContainer: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'center',
  },
  itemBox: {
    width: '100%',
    borderRadius: 4,
    padding: 0,
    margin: 0,
    marginTop: 4,
    position: 'absolute',
    zIndex: 999,
    top: '100%',
    display: 'block',
    border: `1px solid ${COLORS.CHIPS.DEFAULT}`,
    backgroundColor: COLORS.WHITE.DEFAULT,
    maxHeight: 300,
    overflow: 'auto',
    outline: 'none',
  },
  item: {
    height: 31,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemActive: {
    backgroundColor: COLORS.GREY_MEDIUM.HOVER,
  },
  dropdown: {
    position: 'relative',
  },
  dropdownItem: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    zIndex: LEVELS.LOCAL_OFFSET,
    opacity: 0.2,
  },
  notFound: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    fontWeight: 400,
    paddingLeft: 12,
  },
  label: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.DEFAULT,
    fontWeight: 700,
    position: 'absolute',
    top: -23,
    left: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '70%',
  },
});

export default styles;
