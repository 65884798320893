import type { SearchSortOrder } from '@sparted/shared-library';

export type GenericFilterCategory =
  | 'activityStatus'
  | 'activityType'
  | 'campaign'
  | 'contentStatus'
  | 'dolphinContentStatus'
  | 'segment'
  | 'thematic'
  | 'translationStatus'
  | 'user'
  | 'category'
  | 'documentStatus'
  | 'language'
  | 'audienceMode'
  | 'audienceGroup'
  | 'sort';

export type GenericFilterCategoryAndText = GenericFilterCategory | 'text';

export type FilterCategoryWithLabel = {
  value: string;
  label: string;
};

export type FilterCategory = GenericFilterCategoryAndText | FilterCategoryWithLabel;

export type FilterType = 'text' | 'select' | 'multiselect' | 'date';

export type FilterItem = {
  category: FilterCategory;
  selected?: boolean;
  value: string;
  color?: string;
  children?: Array<FilterItem>;
  dimensionId?: number;
  dimensionLabel?: string;
  id: number;
  field?: string;
  order?: SearchSortOrder;
};

export type Filter = {
  category: FilterCategory;
  type: FilterType;
  items: Array<FilterItem>;
};

export type GenericFilterState = {
  category: GenericFilterCategory | FilterCategoryWithLabel;
  ids: Array<number>;
};

export type TextFilterState = {
  category: 'text';
  values: Array<string>;
};

export type FilterState = GenericFilterState | TextFilterState;

export const isCategoryWithLabel = (category: any): category is FilterCategoryWithLabel => category.value !== undefined;

export const isTextFilter = (filter: any): filter is TextFilterState => filter.category === 'text';
