import { useDraggable, useDroppable } from '@dnd-kit/core';
import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import type { CSSProperties } from 'react';

import SOptionsMenu from 'Components/structural/SOptionsMenu/SOptionsMenu';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import { COLORS } from 'Components/foundation';
import type { SOptionsMenuProps } from 'Components/structural/SOptionsMenu/SOptionsMenu';
import { usePrevious } from 'Hooks/usePrevious/usePrevious';

import styles from './SMicroKnowledgeListItem.style';

export type SMicroKnowledgeListItemMode = 'standard' | 'accentuated';

export type SMicroKnowledgeListItemProps = {
  id: string;
  children: ReactNode;
  contextMenuOptions: SOptionsMenuProps['options'];
  backgroundColor: CSSProperties['backgroundColor'];
  style?: CSSProperties;
  onAdd?: (id: string) => void;
  isSelected?: boolean;
  hasError?: boolean;
  isChild?: boolean;
};

/*
 * SMicroKnowledgeItemListItem:
 * Component that works as a wrapper for the children MicroKnowledge components (SText, SChapter, ..)
 * Props:
 * - id: number to distinguish one MicroKnowledge from another
 * - children: The children to render (which are encapsulated by the parent SMicroKnowledgeItemListItem)
 * - contextMenuOptions: The options which the SOptionsMenu displays
 * - backgroundColor: color which is applied to the container
 * - style: to modify the component's style
 * - onAdd: (The plus icon onClick) callback to add a new Chapter or a SText
 * - isSelected: boolean which states if the component is selected or not
 * - hasError: boolean that states whether actual component has an error or not
 * - isChild: boolean that states whether actual component is child or not
 */

export const SMicroKnowledgeListItem = ({
  id,
  children,
  contextMenuOptions,
  isSelected = false,
  hasError = false,
  isChild = false,
  backgroundColor = COLORS.PRIMARY.LIGHTER,
  style = {},
  onAdd,
}: SMicroKnowledgeListItemProps) => {
  const [isHover, setIsHover] = useState(false);
  const handlePlusClick = useCallback(() => onAdd?.(id), [onAdd, id]);
  const handleLeave = useCallback(() => setIsHover(false), []);
  const handleHover = useCallback(() => setIsHover(true), []);

  const plusStyle = useMemo(() => {
    if (isHover) {
      return isChild ? styles.visibleChildPlus : styles.visiblePlus;
    }

    return styles.invisiblePlus;
  }, [isChild, isHover]);

  const { active, attributes, listeners, setNodeRef: setDraggableNodeRef, setActivatorNodeRef } = useDraggable({ id });
  const { setNodeRef: setDroppableNodeRef } = useDroppable({ id });

  const lastActiveId = usePrevious(active?.id?.toString(), (value) => Boolean(value));

  const derivedStyle = useMemo(() => {
    const childStyle = isChild ? styles.childContainer : undefined;
    const draggedStyle = active && active.id === id ? styles.draggedContainer : undefined;
    const errorStyle = hasError ? styles.errorContainer : undefined;
    const hoverStyle = isHover ? styles.onHover : undefined;
    const selectedStyle = isSelected
      ? { ...styles.selectedRightContainer, backgroundColor }
      : { ...styles.rightContainer, backgroundColor };
    const dropAnimation =
      lastActiveId && !active && lastActiveId === id ? { animation: 'dropFadeOut 1.25s ease-in-out' } : undefined;

    return {
      ...childStyle,
      ...selectedStyle,
      ...errorStyle,
      ...draggedStyle,
      ...hoverStyle,
      ...dropAnimation,
      ...style,
    };
  }, [id, lastActiveId, isChild, hasError, isSelected, backgroundColor, isHover, style]);

  return (
    <div
      ref={(element) => {
        setDraggableNodeRef(element);
        setDroppableNodeRef(element);
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={styles.wrapper}
      key={id}
    >
      <div style={plusStyle}>
        <UIconButton icon="plus-light" size="M" ghost type="standard-dark" preventDefault onClick={handlePlusClick} />
      </div>
      <div style={derivedStyle}>
        <div ref={setActivatorNodeRef} {...attributes} {...listeners}>
          <SOptionsMenu
            size="M"
            icon="drag-and-drop"
            type="standard-dark"
            options={contextMenuOptions}
            style={styles.optionsMenu}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default SMicroKnowledgeListItem;
