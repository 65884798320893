type CourseStatuses = {
  draft: boolean;
  published: boolean;
};

export const getCourseStatus = ({ draft, published }: CourseStatuses): 'pending' | 'unpublished' | 'published' => {
  if (draft && published) {
    return 'pending';
  }

  if (draft && !published) {
    return 'unpublished';
  }

  return 'published';
};
