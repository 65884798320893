import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  chipStyle: {
    marginRight: 4,
    marginBottom: 4,
  },
});

export default styleSheet;
