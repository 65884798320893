// @flow

import React, { useMemo } from 'react';
import { t } from 'i18next';

import UIcon from 'Components/unit/UIcon/UIcon';

import { COLORS } from 'Components/foundation';

import styles from './NPSScoreDifference.style';

type Props = {|
  scoreDifference: number,
  nbDaysBetweenScores: number,
|};

export const NPSScoreDifference = ({ scoreDifference, nbDaysBetweenScores }: Props) => {
  const icon = useMemo(() => {
    if (scoreDifference >= 0) {
      return {
        name: 'rise',
        color: COLORS.SUCCESS.DEFAULT,
      };
    }

    return { name: 'fall', color: COLORS.ERROR.DEFAULT };
  }, [scoreDifference]);

  const scoreDifferenceStyle = useMemo(() => {
    const bgColorStyle = scoreDifference > 0 ? styles.scoreDifferencePositive : styles.scoreDifferenceNegative;

    return {
      ...styles.scoreDifference,
      ...bgColorStyle,
    };
  }, [scoreDifference]);

  return (
    <div style={scoreDifferenceStyle}>
      <UIcon size={10} name={icon.name} color={icon.color} />
      <div style={styles.difference}>{`${scoreDifference >= 0 ? '+' : ''}${scoreDifference}`}</div>
      <div style={styles.daysBetweenScores}>
        {t(`analytics:nps.score_difference.day`, { count: nbDaysBetweenScores })}
      </div>
    </div>
  );

};
