import { OriginalRow } from 'Components/structural/STable/types';

export function getCallbackOptionParams(row: OriginalRow) {
  const { dataAccessor, id } = row;

  return {
    id,
    data: dataAccessor,
  };
}

