import React, { useCallback, useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';

import type { Language } from 'Features/audience/types/language';
import { useDeleteAudienceGroupModal } from 'Features/audience/hooks/useDeleteAudienceGroupModal';
import CreateAudienceGroupModal from 'Features/audience/ui/components/modals/CreateAudienceGroupModal/CreateAudienceGroupModal';
import UpdateAudienceGroupModal from 'Features/audience/ui/components/modals/UpdateAudienceGroupModal/UpdateAudienceGroupModal';
import { DeleteAudienceGroupModal } from 'Features/audience/ui/components/modals/DeleteAudienceGroupModal/DeleteAudienceGroupModal';
import { useAppDispatch } from 'Libs/redux/store';
import { actions } from 'Store/entities/audience-group/audience-group.slice';

import styles from './AudienceGroupList.style';
import { AudienceGroupListHeader } from './components/AudienceGroupListHeader';
import { useAudienceGroupListFiltersConfig } from './hooks/useAudienceGroupListFiltersConfig';
import { useAudienceGroupSortConfig } from './hooks/useAudienceGroupSortConfig';
import { useConfigCreateAudienceGroupModal } from './hooks/useConfigCreateAudienceGroupModal';
import { useAudienceGroupListService } from './hooks/useAudienceGroupListService';
import { AudienceGroupListBody } from './components/AudienceGroupListBody';
import { useAudienceGroupListState } from './hooks/useAudienceGroupListState';
import { useAudienceGroupAssignment } from './hooks/useAudienceGroupAssignment';

export type AudienceGroupListProps = {
  entityId: number;
  assignedAudienceGroupIds: readonly number[];
  language: Language;
  onGroupAssignment: (newGroupIds: number[]) => void;
  askConfirmationBeforeGroupAssignmentIfNeeded?: (cb: () => void) => void;
};

export const AudienceGroupList: FunctionComponent<AudienceGroupListProps> = ({
  entityId,
  assignedAudienceGroupIds,
  language,
  onGroupAssignment,
  askConfirmationBeforeGroupAssignmentIfNeeded,
}) => {
  const [selectedAudienceGroupId, setSelectedAudienceGroupId] = useState<number | null>(null);
  const { status, title, displayControlTabs, setTitle } = useAudienceGroupListFiltersConfig();

  const { sortProps, selectedSort, setSortProps } = useAudienceGroupSortConfig();

  const { dialog, assignAudienceGroup, assignedAudienceGroupIdsSet } = useAudienceGroupAssignment({
    entityId,
    assignedAudienceGroupIds,
    onGroupAssignment,
  });

  const handleAudienceGroupAssignment = useCallback(
    (groupId: number) =>
      askConfirmationBeforeGroupAssignmentIfNeeded
        ? askConfirmationBeforeGroupAssignmentIfNeeded(() => assignAudienceGroup(groupId))
        : assignAudienceGroup(groupId),
    [askConfirmationBeforeGroupAssignmentIfNeeded, assignAudienceGroup],
  );

  const { loading, noContent, offset, total, loadAudienceGroups, loadNextBatchAudienceGroups, refreshAudienceGroups } =
    useAudienceGroupListService({
      entityId,
      selectedSort,
      status,
      title,
      language,
    });

  const { audienceGroupListState } = useAudienceGroupListState({
    noContent,
    offset,
    total,
  });

  const { showCreateAudienceGroupModal, createButtonProps, handleCreateGroupModalToggle, handleOnCreateGroupConfirm } =
    useConfigCreateAudienceGroupModal({ refreshAudienceGroups });

  useEffect(() => {
    if (entityId > 0) {
      loadAudienceGroups(entityId, {
        offset: 0,
        selectedSort,
        status,
        title,
      });
    }
  }, [entityId, loadAudienceGroups, selectedSort, status, title]);

  const handleUpdateAudienceGroup = useCallback(() => {
    setSelectedAudienceGroupId(null);
    refreshAudienceGroups();
    onGroupAssignment(Array.from(assignedAudienceGroupIdsSet));
  }, [assignedAudienceGroupIdsSet, onGroupAssignment, refreshAudienceGroups]);

  const openUpdateModal = useCallback((id) => {
    setSelectedAudienceGroupId(id);
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setSelectedAudienceGroupId(null);
  }, []);

  const dispatch = useAppDispatch();

  const handleDeleteAudienceGroupDone = useCallback(
    (id: number) => {
      dispatch(actions.deleteAudienceGroup(id));
    },
    [dispatch],
  );

  const { deleteAudienceGroupModalProps, openDeleteModal } = useDeleteAudienceGroupModal({
    onDone: handleDeleteAudienceGroupDone,
  });

  return (
    <div style={styles.wrapper}>
      <AudienceGroupListHeader
        entityType="course"
        createButtonProps={createButtonProps}
        nameFilterValue={title || ''}
        statusFilterProps={displayControlTabs}
        sortValue={sortProps}
        handleFilterName={setTitle}
        handleSort={setSortProps}
      />
      <AudienceGroupListBody
        loading={loading}
        assignedAudienceGroupIdsSet={assignedAudienceGroupIdsSet}
        audienceGroupListState={audienceGroupListState}
        handleCreateGroupModalToggle={handleCreateGroupModalToggle}
        loadNextBatchAudienceGroups={loadNextBatchAudienceGroups}
        onCardClick={openUpdateModal}
        onCardSelect={handleAudienceGroupAssignment}
        onUpdateAudienceGroup={openUpdateModal}
        onDeleteAudienceGroup={openDeleteModal}
      />
      <CreateAudienceGroupModal
        visible={showCreateAudienceGroupModal}
        onClose={handleCreateGroupModalToggle}
        onConfirm={handleOnCreateGroupConfirm}
      />
      <DeleteAudienceGroupModal {...deleteAudienceGroupModalProps} />
      {!!selectedAudienceGroupId && (
        <UpdateAudienceGroupModal
          audienceGroupId={selectedAudienceGroupId}
          onClose={handleCloseUpdateModal}
          onConfirm={handleUpdateAudienceGroup}
        />
      )}
      {dialog}
    </div>
  );
};

export default AudienceGroupList;
