import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  standardContainer: {
    paddingTop: 40,
    paddingBottom: 16,
    minHeight: 193,
    minWidth: 780,
  },
  compactContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 780,
  },
  title: {
    ...TYPOGRAPHY.TITAN_L_SEMIBOLD,
    marginBottom: 4,
  },
  label: {
    ...TYPOGRAPHY.BODY4,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  count: {
    ...TYPOGRAPHY.BODY4_BOLD,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    marginRight: 4,
  },
};
