import { COLORS, TYPOGRAPHY } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '25px',
    gap: '40px',
  },
  icon: {
    fill: COLORS.BLACK.DEFAULT,
  },
  sessionContainer: {
    display: 'flex',
    gap: '4px',
    color: COLORS.TEXT.DEFAULT,
  },
  sessionText: {
    ...TYPOGRAPHY.BODY1,
  },
  sessionTextManualVariant: {
    color: COLORS.BLUE_GRAY_DARKEST.DEFAULT,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...TYPOGRAPHY.H3_SEMIBOLD,
    height: '20px',
    gap: '6px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    ...TYPOGRAPHY.BODY3,
    height: '20px',
    gap: '6px',
  },
});

export default styleSheet;
