import { DOCUMENT_FILE_TYPES } from 'Components/utils/Enum';
import { mergeDeep } from 'Libs/mergeDeep';

import Category from 'ModelsReact/Category/Category';
import SegmentationGroupItem from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

import type { DocumentFileType } from 'Libs/ts/types';
import type { CategoryType } from 'ModelsReact/Category/Category';
import type { SegmentationGroupItemType } from 'ModelsReact/SegmentationGroupItem/SegmentationGroupItem';

export type DocumentOpenings = { documentId: number; count: number };

export type DocumentType = Readonly<{
  id: number,
  title: string,
  type: DocumentFileType,
  size: number,
  path: string,
  available: boolean,
  archived: boolean,
  categoryId?: number,
  category?: CategoryType,
  segmentation: ReadonlyArray<SegmentationGroupItemType>,
}>;

export default function createDocument(values?: any): DocumentType {
  const defaultState: DocumentType = {
    id: 0,
    title: '',
    type: DOCUMENT_FILE_TYPES.PDF,
    size: 0,
    path: '',
    available: false,
    archived: false,
    categoryId: 0,
    category: Category(),
    segmentation: [],
  };

  if (!values) {
    return defaultState;
  }

  const segmentation = values.segmentation.map((seg: Partial<SegmentationGroupItemType>) => SegmentationGroupItem(seg));
  const category = Category(values.category);

  return mergeDeep<DocumentType>(defaultState, { ...values, category, segmentation });
}
