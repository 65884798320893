// @flow

/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */


/**
 * Display an item of a language with its translation progress
 *
 * Props:
 *  - countryCode: code of the country (ex: fr)
 *  - label: label of the country
 *  - isDefault: true if the language is the default language
 *  - progressLabel: label for the progress bar
 *  - progress: percentage of the progress bar
 *  - options: Array of OptionType which are displayed in the dropdown
 *  - optionsDisabled: boolean to disable/enable options
 *  - optionsTooltipText: string that appears in options tooltip
 *  - style: override component's style
 */

import * as React from 'react';
import { memoize } from 'lodash';

import { COLORS } from 'Components/foundation';
import UFlag from 'Components/unit/UFlag/UFlag';
import UIcon from 'Components/unit/UIcon/UIcon';
import UIconButton from 'Components/unit/UIconButton/UIconButton';
import UProgressBar from 'Components/unit/UProgressBar/UProgressBar';
import UDropdownBox from 'Components/unit/UDropdownBox/UDropdownBox';
import UDropdownItem from 'Components/unit/UDropdownItem/UDropdownItem';
import withTooltip from 'Components/hoc/withTooltip/withTooltip';

import styles from './ULanguageListItem.style';


type UIconButtonReturnType = $Exact<React.ElementConfig<typeof UIconButton>>;

export type OptionType = {|
  title: string,
  callback: () => any,
  color?: string,
|};

type Props = {|
  countryCode: string,
  label: string,
  isDefault: boolean,
  defaultLabel: string,
  progressLabel?: string,
  progress?: ?number,
  options: Array<OptionType>,
  style?: any,
  optionsDisabled: boolean,
  optionsTooltipText: string,
|};

type State = {|
  isDropdownBoxOpen: boolean,
|};

export class ULanguageListItem extends React.PureComponent<Props, State> {
  wrapperRef = React.createRef<HTMLDivElement>();
  WrappedCompUIconButton = withTooltip<UIconButtonReturnType>(UIconButton);

  static defaultProps = {
    style: undefined,
    progress: undefined,
    defaultLabel: 'default',
    progressLabel: '',
    optionsDisabled: false,
    optionsTooltipText: '',
  };

  state = {
    isDropdownBoxOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { isDefault, style } = this.props;
    const wrapperStyle = {
      ...styles.wrapper,
      ...(isDefault ? styles.wrapperDefault : {}),
      ...style,
    };

    return (
      <div style={wrapperStyle}>
        {this.renderItem()}
        {this.renderProgressBar()}
      </div>
    );
  }

  renderItem = () => {
    const { countryCode, label, isDefault, defaultLabel, optionsTooltipText, optionsDisabled } = this.props;
    const Comp = this.WrappedCompUIconButton;

    return (
      <div
        key={countryCode}
        style={styles.containerLanguage}
      >
        <div style={styles.containerFlagLabel}>
          <UFlag
            countryCode={countryCode}
            size={20}
          />
          <div style={styles.containerLabel}>
            <span style={styles.label}>
              {label}
            </span>
            {isDefault ? (
              <span style={styles.defaultLabel}>
                {`(${defaultLabel})`}
              </span>
            ): null}
          </div>
        </div>
        <div
          ref={this.wrapperRef}
          style={styles.containerIcon}
        >
          {isDefault ? (
            <UIcon
              name="lock"
              size={16}
              color={COLORS.TEXT.DISABLED}
            />
          ) : (
            <Comp
              tooltipPosition='left'
              tooltipContent={optionsTooltipText}
              tooltipEnabled={optionsDisabled}
              disabled={optionsDisabled}
              tooltipMaxWidth={400}
              icon="options"
              size='M'
              ghost
              onClick={this.handleClickOptions}
            />
          )}
          {this.renderDropdownBox()}
        </div>
      </div>
    );
  };

  renderDropdownBox = () => {
    const { options } = this.props;
    const { isDropdownBoxOpen } = this.state;

    if (!isDropdownBoxOpen)
      return null;

    return (
      <UDropdownBox
        items={options}
        renderItem={this.renderOption}
        style={styles.optionDropDown}
      />
    );
  };

  renderOption = ({ title, callback, color = COLORS.TEXT.DEFAULT }: OptionType) => {
    return (
      <UDropdownItem
        key={title}
        text={title}
        onClick={this.handleClickItemOption(callback)}
        color={color}
      />
    );
  };

  renderProgressBar = () => {
    const { progress, progressLabel } = this.props;

    if (!progress && progress !== 0)
      return null;

    return (
      <UProgressBar
        percent={progress}
        label={progressLabel}
        style={styles.containerProgressBar}
      />
    );
  };

  handleClickItemOption = memoize<[() => any], () => void>((callback: () => any) => () => {
    this.setState({ isDropdownBoxOpen: false });
    callback();
  });

  handleClickOptions = () => {
    const { optionsDisabled } = this.props;
    const { isDropdownBoxOpen } = this.state;

    if (!optionsDisabled)
      this.setState({ isDropdownBoxOpen: !isDropdownBoxOpen });
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && event.target instanceof Node && !this.wrapperRef.current.contains(event.target))
      this.setState({ isDropdownBoxOpen: false });
  };
}


export default ULanguageListItem;
