import { createStyleSheet } from 'Components/foundation/stylesheets';

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

const styleSheet = createStyleSheet({
  courseCard: {
    margin: 'auto',
  },
  warning: {
    ...TYPOGRAPHY.BODY3,
    color: COLORS.TEXT.SECONDARY_HOVER,
    marginTop: '32px',
  },
  confirmContainer: {
    marginLeft: '20px',
    marginRight: '20px',

    /**
     * To offset title bottom margin => 18 + 32 from the title = 50
     * between title and course card
     */
    marginTop: '18px',
  },
  successContainer: {
    padding: '20px',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  successIllustration: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  successLabel: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '24px',
    ...TYPOGRAPHY.H1,
    fontWeight: 700,
  },
});

export default styleSheet;
