import { apiAction } from 'Libs/redux/utils';
import type { FilterState } from 'Libs/filter/types';
import type { ApiActionType } from 'Libs/redux/utils';
import { store } from 'Libs/redux/store';

import { buildQueryFilterFromState } from './utils';

type KnowledgeGridGetKnowledgeFullPaginated = ApiActionType<'KNOWLEDGE_GRID_GET_FULL_PAGINATED'>;
type KnowledgeGridUpdateFiltersAction = {
  type: 'KNOWLEDGE_GRID_UPDATE_FILTERS';
  selectedFilters: Array<FilterState>;
};
type KnowledgeGridReset = {
  type: 'KNOWLEDGE_GRID_RESET';
};

export const actions = {
  getKnowledgeFullPaginated: (offset: number, limit: number): KnowledgeGridGetKnowledgeFullPaginated => {
    const {
      pages: { knowledgeGrid },
    } = store.getState();
    const filter = buildQueryFilterFromState(knowledgeGrid);

    return apiAction({
      type: 'KNOWLEDGE_GRID_GET_FULL_PAGINATED',
      route: '/api/Knowledge/full/paginated',
      method: 'GET',
      query: {
        filter: { ...filter },
        start: offset,
        length: limit,
      },
      meta: {
        isInitialLoad: offset === 0,
      },
    });
  },
  updateFilters: (selectedFilters: Array<FilterState>): KnowledgeGridUpdateFiltersAction => ({
    type: 'KNOWLEDGE_GRID_UPDATE_FILTERS',
    selectedFilters,
  }),
  reset: (): KnowledgeGridReset => ({ type: 'KNOWLEDGE_GRID_RESET' }),
};

export type ActionKeys = keyof typeof actions;
export type ActionsFn = typeof actions[ActionKeys];
export type KnowledgeGridAction = ReturnType<ActionsFn>;
