import React from 'react';
import { Image, LanguageCode } from '@sparted/shared-library/business/types';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useTypedSelector } from 'Libs/redux/utils';
import { redirect } from 'Libs/routing';

import { getCurrentBOLanguageOrDefault, getLanguagesAvailableWithCode } from 'Services/language/languageService';
import { getAllThematics } from 'Store/entities/configuration/thematic/thematic.selectors';
import { translateThematicsName } from 'Store/entities/configuration/thematic/thematic.service';
import { createContent } from 'Store/entities/content/content.hook';

import MModal from 'Components/modal/MModal/MModal';
import SInput from 'Components/structural/SInput/SInput';
import SSelect from 'Components/structural/SSelect/SSelect';
import STreeSelect from 'Components/structural/STreeSelect/STreeSelect';
import ULabel from 'Components/unit/ULabel/ULabel';
import UMediaPicker, { PickerSelectedImage } from 'Components/unit/UMediaPicker/UMediaPicker';

import styles from './contentCreationModal.style';

const ID_LIMIT = 5;
const TITLE_LIMIT = 40;

export type ContentCreationModalProps = {
  visible: boolean;
  onClose: () => void;
  fetchContents: () => void;
};

const defaultValues = {
  title: '',
  language: 'en-US' as const,
  identifier: '',
  thematic: null,
  image: {
    cdn: '',
    url: '',
  },
};

type FormValues = {
  title: string;
  language: LanguageCode;
  identifier: string;
  thematic: number | null;
  image: Image | PickerSelectedImage;
};

export const ContentCreationModal = ({ visible, onClose, fetchContents }: ContentCreationModalProps) => {
  const userLanguage = getCurrentBOLanguageOrDefault();
  const [error, setError] = useState('');

  const thematics = useTypedSelector(getAllThematics);

  const formatedThematicsForTreeSelect = useMemo(() => {
    if (!thematics || !userLanguage?.id) return [];
    return translateThematicsName(userLanguage.id, thematics).map((thematic) => ({
      id: thematic.id,
      label: thematic.translatedLabel,
      parentId: thematic.ancestorId,
    }));
  }, [thematics, userLanguage]);

  const languages = getLanguagesAvailableWithCode().map((language) => ({
    id: language.id,
    value: language.languageCode as LanguageCode,
    label: language.languageName,
  }));

  const { setValue, watch, reset } = useForm<FormValues>({
    defaultValues,
  });

  const submitForm = async () => {
    const formValues = watch();
    try {
      const response = await createContent({
        ...formValues,
        ...(formValues.thematic ? { thematic: { id: formValues.thematic } } : { thematic: undefined }),
        name: { [formValues.language]: formValues.title },
        coverImage: formValues.image,
        statuses: {
          [formValues.language]: 'draft' as const,
        },
      });
      fetchContents();
      redirect(`/content/${response.id}`);
    } catch (e) {
      setError(t('contents:edition.error'));
    }
  };

  const selectedLanguage = useMemo(
    () => languages.find((language) => language.value === watch('language')),
    [watch('language')],
  );

  const selectedThematic = useMemo(
    () => formatedThematicsForTreeSelect.find((thematic) => thematic.id === watch('thematic')),
    [watch('thematic')],
  );

  const handleClose = () => {
    reset();
    onClose();
    setError('');
  };

  return (
    <MModal
      visible={visible}
      onCloseModal={handleClose}
      onAction={submitForm}
      onSecondAction={handleClose}
      title={t('contents:contents_list.add_content_title')}
      labelActionButton={t('contents:contents_list.add_content_submit')}
      labelSecondButton={t('contents:contents_list.add_content_cancel')}
      labelError={error}
      type="dialog"
      showExitCross
    >
      <form>
        <SInput
          name="title"
          value={watch('title')}
          onChange={(value) => {
            setValue('title', value);
          }}
          label={t('contents:edition.label')}
          required
          type="large"
          maxLength={TITLE_LIMIT}
          placeholder={t('contents:edition.label_placeholder')}
        />
        <div style={styles.firstFormContainer}>
          <div style={styles.imageContainer}>
            <ULabel style={{ marginBottom: '8px' }}>{t('contents:edition.image')}</ULabel>
            <UMediaPicker
              dropArea
              size="L"
              image={watch('image').url}
              media={{
                mediaType: 'image',
                onChange: (media) => {
                  setValue('image', media);
                },
              }}
            />
          </div>
          <div style={styles.idLanguageForm}>
            <SSelect
              label={t('contents:edition.language')}
              required
              items={languages}
              selected={selectedLanguage}
              type="large"
              style={styles.languageSelector}
              onSelect={(language) => {
                setValue('language', language.value as LanguageCode);
              }}
            />
            <SInput
              value={watch('identifier')}
              label={t('contents:edition.id')}
              type="large"
              onChange={(value) => setValue('identifier', value)}
              maxLength={ID_LIMIT}
              placeholder={t('contents:edition.id_placeholder')}
            />
          </div>
        </div>
        <ULabel required style={styles.thematicLabel}>
          {t('contents:edition.thematics')}
        </ULabel>
        <STreeSelect
          items={formatedThematicsForTreeSelect}
          onSelect={(id) => setValue('thematic', id)}
          selectedItem={selectedThematic}
          placeholder={t('contents:edition.thematics_placeholder')}
          type="large"
        />
      </form>
    </MModal>
  );
};
