import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { t } from 'i18next';

import type { CSSProperties, FunctionComponent } from 'react';

import UIcon from 'Components/unit/UIcon/UIcon';
import UEntityCardCore from 'Components/unit/UEntityCardCore/UEntityCardCore';
import STruncatedChipList from 'Components/structural/STruncatedChipList/STruncatedChipList';
import STooltip from 'Components/structural/STooltip/STooltip';
import type { MenuOptions } from 'Components/structural/SOptionsMenu/SOptionsMenu';
import type { ChipItem } from 'Components/structural/STruncatedChipList/STruncatedChipList';
import { UAbstractSelectVariant } from 'Components/unit/UAbstractSelect/UAbstractSelect';

import styles from './SAudienceGroupCard.style';

export type SAudienceGroupCardProps = {
  style?: CSSProperties;
  groupId: number;
  name: string;
  variant?: UAbstractSelectVariant;
  meta: {
    target: string;
    explanation: string;
  };
  description?: string;
  selected?: boolean;
  disabled?: boolean;
  segmentations?: ChipItem[];
  showMenu?: boolean;
  onClick?: (id: number) => void;
  onSelect?: (id: number) => void;
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
};

const MAX_SEGMENTATIONS_DISPLAYED = 10;

export const SAudienceGroupCard: FunctionComponent<SAudienceGroupCardProps> = ({
  style = {},
  name,
  meta,
  groupId,
  variant = 'check',
  selected,
  disabled,
  description,
  segmentations = [],
  showMenu = true,
  onSelect,
  onClick,
  onUpdate,
  onDelete,
}) => {
  const handleClick = useCallback(() => variant !== 'none' && onClick?.(groupId), [onClick, groupId]);
  const handleSelect = useCallback(() => variant !== 'none' && onSelect?.(groupId), [onSelect, groupId]);

  const tooltipId = useMemo(() => uuid(), []);

  const handleUpdate = useCallback(() => {
    onUpdate(groupId);
  }, [groupId, onUpdate]);

  const handleDeleteGroup = useCallback(() => {
    onDelete(groupId);
  }, [groupId, onDelete]);

  const menuOptions = useMemo<MenuOptions[]>(
    () => [
      {
        id: 1,
        text: t('audiences:audience_group_card.menu.edit'),
        mode: 'standard',
        icon: 'edit',
        onClick: handleUpdate,
      },
      {
        id: 2,
        text: t('audiences:audience_group_card.menu.delete'),
        mode: 'destructive',
        icon: 'delete',
        onClick: handleDeleteGroup,
      },
    ],
    [handleDeleteGroup, handleUpdate],
  );

  return (
    <div style={style}>
      <UEntityCardCore
        onSelect={handleSelect}
        onClick={handleClick}
        selected={selected}
        disabled={disabled}
        menuOptions={showMenu ? menuOptions : undefined}
        selectionVariant={variant}
      >
        <div style={styles.mainContainer} data-testid="AUDIENCE_GROUP_MAIN_CONTAINER">
          <div style={styles.groupInformation}>
            <div style={styles.name}>{name}</div>
            <div style={styles.details}>
              <div style={styles.targeting}>{meta.target}</div>
              <div data-tip data-for={tooltipId} style={styles.tooltip}>
                <UIcon name="info" size={11} />
                <STooltip id={tooltipId} content={meta.explanation} maxWidth={400} position="top" />
              </div>
            </div>
            {description && (
              <div data-testid="AUDIENCE_GROUP_DESCRIPTION" style={styles.description}>
                {description}
              </div>
            )}
            {segmentations.length !== 0 && (
              <div style={styles.segmentationContainer}>
                <STruncatedChipList chips={segmentations} max={MAX_SEGMENTATIONS_DISPLAYED} />
              </div>
            )}
          </div>
        </div>
      </UEntityCardCore>
    </div>
  );
};

export default SAudienceGroupCard;
