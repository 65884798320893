import React from 'react';

import styles from 'Components/structural/STable/STable.style';

type EmptyRowProps = {
  isEmpty: boolean;
  isTableLoading: boolean;
  emptyRowText: string;
};

function EmptyRow({ isEmpty, isTableLoading, emptyRowText }: EmptyRowProps) {
  if (!isTableLoading && !isEmpty) return null;

  return <div style={styles.emptyRow}>{emptyRowText}</div>;
}

export default EmptyRow;
