import { t } from 'i18next';

const API_ERRORS: Record<string, () => string> = {
  'must-be-string': () => t('api_errors:must_be_string'),
  'must-be-number': () => t('api_errors:must_be_number'),
  'must-be-number-string': () => t('api_errors:must_be_number_string'),
  'must-be-hexadecimal': () => t('api_errors:must_be_hexadecimal'),
  'must-be-boolean': () => t('api_errors:must_be_boolean'),
  'must-be-boolean-string': () => t('api_errors:must_be_boolean_string'),
  'must-be-object': () => t('api_errors:must_be_object'),
  'must-be-array': () => t('api_errors:must_be_array'),
  'must-be-defined': () => t('api_errors:must_be_defined'),
  'must-be-in-enum': () => t('api_errors:must_be_in_enum'),
  'must-be-valid-date': () => t('api_errors:must_be_valid_date'),
  'must-be-plain-date': () => t('api_errors:must_be_plain_date'),
  'must-be-valid-uuid': () => t('api_errors:must_be_valid_uuid'),
  'must-be-valid-locale': () => t('api_errors:must_be_valid_locale'),
  'must-be-email': () => t('api_errors:must_be_email'),
  'string-too-long': () => t('api_errors:string_too_long'),
  'string-too-short': () => t('api_errors:string_too_short'),
  'number-too-big': () => t('api_errors:number_too_big'),
  'number-too-small': () => t('api_errors:number_too_small'),
  'array-must-not-be-empty': () => t('api_errors:array_must_not_be_empty'),
  'invalid-multilingual-label': () => t('api_errors:invalid_multilingual_label'),
  'invalid-content-status': () => t('api_errors:invalid_content_status'),
  'invalid-zones': () => t('api_errors:invalid_zones'),
  'invalid-cards': () => t('api_errors:invalid_cards'),
  'invalid-media': () => t('api_errors:invalid_media'),
  'invalid-campaign-session': () => t('api_errors:invalid_campaign_session'),
  'thematic-not-found': () => t('api_errors:thematic_not_found'),
  'image-not-found': () => t('api_errors:image_not_found'),
  'video-not-found': () => t('api_errors:video_not_found'),
  'no-correct-choice': () => t('api_errors:no_correct_choice'),
  'not-valid-length': () => t('api_errors:not_valid_length'),
  'invalid-cards-number': () => t('api_errors:invalid_cards_number'),
  'text-or-image-must-be-defined': () => t('api_errors:text_or_image_must_be_defined'),
};

export const translateApiErrors = (error?: string[] | Record<string, string[]>) => {
  if (!error) return undefined;

  const flattenErrors = Array.isArray(error) ? error : Object.values(error).flat();
  return flattenErrors
    .map((err) => {
      if (API_ERRORS[err]) {
        return API_ERRORS[err]();
      }
      console.error('Unknown api error : ', err);
      return err;
    })
    .join(', ');
};
