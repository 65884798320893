import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  sticky: {
    position: 'sticky',
    // If top is not -1px, the element will never intersect with its parent
    top: '-1px',

    // No responsive under 856px according to the figma design
    minWidth: '856px',
    height: '59px',
  },
  container: {
    height: '59px',
  },
});

export default styleSheet;
