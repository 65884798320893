import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  wrapper: {
    borderRadius: 12,
    flex: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    paddingTop: 40,
    maxWidth: '766px',
    textAlign: 'center',
    width: '100%',
  },
  titleWithoutDescription: {
    paddingBottom: 48,
  },
  description: {
    paddingTop: 24,
    paddingBottom: 48,
    maxWidth: '616px',
    textAlign: 'center',
  },
  illustration: {
    paddingTop: 48,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 48,
  },
  textLink: {
    margin: '0px 16px',
  },
});

export default styleSheet;
