import { COLORS } from 'Components/foundation';
import { createStyleSheet } from 'Components/foundation/stylesheets';

const styleSheet = createStyleSheet({
  container: {
    height: '43px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '8px 32px',
    backgroundColor: COLORS.WHITE.DEFAULT,
    borderBottom: '1px solid',
    borderColor: COLORS.GREY_MEDIUM.DEFAULT,
  },
  containerWithContent: {
    backgroundColor: COLORS.PRIMARY_LIGHT.DEFAULT,
    borderColor: COLORS.PRIMARY.MEDIUM,
  },
  activeContainer: {
    backgroundColor: COLORS.SUCCESS.LIGHTER,
    borderColor: COLORS.SUCCESS.LIGHT,
  },
});

export default styleSheet;
