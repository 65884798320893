// @flow

import { TYPOGRAPHY, COLORS } from 'Components/foundation';

export default {
  wrapper: {
    position: 'relative',
    width: 28,
    height: 18,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  opacityDark: {
    opacity: 0.5,
  },
  opacityLight: {
    opacity: 0.8,
  },
  small: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: 1,
    transform: 'translateX(-50%)',
  },
  medium: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: -1,
    transform: 'translateX(-50%)',
  },
};
