import {
  getDailySerieEndDate as _getDailySerieEndDate,
  getDailySerieNumExtraDays as _getDailySerieNumExtraDays,
  getDateForDailySerieDay as _getDateForDailySerieDay,
} from 'Libs/dailyserieManager';

import type { DailySerieType } from './redux/models/DailySerie';

export const getDailySerieEndDate = (dailyserie: DailySerieType, startDate: string): string =>
  _getDailySerieEndDate(dailyserie, startDate, dailyserie.numExtraDays).toISOString();

export const getDailySerieNumExtraDays = (dailyserie: DailySerieType, startDate: string, endDate: string): number =>
  _getDailySerieNumExtraDays(dailyserie, startDate, endDate);

export const getDateForDailySerieDay = (dailyserie: DailySerieType, startDate: string, order: number): string =>
  _getDateForDailySerieDay(dailyserie, startDate, order).toISOString();
