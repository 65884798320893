import styles from 'Components/unit/UGalleryItem/UGalleryItem.style';
import React from 'react';

type Props = {
  width: number;
  height: number;
};

const PlaceHolder = ({ width, height }: Props) => (
  <div
    style={{
      ...styles.placeholder,
      width,
      height,
    }}
  />
);

export default PlaceHolder;
