import _ from 'lodash';
import { t } from 'i18next';
import Model from 'models/Model';
import Enum from 'models/Enum';

var OpenQuestion = {
  endpoint  : 'api/OpenQuestions',
  attributes: {
    question: {
      type : 'pointer',
      Model: 'GameItem',
    },
    answer: {
      type : 'pointer',
      Model: 'GameItem',
    },
    explanation: {
      type : 'pointer',
      Model: 'GameItem',
    },
    imageFront: {
      type : 'pointer',
      Model: 'Image',
    },
    imageBack: {
      type : 'pointer',
      Model: 'Image',
    },
    videoFront: {
      type : 'pointer',
      Model: 'Video',
    },
    videoBack: {
      type : 'pointer',
      Model: 'Video',
    },
    mediaTypeFrontId: {
      type: 'number',
    },
    mediaTypeBackId: {
      type: 'number',
    },
    perfectMode: {
      type: 'boolean',
    },
  },
  classMethods: {
    initGameplay: function initGameplay(_QCM, knowledgeId) {
      return {
        question: {
          typeId     : Enum.gameItemTypes.QUESTION,
          knowledgeId: knowledgeId,
        },
        explanation: {
          typeId     : Enum.gameItemTypes.TEXT,
          knowledgeId: knowledgeId,
        },
        answer: {
          typeId     : Enum.gameItemTypes.ANSWER,
          knowledgeId: knowledgeId,
        },
      };
    },
  },
  methods: {
    isEqualTo: function isEqualTo(_OpenQuestion, toCompare) {
      var jsonToCompare = toCompare.toJSON ? toCompare.toJSON() : toCompare;

      return _.isEqual(this.toJSON(), jsonToCompare);
    },
    presetFromKnowledge: function presetFromKnowledge(_OpenQuestion, knowledge) {
      var sqcm;
      var qcm;

      this.explanation().data(knowledge.explanation().data());
      sqcm = knowledge.getContent(Enum.gameplay.SQCM);

      if (sqcm)
        return this.presetFromSQCM(sqcm, knowledge);

      qcm = knowledge.getContent(Enum.gameplay.QCM);

      if (qcm)
        return this.presetFromQCM(qcm, knowledge);

      if (!this.explanation().data())
        this.explanation().data(knowledge.explanationText());

      return null;
    },
    presetFromSQCM: function presetFromSQCM(_OpenQuestion, sqcm, knowledge) {
      var answers = sqcm.sqcmChoices().filter(function findAnswer(choice) {
        return choice.rightAnswer();
      });

      this.answer().data(answers.length ? answers[0].gameItem().data() : '');
      this.question().data(sqcm.question().data());
      this.explanation().data(sqcm.explanation().data());
      this.mediaTypeFrontId(sqcm.mediaTypeFrontId());
      this.mediaTypeBackId(sqcm.mediaTypeBackId());
      this.imageFront(sqcm.imageFront());
      this.imageBack(sqcm.imageBack());
      this.videoFront(sqcm.videoFront());
      this.videoBack(sqcm.videoBack());
    },
    presetFromQCM: function presetFromQCM(_OpenQuestion, qcm, knowledge) {
      var answers = qcm.qcmChoices().filter(function findAnswer(choice) {
        return choice.rightAnswer();
      });

      this.answer().data(answers.length ? answers[0].gameItem().data() : '');
      this.question().data(qcm.question().data());
      this.explanation().data(qcm.explanation().data());
      this.mediaTypeFrontId(qcm.mediaTypeFrontId());
      this.mediaTypeBackId(qcm.mediaTypeBackId());
      this.imageFront(qcm.imageFront());
      this.imageBack(qcm.imageBack());
      this.videoFront(qcm.videoFront());
      this.videoBack(qcm.videoBack());
    },
    mediaFront: function mediaFront(_OpenQuestion, media) {
      if (!media)
        return this.getMedia('Front');

      return this.setMedia('Front', media);
    },

    mediaBack: function mediaBack(_OpenQuestion, media) {
      if (!media)
        return this.getMedia('Back');

      return this.setMedia('Back', media);
    },

    getMedia: function getMedia(_OpenQuestion, panel) {
      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel]();

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel]();
    },

    setMedia: function setMedia(_OpenQuestion, panel, media) {
      var mediaType = media._type;

      this['mediaType' + panel + 'Id'](Enum.mediaType[mediaType]);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Video)
        return this['video' + panel](media);

      if (this['mediaType' + panel + 'Id']() === Enum.mediaType.Image)
        return this['image' + panel](media);
    },
    countUseOfGameItem: function countUseOfGameItem(_OpenQuestion, giId) {
      var count = 0;

      if (this.question().id() === giId)
        count += 1;
      if (this.explanation().id() === giId)
        count += 1;
      if (this.answer().id() === giId)
        count += 1;

      return count;
    },
    deleteGameItem: function deleteGameItem(_OpenQuestion, giId) {
      var GameItem = Model('GameItem');

      if (this.question().id() === giId)
        this.question(new GameItem());
      if (this.explanation().id() === giId)
        this.question(new GameItem());
      if (this.answer().id() === giId)
        this.answer(new GameItem());
    },

    validate: function validate() {
      if (!this.answer().data())
        throw new Error(t('gameplays:validation.answer_missing'));

      if (!this.explanation().data())
        throw new Error(t('gameplays:validation.explanation_missing'));
      if (!this.question().data())
        throw new Error(t('gameplays:validation.question_missing'));
      if (!this.mediaFront() || !this.mediaFront().url())
        throw new Error(t('gameplays:validation.first_media_missing'));
      if (!this.mediaBack() || !this.mediaBack().url())
        throw new Error(t('gameplays:validation.second_media_missing'));

      return true;
    },
    getDirective: function getDirective() {
      if (!this.mediaFront() || !this.mediaFront().url())
        return t('gameplays:directives.add_media');
      if (!this.mediaBack() || !this.mediaBack().url())
        return t('gameplays:directives.add_media');
      if (!this.question().data())
        return t('gameplays:directives.enter_question');
      if (!this.answer().data())
      return t('gameplays:directives.enter_answer');
      if (!this.explanation().data())
      return t('gameplays:directives.enter_explanation');

      return '';
    },
  },
};

export default Model.register('OpenQuestion', OpenQuestion);
